import React from 'react';
import { Field, reduxForm, formValueSelector, change } from 'redux-form';
import { connect } from 'react-redux'
import 'react-redux';
import 'redux';
import "react-datepicker/dist/react-datepicker.css";
import { TextAreaForRedux, SelectForRedux, RadioListGroupForRedux } from '../../tools/BootstrapControls'
import { clickNextAction, scrollTofocusError } from "../common/MeterFormValidations"
import { validateCertificateFormSelection } from './MeterValidationCertificate.Validation';
const validate = validateCertificateFormSelection;
let MeterValidationCertificateFormSelection = props => {

   const { handleSubmit, meterEquipInfo, loading, hasError } = props

   const disableMeteringEquipment = (parseInt(meterEquipInfo.formStatus) == 2 || meterEquipInfo.maintenanceValidation == 1) || props.disableEditing || loading ;
   const disableFields = ((parseInt(meterEquipInfo.formStatus) == 2) || props.disableFields  || props.disableEditing || loading );
   
   //const disableFormField = (meterEquipInfo.id > 0 && meterEquipInfo.newMeterEquipment != null) ? true : false;
   const meteringFormSelectionOptions =
      [
         {
            id: "2", name: 'REVALIDATION',
            description: 'EXISTING Pattern Approved flow meter or Non Pattern Approved flow meter that already exists on the site infrastructure e.g. where maintenance or repairs have occured', selected: false
         },
         {
            id: "3", name: 'REPLACEMENT',
            description: 'Validation of a NEW Pattern Approved flow meter is replacing an old Pattern Approved flow meter or an old Non Pattern Approved flow meter as a result of the NSW Non-Urban Water Metering Policy', selected: false
         },
         {
            id: "1", name: 'First meter on site',
            description: "Validation of a NEW Pattern Approved flow meter is being installed for the first time on a green field site or new established pump site as a result of the NSW Non-Urban Water Metering Policy", selected: false
         }
      ];

   const showAlertForNew = (e) => {
      if (e.target.value == 1) {
         alert("This option should only be selected if a new meter is being installed where there was no meter previously. This is a meter that is unknown to WaterNSW and will create a new WaterNSW meter number.");
      }
   }

   const maintenanceOptions =
      [
         { id: "1", name: "Select", selected: true },
         { id: "2", name: "Change/Maintenance on batteries", selected: false },
         { id: "3", name: "Change/Maintenance on Aerial", selected: false },
         { id: "4", name: "Change/Maintenance on Solar panel", selected: false },
         { id: "5", name: "Seal number change", selected: false },
         { id: "6", name: "Diagnostic work", selected: false },
         { id: "7", name: "General Maintenance", selected: false },
         { id: "8", name: "Other", selected: false }
      ];
   const resetValues = (event) => {
      if (parseInt(event.target.value) == 1) {
         props.dispatch(change(props.form, 'meteringEquipmentInformation.meterBeforeApril2019', ""));
         props.dispatch(change(props.form, 'meteringEquipmentInformation.dateOfInstallationBeforeApril2019', ""));
         props.dispatch(change(props.form, 'meteringEquipmentInformation.reasonForRevalidation', ""));
         props.dispatch(change(props.form, 'meteringEquipmentInformation.dateOfLastValidation', ""));
         props.dispatch(change(props.form, 'meteringEquipmentInformation.meterCircumstancesOptions', []));
         props.dispatch(change(props.form, 'meteringEquipmentInformation.meterRemovedDate', ""));
         props.dispatch(change(props.form, 'meteringEquipmentInformation.dateBeforeMaintenance', ""));
         props.dispatch(change(props.form, 'meteringEquipmentInformation.readingBeforeMaintenance', ""));
         props.dispatch(change(props.form, 'meteringEquipmentInformation.dateAfterMaintenance', ""));
         props.dispatch(change(props.form, 'meteringEquipmentInformation.readingAfterMaintenance', ""));
         props.dispatch(change(props.form, 'meteringEquipmentInformation.finalReadingRemovedMeter', ""));
         props.dispatch(change(props.form, 'meteringEquipmentInformation.finalReadingDateRemovedMeter', ""));
         props.dispatch(change(props.form, 'meteringEquipmentInformation.previousMeterManufactureModel', ""));
         props.dispatch(change(props.form, 'meteringEquipmentInformation.previousMeterSerialNumber', ""));
         props.dispatch(change(props.form, 'meteringEquipmentInformation.previousMeterSize', ""));

      }
      else if (parseInt(event.target.value) == 3) {
         props.dispatch(change(props.form, 'meteringEquipmentInformation.meterBeforeApril2019', ""));
         props.dispatch(change(props.form, 'meteringEquipmentInformation.dateOfInstallationBeforeApril2019', ""));
         props.dispatch(change(props.form, 'meteringEquipmentInformation.reasonForRevalidation', ""));
         props.dispatch(change(props.form, 'meteringEquipmentInformation.dateOfLastValidation', ""));
         props.dispatch(change(props.form, 'meteringEquipmentInformation.meterCircumstancesOptions', []));
         props.dispatch(change(props.form, 'meteringEquipmentInformation.meterRemovedDate', ""));
         props.dispatch(change(props.form, 'meteringEquipmentInformation.dateBeforeMaintenance', ""));
         props.dispatch(change(props.form, 'meteringEquipmentInformation.readingBeforeMaintenance', ""));
         props.dispatch(change(props.form, 'meteringEquipmentInformation.dateAfterMaintenance', ""));
         props.dispatch(change(props.form, 'meteringEquipmentInformation.readingAfterMaintenance', ""));
      }
      else {
         props.dispatch(change(props.form, 'meteringEquipmentInformation.previousMeterManufactureModel', ""));
         props.dispatch(change(props.form, 'meteringEquipmentInformation.previousMeterSerialNumber', ""));
         props.dispatch(change(props.form, 'meteringEquipmentInformation.previousMeterSize', ""));
         props.dispatch(change(props.form, 'meteringEquipmentInformation.finalReadingRemovedMeter', ""));
         props.dispatch(change(props.form, 'meteringEquipmentInformation.finalReadingDateRemovedMeter', ""));
      }
   }

   return (
      <form className="vertical" onSubmit={handleSubmit}>
         <h5 className='mb-3'>Is this validation for a new/replacement meter or for a revalidation of an existing meter? <span className='text-danger'>* </span></h5>

         <div className='row mb-3'>
            <div className='col-sm-8'>
               <Field
                  name={"meteringEquipmentInformation.newMeterEquipment"}
                  component={RadioListGroupForRedux}
                  options={meteringFormSelectionOptions}
                  onChange={showAlertForNew}
                  props={{ disabled: disableMeteringEquipment || disableFields}}
                  values={meterEquipInfo.newMeterEquipment}
                  handleChange={resetValues}
               />
            </div>
         </div>


         {(meterEquipInfo.maintenanceValidation == 1) &&
            <div className='row mb-3'>
               <div className='col-sm-6'>
                  <Field
                     name="meteringEquipmentInformation.reasonForMaintenance"
                     component={SelectForRedux}
                     options={maintenanceOptions}
                     values={meterEquipInfo.reasonForMaintenance}
                     props={{ disabled: disableFields }}
                     label={<>Reason for maintenance validation <span className='text-danger'>*</span></>}
                  />
               </div>
            </div>
         }

         {(meterEquipInfo.maintenanceValidation == 1) && <>
            <div className='mb-3'>
               <Field
                  name="meteringEquipmentInformation.reasonForMaintenanceComment"
                  label={<>Description Reason<span className='text-danger'>* </span></>}
                  props={{ disabled: disableFields }}
                  component={TextAreaForRedux}
                  cols={40}
                  rows={10}
               />
            </div>
         </>}

         {hasError && <div className='alert alert-danger'>
         Please fix all errors before you continue
         </div>}

         <div className="text-end">
            <button type="button" className="btn btn-success"
               disabled={loading}
               onClick={() => { clickNextAction(props); }}>
               Next
            </button>
         </div>
      </form>
   )
}

MeterValidationCertificateFormSelection = reduxForm({
   destroyOnUnmount: false,
   forceUnregisterOnUnmount: true,
   validate,
   onSubmitFail: (errors, dispatch ) => {
      dispatch(change('certificate', 'hasError', true));
   },
   onSubmitSuccess:(result , dispatch )=>{
      dispatch(change('certificate', 'hasError', false));
      window.scrollTo(0, 0);
   }
})(MeterValidationCertificateFormSelection)

MeterValidationCertificateFormSelection = connect(
   (state, props) => {
      const selectorCert = formValueSelector('certificate')
      const hasError = selectorCert(state, 'hasError')

      // can select values individually
      const selector = formValueSelector(props.form)
      const meterEquipInfo = selector(state, 'meteringEquipmentInformation')
      const loading = selector(state, 'loading')
      const form = props.form
      return {
         meterEquipInfo,
         form,
         loading,
         hasError
      }
   }
)(MeterValidationCertificateFormSelection)

export default MeterValidationCertificateFormSelection