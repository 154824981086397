import React from 'react';
import { Field, reduxForm, formValueSelector, change } from 'redux-form';
import { connect } from 'react-redux'
import 'react-redux';
import 'redux';
import { renderDropdown } from '../design/MeterDesignCertificate.FIelds'
import { validateCertificateChecklist as validate} from './AHDValidationCertificate.Validation';
import { RadiosForRedux, InputTextForRedux , DatePickerForRedux, SelectForRedux, CheckboxForRedux, TextAreaForRedux}  from '../../tools/BootstrapControls'


let AHDValidationAndMaintenanceChecklist = props => {

     const radioStyle = {
          width:'150px'
     }

     const checklistOptions =
          [
               { id: "3", name: "Yes", selected: false },
               { id: "2", name: "No", selected: false },
              
          ];

     const {
          handleSubmit, meterEquipInfo, masterDataValues, previousPage, onSubmit, ahdChecklist,validate
     } = props

     const disableFields = (parseInt(meterEquipInfo.formStatus) == 2)? true : false;

     return (
          <form onSubmit={handleSubmit}>
               <h5 className='mb-3'>Use this checklist as a guide to ensure that all items have been considered when installing, validating or performing meter maintenance.</h5>
               <table className="table table-sm fs-90">
                    <thead>
                         <tr>
                              <th>No.</th>
                              <th>Description of Item</th>
                              <th>Yes/No or NA</th>
                         </tr>
                    </thead>
                    <tbody>
                         <tr>
                              <td>
                                   1
                              </td>
                              <td>
                              The survey method used is in accordance with the requirements of Establishing survey benchmarks for floodplain harvesting meters in NSW (2020) guidelines.

                              </td>
                              <td style={radioStyle}>
                                   <Field 
                                        name="ahdChecklist.q1"
                                        component={SelectForRedux}
                                        values={ahdChecklist.q1}
                                        props={{ disabled: disableFields }}
                                        options={checklistOptions} 
                                   />

                              </td>
                         </tr>
                         <tr>
                              <td>
                                   2
                              </td>
                              <td>
                              Have all features listed in Table 3 of the Establishing survey benchmarks for floodplain harvesting meters in NSW (2020) guidelines have been surveyed and located.
                              If not please detail why.

                              </td>
                              <td>
                                   <Field
                                        name="ahdChecklist.q2"
                                        component={SelectForRedux}
                                        values={ahdChecklist.q2}
                                        props={{ disabled: disableFields }}
                                        options={checklistOptions} 
                                   />
                              </td>
                         </tr>
                         <tr>
                              <td>
                                   3
                              </td>
                              <td>
                                Have the survey benchmarks been installed by non-qualified surveyor?
                              </td>
                              <td>
                                   <Field
                                        name="ahdChecklist.q3"
                                        component={SelectForRedux}
                                        values={ahdChecklist.q3}
                                        props={{ disabled: disableFields }}
                                        options={checklistOptions} />
                              </td>
                         </tr>
                         <tr>
                              <td>
                                   4
                              </td>
                              <td>
                              If the answer to 3 above is yes, has a registered surveyor checked all work and calculations to ensure the requirements of the Establishing survey benchmarks for floodplain harvesting meters in NSW guidelines have been met?
                              </td>
                              <td>
                                   <Field
                                        name="ahdChecklist.q4"
                                        component={SelectForRedux}
                                        values={ahdChecklist.q4}
                                        props={{ disabled: disableFields }}
                                        options={checklistOptions} 
                                   />
                              </td>
                         </tr>
                         <tr>
                              <td>
                                   5
                              </td>
                              <td>
                              Has a CSV file of all features listed in Table 3 of the Establishing survey benchmarks for floodplain harvesting meters in NSW guidelines been provided?
                              </td>
                              <td>
                                   <Field
                                        name="ahdChecklist.q5"
                                        component={SelectForRedux}
                                        values={ahdChecklist.q5}
                                        props={{ disabled: disableFields }}
                                        options={checklistOptions} 
                                   />
                              </td>
                         </tr>
                         <tr>
                              <td>
                                   6
                              </td>
                              <td>
                              Has a PDF file of all features listed in Table 3 of the Establishing survey benchmarks for floodplain harvesting meters in NSW guidelines been provided?
                              </td>
                              <td>
                                   <Field
                                        name="ahdChecklist.q6"
                                        component={SelectForRedux}
                                        values={ahdChecklist.q6}
                                        props={{ disabled: disableFields }}
                                        options={checklistOptions} 
                                   />
                              </td>
                         </tr>
                         <tr>
                              <td>
                                   7
                              </td>
                              <td>
                              Has an aerial photograph been included in the PDF file of all features listed in Table 3 of the Establishing survey benchmarks for floodplain harvesting meters in NSW guidelines?
                              </td>
                              <td>
                                   <Field
                                        name="ahdChecklist.q7"
                                        component={SelectForRedux}
                                        values={ahdChecklist.q7}
                                        props={{ disabled: disableFields }}
                                        options={checklistOptions} 
                                   />
                              </td>
                         </tr>
                         <tr>
                              <td>
                                   8
                              </td>
                              <td>
                              Has a copy of the CSV and PDF files been provided to both the landowner and the meter installer (CSMIV)?
                              </td>
                              <td>
                                   <Field
                                        name="ahdChecklist.q8"
                                        component={SelectForRedux}
                                        values={ahdChecklist.q8}
                                        props={{ disabled: disableFields }}
                                        options={checklistOptions} 
                                   />
                              </td>
                         </tr>          
                         
                       
                    </tbody>
               </table>
         
          </form>
     )
}

AHDValidationAndMaintenanceChecklist = reduxForm({
     destroyOnUnmount: false,
     forceUnregisterOnUnmount: true,
     validate
})(AHDValidationAndMaintenanceChecklist)

AHDValidationAndMaintenanceChecklist = connect(
     (state, props) => {
          // can select values individually
          const selector = formValueSelector(props.form)
          const masterDataValues = selector(state, 'mdata')
          const meterEquipInfo = selector(state, 'meteringEquipmentInformation')         
          const ahdChecklist = selector(state, 'ahdChecklist')
          
          const form = props.form
          return {
               masterDataValues,
               meterEquipInfo,
               ahdChecklist,
               form
          }
     }
)(AHDValidationAndMaintenanceChecklist)

export default AHDValidationAndMaintenanceChecklist



