import React from 'react';
import ApprovalConsentProcess from '../../../components/common/ApprovalConsentProcess';
import { UneditableTextInput } from '../../../components/tools/BootstrapControls';
import uiHelpers from '../../../helpers/uiHelpers';
import serviceUrls from '../../../constants/serviceUrls';
import * as apiCaller from '../../../helpers/apiCaller'
import Select from 'react-select';

const periodOptions = [
    //{id:'DQP_60', label:"Request access for 60 days"},
    //{id:'DQP_180', label:"Request access for 180 days"},
    {id:'DQP_365', label:"Request access for one year"}
]

const initState = {
    approval:null,
    consent:null,
    meters : [],
    callingService:false,

    selectedMeter:null,
    selectedPeriod:periodOptions[0],

    confirming:false,

    requestResult:null
}

class RequestAccess  extends React.Component {

    constructor(props) {
        super(props);
        this.state = JSON.parse(JSON.stringify(initState))
    }

    onReset = () => {
        this.setState(JSON.parse(JSON.stringify(initState)))
    }

    approvalFound = async (approval,consent) => {
        this.setState(Object.assign(this.state,{callingService:true, approval,consent}));
        await apiCaller.post(serviceUrls.meter.getMeterByApprovalNumber,{approvalNumber:this.state.approval.approvalNumber})
        .then((result)=>{
            this.setState({meters:result})
        }).finally(()=>{
            this.setState(Object.assign(this.state,{callingService:false}));
        });
    }

    onSelectMeter = (meter) => {
        this.setState({selectedMeter:meter})
    }

    onSelectPeriod = (period) => {
        this.setState({selectedPeriod:period})
    }

    onRequestAccess = () => {
        this.setState({confirming:true});
    }

    onCancelRequest = () => {
        this.setState({confirming:false});
    }

    onConfirmRequestAccess = async () => {
        this.setState(Object.assign(this.state,{callingService:true}));
        uiHelpers.showProgressOnTop("Requesting access...");
        await apiCaller.post(serviceUrls.das.requestWorkspaceAccess,{period:this.state.selectedPeriod.id, meterId : this.state.selectedMeter.id})
        .then((result)=>{
            this.setState({requestResult:result})
        }).finally(()=>{
            this.setState(Object.assign(this.state,{callingService:false}));
            uiHelpers.hideProgressOnTop();
        });
    }


    render() {
        return <>

            <h3 class="mb-3">DAS Workspace Access</h3>
            <ul class="nav nav-pills mb-3">
                <li class="nav-item">
                    <a class="nav-link active" href="#/das-access/request-access">Request Access</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#/das-access/history">Request History</a>
                </li>
            </ul>
            <hr/>

            {!this.state.approval && <ApprovalConsentProcess approvalFound={this.approvalFound}/>}
            {this.state.approval && <>
                <div className='row mb-3'>
                    <div className='col-md-6'>
                        <UneditableTextInput
                            label={<>Approval</>}
                            value={<>
                            {this.state.approval.approvalNumber}
                            <button className='btn btn-sm btn-primary ms-2' type="button" onClick={this.onReset} disabled={this.state.callingService}>Reset</button>
                            </>}
                        />
                    </div>
                </div>
                <div className='row mb-3'>
                    <div className='col-md-6'>
                        <label class="form-label">Workspace</label>
                        <Select
                            placeholder={<i>Please select a workspace (meter)</i>}
                            isDisabled={this.state.callingService || this.state.confirming}
                            getOptionLabel={o=><>{o.meterSiteId.meterSiteId} {!o.meterLid.isLidTelemetry && <i className='fs-80'>(Non-Telemetry)</i>}</>}
                            getOptionValue={o=>o.id}
                            options={this.state.meters}
                            onChange={this.onSelectMeter}
                            value={this.state.meter}
                            isOptionDisabled={(o) => !o.meterLid.isLidTelemetry}
                        />
                    </div>
                </div>
                <div className='row mb-3'>
                    <div className='col-md-6'>
                        <label class="form-label">Period for Access (from today)</label>
                        <Select
                            placeholder={<i>Please select a period</i>}
                            isDisabled={this.state.callingService || this.state.confirming}
                            getOptionLabel={o=>o.label}
                            getOptionValue={o=>o.id}
                            options={periodOptions}
                            onChange={this.onSelectPeriod}
                            value={this.state.selectedPeriod}
                        />
                    </div>
                </div>
                {this.state.requestResult == null && !this.state.confirming && <div className='row mb-3'>
                    <div className='col-md-6 text-end'>
                        <button className='btn btn-primary' type='button' disabled={!(this.state.selectedMeter && this.state.selectedPeriod) || this.state.callingService} onClick={this.onRequestAccess}>Request Access</button>
                    </div>
                </div>}
                {this.state.requestResult == null && this.state.confirming && <div className='row mb-3'>
                    <div className='col-md-6 '>
                        <div className='alert alert-primary text-center'>
                            <p>Please confirm that you would like to request access for the above workspace</p>
                            <button className='btn btn-secondary' disabled={this.state.callingService } type="button" onClick={this.onCancelRequest}>Cancel</button>
                            <button className='btn btn-primary ms-2' disabled={this.state.callingService } type="button" onClick={this.onConfirmRequestAccess}>Confirm</button>
                        </div>
                    </div>
                </div>}
                {this.state.requestResult && <>
                    {this.state.requestResult.succeeded ? <>
                        <div className='col-md-6'>
                            <div className='alert alert-success'>
                                Your request for access to DAS has been successful. 
                            </div>
                        </div>
                    </> : <>
                        <div className='col-md-6'>
                            <div className='alert alert-danger'>
                                Sorry, your request to access in DAS has failed, please contact system administrator to find out the reason. Alternatively, you could try again using the "Reset" button above.
                            </div>
                        </div>
                    </>}
                </>}
            </>}
        </>
    }
}

export default RequestAccess;