import React from 'react';
import * as apiPathes from '../../apiPathes';
import * as serviceCaller from '../../serviceCaller'
import {convertErrorCode} from '../../conveters'
import {isStringNullOrEmpty} from '../../../helpers/validators'

class ReasonForNotLodgingForm  extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            reason: props.nominatedMeasurementPeriod.reasonForNotLodging,
            saving: false,
            reasonError: null
        };
    }
    
    componentDidMount() {
          
    }

    saveReasonNotLodging = async ()=>{
        const {nominatedMeasurementPeriod} = this.props;
        const {reason} = this.state;
        if(isStringNullOrEmpty(reason)) {
            this.setState({reasonError: 'This is mandatory'})
            return;
        }

        await serviceCaller.post(apiPathes.saveReasonForNotLodging,
            {
                nominatedMeasurementPeriodId: nominatedMeasurementPeriod.id,
                reasonForNotLodging: reason
            }, 
            {
                noIWasLoginHandler: this.props.noLoginCallback,
                loadingMsg: 'Saving your reason....',
                beforeSend: ()=>{
                    this.setState({saving: true})
                },
                errorCodeHandler: (errorCode) =>{
                    this.setState({reasonError:convertErrorCode(errorCode) })
                },
                success: (data)=>{
                    this.props.updatePeriod(data, false,false,()=>{
                        this.props.toggleForm();
                    });
                }
        });

    }

    render(){
        const {reason, saving, reasonError} = this.state;
        return <>
            <div className="row mb-3">
                <div class="col-md-8">
                    <div className='card card-body border-primary border-2'>
                        <div className='alert alert-warning mb-2'>
                            The end date of this measurement period is not within 24h of the current date/time. This will be logged for record with the Natural Resources Access Regulator (NRAR)
                        </div>
                        <label  class="form-label"><strong>Provide a reason for not lodging within 24 hours</strong></label>
                        <textarea class={`form-control mb-3 ${reasonError?'border-danger':''}`} disabled={saving} rows="3" value={reason} onChange={(e)=>this.setState({reason: e.target.value})} />
                        {reasonError ? <div className='text-danger'>{reasonError}</div> : null}
                        <div className='text-end'>
                            <button className='btn btn-primary me-3' disabled={saving} onClick={this.saveReasonNotLodging}>Save</button>  
                            <button className='btn btn-outline-secondary' disabled={saving} onClick={this.props.toggleForm}>Cancel</button> 
                        </div>
                    </div>
                </div>
            </div>
        </>;
    }

}

export default ReasonForNotLodgingForm;