import React from 'react';
import moment from 'moment';
import * as apiPathes from '../apiPathes';
import * as serviceCaller from '../serviceCaller'
import DatePicker from 'react-datepicker';
import {ArrowLeft} from '../../components/tools/Icons'
import uiHelpers from '../../helpers/uiHelpers'
import {periodStatus, pageInitialising} from '../constants'
import {convertAuditAction, convertDateTimeIsoToAusFormat, nameOfCustomer} from '../conveters'
import {UneditableTextInput} from '../../components/tools/BootstrapControls'


class Audit  extends React.Component {

    constructor(props){
        super(props);
        this.state = {
        };
    }

    render(){
        const {audits} = this.props;
        const period = this.props.nominatedMeasurementPeriod;
        return <>
                <div className='row'>
                    <div className='col-md-3 mb-3'>
                        <UneditableTextInput fieldExtraClass="bg-light"  label="Created at:"  value={convertDateTimeIsoToAusFormat(period.createdDate)} />
                    </div>
                    <div className='col-md-3 mb-3'>
                        <UneditableTextInput fieldExtraClass="bg-light" label="Created by:"  value={nameOfCustomer(period.createdByCustomer)} />
                    </div>
                    {period.modifiedDate && <>
                        <div className='col-md-3 mb-3'>
                            <UneditableTextInput fieldExtraClass="bg-light" label="Last modifed at:"  value={convertDateTimeIsoToAusFormat(period.modifiedDate)} />
                        </div>
                        <div className='col-md-3 mb-3'>
                            <UneditableTextInput  fieldExtraClass="bg-light" label="Last modifed by:"  value={nameOfCustomer(period.modifiedByCustomer)} />
                        </div>
                    </>}
                </div>
                <div className='row'>
                    {period.closedDate && <>
                        <div className='col-md-3 mb-3'>
                            <UneditableTextInput  fieldExtraClass="bg-light" label="Closed at:"  value={convertDateTimeIsoToAusFormat(period.closedDate)} />
                        </div>
                        <div className='col-md-3 mb-3'>
                            <UneditableTextInput fieldExtraClass="bg-light"  label="Closed by:"  value={nameOfCustomer(period.closedByCustomer)} />
                        </div>
                    </>}
                    {period.finalisedDate && <>
                        <div className='col-md-3 mb-3'>
                            <UneditableTextInput fieldExtraClass="bg-light" label="Finanlised at:"  value={convertDateTimeIsoToAusFormat(period.finalisedDate)} />
                        </div>
                        <div className='col-md-3 mb-3'>
                            <UneditableTextInput fieldExtraClass="bg-light" label="Finanlised by:"  value={nameOfCustomer(period.finalisedByCustomer)} />
                        </div>
                    </>}
                </div>
            <table class="table table-striped fs-90">
                <thead>
                    <tr>
                        <th style={{width: "200px"}}>Date/Time</th>
                        <th style={{width: "150px"}}>Action</th>
                        <th style={{width: "200px"}}>User</th>
                        <th>Description</th>
                    </tr>
                </thead>
                <tbody>
                    {audits.map(item=><tr>
                        <td>{convertDateTimeIsoToAusFormat(item.auditDate)}</td>
                        <td>{convertAuditAction(item.auditAction)}</td>
                        <td>{nameOfCustomer(item.auditCustomerCustomer)}</td>
                        <td>{item.description}</td>
                    </tr>)}
                </tbody>
            </table>
        </>;
    }

}

export default Audit;