import React from 'react';
import { UneditableTextInput } from '../../../../components/tools/BootstrapControls';
import moment from 'moment';
import serviceUrls from '../../../../constants/serviceUrls';
import * as apiCaller from '../../../../helpers/apiCaller'
import uiHelpers from '../../../../helpers/uiHelpers';
import { convertConsentAuditAction } from '../../../../helpers/converters';

export default class MeterCommissionConsent  extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading:false,
            audits:null,
            selectedHolder:null
        };
    }

    async componentDidMount() {
        const consent = this.props.consent;
        uiHelpers.showProgressOnTop('Getting some customer authorisation data...');
        this.setState({loading:true})
        await apiCaller.post(serviceUrls.consent.getById,{id:consent.id})
        .then((data)=>{
            this.setState({
                loading:false,
                audits:data.consent.audits,
                selectedHolder:data.consent.selectedHolder
            })
        }).finally(()=>{
            uiHelpers.hideProgressOnTop();
        });
    }

    renderHolderPhone = (holder) => {
        if(!holder) return null;
        if(!holder.phoneNumbers) return null;
        if(holder.phoneNumbers==0) return;

        let firstPhone = holder.phoneNumbers[0];
        let mobile;
        let phone;
        let business;
        let phoneToDisplay = firstPhone;
        for(var i=0; i<holder.phoneNumbers.length;i++){
            if(holder.phoneNumbers[i].serviceType=='MOBILE'){
                mobile=holder.phoneNumbers[i];
            } else if(holder.phoneNumbers[i].serviceType=='PHONE'){
                phone=holder.phoneNumbers[i];
            } else if(holder.phoneNumbers[i].serviceType=='BUSINESS'){
                business=holder.phoneNumbers[i];
            }
        }

        if(holder.inpaType =='Person') {

            if(mobile) phoneToDisplay= mobile;
            else if(phone) phoneToDisplay= phone;
        } else {
            if(business) phoneToDisplay= business;
            else if(phone) phoneToDisplay= phone;
        }

        return <>
            <div className='row '>
                <div className='col-md-6 mb-3'>
                    <UneditableTextInput
                        label="Phone Number"
                        value={<>{phoneToDisplay.prefix} {phoneToDisplay.phoneNumber}</>}
                    />
                </div>
            </div>
        </>
    }

    render() {
        const consent = this.props.consent;
        return <>

            <div className='row'>
                <div className='col-md-3 mb-3'>
                    <UneditableTextInput
                        label={<>Authorisation Request Type</>}
                        value={consent.approvalType=='PDF'?"PDF Signature":"Email"}
                    />
                </div>
                <div className='col-md-3 mb-3'>
                    <UneditableTextInput
                        label={<>Authorisation Requested on</>}
                        value={moment(consent.requestedDate).format('DD/MM/YYYY h:mma')}
                    />
                </div>
            </div>
            <div className='row'>
                <div className='col-md-3 mb-3'>
                    <UneditableTextInput
                        label={<>Authorisation Status</>}
                        value={
                            consent.status =='PENDING_APPROVAL' ? 
                            <span className='text-warning'>Pending approval</span>
                            :
                            consent.status =='REJECTED' ? 
                            <span className='text-danger'>Consent not Authorised</span>
                            :
                            consent.status =='APPROVED' ? 
                            <span className='text-success'>Approved</span>
                            :
                            consent.status =='EXPIRED' ? 
                            <span className='text-danger'>Expired</span>
                            :
                            null
                        }
                    />
                </div>
                <div className='col-md-3 mb-3'>
                    <UneditableTextInput
                        label={<>For Government-Owned</>}
                        value={consent.governmentOwn===true?"Yes":consent.governmentOwn===false?"No":""}
                    />
                </div>
            </div>

            <div className='row'>
                <div className='col-md-3 mb-3'>
                    <UneditableTextInput
                        label={<>Approved on</>}
                        value={moment(consent.approvedDate).format('DD/MM/YYYY h:mma')}
                    />
                </div>
                <div className='col-md-3 mb-3'>
                    <UneditableTextInput
                        label={<>Expiring on</>}
                        value={moment(consent.expiryDate).format('DD/MM/YYYY h:mma')}
                    />
                </div>
            </div>
            
            {!this.state.loading && <>

                
            
                {this.state.selectedHolder ? <>
                    <div className='row '>
                        <div className='col-md-6 mb-3'>
                            <UneditableTextInput
                                label={<>Request sent to holder</>}
                                value={this.state.selectedHolder ? this.state.selectedHolder.name:null}
                            />
                        </div>
                    </div>
                    <div className='row '>
                        <div className='col-md-6 mb-3'>
                            <UneditableTextInput
                                label="Holder Type"
                                value={this.state.selectedHolder ? this.state.selectedHolder.inpaType:null}
                            />
                        </div>
                    </div>
                    {this.renderHolderPhone(this.state.selectedHolder)}

                </> : <>
                
                </>}
            </>}

            {consent.approvalType=='EMAIL' && <div className='row '>
                <div className='col-md-6 mb-3'>
                    <UneditableTextInput
                        label="Request sent to email"
                        value={consent.requestedEmail}
                    />
                </div>
            </div>}

            {this.state.audits && this.state.audits.length > 0 && <>
            <h4 className='mt-4'>Audits</h4>
            <hr/>
                <table className='table'>
                    <thead>
                        <tr>
                            <th>
                                Date
                            </th>
                            <th>
                                Action
                            </th>
                            <th>
                                User
                            </th>
                            <th>
                                Request sent to
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.audits.map((a)=>{
                            return <tr>
                                <td>
                                {moment(a.auditDate).format('DD/MM/YYYY h:mma')}
                                </td>
                                <td>
                                    {convertConsentAuditAction(a.auditAction)}
                                </td>
                                <td>
                                    {a.firstName} {a.lastName}
                                </td>
                                <td>
                                    {a.sentToEmail}
                                </td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </>}
        </>
    }
}