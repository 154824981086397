import React, {useState} from 'react';
import { Field, reduxForm, formValueSelector, change } from 'redux-form';
import {  MESSAGE_UPLOAD_LIMITS , uploadOptionsForAHDValidation as uploadOptions } from '../../../constants/MeterFormConstants'

import { connect } from 'react-redux'

import { updateDocuments, updateDeleteDocuments, changeFormStatus, uploadFiles } from '../design/MeterDesignCertificate.Reducers';
import { saveDraftAction, saveSubmitAction, scrollTofocusError } from "./AHDFormValidations"
import 'redux';
import "react-datepicker/dist/react-datepicker.css";
import Authentication from '../../../helpers/Authentication';
import FileUploader from '../../common/FileUploader'
import { SelectForRedux}  from '../../tools/BootstrapControls'

let fileUploaderDisplayListUpdate;


let _validateFiles;

const validate = (values, props) => {
   let clickSaveDraft = (props.clickSaveDraft== undefined? values.clickSaveDraft:props.clickSaveDraft);
   let clickNext = props.clickNext== undefined ? values.clickNext : props.clickNext;

   if (clickSaveDraft == 0 && clickNext === 1 && values.ahdData.formStatus != 2) {
      //alert(_validateFiles)
      if(_validateFiles) {
         if(!_validateFiles()){
            return {meteringEquipmentInformation : {fileType:"Please upload all manadatory supporting documents"}};
         }
      }
      
   }

   return {};
}

let AHDValidationCertificatePartE = props => {

   const authentication = new Authentication();
   let dqpUserName = "";
   let dqpUserEmail = "";
   var user = authentication.currentUser();
   if (authentication.isLoggedIn) {
      dqpUserName = user.firstName + ' ' + user.lastName;
      dqpUserEmail = user.emails;
   }  

   let {
      previousPage, ahdData, handleSubmit, meterEquipInfo,  documents,  loading, hasError
   } = props

   //--------------------------------------
   const [renderCnt, setRenderCnt] = useState(0);

   const updateDisplayFileList = ()=>{

      if(!fileUploaderDisplayListUpdate) return;
      const newList = [];

      if(documents && documents.length!=0) {

         var option;
         for(var i = 0; i< documents.length; i++){
            if(documents[i].status=='new' || documents[i].status=='existing'){
               var option = uploadOptions.filter(option=>option.id==documents[i].documentType)[0];
               newList.push({
                  id: documents[i].id,
                  fileTypeDesc : option?option.name:"",
                  description: documents[i].description,
                  fileOriginalName: documents[i].fileOriginalName,
                  fileGUID: documents[i].fileGUID
               });
            }
         }

      }
      fileUploaderDisplayListUpdate(newList);
      setRenderCnt(renderCnt+1)
   }

   const fileUploaderFilesUploaded=(files)=>{
      
      var fileType = props.meterEquipInfo.fileType;
      var docs = props.documents;

      for(var i = 0; i< files.length; i++){

         docs.push({
            id : files[i].id,
            documentType : fileType,
            description : files[i].description,
            fileOriginalName : files[i].fileOriginalName,
            fileGUID : files[i].fileGUID,
            status : "new"
         });
      }
      props.dispatch(change(props.form, 'documents', docs))
      updateDisplayFileList();
   }

   
   const fileUploaderFileDeleted=(file)=>{
      if(!props.documents) return;

      for(var i = 0; i< props.documents.length; i++){
         if(props.documents[i].id==file.id){
            if (props.documents[i].status == "new")
               props.documents[i].status = "newDeleted";
            else if (props.documents[i].status == "existing")
               props.documents[i].status = "existingDeleted";
         }
      }

      updateDisplayFileList();
   }
   //--------------------------------------
   
   const validateFiles =()=>{
      var validated=[];
      var options=[];
      console.log("documents array has " + JSON.stringify(documents));
      debugger;
      for (var k=0;k<=uploadOptions.length-1;k++)
      {
           if (uploadOptions[k].name!="Other")
           {
              options.push(uploadOptions[k]);
           }
      }
   
      if (props.documents.length>0)
      {
         for (var i=0;i<=props.documents.length-1;i++)
         {
            for (var j=0; j<=options.length-1;j++)
            {
               if (parseInt(props.documents[i].documentType)==parseInt(options[j].id))
               {
                   validated.push(options[j].id);
               }
            }
         }
      }
   
      return validated.length>=options.length;
   }

   _validateFiles = validateFiles;

   const disableFields = (parseInt(ahdData.formStatus) == 2)  || props.disableEditing || loading? true : false;



   
   const dateToday = new Date();
   if ((!props.meterEquipInfo.fileType || props.meterEquipInfo.fileType == 0) && uploadOptions.length > 0) {
      props.dispatch(change(props.form, 'meteringEquipmentInformation.fileType', "3"))
   }

   if (meterEquipInfo.dateOfLastValidation != null && meterEquipInfo.dateOfLastValidation != "")
      meterEquipInfo.dateOfLastValidation = new Date(meterEquipInfo.dateOfLastValidation);
   else {
      meterEquipInfo.dateOfLastValidation = dateToday;
      meterEquipInfo.dateOfLastValidation = new Date(meterEquipInfo.dateOfLastValidation);
   }

   if (meterEquipInfo.meterReadingDate != null && meterEquipInfo.meterReadingDate != "")
      meterEquipInfo.meterReadingDate = new Date(meterEquipInfo.meterReadingDate);
   if (meterEquipInfo.finalReadingDateRemovedMeter != null && meterEquipInfo.finalReadingDateRemovedMeter != "")
      meterEquipInfo.finalReadingDateRemovedMeter = new Date(meterEquipInfo.finalReadingDateRemovedMeter);

   const allowFileTypes = {
      "3" : ["application/vnd.ms-excel","text/csv"],
      "4" : ["application/pdf"],
      "5": ["application/pdf","image/gif","image/jpe","image/jpg", "image/jpeg","application/msword","application/vnd.openxmlformats","officedocument.wordprocessingml.document","bmp","image/png"],
   }[props.meterEquipInfo.fileType];
   const fileTypeErrorMsg = {
      "3" : "Only CSV file is allowed ",
      "4" : "Only PDF file is allowed",
      "5":  "Your file type is invalid, only the following file types are allowed (pdf,gif,jpeg,jpg,doc,docx,png)",
   }[props.meterEquipInfo.fileType];
   

   return (
      <form onSubmit={handleSubmit}>            
         <h5 className='mb-3'>Supporting documentation</h5>

         <ul>
            <li>
            Attach CSV file of all features detailed in Table 2 of the Establishing survey benchmarks for floodplain harvesting meters in NSW guidelines.
            </li>
            <li>
            Attach PDF file of all features detailed in Table 3 of the Establishing survey benchmarks for floodplain harvesting meters in NSW guidelines.
            </li>
            <li>
            Attach photographs (minimum of 6.0 megapixel resolution) of the survey benchmarks
            </li>
         </ul>
         

         <p className='fw-bold'>The following supporting information is mandatory and must be loaded into the DQP portal: </p>

         <div className='my-3 ps-2'>
            {uploadOptions.map((option) => {
               return <div class="form-check">
                  <input class="form-check-input" type="checkbox" value="" id={"checklist_" + option.id} checked={props.documents.filter(doc=>( (doc.status=='new' || doc.status=='existing' ) && doc.documentType == option.id)).length > 0} />
                  <label class="form-check-label" for={"checklist_" + option.value}>
                     {option.name}
                  </label>
               </div>
            })}
         </div>

         <div className='row mb-3'>
            <div className='col-sm-7'>
               <Field
                  name="meteringEquipmentInformation.fileType"
                  component={SelectForRedux}
                  props={{ disabled: disableFields }}
                  value="meteringEquipmentInformation.fileType"
                  options={uploadOptions} 
                  />
            </div>
         </div>

         <FileUploader
            fileInputLabel={<strong>Upload your supporting documents here:</strong>}
            fileTableHeader={<p><strong>Your uploaded files: </strong></p>}
            warning={MESSAGE_UPLOAD_LIMITS}
            maxFileSizeMB={10}
            exceedFileSizeErrorMsg = "Your file is too big"
            disabled={disableFields}
            provideDisplayDocumentsUpdator={(updateFunction)=>{fileUploaderDisplayListUpdate = updateFunction;}}

            fileTableHeadComponent={<tr>
               <th style={{width:"80px"}}></th>
               <th style={{width:"280px"}}>Type</th>
               <th>File name</th>
               <th>Description</th>
            </tr>}

            fileTableRowCompponent={(file, index, deleteButton)=>{
               return <tr key={index}>
                  <td>
                     {deleteButton}
                  </td>
                  <td>
                     {file.fileTypeDesc}
                  </td>
                  <td>
                     <a target='_blank' href={process.env.REACT_APP_WEBAPI_URL + process.env.REACT_APP_FIELS_GET + "/" + file.id}>
                        {file.fileOriginalName}
                     </a>
                  </td>
                  <td>{file.description}</td>
            </tr>
            }}

            onFilesUploaded={fileUploaderFilesUploaded}
            askForDocumentUpdate={updateDisplayFileList}
            onFileDeleted={fileUploaderFileDeleted}

            allowFileTypes={allowFileTypes}
            fileTypeErrorMsg={fileTypeErrorMsg}
         />

         {hasError && <div className='alert alert-danger'>
            Please fix all errors before you continue
         </div>}

         <div className="text-end">
            <button type="button" class="btn btn-primary float-start" data-bs-toggle="modal" data-bs-target="#checkListModal">
               View Checklist
            </button>

            {(parseInt(ahdData.formStatus) == 1) && (!props.disableEditing) && <>
               <button type="button"  className="btn btn-danger me-3" disabled={loading} 
               data-toggle="modal" data-target="#deleteDraftModal">
                  Delete Draft
               </button>
            
               <button type="button"  className="btn btn-primary" disabled={loading}
                  onClick={() => { saveDraftAction(props); }}>
                  Save Draft
               </button>
               <div className='vr mx-3'></div>
            </>}
            <button type="button"  className="btn btn-warning me-3" disabled={loading} onClick={()=>{previousPage()}}>
               Previous
            </button>
            {(parseInt(ahdData.formStatus) == 1) && (!props.disableEditing) && 
            <button type="button" className="btn btn-success" disabled={loading}
                  onClick={() => 
                     {
                        saveSubmitAction(props); 
                     }
                  }>
                  Submit
            </button>
            }

         </div>
      </form>
   )
}



AHDValidationCertificatePartE = reduxForm({
   destroyOnUnmount: false,
   forceUnregisterOnUnmount: true,
   validate,
   onSubmitFail: (errors, dispatch ) => {
      dispatch(change('certificate', 'hasError', true));
   },
   onSubmitSuccess:(result , dispatch )=>{
      dispatch(change('certificate', 'hasError', false));
      window.scrollTo(0, 0);
   }
})(

AHDValidationCertificatePartE)




AHDValidationCertificatePartE = connect(
   (state, props) => {
      const selectorCert = formValueSelector('certificate')
      const hasError = selectorCert(state, 'hasError')

      // can select values individually
      
      const selector = formValueSelector(props.form)
      const loading = selector(state, 'loading')
      const userProfileValues = selector(state, 'mdata.userProfile')
      const masterDataValues = selector(state, 'mdata')
      const meterEquipInfo = selector(state, 'meteringEquipmentInformation')
      const registrationDoc = selector(state, 'registrationDoc')
      const documents = selector(state, 'documents')
      const ahdData = selector(state, 'ahdData')
      const form = props.form
      return {
         userProfileValues,
         ahdData,
         meterEquipInfo,
         documents,
         registrationDoc,
         masterDataValues, 
         form,
         loading,
         hasError
      }
   }, {
      uploadFiles: (formData) => uploadFiles(formData),
      updateDeleteDocuments: (doc) => updateDeleteDocuments(doc),
      changeFormStatus: (payload, val) => changeFormStatus(payload, val),
      updateDocuments: (payload) => updateDocuments(payload)
   }
)(AHDValidationCertificatePartE)

export default AHDValidationCertificatePartE