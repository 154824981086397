import React, {useState, useEffect } from 'react';
import { reduxForm, Field, formValueSelector, change } from 'redux-form';
import { connect } from 'react-redux';
import 'redux';
import "react-datepicker/dist/react-datepicker.css";
import { updateDocuments, updateDeleteDocuments, changeFormStatus, uploadFiles } from '../design/MeterDesignCertificate.Reducers';
import Authentication from '../../../helpers/Authentication';
import { validate } from './TransitionalReportingPartFValidate.Validation';
import { MESSAGE_UPLOAD_LIMITS , patternApprovedOptionsForTransitionalReporting as patternApprovedOptions, patternNotApprovedOptionsForTransactionalReporting as  patternNotApprovedOptions} from '../../../constants/MeterFormConstants'
import { saveDraftAction, saveSubmitAction, scrollTofocusError } from "../common/MeterFormValidations"
import { RadiosForRedux, InputText , DatePickerForRedux, SelectForRedux, CheckboxForRedux}  from '../../tools/BootstrapControls'
import FileUploader from '../../common/FileUploader'

let fileUploaderDisplayListUpdate;

let _validateFiles;

const validateAll = (values, props) => {
   const result = validate(values, props);

   let clickSaveDraft = (props.clickSaveDraft== undefined? values.clickSaveDraft:props.clickSaveDraft);
   let clickNext = props.clickNext== undefined ? values.clickNext : props.clickNext;

   if (clickSaveDraft == 0 && clickNext === 1 && values.meteringEquipmentInformation.formStatus != 2) {
      //alert(_validateFiles)
      if(_validateFiles) {
         if(!_validateFiles()){
            result.meteringEquipmentInformation.fileType = 'Please upload all manadatory supporting documents';
            return result;
         }
      }
   }
   return result;
}

let TransitionalReportingPartF = props => {


   const [renderCnt, setRenderCnt] = useState(0);

   const authentication = new Authentication();
   let dqpUserName = "";
   let dqpUserEmail = "";
   var user = authentication.currentUser();
   if (authentication.isLoggedIn) {
      dqpUserName = user.firstName + ' ' + user.lastName;
      dqpUserEmail = user.emails;
   }

   const meterPatternApprovedOptions =
      [
         { id: "1", name: "Yes", selected: true },
         { id: "2", name: "No", selected: false }
      ];

      const declarationOptions =
      [
         { id: "1", name: "a) all information contained in this certificate is accurate, true and complete.", selected: false }
      ];
      

   const validateFiles =()=>{
      var count = 0;
      for (var k=0;k<=props.documents.length-1;k++)
      {
           if(props.documents[k].status == "new" || props.documents[k].status == "existing")
           {
               count++;
           }
      }
      return count > 0 ? true : false;
   }
   _validateFiles = validateFiles;

   let {
      previousPage, handleSubmit, meterEquipInfo, userProfileValues, documents, registrationDoc, loading, hasError
   } = props

   const disableFields = (parseInt(meterEquipInfo.formStatus) == 2) || props.disableEditing  || loading? true : false;

   let dqpPhoneNumber = "";
   let dqpAddress = "";
   let dqpCmiNo = "";
   let dqpQualificationType = "";
   let dqpBusinessName = "";
   let dqpAbnAcn = "";

   if (userProfileValues != undefined && userProfileValues != null && userProfileValues.length > 0) {
      dqpPhoneNumber = userProfileValues[0].phoneNumber;
      dqpAddress = userProfileValues[0].postalAddress.addressLine;
      dqpCmiNo = userProfileValues[0].cmino;
      dqpQualificationType = userProfileValues[0].dqpQualificationType;
      dqpBusinessName = userProfileValues[0].businessName;
      dqpAbnAcn = userProfileValues[0].abnOrAcn;
   }

   const dateCreated = new Date()
   if (meterEquipInfo.date != null && meterEquipInfo.date == "")
      meterEquipInfo.date = dateCreated;

   meterEquipInfo.date = new Date(meterEquipInfo.date)

   if (meterEquipInfo.dateValidation != null && meterEquipInfo.dateValidation == "")
      meterEquipInfo.dateValidation = dateCreated;

   meterEquipInfo.dateValidation = new Date(meterEquipInfo.dateValidation)


   const [initilised, setInitilised] = useState(false);
   useEffect(() => {
      if(initilised) return;
      if(meterEquipInfo.meterPatternApproved) {
         meterEquipInfo.fileType = ((meterEquipInfo.meterPatternApproved == 1) ? patternApprovedOptions : patternNotApprovedOptions)[0].id
      } 
      setInitilised(true);
   });

   const meterPatternApprovedChange = (e, option) => {

      props.dispatch(change(props.form, 'meteringEquipmentInformation.fileType', ((option.id == "1") ? patternApprovedOptions : patternNotApprovedOptions)[0].id))
      
      if(!props.documents) return;

      for(var i = 0; i< props.documents.length; i++){
         if (props.documents[i].status == "new")
            props.documents[i].status = "newDeleted";
         else if (props.documents[i].status == "existing")
            props.documents[i].status = "existingDeleted";   
      }
      updateDisplayFileList();
   }

   const updateDisplayFileList = ()=>{


      if(!fileUploaderDisplayListUpdate) return;
      const newList = [];

      if(documents && documents.length!=0) {

         var option;
         for(var i = 0; i< documents.length; i++){
            if(documents[i].status=='new' || documents[i].status=='existing'){
               var uploadOptions = meterEquipInfo.meterPatternApproved == 1 ? patternApprovedOptions : patternNotApprovedOptions;  

               var option = uploadOptions.filter(option=>option.id==documents[i].documentType)[0];
               newList.push({
                  id: documents[i].id,
                  fileTypeDesc : option?option.name:"",
                  description: documents[i].description,
                  fileOriginalName: documents[i].fileOriginalName,
                  fileGUID: documents[i].fileGUID
               });
            }
         }

      }
      fileUploaderDisplayListUpdate(newList);
      setRenderCnt(renderCnt+1)
   }

   const fileUploaderFilesUploaded=(files)=>{
      
      var fileType = props.meterEquipInfo.fileType;
      var docs = props.documents;

      for(var i = 0; i< files.length; i++){

         docs.push({
            id : files[i].id,
            documentType : fileType,
            description : files[i].description,
            fileOriginalName : files[i].fileOriginalName,
            fileGUID : files[i].fileGUID,
            status : "new"
         });
      }
      props.dispatch(change(props.form, 'documents', docs))
      updateDisplayFileList();
   }

   
   const fileUploaderFileDeleted=(file)=>{
      if(!props.documents) return;

      for(var i = 0; i< props.documents.length; i++){
         if(props.documents[i].id==file.id){
            if (props.documents[i].status == "new")
               props.documents[i].status = "newDeleted";
            else if (props.documents[i].status == "existing")
               props.documents[i].status = "existingDeleted";
         }
      }

      updateDisplayFileList();
   }


   return (
      <form className="vertical" onSubmit={handleSubmit}>
         <h5 className='mb-3'>Declaration</h5>

         <div className='card mb-3'>
            <div className='card-header fw-bold'>I declare:</div>
            <div className='card-body'>
               <div className='mb-3'>
                  <Field
                     name="meteringEquipmentInformation.transitionalDeclaration"
                     component={CheckboxForRedux}
                     options={declarationOptions}
                     props={{ disabled: disableFields }}
                  />
               </div>
               <div className='row mb-3'>
                  <div className='col-sm-6'>
                     <InputText
                        label="Name"
                        id="dqpUserName"
                        value={dqpUserName}
                        disabled={true}
                     />
                  </div>
               </div>
               <div className='row mb-3'>
                  <div className='col-sm-6'>
                     <InputText
                        label="Address"
                        id="dqpAddress"
                        value={dqpAddress}
                        disabled={true}
                     />
                  </div>
               </div>
               <div className='row mb-3'>
                  <div className='col-sm-6'>
                     <InputText
                        label="Phone"
                        id="dqpPhoneNumber"
                        value={dqpPhoneNumber}
                        disabled={true}
                     />
                  </div>
               </div>
               <div className='row mb-3'>
                  <div className='col-sm-6'>
                     <InputText
                        label="Email"
                        id="dqpUserEmail"
                        value={dqpUserEmail}
                        disabled={true}
                     />
                  </div>
               </div>
               <div className='row mb-3'>
                  <div className='col-sm-3'>
                     <Field
                        name="meteringEquipmentInformation.date"
                        label={<>Date <span className='text-danger'>*</span></>}
                        component={DatePickerForRedux}
                        values={meterEquipInfo.date}
                        props={{ disabled: disableFields, maxDate: new Date() }}
                        handleChange={date => { props.change(props.form, 'meteringEquipmentInformation.date', date) }}
                     />
                  </div>
               </div>
               <p>
                  <i>
                  It is an offence under section 91J of the Water Management Act 2000 for a person to make a statement or furnish information in connection with a metering record the person knows to be false or misleading.
                  </i>
               </p>
            </div>
         </div>

         <div className='card mb-3'>
            <div className='card-header fw-bold'>Supporting Documentation Checklist</div>
            <div className='card-body'>
               <div className='mb-3'>
                  <Field
                     name={"meteringEquipmentInformation.meterPatternApproved"}
                     component={RadiosForRedux}
                     options={meterPatternApprovedOptions}
                     props={{ disabled: disableFields }}
                     values={meterEquipInfo.meterPatternApproved}
                     handleChange={meterPatternApprovedChange}
                     label={<>Is your metering equipment pattern approved? <span className='text-danger'>* </span></>}
                  />
               </div>

               {(meterEquipInfo.meterPatternApproved==1 || meterEquipInfo.meterPatternApproved==2) && <>

                  <p>
                     <strong>Uploaded checklist</strong>
                  </p>
                  <div className='mb-3 ps-2'>
                     
                     {(meterEquipInfo.meterPatternApproved == 1 ? patternApprovedOptions : patternNotApprovedOptions).map((option) => {
                        if(option.name=='Other') return null;
                        return <div class="form-check">
                           <input class="form-check-input" type="checkbox" value="" id={"checklist_" + option.id} checked={props.documents.filter(doc=>( (doc.status=='new' || doc.status=='existing' ) && doc.documentType == option.id)).length > 0} />
                           <label class="form-check-label" for={"checklist_" + option.value}>
                              {option.name}
                           </label>
                        </div>
                     })}
                  </div>
                  
                  <div className='row mb-3 mt-4'>
                     <div className='col-sm-10'>
                        <Field
                           name="meteringEquipmentInformation.fileType"
                           component={SelectForRedux}
                           props={{ disabled: disableFields }}
                           options={meterEquipInfo.meterPatternApproved == 1 ? patternApprovedOptions : patternNotApprovedOptions} 
                           label={
                              meterEquipInfo.meterPatternApproved == 1 ?
                              <>Attach a copy of the existing validation provided by a duly qualified person <span className='text-danger'>* </span></>
                              :
                              <>Attach a copy for the selected description <span className='text-danger'>* </span></>
                           }
                        />

                     </div>
                  </div>

                  <FileUploader
                     fileInputLabel={<strong>Upload your supporting documents here:</strong>}
                     fileTableHeader={<p><strong>Your uploaded files: </strong></p>}
                     warning={MESSAGE_UPLOAD_LIMITS}
                     maxFileSizeMB={10}
                     exceedFileSizeErrorMsg = "Your file is too big"
                     disabled={disableFields}
                     provideDisplayDocumentsUpdator={(updateFunction)=>{fileUploaderDisplayListUpdate = updateFunction;}}

                     fileTableHeadComponent={<tr>
                        <th style={{width:"80px"}}></th>
                        <th style={{width:"280px"}}>Type</th>
                        <th>File name</th>
                        <th>Description</th>
                     </tr>}

                     fileTableRowCompponent={(file, index, deleteButton)=>{
                        return <tr key={index}>
                           <td>
                              {deleteButton}
                           </td>
                           <td>
                              {file.fileTypeDesc}
                           </td>
                           <td>
                              <a target='_blank' href={process.env.REACT_APP_WEBAPI_URL + process.env.REACT_APP_FIELS_GET + "/" + file.id}>
                                 {file.fileOriginalName}
                              </a>
                           </td>
                           <td>{file.description}</td>
                     </tr>
                     }}

                     onFilesUploaded={fileUploaderFilesUploaded}
                     askForDocumentUpdate={updateDisplayFileList}
                     onFileDeleted={fileUploaderFileDeleted}
                  />
               </>}

               { registrationDoc.length > 0  && <>
                  <hr/>
                  <table className='table table-sm fs-90'>
                     {registrationDoc.map((option, index) => (
                        <tr>
                           <td>{option.description}</td>
                           <td>{option.fileOriginalName}</td>
                           <td></td>
                        </tr>
                     ))}
                  </table>
               </>}
            </div>
         </div>

         {hasError && <div className='alert alert-danger'>
            Please fix all errors before you continue
         </div>}
         

         <div class="text-end">
            {(parseInt(meterEquipInfo.formStatus) == 1) && (!props.disableEditing) && <>
               {meterEquipInfo.id!=0 &&
                <button type="button"  className="btn btn-danger me-3" disabled={loading} 
                data-bs-toggle="modal" data-bs-target="#deleteDraftModal">
                    Delete Draft
                </button>
               }
               <button type="button"  className="btn btn-primary" disabled={loading}
                  onClick={() => { saveDraftAction(props); }}>
                  Save Draft
               </button>
               <div className='vr mx-3'></div>
            </>}

            <button type="button"  className="btn btn-warning me-3" disabled={loading} onClick={()=>{previousPage(meterEquipInfo.formStatus)}}>
               Previous
            </button>
            
            {(parseInt(meterEquipInfo.formStatus) == 1) && (!props.disableEditing) && <button type="button"  className="btn btn-success" disabled={loading}
                  onClick={() => { saveSubmitAction(props); }}>
                  Submit
            </button>}
            
         </div>
      </form>
   )
}

TransitionalReportingPartF = reduxForm({
   destroyOnUnmount: false,
   forceUnregisterOnUnmount: true,
   validate : validateAll,
   onSubmitFail: (errors, dispatch ) => {
      dispatch(change('certificate', 'hasError', true));
   },
   onSubmitSuccess:(result , dispatch )=>{
      dispatch(change('certificate', 'hasError', false));
      window.scrollTo(0, 0);
   }
})(TransitionalReportingPartF)

TransitionalReportingPartF = connect(
   (state, props) => {
      const selectorCert = formValueSelector('certificate')
      const hasError = selectorCert(state, 'hasError')

      // can select values individually
      const selector = formValueSelector(props.form)
      const userProfileValues = selector(state, 'mdata.userProfile')
      const meterEquipInfo = selector(state, 'meteringEquipmentInformation')
      const documents = selector(state, 'documents')
      const registrationDoc = selector(state, 'registrationDoc')
      const masterDataValues = selector(state, 'mdata')
      const loading = selector(state, 'loading')
      const form = props.form
      return {
         userProfileValues,
         meterEquipInfo,
         documents,
         registrationDoc,
         masterDataValues,
         form,
         loading,
         hasError
      }
   }, {
   uploadFiles: (formData) => uploadFiles(formData),
   updateDeleteDocuments: (doc) => updateDeleteDocuments(doc),
   changeFormStatus: (payload, val) => changeFormStatus(payload, val),
   updateDocuments: (payload) => updateDocuments(payload)
}
)(TransitionalReportingPartF)

export default TransitionalReportingPartF



