import React from 'react';
import uiHelpers from '../../../helpers/uiHelpers';
import serviceUrls from '../../../constants/serviceUrls';
import * as apiCaller from '../../../helpers/apiCaller'
import moment from 'moment';

class History  extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            history : null
        }
    }

    async componentDidMount () {
        uiHelpers.showProgressOnTop("Getting history...");
        await apiCaller.post(serviceUrls.das.getMyDasAccessRequests,{})
        .then((result)=>{
            this.setState({history:result})
        }).finally(()=>{
            uiHelpers.hideProgressOnTop();
        });
    }


    render() {
        return <>

            <h3 class="mb-3">DAS Workspace Access</h3>
            <ul class="nav nav-pills mb-3">
                <li class="nav-item">
                    <a className="nav-link " href="#/das-access/request-access">Request Access</a>
                </li>
                <li class="nav-item">
                    <a className="nav-link active" href="#/das-access/history">Request History</a>
                </li>
            </ul>
            <hr/>

            {!this.state.history && <div className='text-center'><div class="spinner-border text-primary" role="status">
                <span class="visually-hidden">Loading...</span>
            </div></div>}

            {this.state.history && <table class="table">
                <thead>
                    <tr>
                        <th scope="col" style={{width:'200px'}}>Workspace</th>
                        <th scope="col" style={{width:'150px'}}>Request Period</th>
                        <th scope="col" style={{width:'180px'}}>Requested on</th>
                        <th scope="col">Successful</th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.history.map(h=><tr>
                        <td>{h.meterSiteId}</td>
                        <td>{h.period == "DQP_60" ? "60 days" : h.period == "DQP_180" ? "180 days" : "365 days"}</td>
                        <td>
                            {moment((new Date(h.requestedDate))).format('DD/MM/YYYY h:mma')}
                        </td>
                        <td>{h.succeeded ? <span class="badge bg-success">Yes</span> : <span class="badge bg-danger">No</span>}</td>
                    </tr>)}
                    {this.state.history.length==0 && <tr>
                        <td colspan={4} className='bg-secondary text-center'>No record found</td>    
                    </tr>}
                </tbody>
            </table>}
        </>
    }
}

export default History;