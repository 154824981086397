import React from 'react';
import moment from 'moment';
import * as bootstrap from 'bootstrap'
import * as apiPathes from '../../apiPathes';
import * as serviceCaller from '../../serviceCaller'
import {Meter} from '../../../components/tools/Icons'
import {convertDateTimeIsoToAusFormat} from '../../conveters'
import {isStringNullOrEmpty, isNumber3DecimalPositiveOnly} from '../../../helpers/validators'

class PointOfIntakeMetersForm  extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            meterForReadingModal: null,
            meterModels : [],
            hasVolumeChanged : false
        };
    }

    refreshMeterModels = () => {
        let models = [];
        this.props.nominatedMeasurementPeriod.nominatedMeasurementPeriodPOITMs.forEach((m)=>{
            models.push({
                totalVolumeChangeInput : m.totalVolumeChange,
                totalVolumeChangeError : null,
                changed : false,
                meter: m,
                savingVolume : false
            })
        });
        this.setState({meterModels:models})
    }

    #telemetryReadingModal;
    componentDidMount() {
        this.#telemetryReadingModal = new bootstrap.Modal(document.getElementById('telemetry-reading-modal'));
        this.refreshMeterModels();
    }  

    openTelemetryReadingModal(meter) {
        this.setState({meterForReadingModal : meter});
        this.#telemetryReadingModal.show();
    }

    componentWillUnmount(){
        this.#telemetryReadingModal.dispose();
    }

    toggleIsManual = async (meter) => {
        await serviceCaller.post(apiPathes.savePOITIsManual,
            {
                nominatedMeasurementPeriodPOITMId: meter.id,
                isManual: meter.isManual ? false:true,
                periodStartDate : new Date(this.props.nominatedMeasurementPeriod.periodStartDate),
                periodEndDate : this.props.nominatedMeasurementPeriod.periodEndDate ? new Date(this.props.nominatedMeasurementPeriod.periodEndDate) : null
            }, 
            {
                noIWasLoginHandler: this.props.noLoginCallback,
                loadingMsg: 'Turning on/off manul entry of readings....',
                beforeSend: ()=>{
                    this.props.setDisableFunctions(true)
                },
                success: (data)=>{
                    this.props.updatePeriod(data,false,false,this.refreshMeterModels);
                }
            }
        )
    }

    onPOITVolumeChanged = (value, meterModel) =>{
        meterModel.changed = true;
        meterModel.totalVolumeChangeInput = value;
        meterModel.totalVolumeChangeError = null;
        this.state.hasVolumeChanged = true;
        this.props.setDim(true);
        //this.forceUpdate();
    }

    onCancelVolumeChange = (meterModel) => {
        meterModel.changed = false;
        meterModel.totalVolumeChangeInput = meterModel.meter.totalVolumeChange;
        meterModel.totalVolumeChangeError = null;
        this.state.hasVolumeChanged = false;
        this.props.setDim(false);
    }

    onSaveVolumeChange = async (meterModel) => {
        if(isStringNullOrEmpty(meterModel.totalVolumeChangeInput)) {
            meterModel.totalVolumeChangeError = 'Please provide a value';
            this.forceUpdate();
            return;
        } else if(meterModel.totalVolumeChangeInput && typeof meterModel.totalVolumeChangeInput === 'string'  && !isNumber3DecimalPositiveOnly(meterModel.totalVolumeChangeInput)) {
            meterModel.totalVolumeChangeError = 'This must be a positive number with maximum 3 decimal places';
            this.forceUpdate();
            return;
        }
        meterModel.savingVolume = true;

        await serviceCaller.post(apiPathes.savePOITVolume,
            {
                nominatedMeasurementPeriodPOITMId: meterModel.meter.id,
                isManual: meterModel.meter.isManual,
                totalVolumeChange: isStringNullOrEmpty(meterModel.totalVolumeChangeInput) ? null : parseFloat(meterModel.totalVolumeChangeInput)
            }, 
            {
                noIWasLoginHandler: this.props.noLoginCallback,
                loadingMsg: 'Saving your volume....',
                beforeSend: ()=>{
                    this.props.setDisableFunctions(true, true)
                    this.setState(this.state)
                },
                success: (data)=>{
                    this.props.updatePeriod(data,false,false,this.refreshMeterModels);
                }
            }
        )
    }

    render =() => {
        const {nominatedMeasurementPeriod, dim, disableFunctions} = this.props;

        return <>
            <div className={`card mb-3 `}>
                <div className={`card-header ${dim && !this.state.hasVolumeChanged ?'opacity-25':''}`}>
                    Point of Intake Meters
                </div>
                <div className="card-body">
                    {this.state.meterModels.map((m,index)=>{
                            return <div className={`card card-body mb-3 ${(dim && !m.changed) ?'opacity-25':''} ${(dim && m.changed) ?'border-primary border-2':''}`}>
                                <h5  class="card-title mb-3">{m.meter.meterSiteId}</h5>
                                <div class="form-check form-switch mb-3">
                                    <input class="form-check-input" type="checkbox" id={"poit-m-" + m.id} role="switch" disabled={disableFunctions} checked={m.meter.isManual} onChange={()=>this.toggleIsManual(m.meter)} />
                                    <label class="form-check-label" for={"poit-m-" + m.id}>Telemetry meter is broken</label>
                                </div>
                                {m.meter.isManual && <div className={`alert alert-primary col-md-4 ${dim?'opacity-25':''}`}>
                                    You must report your non-operational meter through the the S91i process.
                                </div>}
                                <div className='row'>
                                    <div className='col-md-4 mb-3'>
                                        <label class="form-label">Volume during period</label>
                                        <div class="input-group ">
                                            <input type="text" className={`form-control ${m.totalVolumeChangeError ? 'is-invalid' : '' }`} disabled={(disableFunctions && ! m.changed) || m.savingVolume || !m.meter.isManual} value={m.totalVolumeChangeInput} onChange={(e)=>{this.onPOITVolumeChanged(e.target.value, m)}} />
                                            <span class="input-group-text">ML</span>
                                            {!m.meter.isManual && <button type="button" class="btn btn-outline-secondary" onClick={()=>this.openTelemetryReadingModal(m.meter)} disabled={disableFunctions}><Meter/></button>}
                                        </div>
                                        {m.totalVolumeChangeError && <div className='text-danger'>
                                            {m.totalVolumeChangeError}
                                        </div>}
                                    </div>
                                    {m.meter.isManual && m.changed && <div className='col-md-4 mb-3'>
                                        <label class="form-label invisible">buttons</label>
                                        <div>
                                            <button class="btn btn-outline-primary me-2" disabled={m.savingVolume} onClick={()=>this.onSaveVolumeChange(m)}>&nbsp;&nbsp;Save&nbsp;&nbsp;&nbsp;</button>
                                            <button class="btn btn-outline-secondary" disabled={m.savingVolume} onClick={()=>{this.onCancelVolumeChange(m)}}>Cancel</button>
                                        </div>
                                    </div>}
                                </div>
                                
                            </div>
                        })
                    }
                </div>
            </div>

            <div class="modal" tabindex="-1" id="telemetry-reading-modal">
                <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">Telemetry readings from DAS</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            {this.state.meterForReadingModal && <>
                                <table className='table'>
                                    <thead>
                                        <tr>
                                            <th>
                                                Time
                                            </th>
                                            <th>
                                                Flow<span className='text-muted'> (ML)</span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            !this.state.meterForReadingModal.dasPriorDate 
                                            &&
                                            this.state.meterForReadingModal.nominatedMeasurementPeriodPOITMReadings.length==0
                                            &&
                                            <tr className='table-warning'>
                                                <td colSpan={2} className="text-center">No reading found</td>
                                            </tr>
                                        }
                                        {this.state.meterForReadingModal.dasPriorDate && <tr className='table-info'>
                                            <td>
                                            {convertDateTimeIsoToAusFormat(this.state.meterForReadingModal.dasPriorDate)}
                                            </td>
                                            <td>
                                            {this.state.meterForReadingModal.volumePriorToPeriodDAS}
                                            </td>
                                        </tr>}
                                        {this.state.meterForReadingModal.nominatedMeasurementPeriodPOITMReadings.sort(function(a, b){
                                            return new Date(a.dasDate) - new Date(b.dasDate)
                                        }).map((r,index)=><tr className={`${index == (this.state.meterForReadingModal.nominatedMeasurementPeriodPOITMReadings.length-1) ? 'table-info' : ''}`}>
                                            <td>
                                                {convertDateTimeIsoToAusFormat(r.dasDate)}
                                            </td>
                                            <td>{r.flow}</td>
                                        </tr>)}
                                    </tbody>
                                </table>
                            </>}
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    }
}

export default PointOfIntakeMetersForm;