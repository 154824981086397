import {
    MESSAGE_REQUIRED,
    MESSAGE_MAX_ALLOWED_CHARCATERS_100,
    MESSAGE_MAX_ALLOWED_CHARCATERS_20,
    MESSAGE_MAX_ALLOWED_CHARCATERS_10,
    MESSAGE_MAX_ALLOWED_CHARCATERS_15,
    MESSAGE_PHONE_ALTERNATEPHONE,
    MESSAGE_INVALID_EMAIL,
    MESSAGE_REQUIRED_NUMBER, integerUpto10Digits, integerUpto10DigitsMessage
} from '../../../constants/MeterFormConstants';

export function isNumber(num) {
    console.log(`function isNumber checking [${num}]`);
    // eslint-disable-next-line
    if (num === null) {
        return false;
    }
    return !isNaN(num) && (0 != num || '' !== ('' + num).trim())
}

export const validate = (values, params) => {
    const errors = {
        authorityHolderDetails: {
            authorityHolderName: "",
            postalAddress: "",
            phone: "",
            alternatePhone: "",
            emailAddress: "",
            contactPersonEmailAddress: "",
            mobile: ""
        }
    }

    let clickSaveDraft = params.clickSaveDraft
    let clickNext = params.clickNext;
    if (clickSaveDraft == undefined)
        clickSaveDraft = values.clickSaveDraft;
    if (clickNext == undefined)
        clickNext = values.clickNext;
    console.log("draft:" + clickSaveDraft);
    if (clickSaveDraft === 1 && clickNext === 0) {
        if (values.authorityHolderDetails.firstName != null && values.authorityHolderDetails.firstName != "" &&
            values.authorityHolderDetails.lastName != null && values.authorityHolderDetails.lastName != "") {
            if (values.authorityHolderDetails.authorityHolderName && values.authorityHolderDetails.authorityHolderName.length > 100) {
                errors.authorityHolderDetails.authorityHolderName = MESSAGE_MAX_ALLOWED_CHARCATERS_100
            }
        }

        if (values.authorityHolderDetails.corporationNameOrAcn && values.authorityHolderDetails.corporationNameOrAcn.length > 100) {
            errors.authorityHolderDetails.corporationNameOrAcn = MESSAGE_MAX_ALLOWED_CHARCATERS_100;
        }

        if (values.authorityHolderDetails.postalAddress && values.authorityHolderDetails.postalAddress.length > 100) {
            errors.authorityHolderDetails.postalAddress = MESSAGE_MAX_ALLOWED_CHARCATERS_100
        }

        if (values.authorityHolderDetails.phone && values.authorityHolderDetails.phone.length > 20) {
            errors.authorityHolderDetails.phone = MESSAGE_MAX_ALLOWED_CHARCATERS_20
        }

        if (values.authorityHolderDetails.mobile) {
            if (!isNumber(values.authorityHolderDetails.mobile)) {
                errors.authorityHolderDetails.mobile = MESSAGE_REQUIRED_NUMBER;
            }
            else if (values.authorityHolderDetails.mobile.length > 15) {
                errors.authorityHolderDetails.mobile = MESSAGE_MAX_ALLOWED_CHARCATERS_15;
            }
            // else if (!integerUpto10Digits.test(values.authorityHolderDetails.mobile)) {
            //     errors.authorityHolderDetails.mobile = integerUpto10DigitsMessage;
            // }

        }



        if (values.authorityHolderDetails.alternatePhone && values.authorityHolderDetails.alternatePhone.length > 20) {
            errors.authorityHolderDetails.alternatePhone = MESSAGE_MAX_ALLOWED_CHARCATERS_20
        }

        if (values.authorityHolderDetails.phone && values.authorityHolderDetails.alternatePhone && (values.authorityHolderDetails.alternatePhone == values.authorityHolderDetails.phone)) {
            errors.authorityHolderDetails.alternatePhone = MESSAGE_PHONE_ALTERNATEPHONE;
        }

        if (values.authorityHolderDetails.emailAddress) {
            if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.authorityHolderDetails.emailAddress)) {
                errors.authorityHolderDetails.emailAddress = MESSAGE_INVALID_EMAIL
            } else if (values.authorityHolderDetails.emailAddress.length > 100) {
                errors.authorityHolderDetails.emailAddress = MESSAGE_MAX_ALLOWED_CHARCATERS_100
            }
        }

        if (values.authorityHolderDetails.contactPersonName && values.authorityHolderDetails.contactPersonName.length > 100) {
            errors.authorityHolderDetails.contactPersonName = MESSAGE_MAX_ALLOWED_CHARCATERS_100
        }

        if (values.authorityHolderDetails.contactPersonPhone && values.authorityHolderDetails.contactPersonPhone.length > 20) {
            errors.authorityHolderDetails.contactPersonPhone = MESSAGE_MAX_ALLOWED_CHARCATERS_20
        }

        if (values.authorityHolderDetails.contactPersonEmailAddress && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.authorityHolderDetails.contactPersonEmailAddress)) {
            errors.authorityHolderDetails.contactPersonEmailAddress = MESSAGE_INVALID_EMAIL
        } else if (values.authorityHolderDetails.contactPersonEmailAddress && values.authorityHolderDetails.contactPersonEmailAddress.length > 100) {
            errors.authorityHolderDetails.contactPersonEmailAddress = MESSAGE_MAX_ALLOWED_CHARCATERS_100;
        }

    }
    else if (clickSaveDraft == 0 && clickNext === 1 && values.meteringEquipmentInformation.formStatus != 2) {

        if (values.authorityHolderDetails.firstName != null && values.authorityHolderDetails.firstName != "" &&
            values.authorityHolderDetails.lastName != null && values.authorityHolderDetails.lastName != "") {
            if (!values.authorityHolderDetails.authorityHolderName) {
                errors.authorityHolderDetails.authorityHolderName = MESSAGE_REQUIRED;
            } else if (values.authorityHolderDetails.authorityHolderName.length > 100) {
                errors.authorityHolderDetails.authorityHolderName = MESSAGE_MAX_ALLOWED_CHARCATERS_100
            }
        }

        if (values.authorityHolderDetails.mobile) {
            if (!isNumber(values.authorityHolderDetails.mobile)) {
                errors.authorityHolderDetails.mobile = MESSAGE_REQUIRED_NUMBER;
            }
            else if (values.authorityHolderDetails.mobile.length > 15) {
                errors.authorityHolderDetails.mobile = MESSAGE_MAX_ALLOWED_CHARCATERS_15;
            }
            // else if (!integerUpto10Digits.test(values.authorityHolderDetails.mobile)) {
            //     errors.authorityHolderDetails.mobile = integerUpto10DigitsMessage;
            // }

        }


        if (values.authorityHolderDetails.corporationNameOrAcn && values.authorityHolderDetails.corporationNameOrAcn.length > 100) {
            errors.authorityHolderDetails.corporationNameOrAcn = MESSAGE_MAX_ALLOWED_CHARCATERS_100;
        }

        if (!values.authorityHolderDetails.postalAddress) {
            errors.authorityHolderDetails.postalAddress = MESSAGE_REQUIRED
        } else if (values.authorityHolderDetails.postalAddress.length > 100) {
            errors.authorityHolderDetails.postalAddress = MESSAGE_MAX_ALLOWED_CHARCATERS_100
        }

        if (!values.authorityHolderDetails.phone) {
            errors.authorityHolderDetails.phone = MESSAGE_REQUIRED
        } else if (values.authorityHolderDetails.phone.length > 20) {
            errors.authorityHolderDetails.phone = MESSAGE_MAX_ALLOWED_CHARCATERS_20
        }



        if (values.authorityHolderDetails.alternatePhone && values.authorityHolderDetails.alternatePhone.length > 20) {
            errors.authorityHolderDetails.alternatePhone = MESSAGE_MAX_ALLOWED_CHARCATERS_20
        }

        if (values.authorityHolderDetails.phone && values.authorityHolderDetails.alternatePhone && (values.authorityHolderDetails.alternatePhone == values.authorityHolderDetails.phone)) {
            errors.authorityHolderDetails.alternatePhone = MESSAGE_PHONE_ALTERNATEPHONE;
        }

        if (!values.authorityHolderDetails.emailAddress) {

            errors.authorityHolderDetails.emailAddress = MESSAGE_REQUIRED
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.authorityHolderDetails.emailAddress)) {
            errors.authorityHolderDetails.emailAddress = MESSAGE_INVALID_EMAIL
        } else if (values.authorityHolderDetails.emailAddress.length > 100) {
            errors.authorityHolderDetails.emailAddress = MESSAGE_MAX_ALLOWED_CHARCATERS_100
        }

        if (values.authorityHolderDetails.contactPersonName && values.authorityHolderDetails.contactPersonName.length > 100) {
            errors.authorityHolderDetails.contactPersonName = MESSAGE_MAX_ALLOWED_CHARCATERS_100
        }

        if (values.authorityHolderDetails.contactPersonPhone && values.authorityHolderDetails.contactPersonPhone.length > 20) {
            errors.authorityHolderDetails.contactPersonPhone = MESSAGE_MAX_ALLOWED_CHARCATERS_20
        }

        if (values.authorityHolderDetails.contactPersonEmailAddress && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.authorityHolderDetails.contactPersonEmailAddress)) {
            errors.authorityHolderDetails.contactPersonEmailAddress = MESSAGE_INVALID_EMAIL
        } else if (values.authorityHolderDetails.contactPersonEmailAddress && values.authorityHolderDetails.contactPersonEmailAddress.length > 100) {
            errors.authorityHolderDetails.contactPersonEmailAddress = MESSAGE_MAX_ALLOWED_CHARCATERS_100;
        }
    }

    console.log(JSON.stringify(errors));
    return errors;
}