import React from 'react';

export default class ValidationReplacingWasMeterFields  extends React.Component {

    render = () => {
        const form = this.props.validationForm;
        return <>
            {form.renderUneditableTextInputRow("replacingWasMeter",<>Meter Number </>,{rowClasses:'mb-3',converter:m=>m.meterNumber})}
            {form.renderUneditableTextInputRow("replacingWasMeter",<>Meter Category </>,{rowClasses:'mb-3',converter:m=>m.meterCategory})}
            {form.renderUneditableTextInputRow("replacingWasMeter",<>Meter Type </>,{rowClasses:'mb-3',converter:m=>m.meterType})}
            {form.renderUneditableTextInputRow("replacingWasMeter",<>Meter Size(mm)  </>,{rowClasses:'mb-3',converter:m=>m.sizeInMM})}
            {form.renderUneditableTextInputRow("replacingWasMeter",<>Meter Serial Number </>,{rowClasses:'mb-3',converter:m=>m.serialNumber})}
        </>
    }
}