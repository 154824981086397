import React from 'react';
import { Field, reduxForm, formValueSelector, change} from 'redux-form';
import { connect } from 'react-redux'
import 'react-redux';
import 'redux';
import { validateCertificatePartA as validate } from './Validation'
import { saveDraftAction, clickNextAction } from "./Validations"
import {InputTextForRedux }  from '../../tools/BootstrapControls'

let CertificatePartA = props => {

 const {
  handleSubmit, masterDataValues, secondaryEquipmentValidationData,loading, hasError
 } = props; 
debugger;
 const disableFields =  true;
 const formStatus = secondaryEquipmentValidationData.formStatus;
 const disableContactFields =   (parseInt(formStatus) == 2) || loading? true : false; 

  if(masterDataValues.userProfile) {
    var thisDQP = masterDataValues.userProfile[0];
    thisDQP = {...thisDQP, "fullName": thisDQP.user.firstName + ' ' + thisDQP.user.lastName};
    props.dispatch(change(props.form, 'dqpdata', thisDQP));
  }

  return (
    <form id="formA" className="vertical" onSubmit={handleSubmit}>
      <h5 className='mb-3'>Duly qualified person (DQP) details </h5>

      <div className='row mb-3'>
        <div className='col-sm-7'>
          <Field
            name="dqpdata.fullName"
            label="DQP name"
            component={InputTextForRedux}
            props={{ disabled: disableFields }}
          />
        </div>
      </div>

      <div className='row mb-3'>
        <div className='col-sm-7'>
          <Field
            name="dqpdata.cmino"
            label="DQP licence number"
            component={InputTextForRedux}
            props={{ disabled: disableFields}}
          />
        </div>
      </div>

      <div className='row mb-3'>
        <div className='col-sm-7'>
          <Field
            name="dqpdata.businessName"
            label="Business name"
            component={InputTextForRedux}
            props={{ disabled: disableFields}}
          />
        </div>
      </div>

      <div className='row mb-3'>
        <div className='col-sm-7'>
          <Field
            name="dqpdata.abnOrAcn"
            label="ABN or ACN"
            component={InputTextForRedux}
            props={{ disabled: disableFields}}
          />
        </div>
      </div>

      <div className='row mb-3'>
        <div className='col-sm-7'>
          <Field
            name="dqpdata.postalAddress.addressLine"
            label="Postal address"
            component={InputTextForRedux}
            props={{ disabled: disableFields }}
          />
        </div>
      </div>

      <div className='row mb-3'>
        <div className='col-sm-7'>
          <Field
            name="dqpdata.phoneNumber"
            label="Phone"
            component={InputTextForRedux}
            props={{ disabled: disableFields }}
          />
        </div>
      </div>

      <div className='row mb-3'>
        <div className='col-sm-7'>
          <Field
            name="dqpdata.user.emailAddress"
            label="Email address"
            component={InputTextForRedux}
            props={{ disabled: disableFields }}
          />
        </div>
      </div>
      <hr className='my-4' />
      <h5 className='mb-3'>Contact person or agent </h5>
      <p>
        <i>
        NOTE: If the DQP is not the main contact for this certificate, provide a contact person or landholder name and details below. 
        </i>
      </p>

      <div className='row mb-3'>
        <div className='col-sm-7'>
          <Field
            name="authorityHolderDetails.contactPersonName"
            label="Name"
            component={InputTextForRedux}
            props={{ disabled: disableContactFields}}
          />
        </div>
      </div>

      <div className='row mb-3'>
        <div className='col-sm-7'>
          <Field
            name="authorityHolderDetails.contactPersonPhone"
            label="Phone number"
            component={InputTextForRedux}
            props={{ disabled: disableContactFields}}
          />
        </div>
      </div>

      <div className='row mb-3'>
        <div className='col-sm-7'>
          <Field
            name="authorityHolderDetails.contactPersonEmailAddress"
            label="Email address"
            component={InputTextForRedux}
            props={{ disabled: disableContactFields }}
          />
        </div>
      </div>

      {hasError && <div className='alert alert-danger'>
         Please fix all errors before you continue
      </div>}

      <div className="text-end">
        {(parseInt(secondaryEquipmentValidationData.formStatus) == 1)  && (!props.disableEditing)  && <>
          {secondaryEquipmentValidationData.id!=0 &&
            <button type="button"  className="btn btn-danger me-3" disabled={loading} 
            data-bs-toggle="modal" data-bs-target="#deleteDraftModal">
              Delete Draft
            </button>
          }

          <button type="button"  className="btn btn-primary" disabled={loading}
            onClick={() => { saveDraftAction(props); }}>
            Save Draft
          </button>
          <div className='vr mx-3'></div>
        </>}
        
        <button type="button"  className="btn btn-success"
          disabled={loading} 
          onClick={() => { clickNextAction(props); }}>
          Next
        </button>
      </div>
    </form>
  )
}

CertificatePartA = reduxForm({
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate,
  onSubmitFail: (errors, dispatch ) => {
      dispatch(change('certificate', 'hasError', true));
  },
  onSubmitSuccess:(result , dispatch )=>{
      dispatch(change('certificate', 'hasError', false));
      window.scrollTo(0, 0);
  }
})(CertificatePartA)

CertificatePartA = connect(
  (state, props) => {
    const selectorCert = formValueSelector('certificate')
    const hasError = selectorCert(state, 'hasError')

    const selector = formValueSelector(props.form);
    const masterDataValues = selector(state, 'mdata');
    const authHolderInfo = selector(state, 'authorityHolderDetails');
    const secondaryEquipmentValidationData = selector(state, 'secondaryEquipmentValidationData');
    const loading = selector(state, 'loading');
    return {
      masterDataValues,
      authHolderInfo,
      secondaryEquipmentValidationData,
      loading,
      form: props.form,
      hasError
    }
  }
)(CertificatePartA)

export default CertificatePartA;
