const config = require("../config")
const { googleAnalytics } = config

export default Object.freeze({
  BASE_URL: getBaseUrl(),
  GTAG_ID: googleAnalytics.gTagId,
  GTM_TAG: googleAnalytics.gtmTag,
  });

  function getBaseUrl() {
    return process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : "";
  }