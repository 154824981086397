import React from 'react';
import * as bootstrap from 'bootstrap'
import { CardImage, ListIcon } from '../../../../components/tools/Icons';

export default class ApprovalFormFileRO  extends React.Component {

    constructor(props) {
        super(props);
        this.state = {view:"IMAGE",canvasImage:null}; 
    }

    bsOffcanvas;
    componentDidMount() {
        this.bsOffcanvas = new bootstrap.Offcanvas(document.getElementById('offcanvasBottomApproval'));
    }

    openImage =(file) =>{
        this.setState({canvasImage:process.env.REACT_APP_WEBAPI_URL + process.env.REACT_APP_FIELS_GET + "/" + file.id})
        this.bsOffcanvas.show();
    }

    render() {
        const documents = this.props.documents;

        const imageDocuments = [];
        const nonImageDocuments = [];
        documents.map((document)=>{
            if(document.fileOriginalName.toUpperCase().includes('.PNG') || document.fileOriginalName.toUpperCase().includes('.GIF') || document.fileOriginalName.toUpperCase().includes('.JPG') || document.fileOriginalName.toUpperCase().includes('.JPEG')) {
                imageDocuments.push(document);
            } else {
                nonImageDocuments.push(document);
            }
        })


        return <>
            {this.state.view == 'IMAGE' && <>
                <div class="btn-group btn-group-sm m-2" role="group">
                    <button type="button" class="btn btn-primary"><CardImage/></button>
                    <button type="button" class="btn btn-outline-primary" onClick={()=>this.setState({view:'LIST'})}><ListIcon/></button>
                </div>
                <br/>
                {imageDocuments.map((file) =>
                    <img 
                            src={process.env.REACT_APP_WEBAPI_URL + process.env.REACT_APP_FIELS_GET + "/" + file.id} 
                            class="img-thumbnail rounded pointer-cursor m-2" 
                            style={{width:"100px",height:"100px", "object-fit": "cover"}} 
                            onClick={()=>this.openImage(file)}
                        />
                )}

                {imageDocuments.length==0 && <div className='alert alert-secondary'>
                    Image file not found in the attachment/s. 
                </div>}

                {nonImageDocuments.length != 0 && 
                <div className='card card-body'>
                    <b>Other Files</b>
                    <table className='table table-striped'>
                        <thead>
                            <tr><th>File name</th><th style={{width:"350px"}}>Description</th></tr>
                        </thead>
                        <tbody>
                            {nonImageDocuments.map(file=><tr>
                                <td>
                                    <a target='_blank' href={process.env.REACT_APP_WEBAPI_URL + process.env.REACT_APP_FIELS_GET + "/" + file.id}>
                                        {file.fileOriginalName}
                                    </a>
                                </td>
                                <td>{file.description}</td>
                            </tr>)}
                        </tbody>
                    </table>
                </div>}

            </>}

            {this.state.view == 'LIST' && <>
                <div class="btn-group btn-group-sm m-2" role="group">
                    <button type="button" class="btn btn-outline-primary" onClick={()=>this.setState({view:'IMAGE'})}><CardImage/></button>
                    <button type="button" class="btn btn-primary" ><ListIcon/></button>
                </div>
                <br/>

                <table className='table table-striped'>
                    <thead>
                        <tr><th>File name</th><th style={{width:"350px"}}>Description</th></tr>
                    </thead>
                    <tbody>
                        {documents.map(file=><tr>
                            <td>
                                <a target='_blank' href={process.env.REACT_APP_WEBAPI_URL + process.env.REACT_APP_FIELS_GET + "/" + file.id}>
                                    {file.fileOriginalName}
                                </a>
                            </td>
                            <td>{file.description}</td>
                        </tr>)}
                    </tbody>
                </table>
            </>}


            



            <div class="offcanvas offcanvas-bottom h-75" tabindex="-1" id="offcanvasBottomApproval">
                <div class="offcanvas-body small text-center">
                    <img src={this.state.canvasImage} />
                </div>
            </div>
        </>
    }
}