import React from 'react';

class HowToGuides extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }


    render() {
        return <>
            <div className='row'>
                <div className='col-sm-6'>

                    <h4 className='text-black'><strong>How to guide / Work Instructions</strong></h4>
                    <ul>
                        {/* 
                        <li>
                            <a className='text-decoration-none' href="https://dqp.waternsw.com.au/blob/static-content/Work%20Instructions%20-%20Meter-Telemetry%20Registration%20-%20New%20meter.pdf" target="_blank">
                                Work Instructions - Meter-Telemetry Registration - New meter
                            </a>    
                        </li>
                        <li>
                            <a className='text-decoration-none' href="https://dqp.waternsw.com.au/blob/static-content/How-To%20Guide%20-%20Multiple%20Meters%20to%20Single%20LID.docx" target="_blank">
                                Mulitple meters to Single LID - Campbell Scientific LID
                            </a>    
                        </li>
                        <li><a className='text-decoration-none' href="https://dqp.waternsw.com.au/blob/static-content/LID-DQP-Portal-infographic.pdf" target="_blank">LID Installation and the DQP Portal</a></li>
                        <li><a className='text-decoration-none' href="https://dqp.waternsw.com.au/blob/static-content/Non-Urban%20Metering%20DQP%20Portal%20User%20Guide.pdf" target="_blank">Non-Urban Metering DQP Portal User Guide</a></li>
                        */}
                        <li><a className='text-decoration-none' href="https://dqp.waternsw.com.au/blob/static-content/How-to%20guide%20-%20DQP%20Portal%204.3.0.pdf" target="_blank">How-to guide - DQP Portal</a></li>
                        <li><a className='text-decoration-none' href="https://dqp.waternsw.com.au/blob/static-content/How-To%20Guide%20-%20Multiple%20Meters%20to%20Single%20LID.pdf" target="_blank">How-to guide - Multi-Meter to Single LID</a></li>
                        <li><a className='text-decoration-none' href="https://dqp.waternsw.com.au/blob/static-content/Data%20Acquisition%20Service%20(DAS)%20Water%20User%20Guide.pdf" target="_blank">Data Acquisition Service (DAS) Water User Guide</a></li>
                        <li><a className='text-decoration-none' href="https://dqp.waternsw.com.au/blob/static-content/Floodplain%20Harvesting%20Gauge%20Board%20Checklist.docx" target="_blank">Floodplain Harvesting Gauge Board Checklist</a></li>
                    </ul>  
                </div>                
            </div>
        </>;
    }
}

export default HowToGuides;