import React from 'react';
import * as apiPathes from '../../apiPathes';
import * as serviceCaller from '../../serviceCaller'
import { Redirect } from 'react-router-dom';
import { convertDateIsoToAusFormat, convertOtherInFlowType} from '../../conveters'

class DeleteOtherInFlowForm  extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            saving: false
        };
    }
    
    componentDidMount() {
          
    }

    delete =async ()=>{
        if(!await serviceCaller.post(apiPathes.deleteOtherInFlow,
            {
                inflowId: this.props.otherInFlow.id,
                nominatedMeasurementPeriodId: this.props.nominatedMeasurementPeriod.id
            }, 
            {
                noIWasLoginHandler: this.props.noLoginCallback,
                loadingMsg: 'Deleting your record....',
                beforeSend: ()=>{
                    this.setState({saving: true})
                },
                success: (data)=>{
                    this.props.onDeleteCompleted(data);
                }
        })) this.setState({saving: false});
    }

    render(){
        const {otherInFlow} = this.props;
        const {saving} = this.state;
        return <tr className='border-primary border-2 '>
            <td>{convertDateIsoToAusFormat(otherInFlow.startDate)}</td>
            <td>{convertDateIsoToAusFormat(otherInFlow.endDate)}</td>
            <td>{convertOtherInFlowType(otherInFlow.inFlowType)}</td>
            <td>{otherInFlow.accessLicense}</td>
            <td>{otherInFlow.volume}</td>
            <td className='text-end'>
                <button className='btn btn-sm btn-outline-primary me-2' disabled={saving} onClick={this.delete}>Confirm delete</button>
                <button className='btn btn-sm btn-outline-secondary' disabled={saving} onClick={()=>this.props.toggleForm()} >Cancel</button>
            </td>
        </tr>
    }

}

export default DeleteOtherInFlowForm;