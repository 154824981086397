import React from 'react'
import authentication from './reactazureb2c';
import {decryptToken} from '../fphMeasurement/API'
import decodeJWT from 'jwt-decode'; 

const LOCAL_STORAGE = 'localStorage'
const SESSION_STORAGE = 'sessionStorage'
const AUTHORIZATION_KEY = 'JAWTAuthorization'
const state = {
    b2c: false,
    launchApp: null,
    accessToken: null,
    scopes: [],
    cacheLocation: null
  }
  const cleanUpStorage = cacheLocation => {
    if (cacheLocation === LOCAL_STORAGE) {
      window.localStorage.removeItem(AUTHORIZATION_KEY)
    } else if (cacheLocation === SESSION_STORAGE) {
      window.sessionStorage.removeItem(AUTHORIZATION_KEY)
    }
  }

  const config = {
    instance: process.env.REACT_APP_B2C_INSTANCE,
      tenant: process.env.REACT_APP_B2C_TENANT,
      signInPolicy: process.env.REACT_APP_B2C_SIGNIN_SIGNOUT_POLICY,
      signUpPolicy: process.env.REACT_APP_B2C_SIGNUP_POLICY,
      profilePolicy: process.env.REACT_APP_B2C_PROFILE_EDIT_POLICY,
      clientId: process.env.REACT_APP_B2C_SECRET_KEY,
      cacheLocation: process.env.REACT_APP_B2C_CACHE_LOCATION,
      scopes: [process.env.REACT_APP_B2C_SCOPES],
      redirectUri: process.env.REACT_APP_B2C_REDIRECT_URI,
      postLogoutRedirectUri: process.env.REACT_APP_B2C_LOGOUT_REDIRECT_URI
}
export const jwtAuthentication = {
  
  initialize: () => {    
    let stateCookie = window.localStorage['msal.client.info'];        
    //debugger;
    if(stateCookie) {
        state.b2c = true;
        authentication.initialize();
    } else {
       
    }
  },
  run: (launchApp) => {
    if(state.b2c) {       
        authentication.run();
    }   
  },
  required: (WrappedComponent, renderLoading) => {
    //debugger;
    return class extends React.Component {
      constructor (props) {
        super(props)
        this.state = {
          signedIn: false,
          error: null
        }
      }
 
      componentWillMount () {
          if(state.b2c) {
        authentication.initAccessToken(() => {
          this.setState({
            signedIn: true
          })
        })
        } else {
            this.setState({
                signedIn: true
              })
        }
      }

      render () {
        if (this.state.signedIn) {
          return (<WrappedComponent {...this.props} />)
        }
        return typeof renderLoading === 'function' ? renderLoading() : null
      }
    }
  },
  goToHomeIfLoggedIn: (WrappedComponent, HomeComponent) => {
    return class extends React.Component {
      constructor (props) {
        super(props)
        this.state = {
          signedIn: false,
          error: null
        }
      }

      componentWillMount () {
        if(state.b2c){
            authentication.acquireTokenWithoutRedirection(() => {
          this.setState({
            signedIn: true
          })
        }) } 
        else {
            this.setState({
                signedIn: true
              })
        }
      }

      render () {
        if (this.state.signedIn) {
          
          return (<HomeComponent {...this.props} />);
        }
        return (<WrappedComponent {...this.props} />);
      }
    }
  },
  signOut: () => {
    if(state.b2c){ 
        authentication.signOut();
    }
  }, //RESTART
  getAccessToken: () => { 
    if(state.b2c){  
       state.accessToken =  authentication.getAccessToken();
       return state.accessToken
    } else {
       // return '';//this is where we make axios call to backend to fetch jwt token
       return state.accessToken;
    }
    },
    getAccessTokenAsync: (method) => {      
       //read encrypted token from url
        //if(enc token can be read) {
        //send request to decrypt token
        //} else { //error out}. Till iWas sends actual tokens a temp token is unconditionally sent as funct needs testing
        if(!state.accessToken){
          decryptToken("PLACEHOLER_FOR_ACTUAL_TOKEN",
            (data) => {
              //debugger;
              state.accessToken = data;
              method(decodeJWT(data))
            },
            (error) => console.log("error->"+ JSON.stringify(error)),
            ()=>console.log("decryptToken call is complete")                  
          );
          }else {
            method(decodeJWT(state.accessToken));
          }      
      },
  setAccessToken: 
  (accessToken) => { 
      if(state.b2c) {
          authentication.setAccessToken(accessToken)
         }
    },
 
  login: () => {
    if(state.b2c) {
        authentication.login();
    }
    
    //redirect();
  },
  signUp: () => {
    if(state.b2c) {
        authentication.signUp();
    }        
  },
  profile: () => {
    if(state.b2c) {
        authentication.profile();
    }    
  },
  isLoggedIn: () => {
    if(state.b2c) {
        return authentication.isLoggedIn();
    }    
    return (state.accessToken != undefined); 
   },
   isB2C: () => {return state.b2c === true }

}


