import axios from 'axios';
import { LOG_JSON } from './MeasurementConstants';
import Authentication from '../helpers/Authentication';

const authentication = new Authentication();

const token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJPbmxpbmUgSldUIEJ1aWxkZXIiLCJpYXQiOjE2MjUxMTk0NTUsImV4cCI6MTY1NjY1NTQ1NSwiYXVkIjoid3d3LmV4YW1wbGUuY29tIiwic3ViIjoianJvY2tldEBleGFtcGxlLmNvbSJ9.uFbBLhsefTDC7CkjSC86L8z6ee5m6V3EaYw1koO4eKc';
const staticConfig = {
    baseURL: process.env.REACT_APP_WEBAPI_URL,
    headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }
};
const rawConfig = {
    baseURL: process.env.REACT_APP_WEBAPI_URL,
    headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json' }
};

const b2cConfig = b2ctoken => {
    return {
        baseURL: process.env.REACT_APP_WEBAPI_URL,
        headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + b2ctoken }
    }
};

const errorString = code => `API call failed with error: ${code}`;

export const submission = (nomination, postSubmissionSucess, postSubmissionFail) => {
    debugger;
    try {
        axios.post('/fphvalidation/postMeasurementData2', JSON.stringify(nomination), staticConfig).then(function (response) {
            if (response.status == 200) {
                LOG_JSON(response.data.payload.nomination, "submission post_data_from_server is:");
                postSubmissionSucess(response.data.payload.nomination);
            }
            else {
                console.log(errorString(response.status));
                postSubmissionFail();
            }
        })
    } catch (error) {
        console.log(error);
        postSubmissionFail();
    }
}


export const deleteDischarge = (dischargeId, postSubmissionSucess, postSubmissionFail) => {
    debugger;
    try {
        axios.post('/fphvalidation/measurementdischarge', { dischargeId: dischargeId }, staticConfig).then(function (response) {
            if (response.status == 200) {
                // LOG_JSON(response.data.payload.nomination, "submission post_data_from_server is:");
                // postSubmissionSucess(response.data.payload.nomination);
                postSubmissionSucess(dischargeId);
            }
            else {
                console.log(errorString(response.status));
                postSubmissionFail("failed");
            }
        })
    } catch (error) {
        console.log(error);
        postSubmissionFail("failed");
    }
}

export const deleteMeasurementPeriod = (nominationId, postSubmissionSucess, postSubmissionFail) => {
    debugger;
    try {
        axios.post('/fphvalidation/measurementperiod', { nominationId: nominationId }, staticConfig).then(function (response) {
            if (response.status == 200) {
                // LOG_JSON(response.data.payload.nomination, "submission post_data_from_server is:");
                // postSubmissionSucess(response.data.payload.nomination);
                postSubmissionSucess(nominationId);
            }
            else {
                console.log(errorString(response.status));
                postSubmissionFail("failed");
            }
        })
    } catch (error) {
        console.log(error);
        postSubmissionFail("failed");
    }
}

export const deleteFlow = (flowId, postSubmissionSucess, postSubmissionFail) => {
    try {
        axios.post('/fphvalidation/measurementflow', { flowId: flowId }, staticConfig).then(function (response) {
            if (response.status == 200) {
                // LOG_JSON(response.data.payload.nomination, "submission post_data_from_server is:");
                // postSubmissionSucess(response.data.payload.nomination);
                postSubmissionSucess(flowId);
            }
            else {
                console.log(errorString(response.status));
                postSubmissionFail("failed");
            }
        })
    } catch (error) {
        console.log(error);
        postSubmissionFail("failed");
    }
}

export const getRatingTableContents = (ratingTableId, success, fail, doFinally) => {
    if (ratingTableId) {
        axios.post('/ratingTables/getRatingTableContentByTableId', { "id": ratingTableId }, staticConfig).then(function (response) {
            if (response.status == 200) {
                LOG_JSON(response.data, "getRatingTableContents data is:");
                LOG_JSON(response.data.data, "getRatingTableContents data data is:");
                success(response.data);
            }
        }).catch(function (error) {
            fail(error);
        }).then(function () {
            doFinally();
        });
    }
}

export const getNominationPDF = (nominationID, success, fail, doFinally) => {
    if (nominationID) {
        axios.post('/fphvalidation/getNominationPDF', { "id": nominationID }, { ...staticConfig, responseType: 'arraybuffer' }).then(function (response) {
            debugger;
            if (response.status == 200) {
                LOG_JSON(response.data, "getRatingTableContents data is:");
                debugger;
                success(response.data);
            }
        }).catch(function (error) {
            fail(error);
        }).then(function () {
            doFinally();
        });
    } else {
        fail("Mandatory param missing: nominationID");
    }
}

export const getSubmission = (formId, success, fail, doFinally) => {
    axios.get('/fphvalidation/postMeasurementData/' + formId, staticConfig).then(function (response) {
        if (response.status == 200) {
            LOG_JSON(response.data.payload, "getSubmission_data_from_server is:");
            success(response.data.payload);
        }
    }).catch(function (error) {
        fail(error);
    }).then(function () {
        doFinally();
    });
}


export const getDasData = (storageId, success, fail, doFinally) => {
    axios.get('/fphvalidation/DasData/' + storageId, staticConfig).then(function (response) {
        if (response.status == 200) {
            LOG_JSON(response.data.payload, "getDasData_from_server is:");
            success(response.data.payload);
        }
    }).catch(function (error) {
        fail(error);
    }).then(function () {
        doFinally();
    });
}



export const getRefData = (userid, success, fail, doFinally) => {
    const params = { userid: userid };
    axios.get('/referenceData/FPHMeasurement', { ...staticConfig, params: params }).then(function (response) {
        if (response.status == 200) {
            LOG_JSON(response.data.payload, "getRefData_data_from_server is:");
            success({ ...response.data.payload, userid: userid });
        }
    }).catch(function (error) {
        fail(error)
    }).then(function () {
        doFinally();
    });
}

export const checkOpenMeasurementForms = (userid, roino, ratingtablename, success, fail, doFinally) => {
    if (userid && roino && ratingtablename) {
        axios.post('/referenceData/FPHCheckOpenMeasurementPeriod', { "userid": userid, "roino": roino, "ratingtablename": ratingtablename }, b2cConfig(authentication.getToken())).then(function (response) {
            if (response.status == 200) {
                LOG_JSON(response.data.data, "checkOpenMeasurementForms_from_server is:");
                success(response.data.data);
            } else {
                fail({ error: 'response status code is: ' + response.status })
            }
        }).catch(function (error) {
            fail(error)
        }).then(function () {
            doFinally();
        });
    } else {
        fail("input missing for email");
    }
}

export const getUserProfile = (email, success, fail, doFinally) => {
    if (email) {
        axios.post('/users/getprofile', { email: email }, b2cConfig(authentication.getToken())).then(function (response) {
            if (response.status == 200) {
                LOG_JSON(response.data.data, "getUserProfile_data_from_server is:");
                success(response.data.data.user);
            } else {
                fail({ error: 'response status code is: ' + response.status })
            }
        }).catch(function (error) {
            fail(error)
        }).then(function () {
            doFinally();
        });
    } else {
        fail("input missing for email");
    }
}

export const decryptToken = (encrptedToken, success, fail, doFinally) => {
    if (encrptedToken) {
        axios.post('/users/decryptToken', { encrptedToken: encrptedToken }, rawConfig).then(function (response) {
            if (response.status == 200) {
                LOG_JSON(response.data.data, "decryptToken_data_from_server is:");
                success(response.data.data);
            } else {
                fail({ error: 'response status code is: ' + response.status })
            }
        }).catch(function (error) {
            fail(error)
        }).then(function () {
            doFinally();
        });
    } else {
        fail("input missing for encrptedToken");
    }
}


