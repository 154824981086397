import { truncateSync } from "fs";
import React from 'react';
import moment from 'moment';

export const RATING_TABLE_UPDATE_CERT_URL = '/ratingTables/updateCertificate/';
export const SECONDARY_METER_VALIDATE_URL = '/secondaryEquipmentValidation/certificate/';
export const STORAGE_MGMT_VALIDATE_URL = '/storageManagementValidation/certificate/';
export const STORAGE_MGMT_CHECK_SERIAL = '/storageManagementValidation/checkserialnumber/';
export const REF_DATA_BY_MODULE_URL = '/referenceData?module=';
export const UPLOAD_CSV = "/uploadfile/uploadcsv";
export const IDENTITY_FUNCTION = a => a;

export const METER_FORM_TYPES = {
    METER_VALIDATION: 1,
    METER_DESIGN: 2,
    METER_ACCURACY: 3,
    TRANSITIONAL_REPORTING: 4,
    MAINTENANCE_VALIDATION: 9
}

export const METER_VALIDATION_TYPE = {
    NEW: 1,
    EXISTING: 2,
    REPLACEMENT: 3
}

export const MANDATORY = (<span style={{ color: 'red' }}>&nbsp;*</span>);

export const certOptions =
    [
        { id: '', name: "Select...", selected: true },
        { id: 1, name: "New survey benchmarks installation and validation", selected: false },
        { id: 2, name: "Reinstatement and validation after damage to existing survey benchmark(s)", selected: false }

    ];

export const benchmark_options =
    [
        { id: '', name: "Select...", selected: true },
        { id: 1, name: "Type 2-state survey mark", selected: false },
        { id: 2, name: "Type 6-galvanised steel fence post", selected: false },
        { id: 3, name: "Type 17-drilled hole", selected: false },
        { id: 4, name: "Type 18-chiselled triangle", selected: false },
        { id: 5, name: "Type 19-deep driven steel star picket fence post with marker post", selected: false },
        { id: 6, name: "Type 20-deep driven steel fence post", selected: false }
    ];

export const meter_options =
    [
        { id: '', name: "Select...", selected: true },
        { id: 1, name: "Radar", selected: false },
        { id: 2, name: "Submersible (elevated platform)", selected: false },
        { id: 3, name: "Submersible (embankment)", selected: false }

    ];

export const gps_options =
    [
        { id: "1", name: "Within 30 mm horizontal", selected: false },
        { id: "2", name: "Within 50 mm vertical (level)", selected: false }
    ];

export const SELECT_VALUE = { id: '', name: "Select...", selected: true };

export const METER_FORM_STATUS = {
    DRAFT: 1,
    FINAL: 2
}

export const btnStyle = {
    float: 'right',
    margin: '3px'
};

export const btnStyleLeft = {
    float: 'left',
    margin: '3px'
};

export const DQP_QUALIFICATION_TYPE = {
    CERTIFIED_METER_INSTALLER: "Certified meter installer",
    CERTIFIED_PRACTISING_HYDROGRAPHER: "Certified practising hydrographer",
    REGISTERED_SURVEYOR: "Registered surveyor",
    CERTIFIED_STORAGE_METER_VALIDATOR: "Certified storage meter validator",
    REGISTERED_ENGINEER: "Registered engineer",
    CERTIFIED_METER_INSTALL_AND_STORAGE_METER_VALIDATOR: "Certified meter installer & Certified storage meter validator"
}

export const DQP_QUALIFICATION_TYPE_VALUE = {
    CERTIFIED_METER_INSTALLER: 1,
    CERTIFIED_PRACTISING_HYDROGRAPHER: 2,
    REGISTERED_SURVEYOR: 3,
    CERTIFIED_STORAGE_METER_VALIDATOR: 4,
    CERTIFIED_METER_INSTALL_AND_STORAGE_METER_VALIDATOR: 5,
    REGISTERED_ENGINEER: 6
}

export const METER_SAVE_CLICK_DRAFT = 1;
export const METER_RESET_CLICK_DRAFT = 0;
export const METER_SAVE_CLICK_NEXT = 1;
export const METER_RESET_CLICK_NEXT = 0;
export const SEARCH_WAN = 0;


export const TEN_CHARS = RegExp(/^[0-9A-Za-z]{1,10}$/i);
export const decimal2UpTo2Digits = new RegExp(/^((0|[1-9]\d{0,1})(\.\d{1,2})*){0,1}$/i);
export const decimal2UpTo2DigitsMessage = "Maximum of 2 digits, up to 2 decimal digits, Leading 0s not allowed in integer part";
export const decimal2UpTo2Digits_notMandatory = new RegExp(/^((0|[1-9]\d?)((\.\d{1,2}))?)?$/);

export const positiveIntegerOnly_notMandatory = new RegExp(/^(0|\d+)$/);

export const pipeLengthValidationMessage = "Please only enter positive number from 0 ~ 100000 with no decimal.";

export const storageIdentifyingNumber_validation = new RegExp(/^([A-Z a-z 0-9]{1,10})(_[0-9 A-Z a-z]{1,10}){2}$/g);  //3 components, separated by _ each 10 char max
export const storageIdentifyingNumber_validation_message = "Should be of form a_b_c where a=ROI Number (Max 10 chars) b=Activity number (Max 10 chars) c=OFS Reference (Max 10 chars)";

export const decimal3UpTo6Digits = new RegExp(/^((0|[1-9]\d{0,2})(\.\d{1,6})*){0,1}$/i);
export const decimal3UpTo6DigitsMessage = "Maximum of 3 digits, up to 6 decimal digits,, Leading 0s not allowed in integer part";

export const decimal1UpTo8Digits = new RegExp(/^((0|[1-9]\d{0,7})(\.\d{1,1})*){0,1}$/i);
export const decimal1UpTo8DigitsMessage = "Maximum of 8 digits, up to 1 decimal digits, Leading 0s not allowed in integer part";

export const decimal2UpTo8Digits = new RegExp(/^((0|[1-9]\d{0,7})(\.\d{1,2})*){0,1}$/i);
export const decimal2UpTo8DigitsMessage = "Maximum of 8 digits, up to 2 decimal digits, Leading 0s not allowed in integer part";

export const decimal3UpTo8Digits = new RegExp(/^((0|[1-9]\d{0,7})(\.\d{1,3})*){0,1}$/i);
export const decimal3UpTo8DigitsMessage = "Maximum of 8 digits, up to 3 decimal digits, Leading 0s not allowed in integer part";

//export const decimal3UpTo5Digits = new RegExp(/^(-?(0|[1-9]\d{0,2})(\.\d{1,5})*){0,1}$/i);
//export const decimal3UpTo5DigitsMessage = "Maximum of 3 digits, up to 5 decimal digits, Leading 0s not allowed in integer part";
//export const workApprovalExpression = new RegExp(/^((([1-9][0-9][CW][A])([0-9][0-9][0-9][0-9][0-9][0-9]))|(([1-9][0-9][M][W])([0-9][0-9][0-9][0-9][0-9][0-9])))$/i);
export const decimal3UpTo7Digits = new RegExp(/^(-?(0|[1-9]\d{0,2})(\.\d{1,7})*){0,1}$/i);
export const decimal3UpTo7DigitsMessage = "Maximum of 3 digits, up to 7 decimal digits, Leading 0s not allowed in integer part";

export const decimal3UpTo14Digits = new RegExp(/^(-?(0|[1-9]\d{0,2})(\.\d{1,14})*){0,1}$/i);
export const decimal9UpTo14Digits = new RegExp(/^(-?(0|[1-9]\d{0,8})(\.\d{1,7})*){0,1}$/i);
export const decimal3UpTo14DigitsMessage = "Maximum of 3 digits, up to 14 decimal digits, Leading 0s not allowed in integer part";
export const decimal9UpTo14DigitsMessage = "Maximum of 9 digits, up to 7 decimal digits, Leading 0s not allowed in integer part";

export const decimal8to8Digits = new RegExp(/^((0|[1-9]\d{0,7})(\.\d{1,8}){0,1})$/i);
export const decimal8to8DigitsMessage = "Entry must be greater than 0. Entry must be a number with up to 8 digits and up to 8 decimal digits";

export const workApprovalExpression = new RegExp(/^((([1-9][0-9][CW][A])([0-9][0-9][0-9][0-9][0-9][0-9]))|(([1-9][0-9][M][W])([0-9][0-9][0-9][0-9][0-9][0-9])))$/i);
export const workApprovalExpressionMessage = <span>The following formats are allowed:<br /> 1. xxCAxxxxxx - for a combined approval,<br /> 2. xxWAxxxxxx - for a work approval, <br />3. xxMWxxxxxx -  for a licence not requiring a work approval<br /> where x is a number</span>
export const decimal2Digits = new RegExp(/^((0|[1-9]\d{0,8})(\.\d{1,2})*){0,1}$/i);
export const POST_CODE = new RegExp(/^\d{4}$/i);
export const decimal2DigitsMessage = "Maximum of 8 digits up to 2 decimal digits allowed, Leading 0s not allowed in integer part"
export const decimal10Digits2 = new RegExp(/^((0|[1-9]\d{0,9})(\.\d{1,2})*){0,1}$/i);
export const decimal10Digits2Message = "Maximum of 10 digits up to 2 decimal digits allowed, Leading 0s not allowed in integer part"
export const decimal4Digits = new RegExp(/^((0|[1-9]\d{0,8})(\.\d{1,4})*){0,1}$/i);
export const decimal15Digits = new RegExp(/^((0|[1-9]\d{0,15})(\.\d{1,15})*)$/i);

export const decimal4DigitsMessage = "Maximum of 8 digits up to 4 decimal digits, Leading 0s not allowed in integer part"
export const integer2to4Digits = new RegExp(/^([1-9]\d{1,3})$/i);
export const integer4DigitsMessage = "Entry must be a number between 10 and 9999. No decimals values allowed"
export const integer2to4DigitsMessage = "Minimum of 2 digits, up to a maximum of 4 digits, Leading 0s not allowed"
export const integerUpto4Digits = new RegExp(/^(0|[1-9]\d{0,3})$/i);
export const integerUpto4DigitsMessage = "Maximum of 4 digits allowed, Leading 0s not allowed"
export const integerUpto8Digits = new RegExp(/^(0|[1-9]\d{0,7})$/i);
export const integerUpto8DigitsMessage = "Maximum of 8 digits allowed, Leading 0s not allowed";
export const integersOnlyUpto8DigitsMessage = "Value must not contain decimals. Maximum of 8 digits allowed. Leading 0s not allowed";
export const integerUpto20Digits = new RegExp(/^(0|[1-9]\d{0,19})$/i);
export const integerUpto20DigitsMessage = "Maximum of 20 digits allowed, Leading 0s not allowed";
export const integerUpto10Digits = new RegExp(/^(0|[1-9]\d{0,9})$/i);
export const integerUpto10DigitsMessage = "Maximum of 10 digits allowed, Leading 0s not allowed";
export const longitudeMessage = "Allowed values between 140.9953061 and 153.6387301";
export const latitudeMessage = "Allowed values between -37.50538547 and -28.15709423";
export const beforeReadingBiggerThanAfterMessage = "Reading cannot be lower than 'Before Maintenance'";
export const beforeReadingDateAfterAfterReadingDateMessage = "Date must be after 'Before' date";
export const integer1to6Digits = new RegExp(/^([1-9]\d{0,5})$/i);
export const integer1to6DigitsMessage = "Minimum of 1 digit, up to a maximum of 6 digits, Leading 0s not allowed"
export const fileSizeUploadLimit = (10 * 1024 * 1024)
export const valid_email = new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i);

export const METER_READING_NOT_ACCURATE = prev => `Value cannot be less than last known reading: ${prev}`;


export function isNumber(num) {
    console.log(`function isNumber checking [${num}]`);
    // eslint-disable-next-line
    if (num === null) {
        return false;
    }
    return !isNaN(num) && (0 != num || '' !== ('' + num).trim())
}

export function isEmpty(text) {
    if (text === null || isNaN(text)) {
        return true;
    }
    if(text.trim()=="") return true;
    return false;
}

export function checkLongitude(num) {
    // eslint-disable-next-line
    if (num >= 140.9953061 && num <= 153.6387301)
        return true;
    else
        return false;
}

export function checkLatitude(num) {
    // eslint-disable-next-line
    if (num <= -28.15709423 && num >= -37.50538547)
        return true;
    else
        return false;
}

export const MESSAGE_REQUIRED = "Required"
export const MESSAGE_REQUIRED_NUMBER = "Required number"
export const MESSAGE_REQUIRED_METER_NUMBER = "Meter number is required to continue"
export const MESSAGE_REQUIRED_ESID = "ESID is required to continue"
export const MESSAGE_DATE_ORDER = (startDate, endDate) => `${endDate} cannot be before the ${startDate}`;
export const MESSAGE_MAX_ALLOWED_CHARCATERS_100 = "Maximum allowed characters are 100"
export const MESSAGE_MAX_ALLOWED_CHARCATERS_15 = "Maximum allowed characters are 15"
export const MESSAGE_MAX_ALLOWED_CHARCATERS_500 = "Maximum allowed characters are 500"
export const MESSAGE_MAX_ALLOWED_CHARCATERS = (number) => `Maximum allowed characters are ${number}`;
export const MESSAGE_MAX_ALLOWED_CHARCATERS_32 = "Maximum allowed characters are 32"
export const MESSAGE_MAX_ALLOWED_CHARCATERS_30 = "Maximum allowed characters are 30"
export const MESSAGE_MAX_ALLOWED_CHARCATERS_50 = "Maximum allowed characters are 50"
export const MESSAGE_MAX_ALLOWED_CHARCATERS_25 = "Maximum allowed characters are 25"
export const MESSAGE_MAX_ALLOWED_CHARCATERS_20 = "Maximum allowed characters are 20"
export const MESSAGE_MAX_ALLOWED_CHARCATERS_10 = "Maximum allowed characters are 10"
export const MESSAGE_MIN_ALLOWED_DIGITS_2_TO_4 = 'Minimum of 2 digits, up to a maximum of 4 digits'
export const MESSAGE_MIN_ALLOWED_DIGITS_3_TO_6 = "Minimum of 3 digits, up to a maximum of 6 digits"
export const MESSAGE_PHONE_ALTERNATEPHONE = "Alternate phone should be different than Phone";
export const MESSAGE_INVALID_EMAIL = "Invalid email address"
export const MESSAGE_UPLOAD_DOCUMENTS = "Please upload all the documents"
export const MESSAGE_UPLOAD_LIMITS = "Note: Maximum file(s) size allowed 10 MB"
export const MESSAGE_DATACOLLECTORTYPE_LID_SELECTED = "The selected LID does not allow data to be manually downloaded and cannot be used as a Logger"

export const formatDate = dbDate => moment((new Date(dbDate))).format('DD/MM/YYYY');
export const formatDateTime = dbDate => moment((new Date(dbDate))).format('DD/MM/YYYY hh:mm A');

//lot layer
export const MAP_SIX_LAYER9 = "https://maps.six.nsw.gov.au/arcgis/rest/services/public/NSW_Cadastre/MapServer/9"
//lotlabel Layer
//export const MAP_SIX_LAYER3 = "https://maps.six.nsw.gov.au/arcgis/rest/services/public/NSW_Cadastre/MapServer/3"
export const MAP_SIX_LAYER3 = "https://portal.spatial.nsw.gov.au/server/rest/services/NSW_Land_Parcel_Property_Theme/FeatureServer/8"
//licence layer 0 query
export const WNSW_LICENCE_LAYER = "https://spatial.water.nsw.gov.au/arcgis/rest/services/Water_Public/WNSW_WaterManagement_V5/MapServer/0/query"
//production bore query
export const WNSW_PRODUCTION_BORE_LAYER = "https://spatial.water.nsw.gov.au/arcgis/rest/services/Water_Public/WNSW_WaterManagement_V5/MapServer/0/query"
//monitoring bore query
export const WNSW_MONITORING_BORE_LAYER = "https://spatial.water.nsw.gov.au/arcgis/rest/services/Water_Public/WNSW_WaterManagement_V5/MapServer/1/query"

export const removeEmptyElementsFromArray = array => {
    let retVal = [];
    for (let index = 0; index < array.length; index++) {
        if (array[index] && ('' + array[index]).trim() != '' && ('' + array[index]).trim() != ',') {
            retVal.push(array[index]);
        }
    }
    console.log("removeEmptyElementsFromArray returns " + retVal);
    return retVal;
}

const ONE_DAY = 24 * 60 * 60 * 1000;
export const enumerateTimePeriod = (start, end) => {
    debugger;

    if (!start) {
        return [];
    }

    let startDate = Date.parse(start);
    let midDay = new Date(startDate).setHours(12, 0, 0, 0);

    let endDate = new Date().setHours(23, 59, 59, 999);

    let dataRangeProvided = false;
    if (end) {
        dataRangeProvided = true;
        endDate = new Date(Date.parse(end));
        endDate.setHours(23, 59, 59, 999)
    }

    if (!dataRangeProvided && (endDate <= startDate || (endDate - startDate < ONE_DAY))) {
        return [start];
    }

    let maxIter = 31;//measurement period has a max 31 days, user could enter a ridiculous date and loop will run long time for nothing
    let retVal = [];
    while (midDay < endDate) {
        retVal.push(new Date(midDay));
        midDay = midDay + ONE_DAY;
        if (maxIter < 1) {
            console.log("loop breaking for >31 day period");
            break;
        }
    }
    console.log("date range from enumerateTimePeriod is");
    console.log(retVal);
    return retVal;
}

export const roundOff = (number, precision = 4) => {
    let multiplier = 10 ** (precision);
    return Math.round(number * multiplier) / multiplier;

}


export const closedConduitandProtableOptionsForValidationCert =
      [
         { id: "3", name: "Meter marker plate", selected: false },
         { id: "4", name: "Evidence of tamper proofing/Meter seals", selected: false },
         { id: "5", name: "Meter body and type", selected: false },
         { id: "6", name: "Functioning display/readout panel showing meter reading", selected: false },
         { id: "7", name: "Site photo showing whole installation", selected: false },
         { id: "11", name: "Meter Test Certificate", selected: false },
         { id: "12", name: "Other", selected: false },
      ];

export const openChannelOptionsForValidationCert =
      [
         //{ id: "3", name: "Meter marker plate", selected: false },
         { id: "4", name: "Evidence of tamper proofing/Meter seals", selected: false },
         { id: "5", name: "Meter body and type", selected: false },
         { id: "6", name: "Functioning display/readout panel showing meter reading", selected: false },
         { id: "7", name: "Site photo showing whole installation", selected: false },
         { id: "8", name: "Sensor location", selected: false },
         { id: "9", name: "Channel looking upstream", selected: false },
         { id: "10", name: "Channel looking downstream", selected: false },
         //{ id: "11", name: "Meter Test Certificate", selected: false },
         { id: "12", name: "Other", selected: false },
      ];


export const closedConduitandProtableOptionsForAccuracyCert =
    [
        { id: "3", name: "Meter marker plate", selected: false },
        { id: "4", name: "Evidence of tamper proofing/Meter seals", selected: false },
        { id: "5", name: "Meter body and type", selected: false },
        { id: "6", name: "Functioning display/readout panel showing meter reading", selected: false },
        { id: "7", name: "Site photo showing whole installation", selected: false },
        { id: "11", name: "Meter Test Certificate", selected: false },
        { id: "12", name: "Other", selected: false },
    ];

export const openChannelOptionsForAccuracyCert =
    [
        //{ id: "3", name: "Meter marker plate", selected: false },
        { id: "4", name: "Evidence of tamper proofing/Meter seals", selected: false },
        { id: "5", name: "Meter body and type", selected: false },
        { id: "6", name: "Functioning display/readout panel showing meter reading", selected: false },
        { id: "7", name: "Site photo showing whole installation", selected: false },
        { id: "8", name: "Sensor location", selected: false },
        { id: "9", name: "Channel looking upstream", selected: false },
        { id: "10", name: "Channel looking downstream", selected: false },
        //{ id: "11", name: "Meter Test Certificate", selected: false },
        { id: "12", name: "Other", selected: false },
    ];

export const uploadOptionsForDesignCert = [
        { id: "2", name: "Engineered design plan for the open channel metering equipment", selected: false },
        { id: "1", name: "Other", selected: false }
     ];

export const patternApprovedOptionsForTransitionalReporting =
     [
        { id: "1", name: "Pattern Approved", selected: false }
     ];

export const patternNotApprovedOptionsForTransactionalReporting =
     [
        { id: "4", name: "Manufacturer’s certificate and a copy of the existing validation provided by a duly qualified person", selected: false },
        { id: "5", name: "Non-urban water meter—certificate of accuracy for existing meter (not pattern-approved)’ provided by a duly qualified person that certifies the maximum error of the equipment does not exceed +/-5% in the field", selected: false }
     ];

export const uploadOptionsForStoreManagementValidation = [
    { id: "3", name: "Installation and calibration checklists of the storage meter installation", selected: false },
    { id: "4", name: "Accuracy certificate for existing meters", selected: false },
    { id: "5", name: "Photos of the storage meter installation", selected: false },
    { id: "6", name: "Other", selected: false }
 ];

 export const uploadOptionsForSecondaryEquipmentValidation = [
    { id: "3", name: "Installation and calibration checklists of the storage meter installation", selected: false },
    { id: "4", name: "Accuracy certificate for existing meters", selected: false },
    { id: "5", name: "PDF of the certified survey plans and data table", selected: false },
    { id: "6", name: "Other", selected: false }
 ];

 export const uploadOptionsForUpdateRatingTable = [{ id: "4", name: "PDF of the certified survey plans and data table", selected: false }];

 export const uploadOptionsForAHDValidation = [
    { id: "3", name: "CSV file of all features detailed in Table 2", selected: false },
    { id: "4", name: "PDF file of all features detailed in Table 3 ", selected: false },
    { id: "5", name: "Photographs of the survey benchmarks", selected: false },      
 ];

 export const replacingLidReasonsRequireRef = ["Transmission failure", "LID damaged beyond repair"];