import React, { Fragment } from 'react';
//import './global';

import axios from 'axios';
import Select from 'react-select';

import $ from 'jquery';
import "react-datepicker/dist/react-datepicker.css";
import RegistrationFormBase from './base/RegistrationFormBase'
import RatingTableSeacherLauncher from '../components/ratingTables/RatingTableSeacherLauncher';
import LIDInfoPage from '../components/meterRegistration/LIDInfoPage';
import {menuItems} from './base/BasePageLayout';
import {ReactSelect,InputText} from '../components/tools/BootstrapControls'

class FPHRegistrationForm extends RegistrationFormBase {


    storageMeasurementMethods = [];
    storageDepthSensorTypes = [];

    constructor(props){
        super(props);
        this.state.meterRegistration.formType = "FPH";
    }

    getMenuItem =() => menuItems.floodplainHarvesting();

    async componentDidMount(){
        await this.renderStorageMeasurementMethods();
        await this.renderStorageDepthSensorTypes();
        super.componentDidMount();
        
    }

    

    renderStorageMeasurementMethods = async () => {
        this.storageMeasurementMethods = await this.registrationApiHelpers.getStorageMeasurementMethodOptions();
        
    }

    renderStorageDepthSensorTypes = async () => {
        this.storageDepthSensorTypes = await this.registrationApiHelpers.getStorageDepthSensorTypeOptions();
    }
    
    renderInfoPage = () => {
        return <LIDInfoPage acknowledgeClick={this.infoPageAcknowledgeClickHandler} />;
    }


    ratingTableSelectedHandler = (selectedRatingTable) => {
        this.state.meterRegistration.ratingTableId = selectedRatingTable.id;
        this.state.selectedRatingTable = selectedRatingTable;
        this.setState(Object.assign(this.state.displayControl, { pageStage : this.pageStages.mainForm() }));
    }

    validateFieldsOnSubmit = () => {
        if(this.state.meterRegistration.storageMeasurementMethodId == 'STORAGE_MESUREMENT') {

            var currentContext = this;
            this.validateFieldIfNoPreviousError("storageDepthSensorTypeId",()=>{
                if (!currentContext.state.meterRegistration.storageDepthSensorTypeId || currentContext.state.meterRegistration.storageDepthSensorTypeId=="") return 'Please select the storage sensor type of the storage for new storage device details';
            });
            if(this.state.meterRegistration.installationType == 4 ){
                this.validateFieldIfNoPreviousError("replaceStorageDepthSensorTypeId",()=>{
                    if (!this.state.meterRegistration.replaceStorageDepthSensorTypeId || this.state.meterRegistration.replaceStorageDepthSensorTypeId=="") return 'Please select the storage sensor type of the storage for old storage device details';
                });
            }
            
            if(this.state.meterRegistration.formStatus==1) {
                this.validateFieldIfNoPreviousError("depthSesorLevel",()=>{

                    if(this.state.meterRegistration.depthSesorLevel == null || $.trim(this.state.meterRegistration.depthSesorLevel) == '') return "Please provide level of the depth sensor (mAHD)";
                    var regexp = /^\d+(\.\d{1,3})?$/;
                    if (!regexp.test($.trim(this.state.meterRegistration.depthSesorLevel))) return "Please provide a valid number (with up to 3 decimal places) for level of the depth sensor (mAHD)";
                });
            }
        }

        if(this.state.meterRegistration.storageMeasurementMethodId == 'POINT_OF_INTAKE') {
            this.validateFieldIfNoPreviousError("workApproval",()=>{
                if(!this.state.meterRegistration.workApproval || $.trim(this.state.meterRegistration.workApproval)=='') return "This field is required";
             });
             this.validateFieldIfNoPreviousError("esid",()=>{
                if(!this.state.meterRegistration.esid || $.trim(this.state.meterRegistration.esid)=='') return "This field is required";
             });
        }
    }
  
    preprocessOnSubmit = () => {

    }
  
    validateFieldsOnSave = () => {
        if(this.state.meterRegistration.storageMeasurementMethodId == 'POINT_OF_INTAKE') {
            this.validateFieldIfNoPreviousError("workApproval",()=>{
                if(!this.state.meterRegistration.workApproval || $.trim(this.state.meterRegistration.workApproval)=='') return "This field is required";
             });
             this.validateFieldIfNoPreviousError("esid",()=>{
                if(!this.state.meterRegistration.esid || $.trim(this.state.meterRegistration.esid)=='') return "This field is required";
             });
        }
    }
  
    preprocessOnSave = () => {

    }
 
    getPageName = () => {
        return "FPHRegistration";
    }

    retrieveSpecificSelectOptions = (meterRegistration) => {
        var currentContext = this;

        this.renderLids();

        if(meterRegistration.storageMeasurementMethodId == "STORAGE_MESUREMENT") {
            axios.post('/ratingTables/getRatingTableById', JSON.stringify({id : meterRegistration.ratingTableId}), this.config).then(function (response) {
                currentContext.setState(Object.assign(currentContext.state, { selectedRatingTable: response.data.data }));
            })
        
            axios.get('metertype/meterTypesForStorageMeasurement', this.config).then(response => {
                var result = [];
                response.data.data.map(row => {
                    result.push({ label: row.name, value: row.id });
                });
                this.setState(Object.assign(this, {manufacturerModels : result}));
             });

             /*

             axios.get('lid/getFPHStorageMeasurementLids', this.config).then(response => {
                var result = [];
                response.data.data.sort((a,b) => a.displayText > b.displayText?1:-1 ).map(row => {
                    result.push({ label: row.displayText, value: row.id, name: row.name });
                });          
                this.setState(Object.assign(this, {lids : result}));
             });

             */
        } else if(meterRegistration.storageMeasurementMethodId == "POINT_OF_INTAKE") {

            axios.get('metertype/meterTypesForFPHPointOfIntake', this.config).then(response => {
                var result = [];
                response.data.data.map(row => {
                    result.push({ label: row.name, value: row.id });
                });
                this.setState(Object.assign(this, {manufacturerModels : result}));
            });

            /*

            axios.get('lid/getFPHPointOfIntakeLids', this.config).then(response => {
                var result = [];
                response.data.data.sort((a,b) => a.displayText > b.displayText?1:-1 ).map(row => {
                    result.push({ label: row.displayText, value: row.id });
                });          
                this.setState(Object.assign(this, {lids : result}));
             });

             */

        }
    }

    infoPageAcknowledgeClickHandler =()=>{
        window.scrollTo(0, 0);
        this.setState(Object.assign(this.state.displayControl, { pageStage: this.pageStages.mainForm()}));
     }
    //========= event handeler ============================

    onChangeOfStorageMeasurementMethod = (e) => {
        //this.renderLids();

        if(e.value && e.value=="STORAGE_MESUREMENT"){
            this.setState(Object.assign(this.state.meterRegistration, { 
                replaceMeterNumber : null,
                latitude: null,
                longitude: null,
                serialNumber: null,
                meterSize: null,
                meterUnitOfMeasures: null,
                meterFlowUnits: null,
                meterType: null,
                replaceManufacturerAndModel: null,
                replaceOtherManufacturerAndModelText: null,
                manufacturerAndModel: null,
                otherManufacturerAndModelText: null,
                lid: null
             }),this.renderLids);
             axios.get('metertype/meterTypesForStorageMeasurement', this.config).then(response => {
                var result = [];
                for (var i = 0; i < response.data.data.length; i++) {
                    result.push({ label: response.data.data[i].name, value: response.data.data[i].id });
                }
                this.setState(Object.assign(this, {manufacturerModels : result}));
             });
             /*
             axios.get('lid/getFPHStorageMeasurementLids', this.config).then(response => {
                let lidData = response.data.data.sort((a,b) => a.displayText > b.displayText?1:-1 ) ;
                var result = [];
                lidData.map(row => {
                    result.push({ label: row.displayText, value: row.id });
                });          
                this.setState(Object.assign(this, {lids : result}));
             });
             */

        } else if(e.value && e.value=="POINT_OF_INTAKE"){

            this.setState(Object.assign(this.state, { isDisplayInfoPage: true }));

            this.setState(Object.assign(this.state.meterRegistration, { 
                storageDepthSensorTypeId : null,
                replaceStorageDepthSensorTypeId : null,
                replaceManufacturerAndModel: null,
                replaceOtherManufacturerAndModelText: null,
                manufacturerAndModel: null,
                otherManufacturerAndModelText: null,
                lid: null
             }),this.renderLids);
             axios.get('metertype/meterTypesForFPHPointOfIntake', this.config).then(response => {
                var result = [];
                for (var i = 0; i < response.data.data.length; i++) {
                    result.push({ label: response.data.data[i].name, value: response.data.data[i].id });
                }
                this.setState(Object.assign(this, {manufacturerModels : result}));
             });
             /*
             axios.get('lid/getFPHPointOfIntakeLids', this.config).then(response => {
                let lidData = response.data.data.sort((a,b) => a.displayText > b.displayText?1:-1 ) ;
                var result = [];
                lidData.map(row => {
                    result.push({ label: row.displayText, value: row.id });
                });          
                this.setState(Object.assign(this, {lids : result}));
             });
             */
        } else {
            this.setState(Object.assign(this.state.meterRegistration, { 
                storageDepthSensorTypeId : null,
                replaceStorageDepthSensorTypeId : null,
                replaceMeterSize: null,
                replaceSerialNumber: null,
                replaceManufacturerAndModel: null,
                replaceOtherManufacturerAndModelText: null,
                replaceMeterDescription: null,
                replaceMeterNumber : null,
                manufacturerAndModel: null,
                otherManufacturerAndModelText: null,
                latitude: null,
                longitude: null,
                serialNumber: null,
                meterDescription: null,
                meterSize: null,
                meterUnitOfMeasures: null,
                meterFlowUnits: null,
                meterType: null,
                lid: null
             }));
        }
        this.setState(Object.assign(this.state.meterRegistration, { storageMeasurementMethodId: e.value }));
    }


    onChangeOfStorageDepthSensorType = (e) => {
        this.setState(Object.assign(this.state.meterRegistration, { storageDepthSensorTypeId: e.value }));
    }

    onChangeOfReplaceStorageDepthSensorType = (e) => {
        this.setState(Object.assign(this.state.meterRegistration, { replaceStorageDepthSensorTypeId: e.value }));
    }

    

    onChangeOfPrimaryHolderFirstname = (e) => {
        this.setState(Object.assign(this.state.meterRegistration, { primaryHolder: e.target.value }));
     }
  
     onChangeOfPrimaryHolderSurname = (e) => {
        this.setState(Object.assign(this.state.meterRegistration, { holderSurname: e.target.value }));
     }

     onChangeDepthSesorLevel = (e) => {
        this.setState(Object.assign(this.state.meterRegistration, { depthSesorLevel: e.target.value }));
     }

    displayMeterDetails = () => {
        return this.state.meterRegistration.storageMeasurementMethodId != null;
    }

    displayMeterType = () => {
        return this.state.meterRegistration.storageMeasurementMethodId == 'POINT_OF_INTAKE';
    }
    
    displayMeterSize = () => {
        return this.state.meterRegistration.storageMeasurementMethodId == 'POINT_OF_INTAKE';
    }

    displayMeterSerialNumber = () => {
        return this.state.meterRegistration.storageMeasurementMethodId == 'POINT_OF_INTAKE';
    }

    displayMeterUnitOfMeasures = () => {
        return this.state.meterRegistration.storageMeasurementMethodId == 'POINT_OF_INTAKE';
    }

    displayMeterUnits = () => {
        return this.state.meterRegistration.storageMeasurementMethodId == 'POINT_OF_INTAKE';
    }

    displayScallingFactor = () => {
        return this.state.meterRegistration.storageMeasurementMethodId == 'POINT_OF_INTAKE';
    }

    displayStorageDepthSensorTypeOptions = () => {
        return this.state.meterRegistration.storageMeasurementMethodId == 'STORAGE_MESUREMENT';
    }

    displayOldMeterNumber = () => {
        return false;
    }

    displayInfoPage = ()=> {
        return this.state.isDisplayInfoPage;
    }

    displayDepthSesorLevel = () => {
        return this.state.meterRegistration.storageMeasurementMethodId == 'STORAGE_MESUREMENT' && this.state.meterRegistration.formStatus>=1;
    }

    //=========== return text functions =========================
    getTitleForApprovalOrStorageSession =() => {
        return "Storage details";
    }

    /*
    getInstallationTypeNameText =(installtionType) => {
        return installtionType.nameForFPH
    }
    */
    

     getTitleForOldMeterDetails = () =>{
        if(this.state.meterRegistration.storageMeasurementMethodId == 'POINT_OF_INTAKE') {
            return "Old meter details";
        } else {
            return "Old storage device details";
        }
     }

     getTitleForNewMeterDetails = () => {
        if(this.state.meterRegistration.storageMeasurementMethodId == 'POINT_OF_INTAKE') {
            return "New meter details";
        } else {
            return "New storage device details";
        }
     }

     getTitleForExistingMeterDetails = () => {
        if(this.state.meterRegistration.storageMeasurementMethodId == 'POINT_OF_INTAKE') {
            return "Existing meter details";
        } else {
            return "Existing storage device details";
        }
     }

     getRatingTable =() =>{
        return this.state.selectedRatingTable;
    }
    //=========== start render functions =======================================================================================================

    renderDepthSesorLevel = () => {
        return <div className='row mb-3'>
            <div className='col-sm-6'>
                <InputText 
                    label={<>What is the level of the depth sensor(mAHD)? <span className='text-danger'>*</span></>}
                    error={this.state.fieldErrors.depthSesorLevel}
                    id="depthSesorLevel"
                    onChange={this.onChangeDepthSesorLevel}
                    value={this.state.meterRegistration.depthSesorLevel} 
                    readOnly={this.state.meterRegistration.formStatus >= 2 }
                    placeholder="level of the depth sensor(mAHD)"
                />
            </div>
        </div>
    }

    renderStorageDepthSensorTypeOptions = (isReplace) => {
        return  <div className='row mb-3'>
            <div className='col-sm-6'>
                {
                    isReplace && 
                    <ReactSelect
                        label={<>
                        Storage sensor type of the storage <span className='text-danger'>*</span>
                        </>}
                        onChange={this.onChangeOfReplaceStorageDepthSensorType}
                        value={this.state.meterRegistration.replaceStorageDepthSensorTypeId} 
                        disabled={this.state.meterRegistration.formStatus >= 1 } 
                        error={this.state.fieldErrors.replaceStorageDepthSensorTypeId}
                        options={this.storageDepthSensorTypes}
                    />
                }
                {
                    !isReplace &&
                    <ReactSelect
                        label={<>
                        Storage sensor type of the storage <span className='text-danger'>*</span>
                        </>}
                        onChange={this.onChangeOfStorageDepthSensorType}
                        value={this.state.meterRegistration.storageDepthSensorTypeId} 
                        disabled={this.state.meterRegistration.formStatus >= 1 } 
                        error={this.state.fieldErrors.storageDepthSensorTypeId}
                        options={this.storageDepthSensorTypes}
                    />
                }
            </div>
        </div>;
    }
   
    renderStorageManagementMethodOptions = () => {
        return  <div className="row mb-3">
            <div className='col-sm-4'>
                <InputText 
                    label="Storage measurement method"
                    id="storageMeasurementMethodId"
                    value={this.storageMeasurementMethods.filter(option => option.value === this.state.meterRegistration.storageMeasurementMethodId)[0].label}
                    readOnly={true}
                />
            </div>
        </div>;
    }

   renderApprovalOrStorageSession = () => {
        if(this.state.meterRegistration.storageMeasurementMethodId == 'POINT_OF_INTAKE') {
            return this.renderApprovalSession();
        } else {
            return  <>
                {this.renderHolderNameSession()}
                <div className='row mb-3'>
                    <div className="col-sm-4">
                        <label for="storageIdentifyingNumber" className='form-label'>Storage identifying number <span className='text-danger'>*</span></label>
                        <input type="text" id="storageIdentifyingNumber" className='form-control' required value={this.state.selectedRatingTable == null ? "" : this.state.selectedRatingTable.ratingTableName} readOnly={true}></input>
                    </div>
                </div>
            </>;
        }
   }

   

   renderSearchForm = () =>{
      return <>
            <h5>What is the measurement method for the storage?<span className='text-danger'>*</span></h5>
            <div className='row mb-3'>
                <div className="col-sm-6">
                    <Select 
                        id="storageMeasurementMethod" 
                        onChange={this.onChangeOfStorageMeasurementMethod} 
                        options={this.storageMeasurementMethods} 
                        value={this.storageMeasurementMethods.filter(option => option.value === this.state.meterRegistration.storageMeasurementMethodId)}
                        isDisabled={this.state.meterRegistration.storageMeasurementMethodId && this.state.meterRegistration.storageMeasurementMethodId!=''} 
                        required
                    />
                </div>
            </div>
            {/*this.state.meterRegistration.storageMeasurementMethodId == 'POINT_OF_INTAKE' && this.renderWorkApprovalSearchForm()*/}

            {this.state.meterRegistration.storageMeasurementMethodId == 'POINT_OF_INTAKE' && <div className='alert alert-warning my-3'>
            Point of Intake meter registration is temporarily unavailable. Please contact WaterNSW via <a href="mailto:dqp.enquiries@waternsw.com.au">dqp.enquiries@waternsw.com.au</a> for more information.
            </div>}

            {this.state.meterRegistration.storageMeasurementMethodId == 'STORAGE_MESUREMENT' && <div>
                <RatingTableSeacherLauncher onRatingTableSelected = {this.ratingTableSelectedHandler} />      
            </div>}
        </>;
   }


   renderPageTitle= () =>{
       return <h3 className='mb-3'>Site Telemetry Registration</h3>;
   }
}

export default FPHRegistrationForm;