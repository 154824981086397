import React from 'react';
import { Field, reduxForm, formValueSelector, change, SubmissionError } from 'redux-form';
import { connect } from 'react-redux';
import { renderTextField, renderDropdown, reactSelect } from '../design/MeterDesignCertificate.FIelds'
import { validate } from './TransitionalReportingApprovalDetails.Validation'
import { saveDraftAction, clickNextAction, scrollTofocusError } from "../common/MeterFormValidations"
import { getMeterNumbers } from '../common/MeterCertificateCommon'
import EsidSelect from '../../common/EsidSelect'
import MeterSelectForEsid from '../../common/MeterSelectForEsid'
import LotSelectForEsid from '../../common/LotSelectForEsid'
import { InputTextForRedux, ShowErrorForRedux, SelectForRedux, CheckboxForRedux } from '../../tools/BootstrapControls'

const INACTIVE_SID = "This ESID is listed as inactive. If you believe this is incorrect, please inform the customer to contact their Water NSW Customer Field Officer (CFO) to update the Extraction Site status.";
const renderError = ({ input, meta, ...props }) => (
   (meta.error && meta.error != '') ? <span {...props} className='text-danger'>{meta.error}</span> : null
)

let TransitionalReportingPartB = props => {
   const {
      handleSubmit, masterDataValues, appWorkInfo, meterEquipInfo, previousPage, waterAccessLicenceNoExternalList, loading, hasError
   } = props
   const disableFields = (parseInt(meterEquipInfo.formStatus) == 2) || props.disableEditing || loading ? true : false;
   const disableWaterFields = (appWorkInfo.waterSourceName && appWorkInfo.waterSourceName.length > 0) || props.disableEditing ? true : false;
   if (props.showSpinner == undefined || props.showSpinner == null) {
      props.dispatch(change(props.form, 'showSpinnerB', 0))
   }

   const lotChangeHandler = (lot) => {
      props.dispatch(change(props.form, 'approvedWorkDetails.lotDp',
         (lot.lotNumber ? lot.lotNumber : "")
         + "/"
         + (lot.sectionNumber ? lot.sectionNumber : "")
         + "/"
         + (lot.planNumber ? lot.planNumber : "")
         + " "
         + (lot.planType ? lot.planType : "")
      ));
   }

   const removeWaterInfoDetails = (e) => {
      if (e.length == 0) {
         props.dispatch(change(props.form, 'approvedWorkDetails.correctWaterSourceDetails', ""));
      }
   }

   const correctWaterSourceOptions =
      [
         { id: "1", name: "The water source information is incorrect.", selected: false }
      ];

   const logTextChangeHandler = (displayText) => {
      props.dispatch(change(props.form, 'approvedWorkDetails.lotDp', displayText));
   }

   const esidChangeHandler = async (esid) => {
      //alert(appWorkInfo.esidExternalList[e.currentTarget.value].name)
      var esidValue = esid.esid;
      var latitude = esid.esLat;
      var longitude = esid.esLong;

      //getMeterNumbers(appWorkInfo.waterSupplyWorkApprovalNo,esidValue)
      props.dispatch(change(props.form, 'approvedWorkDetails.esid', esidValue));//appWorkInfo.esidExternalList[e.currentTarget.value].name));

      props.dispatch(change(props.form, 'meteringEquipmentInformation.longitude', longitude));
      props.dispatch(change(props.form, 'meteringEquipmentInformation.latitude', latitude));

      // start cleaning up meter data
      props.dispatch(change(props.form, 'approvedWorkDetails.waterSourceName', esid.waterSourceName));
      props.dispatch(change(props.form, 'approvedWorkDetails.waterSharingPlanName', esid.waterSharingPlanDesc));
      props.dispatch(change(props.form, 'approvedWorkDetails.waterSourceType', esid.waterType));
      props.dispatch(change(props.form, 'approvedWorkDetails.meterNumber', null));
      props.dispatch(change(props.form, 'meteringEquipmentInformation.meterTypeModelId', null));
      props.dispatch(change(props.form, 'meteringEquipmentInformation.meterTypeModelId', null));
      props.dispatch(change(props.form, 'meteringEquipmentInformation.meterTypeModel', null));
      props.dispatch(change(props.form, 'meteringEquipmentInformation.meterDescription', null));
      props.dispatch(change(props.form, 'meteringEquipmentInformation.meterSize', null));
      props.dispatch(change(props.form, 'meteringEquipmentInformation.meterSerialNo', null));
      props.dispatch(change(props.form, 'approvedWorkDetails.meterNumberGUID', null));
   }

   const meterChangeHandler = (meter) => {
      props.dispatch(change(props.form, 'approvedWorkDetails.meterNumber', meter.METER_NO));

      var meterNumber = meter.METER_NO;
      var meterNumberGUID = meter.GUID;
      var meterSize = meter.SIZE_IN_MM;
      var meterSerialNumber = meter.SERIAL_NUMBER;
      var meterCategoryDesc = meter.METER_CATEGORY_DESC;
      var meterTypeDesc = meter.METER_TYPE_DESCRIPTION;


      //var meterTypeModel = meterCategoryDesc + ' ' + meterTypeDesc;      
      var meterTypeModel = "";
      if (meterCategoryDesc != null && meterCategoryDesc != "") {
         meterTypeModel = meterCategoryDesc;
      }

      var meterTypeModelId = masterDataValues.meterModelTypes.filter(function (option) { if (option.name === meterTypeModel) return option });
      //console.log("meter modelid" + meterTypeModelId)
      if (meterTypeModelId != undefined && meterTypeModelId != null && meterTypeModelId.length > 0) {
         props.dispatch(change(props.form, 'meteringEquipmentInformation.meterTypeModelId', meterTypeModelId[0].id));
      } else {
         meterTypeModelId = masterDataValues.MeterModelTypeOtherId;
         props.dispatch(change(props.form, 'meteringEquipmentInformation.meterTypeModelId', meterTypeModelId));
         props.dispatch(change(props.form, 'meteringEquipmentInformation.meterTypeModel', meterTypeModel));
      }

      if (meterTypeDesc != null && meterTypeDesc != "") {
         props.dispatch(change(props.form, 'meteringEquipmentInformation.meterDescription', meterTypeDesc));
      }

      props.dispatch(change(props.form, 'meteringEquipmentInformation.meterSize', meterSize));
      props.dispatch(change(props.form, 'meteringEquipmentInformation.meterSerialNo', meterSerialNumber));
      props.dispatch(change(props.form, 'approvedWorkDetails.meterNumberGUID', meterNumberGUID));
      props.dispatch(change(props.form, 'approvedWorkDetails.meterNumber', meterNumber));
   }

   if (parseInt(appWorkInfo.waterSourceTypeId) == 0 && masterDataValues.waterSourceTypes.length > 0) {

      for (var i = 0; i < masterDataValues.waterSourceTypes.length; i++) {
         if (masterDataValues.waterSourceTypes[i].code == undefined || masterDataValues.waterSourceTypes[i].code == null)
            continue;
         //alert(appWorkInfo.waterSourceType);
         if (appWorkInfo.waterSourceType == masterDataValues.waterSourceTypes[i].code) {
            //alert(masterDataValues.waterSourceTypes[i].id)
            props.dispatch(change(props.form, 'approvedWorkDetails.waterSourceTypeId', masterDataValues.waterSourceTypes[i].id));
            break;
         }
      }
   }


   /* if( parseInt(appWorkInfo.waterSourceTypeId) == 0 && masterDataValues.waterSourceTypes.length > 0 )
      props.dispatch(change(props.form,'approvedWorkDetails.waterSourceTypeId', masterDataValues.waterSourceTypes[0].id)) */

   return (
      <form onSubmit={handleSubmit} >
         <h5 className='mb-3'>Details of Approved Works</h5>

         <div className='row mb-3'>
            <div className='col-sm-7'>
               <Field
                  name="approvedWorkDetails.propertyName"
                  label="Property name"
                  label={<>Property name <span className='text-danger'>*</span></>}
                  component={InputTextForRedux}
                  props={{ disabled: disableFields }}
               />
            </div>
         </div>

         <div className='row mb-3'>
            <div className='col-sm-7'>
               <Field
                  name="approvedWorkDetails.waterSupplyWorkApprovalNo"
                  label="Work approval number"
                  component={InputTextForRedux}
                  props={{ disabled: true }}
               />
            </div>
         </div>
         {
            (appWorkInfo.approvedByCustomer == 0) || (appWorkInfo.approvedByCustomer == 1 && waterAccessLicenceNoExternalList.length == 0) &&
            <div className='row mb-3'>
               <div className='col-sm-7'>
                  <Field
                     name="approvedWorkDetails.waterAccessLicenceNo"
                     label="Water access license number(s)"
                     component={InputTextForRedux}
                     props={{ disabled: disableFields }}
                  />
               </div>
            </div>
         }
         {
            appWorkInfo.approvedByCustomer == 1 && waterAccessLicenceNoExternalList.length > 0 &&
            <div className='row mb-3'>
               <div className='col-sm-7'>
                  <Field
                     name="approvedWorkDetails.waterAccessLicenceNo"
                     label="Water access license number(s)"
                     component={reactSelect}
                     options={waterAccessLicenceNoExternalList}
                     props={{ disabled: disableFields }}
                  />
               </div>
            </div>
         }

         <div class="row mb-3">
            <div className='col-sm-7'>
               {!disableFields && <>
                  <label className='form-label'>Unique extraction site identifier (ESID) <span className='text-danger'>* </span></label>
                  <EsidSelect approvalNumber={appWorkInfo.waterSupplyWorkApprovalNo} onEsidChange={esidChangeHandler} defaultEsid={appWorkInfo.esid} />
                  <Field name='approvedWorkDetails.esid' component={ShowErrorForRedux} />
               </>}
               {disableFields && <Field
                  name="approvedWorkDetails.esid"
                  label={<>Unique extraction site identifier (ESID) <span className='text-danger'>*</span></>}
                  placeholder="Click here to select the extraction site"
                  autocomplete="off"
                  component={InputTextForRedux}
                  props={{ disabled: true, mandatory: true, autocomplete: "off" }}
               />}
            </div>
         </div>

         <div class="row mb-3">
            <div className='col-sm-7'>
               {!disableFields && <>
                  <label className='form-label'>Lot DP <span className='text-danger'>*</span></label>
                  <LotSelectForEsid
                     approvalNumber={appWorkInfo.waterSupplyWorkApprovalNo}
                     esid={appWorkInfo.esid}
                     onLotChange={lotChangeHandler}
                     onChangeOfDisplayText={logTextChangeHandler}
                     displayText={appWorkInfo.lotDp}
                  />
                  <Field name='approvedWorkDetails.lotDp' component={ShowErrorForRedux} />
               </>}
               {disableFields && <Field
                  name="approvedWorkDetails.lotDp"
                  label={<>Lot DP <span className='text-danger'>*</span></>}
                  placeholder=""
                  autocomplete="off"
                  component={InputTextForRedux}
                  props={{ disabled: true, mandatory: true, autocomplete: "off" }}
               />}
            </div>
         </div>

         <div class="row mb-3">
            <div className='col-sm-7'>
               {
                  !disableFields
                  &&
                  <>
                     <label className='form-label'>Please select the meter <span className='text-danger'>* </span></label>
                     <MeterSelectForEsid approvalNumber={appWorkInfo.waterSupplyWorkApprovalNo} esid={appWorkInfo.esid} onMeterChange={meterChangeHandler} defaultMeterNo={appWorkInfo.meterNumber} />
                     <Field name='approvedWorkDetails.meterNumber' component={ShowErrorForRedux} />
                  </>
               }
               {
                  disableFields
                  &&
                  <div>
                     <Field
                        name="approvedWorkDetails.meterNumber"
                        label="Meter number"
                        component={InputTextForRedux}
                        props={{ disabled: true }}
                     />
                  </div>

               }
            </div>
         </div>

         <div class="row mb-3">
            <div className='col-sm-7'>
               <Field
                  name="approvedWorkDetails.waterSourceName"
                  label="Name of water source"
                  component={InputTextForRedux}
                  props={{ disabled: disableWaterFields }}
               />
            </div>
         </div>

         <div class="row mb-3">
            <div className='col-sm-7'>
               <Field
                  name="approvedWorkDetails.waterSourceTypeId"
                  component={SelectForRedux}
                  options={masterDataValues.waterSourceTypes}
                  label="Type of water source"
                  props={{ disabled: disableWaterFields }}
               />
            </div>
         </div>
         <div class="row mb-3">
            <div className='col-sm-6'>
               <Field
                  name="approvedWorkDetails.correctWaterInfoCheck"
                  component={CheckboxForRedux}
                  options={correctWaterSourceOptions}
                  onChange={removeWaterInfoDetails}
                  props={{ disabled: disableFields, mandatory: false }}
               />
            </div>
         </div>

         {(appWorkInfo.correctWaterInfoCheck == 1) &&
            <div class="row mb-3">
               <div className='col-sm-6'>
                  <label>Please provide detail on incorrect water source information</label>
                  <Field
                     name="approvedWorkDetails.correctWaterSourceDetails"
                     component={InputTextForRedux}
                     props={{ disabled: disableFields, mandatory: false }}
                  />
               </div>
            </div>
         }

         {hasError && <div className='alert alert-danger my-3'>
            Please fix all errors before you continue
         </div>}

         <div className="text-end">

            {(parseInt(meterEquipInfo.formStatus) == 1) && (!props.disableEditing) && <>
               {meterEquipInfo.id != 0 &&
                  <button type="button" className="btn btn-danger me-3" disabled={loading}
                     data-bs-toggle="modal" data-bs-target="#deleteDraftModal">
                     Delete Draft
                  </button>
               }
               <button type="button" className="btn btn-primary" disabled={loading}

                  onClick={() => { saveDraftAction(props); }}>
                  Save Draft
               </button>

               <div className='vr mx-3'></div>
            </>}

            <button type="button" className="btn btn-warning me-3" disabled={loading} onClick={() => { previousPage(meterEquipInfo.formStatus) }}>Previous</button>
            <button type="button" className="btn btn-success" disabled={loading} onClick={() => { clickNextAction(props); }}>Next</button>
         </div>
      </form>
   )
}


TransitionalReportingPartB = reduxForm({
   destroyOnUnmount: false,
   forceUnregisterOnUnmount: true,
   validate,
   onSubmitFail: (errors, dispatch) => {
      dispatch(change('certificate', 'hasError', true));
   },
   onSubmitSuccess: (result, dispatch) => {
      dispatch(change('certificate', 'hasError', false));
      window.scrollTo(0, 0);
   }
})(TransitionalReportingPartB)

TransitionalReportingPartB = connect(
   (state, props) => {
      const selectorCert = formValueSelector('certificate')
      const hasError = selectorCert(state, 'hasError')

      const selector = formValueSelector(props.form);
      const masterDataValues = selector(state, 'mdata');
      const waterAccessLicenceNoExternalList = selector(state, 'waterAccessLicenceNoExternalList');
      const appWorkInfo = selector(state, 'approvedWorkDetails');
      const meterEquipInfo = selector(state, 'meteringEquipmentInformation');
      const loading = selector(state, 'loading');
      return {
         masterDataValues,
         appWorkInfo,
         meterEquipInfo,
         form: props.form,
         waterAccessLicenceNoExternalList,
         loading,
         hasError
      }
   }
)(TransitionalReportingPartB)

export default TransitionalReportingPartB;