import React from 'react';
import {reduxForm, formValueSelector, change} from 'redux-form';
import { connect } from 'react-redux'
import 'react-redux';
import 'redux';

import {   saveDraftAction } from "../common/MeterFormValidations"
import {dropdownDataFromReferenceData} from '../common/MeterCertificateCommon';

let CertificateConfirmation = props => {
    
    const {
        secondaryEquipmentValidationData,        
        authHolderInfo,        
        floodplainWorkDetails,
        masterDataValues

        , cancelConfrimSubmissionAndGoToPage
        , cancelConfrimSubmission

        , completeConfirmation

        , handleSubmit

        , loading
    } = props;

    var dqpdata = masterDataValues.userProfile[0];
    console.log("dqp"+JSON.stringify(dqpdata));
    dqpdata = {...dqpdata, "fullName": dqpdata.user.firstName + ' ' + dqpdata.user.lastName};
    props.dispatch(change(props.form, 'dqpdata', dqpdata));

    let certOptions = dropdownDataFromReferenceData(masterDataValues.refData,"certificateFor");
   console.log("certOptions "+ JSON.stringify(certOptions));
    

  return (
    <form onSubmit={handleSubmit}>
        <h5 className='mb-3'>Review</h5>

        <div class="alert alert-primary" role="alert">
            Please review the information below before completing your submission.
            <br/>           
            The form cannot be edited once submitted. Therefore, please confirm all configuration details are correct before submitting this form.
        </div>        

        <div className='card mb-3'>
            <div className='card-header fw-bold'>Duly qualified person (DQP) details</div>
            <div className='card-body'>
                <table class="table fs-90 mb-3">
                    <tbody>
                        <tr>
                            <th className='w-25'>
                            Registered surveyors’/engineer’s name
                            </th>
                            <td>{dqpdata.fullName}</td>
                        </tr>
                        <tr>
                            <th>
                        Registered surveyor's/engineer’s licence number
                            </th>
                            <td>{dqpdata.cmino}</td>
                        </tr>
                        <tr>
                            <th>
                            Business name
                            </th>
                            <td>{dqpdata.businessName}</td>
                        </tr>
                        
                        <tr>
                            <th>
                            ABN or ACN
                            </th>
                            <td>{dqpdata.abnOrAcn}</td>
                        </tr>
                        <tr>
                            <th>
                            Postal address
                            </th>
                            <td>{dqpdata.postalAddress.addressLine}</td>
                        </tr>
                        <tr>
                            <th>
                            Phone
                            </th>
                            <td>{dqpdata.phoneNumber}</td>
                        </tr>
                        <tr>
                            <th>
                            Email address
                            </th>
                            <td>{dqpdata.user.emailAddress}</td>
                        </tr>
                        {authHolderInfo.contactPersonName &&
                        <tr>
                            <th>
                            Contact Person Name
                            </th>
                            <td>{authHolderInfo.contactPersonName}</td>
                        </tr>
                        }
                        {authHolderInfo.contactPersonPhone &&
                        <tr>
                            <th>
                            Contact Person Phone
                            </th>
                            <td>{authHolderInfo.contactPersonPhone}</td>
                        </tr>
                        }
                        { authHolderInfo.contactPersonEmailAddress &&
                        <tr>
                            <th>
                            Contact Person Email address
                            </th>
                            <td>{authHolderInfo.contactPersonEmailAddress}</td>
                        </tr>
                        }
                    </tbody>
                </table>
            </div>
            <div className='card-footer'>
                <button type="button" className="btn btn-primary" 
                    onClick={() => { cancelConfrimSubmissionAndGoToPage(0); }}>
                        Edit
                </button>
            </div>
        </div>  

        <div className='card mb-3'>
            <div className='card-header fw-bold'>Details of Approved Works</div>
            <div className='card-body'>
                <table class="table fs-90 mb-3">
                    <tbody>
                        <tr>
                            <th className='w-25'>
                            Authority holder type
                            </th>
                            <td>{authHolderInfo.holderType}</td>
                        </tr>
                        {
                            authHolderInfo.holderType == "INDIVIDUAL" && 
                            <tr>
                                <th>
                                Authority holder firstname
                                </th>
                                <td>{authHolderInfo.firstName}</td>
                            </tr>
                        }
                        {
                            authHolderInfo.holderType == "INDIVIDUAL" && 
                            <tr>
                                <th>
                                Authority holder lastname
                                </th>
                                <td>{authHolderInfo.lastName}</td>
                            </tr>
                        }
                        {
                            authHolderInfo.holderType == "ORGANISATION" && 
                            <tr>
                                <th>
                                Organisation name
                                </th>
                                <td>{authHolderInfo.corporationNameOrAcn}</td>
                            </tr>
                        }
                        <tr>
                            <th>
                            Postal address
                            </th>
                            <td>{authHolderInfo.postalAddress}</td>
                        </tr>
                        <tr>
                            <th>
                            Phone
                            </th>
                            <td>{authHolderInfo.phone}</td>
                        </tr>
                        <tr>
                            <th>
                            Alternate phone
                            </th>
                            <td>{authHolderInfo.alternatePhone}</td>
                        </tr>
                        <tr>
                            <th>
                            Email address
                            </th>
                            <td>{authHolderInfo.emailAddress}</td>
                        </tr>
                        <tr>
                            <th>
                            Property name
                            </th>
                            <td>{floodplainWorkDetails.propertyName}</td>
                        </tr>
                        <tr>
                            <th>
                            Lot DP
                            </th>
                            <td>{floodplainWorkDetails.lotDp}</td>
                        </tr>
                    
                        {
                            (floodplainWorkDetails.waterAccessLicenceNo)  &&
                            <tr>
                                <th>
                                Water access license number(s)
                                </th>
                                <td>{floodplainWorkDetails.waterAccessLicenceNo}</td>
                            </tr>
                        }
                        {
                            (floodplainWorkDetails.ratingTable)  &&
                            <tr>
                                <th>
                                Storage identifying number
                                </th>
                                <td>{floodplainWorkDetails.ratingTable.ratingTableName}</td>
                            </tr>
                        }   
                    </tbody>
                </table>
            </div>
            <div className='card-footer'>
                <button type="button" className="btn btn-primary" 
                    onClick={() => { cancelConfrimSubmissionAndGoToPage(1); }}>
                        Edit
                </button>
            </div>
        </div>

        <div className='card mb-3'>
            <div className='card-header fw-bold'>Secondary (gauge board) metering equipment</div>
            <div className='card-body'>
                <table class="table fs-90 mb-3">
                    <tbody>
                        <tr>
                            <th className='w-25'>
                            This certificate is for
                            </th>
                            <td>
                                {certOptions.map((st) => {
                                    debugger;
                                    if(st.id == secondaryEquipmentValidationData.certFor) {
                                        return st.name;
                                    }
                                })}
                            </td>
                        </tr>   
                        <tr>
                            <th>
                            Meter type
                            </th>
                            <td>
                                {dropdownDataFromReferenceData(masterDataValues.refData,"meterType").map((st) => {
                                    debugger;
                                    if(st.id == secondaryEquipmentValidationData.meterType) {
                                        return st.name;
                                    }
                                })}
                            </td>
                        </tr>   
                        <tr>
                            <th>
                            Measurement Increment
                            </th>
                            <td>
                                {dropdownDataFromReferenceData(masterDataValues.refData,"measurementIncrement").map((st) => {
                                    debugger;
                                    if(st.id == secondaryEquipmentValidationData.measurementIncrement) {
                                        return st.name;
                                    }
                                })}
                            </td>
                        </tr>   
                        <tr>
                            <th>
                            Installed by
                            </th>
                            <td>
                                {dropdownDataFromReferenceData(masterDataValues.refData,"installedBy").map((st) => {
                                    debugger;
                                    if(st.id == secondaryEquipmentValidationData.installedBy) {
                                        return st.name;
                                    }
                                })}
                            </td>
                        </tr>   
                        <tr>
                            <th>
                            At what level is the Gauge board set?
                            </th>
                            <td>
                                {dropdownDataFromReferenceData(masterDataValues.refData,"gaugeOption").map((st) => {
                                    debugger;
                                    if(st.id == secondaryEquipmentValidationData.gaugeOption) {
                                        return st.name;
                                    }
                                })}
                            </td>
                        </tr>   
                        <tr>
                            <th>Measurement device location</th>
                            <td> 
                                Latitude: {secondaryEquipmentValidationData.latitude}<br/>
                                Longitude: {secondaryEquipmentValidationData.longitude}<br/>
                            </td>
                        </tr>
                        <tr>
                            <th>
                            Level of gauge height (0.00m) - measuring point (m AHD)
                            </th>
                            <td>{secondaryEquipmentValidationData.levelGaugeHeight}</td>
                        </tr>
                        <tr>
                            <th>
                            Height on gauge at storage floor (m) - (eg. 0.35)
                            </th>
                            <td>{secondaryEquipmentValidationData.gaugeHeight}</td>
                        </tr>
                        <tr>
                            <th>
                            Level of gauge height - measuring point (m AHD)
                            </th>
                            <td>{secondaryEquipmentValidationData.measuringPoint}</td>
                        </tr>
                        <tr>
                            <th>
                            Storage floor level adjacent to gauge (m AHD)
                            </th>
                            <td>{secondaryEquipmentValidationData.storageFloorLevel}</td>
                        </tr>     
                        {(secondaryEquipmentValidationData.sealNo) &&
                        <tr>
                            <th>Record the tamper-evident seal numbers</th>
                            <td>{secondaryEquipmentValidationData.sealNo.map(ele => ele.label).join()}</td>
                        </tr>  
                        }                  
                        <tr>
                            <th>
                            Comments
                            </th>
                            <td>
                                {secondaryEquipmentValidationData.comments}
                            </td>
                        </tr>
                    </tbody>    
                </table>
            </div>
            <div className='card-footer'>
                <button type="button" className="btn btn-primary" 
                    onClick={() => { cancelConfrimSubmissionAndGoToPage(2); }}>
                        Edit
                </button>
            </div>
        </div>
        
        <div class="text-end">
            
            {(parseInt(secondaryEquipmentValidationData.formStatus) == 1) && (!props.disableEditing) && <>
                {secondaryEquipmentValidationData.id!=0 &&   <button type="button"  className="btn btn-danger me-3" disabled={loading} 
                    data-bs-toggle="modal" data-bs-target="#deleteDraftModal">
                    Delete Draft
                </button> }
            
                <button type="button"  className="btn btn-primary" disabled={loading} 
                    onClick={() => { 
                        saveDraftAction(props); 
                    }}>
                    Save Draft
                </button>
                <div className='vr mx-3'></div>
            </>}

            <button type="button" className="btn btn-warning me-3" disabled={loading} 
               onClick={cancelConfrimSubmission}>
                  Previous
            </button>

            <button type="button" className="btn btn-success" disabled={loading} 
               onClick={completeConfirmation}>
                  {(parseInt(secondaryEquipmentValidationData.formStatus) == 1) ? "Confirm" : "Next"}
            </button>
        </div>       
</form>    
  )
}

CertificateConfirmation = reduxForm({
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate : ()=>{return {};}
 })(CertificateConfirmation)

CertificateConfirmation = connect(
  (state, props) => {
    const selector = formValueSelector(props.form);
    const secondaryEquipmentValidationData = selector(state, 'secondaryEquipmentValidationData')
    const authHolderInfo = selector(state, 'authorityHolderDetails');
    const dqpdata = selector(state, 'dqpdata');
    const floodplainWorkDetails = selector(state, 'floodplainWorkDetails');
    const appWorkInfo = selector(state, 'approvedWorkDetails');
    const documents = selector(state, 'documents')
    const meterNonComplianceReason = selector(state, 'meterNonComplianceReason')
    const meterNonCompliance = selector(state, 'meterNonCompliance')
    const registrationDoc = selector(state, 'registrationDoc')
    const userProfileValues = selector(state, 'mdata.userProfile')
    const masterDataValues = selector(state, 'mdata')

    const consentData = selector(state, 'consentData')
    const loading = selector(state, 'loading')

    const form = props.form;
    
    return {
        secondaryEquipmentValidationData,
        authHolderInfo,
        dqpdata,
        floodplainWorkDetails,        
        appWorkInfo,
        masterDataValues,
        documents,
        meterNonComplianceReason,
        meterNonCompliance,
        registrationDoc,
        userProfileValues,
        consentData,
        form,
        loading
    }
  }
)(CertificateConfirmation)

export default CertificateConfirmation;

