import React from 'react';
import { Field, reduxForm, formValueSelector, change } from 'redux-form';
import { connect } from 'react-redux'
import 'react-redux';
import 'redux';
import { certOptions,benchmark_options,formatDate ,meter_options,gps_options} from '../../../constants/MeterFormConstants'
import {  clickNextAction, saveDraftAction } from "../common/MeterFormValidations"
import {getNamesForOptions} from '../common/MeterCertificateCommon';

let AHDValidationCertificateConfirmation = props => {
    
    const {
        ahdData,        
        authHolderInfo,
        //dqpdata,
        floodplainWorkDetails,
        masterDataValues
        

        , cancelConfrimSubmissionAndGoToPage
        , cancelConfrimSubmission

        , completeConfirmation

        , loading
        , handleSubmit
    } = props;

    var dqpdata = masterDataValues.userProfile[0];
    console.log("dqp"+JSON.stringify(dqpdata));
    dqpdata = {...dqpdata, "fullName": dqpdata.user.firstName + ' ' + dqpdata.user.lastName};
    props.dispatch(change(props.form, 'dqpdata', dqpdata));

    const disableFields = (parseInt(props.ahdData.formStatus) ==2) || props.disableEditing || loading? true: false;

    

  return (
    <form className="vertical" onSubmit={handleSubmit}>
        <h5 className='mb-3'>Review</h5>
        <div class="alert alert-primary" role="alert">
            Please review the information below before completing your submission.
            <br/>           
            The form cannot be edited once submitted. Therefore, please confirm all configuration details are correct before submitting this form.
        </div>        

        <div className='card mb-3'>
            <div className='card-header'><strong>Registered surveyor’s/engineer’s details</strong></div>
            <div className='card-body'>
                <table class="table fs-90 mb-3">
                    <tbody>
                        <tr>
                            <th className='w-25'>
                            Registered surveyors’/engineer’s name
                            </th>
                            <td>{dqpdata.fullName}</td>
                        </tr>
                        <tr>
                            <th>
                        Registered surveyor's/engineer’s licence number
                            </th>
                            <td>{dqpdata.cmino}</td>
                        </tr>
                        <tr>
                            <th>
                            Business name
                            </th>
                            <td>{dqpdata.businessName}</td>
                        </tr>
                        
                        <tr>
                            <th>
                            ABN or ACN
                            </th>
                            <td>{dqpdata.abnOrAcn}</td>
                        </tr>
                        <tr>
                            <th>
                            Postal address
                            </th>
                            <td>{dqpdata.postalAddress.addressLine}</td>
                        </tr>
                        <tr>
                            <th>
                            Phone
                            </th>
                            <td>{dqpdata.phoneNumber}</td>
                        </tr>
                        <tr>
                            <th>
                            Email address
                            </th>
                            <td>{dqpdata.user.emailAddress}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    
        <div className='card mb-3'>
            <div className='card-header'><strong>Details of Approved Works</strong></div>
            <div className='card-body'>
                <table class="table fs-90 mb-3">
                    <tbody>
                        <tr>
                            <th className='w-25'>
                            Authority holder type
                            </th>
                            <td>{authHolderInfo.holderType}</td>
                        </tr>
                        {
                            authHolderInfo.holderType == "INDIVIDUAL" && 
                            <tr>
                                <th>
                                Authority holder firstname
                                </th>
                                <td>{authHolderInfo.firstName}</td>
                            </tr>
                        }
                        {
                            authHolderInfo.holderType == "INDIVIDUAL" && 
                            <tr>
                                <th>
                                Authority holder lastname
                                </th>
                                <td>{authHolderInfo.lastName}</td>
                            </tr>
                        }
                        {
                            authHolderInfo.holderType == "ORGANISATION" && 
                            <tr>
                                <th>
                                Organisation name
                                </th>
                                <td>{authHolderInfo.corporationNameOrAcn}</td>
                            </tr>
                        }
                        <tr>
                            <th>
                            Postal address
                            </th>
                            <td>{authHolderInfo.postalAddress}</td>
                        </tr>
                        <tr>
                            <th>
                            Phone
                            </th>
                            <td>{authHolderInfo.phone}</td>
                        </tr>
                        <tr>
                            <th>
                            Alternate phone
                            </th>
                            <td>{authHolderInfo.alternatePhone}</td>
                        </tr>
                        <tr>
                            <th>
                            Email address
                            </th>
                            <td>{authHolderInfo.emailAddress}</td>
                        </tr>
                        <tr>
                            <th>
                            Property name
                            </th>
                            <td>{floodplainWorkDetails.propertyName}</td>
                        </tr>
                        <tr>
                            <th>
                            Lot DP
                            </th>
                            <td>{floodplainWorkDetails.lotDp}</td>
                        </tr>
                    
                        {
                            (floodplainWorkDetails.waterAccessLicenceNo)  &&
                            <tr>
                                <th>
                                Water access license number(s)
                                </th>
                                <td>{floodplainWorkDetails.waterAccessLicenceNo}</td>
                            </tr>
                        }
                        {
                            (floodplainWorkDetails.ratingTable)  &&
                            <tr>
                                <th>
                                Storage identifying number
                                </th>
                                <td>{floodplainWorkDetails.ratingTable.ratingTableName}</td>
                            </tr>
                        }   
                    </tbody>
                </table>
            </div>
            <div className='card-footer'>
                <button type="button" className="btn btn-primary" 
                    onClick={() => { cancelConfrimSubmissionAndGoToPage(1); }}>
                        Edit
                </button>
            </div>
        </div>

        <div className='card mb-3'>
            <div className='card-header'><strong>Survey Benchmarks installation</strong></div>
            <div className='card-body'>
                <table class="table fs-90 mb-3">
                    <tbody>
                        <tr>
                            <th className='w-25'>
                            This validation certificate is for
                            </th>
                            <td>
                                {certOptions.map((st) => {
                                    debugger;
                                    if(st.id == ahdData.benchType) {
                                        return st.name;
                                    }
                                })}
                            </td>
                        </tr>
                      
                        {                           
                            <tr>
                                <th>
                                Date of validation
                                </th>                                
                                <td>{formatDate(ahdData.validationDate)}</td>
                            </tr>
                        }
                         
                         {     (ahdData.lastValidationDate &&               
                            <tr>
                                <th>
                                Date of last last validation
                                </th>
                                <td>{formatDate(ahdData.lastValidationDate)}</td>
                            </tr>)
                        }
                        {     (ahdData.damagedDate &&               
                            <tr>
                                <th>
                                Date of damage to benchmarks or meter
                                </th>
                                <td>{formatDate(ahdData.damagedDate)}</td>
                            </tr>)
                        }
                        <tr>
                            <th>
                            Benchmark 1
                            </th>
                            <td>
                                <div>
                                {benchmark_options.map((st) => {
                                    if(st.id == ahdData.bench1) {
                                        return st.name;
                                    }
                                })}
                                <br/>
                                Latitude: {ahdData.bench1Lat}<br/>
                                Longitude: {ahdData.bench1Long}<br/>
                                Level(m AHD): {ahdData.bench1Level}<br/>
                                </div></td>
                        </tr>
                        <tr>
                            <th>
                            Benchmark 2
                            </th>
                            <td>
                                <div>
                                {benchmark_options.map((st) => {
                                    if(st.id == ahdData.bench2) {
                                        return st.name;
                                    }
                                })}
                                <br/>
                                Latitude: {ahdData.bench2Lat}<br/>
                                Longitude: {ahdData.bench2Long}<br/>
                                Level(m AHD): {ahdData.bench2Level}<br/>
                                </div></td>
                        </tr>
                        <tr>
                            <th>
                            Benchmark 3
                            </th>
                            <td>
                                <div>
                                {benchmark_options.map((st) => {
                                    if(st.id == ahdData.bench3) {
                                        return st.name;
                                    }
                                })}
                                <br/>
                                Latitude: {ahdData.bench3Lat}<br/>
                                Longitude: {ahdData.bench3Long}<br/>
                                Level(m AHD): {ahdData.bench3Level}<br/>
                                </div></td>
                        </tr>
                        <tr>
                            <th>
                            Storage Meter
                            </th>
                            <td>
                                <div>                                
                                Latitude: {ahdData.storageMeterLat}<br/>
                                Longitude: {ahdData.storageMeterLong}<br/>
                                Level(m AHD): {ahdData.storageMeterLevel}<br/>
                                </div></td>
                        </tr>
                        <tr>
                            <th>
                            Storage Centroid
                            </th>
                            <td>
                                <div>                                
                                Latitude: {ahdData.storageCentroidLat}<br/>
                                Longitude: {ahdData.storageCentroidLong}<br/>                         
                                </div></td>
                        </tr>
                        <tr>
                            <th>
                            Top of Storage Wall
                            </th>
                            <td>
                                <div>                                
                                Latitude: {ahdData.topStorageWallLat}<br/>
                                Longitude: {ahdData.topStorageWallLong}<br/>
                                Level(m AHD): {ahdData.topStorageWallLevel}<br/>
                                </div></td>
                        </tr>
                        <tr>
                            <th>
                            Lowest Point In Storage
                            </th>
                            <td>
                                <div> 
                                Latitude: {ahdData.lowestStoragePointLat}<br/>
                                Longitude: {ahdData.lowestStoragePointLong}<br/>
                                Level(m AHD): {ahdData.lowestStoragePointLevel}<br/>
                                </div></td>
                        </tr>
                        <tr>
                            <th>
                            Meter Type
                            </th>
                            <td>
                                {meter_options.map((st) => {
                                    if(st.id == ahdData.meterType) {
                                        return st.name;
                                    }
                                })}
                            </td>
                        </tr>
                        <tr>
                            <th>
                            All GPS surveys accuracy
                            </th>
                            <td>
                           {getNamesForOptions(ahdData.surveysAccuracy, gps_options)}
                             
                            </td>
                        </tr>
                        <tr>
                            <th>
                            Comments
                            </th>
                            <td>
                                {ahdData.benchComments}
                            </td>
                        </tr>
                    </tbody>    
                </table>
            </div>
            <div className='card-footer'>
                <button type="button" className="btn btn-primary" 
                    onClick={() => { cancelConfrimSubmissionAndGoToPage(2); }}>
                        Edit
                </button>
            </div>
        </div>
        
        <div class="text-end">
            {(parseInt(ahdData.formStatus) == 1)  && (!props.disableEditing) && <>
                {ahdData.id!=0 && <button type="button"  className="btn btn-danger me-3" disabled={loading} 
                    data-bs-toggle="modal" data-bs-target="#deleteDraftModal">
                    Delete Draft
                </button>}
                <button type="button"  className="btn btn-primary" disabled={loading} 
                    onClick={() => { 
                        saveDraftAction(props); 
                    }}>
                    Save Draft
                </button>
                <div className='vr mx-3'></div>
            </>}
            <button type="button" className="btn btn-warning me-3"  disabled={loading} 
               onClick={cancelConfrimSubmission}>
                  Previous
            </button>

            <button type="button" className="btn btn-success" disabled={loading} 
               onClick={completeConfirmation}>
                  {(parseInt(ahdData.formStatus) == 1) ? "Confirm" : "Next"}
            </button>
        </div>       
</form>    
  )
}

AHDValidationCertificateConfirmation = reduxForm({
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate:()=>{return {}}
 })(AHDValidationCertificateConfirmation)

AHDValidationCertificateConfirmation = connect(
  (state, props) => {
    const selector = formValueSelector(props.form);
    const ahdData = selector(state, 'ahdData')
    const authHolderInfo = selector(state, 'authorityHolderDetails');
    const dqpdata = selector(state, 'dqpdata');
    const floodplainWorkDetails = selector(state, 'floodplainWorkDetails');
    const appWorkInfo = selector(state, 'approvedWorkDetails');
    const documents = selector(state, 'documents')
    const meterNonComplianceReason = selector(state, 'meterNonComplianceReason')
    const meterNonCompliance = selector(state, 'meterNonCompliance')
    const registrationDoc = selector(state, 'registrationDoc')
    const userProfileValues = selector(state, 'mdata.userProfile')
    const masterDataValues = selector(state, 'mdata')
    const loading = selector(state, 'loading')

    const consentData = selector(state, 'consentData')

    const form = props.form;
    
    return {
        ahdData,
        authHolderInfo,
        dqpdata,
        floodplainWorkDetails,
      
        
        appWorkInfo,
        masterDataValues,
        documents,
        meterNonComplianceReason,
        meterNonCompliance,
        registrationDoc,
        userProfileValues,
        consentData,
        form,
        loading
    }
  }
)(AHDValidationCertificateConfirmation)

export default AHDValidationCertificateConfirmation;

