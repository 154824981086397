import React from 'react';
import { Field, reduxForm, formValueSelector, change } from 'redux-form';
import { connect } from 'react-redux'
import 'react-redux';
import 'redux';
import "react-datepicker/dist/react-datepicker.css";
import { checkSerialNumber } from './MeterValidationCertificate.Reducers'
import { saveDraftAction, clickNextAction, scrollTofocusError } from "../common/MeterFormValidations"
import { validateCertificatePartD } from './MeterValidationCertificate.Validation';
import MeteringEquipmentInformationMain from './MeteringEquipmentInformationMain'
import {InputTextForRedux,CheckboxForRedux, TextAreaForRedux, DatePickerForRedux, RadiosForRedux }  from '../../tools/BootstrapControls'
import {isNumber, METER_READING_NOT_ACCURATE} from '../../../constants/MeterFormConstants';

const validate = validateCertificatePartD;

let MeterValidationCertificatePartD = props => {

   const { handleSubmit, meterEquipInfo, previousPage,  loading , hasError} = props

   const disableFields = (parseInt(meterEquipInfo.formStatus) == 2)|| props.disableFields  || props.disableEditing || loading ? true : false;

   const meteringCircumstanceValidationOptions =
      [
         { id: "1", name: "Validation after maintenance that may have affected the metrology of existing meter", selected: false },
         { id: "2", name: "Validation after work requiring removal of a tamper-evident seal", selected: false },
         { id: "3", name: "Re-validation of existing meter", selected: false },
         { id: "4", name: "Validation after removal and reinstallation of existing meter", selected: false },
         { id: "5", name: "Validation after seals on the meter were broken or removed by a person who is not a duly qualified person", selected: false }
      ];

   const meterInstalledBeforeApril2019Options =
      [
         { id: "1", name: "Yes", selected: false },
         { id: "2", name: "No", selected: false }
      ];  



   if (meterEquipInfo.dateOfInstallationBeforeApril2019 != null && meterEquipInfo.dateOfInstallationBeforeApril2019 != "")
      meterEquipInfo.dateOfInstallationBeforeApril2019 = new Date(meterEquipInfo.dateOfInstallationBeforeApril2019);

   if (meterEquipInfo.dateOfLastValidation != null && meterEquipInfo.dateOfLastValidation != "")
      meterEquipInfo.dateOfLastValidation = new Date(meterEquipInfo.dateOfLastValidation);

   if (meterEquipInfo.meterRemovedDate != null && meterEquipInfo.meterRemovedDate != "")
      meterEquipInfo.meterRemovedDate = new Date(meterEquipInfo.meterRemovedDate);

   if (meterEquipInfo.dateBeforeMaintenance != null && meterEquipInfo.dateBeforeMaintenance != "")
      meterEquipInfo.dateBeforeMaintenance = new Date(meterEquipInfo.dateBeforeMaintenance);

   if (meterEquipInfo.dateAfterMaintenance != null && meterEquipInfo.dateAfterMaintenance != "")
      meterEquipInfo.dateAfterMaintenance = new Date(meterEquipInfo.dateAfterMaintenance);

   if (meterEquipInfo.meterReadingDate != null && meterEquipInfo.meterReadingDate != "")
      meterEquipInfo.meterReadingDate = new Date(meterEquipInfo.meterReadingDate);

   if (meterEquipInfo.finalReadingDateRemovedMeter != null && meterEquipInfo.finalReadingDateRemovedMeter != "")
      meterEquipInfo.finalReadingDateRemovedMeter = new Date(meterEquipInfo.finalReadingDateRemovedMeter);

      
   return (
      <form className="vertical" onSubmit={handleSubmit}>
         <h5 className='mb-3'>Existing Metering Equipment</h5>

         <div className='row mb-3'>
            <div className='col-sm-6'>
               <Field
                  name={"meteringEquipmentInformation.meterBeforeApril2019"}
                  component={RadiosForRedux}
                  options={meterInstalledBeforeApril2019Options}
                  props={{ disabled: disableFields}}
                  values={meterEquipInfo.meterBeforeApril2019}
                  label={<>Was this meter installed before 1st April 2019? <span className='text-danger'>* </span></>}
               />
            </div>
         </div>

         {(meterEquipInfo.meterBeforeApril2019 == 1) && 
            <div className='row mb-3'>
               <div className='col-sm-3'>
                  <Field
                     name="meteringEquipmentInformation.dateOfInstallationBeforeApril2019"
                     handleChange={date => { props.change(props.form, 'meteringEquipmentInformation.dateOfInstallationBeforeApril2019', date) }}
                     component={DatePickerForRedux}
                     values={meterEquipInfo.dateOfInstallationBeforeApril2019}
                     props={{ disabled: disableFields, maxDate: new Date(2019,2,31) }}
                     label="Please provide the date of installation for this meter (if known)"
                  />
               </div>
            </div>
         }

         <div className='row mb-3'>
            <div className='col-sm-6'>
               <Field
                  name="meteringEquipmentInformation.meterCircumstancesOptions"
                  component={CheckboxForRedux}
                  options={meteringCircumstanceValidationOptions}
                  props={{ disabled: disableFields, divClass: 'col_12 column', mandatory:true }}
                  label="This validation certificate is for:"
               />
            </div>
         </div>

         <div className='mb-3'>
            <Field
               name="meteringEquipmentInformation.reasonForRevalidation"
               props={{ disabled: disableFields }}
               label={<>Reason for revalidation <span className='text-danger'>* </span></>}
               component={TextAreaForRedux}
               cols={40}
               rows={10}
            />
         </div>
         <div className='row mb-3'>
            <div className='col-sm-3'>
               <Field
                  name="meteringEquipmentInformation.dateOfLastValidation"
                  handleChange={date => { props.change(props.form, 'meteringEquipmentInformation.dateOfLastValidation', date) }}
                  component={DatePickerForRedux}
                  values={meterEquipInfo.dateOfLastValidation}
                  props={{ disabled: disableFields, maxDate: new Date() }}
                  label="Date of last validation"
               />
            </div>
         </div>

         <h5 className='mt-4 mb-2'>Meter Readings</h5>

         <div className='row mb-3'>
            <div className='col-sm-6'>
               <Field
                  name="meteringEquipmentInformation.readingBeforeMaintenance"
                  props={{ disabled: disableFields }}
                  component={InputTextForRedux}
                  label="Reading before maintenance"
               />
            </div>
            <div className='col-sm-3'>
               <Field
                  name="meteringEquipmentInformation.dateBeforeMaintenance"
                  handleChange={date => { props.change(props.form, 'meteringEquipmentInformation.dateBeforeMaintenance', date) }}
                  component={DatePickerForRedux}
                  values={meterEquipInfo.dateBeforeMaintenance}
                  props={{ disabled: disableFields, maxDate: new Date() }}
                  label="Date"
               />
            </div>
         </div>

         <div className='row mb-3'>
            <div className='col-sm-6'>
               <Field
                  name="meteringEquipmentInformation.readingAfterMaintenance"
                  props={{ disabled: disableFields }}
                  component={InputTextForRedux}
                  label="Reading after maintenance"
               />
               {isNumber(meterEquipInfo.readingAfterMaintenance) &&  (meterEquipInfo.endMeterReading && (meterEquipInfo.readingAfterMaintenance < meterEquipInfo.endMeterReading)) &&
               <div className='alert alert-warning mt-2'>
                  {METER_READING_NOT_ACCURATE(meterEquipInfo.endMeterReading)}
               </div>}
            </div>
            <div className='col-sm-3'>
               <Field
                  name="meteringEquipmentInformation.dateAfterMaintenance"
                  handleChange={date => { props.change(props.form, 'meteringEquipmentInformation.dateAfterMaintenance', date) }}
                  component={DatePickerForRedux}
                  props={{ disabled: disableFields, maxDate: new Date(), errorNewline: true }}
                  values={meterEquipInfo.dateAfterMaintenance}
                  label="Date"
               />
            </div>
         </div>

         <hr/>

         <MeteringEquipmentInformationMain form={props.form} disableEditing={props.disableEditing} />

         {hasError && <div className='alert alert-danger my-3'>
         Please fix all errors before you continue
         </div>}

         <div className="text-end mt-4">
            <button type="button" class="btn btn-primary float-start" data-bs-toggle="modal" data-bs-target="#checkListModal" disabled={loading}>
               View Checklist
            </button>
            {(parseInt(meterEquipInfo.formStatus) == 1) && (!props.disableEditing) && <>
               {meterEquipInfo.id!=0 && <button type="button"  
                  className="btn btn-danger me-3" disabled={loading} 
                  data-bs-toggle="modal" data-bs-target="#deleteDraftModal">
                  Delete Draft
               </button>}
               {/* 
               <button type="button"  className="btn btn-primary" disabled={loading}
                  onClick={() => { saveDraftAction(props); }}>
                  Save Draft
               </button>
               */}
               <div className='vr mx-3' />
            </>}
            <button type="button"  className="btn btn-warning me-3" 
               disabled={loading}
               onClick={()=>{previousPage(meterEquipInfo.formStatus)}}>
               Previous
            </button>
            <button type="button" className="btn btn-success"
               disabled={loading}
               onClick={() => { clickNextAction(props); }}>
               Next
            </button>
         </div>
      </form>
   )
}

MeterValidationCertificatePartD = reduxForm({
   destroyOnUnmount: false,
   forceUnregisterOnUnmount: true,
   validate,
   onSubmitFail: (errors, dispatch ) => {
      dispatch(change('certificate', 'hasError', true));
   },
   onSubmitSuccess:(result , dispatch )=>{
      dispatch(change('certificate', 'hasError', false));
      window.scrollTo(0, 0);
   }
})(MeterValidationCertificatePartD)

MeterValidationCertificatePartD = connect(
   (state, props) => {
      // can select values individually
      const selectorCert = formValueSelector('certificate')
      const hasError = selectorCert(state, 'hasError')

      const selector = formValueSelector(props.form)
      const masterDataValues = selector(state, 'mdata')
      const meterEquipInfo = selector(state, 'meteringEquipmentInformation')
      const documents = selector(state, 'documents')
      const loading = selector(state, 'loading')

      
      const form = props.form
      return {
         masterDataValues,
         meterEquipInfo,
         documents,
         form,
         loading,
         hasError
      }
   },
   {
      checkSerialNumber: (serialNumber) => checkSerialNumber(serialNumber)
   }
)(MeterValidationCertificatePartD)

export default MeterValidationCertificatePartD