export default {
    consent :{
        getByApproval : '/customerConsent/get-by-approval',
        requestConsentByEmail : '/customerConsent/request-consent-by-email',
        resendConsentByEmail : '/customerConsent/resend-consent-by-email',
        requestConsentByPdf : '/customerConsent/request-consent-by-pdf',
        resendConsentByPdf : '/customerConsent/resend-consent-by-pdf',
        validate : '/customerConsent/validate-consent',
        submitPdfConsentForm: '/customerConsent/submit-pdf-consent-form',
        getById: '/customerConsent/get-by-id',
        adminSearch: '/customerConsent/admin-search',
        adminResetEmailLimit: '/customerConsent/admin-reset-email-limit'
    },
    meterCommission :{
        createMeterCommissionProcess: '/meter-commission/create-meter-commission-process',
        getMeterCommissionSiteRegistration:'/meter-commission/get-meter-commission-site-registration',
        saveMeterCommissionSiteRegistration:'/meter-commission/save-meter-commission-site-registration',
        submitSiteRegistration:'/meter-commission/submit-site-registration',
        rejectMeterRegistration:'/meter-commission/reject-meter-registration',
        approveMeterRegistration:'/meter-commission/approve-meter-registration',
        getMeterCommissionValidation:'/meter-commission/get-meter-commission-validation',
        saveMeterCommissionValidation:'/meter-commission/save-meter-commission-validation',
        triggerMeterCommissioning:'/meter-commission/trigger-meter-commissioning',
        submitMeterCommissionValidation:'/meter-commission/submit-meter-commission-validation',
        getMeterCommissionForAssigningOfficer:'/meter-commission/get-meter-commission-for-assigning-officer',
        assignApprovingOfficer:'/meter-commission/assign-approving-officer',
        adminToggleAwaitingResolution:'/meter-commission/admin-toggle-awaiting-resolution',
        rejectMeterValidation:'/meter-commission/reject-meter-validation',
        approveMeterValidation:'/meter-commission/approve-meter-validation',
        deleteDraft:'/meter-commission/delete-draft',
        adminTransferDqp:'/meter-commission/admin-transfer-dqp',
        adminDelete:'/meter-commission/admin-delete-meter-commission',
        validateReadyForInstallationDAS:'/meter-commission/validate-ready-for-installation-das',
        getAuditsById:'/meter-commission/get-audits-by-id',
        saveNotes:'/meter-commission/save-notes',
    },
    meterFormCommon : {
        getWorksAndMetersByWorksiteId:'/meterFormCommon/get-works-and-meters-by-worksite-id',
        getLidOptions:'/meterFormCommon/get-lids-options',
        getExistingLidsForApproval:'/meterFormCommon/get-existing-lids-for-approval',
    },
    admin : {
        searchMeter : '/admin/search-meters',
        getMeter: '/admin/get-meter',
        getMeterIntegrationDetails: '/admin/get-meter-integration-details',
        initialiseMeterIntegration: '/admin/initialise-meter-integration',
        startMeterIntegrationDetails: '/admin/start-meter-integration-details',
        toggleMeterIntegrationStatus: '/admin/toggle-meter-integration-status',
        matchEagleIoIntegrationForMeter: '/admin/match-eagle-io-integration-for-meter',
        matchWasIntegrationForMeter: '/admin/match-was-integration-for-meter',
        searchForms: '/admin/search-forms-new',
        getUserAudits:'/admin/get-user-audits',
        deleteMeter:'/admin/delete-meter'
    },
    meter : {
        getMeterByApprovalNumber : '/meter/get-meters-by-approval-number'
    },
    das : {
        requestWorkspaceAccess : '/das/request-workspace-access',
        getMyDasAccessRequests : '/das/get-my-das-access-requests'
    },
    others : {
        homeSearch : '/others/home-search',
        searchUsers : '/others/search-users'
    }
    
}
