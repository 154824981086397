import { GlobalProvider } from './State';

import React, { useContext, useEffect, useState } from 'react';
import { GlobalContext } from './State';
import { Measurement } from './Measurement';
import { Confirmation } from './Confirmation';
import { Declaration } from './Declaration';
import { RatingTableSearch } from './RatingTableSearch';
import { Link } from 'react-router-dom';
import Intro2 from './Intro2';
import $ from 'jquery';
import { getSubmission, getRefData, getUserProfile } from './API';
import { JwtAuthentication as Authentication } from '../helpers/JwtAuthentication';

import { IDENTITY_FUNCTION } from '../constants/MeterFormConstants';


const token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJPbmxpbmUgSldUIEJ1aWxkZXIiLCJpYXQiOjE2MjUxMTk0NTUsImV4cCI6MTY1NjY1NTQ1NSwiYXVkIjoid3d3LmV4YW1wbGUuY29tIiwic3ViIjoianJvY2tldEBleGFtcGxlLmNvbSJ9.uFbBLhsefTDC7CkjSC86L8z6ee5m6V3EaYw1koO4eKc';
const staticConfig = {
  baseURL: process.env.REACT_APP_WEBAPI_URL,
  headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }
};

export const Master = () => {

  const { holder, loadNominiation, setReferenceData, flickPage } = useContext(GlobalContext);
  const [userObject, setUser] = useState({});
  const authentication = new Authentication();

  const displayUserInformation = () => {

    return (
      <span>
        <span style={{ textDecoration: "none", fontSize: "16px", fontWeight: "bold", color: "black" }}>
          {`${userObject?.lastName}`}
        </span>
        <br />
        <span style={{ textDecoration: "none", fontSize: "14px", color: "grey" }}>
          {`${userObject?.firstName}`}
        </span>
        <br />
      </span>
    );
  }

  const doLogout = () => {
    authentication.logout();
  };
  const handleSubmit = (e) => { };
  const OnHome = () => { }

  const terminalError = (error) => { //TODO refine
    alert('Error happened on the server side when searching for Measurement Data. Reload the page');
    console.log(JSON.stringify(error))
  }

  const doAuth = () => {

    if (authentication.isB2C()) {
      let currentUser = { ...authentication.currentUser(), b2cUser: true };
      getUserProfile(
        authentication.currentUser().emails.find(IDENTITY_FUNCTION),
        data => {
          setUser({ ...currentUser, ...data })
        },
        error => terminalError(error),
        () => console.log("doAuth_completed" + JSON.stringify(userObject))
      );
    } else {
      //jwt logic
      authentication.currentUserAsync(setUser);
    }
  }

  useEffect(() => {
    if (userObject && userObject.emails && userObject.emails[0]) {
      if (userObject && userObject.user && userObject.user.id) {
        debugger;
        getRefData(
          userObject.user.id,
          (data) => {
            setReferenceData(data);

            if (data && data.forms && Array.isArray(data.forms) && data.forms.length > 0) {
              $(".clickable-table").on('click', '.clickable-row', function () {
                debugger;
                window.location = $(this).data("href");
              });
            }
          },
          error => alert('Error happened on the server side when searching for reference data. Reload the page' + error),
          () => {/*stop the spinner */ }
        )

      } else {
        console.error("User id not established after getProfile Call");
      }
    }
  }, [userObject]);

  useEffect(() => {
    doAuth();
  }, []);


  useEffect(() => {
    if (holder.referenceData?.userid) {
      let search = window.location.search;
      let params = new URLSearchParams(search);
      let formId = params.get('id');

      if (formId) {
        getSubmission(formId,
          (data) => loadNominiation(data, 2),
          (error) => terminalError(error),
          () => { //stop the loading....gif
          }
        );
      }
    }
  }, [holder.referenceData?.userid]);



  return (
    <div className="grid" id='printBox'>
      <header>  <div>

        <div class="row">
        </div>
        <div className="tab-content clearfix">
          {(holder?.page == 0) && <Intro2 />}
          {(holder?.page == 1) && <RatingTableSearch />}
          {(holder?.page == 2) && <Measurement />}
          {(holder?.page == 3) && <Confirmation />}
          {(holder?.page == 4) && <Declaration />}
        </div>
      </div>
      </header>
      <div class="row footer">
        <div class="column col_1" />
        <div class="column col_2">
          <a style={{ color: '#FFFFFF' }} href="https://www.waternsw.com.au" target="_blank">WaterNSW</a>
        </div>
        <div class="column col_2">
          <a style={{ color: '#FFFFFF' }} href="https://www.waternsw.com.au/copyright" target="_blank">Copyright</a>
        </div>
        <div class="column col_2">
          <a style={{ color: '#FFFFFF' }} href="https://www.waternsw.com.au/privacy" target="_blank">Disclaimer</a>
        </div>
        <div class="column col_2">
          <a style={{ color: '#FFFFFF' }} href="mailto:dqp.enquiries@waternsw.com.au">Contact Us</a>
        </div>
        <div class="column col_2">
          <Link style={{ color: '#FFFFFF' }} to={{ pathname: '/Help' }}>Help</Link>
        </div>
      </div>





    </div>)
}