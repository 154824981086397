import React, {useState} from 'react';

import {   MESSAGE_UPLOAD_LIMITS , uploadOptionsForStoreManagementValidation as uploadOptions} from '../../../constants/MeterFormConstants'
import { connect } from 'react-redux'
import { Field, reduxForm, formValueSelector, change } from 'redux-form';

import { updateDocuments, updateDeleteDocuments, changeFormStatus, uploadFiles } from '../design/MeterDesignCertificate.Reducers';

import { saveDraftAction, saveSubmitAction } from "./Validations"
import 'redux';
import "react-datepicker/dist/react-datepicker.css";

import { SelectForRedux}  from '../../tools/BootstrapControls'
import FileUploader from '../../common/FileUploader'

let _validateFiles;

let fileUploaderDisplayListUpdate;

const validate = (values, props) => {
   let clickSaveDraft = (props.clickSaveDraft== undefined? values.clickSaveDraft:props.clickSaveDraft);
   let clickNext = props.clickNext== undefined ? values.clickNext : props.clickNext;

   if (clickSaveDraft == 0 && clickNext === 1 && values.storageManagementValidationData.formStatus != 2) {
      //alert(_validateFiles)
      if(_validateFiles) {
         if(!_validateFiles()){
            return {storageManagementValidationData : {fileType:"Please upload all manadatory supporting documents"}};
         }
      }
      
   }

   return {};
}


let CertificatePartE = props => {

   
   let {previousPage, handleSubmit, storageManagementValidationData, documents, loading , hasError} = props    
   let isAccuracyCertNeeded = (storageManagementValidationData.certFor == 2 && storageManagementValidationData.certExistingFor == 1);



    //--------------------------------------
    const [renderCnt, setRenderCnt] = useState(0);
   
    const updateDisplayFileList = ()=>{
 
       if(!fileUploaderDisplayListUpdate) return;
       const newList = [];
 
       if(documents && documents.length!=0) {
 
          var option;
          for(var i = 0; i< documents.length; i++){
             if(documents[i].status=='new' || documents[i].status=='existing'){
                var option = uploadOptions.filter(option=>option.id==documents[i].documentType)[0];
                newList.push({
                   id: documents[i].id,
                   fileTypeDesc : option?option.name:"",
                   description: documents[i].description,
                   fileOriginalName: documents[i].fileOriginalName,
                   fileGUID: documents[i].fileGUID
                });
             }
          }
 
       }
       fileUploaderDisplayListUpdate(newList);
       setRenderCnt(renderCnt+1)
    }
 
    const fileUploaderFilesUploaded=(files)=>{
       
       var fileType = props.storageManagementValidationData.fileType;
       var docs = props.documents;
 
       for(var i = 0; i< files.length; i++){
 
          docs.push({
             id : files[i].id,
             documentType : fileType,
             description : files[i].description,
             fileOriginalName : files[i].fileOriginalName,
             fileGUID : files[i].fileGUID,
             status : "new"
          });
       }
       props.dispatch(change(props.form, 'documents', docs))
       updateDisplayFileList();
    }
 
    
    const fileUploaderFileDeleted=(file)=>{
       if(!props.documents) return;
 
       for(var i = 0; i< props.documents.length; i++){
          if(props.documents[i].id==file.id){
             if (props.documents[i].status == "new")
                props.documents[i].status = "newDeleted";
             else if (props.documents[i].status == "existing")
                props.documents[i].status = "existingDeleted";
          }
       }
 
       updateDisplayFileList();
    }
    //--------------------------------------


   const validateFiles =()=>{   

      var found;
      for (var k=0;k<uploadOptions.length;k++) {
         if(uploadOptions[k].id=="6") continue;
         if(!isAccuracyCertNeeded && uploadOptions[k].id=="4") continue;

         found = false;
         for (var i=0;i<props.documents.length;i++) {
            if(props.documents[i].documentType == uploadOptions[k].id){
               found = true;
               break;
            }
         }
         if(!found) return false;
      }
      

      return true;
   }

   _validateFiles = validateFiles;
   
   const disableFields = (parseInt(storageManagementValidationData.formStatus) == 2) || props.disableEditing || loading ? true : false;   

   if ((!props.storageManagementValidationData.fileType || props.storageManagementValidationData.fileType == 0) && uploadOptions.length > 0) {
      props.dispatch(change(props.form, 'storageManagementValidationData.fileType', "3"))
   }  

   return (
      <form onSubmit={handleSubmit}>        
         <h5 className='mb-3'>Supporting documentation</h5>  

         <p className='fw-bold'>The following supporting information is mandatory and must be loaded into the DQP portal: </p>

         <div className='my-3 ps-2'>
            {uploadOptions.map((option) => {
               if(option.id=='6') return null;
               if(!isAccuracyCertNeeded && option.id=="4") return;
               return <div class="form-check">
                  <input class="form-check-input" type="checkbox" value="" id={"checklist_" + option.id} checked={props.documents.filter(doc=>( (doc.status=='new' || doc.status=='existing' ) && doc.documentType == option.id)).length > 0} />
                  <label class="form-check-label" for={"checklist_" + option.value}>
                     {option.name}
                  </label>
               </div>
            })}
         </div>

         
         
         
         <div className='row mb-3'>
            <div className='col-sm-7'>
               <Field
                  name="storageManagementValidationData.fileType"
                  component={SelectForRedux}
                  props={{ disabled: disableFields }}
                  value="storageManagementValidationData.fileType"
                  options={uploadOptions} />
            </div>
         </div>

         <FileUploader
            fileInputLabel={<strong>Upload your supporting documents here:</strong>}
            fileTableHeader={<p><strong>Your uploaded files: </strong></p>}
            warning={MESSAGE_UPLOAD_LIMITS}
            maxFileSizeMB={10}
            exceedFileSizeErrorMsg = "Your file is too big"
            disabled={disableFields}
            provideDisplayDocumentsUpdator={(updateFunction)=>{fileUploaderDisplayListUpdate = updateFunction;}}

            fileTableHeadComponent={<tr>
               <th style={{width:"80px"}}></th>
               <th style={{width:"280px"}}>Type</th>
               <th>File name</th>
               <th>Description</th>
            </tr>}

            fileTableRowCompponent={(file, index, deleteButton)=>{
               return <tr key={index}>
                  <td>
                     {deleteButton}
                  </td>
                  <td>
                     {file.fileTypeDesc}
                  </td>
                  <td>
                     <a target='_blank' href={process.env.REACT_APP_WEBAPI_URL + process.env.REACT_APP_FIELS_GET + "/" + file.id}>
                        {file.fileOriginalName}
                     </a>
                  </td>
                  <td>{file.description}</td>
            </tr>
            }}

            onFilesUploaded={fileUploaderFilesUploaded}
            askForDocumentUpdate={updateDisplayFileList}
            onFileDeleted={fileUploaderFileDeleted}
         />

         {hasError && <div className='alert alert-danger'>
            Please fix all errors before you continue
         </div>}

         <div className="text-end">           
            {(parseInt(storageManagementValidationData.formStatus) == 1) && (!props.disableEditing) && <>
               {storageManagementValidationData.id!=0 &&  <button type="button"  className="btn btn-danger me-3" disabled={loading} 
               data-toggle="modal" data-target="#deleteDraftModal">
                  Delete Draft
               </button>}
               <button type="button"  className="btn btn-primary" disabled={loading}
                  onClick={() => { saveDraftAction(props); }}>
                  Save Draft
               </button>
               <div className='vr mx-3'></div>
            </>}
            <button type="button"  className="btn btn-warning me-3" disabled={loading} onClick={()=>{previousPage(storageManagementValidationData.formStatus)}}>
               Previous
            </button>
            {(parseInt(storageManagementValidationData.formStatus) == 1) && (!props.disableEditing) && <button type="button" className="btn btn-success" disabled={loading}
                  onClick={() => {saveSubmitAction(props); }
                  }>
                  Submit
            </button>}
         </div>
      </form>
   )
}



CertificatePartE = reduxForm({
   destroyOnUnmount: false,
   forceUnregisterOnUnmount: true,   
   validate ,
   onSubmitFail: (errors, dispatch ) => {
      dispatch(change('certificate', 'hasError', true));
   },
   onSubmitSuccess:(result , dispatch )=>{
      dispatch(change('certificate', 'hasError', false));
      window.scrollTo(0, 0);
   }
})(

CertificatePartE)




CertificatePartE = connect(
   (state, props) => {
      // can select values individually

      const selectorCert = formValueSelector('certificate')
      const hasError = selectorCert(state, 'hasError')
      
      const selector = formValueSelector(props.form)   
      const masterDataValues = selector(state, 'mdata')
      const storageManagementValidationData = selector(state, 'storageManagementValidationData')
      const documents = selector(state, 'documents')
      const loading = selector(state, 'loading')
      const form = props.form
      return {       
         storageManagementValidationData,
         documents,         
         masterDataValues, 
         form,
         hasError,
         loading
      }
   }, {
      uploadFiles: (formData) => uploadFiles(formData),
      updateDeleteDocuments: (doc) => updateDeleteDocuments(doc),
      changeFormStatus: (payload, val) => changeFormStatus(payload, val),
      updateDocuments: (payload) => updateDocuments(payload)
   }
)(CertificatePartE)

export default CertificatePartE