import React from 'react';
import Authentication from '../helpers/Authentication';
import BasePageLayout, {menuItems} from './base/BasePageLayout';


class Register extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            loaded: false
        }
        var action = localStorage.getItem("b2caction")
        if (action != undefined && action != null && action != "") {
            if (action == "SIGNUP") {
                console.log('SIGNUP');
                localStorage.setItem("b2credirect", "SIGNUP");
                window.location = "/Home";
            }
            else
                this.state.loaded = true;
        }
        else
            this.state.loaded = true;
    }

    setAction(params) {
        var url = process.env.REACT_APP_WEBAPI_URL + '/uploadfile/getfile/png/logo';
        var img = new Image();
        img.src = url;
        img.onload = function () {
            localStorage.setItem("b2caction", params);
            this.authenticationUser = new Authentication();
            if (this.authenticationUser.isLoggedIn()) {
                this.authenticationUser.logout();
            }
            else
                window.location = "/Home";
        }
        img.onerror = function () {
            window.location = window.location.origin;
            alert('Server is not running')
        }
    }

    render() {
        const { loaded } = this.state
        if (!loaded) {
            return <div></div>;
        }
        return <>
            <h3 className='mt-5 text-center text-black'>Welcome to the DQP Portal</h3>
            <h5 className='text-center'>Please select one option below</h5>

            <div class="row justify-content-md-center">
                <div className='col-sm-5 text-center pt-5 pb-3'>
                    <p>I am a New User</p>
                    <a href="#" onClick={() => { this.setAction("SIGNUP") }} className="btn btn-secondary" >Create account</a>
                </div>
                <div className='col-sm-2 col-md-auto text-center'>
                    <div id="reg-divider">
                        <span>
                            OR
                        </span>
                    </div>
                </div>
                <div className='col-sm-5  text-center pt-5 pb-3'>
                    <p>I am an Existing User</p>
                    <a href="/Home" onClick={() => { this.setAction("SIGNIN") }} className="btn btn-secondary">Sign in</a>
                </div>
            </div>
        </>;

    }
}

export default Register;
