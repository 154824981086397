import React, { useContext, useEffect, useState } from 'react';
import { GlobalContext } from './State';
import { jsPDF } from "jspdf";
import {Statement} from './Statement'


export const Confirmation = () => {
  const { holder: { nomination, referenceData }, loadNominiation, flickPage } = useContext(GlobalContext);
  const [timestamp, setTimestamp] = useState();

  const handleSubmit = (e) => {
   e.preventDefault();   
   flickPage(4);
  };

  const colourText = (text,colour="#EDEDED") => (<p style={{color: colour}}>{text}</p>)

  const handleElement = {
    '#ignoreElement': function (element, renderer) {
      return true;
    },
    '#anotherIdToBeIgnored': function (element, renderer) {
      return true;
    }
  };

  const doPDF = () => {
    var doc = new jsPDF();   
    var sourceDiv = window.document.getElementById("innerMPrintBox");
    doc.html(
      sourceDiv.innerHTML,
        { 
        'x': 15,
        'y': 15,           
        'width': 180,
        'elementHandlers': handleElement
      });
  
  doc.output("dataurlnewwindow");
  }   

  return (<React.Fragment>
   <Statement nomination={nomination}/>
    <div class="row" >
      <form onSubmit={handleSubmit} id="placeholder">      
        <button  id="confirmNext" class="button btn-success btn-lg" type="submit"
          style={{ display: (nomination.attributes.formStatus < 2) ? 'inline' : 'none', float: 'right' }} >Next</button>
           <div style={{ float: 'right' }}>&nbsp;&nbsp;</div>
        {<button  id="confirmPrevious" class="button btn-success btn-lg" onClick={() => flickPage(2)}
         style={{ display: 'inline', float: 'right' }} >Previous</button>
        }
      </form>
    </div>
    
  </React.Fragment>)
}