import React, { createContext, useReducer } from 'react';


import {
  INTAKE,
  DISCHARGE,
  NEXT,
  DATE_FORMAT,
  START,
  OBJECT_FLOW,
  OBJECT_DISCHARGE,
  FLOW_WATER_SOURCE,
  DISCHARGE_WATER_SOURCE,
  DISCHARGE_AMOUNT,
  START_DAY,
  ACTIONS,
  END_MONTH
} from './MeasurementConstants';

import appReducer from './Reducers'
const initialState = {
  page: 0,
  flowQuestion: 0,
  dischargeQuestion: 0,
  referenceData: { forms: undefined, completedForms: undefined, watersources: undefined, userid: undefined },
  formId: undefined,
  confirmingSubmission: false,
  isSearchFormLoading: false,
  dirty: 0,
  dasDataAlertDisplayed: false,
  nomination: {
    id: undefined,
    logEntry: { type: undefined, priorDayReading: { gaugeHeight: 0, height: 0, area: 0, volume: 0 }, entryLog: [] },
    openingStorageLevel: undefined,
    holderName: undefined,
    nonCompliantCode: 0,
    nonCompliantComments: undefined,
    ratingTable: { id: undefined, ratingTableName: undefined },
    ratingTableContents: [],
    declaration1: false,
    declaration2: false,
    declaration3: false,
    measurementDeviceDamaged: false,
    timeRange: {
      startDate: START_DAY,
      endDate: null,
    },
    attributes: {
      formStatus: 1,
      userid: undefined
    },
    flow: {},
    discharge: {
    }
  },

};

const getAHDForGaugeHeight = (val, ratingTableContents, priorDayReading) => {
  debugger;
  let retVal = { elevation: 0, area: 0, volume: 0, deltaVolume: 0 };
  let retVal_undefined = { elevation: -1, area: -1, volume: -1, deltaVolume: -1 };
  if (!val) {
    return retVal;
  }

  let startAHD = ratingTableContents[0].elevation;
  let endAHD = ratingTableContents[ratingTableContents.length - 1].elevation;
  if (val < startAHD) {
    return retVal;
  }

  if (val > endAHD) {
    return retVal_undefined;
  }

  for (let i = 0; i < ratingTableContents.length; i++) {
    let thisHeight = ratingTableContents[i].elevation;
    if (val <= thisHeight) {
      retVal = { elevation: thisHeight, area: ratingTableContents[i].area, volume: ratingTableContents[i].volume };
      break;
    }
  }
  return retVal;
}

export const GlobalContext = createContext(initialState);

export const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(appReducer, initialState);

  const addNewElement = (type, initial = false) => {
    if (type === INTAKE) {
      dispatch({
        type: ACTIONS.ADD_NEW_FLOW
      });
    } else {
      dispatch({
        type: ACTIONS.ADD_NEW_DISCHARGE
      });
    }

  };

  const delElement = (type, initial = false) => {
    if (type === INTAKE) {
      dispatch({
        type: ACTIONS.DEL_FLOW
      });
    }
    else {
      dispatch({
        type: ACTIONS.DEL_DISCHARGE
      });
    }
  };

  const setReferenceData = (data) => {
    dispatch({
      type: ACTIONS.SET_REFERENCE_DATA,
      payload: data
    });
  }

  const setDasDataAlertDisplayed = (value) => {
    dispatch({
      type: ACTIONS.SET_DAS_DATA_DISPLAYED,
      payload: { dasDataAlertDisplayed: value }
    });
  }

  const updateNomination = payload => {
    debugger;
    dispatch({
      type: ACTIONS.UPDATE,
      payload: payload
    });
  }

  const loadNominiation = (nomination, page = 3) => {

    console.log("loadNominiation called. page=" + page)
    dispatch({
      type: ACTIONS.LOAD,
      payload: nomination,
      page: page
    });
  }

  const setRatingTable = (ratingTable) => {
    console.log("setRatingTable called payload is" + ratingTable);
    dispatch({
      type: ACTIONS.LOAD_RATING_TABLE,
      payload: ratingTable
    });
  }

  const flickPage = (page => {
    dispatch({
      type: ACTIONS.FLICK_PAGE,
      payload: page
    });
  })

  const setentryLog = entryLog => {
    dispatch({
      type: ACTIONS.SET_MANUAL_ENTRY_LOG,
      payload: entryLog ? entryLog : []
    });
  }

  const setManualEntryHeight = (key, val, index) => {
    dispatch({
      type: ACTIONS.SET_MANUAL_ENTRY_LOG_HEIGHT,
      key: key,
      value: val,
      index: index
    });
  }

  const setManualEntryFlag = (payload) => {
    dispatch({
      type: ACTIONS.SET_MANUAL_ENTRY_FLAG,
      payload: payload
    });
  }

  const setRatingTableContents = (contents) => {
    debugger;
    dispatch({
      type: ACTIONS.SET_RATING_TABLE_CONTENTS,
      payload: contents

    });
  }

  const setPriorEntryHeight = (val, area = undefined, volume = undefined) => {
    dispatch({
      type: ACTIONS.SET_PRIOR_ENTRY_HEIGHT,
      payload: val,
      area: area,
      volume: volume
    });
  }

  const updateDeclaration = (id) => {
    dispatch({
      type: ACTIONS.SET_DECLARATION,
      payload: id
    });
  }

  const setAHDHeight = (val, index) => {
    console.log(`in setAHDHeight val is ${val} and index is ${index}`);
    let ahd = getAHDForGaugeHeight(val, state.nomination.ratingTableContents, state.nomination.logEntry.priorDayReading);
    console.log(`gauge height ${val} ahd height ${JSON.stringify(ahd)}`);
    dispatch({
      type: ACTIONS.SET_AHD_HEIGHT,
      payload: { ahd: ahd, index: index, gaugeHeight: val }
    });
  }

  return (
    <GlobalContext.Provider
      value={{
        holder: state,
        updateNomination,
        loadNominiation,
        setRatingTable,
        flickPage,
        addNewElement,
        delElement,
        setReferenceData,
        setManualEntryHeight,
        setentryLog,
        setManualEntryFlag,
        setRatingTableContents,
        setPriorEntryHeight,
        updateDeclaration,
        setAHDHeight,
        setDasDataAlertDisplayed
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};




