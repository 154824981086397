import React from 'react';
import { UneditableTextInput } from '../tools/BootstrapControls';
import { SquareCheck } from '../tools/Icons';

export default class ApprovalFields  extends React.Component {

    render() {
        const approval = this.props.approval;
        return <>
            <div className='row mb-3'>
                <div className='col-md-4 py-2'>Approval Number</div>
                <div className='col-md-8 py-2'>
                    <UneditableTextInput value={approval.approvalNumber} fieldExtraClass="bg-light"/>
                </div>
            </div>
            <div className='row mb-3'>
                <div className='col-md-4 py-2'>Holder/s</div>
                <div className='col-md-8 py-2'>
                    <ul class="list-group">
                        {approval.holders.map(h=><li class="list-group-item">
                            {h.name} {this.props.authoriserDlwcInpaAddrId && this.props.authoriserDlwcInpaAddrId == h.dlwcInpaAddrId && <span className='text-dark-green'><SquareCheck/> </span>}
                            <div className='fs-80 ps-1'>
                                <strong>Email:</strong> {h.email}<br/>
                                <strong>Address:</strong> {h.postalAddressLine1} {h.postalAddressLine2?`, ${h.postalAddressLine2}`:''}, {h.postalSuburb}, {h.postalState}, {h.postalPostcode}<br/>
                                {h.phoneNumbers.length!=0 && <>
                                    <strong>Phone Number/s:</strong> {h.phoneNumbers.map((p,i)=>{
                                        if(i!=0) return <>, {p.prefix} {p.phoneNumber}</>;
                                        return <>{p.prefix} {p.phoneNumber}</>;
                                    })}
                                </>}
                            </div>
                        </li>)}
                    </ul>
                    {this.props.authoriserDlwcInpaAddrId  && <div><span className='text-dark-green'><SquareCheck/></span> <i className='fs-80 text-muted'>DQP is authorised by this holder for the meter compliance process.</i></div>}
                </div>
            </div>
        </>
    }
}