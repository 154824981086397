import React, { Fragment } from 'react';
import Authentication from '../../helpers/Authentication'

import axios from 'axios';
import moment from 'moment';
import $ from 'jquery';


const authentication = new Authentication();
class MeterSelectForEsid extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            metersLoaded : false,
            meters : [],
            selectedMeter : null
        }
    }

    ajaxConfig = {
        baseURL: process.env.REACT_APP_WEBAPI_URL,
        headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authentication.getToken() }
    };

    componentDidUpdate(prevProps) {
        if (prevProps.esid !== this.props.esid) {
            this.esidUpdated();
            this.setState(Object.assign(this.state,{selectedMeter : null}));
        }
        if (prevProps.defaultMeterNo !== this.props.defaultMeterNo) {
            this.setState(Object.assign(this.state,{selectedMeter : null}));
        }
        if(!this.props.defaultMeterNo  && this.state.selectedMeter){
            this.setState(Object.assign(this.state,{selectedMeter : null}));
        }
    }

    componentDidMount() {
        this.esidUpdated();
    }   


    esidUpdated = () => {
        let currentContext = this;
        currentContext.setState(Object.assign(currentContext.state,{metersLoaded: false, meters : [], selectedMeter : null}));

        if(!this.props.esid) return;

        axios.get('/datalake/meterlist?waapprovalno=' + this.props.approvalNumber +
        '&esid=' + this.props.esid, this.ajaxConfig).then(function (response) {
            console.log(response.data)
            currentContext.setState(Object.assign(currentContext.state,{meters : response.data.data, metersLoaded: true}));
        }).catch(function (error) {
            alert('Error happened on the server side when getting meters.');
        }).then(function () {
            
        });
    }

    meterSelected = (meter) => {
        if(this.state.selectedMeter && this.state.selectedMeter.METER_ID == meter.METER_ID){
            return;
        }
        this.setState(Object.assign(this.state,{selectedMeter : meter}));
        this.props.onMeterChange(meter);
    }

    textInputClicked = (e) =>{
        $(e.target).parent().find('[data-bs-toggle="dropdown"]').trigger('click');
    }


    render() {
        if(!this.state.metersLoaded && !this.props.esid)
            return <div class="alert alert-primary">Please provide ESID</div>;

        return <>
                <div class={`input-group ${this.props.error && this.props.error != "" ?"is-invalid":""}`}>
                    <input type="text" class={`form-control ${this.props.error && this.props.error != "" ?"is-invalid":""} ${!this.state.metersLoaded ? 'fst-italic' : ''}`} disabled={!this.state.metersLoaded || this.props.disabled} onFocus={(e) => { e.preventDefault(); }} onClick={this.textInputClicked} onKeyPress={(e) => { e.preventDefault(); }} 
                        value={!this.state.metersLoaded? 'Loading...' :this.state.selectedMeter ? 'Meter No.:'+this.state.selectedMeter.METER_NO : this.props.defaultMeterNo ? 'Meter No.:'+this.props.defaultMeterNo : ''} 
                    />
                    <button type="button" class="btn btn-outline-secondary dropdown-toggle" disabled={!this.state.metersLoaded || this.props.disabled} data-bs-toggle="dropdown" aria-expanded="false"></button>
                    <div class="dropdown-menu dropdown-menu-end" style={{"min-width" : "600px"}}>
                        <div className='px-2'>
                            {this.state.meters.length!=0 &&<table class="table table-hover table-sm">
                                <tbody>
                                    <tr className='table-primary'>
                                        <td colSpan="8" class="text-center">
                                            <strong>Select a meter below</strong>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody className='fs-80'>
                                    <tr>
                                        <th></th>
                                        <th >Meter ID</th>
                                        <th >Meter NO.</th>                                   
                                        <th >Serial NO.</th>
                                        <th>Meter category desc</th>
                                        <th >Meter type desc</th>
                                        <th >Meter size desc</th>
                                        <th></th>
                                    </tr>
                                    {this.state.meters.map((item) =>{
                                        return <tr key={item.METER_ID} style={{cursor : "pointer"}} onClick={() => {this.meterSelected(item)}} 
                                                className={(this.state.selectedMeter && this.state.selectedMeter.METER_ID==item.METER_ID) ? 'table-warning' : ''}
                                            >
                                                <td></td>
                                                <td>{item.METER_ID}</td>
                                                <td className="table-primary">{item.METER_NO}</td>                                     
                                                <td>{item.SERIAL_NUMBER}</td>
                                                <td>{item.METER_CATEGORY_DESC}</td>
                                                <td>{item.METER_TYPE_DESCRIPTION}</td>
                                                <td>{item.METER_SIZE_DESC}</td>
                                                <td></td>
                                            </tr>
                                    })}
                                </tbody>
                            </table>}

                            {this.state.meters.length==0 && <table class="table mb-0">
                                <tbody>
                                    <tr><td class="text-center table-primary">No meter found</td></tr>
                                </tbody>
                            </table>}
                        </div>
                    </div>
                    
                    
                </div>
                <div class="invalid-feedback">
                    {this.props.error}
                </div>
            </>


    }

}

export default MeterSelectForEsid;
