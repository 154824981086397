import React from 'react';
import moment from 'moment';
import { convertMeterAuditAction } from '../../../../helpers/converters';

class MeterAudits  extends React.Component {

    render() {
        const audits = this.props.audits;
        return <>
            
            <div class="table-responsive-xxl">
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th scope="col" style={{width:"200px"}}>Date &amp; Time</th>
                            <th scope="col" style={{width:"200px"}}>Action</th>
                            <th scope="col" style={{width:"120px"}}>Successed</th>
                            <th scope="col" style={{width:"180px"}}>User</th>
                            <th scope="col">Comments / Other Info</th>
                        </tr>
                    </thead>
                    <tbody>
                        {audits.map(a=>{
                        
                            let dasErrMsg;
                            if(a.externalApiPlatform=="DAS"){
                                if(a.externalApiResponseCode>400){
                                    dasErrMsg = JSON.parse(a.externalApiResponseContent).msg;
                                }
                            }
                        
                            return <tr>
                                <td>{moment((new Date(a.auditDate))).format('DD/MM/YYYY h:mma')}</td>
                                <td>{convertMeterAuditAction(a.auditAction)}</td>
                                <td>{a.actionSucceeded?<span className='text-success'>Yes</span>:<span className='text-danger'>Failed</span>}</td>
                                <td>{a.auditUser?<>{a.auditUser.firstname} {a.auditUser.lastname}</>:<i className='text-muted'>System</i>}</td>
                                <td>
                                    {a.comments}
                                    {dasErrMsg && <>{a.comments && <br/>}DAS message: <span className='text-danger'>{dasErrMsg}</span></>}
                                </td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>
            

            
        </>
    }
}

export default MeterAudits;
