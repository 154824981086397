import {
    MANUAL_ENTRY,
    NEW_GAUGE_HEIGHT,
    PRIMARY_HOLDER,
    PRIOR_GAUGE_HEIGHT,
    NON_COMPLIANCE_REASONS,
    PENDING,
    TOTAL_TAKE,
    FLOW_WATER_SOURCE,
    DISCHARGE_START_DATE,
    DISCHARGE_END_DATE,
    DISCHARGE_AMOUNT,
    DISCHARGE_WATER_SOURCE,
    LOG_ENTRY_TYPE
} from './MeasurementConstants';

import { decimal10Digits2, decimal10Digits2Message, formatDateTime } from '../constants/MeterFormConstants';

export const validatePage2 = (isDraft, holder) => {
    debugger;
    let retVal = [];
    let now = Date.now();
    let { nomination, referenceData } = holder;

    if (!(nomination.holderName)) {
        if (!isDraft) {
            retVal.push({ field: PRIMARY_HOLDER });
        }
    } else {
        if (nomination.holderName.length > 200) {
            retVal.push({ field: PRIMARY_HOLDER, message: 'length cannot exceed 200 characters' });
        }
    }

    if (!isDraft && !nomination.timeRange.endDate) {
        retVal.push({ field: 'endDate' });
    }

    let start = Date.parse(nomination.timeRange.startDate);
    let end = Date.parse(nomination.timeRange.endDate);

    if (start >= end) {
        retVal.push({ field: 'endDate', message: '<br/>end date cannot be before start date' });
    } else {
        if (start > now || end > now) {
            if (start > now) {
                retVal.push({ field: 'startDate', message: `Start date cannot be a future date` });
            }
            if (end > now) {
                retVal.push({ field: 'endDate', message: `End date cannot be a future date` });
            }
        }
    }

    {//flows
        let flow = nomination.flow;
        if (flow && Object.keys(flow).length > 0) {
            Object.keys(flow).forEach((key, index) => {
                if (!flow[key].amount || flow[key].amount === '') {
                    if (!isDraft) {
                        retVal.push({ field: 'flowAmount' + index });
                    }
                } else {
                    if (!decimal10Digits2.test(flow[key].amount)) {
                        retVal.push({ field: 'flowAmount' + index, message: '<br />' + decimal10Digits2Message });
                    }
                    else if (flow[key].amount <= 1) {
                        retVal.push({ field: 'flowAmount' + index, message: '<br />' + 'Volume should be greater than 1' });
                    }
                }
                if ((!flow[key].waterSource || flow[key].waterSource == '') && !isDraft) {
                    retVal.push({ field: FLOW_WATER_SOURCE + index });
                }
            });
        }
    }

    {//discharges
        let discharge = nomination.discharge;

        if (discharge && Object.keys(discharge).length > 0) {
            Object.keys(discharge).forEach((key, index) => {
                let obj = discharge[key];
                let timeStart = obj.startDate ? Date.parse(obj.startDate) : 0;
                let timeEnd = obj.endDate ? Date.parse(obj.endDate) : 0;

                if (!obj.startDate) {
                    if (!isDraft) {
                        retVal.push({ field: DISCHARGE_START_DATE + index });
                    }
                } else {
                    if (timeStart < start) {
                        retVal.push({ field: DISCHARGE_START_DATE + index, message: '<br />Discharge start date cannot be before the start date of nomination period' });
                    }
                }

                if (!obj.endDate) {
                    if (!isDraft) {
                        retVal.push({ field: DISCHARGE_END_DATE + index });
                    }
                } else {
                    if (timeEnd > end) {
                        retVal.push({ field: DISCHARGE_END_DATE + index, message: '<br />Discharge end date cannot be after the end date of nomination period' });
                    } else {
                        if (timeEnd < timeStart) {
                            retVal.push({ field: DISCHARGE_END_DATE + index, message: '<br/>end date cannot be before start date' });
                        } else {
                            debugger;
                            if (timeStart > now || timeEnd > now) {
                                if (timeStart > now) {
                                    retVal.push({ field: DISCHARGE_START_DATE + index, message: '<br/>Start date cannot be a future date' });
                                }
                                if (timeEnd > now) {
                                    retVal.push({ field: DISCHARGE_END_DATE + index, message: '<br/>End date cannot be a future date' });
                                }
                            }
                        }
                    }
                }

                if (!obj.waterSource && !isDraft) {
                    retVal.push({ field: DISCHARGE_WATER_SOURCE + index });
                }

                if (!obj.amount || obj.amount === '') {
                    if (!isDraft) {
                        retVal.push({ field: DISCHARGE_AMOUNT + index });
                    }
                } else {
                    if (!decimal10Digits2.test(obj.amount)) {
                        retVal.push({ field: DISCHARGE_AMOUNT + index, message: '<br />' + decimal10Digits2Message });
                    } else if (obj.amount <= 1) {
                        retVal.push({ field: DISCHARGE_AMOUNT + index, message: '<br />' + 'Volume should be greater than 1' });
                    }
                }

            });
        }
    }
    if (!isDraft) {//manual log
        const message = '<br />value cannot be  missing or zero';
        if (holder.nomination.logEntry.type && holder.nomination.logEntry.type === LOG_ENTRY_TYPE.MANUAL) {
            if (!holder.nomination.logEntry.priorDayReading.gaugeHeight || holder.nomination.logEntry.priorDayReading.gaugeHeight == 0) {
                retVal.push({ field: MANUAL_ENTRY + PRIOR_GAUGE_HEIGHT, message: message });
            }
            for (let index = 0; index < holder.nomination.logEntry.entryLog.length; index++) {
                if (!holder.nomination.logEntry.entryLog[index].gaugeHeight || holder.nomination.logEntry.entryLog[index].gaugeHeight == 0) {
                    retVal.push({ field: MANUAL_ENTRY + NEW_GAUGE_HEIGHT + index, message: message });
                }
            }
        }

    }
    let pendingStatus = holder.nomination.logEntry.entryLog.find(row => row.status === PENDING)
    let anyNotPendingStatus = holder.nomination.logEntry.entryLog.find(row => row.status !== PENDING)
    if (pendingStatus && !anyNotPendingStatus) {
        retVal.push({ field: TOTAL_TAKE, message: 'incomplete form, data not enough to proceed' });
    }
    return retVal;
}

const isDateinRange = (date, forms) => {
    debugger;
    let ranges = [];
    let dateRangeObject = {
        start: undefined,
        end: undefined,
        id: undefined
    }
    let retVal = undefined;
    if (forms && forms.length > 0) {
        forms.forEach(form => ranges.push({
            ...dateRangeObject,
            start: Date.parse(form.StartDate),
            end: Date.parse(form.EndDate),
            id: form.id

        }))
    }
    for (let i = 0; i < ranges.length; i++) {
        if (date > ranges[i].start && date < ranges[i].end) {
            retVal = ranges[i].id;
            break;
        }
    }

    return retVal;
}


export const isDateTheLatest = (date, forms) => {
    debugger;
    if (!date || !forms || forms.length < 1) {
        return 0;
    }
    let millis = Date.parse(date);
    let index = forms.length - 1;
    let latestFormDate = Date.parse(forms[index].EndDate);
    debugger;
    if (latestFormDate >= millis) {
        // return [NON_COMPLIANCE_REASONS.warningText[0],NON_COMPLIANCE_REASONS.inputMessage[0]];
        return 1;
    }
    let DAY_BACK = Date.now() - (24 * 60 * 60 * 1000);

    console.log("Day_back is" + formatDateTime(DAY_BACK));
    if (millis < DAY_BACK) {
        //return [NON_COMPLIANCE_REASONS.warningText[1],NON_COMPLIANCE_REASONS.inputMessage[1]];
        return 2;
    }

    return 0;
}


