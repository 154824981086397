import * as apiPathes from './apiPathes';
import axios from 'axios';
import uiHelpers from '../helpers/uiHelpers'
import {convertErrorCode} from './conveters'

const ajaxConfig = {
    baseURL: process.env.REACT_APP_WEBAPI_URL,
    withCredentials: true,
    headers: { 
        'Access-Control-Allow-Origin': '*', 
        'Content-Type': 'application/json'
    }
};

const genericHandler = async function(promiseObj, options){

    if(options && options.noDefaultHandle) {
        return await promiseObj.then(function(response){
            if(response.data.noIWASLogin) {
                if(options && options.noIWasLoginHandler) {
                    options.noIWasLoginHandler();
                }  
                return false;
            }
            if(!response.data.success || response.data.errorCode) {
                if(options && options.errorCodeHandler) {
                    options.errorCodeHandler(response.data.errorCode);
                }
                return false;
            }
            if(options && options.success) options.success(response.data.data);
            return true;
        }).catch(error=>{
            if(options && options.genericErrorHandler) options.genericErrorHandler(error);
            return false;
        })
    }

    if(options && options.loadingMsg) uiHelpers.showProgressOnTop(options.loadingMsg);
    else uiHelpers.showProgressOnTop('Loading...');

    if(options && options.beforeSend) options.beforeSend();

    return await promiseObj.then(function(response){
        if(response.data.noIWASLogin) {
            if(options && options.noIWasLoginHandler) {
                options.noIWasLoginHandler();
                uiHelpers.hideProgressOnTop();
            } else uiHelpers.showErrorTop('You are not login...');
            return false;
        }

        //alert(!response.data.success || response.data.errorCode)

        if(!response.data.success || response.data.errorCode) {
            if(options && options.errorCodeHandler) {
                options.errorCodeHandler(response.data.errorCode);
                uiHelpers.hideProgressOnTop();
            } else {
                uiHelpers.showErrorTop(convertErrorCode(response.data.errorCode));
            }
            return false;
        }

        uiHelpers.hideProgressOnTop();
        if(options && options.success) options.success(response.data.data);
        return true;
    }).catch((error)=>{
        console.log(error);
        if(options && options.genericErrorHandler) options.genericErrorHandler(error);
        else uiHelpers.showErrorTop('Error happened on server...');
        return false;
    });
}

export async function createSession(data) {
    return await axios.post(apiPathes.sessionCreation, JSON.stringify(data),ajaxConfig).then(function (response) {
        return response.data;
    });
}

export async function post(path, data, options) {
    return await genericHandler(axios.post(path,JSON.stringify(data),ajaxConfig), options);
}

export async function get(path,options) {
    return await genericHandler(axios.get(path,ajaxConfig), options);
}
