import React from 'react';
import SystemMessage from './common/SystemMessage';
import ReleaseNotes from './common/ReleaseNotes';


class SystemConfig  extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    async componentDidMount() {
        this.setState({
        });
    }


    render() {
        return <>
            <div className='card mb-3'>
                <div className="card-header">
                    System Message
                </div>
                <div className='card-body'>
                    <SystemMessage/>
                </div>
            </div>

            <div className='card mb-3'>
                <div className="card-header">
                    Release Notes
                </div>
                <div className='card-body'>
                    <ReleaseNotes/>
                </div>
            </div>
        </>
    }
}

export default SystemConfig;
