import React from 'react';
import axios from 'axios';
import Authentication from '../helpers/Authentication'
import TransitionalReportingPartAForm from '../components/certificates/transitionalReporting/TransitionalReportingPartA';
import TransitionalReportingPartBForm from '../components/certificates/transitionalReporting/TransitionalReportingPartB';
import TransitionalReportingPartCForm from '../components/certificates/transitionalReporting/TransitionalReportingPartC';
import SearchCustomerForCertificates from '../components/certificates/common/SearchCustomerForCertificates';

import TransitionalReportingPartFForm from '../components/certificates/transitionalReporting/TransitionalReportingPartF';
import TransitionalReportingConfirmation from '../components/certificates/transitionalReporting/TransitionalReportingConfirmation';
import ValidationAndMaintenanceChecklistForm from '../components/certificates/common/ValidationAndMaintenanceChecklist';
import { formValueSelector, reduxForm, change } from 'redux-form'
import { connect } from 'react-redux'
import { load, save, findById, update } from '../components/certificates/transitionalReporting/TransitionalReporting.Reducers';
import { getMeterNumbers , deleteDraftForm} from '../components/certificates/common/MeterCertificateCommon'
import { patternApprovedOptionsForTransitionalReporting as patternApprovedOptions, patternNotApprovedOptionsForTransactionalReporting as  patternNotApprovedOptions} from '../constants/MeterFormConstants'

import uiHelpers from '../helpers/uiHelpers';
import DisplayForRoles from '../components/tools/DisplayForRoles'
import MeterEquipInfoAudit from '../components/certificates/common/MeterEquipInfoAudit'
import CertificateFiles from '../components/certificates/common/CertificateFiles'
import CertificateAdmin from '../components/certificates/common/CertificateAdmin'
import {StepProgressBar} from '../components/tools/BootstrapControls'
import ReApplyConsentNotice from '../components/common/ReApplyConsentNotice'
import { userRole, addUserRoleUpdateNotifier } from '../helpers/userHelpers';

const authentication = new Authentication();

class TransitionalReporting extends React.Component {

  approvedWorkDetails = {};
  authorityHolderDetails = {};
  meteringEquipmentInformation = {};
  #currentUserEmail;
  #currentUserId;

  ajaxConfig = {
    baseURL: process.env.REACT_APP_WEBAPI_URL,
    headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authentication.getToken() }
  };
  

  constructor(props) {
    super(props)
    this.nextPage = this.nextPage.bind(this);
    this.previousPage = this.previousPage.bind(this);
    this.#currentUserEmail = authentication.currentUser().emails;
    this.state = {
      page: -1,
      validationDate: new Date(),
      meterReadingDate: new Date(),
      showInstructions: false,
      meteringCircumstanceOptions:
        [
          { id: "1", name: "I have an existing validation that was done by a duly qualified person.", selected: false },
          { id: "2", name: "I have a manufacturer’s certificate.", selected: false },
          { id: "3", name: "I have an accuracy certificate provided by a duly qualified person.", selected: false }
        ],
      data: {},
      confirmingSubmission : false,
      tab : 'MAIN'
    }

    addUserRoleUpdateNotifier(() => {
      if(userRole.isSuperAdmin){
        this.forceUpdate();
      }
    });
  }

  async componentDidMount() {

    const currentContxt = this;
    axios.post('users/getprofile', JSON.stringify({ email: this.#currentUserEmail }), this.ajaxConfig).then(response => {
      currentContxt.#currentUserId = response.data.data.user.user.id;
      currentContxt.pageRefresh();
      
    }).catch(function (error) {
      console.log(error);
      uiHelpers.showErrorTop('Error occurred when reading your user info');
    });
    
  }

  pageRefresh = async(id) =>{

    if(!id && this.props.meterEquipInfo) {
      id=this.props.meterEquipInfo.id;
    }


    let search = window.location.search;
    let params = new URLSearchParams(search);

    if(!id || id==0) {
      id = params.get("id");
      if(id)
        id = parseInt(id)
    }
    
    if (id && id!=0) {
      try {

        if(this.state.page==-1)
          this.setState({ page: 0});
        
        uiHelpers.showProgressOnTop("Getting/Refreshing certificate data...");
        this.props.dispatch(change(this.props.form, 'loading', true));
        await this.props.dispatch(findById(id));
        uiHelpers.hideProgressOnTop();
        this.props.dispatch(change(this.props.form, 'loading', false));

        if (
          this.props.approvedWorkDetails.approvedByCustomer == 1
          && this.props.approvedWorkDetails.waterSupplyWorkApprovalNo 
          && this.props.approvedWorkDetails.esid
          && this.props.approvedWorkDetails.waterSupplyWorkApprovalNo != "" 
          && this.props.approvedWorkDetails.esid != ""
        ) {

            uiHelpers.showProgressOnTop("Getting meter data...");
            this.props.dispatch(change(this.props.form, 'loading', true));
            var payloadData = await getMeterNumbers(this.props.approvedWorkDetails.waterSupplyWorkApprovalNo, this.props.approvedWorkDetails.esid);
            uiHelpers.hideProgressOnTop();
            this.props.dispatch(change(this.props.form, 'loading', false));

            if (payloadData.success)
              this.props.dispatch(change(this.props.form, 'meterNumberExternalList', payloadData.meterNumberExternalLists));
            else
              uiHelpers.showErrorTop("Error occured when getting meter data", true, false);
        }
      }
      catch (error) {
        uiHelpers.hideProgressOnTop();
        uiHelpers.showErrorTop("Error when getting data...", true, false);
      }

    } else {
      try {
        uiHelpers.showProgressOnTop("Loading data...");
        this.props.dispatch(change(this.props.form, 'loading', true));
        await this.props.dispatch(load());
        uiHelpers.hideProgressOnTop();
        this.props.dispatch(change(this.props.form, 'loading', false));
      } catch (error) {
        uiHelpers.hideProgressOnTop();
        uiHelpers.showErrorTop("Error when getting data...", true, false);
      }

    }
  }

  nextPage = (values) => {
    this.saveDraft(values);
  }

  completeConfirmation = () => {
    this.setState({ confirmingSubmission: false, page: this.state.page + 1 });
  }

  cancelConfrimSubmission = () => {
    this.setState({ confirmingSubmission: false });
  }

  cancelConfrimSubmissionAndGoToPage = (page) => {
    this.setState({ confirmingSubmission: false, page: page });
  }

  saveDraft = (values) => {
    
    if (parseInt(values.clickSaveDraft) === 1 && parseInt(values.clickNext) === 0) {
      //console.log("MeterValidationPage:saving draft");
      this.resetValues(values);
      try {
        uiHelpers.showProgressOnTop("Saving your record...");
        this.props.dispatch(change(this.props.form, 'loading', true));
        if (values.meteringEquipmentInformation.id > 0)
          this.props.dispatch(update(values, this.pageRefresh));
        else
          this.props.dispatch(save(values, this.pageRefresh));
      } catch (error) {
        console.log(error);
        this.props.dispatch(change(this.props.form, 'loading', false));
        uiHelpers.showErrorTop("Error occurred while saving the record")
      }

    } else if (parseInt(values.clickSaveDraft) === 0 && parseInt(values.clickNext) === 1 && values.meteringEquipmentInformation.formStatus == 2) {
      this.resetValues(values);
      try {
        uiHelpers.showProgressOnTop("submitting your record...");
        this.props.dispatch(change(this.props.form, 'loading', true));
        if (values.meteringEquipmentInformation.id > 0)
          this.props.dispatch(update(values, this.pageRefresh));
        else
          this.props.dispatch(save(values, this.pageRefresh));
      } catch (error) {
        console.log(error);
        this.props.dispatch(change(this.props.form, 'loading', false));
        uiHelpers.showErrorTop("Error occurred while saving the record")
      }

    }
    else {
      //debugger;
      //this.setState({ page: this.state.page + 1 });
      if(values.meteringEquipmentInformation.formStatus==1 && this.state.page == 2 && !this.state.confirmingSubmission ) {
        this.setState({ confirmingSubmission: true });
      } else if(values.meteringEquipmentInformation.formStatus==1 && this.state.page == 2 && this.state.confirmingSubmission ) {
        this.setState({ confirmingSubmission: false, page: this.state.page + 1 });
      } else {
        this.setState({ page: this.state.page + 1 });
      }
      //console.log(JSON.stringify(values));
    }

  }

  resetValues = (values) => {
    if (values.meteringEquipmentInformation.newMeterEquipment == "1") {
      values.meteringEquipmentInformation.meterCircumstancesOptions = [];;
      values.meteringEquipmentInformation.dateOfLastValidation = ""
      values.meteringEquipmentInformation.meterRemovedDate = "";
      values.meteringEquipmentInformation.dateBeforeMaintenance = "";
      values.meteringEquipmentInformation.readingBeforeMaintenance = "";
      values.meteringEquipmentInformation.dateAfterMaintenance = "";
      values.meteringEquipmentInformation.readingAfterMaintenance = "";
      values.meteringEquipmentInformation.finalReadingRemovedMeter = "";
      values.meteringEquipmentInformation.finalReadingDateRemovedMeter = ""
      values.meteringEquipmentInformation.otherConclusion = "";
      values.meteringEquipmentInformation.meterBeforeApril2019 = "";
      values.meteringEquipmentInformation.meterPatternApproved = "";
      values.meteringEquipmentInformation.dateOfInstallationBeforeApril2019 = "";
    }
    if (parseInt(values.meteringEquipmentInformation.meteringSystemTypeId) != parseInt(this.props.masterDataValues.OpenChannelId)) {
      values.meteringEquipmentInformation.channelType = "";
      values.meteringEquipmentInformation.channelWidth = "";
      values.meteringEquipmentInformation.meterDistanceFromSource = "";
      values.meteringEquipmentInformation.channelLengthUpstream = "";
      values.meteringEquipmentInformation.channelLlengthDownstream = "";
      values.meteringEquipmentInformation.designEquipmentDetails = "";
      values.meteringEquipmentInformation.meteringEquipmentConsistent = "";
      values.meteringEquipmentInformation.maxMeasuredDischarge = "";
      values.meteringEquipmentInformation.minMeasuredDischarge = "";
      values.meteringEquipmentInformation.maxMeasuredVelocity = "";
      values.meteringEquipmentInformation.minMeasuredVelocity = "";
      values.meteringEquipmentInformation.maxGaugeHeight = "";
      values.meteringEquipmentInformation.minGaugeHeight = "";
      values.meteringEquipmentInformation.maxError = "";
      values.meteringEquipmentInformation.minError = "";
      values.meteringEquipmentInformation.noOfMeasurementsTaken = "";
      values.meteringEquipmentInformation.openChannelConclusion = "";
    }
    else
    {
      values.meteringEquipmentInformation.pipeType = "";
      values.meteringEquipmentInformation.pipeSize = "";
      values.meteringEquipmentInformation.pipeConnectionType = "";
      values.meteringEquipmentInformation.pipeLengthUpstream = "";
      values.meteringEquipmentInformation.pipeLengthDownstream = "";
    }
  }

  nonCompliancePrevForm = (values) => {
    if (this.props.meterEquipInfo.newMeterEquipment != undefined &&
      this.props.meterEquipInfo.newMeterEquipment == "1") {
      this.setState({ page: this.state.page - 2 });
    }
    else {
      this.setState({ page: this.state.page - 1 });
    }
  }

  previousPage = (formStatus) => {
    if(this.state.page == 3 && formStatus == 1 ) {
      this.setState({ confirmingSubmission: true, page: this.state.page - 1 });
    } else if (this.state.page == 3) {
      this.setState({ page: this.state.page - 1 });
    }

    if (this.props.meterEquipInfo.newMeterEquipment == "2"
      && this.state.page == 4) {
      this.setState({ page: this.state.page - 2 });
    }
    else
      this.setState({ page: this.state.page - 1 });
  }

  HideShow = () => {
    this.setState({ showInstructions: !this.state.showInstructions });
  }

  deleteDraft = ()=>{
    if (this.props.meterEquipInfo.id != 0) {
    let currentContext = this;
      deleteDraftForm(
        parseInt(this.props.meterEquipInfo.id)
        , function(){
          currentContext.props.dispatch(change(currentContext.props.form, 'loading', true));
        }
        , function(){
          window.location = 'Home';
        }
        , function(){
          
        }
        , function(error) {
          console.log(error);
          uiHelpers.showErrorTop('Error occurred when trying to delete the draft record ', true, false);
          currentContext.props.dispatch(change(currentContext.props.form, 'loading', false));
        }
      );
    } else {
      uiHelpers.showErrorTop('You are accessing this function in a invalid way', true, false);
    }
  }

  render() {
    const { page, showInstructions, confirmingSubmission } = this.state

    const {  form, loading, consentValid } = this.props;

    const needReApplyConsent = !userRole.isSuperAdmin && (this.props.meterEquipInfo && this.props.meterEquipInfo.formStatus==1 && consentValid===false && !(this.props.meterEquipInfo.createdBy && this.props.meterEquipInfo.createdBy.id != this.#currentUserId)) ? true : null;

    return (<>
      <h3 className='mb-3'>Transitional Arrangements Report</h3>
      <p>
        <a className="btn btn-primary" onClick={this.HideShow}>Privacy and Glossary</a>
      </p>

      {showInstructions && (<div class="card card-body fs-90 mb-5">
        <h5>Privacy Statement</h5>
        <p>
          The personal information you provide on this form is subject to the Privacy & Personal Information
          Protection Act 1989. It is being collected by NSW Department of Planning, Industry and Environment and
          will be used for purposes related to the NSW non-urban water metering policy.
          NSW Department of Planning, Industry and Environment may disclose your personal information to the
          Natural Resources Access Regulator and WaterNSW. NSW Department Planning, Industry and Environment
          will not disclose your personal information to anybody else unless authorised by law.
          The provision of this information is required to be supplied under the Water Management Act 2000.
          If you choose not to provide the requested information you may be in breach of the conditions of your
          water supply work approval, access licence, or licence or other entitlement under the Water Act 1912.
          You have the right to request access to, and correct details of, your personal information held by the
          department. Further information regarding privacy can be obtained from NSW Department of Planning,
          Industry and Environment website at dpie.nsw.gov.au/privacy or by contacting us at
          patiunit@planning.nsw.gov.au.
        </p>

        <h5>Glossary of terms</h5>
        <p>
          The metering requirements are prescribed in the Water Management Act 2000 (WM Act) and the Water Management (General) Regulation 2018, and are set out in the NSW Non-Urban Water Metering Policy. This glossary explains terms used in the regulations when referring to the metering framework.<br />
          <strong>AS 4747</strong> means the Australian Standard AS 4747—2013, Meters for non-urban water supply. This standard is updated from time to time by Standards Australia. [Clause 228 (1)]<br />
          <strong>Authority</strong> means a water supply work approval, an access licence, or a licence or other entitlement under the Water Act 1912. [Clause 228 (1)]<br />
          <strong>Certified meter installer</strong> is a person who holds a current certification as a meter installer issued by Irrigation Australia Ltd. It is a category of duly qualified person. [Clause 236 (1)]<br />
          <strong>Duly qualified person</strong> is a person that has the following qualifications, skills and experience to carry out specified work in connection with metering equipment: (a) certified meter installer (b) certified practising hydrographer (c) metering system designer, or (d) telemetry technician. [Clause 236 (2)]<br />
          <strong>Faulty metering equipment</strong> means metering equipment that is not operating properly or is not operating. [Clause 240]<br />
          <strong>Existing validation</strong> means that the metering equipment installed before 1 April 2019 was validated by a duly qualified person in accordance with AS 4747: (a) within five years before the application day for the equipment, or (b) within 12 months before the application day for the equipment in the case of metering equipment that is installed in connection with an open channel. [Clause 8 of Schedule 8]<br />
          <strong>Maintenance specifications</strong> set out the requirements for how metering equipment must be maintained. They are published in the NSW Government Gazette and on the NSW Department of Industry website. [Clause 2 (4) of Schedule 8]<br />
          <strong>Open channel</strong>  means a channelor conduit used for conveying water that is not enclosed. [Clause 228 (1)]<br />
          <strong>Water take data</strong> means the flow rate and cumulative volume of water taken with respect to a data logger. [Clause 4 (6) of Schedule 8]

        </p>
      </div>)}

      {!confirmingSubmission && page === -1 && <SearchCustomerForCertificates form={form} onSubmit={this.nextPage} />}

      {page >= 0 && this.props.meterEquipInfo && <>
        <div class="card mb-3">
          <div class="card-body row">
              <div className='col-md-3'>
                <label class="form-label">Record ID</label>
                <input type="text" disabled={true} class="form-control" value={this.props.meterEquipInfo.id == 0 ? "Not saved" : this.props.meterEquipInfo.id} />
              </div>
              <div className='col-md-3'>
                <label class="form-label">Form Status</label>
                <input type="text" disabled={true} class="form-control" value={this.props.meterEquipInfo.formStatus==1?'Draft' : 'Submitted'} />
              </div>
              <div className='col-md-3'>
                <label class="form-label">DQP</label>
                <input type="text" disabled={true} class="form-control" value={this.props.meterEquipInfo.createdBy ? (this.props.meterEquipInfo.createdBy.firstName + " " + this.props.meterEquipInfo.createdBy.lastName) : ""} />
              </div>
          </div>
        </div>
        <ul class="nav nav-tabs mb-3">
              <li class="nav-item ">
                <a class={`nav-link ${this.state.tab == 'MAIN'?'active':''}`} aria-current="page" href="#"
                    onClick={(e)=>{e.preventDefault(); this.setState({ tab : 'MAIN'}); }}
                >Record details</a>
              </li>
              <li class="nav-item">
                <a class={`nav-link ${this.state.tab == 'AUDIT'?'active':''}`} href="#"
                    onClick={(e)=>{e.preventDefault(); this.setState({ tab : 'AUDIT'}); }}
                >Audit/Logs</a>
              </li>
              {this.props.meterEquipInfo.formStatus == 2 && <DisplayForRoles roles={{superAdmin : true, isCFOAdmin:true}}>
                <li class="nav-item">
                    <a class={`nav-link ${this.state.tab == 'FILES'?'active':''}`} href="#"
                      onClick={(e)=>{e.preventDefault(); this.setState({ tab : 'FILES'}); }}
                    >Files</a>
                </li>
              </DisplayForRoles>}
              {this.props.meterEquipInfo.formStatus == 2 && <DisplayForRoles roles={{superAdmin : true}}>
                <li class="nav-item">
                    <a class={`nav-link ${this.state.tab == 'ADMIN'?'active':''}`} href="#"
                      onClick={(e)=>{e.preventDefault(); this.setState({ tab : 'ADMIN'}); }}
                    >Admin</a>
                </li>
              </DisplayForRoles>}
          </ul>

          {this.state.tab == 'AUDIT' && <MeterEquipInfoAudit id={this.props.meterEquipInfo.id} /> }
        
          {this.state.tab == 'FILES' && <DisplayForRoles roles={{superAdmin : true, isCFOAdmin:true}}>
              <CertificateFiles 
                form={form} 
                certificateNameForSelector="meteringEquipmentInformation"
                fileTypeResolver={(document)=>{
                  var option = (this.props.meterEquipInfo.meterPatternApproved == 1 ? patternApprovedOptions : patternNotApprovedOptions).filter(option=>option.id==document.documentType)[0];
                  return <>{option?option.name:""}</>
                  
                }}
              />
          </DisplayForRoles> }

          {this.state.tab == 'ADMIN' && this.props.meterEquipInfo.formStatus == 2 && <DisplayForRoles roles={{superAdmin : true, isCFOAdmin:true}}>
            <CertificateAdmin id={this.props.meterEquipInfo.id} entityType="TransitionalArrangements" />
          </DisplayForRoles> }

          {this.state.tab == 'MAIN' && <>

            {needReApplyConsent && <ReApplyConsentNotice approvalNumber={this.props.approvedWorkDetails.waterSupplyWorkApprovalNo} />}

            {this.props.meterEquipInfo.formStatus == 1 && this.props.meterEquipInfo.createdBy && this.props.meterEquipInfo.createdBy.id != this.#currentUserId && <div className='alert alert-primary'>
              This form is owned by <strong>{this.props.meterEquipInfo.createdBy.firstName} {this.props.meterEquipInfo.createdBy.lastName}</strong> and cannot be edited
            </div>}
            <div className='my-5'>      
              <StepProgressBar 
                totalSteps={5} 
                currentStep={confirmingSubmission? 4 : page>2? page+2: page+1} 
                disableFutureSteps={this.props.meterEquipInfo.formStatus == 1} 
                stepClick={(page) =>{this.setState({page : page>=3?page-2:page-1, confirmingSubmission : page==4 })}}/>
            </div>
            {!confirmingSubmission && page === 0 && <TransitionalReportingPartAForm form={form} onSubmit={this.nextPage} disableEditing={(this.props.meterEquipInfo.createdBy && this.props.meterEquipInfo.createdBy.id != this.#currentUserId) || needReApplyConsent} />}
            {!confirmingSubmission && page === 1 && <TransitionalReportingPartBForm form={form} onSubmit={this.nextPage} previousPage={this.previousPage} disableEditing={(this.props.meterEquipInfo.createdBy && this.props.meterEquipInfo.createdBy.id != this.#currentUserId) || needReApplyConsent} />}
            {!confirmingSubmission && page === 2 && <TransitionalReportingPartCForm form={form} onSubmit={this.nextPage} previousPage={this.previousPage} disableEditing={(this.props.meterEquipInfo.createdBy && this.props.meterEquipInfo.createdBy.id != this.#currentUserId) || needReApplyConsent}  />}
            {confirmingSubmission &&  <TransitionalReportingConfirmation form={form} onSubmit={this.nextPage} completeConfirmation={this.completeConfirmation} cancelConfrimSubmissionAndGoToPage={this.cancelConfrimSubmissionAndGoToPage} cancelConfrimSubmission={this.cancelConfrimSubmission} disableEditing={(this.props.meterEquipInfo.createdBy && this.props.meterEquipInfo.createdBy.id != this.#currentUserId) || needReApplyConsent} /> }
            {page === 3 && <TransitionalReportingPartFForm form={form} onSubmit={this.nextPage} previousPage={this.previousPage} disableEditing={(this.props.meterEquipInfo.createdBy && this.props.meterEquipInfo.createdBy.id != this.#currentUserId) || needReApplyConsent} />}
          </>}
      </>}



        {/* start the delete draft dialog */}
        <div class="modal fade" id="deleteDraftModal" tabindex="-1" role="dialog" aria-labelledby="deleteDraftModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
            <div class="modal-dialog modal-dialog-centered" role="document">
               <div class="modal-content">
                  <div class="modal-header">
                     <button type="button" id="deleteDraftModalCloseBtn"  disabled={loading} class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div class="modal-body">
                    <strong>Are you sure you want to delete this draft version of the form?</strong>
                  </div>
                  <div class="modal-footer">
                    
                      <button type="button" class="btn btn-secondary" disabled={loading} data-bs-dismiss="modal">Cancel</button>
                    
                      <button type="button" class="btn btn-primary"
                        onClick={() => { this.deleteDraft(); }}
                        disabled={loading}
                      >Confirm deletion</button>
                    
                  </div>
               </div>
            </div>
         </div>
         {/* end the delete draft dialog */}

      

      <div class="modal fade" id="checkListModal" tabindex="-1" role="dialog" aria-labelledby="checkListModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="checkListModalLabel">Validation and Maintenance Checklist</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <ValidationAndMaintenanceChecklistForm form={form} />
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>

            </div>
          </div>
        </div>
      </div>

    </>);
  }
}


TransitionalReporting = reduxForm({
  enableReinitialize: true,

})(TransitionalReporting)

TransitionalReporting = connect(
  (state, props) => ({

    initialValues: state.reducerTransitionalReporting,
    loaded: state.reducerTransitionalReporting.loaded,
    meterEquipInfo: formValueSelector(props.form)(state, 'meteringEquipmentInformation'),
    approvedWorkDetails: formValueSelector(props.form)(state, 'approvedWorkDetails'),
    authorityHolderDetails: formValueSelector(props.form)(state, 'authorityHolderDetails'),
    masterDataValues: formValueSelector(props.form)(state, 'mdata'),
    loading: formValueSelector(props.form)(state, 'loading'),
    form: props.form,
    showSpinner: formValueSelector(props.form)(state, 'showSpinner'),
    consentValid: formValueSelector(props.form)(state, 'consentValid')
    // pull initial values from account reducer
  }),
  {
    load: (payload) => load(payload),
    save: (payload) => save(payload),
    findById: (id) => findById(id),
    update: (payload) => update(payload)           // bind account loading action creator
  }
)(TransitionalReporting)

export default TransitionalReporting;


