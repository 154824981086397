import { SubmissionError } from 'redux-form'
import { METER_SAVE_CLICK_DRAFT,
    METER_RESET_CLICK_DRAFT,
    METER_SAVE_CLICK_NEXT,
    METER_RESET_CLICK_NEXT, METER_FORM_STATUS} from '../../../constants/MeterFormConstants'

export const saveDraftAction =(props, loadRatingTableContents = false) => {
    if(!props.dirty)
    {
        alert("There is nothing to save");
        return;
    }    

    if(loadRatingTableContents) {
        if(!window.confirm('Please note that your uploaded file will not be saved with the draft form. Your new rating table/storage curve will only be saved once this form is submitted.')) return;
    }


    submitForm({props: props,clickSaveDraft: METER_SAVE_CLICK_DRAFT, clickNext: METER_RESET_CLICK_NEXT, formStatus: METER_FORM_STATUS.DRAFT});
}

export const clickNextAction =(props) => {
    submitForm({props: props,clickSaveDraft: METER_RESET_CLICK_DRAFT, clickNext: METER_SAVE_CLICK_NEXT, formStatus: METER_FORM_STATUS.DRAFT});
}

export const saveSubmitAction =(props) => {
    submitForm({props: props,clickSaveDraft: METER_RESET_CLICK_DRAFT, clickNext: METER_SAVE_CLICK_NEXT, formStatus: METER_FORM_STATUS.FINAL });
}
export const submitForm = function (params) {
    const callHandleSubmit = params.props.handleSubmit(values => {
    return new Promise((resolve, reject) => {        
       const errors = params.props.validate(values, params);        
       const objError = flattenObject(errors);
       const errorEl = 
          Object.keys(objError).filter(key => objError[key] != "").reduce((a, c) => {a[c] = objError[c]; return a}, {})       
       if (Object.keys(errorEl).length > 0) {
       reject(new SubmissionError(errors));
       } else {
       resolve(params.props.onSubmit({...values, clickSaveDraft: params.clickSaveDraft,clickNext: params.clickNext, updateRatingTableData: { ...values.updateRatingTableData, formStatus: params.formStatus }}));
       }
    })
    });
    callHandleSubmit();
 }

const flattenObject = (obj, prefix = '') =>
    Object.keys(obj).reduce((acc, k) => {
    const pre = prefix.length ? prefix + '.' : '';
    if (typeof obj[k] === 'object') Object.assign(acc, flattenObject(obj[k], pre + k));
    else acc[pre + k] = obj[k];
    return acc;
}, {});

export const scrollTofocusError = (errors) =>
{
    if(errors == undefined || errors == null || errors == {} ) return; 
    console.log("dddderrr"+JSON.stringify(flattenObject(errors)))
    let objError = flattenObject(errors);
    objError = Object.keys(objError).filter(key => objError[key] != "").reduce((a, c) => {a[c] = objError[c]; return a}, {})
    console.log("scrollTofocusError"+JSON.stringify(objError));
    if(Object.keys(objError).length === 0 ) return;
    const errorEl = document.querySelector(
        Object.keys(objError).filter(function(key){
            return objError[key] != "";
        }).map(fieldName => {if(fieldName != "" ) return `[name="${fieldName}"]`}).join(',') || ''
    );
    if (errorEl) {
        errorEl.scrollIntoView({ block: "center" });
    } 
}