import React from 'react';
import { UneditableTextInput } from '../../../../components/tools/BootstrapControls';

export default class ValidationReplacingMeterFields  extends React.Component {

    render = () => {
        const form = this.props.validationForm;
        const meter = form.val('replacingMeter');
        return <>
            <div className='row mb-3'>
                <div className='col-md-4 py-2'>Meter Number</div>
                <div className='col-md-8'>
                    <UneditableTextInput fieldExtraClass="bg-light" value={meter.meterNumber} />
                </div>
            </div>


            {form.renderUneditableTextInputRow("replacingMeter",<>Meter System Type </>,{rowClasses:'mb-3',converter:m=>m.meteringSystemTypes?m.meteringSystemTypes.name:null})}
            {form.renderUneditableTextInputRow("replacingMeter",<>Meter Manufacturer &amp; Model </>,{rowClasses:'mb-3',converter:m=>m.meterTypeModel?m.meterTypeModel.name:null})}
            {meter.meterTypeModel && meter.meterTypeModel.key=="Other" && 
                form.renderUneditableTextInputRow("replacingMeter",<>Other Manufacturer Text </>,{rowClasses:'mb-3',converter:m=>m.otherModelText})
            }
            {form.renderUneditableTextInputRow("replacingMeter",<>Meter Serial Number </>,{rowClasses:'mb-3',converter:m=>m.meterSerialNumber})}
            {form.renderUneditableTextInputRow("replacingMeter",<>Meter Size(mm)  </>,{rowClasses:'mb-3',converter:m=>m.meterSize})}
            {form.renderUneditableTextInputRow("replacingMeter",<>Pattern Approval Number  </>,{rowClasses:'mb-3',converter:m=>m.patternApprovalNumber})}
        </>
    }
}