import React from 'react';
import { Field, reduxForm, formValueSelector, change } from 'redux-form';
import { connect } from 'react-redux'
import 'react-redux';
import 'redux';
import { validate } from '../common/AuthorityHolderDetailsFormSection.Validation'
import { saveDraftAction, clickNextAction, scrollTofocusError } from "../common/MeterFormValidations"
import {InputTextForRedux} from '../../tools/BootstrapControls'


let TransitionalReportingPartA = props => {

  const {
    handleSubmit, meterEquipInfo,authHolderInfo, loading, hasError
  } = props;
  const disableFields = (parseInt(meterEquipInfo.formStatus) == 2) || props.disableEditing || loading? true : false;

  return (
    <form id="formA" onSubmit={handleSubmit}>
      <h5 className='mb-3'>Authority Holder Details</h5>
      {(authHolderInfo.firstName != null && authHolderInfo.lastName != null && authHolderInfo.firstName != '' && authHolderInfo.lastName != '') &&
        <div className='row mb-3'>
          <div className='col-sm-7'>
            <Field
              name="authorityHolderDetails.authorityHolderName"
              label={<>Authority holder name(s) <span className='text-danger'>*</span></>}
              component={InputTextForRedux}
              props={{ disabled: disableFields}}
            />
          </div>
        </div>
      }
      <div className='row mb-3'>
        <div className='col-sm-7'>
          <Field
            name="authorityHolderDetails.corporationNameOrAcn"
            label="Corporation name and ACN"
            component={InputTextForRedux}
            props={{ disabled: disableFields }}
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-sm-7'>
          <Field
            name="authorityHolderDetails.postalAddress"
            label={<>Postal address <span className='text-danger'>*</span></>}
            component={InputTextForRedux}
            props={{ disabled: disableFields }}
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-sm-7'>
          <Field
            name="authorityHolderDetails.phone"
            label={<>Phone <span className='text-danger'>*</span></>}
            component={InputTextForRedux}
            props={{ disabled: disableFields, mandatory: true }}
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-sm-7'>
          <Field
            name="authorityHolderDetails.alternatePhone"
            label="Alternate phone"
            component={InputTextForRedux}
            props={{ disabled: disableFields }}
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-sm-7'>
          <Field
            name="authorityHolderDetails.emailAddress"
            label={<>Email address <span className='text-danger'>*</span></>}
            component={InputTextForRedux}
            props={{ disabled: disableFields }}
          />
        </div>
      </div>
      
      
      <h5 className='mb-3'>Contact Person or Agent</h5>
      <p><i>If the authority holder is not the main contact for this certificate, provide a contact person or agent’s name and details below</i></p>

      <div className='row mb-3'>
        <div className='col-sm-7'>
          <Field
            name="authorityHolderDetails.contactPersonName"
            label="Name"
            component={InputTextForRedux}
            props={{ disabled: disableFields }}
          />
        </div>
      </div>

      <div className='row mb-3'>
        <div className='col-sm-7'>
          <Field
            name="authorityHolderDetails.contactPersonPhone"
            label="Phone"
            component={InputTextForRedux}
            props={{ disabled: disableFields }}
          />
        </div>
      </div>

      <div className='row mb-3'>
        <div className='col-sm-7'>
          <Field
            name="authorityHolderDetails.contactPersonEmailAddress"
            label="Email address"
            component={InputTextForRedux}
            props={{ disabled: disableFields }}
          />
        </div>
      </div>

      {hasError && <div className='alert alert-danger my-3'>
      Please fix all errors before you continue
      </div>}

      <div className="text-end">
        {(parseInt(meterEquipInfo.formStatus) == 1)  && (!props.disableEditing)  && <>
          {meterEquipInfo.id!=0 && <button type="button"  className="btn btn-danger me-3" disabled={loading} 
            data-bs-toggle="modal" data-bs-target="#deleteDraftModal">
            Delete Draft
          </button>}
        
          <button type="button"  className="btn btn-primary" disabled={loading}
            onClick={() => { saveDraftAction(props); }}>
            Save Draft
          </button>

          <div className='vr mx-3'></div>
        </>}

        <button type="button"  className="btn btn-success"
          disabled={loading}
          onClick={() => { clickNextAction(props); }}>
          Next
        </button>
      </div>
    </form>
  )
}

TransitionalReportingPartA = reduxForm({
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate,
  onSubmitFail: (errors, dispatch ) => {
    dispatch(change('certificate', 'hasError', true));
  },
  onSubmitSuccess:(result , dispatch )=>{
      dispatch(change('certificate', 'hasError', false));
      window.scrollTo(0, 0);
  }
})(TransitionalReportingPartA)

TransitionalReportingPartA = connect(
  (state, props) => {
    const selectorCert = formValueSelector('certificate')
    const hasError = selectorCert(state, 'hasError')

    const selector = formValueSelector(props.form);
    const masterDataValues = selector(state, 'mdata');
    const authHolderInfo = selector(state, 'authorityHolderDetails');
    const meterEquipInfo = selector(state, 'meteringEquipmentInformation');
    const loading = selector(state, 'loading');
    return {
      masterDataValues,
      authHolderInfo,
      meterEquipInfo,
      form: props.form,
      loading,
      hasError
    }
  }
)(TransitionalReportingPartA)

export default TransitionalReportingPartA;
