import React from 'react';
import MeterValidationCopyFormWorkFlow from '../components/certificates/meterValidation/MeterValidationCopyFormWorkFlow';

class MeterValidationCopy extends React.Component {

  constructor(props) {
    super(props)
    this.state = {CERT_NAME: 'Maintenance Validation Certificate'}
  }

  render() {
    return <>
      <h3 className='mb-3'>{this.state.CERT_NAME}</h3>
      <div className='alert alert-warning my-3'>
          This function is replaced by the Meter Compliance Process and it is no longer avaiable
      </div>
      {/*<MeterValidationCopyFormWorkFlow />*/}
    </>;
  }
}

export default MeterValidationCopy;


