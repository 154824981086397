import React from 'react';
import moment from 'moment';
import * as apiPathes from '../apiPathes';
import * as serviceCaller from '../serviceCaller'
import DatePicker from 'react-datepicker';
import {Refresh} from '../../components/tools/Icons'
import uiHelpers from '../../helpers/uiHelpers'
import {periodStatus, pageInitialising} from '../constants'
import {convertStatus} from '../conveters'
import { Redirect } from 'react-router-dom';


class Dashboard  extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            disableFunctions: true,
            initialising: true,
            criterias: {
                searchStartDate: null,
                searchEndDate: null,
                statusOpen: true,
                statusClosed: true,
                statusFinalised: false
            }
        };
    }

    allRecords;
    filteredRecords;
    componentDidMount(){
        this.refresh();
        if(uiHelpers.getCookie('showLandingPopup')=='true') return;
        if(this.props.openHelpPopup){
            this.props.openHelpPopup();
            uiHelpers.setCookie("showLandingPopup","true")
        }
        
    }

    refresh = ()=>{
        serviceCaller.get(apiPathes.getNominatedMeasurementPeriods,{
            noIWasLoginHandler: this.props.noLoginCallback,
            loadingMsg: 'Loading your measurement periods...',
            beforeSend: ()=>{
                this.setState({disableFunctions: true})
            },
            success: (data)=>{
                this.allRecords = data;
                this.applyCriterias();
                this.setState({disableFunctions: false,initialising: false});
            }
        });
    }

    criteriasChanged = () => {
        this.setState(this.state);
        this.applyCriterias();
    }

    resetCriterias = () =>{
        this.state.criterias= {
            searchStartDate: null,
            searchEndDate: null,
            statusOpen: true,
            statusClosed: true,
            statusFinalised: false
        };
        this.criteriasChanged();
    }

    applyCriterias =() => {
        this.filteredRecords = [];
        let startDate;
        let endDate;
        let match;
        const csd = this.state.criterias.searchStartDate;
        const ced = this.state.criterias.searchEndDate
        this.allRecords.forEach(r=>{
            startDate = r.periodStartDate ? new Date(r.periodStartDate) : null;
            endDate = r.periodEndDate ? new Date(r.periodEndDate) : new Date();
            match = true;
            if(
                ((csd && startDate && startDate <= csd) && (csd && endDate && endDate <= csd))
                ||
                ((ced && startDate && startDate >= ced) && (csd && endDate && endDate >= ced))
            ) match = false;
            if(periodStatus.open.value == r.status && !this.state.criterias.statusOpen) match=false;
            else if(periodStatus.closed.value == r.status && !this.state.criterias.statusClosed) match=false;
            else if(periodStatus.finalised.value == r.status && !this.state.criterias.statusFinalised) match=false;
            if(match){
                this.filteredRecords.push(r);
            }
        });
        this.setState(this.state)
    }

    openPeriod =(id)=>{
        this.setState({redirectTo: '/details/' + id})
    }

    render() {
        return <>

            {this.state.initialising && <>
                <h3 className='mb-3'>Nominated measurement period dashboard</h3>
                {pageInitialising}
            </>}

            {!this.state.initialising && <>
                {this.state.redirectTo && <Redirect to={this.state.redirectTo} />}
                <h3 className='mb-3'>Nominated measurement period dashboard</h3>
                
                <div class="card  mb-4">
                    <div class="card-body">
                        {/**
                        <button class="btn btn-outline-primary me-3 btn-sm" disabled={this.state.disableFunctions} onClick={this.refresh}>
                            <Refresh/>
                        </button>
                         */}
                        <a href="/measurement-period/#/new" class={`btn btn-primary me-3 ${this.state.disableFunctions ? "disabled" : ""}`}>
                            New period
                        </a>
                    </div>
                </div>
                <div className="card mb-5">
                    <div className="card-header">
                        Search measurement periods
                    </div>
                    <div className='card-body'>
                        <div className="row">
                            
                            <div className="col-md-3 mb-2">
                                <div className="react-datepicker-block">
                                    <label class="form-label d-block">
                                        <strong>Periods overlap between:</strong>
                                    </label>
                                    
                                    <DatePicker
                                    className={`form-control`}
                                    maxDate={new Date()}
                                    onChange={(date)=>{this.state.criterias.searchStartDate = date;this.forceUpdate()}}
                                    selected={this.state.criterias.searchStartDate}
                                    showTimeSelect
                                    timeIntervals={15}
                                    dateFormat={"dd/MM/yyyy h:mm aa"}
                                    timeCaption="Time"
                                    disabled={this.state.disableFunctions}
                                    />
                                </div>
                            </div>
                            <div className="col-md-3 mb-2">
                                <div className="react-datepicker-block">
                                    <label class="form-label d-block">
                                        <strong>and:</strong>
                                    </label>
                                    
                                    <DatePicker
                                    className={`form-control`}
                                    maxDate={new Date()}
                                    onChange={(date)=>{this.state.criterias.searchEndDate = date;this.forceUpdate()}}
                                    selected={this.state.criterias.searchEndDate}
                                    showTimeSelect
                                    timeIntervals={15}
                                    dateFormat={"dd/MM/yyyy h:mm aa"}
                                    timeCaption="Time"
                                    disabled={this.state.disableFunctions}
                                    />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <p className="mb-1">
                                    <strong>Status</strong>
                                </p>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" id="check_open" 
                                        onChange={()=>{this.state.criterias.statusOpen = !this.state.criterias.statusOpen;this.forceUpdate()}} 
                                        checked={this.state.criterias.statusOpen}  disabled={this.state.disableFunctions}
                                    />
                                    <label className="form-check-label" for="check_open">{periodStatus.open.desc}</label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" id="check_closed" 
                                        onChange={()=>{this.state.criterias.statusClosed = !this.state.criterias.statusClosed;this.forceUpdate()}} 
                                        checked={this.state.criterias.statusClosed}  disabled={this.state.disableFunctions}
                                    />
                                    <label className="form-check-label" for="check_closed">{periodStatus.closed.desc}</label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" id="check_finalised" 
                                        onChange={()=>{this.state.criterias.statusFinalised = !this.state.criterias.statusFinalised;this.forceUpdate()}} 
                                        checked={this.state.criterias.statusFinalised}  disabled={this.state.disableFunctions}
                                    />
                                    <label className="form-check-label" for="check_finalised">{periodStatus.finalised.desc}</label>
                                </div>
                            </div>
                        </div>
                        <div>
                            <button className='btn btn-primary me-3' onClick={this.criteriasChanged}>Search</button>
                            <button className='btn btn-outline-secondary' onClick={this.resetCriterias}>Reset</button>
                        </div>
                    </div>
                </div>

                <table class="table table-hover table-sm align-middle">
                    <thead>
                        <tr>
                            <th style={{"width":"40px"}}>ID</th>
                            <th style={{"width":"80px"}}>Status</th>
                            <th style={{"width":"180px"}}>Approval</th>
                            <th style={{"width":"180px"}}>FPH Volume (ML)</th>
                            <th style={{"width":"200px"}}>Start Date &amp; Time</th>
                            <th>End Date &amp; Time</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.filteredRecords.length==0 && <tr className="table-secondary">
                            <td colSpan={6} className="text-center">No record found...</td>    
                        </tr>}
                        {this.filteredRecords.map(record => <tr style={{"cursor":"pointer"}} onClick={()=>this.openPeriod(record.id)} >
                            <td>{record.id}</td>
                            <td>{convertStatus(record.status)}</td>
                            <td>{record.approvalNumber}</td>
                            <td>{record.totalFPHVolume}</td>
                            <td>{record.periodStartDate ? moment((new Date(record.periodStartDate))).format('DD/MM/YYYY h:mm A') : ''}</td>
                            <td>{record.periodEndDate ? moment((new Date(record.periodEndDate))).format('DD/MM/YYYY h:mm A') : ''}</td>
                        </tr>)}
                    </tbody>
                </table>
            </>}
        
        </>
    }
}

export default Dashboard;