import React from 'react';
import { Field, reduxForm, formValueSelector, change } from 'redux-form';
import { connect } from 'react-redux'
import 'react-redux';
import 'redux';
import "react-datepicker/dist/react-datepicker.css";

import { saveDraftAction, clickNextAction, scrollTofocusError } from "./Validations"
import { validateCertificatePartC as validate} from './Validation';
import {dropdownDataFromReferenceData} from '../common/MeterCertificateCommon';
import { DatePickerForRedux, SelectForRedux, TextAreaForRedux}  from '../../tools/BootstrapControls'

let CertificatePartC = props => {

   const {
      handleSubmit, masterDataValues ,updateRatingTableData, previousPage, loading, hasError
   } = props


   let certOptions = dropdownDataFromReferenceData(masterDataValues.refData,"updateReason");
   console.log("certOptions "+ JSON.stringify(certOptions));
   


   const disableFields = (parseInt(updateRatingTableData.formStatus) == 2)|| props.disableEditing  || loading ? true : false; 
   const resetValues = (event) => {    
   }  

   if (updateRatingTableData.validationDate != null && updateRatingTableData.validationDate != "")
   updateRatingTableData.validationDate = new Date(updateRatingTableData.validationDate);   

   if (updateRatingTableData.certificateDate != null && updateRatingTableData.certificateDate != "")
   updateRatingTableData.certificateDate = new Date(updateRatingTableData.certificateDate);

   if (updateRatingTableData.surveyDate != null && updateRatingTableData.surveyDate != "")
   updateRatingTableData.surveyDate = new Date(updateRatingTableData.surveyDate);   

   return (
      <form className="vertical" onSubmit={handleSubmit}>
         <h5 className='mb-3'>Update Details</h5>

         <div className='row mb-3'>
            <div className='col-sm-6'>
               <Field
                  name="updateRatingTableData.updateReason"
                  component={SelectForRedux}
                  options={certOptions}
                  label={<>Reason for update <span className='text-danger'>*</span></>}
                  props={{ disabled: disableFields, mandatory: true }}
               />  
            </div>
         </div>
         <div className='mb-3'>
            <Field
               name="updateRatingTableData.updateDescription"
               props={{ disabled: disableFields}}
               label={<>Description of reason <span className='text-danger'>*</span></>}
               component={TextAreaForRedux}
               cols={40}
               rows={10}
            />  
         </div>
         <div className='mb-3'>
            <Field
               name="updateRatingTableData.surveyType"
               props={{ disabled: disableFields, divClass: 'col_12 column', mandatory: true }}
               label={<>Type of Survey used to capture rating table data <span className='text-danger'>*</span></>}
               component={TextAreaForRedux}
               cols={40}
               rows={10}
            />  
         </div>
         <div className='row mb-3'>
            <div className='col-sm-3'>
               <Field
                  name="updateRatingTableData.surveyDate"
                  label={<>Date of survey <span className='text-danger'>*</span></>}
                  handleChange={date => { props.change(props.form, 'updateRatingTableData.surveyDate', date) }}
                  component={DatePickerForRedux}                        
                  props={{ disabled: disableFields, maxDate: new Date()}}
                  values={updateRatingTableData.surveyDate}
               />  
            </div>
         </div>

         {hasError && <div className='alert alert-danger'>
            Please fix all errors before you continue
         </div>}

         <div className="text-end">
           

            {(parseInt(updateRatingTableData.formStatus) == 1) && (!props.disableEditing) && <>
               {updateRatingTableData.id!=0 && <button type="button"  className="btn btn-danger me-3" disabled={loading} 
               data-bs-toggle="modal" data-bs-target="#deleteDraftModal">
                  Delete Draft
               </button>}
               <button type="button"  className="btn btn-primary" disabled={loading}
                  onClick={() => { saveDraftAction(props); }}>
                  Save Draft
               </button>
               <div className='vr mx-3'></div>
            </>}
            <button type="button"  className="btn btn-warning me-3" disabled={loading} onClick={()=>{previousPage(updateRatingTableData.formStatus)}}>
               Previous
            </button>
            <button type="button" className="btn btn-success" disabled={loading}
                  onClick={() => { clickNextAction(props); }}>
                  Next
            </button>


         </div>
      </form>
   )
}

CertificatePartC = reduxForm({
   destroyOnUnmount: false,
   forceUnregisterOnUnmount: true,
   validate ,
   onSubmitFail: (errors, dispatch ) => {
      dispatch(change('certificate', 'hasError', true));
   },
   onSubmitSuccess:(result , dispatch )=>{
      dispatch(change('certificate', 'hasError', false));
      window.scrollTo(0, 0);
   }
})(CertificatePartC)

CertificatePartC = connect(
   (state, props) => {     
      const selectorCert = formValueSelector('certificate')
      const hasError = selectorCert(state, 'hasError')

      const selector = formValueSelector(props.form)
      const masterDataValues = selector(state, 'mdata')
      const documents = selector(state, 'documents')
      const updateRatingTableData = selector(state, 'updateRatingTableData')
      const loading = selector(state, 'loading')
      const form = props.form
      return {
         masterDataValues,
         updateRatingTableData,
         documents,
         form,
         loading,
         hasError
      }
   }
)(CertificatePartC)

export default CertificatePartC