import React from 'react';
import ApprovalFields from '../../../../components/common/ApprovalFields';
import { converters } from '../../../../helpers/converters';
import moment from 'moment';
import ValidationSupportingDocRO from './ValidationSupportingDocRO';
import { UneditableTextInput } from '../../../../components/tools/BootstrapControls';
import { MapView } from '../../../../components/tools/MapView';
import ValidationReplacingMeterFields from './ValidationReplacingMeterFields';
import ValidationReplacingMeterLocation from './ValidationReplacingMeterLocation';
import ValidationReplacingLidFields from './ValidationReplacingLidFields';
import ValidationReplacingWasMeterFields from './ValidationReplacingWasMeterFields';
import { isStringNullOrEmpty } from '../../../../helpers/validators';

export default class ValidationAllFieldsRO  extends React.Component {

    render() {
        const approval = this.props.approval;
        const form = this.props.validationForm;

        return <>
            <div class="card mb-3">
                <div class="card-header bg-primary text-white">
                    <b>Summary </b>
                </div>
                <div className='card-body'>

                    <p class="fw-bold border-bottom pb-2 mb-3">Site Commissioning Information</p>
                    {form.renderUneditableTextInputRow('meterUnitOfMeasures',<>Unit of measure as displayed on meter</>,{rowClasses:'mb-3',converter:o=>o?o.name:null})}
                    {form.renderUneditableTextInputRow('meterFlowUnits',<>Unit of measure transmitted by LID</>,{rowClasses:'mb-3', converter:u=>u?u.name:null})}

                    <p class="fw-bold border-bottom pb-2 mb-3 mt-5">Work Approval Details</p>
                    <ApprovalFields approval={approval} authoriserDlwcInpaAddrId={this.props.authoriserDlwcInpaAddrId} />
                    <p class="fw-bold border-bottom pb-2 mb-3 mt-5">Details of Approved Works</p>

                    
                    
                    {form.renderUneditableTextInputRow('dlwcProperty',<>Property</>,{rowClasses:'mb-3',converter:p=>p?p.propertyName:null})}
                    {form.renderUneditableTextInputRow('dlwcLot',<>Lot/DP</>,{rowClasses:'mb-3',converter:o=>o?<>{o.lotNumber}/{o.sectionNumber}/{o.planNumber} {o.planType}</>:null})}
                    {form.renderUneditableTextInputRow('extractionSite',<>Extraction Site</>,{rowClasses:'mb-3',converter:site=>site?site.esid:null})}
                    {form.renderUneditableTextInputRow('extractionSite',<>Water Sharing Plan</>,{rowClasses:'mb-3',converter:site=>site?site.waterSharingPlanDesc:null})}
                    {form.renderUneditableTextInputRow('extractionSite',<>Water Source</>,{rowClasses:'mb-3',converter:site=>site?site.waterSourceName:null})}
                    {form.renderUneditableTextInputRow('extractionSite',<>Water Type</>,{rowClasses:'mb-3',converter:site=>site?site.waterType:null})}
                    {form.renderUneditableTextInputRow('works',<>Works ID</>,{rowClasses:'mb-3',converter:works=>works?works.worksId:null})}
                    {form.renderUneditableTextInputRow('works',<>Works Description</>,{rowClasses:'mb-3',converter:works=>works?works.workType:null})}

                    
                    
                    <p class="fw-bold border-bottom pb-2 mb-3 mt-5">Customer Site Details</p>
                    
                    {form.renderUneditableTextInputRow('customersSiteName',<>Customer Site Name</>,{rowClasses:'mb-3'})}
                    {form.renderUneditableTextInputRow('customersPumpNumber',<>Customer Pump Number</>,{rowClasses:'mb-3'})}

                    {form.val('newMeterEquipment') == '3' && <>
                        {form.val('replacingMeter') ? <>
                            <p class="fw-bold border-bottom pb-2 mb-3 mt-5"> Old Meter Details</p>
                            <ValidationReplacingMeterFields validationForm={form}/>
                            <p class="fw-bold border-bottom pb-2 mb-3 mt-5"> Old Meter Location</p>
                            <ValidationReplacingMeterLocation validationForm={form}/>
                            <p class="fw-bold border-bottom pb-2 mb-3 mt-5"> Old Lid Details</p>
                            <ValidationReplacingLidFields validationForm={form}/>
                        </> :  form.val('replacingWasMeter') ? <>
                            <p class="fw-bold border-bottom pb-2 mb-3 mt-5"> Old Meter Details</p>
                            <ValidationReplacingWasMeterFields validationForm={form}/>
                        </>:null}
                        
                    </>}
                    
                    <p class="fw-bold border-bottom pb-2 mb-3 mt-5">
                        {(form.val('newMeterEquipment') == '1' || form.val('newMeterEquipment') == '3') && 'New '}
                        {(form.val('newMeterEquipment') == '2' || form.val('newMeterEquipment') == '4' || form.val('newMeterEquipment') == '6') && 'Existing '}
                        Meter Details
                    </p>

                    <div className='row mb-3'>
                        <div className='col-md-4 py-2'>Meter Number</div>
                        <div className='col-md-8'>
                            <UneditableTextInput fieldExtraClass="bg-light" value={this.props.meter.meterNumber} />
                        </div>
                    </div>

                    {form.renderUneditableTextInputRow('meteringSystemTypes',<>Meter System Type</>,{rowClasses:'mb-3',converter:o=>o?o.name:null})}
                    {form.renderUneditableTextInputRow('meterTypeModels',<>Meter Manufacturer Model</>,{rowClasses:'mb-3',converter:o=>o?o.name:null})}
                    {form.val('meterTypeModels') && form.val('meterTypeModels').key=='Other' && form.renderUneditableTextInputRow("meterTypeModel",<>Other Meter Manufacturer and Model</>,{rowClasses:'mb-3'})}
                    
                    {form.renderUneditableTextInputRow('meterSerialNo',<>Meter Serial Number</>,{rowClasses:'mb-3'})}
                    {form.renderUneditableTextInputRow('meterSize',<>Meter Size(mm)</>,{rowClasses:'mb-3'})}
                    {form.renderUneditableTextInputRow('patternApprovalNumber',<>Pattern Approval Number (if applicable)</>,{rowClasses:'mb-3'})}
                    {form.renderUneditableTextInputRow('supplyType',<>Type of Supply</>,{rowClasses:'mb-3',converter:o=>o?o.name:null})}
                    <div className='row'>
                        <div className='col-md-4 py-2'>Tamper-evident Seal Numbers</div>
                        <div className='col-md-8 py-2'>
                            {form.val('tamperSealNumbers').length==0 && <i className='text-muted mb-3 d-inline-block'>No tamper seal number</i>}
                            {form.val('tamperSealNumbers').map(n=><span className='bg-light me-3 p-2 d-inline-block'>{n.sealNumber}</span>)}
                        </div>
                    </div>
                    
                    <p class="fw-bold border-bottom pb-2 mb-3 mt-5">
                        {(form.val('newMeterEquipment') == '1' || form.val('newMeterEquipment') == '3') && 'New '}
                        {(form.val('newMeterEquipment') == '2' || form.val('newMeterEquipment') == '4' || form.val('newMeterEquipment') == '6') && 'Existing '}
                        Meter Location
                    </p>

                    <div className='row'>
                        <div className='col-md-3 mb-3'>
                            <UneditableTextInput label={"Latitude"} value={form.val('latitude')} fieldExtraClass="bg-light"/>
                        </div>
                        <div className='col-md-3 mb-3'>
                            <UneditableTextInput label={"Longitude"} value={form.val('longitude')} fieldExtraClass="bg-light"/>
                        </div>
                    </div>
                    <MapView
                        readOnly={true}                
                        point={{ latitude: form.val('latitude'), longitude: form.val('longitude')}}
                        disabled={true}
                    />
                    
                    
                    {form.val('meteringSystemTypes') && (form.val('meteringSystemTypes').key=='CLOSED_CONDUIT' || form.val('meteringSystemTypes').key=='PORTABLE_METER') && <>
                        <p class="fw-bold border-bottom pb-2 mb-3 mt-5">Emplacement Details</p>
                        {form.renderUneditableTextInputRow('pipeLengthUpstream',<>Pipe length upstream of meter (mm)</>,{rowClasses:'mb-3'})}
                        {form.renderUneditableTextInputRow('pipeLengthDownstream',<>Pipe length downstream of meter (mm)</>,{rowClasses:'mb-3'})}
                    </>}
                    <p class="fw-bold border-bottom pb-2 mb-3 mt-5">
                        {(form.val('newMeterEquipment') != '5') && 'New '}
                        {(form.val('newMeterEquipment') == '5') && 'Existing '}
                        LID Details
                    </p>

                    {form.renderUneditableTextInputRow('lIDTelemetry',<>Is the LID being used for Telemetry?</>,{rowClasses:'mb-3', converter:converters.common.converBoolToYesNo})}
                    {form.val('lIDTelemetry')===true && form.renderUneditableTextInputRow('telemetryType',<>LID Telemetry Type</>,{rowClasses:'mb-3', converter:type=>type?type.name:null})}
                    {
                        (form.val('lIDTelemetry')===false || (form.val('lIDTelemetry')===true && form.val('telemetryType'))) 
                        && 
                        form.renderUneditableTextInputRow('lid',<>LID Device</>,{rowClasses:'mb-3', converter:lid=>lid?lid.name:null})
                    }
                    {form.val('lid').allowMultiple && <>
                        {form.renderUneditableTextInputRow('isFirstMeterForLID',<>Is this the first meter registered to this LID? </>,{rowClasses:'mb-3', converter:value=>value===true?"Yes":value===false?"No":null})}
                        {form.renderUneditableTextInputRow('allMetersForLid',<>Currently Connecting to</>,{rowClasses:'mb-3', converter:value=>{
                            return <span className='fs-80'>{value.map((m,i)=>(i!=0?",":"") + m.meterSiteId)}</span>
                        }})}
                    </>}
                    {form.renderUneditableTextInputRow("dataCollectorSerialNo",<>LID Serial number</>,{rowClasses:'mb-3'})}
                    {form.renderUneditableTextInputRow("lIDType",<>LID Type</>,{rowClasses:'mb-3', converter:(o)=>o?o.name:null})}
                    {form.val('lIDType') && form.val('lIDType').key=='PULSE' && form.renderUneditableTextInputRow("scalingFactor",<>Scaling factor</>,{rowClasses:'mb-3'})}

 
                    <p class="fw-bold border-bottom pb-2 mb-3 mt-5">Compliance</p>
                    <p>
                    <b>Does the metering equipment comply with Australian Standard 4747: Meters for non-urban water supply?</b>
                    </p>
                    <p>
                        {form.val('complying')===true?"Yes":form.val('complying')===false?"No":null}
                    </p>
                    {form.val('complying')===false && <>
                    <p>
                    <b>Reasons for not compliance?</b>
                    </p>
                    <ul>
                        {form.val('nonComplianceReasons').map(r=><li>{r}</li>)}
                    </ul>
                    </>}



                    <p class="fw-bold border-bottom pb-2 mb-3 mt-5">Supporting Documents</p>
                    <ValidationSupportingDocRO validationForm={form}/>
                    <p class="fw-bold border-bottom pb-2 mb-3 mt-5">Installation Details</p>

                    {form.renderUneditableTextInputRow('installerDQPName',<>
                        Installer DQP Name
                    </>,{rowClasses:'mb-3'})}

                    {form.renderUneditableTextInputRow('installerDQPCMI',<>
                        Installer DQP CMI Number
                    </>,{rowClasses:'mb-3'})}

                    {form.val('meterReadingDate') && form.renderUneditableTextInputRow("meterReadingDate",<>Meter Installation Date</>,{rowClasses:'mb-3',converter:o=>o?moment((new Date(o))).format('DD/MM/YYYY h:mma'):null})}
                    {form.val('meterReading') && form.renderUneditableTextInputRow("meterReading",<>
                        Meter Reading At The Time Of Installation
                        <br/><span className='fs-80'>(as displayed on meter)</span>
                    </>,{})}

                    {form.vals().newMeterEquipment == '3' && 
                        form.renderUneditableTextInputRow('replacedMeterFinalReading',<>
                            Final Meter Reading of the Replacement Meter
                            <br/><span className='fs-80'>(as displayed on meter)</span>
                        </>,{})
                    }


                    {form.renderUneditableTextInputRow("lidInstallationDate",<>
                        Site Commissioning Date
                        <br/><span className='fs-80'>(date when LID is connected to meter)</span>
                    </>,{converter:o=>o?moment((new Date(o))).format('DD/MM/YYYY h:mma'):null})}

                    {form.renderUneditableTextInputRow("meterReadingAtLidInstallation",<>
                        Meter Reading On Commissioning Date
                        <br/><span className='fs-80'>(as displayed on meter)</span>
                    </>,{})}


                    {form.renderUneditableTextInputRow('unitInDasCorrect',<>Confirm that flow units in DAS are correct?</>,{rowClasses:'mb-3', converter:converters.common.converBoolToYesNo})}
                    {form.renderUneditableTextInputRow('dasCurrentReading',<>Meter Reading in DAS at 7pm on Commissioning Date</>,{rowClasses:'mb-3'})}

                    {form.vals().newMeterEquipment == '5' && 
                        form.renderUneditableTextInputRow("maintenanceNotificateDate",<>
                        Maintenance Notification Date
                        <br/><span className='fs-80'>(date as seen in DAS event list which triggered maintenance)</span>
                    </>,{converter:o=>o?moment((new Date(o))).format('DD/MM/YYYY h:mma'):null})
                    }

                    {form.renderUneditableTextInputRow('dqpNotes',<>DQP Commissioning and Validation Notes</>,{rowClasses:'mb-3'})}

                    {/* 
                    <p class="fw-bold border-bottom pb-2 mb-3 mt-5">Validation Certificate Email</p>
                    {form.val('certificateSendToNoneHolder') === true && form.renderUneditableTextInputRow('certNoneHolderEmail',<>Email to Send Validation Certificate</>,{rowClasses:'mb-3'})}
                    {(form.val('certificateSendToNoneHolder')==null || form.val('certificateSendToNoneHolder')!== true) && <div class="row ">
                        <div class="col-md-4 py-2">Email to Send Validation Certificate</div>
                        <div class="col-md-8">
                            <ul class="list-group">
                                {approval.holders.map(h=>isStringNullOrEmpty(h.email)?null:<li class="list-group-item bg-light">{h.email}</li>)}   
                            </ul>
                        </div>
                    </div>}

                    {form.val('lIDTelemetry') && <>
                        <p class="fw-bold border-bottom pb-2 mb-3 mt-5">DAS Access</p>
                        {form.val('dasAccessToNoneHolder') === true && form.renderUneditableTextInputRow('dasAccessNoneHolderEmail',<>Email/s to Have DAS Access</>,{rowClasses:'mb-3'})}
                        {(form.val('dasAccessToNoneHolder')==null || form.val('dasAccessToNoneHolder')!== true) && <div class="row ">
                            <div class="col-md-4 py-2">Email/s to Have DAS Access</div>
                            <div class="col-md-8">
                                <ul class="list-group">
                                    {approval.holders.map(h=>isStringNullOrEmpty(h.email)?null:<li class="list-group-item bg-light">{h.email}</li>)}   
                                </ul>
                            </div>
                        </div>}
                    </>}
                    */}
                </div>
            </div>
        </>;
    }
}