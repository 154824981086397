import React from 'react';
import Authentication from '../../helpers/Authentication'
import axios from 'axios';
import {convertRegistrationFormStatus, convertRegistrationFormType} from '../../helpers/converters'
import Pagination from '../tools/Pagination';
import {renderRecordTrRow, renderRecordHeaderTr} from '../../components/admin/renderCombineResult';

const authentication = new Authentication();

class RegistrationsWithIssues extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            errorOnLoading : false,
            registrationsForDisplay : null,
            certificatesForDisplay : null
        }
    }

    registrations = null;
    certificates = null;

    ajaxConfig = {
        baseURL: process.env.REACT_APP_WEBAPI_URL,
        headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authentication.getToken() }
    };

    componentDidMount() {

        this.refreshData();
        
    }   

    refreshData = async () =>{
        try {
            this.setState({loading : true});
            var response = await axios.get('/admin/forms-with-issues',this.ajaxConfig);           
            this.registrations = response.data.data.data.registrations;
            this.certificates = response.data.data.data.certificates;
            this.registrationDataSetter(this.registrations)
            this.certificateDataSetter(this.certificates)
        } catch(e) {
            console.log(e);
            this.setState({errorOnLoading : true});
        }
        this.setState({loading : false});
    }

    retryRefresh = () => {
        this.refreshData();
    }

    registrationDataSetter = null;
    registrationDataSetterProvider = (dataSetter) =>{
        this.registrationDataSetter = dataSetter;
    }

    refreshDisplayedRegistrations = (data)=>{
        this.setState({registrationsForDisplay: data });
    }

    certificateDataSetter = null
    certificateDataSetterProvider = (dataSetter) =>{
        this.certificateDataSetter = dataSetter;
    }

    refreshDisplayedCertificates = (data)=>{
        this.setState({certificatesForDisplay: data });
    }

    convertIssue = (issue) => {
        switch(issue) {
            case 'SITE_CREATION_FAILED_FOR_DAS' : return 'DAS site creation might have failed'
            case 'SET_TO_INSTALL_FAILED_FOR_DAS' : return 'Set-to-install in DAS might have failed'
            default:return 'Unknown'
        }
    }


    render() {
        const {loading, errorOnLoading, registrationsForDisplay, certificatesForDisplay} = this.state;
        return <>
            <div class="card mb-5">
                <div class="card-header bg-primary text-white">
                <strong>Registrations with issues</strong>
                </div>
                <div class="card-body">
                    {loading && <>
                        <div className='text-center'>
                            <div class="spinner-border text-primary" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    </>}
                    {!loading && errorOnLoading && <>
                        <div class="alert alert-danger" role="alert">
                            Error occured when loading data, you can <a className='btn btn-sm btn-primary' href="#" onClick={this.retryRefresh}>Retry</a>
                        </div>
                    </>}
                    {!loading && !errorOnLoading && this.registrations && this.registrations.length==0 && <>
                        <div className="alert alert-primary">
                            No result returned.
                        </div>
                    </>}

                    {!loading && !errorOnLoading && registrationsForDisplay &&  registrationsForDisplay.length!=0  && <>
                        <table className="table table-striped fs-90">
                            <thead>
                                <tr>
                                    <th style={{width:"50px"}}></th>
                                    <th style={{width:"50px"}}>ID</th>
                                    <th style={{width:"100px"}}>Status</th>
                                    <th style={{width:"150px"}}>DQP</th>
                                    <th style={{width:"150px"}}>Type</th>
                                    <th style={{width:"200px"}}>DAS Workspace ID</th>
                                    <th>Protential issue</th>
                                </tr>
                            </thead>
                            <tbody>
                                {registrationsForDisplay.map((registration, i) => <tr>
                                    <td><a href={"NonUrbanRegistration?id=" + registration.id} target={"_blank"}>Details</a></td>
                                    <td>{registration.id}</td>
                                    <td>{convertRegistrationFormStatus(registration.formStatus)}</td>
                                    <td>{registration.createdBy.firstName} {registration.createdBy.lastName}</td>
                                    <td>{convertRegistrationFormType(registration.formType)}</td>
                                    <td>{registration.meterSiteId}</td>
                                    <td>
                                        <span className='text-danger'>{this.convertIssue(registration.issue)}</span>
                                    </td>
                                </tr>)}
                            </tbody>
                        </table>
                    </>}
                </div>
                <div class="card-footer text-center">
                <div class="row justify-content-md-center">
                    <div class="col"></div>
                    <div class="col-md-auto">
                        <Pagination dataProvider={this.registrationDataSetterProvider} displayRecordsUpdate={this.refreshDisplayedRegistrations} />
                    </div>
                    <div class="col"></div>
                </div>
                </div>
            </div>


            <div class="card mb-5">
                <div class="card-header bg-primary text-white">
                <strong>Certificates failed to generated</strong>
                </div>
                <div class="card-body">
                    {loading && <>
                        <div className='text-center'>
                            <div class="spinner-border text-primary" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    </>}
                    {!loading && errorOnLoading && <>
                        <div class="alert alert-danger" role="alert">
                            Error occured when loading data, you can <a className='btn btn-sm btn-primary' href="#" onClick={this.retryRefresh}>Retry</a>
                        </div>
                    </>}
                    {!loading && !errorOnLoading && this.certificates && this.certificates.length==0 && <>
                        <div className="alert alert-primary">
                            No result returned.
                        </div>
                    </>}

                    {!loading && !errorOnLoading && certificatesForDisplay &&  certificatesForDisplay.length!=0  && <>
                        <table className="table table-striped fs-90">
                            <thead>
                                {renderRecordHeaderTr()}
                            </thead>
                            <tbody>
                                {certificatesForDisplay.map((certificate, i) => renderRecordTrRow(certificate))}
                            </tbody>
                        </table>
                    </>}
                </div>
                <div class="card-footer text-center">
                <div class="row justify-content-md-center">
                    <div class="col"></div>
                    <div class="col-md-auto">
                        <Pagination dataProvider={this.certificateDataSetterProvider} displayRecordsUpdate={this.refreshDisplayedCertificates} />
                    </div>
                    <div class="col"></div>
                </div>
                </div>
            </div>
        </>
    }

}

export default RegistrationsWithIssues;


