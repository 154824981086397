import React from 'react';
import Select from 'react-select';
import MeterCommissionAdminTransfer from './MeterCommissionAdminTransfer';
import MeterCommissionAdminDeletion from './MeterCommissionAdminDeletion';

const adminOptions = [
    {key:"DELETE", label:"Delete Form"},
    {key:"TRANSFER", label:"DQP Transfer"}
]
export default class MeterCommissionAdmin  extends React.Component {

    constructor(props) {
        super(props);
        this.state = {selectedOption:null};
    }

    onAdminOptionChange = (option) => {
        this.setState({selectedOption:option})
    }

    render() {
        return <>

            <div className='card card-body'>
                <div className='row mb-3'>
                    <div className='col-md-4 py-2'>
                        Action Type
                    </div>
                    <div className='col-md-8'>
                        <Select
                            getOptionLabel={(o)=>o.label}
                            getOptionValue={(o)=>o.key}
                            options={adminOptions}
                            onChange={this.onAdminOptionChange}
                            value={this.state.selectedOption}
                        />
                    </div>
                </div>

                {this.state.selectedOption && this.state.selectedOption.key=='DELETE' && <MeterCommissionAdminDeletion 
                    meterCommission={this.props.meterCommission} 
                    refreshPage={this.props.refreshPage} 
                    installationTypeKey={this.props.installationTypeKey}
                    lid={this.props.lid}
                />}
                {this.state.selectedOption && this.state.selectedOption.key=='TRANSFER' && <MeterCommissionAdminTransfer 
                    meterCommission={this.props.meterCommission} 
                    refreshPage={this.props.refreshPage} 
                    
                />}

            </div>
            
        </>
    }
}