import React from 'react';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import * as apiCaller from '../helpers/apiCaller'
import uiHelpers from '../helpers/uiHelpers';
import serviceUrls from '../constants/serviceUrls';

class ValidateConsent extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            loaded: false,
            message : null

        }
    }

    async componentDidMount() {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const consentToken = params.get('cct');
        const customerSelect = params.get('type');
        const authorizationPeriod = params.get('validity');

        if (consentToken != null && consentToken != "") {

            uiHelpers.showProgressOnTop('Please wait...');
            await apiCaller.post(serviceUrls.consent.validate,{
                token: consentToken,
                type: customerSelect,
                validity: authorizationPeriod
            }).then((result)=>{
                if(!result.data) {
                    this.setState(Object.assign(this.state, { 
                        message : <div className='alert alert-danger m-5 p-5'>This link might have been expired or not working due to invalid logic. (e.g., Withdrawing without approving it before)</div>
                    }));
                    return;
                }
                if (result.data.approved) {
                    this.setState(Object.assign(this.state, { 
                        message : <div className='alert alert-success m-5 p-5'>You have authorised consent to <strong>{result.data.dqpFirstName} {result.data.dqpLastName}</strong> from <strong>{result.data.dqpBusinessName}</strong> to view the license, approval and meter information currently held with WaterNSW.</div>
                    }));
                } else if (result.data.rejected) {
                    this.setState(Object.assign(this.state, { 
                        message : <div className='alert alert-danger m-5 p-5'>You have not authorised consent to <strong>{result.data.dqpFirstName} {result.data.dqpLastName}</strong> from <strong>{result.data.dqpBusinessName}</strong> to view the license, approval and meter information currently held with WaterNSW.</div>
                    }));
                } else if (result.data.withdrew) {
                    this.setState(Object.assign(this.state, { 
                        message : <div className='alert alert-warning m-5 p-5'>You have withdrawn consent to <strong>{result.data.dqpFirstName} {result.data.dqpLastName}</strong> from <strong>{result.data.dqpBusinessName}</strong> to view the license, approval and meter information currently held with WaterNSW.</div>
                    }));
                }
            }).finally(()=>{
                this.setState(Object.assign(this.state, { loaded: true }));
                uiHelpers.hideProgressOnTop();
            });
        } else {
            this.setState(Object.assign(this.state, { loaded:true, errorMsg: "Your link is invalid" }))
        }

    }

    render() {
        if(!this.state.loaded) return null;
        return this.state.message;
    }
}

export default ValidateConsent;
