import React , { useState } from 'react';
import uiHelpers from '../../../helpers/uiHelpers';
import axios from 'axios';
import Authentication from '../../../helpers/Authentication'
import ApprovalSearchFormWithConsent from '../../common/ApprovalSearchFormWithConsent'
import EsidSelect from '../../common/EsidSelect'
import Pagination from '../../tools/Pagination';


const authentication = new Authentication();
var rowsDataSetter = null;

const MeterValidationCopyFormWorkFlow = props => {

   const config = {
      baseURL: process.env.REACT_APP_WEBAPI_URL,
      headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authentication.getToken() }
   };

   const [approvalNumber, setApprovalNumber] = useState("");
   const [esid, setEsid] = useState(null);
   const [tablePassedSubmissionRows, setTablePassedSubmissionRows] = useState(null);
   const [loading, setLoading] = useState(false);
   
   const approvalSelectedHandler = async (approvalNumber, selectedHolder, callback) => {
      setApprovalNumber( approvalNumber)
   }

   const esidChangeHandler = async (esid) => {
      setEsid(esid.esid)
      rowsDataSetter(null);
      setTablePassedSubmissionRows(null);
   }

   
   const paginationDataProvider = (dataSetter) =>{
      rowsDataSetter = dataSetter;
   }

   const refreshRecordsForPagination = (data)=>{
      setTablePassedSubmissionRows(data);
   }
   
   const getMeterValidationList = async () => {
      try {
         uiHelpers.showProgressOnTop("Searching for validation certificates...")
         setLoading(true);
         var response = await axios.get('/referenceData/validationlist/'+approvalNumber+'/'+esid, config);
         setLoading(false)
         let submittedList=[];
         if (response.status == 200) {
            uiHelpers.hideProgressOnTop();
            var allData = response.data.allData.sort(function(a, b){return b.timestamp > a.timestamp?1:-1});
            
            for (var i = 0; i <= allData.length - 1; i++) {
               var thisRow=allData[i];
               if (thisRow.status === 'Submitted' && thisRow.formType=='Validation Certificate') {                  
                  submittedList.push(thisRow);
               }
            }
            rowsDataSetter(submittedList);
         } else {
            uiHelpers.showErrorTop('Error retrieving Meter Validation Certificates');
         }
      } catch(error) {
         uiHelpers.showErrorTop('Error retrieving Meter Validation Certificates');
         console.log(error);
      };
   }

   const rowClick = (url) => {
      window.location = url +'&showReview=1';
   }

   const SubmissionRows=()=>{  
      if(tablePassedSubmissionRows != undefined && tablePassedSubmissionRows.length > 0)  {
         return tablePassedSubmissionRows.map(function(row,i){
         return <tr onClick={  ()=> rowClick(row.url)} key={i}>
               <td>{row.submitted}</td>
               <td>{row.formType}</td> 
               <td>{row.approvalHolder}</td> 
               <td>{row.wan}</td>
               <td>{row.status}</td>
            </tr>
         });
      } 
   }

   const resetForm = () => {
      setApprovalNumber("");
      setEsid(null);
   }

   

   return <>
   

      {!approvalNumber &&  <ApprovalSearchFormWithConsent onCustomerConsentValid={approvalSelectedHandler} />}
      {approvalNumber && <>
         <div className="row mb-3">
            <div className='col-sm-6'>
               <label for="searchWorkApprovalNumber">Work approval number</label>
               <input type="text" class="form-control" id="searchWorkApprovalNumber" readOnly={true} onChange={()=>{}} value={approvalNumber} />
            </div>
         </div>
         <div className="row mb-3">
            <div className='col-sm-6'>
               <label>Unique extraction site identifier (ESID) <span style={{color: "red"}}>* </span></label>
                  <EsidSelect approvalNumber={approvalNumber} onEsidChange={esidChangeHandler} disabled={loading} defaultEsid={esid} />
            </div>
         </div>
         <div className="row mb-3">
            <div className='col-sm-6'>
               <button type="button" className="btn btn-secondary" disabled={loading} onClick={resetForm}>
                  Reset
               </button>
               {esid && <button type="button" className="btn btn-primary float-end" disabled={loading} onClick={getMeterValidationList}>
                  View results
               </button>}
            </div>
         </div>

         {(tablePassedSubmissionRows != null) && <>
            <table class="table table-sm table-hover fs-90 clickable-table">
               <thead>
                  <tr>
                     <th>Submitted</th>
                     <th>Form Type</th>
                     <th>Approval Holder Name</th>
                     <th>Worksite ID</th>
                     <th>Status</th>
                  </tr>
               </thead>
               {tablePassedSubmissionRows.length > 0 &&<tbody>
                  {SubmissionRows()}
               </tbody>}
               {tablePassedSubmissionRows.length == 0 &&<tbody>
                  <tr className='table-primary'>
                     <td className='text-center' colSpan={6}>
                        No record can be found for {approvalNumber} with ESID {esid}
                     </td>
                  </tr>
               </tbody>}
            </table>
         </>}
         <div className='row'>
            <div className='col'/>
            <div className='col-md-auto'>
               <Pagination dataProvider={paginationDataProvider} displayRecordsUpdate={refreshRecordsForPagination} />
            </div>
            <div className='col'/>
         </div>
      </>} 
   </>
}

export default MeterValidationCopyFormWorkFlow