import React from 'react';
import { reduxForm, formValueSelector, change } from 'redux-form';
import { connect } from 'react-redux'
import 'react-redux';
import 'redux';
import moment from 'moment';
import { saveDraftAction } from "../common/MeterFormValidations"
import { validateCertificatePartE } from './MeterValidationCertificate.Validation';
import { METER_FORM_TYPES, METER_VALIDATION_TYPE } from '../../../constants/MeterFormConstants'
const validate = validateCertificatePartE;


let MeterValidationCertificateConfirmation = props => {

    const {
        meterEquipInfo,
        authHolderInfo,
        meterNumberExternalList,
        waterAccessLicenceNoExternalList,
        appWorkInfo,
        meterNonComplianceReason,
        meterNonCompliance,
        masterDataValues
        , cancelConfrimSubmissionAndGoToPage
        , cancelConfrimSubmission

        , completeConfirmation

        , handleSubmit
        , showReview
        , loading
        , hasError
    } = props;


    if (showReview) {
        props.dispatch(change(props.form, 'cloneStatus', 1));
    }

    const meteringFormSelectionOptions =
        [
            { id: "1", name: "New", selected: false },
            { id: "2", name: "Existing", selected: false }
        ];

    const meteringEquipmentConsistentOptions =
        [
            { id: "1", name: "Yes", selected: false },
            { id: "2", name: "No", selected: false }
        ];

    const meterInstalledBeforeApril2019Options =
        [
            { id: "1", name: "Yes", selected: false },
            { id: "2", name: "No", selected: false }
        ];

    const meteringCircumstanceValidationOptions =
        [
            { id: "1", name: "Validation after maintenance that may have affected the metrology of existing meter", selected: false },
            { id: "2", name: "Validation after work requiring removal of a tamper-evident seal", selected: false },
            { id: "3", name: "Re-validation of existing meter", selected: false },
            { id: "4", name: "Validation after removal and reinstallation of existing meter", selected: false },
            { id: "5", name: "Validation after seals on the meter were broken or removed by a person who is not a duly qualified person", selected: false }
        ];

    const meteringEquipmentCompylyOptions =
        [
            { id: "1", name: "Yes", selected: false },
            { id: "2", name: "No", selected: false }
        ];

    const complyOptions =
        [
            { id: "1", name: "(a) The metering equipment does not comply with AS 4747 for the reasons specified below and it can be modified to comply with AS 4747 as specified below", selected: false },
            { id: "2", name: "(b) The metering equipment does not comply with AS 4747 for the reasons specified below and it cannot be modified to comply with AS 4747", selected: false }
        ];
    const flattenObject = (obj, prefix = '') =>
        Object.keys(obj).reduce((acc, k) => {
            const pre = prefix.length ? prefix + '.' : '';
            if (typeof obj[k] === 'object') Object.assign(acc, flattenObject(obj[k], pre + k));
            else acc[pre + k] = obj[k];
            return acc;
        }, {});

    const closeConfirmation = () => {
        window.location = 'MeterValidationCopy';
    }

    const setMaintenanceValidation = () => {
        props.dispatch(change(props.form, 'meteringEquipmentInformation.newMeterEquipment', "2"));
        props.dispatch(change(props.form, 'meteringEquipmentInformation.meterFormType', METER_FORM_TYPES.MAINTENANCE_VALIDATION));
        props.dispatch(change(props.form, 'meteringEquipmentInformation.maintenanceValidation', 1));
    }

    return (
        <form className="vertical" onSubmit={handleSubmit}>
            <h5 className='mb-3'>Review</h5>
            <div class="alert alert-primary" role="alert">
                Please review the information below before completing your submission.
                <br />
                The form cannot be edited once submitted. Therefore, please confirm all configuration details are correct before submitting this form.
            </div>
            <h5 className='mb-3'>This validation is for a
                <span class="text-primary">
                    {meteringFormSelectionOptions.map((mo) => {
                        if (mo.id == meterEquipInfo.newMeterEquipment) {
                            return ' "' + mo.name + '" ';
                        }
                    })}
                </span> meter
            </h5>

            {!showReview &&
                <p>
                    <button type="button" className="btn btn-primary"
                        onClick={() => { cancelConfrimSubmissionAndGoToPage(0); }}>
                        Edit
                    </button>
                </p>
            }

            <div className='card mt-4 mb-3'>
                <div className='card-header'>
                    <strong>Authority Holder Details</strong>
                </div>
                <div className='card-body'>
                    <table class="table fs-90 mb-3">
                        <tbody>
                            {(authHolderInfo.firstName != null && authHolderInfo.lastName != null && authHolderInfo.firstName != '' && authHolderInfo.lastName != '') &&
                                <tr>
                                    <th className='w-25'>
                                        Authority holder name(s)
                                    </th>
                                    <td>{authHolderInfo.authorityHolderName}</td>
                                </tr>
                            }
                            <tr>
                                <th className='w-25'>
                                    Corporation name and ACN
                                </th>
                                <td>{authHolderInfo.corporationNameOrAcn}</td>
                            </tr>
                            <tr>
                                <th>
                                    Postal address
                                </th>
                                <td>{authHolderInfo.postalAddress}</td>
                            </tr>
                            <tr>
                                <th>
                                    Phone
                                </th>
                                <td>{authHolderInfo.phone}</td>
                            </tr>
                            <tr>
                                <th>
                                    Mobile
                                </th>
                                <td>{authHolderInfo.mobile}</td>
                            </tr>
                            <tr>
                                <th>
                                    Alternate phone
                                </th>
                                <td>{authHolderInfo.alternatePhone}</td>
                            </tr>
                            <tr>
                                <th>
                                    Email address
                                </th>
                                <td>{authHolderInfo.emailAddress}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className='card-header'>
                    <strong>Contact Person or Agent</strong>
                </div>
                <div className='card-body'>
                    <table class="table fs-90 mb-3">
                        <tbody>
                            <tr>
                                <th className='w-25'>
                                    Name
                                </th>
                                <td>{authHolderInfo.contactPersonName}</td>
                            </tr>
                            <tr>
                                <th>
                                    Phone
                                </th>
                                <td>{authHolderInfo.contactPersonPhone}</td>
                            </tr>
                            <tr>
                                <th>
                                    Email address
                                </th>
                                <td>{authHolderInfo.contactPersonEmailAddress}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                {!showReview && <div className='card-footer'>
                    <button type="button" className="btn btn-primary"
                        onClick={() => { cancelConfrimSubmissionAndGoToPage(1); }}>
                        Edit
                    </button>
                </div>}
            </div>

            <div className='card mt-4 mb-3'>
                <div className='card-header'>
                    <strong>Details of Approved Works</strong>
                </div>
                <div className='card-body'>
                    <table class="table fs-90 mb-3">
                        <tbody>
                            <tr>
                                <th className='w-25'>
                                    Property name
                                </th>
                                <td>{appWorkInfo.propertyName}</td>
                            </tr>
                            <tr>
                                <th>
                                    Lot DP
                                </th>
                                <td>{appWorkInfo.lotDp}</td>
                            </tr>
                            <tr>
                                <th>
                                    Water supply work approval number
                                </th>
                                <td>{appWorkInfo.waterSupplyWorkApprovalNo}</td>
                            </tr>
                            {
                                (appWorkInfo.approvedByCustomer == 0) || (appWorkInfo.approvedByCustomer == 1 && waterAccessLicenceNoExternalList.length == 0) &&
                                <tr>
                                    <th>
                                        Water access licence number/s(WAL numbers/s)
                                    </th>
                                    <td>{appWorkInfo.waterAccessLicenceNo}</td>
                                </tr>
                            }
                            {
                                appWorkInfo.approvedByCustomer == 1 && waterAccessLicenceNoExternalList.length > 0 &&
                                <tr>
                                    <th>
                                        Water access licence number/s(WAL numbers/s)
                                    </th>
                                    <td>{appWorkInfo.waterAccessLicenceNo}</td>
                                </tr>
                            }
                            <tr>
                                <th>
                                    Unique extraction site identifier (ESID)
                                </th>
                                <td>{appWorkInfo.esid}</td>
                            </tr>
                            {
                                appWorkInfo.approvedByCustomer == 1 && meterNumberExternalList.length > 0 &&
                                (
                                    (
                                        meterEquipInfo.newMeterEquipment == METER_VALIDATION_TYPE.EXISTING &&
                                        (meterEquipInfo.meterFormType == METER_FORM_TYPES.METER_VALIDATION || meterEquipInfo.meterFormType == METER_FORM_TYPES.MAINTENANCE_VALIDATION)
                                    )
                                    ||
                                    meterEquipInfo.meterFormType == METER_FORM_TYPES.METER_ACCURACY)
                                &&
                                <tr>
                                    <th>
                                        Selected meter
                                    </th>
                                    <td>{appWorkInfo.selectedMeter}</td>
                                </tr>
                            }
                            {((
                                meterEquipInfo.meterFormType == METER_FORM_TYPES.METER_VALIDATION) ||
                                meterEquipInfo.meterFormType == METER_FORM_TYPES.METER_ACCURACY || meterEquipInfo.meterFormType == METER_FORM_TYPES.MAINTENANCE_VALIDATION) &&
                                <tr>
                                    <th>
                                        Meter number
                                    </th>
                                    <td>{appWorkInfo.meterNumber}</td>
                                </tr>
                            }
                            <tr>
                                <th>
                                    Customer's site name
                                </th>
                                <td>{appWorkInfo.customersSiteName}</td>
                            </tr>
                            <tr>
                                <th>
                                    Customer's pump number
                                </th>
                                <td>{appWorkInfo.customersPumpNumber}</td>
                            </tr>
                            <tr>
                                <th>
                                    Name of water source
                                </th>
                                <td>{appWorkInfo.waterSourceName}</td>
                            </tr>
                            <tr>
                                <th>
                                    Name of water sharing plan
                                </th>
                                <td>{appWorkInfo.waterSharingPlanName}</td>
                            </tr>
                            <tr>
                                <th>
                                    Type of water source
                                </th>
                                <td>
                                    {masterDataValues.waterSourceTypes && masterDataValues.waterSourceTypes.map((ws) => {
                                        if (ws.id == appWorkInfo.waterSourceTypeId) {
                                            return ws.name;
                                        }
                                    })}
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    The water source information shown is incorrect
                                </th>
                                <td>{appWorkInfo.correctWaterInfoCheck == 1 ? true : false}</td>
                            </tr>
                            <tr>
                                <th>
                                    Correct Water Source Details
                                </th>
                                <td>{appWorkInfo.correctWaterSourceDetails}</td>
                            </tr>
                            <tr>
                                <th>
                                    Is this Validation certificate related to a submitted Meter-Telemetry registration form?
                                </th>
                                <td>{appWorkInfo.isRelatedToMeterRegistry}</td>
                            </tr>
                            <tr>
                                <th>
                                    Enter the Workspace ID as displayed in the DAS (eg. 90CA999999-1234-1)
                                </th>
                                <td>{appWorkInfo.workspaceId}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                {!showReview && <div className='card-footer'>
                    <button type="button" className="btn btn-primary"
                        onClick={() => { cancelConfrimSubmissionAndGoToPage(2); }}>
                        Edit
                    </button>
                </div>}
            </div>


            {(meterEquipInfo.newMeterEquipment == METER_VALIDATION_TYPE.NEW || meterEquipInfo.newMeterEquipment == METER_VALIDATION_TYPE.REPLACEMENT) && <>

                <div className='card mt-4 mb-3'>
                    <div className='card-header'>
                        <strong>New Metering Equipment</strong>
                    </div>
                    <div className='card-body'>
                        <table class="table fs-90 mb-3">
                            <tbody>
                                <tr>
                                    <th className='w-25'>
                                        Meter system type
                                    </th>
                                    <td>
                                        {masterDataValues.meteringSystemTypes && masterDataValues.meteringSystemTypes.map((st) => {
                                            if (st.id == meterEquipInfo.meteringSystemTypeId) {
                                                return st.name;
                                            }
                                        })}
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        Meter manufacturer &amp; model
                                    </th>
                                    <td>
                                        {masterDataValues.meterModelTypes && masterDataValues.meterModelTypes.map((st) => {
                                            if (st.id == meterEquipInfo.meterTypeModelId) {
                                                return st.name;
                                            }
                                        })}
                                    </td>
                                </tr>
                                {
                                    (parseInt(masterDataValues.MeterModelTypeOtherId) === parseInt(meterEquipInfo.meterTypeModelId)) &&
                                    <tr>
                                        <th>
                                            Other meter manufacturer &amp; model
                                        </th>
                                        <td>{meterEquipInfo.meterTypeModel}</td>
                                    </tr>
                                }
                                <tr>
                                    <th>
                                        Meter serial number
                                    </th>
                                    <td>{meterEquipInfo.meterSerialNo}</td>
                                </tr>
                                <tr>
                                    <th>
                                        Meter size (mm)
                                    </th>
                                    <td>{meterEquipInfo.meterSize}</td>
                                </tr>

                                <tr>
                                    <th>
                                        Unit of measure as displayed on meter
                                    </th>
                                    <td>
                                        {masterDataValues.meterUnitOfMeasures && masterDataValues.meterUnitOfMeasures.map((dt) => {
                                            if (dt.id == meterEquipInfo.meterUnitOfMeasuresId) {
                                                return dt.name;
                                            }
                                        })}
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        Meter reading at the date of this validation
                                    </th>
                                    <td>{meterEquipInfo.meterReading}</td>
                                </tr>

                                <tr>
                                    <th>
                                        Tamper-evident seal numbers
                                    </th>
                                    <td>
                                        {meterEquipInfo.sealNo && meterEquipInfo.sealNo.map((sn) => {
                                            return sn.label + "; ";
                                        })}
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        Pattern approval number (if applicable)
                                    </th>
                                    <td>{meterEquipInfo.patternApprovalNumber}</td>
                                </tr>
                                {meterEquipInfo.meteringSystemTypeId != 2 && <>
                                    <tr>
                                        <th>
                                            Pipe Material
                                        </th>
                                        <td>
                                            {masterDataValues.pipeMaterials && masterDataValues.pipeMaterials.map((dt) => {
                                                if (dt.id == 21) {
                                                    return meterEquipInfo.pipeType;
                                                }
                                                else if (dt.id == meterEquipInfo.pipeMaterialsId) {
                                                    return dt.name;
                                                }
                                            })}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            Pipe Size (mm) ID (Internal Diameter)
                                        </th>
                                        <td>{meterEquipInfo.pipeSize}</td>
                                    </tr>
                                    <tr>
                                        <th>
                                            Pipe length upstream of meter (mm)
                                        </th>
                                        <td>{meterEquipInfo.pipeLengthUpstream}</td>
                                    </tr>
                                    <tr>
                                        <th>
                                            Pipe length downstream of meter (mm)
                                        </th>
                                        <td>{meterEquipInfo.pipeLengthDownstream}</td>
                                    </tr>
                                </>}
                                <tr>
                                    <th>
                                        Meter Q1 flow rate minimum
                                    </th>
                                    <td>{meterEquipInfo.meterq1FlowRateMinimum}</td>
                                </tr>
                                <tr>
                                    <th>
                                        Meter Q3 flow rate optimum
                                    </th>
                                    <td>{meterEquipInfo.meterq3FlowRateOptimum}</td>
                                </tr>
                                <tr>
                                    <th>
                                        Design flow rate
                                    </th>
                                    <td>{meterEquipInfo.designFlowRate}</td>
                                </tr>
                                <tr>
                                    <th>
                                        Software version
                                    </th>
                                    <td>{meterEquipInfo.softwareVersion}</td>
                                </tr>

                                <tr>
                                    <th>
                                        Meter location longitude
                                    </th>
                                    <td>{meterEquipInfo.longitude}</td>
                                </tr>
                                <tr>
                                    <th>
                                        Meter location latitude
                                    </th>
                                    <td>{meterEquipInfo.latitude}</td>
                                </tr>
                                <tr>
                                    <th>
                                        Meter Description (Please provide any further details or description of the meter)
                                    </th>
                                    <td>{meterEquipInfo.meterDescription}</td>
                                </tr>

                                <tr>
                                    <th>
                                        Type of supply
                                    </th>
                                    <td>
                                        {masterDataValues.supplyTypes && masterDataValues.supplyTypes.map((st) => {
                                            if (st.id == meterEquipInfo.supplyTypeId) {
                                                return st.name;
                                            }
                                        })}
                                    </td>
                                </tr>

                                {meterEquipInfo.newMeterEquipment == METER_VALIDATION_TYPE.REPLACEMENT && <>
                                    <tr>
                                        <th>
                                            Old Meter manufacturer & model
                                        </th>
                                        <td>{meterEquipInfo.previousMeterManufactureModel}</td>
                                    </tr>
                                    <tr>
                                        <th>
                                            Old Meter serial number (if available)
                                        </th>
                                        <td>{meterEquipInfo.previousMeterSerialNumber}</td>
                                    </tr>
                                    <tr>
                                        <th>
                                            Old Meter Size (mm)
                                        </th>
                                        <td>{meterEquipInfo.previousMeterSize}</td>
                                    </tr>
                                    <tr>
                                        <th>
                                            Meter reading of old meter being removed
                                        </th>
                                        <td>{meterEquipInfo.finalReadingRemovedMeter}</td>
                                    </tr>
                                    <tr>
                                        <th>
                                            Final reading date of removed meter
                                        </th>
                                        <td>
                                            {meterEquipInfo.finalReadingDateRemovedMeter == "" ? "" : moment((new Date(meterEquipInfo.finalReadingDateRemovedMeter))).format('DD/MM/YYYY')}
                                        </td>
                                    </tr>
                                </>}
                                {(meterEquipInfo.dataCollectorTypeId != 4 && meterEquipInfo.dataCollectorTypeId != '4') && <>
                                    <tr>
                                        <th>
                                            LID Device
                                        </th>
                                        <td>
                                            {/* {meterEquipInfo.lIDManufacturer} */}
                                            {masterDataValues.lids && masterDataValues.lids.map((dt) => {
                                                if (dt.id == meterEquipInfo.lidId) {
                                                    return dt.displayText;
                                                }
                                            })}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            Data collector type
                                        </th>
                                        <td>
                                            {masterDataValues.dataCollectorTypes && masterDataValues.dataCollectorTypes.map((dt) => {
                                                if (dt.id == meterEquipInfo.dataCollectorTypeId) {
                                                    return dt.name;
                                                }
                                            })}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            Model number
                                        </th>
                                        <td>
                                            {meterEquipInfo.dataCollectorModelNo}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            Serial number
                                        </th>
                                        <td>
                                            {meterEquipInfo.dataCollectorSerialNo}
                                        </td>
                                    </tr>

                                    <tr>
                                        <th>
                                            LID Type
                                        </th>
                                        <td>
                                            {meterEquipInfo.lIDType}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            Meter reading when LID was installed, as displayed on the meter. (This reading must be correct to avoid revisiting the site)
                                        </th>
                                        <td>
                                            {meterEquipInfo.lIDMeterReading}
                                        </td>
                                    </tr>

                                    <tr>
                                        <th>
                                            Scaling factor – (Refer to manufacturer)
                                        </th>
                                        <td>
                                            {meterEquipInfo.scalingFactor}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            Is the LID being used for Telemetry?
                                        </th>
                                        <td>
                                            {meterEquipInfo.lIDTelemetry}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            Unit of measure
                                        </th>
                                        <td>
                                            {masterDataValues.meterFlowUnits && masterDataValues.meterFlowUnits.map((dt) => {
                                                if (dt.id == meterEquipInfo.meterFlowUnitsId) {
                                                    return dt.name;
                                                }
                                            })}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            Power source
                                        </th>
                                        <td>
                                            {masterDataValues.powerSources && masterDataValues.powerSources.map((ps) => {
                                                if (ps.id == meterEquipInfo.dataCollectorPowerSourceId) {
                                                    if (ps.name == 'Select...') return "";
                                                    return ps.name;
                                                }
                                            })}
                                        </td>
                                    </tr>
                                    {(meterEquipInfo.dataCollectorPowerSourceId == 1) && <tr>
                                        <th>
                                            Solar panel serial number
                                        </th>
                                        <td>
                                            {meterEquipInfo.dataCollectorSolarPanelSerialNumber}
                                        </td>
                                    </tr>}
                                </>}
                                {!(props.masterDataValues.OpenChannelId != props.meterEquipInfo.meteringSystemTypeId) && <>
                                    <tr>
                                        <th>
                                            Channel type
                                        </th>
                                        <td>
                                            {meterEquipInfo.channelType}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            Channel width (m)
                                        </th>
                                        <td>
                                            {meterEquipInfo.channelWidth}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            Distance of the meter from pumping source or filling point (m)
                                        </th>
                                        <td>
                                            {meterEquipInfo.meterDistanceFromSource}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            Channel length upstream of meter (m)
                                        </th>
                                        <td>
                                            {meterEquipInfo.channelLengthUpstream}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            Channel length downstream of meter (m)
                                        </th>
                                        <td>
                                            {meterEquipInfo.channelLlengthDownstream}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            Design
                                        </th>
                                        <td>
                                            {meterEquipInfo.designEquipmentDetails}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            The metering equipment that has been installed is consistent with the design certificate
                                        </th>
                                        <td>
                                            {meteringEquipmentConsistentOptions.map((mc) => {
                                                if (mc.id == meterEquipInfo.meteringEquipmentConsistent) {
                                                    return mc.name;
                                                }
                                            })}
                                        </td>
                                    </tr>
                                </>}
                            </tbody>
                        </table>
                        {!(props.masterDataValues.OpenChannelId != props.meterEquipInfo.meteringSystemTypeId) && <>
                            <div className='card-header'>
                                <strong>Calibration Measurement Details</strong>
                            </div>
                            <div className='card-body'>
                                <table class="table fs-90 mb-3">
                                    <tbody>
                                        <tr>
                                            <th className='w-25'>
                                                Maximum measured discharge (m³/s)
                                            </th>
                                            <td>
                                                {meterEquipInfo.maxMeasuredDischarge}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>
                                                Minimum measured discharge (m³/s)
                                            </th>
                                            <td>
                                                {meterEquipInfo.minMeasuredDischarge}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>
                                                Maximum measured velocity (m/s)
                                            </th>
                                            <td>
                                                {meterEquipInfo.maxMeasuredVelocity}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>
                                                Minimum measured velocity (m/s)
                                            </th>
                                            <td>
                                                {meterEquipInfo.minMeasuredVelocity}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>
                                                Maximum gauge height (m)
                                            </th>
                                            <td>
                                                {meterEquipInfo.maxGaugeHeight}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>
                                                Minimum gauge height (m)
                                            </th>
                                            <td>
                                                {meterEquipInfo.minGaugeHeight}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>
                                                Maximum error (%)
                                            </th>
                                            <td>
                                                {meterEquipInfo.maxError}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>
                                                Minimum error (%)
                                            </th>
                                            <td>
                                                {meterEquipInfo.minError}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>
                                                Number of measurements undertaken for index equation (if applicable)
                                            </th>
                                            <td>
                                                {meterEquipInfo.noOfMeasurementsTaken}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </>}
                    </div>
                    <div className='card-header'>
                        <strong>Method used to perform in situ volumetric measurements</strong>
                    </div>
                    <div className='card-body'>
                        {meterEquipInfo.methodUsed}
                    </div>
                    <div className='card-header'>
                        <strong>Conclusion</strong>
                    </div>
                    <div className='card-body'>
                        {meterEquipInfo.openChannelConclusion}
                    </div>
                    {!showReview && <div className='card-footer'>
                        <button type="button" className="btn btn-primary"
                            onClick={() => { cancelConfrimSubmissionAndGoToPage(3); }}>
                            Edit
                        </button>
                    </div>}
                </div>
            </>}

            {(meterEquipInfo.newMeterEquipment == METER_VALIDATION_TYPE.EXISTING) && <>

                <div className='card mb-3'>
                    <div className='card-header'>
                        <strong>Existing Metering Equipment</strong>
                    </div>
                    <div className='card-body'>
                        <table class="table fs-90 mb-3">
                            <tbody>
                                <tr>
                                    <th className='w-25'>
                                        Was this meter installed before 1st April 2019?
                                    </th>
                                    <td>
                                        {meterInstalledBeforeApril2019Options.map((o) => {
                                            if (o.id == meterEquipInfo.meterBeforeApril2019) {
                                                return o.name;
                                            }
                                        })}
                                    </td>
                                </tr>
                                {(meterEquipInfo.meterBeforeApril2019 == 1) &&
                                    <tr>
                                        <th>
                                            Date of installation for this meter
                                        </th>
                                        <td>
                                            {meterEquipInfo.dateOfInstallationBeforeApril2019 == "" ? "" : moment((new Date(meterEquipInfo.dateOfInstallationBeforeApril2019))).format('DD/MM/YYYY')}
                                        </td>
                                    </tr>
                                }
                                <tr>
                                    <th>
                                        This validation certificate is for
                                    </th>
                                    <td>
                                        {meteringCircumstanceValidationOptions.map((o) => {
                                            if (meterEquipInfo.meterCircumstancesOptions != null) {
                                                for (var i = 0; i < meterEquipInfo.meterCircumstancesOptions.length; i++) {
                                                    if (o.id == meterEquipInfo.meterCircumstancesOptions[i]) {
                                                        return <p>{o.name}</p>;
                                                    }
                                                }
                                            }
                                        })}
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        Reason for revalidation
                                    </th>
                                    <td>
                                        {meterEquipInfo.reasonForRevalidation}
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        Date of last validation
                                    </th>
                                    <td>
                                        {meterEquipInfo.dateOfLastValidation == "" ? "" : moment((new Date(meterEquipInfo.dateOfLastValidation))).format('DD/MM/YYYY')}
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        Meter reading before maintenance
                                    </th>
                                    <td>
                                        {meterEquipInfo.readingBeforeMaintenance}
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        Meter reading date before maintenance
                                    </th>
                                    <td>
                                        {meterEquipInfo.dateBeforeMaintenance == "" ? "" : moment((new Date(meterEquipInfo.dateBeforeMaintenance))).format('DD/MM/YYYY')}
                                    </td>
                                </tr>

                                <tr>
                                    <th>
                                        Meter reading after maintenance
                                    </th>
                                    <td>
                                        {meterEquipInfo.readingAfterMaintenance}
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        Meter reading date after maintenance
                                    </th>
                                    <td>
                                        {meterEquipInfo.dateAfterMaintenance == "" ? "" : moment((new Date(meterEquipInfo.dateAfterMaintenance))).format('DD/MM/YYYY')}
                                    </td>
                                </tr>

                                <tr>
                                    <th>
                                        Meter system type
                                    </th>
                                    <td>
                                        {masterDataValues.meteringSystemTypes && masterDataValues.meteringSystemTypes.map((st) => {
                                            if (st.id == meterEquipInfo.meteringSystemTypeId) {
                                                return st.name;
                                            }
                                        })}
                                    </td>
                                </tr>

                                <tr>
                                    <th>
                                        Meter manufacturer &amp; model
                                    </th>
                                    <td>
                                        {masterDataValues.meterModelTypes && masterDataValues.meterModelTypes.map((o) => {
                                            if (o.id == meterEquipInfo.meterTypeModelId) {
                                                return o.name;
                                            }
                                        })}
                                    </td>
                                </tr>
                                {
                                    (parseInt(masterDataValues.MeterModelTypeOtherId) === parseInt(meterEquipInfo.meterTypeModelId)) &&
                                    <tr>
                                        <th>
                                            Other meter manufacturer &amp; model
                                        </th>
                                        <td>
                                            {meterEquipInfo.meterTypeModel}
                                        </td>
                                    </tr>
                                }
                                <tr>
                                    <th>
                                        Meter serial number
                                    </th>
                                    <td>
                                        {meterEquipInfo.meterSerialNo}
                                    </td>
                                </tr>

                                <tr>
                                    <th>
                                        Meter size (mm)
                                    </th>
                                    <td>
                                        {meterEquipInfo.meterSize}
                                    </td>
                                </tr>

                                <tr>
                                    <th>
                                        Unit of measure as displayed on meter
                                    </th>
                                    <td>
                                        {masterDataValues.meterUnitOfMeasures && masterDataValues.meterUnitOfMeasures.map((dt) => {
                                            if (dt.id == meterEquipInfo.meterUnitOfMeasuresId) {
                                                return dt.name;
                                            }
                                        })}
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        Meter reading at the date of this validation
                                    </th>
                                    <td>{meterEquipInfo.meterReading}</td>
                                </tr>
                                <tr>
                                    <th>
                                        Tamper-evident seal numbers
                                    </th>
                                    <td>
                                        {meterEquipInfo.sealNo && meterEquipInfo.sealNo.map((sn) => {
                                            return sn.label + "; ";
                                        })}
                                    </td>
                                </tr>

                                <tr>
                                    <th>
                                        Pattern approval number (if applicable)
                                    </th>
                                    <td>
                                        {meterEquipInfo.patternApprovalNumber}
                                    </td>
                                </tr>
                                {meterEquipInfo.meteringSystemTypeId != 2 && <>
                                    <tr>
                                        <th>
                                            Pipe Material
                                        </th>
                                        <td>
                                            {masterDataValues.pipeMaterials && masterDataValues.pipeMaterials.map((dt) => {
                                                if (dt.id == 21) {
                                                    return meterEquipInfo.pipeType;
                                                }
                                                else if (dt.id == meterEquipInfo.pipeMaterialsId) {
                                                    return dt.name;
                                                }
                                            })}

                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            Pipe Size (mm) ID (Internal Diameter)
                                        </th>
                                        <td>
                                            {meterEquipInfo.pipeSize}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            Pipe connection type
                                        </th>
                                        <td>
                                            {meterEquipInfo.pipeConnectionType}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            Pipe length upstream of meter (mm)
                                        </th>
                                        <td>
                                            {meterEquipInfo.pipeLengthUpstream}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            Pipe length downstream of meter (mm)
                                        </th>
                                        <td>
                                            {meterEquipInfo.pipeLengthDownstream}
                                        </td>
                                    </tr>
                                </>}
                                <tr>
                                    <th>
                                        Meter Q1 flow rate minimum
                                    </th>
                                    <td>
                                        {meterEquipInfo.meterq1FlowRateMinimum}
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        Meter Q3 flow rate optimum
                                    </th>
                                    <td>
                                        {meterEquipInfo.meterq3FlowRateOptimum}
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        Design flow rate
                                    </th>
                                    <td>
                                        {meterEquipInfo.designFlowRate}
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        Software version
                                    </th>
                                    <td>
                                        {meterEquipInfo.softwareVersion}
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        Meter location longitude
                                    </th>
                                    <td>
                                        {meterEquipInfo.longitude}
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        Meter location latitude
                                    </th>
                                    <td>
                                        {meterEquipInfo.latitude}
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        Meter Description (Please provide any further details or description of the meter)
                                    </th>
                                    <td>
                                        {meterEquipInfo.meterDescription}
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        Type of supply
                                    </th>
                                    <td>
                                        {masterDataValues.supplyTypes && masterDataValues.supplyTypes.map((o) => {
                                            if (o.id == meterEquipInfo.supplyTypeId) {
                                                return o.name;
                                            }
                                        })}
                                    </td>
                                </tr>
                                {(meterEquipInfo.dataCollectorTypeId != 4 && meterEquipInfo.dataCollectorTypeId != '4') && <>
                                    <tr>
                                        <th>
                                            LID Device
                                        </th>
                                        <td>
                                            {masterDataValues.lids && masterDataValues.lids.map((dt) => {
                                                if (dt.id == meterEquipInfo.lidId) {
                                                    return dt.displayText;
                                                }
                                            })}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            Data collector type
                                        </th>
                                        <td>
                                            {masterDataValues.dataCollectorTypes && masterDataValues.dataCollectorTypes.map((dt) => {
                                                if (dt.id == meterEquipInfo.dataCollectorTypeId) {
                                                    return dt.name;
                                                }
                                            })}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            Model number
                                        </th>
                                        <td>
                                            {meterEquipInfo.dataCollectorModelNo}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            Serial number
                                        </th>
                                        <td>
                                            {meterEquipInfo.dataCollectorSerialNo}
                                        </td>
                                    </tr>

                                    <tr>
                                        <th>
                                            LID Type
                                        </th>
                                        <td>
                                            {meterEquipInfo.lIDType}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            Meter reading when LID was installed, as displayed on the meter. (This reading must be correct to avoid revisiting the site)
                                        </th>
                                        <td>
                                            {meterEquipInfo.lIDMeterReading}
                                        </td>
                                    </tr>

                                    <tr>
                                        <th>
                                            Scaling factor – (Refer to manufacturer)
                                        </th>
                                        <td>
                                            {meterEquipInfo.scalingFactor}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            Is the LID being used for Telemetry?
                                        </th>
                                        <td>
                                            {meterEquipInfo.lIDTelemetry}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            Unit of measure
                                        </th>
                                        <td>
                                            {/* {meterEquipInfo.calibrationFactorMeter} */}
                                            {masterDataValues.meterFlowUnits && masterDataValues.meterFlowUnits.map((dt) => {
                                                if (dt.id == meterEquipInfo.meterFlowUnitsId) {
                                                    return dt.name;
                                                }
                                            })}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            Power source
                                        </th>
                                        <td>
                                            {masterDataValues.powerSources && masterDataValues.powerSources.map((ps) => {
                                                if (ps.id == meterEquipInfo.dataCollectorPowerSourceId) {
                                                    if (ps.name == 'Select...') return "";
                                                    return ps.name;
                                                }
                                            })}
                                        </td>
                                    </tr>
                                    {
                                        (meterEquipInfo.dataCollectorPowerSourceId == 1) &&
                                        <tr>
                                            <th>
                                                Solar panel serial number
                                            </th>
                                            <td>
                                                {meterEquipInfo.dataCollectorSolarPanelSerialNumber}
                                            </td>
                                        </tr>
                                    }
                                </>}
                                {meterEquipInfo.meteringSystemTypeId != 2 && <>
                                    <tr>
                                        <th>
                                            Method Used
                                        </th>
                                        <td>
                                            {meterEquipInfo.methodUsed}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            Conclusion
                                        </th>
                                        <td>
                                            {meterEquipInfo.otherConclusion}
                                        </td>
                                    </tr>
                                </>}
                                {(parseInt(masterDataValues.OpenChannelId) == parseInt(meterEquipInfo.meteringSystemTypeId)) && <>
                                    <tr>
                                        <th>
                                            Channel type
                                        </th>
                                        <td>
                                            {meterEquipInfo.channelType}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            Channel width (m)
                                        </th>
                                        <td>
                                            {meterEquipInfo.channelWidth}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            Distance of the meter from pumping source or filling point (m)
                                        </th>
                                        <td>
                                            {meterEquipInfo.meterDistanceFromSource}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            Channel length upstream of meter (m)
                                        </th>
                                        <td>
                                            {meterEquipInfo.channelLengthUpstream}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            Channel length downstream of meter (m)
                                        </th>
                                        <td>
                                            {meterEquipInfo.channelLlengthDownstream}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            Design
                                        </th>
                                        <td>
                                            {meterEquipInfo.designEquipmentDetails}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            The Metering Equipment that has been installed is consistent with the design certificate
                                        </th>
                                        <td>
                                            {meteringEquipmentConsistentOptions.map((mc) => {
                                                if (mc.id == meterEquipInfo.meteringEquipmentConsistent) {
                                                    return mc.name;
                                                }
                                            })}
                                        </td>
                                    </tr>
                                </>}
                            </tbody>
                        </table>
                    </div>
                    {(parseInt(props.masterDataValues.OpenChannelId) == parseInt(props.meterEquipInfo.meteringSystemTypeId)) && <>
                        <div className='card-header'>
                            <strong>Calibration Measurement Details</strong>
                        </div>
                        <div className='card-body'>
                            <table class="table fs-90 mb-3">
                                <tbody>
                                    <tr>
                                        <th className='w-25'>
                                            Maximum measured discharge (m³/s)
                                        </th>
                                        <td>
                                            {meterEquipInfo.maxMeasuredDischarge}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            Minimum measured discharge (m³/s)
                                        </th>
                                        <td>
                                            {meterEquipInfo.minMeasuredDischarge}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            Maximum measured velocity (m/s)
                                        </th>
                                        <td>
                                            {meterEquipInfo.maxMeasuredVelocity}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            Minimum measured velocity (m/s)
                                        </th>
                                        <td>
                                            {meterEquipInfo.minMeasuredVelocity}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            Maximum gauge height (m)
                                        </th>
                                        <td>
                                            {meterEquipInfo.maxGaugeHeight}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            Minimum gauge height (m)
                                        </th>
                                        <td>
                                            {meterEquipInfo.minGaugeHeight}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            Maximum error (%)
                                        </th>
                                        <td>
                                            {meterEquipInfo.maxError}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            Minimum error (%)
                                        </th>
                                        <td>
                                            {meterEquipInfo.minError}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            Number of measurements undertaken for index equation (if applicable)
                                        </th>
                                        <td>
                                            {meterEquipInfo.noOfMeasurementsTaken}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className='card-header'>
                            <strong>Method Used to Perform in Situ Volumetric Measurements</strong>
                        </div>
                        <div className='card-body'>
                            {meterEquipInfo.methodUsed}
                        </div>
                        <div className='card-header'>
                            <strong>Conclusion</strong>
                        </div>
                        <div className='card-body'>
                            {meterEquipInfo.openChannelConclusion}
                        </div>
                    </>}
                    {!showReview && <div className='card-footer'>
                        <button type="button" className="btn btn-primary"
                            onClick={() => { cancelConfrimSubmissionAndGoToPage(4); }}>
                            Edit
                        </button>
                    </div>}
                </div>
            </>}

            <div className='card mb-3'>
                <div className='card-header'>
                    <strong>Compliance</strong>
                </div>
                <div className='card-body'>
                    <p>
                        <strong>Does the metering equipment comply with Australian Standard 4747: Meters for non-urban water supply?</strong>
                    </p>
                    <p className='ps-3'>
                        {meteringEquipmentCompylyOptions.map((o) => {
                            if (o.id == meterEquipInfo.complyMeteringEquipment) {
                                return o.name;
                            }
                        })}
                    </p>
                    {(parseInt(meterEquipInfo.complyMeteringEquipment) == 2) && <>
                        <p>
                            <strong>Please select either (a) or (b) below (attach further pages if required to list more reasons or modifications)</strong>
                        </p>
                        <p className='ps-3'>
                            {complyOptions.map((o) => {
                                if (o.id == meterEquipInfo.isModificationToCompliance) {
                                    return o.name;
                                }
                            })}
                        </p>
                        {
                            (meterNonComplianceReason && meterNonComplianceReason.length > 0) &&
                            <>
                                <p><strong>Reason(s):</strong></p>
                                {meterNonComplianceReason.map((o) => {
                                    return <p className='ps-3'>{o.reason}</p>
                                })}
                            </>
                        }

                        {
                            (meterEquipInfo.isModificationToCompliance != undefined && parseInt(meterEquipInfo.isModificationToCompliance) == 1) &&
                            <>
                                <p>
                                    <strong>Modifications required to be performed<span style={{ color: 'red' }}>* </span></strong>
                                </p>
                                <p className='ps-3'>
                                    {meterNonCompliance.modificationsPerformed}
                                </p>
                            </>
                        }
                    </>}
                </div>
                {!showReview && <div className='card-footer'>
                    <button type="button" className="btn btn-primary"
                        onClick={() => { cancelConfrimSubmissionAndGoToPage(5); }}>
                        Edit
                    </button>
                </div>}
            </div>

            {hasError && <div className='alert alert-danger'>
            Please fix all errors before you continue
            </div>}


            <div className="text-end mt-4">
                <button type="button" class="btn btn-primary float-start" data-bs-toggle="modal" data-bs-target="#checkListModal" disabled={loading}>
                    View Checklist
                </button>
                {(parseInt(meterEquipInfo.formStatus) == 1) && (!props.disableEditing) && <>
                    {meterEquipInfo.id != 0 && <button type="button"
                        className="btn btn-danger me-3" disabled={loading}
                        data-bs-toggle="modal" data-bs-target="#deleteDraftModal">
                        Delete Draft
                    </button>}
                    {/* 
                    <button type="button" className="btn btn-primary" disabled={loading}
                        onClick={() => { saveDraftAction(props); }}>
                        Save Draft
                    </button>
                    */}
                    <div className='vr mx-3' />
                </>}


                {!showReview && <>
                    <button type="button" className="btn btn-warning me-3"
                        disabled={loading}
                        onClick={cancelConfrimSubmission}>
                        Previous
                    </button>

                    <button type="button" className="btn btn-success"
                        disabled={loading}
                        onClick={completeConfirmation}>
                        {(parseInt(meterEquipInfo.formStatus) == 1) ? "Confirm" : "Next"}
                    </button>
                </>}

                {showReview > 0 && <>
                    <button type="button" className="btn btn-secondary me-3"
                        disabled={loading}
                        onClick={() => { closeConfirmation(); }}>
                        Close
                    </button>
                    <button type="button" className="btn btn-primary"
                        disabled={loading}
                        onClick={() => { setMaintenanceValidation(); saveDraftAction(props); }}>
                        Clone this record
                    </button>
                </>}
            </div>
        </form>
    )
}

MeterValidationCertificateConfirmation = reduxForm({
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate,
    onSubmitFail: (errors, dispatch ) => {
        dispatch(change('certificate', 'hasError', true));
     },
     onSubmitSuccess:(result , dispatch )=>{
        dispatch(change('certificate', 'hasError', false));
        window.scrollTo(0, 0);
     }
})(MeterValidationCertificateConfirmation)

MeterValidationCertificateConfirmation = connect(
    (state, props) => {
        const selectorCert = formValueSelector('certificate')
        const hasError = selectorCert(state, 'hasError')


        const selector = formValueSelector(props.form);
        const meterEquipInfo = selector(state, 'meteringEquipmentInformation')
        const authHolderInfo = selector(state, 'authorityHolderDetails');
        const esidExternalList = selector(state, 'esidExternalList');
        const meterNumberExternalList = selector(state, 'meterNumberExternalList');
        const waterAccessLicenceNoExternalList = selector(state, 'waterAccessLicenceNoExternalList');
        const appWorkInfo = selector(state, 'approvedWorkDetails');
        const documents = selector(state, 'documents')
        const meterNonComplianceReason = selector(state, 'meterNonComplianceReason')
        const meterNonCompliance = selector(state, 'meterNonCompliance')
        const registrationDoc = selector(state, 'registrationDoc')
        const userProfileValues = selector(state, 'mdata.userProfile')
        const masterDataValues = selector(state, 'mdata')
        const loading = selector(state, 'loading')
        const consentData = selector(state, 'consentData')

        const form = props.form;

        return {
            meterEquipInfo,
            authHolderInfo,
            esidExternalList,
            meterNumberExternalList,
            waterAccessLicenceNoExternalList,
            appWorkInfo,
            masterDataValues,
            documents,
            meterNonComplianceReason,
            meterNonCompliance,
            registrationDoc,
            userProfileValues,
            consentData,
            form,
            loading,
            hasError
        }
    }
)(MeterValidationCertificateConfirmation)

export default MeterValidationCertificateConfirmation;

