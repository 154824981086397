import React from 'react';
import { UneditableTextInput } from '../../../../components/tools/BootstrapControls';
import ApprovalFields from '../../../../components/common/ApprovalFields';
import { MapView } from '../../../../components/tools/MapView';

class MeterInfo  extends React.Component {

    render() {
        const meter = this.props.meter;
        return <>
            
            <div class="card mb-3">
                <div class="card-header  bg-primary text-white">
                    <b>Approval Details </b>
                </div>
                <div className='card-body'>
                    <ApprovalFields approval={meter.approval} />
                </div>
            </div>

            <div class="card mb-3">
                <div class="card-header bg-primary text-white">
                    <b>Details of Approved Works</b>
                </div>
                <div className='card-body'>
                    <div className='row mb-3'>
                        <div className='col-md-4 py-2'>
                        Property
                        </div>
                        <div className='col-md-8'>
                            <UneditableTextInput
                                fieldExtraClass="bg-light"
                                value={meter.property?meter.property.propertyName:null}
                            />
                        </div>
                    </div>
                    <div className='row mb-3'>
                        <div className='col-md-4 py-2'>
                        Lot
                        </div>
                        <div className='col-md-8'>
                            <UneditableTextInput
                                fieldExtraClass="bg-light"
                                value={meter.lot?<>{meter.lot.lotNumber}/{meter.lot.sectionNumber}/{meter.lot.planNumber} {meter.lot.planType}</>:null}
                            />
                        </div>
                    </div>
                    <div className='row mb-3'>
                        <div className='col-md-4 py-2'>
                            Extraction Site
                        </div>
                        <div className='col-md-8'>
                            <UneditableTextInput
                                fieldExtraClass="bg-light"
                                value={meter.extractionSite?meter.extractionSite.esid:null}
                            />
                        </div>
                    </div>
                    <div className='row mb-3'>
                        <div className='col-md-4 py-2'>
                            Water Sharing Plan
                        </div>
                        <div className='col-md-8'>
                            <UneditableTextInput
                                fieldExtraClass="bg-light"
                                value={meter.extractionSite?meter.extractionSite.waterSharingPlanDesc:null}
                            />
                        </div>
                    </div>
                    <div className='row mb-3'>
                        <div className='col-md-4 py-2'>
                        Water Source
                        </div>
                        <div className='col-md-8'>
                            <UneditableTextInput
                                fieldExtraClass="bg-light"
                                value={meter.extractionSite?meter.extractionSite.waterSourceName:null}
                            />
                        </div>
                    </div>
                    <div className='row mb-3'>
                        <div className='col-md-4 py-2'>
                        Water Type
                        </div>
                        <div className='col-md-8'>
                            <UneditableTextInput
                                fieldExtraClass="bg-light"
                                value={meter.extractionSite?meter.extractionSite.waterType:null}
                            />
                        </div>
                    </div>
                    <div className='row mb-3'>
                        <div className='col-md-4 py-2'>
                        Works ID
                        </div>
                        <div className='col-md-8'>
                            <UneditableTextInput
                                fieldExtraClass="bg-light"
                                value={meter.works?meter.works.worksId:null}
                            />
                        </div>
                    </div>
                    <div className='row mb-3'>
                        <div className='col-md-4 py-2'>
                        Works Description
                        </div>
                        <div className='col-md-8'>
                            <UneditableTextInput
                                fieldExtraClass="bg-light"
                                value={meter.works?meter.works.workType:null}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div class="card mb-3">
                <div class="card-header bg-primary text-white">
                    <b>Customer Site Details</b>
                </div>
                <div className='card-body'>
                    <div className='row mb-3'>
                        <div className='col-md-4 py-2'>
                        Customer Site Name
                        </div>
                        <div className='col-md-8'>
                            <UneditableTextInput
                                fieldExtraClass="bg-light"
                                value={meter.customerSiteName}
                            />
                        </div>
                    </div>
                    <div className='row mb-3'>
                        <div className='col-md-4 py-2'>
                        Customer Pump Number
                        </div>
                        <div className='col-md-8'>
                            <UneditableTextInput
                                fieldExtraClass="bg-light"
                                value={meter.customersPumpNumber}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div class="card mb-3">
                <div class="card-header bg-primary text-white">
                    <b>Meter Details</b>
                </div>
                <div className='card-body'>
                    <div className='row mb-3'>
                        <div className='col-md-4 py-2'>
                        Meter Number
                        </div>
                        <div className='col-md-8'>
                            <UneditableTextInput
                                fieldExtraClass="bg-light"
                                value={meter.meterNumber}
                            />
                        </div>
                    </div>
                    <div className='row mb-3'>
                        <div className='col-md-4 py-2'>
                        Meter System Type
                        </div>
                        <div className='col-md-8'>
                            <UneditableTextInput
                                fieldExtraClass="bg-light"
                                value={meter.meteringSystemTypes?meter.meteringSystemTypes.name:null}
                            />
                        </div>
                    </div>
                    <div className='row mb-3'>
                        <div className='col-md-4 py-2'>
                        Meter Manufacturer and Model
                        </div>
                        <div className='col-md-8'>
                            <UneditableTextInput
                                fieldExtraClass="bg-light"
                                value={meter.meterTypeModel?meter.meterTypeModel.name:null}
                            />
                        </div>
                    </div>
                    {meter.meterTypeModel && meter.meterTypeModel.key=='Other' && <div className='row mb-3'>
                        <div className='col-md-4 py-2'>
                        Other Manufacturer and Model Text
                        </div>
                        <div className='col-md-8'>
                            <UneditableTextInput
                                fieldExtraClass="bg-light"
                                value={meter.otherModelText}
                            />
                        </div>
                    </div>}
                    <div className='row mb-3'>
                        <div className='col-md-4 py-2'>
                        Meter Serial Number
                        </div>
                        <div className='col-md-8'>
                            <UneditableTextInput
                                fieldExtraClass="bg-light"
                                value={meter.meterSerialNumber}
                            />
                        </div>
                    </div>
                    <div className='row mb-3'>
                        <div className='col-md-4 py-2'>
                        Meter Size(mm)
                        </div>
                        <div className='col-md-8'>
                            <UneditableTextInput
                                fieldExtraClass="bg-light"
                                value={meter.meterSize}
                            />
                        </div>
                    </div>
                    <div className='row mb-3'>
                        <div className='col-md-4 py-2'>
                        Pattern Approval Number (if applicable)
                        </div>
                        <div className='col-md-8'>
                            <UneditableTextInput
                                fieldExtraClass="bg-light"
                                value={meter.patternApprovalNumber}
                            />
                        </div>
                    </div>
                    <div className='row mb-3'>
                        <div className='col-md-4 py-2'>
                        Tamper-evident Seal Numbers
                        </div>
                        <div className='col-md-8'>
                            {meter.sealNumbers.map(s=><span class="bg-light me-3 p-2 d-inline-block">{s}</span>)}
                        </div>
                    </div>
                </div>
            </div>

            <div class="card mb-3">
                <div class="card-header bg-primary text-white">
                    <b>Meter Location</b>
                </div>
                <div className='card-body'>
                    <div className='row mb-3'>
                        <div className='col-md-4'>
                            <UneditableTextInput
                                label="Latitude"
                                fieldExtraClass="bg-light"
                                value={meter.latitude}
                            />
                        </div>
                        <div className='col-md-4'>
                            <UneditableTextInput
                                label="Longitude"
                                fieldExtraClass="bg-light"
                                value={meter.longitude}
                            />
                        </div>
                    </div>

                    <MapView
                        readOnly={true}                
                        point={{ latitude: meter.latitude, longitude: meter.longitude}}
                        disabled={true}
                    />
                </div>
            </div>
            {meter.meteringSystemTypes && (meter.meteringSystemTypes.key=='CLOSED_CONDUIT' || meter.meteringSystemTypes.key=='PORTABLE_METER') && <div class="card mb-3">
                <div class="card-header bg-primary text-white">
                    <b>Emplacement Details</b>
                </div>
                <div className='card-body'>
                    <div className='row mb-3'>
                        <div className='col-md-4 py-2'>
                        Pipe length upstream of meter (mm)
                        </div>
                        <div className='col-md-8'>
                            <UneditableTextInput
                                fieldExtraClass="bg-light"
                                value={meter.pipeLengthUpstream}
                            />
                        </div>
                    </div>
                    <div className='row mb-3'>
                        <div className='col-md-4 py-2'>
                        Pipe length downstream of meter (mm)
                        </div>
                        <div className='col-md-8'>
                            <UneditableTextInput
                                fieldExtraClass="bg-light"
                                value={meter.pipeLengthDownstream}
                            />
                        </div>
                    </div>
                </div>
            </div>}

            <div class="card mb-3">
                <div class="card-header bg-primary text-white">
                    <b>LID Details</b>
                </div>
                <div className='card-body'>
                    <div className='row mb-3'>
                        <div className='col-md-4 py-2'>
                        Is the LID being used for Telemetry?
                        </div>
                        <div className='col-md-8'>
                            <UneditableTextInput
                                fieldExtraClass="bg-light"
                                value={meter.meterLid.isLidTelemetry === true ? "Yes" : meter.meterLid.isLidTelemetry === false ? "No" : null}
                            />
                        </div>
                    </div>
                    {meter.meterLid.isLidTelemetry && <div className='row mb-3'>
                        <div className='col-md-4 py-2'>
                        LID Telemetry Type
                        </div>
                        <div className='col-md-8'>
                            <UneditableTextInput
                                fieldExtraClass="bg-light"
                                value={meter.meterLid.telemetryType}
                            />
                        </div>
                    </div>}
                    <div className='row mb-3'>
                        <div className='col-md-4 py-2'>
                        LID Device
                        </div>
                        <div className='col-md-8'>
                            <UneditableTextInput
                                fieldExtraClass="bg-light"
                                value={meter.meterLid.lid.name}
                            />
                        </div>
                    </div>
                    <div className='row mb-3'>
                        <div className='col-md-4 py-2'>
                        LID Serial number
                        </div>
                        <div className='col-md-8'>
                            <UneditableTextInput
                                fieldExtraClass="bg-light"
                                value={meter.meterLid.lidSerialNumber}
                            />
                        </div>
                    </div>

                    <div className='row mb-3'>
                        <div className='col-md-4 py-2'>
                        LID Type
                        </div>
                        <div className='col-md-8'>
                            <UneditableTextInput
                                fieldExtraClass="bg-light"
                                value={meter.meterType?meter.meterType.name:null}
                            />
                        </div>
                    </div>
                    {meter.meterType && meter.meterType.key=='PULSE' && <div className='row mb-3'>
                        <div className='col-md-4 py-2'>
                        Scaling Factor (Multiplier)
                        </div>
                        <div className='col-md-8'>
                            <UneditableTextInput
                                fieldExtraClass="bg-light"
                                value={meter.meterScalingFactor}
                            />
                        </div>
                    </div>}
                </div>
            </div>

            <div class="card mb-3">
                <div class="card-header bg-primary text-white">
                    <b>Compliance</b>
                </div>
                <div className='card-body'>
                    <p><b>Does the metering equipment comply with Australian Standard 4747: Meters for non-urban water supply?</b></p>
                    <p>{meter.isComplied===true?<>Yes</>:meter.isComplied===false?<>No</>:"Nil"}</p>
                    {meter.meterNoneComplianceReasons.length>0 && <>
                        <p><b>Reasons for not compliance</b></p>
                        <ul>
                            {meter.meterNoneComplianceReasons.map(r=><li>{r}</li>)}
                        </ul>
                    </>}
                </div>
            </div>

            

            
        </>
    }
}

export default MeterInfo;
