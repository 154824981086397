import React from 'react';
//import '../global';
import Authentication from '../helpers/Authentication'

const authentication = new Authentication();

class AHDDeclaration extends React.Component
{


    constructor(props)
    {
       super(props);
       this.state = {       
        isLogin : authentication.isLoggedIn()
       };
    }

    componentDidMount() {
        
        console.log("AHDDeclaration componentDidMount");
        
    }   

   render()
   {
       return <>
            <h3 className='mb-3'>Survey Benchmark Installations Guidance for validation certificates</h3>
            <div className='row'>
                <div className='col-sm-7'>
                    <p>The following guide will assist a duly qualified person (Registered Surveyor) to record necessary information to <strong>validate survey benchmark installations.</strong>  </p>
                    <p>
                    This guide will assist a duly qualified person (DQP) (Registered surveyor) to start installing benchmarks ahead of the release of a formal validation certificate for online submission in the DQP Portal.</p>
                    <p>
                        <a href="/ahd_guide.pdf" target = "_blank">Floodplain harvesting survey benchmarks validation certificate - Guidance</a>.
                    </p>
                </div>
            </div>
                
        </>;
   }
}

export default AHDDeclaration;