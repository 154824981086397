import React from 'react';
import { StepProgressBar } from '../../../../components/tools/BootstrapControls';
import ValidationFileUploder from './ValidationFileUploder';
import { AsteriskRed } from '../../../../components/tools/Others';
import { MESSAGE_UPLOAD_LIMITS } from '../../../../constants/MeterFormConstants';


const predefinedReasons = [
    "Telemetry Equipment not available",
    "Telemetry blackspot - no service/pending exemption",
    "Grandfathered - Installed prior to April 2019",
    "DQP failed to complete commissioning process"
]

export default class ValidationPage3  extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            hasFileError : false,
            errorType3 : null,
            errorType4 : null,
            errorType5 : null,
            errorType6 : null,
            errorType7 : null,
            errorType13 : null,
            errorType14 : null,
            errorType16:null
        }
    }

    next = () => {
        const form = this.props.validationForm;
        const newState = {
            errorType3 : null,
            errorType4 : null,
            errorType5 : null,
            errorType6 : null,
            errorType7 : null,
            errorType13 : null,
            errorType14 : null,
        };
        var hasErr = false;
        if(form.val('documents').filter(d=>d.documentType==3).length==0) {
            newState.errorType3 = "Please upload at least one file for this";
            hasErr = true;
        }
        if(form.val('documents').filter(d=>d.documentType==4).length==0) {
            newState.errorType4 = "Please upload at least one file for this";
            hasErr = true;
        }
        if(form.val('documents').filter(d=>d.documentType==5).length==0) {
            newState.errorType5 = "Please upload at least one file for this";
            hasErr = true;
        }
        if(form.val('documents').filter(d=>d.documentType==6).length==0) {
            newState.errorType6 = "Please upload at least one file for this";
            hasErr = true;
        }
        if(form.val('documents').filter(d=>d.documentType==7).length==0) {
            newState.errorType7 = "Please upload at least one file for this";
            hasErr = true;
        }
        if(form.val('documents').filter(d=>d.documentType==13).length==0) {
            newState.errorType13 = "Please upload at least one file for this";
            hasErr = true;
        }
        if(form.val('documents').filter(d=>d.documentType==14).length==0) {
            newState.errorType14 = "Please upload at least one file for this";
            hasErr = true;
        }
        if(form.val('documents').filter(d=>d.documentType==16).length==0) {
            newState.errorType16 = "Please upload at least one file for this";
            hasErr = true;
        }
        newState.hasFileError = hasErr;
        this.setState(newState,!hasErr?()=>{
            form.runAction('goToStep4')
        }:null)
        
    }

    nonComplianceReasonsChanged = (reason) =>{
        const form = this.props.validationForm;
        var index = -1;
        form.val("nonComplianceReasons").forEach((r,i)=>{
            if(reason == r) index = i;
        })
        if(index == -1) form.val("nonComplianceReasons").push(reason);
        else form.val("nonComplianceReasons").splice(index, 1);
        form.vals({nonComplianceReasons:form.val("nonComplianceReasons")})
    }

    render() {
        const approval = this.props.approval;
        const form = this.props.validationForm;
        const role = this.props.role;
        return <>
            <div className='my-5'>      
                <StepProgressBar
                    totalSteps={5} 
                    currentStep={3} 
                    disableFutureSteps={form.val('formStatus')==1} 
                    stepClick={(page) =>{this.props.gotToStep(page)}}
                />
            </div>

            <h5 className='pb-2 border-bottom mb-3'>Compliance</h5>
            <p>
            <b>Does the metering equipment comply with Australian Standard 4747: Meters for non-urban water supply? <AsteriskRed/></b>
            </p>
            <div className='mb-4'>
                {form.renderYesNoOptions('complying')}
            </div>
            {form.val('complying')===false && <>
                <p>
                    <b>Reasons for not compliance? <AsteriskRed/></b>
                </p>
                <div className='mb-4'>
                    {predefinedReasons.map((r,i)=>{
                        const found = form.val('nonComplianceReasons').filter(cr=>cr==r).length>0;
                        return <div class="form-check">
                            <input class="form-check-input" type="checkbox" onChange={()=>this.nonComplianceReasonsChanged(r)} checked={found}  id={`nonComplianceReason_${i}`} disabled={form.isFieldDisabled('nonComplianceReasons') || form.isFormDisabled()} />
                            <label class="form-check-label" for={`nonComplianceReason_${i}`}>
                            {r}
                            </label>
                        </div>
                    })}
                    {form.val('nonComplianceReasons').map((r,i)=>{
                        if(predefinedReasons.filter(cr=>cr==r).length>0) return null;
                        return <div class="form-check">
                            <input class="form-check-input" type="checkbox" onChange={()=>this.nonComplianceReasonsChanged(r)} checked={true}  id={`nonComplianceReason_${(i+1)*10}`} disabled={form.isFieldDisabled('nonComplianceReasons') || form.isFormDisabled()} />
                            <label class="form-check-label" for={`nonComplianceReason_${(i+1)*10}`}>
                            {r}
                            </label>
                        </div>
                    })}
                    {form.err('nonComplianceReasons') && <div className='text-danger'>{form.err('nonComplianceReasons')}</div>}
                </div>
            </>}

            <h5 className='pb-2 border-bottom mb-3'>Supporting Documents</h5>

            <p className='form-text text-warning'>{MESSAGE_UPLOAD_LIMITS}</p>
            <ul className="list-group mb-3">
                <li className="list-group-item">
                    <div className='row'>
                        <div className='col-md-4'>
                            <b>Meter marker plate <AsteriskRed/></b>
                            {this.state.errorType3 && <div className='text-danger'>{this.state.errorType3}</div>}
                        </div>
                        <div className='col-md-8'>
                            <ValidationFileUploder
                                files={form.val('documents').filter(d=>d.documentType==3)}
                                validationForm={form}
                                documentType={3}
                            />
                            
                        </div>
                    </div>
                </li>
                <li className="list-group-item">
                    <div className='row'>
                        <div className='col-md-4'>
                            <b>Meter serial number <AsteriskRed/></b>
                            {this.state.errorType13 && <div className='text-danger'>{this.state.errorType13}</div>}
                        </div>
                        <div className='col-md-8'>
                            <ValidationFileUploder
                                files={form.val('documents').filter(d=>d.documentType==13)}
                                validationForm={form}
                                documentType={13}
                            />
                            
                        </div>
                    </div>
                </li>
                <li className="list-group-item">
                    <div className='row'>
                        <div className='col-md-4'>
                            <b>LID serial number <AsteriskRed/></b>
                            {this.state.errorType14 && <div className='text-danger'>{this.state.errorType14}</div>}
                        </div>
                        <div className='col-md-8'>
                            <ValidationFileUploder
                                files={form.val('documents').filter(d=>d.documentType==14)}
                                validationForm={form}
                                documentType={14}
                            />
                            
                        </div>
                    </div>
                </li>
                <li className="list-group-item">
                    <div className='row'>
                        <div className='col-md-4'>
                            <b>Evidence of tamper proofing/Meter seals <AsteriskRed/></b>
                            {this.state.errorType4 && <div className='text-danger'>{this.state.errorType4}</div>}
                        </div>
                        <div className='col-md-8'>
                            <ValidationFileUploder
                                files={form.val('documents').filter(d=>d.documentType==4)}
                                validationForm={form}
                                documentType={4}
                            />
                            
                        </div>
                    </div>
                </li>
                <li className="list-group-item">
                    <div className='row'>
                        <div className='col-md-4'>
                            <b>Meter emplacement <AsteriskRed/></b>
                            {this.state.errorType5 && <div className='text-danger'>{this.state.errorType5}</div>}
                        </div>
                        <div className='col-md-8'>
                            <ValidationFileUploder
                                files={form.val('documents').filter(d=>d.documentType==5)}
                                validationForm={form}
                                documentType={5}
                            />
                            
                        </div>
                    </div>
                </li>
                <li className="list-group-item">
                    <div className='row'>
                        <div className='col-md-4'>
                            <b>Meter display reading Nett total date of commissioning <AsteriskRed/></b>
                            {this.state.errorType6 && <div className='text-danger'>{this.state.errorType6}</div>}
                        </div>
                        <div className='col-md-8'>
                            <ValidationFileUploder
                                files={form.val('documents').filter(d=>d.documentType==6)}
                                validationForm={form}
                                documentType={6}
                            />
                            
                        </div>
                    </div>
                </li>
                <li className="list-group-item">
                    <div className='row'>
                        <div className='col-md-4'>
                            <b>Site installation photos <AsteriskRed/></b>
                            {this.state.errorType7 && <div className='text-danger'>{this.state.errorType7}</div>}
                        </div>
                        <div className='col-md-8'>
                            <ValidationFileUploder
                                files={form.val('documents').filter(d=>d.documentType==7)}
                                validationForm={form}
                                documentType={7}
                            />
                            
                        </div>
                    </div>
                </li>

                <li className="list-group-item">
                    <div className='row'>
                        <div className='col-md-4'>
                            <b>Meter reading on date LID was installed <AsteriskRed /></b>
                            {this.state.errorType16 && <div className='text-danger'>{this.state.errorType16}</div>}
                        </div>
                        <div className='col-md-8'>
                            <ValidationFileUploder
                                files={form.val('documents').filter(d=>d.documentType==16)}
                                validationForm={form}
                                documentType={16}
                            />
                            
                        </div>
                    </div>
                </li>

                <li className="list-group-item">
                    <div className='row'>
                        <div className='col-md-4'>
                            <b>Calibration certificate</b>
                        </div>
                        <div className='col-md-8'>
                            <ValidationFileUploder
                                files={form.val('documents').filter(d=>d.documentType==15)}
                                validationForm={form}
                                documentType={15}
                            />
                            
                        </div>
                    </div>
                </li>

                <li className="list-group-item">
                    <div className='row'>
                        <div className='col-md-4'>
                            <b>Other</b>
                        </div>
                        <div className='col-md-8'>
                            <ValidationFileUploder
                                files={form.val('documents').filter(d=>d.documentType==12)}
                                validationForm={form}
                                documentType={12}
                            />
                        </div>
                    </div>
                </li>
            </ul>

            {form.val('formStatus')==1 && <div class="text-end">

                {(this.state.hasFileError || form.haveErrors()) && <div className='alert alert-danger text-end'>
                Please complete the required information and resolve any errors that may display in order to proceed further.
                </div>}

                <button type="button" class="btn btn-primary float-start" onClick={()=>this.props.gotToStep(2)}  disabled={form.isFormDisabled()}>
                    Back
                </button>

                {role=="OWNER" && <button type="button" class="btn btn-primary me-3" onClick={()=>form.runAction('save')}  disabled={form.isFormDisabled()}>
                    Save
                </button>}
                <button type="button" class="btn btn-success" onClick={this.next}  disabled={form.isFormDisabled()}>
                    Next
                </button>
            </div>}
        </>;
    }
}