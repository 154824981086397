import React from 'react';
import { Field, FieldArray, reduxForm, formValueSelector, change } from 'redux-form';
import { connect } from 'react-redux'
import 'react-redux';
import 'redux';
import "react-datepicker/dist/react-datepicker.css";
import { saveDraftAction, clickNextAction, scrollTofocusError } from "../common/MeterFormValidations"
import { validateCertificatePartE } from './MeterValidationCertificate.Validation';
import {InputTextArrayForRedux, TextAreaForRedux, DatePickerForRedux, RadiosForRedux }  from '../../tools/BootstrapControls'

const validate = validateCertificatePartE;

let MeterValidationCertificatePartE = props => {
  const {
    handleSubmit, meterEquipInfo, meterNonComplianceReason, previousPage, loading, hasError
  } = props

  const disableFields = (parseInt(meterEquipInfo.formStatus) == 2)|| props.disableFields  || props.disableEditing || loading? true: false;


  const complyOptions =
      [
         { id: "1", name: "(a) The metering equipment does not comply with AS 4747 for the reasons specified below and it can be modified to comply with AS 4747 as specified below", selected: false },
         { id: "2", name: "(b) The metering equipment does not comply with AS 4747 for the reasons specified below and it cannot be modified to comply with AS 4747", selected: false }
      ];
  const meteringEquipmentCompylyOptions =
  [
      { id: "1", name: "Yes", selected: false },
      { id: "2", name: "No", selected: false }
  ];
  const clickNext = React.useRef(null);
  const complyChange = (event) => {
    
    if(parseInt(event.target.value) == 1)
    {
      props.dispatch(change(props.form, 'meteringEquipmentInformation.validationMeterEquipmentComplies', "1"))
      props.dispatch(change(props.form, 'meteringEquipmentInformation.compylyNavigation', 1))
      
      //clickNextAction(props);
    }
    
  }
  if(meterEquipInfo.compylyNavigation == 1)
  {
    clickNextAction(props);
    props.dispatch(change(props.form, 'meteringEquipmentInformation.compylyNavigation', 0))
  }

  if(parseInt(meterEquipInfo.complyMeteringEquipment) == 2)
  {
    if(props.meterEquipInfo.isModificationToCompliance == null || props.meterEquipInfo.isModificationToCompliance == "" )
    {
      
      props.dispatch(change(props.form, 'meteringEquipmentInformation.validationMeterEquipmentComplies', "2"))
    }
     
  }
  
  if(parseInt(meterEquipInfo.complyMeteringEquipment) == 1)
  {
     props.dispatch(change(props.form, 'meteringEquipmentInformation.validationMeterEquipmentComplies', "1"))
     //clickNextAction(props);
     
  }
  else if(parseInt(meterEquipInfo.complyMeteringEquipment) == 2)
  {
     if(meterNonComplianceReason.length == 0)
     {
      props.dispatch(change(props.form, 'meterNonComplianceReason',meterNonComplianceReason.concat({reason:'',comments:''}) ))
     }
     if(parseInt(meterEquipInfo.isModificationToCompliance) == 1)
       props.dispatch(change(props.form, 'meteringEquipmentInformation.validationMeterEquipmentComplies', "2"))
     else if(parseInt(meterEquipInfo.isModificationToCompliance) == 2)
       props.dispatch(change(props.form, 'meteringEquipmentInformation.validationMeterEquipmentComplies', "3"))
  }
  

  return (
    <form className="vertical" onSubmit={handleSubmit}>
      <h5 className='mb-3'>Compliance</h5>

      <div className='mb-3'>
        <Field 
          name={"meteringEquipmentInformation.complyMeteringEquipment"}
          component={RadiosForRedux} 
          options={meteringEquipmentCompylyOptions}
          props={{ disabled: disableFields}}
          values={meterEquipInfo.complyMeteringEquipment}
          label={<>Does the metering equipment comply with Australian Standard 4747: Meters for non-urban water supply?<span className='text-danger'>* </span></>}
          handleChange={complyChange}
        />
      </div>

      {(parseInt(meterEquipInfo.complyMeteringEquipment) == 2) && <>
          <div className="row mb-3">
            <div className='col-sm-7'>
              <Field
                label={<>Please select either (a) or (b) below (attach further pages if required to list more reasons or modifications):<span className='text-danger'>* </span></>}
                name={"meteringEquipmentInformation.isModificationToCompliance"} 
                values={meterEquipInfo.isModificationToCompliance}
                props={{ disabled: disableFields }}
                component={RadiosForRedux} options={complyOptions} 
              />
            </div>
          </div>

          <div className="row mb-3">
            <div className='col-sm-7'>
              <FieldArray 
                name="meterNonComplianceReason" 
                props={{ disabled: disableFields }} 
                label={<>Reason/s <span className='text-danger'>*</span></>}
                fieldName="reason"
                component={InputTextArrayForRedux}
              />
            </div>
          </div>


          {(meterEquipInfo.isModificationToCompliance != undefined && parseInt(meterEquipInfo.isModificationToCompliance) == 1) &&
            <div className='mb-3'>
              <Field 
                name="meterNonCompliance.modificationsPerformed" 
                props={{ disabled: disableFields }} 
                label={<>Modifications required to be performed <span className='text-danger'>*</span></>}
                component={TextAreaForRedux} 
                cols={40}
                rows={10}
              />
          </div>}
      </>}

      {hasError && <div className='alert alert-danger'>
         Please fix all errors before you continue
      </div>}

      <div className="text-end mt-4">
        <button type="button" class="btn btn-primary float-start" data-bs-toggle="modal" data-bs-target="#checkListModal" disabled={loading}>
            View Checklist
        </button>
        {(parseInt(meterEquipInfo.formStatus) == 1) && (!props.disableEditing) && <>
            {meterEquipInfo.id!=0 && <button type="button"  
              className="btn btn-danger me-3" disabled={loading} 
              data-bs-toggle="modal" data-bs-target="#deleteDraftModal">
              Delete Draft
            </button>}
            {/*}
            <button type="button"  className="btn btn-primary" disabled={loading}
              onClick={() => { saveDraftAction(props); }}>
              Save Draft
            </button>
        */}
            <div className='vr mx-3' />
        </>}
        <button type="button"  className="btn btn-warning me-3" 
            disabled={loading}
            onClick={()=>{previousPage(meterEquipInfo.formStatus)}}>
            Previous
        </button>
        <button type="button" className="btn btn-success"
            disabled={loading}
            onClick={() => { clickNextAction(props); }}>
            Next
        </button>
      </div>
    </form>
  )
}
MeterValidationCertificatePartE = reduxForm({
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate,
  onSubmitFail: (errors, dispatch ) => {
    dispatch(change('certificate', 'hasError', true));
 },
 onSubmitSuccess:(result , dispatch )=>{
    dispatch(change('certificate', 'hasError', false));
    window.scrollTo(0, 0);
 }
})(MeterValidationCertificatePartE)

MeterValidationCertificatePartE = connect(
  (state,props) => {
    const selectorCert = formValueSelector('certificate')
    const hasError = selectorCert(state, 'hasError')


    // can select values individually
    const selector = formValueSelector(props.form);
    const meterEquipInfo = selector(state, 'meteringEquipmentInformation');
    const meterNonComplianceReason = selector(state, 'meterNonComplianceReason')
    const loading = selector(state, 'loading')
    const form = props.form
    return {
        meterEquipInfo,
        meterNonComplianceReason,
        form,
        loading,
        hasError
    }
  }
)(MeterValidationCertificatePartE)

export default MeterValidationCertificatePartE


