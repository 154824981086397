

//const EMAIL = /^[a-zA-Z0-9][\-_\.\+\!\#\$\%\&\'\*\/\=\?\^\`\{\|]{0,1}([a-zA-Z0-9][\-_\.\+\!\#\$\%\&\'\*\/\=\?\^\`\{\|]{0,1})*[a-zA-Z0-9]@[a-zA-Z0-9][-\.]{0,1}([a-zA-Z][-\.]{0,1})*[a-zA-Z0-9]\.[a-zA-Z0-9]{1,}([\.\-]{0,1}[a-zA-Z]){0,}[a-zA-Z0-9]{0,}$/i;
const EMAIL = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
const DECIMAL_NUMBER_3_POSITIVE = /^\s*[0-9]\d*(\.\d{1,3})?\s*$/;
const POSITIVE_NUMBER = /^\s*[0-9]\d*(\.\d+)?\s*$/;
const IS_NUMBER = /^\s*\-?[0-9]\d*(\.\d+)?\s*$/;
const HAS_DIGIT = /[0-9]/;
const POSITIVE_WHOLE_NUMBER=/^\s*[1-9]\d*\s*$/;
const JPEG = "image/jpeg";
const PDF = "application/pdf";
const PNG = "image/png";
const GIF = "image/gif";

export default {
    regex:  {
        EMAIL,
        DECIMAL_NUMBER_3_POSITIVE,
        POSITIVE_NUMBER,
        HAS_DIGIT,
        IS_NUMBER,
        POSITIVE_WHOLE_NUMBER
    },
    fileTypes: {
        JPEG,
        PDF,
        PNG,
        GIF
    }
    
}
