import React from 'react';
import * as apiPathes from '../apiPathes';

import * as serviceCaller from '../serviceCaller'

class Dashboard  extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    async componentDidMount(){
        const result = await serviceCaller.get(apiPathes.sessionDestroy,{noDefaultHandle: true});
        if(result) {
            this.setState({logoutSuccessed: true})
        }
    }

    render() {
        return <>
            <div className='text-center m-5 p-5'>
                {this.state.logoutSuccessed && <div className='alert alert-primary'>
                    You have logged out from the Nominated Measurement Period module.
                </div>}
                {!this.state.logoutSuccessed && <>
                    <span className="spinner-border spinner-border-sm me-2 text-primary" role="status" aria-hidden="true"></span>
                    Please wait...
                </>}
            </div>
        </>
    }
}

export default Dashboard;