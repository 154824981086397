import React from 'react';

import Authentication from '../helpers/Authentication';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import axios from 'axios';
import ApprovalSearchFormWithConsent from '../components/common/ApprovalSearchFormWithConsent'

class RequestConsent extends React.Component {

    constructor(props) {
        super(props)

        let search = window.location.search;
        let params = new URLSearchParams(search);
        let approvalNumber = params.get('approvalNumber');

        this.state = {
            approvalNumber
        }
    }

    config = {
        baseURL: process.env.REACT_APP_WEBAPI_URL,
        headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json' }
    };

    componentDidMount() {
        
    }

    approvalSelectedHandler = async (approvalNumber, selectedHolder, callback) => {

    }

    render() {
        return <>
            <h3>Request for consent</h3>
            <ApprovalSearchFormWithConsent onCustomerConsentValid={this.approvalSelectedHandler} approvalNumber={this.state.approvalNumber} />
        </>;
    }
}

export default RequestConsent;
