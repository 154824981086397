import React from 'react';
import { loadModules } from 'esri-loader';
import { gridLayer } from 'leaflet';
import {
  MAP_SIX_LAYER9, MAP_SIX_LAYER3, WNSW_LICENCE_LAYER,
  WNSW_PRODUCTION_BORE_LAYER, WNSW_MONITORING_BORE_LAYER
} from '../../constants/MeterFormConstants'

const modules = ['esri/Map', 'esri/views/MapView', 'esri/layers/FeatureLayer',
'esri/layers/GraphicsLayer', 'esri/Graphic', 'esri/config', 'esri/widgets/BasemapGallery',
"esri/widgets/Fullscreen", 'esri/widgets/BasemapToggle', "esri/widgets/Sketch/SketchViewModel",
"esri/geometry/support/webMercatorUtils", "dojo/dom-geometry",
"esri/symbols/SimpleFillSymbol",
"esri/symbols/SimpleLineSymbol",
"esri/Color", "esri/geometry/geometryEngine", "esri/geometry/Point", "dojo/request",
"esri/geometry/SpatialReference"];

const initCenter = [151, -33];

const labelClassLot = {
    symbol: {
        type: "text",  // autocasts as new TextSymbol()
        color: "rgba(255, 255, 255, 1)",
        verticalAlignment: "bottom",
        horizontalAlignment: "left",
        font: {  // autocast as new Font()
            family: "Arial",
            size: 8,
        }
    },
    labelPlacement: "always-horizontal",
    labelExpressionInfo: {expression: "return $feature.lotidstring"},
    maxScale: 0,
    minScale: 100000
};


const lotRenderer = {  // autocasts as new LabelClass()
    type: "simple",
    symbol: {
        type: "simple-fill",  // autocasts as new TextSymbol()
        outline: {  // autocasts as new SimpleLineSymbol()
            color: "orange",
            width: 0.5
        }
    }
}

const pointSymbol = {
    type: "picture-marker",
    url: "https://static.arcgis.com/images/Symbols/Shapes/BluePin1LargeB.png",
    height: "50px",
    width: "50px",
    yoffset: 13,
    outline: {
        width: 2,
        color: [0, 255, 255],
    },
    size: "50px"
};

export class MapView extends React.Component {


    constructor(props) {
        super(props);
        this.mapRef = React.createRef();
    }

    componentDidMount() {
        this.initialisingMap();
    }

    initialisingMap = ()=>{
        loadModules(modules, { css: true }).then(([
            ArcGISMap, MapView, FeatureLayer, GraphicsLayer, Graphic, esriConfig, BasemapGallery,
            Fullscreen, BasemapToggle, SketchViewModel, webMercatorUtils, domGeom,
            SimpleFillSymbol, SimpleLineSymbol, Color, geometryEngine, Point, esriRequest, Spatialreference
        ]) => {

            /**
             * Creating the map
             */
            const map = new ArcGISMap({
                //basemap: "satellite"
                basemap: "osm"
            });
            this.view = new MapView({
                container: this.mapRef.current, 
                map: map,
                center: initCenter,
                zoom: 5,
            });

            /**
             * Start creating layers and widdgets on the map
             */
            const lotLayer = new FeatureLayer({
                url: MAP_SIX_LAYER3,
                outfields: ["*"],
                labelsVisible: true,
                labelingInfo: [labelClassLot],
                visible: true,
                renderer: lotRenderer,
                minScale: 300000
            });

            this.pointLayer = new GraphicsLayer();
            this.settingPointLocationFromProps(true);
            this.pointInitialised = true;

            const toggle = new BasemapToggle({
                view: this.view,
                nextBasemap: "satellite"
                //nextBasemap: "osm"
            });
            const fullscreen = new Fullscreen({view: this.view});

            /**
             * Adding layers and widdgets on the map
             */
            map.add(lotLayer);
            this.view.ui.add(toggle, "top-right");
            this.view.ui.add(fullscreen, "top-right");
            map.add(this.pointLayer);

            /**
             * Handling events
             */
            this.view.on("drag", (e) => {

                if(this.props.disabled) return;

                const loc = this.view.toMap(e);
                if(e.action==='end'){
                    if(this.draggingPoint) this.props.onPointLocationUpdate(loc.latitude,loc.longitude);
                    this.draggingPoint = false;
                } else if(e.action === 'start'){
                    this.view.hitTest(e).then(resp => {
                        if(resp.results.filter((result) => result.graphic.layer === this.pointLayer).length>0){
                            this.draggingPoint = true;
                        }
                    });
                } 

                if(this.draggingPoint) {
                    e.stopPropagation();
                    this.props.onPointLocationUpdate(loc.latitude,loc.longitude);
                }
            });

            this.view.on("click", (e) => {
                if(this.props.disabled) return;
                this.props.onPointLocationUpdate(e.mapPoint.latitude,e.mapPoint.longitude);
            });
            
            
        });
    }

    componentDidUpdate(prevProps) {
        this.settingPointLocationFromProps();
    }

    settingPointLocationFromProps = (initialisingPoint) =>{

        if(!initialisingPoint && !this.pointInitialised) return;

        var longitude = parseFloat(this.props.point.longitude);
        var latitude = parseFloat(this.props.point.latitude);


        if(Number.isNaN(longitude) || Number.isNaN(latitude)){
            this.pointLayer.removeAll();
        }

        loadModules(modules, { css: true }).then(([ArcGISMap, MapView, FeatureLayer, GraphicsLayer, Graphic]) => {
            this.pointLayer.removeAll();
            var pointGeometry = {
                type: "point",
                longitude,
                latitude
            };
            this.point = new Graphic({geometry: pointGeometry,symbol: pointSymbol});
    
            this.pointLayer.add(this.point);
        });
    }


    componentWillUnmount() {
        if (this.view) {this.view.container = null;}
    }

    render() {
        return <>
          <div className="webmap" ref={this.mapRef}></div>
        </>
    }
}