import React from 'react';
import Authentication from '../../helpers/Authentication'
import ApprovalSearchFormWithConsent from '../common/ApprovalSearchFormWithConsent'
import EsidSelect from '../common/EsidSelect'
import ApprovalConsents from './ApprovalConsents';
import axios from 'axios';
import moment from 'moment';
import uiHelpers from '../../helpers/uiHelpers'
import $ from 'jquery';
import { userRole, addUserRoleUpdateNotifier, notifyUserRoleUpdated } from '../../helpers/userHelpers';

const authentication = new Authentication();


class ApprovalLTSReportComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            approvalSelected: false,
            approvalNumber: '',

            esid: null,
            isEsidSelected: false,
            meterViewOption: 'SUMMARY',
            showConsent: false,


            trackingStatusRequesting: false,
            trackingStatusRequested: false,
            trackingStatusRecords: [],
            displayTrackingStatusRecords: [],

            commentsForm: {
                value: ''
            },
            commentsSubmitting: false,

            pdfDownloading: false
        }
        addUserRoleUpdateNotifier(() => {
            this.forceUpdate();
        });

    }

    ajaxConfig = {
        baseURL: process.env.REACT_APP_WEBAPI_URL,
        headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authentication.getToken() }
    };

    approvalSelected = (approvalNumber) => {
        this.setState(Object.assign(this.state, { approvalNumber: approvalNumber, approvalSelected: true }));
    }

    esidChangeHandler = (esid) => {
        this.setState(Object.assign(this.state, {
            esid: esid,
            isEsidSelected: true,
            trackingStatusRequesting: false,
            trackingStatusRequested: false,
            trackingStatusRecords: [],
            filters: [],



            commentsForm: {
                value: '',
                error: null
            },
            commentsSubmitting: false
        }));
    }

    initDisplayRecordsAndFilters(records) {
        var filters = [{
            filterName: 'No worksite ID',
            checked: true,
            noWorksiteidFilter: true
        }];
        var noWorksiteFilter = filters[0];
        var worksiteID;
        for (var i = 0; i < records.length; i++) {
            if (records[i].meterRegistration) {
                if (!records[i].meterRegistration.meterNumber) {
                    records[i].filterName = noWorksiteFilter.filterName;
                    continue;
                }
                worksiteID = this.state.approvalNumber + '-' + this.state.esid.esid + '-' + records[i].meterRegistration.meterNumber;
                records[i].filterName = this.findFilterIfNotCreateOne(worksiteID, filters).filterName;
            } else if (records[i].meteringEquipmentInformation) {
                if (
                    !records[i].meteringEquipmentInformation.approvedWorkDetails
                    || records[i].meteringEquipmentInformation.approvedWorkDetails.length == 0
                    || !records[i].meteringEquipmentInformation.approvedWorkDetails[0].meterNumber
                ) {
                    records[i].filterName = noWorksiteFilter.filterName;
                    continue;
                }
                worksiteID = this.state.approvalNumber + '-' + this.state.esid.esid + '-' + records[i].meteringEquipmentInformation.approvedWorkDetails[0].meterNumber;
                records[i].filterName = this.findFilterIfNotCreateOne(worksiteID, filters).filterName;
            } else {
                records[i].filterName = noWorksiteFilter.filterName;
            }
        }
        this.setState(Object.assign(this.state, { filters: filters, trackingStatusRequested: true, trackingStatusRecords: records }));
        this.updateDisplayRecordsByFilter();
    }

    viewReportClick = () => {
        let currentContext = this;
        currentContext.setState(Object.assign(currentContext.state, { trackingStatusRequesting: true }));
        uiHelpers.showProgressOnTop('Loading report content...');
        axios.post('/trackingStatusForApproval/getByApprovalNumberAndEsid', JSON.stringify({
            approvalNumber: this.state.approvalNumber
            , esid: this.state.esid.esid
            , statusType: this.props.statusType
        }), this.ajaxConfig).then(function (response) {
            currentContext.initDisplayRecordsAndFilters(response.data.data);
            uiHelpers.hideProgressOnTop();
        }).catch(function (error) {
            console.log(error);
            uiHelpers.showErrorTop('Error happened on the server side when getting tracking records.');
        }).then(function () {
            currentContext.setState(Object.assign(currentContext.state, { trackingStatusRequesting: false }));
        });
    }

    convertAtionText = (trackingStatusRecord) => {
        if (trackingStatusRecord.recordType == "NON_PORTAL_ACTIONS" && trackingStatusRecord.action == "COMMENT") return <span><span class="text-primary">DQP Comment:</span> {trackingStatusRecord.comments}</span>;

        else if (trackingStatusRecord.recordType == "METER_REGISTRATION" && trackingStatusRecord.action == "SUBMIT_AND_CREATE") return <span>Site registration in portal submitted {this.state.meterViewOption != 'SUMMARY' && <span class="label label-default">id={trackingStatusRecord.meterRegistration.id}</span>}</span>;
        else if (trackingStatusRecord.recordType == "METER_REGISTRATION" && trackingStatusRecord.action == "SET_TO_INSTALL") return <span>Site registration in portal set to installed {this.state.meterViewOption != 'SUMMARY' && <span class="label label-default">id={trackingStatusRecord.meterRegistration.id}</span>}</span>;
        else if (trackingStatusRecord.recordType == "METER_REGISTRATION" && trackingStatusRecord.action == "SAVE_DRAFT_AND_CREATE") return <span>Site registration in portal draft created {this.state.meterViewOption != 'SUMMARY' && <span class="label label-default">id={trackingStatusRecord.meterRegistration.id}</span>}</span>;
        else if (trackingStatusRecord.recordType == "METER_REGISTRATION" && trackingStatusRecord.action == "SAVE_DRAFT") return <span>Site registration in portal draft saved {this.state.meterViewOption != 'SUMMARY' && <span class="label label-default">id={trackingStatusRecord.meterRegistration.id}</span>}</span>;
        else if (trackingStatusRecord.recordType == "METER_REGISTRATION" && trackingStatusRecord.action == "SUBMIT") return <span>Site registration in portal submitted {this.state.meterViewOption != 'SUMMARY' && <span class="label label-default">id={trackingStatusRecord.meterRegistration.id}</span>}</span>;
        else if (trackingStatusRecord.recordType == "METER_REGISTRATION" && trackingStatusRecord.action == "DELETE_DRAFT") return <span>Site registration in portal draft deleted {this.state.meterViewOption != 'SUMMARY' && <span class="label label-default">id={trackingStatusRecord.meterRegistration.id}</span>}</span>;
        else if (trackingStatusRecord.recordType == "METER_REGISTRATION" && trackingStatusRecord.action == "REMOVE") return <span>Site registration in portal removed {this.state.meterViewOption != 'SUMMARY' && <span class="label label-default">id={trackingStatusRecord.meterRegistration.id}</span>}</span>;
        else if (
            trackingStatusRecord.recordType == "METER_VALIDATION"
            || trackingStatusRecord.recordType == "METER_DESIGN"
            || trackingStatusRecord.recordType == "METER_ACCURACY"
            || trackingStatusRecord.recordType == "TRANSITIONAL_REPORTING"
            || trackingStatusRecord.recordType == "MAINTENANCE_VALIDATION"
        ) {
            var recordTypeText = trackingStatusRecord.recordType == "METER_VALIDATION" ? "Meter Validation Certificate" :
                trackingStatusRecord.recordType == "METER_DESIGN" ? "Meter Design Certificate" :
                    trackingStatusRecord.recordType == "METER_ACCURACY" ? "Meter Accuracy" :
                        trackingStatusRecord.recordType == "TRANSITIONAL_REPORTING" ? "Transitional Reporting" :
                            trackingStatusRecord.recordType == "MAINTENANCE_VALIDATION" ? "Maintenance Validation Certificate" : "Unknown type of certificate"
                ;
            var actionText = trackingStatusRecord.action == "SUBMIT_AND_CREATE" ? "submitted" :
                trackingStatusRecord.action == "SET_TO_INSTALL" ? "set to installed" :
                    trackingStatusRecord.action == "SAVE_DRAFT_AND_CREATE" ? "draft created" :
                        trackingStatusRecord.action == "SAVE_DRAFT" ? "draft saved" :
                            trackingStatusRecord.action == "SUBMIT" ? "submitted" :
                                trackingStatusRecord.action == "DELETE_DRAFT" ? "draft deleted" :
                                    trackingStatusRecord.action == "REMOVE" ? "removed" : " has been perform unknown action"
                ;

            return <span>{recordTypeText} {this.state.meterViewOption != 'SUMMARY' && <span class="label label-default">id={trackingStatusRecord.meteringEquipmentInformation.id}</span>} {actionText}</span>;

        } else return <span>Unknown action</span>;
    }

    findFilterIfNotCreateOne(filterName, filters) {
        for (var i = 0; i < filters.length; i++) {
            if (filters[i].filterName == filterName) {
                return filters[i];
            }
        }
        var result = { filterName: filterName, checked: true };
        filters.push(result);
        return result;
    }

    changeMeterViewOption = (viewOption) => {
        this.setState(Object.assign(this.state, { meterViewOption: viewOption }));
        this.updateDisplayRecordsByFilter();
    }

    submitCommentsForm = (event) => {
        if ($.trim(this.state.commentsForm.value) == '') {
            this.setState(Object.assign(this.state.commentsForm, { error: 'Please provide a comment' }));
            return;
        } else {
            this.setState(Object.assign(this.state.commentsForm, { error: null }));
        }

        let currentContext = this;
        uiHelpers.showProgressOnTop('Submitting comments...')
        currentContext.setState(Object.assign(currentContext.state, { commentsSubmitting: true }));
        axios.post('/trackingStatusForApproval/submitComment', JSON.stringify(
            {
                approvalNumber: this.state.approvalNumber
                , esid: this.state.esid.esid
                , comments: this.state.commentsForm.value
                , statusType: this.props.statusType
            }), this.ajaxConfig).then(function (response) {
                //var records = response.data.data;
                currentContext.initDisplayRecordsAndFilters(response.data.data);
                //currentContext.setState(Object.assign(currentContext.state,{trackingStatusRecords : records} ));
                currentContext.setState(Object.assign(currentContext.state.commentsForm, { value: '' }));
                uiHelpers.hideProgressOnTop();
            }).catch(function (error) {
                uiHelpers.showErrorTop('Error happened on the server side when submitting comments.');
            }).then(function () {
                currentContext.setState(Object.assign(currentContext.state, { commentsSubmitting: false }));
            });

    }

    commentsChange = (event) => {
        this.setState(Object.assign(this.state.commentsForm, { value: event.target.value }));
    }

    filterOptionChange = (filter) => {
        const newFilters = this.state.filters.map((item) => {
            if (item.filterName === filter.filterName) {
                const updatedFilter = {
                    ...item,
                    checked: !item.checked,
                };

                return updatedFilter;
            }
            return item;
        });
        this.setState(Object.assign(this.state, { filters: newFilters }));
        this.updateDisplayRecordsByFilter();
    }

    updateDisplayRecordsByFilter() {
        var dispalyRecords = [];
        var filter;
        var maxId = 0;
        for (var i = 0; i < this.state.trackingStatusRecords.length; i++) {

            if (this.state.meterViewOption == 'SUMMARY' &&
                (
                    (
                        this.state.trackingStatusRecords[i].meterRegistration
                        &&
                        (
                            this.state.trackingStatusRecords[i].meterRegistration.isDeleted
                            ||
                            (
                                this.state.trackingStatusRecords[i].meterRegistration.formStatus != 0
                                &&
                                (
                                    this.state.trackingStatusRecords[i].action == 'SAVE_DRAFT'
                                    ||
                                    this.state.trackingStatusRecords[i].action == 'SAVE_DRAFT_AND_CREATE'
                                )
                            )
                        )
                    )
                    ||
                    (
                        this.state.trackingStatusRecords[i].meteringEquipmentInformation
                        &&
                        (
                            this.state.trackingStatusRecords[i].meteringEquipmentInformation.isDeleted
                            ||
                            (
                                this.state.trackingStatusRecords[i].meteringEquipmentInformation.formStatus != 1
                                &&
                                (
                                    this.state.trackingStatusRecords[i].action == 'SAVE_DRAFT'
                                    ||
                                    this.state.trackingStatusRecords[i].action == 'SAVE_DRAFT_AND_CREATE'
                                )
                            )
                        )
                    )
                )
            ) {
                continue;
            }


            if (this.state.meterViewOption == 'SUMMARY' && this.state.trackingStatusRecords[i].meterRegistration && this.state.trackingStatusRecords[i].meterRegistration.formStatus == 0) {
                maxId = 0;
                for (var j = 0; j < this.state.trackingStatusRecords.length; j++) {
                    if (
                        (this.state.trackingStatusRecords[j].meterRegistration && this.state.trackingStatusRecords[i].meterRegistration.id != this.state.trackingStatusRecords[j].meterRegistration.id)
                        ||
                        this.state.trackingStatusRecords[j].meteringEquipmentInformation
                    ) {
                        continue;
                    }
                    if (maxId < this.state.trackingStatusRecords[j].id) {
                        maxId = this.state.trackingStatusRecords[j].id;
                    }
                }
                if (this.state.trackingStatusRecords[i].id != maxId) continue;


                if (this.state.trackingStatusRecords[i].id == maxId) {
                    console.log(this.state.trackingStatusRecords[i]);
                }
            }

            if (this.state.meterViewOption == 'SUMMARY' && this.state.trackingStatusRecords[i].meteringEquipmentInformation && this.state.trackingStatusRecords[i].meteringEquipmentInformation.formStatus == 1) {
                maxId = 0;
                for (var j = 0; j < this.state.trackingStatusRecords.length; j++) {
                    if (
                        (this.state.trackingStatusRecords[j].meteringEquipmentInformation && this.state.trackingStatusRecords[i].meteringEquipmentInformation.id != this.state.trackingStatusRecords[j].meteringEquipmentInformation.id)
                        ||
                        this.state.trackingStatusRecords[j].meterRegistration
                    ) {
                        continue;
                    }
                    if (maxId < this.state.trackingStatusRecords[j].id) {
                        maxId = this.state.trackingStatusRecords[j].id;
                    }
                }
                if (this.state.trackingStatusRecords[i].id != maxId) continue;
            }


            var filter = this.findFilterIfNotCreateOne(this.state.trackingStatusRecords[i].filterName, this.state.filters);
            if (filter.checked) {
                this.state.trackingStatusRecords[i].selected = true;
                dispalyRecords.push(this.state.trackingStatusRecords[i]);
            }
        }
        this.setState(Object.assign(this.state, { displayTrackingStatusRecords: dispalyRecords }));
    }

    selectedRecordChange(id) {
        const newRecords = this.state.displayTrackingStatusRecords.map((item) => {
            if (item.id === id) {
                const updatedRecord = {
                    ...item,
                    selected: !item.selected,
                };

                return updatedRecord;
            }
            return item;
        });
        this.setState(Object.assign(this.state, { displayTrackingStatusRecords: newRecords }));
    }

    downloadPDF = () => {
        var selectedIDs = [];
        for (var i = 0; i < this.state.displayTrackingStatusRecords.length; i++) {
            if (this.state.displayTrackingStatusRecords[i].selected) {
                selectedIDs.push(this.state.displayTrackingStatusRecords[i].id);
            }
        }

        if (selectedIDs.length == 0) {
            alert('Please select the list of actions to download into the PDF')
            return;
        }
        let currentContext = this;


        currentContext.setState(Object.assign(currentContext.state, { pdfDownloading: true }));
        uiHelpers.showProgressOnTop('Generating pdf...');
        axios.post('/trackingStatusForApproval/generatePDF', JSON.stringify({
            approvalNumber: this.state.approvalNumber
            , esid: this.state.esid.esid
            , selectedIDs: selectedIDs
            , statusType: this.props.statusType
            , showConsent: this.state.showConsent
        })
            , { ...this.ajaxConfig, responseType: 'arraybuffer' }).then(function (response) {

                uiHelpers.hideProgressOnTop();
                var mime = 'application/pdf';
                var filename = currentContext.state.approvalNumber + '-' + currentContext.state.esid.esid + '.pdf';
                console.log(response);
                var blob = new Blob([response.data], { type: mime || 'application/pdf' });

                if (typeof window.navigator.msSaveBlob !== 'undefined') {
                    // IE workaround for "HTML7007: One or more blob URLs were
                    // revoked by closing the blob for which they were created.
                    // These URLs will no longer resolve as the data backing
                    // the URL has been freed."
                    window.navigator.msSaveBlob(blob, filename);
                }
                else {
                    var blobURL = (window.URL && window.URL.createObjectURL) ? window.URL.createObjectURL(blob) : window.webkitURL.createObjectURL(blob);
                    var tempLink = document.createElement('a');
                    tempLink.style.display = 'none';
                    tempLink.href = blobURL;
                    tempLink.setAttribute('download', filename);

                    // Safari thinks _blank anchor are pop ups. We only want to set _blank
                    // target if the browser does not support the HTML5 download attribute.
                    // This allows you to download files in desktop safari if pop up blocking
                    // is enabled.
                    if (typeof tempLink.download === 'undefined') {
                        tempLink.setAttribute('target', '_blank');
                    }

                    document.body.appendChild(tempLink);
                    tempLink.click();

                    // Fixes "webkit blob resource error 1"
                    setTimeout(function () {
                        document.body.removeChild(tempLink);
                        window.URL.revokeObjectURL(blobURL);
                    }, 200)
                }
            }).catch(function (error) {
                console.log(error);
                uiHelpers.showErrorTop('Error happened when dowloading file.');
            }).then(function () {
                currentContext.setState(Object.assign(currentContext.state, { pdfDownloading: false }));
            });
    }


    render() {
        return <>

            {!this.state.approvalSelected && <ApprovalSearchFormWithConsent onCustomerConsentValid={this.approvalSelected} />}


            {this.state.approvalSelected && <div>

                <div class="row">
                    <div class="col-md-6">
                        <div className="mb-3">
                            <label for="workApprovalNumber" class="form-label">Work approval number</label>
                            <input type="text" class="form-control" id="workApprovalNumber" readOnly={true} value={this.state.approvalNumber} />
                        </div>
                        <div className="mb-3">
                            <label class="form-label">Unique extraction site ID (ESID)</label>
                            <EsidSelect approvalNumber={this.state.approvalNumber} onEsidChange={this.esidChangeHandler} disabled={this.state.trackingStatusRequesting} defaultEsid={this.state.esid} />
                        </div>

                        {this.state.isEsidSelected && <p>
                            <button type="button" class="btn btn-primary" onClick={this.viewReportClick} disabled={this.state.trackingStatusRequesting}>
                                View report
                            </button>
                        </p>}
                    </div>
                </div>



                {this.state.trackingStatusRequested && <div>

                    <div class="card mb-5">
                        <div class="card-header">
                            <strong>Add comment to track any non-portal completed/in progress actions</strong>
                        </div>
                        <div class="card-body">
                            <p className={`form-group ${(this.state.commentsForm.error && this.state.commentsForm.error != '') ? "is-invalid" : ""}`}>
                                <textarea class={`form-control ${(this.state.commentsForm.error && this.state.commentsForm.error != '') ? "has-error" : ""}`} rows="3" onChange={this.commentsChange} value={this.state.commentsForm.value} />
                                <div id="validationServerUsernameFeedback" class="invalid-feedback">
                                    {this.state.commentsForm.error}
                                </div>
                            </p>
                            <div>
                                <button type="button" class="btn btn-primary" disabled={this.state.commentsSubmitting} onClick={this.submitCommentsForm}>Add comment</button>
                            </div>
                        </div>
                    </div>

                    <h5>Meter history</h5>


                    <div class="card">
                        <div class="card-header">
                            <strong>Filter on worksite : </strong>
                            <div class="btn-group-vertical" role="group">
                                {this.state.filters.map((item) => {
                                    if (item.noWorksiteidFilter) return null;
                                    return <button class="btn btn-outline-secondary btn-sm" style={{ textAlign: "left" }} type="button" key={item.filterName} onClick={(e) => { this.filterOptionChange(item) }}>
                                        <input type="checkbox" checked={item.checked} onChange={(e) => { }} />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{item.filterName}
                                    </button>
                                })}
                            </div>
                        </div>

                        <div class="card-header">
                            <strong>View options : </strong>
                            <div class="btn-group">
                                <button type="button" class="btn btn-secondary dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    {this.state.meterViewOption == 'SUMMARY' && <span>Summary view</span>}
                                    {this.state.meterViewOption == 'DETAILED' && <span>Detailed view</span>}
                                    &nbsp;&nbsp;
                                    <span class="caret"></span>
                                </button>
                                <ul class="dropdown-menu">
                                    <li><a class="dropdown-item" href="#" onClick={(e) => { e.preventDefault(); this.changeMeterViewOption('SUMMARY') }}>Summary view</a></li>
                                    <li><a class="dropdown-item" href="#" onClick={(e) => { e.preventDefault(); this.changeMeterViewOption('DETAILED') }}>Detailed view</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="card-header">
                            <div class="row row-cols-lg-auto g-3 align-items-center">
                                <div class="col-12">
                                    <strong>Show consent records for {this.state.approvalNumber} : </strong>
                                </div>
                                <div class="col-12">
                                    <div class="input-group ">
                                        <div class="input-group-text">
                                            <input class="form-check-input mt-0" type="checkbox" defaultChecked={this.state.showConsent} onChange={(e) => { this.setState(Object.assign(this.state, { showConsent: e.target.checked })) }} />
                                        </div>
                                        <input type="text" class="form-control" value={this.state.showConsent ? 'Yes' : 'No'} readOnly={true} style={{ "width": "80px" }} />
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="card-body">
                            {this.state.displayTrackingStatusRecords && this.state.displayTrackingStatusRecords.length > 0 &&
                                <p>
                                    <button type="button" disabled={this.state.pdfDownloading} class="btn btn-primary" onClick={this.downloadPDF}>Download PDF</button>
                                </p>
                            }
                            {this.state.showConsent && <div>
                                <h4>Consents</h4>
                            </div>}
                            {this.state.showConsent && <ApprovalConsents approvalNumber={this.state.approvalNumber} hidePDFButton={true} />}
                            {this.state.showConsent && <hr />}
                            {this.state.showConsent && <div>
                                <h4>Tracking status</h4>
                            </div>}
                            {!this.state.displayTrackingStatusRecords || this.state.displayTrackingStatusRecords.length == 0 && <div class="alert alert-info" role="alert">No history found</div>}

                            {this.state.displayTrackingStatusRecords && this.state.displayTrackingStatusRecords.length > 0 &&
                                <div>

                                    <div class="alert alert-primary" role="alert">
                                        Select using checkboxes which action/s to include in the PDF
                                    </div>
                                    <table class="table table-sm fs-90">
                                        <tbody>
                                            <tr class="table-primary">
                                                <th style={{ width: "20px" }}></th>
                                                <th>Action</th>
                                                <th style={{ width: "15%" }}>Worksite ID</th>
                                                <th style={{ width: "15%" }}>DQP Name</th>
                                                <th style={{ width: "15%" }}>Action Date</th>
                                            </tr>
                                            {this.state.displayTrackingStatusRecords.map((item) => {
                                                return <tr key={item.id}>
                                                    <td>
                                                        <input type="checkbox" checked={item.selected} onChange={() => { this.selectedRecordChange(item.id) }} />
                                                    </td>
                                                    <td>
                                                        {this.convertAtionText(item)}
                                                    </td>
                                                    <td>
                                                        {item.filterName}
                                                    </td>
                                                    <td>
                                                        {item.actionUser.firstName} {item.actionUser.lastName}
                                                    </td>
                                                    <td>
                                                        {
                                                            moment((new Date(item.actionDate))).format('DD/MM/YYYY h:mm:ss a')
                                                        }
                                                    </td>
                                                </tr>

                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            }
                        </div>
                    </div>


                </div>}


            </div>}

        </>
    }
}

export default ApprovalLTSReportComponent;
