import React from 'react';
import  { Redirect } from 'react-router-dom'

import axios from 'axios';
import * as serviceCaller from '../serviceCaller'

import MessageTranslate from './MessageTranslate'

class IWasRedirect  extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    async componentDidMount(){
        try {
            let params = new URLSearchParams(window.location.search);
            const sessionId = parseInt(params.get('sessionid'));
            const result = await serviceCaller.createSession({iWasSessionId: sessionId});
            if(result.success) {
                this.setState({redirect : true});
            } else {
                this.setState({showMsg: true,msgKey: result.errorCode})
            }
        } catch (error){
            console.error(error);
            this.setState({showMsg: true,msgKey: 'SYSTEM_ERROR'})
        }
    }

    render() {
        return <>
            <div className='container p-5'>
                <div className='row'>
                    <div className='col'></div>
                    <div className='col-6'>
                        <div className='card card-body'>
                            <div className='text-center'>
                                {function(state){
                                    if(state.showMsg) return <div className='alert alert-danger m-0'><MessageTranslate msgKey={state.msgKey} /></div>;
                                    else return <>
                                        <span className="spinner-border spinner-border-sm me-2 text-primary" role="status" aria-hidden="true"></span>
                                        Please wait...
                                        {state.redirect && <Redirect to="/measurement-period/#/dashboard" />}
                                    </>
                                }(this.state)}
                            </div>
                        </div>
                    </div>
                    <div className='col'></div>
                </div>
            </div>
        </>
    }
}

export default IWasRedirect;