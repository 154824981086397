import React from 'react';
import axios from 'axios';
import Authentication from '../helpers/Authentication'
import RatingTableSeacherLauncher from '../components/ratingTables/RatingTableSeacherLauncher';
import DQPInfoSection from '../components/certificates/common/DQPInfoSection';
import FPHAuthorityHolderDetailsFormSection from '../components/certificates/common/FPHAuthorityHolderDetailsFormSection';

import CertificatePartC from '../components/certificates/updateRatingTable/CertificatePartC';
import CertificatePartD from '../components/certificates/updateRatingTable/CertificatePartD';
import CertificatePartE from '../components/certificates/updateRatingTable/CertificatePartE';
import CertificateConfirmation from '../components/certificates/updateRatingTable/CertificateConfirmation'
import Audit from '../components/certificates/updateRatingTable/Audit';

import { uploadOptionsForUpdateRatingTable as uploadOptions } from '../constants/MeterFormConstants'

import { formValueSelector, reduxForm, change } from 'redux-form'
import { connect } from 'react-redux'
import { load, save, findById } from '../components/certificates/updateRatingTable/Reducers';
import { deleteDraftFormGeneric} from '../components/certificates/common/MeterCertificateCommon'
import {PrivacyStatementFPH, GlossaryFPH} from '../components/common/StaticContents';

import {StepProgressBar} from '../components/tools/BootstrapControls'
import uiHelpers from '../helpers/uiHelpers';

import DisplayForRoles from '../components/tools/DisplayForRoles'
import CertificateFiles from '../components/certificates/common/CertificateFiles'
import CertificateAdmin from '../components/certificates/common/CertificateAdmin'

const ID = 'id';

const authentication = new Authentication();

export default class UpdateRatingTableCertificate extends React.Component {

  approvedWorkDetails = {};
  authorityHolderDetails = {}; 
  floodplainWorkDetails = {};
  #currentUserEmail;
  #currentUserId;

  ajaxConfig = {
    baseURL: process.env.REACT_APP_WEBAPI_URL,
    headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authentication.getToken() }
  };

  constructor(props) {
    super(props)
    this.nextPage = this.nextPage.bind(this);
    this.previousPage = this.previousPage.bind(this);
    this.#currentUserEmail = authentication.currentUser().emails;
    this.state = {
      page: -1,        
      data: {},
      confirmingSubmission : false,
      tab : 'MAIN'
    }
  }

  async componentDidMount() {  
    
    const currentContxt = this;
    axios.post('users/getprofile', JSON.stringify({ email: this.#currentUserEmail }), this.ajaxConfig).then(response => {

      currentContxt.#currentUserId = response.data.data.user.user.id;
      currentContxt.pageRefresh();
      
    }).catch(function (error) {
      console.log(error);
      uiHelpers.showErrorTop('Error occurred when reading your user info');
    });

    
    
    //this.pageRefresh();
    
  }

  loadFromRatingTableSearch = () => {

    let pageURL = window.location.href;
    let tokens = pageURL.split("/");
    let index = tokens.indexOf("RatingTable") +1;
    console.log("page_url is: " + pageURL);
    console.log("index is url is: " + index);
    let ratingTable = undefined;    

    if(index>1 && (!this.props.floodplainWorkDetails || this.props.floodplainWorkDetails.id==0) ) {
      ratingTable = JSON.parse(atob(tokens[index]));   
      console.log("ratingTable is: " + ratingTable);   
      this.setState({ page: 0 });
    }
    
  return ratingTable;
  }


  pageRefresh = async(id) =>{
    var certificateID = id;

    if(!id && this.props.updateRatingTableData)
      certificateID = this.props.updateRatingTableData.id;

    let search = window.location.search;
    let params = new URLSearchParams(search);

    if(!certificateID || certificateID==0){
      certificateID = params.get(ID);
      if(certificateID)
        certificateID = parseInt(certificateID)
    }

    if (certificateID && certificateID!=0) {
      try {
        if(this.state.page==-1) {
          this.setState({ page: 0});
        }

        uiHelpers.showProgressOnTop("Getting/Refreshing certificate data...");
        this.props.dispatch(change(this.props.form, 'loading', true));
        await this.props.dispatch(findById(certificateID));        
        uiHelpers.hideProgressOnTop();
        this.props.dispatch(change(this.props.form, 'loading', false));
      } catch (error) {
        uiHelpers.showErrorTop("Error when getting data...", true, false);
      }

    } else {
      try {
        let ratingTable = this.loadFromRatingTableSearch();
        uiHelpers.showProgressOnTop("Loading data...");
        this.props.dispatch(change(this.props.form, 'loading', true));
        await this.props.dispatch(load());
        uiHelpers.hideProgressOnTop();
        this.props.dispatch(change(this.props.form, 'loading', false));  
        if(ratingTable) {
           this.props.floodplainWorkDetails.ratingTable = ratingTable;
        }
      } catch (error) {
        uiHelpers.showErrorTop("Error when getting data...", true, false);
      }

    }
  }

  nextPage = (values) => {
    this.saveDraft(values);
  }

  completeConfirmation = () => {
    this.setState({ confirmingSubmission: false, page: this.state.page + 1 });
  }

  cancelConfrimSubmission = () => {
    this.setState({ confirmingSubmission: false });
  }

  cancelConfrimSubmissionAndGoToPage = (page) => {
    this.setState({ confirmingSubmission: false, page: page });
  }

  saveDraft = (values) => {  
    
    if (parseInt(values.clickSaveDraft) === 1 && parseInt(values.clickNext) === 0) {     
      try {
        uiHelpers.showProgressOnTop("Saving your record...");
        this.props.dispatch(change(this.props.form, 'loading', true));        
        this.props.dispatch(save(values, this.pageRefresh));
      } catch (error) {
        uiHelpers.showErrorTop("Error when saving your record..", true, false);
      }

    } else if (parseInt(values.clickSaveDraft) === 0 && parseInt(values.clickNext) === 1 && values.updateRatingTableData.formStatus == 2 ) {
     
      try {
        uiHelpers.showProgressOnTop("Saving your record...");
        this.props.dispatch(change(this.props.form, 'loading', true));
        this.props.dispatch(save(values, this.pageRefresh));
      } catch (error) {
        uiHelpers.showErrorTop("Error when saving your record..", true, false);
      }
    } else {
      debugger;
      if(this.state.page == 2 && !this.state.confirmingSubmission ) {//if page 4 and write-mode and not confirmed yet
        this.setState({ confirmingSubmission: true }); //show confirm
      } else if(this.state.page == 2 && this.state.confirmingSubmission ) { //if previously confirmed , goto next page
        this.setState({ confirmingSubmission: false, page: this.state.page + 1 });
      }else {
        this.setState({ page: this.state.page + 1 });
      }
    }
      console.log(JSON.stringify("end_of_saveDraft"));
    
  }

  ratingTableSelectedHandler = (selectedRatingTable) => {    

    this.props.floodplainWorkDetails.ratingTable = selectedRatingTable;
    debugger;
    this.setState({ page: this.state.page + 1 });
  }


  previousPage = (formStatus) => {
    debugger;
    if(this.state.page == 3) {      
      this.setState({ confirmingSubmission: true, page: this.state.page - 1 });
    } else  {
      this.setState({ page: this.state.page - 1 });  
    }         
  }

  HideShow = () => {
    this.setState({ showInstructions: !this.state.showInstructions });
  }

  deleteDraft = ()=>{
    
    if (this.props.updateRatingTableData.id != 0) {
      let currentContext = this;
      let url = '/ratingTables/deleteDraft?cid='+this.props.updateRatingTableData.id;
      deleteDraftFormGeneric(
        parseInt(this.props.updateRatingTableData.id)
        , function(){
          currentContext.props.dispatch(change(currentContext.props.form, 'loading', true));
        }
        , function(){
          window.location = 'Home';
        }
        , function() {

        }, url
      );
    } else {
      uiHelpers.showErrorTop('You are accessing this function in a invalid way');
    }
  }

  render() {
    const { page, showInstructions, confirmingSubmission} = this.state

    const { form , loading} = this.props
    

    return (<div className="tab-content clearfix">
      <h3 className='mb-3'>Update Rating Table</h3>
      <p>
        <a className="btn btn-primary" onClick={this.HideShow}>Privacy and Glossary</a>
      </p>

      {showInstructions && (<div class="card card-body fs-90 mb-5">
        <h4>Privacy Statement</h4>
        {PrivacyStatementFPH}
        <h4>Glossary of terms</h4>
        {GlossaryFPH}
      </div>)}
     
      {!confirmingSubmission && page === -1 && <RatingTableSeacherLauncher onRatingTableSelected = {this.ratingTableSelectedHandler} />}

      {page >= 0 && <>
        <div class="card mb-5">
          <div class="card-body row">
              <div className='col-md-3'>
                <label class="form-label">Record ID</label>
                <input type="text" disabled={true} class="form-control" value={this.props.updateRatingTableData.id == 0 ? "Not saved" : this.props.updateRatingTableData.id} />
              </div>
              <div className='col-md-3'>
                <label class="form-label">Form Status</label>
                <input type="text" disabled={true} class="form-control" value={this.props.updateRatingTableData.formStatus==1?'Draft' : 'Submitted'} />
              </div>
              <div className='col-md-3'>
                <label class="form-label">DQP</label>
                <input type="text" disabled={true} class="form-control" value={this.props.updateRatingTableData.createdBy ? (this.props.updateRatingTableData.createdBy.firstName + " " + this.props.updateRatingTableData.createdBy.lastName) : ""} />
              </div>
          </div>
        </div>
      
        <ul class="nav nav-tabs mb-3">
            <li class="nav-item ">
              <a class={`nav-link ${this.state.tab == 'MAIN'?'active':''}`} aria-current="page" href="#"
                  onClick={(e)=>{e.preventDefault(); this.setState({ tab : 'MAIN'}); }}
              >Record details</a>
            </li>
            <li class="nav-item">
              <a class={`nav-link ${this.state.tab == 'AUDIT'?'active':''}`} href="#"
                  onClick={(e)=>{e.preventDefault(); this.setState({ tab : 'AUDIT'}); }}
              >Audit/Logs</a>
            </li>
            {this.props.updateRatingTableData.formStatus == 2 && <DisplayForRoles roles={{superAdmin : true, isCFOAdmin:true}}>
              <li class="nav-item">
                  <a class={`nav-link ${this.state.tab == 'FILES'?'active':''}`} href="#"
                    onClick={(e)=>{e.preventDefault(); this.setState({ tab : 'FILES'}); }}
                  >Files</a>
              </li>
            </DisplayForRoles>}
            {this.props.updateRatingTableData.formStatus == 2 && <DisplayForRoles roles={{superAdmin : true}}>
              <li class="nav-item">
                  <a class={`nav-link ${this.state.tab == 'ADMIN'?'active':''}`} href="#"
                    onClick={(e)=>{e.preventDefault(); this.setState({ tab : 'ADMIN'}); }}
                  >Admin</a>
              </li>
            </DisplayForRoles>}
        </ul>
        

        {this.state.tab == 'AUDIT' && <Audit id={this.props.updateRatingTableData.id} /> }

        {this.state.tab == 'ADMIN' && this.props.updateRatingTableData.formStatus == 2 && <DisplayForRoles roles={{superAdmin : true, isCFOAdmin:true}}>
          <CertificateAdmin id={this.props.updateRatingTableData.id} entityType="RatingTableCertificate" />
        </DisplayForRoles> }

        {this.state.tab == 'FILES' && <DisplayForRoles roles={{superAdmin : true, isCFOAdmin:true}}>
            <CertificateFiles 
              form={form} 
              certificateNameForSelector="updateRatingTableData"
              fileTypeResolver={(document)=>{
                
                var option = uploadOptions.filter(option=>option.id==document.documentType)[0];
                return <>{option?option.name:""}</>
                
              }}
            />
        </DisplayForRoles> }

        {this.state.tab == 'MAIN' && <>

          {this.props.updateRatingTableData.formStatus == 1 && this.props.updateRatingTableData.createdBy && this.props.updateRatingTableData.createdBy.id != this.#currentUserId && <div className='alert alert-primary'>
            This form is owned by <strong>{this.props.updateRatingTableData.createdBy.firstName} {this.props.updateRatingTableData.createdBy.lastName}</strong> and cannot be edited
          </div>}

          <div className='my-5'>      
            <StepProgressBar 
              totalSteps={6} 
              currentStep={confirmingSubmission? 4 : page>2? page+2: page+1} 
              disableFutureSteps={this.props.updateRatingTableData.formStatus == 1} 
              stepClick={(page) =>{this.setState({page : page>=4?page-2:page-1, confirmingSubmission : page==4 })}}/>
          </div>
          {!confirmingSubmission && page === 0 && <DQPInfoSection form={form} onSubmit={this.nextPage} previousPage={this.previousPage} disableEditing={this.props.updateRatingTableData.createdBy && this.props.updateRatingTableData.createdBy.id != this.#currentUserId} />}
          {!confirmingSubmission && page === 1 && <FPHAuthorityHolderDetailsFormSection form={form} onSubmit={this.nextPage} previousPage={this.previousPage} disableEditing={this.props.updateRatingTableData.createdBy && this.props.updateRatingTableData.createdBy.id != this.#currentUserId} />}
          {!confirmingSubmission && page === 2 && <CertificatePartC form={form} onSubmit={this.nextPage} previousPage={this.previousPage} disableEditing={this.props.updateRatingTableData.createdBy && this.props.updateRatingTableData.createdBy.id != this.#currentUserId} />}

          
          {confirmingSubmission &&  
            <CertificateConfirmation 
              form={form}
              onSubmit={this.nextPage}
              completeConfirmation={this.completeConfirmation}
              cancelConfrimSubmissionAndGoToPage={this.cancelConfrimSubmissionAndGoToPage}
              cancelConfrimSubmission={this.cancelConfrimSubmission}   
              disableEditing={this.props.updateRatingTableData.createdBy && this.props.updateRatingTableData.createdBy.id != this.#currentUserId}
            />}       

          {!confirmingSubmission && page === 3 && <CertificatePartD form={form} onSubmit={this.nextPage} previousPage={this.previousPage} disableEditing={this.props.updateRatingTableData.createdBy && this.props.updateRatingTableData.createdBy.id != this.#currentUserId} />}
          {!confirmingSubmission && page === 4 && <CertificatePartE form={form} onSubmit={this.nextPage} previousPage={this.previousPage} disableEditing={this.props.updateRatingTableData.createdBy && this.props.updateRatingTableData.createdBy.id != this.#currentUserId} />}
        
        </>}
      </>}
    

          
      {/* start the delete draft dialog */}
      <div class="modal fade" id="deleteDraftModal" tabindex="-1" role="dialog" aria-labelledby="deleteDraftModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
            <div class="modal-dialog modal-dialog-centered" role="document">
               <div class="modal-content">
                <div class="modal-header">
                    <button type="button" id="deleteDraftModalCloseBtn"  disabled={loading} class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                  <div class="modal-body">
                    <strong>Are you sure you want to delete this draft version of the form?</strong>
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" disabled={loading} data-bs-dismiss="modal">Cancel</button>
                
                    <button type="button" disabled={loading} class="btn btn-primary"
                      onClick={() => { this.deleteDraft(); }}
                    >Confirm deletion</button>
                  </div>
               </div>
            </div>
         </div>
        {/* end the delete draft dialog */}   

    </div>);
  }
}


UpdateRatingTableCertificate = reduxForm({
  enableReinitialize: true,

})(UpdateRatingTableCertificate)

UpdateRatingTableCertificate = connect(
  (state, props) => ({

    initialValues: state.usbReducer,
    loaded: state.usbReducer.loaded,
    form: props.form,
    authorityHolderDetails: formValueSelector(props.form)(state, 'authorityHolderDetails'),
    floodplainWorkDetails: formValueSelector(props.form)(state, 'floodplainWorkDetails'),
    updateRatingTableData: formValueSelector(props.form)(state, 'updateRatingTableData'),
    loading: formValueSelector(props.form)(state, 'loading')
    // pull initial values from account reducer
  }),
  {
    load: (payload) => load(payload),
    save: (payload) => save(payload),
    findById: (id) => findById(id),   
  }
)(UpdateRatingTableCertificate)


