import React from 'react';
import * as apiCaller from '../../../../helpers/apiCaller'
import { isStringNullOrEmpty } from '../../../../helpers/validators';



class ReleaseNotes extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            releaseNotes :[],
            loading:false
        }
    }

    async componentDidMount() {
        this.refresh();
    }   

    bindResult = (notes) => {
        this.setState({
            releaseNotes: notes.map(n=>{
                return {
                    text:n.text,
                    textErr:false,
                    link:n.link,
                    linkErr:false
                }
            }),
            loading:false
        });
    }

    refresh = async () => {
        this.setState({loading:true});
        await apiCaller.get('/system/release-notes',false)
        .then((data)=>{
            this.bindResult(data.notes);
        });
    }

    onAddNoteClick = () => {
        this.state.releaseNotes.push({text:"", textErr:false,link:"",linkErr:false});
        this.forceUpdate();
    }

    onDeleteNoteClick = (index) => {
        this.state.releaseNotes.splice(index, 1);
        this.forceUpdate();
    }

    onSaveClick = async () => {
        var hasErr = false;
        for(var i = 0; i < this.state.releaseNotes.length; i++){
            if(isStringNullOrEmpty(this.state.releaseNotes[i].text)){
                this.state.releaseNotes[i].textErr = true;
                hasErr = true;
            } else {
                this.state.releaseNotes[i].textErr = false;
            }
            if(isStringNullOrEmpty(this.state.releaseNotes[i].link)){
                this.state.releaseNotes[i].linkErr = true;
                hasErr = true;
            } else {
                this.state.releaseNotes[i].linkErr = false;
            }
        }
        this.forceUpdate();
        if(hasErr) {
            return;
        }

        this.setState({loading:true});
        await apiCaller.post('/admin/save-release-notes',{notes:this.state.releaseNotes.map(n=>{return {text:n.text,link:n.link}})})
        .then((data)=>{
            this.bindResult(data.notes);
        });
    }

    render() {
        return <>
            {this.state.releaseNotes.map((note,i)=><>
                <div class="input-group mb-3">
                    <span class="input-group-text">Text:</span>
                    <input type="text" className={`form-control ${note.textErr?"is-invalid":""}`} value={note.text} onChange={(e)=>{
                        note.text = e.target.value;this.forceUpdate();
                    }} disabled={this.state.loading} />
                    <span class="input-group-text">Link:</span>
                    <input type="text" className={`form-control ${note.linkErr?"is-invalid":""}`} value={note.link} onChange={(e)=>{
                        note.link = e.target.value;this.forceUpdate();
                    }} disabled={this.state.loading} />
                    <button class="btn btn-outline-secondary" type="button" onClick={()=>this.onDeleteNoteClick(i)} disabled={this.state.loading}>Delete</button>
                </div>
            </>)}

            <div className='text-center'>
                <button className='btn btn-primary me-3' onClick={this.onAddNoteClick} disabled={this.state.loading}>+</button>
                <button className='btn btn-primary me-3' onClick={this.onSaveClick} disabled={this.state.loading}>Save</button>
                <button className='btn btn-secondary' onClick={this.refresh} disabled={this.state.loading}>Reset</button>
            </div>
        </>
    }

}

export default ReleaseNotes;


