import React from 'react';

class PageNotFound extends React.Component {

    constructor(props) {
        super(props)
    }

    render() {
        return <>
            <div class="alert alert-danger" role="alert">
                This page cannot be found, please check your URL
            </div>
        </>;

    }
}

export default PageNotFound;
