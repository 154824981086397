import React from 'react';
import moment from 'moment';
import Select from 'react-select';
import * as apiPathes from '../apiPathes';
import * as serviceCaller from '../serviceCaller'
import DatePicker from 'react-datepicker';
import {ArrowLeft} from '../../components/tools/Icons'
import uiHelpers from '../../helpers/uiHelpers'

import {UneditableTextInput} from '../../components/tools/BootstrapControls'

import StorageReadingForm from './forms/StorageReadingForm'



class Storage  extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            
        };
    }
    
    componentDidMount() {
          
    }

    render(){
        const {storage, dim, disableFunctions} = this.props;

        const storageOptions = [];
        this.props.nominatedMeasurementPeriod.nominatedMeasurementPeriodStorages.forEach(s=>{
            storageOptions.push({label: s.ratingTable.ratingTableName, value:s})
        })

        return <>
            
            <div className={`card mb-3 ${dim?'opacity-25':''}`}>
                <div className=' card-body py-2'>
                    <button className='btn btn-warning btn-sm' disabled={disableFunctions} onClick={(e)=>this.props.setStorage(null)}><ArrowLeft/> Back to period summary</button>           
                </div>
            </div>
            <h5>Storage details for:</h5>
            
            <div className='row mb-4'>
                <div className='col-md-4'>
                    <Select
                        onChange={(s)=>this.props.setStorage(s.value)}
                        options={storageOptions}
                        value={storageOptions ? storageOptions.filter(option => option.value === storage) : null}
                        isDisabled={disableFunctions}
                    />
                </div>
            </div>
            <div className='mb-4'>
                Storage curve height(AHD) between: <strong className='text-primary'>{storage.minStorageVolume}m - {storage.maxStorageVolume}m</strong>
            </div>
            
 
            <StorageReadingForm storage={storage} nominatedMeasurementPeriod={this.props.nominatedMeasurementPeriod} disableFunctions={disableFunctions} setDisableFunctions={this.props.setDisableFunctions} updatePeriod={this.props.updatePeriod} />
            
        </>;
    }

}

export default Storage;