import React from 'react';
import { MapView } from '../../../../components/tools/MapView';
import { AsteriskRed } from '../../../../components/tools/Others';

export default class ValidationReplacingMeterLocation  extends React.Component {

    render = () => {
        const form = this.props.validationForm;
        const meter = form.val('replacingMeter');
        return <>
            <div className='row'>
                <div className='col-md-3 mb-3'>
                    {form.renderUneditableTextInput('replacingMeter',r=>r.latitude,<>Latitude</>)}
                </div>
                <div className='col-md-3 mb-3'>
                    {form.renderUneditableTextInput('replacingMeter',r=>r.longitude,<>Longitude</>)}
                </div>
            </div>
            <MapView
                readOnly={true}                
                point={{ latitude: meter.latitude, longitude: meter.longitude}}
                disabled={true}
            />
        </>
    }
}