import React from 'react';
import { reduxForm, formValueSelector, change } from 'redux-form';
import { connect } from 'react-redux'
import 'react-redux';
import 'redux';

import { formatDate } from '../../../constants/MeterFormConstants'
import { saveDraftAction } from "../common/MeterFormValidations"
import { dropdownDataFromReferenceData, getNamesForOptions, getNameForOption } from '../common/MeterCertificateCommon';


const NO_DATE = 'Date not provided';
let CertificateConfirmation = props => {

    const {
        storageManagementValidationData
        , authHolderInfo
        , floodplainWorkDetails
        , masterDataValues
        , cancelConfrimSubmissionAndGoToPage
        , cancelConfrimSubmission
        , completeConfirmation
        , loading
        , handleSubmit
    } = props;

    var dqpdata = masterDataValues.userProfile[0];

    console.log("dqp" + JSON.stringify(dqpdata));
    dqpdata = { ...dqpdata, "fullName": dqpdata.user.firstName + ' ' + dqpdata.user.lastName };
    debugger;
    props.dispatch(change(props.form, 'dqpdata', dqpdata));

    let certOptions = dropdownDataFromReferenceData(masterDataValues.refData, "certificateFor");

    const alloptions = ["complianceOption1", "complianceOption2", "complianceOption3", "complianceOption4", "complianceAuthority"];
    const alloptionsData = [];
    alloptions.forEach(ele => {
        alloptionsData.push(dropdownDataFromReferenceData(masterDataValues.refData, ele, "", "int",));
    });

    const getFromCode = (value, data) => {
        if (!value || value == '') {
            return '';
        }

        console.log("data" + JSON.stringify(data, null, 2));
        console.log("value" + value);
        return (data.find(ele => ele.id == value)).name;
    }


    return (
        <form className="vertical" onSubmit={handleSubmit}>
            <h5 className='mb-3'>Review</h5>
            <div class="alert alert-primary" role="alert">
                Please review the information below before completing your submission.
                <br />
                The form cannot be edited once submitted. Therefore, please confirm all configuration details are correct before submitting this form.
            </div>

            <div className='card mb-3'>
                <div className='card-header fw-bold'>Duly qualified person (DQP) details</div>
                <div className='card-body'>
                    <table class="table fs-90 mb-3">
                        <tbody>
                            <tr>
                                <th className='w-25'>
                                    Registered surveyors’/engineer’s name
                                </th>
                                <td>{dqpdata.fullName}</td>
                            </tr>
                            <tr>
                                <th>
                                    Registered surveyor's/engineer’s licence number
                                </th>
                                <td>{dqpdata.cmino}</td>
                            </tr>
                            <tr>
                                <th>
                                    Business name
                                </th>
                                <td>{dqpdata.businessName}</td>
                            </tr>

                            <tr>
                                <th>
                                    ABN or ACN
                                </th>
                                <td>{dqpdata.abnOrAcn}</td>
                            </tr>
                            <tr>
                                <th>
                                    Postal address
                                </th>
                                <td>{dqpdata.postalAddress.addressLine}</td>
                            </tr>
                            <tr>
                                <th>
                                    Phone
                                </th>
                                <td>{dqpdata.phoneNumber}</td>
                            </tr>
                            <tr>
                                <th>
                                    Email address
                                </th>
                                <td>{dqpdata.user.emailAddress}</td>
                            </tr>
                            {authHolderInfo.contactPersonName &&
                                <tr>
                                    <th>
                                        Contact Person Name
                                    </th>
                                    <td>{authHolderInfo.contactPersonName}</td>
                                </tr>
                            }
                            {authHolderInfo.contactPersonPhone &&
                                <tr>
                                    <th>
                                        Contact Person Phone
                                    </th>
                                    <td>{authHolderInfo.contactPersonPhone}</td>
                                </tr>
                            }
                            {authHolderInfo.contactPersonEmailAddress &&
                                <tr>
                                    <th>
                                        Contact Person Email address
                                    </th>
                                    <td>{authHolderInfo.contactPersonEmailAddress}</td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>
                <div className='card-footer'>
                    <button type="button" className="btn btn-primary"
                        onClick={() => { cancelConfrimSubmissionAndGoToPage(0); }}>
                        Edit
                    </button>
                </div>
            </div>

            <div className='card mb-3'>
                <div className='card-header fw-bold'>Details of Approved Works</div>
                <div className='card-body'>
                    <table class="table fs-90 mb-3">
                        <tbody>
                            <tr>
                                <th>
                                    Authority holder type
                                </th>
                                <td>{authHolderInfo.holderType}</td>
                            </tr>
                            {
                                authHolderInfo.holderType == "INDIVIDUAL" &&
                                <tr>
                                    <th>
                                        Authority holder firstname
                                    </th>
                                    <td>{authHolderInfo.firstName}</td>
                                </tr>
                            }
                            {
                                authHolderInfo.holderType == "INDIVIDUAL" &&
                                <tr>
                                    <th>
                                        Authority holder lastname
                                    </th>
                                    <td>{authHolderInfo.lastName}</td>
                                </tr>
                            }
                            {
                                authHolderInfo.holderType == "ORGANISATION" &&
                                <tr>
                                    <th>
                                        Organisation name
                                    </th>
                                    <td>{authHolderInfo.corporationNameOrAcn}</td>
                                </tr>
                            }
                            <tr>
                                <th>
                                    Postal address
                                </th>
                                <td>{authHolderInfo.postalAddress}</td>
                            </tr>
                            <tr>
                                <th>
                                    Phone
                                </th>
                                <td>{authHolderInfo.phone}</td>
                            </tr>
                            <tr>
                                <th>
                                    Alternate phone
                                </th>
                                <td>{authHolderInfo.alternatePhone}</td>
                            </tr>
                            <tr>
                                <th>
                                    Email address
                                </th>
                                <td>{authHolderInfo.emailAddress}</td>
                            </tr>
                            <tr>
                                <th>
                                    Property name
                                </th>
                                <td>{floodplainWorkDetails.propertyName}</td>
                            </tr>
                            <tr>
                                <th>
                                    Lot DP
                                </th>
                                <td>{floodplainWorkDetails.lotDp}</td>
                            </tr>

                            {
                                (floodplainWorkDetails.waterAccessLicenceNo) &&
                                <tr>
                                    <th>
                                        Water access license number(s)
                                    </th>
                                    <td>{floodplainWorkDetails.waterAccessLicenceNo}</td>
                                </tr>
                            }
                            {
                                (floodplainWorkDetails.ratingTable) &&
                                <tr>
                                    <th>
                                        Storage identifying number
                                    </th>
                                    <td>{floodplainWorkDetails.ratingTable.ratingTableName}</td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>
                <div className='card-footer'>
                    <button type="button" className="btn btn-primary"
                        onClick={() => { cancelConfrimSubmissionAndGoToPage(1); }}>
                        Edit
                    </button>
                </div>
            </div>

            <div className='card mb-3'>
                <div className='card-header fw-bold'>Details of Approved Works</div>
                <div className='card-body'>
                    <table class="table fs-90 mb-3">
                        <tbody>
                            <tr>
                                <th className='w-25'>
                                    This certificate is for
                                </th>
                                <td>
                                    {certOptions.map((st) => {
                                        if (st.id == storageManagementValidationData.certFor) {
                                            return st.name;
                                        }
                                    })}
                                </td>
                            </tr>
                            {(storageManagementValidationData.certFor == 2) &&
                                <tr>
                                    <th>
                                        This validation certificate is for
                                    </th>
                                    <td>
                                        {dropdownDataFromReferenceData(masterDataValues.refData, "certExistingFor").map((st) => {

                                            if (st.id == storageManagementValidationData.certExistingFor) {
                                                return st.name;
                                            }
                                        })}
                                    </td>
                                </tr>
                            }

                            {(storageManagementValidationData.certFor == 2 && storageManagementValidationData.certExistingFor == 1) &&
                                <React.Fragment>
                                    <tr className='table-primary'>
                                        <th colSpan="2"><strong>I certify that the existing storage meter:</strong></th>
                                    </tr>
                                    <tr >
                                        <td colSpan="2">
                                            <ul>
                                                <li>{getFromCode(storageManagementValidationData.storageManagementRegulation.compliance1, alloptionsData[0])}</li>
                                                <li>{getFromCode(storageManagementValidationData.storageManagementRegulation.compliance2, alloptionsData[1])}</li>
                                                <ul>
                                                    <li>{getFromCode(storageManagementValidationData.storageManagementRegulation.complianceAuthority, alloptionsData[4])}</li>
                                                </ul>
                                                <li>{getFromCode(storageManagementValidationData.storageManagementRegulation.compliance3, alloptionsData[2])}</li>
                                                <li>{getFromCode(storageManagementValidationData.storageManagementRegulation.compliance4, alloptionsData[3])}</li>
                                            </ul>
                                        </td>
                                    </tr>
                                </React.Fragment>
                            }

                            {(storageManagementValidationData.lastValidationDate) &&
                                <tr>
                                    <th>
                                        Date of last validation (if applicable)
                                    </th>
                                    <td>
                                        {formatDate(storageManagementValidationData.lastValidationDate)}
                                    </td>
                                </tr>
                            }
                            {(storageManagementValidationData.references) &&
                                <tr>
                                    <th>
                                        Other relevant approval references from NSW Government (ie. modification to works approval)
                                    </th>
                                    <td>
                                        {storageManagementValidationData.references}
                                    </td>
                                </tr>}
                            {(storageManagementValidationData.reinstallationDate) && <tr>
                                <th>
                                    Date storage metering equipment removed and reinstalled (if applicable)
                                </th>
                                <td>
                                    {formatDate(storageManagementValidationData.reinstallationDate)}
                                </td>
                            </tr>
                            }
                            {(storageManagementValidationData.certFor == 2) &&
                                <tr>
                                    <th>
                                        <b>Storage Meter Readings</b>
                                    </th>
                                    <td>
                                        &nbsp;
                                    </td>
                                </tr>
                            }
                            {(storageManagementValidationData.readingBeforeMaintenance) &&
                                <tr>
                                    <th>
                                        Before maintenance
                                    </th>
                                    <td>
                                        {storageManagementValidationData.readingBeforeMaintenance} On: {storageManagementValidationData.readingBeforeMaintenanceDate ? formatDate(storageManagementValidationData.readingBeforeMaintenanceDate) : NO_DATE}
                                    </td>
                                </tr>
                            }
                            {(storageManagementValidationData.readingAfterMaintenance) &&
                                <tr>
                                    <th>
                                        After maintenance
                                    </th>
                                    <td>
                                        {storageManagementValidationData.readingAfterMaintenance} On: {storageManagementValidationData.readingAfterMaintenanceDate ? formatDate(storageManagementValidationData.readingAfterMaintenanceDate) : NO_DATE}
                                    </td>
                                </tr>
                            }
                            <tr>
                                <th>
                                    Storage Meter Type
                                </th>
                                <td>
                                    {getNamesForOptions(storageManagementValidationData.storageMeterType, dropdownDataFromReferenceData(masterDataValues.refData, "storageMeterType"))}
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    Storage Meter Mounting Method
                                </th>
                                <td>
                                    {getNamesForOptions(storageManagementValidationData.mountingMethod, dropdownDataFromReferenceData(masterDataValues.refData, "mountingMethod"))}
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    Storage meter manufacturer &amp; model
                                </th>
                                <td>
                                    {masterDataValues.meteringSystemTypes.map((st) => {

                                        if (st.id == storageManagementValidationData.meteringSystemType) {
                                            return st.name;
                                        }
                                    })}
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    Storage meter serial number
                                </th>
                                <td>
                                    {storageManagementValidationData.meterSerialNumber}
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    Storage meter protocol
                                </th>
                                <td>
                                    {getNameForOption(
                                        storageManagementValidationData.meterProtocol,
                                        masterDataValues.storageMeterProtocols
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    Storage meter test/accuracy certificate reference number/date
                                </th>
                                <td>
                                    {storageManagementValidationData.refNum}
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    <b>LID Details</b>
                                </th>
                                <td>
                                    &nbsp;
                                </td>
                            </tr>
                            {/* 
                            <tr>
                                <th>
                                    LID Type
                                </th>
                                <td>
                                    {getNameForOption(
                                        storageManagementValidationData.lidType,
                                        dropdownDataFromReferenceData(masterDataValues.refData, "lidType"))
                                    }
                                </td>
                            </tr>
                            */}
                            <tr>
                                <th>
                                    LID Model
                                </th>
                                <td>
                                    {getNameForOption(
                                        storageManagementValidationData.lidModel,
                                        masterDataValues.lids
                                    )
                                    }
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    LID serial number
                                </th>
                                <td>
                                    {storageManagementValidationData.lidSerialNumber}
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    LID firmware version
                                </th>
                                <td>
                                    {storageManagementValidationData.lidVersion}
                                </td>
                            </tr>
                            <tr>
                                <th>Benchmark 1 (Primary) - Measurement device location</th>
                                <td> 
                                    Latitude: {storageManagementValidationData.latitude}<br />
                                    Longitude: {storageManagementValidationData.longitude}
                                </td>
                            </tr>

                            <tr>
                                <th>
                                    Level of meter sensor - measuring point (m AHD)
                                </th>
                                <td>
                                    {storageManagementValidationData.levelSensor}
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    Water level displayed in the DAS (if water available)
                                </th>
                                <td>
                                    {storageManagementValidationData.waterLevel}
                                </td>
                            </tr>


                            {(storageManagementValidationData.sealNo) &&
                                <tr>
                                    <th>Record the tamper-evident seal numbers</th>
                                    <td>{storageManagementValidationData.sealNo.map(ele => ele.label).join()}</td>
                                </tr>
                            }
                            {/* 
                            <tr>
                                <th >
                                    Power Source
                                </th>
                                <td>
                                    {getNamesForOptions(
                                        storageManagementValidationData.powerSource,
                                        dropdownDataFromReferenceData(masterDataValues.refData, "powerSource", "")
                                    )
                                    }
                                </td>
                            </tr>
                            
                            {(storageManagementValidationData.powerSource == 1 && storageManagementValidationData.solarVoltage) &&
                                <tr>
                                    <th>
                                        Solar panel voltage and wattage
                                    </th>
                                    <td>
                                        {storageManagementValidationData.solarVoltage}
                                    </td>
                                </tr>

                            }
                            <tr>
                                <th>
                                    Signal Strength
                                </th>
                                <td>
                                    {getNamesForOptions(
                                        storageManagementValidationData.network,
                                        dropdownDataFromReferenceData(masterDataValues.refData, "network", "")
                                    )
                                    }
                                </td>
                            </tr>
                            */}
                            {(storageManagementValidationData.comments) &&
                                <tr>
                                    <th>
                                        Comments
                                    </th>
                                    <td>
                                        {storageManagementValidationData.comments}
                                    </td>
                                </tr>}
                        </tbody>
                    </table>
                </div>
                <div className='card-footer'>
                    <button type="button" className="btn btn-primary"
                        onClick={() => { cancelConfrimSubmissionAndGoToPage(2); }}>
                        Edit
                    </button>
                </div>
            </div>

            <div class="text-end">
                {(parseInt(storageManagementValidationData.formStatus) == 1) && (!props.disableEditing) && <>
                    {storageManagementValidationData.id != 0 && <button type="button" className="btn btn-danger me-3" disabled={loading}
                        data-bs-toggle="modal" data-bs-target="#deleteDraftModal">
                        Delete Draft
                    </button>}
                    <button type="button" className="btn btn-primary" disabled={loading}
                        onClick={() => {
                            saveDraftAction(props);
                        }}>
                        Save Draft
                    </button>
                    <div className='vr mx-3'></div>
                </>}

                <button type="button" className="btn btn-warning me-3" disabled={loading}
                    onClick={cancelConfrimSubmission}>
                    Previous
                </button>

                <button type="button" className="btn btn-success" disabled={loading}
                    onClick={completeConfirmation}>
                    {(parseInt(storageManagementValidationData.formStatus) == 1) ? "Confirm" : "Next"}
                </button>
            </div>
        </form>
    )
}

CertificateConfirmation = reduxForm({
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate: () => { return {}; }
})(CertificateConfirmation)

CertificateConfirmation = connect(
    (state, props) => {

        const selector = formValueSelector(props.form);
        const storageManagementValidationData = selector(state, 'storageManagementValidationData')
        const authHolderInfo = selector(state, 'authorityHolderDetails');
        const dqpdata = selector(state, 'dqpdata');
        const floodplainWorkDetails = selector(state, 'floodplainWorkDetails');
        const appWorkInfo = selector(state, 'approvedWorkDetails');
        const documents = selector(state, 'documents')
        const meterNonComplianceReason = selector(state, 'meterNonComplianceReason')
        const meterNonCompliance = selector(state, 'meterNonCompliance')
        const registrationDoc = selector(state, 'registrationDoc')
        const userProfileValues = selector(state, 'mdata.userProfile')
        const masterDataValues = selector(state, 'mdata')
        const loading = selector(state, 'loading')

        const consentData = selector(state, 'consentData')

        const form = props.form;

        return {
            storageManagementValidationData,
            authHolderInfo,
            dqpdata,
            floodplainWorkDetails,
            appWorkInfo,
            masterDataValues,
            documents,
            meterNonComplianceReason,
            meterNonCompliance,
            registrationDoc,
            userProfileValues,
            consentData,
            form,
            loading
        }
    }
)(CertificateConfirmation)

export default CertificateConfirmation;

