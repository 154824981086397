import React from 'react';
import Select from 'react-select';
import axios from 'axios';
import { InputTextWithFloatingLabel } from '../tools/BootstrapControls'
import Authentication from '../../helpers/Authentication'
import uiHelpers from '../../helpers/uiHelpers'
import CustomerApprovalForm from './CustomerApprovalForm'
import { userRole, addUserRoleUpdateNotifier, notifyUserRoleUpdated } from '../../helpers/userHelpers';


const authentication = new Authentication();

const approvalSearchFormInitValues = {
    workApprovalNumerInput: '',
    workApprovalNumber: null,
    workApprovalNumberError: null,
    workApprovalNumberFound: false,

    holderOptions: [],
    holders: [],

    selectedHolder: null,
    consentValid: false,

    formLoading: false
};

class ApprovalSearchFormWithConsent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            approvalSearchForm: Object.assign({}, approvalSearchFormInitValues),
            consentsForApprovalForm: [],
            searchDataForApprovalForm: null,
            showApprovalForm: false,
            awaitingCustomerConsentValidAction: false
        };

        if(props.approvalNumber)
            this.state.approvalSearchForm.workApprovalNumerInput = props.approvalNumber;

        addUserRoleUpdateNotifier(() => {
            this.forceUpdate();
        });
    }

    ajaxConfig = {
        baseURL: process.env.REACT_APP_WEBAPI_URL,
        headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authentication.getToken() }
    };

    componentDidMount() {
        if(this.props.approvalNumber) {
            this.searchApproval();
        }
    }

    workApprovalNumberChange = (event) => {
        this.setState(Object.assign(this.state.approvalSearchForm, { workApprovalNumerInput: event.target.value }));
    }

    researchSearchForm = () => {
        this.setState(Object.assign(this.state.approvalSearchForm, approvalSearchFormInitValues));
    }

    nameOptionChange = (e) => {
        var holder = null;
        this.state.holders.map((user, i) => {
            if (user.DLWC_INPA_ID == e.value) holder = user;
        });
        var workApprovalNumber = this.state.approvalSearchForm.workApprovalNumber;
        this.setState(Object.assign(this.state.approvalSearchForm, { selectedHolder: holder }));

        let currentContext = this;
        //alert(userRole.isSuperAdmin);
        if (userRole.isSuperAdmin || userRole.isCFOAdmin) {

            currentContext.setState(Object.assign(currentContext.state.approvalSearchForm, { consentValid: true }));
            return;
        }

        currentContext.setState(Object.assign(currentContext.state.approvalSearchForm, { formLoading: true }));
        uiHelpers.showProgressOnTop('Finding approval consent');
        axios.post('/customerConsent/getConsentByApprovalNumberForUser', JSON.stringify({
            approvalNumber: workApprovalNumber
        }), this.ajaxConfig).then(function (response) {

            var consent = response.data.data;
            if (consent == null) {
                currentContext.setState(Object.assign(currentContext.state, {
                    searchDataForApprovalForm: {
                        customerEmail: holder.E_MAIL,
                        workApprovalNo: workApprovalNumber,
                        firstName: holder.Given_Names,
                        lastName: holder.family_name,
                        organisation: holder.ORGANISATION_NAME
                    }
                }));
                currentContext.setState(Object.assign(currentContext.state, { showApprovalForm: true }));
                return;
            }




            var isValidityExpired = false;
            if (consent.approved == true) {
                var today = new Date();
                var approvedDate = consent.approvedDate;
                var date1 = new Date(approvedDate);
                var date2 = new Date(today);
                var diffDays = parseInt((date2 - date1) / (1000 * 60 * 60 * 24), 10);
                if (diffDays > consent.authorizationPeriod) {
                    isValidityExpired = true;
                } else {
                    isValidityExpired = false;
                }
            }

            if (consent.approved == true && isValidityExpired == false) {
                currentContext.setState(Object.assign(currentContext.state.approvalSearchForm, { consentValid: true }));
            } else {
                currentContext.setState(Object.assign(currentContext.state, { consentsForApprovalForm: [consent] }));
                currentContext.setState(Object.assign(currentContext.state, { showApprovalForm: true }));
            }

        }).catch(function (error) {
            uiHelpers.showErrorTop('Error happened on the server side when getting customer consent details.');
        }).then(function () {
            currentContext.setState(Object.assign(currentContext.state.approvalSearchForm, { formLoading: false }));
            uiHelpers.hideProgressOnTop();
        });

    }

    continue = () => {
        this.setState(Object.assign(this.state, { awaitingCustomerConsentValidAction: true }));
        var currentContext = this;
        this.props.onCustomerConsentValid(this.state.approvalSearchForm.workApprovalNumber, this.state.approvalSearchForm.selectedHolder, () => {
            currentContext.setState(Object.assign(currentContext.state, { awaitingCustomerConsentValidAction: false }));
        });
        //this.setState(Object.assign(this.state,{awaitingCustomerConsentValidAction : false} ));
    }

    searchApproval = (e) => {
        if (this.state.approvalSearchForm.workApprovalNumerInput == null || this.state.approvalSearchForm.workApprovalNumerInput == '') {
            this.setState(Object.assign(this.state.approvalSearchForm, { workApprovalNumberError: 'Please provide a work approval number' }));
            return;
        }

        this.setState(Object.assign(this.state.approvalSearchForm, {
            workApprovalNumberError: null,
            formLoading: true,
            workApprovalNumberFound: false,
            workApprovalNumber: null
        }));
        uiHelpers.showProgressOnTop('Searching for approval...');
        let currentContext = this;

        axios.post(process.env.REACT_APP_DATALAKE_APPROVAL_SEARCH, JSON.stringify({ workApprovalNo: this.state.approvalSearchForm.workApprovalNumerInput }), this.ajaxConfig).then(function (response) {

            if (response.data.data.errorCode == null) {
                currentContext.setState(Object.assign(currentContext.state.approvalSearchForm, { workApprovalNumber: response.data.data.licence.license }));
                if (response.data.data.names.length > 0) {
                    currentContext.setState(Object.assign(currentContext.state.approvalSearchForm, { workApprovalNumberFound: true }));
                    var holderOptions = [];
                    response.data.data.names.map((user, i) => {

                        let personName = user.Given_Names + ' ' + user.family_name;
                        let inpa_type = user.INPA_TYPE;

                        let name = '';
                        name = personName;
                        if (inpa_type !== "P") {
                            name = user.ORGANISATION_NAME;
                        }

                        holderOptions.push({ "label": name, "value": user.DLWC_INPA_ID });
                    });
                    currentContext.setState(Object.assign(currentContext.state.approvalSearchForm, { holderOptions: holderOptions }));
                    currentContext.setState(Object.assign(currentContext.state.approvalSearchForm, { holders: response.data.data.names }));
                } else {
                    currentContext.setState(Object.assign(currentContext.state.approvalSearchForm, { workApprovalNumberError: 'No customer details avaiable under this work approval number, please check with system administrator.' }));
                }

            } else if (response.data.data.errorCode == 'LICENCE_NOT_FOUND') {
                currentContext.setState(Object.assign(currentContext.state.approvalSearchForm, { workApprovalNumberError: 'No match found for this number. Please check with the customer and try again.' }));
            } else if (response.data.data.errorCode == 'NOT_VALID_LIC_TYPE') {
                currentContext.setState(Object.assign(currentContext.state.approvalSearchForm, { workApprovalNumberError: 'Invalid number entered. Work approval number should match the following format: 1.xxWAxxxxxx 2.xxCAxxxxxx 3.xxMWxxxxxx. Please check with the customer and try again.' }));
            } else if (response.data.data.errorCode == 'NOT_VALID_STATUS') {
                currentContext.setState(Object.assign(currentContext.state.approvalSearchForm, { workApprovalNumberError: 'Number entered is not in "Current" status. Please check with the customer and try again.' }));
            } else if (response.data.data.errorCode == 'NO_VALID_ESID') {
                currentContext.setState(Object.assign(currentContext.state.approvalSearchForm, { workApprovalNumberError: 'There are no Extraction sites associated with this number. Please check with the customer and try again.' }));
            }
        }).catch(function (error) {
            uiHelpers.showErrorTop('Error happened on the server side when searching the approval.')
        }).then(function () {
            uiHelpers.hideProgressOnTop();
            currentContext.setState(Object.assign(currentContext.state.approvalSearchForm, { formLoading: false }));
        });
    }


    render() {
        return <div>
            {!this.state.showApprovalForm && <>
                <h5>Search customer details</h5>
                <div class="row mb-2">
                    <div class="col-md-6">

                        <InputTextWithFloatingLabel
                            className="mb-3"
                            readOnly={this.state.approvalSearchForm.workApprovalNumber && this.state.approvalSearchForm.workApprovalNumber != ''}
                            id="workApprovalNumber_searchWithConsent"
                            value={this.state.approvalSearchForm.workApprovalNumerInput}
                            disabled={this.state.approvalSearchForm.formLoading || this.props.disabled}
                            onChange={this.workApprovalNumberChange}
                            error={this.state.approvalSearchForm.workApprovalNumberError}
                            label={<>Work approval number <span className='text-danger'>*</span></>}
                        />

                        <div className='mb-3'>
                            {this.state.approvalSearchForm.workApprovalNumberFound && <div class="form-group">
                                <Select
                                    isDisabled={this.state.approvalSearchForm.consentValid || this.state.approvalSearchForm.formLoading || this.props.disabled}
                                    options={this.state.approvalSearchForm.holderOptions}
                                    onChange={this.nameOptionChange}
                                />
                            </div>}
                        </div>

                        <p>
                            {!this.state.approvalSearchForm.workApprovalNumberFound &&
                                <button type="button" class="btn btn-primary" disabled={this.state.approvalSearchForm.formLoading || this.props.disabled} onClick={this.searchApproval}>
                                    Search
                                </button>
                            }
                            {this.state.approvalSearchForm.workApprovalNumberFound &&
                                <button type="button" class="btn btn-secondary" onClick={this.researchSearchForm}
                                    disabled={this.state.awaitingCustomerConsentValidAction || this.state.approvalSearchForm.formLoading || this.props.disabled}
                                >Reset</button>
                            }
                            {this.state.approvalSearchForm.consentValid &&
                                <button type="button" class="btn btn-primary float-end" onClick={this.continue}
                                    disabled={this.state.awaitingCustomerConsentValidAction || this.props.disabled}
                                >Continue</button>
                            }
                        </p>
                    </div>
                </div>

            </>}

            {this.state.showApprovalForm && <CustomerApprovalForm consentData={this.state.consentsForApprovalForm} searchData={this.state.searchDataForApprovalForm} />}

        </div>
    }
}

export default ApprovalSearchFormWithConsent;