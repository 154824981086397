import React from 'react';
import FileUploader from '../../../../components/common/FileUploader';

const fileTableHeadComponent = <tr><th style={{width:"80px"}}></th><th>File name</th><th style={{width:"350px"}}>Description</th></tr>;
const fileTableRowCompponent=(file, index, deleteButton)=>{
    return <tr key={index}>
       <td>
          {deleteButton}
       </td>
       <td>
          <a target='_blank' href={process.env.REACT_APP_WEBAPI_URL + process.env.REACT_APP_FIELS_GET + "/" + file.documentId}>
             {file.fileOriginalName}
          </a>
       </td>
       <td>{file.description}</td>
 </tr>
 }
export default class ValidationFileUploder  extends React.Component {

    fileUploaderDisplayListUpdate;
    fileUploaderFilesUploaded = (files) => {
        const form = this.props.validationForm;
        const documents = form.val('documents');
        files.forEach(f=>{
            documents.push({
                id:null,
                description:f.description,
                documentType:this.props.documentType,
                documentId:f.id,
                fileName:f.fileName,
                fileType: f.fileType,
                fileUrl: f.fileUrl,
                fileSize: f.fileSize,
                fileOriginalName: f.fileOriginalName,
                fileGUID: f.fileGUID
            })
        });
        form.vals({documents}, true, this.refresh);
    }

    componentDidMount() {
        this.refresh();
    }

    refresh = () => {
        this.fileUploaderDisplayListUpdate(this.props.files);
    }

    onfileDeleted=(file)=>{
        const form = this.props.validationForm;
        const documents = form.val('documents');
  
        for(var i = 0; i< documents.length; i++){
            if(file===documents[i]) {
                documents.splice(i, 1);
                break;
            }
        }
  
        form.vals({documents}, true, this.refresh);
     }

    render() {
        const form = this.props.validationForm;
        return <FileUploader
            maxFileSizeMB={10}
            exceedFileSizeErrorMsg = "Your file is too big"
            disabled={form.isFieldDisabled('documents') || form.isFormDisabled()}
            notShowInput={false}
            fileTableHeadComponent = {fileTableHeadComponent}
            fileTableRowCompponent = {fileTableRowCompponent}
            onFilesUploaded={this.fileUploaderFilesUploaded}
            provideDisplayDocumentsUpdator={(updateFunction)=>{this.fileUploaderDisplayListUpdate = updateFunction;}}
            onFileDeleted={this.onfileDeleted}
        />
    }
}