import React from 'react';
import serviceUrls from '../../../../constants/serviceUrls';
import * as bootstrap from 'bootstrap';
import * as apiCaller from '../../../../helpers/apiCaller'
import { LoadingPage } from '../../../../components/tools/Others';
import uiHelpers from '../../../../helpers/uiHelpers';
import { InputText, UneditableTextInput } from '../../../../components/tools/BootstrapControls';
import moment from 'moment';
import { convertMeterIntegrationAuditAction, convertWasReadingIntegrationStatus } from '../../../../helpers/converters';
import { Info } from '../../../../components/tools/Icons';

class MeterIntegrations  extends React.Component {

    #errorModalElementId = "err_model_" + (new Date()).getTime();
    #errorModal;

    #toggleInteStatusModalElementId = "ints_model_" + (new Date()).getTime();
    #toggleInteStatusModal;

    constructor(props) {
        super(props)
        this.state = {
            initialised:false,
            callingService: false,
            meterIntegration:null,
            wasMeter:null,

            errorModelTitle:null,
            errorModelMsg:null,

            switchStatusReason:'',
            switchStatusErr:null
        }
    }

    async componentDidMount() {
        this.refresh();
    }

    getErrModal = () => {
        if(!this.#errorModal) this.#errorModal = new bootstrap.Modal(document.getElementById(this.#errorModalElementId));
        return this.#errorModal;
    }

    getToggleIntegrationStatusModel = () => {
        if(!this.#toggleInteStatusModal) this.#toggleInteStatusModal = new bootstrap.Modal(document.getElementById(this.#toggleInteStatusModalElementId));
        return this.#toggleInteStatusModal;
    }

    refresh = async () => {
        uiHelpers.showProgressOnTop('Getting integration record details...');
        this.setState({callingService:true});
        await apiCaller.post(serviceUrls.admin.getMeterIntegrationDetails,{id:this.props.meter.id})
        .then((data)=>{
            this.setState({
                initialised: true,
                meterIntegration: data.meterIntegration,
                wasMeter:data.wasMeter,
                audits:data.audits
            });
        }).finally(()=>{
            uiHelpers.hideProgressOnTop();
            this.setState({callingService:false});

            
        });
    }

    onInitialiseClick = async () =>{
        uiHelpers.showProgressOnTop('Initialising integration record...');
        this.setState({callingService:true});
        await apiCaller.post(serviceUrls.admin.initialiseMeterIntegration,{id:this.props.meter.id})
        .then((data)=>{
            this.refresh();
        })
    }

    onMatchEagleIoClick = () => {
        this.onMatchClickGeneric(serviceUrls.admin.matchEagleIoIntegrationForMeter);
    }

    onMatchWasClick = () => {
        this.onMatchClickGeneric(serviceUrls.admin.matchWasIntegrationForMeter);
    }

    onMatchClickGeneric = async (url) => {
        uiHelpers.showProgressOnTop('Trying to match meter...');
        this.setState({callingService:true});
        await apiCaller.post(url,{id:this.props.meter.id})
        .then((data)=>{
            if(data.succeed) {
                this.refresh();
            } else {
                this.setState({
                    errorModelTitle : <span className='text-danger'>Issue happened</span>,
                    errorModelMsg: <div className='text-danger'>Error message: {data.errMsg}</div>
                },()=>{
                    this.getErrModal().show();
                    uiHelpers.hideProgressOnTop();
                    this.setState({callingService:false});
                });
                
            }
        })
    }

    onStartWasIntegrationClick = async () => {
        const errList = [];
        if(this.state.meterIntegration.eagleIoId==null) {
            errList.push(<li>Linkage to EagleIO is not established</li>);
        }
        if(this.state.meterIntegration.wasMeterId==null) {
            errList.push(<li>Linkage to WAS is not established</li>);
        }
        if(this.state.meterIntegration.nextWasReadingFromUtcStr == null) {
            errList.push(<li><b>Next Reading Date</b> is empty</li>);
        }
        if(this.state.wasMeter && this.state.wasMeter.assetCode!=this.props.meter.meterSiteId){
            errList.push(<li>DAS ID in WAS is not matching this meter</li>);
        }

        if(errList.length>0){
            this.setState({
                errorModelTitle : <span className='text-danger'>Integration cannot be started</span>,
                errorModelMsg: <ul className='text-danger mb-0'>{errList}</ul>
            },()=>{
                this.getErrModal().show();
            });
        } else {
            uiHelpers.showProgressOnTop('Starting integration...');
            this.setState({callingService:true});
            await apiCaller.post(serviceUrls.admin.startMeterIntegrationDetails,{id:this.props.meter.id})
            .then((data)=>{
                this.refresh();
            })
        }
        
    }

    onToggleIntegrationStatus = () => {
        this.setState({switchStatusErr:null,switchStatusReason:''})
        this.getToggleIntegrationStatusModel().show();
    }

    onSwitchStatusReasonChanged= (e) => {
        this.setState({switchStatusReason:e.target.value})
    }

    onConfirmStatusChangedClick = async () => {
        if(this.state.switchStatusReason.trim()=='') {
            this.setState({switchStatusErr:'Please provide a reason to proceed'})
            return;
        } else {
            this.setState({switchStatusErr:null, callingService:true});
            uiHelpers.showProgressOnTop('Switching...');
            this.setState({callingService:true});
            await apiCaller.post(serviceUrls.admin.toggleMeterIntegrationStatus,{id:this.props.meter.id, comments:this.state.switchStatusReason})
            .then((data)=>{
                this.getToggleIntegrationStatusModel().hide();
                this.refresh();
            })
        }
    }

    onAuditErrorClick = async(audit) =>{
        this.setState({
            errorModelTitle : <span className='text-danger'>Error for Audit</span>,
            errorModelMsg: <div className='text-danger overflow-auto' style={{"white-space":"pre", "min-height":"300px"}}>{audit.error}</div>
        },()=>{
            this.getErrModal().show();
            uiHelpers.hideProgressOnTop();
            this.setState({callingService:false});
        });
    }

    render() {
        if(!this.state.initialised) return <LoadingPage />
        if(!this.state.meterIntegration) return <div className='bg-light p-5 text-center'>
            <p>Integration record is not initialised.</p>
            <button className='btn btn-primary' disabled={this.state.callingService} onClick={this.onInitialiseClick}>Initialise</button>
        </div>

        const meterIntegration = this.state.meterIntegration;
        const wasMeter = this.state.wasMeter;
        const audits = this.state.audits;
        return <>
            <div class="card mb-3">
                <div class="card-header  bg-primary text-white">
                    <b>Integration Record</b>
                </div>
                <div className='card-body'>
                    <div className='row mb-3'>
                        <div className='col-md-4 py-2'>
                        Record Created Date
                        </div>
                        <div className='col-md-8'>
                            <UneditableTextInput
                                fieldExtraClass="bg-light"
                                value={moment((new Date(meterIntegration.createdDate))).format('DD/MM/YYYY h:mma')}
                            />
                        </div>
                    </div>
                    <div className='row mb-3'>
                        <div className='col-md-4 py-2'>
                        WAS Reading Integration Status
                        </div>
                        <div className='col-md-8'>
                            {meterIntegration.wasReadingIntegrationStatus=="NOT_STARTED"
                            ?
                            <UneditableTextInput
                                fieldExtraClass="bg-light"
                                value={<>{convertWasReadingIntegrationStatus(meterIntegration.wasReadingIntegrationStatus)}<button disabled={this.state.callingService} onClick={this.onStartWasIntegrationClick} className='btn btn-outline-primary btn-sm ms-3'>Start</button></>}
                            />
                            :
                            <>
                                <div class="form-check form-switch my-2">
                                    <input class="form-check-input" type="checkbox" role="switch" onChange={this.onToggleIntegrationStatus} id="switchIntegration" checked={meterIntegration.wasReadingIntegrationStatus=="ON"}/>
                                    <label class="form-check-label" for="switchIntegration">{convertWasReadingIntegrationStatus(meterIntegration.wasReadingIntegrationStatus)}</label>
                                </div>
                            </>
                            }
                            
                        </div>
                    </div>
                    <div className='row mb-3'>
                        <div className='col-md-4 py-2'>
                        Next Reading Date (to WAS) Starts From
                        </div>
                        <div className='col-md-8'>
                            <UneditableTextInput
                                fieldExtraClass="bg-light"
                                value={meterIntegration.nextWasReadingFromUtcStr?moment((new Date(meterIntegration.nextWasReadingFromUtcStr))).format('DD/MM/YYYY h:mm:ssa'):null}
                            />
                        </div>
                    </div>
                    <div className='row mb-3'>
                        <div className='col-md-4 py-2'>
                        ID in EagleIO
                        </div>
                        <div className='col-md-8'>
                            <UneditableTextInput
                                fieldExtraClass="bg-light"
                                value={meterIntegration.eagleIoId ? meterIntegration.eagleIoId : <>
                                    <i>Not linked</i>
                                    <button className='btn btn-outline-primary btn-sm ms-3' onClick={this.onMatchEagleIoClick} disabled={this.state.callingService}>Try to match</button>
                                </>}
                            />
                        </div>
                    </div>
                    <div className='row mb-3'>
                        <div className='col-md-4 py-2'>
                        EagleIO Link Established On
                        </div>
                        <div className='col-md-8'>
                            <UneditableTextInput
                                fieldExtraClass="bg-light"
                                value={meterIntegration.eagleIoIntegrationDate?moment((new Date(meterIntegration.eagleIoIntegrationDate))).format('DD/MM/YYYY h:mma'):null}
                            />
                        </div>
                    </div>
                    <div className='row mb-3'>
                        <div className='col-md-4 py-2'>
                        WAS Meter ID
                        </div>
                        <div className='col-md-8'>
                            <UneditableTextInput
                                fieldExtraClass="bg-light"
                                value={meterIntegration.wasMeterId ? meterIntegration.wasMeterId : <>
                                    <i>Not linked</i>
                                    <button className='btn btn-outline-primary btn-sm ms-3' onClick={this.onMatchWasClick} disabled={this.state.callingService}>Try to match</button>
                                </>}
                            />
                        </div>
                    </div>
                    <div className='row mb-3'>
                        <div className='col-md-4 py-2'>
                        WAS Meter GUID
                        </div>
                        <div className='col-md-8'>
                            <UneditableTextInput
                                fieldExtraClass="bg-light"
                                value={meterIntegration.wasGuid}
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-4 py-2'>
                        WAS Link Established On
                        </div>
                        <div className='col-md-8'>
                            <UneditableTextInput
                                fieldExtraClass="bg-light"
                                value={meterIntegration.wasIntegrationDate?moment((new Date(meterIntegration.wasIntegrationDate))).format('DD/MM/YYYY h:mma'):null}
                            />
                        </div>
                    </div>
                </div>
            </div>

            {wasMeter && <div class="card mb-3">
                <div class="card-header  bg-primary text-white">
                    <b>Meter Record in WAS</b>
                </div>
                <div className='card-body'>
                    <div className='row mb-3'>
                        <div className='col-md-4 py-2'>
                            DAS ID
                        </div>
                        <div className='col-md-8'>
                            <UneditableTextInput fieldExtraClass="bg-light" value={
                                wasMeter.assetCode==this.props.meter.meterSiteId ? wasMeter.assetCode :
                                <span>{wasMeter.assetCode} <span className='text-danger'>(This is not matching the DQP Portal)</span></span>
                            }/>
                        </div>
                    </div>
                    <div className='row mb-3'>
                        <div className='col-md-4 py-2'>
                            GUID
                        </div>
                        <div className='col-md-8'>
                            <UneditableTextInput fieldExtraClass="bg-light" value={wasMeter.guid}/>
                        </div>
                    </div>
                    <div className='row mb-3'>
                        <div className='col-md-4 py-2'>
                        Latitude
                        </div>
                        <div className='col-md-8'>
                            <UneditableTextInput fieldExtraClass="bg-light" value={wasMeter.latitude}/>
                        </div>
                    </div>
                    <div className='row mb-3'>
                        <div className='col-md-4 py-2'>
                        Longitude
                        </div>
                        <div className='col-md-8'>
                            <UneditableTextInput fieldExtraClass="bg-light" value={wasMeter.longitude}/>
                        </div>
                    </div>
                    <div className='row mb-3'>
                        <div className='col-md-4 py-2'>
                        Meter Category
                        </div>
                        <div className='col-md-8'>
                            <UneditableTextInput fieldExtraClass="bg-light" value={wasMeter.meterCategory}/>
                        </div>
                    </div>
                    <div className='row mb-3'>
                        <div className='col-md-4 py-2'>
                        Meter Type
                        </div>
                        <div className='col-md-8'>
                            <UneditableTextInput fieldExtraClass="bg-light" value={wasMeter.meterType}/>
                        </div>
                    </div>
                    <div className='row mb-3'>
                        <div className='col-md-4 py-2'>
                        Serial Number
                        </div>
                        <div className='col-md-8'>
                            <UneditableTextInput fieldExtraClass="bg-light" value={wasMeter.serialNumber}/>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-4 py-2'>
                        Meter Size (MM)
                        </div>
                        <div className='col-md-8'>
                            <UneditableTextInput fieldExtraClass="bg-light" value={wasMeter.sizeInMM}/>
                        </div>
                    </div>
                </div>
            </div>}

            <div class="card mb-3">
                <div class="card-header  bg-primary text-white">
                    <b>Audit for Integration Record</b>
                </div>
                <div className='card-body'>
                    <div class="table-responsive-xxl">
                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <th scope="col" style={{width:"200px"}}>Date &amp; Time</th>
                                    <th scope="col" style={{width:"200px"}}>Action</th>
                                    <th scope="col" style={{width:"180px"}}>User</th>
                                    <th scope="col">Comments / Other Info</th>
                                </tr>
                            </thead>
                            <tbody>
                                {audits.map(a=>{
                                    return <tr>
                                        <td>{moment((new Date(a.auditDate))).format('DD/MM/YYYY h:mm:ss a')}</td>
                                        <td>{convertMeterIntegrationAuditAction(a.auditAction)}</td>
                                        <td>{a.auditUser?<>{a.auditUser.firstname} {a.auditUser.lastname}</>:<i className='text-muted'>System</i>}</td>
                                        <td>
                                            {a.meterCommissionId && <p>
                                                This action is triggered by this <a href={`/#/compliance-process/${a.meterCommissionId}/site-registration`}>Meter Compliance Process</a>
                                            </p>}
                                            
                                            {a.comments} 
                                            {a.auditAction == 'UPDATE_NEXT_READING_DATE_FROM' && <i className='fs-80'>(Sydney time: {moment((new Date(a.comments))).format('DD/MM/YYYY h:mm ssa')})</i>}
                                            {a.auditAction=='PAUSE_ON_ERROR' && <button className='btn btn-outline-danger btn-sm ms-2' onClick={()=>this.onAuditErrorClick(a)}><Info/></button>}
                                        </td>
                                    </tr>
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div class="modal fade" id={this.#errorModalElementId} data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-xl">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">{this.state.errorModelTitle}</h5>
                        </div>
                    <div class="modal-body">
                        {this.state.errorModelMsg}
                    </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>


            <div class="modal fade" id={this.#toggleInteStatusModalElementId} data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">Confirmation of switch {this.state.meterIntegration.wasReadingIntegrationStatus =="ON" ? "off" : "on"} integration</h5>
                        </div>
                    <div class="modal-body">
                        <div className='alert alert-primary'>Please confirm that you would like to switch {this.state.meterIntegration.wasReadingIntegrationStatus =="ON" ? "off" : "on"} the integrtation to WAS, and please provide a reason for that:</div>
                        <InputText label="Reason" disabled={this.state.callingService} value={this.state.switchStatusReason} error={this.state.switchStatusErr} onChange={this.onSwitchStatusReasonChanged}/>
                    </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-primary" onClick={this.onConfirmStatusChangedClick} disabled={this.state.callingService}>Confirm</button>
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" disabled={this.state.callingService}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>

            
        </>
    }
}

export default MeterIntegrations;
