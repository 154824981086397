import React, { Fragment } from 'react';
import axios from 'axios';
import * as bootstrap from 'bootstrap';
import Authentication from '../../helpers/Authentication'
import { fileSizeUploadLimit } from '../../constants/MeterFormConstants'
import {InputTextWithFloatingLabel} from '../tools/BootstrapControls'
import commonConstants from '../../constants/commonConstants'
import uiHelpers from '../../helpers/uiHelpers'
import FileUploader from './FileUploader'

const authentication = new Authentication();

class CustomerApprovalForm extends React.Component {

    

    constructor(props) {
        super(props);
        this.state = {
            consentForm: {
                id: 0,
                customerEmail: "",
                workApprovalNo: "",
                duration: 0,
                formType: 1,
                approvalType: 1,
                firstName: "",
                lastName: "",
                orgaisation: "",
                isAutomatic: true,
                isLoading: false,
                isSendError: false,
                //isFileUploading: false,
                approved: false,
                createdBy: 0,
                passcode: "",
                rejected: false,
                resend: false,
                token: "",
                pdfUrl: "",
                emailCount: 0,
                authorizationPeriod: 30,
                documentId: 0,
                baseUrl: '',
                dqpFirstName: '',
                dqpLastName: '',
                dqpBusinessName: ''
            },
            consentFormError : {
                workApprovalNo : "",
                customerEmail : "",
                passcode : ''
            },
            documents: [],
            isPdfCreating: false,
            pdfCreateDisable: false,
            consentExpired: false,
            //pdfDownloadUrl: ''

        };

        if (this.props.consentData) {
            if (this.props.consentData.length > 0) {

                var isValidityExpired = false;
                if (this.props.consentData[0].approved == true) {
                    var today = new Date();
                    var approvedDate = this.props.consentData[0].approvedDate;
                    var date1 = new Date(approvedDate);
                    var date2 = new Date(today);
                    var diffDays = parseInt((date2 - date1) / (1000 * 60 * 60 * 24), 10);
                    if (diffDays > this.props.consentData[0].authorizationPeriod) {
                        isValidityExpired = true;
                    }
                    else {
                        isValidityExpired = false;
                    }
                }

                this.setState(Object.assign(this.state, { consentExpired: isValidityExpired }));

                this.setState(Object.assign(this.state.consentForm, { firstName: this.props.consentData[0].firstName }));
                this.setState(Object.assign(this.state.consentForm, { workApprovalNo: this.props.consentData[0].workApprovalNo }));
                this.setState(Object.assign(this.state.consentForm, { duration: this.props.consentData[0].duration }));
                this.setState(Object.assign(this.state.consentForm, { lastName: this.props.consentData[0].lastName }));
                this.setState(Object.assign(this.state.consentForm, { organisation: this.props.consentData[0].organisation }));

                this.setState(Object.assign(this.state.consentForm, { approvalType: this.props.consentData[0].approvalType }));
                this.setState(Object.assign(this.state.consentForm, { approved: this.props.consentData[0].approved }));
                this.setState(Object.assign(this.state.consentForm, { createdBy: this.props.consentData[0].createdBy }));
                this.setState(Object.assign(this.state.consentForm, { customerEmail: this.props.consentData[0].customerEmail }));
                this.setState(Object.assign(this.state.consentForm, { formType: this.props.consentData[0].formType }));
                this.setState(Object.assign(this.state.consentForm, { id: this.props.consentData[0].id }));
                //this.setState(Object.assign(this.state.consentForm, { passcode: this.props.consentData[0].passcode }));
                //this.setState(Object.assign(this.state.consentForm, { passcode: this.props.consentData[0].passcode }));
                this.setState(Object.assign(this.state.consentForm, { passcode: '' }));
                this.setState(Object.assign(this.state.consentForm, { rejected: this.props.consentData[0].rejected }));
                this.setState(Object.assign(this.state.consentForm, { resend: this.props.consentData[0].resend }));
                this.setState(Object.assign(this.state.consentForm, { token: this.props.consentData[0].token }));
                this.setState(Object.assign(this.state.consentForm, { pdfUrl: this.props.consentData[0].pdfUrl }));
                //this.setState(Object.assign(this.state, { pdfDownloadUrl: process.env.REACT_APP_WEBAPI_URL + '/uploadfile/getpdffile/pdf/' + this.props.consentData[0].pdfUrl }));

                this.setState(Object.assign(this.state.consentForm, { emailCount: this.props.consentData[0].emailCount }));
                this.setState(Object.assign(this.state.consentForm, { authorizationPeriod: this.props.consentData[0].authorizationPeriod }));
                this.setState(Object.assign(this.state.consentForm, { documentId: this.props.consentData[0].documentId }));

                this.setState(Object.assign(this.state.consentForm, { baseUrl: this.props.consentData[0].baseUrl }));
                this.setState(Object.assign(this.state.consentForm, { dqpFirstName: this.props.consentData[0].dqpFirstName }));
                this.setState(Object.assign(this.state.consentForm, { dqpLastName: this.props.consentData[0].dqpLastName }));
                this.setState(Object.assign(this.state.consentForm, { dqpBusinessName: this.props.consentData[0].dqpBusinessName }));
            
            }
            else {
                if (this.props.searchData) {
                    if (this.props.searchData.customerEmail != null || this.props.searchData.customerEmail != '') {
                        this.setState(Object.assign(this.state.consentForm, { customerEmail: this.props.searchData.customerEmail }));
                    }
                    this.setState(Object.assign(this.state.consentForm, { workApprovalNo: this.props.searchData.workApprovalNo }));
                    this.setState(Object.assign(this.state.consentForm, { firstName: this.props.searchData.holderName }));
                    this.setState(Object.assign(this.state.consentForm, { lastName: this.props.searchData.authorityHolderLastName }));
                    this.setState(Object.assign(this.state.consentForm, { organisation: this.props.searchData.organisation }));
                }
            }
        }

    }

    config = {
        baseURL: process.env.REACT_APP_WEBAPI_URL,
        headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authentication.getToken() }
    };
    

    setApprovalCriteria = (e) => {
        //debugger
        if (e.target.value == "manual") {
            this.setState(Object.assign(this.state.consentForm, { isAutomatic: false }));
            this.setState(Object.assign(this.state.consentForm, { holderName: '' }));
            this.setState(Object.assign(this.state.consentForm, { authorityHolderLastName: '' }));
        }
        else if (e.target.value == "automatic") {
            this.setState(Object.assign(this.state.consentForm, { isAutomatic: true }));
            this.setState(Object.assign(this.state.consentForm, { organisation: '' }));
        }
        else { }
    }

    onChangeCustomerEmail = (e) => {
        this.setState(Object.assign(this.state.consentForm, { customerEmail: e.target.value }));
    }

    onChangePasscode = (e) => {
        this.setState(Object.assign(this.state.consentForm, { passcode: e.target.value }));
    }

    validateEmail(){
        this.setState(Object.assign(this.state.consentFormError, { customerEmail: "" }));
        if (this.state.consentForm.customerEmail == null || this.state.consentForm.customerEmail == "") {
            this.setState(Object.assign(this.state.consentFormError, { customerEmail: "Please enter email" }));
            return false;
        } else if (!this.state.consentForm.customerEmail.match(commonConstants.regex.EMAIL)) {
            this.setState(Object.assign(this.state.consentFormError, { customerEmail: "Please enter a valid email" }));
            return false;
        }
        return true;
    }

    

    sendManualRequest = () => {
        const currentContext = this;
        var host = window.location.origin;
        var consentData = {
            consentDetail: {
                customerEmail: this.state.consentForm.customerEmail,
                workApprovalNo: this.state.consentForm.workApprovalNo,
                duration: this.state.consentForm.duration,
                formType: this.state.consentForm.formType,
                approvalType: 2,
                firstName: this.state.consentForm.firstName,
                lastName: this.state.consentForm.lastName,
                organisation: this.state.consentForm.organisation,
                id: this.state.consentForm.id,
                emailCount: this.state.consentForm.emailCount,
                baseUrl: host,
                dqpFirstName: this.state.consentForm.dqpFirstName,
                dqpLastName: this.state.consentForm.dqpLastName,
                dqpBusinessName: this.state.consentForm.dqpBusinessName
            }
        };
        currentContext.setState(Object.assign(currentContext.state, { pdfCreateDisable: true , isPdfCreating: true }));
        uiHelpers.showProgressOnTop('PDF generating and sending...');
        axios.post('/meterregistration/sendmanualrequest', JSON.stringify(consentData), this.config).then(function (response) {
            currentContext.setState(Object.assign(currentContext.state.consentForm, { isPdfCreating: false, id: response.data.data.id, pdfUrl: response.data.data.filename }));
            //currentContext.setState(Object.assign(currentContext.state, { pdfDownloadUrl: process.env.REACT_APP_WEBAPI_URL + '/uploadfile/getpdffile/pdf/' + response.data.data.filename }));
            uiHelpers.showSuccessMsgTop("Generated pdf has been sent to your email. Check your email to download the consent form.")
        }).catch(function (error) {
            uiHelpers.showErrorTop("Error occured when generating PDF...")
            currentContext.setState(Object.assign(currentContext.state, { pdfCreateDisable: false , isPdfCreating: false }));
        });

    }

    validateManualConsent = () => {
        const currentContext = this;
        var consentData = {
            consentDetail: {
                customerEmail: this.state.consentForm.customerEmail,
                workApprovalNo: this.state.consentForm.workApprovalNo,
                duration: this.state.consentForm.duration,
                formType: this.state.consentForm.formType,
                approvalType: 2,
                firstName: this.state.consentForm.firstName,
                lastName: this.state.consentForm.lastName,
                organisation: this.state.consentForm.organisation,
                id: this.state.consentForm.id,
                passcode: this.state.consentForm.passcode,
                documentId: this.state.documents[0].id,
                baseUrl: this.state.consentForm.baseUrl,
                dqpFirstName: this.state.consentForm.dqpFirstName,
                dqpLastName: this.state.consentForm.dqpLastName,
                dqpBusinessName: this.state.consentForm.dqpBusinessName
            }
        };
        currentContext.setState(Object.assign(currentContext.state.consentForm, { isLoading: true }));
        uiHelpers.showProgressOnTop('Submiting...');
        currentContext.setState(Object.assign(currentContext.state.consentFormError, { passcode: null }));
        axios.post('/meterregistration/validatemanualconsent', JSON.stringify(consentData), this.config).then(function (response) {

            if(response.data.passcodeMissMatch) {
                currentContext.setState(Object.assign(currentContext.state.consentFormError, { passcode: 'Please check your passcode' }));
                uiHelpers.hideProgressOnTop();
                currentContext.setState(Object.assign(currentContext.state.consentForm, { isLoading: false }));
            } else {
                currentContext.setState(Object.assign(currentContext.state.consentForm, { id: response.data.data.id }));
                uiHelpers.showSuccessMsgTop("Customer authority form has been approved. Please search again to submit the form.");
                
                setTimeout(()=>{
                    window.location.reload();
                }, 3000);
            }
        }).catch(function (error) {
            console.log(error)
            uiHelpers.showErrorTop('Failed to submit');
            currentContext.setState(Object.assign(currentContext.state.consentForm, { isLoading: false }));
        });

    }

    sendEmailBase(isResent){
        if(!this.validateEmail()) return;

        var host = window.location.origin;
        const currentContext = this;
        var consentData = {
            consentDetail: {
                customerEmail: this.state.consentForm.customerEmail,
                workApprovalNo: this.state.consentForm.workApprovalNo,
                //duration: this.state.consentForm.duration,
                duration: 365,
                formType: this.state.consentForm.formType,
                approvalType: 1,
                firstName: this.state.consentForm.firstName,
                lastName: this.state.consentForm.lastName,
                organisation: this.state.consentForm.organisation,
                id: isResent?this.state.consentForm.id:null,
                pdfUrl: isResent?this.state.consentForm.pdfUrl:null,
                passcode: this.state.consentForm.passcode,
                documentId: this.state.consentForm.documentId,
                emailCount: this.state.consentForm.emailCount,
                baseUrl: host,
                dqpFirstName: this.state.consentForm.dqpFirstName,
                dqpLastName: this.state.consentForm.dqpLastName,
                dqpBusinessName: this.state.consentForm.dqpBusinessName
            }
        };
        currentContext.setState(Object.assign(currentContext.state.consentForm, { isLoading: true }));
        uiHelpers.showProgressOnTop("Sending request...");

        
        axios.post('/meterregistration/sendrequest', JSON.stringify(consentData), this.config).then(function (response) {
            currentContext.setState(Object.assign(currentContext.state.consentForm, { isLoading: false }));
            currentContext.setState(Object.assign(currentContext.state.consentForm, { id: response.data.data.id }));
            currentContext.setState(Object.assign(currentContext.state.consentForm, { emailCount: response.data.data.emailCount }));
            uiHelpers.showSuccessMsgTop("Request sent")
        }).catch(function (error) {
            uiHelpers.showErrorTop('Error occured while sendilg email')
        }).then(function(){
            currentContext.setState(Object.assign(currentContext.state.consentForm, { isLoading: false }));
        });
        
    }

    sendEmail = () => {
        this.sendEmailBase(false);
    }

    resendEmail = () => {
        this.sendEmailBase(true);
    }

    uploadedFileChange =(files) =>{
        var rowData = [];
        var documentType = 1;
        for (var i = 0; i < files.length; i++) {
            var row = {};
            row.id = files[i].id;
            row.documentType = documentType;
            row.description = files[i].description;
            row.fileOriginalName = files[i].fileOriginalName;
            row.status = "new"
            rowData.push(row);
        }
        this.setState(Object.assign(this.state, { documents: rowData }));
    }

    #fileUploaderDisplayListUpdate;

    updateDisplayFileList = ()=>{
        if(!this.#fileUploaderDisplayListUpdate) return;
        const newList = [];
        if(this.state.documents && this.state.documents.length!=0) {
           for(var i = 0; i< this.state.documents.length; i++){
                newList.push({
                    id:this.state.documents[i].id,
                    description:this.state.documents[i].description,
                    fileOriginalName:this.state.documents[i].fileOriginalName,
                    fileGUID:this.state.documents[i].fileGUID
                });
           }
        }
        this.#fileUploaderDisplayListUpdate(newList);
    }

    fileUploaderFilesUploaded=(files)=>{
        const documentType = 1;
        for(var i = 0; i< files.length; i++){
           this.state.documents.push({
              id : files[i].id,
              documentType : documentType,
              description : files[i].description,
              fileOriginalName : files[i].fileOriginalName,
              fileGUID : files[i].fileGUID,
              status : "new"
           });
        }
        this.updateDisplayFileList();
    }

    fileUploaderFileDeleted=(file)=>{
        if(!this.state.documents) return;
  
        console.log(this.state.documents);
        for(var i = 0; i< this.state.documents.length; i++){
           if(this.state.documents[i].id==file.id){
              if (this.state.documents[i].status == "new")
                 this.state.documents[i].status = "newDeleted";
              else if (this.state.documents[i].status == "existing")
                 this.state.documents[i].status = "existingDeleted";
           }
        }
        this.updateDisplayFileList();
     }

    render() {
        return <>
            <form  onSubmit={this.onSearchSubmit}  >
                <h3 className='mb-3' >Customer consent approval</h3>

                    {(this.props.consentData.length > 0 && this.state.consentForm.id > 0 && !this.state.consentForm.rejected && this.props.consentData[0].approvalType == 1 && !this.state.consentExpired) && 
                        <p className='text-success fw-bold'>Email request has already been sent to {this.props.consentData[0].customerEmail}.</p>
                    }

                    {(this.props.consentData.length > 0 && this.state.consentForm.id > 0 && !this.state.consentForm.rejected && this.props.consentData[0].approvalType == 2 && this.props.consentData[0].pdfUrl != "" && !this.state.consentExpired) && 
                        <p className='text-success fw-bold'>Manual request has already been created for {this.props.consentData[0].workApprovalNo}.</p>
                    }

                    {(this.props.consentData.length > 0 && this.state.consentForm.id > 0 && this.state.consentForm.rejected) && 
                        <p className='text-danger fw-bold'>The request has been rejected for the Work Approval No {this.props.consentData[0].workApprovalNo}. please resend the request.</p>
                    }
                        
                    {(this.props.consentData.length > 0 && this.state.consentForm.id > 0 && this.state.consentExpired) && 
                        <p className='text-danger fw-bold'>The authorisation period for this Work Approval {this.props.consentData[0].workApprovalNo} has expired. Please resend the request.</p>
                    }

                    <div className='row'>
                        <div className='col-sm-6'>
                            <InputTextWithFloatingLabel 
                                disabled={true}
                                label="Work approval number"
                                id="approval_input_consent"
                                value={this.state.consentForm.workApprovalNo}
                                className="mb-3"
                            />

                            <div onChange={this.setApprovalCriteria.bind(this)} className='mb-3'>
                                <div class="form-check">
                                    <input class="form-check-input" disabled={this.state.consentForm.isLoading} type="radio" name="searchCriteria" id="approval-criteria-auto" value="automatic" checked={this.state.consentForm.isAutomatic} />
                                    <label class="form-check-label" for="approval-criteria-auto">
                                        Send request via email for customer authorisation
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" disabled={this.state.consentForm.isLoading} type="radio" name="searchCriteria" id="approval-criteria-manual" value="manual"/>
                                    <label class="form-check-label" for="approval-criteria-manual">
                                        Manually print and give to customer to complete
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    {this.state.consentForm.isAutomatic && <>
                        <div className='row'>
                            <div className='col-sm-6'>
                                <InputTextWithFloatingLabel 
                                    label={<>Approval holder email <span className='text-danger'>*</span></>}
                                    id="approval_holder_email"
                                    className="mb-3"
                                    onChange={this.onChangeCustomerEmail} 
                                    value={this.state.consentForm.customerEmail}
                                    asterisk={true}
                                    error={this.state.consentFormError.customerEmail}
                                    disabled={this.state.consentForm.isLoading}
                                />

                                {(this.state.consentForm.id == 0) && (
                                    <p>
                                        <button type="button" onClick={this.sendEmail} disabled={this.state.consentForm.isLoading || this.state.consentForm.id > 0} class="btn btn-primary">
                                            Send Request
                                        </button>
                                    </p>
                                )}
                                {(this.state.consentForm.id > 0) && <>
                                    <p>
                                        <button type="button" onClick={this.resendEmail} disabled={this.state.consentForm.isLoading || this.state.consentForm.emailCount > 5} class="btn btn-warning">
                                            Resend Request
                                        </button>
                                    </p>
                                    
                                    {(this.state.consentForm.id > 0 && this.state.consentForm.emailCount > 5) && (
                                        <p>
                                            <span className='text-danger' >Max. limit reached</span>
                                        </p>
                                    )}
                                </>}
                                
                                {this.state.consentForm.isSendError && <p className='text-danger'>Error while sending email. Please resend the request.</p>}

                            </div>
                        </div>
                    </>}

                    {!this.state.consentForm.isAutomatic && <>
                        <div className="row">
                            <div className='col-sm-6'>
                                <p>
                                    <button type="button" disabled={this.state.isPdfCreating || this.state.pdfCreateDisable || this.state.consentForm.isLoading} class="btn btn-success" onClick={this.sendManualRequest} >Generate pdf</button>
                                </p>
                                {(this.state.consentForm.id == 0 && this.state.consentForm.pdfUrl != "") && <p className='fs-5 text-secondary'>
                                    complete authority form and search again to upload.
                                </p>}
                            </div>
                        </div>

                        {(this.state.consentForm.id > 0 && this.state.consentForm.pdfUrl != "" && this.state.consentForm.pdfUrl != null) && <>
                            <div className='row'>
                                <div className='col-sm-6'>
                                    <p className='text-center '>OR</p>
                                    
                                    <FileUploader
                                        fileInputLabel={<strong>Upload copy of signed form  <span className='text-danger'>*</span> :</strong>}
                                        fileTableHeader={<p><strong>Your uploaded copy of signed form</strong></p>}
                                        warning={<>Max File Upload Size : 10MB</>}
                                        allowFileTypes={[
                                            commonConstants.fileTypes.GIF,
                                            commonConstants.fileTypes.JPEG,
                                            commonConstants.fileTypes.PDF,
                                            commonConstants.fileTypes.PNG,
                                        ]}
                                        fileTypeErrorMsg="File type is not valid, please only uplaod pdf or image files (jpeg, gif, png)"
                                        maxFileSizeMB={10}
                                        singleFileMode={true}
                                        exceedFileSizeErrorMsg = "Your file is too big"
                                        disabled={this.state.consentForm.isLoading}

                                        provideDisplayDocumentsUpdator={(updateFunction)=>{this.#fileUploaderDisplayListUpdate = updateFunction;}}
                                        askForDocumentUpdate={this.updateDisplayFileList}
                                        onFilesUploaded={this.fileUploaderFilesUploaded}
                                        onFileDeleted={this.fileUploaderFileDeleted}
                                        
                                    />

                                    <div className='row mb-3'>
                                        <div className='col-sm-6'>
                                            <InputTextWithFloatingLabel 
                                                label={<>Passcode <span className='text-dangger'>*</span></>}
                                                id="approval_holder_email"
                                                onChange={this.onChangePasscode} 
                                                disabled={this.state.consentForm.isLoading}
                                                value={this.state.consentForm.passcode}
                                                error={this.state.consentFormError.passcode}
                                            />
                                        </div>
                                    </div>
                                    <p>
                                        <button type="button" onClick={this.validateManualConsent} 
                                            disabled={this.state.documents.length == 0 || this.state.consentForm.id == 0 || this.state.consentForm.isLoading} class="btn btn-primary">Submit</button>
                                    </p>
                                </div>
                            </div>
                        </>}
                    </>}
                </form>


        </>
    }
}

export default CustomerApprovalForm;
