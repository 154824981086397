import React from 'react';


class ReApplyConsentNotice extends React.Component {



  render() {
    return <div className='alert alert-warning'>
      The customer consent for the approval {this.props.approvalNumber} <strong className='text-danger'>has expired</strong>. Please click <a href={"request-consent?approvalNumber=" + this.props.approvalNumber}>here</a> to request a new customer consent.
    </div>;
  }
}


export default ReApplyConsentNotice;

