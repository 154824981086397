import { STORAGE_MGMT_VALIDATE_URL, REF_DATA_BY_MODULE_URL, STORAGE_MGMT_CHECK_SERIAL } from '../../../constants/MeterFormConstants';
import axios from 'axios';
import dispatch from 'redux';
import Authentication from '../../../helpers/Authentication';
import uiHelpers from '../../../helpers/uiHelpers';

const authentication = new Authentication();
const LOAD = '[Certificate] LOAD'
const SAVE = '[Certificate] SAVE'
const SEACRH_METER_DESIGN = '[Certificate] SEARCH'
const UPDATE_METER_DESIGN = '[Certificate] UPDATE'
const CHECK_METER_SERIALNUMBER = '[Certificate] SERIALNUMBER'
const POST_SAVE = '[Certificate] POST_SAVE'
let initial = {
  loaded: false,
  searchForm: {
    workApprovalNo: "",
    holderName: "",
    isMatched: false,
    authorityHolderLastName: "",
    organisation: "",
    isCustomerConsent: false,
    customerEmail: ''
  },
  clickNext: 0,
  clickSaveDraft: 1,
  authorityHolderDetails: {
    id: 0,
    holderType: '',
    salutationId: 0,
    salutationText: '',
    authorityHolderName: '',
    corporationNameOrAcn: '',
    postalAddress: '',
    phone: '',
    alternatePhone: '',
    emailAddress: '',
    contactPersonName: '',
    contactPersonPhone: '',
    contactPersonEmailAddress: '',
    firstName: '',
    lastName: ''
  },
  floodplainWorkDetails: {
    id: 0,
    propertyName: '',
    lotDp: '',
    waterAccessLicenceNo: '',
    approvedByCustomer: 0,
    //storageIdentifyingNumber: '',
    ratingTable: {
      id: 0,
      roiNumber: '',
      ratingTableName: '',
      activityNumber: '',
      ofsReference: '',
      storageWorkApprovalNumber: ''
    }
  },
  storageManagementValidationData: {
    id: 0,
    certFor: undefined,
    certExistingFor: undefined,
    lastValidationDate: undefined,
    references: undefined,
    reinstallationDate: undefined,
    readingBeforeMaintenance: undefined,
    readingBeforeMaintenanceDate: undefined,
    readingAfterMaintenance: undefined,
    readingAfterMaintenanceDate: undefined,
    storageMeterType: undefined,
    mountingMethod: undefined,
    meteringSystemType: undefined,
    meterSerialNumber: undefined,
    meterProtocol: undefined,
    sealNo: '',
    refNum: undefined,
    lidType: undefined,
    lidModel: undefined,
    lidSerialNumber: undefined,
    lidVersion: undefined,
    easting: undefined,
    northing: undefined,
    mga: undefined,
    levelSensor: undefined,
    waterLevel: undefined,
    powerSource: undefined,
    solarVoltage: undefined,
    network: '',
    comments: undefined,
    formStatus: 1,
    fileType: 0, //not to be added in DB
    storageManagementRegulation: {
      id: undefined,
      compliance1: undefined,
      compliance2: undefined,
      compliance3: undefined,
      compliance4: undefined,
      complianceAuthority: ''
    }
  },
  mdata: [{}],
  documents: []
}

const smvReducer = (state = initial, action) => {//rename to same as declared in index.jsx doChange
  console.log('---------smvReducer_action--------' + JSON.stringify(action, null, 2));

  switch (action.type) {
    case LOAD:

      return {
        ...state,
        loaded: true,
        mdata: action.data.data,
      }

    case SAVE: return state;

    case POST_SAVE:
      return {
        ...state,
        id: action.data.benchmarkDetailsId
      }

    case SEACRH_METER_DESIGN:
      console.log("in dispatch SEACRH_METER_DESIGN. data is" + JSON.stringify(action.data.payload, null, 2));
      return {
        ...state,
        loaded: action.data.payload.loaded,
        mdata: action.data.payload.mdata,
        authorityHolderDetails: action.data.payload.authorityHolderDetails,
        documents: action.data.payload.documents,
        storageManagementValidationData: action.data.payload.storageManagementValidationData,
        floodplainWorkDetails: action.data.payload.floodplainWorkDetails,
        ratingTableContents: action.data.payload.ratingTableContents
      }

    case UPDATE_METER_DESIGN:
      return state;
    case CHECK_METER_SERIALNUMBER:
      return state;

    default:
      return state;
  }
  return state;
}

const CERT_MODULE = 'StorageManagementValidation';
export const load = (payload) => {
  const options = {
    url: process.env.REACT_APP_WEBAPI_URL + REF_DATA_BY_MODULE_URL + CERT_MODULE,

    method: 'GET',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Accept': 'application/json',
      'Content-Type': 'application/json;charset=UTF-8',
      'Authorization': 'Bearer ' + authentication.getToken()
    },
    data: {
      payload
    }
  };

  return async (dispatch) => {
    console.log("url  in sevCert/Reducers" + JSON.stringify(options));
    const response = await axios(options);
    const data = await response.data;
    console.log("printing in sevCert/Reducers");
    console.log(JSON.stringify(data));
    dispatch({ type: LOAD, data });
  }
}

/**
 * Simulates data loaded into this reducer from somewhere
 */
export const checkSerialNumber = (id, serialNumber, isLid = "true") => {

  const options = {
    url: process.env.REACT_APP_WEBAPI_URL + STORAGE_MGMT_CHECK_SERIAL + id + '/' + serialNumber + '/' + isLid,
    method: 'GET',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Accept': 'application/json',
      'Content-Type': 'application/json;charset=UTF-8',
      'Authorization': 'Bearer ' + authentication.getToken()
    }
  };
  return async (dispatch) => {
    console.log("checkSerialNumber");
    try {
      const response = await axios(options);
      const data = await response.data;
      console.log("checkSerialNumber" + JSON.stringify(data, null, 2));

      if (data.success)
        alert(data.message);

    }
    catch (error) {

    }
    dispatch({ type: CHECK_METER_SERIALNUMBER });

  }
}


export const save = (payload, callback) => {

  console.log("data sent to save" + JSON.stringify(payload, null, 2));
  debugger;
  const options = {
    url: process.env.REACT_APP_WEBAPI_URL + STORAGE_MGMT_VALIDATE_URL,
    method: 'POST',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Accept': 'application/json',
      'Content-Type': 'application/json;charset=UTF-8',
      'Authorization': 'Bearer ' + authentication.getToken()
    },
    data: {
      payload
    }
  };
  return async (dispatch) => {
    try {
      const response = await axios(options);
      const data = await response.data;
      console.log("save response" + JSON.stringify(data));
      debugger;
      if (data.success) {
        if (callback) {
          callback(data.id);
        } else {
          document.location.href = "/StorageManagementValidation?id=" + data.id;
        }
      } else {
        uiHelpers.showErrorTop("Error when saving data", true, false)
      }
      dispatch({ type: SAVE });

    }
    catch (error) {
      uiHelpers.showErrorTop("Error when saving data", true, false)
    }

  }
}

export const findById = (id) => {

  const options = {
    url: process.env.REACT_APP_WEBAPI_URL + STORAGE_MGMT_VALIDATE_URL + id,
    method: 'GET',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Accept': 'application/json',
      'Content-Type': 'application/json;charset=UTF-8',
      'Authorization': 'Bearer ' + authentication.getToken()
    }
  };
  return async (dispatch) => {
    try {
      const response = await axios(options);
      const data = await response.data;
      debugger;
      console.log("printing values");
      console.log(JSON.stringify(data, null, 2));
      if (!data.success) {
        if (data.message != undefined && data.message != "")
          alert(data.message);
        else if(data.noPermission)
          alert('Access denied.');
        else
          alert("Error in loading Data");
      }
      else {
        console.log("data return from findById " + JSON.stringify(data, null, 2));
        dispatch({ type: SEACRH_METER_DESIGN, data });
      }
      debugger;
    }
    catch (error) {

      if (!error.success) {
        if (error.message != undefined && error.message != "") {
          alert(error.message);
        }
      }
    }
  }
}
export default smvReducer