import axios from 'axios';
import Authentication from '../../../helpers/Authentication';
import uiHelpers from '../../../helpers/uiHelpers';

const authentication = new Authentication();
const LOAD = '[/AccuracyExistingMeteringValidation] LOAD'
const SAVE = '[/AccuracyExistingMeteringValidation] SAVE'
const SEACRH_METER_ACCURACY = '[/AccuracyExistingMeteringValidation] SEARCH'
const UPDATE_METER_ACCURACY = '[/AccuracyExistingMeteringValidation] UPDATE'
const UPLOAD = '[/AccuracyExistingMeteringValidation] UPLOAD'
const UPDATEDOC = '[/AccuracyExistingMeteringValidation] UPDATEDOC'
const UPDATEDELETEDOC = '[/AccuracyExistingMeteringValidation] UPDATEDELETEDOC'
const CHANGEFORMSTATE = '[/AccuracyExistingMeteringValidation] CHANGEFORMSTATE'

let initial = {
  loaded: false,
  searchForm: {
    workApprovalNo: "",
    holderName: "",
    isMatched: false,
    authorityHolderLastName: "",
    organisation: "",
    isCustomerConsent: false,
    customerEmail: ''
  },
  consentData: [],
  clickNext: 0,
  clickSaveDraft: 1,
  authorityHolderDetails: {
    id: 0,
    salutationId: 0,
    salutationText: '',
    authorityHolderName: '',
    corporationNameOrAcn: '',
    postalAddress: '',
    phone: '',
    alternatePhone: '',
    emailAddress: '',
    contactPersonName: '',
    contactPersonPhone: '',
    contactPersonEmailAddress: '',
    firstName: '',
    lastName: ''

  },
  approvedWorkDetails: {
    id: 0,
    meterCircumstancesOptions: '',
    propertyName: '',
    lotDp: '',
    waterSupplyWorkApprovalNo: '',
    waterAccessLicenceNo: '',
    esid: '',
    waterSourceId: 0,
    waterSourceName: '',
    waterSharingPlanName: '',
    waterSourceTypeId: 0,
    waterSourceType: '',
    meterId: '', //added by sk06052020 persist in DB R2
    meterNumber: '', //added by sk06052020 persist in DB R2
    meterNumberGUID: '', //added by sk06052020 persist in DB R2
    approvedByCustomer: 0,
    worksId: undefined,
    noOfWorks: undefined
  },
  meteringEquipmentInformation: {

    id: 0,//number
    meterFormType: 3,//number
    meterCircumstancesOptions: '',//text
    dateOfLastValidation: '',//date
    meteringSystemTypeId: 0,//number
    meteringSystemType: [],//text
    meterManufacturer: '',//text
    meterTypeModel: '',//text
    meterSerialNo: '',//text
    meterSize: '', //number
    pipeType: '',//text
    pipeSize: '',//number
    pipeConnectionType: '',//decimal
    pipeLengthUpstream: '',//decimal
    pipeLengthDownstream: '',//decimal
    longitude: 154,//decimal
    latitude: -32,//decimal
    supplyTypeId: 0,//number
    supplyType: '',//text,
    sealNoIds: '',//text
    sealNo: [],//text
    dataCollectorTypeId: 0,//number
    dataCollectorType: '',//text
    dataCollectorModelNo: '',//text
    dataCollectorSerialNo: '',//text
    dataCollectorPowerSourceId: 0,//number
    dataCollectorPowerSource: '',//text
    dataCollectorSolarPanelWattage: '',//text
    //:'',//text
    channelType: '',//text
    channelWidth: '',//text
    meterDistanceFromSource: '',//number
    channelLengthUpstream: '',//number
    channelLlengthDownstream: '',//number
    maxMeasuredDischarge: '',//decimal
    minMeasuredDischarge: '',//decimal
    maxMeasuredVelocity: '',//decimal
    minMeasuredVelocity: '',//decimal
    maxGaugeHeight: '',//decimal
    minGaugeHeight: '',//decimal
    maxError: '',//decimal
    minError: '',//decimal
    noOfMeasurementsTaken: '',//nummber
    accuracyMethod: '',//text
    accuracyConclusionOpenChannel: '',//text
    accuracyMaxErrorMeteringEquipment: '',//number
    accuracyOwnMeteringEquipment: '',//number
    dateValidation: '',//date
    formStatus: 1,//number
    date: '',//date
    meterTypeModelId: 0,
    processUsedToCheckDesign: '',
    designingOwnEquipment: '',
    meterDescription: ''

  },
  mdata: [{}],
  documents: [],
  meterSupportingDocuments: { documentType2: '', documentType3: '' },
  esidExternalList: [], //added by sk06052020 retrieved from external System R2
  meterNumberExternalList: [], //added by sk06052020 retrieved from external System R2,
  waterAccessLicenceNoExternalList: [] //added by sk06052020 retrieved from external System R2,

}

const reducerMeterAccuracy = (state = initial, action) => {
  //console.log('state'+JSON.stringify('1'));
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loaded: true,
        mdata: action.data.data
      }
    case SAVE:
      return state;

    case SEACRH_METER_ACCURACY:
      return {
        ...state,
        loaded: action.data.payload.loaded,
        mdata: action.data.payload.mdata,
        authorityHolderDetails: action.data.payload.authorityHolderDetails,
        approvedWorkDetails: action.data.payload.approvedWorkDetails,
        meteringEquipmentInformation: action.data.payload.meteringEquipmentInformation,
        documents: action.data.payload.documents,
        consentValid: action.data.consentValid
      }
    case UPDATE_METER_ACCURACY:
      return state;
    case UPLOAD:
      return state;
    case UPDATEDOC:
      var prevDocuments = JSON.parse(JSON.stringify(state.documents));
      prevDocuments = prevDocuments.concat(action.payload);
      return {
        ...state,
        documents: prevDocuments
      }
    case UPDATEDELETEDOC:
      var array = JSON.parse(JSON.stringify(state.documents))
      array[action.payload.index].status = action.payload.status;
      return {
        ...state,
        documents: array
      }
    case CHANGEFORMSTATE:
      var array = JSON.parse(JSON.stringify(state.meteringEquipmentInformation))
      array.formStatus = action.val
      return {
        ...state,
        meteringEquipmentInformation: array
      }
    default:
      return state;
  }

  return state;
}

/**
 * Simulates data loaded into this reducer from somewhere
 */
export const load = () => {

  const options = {
    url: process.env.REACT_APP_WEBAPI_URL + process.env.REACT_APP_METER_ACCURACY_CERT_GETALLDATA,
    method: 'GET',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Accept': 'application/json',
      'Content-Type': 'application/json;charset=UTF-8',
      'Authorization': 'Bearer ' + authentication.getToken()
    }
  };
  return async (dispatch) => {
    const response = await axios(options);
    const data = await response.data;

    dispatch({ type: LOAD, data });
  }
}

export const save = (payload, page, callback) => {
  console.log(payload);
  const options = {
    url: process.env.REACT_APP_WEBAPI_URL + process.env.REACT_APP_METER_ACCURACY_CERT_POSTDATA,
    method: 'POST',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Accept': 'application/json',
      'Content-Type': 'application/json;charset=UTF-8',
      'Authorization': 'Bearer ' + authentication.getToken()
    },
    data: {
      payload
    }
  };
  return async (dispatch) => {
    try {
      const response = await axios(options);
      const data = await response.data;
      //console.log(JSON.stringify(data));
      if (data.success) {
        if (callback) {
          callback(data.meterDesignId, page);
        }
      } else {
        uiHelpers.showErrorTop("Error in saving Data...", true, false);
      }
      dispatch({ type: SAVE });
    } catch (error) {
      uiHelpers.showErrorTop("Errors in saving Data", true, false);
    }


  }
}

export const uploadFiles = (payload) => {
  console.log(payload);
  const options = {
    url: process.env.REACT_APP_WEBAPI_URL + process.env.REACT_APP_METER_ACCURACY_CERT_UPLOADMULTIPLE,
    method: 'POST',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Accept': 'application/json',
      'Content-Type': 'multipart/form-data;boundary=' + payload._boundary,
      'Authorization': 'Bearer ' + authentication.getToken()
    },
    data: {
      payload
    }
  };
  return async (dispatch) => {
    const response = await axios(options);
    //alert(response);
    const data = await response.data;
    dispatch({ type: UPLOAD });
  }
}


export const findById = (id) => {
  //console.log(id);
  const options = {
    url: process.env.REACT_APP_WEBAPI_URL + process.env.REACT_APP_METER_ACCURACY_CERT_URI + id,
    method: 'GET',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Accept': 'application/json',
      'Content-Type': 'application/json;charset=UTF-8',
      'Authorization': 'Bearer ' + authentication.getToken()
    }
  };
  return async (dispatch) => {
    try {
      const response = await axios(options);
      const data = await response.data;

      if (!data.success) {
        if (data.message != undefined && data.message != "")
          uiHelpers.showErrorTop(data.message, true, false);
        else
          uiHelpers.showErrorTop("Error in loading Data", true, false);
      } else {
        console.log(JSON.stringify(data));
        dispatch({ type: SEACRH_METER_ACCURACY, data });
      }
    } catch (error) {
      if (!error.success) {
        if (error.message != undefined && error.message != "") {
          uiHelpers.showErrorTop(error.message, true, false);
        }
      }
    }
  }
}

export const update = (payload, page, callback) => {
  console.log(payload);
  const options = {
    url: process.env.REACT_APP_WEBAPI_URL + process.env.REACT_APP_METER_ACCURACY_CERT_UPDATEDATA,
    method: 'POST',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Accept': 'application/json',
      'Content-Type': 'application/json;charset=UTF-8',
      'Authorization': 'Bearer ' + authentication.getToken()
    },
    data: {
      payload
    }
  };
  return async (dispatch) => {
    try {
      const response = await axios(options);
      const data = await response.data;
      console.log(JSON.stringify(data));
      if (data.success) {
        if (callback) {
          callback(data.meterDesignId, page);
        } else {
          document.location.href = "/AccuracyExistingMeteringValidation?id=" + data.meterDesignId;
        }
      }
      else
        uiHelpers.showErrorTop("Error when saving data", true, false)

      console.log(JSON.stringify(data));
      dispatch({ type: UPDATE_METER_ACCURACY, data });
    } catch (error) {
      uiHelpers.showErrorTop("Error when saving data", true, false)
    }
  }
}

export const updateDocuments = (payload) => {
  console.log("Document Object Updated" + JSON.stringify(payload))
  return (dispatch) => {
    dispatch({ type: UPDATEDOC, payload });
  }
}

export const updateDeleteDocuments = (payload) => {
  console.log("Document Object Updated" + JSON.stringify(payload))
  return (dispatch) => {
    dispatch({ type: UPDATEDELETEDOC, payload });
  }
}
export const changeFormStatus = (payload, val) => {
  console.log("Final Save" + JSON.stringify(val))
  return (dispatch) => {
    dispatch({ type: CHANGEFORMSTATE, val });
    return update(payload);
  }
}

export default reducerMeterAccuracy