import React from 'react';
import { Field, reduxForm, formValueSelector, change, clearSubmitErrors, untouch, stopSubmit, SubmissionError } from 'redux-form';
import { connect } from 'react-redux'
import 'react-redux';
import 'redux';

import { saveDraftAction, clickNextAction } from "../ahdValidation/AHDFormValidations"
import {  InputTextForRedux }  from '../../tools/BootstrapControls'

let DQPInfoSection = props => {

 const {
  handleSubmit, masterDataValues, ahdData
 } = props; 

 const disableFields =  true;

  
  if(masterDataValues.userProfile) {
    var thisDQP = masterDataValues.userProfile[0];
    thisDQP = {...thisDQP, "fullName": thisDQP.user.firstName + ' ' + thisDQP.user.lastName};
    props.dispatch(change(props.form, 'dqpdata', thisDQP));
  }
  
  return (
    <form id="formA" className="vertical" onSubmit={handleSubmit}>
      <h5 className='mb-3'>Registered surveyor’s/engineer’s details</h5>

      <div className='row mb-3'>
        <div className='col-sm-7'>
          <Field
            name="dqpdata.fullName"
            label={<>Registered surveyors’/engineer’s name</>}
            component={InputTextForRedux}
            props={{ disabled: disableFields }}
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-sm-7'>
          <Field
            name="dqpdata.cmino"
            label={<>Registered surveyor's/engineer’s licence number</>}
            component={InputTextForRedux}
            props={{ disabled: disableFields }}
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-sm-7'>
          <Field
            name="dqpdata.businessName"
            label="Business name"
            component={InputTextForRedux}
            props={{ disabled: disableFields }}
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-sm-7'>
          <Field
            name="dqpdata.abnOrAcn"
            label="ABN or ACN"
            component={InputTextForRedux}
            props={{ disabled: disableFields }}
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-sm-7'>
          <Field
            name="dqpdata.postalAddress.addressLine"
            label="Postal address"
            component={InputTextForRedux}
            props={{ disabled: disableFields }}
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-sm-7'>
          <Field
            name="dqpdata.phoneNumber"
            label="Phone"
            component={InputTextForRedux}
            props={{ disabled: disableFields}}
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-sm-7'>
          <Field
            name="dqpdata.user.emailAddress"
            label="Email address"
            component={InputTextForRedux}
            props={{ disabled: disableFields}}
          />
        </div>
      </div>

      <div className="text-end">
        <button type="button"  className="btn btn-success"
          onClick={() => { clickNextAction(props); }}>
          Next
        </button>
      </div>
    </form>
  )
}

DQPInfoSection = reduxForm({
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate : ()=>{return {}}
})(DQPInfoSection)

DQPInfoSection = connect(
  (state, props) => {
    const selector = formValueSelector(props.form);
    const masterDataValues = selector(state, 'mdata');
    const ahdData = selector(state, 'ahdData');
    
    return {
      masterDataValues,
      form: props.form,
      ahdData
    }
  }
)(DQPInfoSection)

export default DQPInfoSection;
