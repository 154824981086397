import React from 'react';
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import StartCommisionProcess from './components/StartCommisionProcess'
import SiteRegistration from './components/SiteRegistration';
import Validation from './components/Validation';




class Routes  extends React.Component {

    render(){        
        return <Switch>
            <Route exact path="/compliance-process/start" component={StartCommisionProcess} />
            <Route exact path="/compliance-process/:id/site-registration" component={SiteRegistration} />
            <Route exact path="/compliance-process/:id/validation" component={Validation} />
        </Switch>
    }
}

export default Routes;