import React, { Component } from 'react'
import "react-datepicker/dist/react-datepicker.css";
import { Field } from 'redux-form'
import DatePicker from 'react-datepicker';
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select/creatable';
import _find from 'underscore';
import { MESSAGE_REQUIRED } from '../../../constants/MeterFormConstants'
import ReactHtmlParser from 'react-html-parser';

export const renderTextField = (props) => {
  const { input, divClass, label, options, values, autocomplete, mandatory, placeholder, disabled, meta: { touched, error, warning, pristine, invalid } } = props;
  let divClassName = "col_6 column";
  if (divClass != undefined && divClass != null && divClass != "") {
    divClassName = divClass;
  }
  let redClass = ''
  if (touched && error) {
    redClass = 'highlight-redbox';
  }

  let labelMandatorySpan = '';
  if (mandatory) {
    labelMandatorySpan = '*';
  }
  return (
    <div>
      <div className={divClassName}>
        <label>{label} <span style={{ color: 'red' }}>{labelMandatorySpan} </span></label>
        <input class={redClass}  {...input} disabled={disabled} placeholder={placeholder} autocomplete={autocomplete} type="text" />
        {touched && error && (error != MESSAGE_REQUIRED) && <span className="text-danger">{error}</span> || (warning && <span>{warning}</span>)}
      </div>
    </div>)
}

export const renderTextFieldFull = ({ input, label, disabled, meta: { touched, error, warning } }) => {
  let redClass = ''
  if (touched && error) {
    redClass = 'highlight-redbox';
  }
  return (
    <div>
      <input class={redClass} {...input} disabled={disabled} type="text" />
      {touched && error && (error != MESSAGE_REQUIRED) && <span className="text-danger">{error}</span> || (warning && <span>{warning}</span>)}
    </div>
  )
}

export const renderTextArea = ({ textarea, input, disabled, meta: { touched, error, warning } }) => {
  let redClass = ''
  if (touched && error) {
    redClass = 'highlight-redbox';
  }
  return (

    <div>
      <div>
        <span>{textarea}</span>
        <textarea class={redClass} {...input} rows="10" cols="40" disabled={disabled}></textarea>
        {touched && (error != MESSAGE_REQUIRED) && ((error && <span className="text-danger">{error}</span>) || (warning && <span>{warning}</span>))}
      </div>
    </div>
  )
}

export const renderTextAreaIndent = ({ textarea, input, disabled, meta: { touched, error, warning } }) => {//TODO indent should be passed to this comp
  let redClass = ''
  if (touched && error) {
    redClass = 'highlight-redbox';
  }
  return (

    <div>
      <div>
        <span>{textarea}</span>
        <div class="col_12 column">
          <textarea class={redClass} {...input} rows="10" cols="10" disabled={disabled}></textarea>
          {touched && (error != MESSAGE_REQUIRED) && ((error && <span className="text-danger">{error}</span>) || (warning && <span>{warning}</span>))}
        </div>
      </div>
    </div>
  )
}

export const renderCheckboxGroup = (field) => {
  let { label, required, options, disabled, input, divClass, meta: { touched, error, warning }, decorateName } = field;
  let divClassName = "col_6 column";
  if (divClass != undefined && divClass != null && divClass != "") {
    divClassName = divClass;
  }

  if (!decorateName) {
    decorateName = name => name;
  }

  let redClass = ''
  if (touched && error) {
    redClass = 'highlight-redbox';
  }
  let labelMandatorySpan = '';
  if (required) {
    labelMandatorySpan = '*';
  }

  return (
    <div className={divClassName}>
      <label>{label}  <span style={{ color: 'red' }}>{labelMandatorySpan} </span></label>
      {options.map((option, index) => (
        <div class={redClass}>
          <input type="checkbox"
            disabled={disabled}
            value={option.id}
            checked={(input.value != "" && input.value.length > 0 && input.value.indexOf(option.id) !== -1)}
            onChange={(event) => {
              const newValue = [...input.value];
              if (event.target.checked) {
                newValue.push(option.id);
              } else {
                newValue.splice(newValue.indexOf(option.id), 1);
              }

              return input.onChange(newValue);
            }} />
          &nbsp;{decorateName(option.name)}

        </div>

      ))
      }
      {touched && error && (error != MESSAGE_REQUIRED) && <span className="text-danger">{error}</span> || (warning && <span>{warning}</span>)}
    </div>

  )

}

const components = {
  DropdownIndicator: null,
};

const createOption = (label: string) => ({
  label,
  value: label,
  id: 0,
  status: "new"
});

export default class MultiSelect extends Component<*, State> {
  state = {
    inputValue: '',
    value: [],
  };
  handleChange = (value: any, actionMeta: any) => {
    console.group('Value Changed');
    console.log(value);
    console.log(`action: ${actionMeta.action}`);
    console.groupEnd();
    if (!value || value.length == 0) {
      value = [];
    }
    this.setState({ value }, () => { this.props.input.onChange(value) });
  };

  handleInputChange = (inputValue: string) => {
    if (inputValue && inputValue.trim !== '') {
      this.setState({ inputValue });
    }
  };
  handleKeyDown = (event: SyntheticKeyboardEvent<HTMLElement>) => {
    let { inputValue, value } = this.state;
    if (!inputValue) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        if (!value || value.length == 0) {
          value = [];
          if (this.props.options.length > 0)
            value = this.props.options.concat(value);
        }
        if (value != null && value.length > 0) {
          if (
            value.filter(function (option) {
              return option.value === inputValue;
            }).length > 0
          ) {
            alert("Duplicate Value: " + inputValue);
          }
        }
        this.setState({
          inputValue: '',
          value: [...value, createOption(inputValue)],
        }, () => {

          this.props.input.onChange(this.state.value);
        });

        event.preventDefault();
    }
  };

  handlePressButton = param => (event) => {
    event.preventDefault();

    let inputValue = param;
    let value = this.state.value;
    if (!inputValue) return;

    if (!value || value.length == 0) {
      value = [];
      if (this.props.options.length > 0)
        value = this.props.options.concat(value);
    }
    if (value != null && value.length > 0) {
      if (
        value.filter(function (option) {
          return option.value === inputValue;
        }).length > 0
      ) {
        alert("Duplicate Value: " + inputValue);
      }
    }
    this.setState({
      inputValue: '',
      value: [...value, createOption(inputValue)],
    }, () => {

      this.props.input.onChange(this.state.value);
    });


  };

  render() {
    let { inputValue, value } = this.state;

    const tagStyle = {
      height: '100px'
    }
    const { input, disabled, options, meta: { touched, error, warning } } = this.props;
    let redClass = ''
    if (touched && error) {
      redClass = 'highlight-redbox';
    }
    if (value && value.length == 0 && options.length)
      value = options.concat(value);

    return (
      <div class='col_12 column'>
        <div class='col_9 column'>
          <div class={redClass}>
            <CreatableSelect
              styles={tagStyle}
              components={components}
              inputValue={inputValue}
              isClearable
              isMulti
              menuIsOpen={false}
              onChange={this.handleChange}
              onInputChange={this.handleInputChange}
              onKeyDown={this.handleKeyDown}
              placeholder="Type Seal Nos and press enter (or click +) to enter multiple entries"
              value={value}
              isDisabled={disabled}
            />
            {touched && error && <span className="text-danger">{error}</span> || (warning && <span>{warning}</span>)}

          </div>
        </div>
        <div class='col_3 column'> <button onClick={this.handlePressButton(inputValue)}>+</button></div>
      </div>
    );
  }
}

export const renderRadioGroup = ({ input, options, disabled, values, handleChange, divClass, meta: { touched, error, warning } }) => {
  let divClasses = 'col_6 column';
  if (divClass != undefined && divClass != null && divClass != "") {
    divClasses = divClass;
  }
  const _onChange = (event) => {
    input.onChange(event);
    if (handleChange) {
      handleChange(event);
    }
  }
  const cssStyle = {
    float: 'left',
    marginRight: '10px',
    textDecoration: 'none',
    fontWeight: 'normal'
  }
  const cssStyleInput = {
    marginRight: '5px',
    textDecoration: 'none',
    fontWeight: 'normal'
  }
  let redClass = ''
  if (touched && error) {
    divClasses = divClasses + ' highlight-redbox';
  }
  return (
    <div className={divClasses} ref={element => {
      if (element) element.style.setProperty('float', 'left', 'important');
    }}>
      {
        options.map(option => (
          <label key={option.id} style={cssStyle}>
            {(values == option.id) && <input style={cssStyleInput} disabled={disabled}
              {...input}
              type="radio"
              value={option.id}
              checked
              key={`${option.name}_${option.id}`}
              onChange={_onChange}
            />
            }
            {(values != option.id) && <input style={cssStyleInput} disabled={disabled}
              {...input}
              type="radio"
              value={option.id}

              key={`${option.name}_${option.id}`}
              onChange={_onChange}
            />
            }
            {ReactHtmlParser(option.name)}
          </label>
        ))}
      <br />
      {touched && error && (error != MESSAGE_REQUIRED) && <span className="text-danger"><br />{error}</span> || (warning && <span>{warning}</span>)}
    </div>
  );
};

export const renderTabulatedRadioGroup = ({ input, options, disabled, values, handleChange, divClass, meta: { touched, error, warning } }) => {
  let divClasses = 'col_6 column';
  if (divClass != undefined && divClass != null && divClass != "") {
    divClasses = divClass;
  }
  const _onChange = (event) => {
    input.onChange(event);
    if (handleChange) {
      handleChange(event);
    }
  }
  const cssStyle = {
    float: 'left',
    marginRight: '10px',
    textDecoration: 'none',
    fontWeight: 'normal'
  }
  const cssStyleInput = {
    marginRight: '5px',
    textDecoration: 'none',
    fontWeight: 'normal'
  }
  let redClass = ''
  if (touched && error) {
    divClasses = divClasses + ' highlight-redbox';
  }
  return (
    <div className={divClasses} ref={element => {
      if (element) element.style.setProperty('float', 'left', 'important');
    }}>
      <table><tbody>
        {
          options.map(option => (
            <tr>
              <td>
                {(values == option.id) && <input style={cssStyleInput} disabled={disabled}
                  {...input}
                  type="radio"
                  value={option.id}
                  checked
                  key={`${option.name}_${option.id}`}
                  onChange={_onChange}
                />
                }

                {(values != option.id) && <input style={cssStyleInput} disabled={disabled}
                  {...input}
                  type="radio"
                  value={option.id}

                  key={`${option.name}_${option.id}`}
                  onChange={_onChange}
                />
                }
              </td>
              <td>
                <label> {option.name}</label>
              </td>
              <td style={{ fontWeight: 'normal' }}>
                {option.description}
              </td>

            </tr>
          )
          )
        }
      </tbody></table>
      <br />
      {touched && error && (error != MESSAGE_REQUIRED) && <span className="text-danger"><br />{error}</span> || (warning && <span>{warning}</span>)}
    </div>
  );
};

export const renderDropdown = (field) => {
  const { input, label, options, mandatory, height, disabled, handleChange, values, meta: { touched, error, warning } } = field;
  let redClass = ''
  if (touched && error) {
    redClass = 'highlight-redbox';
  }
  const selectStyle = {
    height: (height) ? height : '36.74px'
  }

  let labelMandatorySpan = '';
  if (mandatory) {
    labelMandatorySpan = '*';
  }

  return (
    <div className="col_6 column">
      <label>{label} <span style={{ color: 'red' }}>{labelMandatorySpan} </span></label>
      <select class={redClass} {...input} disabled={disabled} style={selectStyle} onChange={value => {
        input.onChange(value); if (handleChange) handleChange(value)
      }} >
        {options.map(({ id, name }) => (
          <option value={id} key={id}>
            {name}
          </option>
        ))}
      </select>
      {touched && error && (error != MESSAGE_REQUIRED) && <span className="text-danger">{error}</span> || (warning && <span>{warning}</span>)}
    </div>
  )
}

export const renderDropdownWithDisplayText = (field) => {
  const { input, label, options, mandatory, height, disabled, handleChange, values, meta: { touched, error, warning } } = field;
  let redClass = ''
  if (touched && error) {
    redClass = 'highlight-redbox';
  }
  const selectStyle = {
    height: (height) ? height : '36.74px'
  }

  let labelMandatorySpan = '';
  if (mandatory) {
    labelMandatorySpan = '*';
  }

  return (
    <div className="col_6 column">
      <label>{label} <span style={{ color: 'red' }}>{labelMandatorySpan} </span></label>
      <select class={redClass} {...input} disabled={disabled} style={selectStyle} onChange={value => {
        input.onChange(value); if (handleChange) handleChange(value)
      }} >
        {options.map(({ id, displayText }) => (
          <option value={id} key={id}>
            {displayText}
          </option>
        ))}
      </select>
      {touched && error && (error != MESSAGE_REQUIRED) && <span className="text-danger">{error}</span> || (warning && <span>{warning}</span>)}
    </div>
  )
}


export const renderEmphasisedDropdown = (field) => {
  const { input, label, options, mandatory, disabled, handleChange, values, meta: { touched, error, warning } } = field;
  let redClass = ''
  if (touched && error) {
    redClass = 'highlight-redbox';
  }
  const selectStyle = {
    height: '36.74px'
  }

  let labelMandatorySpan = '';
  if (mandatory) {
    labelMandatorySpan = '*';
  }

  return (
    <div className="col_6 column">
      <div className="col_6 column text-primary display-3 .bg-primary">{label} <span style={{ color: 'red' }}>{labelMandatorySpan} </span></div>

      <select class={redClass} {...input} disabled={disabled} style={selectStyle} onChange={value => {
        input.onChange(value); if (handleChange) handleChange(value)
      }} >
        {options.map(({ id, name }) => (
          <option value={id} key={id}>
            {name}
          </option>
        ))}
      </select>
      {touched && error && (error != MESSAGE_REQUIRED) && <span className="text-danger">{error}</span> || (warning && <span>{warning}</span>)}
    </div>
  )
}


export const renderDatePicker = (field) => {
  const { input, label, values, disabled, mandatory, onChange, errorNewline, meta: { touched, error, warning }, maxDate } = field
  let redClass = ''
  if (touched && error) {
    redClass = 'highlight-redbox';
  }
  let errorOnNewline = false;
  if (errorNewline) {
    errorOnNewline = true;
  }
  let labelMandatorySpan = '';
  if (mandatory) {
    labelMandatorySpan = '*';
  }

  let maxDateField = new Date() + 10000;
  if (maxDate) {
    maxDateField = maxDate;
  }

  return (
    <div className="col_6 column">
      <label>{label} <span style={{ color: 'red' }}>{labelMandatorySpan} </span></label>
      {disabled && <DatePicker  {...input} disabled selected={values} dateFormat="dd/MM/yyyy" onChange={value => {
        field.handleChange(value);
        console.log('rend' + value);
        input.onChange(value)
      }} onBlur={() => input.onBlur(input.value)} maxDate={maxDate} />}
      {!disabled && <DatePicker className={redClass} {...input} selected={values} dateFormat="dd/MM/yyyy" onChange={value => {
        field.handleChange(value)
        console.log('rend' + value);
        input.onChange(value)
      }} onBlur={() => input.onBlur(input.value)} maxDate={maxDate} />}
      {touched && error && (error != MESSAGE_REQUIRED) && <span className="text-danger">{errorOnNewline && <br />}{error}</span> || (warning && <span>{warning}</span>)}

    </div>

  );
}

export const renderDatePickerAU = (field) => {
  const { input, label, values, disabled, mandatory, onChange, meta: { touched, error, warning }, maxDate } = field
  let redClass = ''
  if (touched && error) {
    redClass = 'highlight-redbox';
  }
  let labelMandatorySpan = '';
  if (mandatory) {
    labelMandatorySpan = '*';
  }

  let maxDateField = new Date() + 10000;
  if (maxDate) {
    maxDateField = maxDate;
  }

  return (
    <div className="col_6 column">
      <label>{label} <span style={{ color: 'red' }}>{labelMandatorySpan} </span></label>
      {disabled && <DatePicker  {...input} disabled selected={values} dateFormat="dd/MM/yyyy" onChange={value => {
        let value2 = value;
        if (value) {
          value2 = new Date(value).setHours(12);
        }
        field.handleChange(value2);
        console.log('rend' + value2);
        input.onChange(value2)
      }} onBlur={() => input.onBlur(input.value)} maxDate={maxDate} />}
      {!disabled && <DatePicker className={redClass} {...input} selected={values} dateFormat="dd/MM/yyyy" onChange={value => {
        let value2 = value;
        if (value) {
          value2 = new Date(value).setHours(12);
        }
        field.handleChange(value2)
        console.log('rend' + value2);
        input.onChange(value2)
      }} onBlur={() => input.onBlur(input.value)} maxDate={maxDate} />}
      {touched && error && (error != MESSAGE_REQUIRED) && <span className="text-danger">{error}</span> || (warning && <span>{warning}</span>)}

    </div>

  );
}

export const renderField = ({ input, label, disabled, type, meta: { touched, error, warning } }) => {
  let redClass = ''
  if (touched && error) {
    redClass = 'highlight-redbox';
  }
  return (
    <div>
      <label>{label}</label>
      <div>
        <input className={redClass} disabled={disabled} {...input} type={type} placeholder={label} />
        {touched && error && (error != MESSAGE_REQUIRED) && <span className="text-danger">{error}</span> || (warning && <span>{warning}</span>)}
      </div>
    </div>
  );
}

export const renderNonComplianceForm = ({ fields, disabled, meta: { touched, error, warning } }) => (
  <table>
    <thead>
      <tr>
        <th>Reason <span style={{ color: 'red' }}>* </span></th>
        {/* <th>Comments <span style={{color:'red'}}>* </span></th> */}
        <th></th>
      </tr>
    </thead>
    {fields.map((noncompliance, index) =>
      <tr key={index}>
        <td>
          <Field
            name={`${noncompliance}.reason`}
            type="text"
            props={{ disabled: disabled }}
            component={renderField} />
        </td>
        {/* <td>
          <Field
            name={`${noncompliance}.comments`}
            type="text"
            props={{ disabled: disabled}} 
            component={renderField} />
        </td> */}
        <td>
          <button
            type="button"
            disabled={disabled}
            title="Remove Reason"
            onClick={() => fields.remove(index)}>Delete</button>
        </td>
      </tr>
    )}
    <tr>
      <button type="button" disabled={disabled} onClick={() => fields.push({})}>Add Reason</button>
      {touched && error && <span className="text-danger">{error}</span> || (warning && <span>{warning}</span>)}
    </tr>
  </table>
)

export const reactSelect = ({ input, label, mandatory, options, disabled, type, meta: { touched, error, warning } }) => {
  let redClass = ''
  if (touched && error) {
    redClass = 'highlight-redbox';
  }
  const selectStyle = {
    height: '36.74px'
  }

  let labelMandatorySpan = '';
  if (mandatory) {
    labelMandatorySpan = '*';
  }
  return (
    <div class={redClass}>
      <label>{label}<span style={{ color: 'red' }}>{labelMandatorySpan} </span></label>
      <Select
        {...input}
        value={input.value}
        onChange={(value) => input.onChange(value)}
        onBlur={() => input.onBlur(input.value)}
        options={options}
        isDisabled={disabled}
      />
    </div>
  );
}


