import React from 'react';
import { MapView } from '../../../../components/tools/MapView';
import { toStringIfNotNull } from '../../../../helpers/formatters';
import { AsteriskRed } from '../../../../components/tools/Others';

export default class ValidationMeterLocation  extends React.Component {

    render = () => {
        const form = this.props.validationForm;
        return <>
            <div className='row'>
                <div className='col-md-3 mb-3'>
                    {form.renderUneditableTextInput('latitude',null,<>Latitude <AsteriskRed/></>)}
                </div>
                <div className='col-md-3 mb-3'>
                    {form.renderUneditableTextInput('longitude',null,<>Longitude <AsteriskRed/></>)}
                </div>
            </div>
            <MapView
                readOnly={true}                
                point={{ latitude: form.val('latitude'), longitude: form.val('longitude')}}
                disabled={true}
            />
        </>
    }
}