import React, { useState } from 'react';
import { Field, reduxForm, formValueSelector, change } from 'redux-form';
import { connect } from 'react-redux';
import { InputTextForRedux, SelectForRedux, ShowErrorForRedux, RadiosForRedux, CheckboxForRedux } from '../../tools/BootstrapControls'
import { validate } from './ApprovalDetailsFormSection.Validation'
import { saveDraftAction, clickNextAction, scrollTofocusError } from "./MeterFormValidations"
import { METER_FORM_TYPES, METER_VALIDATION_TYPE } from '../../../constants/MeterFormConstants'
import { getMeterNumbers } from './MeterCertificateCommon'
import $ from 'jquery';
import EsidSelect from '../../common/EsidSelect'
import MeterSelectForEsid from '../../common/MeterSelectForEsid'
import DasWorkspaceIdSelect from '../../common/DasWorkspaceIdSelect'
import Authentication from '../../../helpers/Authentication'


let ApprovalDetailsFormSection = props => {
   const {
      handleSubmit,
      masterDataValues,
      appWorkInfo,
      meterEquipInfo,
      previousPage,
      waterAccessLicenceNoExternalList,
      loading,
      hasError
   } = props
   const disableFields = (parseInt(meterEquipInfo.formStatus) == 2) || props.disableFields || props.disableEditing || loading;

   const disableWaterFields = (appWorkInfo.waterSourceName && appWorkInfo.waterSourceName.length > 0) || props.disableEditing || loading;

   const correctWaterSourceOptions =
      [
         { id: "1", name: "The water source information is incorrect.", selected: false }
      ];

   const isRelatedTelemetryOptions =
      [
         { id: "1", name: "Yes", selected: true },
         { id: "2", name: "No", selected: false }
      ];
   const [esidMatchWorkspaceWarning, setEsidMatchWorkspaceWarning] = useState(false);

   const validateEsidAgainstWorkspaceId = (workspaceId, esid) => {



      if (!(workspaceId && esid)) return;
      if (workspaceId.includes("-")) {
         var res = workspaceId.split("-");
         if (res.length != 3) return;
         if (res[1] != esid) {
            setEsidMatchWorkspaceWarning(true);
            return;
         }
      }
      setEsidMatchWorkspaceWarning(false);
   }

   const removeWaterInfoDetails = (e) => {
      if (e.length == 0) {
         props.dispatch(change(props.form, 'approvedWorkDetails.correctWaterSourceDetails', ""));
      }
   }

   const workspaceIdChangeHandler = (workspaceId) => {
      props.dispatch(change(props.form, 'approvedWorkDetails.workspaceId', workspaceId));
      if (workspaceId.includes("-")) {
         var res = workspaceId.split("-");
         if (res.length != 3) return;
         props.dispatch(change(props.form, 'approvedWorkDetails.meterNumber', res[2]));
      }
      validateEsidAgainstWorkspaceId(workspaceId, appWorkInfo.esid);
   }

   const [meterLoading, setMeterLoading] = useState(false);
   const esidChangeHandler = async (esid) => {
      props.dispatch(change(props.form, 'approvedWorkDetails.esid', esid.esid));
      props.dispatch(change(props.form, 'meteringEquipmentInformation.longitude', esid.esLong));
      props.dispatch(change(props.form, 'meteringEquipmentInformation.latitude', esid.esLat));
      props.dispatch(change(props.form, 'approvedWorkDetails.worksId', esid.workId));
      props.dispatch(change(props.form, 'approvedWorkDetails.noOfWorks', esid.numberOfWorks));

      props.dispatch(change(props.form, 'approvedWorkDetails.waterSourceName', esid.waterSourceName));
      props.dispatch(change(props.form, 'approvedWorkDetails.waterSharingPlanName', esid.waterSharingPlanDesc));
      props.dispatch(change(props.form, 'approvedWorkDetails.waterSourceType', esid.waterType));

      if (meterEquipInfo.newMeterEquipment == METER_VALIDATION_TYPE.NEW && appWorkInfo.isRelatedToMeterRegistry != 1) {

         setMeterLoading(true);
         var payloadData = await getMeterNumbers(appWorkInfo.waterSupplyWorkApprovalNo, esid.esid);;
         setMeterLoading(false);

         if (payloadData.success) {
            if (payloadData.meterNumberExternalList.length > 0) {
               var highest = Math.max.apply(this, $.map(payloadData.meterNumberExternalList, function (o) { return o.meterNumber; }));
               props.dispatch(change(props.form, 'approvedWorkDetails.meterNumber', highest + 1));
            } else {
               props.dispatch(change(props.form, 'approvedWorkDetails.meterNumber', 1));
            }
         } else {
            alert('Error happened when loading meters fro this ESID');
            props.dispatch(change(props.form, 'approvedWorkDetails.meterNumber', null));
            return;
         }
      }

      validateEsidAgainstWorkspaceId(appWorkInfo.workspaceId, esid.esid);
   }

   const validateWorkspaceId = (e) => {
      //debugger;
      if ((meterEquipInfo.meterFormType == METER_FORM_TYPES.METER_VALIDATION ||
         meterEquipInfo.meterFormType == METER_FORM_TYPES.MAINTENANCE_VALIDATION) && appWorkInfo.isRelatedToMeterRegistry == "1") {
         var workspId = appWorkInfo.workspaceId;

         if (workspId != null && workspId != "") {
            if (workspId.includes("-")) {
               var res = workspId.split("-");
               if (res.length < 3 || res.length > 3) {
                  return false;
               }

               var appNo = res[0];
               var esidNo = res[1];
               var dasid = res[2];

               if (appNo.toLowerCase() == appWorkInfo.waterSupplyWorkApprovalNo.toLowerCase() && esidNo == appWorkInfo.esid && dasid != "" && !isNaN(dasid)) {
                  return true;
               }
            }
         }

         return false;
      }
      return true;
   }

   if (meterEquipInfo.meterFormType == METER_FORM_TYPES.METER_VALIDATION || meterEquipInfo.meterFormType == METER_FORM_TYPES.MAINTENANCE_VALIDATION) {
      var meterform = 1;
      props.dispatch(change(props.form, 'approvedWorkDetails.meterForm', meterform));
   }

   const meterChangeHandler = (meter) => {
      props.dispatch(change(props.form, 'approvedWorkDetails.meterNumber', meter.METER_NO));

      var meterNumber = meter.METER_NO;
      var meterNumberGUID = meter.GUID;
      var meterSize = meter.SIZE_IN_MM;
      var meterSerialNumber = meter.SERIAL_NUMBER;
      var meterCategoryDesc = meter.METER_CATEGORY_DESC;
      var meterTypeDesc = meter.METER_TYPE_DESCRIPTION;
      var endMeterReading = meter.END_METER_READING;

      // Meter model
      var meterTypeModel = "";
      if (meterCategoryDesc != null && meterCategoryDesc != "") {
         meterTypeModel = meterCategoryDesc;
      }
      var meterTypeModelId = masterDataValues.meterModelTypes.filter(function (option) { if (option.name === meterTypeModel) return option });
      if (meterTypeModelId != undefined && meterTypeModelId != null && meterTypeModelId.length > 0) {
         props.dispatch(change(props.form, 'meteringEquipmentInformation.meterTypeModelId', meterTypeModelId[0].id));
      } else {
         meterTypeModelId = masterDataValues.MeterModelTypeOtherId;
         props.dispatch(change(props.form, 'meteringEquipmentInformation.meterTypeModelId', meterTypeModelId));
         props.dispatch(change(props.form, 'meteringEquipmentInformation.meterTypeModel', meterTypeModel));
      }

      // Meter type
      if (meterTypeDesc != null && meterTypeDesc != "") {
         props.dispatch(change(props.form, 'meteringEquipmentInformation.meterDescription', meterTypeDesc));
      }

      //need to get end meter reading
      if (endMeterReading) {
         props.dispatch(change(props.form, 'meteringEquipmentInformation.endMeterReading', endMeterReading));
      }


      props.dispatch(change(props.form, 'meteringEquipmentInformation.meterSize', meterSize));
      props.dispatch(change(props.form, 'meteringEquipmentInformation.meterSerialNo', meterSerialNumber));

      if (
         (
            (
               meterEquipInfo.newMeterEquipment == METER_VALIDATION_TYPE.EXISTING
               ||
               meterEquipInfo.newMeterEquipment == METER_VALIDATION_TYPE.REPLACEMENT
            )
            &&
            meterEquipInfo.meterFormType == METER_FORM_TYPES.METER_VALIDATION
         )
         ||
         meterEquipInfo.meterFormType == METER_FORM_TYPES.METER_ACCURACY
         ||
         meterEquipInfo.meterFormType == METER_FORM_TYPES.MAINTENANCE_VALIDATION
      ) {
         props.dispatch(change(props.form, 'approvedWorkDetails.meterNumberGUID', meterNumberGUID));
         props.dispatch(change(props.form, 'approvedWorkDetails.meterNumber', meterNumber));
      }
   }

   if (parseInt(appWorkInfo.waterSourceTypeId) == 0 && masterDataValues.waterSourceTypes.length > 0) {

      for (var i = 0; i < masterDataValues.waterSourceTypes.length; i++) {
         if (masterDataValues.waterSourceTypes[i].code == undefined || masterDataValues.waterSourceTypes[i].code == null)
            continue;

         if (appWorkInfo.waterSourceType == masterDataValues.waterSourceTypes[i].code) {
            props.dispatch(change(props.form, 'approvedWorkDetails.waterSourceTypeId', masterDataValues.waterSourceTypes[i].id));
            break;
         }
      }
   }



   return (
      <form onSubmit={handleSubmit} >
         <h5 className='mb-3'>Details of Approved Works</h5>
         <div className='row mb-3'>
            <div className='col-sm-6'>
               <Field
                  name="approvedWorkDetails.propertyName"
                  label={<>Property name <span className='text-danger'>*</span></>}
                  component={InputTextForRedux}
                  props={{ disabled: disableFields, mandatory: true }}
               />
            </div>
         </div>

         <div className='row mb-3'>
            <div className='col-sm-6'>
               <Field
                  name="approvedWorkDetails.waterSupplyWorkApprovalNo"
                  label={<>Water supply work approval number <span className='text-danger'>*</span></>}
                  component={InputTextForRedux}
                  props={{ disabled: true, mandatory: true }}
               />
            </div>
         </div>

         {
            (appWorkInfo.approvedByCustomer == 0) || (appWorkInfo.approvedByCustomer == 1 && waterAccessLicenceNoExternalList.length == 0) &&
            <div className='row mb-3'>
               <div className='col-sm-6'>
                  <Field
                     name="approvedWorkDetails.waterAccessLicenceNo"
                     label={<>Water access licence number/s(WAL numbers/s)</>}
                     component={InputTextForRedux}
                     props={{ disabled: disableFields, mandatory: false }}
                  />
               </div>
            </div>
         }
         {
            appWorkInfo.approvedByCustomer == 1 && waterAccessLicenceNoExternalList.length > 0 &&
            <div className='row mb-3'>
               <div className='col-sm-6'>
                  <Field
                     name="approvedWorkDetails.waterAccessLicenceNo"
                     label="Water access license number(s)"
                     component={SelectForRedux}
                     options={waterAccessLicenceNoExternalList}
                     props={{ disabled: disableFields, mandatory: false }}

                  />
               </div>
            </div>
         }

         {(meterEquipInfo.meterFormType == METER_FORM_TYPES.METER_VALIDATION || meterEquipInfo.meterFormType == METER_FORM_TYPES.MAINTENANCE_VALIDATION) && <>
            <div class="row mb-3">
               <div className='col-sm-6'>
                  <label>Is this Validation certificate related to a submitted Meter-Telemetry registration form? <span style={{ color: 'red' }}>* </span></label>
                  <Field
                     name={"approvedWorkDetails.isRelatedToMeterRegistry"}
                     component={RadiosForRedux}
                     options={isRelatedTelemetryOptions}
                     props={{ disabled: disableFields }}
                     values={appWorkInfo.isRelatedToMeterRegistry}
                  />
               </div>
            </div>
            {(appWorkInfo.isRelatedToMeterRegistry == 1) && <>
               <div class="row mb-3">
                  <div className='col-sm-6'>
                     <DasWorkspaceIdSelect
                        approvalNumber={appWorkInfo.waterSupplyWorkApprovalNo}
                        onWorkspaceIdChange={workspaceIdChangeHandler}
                        selectedWorkspaceId={
                           appWorkInfo.workspaceId
                        }
                        disabled={disableFields}
                     />
                  </div>
               </div>
            </>}
         </>}



         <div class="row mb-3">
            <div className='col-sm-6'>
               {!disableFields && <>
                  <label className='form-label'>Unique extraction site identifier (ESID) <span className='text-danger'>* </span></label>
                  <EsidSelect
                     approvalNumber={appWorkInfo.waterSupplyWorkApprovalNo}
                     onEsidChange={esidChangeHandler}
                     defaultEsid={appWorkInfo.esid}
                  />
                  <Field name='approvedWorkDetails.esid' component={ShowErrorForRedux} />
               </>}
               {disableFields && <Field
                  name="approvedWorkDetails.esid"
                  label="Unique extraction site identifier (ESID)"
                  placeholder="Click here to select the extraction site"
                  autocomplete="off"
                  component={InputTextForRedux}
                  props={{ disabled: true, mandatory: true, autocomplete: "off" }}
               />}

               {esidMatchWorkspaceWarning && <div className='alert alert-warning mt-2 mb-0'>
                  Please note that the ESID you selected does not match the workspace ID you selected.
               </div>}
            </div>
         </div>
         <div class="row mb-3">
            <div className='col-sm-6'>
               <Field
                  name="approvedWorkDetails.worksId"
                  label={<>Works ID <span className='text-danger'>*</span></>}
                  component={InputTextForRedux}
                  props={{ disabled: true, mandatory: true }}
               />
            </div>
         </div>
         <div class="row mb-3">
            <div className='col-sm-6'>
               <Field
                  name="approvedWorkDetails.noOfWorks"
                  label={<>No. of works <span className='text-danger'>*</span></>}
                  component={InputTextForRedux}
                  props={{ disabled: true, mandatory: true }}
               />
            </div>
         </div>

         {
            (
               meterEquipInfo.meterFormType == METER_FORM_TYPES.METER_VALIDATION ||
               meterEquipInfo.meterFormType == METER_FORM_TYPES.METER_ACCURACY ||
               meterEquipInfo.meterFormType == METER_FORM_TYPES.MAINTENANCE_VALIDATION
            )
            &&
            (
               meterEquipInfo.newMeterEquipment == METER_VALIDATION_TYPE.EXISTING
               ||
               meterEquipInfo.newMeterEquipment == METER_VALIDATION_TYPE.REPLACEMENT
            )
            &&
            !disableFields
            &&
            <div class="row mb-3">
               <div className='col-sm-6'>
                  <label>Please select the meter <span style={{ color: "red" }}>* </span></label>
                  <MeterSelectForEsid approvalNumber={appWorkInfo.waterSupplyWorkApprovalNo} esid={appWorkInfo.esid} onMeterChange={meterChangeHandler} defaultMeterNo={appWorkInfo.meterNumber} />
                  <Field name='approvedWorkDetails.meterNumber' component={ShowErrorForRedux} />
               </div>
            </div>
         }

         {
            (
               (
                  (
                     meterEquipInfo.meterFormType == METER_FORM_TYPES.METER_VALIDATION ||
                     meterEquipInfo.meterFormType == METER_FORM_TYPES.METER_ACCURACY ||
                     meterEquipInfo.meterFormType == METER_FORM_TYPES.MAINTENANCE_VALIDATION
                  )
                  &&
                  (meterEquipInfo.newMeterEquipment == METER_VALIDATION_TYPE.NEW)
               )
               ||
               disableFields
            )
            &&
            <div class="row mb-3">
               <div className='col-sm-6'>
                  <Field
                     name="approvedWorkDetails.meterNumber"
                     label="Meter number"
                     component={InputTextForRedux}
                     style={{ display: "none" }}
                     props={{ disabled: true, mandatory: false }}
                  />
                  {meterLoading &&
                     <div class="spinner-border text-primary spinner-border-sm" role="status">
                        <span class="visually-hidden">Loading...</span>
                     </div>
                  }
               </div>
            </div>
         }

         {(meterEquipInfo.meterFormType == METER_FORM_TYPES.METER_VALIDATION || meterEquipInfo.meterFormType == METER_FORM_TYPES.MAINTENANCE_VALIDATION) &&
            <div class="row mb-3">
               <div className='col-sm-6'>
                  <Field
                     name="approvedWorkDetails.customersSiteName"
                     label={<>Customer's site name <span className='text-danger'>*</span></>}
                     component={InputTextForRedux}
                     style={{ display: "none" }}
                     props={{ disabled: disableFields, mandatory: true }}
                  />
                  {meterLoading &&
                     <div class="spinner-border text-primary spinner-border-sm" role="status">
                        <span class="visually-hidden">Loading...</span>
                     </div>
                  }
               </div>
            </div>
         }

         {(
            meterEquipInfo.meterFormType == METER_FORM_TYPES.METER_VALIDATION || meterEquipInfo.meterFormType == METER_FORM_TYPES.MAINTENANCE_VALIDATION) &&
            <div class="row mb-3">
               <div className='col-sm-6'>
                  <Field
                     name="approvedWorkDetails.customersPumpNumber"
                     label="Customer's pump number"
                     component={InputTextForRedux}
                     props={{ disabled: disableFields }}
                  />
               </div>
            </div>
         }

         <div class="row mb-3">
            <div className='col-sm-6'>
               <Field
                  name="approvedWorkDetails.waterSourceName"
                  label="Name of water source"
                  component={InputTextForRedux}
                  props={{ disabled: disableWaterFields, mandatory: false }}
               />
            </div>
         </div>

         <div class="row mb-3">
            <div className='col-sm-6'>
               <Field
                  name="approvedWorkDetails.waterSharingPlanName"
                  label="Name of water sharing plan"
                  component={InputTextForRedux}
                  props={{ disabled: disableWaterFields, mandatory: false }}
               />
            </div>
         </div>


         <div class="row mb-3">
            <div className='col-sm-6'>
               <Field
                  name="approvedWorkDetails.waterSourceTypeId"
                  component={SelectForRedux}
                  options={masterDataValues.waterSourceTypes}
                  label="Type of water source"
                  props={{ disabled: disableWaterFields, mandatory: false }}
               />
            </div>
         </div>


         <div class="row mb-3">
            <div className='col-sm-6'>
               <Field
                  name="approvedWorkDetails.correctWaterInfoCheck"
                  component={CheckboxForRedux}
                  options={correctWaterSourceOptions}
                  onChange={removeWaterInfoDetails}
                  props={{ disabled: disableFields, mandatory: false }}
               />
            </div>
         </div>
         {(appWorkInfo.correctWaterInfoCheck == 1) &&
            <div class="row mb-3">
               <div className='col-sm-6'>
                  <label>Please provide detail on incorrect water source information</label>
                  <Field
                     name="approvedWorkDetails.correctWaterSourceDetails"
                     component={InputTextForRedux}
                     props={{ disabled: disableFields, mandatory: false }}
                  />
               </div>
            </div>
         }

         {hasError && <div className='alert alert-danger'>
            Please fix all errors before you continue
         </div>}

         <div className="text-end">
            {(parseInt(meterEquipInfo.formStatus) == 1) && (!props.disableEditing) && <>
               {meterEquipInfo.id != 0 &&
                  <button type="button" className="btn btn-danger me-3" disabled={loading}
                     data-bs-toggle="modal" data-bs-target="#deleteDraftModal">
                     Delete Draft
                  </button>
               }
               <button type="button" className="btn btn-primary" disabled={loading}
                  onClick={() => { saveDraftAction(props); }}>
                  Save Draft
               </button>
               <div className='vr mx-3'></div>
            </>}
            <button type="button" className="btn btn-warning me-3" disabled={loading} onClick={() => { previousPage(meterEquipInfo.formStatus) }}>Previous</button>

            <button type="button" className="btn btn-success"
               disabled={loading}
               onClick={() => { if (!validateWorkspaceId()) { alert('The work approval number and ESID in the DAS ID do not match the current form. Please check your DAS ID.'); } else { clickNextAction(props); } }}>
               Next</button>

         </div>
      </form>
   )
}


ApprovalDetailsFormSection = reduxForm({
   destroyOnUnmount: false,
   forceUnregisterOnUnmount: true,
   validate,
   onSubmitFail: (errors, dispatch) => {
      dispatch(change('certificate', 'hasError', true));
   },
   onSubmitSuccess: (result, dispatch) => {
      dispatch(change('certificate', 'hasError', false));
      window.scrollTo(0, 0);
   }
})(ApprovalDetailsFormSection)

ApprovalDetailsFormSection = connect(
   (state, props) => {
      const selectorCert = formValueSelector('certificate')
      const hasError = selectorCert(state, 'hasError')

      const selector = formValueSelector(props.form);
      const loading = selector(state, 'loading');
      const masterDataValues = selector(state, 'mdata');
      const waterAccessLicenceNoExternalList = selector(state, 'waterAccessLicenceNoExternalList');
      const appWorkInfo = selector(state, 'approvedWorkDetails');
      const meterEquipInfo = selector(state, 'meteringEquipmentInformation');
      return {
         masterDataValues,
         appWorkInfo,
         meterEquipInfo,
         form: props.form,
         loading,
         waterAccessLicenceNoExternalList,
         hasError
      }
   }
)(ApprovalDetailsFormSection)

export default ApprovalDetailsFormSection;
