import React from 'react';
import { StepProgressBar } from '../../../../components/tools/BootstrapControls';

import ValidationInstallationFields from './ValidationInstallationFields';
import { AsteriskRed } from '../../../../components/tools/Others';
import { isStringNullOrEmpty } from '../../../../helpers/validators';

export default class ValidationPage4  extends React.Component {

    

    render() {
        const form = this.props.validationForm;
        const role = this.props.role;
        const approval = this.props.approval;
        return <>
            <div className='my-5'>      
                <StepProgressBar
                    totalSteps={5} 
                    currentStep={4} 
                    disableFutureSteps={form.val('formStatus')==0} 
                    stepClick={(page) =>{this.props.gotToStep(page)}}
                />
            </div>

            <div class="card mb-3">
                <div class="card-header bg-primary text-white">
                    Installation Information
                </div>
                <div className='card-body'>
                    <ValidationInstallationFields validationForm={form}/>

                    <div className={`row mb-3`}>
                        <div className={`col-md-4`}>
                            DQP Commissioning and Validation Notes <AsteriskRed/>
                            <br/><span className='fs-80 text-muted'>(these notes will display on the Validation Certificate)</span>
                        </div>
                        <div className={`col-md-8`}>
                            {form.renderTextArea("dqpNotes")}
                        </div>
                    </div>

                    {this.props.displayDasInstalaltionErrMsg && <div className='alert alert-danger mt-3 mb-0'>
                        <b>This meter cannot be "Set to Install" due to errors in DAS. Please check the following - </b>
                        <ol className='ms-3 mb-0'>
                            <li>SIM card is activated</li>
                            <li>DAS can connect to the meter and historical readings are present</li>
                        </ol>
                    </div>}
                </div>
            </div>

            {/* 
            <div class="card mb-3">
                <div class="card-header bg-primary text-white">
                    Validation Certificate Email
                </div>
                <div className='card-body'>
                    <div className='mb-3'>
                        {form.renderRadioOptions('certificateSendToNoneHolder',[
                            {value:false,optionId:'toHolder',label:'Send Validation Certificate to holders', isChecked:((value)=>value==null || value!==true)},
                            {value:true,optionId:'toNoneHolder',label:'Send Validation Certificate to a specified email (none holder)', isChecked:((value)=>value===true)}
                        ])}
                    </div>

                    {form.val('certificateSendToNoneHolder')===true && form.renderTextBoxRow('certNoneHolderEmail',<>Send Certificate to Email <AsteriskRed/></>,{labelWidth:3, fieldWidth:9})}


                    {(form.val('certificateSendToNoneHolder')== null || form.val('certificateSendToNoneHolder')!== true) && <div class="row ">
                        <div class="col-md-3 py-2">Send Certificate to Email <span class="text-danger">*</span></div>
                        <div class="col-md-9">
                            <ul class="list-group">
                                {approval.holders.map(h=>isStringNullOrEmpty(h.email)?null:<li class="list-group-item bg-light">{h.email}</li>)}   
                            </ul>
                        </div>
                    </div>}
                </div>
            </div>

            {form.val('lIDTelemetry') && <div class="card mb-3">
                <div class="card-header bg-primary text-white">
                    DAS Access
                </div>
                <div className='card-body'>
                    <div className='mb-3'>
                        {form.renderRadioOptions('dasAccessToNoneHolder',[
                            {value:false,optionId:'toHolder',label:'Grant DAS access to holders', isChecked:((value)=>value==null || value!==true)},
                            {value:true,optionId:'toNoneHolder',label:'Grant DAS access to a specified email (none holder)', isChecked:((value)=>value===true)}
                        ])}
                    </div>

                    {form.val('dasAccessToNoneHolder')===true && form.renderTextBoxRow('dasAccessNoneHolderEmail',<>Grant DAS Access to Email <AsteriskRed/></>,{labelWidth:3, fieldWidth:9})}

                    {(form.val('dasAccessToNoneHolder')== null || form.val('dasAccessToNoneHolder')!== true) && <div class="row ">
                        <div class="col-md-3 py-2">Send Certificate to Email <span class="text-danger">*</span></div>
                        <div class="col-md-9">
                            <ul class="list-group">
                                {approval.holders.map(h=>isStringNullOrEmpty(h.email)?null:<li class="list-group-item bg-light">{h.email}</li>)}   
                            </ul>
                        </div>
                    </div>}
                </div>
            </div>}
            */}

            {form.val('formStatus')==1 && <div class="text-end">

                {form.haveErrors() && <div className='alert alert-danger text-end'>
                Please complete the required information and resolve any errors that may display in order to proceed further.
                </div>}

                <button type="button" class="btn btn-primary float-start" onClick={()=>this.props.gotToStep(3)} disabled={form.isFormDisabled()}>
                    Back
                </button>


                {role=="OWNER" && <button type="button" class="btn btn-primary me-3" onClick={()=>form.runAction('save')} disabled={form.isFormDisabled()}>
                    Save
                </button>}
                <button type="button" class="btn btn-success" onClick={()=>form.runAction('goToStep5')} disabled={form.isFormDisabled()}>
                    Next
                </button>
            </div>}
        </>;
    }
}