
export const sessionCreation = "/nominatedMeasurementPeriod/sessionCreation";
export const accountInfo = "/nominatedMeasurementPeriod/accountInfo";
export const sessionDestroy = "/nominatedMeasurementPeriod/sessionDestroy";
export const getNominatedMeasurementPeriods = "/nominatedMeasurementPeriod/getNominatedMeasurementPeriods";
export const getApprovals = "/nominatedMeasurementPeriod/getApprovals";
export const createNominatedMeasurementPeriod = "/nominatedMeasurementPeriod/createNominatedMeasurementPeriod";
export const getNominatedMeasurementPeriod = "/nominatedMeasurementPeriod/getNominatedMeasurementPeriod";
export const changePeriodDates = "/nominatedMeasurementPeriod/changePeriodDates";
export const saveReasonForNotLodging = "/nominatedMeasurementPeriod/saveReasonForNotLodging";
export const deleteNominatedMeasurementPeriod = "/nominatedMeasurementPeriod/deleteNominatedMeasurementPeriod";
export const deleteOtherInFlow = "/nominatedMeasurementPeriod/deleteOtherInFlow";
export const addOtherInFlow = "/nominatedMeasurementPeriod/addOtherInFlow";
export const saveStorageReading = "/nominatedMeasurementPeriodStorage/saveStorageReading";
export const saveStorageIsManual = "/nominatedMeasurementPeriodStorage/saveIsManual";
export const savePOITIsManual = "/nominatedMeasurementPeriod/savePOITIsManual";
export const savePOITVolume = "/nominatedMeasurementPeriod/savePOITVolume";
export const closeNominatedMeasurementPeriod = "/nominatedMeasurementPeriod/closeNominatedMeasurementPeriod";
export const finaliseNominatedMeasurementPeriod = "/nominatedMeasurementPeriod/finaliseNominatedMeasurementPeriod";



