import React from 'react';
import axios from 'axios';
import moment from 'moment';
import * as bootstrap from 'bootstrap';
import Authentication from '../../../helpers/Authentication'
import uiHelpers from '../../../helpers/uiHelpers'
import {convertCertificateAuditAction} from '../../../helpers/converters';
import {Refresh} from '../../tools/Icons'
import {InputText} from '../../tools/BootstrapControls';

const authentication = new Authentication();

class Audit extends React.Component {

    #commentsModalElementId = "model_" + (new Date()).getTime();
    #commentsModal;

    ajaxConfig = {
        baseURL: process.env.REACT_APP_WEBAPI_URL,
        headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authentication.getToken() }
     };

    constructor(props) {
        super(props);
        this.state = {
            audits : [],
            loading: false,
            notes : '',
            notesError : ''
        }
    }

    async componentDidMount() {
        this.refresh();
        if(this.props.id==0) return;
        this.#commentsModal = new bootstrap.Modal(document.getElementById(this.#commentsModalElementId));
    }   

    componentWillUnmount() {
        if(this.props.id==0) return;
        if(this.#commentsModal) this.#commentsModal.dispose();
    }

    openCommentsModal = () => {
        this.#commentsModal.show();
    }

    saveNotes = async () => {

        this.setState({notesError : ''});
        if(this.state.notes.trim() == '') {
            this.setState({notesError : 'Please provide notes to be saved'});
            return;
        }

        this.setState({loading:true})
        try {
            const response = await axios.post('/ratingTables/save-notes', {
                id : this.props.id
                , notes: this.state.notes
            }, this.ajaxConfig);
            this.setState({
                audits : response.data.data
            });
            this.state.notes = '';
        } catch(e){
            console.log(e);
            uiHelpers.showErrorTop('Error occured when saving notes...');
        }
        this.#commentsModal.hide();
        this.setState({loading:false})
    }

    refresh = async () =>{
        if(this.props.id==0) return;
        
        uiHelpers.showProgressOnTop("Loading audit records...");
        this.setState({loading:true})
        try {
            const response = await axios.post('/ratingTables/audit', {id : this.props.id}, this.ajaxConfig);
            this.state.audits = response.data.data;
            uiHelpers.hideProgressOnTop();
        } catch(e){
            console.log(e);
            uiHelpers.showErrorTop('Error occured when loading audit records...');
        }
        this.setState({loading:false})
    }

    render() {
        if(this.props.id==0)
            return <div className='my-3 alert alert-primary'>
                No audit as your record has not been saved
            </div>
        return <>
            <div class="card mb-3" >
                <div className='card-body'>
                    <button className='btn btn-outline-primary me-3 btn-sm' disabled={this.state.loading} onClick={this.refresh}><Refresh/></button>
                    <button className='btn btn-outline-primary me-3 btn-sm' disabled={this.state.loading} onClick={this.openCommentsModal}>Add notes</button>
                </div>
            </div>
            <table className='table table-striped fs-90'>
                <thead>
                    <tr>
                        <th style={{width:"200px"}}>Date/Time</th>
                        <th style={{width:"150px"}}>Action</th>
                        <th style={{width:"200px"}}>User</th>
                        <th>Comment</th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.audits.map((record)=><>
                        <tr>
                            <td>
                            {moment((new Date(record.auditDate))).format('DD/MM/YYYY h:mma')}
                            </td>
                            <td>
                            {convertCertificateAuditAction(record.auditAction)}
                            </td>
                            <td>
                            {record.auditUser.firstName} {record.auditUser.lastName}
                            </td>
                            <td>
                            {(record.comments)} 
                            </td>
                        </tr>
                    </>)}
                </tbody>
            </table>

            <div class="modal fade" id={this.#commentsModalElementId} data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">Add notes</h5>
                        </div>
                    <div class="modal-body">
                        <div class="form-group">
                            <InputText
                                label={null}
                                error={this.state.notesError}
                                id="notesInput"
                                onChange={(e) => {this.setState({notes : e.target.value})}}
                                value={this.state.notes}
                                disabled={this.state.loading}
                                placeholder="Notes"
                            />
                        </div>
                    </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" disabled={this.state.loading} data-bs-dismiss="modal">Close</button>
                            <button type="button" class="btn btn-primary" disabled={this.state.loading} onClick={this.saveNotes}>
                                Save
                                {this.state.loading && <>&nbsp;&nbsp;&nbsp;<div class="spinner-grow spinner-grow-sm" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div></>}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    }

}

export default Audit;
