import React from 'react';
import * as apiPathes from '../apiPathes';
import * as serviceCaller from '../serviceCaller'
import { Person, Info } from '../../components/tools/Icons';

export default class UserInfo  extends React.Component {

    constructor(){
        super();
        this.state = {initialising: true}
    }

    componentDidMount(){
        serviceCaller.get(apiPathes.accountInfo,{
            noDefaultHandle: true,
            noIWasLoginHandler: ()=>{
                this.setState({noIWasLogin : true, initialising: false})
            },
            success: (data) =>{
                this.setState({accountInfo: data, initialising: false})
            }
        });
    }

    onInfoClick = () => {
        if(this.props.openHelpPopup) this.props.openHelpPopup();
    }

    render(){
        return <div class="d-flex dropdown float-end">

            <button class="btn btn-outline-secondary btn-sm me-2" onClick={this.onInfoClick}><Info/></button>

            <button class="btn btn-outline-primary btn-sm" data-bs-toggle="dropdown" id="drop-btn-iwas-acct"><Person/></button>
        
            <div class="dropdown-menu dropdown-menu-end" aria-labelledby="drop-btn-iwas-acct">
                {this.state.initialising && <div className='text-center p-5'>
                    <span class="spinner-border text-primary" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </span>
                </div>}
                {this.state.noIWasLogin && <div className='text-center text-danger px-2 py-4'>
                    Please login from iWAS
                </div>}
                {this.state.accountInfo && <>
                    <div className='px-2 py-3 text-center text-primary text-truncate' style={{"max-width" : "250px"}}>
                        {function(accountInfo){
                            if(accountInfo.inpaType == "P"){
                                return <>{accountInfo.givenName} {accountInfo.familyName}</>
                            } else {
                                return <>{accountInfo.organisationName}</>
                            }
                        }(this.state.accountInfo)}
                        <br/>
                        <small>Customer No.: {this.state.accountInfo.customerId}</small>
                    </div>
                    <hr class="dropdown-divider" />
                    <div className='px-2 text-center'>
                        <a className='btn btn-outline-primary btn-sm' href="/measurement-period/#/logout">
                            Logout
                        </a>
                    </div>
                </>}
            </div>
        </div>;
    }
}