import React from 'react';
import Select from 'react-select';
import { QuestionFill } from './Icons';
import * as bootstrap from 'bootstrap'
import DatePicker from 'react-datepicker';
import ReactHtmlParser from 'react-html-parser';
import CreatableSelect from 'react-select/creatable';
import { Field } from 'redux-form'

export const InputTextWithFloatingLabel = (props) => {
    return <div class={`form-floating ${props.className ? props.className : ""}`}>
        <input
            type="text"
            className={`form-control ${(props.error && props.error != '') ? "is-invalid" : ""}`}
            id={props.id}
            readOnly={props.readOnly}
            onChange={props.onChange}
            value={props.value}
            disabled={props.disabled}
        />
        <label for={props.id}>{props.label}</label>
        <div class="invalid-feedback">
            {props.error}
        </div>
    </div>
}

export const InputText = (props) => {
    return <div className={props.className}>
        {props.label && <label for={props.id} class="form-label" >{props.label}</label>}
        <input
            type="text"
            id={props.id}
            className={`form-control ${props.inputExtraClasses} ${(props.error && props.error != '') ? "is-invalid" : ""}`}
            onChange={props.onChange}
            defaultValue={props.defaultValue}
            value={props.value}
            readOnly={props.readOnly}
            onBlur={props.onBlur}
            placeholder={props.placeholder}
            disabled={props.disabled}
        />
        <div class="invalid-feedback">
            {props.error}
        </div>
    </div>
}

export const InputTextWithEndAddOn = (props) => {
    return <div className={props.className}>
        {props.label && <label for={props.id} class="form-label" >{props.label}</label>}
        <div class="input-group mb-3">
            <input
                type="text"
                id={props.id}
                className={`form-control ${props.inputExtraClasses} ${(props.error && props.error != '') ? "is-invalid" : ""}`}
                onChange={props.onChange}
                defaultValue={props.defaultValue}
                value={props.value}
                readOnly={props.readOnly}
                onBlur={props.onBlur}
                placeholder={props.placeholder}
                disabled={props.disabled}
            />
            <span class="input-group-text">{props.addOnText}</span>
        </div>
        <div class="invalid-feedback">
            {props.error}
        </div>
    </div>
}

export const InputTextForRedux = (props) => {
    const { input, autocomplete, placeholder, disabled, handleChange, meta: { touched, error } } = props;
    return <div className={props.className}>
        {props.label && <label for={props.id} class="form-label" >{props.label}</label>}
        <input
            type="text"
            id={props.id}
            class={`form-control ${props.inputExtraClasses ? props.inputExtraClasses : ""} ${(touched && error) ? "is-invalid" : ""}`}
            {...input}
            disabled={disabled}
            placeholder={placeholder}
            autocomplete={autocomplete}
            onChange={value => {
                input.onChange(value); if (handleChange) handleChange(value)
            }}
        />
        <div class="invalid-feedback">
            {error}
        </div>
    </div>;
}

export const TextAreaForRedux = (props) => {
    const { autocomplete, placeholder, input, disabled, rows, cols, meta: { touched, error, warning } } = props;
    let redClass = ''
    if (touched && error) {
        redClass = 'highlight-redbox';
    }
    return <div className={props.className}>
        {props.label && <label for={props.id} class="form-label" >{props.label}</label>}
        <textarea
            id={props.id}
            class={`form-control ${props.inputExtraClasses ? props.inputExtraClasses : ""} ${(touched && error) ? "is-invalid" : ""}`}
            placeholder={placeholder}
            autocomplete={autocomplete}
            {...input}
            rows={rows}
            cols={cols}
            disabled={disabled}
        ></textarea>
        <div class="invalid-feedback">
            {error}
        </div>
    </div>
}

export const SelectForRedux = (props) => {
    const { input, label, options, disabled, handleChange, propForDisplay, meta: { touched, error } } = props;
    var propName = propForDisplay
    if (!propName) propName = "name";
    return <div className={props.className}>
        {label && <label for={props.id} class="form-label">{label}</label>}
        <select
            class={`form-select ${props.selectExtraClasses ? props.selectExtraClasses : ""} ${(touched && error) ? "is-invalid" : ""}`}
            id={props.id}
            {...input}
            disabled={disabled}
            onChange={value => {
                input.onChange(value); if (handleChange) handleChange(value)
            }}
        >
            {options.map((option) => (
                <option value={option.id} key={option.id}>
                    {option[propName]}
                </option>
            ))}
        </select>
        <div class="invalid-feedback">
            {error}
        </div>
    </div>
}

export const RadioListGroupForRedux = ({ input, options, name, disabled, values, handleChange, meta: { touched, error } }) => {
    const _onChange = (event) => { input.onChange(event); if (handleChange) { handleChange(event); } }
    return <>

        <div class="list-group fs-90">
            {options.map(option => (
                <label
                    for={`${input.name}_${option.id}`}
                    className={`list-group-item list-group-item-action ${(values == option.id) ? "bg-primary bg-opacity-25" : ""}`}
                >
                    <div className='float-start pt-1'>
                        <input
                            disabled={disabled}
                            {...input}
                            type="radio"
                            value={option.id}
                            checked={(values == option.id)}
                            key={`${input.name}_${option.id}`}
                            id={`${input.name}_${option.id}`}
                            onChange={_onChange}
                        />
                    </div>
                    <div className='ms-4'>
                        <p class="mb-1"><strong>{option.name}</strong></p>
                        <div>{option.description}</div>
                    </div>
                </label>
            ))}
        </div>
        {touched && error && <div className="text-danger mt-1">{error}</div>}
    </>
};

export const DatePickerForRedux = (props) => {
    const { input, label, values, disabled, meta: { touched, error }, maxDate } = props
    return <div className={`react-datepicker-block ${props.className} ${(touched && error) ? "react-date-invalid" : ""}`}>
        {label && <label for={props.id} class="form-label">{label}</label>}
        <DatePicker
            {...input}
            className={`form-control ${props.selectExtraClasses ? props.selectExtraClasses : ""} ${(touched && error) ? "is-invalid" : ""}`}
            disabled={disabled}
            selected={values}
            dateFormat="dd/MM/yyyy"
            onChange={value => {
                props.handleChange(value);
                input.onChange(value)
            }}
            onBlur={() => input.onBlur(input.value)}
            maxDate={maxDate}
        />
        <div class="invalid-feedback">
            {error}
        </div>
    </div>;
}


export const InputTextArrayForRedux = ({ className, fields, disabled, label, fieldName }) => <>
    <div className={className}>
        {label && <label className='form-label'>{label}</label>}
        {fields.map((field, index) => <>
            <div className='row mb-3'>
                <div className='col'>
                    <Field
                        name={`${field}.${fieldName}`}
                        props={{ disabled: disabled }}
                        component={InputTextForRedux}
                    />
                </div>
                <div className='col-md-auto' style={{ width: "200px" }}>
                    <button type="button" class="btn btn-primary me-3"
                        onClick={() => fields.remove(index)} disabled={disabled}
                    >Delete</button>
                    {fields.length == (index + 1) && <button type="button" class="btn btn-primary" disabled={disabled} onClick={() => fields.push({})}>Add</button>}
                </div>
            </div>
        </>)}
    </div>
</>


export const RadiosForRedux = (props) => {
    const { input, options, autocomplete, disabled, values, handleChange, meta: { touched, error } } = props;

    const _onChange = (event, option) => {



        input.onChange(event);
        if (handleChange) {
            handleChange(event, option);
        }
    }

    return <div className={props.className}>
        {props.label && <label for={props.id} class="form-label" >{props.label}</label>}
        <div className='ps-2'>
            {options.map((option, index) => <>
                <div class="form-check" key={option.id}>
                    <input
                        className={`form-check-input ${(touched && error) ? "is-invalid" : ""}`}
                        type="radio"
                        id={input.name + "_" + option.id}
                        autocomplete={autocomplete}
                        value={option.id}
                        checked={values == option.id}
                        onChange={(e) => { _onChange(e, option) }}
                        disabled={disabled}
                    />
                    <label class="form-check-label" for={input.name + "_" + option.id} >
                        {ReactHtmlParser(option.name)}
                    </label>
                    {options.length == (index + 1) && <div class="invalid-feedback">{error}</div>}
                </div>
            </>)}
        </div>
    </div>;
};

export const CheckboxForRedux = (props) => {
    let { options, disabled, autocomplete, input, meta: { touched, error }, decorateName } = props;

    if (!decorateName) {
        decorateName = name => name;
    }

    return <>
        <div className={props.className}>
            {props.label && <label for={props.id} class="form-label" >{props.label}</label>}
            <div className='ps-2'>
                {options.map((option, index) => <>
                    <div class="form-check" key={option.id}>
                        <input
                            className={`form-check-input ${(touched && error) ? "is-invalid" : ""}`}
                            type="checkbox"
                            id={input.name + "_" + option.id}
                            autocomplete={autocomplete}
                            value={option.id}
                            checked={(input.value != "" && input.value.length > 0 && input.value.indexOf(option.id) !== -1)}
                            onChange={(event) => {
                                const newValue = [...input.value];
                                if (event.target.checked) {
                                    newValue.push(option.id);
                                } else {
                                    newValue.splice(newValue.indexOf(option.id), 1);
                                }
                                return input.onChange(newValue);
                            }}
                            disabled={disabled}
                            name={input.name}
                        />
                        <label class="form-check-label" for={input.name + "_" + option.id} >
                            {decorateName(option.name)}
                        </label>
                        {options.length == (index + 1) && <div class="invalid-feedback">{error}</div>}
                    </div>
                </>)}
            </div>
        </div>
    </>

}


export const ShowErrorForRedux = ({ input, meta, ...props }) => (
    (meta.error && meta.error != '') ? <span {...props} className={`text-danger ${props.className}`}>{meta.error}</span> : null
)

export const ShowErrorInAlertForRedux = ({ input, meta, ...props }) => (
    (meta.error && meta.error != '') ? <div {...props} className={`alert alert-danger ${props.className}`}>{meta.error}</div> : null
)

export const ReactSelect = (props) => {
    return <div className={props.className}>
        {props.label && <label className='form-label'>{props.label}</label>}
        <Select
            id={props.id}
            className={props.error && props.error != '' ? "react-select-invalid" : ""}
            onChange={props.onChange}
            options={props.options}
            value={props.options ? props.options.filter(option => option.value === props.value) : null}
            isDisabled={props.disabled}
            isOptionDisabled={props.isOptionDisabled}
        />
        {props.error && props.error != '' && <div className='invalid-feedback'>{props.error}</div>}
    </div>
}

export class CreatableSelectForRedux extends React.Component {

    createOption = (label) => ({
        label,
        value: label,
        id: 0,
        status: "new"
    });

    components = {
        DropdownIndicator: null,
    };

    state = {
        inputValue: '',
        value: [],
        warning: null
    };

    handleInputChange = (inputValue) => {
        if (inputValue && inputValue.trim !== '') {
            this.setState({ inputValue });
        }
    };

    handleChange = (value, actionMeta) => {

        if (!value || value.length == 0) {
            value = [];
        }

        this.setState({ value }, () => { this.props.input.onChange(value) });
    };


    handleKeyDown = (event) => {
        let { inputValue, value } = this.state;
        if (!inputValue) return;
        switch (event.key) {
            case 'Enter':
            case 'Tab':
                this.updateChanges(inputValue, value);
                event.preventDefault();
        }
    };

    updateChanges = (inputValue, value) => {
        if (!value || value.length == 0) {
            value = [];
            if (this.props.options.length > 0)
                value = this.props.options.concat(value);
        }
        if (value != null && value.length > 0) {
            if (
                value.filter(function (option) {
                    return option.value === inputValue;
                }).length > 0
            ) {
                alert("Duplicate Value: " + inputValue)
            }
        }
        this.setState({
            inputValue: '',
            value: [...value, this.createOption(inputValue)],
        }, () => {

            this.props.input.onChange(this.state.value);
        });
    }

    handlePressButton = param => (event) => {
        event.preventDefault();
        let inputValue = param;
        let value = this.state.value;
        if (!inputValue) return;
        this.updateChanges(inputValue, value);
    };

    render() {
        const { disabled, options, placeholder, meta: { touched, error } } = this.props;
        let { inputValue, value } = this.state;
        if (value && value.length == 0 && options.length)
            value = options.concat(value);

        return <div className='row'>
            <div class='col mb-3'>
                <CreatableSelect
                    className={(touched && error) ? "react-select-invalid" : ""}
                    components={this.components}
                    inputValue={inputValue}
                    isClearable
                    isMulti
                    menuIsOpen={false}
                    onChange={this.handleChange}
                    onInputChange={this.handleInputChange}
                    onKeyDown={this.handleKeyDown}
                    placeholder={placeholder}
                    value={value}
                    isDisabled={disabled}
                />
                <div className='invalid-feedback'>{error}</div>
                {this.state.warning}
            </div>
            <div class='col-md-auto'> <button onClick={this.handlePressButton(inputValue)} disabled={disabled} className='btn btn-primary'>+</button></div>
        </div>
    }
}

export class Popover extends React.Component {

    #popover;
    #popoverId;

    constructor(props) {
        super(props)
        if (props.id) this.#popoverId = props.id;
        else this.#popoverId = 'popover_' + (new Date()).getTime();
    }

    componentWillUnmount() {
        if (this.#popover) {
            this.#popover.dispose();
        }

    }

    componentDidMount() {
        this.#popover = new bootstrap.Popover(document.getElementById(this.#popoverId));
    }

    render() {
        return <span
            id={this.#popoverId}
            style={{ "cursor": "pointer" }}
            className={` ${this.props.className}`}
            data-bs-toggle="popover"
            data-bs-html={true}
            data-bs-content={this.props.content}
        >{this.props.label?this.props.label : <QuestionFill />}
        </span>
    }
}

export const StepProgressBar = (props) => {

    const totalSteps = props.totalSteps ? props.totalSteps : 1;
    const currentStep = props.currentStep ? props.currentStep : 1;

    const percentageGap = (100 / (totalSteps - 1));



    return <div className='mx-3'>
        <div class="position-relative">
            <div class="progress" style={{ "height": "1px" }}>
                <div class="progress-bar" role="progressbar" style={{ "width": percentageGap * (currentStep - 1) + "%" }} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
            {function () {
                const result = [];
                for (let i = 0; i < totalSteps; i++) {
                    var extraClass = "btn-primary";
                    var style = {
                        "width": "2em",
                        "height": "2em",
                        "left": (percentageGap * i) + "%"
                    };
                    var disabled = false;
                    if (i + 1 > currentStep) {
                        if (props.disableFutureSteps) {
                            extraClass = "btn-light border-dark"
                            disabled = true;
                        }
                    } else if (i + 1 == currentStep) {
                        style = {
                            "width": "3em",
                            "height": "3em",
                            "left": (percentageGap * i) + "%"
                        };
                    }

                    result.push(<button type="button" disabled={disabled} onClick={() => {
                        if (!props.stepClick) return;
                        props.stepClick((i + 1))
                    }} class={`position-absolute top-0 translate-middle btn btn-sm rounded-pill ${extraClass}`} style={style}>{i + 1}</button>);
                }
                return result;
            }()}
        </div>
    </div>

}


export const UneditableTextInput = (props) => {
    const {error,label,value, fieldExtraClass} = props;
    return <>
        {label? <label class="form-label">{label}</label> : null}
        <div className={`card  ${error?'border-danger':''} ${fieldExtraClass?fieldExtraClass:''}`}>
            <div className='card-body p-2' style={{'white-space':'pre'}}>
                {value?value:<em className='text-muted invisible'>Empty..</em>}
            </div>
        </div>
        {error?<div className='text-danger'>{error}</div>:null}
    </>
}

export const ReactDateTimePicker = (props) =>{
    const {error,label,value,onChange, maxDate, disabled} = props;
    return <div className={`react-datepicker-block ${error?'react-date-invalid':''}`}>
        {label ? <label class="form-label">{label}</label> : null}
        <DatePicker
            className={`form-control ${error?'is-invalid':''}`}
            maxDate={maxDate}
            onChange={onChange}
            selected={value}
            showTimeSelect
            timeIntervals={15}
            dateFormat={"dd/MM/yyyy h:mm aa"}
            timeCaption="Time"
            disabled={disabled}
        />
        <div className='invalid-feedback'>{error}</div>
    </div>
}

export const ReactDatePicker = (props) =>{
    const {error,label,value,onChange, maxDate, disabled} = props;
    return <div className={`react-datepicker-block ${error?'react-date-invalid':''}`}>
        {label ? <label class="form-label">{label}</label> : null}
        <DatePicker
            className={`form-control ${error?'is-invalid':''}`}
            maxDate={maxDate}
            onChange={onChange}
            selected={value}
            dateFormat={"dd/MM/yyyy"}
            disabled={disabled}
        />
        <div className='invalid-feedback'>{error}</div>
    </div>
}