import React, { useContext, useEffect, useState } from 'react';
import { formatDate, enumerateTimePeriod, roundOff, MANDATORY } from '../constants/MeterFormConstants'
import { GlobalContext } from './State';
import DatePicker from 'react-datepicker';
import { TransactionTable } from './TransactionTable';
import Select from 'react-select';
import $ from 'jquery';
import { submission, getDasData, getRatingTableContents, deleteDischarge, deleteFlow } from './API';
import {
  INTAKE,
  DISCHARGE,
  DATE_FORMAT,
  START,
  FLOW_WATER_SOURCE,
  DISCHARGE_WATER_SOURCE,
  DISCHARGE_AMOUNT,
  DISCHARGE_START_DATE,
  DISCHARGE_END_DATE,
  filterWaterSource,
  TIMESTAMP,
  PRIMARY_HOLDER,
  parseISODate,
  colourText,
  OBJECT_MANUAL_ENTRY,
  OBJECT_DAS_ENTRY,
  MANUAL_ENTRY,
  NON_COMPLIANCE_REASONS,
  TIME_AT_START_OF_DAY,
  DAY_BEFORE,
  HOURS_24,
  LOG_ENTRY_TYPE,
  PRIOR_GAUGE_HEIGHT,
  NEW_GAUGE_HEIGHT,
  MSG_SAVE,
  PENDING,
  RENDER_CONDITIONAL,
  MESSAGE,
  SPAN_RED
} from './MeasurementConstants';

import "react-datepicker/dist/react-datepicker.css";
import { validatePage2 as validate, isDateTheLatest } from './Validate'
import { end } from '@popperjs/core';




const BORDER_DEFAULT = '1px solid';
const BORDER_DEFAULT_COLOR = '#CCC';
const COL_WIDTH = '20%';
const COL_WIDTH_DAILY_TAKE = '16.6666666667%';

export const Measurement = () => {

  const { holder, updateNomination, addNewElement, delElement, flickPage, loadNominiation, setentryLog, setManualEntryHeight, setPriorEntryHeight, setManualEntryFlag, setAHDHeight, setDasDataAlertDisplayed } = useContext(GlobalContext);
  const [flowQuestion, setFlowQuestion] = useState(0);
  const [currentDayDasDataExist, setCurrentDayDasDataExist] = useState(true);
  const [dischargeQuestion, setDischargeQuestion] = useState(0);
  const [timestamp, setTimestamp] = useState();
  const [dasData, setDasData] = useState();

  const onPriorEntry = (e) => {
    setPriorEntryHeight(e.value);
  }

  const onSubmit = () => {
    doAfterValidate(true,
      () => setTimestamp(TIMESTAMP())
    )
  }

  const onDischargeDelete = (id) => {
    var message = "";
    message = "Are you sure you want to delete?";
    const proceed = window.confirm(message);
    if (proceed) {
      deleteDischarge(id,
        (id) => {
          var ids = Object.keys(holder.nomination.discharge);
          var discid = ids.filter(function (option) { return id == option });
          if (discid.length > 0) {
            delete holder.nomination.discharge[discid[0]];
            delElement(DISCHARGE);
          }
        }
        ,
        () => {
          alert(MSG_SAVE.ERROR);
        })
    }
  }

  const onFlowDelete = (id) => {
    var message = "";
    message = "Are you sure you want to delete?";
    const proceed = window.confirm(message);
    if (proceed) {
      deleteFlow(id,
        (id) => {
          var ids = Object.keys(holder.nomination.flow);
          var flowid = ids.filter(function (option) { return id == option });
          if (flowid.length > 0) {
            delete holder.nomination.flow[flowid[0]];
            debugger;
            delElement(INTAKE);
          }
        }
        ,
        () => {
          alert(MSG_SAVE.ERROR);
        })
    }
  }

  const doNext = () => {
    doAfterValidate(false,
      () => flickPage(3)
    )
  }

  const setAHDHeightLocal = (value, index) => {
    setAHDHeight(value, index);
    resetError();
  }

  const renderContitional = (useCase, row) => {
    let isNotPending = (row.status !== PENDING);
    switch (useCase) {
      case RENDER_CONDITIONAL.HEIGHT:
        return isNotPending ? roundOff(row.height) : 'DAS data not available for today';

      case RENDER_CONDITIONAL.AREA:
        return isNotPending ? row.area : ''

      case RENDER_CONDITIONAL.VOLUME:
        return isNotPending ? row.volume : ''

      case RENDER_CONDITIONAL.DELTA_VOLUME:
        return isNotPending ? roundOff(row.deltaVolume) : 'NA. This date will not be used in calculations'
    }
  }

  const CurrentDasEntryCheck = () => {
    if (holder.nomination.timeRange.endDate != null) {
      let enddate = TIME_AT_START_OF_DAY(holder.nomination.timeRange.endDate);
      let todaydate = TIME_AT_START_OF_DAY(new Date(new Date().getTime()));
      if (enddate == todaydate) {
        let dasLastEntry = holder.nomination.logEntry.entryLog.find(row => TIME_AT_START_OF_DAY(row.entryDate) === enddate);
        console.log('dasLastEntry');
        console.log(dasLastEntry);
        if (dasLastEntry == null) {
          setCurrentDayDasDataExist(false);
        }
        else {
          if (dasLastEntry.status !== PENDING)
            setCurrentDayDasDataExist(true);
          else
            setCurrentDayDasDataExist(false);
        }
      }
    }
  }

  const doAfterValidate = (isDraft, postValidate) => {
    debugger;
    if (currentDayDasDataExist) {
      let errors = validate(isDraft, holder);
      if (errors.length > 0) {
        markValidationErrors(errors, isDraft);
      } else {
        postValidate();
      }
    }
    else {
      alert("Measurement Period Closure Pending - Final Telemetry data not yet received.");
    }

  }

  const markValidationErrors = (errorArray, isDraft) => {
    errorArray.forEach(element => {
      let fieldLabel = '#' + element.field;
      let fieldMessage = '#' + element.field + MESSAGE;
      $(fieldLabel).css('border', '1px solid #eb516d');
      if (element.message) {
        $(fieldMessage).html(element.message);
      }
    });
  }

  const resetError = () => {
    resetErrorForElement(PRIMARY_HOLDER);
    resetErrorForElement("endDate");
    resetErrorForElement("startDate");
    resetErrorForElement(MANUAL_ENTRY + PRIOR_GAUGE_HEIGHT);
    holder.nomination.logEntry.entryLog.map((row, index) => {
      resetErrorForElement(MANUAL_ENTRY + NEW_GAUGE_HEIGHT + index);
    });
  }

  const resetErrorForElement = (element) => {
    $('#' + element).css('border', BORDER_DEFAULT).css('border-color', BORDER_DEFAULT_COLOR);
    $('#' + element + MESSAGE).html('');
  }

  const calculateDeltaVolumes = (entryLog, priorDateObject) => {
    debugger;
    console.log("entryLog is ");
    console.log(entryLog);
    console.log("priorDateObject_is ");
    console.log(priorDateObject);
    if (!entryLog || !Array.isArray(entryLog) || entryLog.length == 0) {
      return entryLog;
    }
    console.log("entryLog_calculated");

    for (let i = 0; i < entryLog.length; i++) {
      if (i == 0) {
        entryLog[i].deltaVolume = entryLog[i].volume - priorDateObject.volume;
      } else {
        entryLog[i].deltaVolume = entryLog[i].volume - entryLog[i - 1].volume;
      }
    }
    return entryLog;
  }

  const onHolderName = (e) => {
    resetError();
    updateNomination({ holderName: e.target.value });

  }

  const onDateChange = (type, e) => {
    resetError();

    if (type == START) {
      updateNomination({ startDate: e });
    } else {
      let code = isDateTheLatest(e, holder.referenceData.completedForms);

      if (code && code > 0) {
        updateNomination({ endDate: e, nonCompliantCode: code });

      } else {
        updateNomination({ endDate: e });
      }
    }
  }

  /**
   * 
   * @param key entire object 
   * @param {*} e changed new value
   * @param {*} index index in array
   */
  const onManualEntry = (key, e, index) => {
    console.log(`key= [${key.id}] Value_selected= [${e.id}] index=[${index}]`);
    setManualEntryHeight(key.id, e.value, index);
  }

  const onChangeSourceType = (index, type, key, e) => {
    resetError();
    if (type == INTAKE) {
      resetErrorForElement(FLOW_WATER_SOURCE + index);
      updateNomination({ key: key, subType: INTAKE, waterSource: e.value });
    } else {
      resetErrorForElement(DISCHARGE_WATER_SOURCE + index);
      updateNomination({ key: key, subType: DISCHARGE, waterSource: e.value });
    }
  }

  const filterManualEntries = (id) => {
    console.log("id in filterManualEntries is:" + id);
    console.log("ratingTableContents filterManualEntries is:");
    console.log(holder.nomination.ratingTableContents);

    if (!id) {
      return undefined;
    }
    let retVal = holder.nomination.ratingTableContents.find(entry => entry.elevation == id);
    console.log("retVal_in_filterManualEntries" + JSON.stringify(retVal));
    return retVal;
  }



  const onFlowText = (type, key, index) => e => {
    resetError();
    if (type == INTAKE) {
      resetErrorForElement('flowAmount' + index);
      updateNomination({ key: key, subType: INTAKE, amount: e.target.value });

    } else {
      resetErrorForElement(DISCHARGE_AMOUNT + index);
      updateNomination({ key: key, subType: DISCHARGE, amount: e.target.value });
    }
  }

  const onDischargeDate = (index, key, type) => e => {
    debugger;
    resetError();
    if (type === START) {
      resetErrorForElement(DISCHARGE_START_DATE + index);
      updateNomination({ key: key, subType: DISCHARGE, startDate: e });
    } else {
      resetErrorForElement(DISCHARGE_END_DATE + index);
      updateNomination({ key: key, subType: DISCHARGE, endDate: e });
    }

  }

  const updateTextArea = e => {
    let input = e.target.value;
    if (e.target.value == "" || e.target.value != "")
      updateNomination({ nonCompliantComments: e.target.value });
  }

  const changeLogEntryType = (e) => {
    if (e.target.checked)
      setManualEntryFlag(LOG_ENTRY_TYPE.MANUAL);
    else
      setManualEntryFlag(LOG_ENTRY_TYPE.DAS);

    updateNomination({ measurementDeviceDamaged: e.target.checked });
  }

  const onFlowQuestion = (type, val) => {
    resetError();
    switch (type) {
      case INTAKE:
        if (val === 1 && Object.keys(holder.nomination.flow).length == 0) {
          addNewElement(INTAKE);
        }
        setFlowQuestion(val);
        break;

      default:
        if (val === 1 && Object.keys(holder.nomination.discharge).length == 0) {
          addNewElement(DISCHARGE);
        }
        setDischargeQuestion(val);
    }
  }

  const doManualLog = () => {
    let entryLog = holder.nomination.logEntry.entryLog;
    let id = TIMESTAMP();
    if (holder.nomination.timeRange?.startDate) {
      enumerateTimePeriod(holder.nomination.timeRange.startDate, holder.nomination.timeRange.endDate).forEach(date => {
        if (!entryLog.find(entry => TIME_AT_START_OF_DAY(entry.entryDate) == TIME_AT_START_OF_DAY(date))) {
          entryLog.push(
            OBJECT_MANUAL_ENTRY(date, id++)
          )
        }
      }
      );
      debugger;
      setentryLog(entryLog.sort((a, b) => Date.parse(a.entryDate) > Date.parse(b.entryDate) ? 1 : -1));
    }
  }

  const doDasLog = (data) => {
    let id = TIMESTAMP();
    let entryLog = holder.nomination.logEntry.entryLog;
    setManualEntryFlag(LOG_ENTRY_TYPE.DAS);
    console.log('DAS data sourced sucessfully');

    let priorDateObject;
    if (holder.nomination.timeRange?.startDate) {
      debugger;
      let priorDate = DAY_BEFORE(holder.nomination.timeRange.startDate);
      priorDateObject = OBJECT_DAS_ENTRY(new Date(priorDate), id++, data.data, holder.nomination.ratingTableContents)
      console.log("setPriorEntryHeight in DAS is" + priorDateObject.height);
      setPriorEntryHeight(priorDateObject.height, priorDateObject.area, priorDateObject.volume);
    }
    if (holder.nomination.timeRange?.startDate) {
      enumerateTimePeriod(holder.nomination.timeRange.startDate, holder.nomination.timeRange.endDate).forEach(date => {
        if (!entryLog.find(entry => TIME_AT_START_OF_DAY(entry.entryDate) == TIME_AT_START_OF_DAY(date))) {
          entryLog.push(
            OBJECT_DAS_ENTRY(date, id++, data.data, holder.nomination.ratingTableContents)
          )

        }
      }
      );
      CurrentDasEntryCheck();
      setentryLog(calculateDeltaVolumes(entryLog.sort((a, b) => Date.parse(a.entryDate) > Date.parse(b.entryDate) ? 1 : -1), priorDateObject));
    }
  }

  const copyRow = (index) => {
    let entryLog = holder.nomination.logEntry.entryLog;
    for (let idx = 0; idx < entryLog.length; idx++) {
      if (idx === index) {
        let targetLog = entryLog[idx];
        if (index == 0) {
          cloneLogRecord(targetLog, holder.nomination.logEntry.priorDayReading)
        } else {
          cloneLogRecord(targetLog, entryLog[idx - 1])
        }
      }
    }

    setentryLog(entryLog);

  }

  let cloneLogRecord = (source, dest) => {

    Object.keys(dest).filter(key => !(key == 'entryDate' || key == 'id')).map(thisKey => source[thisKey] = dest[thisKey]);
  }


  useEffect(() => {
    if (timestamp) {
      submission(holder.nomination,
        (data) => {
          loadNominiation(data, 2);
          alert(MSG_SAVE.SUCCESS);
        },
        () => {
          alert(MSG_SAVE.ERROR);
        })
    }
  }, [timestamp]);

  useEffect(() => {
    if (holder?.referenceData?.userid && !holder.nomination.attributes.createdBy) {
      updateNomination({ userid: holder.referenceData.userid });
    }

  }, []);


  useEffect(() => {
    debugger;
    let ratingTableId = holder.nomination?.ratingTable?.id;
    if (ratingTableId) {
      const triggerManualLog = () => {
        setManualEntryFlag(LOG_ENTRY_TYPE.MANUAL);
        debugger;
        console.log('DAS has no data');
        doManualLog();
      }

      getDasData(ratingTableId,
        (data) => {
          debugger;
          // data.data = undefined; //SWITCH_OFF_DAS
          if (!data || !data.data || !Array.isArray(data.data) || (data.data.length == 0)) {//TODO check if data exists for Date range

            if (holder.nomination.attributes.formStatus < 2 && !holder.dasDataAlertDisplayed) {
              console.log("dasDataAlertDisplayed: " + holder.dasDataAlertDisplayed);
              alert("DAS has no data for the chosen storage, use manual log");
              setDasDataAlertDisplayed(true);
            }
            triggerManualLog();

          } else {
            let start = TIME_AT_START_OF_DAY(new Date(new Date().getTime() - HOURS_24));
            let dasEntry = data.data.find(row => TIME_AT_START_OF_DAY(row.date) === start);

            console.log('dasEntry');
            console.log(dasEntry);

            if (dasEntry) {
              console.log(holder.nomination.ratingTableContents);
              setDasData(data.data);
              doDasLog(data)
              let ratingTableEntry = holder.nomination.ratingTableContents?.find(entry => entry.elevation == dasEntry.elevation);
              console.log('ratingTableEntry');
              console.log(ratingTableEntry);
              debugger;
              if (!ratingTableEntry) {
                console.log("Rating Table mismatch with DAS data use manual log");

              }
            } else {
              alert("DAS has no data for " + formatDate(new Date()) + " use manual log");
              triggerManualLog();
            }
          }
        },
        (error) => {
          debugger;
          console.log('error sourcing DAS data. ' + JSON.stringify(error));
          alert("Error fetching DAS data.");
          console.log(error);
          triggerManualLog();
        },
        () => {
          console.log('DAS data call performed');

        }
      )
    }
    CurrentDasEntryCheck();
  }, [holder.nomination.timeRange?.startDate, holder.nomination.timeRange?.endDate]);


  return (
    <>
      <h3>New Measurement Period</h3>
      {<div>
        <div id="warnFlag" class="visually-hidden alert alert-warning" role="alert"></div>
        <div className="row">
          <div className="col-6">
            <div>
              <label>Storage Identifier</label>
              <input type="text" class="form-control" placeholder="ROI / Storage work approval" disabled={true} value={holder.nomination.ratingTable.ratingTableName} />
            </div>
          </div>
          <div className="col-6">
            <div>
              <label>Primary Holder</label>{MANDATORY}
              <input type="text"
                id={PRIMARY_HOLDER}
                class="form-control"
                placeholder="Name"
                onChange={onHolderName}
                disabled={holder.nomination.attributes.formStatus > 1}
                value={holder.nomination.holderName} />
              <span style={SPAN_RED} id={PRIMARY_HOLDER + MESSAGE}></span>
            </div>
          </div>
          <div style={{ height: '50px' }}>&nbsp;</div>
        </div>
        {<div class="row">
          <hr style={{ fontSize: "25px" }} />
          <div className="col-6">
            <label id="startDateLabel">Measurement Period Start date/time</label>{MANDATORY}<br />
            <DatePicker
              selected={parseISODate(holder.nomination.timeRange.startDate)}
              dateFormat={DATE_FORMAT}
              timeCaption="Time"
              showTimeSelect
              id="startDate"
              onChange={e => { onDateChange(START, e) }}
              maxDate={new Date()}
              readOnly={holder.nomination.attributes.formStatus > 1}
            /><br /> <span style={SPAN_RED} id="startDateMessage"></span>
          </div>
          <div class="col-6">
            <label id='endDateLabel'>Measurement Period End date/time</label>{MANDATORY}
            {<div><DatePicker

              selected={parseISODate(holder.nomination.timeRange.endDate)}
              dateFormat={DATE_FORMAT}
              timeCaption="Time"
              showTimeSelect
              id="endDate"
              maxDate={new Date()}
              onChange={e => { onDateChange('', e) }}
              readOnly={holder.nomination.attributes.formStatus > 1} /><span style={SPAN_RED} id="endDateMessage"></span></div>
            }
          </div>
        </div>
        }

        {(holder.nomination.nonCompliantCode > 0) &&
          <div class="row">
            <div class="col-12 warn warning alert alert-warning">
              <label>&#9888;</label><label> {NON_COMPLIANCE_REASONS.warningText[holder.nomination.nonCompliantCode - 1]}</label><br />
              <br />{NON_COMPLIANCE_REASONS.inputMessage[holder.nomination.nonCompliantCode - 1]}<br />
              <div class="col-12"><textarea
                value={holder.nomination.nonCompliantComments}
                rows="8"
                cols="90"
                onChange={e => updateTextArea(e)
                }
                disabled={holder.nomination.attributes.formStatus > 1}>
              </textarea></div>
            </div>
          </div>
        }

        {(holder.nomination.timeRange.endDate != null) &&
          <div class="row">
            <div class="col-9">
              <div className="clear" /><br />
              <label>Was there any other in-flow during the measurement period?&nbsp;&nbsp;</label>
              <input type="radio"
                checked={flowQuestion === 1 || Object.keys(holder.nomination.flow).length > 0}
                value="1"
                disabled={holder.nomination.attributes.formStatus > 1}
                name="flowQuestion"
                onChange={() => onFlowQuestion(INTAKE, 1)}></input>Yes
              &nbsp; &nbsp;
              <input type="radio"
                disabled={holder.nomination.attributes.formStatus > 1}
                value="0"
                name="flowQuestion"
                onChange={() => onFlowQuestion(INTAKE, 0)}>
              </input>No
            </div>
          </div>
        }
        <div>
          {(Object.keys(holder.nomination.flow).length > 0) &&
            (
              <div>
                <div>
                  {Object.keys(holder.nomination.flow).map((key, index) => {
                    return (
                      <div class="row">
                        <div class="col-1">&nbsp;</div>
                        <div class="col-9">
                          <div>
                            <svg width="8" height="10">
                              <rect id="backgroundrect" width="100%" height="100%" x="0" y="0" fill-opacity="0" stroke="none" />
                            </svg>
                          </div>
                          <div key={index} class="row" style={{ border: 'solid 2px black' }}>
                            <table >
                              <tr>
                                <td width="50%"><label>Type of water</label>{MANDATORY}</td>  <td width="50%"><label>Volume (ML)</label>{MANDATORY} </td>
                              </tr>
                              <tr>
                                <td width="50%">
                                  <div style={{ width: "50%" }}>
                                    <Select id={FLOW_WATER_SOURCE + index} classNamePrefix={'react-select-flow' + index}
                                      isDisabled={holder.nomination.attributes.formStatus > 1}
                                      value={filterWaterSource(holder.nomination.flow[key].waterSource, holder.referenceData)}
                                      onChange={event => onChangeSourceType(index, INTAKE, key, event)} options={holder.referenceData.watersources} ></Select>
                                    <span style={SPAN_RED} id={FLOW_WATER_SOURCE + key + MESSAGE}></span>
                                  </div>
                                </td>
                                <td>
                                  <input type="text"
                                    id={'flowAmount' + index}
                                    disabled={holder.nomination.attributes.formStatus > 1}
                                    value={holder.nomination.flow[key].amount} onChange={onFlowText(INTAKE, key, index)} />
                                  <span style={SPAN_RED} id={'flowAmount' + index + MESSAGE}></span>
                                  {(holder.nomination.flow[key].id != null && holder.nomination.flow[key].id > 0) &&
                                    <button type="button" className="Button btn-m3" id="DEL_ADDITIONAL_FLOW" style={{ float: 'right' }} disabled={holder.nomination.attributes.formStatus > 1}
                                      onClick={() => onFlowDelete(holder.nomination.flow[key].id)}>
                                      Delete Flow
                                    </button>
                                  }
                                </td>
                              </tr>
                            </table>
                          </div>
                        </div>
                      </div>
                    )
                  }
                  )}
                </div>
                {holder.nomination.attributes.formStatus < 2 &&
                  <div class="row">
                    <div class="col-2">&nbsp;</div>
                    <div class="col-9">
                      <button type="button" className="Button btn-m3" id="ADD_ADDITIONAL_FLOW" style={{ float: 'right' }} disabled={holder.nomination.attributes.formStatus > 1}
                        onClick={() => addNewElement(INTAKE)}>
                        Add Additional inflow
                      </button>
                    </div>
                    <div class="col-3"><div class="clear" /></div>
                  </div>
                }
              </div>
            )
          }
        </div>
        {
          <div class="row">
            <div class="col-9">
              <div className="clear" /><br />
              <label>Has there been any Discharge during this measurement period?</label>
              &nbsp; &nbsp;<input type="radio" disabled={holder.nomination.attributes.formStatus > 1}
                value="1" checked={dischargeQuestion === 1 || Object.keys(holder.nomination.discharge).length > 0}
                id="dischargeQuestion" name="dischargeQuestion" onChange={() => onFlowQuestion(DISCHARGE, 1)}></input>Yes
              &nbsp; &nbsp;<input type="radio" disabled={holder.nomination.attributes.formStatus > 1}
                value="0" name="dischargeQuestion" onChange={() => onFlowQuestion(DISCHARGE, 0)}></input>No
            </div>
            <div>
              {
                (Object.keys(holder.nomination.discharge).length > 0) && (
                  <div class="row"> <div class="col-1">&nbsp;</div>
                    <div class="col-9">
                      {
                        Object.keys(holder.nomination.discharge).map((key, index) => {
                          return (<div>
                            <div style={{ border: 'height: 10px' }}>
                              <svg width="8" height="10"><rect id="backgroundrect" width="100%" height="100%" x="0" y="0" fill-opacity="0" stroke="none" /></svg>
                            </div>
                            <div key={index} class="row" style={{ border: 'solid 2px black' }}>
                              {
                                <table border='0'>
                                  <tbody>
                                    <tr>
                                      <td><label>Discharge Start date/time</label>{MANDATORY}{
                                        <DatePicker
                                          id={DISCHARGE_START_DATE + index}
                                          style={{ width: '30 px' }}
                                          selected={parseISODate(holder.nomination.discharge[key].startDate)}
                                          dateFormat={DATE_FORMAT}
                                          maxDate={new Date()}
                                          timeCaption="Time"
                                          showTimeSelect
                                          onChange={onDischargeDate(index, key, START)}
                                          readOnly={holder.nomination.attributes.formStatus > 1} />
                                      }
                                        <span style={SPAN_RED} id={DISCHARGE_START_DATE + index + MESSAGE}></span>
                                      </td>
                                      <td width="20%"></td>
                                      <td width="40%"><label>Discharge end datetime</label>{MANDATORY} {
                                        <DatePicker
                                          id={DISCHARGE_END_DATE + index}
                                          width='100%'
                                          dateFormat={DATE_FORMAT}
                                          timeCaption="Time of the day"
                                          maxDate={new Date()}
                                          showTimeSelect
                                          onChange={onDischargeDate(index, key, '')}
                                          selected={parseISODate(holder.nomination.discharge[key].endDate)}
                                          readOnly={holder.nomination.attributes.formStatus > 1} />}
                                        <span style={SPAN_RED} id={DISCHARGE_END_DATE + index + MESSAGE}></span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td width="50"><div><label>Type of water</label>{MANDATORY}</div><div style={{ width: "60%" }}>
                                        <Select id={DISCHARGE_WATER_SOURCE + index} classNamePrefix={'react-select-discharge' + index}
                                          isDisabled={holder.nomination.attributes.formStatus > 1}
                                          value={filterWaterSource(holder.nomination.discharge[key].waterSource, holder.referenceData)}
                                          onChange={event => onChangeSourceType(index, DISCHARGE, key, event)}
                                          options={holder.referenceData.watersources} ></Select>
                                        <span style={SPAN_RED} id={DISCHARGE_WATER_SOURCE + index + MESSAGE}></span>
                                      </div></td>
                                      <td width="20%"></td>
                                      <td>
                                        <label>Volume (ML)</label>{MANDATORY} <br />
                                        <input id={DISCHARGE_AMOUNT + index}
                                          disabled={holder.nomination.attributes.formStatus > 1}
                                          type="text" onChange={onFlowText(DISCHARGE, key, index)} value={holder.nomination.discharge[key].amount} />
                                        <span style={SPAN_RED} id={DISCHARGE_AMOUNT + index + MESSAGE}></span>
                                        {(holder.nomination.discharge[key].id != null && holder.nomination.discharge[key].id > 0) &&
                                          <button type="button" className="Button btn-m3" id="DEL_ADDITIONAL_DISCHARGE" style={{ float: 'right' }} disabled={holder.nomination.attributes.formStatus > 1}
                                            onClick={() => onDischargeDelete(holder.nomination.discharge[key].id)}>
                                            Delete Discharge
                                          </button>
                                        }
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>}
                            </div>
                          </div>)
                        })
                      }
                    </div>
                    {holder.nomination.attributes.formStatus < 2 &&
                      <div class="row">
                        <div class="col-11">
                          <button type="button" className="Button btn-m3" style={{ float: 'right' }} id="ADD_ADDITIONAL_DISCHARGE" disabled={holder.nomination.attributes.formStatus > 1}
                            onClick={() => addNewElement(DISCHARGE)}>
                            Add Additional discharge
                          </button>
                        </div>
                      </div>
                    }


                  </div>)
              }
            </div>
          </div>
        }


        {
          <div class="row">
            <div class="col-9">
              <div className="clear" /><br />
              <label>My storage measurement device is damaged, I need to enter readings manually.</label>
              &nbsp; &nbsp;<input type="checkbox" disabled={holder.nomination.attributes.formStatus > 1}
                //checked={dischargeQuestion === 1 || Object.keys(holder.nomination.discharge).length > 0}
                checked={holder.nomination.measurementDeviceDamaged == true}
                id="storageMeasurementDeviceDamaged" name="storageMeasurementDevice"
                onChange={(e) => changeLogEntryType(e)}
              ></input>
            </div>
            <div>
            </div>
          </div>
        }
      </div>
      }
      <div class="mb3" >&nbsp;</div>
      <hr style={{ fontSize: "25px" }} />
      <div class="mb3" >&nbsp;</div>
      <div >

        {((holder.nomination.logEntry.type === LOG_ENTRY_TYPE.MANUAL || holder.nomination.logEntry.type === LOG_ENTRY_TYPE.DAS) && holder.nomination.logEntry.entryLog.length > 0) &&
          <React.Fragment>
            <h4>{holder.nomination.logEntry.type === LOG_ENTRY_TYPE.MANUAL ? "Manual Log Book" : "Reading From Telemetry"} </h4>
            <div style={{ border: 'solid 2px black' }}>
              <div class="row"><div >&nbsp;</div></div>
              <div class="row"> <h4>&nbsp;Reading prior to start period</h4></div>
              <div class="row">
                <div style={{ width: '0.025%' }}></div>
                <div style={{ width: '97.5%' }}>
                  <table class="table table-bordered" border='1'>
                    <thead bgcolor="#0054a6">
                      <th width={COL_WIDTH}>{colourText('Date')}</th>
                      {(holder.nomination.logEntry.type === LOG_ENTRY_TYPE.MANUAL) &&
                        <th width={COL_WIDTH}>{colourText('Height (Gauge)')}</th>
                      }
                      <th width={COL_WIDTH}>{colourText('Height (AHD)')}</th>
                      <th width={COL_WIDTH}>{colourText('Area')}</th>
                      <th width={COL_WIDTH}>{colourText('Volume')}</th>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{formatDate(DAY_BEFORE(holder.nomination.timeRange.startDate))}</td>
                        {(holder.nomination.logEntry.type === LOG_ENTRY_TYPE.MANUAL) &&
                          <td>
                            <input type="text"
                              id={MANUAL_ENTRY + PRIOR_GAUGE_HEIGHT}
                              disabled={holder.nomination.attributes.formStatus > 1}
                              value={holder.nomination.logEntry.priorDayReading.gaugeHeight} onChange={e => setAHDHeightLocal(e.target.value, -1)} />
                            <span style={SPAN_RED} id={MANUAL_ENTRY + PRIOR_GAUGE_HEIGHT + MESSAGE}></span>
                          </td>

                        }
                        {(holder.nomination.logEntry.type === LOG_ENTRY_TYPE.DAS) &&
                          <td>{holder.nomination.logEntry.priorDayReading.height}</td>
                        }
                        {(holder.nomination.logEntry.type === LOG_ENTRY_TYPE.MANUAL) &&
                          <td>{holder.nomination.logEntry.priorDayReading.height}</td>
                        }
                        <td>{holder.nomination.logEntry.priorDayReading.area}</td>
                        <td>{holder.nomination.logEntry.priorDayReading.volume}</td>

                      </tr>
                    </tbody>
                  </table>
                </div></div>
              <div class="row mb-3"><div >&nbsp;
                {!currentDayDasDataExist &&
                  <label style={{ color: 'red' }}>Data not yet received from DAS for the current day</label>
                }
              </div></div>
              <div class="row"><h4>&nbsp;Daily Log</h4> </div>
              <div class="row">
                <div style={{ width: '0.025%' }}></div>
                <div style={{ width: '97.5%' }} >
                  <table class="table table-bordered" border='1'>
                    <thead bgcolor="#0054a6">
                      <th width={COL_WIDTH_DAILY_TAKE}>{colourText('Date')}</th>
                      {(holder.nomination.logEntry.type === LOG_ENTRY_TYPE.MANUAL) &&
                        <th width={COL_WIDTH_DAILY_TAKE}>{colourText('Height (Gauge)')}</th>
                      }
                      <th width={COL_WIDTH_DAILY_TAKE}>{colourText('Height')}</th>
                      <th width={COL_WIDTH_DAILY_TAKE}>{colourText('Area')}</th>
                      <th width={COL_WIDTH_DAILY_TAKE}>{colourText('Volume')}</th>
                      <th width={COL_WIDTH_DAILY_TAKE}>{colourText('Water Take(ML)')}</th>
                    </thead>
                    <tbody>
                      {holder.nomination.logEntry.entryLog.map((row, index) =>
                        //{
                        // {
                        //   ((row.entryDate >= holder.nomination.timeRange.startDate) && (row.entryDate <= holder.nomination.timeRange.endDate)) &&


                        // }
                        ((TIME_AT_START_OF_DAY(row.entryDate) >= TIME_AT_START_OF_DAY(holder.nomination.timeRange.startDate)) && (TIME_AT_START_OF_DAY(row.entryDate) <= TIME_AT_START_OF_DAY(holder.nomination.timeRange.endDate))) &&
                        <tr>
                          <td>{formatDate(row.entryDate)}

                            {(holder.nomination.logEntry.type === LOG_ENTRY_TYPE.MANUAL) &&
                              <button style={{ display: (holder.nomination.attributes.formStatus < 2) ? 'inline' : 'none', float: 'right' }}
                                onClick={() => copyRow(index)}>no change</button>
                            }
                          </td>
                          {(holder.nomination.logEntry.type === LOG_ENTRY_TYPE.MANUAL) &&
                            <td>
                              <input type="text"
                                id={MANUAL_ENTRY + NEW_GAUGE_HEIGHT + index}
                                disabled={holder.nomination.attributes.formStatus > 1}
                                value={holder.nomination.logEntry.entryLog[index].gaugeHeight} onChange={e => setAHDHeightLocal(e.target.value, index)} />
                              <span style={SPAN_RED} id={MANUAL_ENTRY + NEW_GAUGE_HEIGHT + index + MESSAGE}></span>
                            </td>
                          }
                          <td>{renderContitional(RENDER_CONDITIONAL.HEIGHT, row)}</td>
                          <td>{renderContitional(RENDER_CONDITIONAL.AREA, row)}</td>
                          <td>{renderContitional(RENDER_CONDITIONAL.VOLUME, row)}</td>
                          <td>{renderContitional(RENDER_CONDITIONAL.DELTA_VOLUME, row)}</td>
                        </tr>
                        //}
                      )
                      }
                    </tbody>
                  </table>
                </div>
                <div class="row mb-3"><div >&nbsp;</div></div>
              </div></div>
          </React.Fragment>
        }
        <div class="mb3" >&nbsp;</div>
        <hr style={{ fontSize: "25px" }} />
        <div class="mb3" >&nbsp;</div>
        {(holder.nomination.logEntry && holder.nomination.logEntry.entryLog && holder.nomination.logEntry.entryLog.length) > 0 &&
          <React.Fragment>
            <div class="row">

              <div class="col-12">
                <h3>Water Transaction Summary</h3>
              </div>
            </div>
            <div class="row">

              <div class="col-12"  >

                <TransactionTable nomination={holder.nomination} />
              </div>

            </div>
          </React.Fragment>
        }
        <hr style={{ fontSize: "25px" }} />
        <form id="placeholder">

          <button id="next_button" class="button btn-success btn-lg" type="button" onClick={() => doNext()} style={{ display: 'inline', float: 'right' }} >Next</button>
          <div style={{ float: 'right' }}>&nbsp;&nbsp;</div>
          <button id="save_button" class="button btn-success btn-lg" type="button" onClick={() => onSubmit()} style={{ display: (holder.nomination.attributes.formStatus < 2) ? 'inline' : 'none', float: 'right' }} >Save</button>

        </form>
      </div>
    </>
  )
}

