import React from 'react';
import { AsteriskRed } from '../../../../components/tools/Others';
import { Plus, X } from '../../../../components/tools/Icons';
import { isStringNullOrEmpty } from '../../../../helpers/validators';
import { nulToEmptyAndTrim } from '../../../../helpers/formatters';
import { UneditableTextInput } from '../../../../components/tools/BootstrapControls';

export default class ValidationMeterEquipmentDetails  extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            newSealNumber:''
        }
    }

    onNewSealNumberChanged = (e)=>{
        this.setState({newSealNumber:e.target.value})
    }

    onAddSealNumberClicked = () =>{
        if(isStringNullOrEmpty(this.state.newSealNumber)) return;
        const form = this.props.validationForm;
        const sealNumbers = form.val('tamperSealNumbers');
        sealNumbers.push({id:null,sealNumber:nulToEmptyAndTrim(this.state.newSealNumber)});
        form.vals({tamperSealNumbers:sealNumbers},true,()=>this.setState({newSealNumber:''}))
    }

    onDeleteSealNumberClicked = (sealNumber) => {
        const form = this.props.validationForm;
        const sealNumbers = form.val('tamperSealNumbers');
        for(var i=0; i<sealNumbers.length; i++){
            if(sealNumbers[i] === sealNumber) {
                sealNumbers.splice(i, 1);
                form.vals({tamperSealNumbers:sealNumbers});
                return;
            }
        }
    }

    render = () => {
        const form = this.props.validationForm;
        return <>


            <div className='row mb-3'>
                <div className='col-md-4 py-2'>Meter Number</div>
                <div className='col-md-8'>
                    <UneditableTextInput fieldExtraClass="bg-light" value={this.props.meter.meterNumber} />
                </div>
            </div>


            {form.renderUneditableTextInputRow("meteringSystemTypes",<>Meter System Type <AsteriskRed/></>,{rowClasses:'mb-3',converter:o=>o?o.name:null})}
            {form.renderUneditableTextInputRow("meterTypeModels",<>Meter Manufacturer and Model</>,{rowClasses:'mb-3',converter:o=>o?o.name:null})}
            {form.val('meterTypeModels') && form.val('meterTypeModels').key=='Other' && form.renderUneditableTextInputRow("meterTypeModel",<>Other Meter Manufacturer and Model</>,{rowClasses:'mb-3'})}
            {form.renderTextBoxRow('meterSerialNo',<>Meter Serial Number <AsteriskRed/></>,{rowClasses:'mb-3'})}
            {form.renderTextBoxRow('meterSize',<>Meter Size(mm) <AsteriskRed/></>,{rowClasses:'mb-3'})}
            {form.renderTextBoxRow('patternApprovalNumber',<>Pattern Approval Number (if applicable)</>,{rowClasses:'mb-3'})}
            {form.renderDropDownRow("supplyType",<>Type of Supply</>,(o)=>o.id,o=>o.name,{rowClasses:'mb-3'})}

            <div className='row'>
                <div className='col-md-4 py-2'>Tamper-evident Seal Numbers <AsteriskRed/></div>
                <div className='col-md-8'>
                    {form.val('tamperSealNumbers').map(t=><span className='p-2 bg-light btn-sm me-3 d-inline-block mb-3'>
                        {t.sealNumber}
                        <button className='ms-3 btn btn-outline-secondary btn-sm' onClick={()=>this.onDeleteSealNumberClicked(t)} disabled={form.isFormDisabled() || form.isFieldDisabled('tamperSealNumbers')}>
                            <X/>
                        </button>
                    </span>)}

                    <div class="input-group mb-3">
                        <input type="text" class={`form-control ${form.err('tamperSealNumbers')?'is-invalid':''}`} value={this.state.newSealNumber} onChange={this.onNewSealNumberChanged} disabled={form.isFormDisabled() || form.isFieldDisabled('tamperSealNumbers')}/>
                        <button class="btn btn-outline-secondary" type="button" disabled={form.isFormDisabled() || form.isFieldDisabled('tamperSealNumbers')} onClick={this.onAddSealNumberClicked}>Add <Plus/></button>
                    </div>

                    {form.err('tamperSealNumbers') && <div className='text-danger'>{form.err('tamperSealNumbers')}</div>}
                </div>
            </div>
        </>
    }
}
