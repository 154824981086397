import React from 'react';
import * as bootstrap from 'bootstrap'
import {iWasUrl} from '../constants';

export default class GlobalModals extends React.Component {

    constructor(props){
        super(props);
        if(props.noLoginCallbackReceiver) props.noLoginCallbackReceiver(this.onNoLoginError);
        if(props.helpPopupCallbackReceiver) props.helpPopupCallbackReceiver(this.onShowHelpModal);
    }

    #errorModal;
    #helpModal;
    componentDidMount() {
        this.#errorModal = new bootstrap.Modal(document.getElementById('no-login-msg-modal'), {
            keyboard: false
        });
        this.#helpModal = new bootstrap.Modal(document.getElementById('help-modal'));
    }   

    componentWillUnmount(){
        this.#errorModal.dispose();
        this.#helpModal.dispose();
    }

    onNoLoginError = () => {
        this.#errorModal.show();
    }

    onShowHelpModal=()=>{
        if(!this.#helpModal) {
            setTimeout(this.onShowHelpModal, 1000);
        } else 
            this.#helpModal.show();
    }

    render(){
        return <>
            {this.props.children}

            <div class="modal" tabindex="-1" id="help-modal">
                <div class="modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">About nominated measurement period</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <p>
                            It is a requirement under clause 238E of the Water Management (General) Regulation 2018, that a water user with a floodplain harvesting access licence nominate the beginning and end of a measurement period.
                            </p>
                            <p>
                            A measurement period is the only time when floodplain harvesting is permitted. 
                            </p>
                            <h5 className='mt-4'>Nominating your measurement period</h5>
                            <p>
                            You must notify the <strong>start</strong> of the measurement period when the following occurs: 
                            </p>
                            <ul>
                                <li>
                                when overland flow water collected or impounded by the work is: 
                                <ul>
                                    <li>filling infrastructure, such as surge areas, field storage and dams, or </li>
                                    <li>not able to be isolated from water taken under access licences which are not for floodplain harvesting, basic landholder rights, or licence exemptions.</li>
                                </ul>
                                </li>
                            </ul>
                            <p>
                            You must notify the <strong>end</strong> of the measurement period when: 
                            </p>
                            <ul>
                                <li>overland flow water is no longer being collected or impounded by the water supply work, and</li>
                                <li>supply channels are not conveying water to a storage, and</li>
                                <li>all infrastructure, such as surge areas, field storage and dams, that are not fitted with metering equipment are empty.</li>
                            </ul>
                            <p>
                            Water users must notify within 24 hrs of the measurement period ending. Backdating your measurement period by more than 24 hours will require you to enter a reason. 
                            </p>
                            <p>
                            <strong>Other in-flows  from licenced sources:</strong>
                            </p>
                            <p>
                            Water users are required to manually deduct non-floodplain harvesting licenced inflows into their approved works during the nominated measurement period. 
                            </p>

                            <p>
                            <strong>Need help?</strong>
                            </p>
                            <p>
                            If you have incorrectly entered data to finalise your measurement period, you can contact WaterNSW at 1300 662 077 and request a data change. 
                            </p>
                            <p>
                            Information captured in this system may be used by the Natural Resources Access Regulator to perform compliance functions.
                            </p>

                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal fade" id="no-login-msg-modal" tabindex="-1" role="dialog" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
                <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-body m-5">
                        <div className='alert alert-warning mb-0 p-4'>
                            <p>
                                You have either <strong>logged off</strong> the module
                            </p>
                            <p>
                                <strong>Or</strong>
                            </p>
                            <p>
                                Your session <strong>has expired</strong>
                            </p>
                            <p className='text-primary mb-0'>Please log in via <a href={iWasUrl}>iWAS</a></p>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </>
    }
}