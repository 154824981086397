import React, { Fragment } from 'react';
import moment from 'moment';
import Authentication from '../../helpers/Authentication'
import RegistrationApiHelpers from '../../helpers/RegistrationApiHelpers'



import "react-datepicker/dist/react-datepicker.css";

const authentication = new Authentication();

const convertFormStatus = (formStatus) => {
    switch (formStatus) {
        case 0: return 'Open'
        case 1: return 'Submitted'
        case 2: return 'Installed'
        case 3: return 'Removed'
        default: return 'Unknown'
    }
}

class RegistrationFormReadOnly extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            meterRegistration: props.meterRegistration,
            selectedMeterRegistrationLID: props.selectedMeterRegistrationLID
        }

        this.installationTypes = props.installationTypes;
        this.telemetryTypes = props.telemetryTypes;
        this.lids = props.lids;
        this.meterflowUnits = props.meterflowUnits;
        this.billingFlowUnits = props.billingFlowUnits;
        this.meterTypes = props.meterTypes;
        this.manufacturerModels = props.manufacturerModels;
        this.meterUnitOfMeasures = props.meterUnitOfMeasures;
        this.storageMeasurementMethods = props.storageMeasurementMethods;
        this.storageDepthSensorTypes = props.storageDepthSensorTypes;

    }


    async componentDidMount() {
        this.registrationApiHelpers = new RegistrationApiHelpers(authentication.getToken());

        if (!this.installationTypes) this.installationTypes = await this.registrationApiHelpers.getInstallationTypeOptions(this.state.meterRegistration.formType, this.state.meterRegistration.storageMeasurementMethodId);
        if (!this.telemetryTypes) this.telemetryTypes = await this.registrationApiHelpers.getTelemetryTypeOptions();
        if (!this.lids) this.lids = await this.registrationApiHelpers.getLidOptions(this.state.meterRegistration.formType, this.state.meterRegistration.storageMeasurementMethodId);
        if (!this.meterflowUnits) this.meterflowUnits = await this.registrationApiHelpers.getMeterFlowUnitOptions();
        if (!this.billingPeriods) this.billingPeriods = await this.registrationApiHelpers.getBillingPeriodOptions();
        if (!this.billingFlowUnits) this.billingFlowUnits = await this.registrationApiHelpers.getBillingFlowUnitOptions();
        if (!this.meterTypes) this.meterTypes = await this.registrationApiHelpers.getMeterTypeOptions();
        if (!this.manufacturerModels) this.manufacturerModels = await this.registrationApiHelpers.getMeterSystemTypeOptions(this.state.meterRegistration.formType, this.state.meterRegistration.storageMeasurementMethodId);
        if (!this.meterUnitOfMeasures) this.meterUnitOfMeasures = await this.registrationApiHelpers.getMeterUnitOfMeasureOptions();

        if (this.state.meterRegistration.manufacturerAndModel != null && this.manufacturerModels.length != 0) {
            if (this.manufacturerModels.filter(option => option.value === this.state.meterRegistration.manufacturerAndModel)[0].label == 'Other') {
                this.state.showOtherManufactuerModel = true;
            }
        }

        if (this.state.meterRegistration.replaceManufacturerAndModel != null && this.manufacturerModels.length != 0) {
            if (this.manufacturerModels.filter(option => option.value === this.state.meterRegistration.replaceManufacturerAndModel)[0].label == 'Other') {
                this.state.showReplaceOtherManufactuerModel = true;
            }
        }


        if (this.state.meterRegistration.formType == "NON_URBAN") {
            this.state.titleForApprovalOrStorageSession = "Work approval details";
            this.state.titleForOldMeterDetails = "Old meter details";
            if (this.state.meterRegistration.installationType == 2 || this.state.meterRegistration.installationType == 4)
                this.state.titleForNewMeterDetails = "New meter details";
            else
                this.state.titleForNewMeterDetails = "Existing meter details";
            this.state.displayMeterSize = true;
            this.state.displayMeterSerialNumber = true;
            this.state.displayOldMeterNumber = true;
            this.state.displayMeterType = true;
            this.state.displayDepthSesorLevel = false;
            this.state.displayMeterUnitOfMeasures = true;
            this.state.displayMeterUnits = true;
            this.state.displayScallingFactor = true;
        } else if (this.state.meterRegistration.storageMeasurementMethodId == 'POINT_OF_INTAKE') {
            this.state.titleForApprovalOrStorageSession = "Work approval details";
            this.state.titleForOldMeterDetails = "Old meter details";
            if (this.state.meterRegistration.installationType == 2 || this.state.meterRegistration.installationType == 4)
                this.state.titleForNewMeterDetails = "New meter details";
            else
                this.state.titleForNewMeterDetails = "Existing meter details";
            this.state.displayMeterSize = true;
            this.state.displayMeterSerialNumber = true;
            this.state.displayOldMeterNumber = false;
            this.state.displayMeterType = true;
            this.state.displayDepthSesorLevel = false;
            this.state.displayMeterUnitOfMeasures = true;
            this.state.displayMeterUnits = true;
            this.state.displayScallingFactor = true;
        } else {
            this.state.titleForApprovalOrStorageSession = "Storage details";
            this.state.titleForOldMeterDetails = "Old storage device details";
            if (this.state.meterRegistration.installationType == 2 || this.state.meterRegistration.installationType == 4)
                this.state.titleForNewMeterDetails = "New storage device details";
            else
                this.state.titleForNewMeterDetails = "Existing storage device details";
            this.state.displayMeterSize = false;
            this.state.displayMeterSerialNumber = false;
            this.state.displayOldMeterNumber = false;
            this.state.displayMeterType = false;
            if (this.state.meterRegistration.formStatus >= 1)
                this.state.displayDepthSesorLevel = true;
            else
                this.state.displayDepthSesorLevel = false;
            this.state.displayMeterUnitOfMeasures = false;
            this.state.displayMeterUnits = false;
            this.state.displayScallingFactor = false;

            if (!this.storageMeasurementMethods) this.storageMeasurementMethods = await this.registrationApiHelpers.getStorageMeasurementMethodOptions();
            if (!this.storageDepthSensorTypes) this.storageDepthSensorTypes = await this.registrationApiHelpers.getStorageMeasurementMethodOptions();
        }
        this.forceUpdate();
    }

    isLIDForMultipleMeters = () => {
        let lids = this.lids.filter(l => l.value == this.state.meterRegistration.lid);
        if (lids.length == 0) {
            return false;
        } else if (lids[0].name == 'flowpro_campbell') {
            return true;
        }
        return false;
    }


    renderStorageDepthSensorLevel = () => {
        if (this.state.meterRegistration.formType == 'NON_URBAN' || this.state.meterRegistration.storageMeasurementMethodId == 'POINT_OF_INTAKE') return null;

        return <tr>
            <th className='w-25'>Level of the depth sensor(mAHD)?</th>
            <td>
                {this.state.meterRegistration.depthSesorLevel}
            </td>
        </tr>
    }

    renderStorageDepthSensorType = (isReplace) => {
        if (this.state.meterRegistration.formType == 'NON_URBAN' || this.state.meterRegistration.storageMeasurementMethodId == 'POINT_OF_INTAKE') return null;

        return <tr>
            <th className='w-25'>Storage sensor type of the storage</th>
            <td>
                {isReplace && this.storageDepthSensorTypes && this.storageDepthSensorTypes.map((option, index) => {
                    if (option.value == this.state.meterRegistration.replaceStorageDepthSensorTypeId) {
                        return option.label;
                    }
                })}

                {!isReplace && this.storageDepthSensorTypes && this.storageDepthSensorTypes.map((option, index) => {
                    if (option.value == this.state.meterRegistration.storageDepthSensorTypeId) {
                        return option.label;
                    }
                })}
            </td>
        </tr>;
    }

    renderStorageManagementMethod = () => {
        if (this.state.meterRegistration.formType == "NON_URBAN") return null;

        return <tr>
            <th className='w-25'>Storage measurement method</th>
            <td>
                {this.storageMeasurementMethods && this.storageMeasurementMethods.map((option, index) => {
                    if (option.value == this.state.meterRegistration.storageMeasurementMethodId) {
                        return option.label;
                    }
                })}
            </td>
        </tr>;
    }


    renderApprovalOrStageSession = () => {
        if (this.state.meterRegistration.formType == 'NON_URBAN' || this.state.meterRegistration.storageMeasurementMethodId == 'POINT_OF_INTAKE')
            return <>
                <tr>
                    <th className='w-25'>Work approval number</th>
                    <td>{this.state.meterRegistration.workApproval}</td>
                </tr>
                <tr>
                    <th className='w-25'>Water access license (WAL) number</th>
                    <td>{this.state.meterRegistration.linkedAl}</td>
                </tr>
                <tr>
                    <th>Unique extraction site ID (ESID)</th>
                    <td>{this.state.meterRegistration.esid}</td>
                </tr>

                {(this.state.meterRegistration.installationType == 5 || this.state.meterRegistration.installationType == 4) && <tr>
                    {(this.state.meterRegistration.installationType == 5) && (
                        <th>Adding telemetry to meter </th>
                    )}
                    {(this.state.meterRegistration.installationType == 4) && (
                        <th>Replacing meter </th>
                    )}
                    <td>{this.state.meterRegistration.replaceMeterNumber}</td>
                </tr>}

                <tr>
                    <th className='w-25'>Primary holder type</th>
                    <td>{this.state.meterRegistration.holderType == "INDIVIDUAL" ? "Individual" : "Organisation"}</td>
                </tr>
                {(this.state.meterRegistration.holderType == "INDIVIDUAL") && (
                    <tr>
                        <th className='w-25'>Primary holder first name</th>
                        <td>{this.state.meterRegistration.primaryHolder}</td>
                    </tr>
                )}
                {(this.state.meterRegistration.holderType == "INDIVIDUAL") && (
                    <tr>
                        <th>Primary holder surname</th>
                        <td>{this.state.meterRegistration.holderSurname}</td>
                    </tr>
                )}
                {(this.state.meterRegistration.holderType != "INDIVIDUAL") && (
                    <tr>
                        <th>Organisation name</th>
                        <td>{this.state.meterRegistration.primaryHolder}</td>
                    </tr>
                )}
            </>;

        return <>
            <tr>
                <th className='w-25'>Authority holder type</th>
                <td>{this.state.meterRegistration.holderType == "INDIVIDUAL" ? "Individual" : "Organisation"}</td>
            </tr>
            {(this.state.meterRegistration.holderType == "INDIVIDUAL") && (
                <tr>
                    <th>Authority holder first name</th>
                    <td>{this.state.meterRegistration.primaryHolder}</td>
                </tr>
            )}
            {(this.state.meterRegistration.holderType == "INDIVIDUAL") && (
                <tr>
                    <th>Authority holder surname</th>
                    <td>{this.state.meterRegistration.holderSurname}</td>
                </tr>
            )}
            {(this.state.meterRegistration.holderType != "INDIVIDUAL") && (
                <tr>
                    <th>Organisation name</th>
                    <td>{this.state.meterRegistration.primaryHolder}</td>
                </tr>
            )}
            <tr>
                <th>Storage identifying number</th>
                <td>{this.props.ratingTable == null ? "" : this.props.ratingTable.ratingTableName}</td>
            </tr>
        </>
    }

    render() {
        return <>

            <div className='card mb-4 card-body'>
                <table class="table fs-90 mb-3">
                    <tbody>
                        {
                            this.state.meterRegistration.registrationNo && this.state.meterRegistration.registrationNo != '' &&
                            <tr>
                                <th className='w-25'>
                                    Registration ID
                                </th>
                                <td>{this.state.meterRegistration.registrationNo}</td>
                            </tr>
                        }
                        <tr>
                            <th className='w-25'>Form status</th>
                            <td>
                                {convertFormStatus(this.state.meterRegistration.formStatus)}
                            </td>
                        </tr>
                        <tr>
                            <th className='w-25'>Form type</th>
                            <td>
                                {this.state.meterRegistration.formType == 'FPH' ? "Floodplain Havesting" : "Non-urban Metering"}
                            </td>
                        </tr>
                        {this.renderStorageManagementMethod()}
                        <tr>
                            <th className='w-25'>Installation type</th>
                            <td>
                                {this.installationTypes && this.installationTypes.map((option, index) => {
                                    if (option.value == this.state.meterRegistration.installationType) {
                                        return option.label;
                                    }
                                })}
                            </td>
                        </tr>

                        {this.state.meterRegistration.installationType == 4 &&
                            <tr>
                                <th scope="row">Replacement start date</th>
                                <td>
                                    {
                                        (
                                            this.state.meterRegistration.replacementStartDate == null
                                            ||
                                            this.state.meterRegistration.replacementStartDate == ""
                                        )
                                            ?
                                            ""
                                            :
                                            moment((new Date(this.state.meterRegistration.replacementStartDate))).format('DD/MM/YYYY')
                                    }
                                </td>
                            </tr>
                        }

                        {this.state.meterRegistration.meterSiteId && <tr>
                            <th>Workspace ID</th>
                            <td>{this.state.meterRegistration.meterSiteId.meterSiteId}</td>
                        </tr>}
                        {this.state.meterRegistration.meterId && <tr>
                            <th>Source ID</th>
                            <td>{this.state.meterRegistration.meterId.meterId}</td>
                        </tr>}
                    </tbody>
                </table>
            </div>

            {!this.props.hideDetails && <>

                <div className='card my-4'>
                    <div className='card-header fw-bold'>
                        {this.state.titleForApprovalOrStorageSession}
                    </div>
                    <div className='card-body'>
                        <table class="table fs-90">
                            <tbody>
                                {this.renderApprovalOrStageSession()}
                                <tr>
                                    <th className='w-25'>Address</th>
                                    <td>{this.state.meterRegistration.holderAddress}</td>
                                </tr>
                                <tr>
                                    <th>Suburb</th>
                                    <td>{this.state.meterRegistration.holderSuburb}</td>
                                </tr>
                                <tr>
                                    <th>Postcode</th>
                                    <td>{this.state.meterRegistration.holderPostcode}</td>
                                </tr>
                                <tr>
                                    <th>State</th>
                                    <td>{this.state.meterRegistration.holderState}</td>
                                </tr>
                                <tr>
                                    <th>Phone number</th>
                                    <td>{this.state.meterRegistration.holderPhone}</td>
                                </tr>
                                <tr>
                                    <th>Customer's current email address</th>
                                    <td>{this.state.meterRegistration.holderEmail}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>




                {this.state.meterRegistration.installationType == 4 && (
                    <div className='card mb-4'>
                        <div className='card-header fw-bold'>{this.state.titleForOldMeterDetails}</div>
                        <div className='card-body'>
                            <table class="table fs-90">
                                <tbody>
                                    {this.renderStorageDepthSensorType(true)}
                                    <tr>
                                        <th className='w-25'>Manufacturer &amp; model</th>
                                        <td>
                                            {this.manufacturerModels && this.manufacturerModels.map((option, index) => {
                                                if (option.value == this.state.meterRegistration.replaceManufacturerAndModel) {
                                                    return option.label;
                                                }
                                            })}
                                        </td>
                                    </tr>
                                    {(this.state.showReplaceOtherManufactuerModel) && (
                                        <tr>
                                            <th>Other manufactuer and model</th>
                                            <td>{this.state.meterRegistration.replaceOtherManufacturerAndModelText}</td>
                                        </tr>
                                    )}
                                    {this.state.displayMeterSize && <tr>
                                        <th>Meter Size (mm)</th>
                                        <td>{this.state.meterRegistration.replaceMeterSize}</td>
                                    </tr>}
                                    {this.state.displayMeterSerialNumber && <tr>
                                        <th>Serial number</th>
                                        <td>{this.state.meterRegistration.replaceSerialNumber}</td>
                                    </tr>}
                                    {this.state.displayOldMeterNumber && <tr>
                                        <th>Meter number</th>
                                        <td>{this.state.meterRegistration.replaceMeterNumber}</td>
                                    </tr>}
                                    <tr>
                                        <th>Description</th>
                                        <td>{this.state.meterRegistration.replaceMeterDescription}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                )}

                <div className='card mb-4'>
                    <div className='card-header fw-bold'>{this.state.titleForNewMeterDetails}</div>
                    <div className='card-body'>
                        <table class="table fs-90">
                            <tbody>
                                {this.state.displayMeterType && <tr>
                                    <th className='w-25'>Type</th>
                                    <td>
                                        {this.meterTypes && this.meterTypes.map((option, index) => {
                                            if (option.value == this.state.meterRegistration.meterType) {
                                                return option.label;
                                            }
                                        })}
                                    </td>
                                </tr>}
                                {this.renderStorageDepthSensorType(false)}
                                {this.state.displayDepthSesorLevel && this.renderStorageDepthSensorLevel()}
                                <tr>
                                    <th className='w-25'>Manufacturer &amp; model</th>
                                    <td>
                                        {this.manufacturerModels && this.manufacturerModels.map((option, index) => {
                                            if (option.value == this.state.meterRegistration.manufacturerAndModel) {
                                                return option.label;
                                            }
                                        })}
                                    </td>
                                </tr>
                                {(this.state.showOtherManufactuerModel) && (
                                    <tr>
                                        <th>Other manufactuer and model</th>
                                        <td>{this.state.meterRegistration.otherManufacturerAndModelText}</td>
                                    </tr>
                                )}
                                {this.state.displayMeterSize && <tr>
                                    <th>Meter Size (mm)</th>
                                    <td>{this.state.meterRegistration.meterSize}</td>
                                </tr>}
                                {this.state.displayMeterUnitOfMeasures && <tr>
                                    <th>Unit of measure as displayed on meter</th>
                                    <td>
                                        {this.meterUnitOfMeasures && this.meterUnitOfMeasures.map((option, index) => {
                                            if (option.value == this.state.meterRegistration.meterUnitOfMeasures) {
                                                return option.label;
                                            }
                                        })}
                                    </td>
                                </tr>}

                                {this.state.displayMeterSerialNumber && <tr>
                                    <th>Serial number</th>
                                    <td>{this.state.meterRegistration.serialNumber}</td>
                                </tr>}
                                <tr>
                                    <th>Location - latitude </th>
                                    <td>{this.state.meterRegistration.latitude}</td>
                                </tr>
                                <tr>
                                    <th>Location - longitude </th>
                                    <td>{this.state.meterRegistration.longitude}</td>
                                </tr>
                                <tr>
                                    <th>Description</th>
                                    <td>{this.state.meterRegistration.meterDescription}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className='card mb-4'>
                    <div className='card-header fw-bold'>
                        LID Details
                    </div>
                    <div className='card-body'>
                        <table class="table fs-90">
                            <tbody>
                                <tr>
                                    <th className='w-25'>LID type</th>
                                    <td>
                                        {this.telemetryTypes && this.telemetryTypes.map((option, index) => {
                                            if (option.value == this.state.meterRegistration.telemetryType) {
                                                return option.label;
                                            }
                                        })}
                                    </td>
                                </tr>
                                <tr>
                                    <th>LID device</th>
                                    <td>
                                        {this.lids && this.lids.map((option, index) => {
                                            if (option.value == parseInt(this.state.meterRegistration.lid)) {
                                                return option.label;
                                            }
                                        })}
                                    </td>
                                </tr>

                                {this.isLIDForMultipleMeters() && <>
                                    <tr>
                                        <th>Is this the first meter of this LID</th>
                                        <td>
                                            {
                                                this.state.meterRegistration.isFirstMeterForLID == null ? "" :
                                                    this.state.meterRegistration.isFirstMeterForLID ? "YES" :
                                                        "NO"
                                            }
                                        </td>
                                    </tr>

                                    {this.state.meterRegistration.isFirstMeterForLID === false && <>
                                        <tr>
                                            <th>This meter is connecting to same LID as </th>
                                            <td>
                                                {this.state.selectedMeterRegistrationLID ? this.state.selectedMeterRegistrationLID.firstRegistrationMeterSiteId : null}
                                            </td>
                                        </tr>
                                    </>}

                                </>}

                                {this.state.displayScallingFactor && <tr>
                                    <th>Scaling factor</th>
                                    <td>{this.state.meterRegistration.meterScalingFactor}</td>
                                </tr>}
                                {this.state.displayMeterUnits && <tr>
                                    <th>Unit of measure transmitted by LID</th>
                                    <td>
                                        {this.meterflowUnits.map((option, index) => {
                                            if (option.value == this.state.meterRegistration.meterFlowUnits) {
                                                return option.label;
                                            }
                                        })}
                                    </td>
                                </tr>}
                                <tr>
                                    <th>Is the LID being used for Telemetry?</th>
                                    <td>
                                        {this.state.meterRegistration.isLidTelemetry ? "YES" : "NO"}
                                    </td>
                                </tr>
                                <tr>
                                    <th>Additional comments</th>
                                    <td>{this.state.meterRegistration.holderComments}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className='card mb-4'>
                    <div className='card-header fw-bold'>
                        Supporting documents
                    </div>
                    <div className='card-body'>
                        {
                            this.state.meterRegistration.documents && this.state.meterRegistration.documents
                                .filter(option => (option.status === "new" || option.status === "existing")).length > 0 &&
                            <table class="table fs-90" >
                                <thead>
                                    <tr>
                                        <th className='w-25'>FileName</th>
                                        <th >Description</th>

                                    </tr>
                                </thead>
                                <tbody>

                                    {this.state.meterRegistration.documents
                                        .filter(option => (option.status === "new" || option.status === "existing"))
                                        .map((option, index) => <tr>
                                            <td>
                                                <a target='_blank' href={process.env.REACT_APP_WEBAPI_URL + process.env.REACT_APP_FIELS_GET + "/" + option.id}>
                                                    {option.fileOriginalName}
                                                </a>
                                            </td>
                                            <td>{option.description}</td>
                                        </tr>)
                                    }
                                </tbody>
                            </table>
                        }
                        {
                            !this.state.meterRegistration.documents
                            ||
                            this.state.meterRegistration.documents
                                .filter(option => (option.status === "new" || option.status === "existing")).length == 0 &&
                            <div className='alert alert-secondary'>
                                No supporting document attached
                            </div>
                        }
                    </div>
                </div>



                {(this.state.meterRegistration.formStatus == 1 || this.state.meterRegistration.formStatus == 2) &&
                    <div className='card card-body mb-4'>
                        <table class="table fs-90">
                            <tbody>
                                <tr>
                                    <th className='w-25'>Date Of Installation</th>
                                    <td>{this.state.meterRegistration.dateOfInstallation ? moment((new Date(this.state.meterRegistration.dateOfInstallation))).format('DD/MM/YYYY h:mm a') : ""}</td>
                                </tr>
                                <tr>
                                    <th>Meter reading at the time of installation</th>
                                    <td>{this.state.meterRegistration.meterReadingAtInstallation}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>}
            </>}
        </>
    }
}

export default RegistrationFormReadOnly;