import React from 'react';
import ApprovalLTSReportComponent from '../components/trackingStatus/ApprovalLTSReportComponent'
import BasePageLayout, {menuItems} from './base/BasePageLayout';


class TrackingStatusNUM extends React.Component {

    constructor(props) {
        super(props);
        
    }

    render() {
        return <>
            <h3 className='mb-3'>Landholder tracking status report for Non-urban Metering </h3>
            <ApprovalLTSReportComponent statusType="NUM" />
        </>
    }

}

export default TrackingStatusNUM;

