import React from 'react';
import { InputTextWithFloatingLabel } from '../../../components/tools/BootstrapControls';
import * as apiCaller from '../../../helpers/apiCaller'
import serviceUrls from '../../../constants/serviceUrls';
import uiHelpers from '../../../helpers/uiHelpers';
import { Redirect  } from "react-router-dom";
import moment from 'moment';
import { convertMeterStatus } from '../../../helpers/converters';
class SearchMeters  extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            keyword:'',
            searching:false,
            result:null,
            redirectTo : null
        }
    }

    async componentDidMount() {
        
    }


    onKeywordChanged = (e) => {
        this.setState({keyword:e.target.value})
    }

    search = async () => {
        uiHelpers.showProgressOnTop('Please wait...');
        this.setState({searching:true})
        await apiCaller.post(serviceUrls.admin.searchMeter,{keyword:this.state.keyword})
        .then((data)=>{
            this.setState({
                result:data.result,
                searching:false
            })
        })
        .finally(()=>{
            uiHelpers.hideProgressOnTop();
        });
    }

    openMeter = (m) => {
        this.setState({redirectTo:'/admin/meter/' + m.id})
    }

    render() {
        if(this.state.redirectTo) return <Redirect to={this.state.redirectTo}  />;
        return <>

            <h3 class="mb-3">Search Meters</h3>
            <div className='row mb-3'>
                <div className='col-md-6'>
                    <InputTextWithFloatingLabel
                        value={this.state.keyword} 
                        disabled={this.state.searching}
                        onChange={this.onKeywordChanged}
                        label={<>Keyword <i className='fs-80'>(Approval / ESID / Worksite ID)</i></>}
                    />
                </div>
            </div>
            <div className='mb-3'>
                <button className='btn btn-primary' onClick={this.search} disabled={this.state.searching}>Search</button>
            </div>

            {this.state.result && <>
                {this.state.result.length==0 && <div className='p-4 text-center bg-light'>
                    No meter found from this keyword
                </div>}

                {this.state.result.length>0  && <ul className={`list-group ${this.state.searching ? 'opacity-25':''}`}>
                    {this.state.result.map(m=><li class="list-group-item">
                        <div className='row'>
                            <div className='col-md-4 mb-3'>
                                {m.meterSiteId.meterSiteId}
                                <br/><span class="fs-80 text-secondary">ID: #<b>{m.id}</b></span>
                                <br/><span class="fs-80 text-secondary">Created: <b>{moment(m.createdDate).format('DD/MM/YYYY')}</b></span>
                            </div>
                            <div className='col-md-6 mb-3'>
                                <span class="fs-80 text-secondary">Status: <b className='text-primary'>{convertMeterStatus(m.status)}</b></span>
                                <br/><span class="fs-80 text-secondary">LID: <b>{m.meterLid.lid.displayText}</b></span>
                                <br/><span class="fs-80 text-secondary">Manufacturer and Model: <b>{m.meterTypeModel.name}</b></span>
                            </div>
                            <div className='col-md-2 text-end'>
                                <a href={`/#/admin/meter/${m.id}`} className={`btn btn-primary btn-sm ${this.state.searching && 'disabled'}`}>Meter Details</a>
                            </div>
                        </div>
                    </li>)}
                </ul>}
            </>}
            
        </>
    }
}

export default SearchMeters;
