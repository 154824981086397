import React from 'react';
import * as bootstrap from 'bootstrap';
import { InputText, UneditableTextInput } from '../../../../components/tools/BootstrapControls';
import { isStringNullOrEmpty } from '../../../../helpers/validators';
import serviceUrls from '../../../../constants/serviceUrls';
import * as apiCaller from '../../../../helpers/apiCaller';
import uiHelpers from '../../../../helpers/uiHelpers';
import moment from 'moment';
import { Info, ListIcon } from '../../../../components/tools/Icons';
import { convertConsentAuditAction } from '../../../../helpers/converters';

const initState = {
    callingBackend:false,
    dqpName:'',
    dqpNameErr:null,
    approval:'',
    approvalErr:null,
    overAllErr:null,
    searched:false,
    records:null,
    confirmingReset:false,
    resettingConsent:null,
    consentToShow:null
}

class SearchConsents  extends React.Component {

    #detailsModalElementId = "model_" + (new Date()).getTime();
    #detailsModal;

    constructor(props) {
        super(props);
        this.state = JSON.parse(JSON.stringify(initState));
    }

    async componentDidMount() {
        this.#detailsModal = new bootstrap.Modal(document.getElementById(this.#detailsModalElementId));
    }

    onDqpNameChanged  = (e) => {this.setState({dqpName:e.target.value});}
    onApprovalChanged  = (e) => {this.setState({approval:e.target.value});}
    search = async (e) => {
        e.preventDefault();

        if(isStringNullOrEmpty(this.state.approval) && isStringNullOrEmpty(this.state.dqpName)){
            
            this.setState({overAllErr:'Please enter some search criteria'});
            return;
        }
        this.setState({overAllErr:null});

        uiHelpers.showProgressOnTop('Please wait...');
        this.setState(Object.assign(this.state,{callingBackend:true, searched:false}));
        this.refresh();
        
    }

    refresh = async () => {
        
        await apiCaller.post(serviceUrls.consent.adminSearch,{
            name:this.state.dqpName,
            approvalNumber:this.state.approval
        })
        .then((data)=>{
            this.setState({searched:true,records:data.consents})
        }).finally(this.wholePageAfterRefresh);
    }

    wholePageAfterRefresh = () => {
        this.setState(Object.assign(this.state,{callingBackend:false,editApprovingOfficerForm:null, confirmingReset:false, resettingConsent:null}));
        uiHelpers.hideProgressOnTop();
    }

    onResetBtnClick = (consent) => {
        this.setState({resettingConsent:consent, confirmingReset:true})
    }

    onResetCancelClick = () => {
        this.setState({resettingConsent:null, confirmingReset:false})
    }

    onResetConfirmClick = async () => {
        uiHelpers.showProgressOnTop('Please wait...');
        this.setState(Object.assign(this.state,{callingBackend:true}));
        await apiCaller.post(serviceUrls.consent.adminResetEmailLimit,{
            consentId: this.state.resettingConsent.id
        })
        .then((data)=>{
            this.refresh();
        })
    }

    onOpenDetailsClick = async (consent) => {
        this.setState({consentToShow:null})
        this.#detailsModal.show();

        await apiCaller.post(serviceUrls.consent.getById,{
            id: consent.id
        })
        .then((data)=>{
            this.setState({consentToShow:data.consent});
        })
    }

    render() {
        return <>
            <h3 class="mb-3">Search Consents</h3>
            <form action={""} onSubmit={this.search} >
                <div class="row mb-3">
                    <div class="col-md-3 mb-2">
                        <InputText
                            value={this.state.approval} 
                            disabled={this.state.callingBackend|| this.state.confirmingReset}
                            onChange={this.onApprovalChanged}
                            error={this.state.approvalErr}
                            label={<>Approval Number</>}
                        />
                    </div>
                    <div class="col-md-5 mb-2">
                        <InputText
                            value={this.state.dqpName} 
                            disabled={this.state.callingBackend ||this.state.confirmingReset}
                            onChange={this.onDqpNameChanged}
                            label={<>DQP Name</>}
                            error={this.state.dqpNameErr}
                        />
                    </div>
                </div>
                <div className='row mb-3'>
                    <div class="col-md-8">
                        <button className='btn btn-primary' type='submit' disabled={this.state.callingBackend ||this.state.confirmingReset}>
                            Search
                        </button>
                        {this.state.overAllErr && <span className='text-danger ms-3'>{this.state.overAllErr}</span>}
                    </div>
                </div>
            </form>

            {this.state.searched && <>

                {this.state.records.length==0 && <div className='bg-light text-center p-3'>
                    No records found
                </div>}

                {this.state.records.length>0 && <ul className={`list-group my-5`}>
                    {this.state.records.map(c=><>
                        <li class="list-group-item">
                            <div className='row'>
                                <div className='col-md-2 mb-3'>
                                    {c.approval}
                                    <br/><span class="fs-80 text-secondary">Status: <b>{
                                        c.status =='PENDING_APPROVAL' ? 
                                        <span className='text-warning'>Pending approval</span>
                                        :
                                        c.status =='REJECTED' ? 
                                        <span className='text-danger'>Consent not Authorised</span>
                                        :
                                        c.status =='APPROVED' ? 
                                        <span className='text-success'>Approved</span>
                                        :
                                        c.status =='EXPIRED' ? 
                                        <span className='text-danger'>Expired</span>
                                        :
                                        null
                                    }</b></span>
                                    
                                    <br/><span class="fs-80 text-secondary">DQP: <b>{c.dqpFirstName} {c.dqpLastName}</b></span>
                                </div>
                                <div className='col-md-4 mb-3'>
                                    <span class="fs-80 text-secondary">Authorisation Requested on: <b>{c.requestedDate?moment(c.requestedDate).format('DD/MM/YYYY'):""}</b></span>
                                    <br/><span class="fs-80 text-secondary">Authorisation Approved on: <b>{c.approvedDate?moment(c.approvedDate).format('DD/MM/YYYY'):""}</b></span>
                                    <br/><span class="fs-80 text-secondary">Expiry Date: <b>{c.expiryDate?moment(c.expiryDate).format('DD/MM/YYYY'):""}</b></span>
                                </div>
                                <div className='col-md-4 mb-3'>
                                    <span class="fs-80 text-secondary">Authorisation Request Type: <b>{c.approvalType=='PDF'?"PDF Signature":"Email"}</b></span>
                                    <br/><span class="fs-80 text-secondary">For Government-Owned: <b>{c.governmentOwn===true?"Yes":c.governmentOwn===false?"No":""}</b></span>
                                    <br/>
                                    <span class="fs-80 text-secondary">
                                        Resend Email Limit Reached: <b>{c.reachResendLimit===true?<><span className='text-danger me-2'>Yes</span>
                                        {this.state.confirmingReset && this.state.resettingConsent.id == c.id ? <>
                                            <br/>
                                            <span className='text-primary'>Please confirm you would like to reset the limit</span><br/>
                                            <button className='btn btn-sm btn-primary me-1' onClick={this.onResetConfirmClick} disabled={this.state.callingBackend}>Confirm</button>
                                            <button className='btn btn-sm btn-secondary' onClick={this.onResetCancelClick} disabled={this.state.callingBackend}>Cancel</button>
                                        </>:<>
                                            <button className='btn btn-sm btn-outline-primary' onClick={()=>this.onResetBtnClick(c)} disabled={this.state.callingBackend || this.state.confirmingReset}>Reset</button>
                                        </>}
                                        
                                        </>:c.reachResendLimit===false?"No":""}
                                        </b>
                                    </span>
                                </div>
                                <div className='col-md-2 text-end'>
                                    <button className='btn btn-outline-primary btn-sm' disabled={this.state.callingBackend || this.state.confirmingReset} onClick={()=>this.onOpenDetailsClick(c)}><ListIcon/></button>
                                </div>
                            </div>
                        </li>
                    </>)}
                </ul>}
            </>}


            <div class="modal fade" id={this.#detailsModalElementId} data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">Consent Details</h5>
                        </div>
                    <div class="modal-body">
                        <div class="form-group">
                            {this.renderConsentDetails()}
                        </div>
                    </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" disabled={this.state.loading} data-bs-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    }

    renderConsentDetails = () => {
        const consent = this.state.consentToShow;
        if(!consent) return <div className='text-center'>
            <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>

        return <>
            <div className='mb-3'>
                <UneditableTextInput
                        label={<>Approval</>}
                        value={consent.approval}
                    />                    
            </div>

            <div className='mb-3'>
                <UneditableTextInput
                        label={<>User <span className='text-muted fs-80'>(DQP)</span></>}
                        value={<>
                            {consent.createdUser.firstname} {consent.createdUser.lastname}
                        </>}
                    />                    
            </div>

            
            <div className='mb-3'>
                <UneditableTextInput
                        label={<>Authorisation Request Type</>}
                        value={consent.approvalType=='PDF'?"PDF Signature":"Email"}
                    />                    
            </div>
            <div className='mb-3'>
                <UneditableTextInput
                        label={<>Email Count</>}
                        value={<>{consent.emailCount} {consent.reachResendLimit===true && <span className='text-danger fs-80'> - Reached limit</span>}</>}
                    />                    
            </div>

            <div className='mb-3'>
                <UneditableTextInput
                    label={<>Authorisation Status</>}
                    value={
                        consent.status =='PENDING_APPROVAL' ? 
                        <span className='text-warning'>Pending approval</span>
                        :
                        consent.status =='REJECTED' ? 
                        <span className='text-danger'>Consent not Authorised</span>
                        :
                        consent.status =='APPROVED' ? 
                        <span className='text-success'>Approved</span>
                        :
                        consent.status =='EXPIRED' ? 
                        <span className='text-danger'>Expired</span>
                        :
                        null
                    }
                />
            </div>
            <div className='mb-3'>
                <UneditableTextInput
                        label={<>For Government-Owned</>}
                        value={consent.governmentOwn===true?"Yes":consent.governmentOwn===false?"No":""}
                    />                    
            </div>
            <div className='mb-3'>
                <UneditableTextInput
                    label={<>Approved on</>}
                    value={consent.approvedDate ? moment(consent.approvedDate).format('DD/MM/YYYY h:mma'): null}
                />
            </div>
            <div className='mb-3'>
            <UneditableTextInput
                label={<>Expiring on</>}
                value={consent.expiryDate ? moment(consent.expiryDate).format('DD/MM/YYYY h:mma'): null}
            />
            </div>
            
            {consent.selectedHolder && <>
                <div className='mb-3'>
                <UneditableTextInput
                    label={<>Request sent to holder</>}
                    value={consent.selectedHolder.name}
                />
                </div>
                <div className='mb-3'>
                <UneditableTextInput
                    label="Holder Type"
                    value={consent.selectedHolder.inpaType}
                />
                </div>
                {this.renderHolderPhone(consent.selectedHolder)}
            </>}

            {consent.approvalType=='EMAIL' && <div className='mb-3 '>
                    <UneditableTextInput
                        label="Request sent to email"
                        value={consent.requestedEmail}/>
            </div>}

            <h4 className='mt-4'>Audits</h4>
            <hr/>
                <table className='table'>
                    <thead>
                        <tr>
                            <th>
                                Date
                            </th>
                            <th>
                                Action
                            </th>
                            <th>
                                User
                            </th>
                            <th>
                                Request sent to
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {consent.audits.length==0 && <tr>
                            <td colSpan={4} className='bg-light text-center'>
                                No audit record found
                            </td>
                        </tr>}
                        {consent.audits.map((a)=>{
                            return <tr>
                                <td>
                                {moment(a.auditDate).format('DD/MM/YYYY h:mma')}
                                </td>
                                <td>
                                    {convertConsentAuditAction(a.auditAction)}
                                </td>
                                <td>
                                    {a.firstName} {a.lastName}
                                </td>
                                <td>
                                    {a.sentToEmail}
                                </td>
                            </tr>
                        })}
                    </tbody>
                </table>


        </>
    }

    renderHolderPhone = (holder) => {
        if(!holder) return null;
        if(!holder.phoneNumbers) return null;
        if(holder.phoneNumbers==0) return;

        let firstPhone = holder.phoneNumbers[0];
        let mobile;
        let phone;
        let business;
        let phoneToDisplay = firstPhone;
        for(var i=0; i<holder.phoneNumbers.length;i++){
            if(holder.phoneNumbers[i].serviceType=='MOBILE'){
                mobile=holder.phoneNumbers[i];
            } else if(holder.phoneNumbers[i].serviceType=='PHONE'){
                phone=holder.phoneNumbers[i];
            } else if(holder.phoneNumbers[i].serviceType=='BUSINESS'){
                business=holder.phoneNumbers[i];
            }
        }

        if(holder.inpaType =='Person') {

            if(mobile) phoneToDisplay= mobile;
            else if(phone) phoneToDisplay= phone;
        } else {
            if(business) phoneToDisplay= business;
            else if(phone) phoneToDisplay= phone;
        }

        return <>
            <div className='row '>
                <div className='col-md-6 mb-3'>
                    <UneditableTextInput
                        label="Phone Number"
                        value={<>{phoneToDisplay.prefix} {phoneToDisplay.phoneNumber}</>}
                    />
                </div>
            </div>
        </>
    }
}

export default SearchConsents;

