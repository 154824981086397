import React from 'react';
import ApprovalFields from '../../../../components/common/ApprovalFields';
import { StepProgressBar } from '../../../../components/tools/BootstrapControls';
import ValidationApprovedWorksFields from './ValidationApprovedWorksFields';
import ValidationCustomerSiteFields from './ValidationCustomerSiteFields';
import ValidationMeterEquipmentDetails from './ValidationMeterEquipmentDetails';
import ValidationMeterLocation from './ValidationMeterLocation';
import ValidationEmplacementFields from './ValidationEmplacementFields';
import ValidationLidFields from './ValidationLidFields';
import ValidationReplacingMeterFields from './ValidationReplacingMeterFields';
import ValidationReplacingMeterLocation from './ValidationReplacingMeterLocation';
import ValidationReplacingLidFields from './ValidationReplacingLidFields';
import ValidationReplacingWasMeterFields from './ValidationReplacingWasMeterFields';

export default class ValidationPage2
  extends React.Component {

    render() {
        const approval = this.props.approval;
        const form = this.props.validationForm;
        const role = this.props.role;
        const meterCommission = this.props.meterCommission;
        const hasErrWorks = form.haveErrors(["dlwcProperty","dlwcLot"]);
        const hasErrCusSite = form.haveErrors(["customersSiteName","customersPumpNumber"])
        const hasErrMeterEquip = form.haveErrors([
            "meteringSystemTypes",
            "meterTypeModels",
            "meterUnitOfMeasures",
            "meterSerialNo",
            "meterSize",
            "patternApprovalNumber",
            "supplyType",
            "tamperSealNumbers"
        ])
        const hasErrMeterLoc = form.haveErrors(["latitude","longitude"])
        const hasErrEmplacement = form.haveErrors(["pipeLengthUpstream","pipeLengthDownstream"])
        const hasErrLid = form.haveErrors(["dataCollectorSerialNo","lIDType","scalingFactor"])
        return <>
            <div className='my-5'>      
                <StepProgressBar
                    totalSteps={5} 
                    currentStep={2} 
                    disableFutureSteps={form.val('formStatus')==1} 
                    stepClick={(page) =>{this.props.gotToStep(page)}}
                />
            </div>

            

            <div class="accordion mb-3" id="accordionValidationForm">
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingWorkApprovalDetails">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseWorkApproval" aria-expanded="false" aria-controls="collapseWorkApproval">
                        Work Approval Details
                        </button>
                    </h2>
                    <div id="collapseWorkApproval" class="accordion-collapse collapse" aria-labelledby="headingWorkApprovalDetails" >
                        <div class="accordion-body">
                            <ApprovalFields approval={approval} authoriserDlwcInpaAddrId={this.props.authoriserDlwcInpaAddrId} />
                        </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingApprovedWorks">
                        {
                            hasErrWorks ?
                            <button class="accordion-button collapsed  bg-danger bg-opacity-10" type="button" data-bs-toggle="collapse" data-bs-target="#collapseApprovedWorks" aria-expanded="false" aria-controls="collapseApprovedWorks">
                            <span className='text-danger'>Details of Approved Works <span className='fs-80'>- Error/s need to be resolved</span></span>
                            </button> : 
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseApprovedWorks" aria-expanded="false" aria-controls="collapseApprovedWorks">
                            Details of Approved Works 
                            </button>
                        }
                    </h2>
                    <div id="collapseApprovedWorks" class="accordion-collapse collapse" aria-labelledby="headingApprovedWorks" >
                        <div class="accordion-body">
                            <ValidationApprovedWorksFields validationForm={form} meterCommission={meterCommission}/>
                        </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingCustomerSite">
                        {
                            hasErrCusSite?
                            <button class="accordion-button collapsed bg-danger bg-opacity-10" type="button" data-bs-toggle="collapse" data-bs-target="#collapseCustomerSite" aria-expanded="false" aria-controls="collapseCustomerSite">
                            <span className='text-danger'>Customer Site Details <span className='fs-80'>- Error/s need to be resolved</span></span>
                            </button>:
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseCustomerSite" aria-expanded="false" aria-controls="collapseCustomerSite">
                            Customer Site Details
                            </button>
                        }
                    </h2>
                    <div id="collapseCustomerSite" class="accordion-collapse collapse" aria-labelledby="headingCustomerSite" >
                        <div class="accordion-body">
                            <ValidationCustomerSiteFields validationForm={form} meterCommission={meterCommission}/>
                        </div>
                    </div>
                </div>
                
                {form.val('newMeterEquipment') == '3' && <>
                    {form.val('replacingMeter') ? <>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headingOldMeter">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOldMeter" aria-expanded="false" aria-controls="collapseOldMeter">
                                    Old Meter Details
                                </button>
                            </h2>
                            <div id="collapseOldMeter" class="accordion-collapse collapse" aria-labelledby="headingOldMeter" >
                                <div class="accordion-body">
                                    <ValidationReplacingMeterFields validationForm={form}/>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headingOldMeterLocation">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOldMeterLocation" aria-expanded="false" aria-controls="collapseOldMeterLocation">
                                    Old Meter Location
                                </button>
                            </h2>
                            <div id="collapseOldMeterLocation" class="accordion-collapse collapse" aria-labelledby="headingOldMeterLocation" >
                                <div class="accordion-body">
                                    <ValidationReplacingMeterLocation validationForm={form}/>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headingOldMeterLidDetails">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOldMeterLidDetails" aria-expanded="false" aria-controls="collapseOldMeterLidDetails">
                                    Old Lid Details
                                </button>
                            </h2>
                            <div id="collapseOldMeterLidDetails" class="accordion-collapse collapse" aria-labelledby="headingOldMeterLidDetails" >
                                <div class="accordion-body">
                                    <ValidationReplacingLidFields validationForm={form}/>
                                </div>
                            </div>
                        </div>
                    </>:form.val('replacingWasMeter') ? <>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headingOldMeter">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOldMeter" aria-expanded="false" aria-controls="collapseOldMeter">
                                    Old Meter Details
                                </button>
                            </h2>
                            <div id="collapseOldMeter" class="accordion-collapse collapse" aria-labelledby="headingOldMeter" >
                                <div class="accordion-body">
                                    <ValidationReplacingWasMeterFields validationForm={form}/>
                                </div>
                            </div>
                        </div>
                    </> : null}

                
                </>}
                

                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingMeterEquipment">
                        {
                            hasErrMeterEquip?
                            <button class="accordion-button collapsed bg-danger bg-opacity-10" type="button" data-bs-toggle="collapse" data-bs-target="#collapseMeterEquipment" aria-expanded="false" aria-controls="collapseMeterEquipment">
                            <span className='text-danger'>
                                {(form.val('newMeterEquipment') == '1' || form.val('newMeterEquipment') == '3') && 'New '}
                                {(form.val('newMeterEquipment') == '2' || form.val('newMeterEquipment') == '4' || form.val('newMeterEquipment') == '6') && 'Existing '}
                                Meter Details 
                                <span className='fs-80'>- Error/s need to be resolved</span></span>
                            </button>:
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseMeterEquipment" aria-expanded="false" aria-controls="collapseMeterEquipment">
                                {(form.val('newMeterEquipment') == '1' || form.val('newMeterEquipment') == '3') && 'New '}
                                {(form.val('newMeterEquipment') == '2' || form.val('newMeterEquipment') == '4' || form.val('newMeterEquipment') == '6') && 'Existing '}
                                Meter Details
                            </button>
                        }
                        
                    </h2>
                    <div id="collapseMeterEquipment" class="accordion-collapse collapse" aria-labelledby="headingMeterEquipment" >
                        <div class="accordion-body">
                            <ValidationMeterEquipmentDetails validationForm={form} meter={this.props.meter}/>
                        </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingMeterLocation">
                        {
                            hasErrMeterLoc?
                            <button class="accordion-button collapsed bg-danger bg-opacity-10" type="button" data-bs-toggle="collapse" data-bs-target="#collapseMeterLocation" aria-expanded="false" aria-controls="collapseMeterLocation">
                            <span className='text-danger'>
                                {(form.val('newMeterEquipment') == '1' || form.val('newMeterEquipment') == '3') && 'New '}
                                {(form.val('newMeterEquipment') == '2' || form.val('newMeterEquipment') == '4' || form.val('newMeterEquipment') == '6') && 'Existing '}
                                Meter Location <span className='fs-80'>- Error/s need to be resolved</span></span>
                            </button>:
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseMeterLocation" aria-expanded="false" aria-controls="collapseMeterLocation">
                                {(form.val('newMeterEquipment') == '1' || form.val('newMeterEquipment') == '3') && 'New '}
                                {(form.val('newMeterEquipment') == '2' || form.val('newMeterEquipment') == '4' || form.val('newMeterEquipment') == '6') && 'Existing '}
                                Meter Location
                            </button>
                        }
                        
                    </h2>
                    <div id="collapseMeterLocation" class="accordion-collapse collapse" aria-labelledby="headingMeterLocation" >
                        <div class="accordion-body">
                            <ValidationMeterLocation validationForm={form}/>
                        </div>
                    </div>
                </div>
                {form.val('meteringSystemTypes') && (form.val('meteringSystemTypes').key=='CLOSED_CONDUIT' || form.val('meteringSystemTypes').key=='PORTABLE_METER') && (
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="headingPipeDetails">
                            {
                                hasErrEmplacement?
                                <button class="accordion-button collapsed bg-danger bg-opacity-10" type="button" data-bs-toggle="collapse" data-bs-target="#collapsePipeDetails" aria-expanded="false" aria-controls="collapsePipeDetails">
                                <span className='text-danger'>Emplacement Details <span className='fs-80'>- Error/s need to be resolved</span></span>
                                </button>:
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsePipeDetails" aria-expanded="false" aria-controls="collapsePipeDetails">
                                Emplacement Details
                                </button>
                            }
                            
                        </h2>
                        <div id="collapsePipeDetails" class="accordion-collapse collapse" aria-labelledby="headingPipeDetails" >
                            <div class="accordion-body">
                                <ValidationEmplacementFields validationForm={form}/>
                            </div>
                        </div>
                    </div>
                )}
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingLidDetails">
                        {
                            hasErrLid?
                            <button class="accordion-button collapsed bg-danger bg-opacity-10" type="button" data-bs-toggle="collapse" data-bs-target="#collapseLidDetails" aria-expanded="false" aria-controls="collapseLidDetails">
                            <span className='text-danger'>
                                {(form.val('newMeterEquipment') != '5' && (!form.vals().lid.allowMultiple) || form.vals().isFirstMeterForLID===true) && 'New '}
                                {(form.val('newMeterEquipment') == '5') && 'Existing '}
                                LID Details<span className='fs-80'>- Error/s need to be resolved</span></span>
                            </button>:
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseLidDetails" aria-expanded="false" aria-controls="collapseLidDetails">
                                {(form.val('newMeterEquipment') != '5' && (!form.vals().lid.allowMultiple) || form.vals().isFirstMeterForLID===true) && 'New '}
                                {(form.val('newMeterEquipment') == '5') && 'Existing '}
                                LID Details
                            </button>
                        }
                    </h2>
                    <div id="collapseLidDetails" class="accordion-collapse collapse" aria-labelledby="headingLidDetails" >
                        <div class="accordion-body">
                            <ValidationLidFields validationForm={form}/>
                        </div>
                    </div>
                </div>
            </div>

            {form.val('formStatus')==1 && <div class="text-end">
                {form.haveErrors() && <div className='alert alert-danger text-end'>
                Please complete the required information and resolve any errors that may display in order to proceed further.
                </div>}

                <button type="button" class="btn btn-primary float-start"  disabled={form.isFormDisabled()} onClick={()=>this.props.gotToStep(1)} >
                    Back
                </button>

                {role=="OWNER" && <button type="button"   disabled={form.isFormDisabled()} class="btn btn-primary me-3" onClick={()=>form.runAction('save')} >
                    Save
                </button>}
                <button type="button" class="btn btn-success"  disabled={form.isFormDisabled()} onClick={()=>form.runAction('goToStep3')} >
                    Next
                </button>
            </div>}
        </>;
    }
}