import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';
import Constants from "./constants/Constants";

const tagManagerArgs = {
  gtmId: Constants.GTM_TAG
};

ReactGA.initialize(Constants.GTAG_ID);
TagManager.initialize(tagManagerArgs);
