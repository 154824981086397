import React from 'react';
import * as apiPathes from '../../apiPathes';
import * as serviceCaller from '../../serviceCaller'
import { Redirect } from 'react-router-dom';
import { convertDateIsoToAusFormat, convertOtherInFlowType} from '../../conveters'
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import {ReactSelect, InputText, ReactDatePicker} from '../../../components/tools/BootstrapControls'
import {isStringNullOrEmpty, isNumber3DecimalPositiveOnly} from '../../../helpers/validators'

const inFlowTypeOptions = [
    {label:"- Select -", value:""},
    {label:"Regulated Water", value:"REG"},
    {label:"Unregulated water", value:"UNREG"},
    {label:"Goundwater", value:"GROUNDWATER"},
    {label:"Supplementary water", value:"SUPPLEMENTARY"}
]

class AddOtherInflowForm  extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            saving: false,
            startDate: null,
            endDate: null,
            inFlowType: null,
            volume: null,
            accessLicense: null,

            startDateError: null,
            endDateError:null,
            inFlowTypeError:null,
            volumeError: null,
            accessLicenseError: null,

            overallError: null
        };
    }
    
    componentDidMount() {
          
    }

    validateForm = () => {

        const period = this.props.nominatedMeasurementPeriod;
        const periodStartDate = new Date(period.periodStartDate);
        const periodEndDate = period.periodEndDate ? new Date(period.periodEndDate) : new Date();

        const periodStartWithoutTime = new Date(
            periodStartDate.getFullYear(),
            periodStartDate.getMonth(),
            periodStartDate.getDate()
        );
        const periodEndWithoutTime = new Date(
            periodEndDate.getFullYear(),
            periodEndDate.getMonth(),
            periodEndDate.getDate()
        );

        const {startDate,endDate} = this.state;
        const startDateWithoutTime = startDate ? new Date(
            startDate.getFullYear(),
            startDate.getMonth(),
            startDate.getDate()
        ):null;
        const endDateWithoutTime = endDate ? new Date(
            endDate.getFullYear(),
            endDate.getMonth(),
            endDate.getDate()
        ) : null;

        const today = new Date();
        this.state.startDateError = null;
        this.state.endDateError = null;
        this.state.inFlowTypeError = null;
        this.state.volumeError = null;
        this.state.accessLicenseError = null;

        if(!startDate) this.state.startDateError = "This is required";
        else if(startDate > today ) this.state.startDateError = "This cannot be future date";
        else if(periodStartWithoutTime > startDateWithoutTime || periodEndWithoutTime < startDateWithoutTime) this.state.startDateError = "This cannot be outside the measurement period";

        if(!endDate) this.state.endDateError = "This is required";
        else if(endDate > today) this.state.endDateError = "This cannot be future date";
        else if(startDate && startDate > endDate) this.state.endDateError = "End date needs to be after start date";
        else if(periodStartWithoutTime > endDateWithoutTime || periodEndWithoutTime < endDateWithoutTime) this.state.endDateError = "This cannot be outside the measurement period";

        if(isStringNullOrEmpty(this.state.inFlowType)) this.state.inFlowTypeError = "This is required";

        if(isStringNullOrEmpty(this.state.volume)) this.state.volumeError = "This is required";
        else if(!isNumber3DecimalPositiveOnly(this.state.volume)) this.state.volumeError = "This must be a positive number with maximum 3 decimal places";

        const hasError = (this.state.startDateError || this.state.endDateError || this.state.volumeError || this.state.inFlowTypeError || this.state.accessLicenseError);
        if(hasError) this.setState(this.state)
        return !hasError;
    }

    saveChanges = async () =>{
        if(!this.validateForm()){
            return;
        }


        if(!await serviceCaller.post(apiPathes.addOtherInFlow,
            {
                nominatedMeasurementPeriodId : this.props.nominatedMeasurementPeriod.id,
                startDate : this.state.startDate,
                endDate : this.state.endDate,
                inFlowType : this.state.inFlowType,
                volume : parseFloat(this.state.volume),
                accessLicense : this.state.accessLicense
            }, 
            {
                noIWasLoginHandler: this.props.noLoginCallback,
                loadingMsg: 'Saving your other inflow record....',
                beforeSend: ()=>{
                    this.setState({saving: true})
                },
                success: (data)=>{
                    this.props.onAddCompleted(data)
                }
        })) this.setState({saving: false});
    }

    render(){
        const {saving} = this.state;
        return <tr className='border-primary border-2 align-top'>
            <td>
                <ReactDatePicker 
                    maxDate={new Date()}
                    onChange={(date)=>{this.setState({startDate:date})}}
                    value={this.state.startDate}
                    error={this.state.startDateError}
                    disabled={saving}
                />
            </td>
            <td>
                <ReactDatePicker 
                    maxDate={new Date()}
                    onChange={(date)=>{this.setState({endDate:date})}}
                    value={this.state.endDate}
                    error={this.state.endDateError}
                    disabled={saving}
                />
            </td>
            
            <td>
                <ReactSelect 
                    options={inFlowTypeOptions}
                    onChange={(e)=>{this.setState({inFlowType: e.value})}}
                    value={this.state.inFlowType}
                    isDisabled={saving}
                    error={this.state.inFlowTypeError}
                    disabled={saving}
                />
            </td>
            <td>
                <InputText 
                    onChange={(e)=>{this.setState({accessLicense: e.target.value})}}
                    value={this.state.accessLicense}
                    error={this.state.accessLicenseError}
                    disabled={saving}
                />
            </td>
            <td>
                <InputText 
                    onChange={(e)=>{this.setState({volume: e.target.value})}}
                    value={this.state.volume}
                    error={this.state.volumeError}
                    disabled={saving}
                />
            </td>
            <td className='text-end'>
                <button className='btn btn-sm btn-outline-primary me-2' disabled={saving} onClick={this.saveChanges}>&nbsp;&nbsp;Save&nbsp;&nbsp;&nbsp;</button>
                <button className='btn btn-sm btn-outline-secondary' disabled={saving} onClick={()=>this.props.toggleForm()} >Cancel</button>
            </td>
        </tr>
    }

}

export default AddOtherInflowForm;