import React from 'react';
import { AsteriskRed } from '../../../../components/tools/Others';
import { StepProgressBar, UneditableTextInput } from '../../../../components/tools/BootstrapControls';
import moment from 'moment';

export default class ValidationPage1 extends React.Component {

    constructor(props) {  
      super(props);
      this.state = {
          confirming : false,
          current : {
            meterUnitOfMeasures:null,
            meterFlowUnits:null
          },
          triggerError : null
      }
    }

    triggerCommissioning = () => {
      const form = this.props.validationForm;
      this.setState({confirming:true,current:{
        meterUnitOfMeasures : form.val('meterUnitOfMeasures'),
        meterFlowUnits : form.val('meterFlowUnits')
      }})
    }

    cancel = () => {
      const form = this.props.validationForm;
      this.setState({confirming:false, triggerError: null},()=>{
        form.vals({
          meterUnitOfMeasures:this.state.current.meterUnitOfMeasures,
          meterFlowUnits:this.state.current.meterFlowUnits
        })
      })
    }

    confirm = () => {
      const form = this.props.validationForm;
      form.runAction('triggerCommissioning', this.triggerCompleted );
    }

    triggerCompleted = () => {
      const form = this.props.validationForm;
      this.setState({confirming:false, triggerError: null , current:{
        meterUnitOfMeasures : form.val('meterUnitOfMeasures'),
        meterFlowUnits : form.val('meterFlowUnits')
      }})
    }

    render() {
        const confirming = this.state.confirming;
        const form = this.props.validationForm;
        const commissiongHistories = this.props.commissiongHistories;
        return <>
            <div className='my-5'>      
                <StepProgressBar
                    totalSteps={5} 
                    currentStep={1} 
                    disableFutureSteps={form.val('formStatus')==1} 
                    stepClick={(page) =>{this.props.gotToStep(page)}}
                />
            </div>
            <div class="card mb-3">
                <div class="card-header bg-primary text-white">
                    <b>Site Commissioning</b>
                </div>
                <div className='card-body'>
                    <h6 className='border-bottom my-3 pb-2'>History of Commissioning</h6>
                    {commissiongHistories.length== 0 && <div className='bg-light text-center p-5 mb-5'>No record of meter compliance process</div>}
                    {commissiongHistories.length > 0 && <table className='table table-striped mb-5'>
                        <thead>
                            <tr>
                              <th scope="col">Date</th>
                              <th scope="col">By</th>
                              <th scope="col">Unit of measure as displayed on meter</th>
                              <th scope="col">Unit of measure transmitted by LID</th>
                            </tr>
                        </thead>
                        <tbody>
                            {commissiongHistories.map(h=><tr>
                                <td>
                                    {moment((new Date(h.createdDate))).format('DD/MM/YYYY h:mm a')}
                                </td>
                                <td>
                                    {h.createdBy.firstname} {h.createdBy.lastname}
                                </td>
                                <td>
                                    {h.meterUnitOfMeasures.name}
                                </td>
                                <td>
                                    {h.meterFlowUnits.name}
                                </td>
                                
                            </tr>)}
                        </tbody>
                    </table>}

                    {!confirming && <>
                        <div className='row mb-3'>
                            <div className='col-md-2' />
                            <div className='col-md-4'>
                                <UneditableTextInput
                                    label="Current unit of measure as displayed on meter"
                                    value={form.val('meterUnitOfMeasures')?form.val('meterUnitOfMeasures').name:<i>Nil</i>}
                                />
                            </div>
                            <div className='col-md-4'>
                                <UneditableTextInput
                                    label="Current unit of measure transmitted by LID"
                                    value={form.val('meterFlowUnits')?form.val('meterFlowUnits').name:<i>Nil</i>}
                                    error={form.errs().meterFlowUnits}
                                />
                            </div>
                            <div className='col-md-2' />
                        </div>
                      

                        <div class="d-grid gap-2 col-5 mx-auto mt-4">
                            <button className='btn btn-primary btn-lg' onClick={this.triggerCommissioning}>Trigger a Commissioning Activity</button>
                        </div>
                    </>}


                    {confirming && <>
                        <div className='card card-body bg-primary bg-opacity-10 mt-4 border border-primary border-2'>
                            {form.renderDropDownRow("meterUnitOfMeasures",<>Unit of measure as displayed on meter <AsteriskRed/></>,(o)=>o.id,o=>o.name,{labelWidth:4,fieldWidth:4,rowClasses:'mb-3'})}

                            {(form.vals().newMeterEquipment == "5" && form.vals().lid.allowMultiple)  ? <>
                                {form.renderUneditableTextInputRow('meterFlowUnits',"Unit of measure transmitted by LID :", {rowClasses:'mb-3',fieldWidth:4, converter:u=>u?u.name:null})}
                            </> : <>
                                {form.renderDropDownRow("meterFlowUnits",<>Unit of measure transmitted by LID <AsteriskRed/></>,(o)=>o.id,o=>o.name,{labelWidth:4,fieldWidth:4,rowClasses:'mb-3'})}
                            </>}
                            
                            <div className='text-center'>
                                <p className='text-danger'>
                                  {this.state.triggerError}
                                </p>
                                <button className='btn btn-secondary me-3' disabled={form.isFormDisabled()} onClick={this.cancel}>Cancel</button>
                                <button className='btn btn-primary' disabled={form.isFormDisabled()} onClick={this.confirm}>Confirm</button>
                            </div>
                        </div>
                    </>}
                </div>
            </div>

            <div class="text-end">
                <button type="button" class="btn btn-success"  onClick={()=>form.runAction('goToStep2')} disabled={commissiongHistories.length== 0 || confirming}>
                    Next
                </button>
            </div>
        </>;
    }
}