import React from 'react';
import Authentication from '../../helpers/Authentication'

import axios from 'axios';
import $ from 'jquery';


const authentication = new Authentication();
class EsidSelect extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            esidsLoaded : false,
            esids : [],
            esidsCannotBeSeleted : [],
            selectedEsid : null,
            defaultEsid : null
        }
    }

    ajaxConfig = {
        baseURL: process.env.REACT_APP_WEBAPI_URL,
        headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authentication.getToken() }
    };

    componentDidUpdate(prevProps) {
        if(!this.props.defaultEsid && this.state.selectedEsid){
            this.setState(Object.assign(this.state,{selectedEsid : null}));
        }
    }

    componentDidMount() {
        let currentContext = this;
        axios.post('/datalake/getESIDsDetailsByLicenceNumber', JSON.stringify({ 
            workApprovalNo: this.props.approvalNumber
        }), this.ajaxConfig).then(function (response) {
            currentContext.setState(Object.assign(currentContext.state,{esidsCannotBeSeleted : response.data.data.esidsCannotBeSeleted, esids : response.data.data.esids, esidsLoaded: true}));
        }).catch(function (error) {
            alert('Error happened on the server side when getting esids details.');
        }).then(function () {
            
        }); 
    }   

    esidSelected = (esid) => {
        if(this.state.selectedEsid && this.state.selectedEsid.esid == esid.esid){
            return;
        }
        this.setState(Object.assign(this.state,{selectedEsid : esid}));
        this.props.onEsidChange(esid);
    }

    esidInputClick = (e) =>{
        $(e.target).parent().find('[data-bs-toggle="dropdown"]').trigger('click');
    }


    render() {

        return <>
            <div class={`input-group ${this.props.error && this.props.error != "" ?"is-invalid":""}`}>
                <input type="text" className={`form-control ${this.props.error && this.props.error != "" ?"is-invalid":""} ${!this.state.esidsLoaded ? 'fst-italic' : ''}`} disabled={!this.state.esidsLoaded || this.props.disabled} onFocus={(e) => { e.preventDefault(); }} onClick={this.esidInputClick} onKeyPress={(e) => { e.preventDefault(); }} 
                value={!this.state.esidsLoaded? 'Loading...' : this.state.selectedEsid ? this.state.selectedEsid.esid : this.props.defaultEsid ? this.props.defaultEsid : ''} />
                

                <button class="btn btn-outline-secondary dropdown-toggle" disabled={!this.state.esidsLoaded || this.props.disabled} type="button" data-bs-toggle="dropdown" aria-expanded="false" />
                <div class="dropdown-menu dropdown-menu-end" style={{"min-width" : "500px"}}>
                                    <p className="text-center mb-3 mt-3">
                                        <button class="btn btn-warning btn-sm" type="button" data-bs-toggle="modal" data-bs-target="#esidCannotbeSelectedModal">Cannot find your ESID?</button>
                                    </p>
                                    
                                    {this.state.esids.length!=0 && <div className='px-2'>
                                    <table class="table table-hover table-sm">
                                        <thead>
                                            <tr className='table-primary'>
                                                <td colSpan="6" class="text-center ">
                                                    <strong>Select a ESID below</strong>
                                                </td>
                                            </tr>
                                        </thead>
                                        <tbody className='fs-90'>
                                            <tr>
                                                <th >ESID</th>
                                                <th >Works ID</th>
                                                <th >WAS Status</th>
                                                <th>Work description</th>
                                                <th >No. of works</th>
                                            </tr>
                                            {this.state.esids.map((item) =>{
                                                return <tr key={item.worksiteId} style={{cursor : "pointer"}} onClick={() => {this.esidSelected(item)}} className={(this.state.selectedEsid && this.state.selectedEsid.esid==item.esid) ? 'table-warning' : ''}>
                                                        <td className="table-primary">{item.esid}</td>
                                                        <td>{item.workId}</td>
                                                        <td>{item.wasExtractionSiteStatus}</td>
                                                        <td>{item.workTypeDescription}</td>
                                                        <td>{item.numberOfWorks}</td>
                                                    </tr>
                                            })}
                                        </tbody>
                                    </table>
                                    </div>}
                                    

                                    {this.state.esids.length==0 && <table class="table">
                                        <tr><td class="text-center bg-info">No ESID found</td></tr>
                                    </table>}
                </div>
                
            </div>

            <div class="invalid-feedback">
                {this.props.error}
            </div>


            <div id="esidCannotbeSelectedModal" class="modal fade" role="dialog">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title">ESID cannot be selected</h4>
                        </div>
                        <div class="modal-body">
                            <div class="alert alert-warning" role="alert">
                                <p>
                                There are various reasons that the ESIDs below cannot be selected. 
                                </p>
                                <p>
                                If any of the shown status below are marked red, they will need to be corrected before you can select this ESID.
                                </p>
                                <p>
                                Your customer will need to resolve this with WaterNSW.
                                </p>
                            </div>
                            {this.state.esidsCannotBeSeleted.length==0 && <table className="table table-primary">
                                <thead>
                                    <tr><td class="text-center ">No ESID that cannot be selected under this approval</td></tr>
                                </thead>
                            </table>}
                            {this.state.esidsCannotBeSeleted.length!=0 && <table class="table">
                                <tbody>
                                    <tr>
                                        <th style={{width:'5px'}}></th>
                                        <th style={{width:'15%'}}>ESID</th>
                                        <th style={{width:'15%'}}>Works ID</th>
                                        <th>Work description</th>
                                        <th>Site status</th>
                                        <th>Site status in WAS</th>
                                        <th>Work status</th>
                                        <th>Transaction status</th>
                                        <th style={{width:'5%'}}></th>
                                    </tr>
                                    {this.state.esidsCannotBeSeleted.map((item) =>{
                                        return <tr key={item.esid}>
                                                <td></td>
                                                <td className="table-primary">{item.esid}</td>
                                                <td>{item.workId}</td>
                                                <td>{item.workTypeDescription}</td>
                                                <td className={ item.status=='Active' ? "" : "bg-danger"}>{item.status}</td>
                                                <td className={ item.wasExtractionSiteStatus=='Active' ? "" : "bg-danger"}>{item.wasExtractionSiteStatus}</td>
                                                <td className={ item.workStatus=='Active' ? "" : "bg-danger"}>{item.workStatus}</td>
                                                <td className={ item.wasExtractionSiteTransactionStatus=='Current' ? "" : "bg-danger"}>{item.wasExtractionSiteTransactionStatus}</td>
                                                <td></td>
                                            </tr>
                                    })}
                                </tbody>
                            </table>}
                        </div>
                        <div class="modal-footer">
                            <button type="button" id="closeEsid" class="btn btn-primary" data-bs-dismiss="modal">Got it</button>
                        </div>
                    </div>
                </div>
            </div>
        </>


    }

}

export default EsidSelect;
