import React from 'react';
import { AsteriskRed } from '../../../../components/tools/Others';

export default class ValidationApprovedWorksFields  extends React.Component {

    render = () => {
        const form = this.props.validationForm;
        const meterCommission = this.props.meterCommission;
        return <>
            {meterCommission.formVersion == 1 && <>
                {form.renderDropDownRow('dlwcProperty','Property',(o)=>o.dlwcPropertyId, (o)=>o.propertyName,{rowClasses:'mb-3'})}
                {form.renderDropDownRow('dlwcLot','Lot/DP ',(o)=>o.dlwcLotId, (o)=><>{o.lotNumber}/{o.sectionNumber}/{o.planNumber} {o.planType}</>,{rowClasses:'mb-3'})}
            </>}
            {meterCommission.formVersion == 2 && <>
                {form.renderUneditableTextInputRow('dlwcProperty',<>Property</>,{rowClasses:'mb-3',converter:o=>o?o.propertyName:null})}
                {form.renderUneditableTextInputRow('dlwcLot',<>Lot/DP</>,{rowClasses:'mb-3',converter:o=>o?<>{o.lotNumber}/{o.sectionNumber}/{o.planNumber} {o.planType}</>:null})}
            </>}
            {form.renderUneditableTextInputRow('extractionSite',<>Extraction Site</>,{rowClasses:'mb-3',converter:site=>site?site.esid:null})}
            {form.renderUneditableTextInputRow('extractionSite',<>Water Sharing Plan</>,{rowClasses:'mb-3',converter:site=>site?site.waterSharingPlanDesc:null})}
            {form.renderUneditableTextInputRow('extractionSite',<>Water Source</>,{rowClasses:'mb-3',converter:site=>site?site.waterSourceName:null})}
            {form.renderUneditableTextInputRow('extractionSite',<>Water Type</>,{rowClasses:'mb-3',converter:site=>site?site.waterType:null})}
            {form.renderUneditableTextInputRow('works',<>Works ID</>,{rowClasses:'mb-3',converter:works=>works?works.worksId:null})}
            {form.renderUneditableTextInputRow('works',<>Works Description</>,{rowClasses:'mb-3',converter:works=>works?works.workType:null})}
        </>
    }
}