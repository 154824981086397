// note on window.msal usage. There is little point holding the object constructed by new Msal.UserAgentApplication
// as the constructor for this class will make callbacks to the acquireToken function and these occur before
// any local assignment can take place. Not nice but its how it works.
import * as Msal from 'msal'
import React from 'react'

const LOCAL_STORAGE = 'localStorage'
const SESSION_STORAGE = 'sessionStorage'
const AUTHORIZATION_KEY = 'Authorization'

const state = {
  stopLoopingRedirect: false,
  launchApp: null,
  accessToken: null,
  scopes: [],
  cacheLocation: null
}

function authCallback (errorDesc, token, error, tokenType) {
  if (errorDesc && errorDesc.indexOf('AADB2C90118') > -1) {
    redirect()
  } else if (errorDesc && errorDesc.indexOf('AADB2C90091') > -1) {
    window.location = process.env.REACT_APP_B2C_REDIRECT_URI
  } else if (errorDesc) {
    state.stopLoopingRedirect = true
  } else {
    acquireToken()
  }
}

function redirect () {
  acquireToken()
}

function acquireTokenWithoutRedirection (successCallback) {
  const user = window.msal.getUser(state.scopes)
  if (!user) {
    //window.msal.loginRedirect(state.scopes)
  } else {
    window.msal.acquireTokenSilent(state.scopes).then(accessToken => {
      if (state.cacheLocation === LOCAL_STORAGE) {
        window.localStorage.setItem(AUTHORIZATION_KEY, 'Bearer ' + accessToken)
      } else {
        window.sessionStorage.setItem(AUTHORIZATION_KEY, 'Bearer ' + accessToken)
      }

      state.accessToken = accessToken
      if (state.launchApp) {
        state.launchApp()
      }
      if (successCallback) {
        successCallback()
      }
    }, error => {
      if (error) {
        window.msal.acquireTokenRedirect(state.scopes)
      }
    })
  }
}

function acquireToken (successCallback) {
  const user = window.msal.getUser(state.scopes)
  if (!user) {
    window.msal.loginRedirect(state.scopes)
  } else {
    window.msal.acquireTokenSilent(state.scopes).then(accessToken => {
      if (state.cacheLocation === LOCAL_STORAGE) {
        window.localStorage.setItem(AUTHORIZATION_KEY, 'Bearer ' + accessToken)
      } else {
        window.sessionStorage.setItem(AUTHORIZATION_KEY, 'Bearer ' + accessToken)
      }

      state.accessToken = accessToken
      if (state.launchApp) {
        state.launchApp()
      }
      if (successCallback) {
        successCallback()
      }
    }, error => {
      if (error) {
        window.msal.acquireTokenRedirect(state.scopes)
      }
    })
  }
}

const cleanUpStorage = cacheLocation => {
  if (cacheLocation === LOCAL_STORAGE) {
    window.localStorage.removeItem(AUTHORIZATION_KEY)
  } else if (cacheLocation === SESSION_STORAGE) {
    window.sessionStorage.removeItem(AUTHORIZATION_KEY)
  }
}
const config = {
    instance: process.env.REACT_APP_B2C_INSTANCE,
      tenant: process.env.REACT_APP_B2C_TENANT,
      signInPolicy: process.env.REACT_APP_B2C_SIGNIN_SIGNOUT_POLICY,
      signUpPolicy: process.env.REACT_APP_B2C_SIGNUP_POLICY,
      profilePolicy: process.env.REACT_APP_B2C_PROFILE_EDIT_POLICY,
      clientId: process.env.REACT_APP_B2C_SECRET_KEY,
      cacheLocation: process.env.REACT_APP_B2C_CACHE_LOCATION,
      scopes: [process.env.REACT_APP_B2C_SCOPES],
      redirectUri: process.env.REACT_APP_B2C_REDIRECT_URI,
      postLogoutRedirectUri: process.env.REACT_APP_B2C_LOGOUT_REDIRECT_URI
}
const tenantSubdomain = config.tenant.split('.')[0]
const instance = `https://${tenantSubdomain}.b2clogin.com/tfp/`
let authority = `${instance}${config.tenant}/${config.signInPolicy}`

const authentication = {
  
  initialize: () => {   
    authority = `${instance}${config.tenant}/${config.signInPolicy}`
    cleanUpStorage(config.cacheLocation)
    let scopes = config.scopes
    if (!scopes || scopes.length === 0) {
      console.log('To obtain access tokens you must specify one or more scopes. See https://docs.microsoft.com/en-us/azure/active-directory-b2c/active-directory-b2c-access-tokens')
      state.stopLoopingRedirect = true
    }
    state.scopes = scopes
    state.cacheLocation = config.cacheLocation

    if (config.redirectUri) {
      new Msal.UserAgentApplication(
        config.clientId,
        authority,
        authCallback,
        {
          cacheLocation: config.cacheLocation,
          redirectUri: config.redirectUri,
          postLogoutRedirectUri: config.postLogoutRedirectUri,
          validateAuthority: false,
          storeAuthStateInCookie: true
        }
      )
    } else {
      new Msal.UserAgentApplication(
        config.clientId,
        authority,
        authCallback,
        {
          cacheLocation: config.cacheLocation,
          validateAuthority: false,
          storeAuthStateInCookie: true
        }
      )
    }
  },
  run: (launchApp) => {
    state.launchApp = launchApp
    if (!window.msal.isCallback(window.location.hash) && window.parent === window && !window.opener) {
      if (!state.stopLoopingRedirect) {
        acquireToken()
      }
    }
  },

  tryRequiredTokenWithRedirection : (callback) => {
    acquireToken(callback)
  },

  tryRequiredTokenWithoutRedirection : (callback) => {
    acquireTokenWithoutRedirection(callback)
  },

  required: (WrappedComponent, renderLoading) => {    
    return class extends React.Component {
      constructor (props) {
        super(props)
        this.state = {
          signedIn: false,
          error: null
        }
      }
 
      componentWillMount () {
        acquireToken(() => {
          this.setState({
            signedIn: true
          })
        })
      }

      render () {
        if (this.state.signedIn) {
          return (<WrappedComponent {...this.props} />)
        }
        return typeof renderLoading === 'function' ? renderLoading() : null
      }
    }
  },
  goToHomeIfLoggedIn: (WrappedComponent, HomeComponent) => {
    return class extends React.Component {
      constructor (props) {
        super(props)
        this.state = {
          signedIn: false,
          error: null
        }
      }

      componentWillMount () {
        acquireTokenWithoutRedirection(() => {
          this.setState({
            signedIn: true
          })
        })
      }

      render () {
        if (this.state.signedIn) {
          //return window.location = "Home";
          return (<HomeComponent {...this.props} />);
        }
        return (<WrappedComponent {...this.props} />);
      }
    }
  },
  signOut: () => window.msal.logout(),
  getAccessToken: () => { acquireToken(); return state.accessToken}, 

  getTokenSilentAsync : async ()=>{
    return window.msal.acquireTokenSilent(state.scopes).then(accessToken => {
      if (state.cacheLocation === LOCAL_STORAGE) {
        window.localStorage.setItem(AUTHORIZATION_KEY, 'Bearer ' + accessToken)
      } else {
        window.sessionStorage.setItem(AUTHORIZATION_KEY, 'Bearer ' + accessToken)
      }

      return accessToken;
    }, error => {
      return null;
    })
  } ,

  initAccessToken: (callback) => { acquireToken(callback)},
  setAccessToken: (accessToken) => {state.accessToken = accessToken},
  login: () => {
    window.msal.authority = `${instance}${config.tenant}/${config.signInPolicy}`;
    //redirect();
  },
  signUp: () => {
    window.msal.authority = `${instance}${config.tenant}/${config.signUpPolicy}`;
    //redirect();
  },
  profile: () => {
    window.msal.authority = `${instance}${config.tenant}/${config.profilePolicy}`;
    
    if (state.cacheLocation === LOCAL_STORAGE) {
      
      var keys = Object.keys(window.localStorage);
      console.log("localstorage"+keys)
      for(var key of keys) {
        if (key.startsWith('{"authority":')) {
          var val = JSON.parse(window.localStorage.getItem(key));
          if (val != null) {
            window.localStorage.removeItem(key);
          }
        } 
      }
    } else {
      var keys = Object.keys(window.sessionStorage)
      console.log("sessionstorage"+keys)
      for (var key of keys ) {
        if (key.startsWith('{"authority":')) {
          var val = JSON.parse(window.sessionStorage.getItem(key))
          if (val != null) {
            window.sessionStorage.removeItem(key);
          }
        }
      }
    }

    window.msal.acquireTokenRedirect(state.scopes,`${instance}${config.tenant}/${config.profilePolicy}`,window.msal.getUser(state.scopes),{forceRefresh:true,storeAuthStateInCookie: true})
  },
  isLoggedIn: () => {
    var user = window.msal.getUser(state.scopes)
    if(!user) 
      return false;
    else
      return true;
  },

  isLoggedInAndTokenValid: async () => {
    var user = window.msal.getUser(state.scopes)
    if(!user) 
      return false;

    var token = await window.msal.acquireTokenSilent(config.scopes).then(
      (accessToken) => {
        if (state.cacheLocation === LOCAL_STORAGE) {
          window.localStorage.setItem(AUTHORIZATION_KEY, 'Bearer ' + accessToken)
        } else {
          window.sessionStorage.setItem(AUTHORIZATION_KEY, 'Bearer ' + accessToken)
        }
        return accessToken;
      },
      async (error) => {
        console.log(error)
        return null;
      }
    );

    if(token) return true;
    return false;
  }

}

export default authentication
