import React, { useContext, useEffect, useState } from 'react';
import { formatDateTime } from '../constants/MeterFormConstants';
import { GlobalContext } from './State';
import { Statement } from './Statement';
import { getSubmission, getNominationPDF, deleteMeasurementPeriod } from './API';

const DRAFT_TABLE_COL = "draftTable";
const Intro2 = () => {
    const { holder: { referenceData }, flickPage, setReferenceData } = useContext(GlobalContext);
    const [selectedNomination, setSelectedNomination] = useState(0);
    const [pdfDownloading, setPdfDownloading] = useState(false);

    let forms = [];
    if (referenceData && referenceData.forms && referenceData.forms.length > 0) {
        forms = referenceData.forms;
        console.log("forms");
        console.log(forms);
    }

    const onMeasurementPeriodDelete = (id) => {
        var message = "";
        message = "Are you sure you would like to delete this measurement period?";
        const proceed = window.confirm(message);
        if (proceed) {
            deleteMeasurementPeriod(id,
                (id) => {
                    //var ids = Object.keys(holder.nomination.discharge);
                    //var formid = forms.filter(function (option) { return id == option });
                    //   if (discid.length > 0) {
                    // delete forms[formid[0]];
                    //     delElement(DISCHARGE);
                    //   }
                    window.location.reload();
                }
                ,
                () => {
                    //alert(MSG_SAVE.ERROR);
                })
        }
    }

    const openModal = (id) => {
        getSubmission(id,
            (data) => { setSelectedNomination(data); document.getElementById("statementModalButton").click() },
            (error) => alert('Error happened on the server side when searching for Measurement Data. Reload the page' + error),
            () => { //stop the loading....gif
            }
        );

    }


    return (
        <div>

            <div className="row">
                <div className="col_12 column">
                    <h4><strong>Floodplain Harvesting Measurement Period</strong></h4>
                    <p>As outlined in the <a href="https://www.industry.nsw.gov.au/__data/assets/pdf_file/0006/317859/floodplain-harvesting-measurement-policy.pdf" target="blank">Floodplain Harvesting Measurement Policy (2020) </a>
                        the water user has a legal requirement to nominate the start and end date of their measurement period. This period is referred to as the Measurement Period.
                        <br /> <br />
                        <a href="https://www.industry.nsw.gov.au/__data/assets/pdf_file/0006/317859/floodplain-harvesting-measurement-policy.pdf" target="blank">Click here for more information on Nominating a Floodplain Harvesting Measurement Period.</a>
                    </p>
                </div>
            </div>

            <div class="row">
                <div className="col_3 column">
                    <button
                        style={{ height: "37px" }}
                        onClick={() => flickPage(1)}
                        type="button"
                        class="btn btn-primary"><label>New Measurement Period</label></button>
                </div>
            </div>

            <div>
                <br />
                <div className="row"><div className="col_12 column"><h4>Open Measurement Periods</h4><div></div>
                    <table class="table tableList clickable-table" cellspacing="0" cellpadding="0">
                        <tbody>
                            <tr>
                                <th>Storage Identifier</th>
                                <th>Start Measurement Date &amp; Time</th>
                                <th>End Measurement Date &amp; Time</th>
                                <th>Last Modified</th>
                                <th></th>
                            </tr>
                            {
                                forms?.filter(form => form.FormStatus === 1).map((row, i) =>
                                    <tr key={i}>
                                        <td id={DRAFT_TABLE_COL + "Name_" + i} class="clickable-column" data-href={row.url}>{row.RatingTableName}</td>
                                        <td id={DRAFT_TABLE_COL + "StartDate_" + i} class="clickable-column" data-href={row.url}>{formatDateTime(row.StartDate)}</td>
                                        <td id={DRAFT_TABLE_COL + "EndDate_" + i} class="clickable-column" data-href={row.url}>{formatDateTime(row.EndDate)}</td>
                                        <td id={DRAFT_TABLE_COL + "Modified_" + i} class="clickable-column" data-href={row.url}>{formatDateTime(row.ModifiedDate)}</td>
                                        <td id={DRAFT_TABLE_COL + "Delete_" + i}><button type="button" className="btn btn-danger me-3"
                                            onClick={() => onMeasurementPeriodDelete(row.id)} >
                                            Delete
                                        </button></td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>
                </div>

                    <h4>Previous Measurement Periods</h4>
                    <div>
                        <table class="table tableList clickable-table" cellspacing="0" cellpadding="0">
                            <tbody>
                                <tr>
                                    <th>Storage Identifier</th>
                                    <th>Start Measurement Date &amp; Time</th>
                                    <th>End Measurement Date &amp; Time</th>
                                    <th>Date Changed</th>
                                    <th>Water Take Statement</th>
                                </tr>
                                {
                                    forms?.filter(form => form.FormStatus === 2).map((row, i) =>
                                        <tr key={i}>
                                            <td>{row.RatingTableName}</td>
                                            <td>{formatDateTime(row.StartDate)}</td>
                                            <td>{formatDateTime(row.EndDate)}</td>
                                            <td>{formatDateTime(row.ModifiedDate)}</td>
                                            <td><div onClick={() => openModal(row.id)} ><a >View Statement</a></div></td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>

                    </div>

                </div>
            </div>
            <div class="row">

            </div>
            <button type="button" style={{ display: 'none' }} id="statementModalButton" className="btn btn-primary"
                data-bs-toggle="modal" data-bs-target="#statementModal">
                Launch modal
            </button>
            <div class="modal fade" id="statementModal" tabindex="-1" role="dialog" aria-labelledby="statementModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-xl" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button" id="statementModalCloseBtn" class="close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div id="printWindow" class="container">
                            <div class="row">
                                <div class="col_9"  >
                                    <img style={{ float: 'right' }} class="logo" alt="WaterNSW" src={require("../images/WaterNSWlogo.PNG")} />
                                </div>
                                <div class="col_3"  >
                                    &nbsp;&nbsp;&nbsp;
                                </div>
                            </div>
                            <div class="row">
                                <div class="col_1"  >
                                    &nbsp;&nbsp;&nbsp;
                                </div>
                                <div class="col_9"  >
                                    {(selectedNomination) &&
                                        <Statement nomination={selectedNomination} />
                                    }
                                </div>
                            </div>
                        </div>
                        {(selectedNomination) &&
                            <div class="modal-footer">
                                <button type="button" class="button btn-success btn-lg" id="gen_pdf" onClick={() => {
                                    console.log("in doPDF: " + selectedNomination.id);
                                    setPdfDownloading(true);
                                    getNominationPDF(
                                        selectedNomination.id,
                                        data => {

                                            debugger;
                                            var mime = 'application/pdf';
                                            var filename = `Meausrement_Period_Statement_${selectedNomination.id}.pdf`;
                                            var blob = new Blob([data], { type: mime || 'application/pdf' });
                                            if (typeof window.navigator.msSaveBlob !== 'undefined') { //IE7                    
                                                window.navigator.msSaveBlob(blob, filename);
                                            } else { //all others 
                                                var blobURL = (window.URL && window.URL.createObjectURL) ? window.URL.createObjectURL(blob) : window.webkitURL.createObjectURL(blob);
                                                var tempLink = document.createElement('a');
                                                tempLink.style.display = 'none';
                                                tempLink.href = blobURL;
                                                tempLink.setAttribute('download', filename);
                                                if (typeof tempLink.download === 'undefined') {
                                                    tempLink.setAttribute('target', '_blank');
                                                }

                                                document.body.appendChild(tempLink);
                                                tempLink.click();
                                                setTimeout(function () {
                                                    document.body.removeChild(tempLink);
                                                    window.URL.revokeObjectURL(blobURL);
                                                }, 200)
                                            }
                                        },
                                        error => console.log("getNominationPDF Failed" + JSON.stringify(error)),
                                        () => setPdfDownloading(false),
                                    )
                                }

                                }> Generate PDF</button>
                                {pdfDownloading &&
                                    <div class="spinner-border  text-success" role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                }
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
};
export default Intro2;