import React from 'react';
import { Field, reduxForm, formValueSelector,change} from 'redux-form';
import { connect } from 'react-redux'
import 'react-redux';
import 'redux';
import "react-datepicker/dist/react-datepicker.css";
import { saveDraftAction, clickNextAction, scrollTofocusError } from "./Validations"
import { validateCertificatePartC as validate} from './Validation';
import {dropdownDataFromReferenceData} from '../common/MeterCertificateCommon';
import { checkSerialNumber } from './Reducers'
import { RadiosForRedux, InputTextForRedux , DatePickerForRedux, SelectForRedux, CheckboxForRedux, CreatableSelectForRedux, TextAreaForRedux}  from '../../tools/BootstrapControls'

let CertificatePartC = props => {
   const {
      handleSubmit, masterDataValues, storageManagementValidationData, previousPage, hasError, loading
   } = props

   //console.log("refData "+ JSON.stringify(masterDataValues));
   //debugger;
   let certOptions = dropdownDataFromReferenceData(masterDataValues.refData,"certificateFor");
   //console.log("certOptions "+ JSON.stringify(certOptions));   

   const alloptions = ["complianceOption1","complianceOption2","complianceOption3","complianceOption4","complianceAuthority"];
   const alloptionsData = [];
   alloptions.forEach(ele => {
      alloptionsData.push(dropdownDataFromReferenceData(masterDataValues.refData,ele,"","int",));
   });


   const disableFields = (parseInt(storageManagementValidationData.formStatus) == 2) || props.disableEditing  || loading ? true : false; 
   const resetValues = () => {  
     
      if(storageManagementValidationData.certFor == 1){
         props.dispatch(change(props.form, 'storageManagementValidationData.certExistingFor', '')); 
         props.dispatch(change(props.form, 'storageManagementValidationData.lastValidationDate', null)); 
         props.dispatch(change(props.form, 'storageManagementValidationData.references', null)); 
         props.dispatch(change(props.form, 'storageManagementValidationData.reinstallationDate', null)); 
         props.dispatch(change(props.form, 'storageManagementValidationData.readingBeforeMaintenance', null)); 
         props.dispatch(change(props.form, 'storageManagementValidationData.readingBeforeMaintenanceDate', null));
         props.dispatch(change(props.form, 'storageManagementValidationData.readingAfterMaintenance', null)); 
         props.dispatch(change(props.form, 'storageManagementValidationData.readingAfterMaintenanceDate', null));  
         props.dispatch(change(props.form, 'storageManagementValidationData.lastValidationDate', null)); 
      }  
   }  

   if (storageManagementValidationData.lastValidationDate != null && storageManagementValidationData.lastValidationDate != "")
   storageManagementValidationData.lastValidationDate = new Date(storageManagementValidationData.lastValidationDate);   

   if (storageManagementValidationData.reinstallationDate != null && storageManagementValidationData.reinstallationDate != "")
   storageManagementValidationData.reinstallationDate = new Date(storageManagementValidationData.reinstallationDate); 

   if (storageManagementValidationData.readingBeforeMaintenanceDate != null && storageManagementValidationData.readingBeforeMaintenanceDate != "")
   storageManagementValidationData.readingBeforeMaintenanceDate = new Date(storageManagementValidationData.readingBeforeMaintenanceDate); 

   if (storageManagementValidationData.readingAfterMaintenanceDate != null && storageManagementValidationData.readingAfterMaintenanceDate != "")
   storageManagementValidationData.readingAfterMaintenanceDate = new Date(storageManagementValidationData.readingAfterMaintenanceDate); 



   const defaultOption =  {name: "Select...", id: ""};
   let meteringSystemTypes = [] ;
   meteringSystemTypes = meteringSystemTypes.concat(defaultOption);
   meteringSystemTypes = meteringSystemTypes.concat(masterDataValues.meteringSystemTypes);
   let lids = [];
   lids = lids.concat(defaultOption);
   lids = lids.concat(masterDataValues.lids);
   let storageMeterProtocols = [] ;
   storageMeterProtocols = storageMeterProtocols.concat(defaultOption);
   storageMeterProtocols = storageMeterProtocols.concat(masterDataValues.storageMeterProtocols);
  

   return (
      <form className="vertical" onSubmit={handleSubmit}>
         <h5 className='mb-4'>Storage metering equipment </h5>      
          
         <div class="row mb-3">   
            <div class= "col-sm-7">
               <Field
                  name="storageManagementValidationData.certFor"
                  component={SelectForRedux}
                  options={certOptions}
                  props={{ disabled: disableFields }}
                  handleChange = {resetValues}
                  label={<>This certificate is for <span className='text-danger'>*</span></>}
               />   
            </div>     
         </div>                    

         {(storageManagementValidationData.certFor == 2) && ( <>
            <div class= "row mb-3">   
               <div class= "col-sm-9">
                  <Field
                     name="storageManagementValidationData.certExistingFor"
                     component={SelectForRedux}
                     options={dropdownDataFromReferenceData(masterDataValues.refData,"certExistingFor")}
                     label={<>This validation certificate is for (please indicate which applies) <span className='text-danger'>*</span></>}
                     props={{ disabled: disableFields }}
                  />   
               </div>
            </div>
            {(storageManagementValidationData.certFor == 2 && storageManagementValidationData.certExistingFor == 1) && <>
               <div className='mb-3'>
                  <label className='form-label'>
                     I certify that the existing storage meter:
                  </label>
                  <Field
                     name="storageManagementValidationData.storageManagementRegulation.compliance1"
                     component={CheckboxForRedux}
                     options={alloptionsData[0]}                                                                                           
                     props={{ disabled: disableFields, decorateName: name => (<strong>{name} <span className='text-danger'>*</span></strong>)}}
                  />
                  <Field
                     name="storageManagementValidationData.storageManagementRegulation.compliance2"
                     component={CheckboxForRedux}
                     options={alloptionsData[1]}                             
                     label=""
                     props={{ disabled: disableFields, decorateName: name => (<strong>{name} <span className='text-danger'>*</span></strong>)}}
                  />
                  { (storageManagementValidationData.storageManagementRegulation && storageManagementValidationData.storageManagementRegulation.compliance2 && storageManagementValidationData.storageManagementRegulation.compliance2[0] == 1) &&
                     <div className='ps-4'>
                        <Field name="storageManagementValidationData.storageManagementRegulation.complianceAuthority"
                           component={RadiosForRedux} 
                           options={alloptionsData[4]} 
                           values={storageManagementValidationData.storageManagementRegulation.complianceAuthority}
                           props={{ disabled: disableFields }}
                        />
                     </div>
                  }  
                  <Field
                     name="storageManagementValidationData.storageManagementRegulation.compliance3"
                     component={CheckboxForRedux}
                     options={alloptionsData[2]}
                     props={{ disabled: disableFields, decorateName: name => (<strong>{name} <span className='text-danger'>*</span></strong>)}}
                  />
                  <Field
                     name="storageManagementValidationData.storageManagementRegulation.compliance4"
                     component={CheckboxForRedux}                              
                     options={alloptionsData[3]}                              
                     props={{ disabled: disableFields, decorateName: name => (<strong>{name} <span className='text-danger'>*</span></strong>)}}
                  />
               </div>
            </>}
            <div className='row mb-3'>
               <div className='col-sm-3'>
                  <Field
                     name="storageManagementValidationData.lastValidationDate"
                     label="Date of last validation (if applicable)"
                     handleChange={date => { props.change(props.form, 'storageManagementValidationData.lastValidationDate', date) }}
                     component={DatePickerForRedux}                        
                     props={{ disabled: disableFields, maxDate: new Date()}}
                     values={storageManagementValidationData.lastValidationDate}
                  />
               </div>
            </div>
            <div className='row mb-3'>
               <div className='col-sm-6'>
                  <Field
                     name="storageManagementValidationData.references"
                     label="Other relevant approval references from NSW Government (ie. modification to works approval)"
                     props={{ disabled: disableFields }}
                     component={InputTextForRedux}                        
                  /> 
               </div>
            </div>
            <div className='row mb-3'>
               <div className='col-sm-3'>
                  <Field
                     name="storageManagementValidationData.reinstallationDate"
                     label="Date storage metering equipment removed and reinstalled (if applicable)"
                     handleChange={date => { props.change(props.form, 'storageManagementValidationData.reinstallationDate', date) }}
                     component={DatePickerForRedux}                        
                     props={{ disabled: disableFields, maxDate: new Date()}}
                     values={storageManagementValidationData.reinstallationDate}/>
               </div>
            </div>
            <p className='mt-4 mb-3 fw-bold border-bottom text-primary border-primary'>
            Storage Meter Readings
            </p>

            <div className='row mb-3'>
               <div className='col-sm-6'>
                  <Field
                     name="storageManagementValidationData.readingBeforeMaintenance"
                     label="Before maintenance"
                     props={{ disabled: disableFields}}
                     component={InputTextForRedux}                        
                  />  
               </div>
               <div className='col-sm-3'>
                  <Field
                     name="storageManagementValidationData.readingBeforeMaintenanceDate"
                     label="Reading Date"
                     handleChange={date => { props.change(props.form, 'storageManagementValidationData.readingBeforeMaintenanceDate', date) }}
                     component={DatePickerForRedux}                        
                     props={{ disabled: disableFields, maxDate: new Date()}}
                     values={storageManagementValidationData.readingBeforeMaintenanceDate}
                  />
               </div>
            </div>
            <div className='row mb-3'>
               <div className='col-sm-6'>
                  <Field
                     name="storageManagementValidationData.readingAfterMaintenance"
                     label="After maintenance"
                     props={{ disabled: disableFields }}
                     component={InputTextForRedux}                        
                  />
               </div>
               <div className='col-sm-3'>
                  <Field
                     name="storageManagementValidationData.readingAfterMaintenanceDate"
                     label="Reading Date"
                     handleChange={date => { props.change(props.form, 'storageManagementValidationData.readingAfterMaintenanceDate', date) }}
                     component={DatePickerForRedux}                        
                     props={{ disabled: disableFields, maxDate: new Date()}}
                     values={storageManagementValidationData.readingAfterMaintenanceDate}/>
               </div>
            </div> 
         </>)}
         {(storageManagementValidationData.certFor == 1 ||  storageManagementValidationData.certFor == 2) && <div className='mb-3'>
            <Field
               name="storageManagementValidationData.storageMeterType"
               component={RadiosForRedux}
               options={dropdownDataFromReferenceData(masterDataValues.refData,"storageMeterType","")}
               label={<>Storage Meter Type <span className='text-danger'>*</span></>}
               values={storageManagementValidationData.storageMeterType}
               props={{ disabled: disableFields }}
            />    
         </div>}
                      
         {(storageManagementValidationData.certFor == 1 || storageManagementValidationData.certFor == 2) && <>
            <div className='mb-3'>
               <Field
                  name="storageManagementValidationData.mountingMethod"
                  component={RadiosForRedux}
                  options={dropdownDataFromReferenceData(masterDataValues.refData,"mountingMethod","")}
                  label={<>Storage Meter Mounting Method <span className='text-danger'>*</span></>}
                  values={storageManagementValidationData.mountingMethod}
                  props={{ disabled: disableFields}}
               />  
            </div>

            <div className='row mb-3'>
               <div className='col-sm-6'>
                  <Field
                     name="storageManagementValidationData.meteringSystemType"
                     component={SelectForRedux}
                     options={meteringSystemTypes}
                     label={<>Storage meter manufacturer &amp; model <span className='text-danger'>*</span></>}
                     props={{ disabled: disableFields }}
                  />  
               </div>
            </div>

            <div className='row mb-3'>
               <div className='col-sm-6'>
                  <Field
                     name="storageManagementValidationData.meterSerialNumber"
                     label={<>Storage meter serial number <span className='text-danger'>*</span></>}
                     props={{ disabled: disableFields}}
                     component={InputTextForRedux}   
                     onBlur={() => props.dispatch(checkSerialNumber(props.storageManagementValidationData.id, props.storageManagementValidationData.meterSerialNumber,"false"))}                     
                  />    
               </div>
            </div>

            <div className='row mb-3'>
               <div className='col-sm-6'>
                  <Field
                     name="storageManagementValidationData.meterProtocol"
                     component={SelectForRedux}
                     options={storageMeterProtocols}
                     label={<>Storage meter protocol <span className='text-danger'>*</span></>}
                     props={{ disabled: disableFields }}
                  />  
               </div>
               <div className='col-sm-6'>
                  <Field
                     name="storageManagementValidationData.refNum"
                     label={<>Storage meter test/accuracy certificate reference number/date </>}
                     props={{ disabled: disableFields }}
                     component={InputTextForRedux}                        
                  />  
               </div>
            </div>
            
            <div className='row mb-3'>
               <div className='col-sm-6'>
                  <label className='form-label'>Tamper-evident seal numbers <span className='text-danger'>* </span></label>
                  <Field
                     name='storageManagementValidationData.sealNo'
                     component={CreatableSelectForRedux}
                     options={storageManagementValidationData.sealNo}
                     props={{ disabled: disableFields }}
                     placeholder="Tamper-evident seal numbers"
                  />
               </div>
            </div>

            <p className='mt-4 mb-3 fw-bold border-bottom text-primary border-primary'>
            LID Details
            </p>

            <div className='row mb-3'>
               {/* 80827 - this is to be fixed to serial, and removed from screen 
               <div className='col-sm-6'>
                  <Field
                     name="storageManagementValidationData.lidType"
                     component={SelectForRedux}
                     options={dropdownDataFromReferenceData(masterDataValues.refData,"lidType")}
                     label={<>LID Type <span className='text-danger'>*</span></>}
                     props={{ disabled: disableFields }}
                  /> 
               </div>
               */}
               <div className='col-sm-6'>
                  <Field
                     name="storageManagementValidationData.lidModel"
                     component={SelectForRedux}
                     options={lids}
                     label={<>LID Manufacturer &amp; Model <span className='text-danger'>*</span></>}
                     props={{ disabled: disableFields }}
                  /> 
               </div>
            </div>

            <div className='row mb-3'>
               <div className='col-sm-6'>
                  <Field
                     name="storageManagementValidationData.lidSerialNumber"
                     props={{ disabled: disableFields }}
                     label={<>LID serial number <span className='text-danger'>*</span></>}
                     component={InputTextForRedux}  
                     onBlur={() => props.dispatch(checkSerialNumber(props.storageManagementValidationData.id, props.storageManagementValidationData.lidSerialNumber))}                      
                  /> 
               </div>
               <div className='col-sm-6'>
                  <Field
                     name="storageManagementValidationData.lidVersion"
                     label={<>LID firmware version <span className='text-danger'>*</span></>}
                     props={{ disabled: disableFields}}
                     component={InputTextForRedux}                        
                  /> 
               </div>
            </div>

            <p className='mt-4 mb-3 fw-bold border-bottom text-primary border-primary'>
            Benchmark 1 (Primary) - Measurement device location
            </p>

            <div className='row mb-3'>
               <div className='col-sm-6'>
                  {/* 
                  <Field
                     name="storageManagementValidationData.easting"
                     label={<>Easting <span className='text-danger'>*</span></>}
                     props={{ disabled: disableFields }}
                     component={InputTextForRedux}                        
                  /> 
                  */}
                  <Field
                     name="storageManagementValidationData.latitude"
                     label={<>Latitude <span className='text-danger'>*</span></>}
                     props={{ disabled: disableFields }}
                     component={InputTextForRedux}                        
                  /> 
               </div>
               <div className='col-sm-6'>
                  {/* 
                  <Field
                     name="storageManagementValidationData.northing"
                     label={<>Northing <span className='text-danger'>*</span></>}
                     props={{ disabled: disableFields }}
                     component={InputTextForRedux}                        
                  /> 
                  */}
                  <Field
                     name="storageManagementValidationData.longitude"
                     label={<>Longitude <span className='text-danger'>*</span></>}
                     props={{ disabled: disableFields }}
                     component={InputTextForRedux}                        
                  /> 
               </div>
            </div>

            <div className='row mb-3'>
               {/* 
               <div className='col-sm-6'>
                  <Field
                     name="storageManagementValidationData.mga"
                     label={<>MGA Zone <span className='text-danger'>*</span></>}
                     props={{ disabled: disableFields }}
                     component={InputTextForRedux}                        
                  /> 
               </div>
               */}
               <div className='col-sm-6'>
                  <Field
                     name="storageManagementValidationData.levelSensor"
                     label={<>Level of meter sensor - measuring point (m AHD) <span className='text-danger'>*</span></>}
                     props={{ disabled: disableFields}}
                     component={InputTextForRedux}                        
                  />
               </div>
            </div>

            <div className='row mb-3'>
               <div className='col-sm-6'>
                  <Field
                     name="storageManagementValidationData.waterLevel"
                     label={<>Water level displayed in the DAS (if water available) <span className='text-danger'>*</span></>}
                     props={{ disabled: disableFields }}
                     component={InputTextForRedux}                        
                  />
               </div>
            </div>


            {/** This is commented out by 80837 in DevOps
            <div className='mb-3'>
               <Field
                  name="storageManagementValidationData.powerSource"
                  component={RadiosForRedux}
                  options={dropdownDataFromReferenceData(masterDataValues.refData,"powerSource","")}  
                  values={storageManagementValidationData.powerSource}                      
                  props={{ disabled: disableFields }}
                  label={<>Power Source <span className='text-danger'>* </span></>}
               />
            </div>
            {(storageManagementValidationData.powerSource == 1 ) && <div className='mb-3 row'>
               <div className='col-sm-6'>
                  <Field
                     name="storageManagementValidationData.solarVoltage"
                     label={<>Solar panel voltage and wattage <span className='text-danger'>*</span></>}
                     props={{ disabled: disableFields }}
                     component={InputTextForRedux}                        
                  />
               </div>
            </div>}
             
            <div className='mb-3'>
               <Field
                  name="storageManagementValidationData.network"
                  component={RadiosForRedux}
                  options={dropdownDataFromReferenceData(masterDataValues.refData,"network","")}
                  values={storageManagementValidationData.network}
                  props={{ disabled: disableFields}}
                  label={<>Signal Strength <span className='text-danger'>*</span></>}
               />   
            </div>
            */}
            <div className='mb-3'>
               <Field
                  name="storageManagementValidationData.comments"
                  props={{ disabled: disableFields}}
                  label={<>Comments</>}
                  cols={40}
                  rows={10}
                  component={TextAreaForRedux}
               />   
            </div>
         </>}   

         {hasError && <div className='alert alert-danger'>
            Please fix all errors before you continue
         </div>}

         <div className="text-end">

            {(parseInt(storageManagementValidationData.formStatus) == 1)  && (!props.disableEditing) && <>
               {storageManagementValidationData.id!=0 && <>
                  <button type="button"  className="btn btn-danger me-3" disabled={loading} 
                  data-bs-toggle="modal" data-bs-target="#deleteDraftModal">
                     Delete Draft
                  </button>
               </>}

               <button type="button"  className="btn btn-primary" disabled={loading}
                  onClick={() => { saveDraftAction(props); }}>
                  Save Draft
               </button>
               <div className='vr mx-3'></div>
            </>}

            <button type="button"  className="btn btn-warning me-3" disabled={loading} onClick={()=>{previousPage(storageManagementValidationData.formStatus)}}>
               Previous
            </button>

            <button type="button" disabled={loading} className="btn btn-success"
                  onClick={() => { clickNextAction(props); }}>
                  Next
            </button>


         </div>
      </form>
   )
}

CertificatePartC = reduxForm({
   destroyOnUnmount: false,
   forceUnregisterOnUnmount: true,
   validate,
   onSubmitFail: (errors, dispatch ) => {
      dispatch(change('certificate', 'hasError', true));
   },
   onSubmitSuccess:(result , dispatch )=>{
      dispatch(change('certificate', 'hasError', false));
      window.scrollTo(0, 0);
   }
})(CertificatePartC)

CertificatePartC = connect(
   (state, props) => {     
      const selectorCert = formValueSelector('certificate')
      const hasError = selectorCert(state, 'hasError')

      const selector = formValueSelector(props.form)
      const masterDataValues = selector(state, 'mdata')
      const meterEquipInfo = selector(state, 'meteringEquipmentInformation')
      const documents = selector(state, 'documents')
      const storageManagementValidationData = selector(state, 'storageManagementValidationData')
      const loading = selector(state, 'loading')
      const form = props.form
      return {
         masterDataValues,
         meterEquipInfo,
         storageManagementValidationData,
         documents,
         form,
         hasError,
         loading
      }
   },
   
   {
      checkSerialNumber: (serialNumber) => checkSerialNumber(serialNumber)
   }
)(CertificatePartC)

export default CertificatePartC