import React from 'react';
import { Field, reduxForm, formValueSelector, change } from 'redux-form';
import { connect } from 'react-redux'
import 'react-redux';
import 'redux';
import {InputTextForRedux} from '../../tools/BootstrapControls'
import { validate } from './AuthorityHolderDetailsFormSection.Validation'
import { saveDraftAction, clickNextAction } from "./MeterFormValidations"

let AuthorityHolderDetailsFormSection = props => {

  const {
    handleSubmit, previousPage, meterEquipInfo,authHolderInfo , loading, hasError
  } = props;
  const disableFields = (parseInt(meterEquipInfo.formStatus) == 2) || props.disableFields || props.disableEditing || loading? true : false;

  return (
    <form onSubmit={handleSubmit}>
      <h5 className='mb-3'>Work Approval Holder Details</h5>
      {(authHolderInfo.firstName != null && authHolderInfo.lastName != null && authHolderInfo.firstName != '' && authHolderInfo.lastName != '') &&
        <div className='row mb-3'>
          <div className='col-sm-6'>
            <Field
              name="authorityHolderDetails.authorityHolderName"
              label={<>Work approval holder name/s <span className='text-danger'>*</span></>}
              component={InputTextForRedux}
              props={{ disabled: disableFields }}
            />
          </div>
        </div>
      }
      <div className='row mb-3'>
        <div className='col-sm-6'>
          <Field
            name="authorityHolderDetails.corporationNameOrAcn"
            label="Corporation name and ACN"
            component={InputTextForRedux}
            props={{ disabled: disableFields }}
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-sm-6'>
          <Field
            name="authorityHolderDetails.postalAddress"
            label={<>Postal address <span className='text-danger'>*</span></>}
            component={InputTextForRedux}
            props={{ disabled: disableFields}}
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-sm-6'>
          <Field
            name="authorityHolderDetails.phone"
            label={<>Phone <span className='text-danger'>*</span></>}
            component={InputTextForRedux}
            props={{ disabled: disableFields}}
          />
        </div>
      </div>

      <div className='row mb-3'>
        <div className='col-sm-6'>
          <Field
            name="authorityHolderDetails.alternatePhone"
            label="Alternate phone"
            component={InputTextForRedux}
            props={{ disabled: disableFields }}
          />
        </div>
      </div>

      <div className='row mb-3'>
        <div className='col-sm-6'>
          <Field
            name="authorityHolderDetails.emailAddress"
            label={<>Customers current email address <span className='text-danger'>*</span></>}
            component={InputTextForRedux}
            props={{ disabled: disableFields }}
          />
        </div>
      </div>

      <div className='row mb-3'>
        <div className='col-sm-6'>
          <Field
            name="authorityHolderDetails.mobile"
            label="Mobile"
            component={InputTextForRedux}
            props={{ disabled: disableFields }}
          />
        </div>
      </div>

      <h5 className='mb-1'>
        Contact Person or Agent<br/>
      </h5>
      <p>
        <small>If the authority holder is not the main contact for this certificate, provide a contact person or agent’s name and details below</small>
      </p>
      <div className='row mb-3'>
        <div className='col-sm-6'>
          <Field
            name="authorityHolderDetails.contactPersonName"
            label="Name"
            component={InputTextForRedux}
            props={{ disabled: disableFields }}
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-sm-6'>
          <Field
            name="authorityHolderDetails.contactPersonPhone"
            label="Phone"
            component={InputTextForRedux}
            props={{ disabled: disableFields }}
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-sm-6'>
          <Field
            name="authorityHolderDetails.contactPersonEmailAddress"
            label="Email address"
            component={InputTextForRedux}
            props={{ disabled: disableFields }}
          />
        </div>
      </div>

      {hasError && <div className='alert alert-danger'>
         Please fix all errors before you continue
      </div>}

      <div className="text-end">

        {(parseInt(meterEquipInfo.formStatus) == 1) && (!props.disableEditing) && <>
          {meterEquipInfo.id!=0 &&
            <button 
              type="button"  
              className="btn btn-danger me-3" 
              disabled={loading} 
              data-bs-toggle="modal" 
              data-bs-target="#deleteDraftModal"
            >
              Delete Draft
            </button>
          }
          <button type="button"  
            className="btn btn-primary" 
            disabled={loading}
            onClick={() => { saveDraftAction(props); }}
          >
            Save Draft
          </button>
          <div className='vr mx-3'></div>
        </>}

        {(meterEquipInfo.meterFormType == 1 || meterEquipInfo.meterFormType == 9) &&
          <button type="button" disabled={loading} className="btn btn-warning me-3" onClick={()=>{previousPage(meterEquipInfo.formStatus)}}>
            Previous
         </button>
        }
        <button type="button"  className="btn btn-success"
        disabled={loading}
          onClick={() => { clickNextAction(props); }}>
          Next
        </button>
      </div>
    </form>
  )
}

AuthorityHolderDetailsFormSection = reduxForm({
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate,
  onSubmitFail: (errors, dispatch ) => {
    dispatch(change('certificate', 'hasError', true));
  },
  onSubmitSuccess:(result , dispatch )=>{
      dispatch(change('certificate', 'hasError', false));
      window.scrollTo(0, 0);
  }
})(AuthorityHolderDetailsFormSection)

AuthorityHolderDetailsFormSection = connect(
  (state, props) => {
    const selectorCert = formValueSelector('certificate')
    const hasError = selectorCert(state, 'hasError')

    const selector = formValueSelector(props.form);
    const loading = selector(state, 'loading');
    const masterDataValues = selector(state, 'mdata');
    const authHolderInfo = selector(state, 'authorityHolderDetails');
    const meterEquipInfo = selector(state, 'meteringEquipmentInformation');
    return {
      masterDataValues,
      authHolderInfo,
      meterEquipInfo,
      form: props.form,
      loading, 
      hasError
    }
  }
)(AuthorityHolderDetailsFormSection)

export default AuthorityHolderDetailsFormSection;
