import React from 'react';
import { reduxForm, formValueSelector, change } from 'redux-form';
import { connect } from 'react-redux'
import {  METER_FORM_TYPES, METER_VALIDATION_TYPE } from '../../../constants/MeterFormConstants'
import 'react-redux';
import 'redux';
import {  saveDraftAction } from "../common/MeterFormValidations"
import moment from 'moment';


let ConfirmationFormSection = props => {
    const {
        masterDataValues,authHolderInfo,appWorkInfo,waterAccessLicenceNoExternalList,meterEquipInfo,
        cancelConfrimSubmissionAndGoToPage,cancelConfrimSubmission,goToFinalPageFromReviewPage
        ,handleSubmit, loading, hasError
    } = props;

  return (
    <form className="vertical" onSubmit={handleSubmit}>
        <h5 className='mb-3'>Review</h5>
        <div class="alert alert-primary" role="alert">
            Please review the information below before completing your submission.
            <br/>
            The form cannot be edited once submitted. Therefore, please confirm all configuration details are correct before submitting this form.
        </div>

        <div className='card mb-3'>
            <div className='card-header'><strong>Authority Holder Details</strong></div>
            <div className='card-body'>
                <table class="table fs-90 mb-3">
                    <tbody>
                        {(authHolderInfo.firstName != null && authHolderInfo.lastName != null && authHolderInfo.firstName != '' && authHolderInfo.lastName != '') &&
                        <tr>
                            <th className='w-25'>
                            Authority holder name(s)
                            </th>
                            <td>{authHolderInfo.authorityHolderName}</td>
                        </tr>
                        }
                        <tr>
                            <th className='w-25'>
                            Corporation name and ACN
                            </th>
                            <td>{authHolderInfo.corporationNameOrAcn}</td>
                        </tr>
                        <tr>
                            <th>
                            Postal address
                            </th>
                            <td>{authHolderInfo.postalAddress}</td>
                        </tr>
                        <tr>
                            <th>
                            Phone
                            </th>
                            <td>{authHolderInfo.phone}</td>
                        </tr>
                        <tr>
                            <th>
                            Alternate phone
                            </th>
                            <td>{authHolderInfo.alternatePhone}</td>
                        </tr>
                        <tr>
                            <th>
                            Email address
                            </th>
                            <td>{authHolderInfo.emailAddress}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className='card-header'><strong>Contact Person or Agent</strong></div>
            <div className='card-body'>
                <table class="table fs-90 mb-3">
                    <tbody>
                        <tr>
                            <th className='w-25'>
                            Name
                            </th>
                            <td>{authHolderInfo.contactPersonName}</td>
                        </tr>
                        <tr>
                            <th>
                            Phone
                            </th>
                            <td>{authHolderInfo.contactPersonPhone}</td>
                        </tr>
                        <tr>
                            <th>
                            Email address
                            </th>
                            <td>{authHolderInfo.contactPersonEmailAddress}</td>
                        </tr>
                    </tbody>
                </table>
                
            </div>
            <div className='card-footer'>
                <button type="button" className="btn btn-primary btn-sm" 
                    onClick={() => { cancelConfrimSubmissionAndGoToPage(1); }}>
                        Edit
                </button>
            </div>
        </div>

        <div className='card mb-3'>
            <div className='card-header'><strong>Details of Approved Works</strong></div>
            <div className='card-body'>
                <table class="table fs-90 mb-3">
                    <tbody>
                        <tr>
                            <th className='w-25'>
                            Property name
                            </th>
                            <td>{appWorkInfo.propertyName}</td>
                        </tr>
                        <tr>
                            <th>
                            Lot DP
                            </th>
                            <td>{appWorkInfo.lotDp}</td>
                        </tr>
                        <tr>
                            <th>
                            Work approval number
                            </th>
                            <td>{appWorkInfo.waterSupplyWorkApprovalNo}</td>
                        </tr>
                        {
                            (appWorkInfo.approvedByCustomer == 0) || (appWorkInfo.approvedByCustomer == 1 && waterAccessLicenceNoExternalList.length == 0) &&
                            <tr>
                                <th>
                                Water access license number(s)
                                </th>
                                <td>{appWorkInfo.waterAccessLicenceNo}</td>
                            </tr>
                        }
                        {
                            appWorkInfo.approvedByCustomer == 1 && waterAccessLicenceNoExternalList.length > 0 &&
                            <tr>
                                <th>
                                Water access license number(s)
                                </th>
                                <td>{appWorkInfo.waterAccessLicenceNo}</td>
                            </tr>
                        }
                        <tr>
                            <th>
                            Unique extraction site identifier (ESID)
                            </th>
                            <td>{appWorkInfo.esid}</td>
                        </tr>
                        {
                            appWorkInfo.approvedByCustomer == 1 && 
                            (
                                (
                                    meterEquipInfo.newMeterEquipment == METER_VALIDATION_TYPE.EXISTING &&
                                    meterEquipInfo.meterFormType == METER_FORM_TYPES.METER_VALIDATION
                                ) 
                                ||
                                meterEquipInfo.meterFormType == METER_FORM_TYPES.METER_ACCURACY) 
                                &&
                                <tr>
                                    <th>
                                    Selected meter
                                    </th>
                                    <td>{appWorkInfo.selectedMeter}</td>
                                </tr>
                        }
                        {((
                            meterEquipInfo.meterFormType == METER_FORM_TYPES.METER_VALIDATION) ||
                            meterEquipInfo.meterFormType == METER_FORM_TYPES.METER_ACCURACY) &&
                            <tr>
                                <th>
                                Meter number
                                </th>
                                <td>{appWorkInfo.meterNumber}</td>
                            </tr>
                        }
                        <tr>
                            <th>
                            Name of water source
                            </th>
                            <td>{appWorkInfo.waterSourceName}</td>
                        </tr>
                        <tr>
                            <th>
                            Name of water sharing plan
                            </th>
                            <td>{appWorkInfo.waterSharingPlanName}</td>
                        </tr>
                        <tr>
                            <th>
                            Type of water source
                            </th>
                            <td>
                                {masterDataValues.waterSourceTypes &&  masterDataValues.waterSourceTypes.map((ws) => {
                                    if(ws.id == appWorkInfo.waterSourceTypeId) {
                                        return ws.name;
                                    }
                                })}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className='card-footer'>
                <button type="button" className="btn btn-primary btn-sm" 
                    onClick={() => { cancelConfrimSubmissionAndGoToPage(2); }}>
                        Edit
                </button>
            </div>
        </div>

        <div className='card mb-3'>
            <div className='card-header'><strong>Metering Equipment Information</strong></div>
            <div className='card-body'>
                <table class="table fs-90 mb-3">
                    <tbody>
                        <tr>
                            <th className='w-25'>
                            Last date of validation
                            </th>
                            <td>
                                {meterEquipInfo.dateOfLastValidation==""?"":moment((new Date(meterEquipInfo.dateOfLastValidation))).format('DD/MM/YYYY')}
                            </td>
                        </tr>
                        <tr>
                            <th>
                            Meter system type
                            </th>
                            <td>
                                {masterDataValues.meteringSystemTypes &&  masterDataValues.meteringSystemTypes.map((st) => {
                                    if(st.id == meterEquipInfo.meteringSystemTypeId) {
                                        return st.name;
                                    }
                                })}
                            </td>
                        </tr>
                        {(parseInt(meterEquipInfo.meteringSystemTypeId) != 0) && <>
                            <tr>
                                <th>
                                Meter manufacturer &amp; model
                                </th>
                                <td>
                                    {masterDataValues.meterModelTypes &&  masterDataValues.meterModelTypes.map((st) => {
                                        if(st.id == meterEquipInfo.meterTypeModelId) {
                                            return st.name;
                                        }
                                    })}
                                </td>
                            </tr>
                            {
                                (parseInt(masterDataValues.MeterModelTypeOtherId) === parseInt(meterEquipInfo.meterTypeModelId)) &&
                                <tr>
                                    <th>
                                    Other meter manufacturer &amp; model
                                    </th>
                                    <td>{meterEquipInfo.meterTypeModel}</td>
                                </tr>
                            }
                            <tr>
                                <th>
                                Meter serial number
                                </th>
                                <td>{meterEquipInfo.meterSerialNo}</td>
                            </tr>
                            <tr>
                                <th>
                                Meter size (mm)
                                </th>
                                <td>{meterEquipInfo.meterSize}</td>
                            </tr>
                        </>}
                        {meterEquipInfo.meteringSystemTypeId != 2 && <>
                            <tr>
                                <th>
                                Pipe type
                                </th>
                                <td>{meterEquipInfo.pipeType}</td>
                            </tr>
                            <tr>
                                <th>
                                Pipe size (mm)
                                </th>
                                <td>{meterEquipInfo.pipeSize}</td>
                            </tr>
                            <tr>
                                <th>
                                Pipe connection type
                                </th>
                                <td>{meterEquipInfo.pipeConnectionType}</td>
                            </tr>
                            <tr>
                                <th>
                                Pipe length upstream of meter (mm)
                                </th>
                                <td>{meterEquipInfo.pipeLengthUpstream}</td>
                            </tr>
                            <tr>
                                <th>
                                Pipe length downstream of meter (mm)
                                </th>
                                <td>{meterEquipInfo.pipeLengthDownstream}</td>
                            </tr>
                        </>}
                        <tr>
                            <th>
                            Meter location longitude
                            </th>
                            <td>{meterEquipInfo.longitude}</td>
                        </tr>
                        <tr>
                            <th>
                            Meter location latitude
                            </th>
                            <td>{meterEquipInfo.latitude}</td>
                        </tr>
                        <tr>
                            <th>
                            Meter Description
                            </th>
                            <td>{meterEquipInfo.meterDescription}</td>
                        </tr>
                        <tr>
                            <th>
                            Type of supply
                            </th>
                            <td>
                                {masterDataValues.supplyTypes &&  masterDataValues.supplyTypes.map((st) => {
                                    if(st.id == meterEquipInfo.supplyTypeId) {
                                        return st.name;
                                    }
                                })}
                            </td>
                        </tr>
                        <tr>
                            <th>
                            Tamper-evident seal numbers
                            </th>
                            <td>
                                {meterEquipInfo.sealNo &&  meterEquipInfo.sealNo.map((sn) => {
                                    return sn.label + "; ";
                                })}
                            </td>
                        </tr>
                        <tr>
                            <th>
                            Data collector type
                            </th>
                            <td>
                                {masterDataValues.dataCollectorTypes &&  masterDataValues.dataCollectorTypes.map((dt) => {
                                    if(dt.id == meterEquipInfo.dataCollectorTypeId) {
                                        return dt.name;
                                    }
                                })}
                            </td>
                        </tr>
                        
                        {(meterEquipInfo.dataCollectorTypeId !=4 && meterEquipInfo.dataCollectorTypeId !='4') && <>
                            <tr>
                                <th>
                                Model number
                                </th>
                                <td>{meterEquipInfo.dataCollectorModelNo}</td>
                            </tr>
                            <tr>
                                <th>
                                Serial number
                                </th>
                                <td>{meterEquipInfo.dataCollectorSerialNo}</td>
                            </tr>
                            <tr>
                                <th>
                                Power source
                                </th>
                                <td>
                                    {masterDataValues.powerSources &&  masterDataValues.powerSources.map((ps) => {
                                        if(ps.id == meterEquipInfo.dataCollectorPowerSourceId) {
                                            if(ps.name == 'Select...') return "";
                                            return ps.name;
                                        }
                                    })}
                                </td>
                            </tr>
                            {(meterEquipInfo.dataCollectorPowerSourceId == 1) && <tr>
                                <th>
                                Solar panel wattage
                                </th>
                                <td>{meterEquipInfo.dataCollectorSolarPanelWattage}</td>
                            </tr>}
                        </>}
                        {(parseInt(masterDataValues.OpenChannelId) == parseInt(meterEquipInfo.meteringSystemTypeId)) && <>
                            <tr>
                                <th>
                                Channel type
                                </th>
                                <td>{meterEquipInfo.channelType}</td>
                            </tr>
                            <tr>
                                <th>
                                Channel width (m)
                                </th>
                                <td>{meterEquipInfo.channelWidth}</td>
                            </tr>
                            <tr>
                                <th>
                                Distance of the meter from pumping source or filling point (m)
                                </th>
                                <td>{meterEquipInfo.meterDistanceFromSource}</td>
                            </tr>
                            <tr>
                                <th>
                                Channel length upstream of meter (m)
                                </th>
                                <td>{meterEquipInfo.channelLengthUpstream}</td>
                            </tr>
                            <tr>
                                <th>
                                Channel length downstream of meter (m)
                                </th>
                                <td>{meterEquipInfo.channelLlengthDownstream}</td>
                            </tr>
                        </>}
                    </tbody>
                </table>
            </div>

            {(parseInt(masterDataValues.OpenChannelId) == parseInt(meterEquipInfo.meteringSystemTypeId)) && <>
                <div className='card-header'><strong>Calibration Measurement Details</strong></div>
                <div className='card-body'>
                    <table class="table fs-90 mb-3">
                        <tbody>
                            <tr>
                                <th className='w-25'>
                                Maximum measured discharge (m³/s)
                                </th>
                                <td>
                                    {meterEquipInfo.maxMeasuredDischarge}
                                </td>
                            </tr>
                            <tr>
                                <th>
                                Minimum measured discharge (m³/s)
                                </th>
                                <td>
                                    {meterEquipInfo.minMeasuredDischarge}
                                </td>
                            </tr>
                            <tr>
                                <th>
                                Maximum measured velocity (m/s)
                                </th>
                                <td>
                                    {meterEquipInfo.maxMeasuredVelocity}
                                </td>
                            </tr>
                            <tr>
                                <th>
                                Minimum measured velocity (m/s)
                                </th>
                                <td>
                                    {meterEquipInfo.minMeasuredVelocity}
                                </td>
                            </tr>
                            <tr>
                                <th>
                                Maximum gauge height (m)
                                </th>
                                <td>
                                    {meterEquipInfo.maxGaugeHeight}
                                </td>
                            </tr>
                            <tr>
                                <th>
                                Minimum gauge height (m)
                                </th>
                                <td>
                                    {meterEquipInfo.minGaugeHeight}
                                </td>
                            </tr>
                            <tr>
                                <th>
                                Maximum gauge height (m)
                                </th>
                                <td>
                                    {meterEquipInfo.maxError}
                                </td>
                            </tr>
                            <tr>
                                <th>
                                Minimum gauge height (m)
                                </th>
                                <td>
                                    {meterEquipInfo.minError}
                                </td>
                            </tr>
                            <tr>
                                <th>
                                Number of measurements undertaken for index equation (if applicable)
                                </th>
                                <td>
                                    {meterEquipInfo.noOfMeasurementsTaken}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    
                </div>
                <div className='card-footer'>
                    <button type="button" className="btn btn-primary btn-sm" 
                        onClick={() => { cancelConfrimSubmissionAndGoToPage(3); }}>
                            Edit
                    </button>
                </div>
            </>}
        </div>

        <div className='card mb-4'>
            <div className='card-header'>
                <strong>Method used to check accuracy of the metering equipment:</strong>
            </div>
            <div className='card-body'>
                {meterEquipInfo.accuracyMethod}
            </div>
            {(parseInt(props.masterDataValues.OpenChannelId) == parseInt(props.meterEquipInfo.meteringSystemTypeId)) && <>
                <div className='card-header'>
                <strong>Conclusion - for open channel meters only</strong>
                </div>
                <div className='card-body'>
                {meterEquipInfo.accuracyConclusionOpenChannel}
                </div>
            </>}
            <div className='card-footer'>
                <button type="button" className="btn btn-primary btn-sm" 
                    onClick={() => { cancelConfrimSubmissionAndGoToPage(4); }}>
                        Edit
                </button>
            </div>
        </div>

        {hasError && <div className='alert alert-danger'>
            Please fix all errors before you continue
        </div>}

        <div class="text-end">
            {(parseInt(meterEquipInfo.formStatus) == 1)  && (!props.disableEditing) && <>
                {meterEquipInfo.id!=0 &&
                    <button type="button"  
                        className="btn btn-danger me-3"  disabled={loading}
                        data-bs-toggle="modal" data-bs-target="#deleteDraftModal"
                    >
                        Delete Draft
                    </button>
                }
                <button type="button"  
                    className="btn btn-primary" disabled={loading}
                    onClick={() => { 
                        saveDraftAction(props); 
                    }}
                >
                    Save Draft
                </button>

                <div className='vr mx-3'/>
            </>}

            <button type="button"  
                className="btn btn-warning me-3" disabled={loading}
               onClick={cancelConfrimSubmission}>
                  Previous
            </button>

            <button type="button"  
                className="btn btn-success"  disabled={loading}
                onClick={goToFinalPageFromReviewPage}>
                  {(parseInt(meterEquipInfo.formStatus) == 1) ? "Confirm" : "Next"}
            </button>

            
        </div>

    </form>
  )
}


ConfirmationFormSection = reduxForm({
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate : ()=>{return {}},
    onSubmitFail: (errors, dispatch ) => {
        dispatch(change('certificate', 'hasError', true));
    },
    onSubmitSuccess:(result , dispatch )=>{
        dispatch(change('certificate', 'hasError', false));
        window.scrollTo(0, 0);
    }
})(ConfirmationFormSection)

ConfirmationFormSection = connect(
    (state, props) => {
        const selectorCert = formValueSelector('certificate')
        const hasError = selectorCert(state, 'hasError')

       // can select values individually
       const selector = formValueSelector(props.form)
       const masterDataValues = selector(state, 'mdata');
       const authHolderInfo = selector(state, 'authorityHolderDetails');
       const appWorkInfo = selector(state, 'approvedWorkDetails');
       const meterEquipInfo = selector(state, 'meteringEquipmentInformation');
       const waterAccessLicenceNoExternalList = selector(state, 'waterAccessLicenceNoExternalList');
       const documents = selector(state, 'documents');
       const loading = selector(state, 'loading');
       const form = props.form
       return {
          masterDataValues,
          form,
          authHolderInfo,
          appWorkInfo,
          meterEquipInfo,
          waterAccessLicenceNoExternalList,
          documents,
          loading,
          hasError
       }
    }
 )(ConfirmationFormSection)
export default ConfirmationFormSection;
