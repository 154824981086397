import React from 'react';
import { Field, FieldArray, reduxForm, formValueSelector, change, initialize } from 'redux-form';
import { connect } from 'react-redux'
import { checkSerialNumber } from './AHDValidationCertificate.Reducers';
import 'react-redux';
import 'redux';
import "react-datepicker/dist/react-datepicker.css";

import { certOptions,benchmark_options,meter_options,gps_options } from '../../../constants/MeterFormConstants'
import { saveDraftAction, clickNextAction, scrollTofocusError } from "./AHDFormValidations"
import { validateCertificatePartC as validate} from './AHDValidationCertificate.Validation';
import { InputTextForRedux , DatePickerForRedux, SelectForRedux, CheckboxForRedux, TextAreaForRedux}  from '../../tools/BootstrapControls'

let AHDValidationCertificatePartC = props => {

   const {
      handleSubmit,  meterEquipInfo,ahdData, previousPage, loading, hasError
   } = props


   const disableFields = (parseInt(ahdData.formStatus) == 2) || props.disableEditing || loading ? true : false;   
      


   if (ahdData.validationDate != null && ahdData.validationDate != "")
   ahdData.validationDate = new Date(ahdData.validationDate);


   if (ahdData.lastValidationDate != null && ahdData.lastValidationDate != "")
   ahdData.lastValidationDate = new Date(ahdData.lastValidationDate);

   if (ahdData.damagedDate != null && ahdData.damagedDate != "")
   ahdData.damagedDate = new Date(ahdData.damagedDate);   

   return (
      <form className="vertical" onSubmit={handleSubmit}>
         <h5 className='mb-3'>Survey Benchmarks installation</h5>    

         <div className='row mb-3'>
            <div className='col-sm-6'>
               <Field
                  name="ahdData.benchType"
                  component={SelectForRedux}
                  options={certOptions}
                  label={<>This validation certificate is for (please indicate which applies) <span className='text-danger'>*</span></>}
                  props={{ disabled: disableFields }}
               /> 
            </div>
            <div className='col-sm-3'>
               <Field
                  name="ahdData.validationDate"
                  handleChange={date => { props.change(props.form, 'ahdData.validationDate', date) }}
                  component={DatePickerForRedux}  
                  label={<>Date of validation <span className='text-danger'>*</span></>}                      
                  props={{ disabled: disableFields, maxDate: new Date()}}
                  values={ahdData.validationDate}
               /> 
            </div>
         </div>
         <div className='row mb-3'>
            <div className='col-sm-3'>
               <Field
                  name="ahdData.lastValidationDate"
                  label="Date of last validation"
                  handleChange={date => { props.change(props.form, 'ahdData.lastValidationDate', date) }}
                  component={DatePickerForRedux}
                  props={{ disabled: disableFields, maxDate: new Date()}}
                  values={ahdData.lastValidationDate}
               />   
            </div>
            <div className='col-sm-3'></div>
            <div className='col-sm-3'>
               <Field
                  name="ahdData.damagedDate"
                  label="Date of damage to benchmarks or meter"
                  handleChange={date => { props.change(props.form, 'ahdData.damagedDate', date) }}
                  component={DatePickerForRedux}
                  props={{ disabled: disableFields, maxDate: new Date()}}
                  values={ahdData.damagedDate}
               /> 
            </div>
         </div>

         <p className='mt-4 mb-3 border-primary border-bottom text-primary'>
         Benchmark 1
         </p>

         <div className='row mb-3'>
            <div className='col-sm-6'>
               <Field
                  name="ahdData.bench1"
                  component={SelectForRedux}
                  options={benchmark_options}
                  label={<>Benchmark Type <span className='text-danger'>*</span></>}
                  props={{ disabled: disableFields }}
               />  
            </div>
         </div>

         <div className='row mb-3'>
            <div className='col-sm-6'>
               {/* 
               <Field
                  name="ahdData.bench1East"
                  label={<>Easting <span className='text-danger'>*</span></>}
                  props={{ disabled: disableFields }}
                  component={InputTextForRedux}                        
               />  
               */}  
                <Field
                  name="ahdData.bench1Lat"
                  label={<>Latitude <span className='text-danger'>*</span></>}
                  props={{ disabled: disableFields }}
                  component={InputTextForRedux}                        
               />   
            </div>
            <div className='col-sm-6'>
               {/* 
               <Field
                  name="ahdData.bench1North"
                  label={<>Northing <span className='text-danger'>*</span></>}
                  props={{ disabled: disableFields }}
                  component={InputTextForRedux}                        
               />
               */}

               <Field
                  name="ahdData.bench1Long"
                  label={<>Longitude <span className='text-danger'>*</span></>}
                  props={{ disabled: disableFields }}
                  component={InputTextForRedux}                        
               />
            </div>
         </div>
         <div className='row mb-3'>
            {/* 
            <div className='col-sm-6'>
               <Field
                  name="ahdData.bench1MGA"
                  label={<>MGA Zone <span className='text-danger'>*</span></>}
                  props={{ disabled: disableFields}}
                  component={InputTextForRedux}                        
               />   
            </div>
            */ }
            <div className='col-sm-6'>
               <Field
                  name="ahdData.bench1Level"
                  label={<>Level(m AHD) <span className='text-danger'>*</span></>}
                  props={{ disabled: disableFields }}
                  component={InputTextForRedux}                        
               />
            </div>
         </div>

         <p className='mt-4 mb-3 border-primary border-bottom text-primary'>
         Benchmark 2
         </p>

         <div className='row mb-3'>
            <div className='col-sm-6'>
               <Field
                  name="ahdData.bench2"
                  component={SelectForRedux}
                  options={benchmark_options}
                  label={<>Benchmark Type <span className='text-danger'>*</span></>}
                  props={{ disabled: disableFields}}
               />  
            </div>
         </div>

         <div className='row mb-3'>
            <div className='col-sm-6'>
               {/* 
               <Field   
                  name="ahdData.bench2East"
                  label={<>Easting <span className='text-danger'>*</span></>}
                  props={{ disabled: disableFields }}
                  component={InputTextForRedux}                        
               /> 
               */}

               <Field
                  name="ahdData.bench2Lat"
                  label={<>Latitude <span className='text-danger'>*</span></>}
                  props={{ disabled: disableFields }}
                  component={InputTextForRedux}                        
               />  
            </div>
            <div className='col-sm-6'>
               {/* 
               <Field
                  name="ahdData.bench2North"
                  label={<>Northing <span className='text-danger'>*</span></>}
                  props={{ disabled: disableFields }}
                  component={InputTextForRedux}                        
               />
               */}
               <Field
                  name="ahdData.bench2Long"
                  label={<>Longitude <span className='text-danger'>*</span></>}
                  props={{ disabled: disableFields }}
                  component={InputTextForRedux}                        
               />
            </div>
         </div>

         <div className='row mb-3'>
            {/* 
            <div className='col-sm-6'>
               <Field
                  name="ahdData.bench2MGA"
                  label={<>MGA Zone <span className='text-danger'>*</span></>}
                  props={{ disabled: disableFields }}
                  component={InputTextForRedux}                        
               />
            </div>
            */}
            <div className='col-sm-6'>
               <Field
                  name="ahdData.bench2Level"
                  label={<>Level(m AHD) <span className='text-danger'>*</span></>}
                  props={{ disabled: disableFields }}
                  component={InputTextForRedux}                        
               />
            </div>
         </div>

         <p className='mt-4 mb-3 border-primary border-bottom text-primary'>
         Benchmark 3
         </p>
                   

         <div className='row mb-3'>
            <div className='col-sm-6'>
               <Field
                  name="ahdData.bench3"
                  component={SelectForRedux}
                  options={benchmark_options}
                  label={<>Benchmark Type <span className='text-danger'>*</span></>}
                  props={{ disabled: disableFields}}
               />  
            </div>
         </div>

         <div className='row mb-3'>
            <div className='col-sm-6'>
               {/* 
               <Field
                  name="ahdData.bench3East"
                  props={{ disabled: disableFields }}
                  label={<>Easting <span className='text-danger'>*</span></>}
                  component={InputTextForRedux}                        
               /> 
               */}
               <Field
                  name="ahdData.bench3Lat"
                  label={<>Latitude <span className='text-danger'>*</span></>}
                  props={{ disabled: disableFields }}
                  component={InputTextForRedux}                        
               />  
            </div>
            <div className='col-sm-6'>
               {/* 
               <Field
                  name="ahdData.bench3North"
                  label={<>Northing <span className='text-danger'>*</span></>}
                  props={{ disabled: disableFields }}
                  component={InputTextForRedux}                        
               /> 
               */}

               <Field
                  name="ahdData.bench3Long"
                  label={<>Longitude <span className='text-danger'>*</span></>}
                  props={{ disabled: disableFields }}
                  component={InputTextForRedux}                        
               />
            </div>
         </div>

         <div className='row mb-3'>
            {/* 
            <div className='col-sm-6'>
               <Field
                  name="ahdData.bench3MGA"
                  label={<>MGA Zone <span className='text-danger'>*</span></>}
                  props={{ disabled: disableFields }}
                  component={InputTextForRedux}                        
               /> 
            </div>
            */}
            <div className='col-sm-6'>
               <Field
                  name="ahdData.bench3Level"
                  label={<>Level(m AHD) <span className='text-danger'>*</span></>}
                  props={{ disabled: disableFields }}
                  component={InputTextForRedux}                        
               /> 
            </div>
         </div>

         <p className='mt-4 mb-3 border-primary border-bottom text-primary'>
         Storage Meter
         </p>

         <div className='row mb-3'>
            <div className='col-sm-6'>
               {/* 
               <Field
                  name="ahdData.storageMeterEast"
                  label={<>Easting</>}
                  props={{ disabled: disableFields}}
                  component={InputTextForRedux}                        
               />
               */}

               <Field
                  name="ahdData.storageMeterLat"
                  label={<>Latitude</>}
                  props={{ disabled: disableFields }}
                  component={InputTextForRedux}                        
               />  
            </div>
            <div className='col-sm-6'>
               {/* 
               <Field
                  name="ahdData.storageMeterNorth"
                  label={<>Northing</>}
                  props={{ disabled: disableFields }}
                  component={InputTextForRedux}                        
               />
               */}
               <Field
                  name="ahdData.storageMeterLong"
                  label={<>Longitude</>}
                  props={{ disabled: disableFields }}
                  component={InputTextForRedux}                        
               />
            </div>
         </div>

         <div className='row mb-3'>
            {/* 
            <div className='col-sm-6'>
               <Field
                  name="ahdData.storageMeterMGA"
                  label={<>MGA Zone</>}
                  props={{ disabled: disableFields, mandatory: true }}
                  component={InputTextForRedux}                        
               /> 
            </div>
            */}
            <div className='col-sm-6'>
               <Field
                  name="ahdData.storageMeterLevel"
                  label={<>Level (m AHD)</>}
                  props={{ disabled: disableFields, mandatory: true }}
                  component={InputTextForRedux}                        
               />
            </div>
         </div>

         <p className='mt-4 mb-3 border-primary border-bottom text-primary'>
         Storage Centroid
         </p>

         <div className='row mb-3'>
            <div className='col-sm-6'>
               {/* 
               <Field
                  name="ahdData.storageCentroidEast"
                  label={<>Easting</>}
                  props={{ disabled: disableFields }}
                  component={InputTextForRedux}                        
               />  
               */}

               <Field
                  name="ahdData.storageCentroidLat"
                  label={<>Latitude</>}
                  props={{ disabled: disableFields }}
                  component={InputTextForRedux}                        
               />  
            </div>
            <div className='col-sm-6'>
               {/* 
               <Field
                  name="ahdData.storageCentroidNorth"
                  label={<>Northing</>}
                  props={{ disabled: disableFields}}
                  component={InputTextForRedux}                        
               /> 
               */}

               <Field
                  name="ahdData.storageCentroidLong"
                  label={<>Longitude</>}
                  props={{ disabled: disableFields }}
                  component={InputTextForRedux}                        
               />
            </div>
         </div>

         <div className='row mb-3'>
            {/* 
            <div className='col-sm-6'>
               <Field
                  name="ahdData.storageCentroidMGA"
                  label={<>MGA Zone</>}
                  props={{ disabled: disableFields}}
                  component={InputTextForRedux}                        
               /> 
            </div>
            */}
         </div>

         <p className='mt-4 mb-3 border-primary border-bottom text-primary'>
         Top of Storage Wall
         </p>

         <div className='row mb-3'>
            <div className='col-sm-6'>
               {/* 
               <Field
                  name="ahdData.topStorageWallEast"
                  label={<>Easting <span className='text-danger'>*</span></>}
                  props={{ disabled: disableFields }}
                  component={InputTextForRedux}                        
               />
               */}

               <Field
                  name="ahdData.topStorageWallLat"
                  label={<>Latitude <span className='text-danger'>*</span></>}
                  props={{ disabled: disableFields }}
                  component={InputTextForRedux}                        
               />  
            </div>
            <div className='col-sm-6'>
               {/* 
               <Field
                  name="ahdData.topStorageWallNorth"
                  label={<>Northing <span className='text-danger'>*</span></>}
                  props={{ disabled: disableFields }}
                  component={InputTextForRedux}                        
               />
               */}

               <Field
                  name="ahdData.topStorageWallLong"
                  label={<>Longitude <span className='text-danger'>*</span></>}
                  props={{ disabled: disableFields }}
                  component={InputTextForRedux}                        
               />
            </div>
         </div>

         <div className='row mb-3'>
            {/* 
            <div className='col-sm-6'>
               <Field
                  name="ahdData.topStorageWallMGA"
                  label={<>MGA Zone <span className='text-danger'>*</span></>}
                  props={{ disabled: disableFields}}
                  component={InputTextForRedux}                        
               /> 
            </div>
            */}
            <div className='col-sm-6'>
               <Field
                  name="ahdData.topStorageWallLevel"
                  label={<>Level(m AHD) <span className='text-danger'>*</span></>}
                  props={{ disabled: disableFields }}
                  component={InputTextForRedux}                        
               /> 
            </div>
         </div>

         <p className='mt-4 mb-3 border-primary border-bottom text-primary'>
         Lowest Point In Storage
         </p>

         <div className='row mb-3'>
            <div className='col-sm-6'>
               {/* 
               <Field
                  name="ahdData.lowestStoragePointEast"
                  label={<>Easting</>}
                  props={{ disabled: disableFields }}
                  component={InputTextForRedux}                        
               />
               */}
               <Field
                  name="ahdData.lowestStoragePointLat"
                  label={<>Latitude</>}
                  props={{ disabled: disableFields }}
                  component={InputTextForRedux}                        
               /> 
            </div>
            <div className='col-sm-6'>
               {/*
               <Field
                  name="ahdData.lowestStoragePointNorth"
                  label={<>Northing</>}
                  props={{ disabled: disableFields }}
                  component={InputTextForRedux}                        
               />
               */}
               <Field
                  name="ahdData.lowestStoragePointLong"
                  label={<>Longitude</>}
                  props={{ disabled: disableFields }}
                  component={InputTextForRedux}                        
               />
            </div>
         </div>

         <div className='row mb-3'>
            {/* 
            <div className='col-sm-6'>
               <Field
                  name="ahdData.lowestStoragePointMGA"
                  label={<>MGA Zone</>}
                  props={{ disabled: disableFields }}
                  component={InputTextForRedux}                        
               /> 
            </div>
            */}
            <div className='col-sm-6'>
               <Field
                  name="ahdData.lowestStoragePointLevel"
                  label="Level(m AHD)"
                  props={{ disabled: disableFields }}
                  component={InputTextForRedux}                        
               />
            </div>
         </div>

         <hr className='my-4'/>

         <div className='row mb-3'>
            <div className='col-sm-6'>
               <Field
                  name="ahdData.meterType"
                  component={SelectForRedux}
                  options={meter_options}
                  props={{ disabled: disableFields }}
                  label={<>Meter type</>}
               /> 
            </div>
            <div className='col-sm-6'>
               <Field
                  name="ahdData.surveysAccuracy"
                  component={CheckboxForRedux}
                  options={gps_options}
                  props={{
                     disabled: disableFields,
                     required: false,                            
                     decorateName: name=>  (<span>{name}<span style={{ color: 'red' }}> * </span> </span>)
                  }}
                  label="Please confirm GPS survey accuracy"                        
               />  
            </div>
         </div>

         <div className='mb-3'>
            <Field
               name="ahdData.benchComments"
               props={{ disabled: disableFields }}
               label="Comments"
               component={TextAreaForRedux}
               cols={40}
               rows={10}
            />  
         </div>

         {hasError && <div className='alert alert-danger'>
            Please fix all errors before you continue
         </div>}
         
         <div className="text-end">
            {(parseInt(ahdData.formStatus) == 1) && (!props.disableEditing) && <>

               {ahdData.id!=0 && <button type="button"  className="btn btn-danger me-3" disabled={loading} 
                  data-bs-toggle="modal" data-bs-target="#deleteDraftModal">
                  Delete Draft
               </button>}
               <button type="button"  className="btn btn-primary" disabled={loading}
                  onClick={() => { saveDraftAction(props); }}>
                  Save Draft
               </button>
               <div className='vr mx-3'></div>
            </>}
            <button type="button"  className="btn btn-warning me-3" onClick={()=>{previousPage(meterEquipInfo.formStatus)}} disabled={loading} >
               Previous
            </button>
            <button type="button" className="btn btn-success"
                  onClick={() => { clickNextAction(props); }} disabled={loading} >
                  Next
            </button>
         </div>
      </form>
   )
}

AHDValidationCertificatePartC = reduxForm({
   destroyOnUnmount: false,
   forceUnregisterOnUnmount: true,
   validate,
   onSubmitFail: (errors, dispatch ) => {
      dispatch(change('certificate', 'hasError', true));
   },
   onSubmitSuccess:(result , dispatch )=>{
      dispatch(change('certificate', 'hasError', false));
      window.scrollTo(0, 0);
   }
})(AHDValidationCertificatePartC)

AHDValidationCertificatePartC = connect(
   (state, props) => {
      const selectorCert = formValueSelector('certificate')
      const hasError = selectorCert(state, 'hasError')

      // can select values individually
      const selector = formValueSelector(props.form)
      const masterDataValues = selector(state, 'mdata')
      const meterEquipInfo = selector(state, 'meteringEquipmentInformation')
      const documents = selector(state, 'documents')
      const ahdData = selector(state, 'ahdData')
      const loading = selector(state, 'loading')
      const form = props.form
      return {
         masterDataValues,
         meterEquipInfo,
         ahdData,
         documents,
         form,
         loading,
         hasError
      }
   },
   {
      checkSerialNumber: (serialNumber) => checkSerialNumber(serialNumber)
   }
)(AHDValidationCertificatePartC)

export default AHDValidationCertificatePartC