import React, { Fragment } from 'react';
import Authentication from '../../helpers/Authentication'

import axios from 'axios';
import moment from 'moment';
import $ from 'jquery';


const authentication = new Authentication();
class LotSelectForEsid extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            lots : [],
            lotsLoaded: false
        }
    }

    ajaxConfig = {
        baseURL: process.env.REACT_APP_WEBAPI_URL,
        headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authentication.getToken() }
    };

    componentDidUpdate(prevProps) {
        if (prevProps.esid !== this.props.esid) {
            this.esidUpdated();
        }
    }

    componentDidMount() {
        this.esidUpdated();
    }   

    esidUpdated = () => {
        let currentContext = this;
        currentContext.setState(Object.assign(currentContext.state,{lotsLoaded: false, lots : []}));
        axios.post('/datalake/getLotsForESID', JSON.stringify({ 
            approvalNumber: this.props.approvalNumber,
            esid : this.props.esid
        }), this.ajaxConfig).then(function (response) {
            currentContext.setState(Object.assign(currentContext.state,{lots : response.data.data, lotsLoaded: true}));
            //currentContext.setState(Object.assign(currentContext.state,{lots : [], lotsLoaded: true}));
        }).catch(function (error) {
            alert('Error happened on the server side when getting lots for ESID.');
        }).then(function () {
            
        }); 
    }

    lotSelected = (lot) => {
        this.props.onLotChange(lot);
    }

    lotInputClick = (e) =>{
        $(e.target).parent().find('[data-bs-toggle="dropdown"]').trigger('click');
    }

    onChangeOfDisplayText = (e) => {
        this.props.onChangeOfDisplayText(e.target.value);
    }


    render() {
        return <>
            <div class="input-group">
                <input type="text" class="form-control" id="lot-display-text-input" onChange={this.onChangeOfDisplayText} onFocus={(e) => { if(this.state.lots.length>0) e.preventDefault(); }} onClick={this.lotInputClick} onKeyPress={(e) => { if(this.state.lots.length>0) e.preventDefault(); }} value={this.props.displayText} />
                <div class="input-group-btn">
                    <button class="btn btn-outline-secondary dropdown-toggle" disabled={!this.state.lotsLoaded || this.props.disabled} type="button" data-toggle="dropdown" data-bs-toggle="dropdown" aria-expanded="false"></button>
                    <div class="dropdown-menu dropdown-menu-end" style={{minWidth : "300px"}}>
                        {this.state.lots.length!=0 && <div className='px-2'>
                            <table class="table table-hover table-sm">
                                <thead>
                                    <tr className='table-primary'>
                                        <td colSpan="6" class="text-center ">
                                        <strong>Select a lot below</strong>
                                        </td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.lots.map((item) =>{
                                        return <tr key={item.dlwcLotId} style={{cursor : "pointer"}} onClick={() => {this.lotSelected(item)}} >
                                                <td>
                                                    {
                                                        (item.lotNumber ? item.lotNumber : "")
                                                        + "/" 
                                                        + (item.sectionNumber ? item.sectionNumber  : "")
                                                        + "/" 
                                                        + (item.planNumber ? item.planNumber  : "")
                                                        + " " 
                                                        + (item.planType ? item.planType : "")
                                                    }
                                                </td>
                                            </tr>
                                    })}
                                </tbody>
                            </table>
                        </div>}

                        {this.state.lots.length==0 && <table class="table">
                            <tr><td class="text-center bg-info">No lot found</td></tr>
                        </table>}
                        
                    </div>

                </div>
                
            </div>
        </>
    }

}

export default LotSelectForEsid;
