import React from 'react';
import * as bootstrap from 'bootstrap'
import { CardImage, ListIcon } from '../../../../components/tools/Icons';

export default class ValidationSupportingDocRO  extends React.Component {

    constructor(props) {
        super(props);
        this.state = {view:'LIST', canvasTitle:null, canvasImage:null}; 
    }

    bsOffcanvas;
    componentDidMount() {
        this.bsOffcanvas = new bootstrap.Offcanvas(document.getElementById('offcanvasBottom'));
    }

    openImage =(title,file) =>{
        this.setState({canvasTitle:title, canvasImage:process.env.REACT_APP_WEBAPI_URL + process.env.REACT_APP_FIELS_GET + "/" + file.documentId})
        this.bsOffcanvas.show();
    }

    render = () => {
        const form = this.props.validationForm;
        const documentTypes = [
            {type:3, name:"Meter marker plate"},
            {type:13, name:"Meter serial number"},
            {type:14, name:"LID serial number"},
            {type:4, name:"Evidence of tamper proofing/Meter seals"},
            {type:5, name:"Meter emplacement"},
            {type:6, name:"Meter display reading Nett total date of commissioning"},
            {type:7, name:"Site installation photos"},
            {type:16, name:"Meter reading on date LID was installed"},
            {type:15, name:"Calibration certificate"},
            {type:12, name:"Other"},
        ];

        const imageDocuments = [];
        const nonImageDocuments = [];
        form.val('documents').map((document)=>{
            if(document.fileOriginalName.toUpperCase().includes('.PNG') || document.fileOriginalName.toUpperCase().includes('.GIF') || document.fileOriginalName.toUpperCase().includes('.JPG') || document.fileOriginalName.toUpperCase().includes('.JPEG')) {
                imageDocuments.push(document);
            } else {
                nonImageDocuments.push(document);
            }
        })


        

        return <>               

            {this.state.view=='IMAGE' && <>

                <div class="btn-group mb-3" role="group">
                    <button type="button" class="btn btn-outline-primary btn-sm" onClick={()=>this.setState({view:'LIST'})}><ListIcon /></button>
                    <button type="button" class="btn btn-primary btn-sm"><CardImage/></button>
                </div>
                <br/>

                {imageDocuments.length==0 && <div className='alert alert-secondary'>
                    Image file not found in the Supporting Documents attached. 
                </div>}

                {documentTypes.map(t=>imageDocuments.filter(d=>d.documentType==t.type).map(file=><>
                    <div className='float-start text-center' style={{width:"200px",height:"200px"}} >
                        <img 
                            src={process.env.REACT_APP_WEBAPI_URL + process.env.REACT_APP_FIELS_GET + "/" + file.documentId} 
                            class="img-thumbnail rounded pointer-cursor m-2" 
                            style={{width:"150px",height:"100px", "object-fit": "cover"}} 
                            onClick={()=>this.openImage(t.name,file)}
                        />
                        <br/>
                        {t.name}
                    </div>
                </>))}
                <br style={{"clear":"both"}}/>
                {nonImageDocuments.length != 0 && 
                <div className='card card-body'>
                    <b>Other Files</b>
                    <table className='table table-striped'>
                        <thead>
                            <tr><th>Type</th><th>File name</th><th style={{width:"350px"}}>Description</th></tr>
                        </thead>
                        <tbody>
                            {documentTypes.map(t=>nonImageDocuments.filter(d=>d.documentType==t.type).map(file=><tr>
                                <td>{t.name}</td>
                                <td>
                                    <a target='_blank' href={process.env.REACT_APP_WEBAPI_URL + process.env.REACT_APP_FIELS_GET + "/" + file.documentId}>
                                        {file.fileOriginalName}
                                    </a>
                                </td>
                                <td>{file.description}</td>
                            </tr>))}
                            {form.val('documents') && form.val('documents').length==0 && <tr>
                                <td colspan="10" className='text-center bg-light'><i>No records</i></td>
                            </tr>}
                            
                        </tbody>
                    </table>
                </div>}
            </>}

            {this.state.view=='LIST' && <>

                <div class="btn-group mb-3" role="group">
                    <button type="button" class="btn btn-primary btn-sm"><ListIcon /></button>
                    <button type="button" class="btn btn-outline-primary btn-sm" onClick={()=>this.setState({view:'IMAGE'})}><CardImage/></button>
                </div>

                <table className='table table-striped'>
                    <thead>
                        <tr><th>Type</th><th>File name</th><th style={{width:"350px"}}>Description</th></tr>
                    </thead>
                    <tbody>
                        {documentTypes.map(t=>form.val('documents').filter(d=>d.documentType==t.type).map(file=><tr>
                            <td>{t.name}</td>
                            <td>
                                <a target='_blank' href={process.env.REACT_APP_WEBAPI_URL + process.env.REACT_APP_FIELS_GET + "/" + file.documentId}>
                                    {file.fileOriginalName}
                                </a>
                            </td>
                            <td>{file.description}</td>
                        </tr>))}
                        {form.val('documents') && form.val('documents').length==0 && <tr>
                            <td colspan="10" className='text-center bg-light'><i>No records</i></td>
                        </tr>}
                        
                    </tbody>
                </table>
            </>}

            <div class="offcanvas offcanvas-bottom h-75" tabindex="-1" id="offcanvasBottom" aria-labelledby="offcanvasBottomLabel">
                <div class="offcanvas-body small text-center">
                    <p className='text-center text-primary'><b>{this.state.canvasTitle}</b></p>
                    <img src={this.state.canvasImage} />
                </div>
            </div>
        </>
    }
}