import axios from 'axios';
import Authentication from '../../../helpers/Authentication';
const authentication = new Authentication();
const LOAD = '[AHDValidationCertificate] LOAD'
const SAVE = '[AHDValidationCertificate] SAVE'
const SEACRH_METER_DESIGN = '[AHDValidationCertificate] SEARCH'
const UPDATE_METER_DESIGN = '[AHDValidationCertificate] UPDATE'
const CHECK_METER_SERIALNUMBER = '[AHDValidationCertificate] SERIALNUMBER'
const POST_SAVE = '[AHDValidationCertificate] POST_SAVE'
let initial = {
  loaded: false,
  searchForm: {
    workApprovalNo: "",
    holderName: "",
    isMatched: false,
    authorityHolderLastName: "",
    organisation: "",
    isCustomerConsent: false,
    customerEmail: ''
  },
  consentData: [],
  clickNext: 0,
  clickSaveDraft: 1,
  authorityHolderDetails: {
    id: 0,
    holderType: '',
    salutationId: 0,
    salutationText: '',
    authorityHolderName: '',
    corporationNameOrAcn: '',
    postalAddress: '',
    phone: '',
    alternatePhone: '',
    emailAddress: '',
    contactPersonName: '',
    contactPersonPhone: '',
    contactPersonEmailAddress: '',
    firstName: '',
    lastName: ''
  },
  approvedWorkDetails: {
    id: 0,
    meterCircumstancesOptions: '',
    propertyName: '',
    lotDp: '',
    waterSupplyWorkApprovalNo: '',
    waterAccessLicenceNo: '',
    esid: '',
    waterSourceId: 0,
    waterSourceName: '',
    waterSharingPlanName: '',
    waterSourceTypeId: 0,
    waterSourceType: '',
    meterId: '', //added by sk06052020 persist in DB R2
    meterNumber: '', //added by sk06052020 persist in DB R2
    meterNumberGUID: '', //added by sk06052020 persist in DB R2
    approvedByCustomer: 0 //added by sk06052020 persist in DB R2 change
  },
  floodplainWorkDetails: {
    id: 0,
    propertyName: '',
    lotDp: '',
    waterAccessLicenceNo: '',
    approvedByCustomer: 0,
    //storageIdentifyingNumber: '',
    ratingTable: {
      id: 0,
      roiNumber: '',
      ratingTableName: '',
      activityNumber: '',
      ofsReference: '',
      storageWorkApprovalNumber: ''
    },
    siteDescription: ''
  },

  ahdData: {
    id: 0,
    benchType: 0,
    validationDate: null,
    lastValidationDate: null,
    damagedDate: null,
    bench1: null,
    bench2: null,
    bench3: null,
    bench1East: null,
    bench1North: null,
    bench1MGA: null,
    bench1Level: null,
    bench2East: null,
    bench2North: null,
    bench2MGA: null,
    bench2Level: null,
    bench3East: null,
    bench3North: null,
    bench3MGA: null,
    bench3Level: null,
    storageMeterEast: null,
    storageMeterNorth: null,
    storageMeterMGA: null,
    storageMeterLevel: null,
    storageCentroidEast: null,
    storageCentroidNorth: null,
    storageCentroidMGA: null,
    topStorageWallEast: null,
    topStorageWallNorth: null,
    topStorageWallMGA: null,
    topStorageWallLevel: null,
    lowestStoragePointEast: null,
    lowestStoragePointNorth: null,
    lowestStoragePointMGA: null,
    lowestStoragePointLevel: null,
    meterType: 0,
    surveysAccuracy: '',
    benchComments: '',
    createdBy: null,
    modifiedBy: null,
    expiryDate: null,
    confirmCert: 2,
    formStatus: 1,
    notifyMinister: 2
  },
  meteringEquipmentInformation: {

    id: 0,//number
    meterFormType: 1,//number
    meterCircumstancesOptions: '',//text
    dateOfLastValidation: '',//date
    meteringSystemTypeId: 0,//number
    meteringSystemType: [],//text
    meterManufacturer: '',//text
    meterTypeModel: '',//text
    meterSerialNo: '',//text
    meterSize: '', //number
    pipeType: '',//text
    pipeSize: '',//number
    pipeConnectionType: '',//decimal
    pipeLengthUpstream: '',//decimal
    pipeLengthDownstream: '',//decimal
    longitude: 154,//decimal
    latitude: -32,//decimal
    supplyTypeId: 0,//number
    supplyType: '',//text,
    sealNoIds: '',//text
    sealNo: [],//text
    dataCollectorTypeId: 0,//number
    dataCollectorType: '',//text
    dataCollectorModelNo: '',//text
    dataCollectorSerialNo: '',//text
    dataCollectorPowerSourceId: 0,//number
    dataCollectorPowerSource: '',//text
    dataCollectorSolarPanelWattage: '',//text
    dataCollectorSolarPanelSerialNumber: '',//text
    channelType: '',//text
    channelWidth: '',//text
    meterDistanceFromSource: '',//number
    channelLengthUpstream: '',//number
    channelLlengthDownstream: '',//number
    maxMeasuredDischarge: '',//decimal
    minMeasuredDischarge: '',//decimal
    maxMeasuredVelocity: '',//decimal
    minMeasuredVelocity: '',//decimal
    maxGaugeHeight: '',//decimal
    minGaugeHeight: '',//decimal
    maxError: '',//decimal
    minError: '',//decimal
    noOfMeasurementsTaken: '',//nummber
    accuracyMethod: '',//text
    accuracyConclusionOpenChannel: '',//text
    accuracyMaxErrorMeteringEquipment: '',//number
    accuracyOwnMeteringEquipment: '',//number
    dateValidation: '',//date
    formStatus: 1,//number
    date: '',//date
    //added for MeterValidationCertificatePartC extra fields
    patternApprovalNumber: '',
    meterTestCertificate: '',
    meterq1FlowRateMinimum: '',
    meterq3FlowRateOptimum: '',
    designFlowRate: '',
    softwareVersion: '',
    gpsLocation: '',
    meterReading: '',
    meterReadingDate: '',
    finalReadingRemovedMeter: '',
    finalReadingDateRemovedMeter: '',
    newMeterEquipment: '',
    designEquipmentDetails: '',
    meteringEquipmentConsistent: '',
    meterRemovedDate: '',
    readingBeforeMaintenance: '',
    dateBeforeMaintenance: '',
    readingAfterMaintenance: '',
    dateAfterMaintenance: '',

    isModificationToCompliance: '',
    validationMeterEquipmentComplies: '',
    validationMaxErrorMeteringEquipment: '',//number
    validationOwnMeteringEquipment: '',//number
    otherConclusion: '',
    openChannelConclusion: '',

    fileType: 0, //not to be added in db
    meterTypeModelId: 0,
    processUsedToCheckDesign: '',
    designingOwnEquipment: '',
    complyMeteringEquipment: 0,
    compylyNavigation: 0,
    meterBeforeApril2019: 0,//added new fields for existing meter
    dateOfInstallationBeforeApril2019: '',//added
    meterDescription: ''
  },

  meterNonComplianceReason: [{
    //nonComplianceData: [],
    reason: '',
    comments: '',
    //modificationsPerformed: '',
    //meteringEquipmentInformationId: ''
  }],

  meterNonCompliance: {
    modificationsPerformed: '',
    complianceReasonId: '',
    meteringEquipmentInformationId: ''
  },

  meterCertification: {
    meterEquipmentComplies: '',
    permissibleError: '',
    ownMeteringValidation: '',

    name: '',
    cmino: '',
    dqpqualificationType: '',
    businessName: '',
    abnorACN: '',
    address: '',
    phoneNumber: '',
    emailAddress: '',
    validationDate: '',
    signature: '',
    date: ''
  },

  ahdChecklist: {
    q1: '3',
    q2: '3',
    q3: '3',
    q4: '3',
    q5: '3',
    q6: '3',
    q7: '3',
    q8: '3',
  },
  maintenanceChecklist: {
    q1: '3',
    q2: '3',
    q3: '3',
    q4: '3',
    q5: '3',
    q6: '3',
    q7: '3',
    q8: '3',
    q9: '3',
    q10: '3',
    q11: '3',
    q12: '3',
    q13: '3',
    q14: '3',
    q15: '3',
    q16: '3',
    q17: '3',
    q18: '3',
    q19: '3',
    q20: '3',
    q21: '3',
    q22: '3',
    q23: '3',
    q24: '3',
    q25: '3',
    q26: '3',
    q27: '3',
    q28: '3',
    q29: '3',
    q30: '3',
    q31: '3',
    q32: '3',
    q33: '3',
    q34: '3',
    q35: '3',
    q36: '3',
    q37: '3',
    q38: '3'
  },
  mdata: [{}],
  documents: [],
  registrationDoc: [],
  esidExternalList: [], //added by sk06052020 retrieved from external System R2
  meterNumberExternalList: [], //added by sk06052020 retrieved from external System R2,
  waterAccessLicenceNoExternalList: [] //added by sk06052020 retrieved from external System R2,
}

const reducerAHDValidation = (state = initial, action) => {
  console.log('---------action--------' + JSON.stringify(action));

  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loaded: true,
        mdata: action.data.data,
        //approvedWorkDetails: action.data.approvedWorkDetails,
        //authorityHolderDetails: action.data.authorityHolderDetails,
        //esidExternalList: action.data.esidExternalList,
        //waterAccessLicenceNoExternalList: action.data.waterAccessLicenceNoExternalList
      }
    case SAVE:
      return state;

    case POST_SAVE:
      alert(action.data.benchmarkDetailsId);
      return {
        ...state,
        id: action.data.benchmarkDetailsId
      }

    case SEACRH_METER_DESIGN:

      //console.log(state);
      //debugger;

      return {
        ...state,
        loaded: action.data.payload.loaded,
        mdata: action.data.payload.mdata,
        authorityHolderDetails: action.data.payload.authorityHolderDetails,
        approvedWorkDetails: action.data.payload.approvedWorkDetails,
        meteringEquipmentInformation: action.data.payload.meteringEquipmentInformation,
        meterNonCompliance: action.data.payload.meterNonCompliance,
        meterNonComplianceReason: action.data.payload.meterNonComplianceReason,
        maintenanceChecklist: action.data.payload.checklist,
        documents: action.data.payload.documents,
        registrationDoc: action.data.payload.registrationDoc,
        ahdData: action.data.payload.ahdData,
        floodplainWorkDetails: action.data.payload.floodplainWorkDetails,
        ahdChecklist: action.data.payload.ahdChecklist
      }

    case UPDATE_METER_DESIGN:
      return state;
    case CHECK_METER_SERIALNUMBER:
      return state;
    default:
      return state;
  }
  return state;
}

/**
 * Simulates data loaded into this reducer from somewhere
 */
/* export const load = () => {

  const options = {
    url: process.env.REACT_APP_WEBAPI_URL + process.env.REACT_APP_METER_VALIDATION_CERT_GETALLDATA,
    method: 'GET',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Accept': 'application/json',
      'Content-Type': 'application/json;charset=UTF-8',
      'Authorization': 'Bearer ' + authentication.getToken()
    }
  };
  return async (dispatch) => {
    const response = await axios(options);
    const data = await response.data;
    console.log("printing");
    console.log(data);
    dispatch({ type: LOAD, data });
  }
} */

export const load = (payload) => {

  const options = {
    url: process.env.REACT_APP_WEBAPI_URL + process.env.REACT_APP_METER_VALIDATION_CERT_GETALLDATA,
    method: 'POST',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Accept': 'application/json',
      'Content-Type': 'application/json;charset=UTF-8',
      'Authorization': 'Bearer ' + authentication.getToken()
    },
    data: {
      payload
    }
  };
  return async (dispatch) => {
    const response = await axios(options);
    const data = await response.data;
    console.log("printing in AHDValidationCertificate.Reducers");
    console.log(JSON.stringify(data));
    dispatch({ type: LOAD, data });
  }
}



export const save = (payload, callback) => {

  console.log("data sent to save" + JSON.stringify(payload));
  debugger;
  const options = {
    url: process.env.REACT_APP_WEBAPI_URL + process.env.REACT_APP_AHD_CERT_POSTDATA,
    method: 'POST',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Accept': 'application/json',
      'Content-Type': 'application/json;charset=UTF-8',
      'Authorization': 'Bearer ' + authentication.getToken()
    },
    data: {
      payload
    }
  };

  debugger;

  return async (dispatch) => {
    try {
      //console.log("in_save_reducer:" + JSON.stringify(payload));
      const response = await axios(options);
      const data = await response.data;
      console.log(JSON.stringify("save response" + data));
      if (data.success) {
        debugger;

        if (callback) {
          callback(data.benchmarkDetailsId);
        } else {
          document.location.href = "/SurveyBenchmarkCertificate?id=" + data.benchmarkDetailsId;
        }
      }
      else {
        alert("Error in saving Data");
      }

      dispatch({ type: SAVE });

    }
    catch (error) {
      alert("Errors in saving Data" + error);
    }

  }
}

export const findById = (id) => {

  const options = {
    url: process.env.REACT_APP_WEBAPI_URL + process.env.REACT_APP_AHD_VALIDATION_CERT_URI + id,
    method: 'GET',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Accept': 'application/json',
      'Content-Type': 'application/json;charset=UTF-8',
      'Authorization': 'Bearer ' + authentication.getToken()
    }
  };
  return async (dispatch) => {
    try {
      const response = await axios(options);
      const data = await response.data;
      debugger;
      console.log("printing values");
      console.log(JSON.stringify(data));
      if (!data.success) {
        if (data.message != undefined && data.message != "")
          alert(data.message);
        else if(data.noPermission)
          alert('Access denied.');
        else
          alert("Error in loading Data");
      }
      else {
        console.log("data return from findById " + JSON.stringify(data, null, 2));
        dispatch({ type: SEACRH_METER_DESIGN, data });
      }
      debugger;
    }
    catch (error) {

      if (!error.success) {
        if (error.message != undefined && error.message != "") {
          alert(error.message);
        }
      }
    }


  }
}

export const update = (payload, callback) => {
  //alert("update in reducer called");
  console.log(payload);
  const options = {
    url: process.env.REACT_APP_WEBAPI_URL + process.env.REACT_APP_METER_VALIDATION_CERT_UPDATEDATA,
    method: 'POST',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Accept': 'application/json',
      'Content-Type': 'application/json;charset=UTF-8',
      'Authorization': 'Bearer ' + authentication.getToken()
    },
    data: {
      payload
    }
  };
  return async (dispatch) => {
    // const response = await axios(options);
    // //alert(response);
    // const data = await response.data;
    // alert(JSON.stringify(data));
    // dispatch({ type: UPDATE_METER_DESIGN, data });

    try {
      const response = await axios(options);

      const data = await response.data;
      console.log(JSON.stringify(data));
      if (data.success) {
        if (callback) {
          callback(data.benchmarkDetailsId);
        } else {
          document.location.href = "/SurveyBenchmarkCertificate?id=" + data.meterDesignId;
        }
      }
      else
        alert("Error in saving Data");
      console.log(JSON.stringify(data));
      dispatch({ type: UPDATE_METER_DESIGN, data });
    }
    catch (error) {

      alert("Errors in saving Data");
    }


  }
}

/**
 * Simulates data loaded into this reducer from somewhere
 */
export const checkSerialNumber = (id, serialNumber) => {

  const options = {
    url: process.env.REACT_APP_WEBAPI_URL + process.env.REACT_APP_METER_VALIDATION_CERT_CHECK_SERIALNUMBER + '/' + id + '/' + serialNumber,
    method: 'GET',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Accept': 'application/json',
      'Content-Type': 'application/json;charset=UTF-8',
      'Authorization': 'Bearer ' + authentication.getToken()
    }
  };
  return async (dispatch) => {
    try {
      const response = await axios(options);
      const data = await response.data;
      console.log("printing");
      console.log(data);
      if (data.success)
        alert(data.message);

    }
    catch (error) {

    }
    dispatch({ type: CHECK_METER_SERIALNUMBER });

  }
}



export default reducerAHDValidation