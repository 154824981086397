import React, { useContext } from 'react';
import { GlobalContext } from './State';
import { formatDateTime, formatDate, roundOff } from '../constants/MeterFormConstants'
import { TIMESTAMP, colourText, LOG_ENTRY_TYPE, filterWaterSource, TIME_AT_START_OF_DAY } from './MeasurementConstants';

const DAS_TAKE = 0.0;
const DAS_OPENING_LEVEL = 0.0;
const DAS_CLOSING_LEVEL = 0.0;
const CLSOING_STAORAGE_LEVEL = 0.0;

const getTakeFromNominiation = (nomination, consumedVolume) => {
  let total = DAS_TAKE;
  Object.keys(nomination.discharge).forEach((key, index) => {
    total -= parseInt(nomination.discharge[key].amount);
  })
  Object.keys(nomination.flow).forEach((key, index) => {
    total += parseInt(nomination.flow[key].amount);
  })
  if (consumedVolume) {
    total += consumedVolume;
  }
  return roundOff(total);
}

const getTelemeteredValues = (nomination, filterPredicate, offset = 0) => {

  return roundOff(nomination.logEntry.entryLog
    .filter(filterPredicate)
    .map(entry => entry.deltaVolume)
    .reduce((pre, cur) => pre + cur, offset * -1));
}

const getTotalFlow = (nomination, isDischarge) => {
  let flowDirection = isDischarge ? nomination.discharge : nomination.flow;
  let total = 0;
  Object.keys(flowDirection).forEach((key, index) => {
    if (flowDirection[key].amount) {
      total += parseInt(flowDirection[key].amount);
    }
  })
  return roundOff(total);
}

export const Statement = ({ nomination }) => {
  const { holder } = useContext(GlobalContext);
  console.log("nomination_summary_is");
  console.log(nomination);
  let entryLog = nomination.logEntry.entryLog;
  debugger;
  let dasLog = nomination.logEntry.type === LOG_ENTRY_TYPE.DAS;
  let priorDayReading = nomination.logEntry.priorDayReading;
  let consumedVolume = (entryLog[entryLog.length - 1].volume) - (priorDayReading.volume)
  return (
    <div>
      <div class="row">
        <div class="col_12"><h3 style={{ color: "#0054a6" }}>Flood Plain Harvesting - Measurement Period Water Take Statement</h3></div>
      </div>
      <div class="row">
        <div class="col_12">&nbsp;</div>
      </div>

      <div className="row">
        <div class="col-1">&nbsp;</div>
        <div class="col-11">
          <table class="table table-bordered" border='1'>
            <thead>
              <tr bgcolor="#0054a6">
                <td width="20%">{colourText('Storage Name')}</td>
                <td width="20%">{colourText('Start Date/Time')}</td>
                <td width="20%">{colourText('End Date/Time')}</td>
                <td width="20%">{colourText('Date Changed')}</td>
                <td width="20%">{colourText('Submitted By')}</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{nomination.ratingTable.ratingTableName}</td>
                <td>{formatDateTime(nomination.timeRange.startDate)}</td>
                <td>{formatDateTime(nomination.timeRange.endDate)}</td>
                <td>{formatDateTime(nomination.timeRange.endDate)}</td>
                <td>{nomination.holderName}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="row">
        <div class="col_12">&nbsp;</div>
      </div>


      {(entryLog && entryLog.length > 0) &&
        <div class="row">
          <div class="col-9">
            <table border='1'>
              <thead>
                <tr>
                  <td width="40%" bgcolor="#0054a6">{colourText('Opening Storage Level(Manual)')}</td>
                  <td>{priorDayReading.height} mAHD</td>
                </tr>
              </thead>
              <tbody>
                {entryLog.length > 0 &&
                  <tr>
                    <td width="40%" bgcolor="#0054a6">{colourText('Closing Storage Level(Manual)')}</td>
                    <td>{entryLog[entryLog.length - 1].height} mAHD</td>
                  </tr>
                }
              </tbody>
            </table>
          </div>
        </div>
      }


      <div class="row">
        <div class="col_12">&nbsp;</div>
      </div>
      <div className="row">
        <div class="col_12">
          <table class="table table-bordered" border='1'>
            <thead>
              <tr bgcolor="#0054a6">
                <td width="14.2857%">{colourText('Description')}</td>
                <td width="14.2857%">{colourText('Type of water')}</td>
                <td width="14.2857%">{colourText('Date')}</td>
                <td width="14.2857%">{colourText('Data Source')}</td>
                <td width="14.2857%">{colourText('Prior Telemetered storage level')}</td>
                <td width="14.2857%">{colourText('Current Telemetered storage level')}</td>
                <td width="14.2857%">{colourText('Water Take Volume')}</td>
              </tr>
            </thead>
            <tbody>

              {nomination.logEntry.entryLog.map((entry, index) => {
                return (
                  ((TIME_AT_START_OF_DAY(entry.entryDate) >= TIME_AT_START_OF_DAY(nomination.timeRange.startDate)) && (TIME_AT_START_OF_DAY(entry.entryDate) <= TIME_AT_START_OF_DAY(nomination.timeRange.endDate))) &&
                  <tr key={index}>
                    <td>{dasLog ? "Telemetry" : "Manual"}</td>
                    <td>Overland flows into storage</td>
                    <td>{formatDateTime(entry.entryDate)}</td>
                    <td>{dasLog ? "Telemetry" : "Manual"}</td>
                    <td> {index != 0 ? entryLog[index - 1].height : priorDayReading.height}mAHD</td>
                    <td>{(entry.height)}mAHD</td>
                    <td>{roundOff(entry.deltaVolume)}ML</td>
                  </tr>
                )
              })}

              {Object.keys(nomination.flow).map((key, index) => {
                return <tr key={1000 + index}>
                  <td>Other in-flow</td>
                  <td>{filterWaterSource(nomination.flow[key].waterSource, holder.referenceData).label}</td>
                  {/* <td>{formatDate(nomination.timeRange.startDate)}</td> */}
                  {(nomination.timeRange.startDate == null) &&
                    <td></td>
                  }
                  {((nomination.timeRange.startDate != null) && (nomination.timeRange.endDate == null)) &&
                    <td>{formatDateTime(nomination.timeRange.startDate)} to </td>
                  }
                  {((nomination.timeRange.startDate != null) && (nomination.timeRange.endDate != null)) &&
                    <td>{formatDateTime(nomination.timeRange.startDate)} to {formatDateTime(nomination.timeRange.endDate ? nomination.timeRange.endDate : Date.now)}</td>
                  }
                  <td>Customer Readings</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>{nomination.flow[key].amount}ML</td>
                </tr>
              })}
              {Object.keys(nomination.discharge).map((key, index) => {
                return <tr key={1000000 + index}>
                  <td>Discharge </td>
                  <td>{filterWaterSource(nomination.discharge[key].waterSource, holder.referenceData).label}</td>
                  {(nomination.discharge[key].startDate == null) &&
                    <td></td>
                  }
                  {(nomination.discharge[key].startDate != null) && (nomination.discharge[key].endDate == null) &&
                    <td>{formatDateTime(nomination.discharge[key].startDate)} to </td>
                  }
                  {(nomination.discharge[key].startDate != null) && (nomination.discharge[key].endDate != null) &&
                    <td>{formatDateTime(nomination.discharge[key].startDate)} to {formatDateTime(nomination.discharge[key].endDate ? nomination.discharge[key].endDate : Date.now)}</td>
                  }
                  <td>Customer Readings</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>-{nomination.discharge[key].amount}ML</td>
                </tr>
              })}
            </tbody>
          </table>
        </div>
      </div>

      <div class="row">
        <div class="col_12">&nbsp;</div>
      </div>
      <div class="row">
        <div class="col_12"><h3>Summarised Totals</h3></div>
      </div>

      <div>
        <table class="table table-bordered" border='1'>
          <thead>
            <tr bgcolor="#0054a6">
              <td width="20%">{colourText('Total water increase in storage')}</td>
              <td width="20%">{colourText('Total water descrease in storage')}</td>
              <td width="20%">{colourText('Total discharge reported')}</td>
              <td width="20%">{colourText('Total other inflow reported')}</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td id="nettTotal1">{getTelemeteredValues(nomination, a => a.deltaVolume >= 0)} ML</td>
              <td id="nettTotal2">{getTelemeteredValues(nomination, a => a.deltaVolume < 0)} ML</td>
              <td id="nettTotal3">{getTotalFlow(nomination, true)} ML</td>
              <td id="nettTotal4">{getTotalFlow(nomination, false)} ML</td>

            </tr>
          </tbody>
        </table>
      </div>

      <div class="row">
        <div class="col-md-9">&nbsp;</div>
        <div class="col-md-3">
          <table width="10%" class="table table-bordered" border='1'>
            <thead>
              <tr bgcolor="#0054a6">
                <td width="20%">{colourText('FPH water take')}</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td id="nettTotal5">{roundOff(getTelemeteredValues(nomination, a => a.deltaVolume >= 0, getTotalFlow(nomination, false)))} ML</td>
              </tr>
            </tbody>
          </table>
        </div>

      </div>

    </div>
  )
}