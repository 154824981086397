import React, { useContext, useEffect, useState } from 'react';
import { GlobalContext } from './State';
import axios from 'axios';
import { getUserProfile } from './API';
import { TIMESTAMP } from './MeasurementConstants';
import { JwtAuthentication as Authentication } from '../helpers/JwtAuthentication';

var token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJPbmxpbmUgSldUIEJ1aWxkZXIiLCJpYXQiOjE2MjUxMTk0NTUsImV4cCI6MTY1NjY1NTQ1NSwiYXVkIjoid3d3LmV4YW1wbGUuY29tIiwic3ViIjoianJvY2tldEBleGFtcGxlLmNvbSJ9.uFbBLhsefTDC7CkjSC86L8z6ee5m6V3EaYw1koO4eKc';
const staticConfig = {
    baseURL: process.env.REACT_APP_WEBAPI_URL,
    headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }
};
const ajaxConfig = {
    baseURL: process.env.REACT_APP_WEBAPI_URL,
    headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }
};

export const RatingTableSearch = () => {
    const { holder: { ratingTable, referenceData }, setRatingTable, flickPage, setRatingTableContents } = useContext(GlobalContext);
    const [userObject, setUser] = useState({});
    const authentication = new Authentication();
    const [ratingTableName, setRatingTableName] = useState();
    const [disableContinue, setDisableContinue] = useState(true);
    const [searchFormLoading, setSearchFormLoading] = useState();
    const [contentsLoading, setContentsLoading] = useState(false);
    const [searchPerformed, setsearchPerformed] = useState();
    const [searchResults, setSearchResults] = useState([]);
    const [ratingTableContentsLoaded, setRatingTableContentsLoaded] = useState(0);

    // const doAuth = () => {

    //     if (authentication.isB2C()) {
    //       let currentUser = { ...authentication.currentUser(), b2cUser: true };
    //       getUserProfile(
    //         authentication.currentUser().emails.find(IDENTITY_FUNCTION),
    //         data => {
    //           setUser({ ...currentUser, ...data })
    //         },
    //         error => terminalError(error),
    //         () => console.log("doAuth_completed" + JSON.stringify(userObject))
    //       );
    //     } else {
    //       //jwt logic
    //       authentication.currentUserAsync(setUser);
    //     }
    // }

    // useEffect(() => {
    //     doAuth();
    //   }, []);

    const searchForm = (ratingTableName) => { return { roiOrStorageWorkApproval: ratingTableName } }

    const handleSubmit = (e) => {
        e.preventDefault();
        setSearchFormLoading(true);
    };

    const doRatingTableName = (e) => {
        setRatingTableName(e.target.value);
        console.log("now rating table name is" + e.target.value);
    }

    const onRatingTableChange = e => {
        console.log("selected id is" + e.target.value);
        if (e.target.value) {
            let obj = searchResults.find(result => result.id == e.target.value);
            console.log("obj is" + JSON.stringify(obj, null, 2));
            debugger;
            var data = getContents(obj);
            setRatingTable(obj);

            //setRatingTableContentsLoaded(ratingTableContentsLoaded+1);
        }
    }

    useEffect(() => {

        if (searchFormLoading) {
            console.log("ratingTableName");
            axios.post('/ratingTables/searchByRoiOrStorageWorkApproval', JSON.stringify(searchForm(ratingTableName)), ajaxConfig).then(function (response) {
                console.log("data from server is" + JSON.stringify(response.data.data));
                //setRatingTable(response.data.data);    

                setSearchResults(response.data.data);
                setsearchPerformed(true);
            }).catch(function (error) {
                alert('Error happened on the server side when searching for ROI / Storage work approval number.');

            }).then(function () {
                setSearchFormLoading(false);
            });
        }

    }, [searchFormLoading]);

    async function getContents(obj) {
        if (!obj) return;
        debugger;
        console.log("load rating table contents");
        //alert(referenceData.userid);
        const options = {
            url: process.env.REACT_APP_WEBAPI_URL + '/ratingTables/getRatingTableContentByTableId',
            method: 'POST',
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization': 'Bearer ' + token
            },
            data: {
                userid: referenceData.userid,
                id: obj.id
            }
        };
        setContentsLoading(true);
        const response = await axios(options);
        const result = await response.data;
        setRatingTableContents(response.data.data);

        if (response.data.data.openMeasurements.length > 0) {
            setDisableContinue(true);
            alert("There is already an Open Measurement Period for this storage. Please close or delete the existing measurement period before opening another for this storage.");
        }
        else {
            setDisableContinue(false);
        }
        setContentsLoading(false);
        return 0;
    }



    return (
        <React.Fragment>
            <form onSubmit={handleSubmit}>
                <h4>Search rating table / storage curve</h4>
                <p>Enter Registration of Interest (ROI) number / Storage work approval number</p>

                <div class="row">
                    <div class="col-md-7">
                        <div class="form-group">
                            <input type="text" class="form-control" onChange={doRatingTableName}
                                value={ratingTableName} placeholder="ROI / Storage work approval" />
                        </div>
                        <p>
                            <br />
                            {!searchFormLoading && <button type="submit" class="btn btn-primary" >Search</button>}
                            {searchFormLoading && <span style={{ color: 'green' }}><i className="fa fa-spinner fa-spin" style={{ fontSize: '24px', color: 'green' }}></i></span>}
                        </p>

                    </div>
                </div>

                {searchPerformed && searchResults && searchResults.length == 0 &&
                    <div>
                        <div class="alert alert-info" role="alert">
                            No data found for Registration of Interest (ROI) number / Storage work approval number
                        </div>
                    </div>
                }

                {searchPerformed && !searchFormLoading && searchResults && searchResults.length > 0 &&
                    <div>
                        <h5>Select storage</h5>
                        <div class="row">
                            <div class="col-md-7">
                                <div class="form-group">
                                    <select class="form-control" onChange={onRatingTableChange} value={ratingTable?.id}>
                                        <option value={-1}>Select</option>
                                        {searchResults.map(function (object, i) {
                                            return <option value={object.id} key={object.id}>{object.ratingTableName}</option>;
                                        })}
                                    </select>
                                </div>
                                <p>
                                    <br />
                                    {
                                        contentsLoading && <span style={{ color: 'green' }}><i className="fa fa-spinner fa-spin" style={{ fontSize: '24px', color: 'green' }}></i></span>
                                    }
                                    {(!searchFormLoading && !contentsLoading) &&
                                        <button type="button" class="btn btn-primary" disabled={disableContinue} onClick={() => flickPage(2)}>
                                            Continue
                                        </button>
                                    }

                                </p>
                            </div>
                        </div>
                    </div>
                }
            </form>
        </React.Fragment>
    )
}