import React from 'react';
import * as apiCaller from '../../../helpers/apiCaller'
class Video extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        if (this.props.type == "youtube") {
            return <div style={{ position: "relative", height: 0, "padding-bottom": "56.25%" }}>
                <iframe width="100%" height="100%" style={{ position: "absolute", top: 0, left: 0 }} src={this.props.src} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="true" webkitallowfullscreen="true" mozallowfullscreen="true"></iframe>
            </div>
        }
        return (
            <video style={{ width: "100%" }} controls id="main-video">
                <source src={this.props.src} type="video/mp4" />
            </video>
        );
    }
}

const videos = [
    {
        //src: "https://dqp.waternsw.com.au/blob/static-content/tutorials/20210709/LID-DQP-Video-DELIVERY.mp4",
        src: "https://www.youtube.com/embed/wO6p7Bp3t4w",
        title: "01 - Overview of Meter-Telemetry Installation Process",
        thumb: "https://dqp.waternsw.com.au/blob/static-content/tutorials/20210709/Video%20thumbnail.png",
        type: "youtube"
    }
    ,
    {
        //src: "https://dqp.waternsw.com.au/blob/static-content/tutorials/20210709/01%20-%20Landing%20Page%20%26%20User%20Registration.mp4",
        src: "https://www.youtube.com/embed/qYEc0kwk9Ls",
        title: "02 - Landing Page & User Registration",
        thumb: "https://dqp.waternsw.com.au/blob/static-content/tutorials/20210709/01%20-%20Landing%20Page%20%26%20User%20Registration.png",
        type: "youtube"
    }
    ,
    {
        //src: "https://dqp.waternsw.com.au/blob/static-content/tutorials/20210709/02%20-%20Homepage.mp4",
        src: "https://www.youtube.com/embed/ZB0t2U2NuKM",
        title: "03 - Homepage",
        thumb: "https://dqp.waternsw.com.au/blob/static-content/tutorials/20210709/02%20-%20Homepage.png",
        type: "youtube"
    }
    ,
    {
        //src: "https://dqp.waternsw.com.au/blob/static-content/tutorials/20210709/03%20-%20Customer%20Consent.mp4",
        src: "https://www.youtube.com/embed/4-tSP0ALOiw",
        title: "04 - Customer Consent",
        thumb: "https://dqp.waternsw.com.au/blob/static-content/tutorials/20210709/03%20-%20Customer%20Consent.png",
        type: "youtube"
    }
    ,
    {
        //src: "https://dqp.waternsw.com.au/blob/static-content/tutorials/20210709/04%20-%20Meter-Telemetry%20Registration%20Form.mp4",
        src: "https://www.youtube.com/embed/jvMOmUP6h4Y",
        title: "05 - Meter-Telemetry Registration Form",
        thumb: "https://dqp.waternsw.com.au/blob/static-content/tutorials/20210709/04%20-%20Meter-Telemetry%20Registration%20Form.png",
        type: "youtube"
    }
    ,
    {
        //src: "https://dqp.waternsw.com.au/blob/static-content/tutorials/20210709/05%20-%20Non-Urban%20Metering%20Validation%20Certificate.mp4",
        src: "https://www.youtube.com/embed/Woc8MD_Uvew",
        title: "06 - Non-Urban Metering Validation Certificate",
        thumb: "https://dqp.waternsw.com.au/blob/static-content/tutorials/20210709/05%20-%20Non-Urban%20Metering%20Validation%20Certificate.png",
        type: "youtube"
    }
    ,
    {
        //src: "https://dqp.waternsw.com.au/blob/static-content/tutorials/20210709/06%20-%20DQP%20Portal%20-%20Design%20Certificate.mp4",
        src: "https://www.youtube.com/embed/APuqEoXoiKA",
        title: "07 - Design Certificate",
        thumb: "https://dqp.waternsw.com.au/blob/static-content/tutorials/20210709/06%20-%20DQP%20Portal%20-%20Design%20Certificate.png",
        type: "youtube"
    }
    ,
    {
        src: "https://dqp.waternsw.com.au/blob/static-content/tutorials/20210709/07%20-%20DQP%20Portal%20-%20Certificate%20of%20Accuracy.mp4",
        src: "https://www.youtube.com/embed/OQY3o5o3A1c",
        title: "08 - Certificate of Accuracy",
        thumb: "https://dqp.waternsw.com.au/blob/static-content/tutorials/20210709/07%20-%20DQP%20Portal%20-%20Certificate%20of%20Accuracy.png",
        type: "youtube"
    }
];

class Help extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            videos: videos,
            mainVideo: videos[0],
            releaseNotes:[]
        };
    }

    componentDidMount(){
        this.refresh();
    }

    selectVideo(video) {
        this.setState(state => ({
            mainVideo: video
        }), function () {
            if (video.type == null)
                document.getElementById("main-video").load();
        });

    }

    refresh = async () => {
        this.setState({loading:true});
        await apiCaller.get('/system/release-notes',false)
        .then((data)=>{
            this.setState({releaseNotes:data.notes});
        });
    }


    render() {
        return <>
            <div className='row'>
                <div className='col-sm-6'>
                    <h4 className='text-black'><strong>Contact</strong></h4>
                    <p>
                        If you experience any issues with this portal email
                        &nbsp;<a className='text-decoration-none' href="mailto:dqp.enquiries@waternsw.com.au">dqp.enquiries@waternsw.com.au</a>
                        .
                    </p>
                    <p>
                        For immediate assistance, you may find the answer to your enquiry by viewing the
                        &nbsp;<a className='text-decoration-none' href="https://dqp.waternsw.com.au/blob/static-content/DQP%20Portal%20Frequently%20Asked%20Questions.pdf" target="_blank">Frequently Asked Questions (FAQs)</a>.
                    </p>
                    <h4 className='text-black'><strong>What's new in DQP Portal</strong></h4>
                    <ul>
                        {this.state.releaseNotes.map(n=><>
                                <li><a className='text-decoration-none' href={n.link} target="_blank">{n.text}</a></li>
                        </>)}
                    </ul>


                    <h4 className='text-black'><strong>How to guide / Work Instructions</strong></h4>
                    <ul>
                        <li><a className='text-decoration-none' href="/how-to-guide">Click here to see the list of how to guide / work instructions</a></li>
                    </ul>
                </div>

                <div className='col-sm-6'>
                    <h4 className='text-black'><strong>Useful Links</strong></h4>
                    <ul>
                        <li><a className='text-decoration-none' href="https://nswdpie.tfaforms.net/267" target="_blank">Metering Guidance Tool</a></li>
                        <li><a className='text-decoration-none' href="https://waterregister.waternsw.com.au/water-register-frame" target="_blank">WaterNSW Water Register</a></li>
                        <li><a className='text-decoration-none' href="https://www.industry.nsw.gov.au/water/metering/metering-requirements-and-specifications" target="_blank">List of Pattern-Approved Meters (MDBA)</a></li>
                        <li><a className='text-decoration-none' href="https://www.irrigationaustralia.com.au/COIE/Certification/Metering-Policy---Regulations.aspx" target="_blank">NSW non-urban water metering (IAL)</a></li>
                        <li><a className='text-decoration-none' href="https://dqp.waternsw.com.au/blob/static-content/DQP%20Portal%20Frequently%20Asked%20Questions.pdf" target="_blank">DQP Portal Frequently Asked Questions (FAQs)</a></li>
                    </ul>

                </div>
            </div>
        </>;
    }
}

export default Help;