import React from 'react';
import Authentication from '../../helpers/Authentication'
import axios from 'axios';
import uiHelpers from '../../helpers/uiHelpers'
import Pagination from '../../components/tools/Pagination';
import {InputTextWithFloatingLabel, InputText} from '../../components/tools/BootstrapControls'
import {Trash, PenSquare, Refresh, ListIcon} from '../../components/tools/Icons'
import * as bootstrap from 'bootstrap'
import * as apiCaller from '../../helpers/apiCaller'
import serviceUrls from '../../constants/serviceUrls';
import moment from 'moment';
import commonConstants from '../../constants/commonConstants'

const authentication = new Authentication();

const convertUserAdminRole = (admin) =>{
    if(!admin) return "DQP";
    if(admin==1) return "CFO Admin"
    if(admin==2) return "Super Admin"
    if(admin==3) return "CDO"
    else return "Unknown"
}

class ManageUsers extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            searchEmail : null,
            searchDQP : true,
            searchCFOAdmin : true,
            searchSuperAdmin : true,
            searchCDO:true,

            email : null,
            emailError : null,
            displayRecords : [],

            editRoleRecord : {},
            roleChangeTo : null,

            deletingEmail : null,

            toggleActivationRecord : null,

            auditUser:null,
            audits:null
        }
    }

    searchResult = [];

    ajaxConfig = {
        baseURL: process.env.REACT_APP_WEBAPI_URL,
        headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authentication.getToken() }
    };

    #editRoleModal;
    #deleteConfirmationModal;
    #addEmailModal;
    #toggleActivationConfirmationModal;
    #auditModal;
    async componentDidMount() {
        this.#editRoleModal = new bootstrap.Modal(document.getElementById('editRoleModal'), {
            keyboard: false
        });
        this.#deleteConfirmationModal = new bootstrap.Modal(document.getElementById('deleteConfirmationModal'), {
            keyboard: false
        })
        this.#addEmailModal = new bootstrap.Modal(document.getElementById('addEmailModal'), {
            keyboard: false
        })
        this.#toggleActivationConfirmationModal = new bootstrap.Modal(document.getElementById('toggleActivationConfirmationModal'), {
            keyboard: false
        })
        this.#auditModal = new bootstrap.Modal(document.getElementById('auditModal'), {
            keyboard: false
        })
        this.search();
    }   


    handleChange = (e) =>{
        this.setState({systemMessage: e.target.value});
    }

    deleteDataModal=(record)=>{
        this.state.deletingId = record.id;
        this.#deleteConfirmationModal.show();
    }

    openAuditModel = async (user) => {
        this.setState({auditUser:user, audits:null});
        this.#auditModal.show();
        await apiCaller.post(serviceUrls.admin.getUserAudits,{userId:user.userId})
        .then((data)=>{
            this.setState({audits:data.audits})
        })
        
    }

    search =async (preservePageIndex) =>{
        uiHelpers.showProgressOnTop('Loading users...');
        this.setState({loading:true});
        try {
            var response = await axios.post('/admin/search-users',{
                email:this.state.searchEmail,
                dqp : this.state.searchDQP,
                cfoAdmin : this.state.searchCFOAdmin,
                superAdmin : this.state.searchSuperAdmin,
                cdo: this.state.searchCDO
            },this.ajaxConfig);
            this.searchResult = response.data.data.data;
            if(this.displayRecordSetter) this.displayRecordSetter(this.searchResult, preservePageIndex);
            uiHelpers.hideProgressOnTop();
        } catch(e){
            console.log(e);
            uiHelpers.showErrorTop("Error on loading users...");
        }

        this.setState({loading:false});
    }

    export = async () => {
        uiHelpers.showProgressOnTop('Loading users...');
        this.setState({loading:true});

        try {
            await axios.post('/admin/export-users',{
                email:this.state.searchEmail,
                dqp : this.state.searchDQP,
                cfoAdmin : this.state.searchCFOAdmin,
                superAdmin : this.state.searchSuperAdmin,
                cdo: this.state.searchCDO
            },this.ajaxConfig).then((response) => {
                uiHelpers.hideProgressOnTop();
                var mime = 'application/octet-stream';
                var filename = 'user-export.csv';
                var blob = new Blob([response.data], {type: mime || 'application/octet-stream'});
            
                if (typeof window.navigator.msSaveBlob !== 'undefined') {
                    // IE workaround for "HTML7007: One or more blob URLs were
                    // revoked by closing the blob for which they were created.
                    // These URLs will no longer resolve as the data backing
                    // the URL has been freed."
                    window.navigator.msSaveBlob(blob, filename);
                }
                else {
                    var blobURL = (window.URL && window.URL.createObjectURL) ? window.URL.createObjectURL(blob) : window.webkitURL.createObjectURL(blob);
                    var tempLink = document.createElement('a');
                    tempLink.style.display = 'none';
                    tempLink.href = blobURL;
                    tempLink.setAttribute('download', filename);
            
                    // Safari thinks _blank anchor are pop ups. We only want to set _blank
                    // target if the browser does not support the HTML5 download attribute.
                    // This allows you to download files in desktop safari if pop up blocking
                    // is enabled.
                    if (typeof tempLink.download === 'undefined') {
                        tempLink.setAttribute('target', '_blank');
                    }
            
                    document.body.appendChild(tempLink);
                    tempLink.click();
            
                    // Fixes "webkit blob resource error 1"
                    setTimeout(function() {
                        document.body.removeChild(tempLink);
                        window.URL.revokeObjectURL(blobURL);
                    }, 200)
                }

                this.setState({loading:false});

                return true;
            });
        } catch(e){
            console.log(e);
            uiHelpers.showErrorTop("Error on loading users...");
            this.setState({loading:false});
        }

        this.setState({loading:false});
    }

    refreshDisplayRecords = (data)=>{
        this.setState({displayRecords: data });
    }

    displayRecordSetter = null;
    displayRecordPagenationDataProvider = (dataSetter) =>{
        this.displayRecordSetter = dataSetter;
    }


    searchEmailChange = (e) =>{
        this.setState({searchEmail:e.target.value})
    }

    

    submitNewEmail = async() =>{
        if(!this.state.email || this.state.email.trim()=="") {
            this.setState({emailError : "Please provide a email address"})
            return;
        } else if(!commonConstants.regex.EMAIL.test(this.state.email)){
            this.setState({emailError : "Please enter a valid email address"})
            return;
        }

        this.setState({emailError : null, loading:true});
        try {
            const response = await axios.post('/admin/add-dqp-info',{email:this.state.email},this.ajaxConfig);
            if(!response.data.success){
                this.setState({emailError : response.data.message, loading:false});
                uiHelpers.hideProgressOnTop();
                return;
            }
            this.search();
        } catch(e){
            console.log(e);
            uiHelpers.showErrorTop("Error when adding email");
            this.setState({loading:false});
        }
        this.#addEmailModal.hide();


    }

    changeEmail = (e)=>{
        this.setState({email:e.target.value})
    }

    openEditRoleModal = (record) =>{
        this.setState({editRoleRecord:record, roleChangeTo: record.admin});
        this.#editRoleModal.show();
    }


    roleSelectChange =(e) => {
        if(e.target.value == '1') this.setState({roleChangeTo: 1});
        else if(e.target.value == '2') this.setState({roleChangeTo: 2});
        else if(e.target.value == '3') this.setState({roleChangeTo: 3});
        else this.setState({roleChangeTo: null});
    }


    saveRole=async ()=>{
        this.setState({loading:true});
        try {
            await axios.post('/admin/save-role',{email: this.state.editRoleRecord.email, admin : this.state.roleChangeTo},this.ajaxConfig);            
        } catch(e){
            console.log(e);
            uiHelpers.showErrorTop("Error occurred when deleting this record");
        }
        this.#editRoleModal.hide();
        this.setState({loading:false});
        this.search(true);
    }

    deleteDataModal=(record)=>{
        this.setState({deletingEmail : record.email});
        this.#deleteConfirmationModal.show();
    }


    delete =async () => {

        this.setState({loading:true});
        try {
            await axios.post('/admin/remove-dqp-info',{email: this.state.deletingEmail},this.ajaxConfig);
        } catch(e){
            console.log(e);
            uiHelpers.showErrorTop("Error occurred when deleting this record");
        }
        this.#deleteConfirmationModal.hide();
        this.setState({loading:false});
        this.search(true);
    }

    openAddEmailModal = ()=>{
        this.setState({email:null, emailError:null})
        this.#addEmailModal.show();
    }

    openToggleActivationModal =async (record) =>{
        this.setState({toggleActivationRecord : record});
        this.#toggleActivationConfirmationModal.show();
    }

    toggleActivation = async() => {
        

        this.setState({loading:true});
        try {
            await axios.post('/admin/toggle-user-activation',{id: this.state.toggleActivationRecord.userId},this.ajaxConfig);
        } catch(e){
            console.log(e);
            uiHelpers.showErrorTop("Error occurred when toggleing user ativation");
        }
        this.#toggleActivationConfirmationModal.hide();
        this.setState({loading:false});
        this.search(true);
    }

    convertAuditAction =(action) => {
        switch(action) {
            case 'CREATE' : return 'Create';
            case 'CHANGE_ROLE' : return 'Change Role';
            case 'ACTIVATE' : return 'Activate';
            case 'DEACTIVATE' : return 'Deactivate';
            default : return action
        }
    }

    render() {
        return <>
            <h3 className='mb-3'>Manage Users</h3>

            <div class="card  mb-3" >
                <div className='card-body'>
                    <button className='btn btn-outline-primary btn-sm' disabled={this.state.loading} onClick={this.openAddEmailModal}>Add email</button>
                </div>
            </div>

            <hr/>

            <form onSubmit={(e)=>{e.preventDefault();this.search();}}>
                <div class="row mb-3">
                    <div class="col-md-7">
                        <InputTextWithFloatingLabel
                            value={this.state.searchEmail}
                            className="mb-3"
                            disabled={this.state.loading}
                            onChange={this.searchEmailChange}
                            label="Search for email"
                        />

                        <div class="form-check">
                            <input class="form-check-input" disabled={this.state.loading} type="checkbox" value="" id="check_1" checked={this.state.searchDQP} onChange={()=>{this.setState({searchDQP : !this.state.searchDQP})}}/>
                            <label class="form-check-label" for="check_1">
                                DQP
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" disabled={this.state.loading} type="checkbox" value="" id="check_2" checked={this.state.searchCFOAdmin} onChange={()=>{this.setState({searchCFOAdmin : !this.state.searchCFOAdmin})}}/>
                            <label class="form-check-label" for="check_2">
                                CFO Admin
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" disabled={this.state.loading} type="checkbox" value="" id="check_4" checked={this.state.searchCDO} onChange={()=>{this.setState({searchCDO : !this.state.searchCDO})}}/>
                            <label class="form-check-label" for="check_4">
                                CDO
                            </label>
                        </div>
                        <div class="form-check mb-3">
                            <input class="form-check-input" disabled={this.state.loading} type="checkbox" value="" id="check_3" checked={this.state.searchSuperAdmin} onChange={()=>{this.setState({searchSuperAdmin : !this.state.searchSuperAdmin})}}/>
                            <label class="form-check-label" for="check_3">
                                Super Admin
                            </label>
                        </div>
                        
                        <div>
                            <button className='btn btn-primary me-2' type="submit" disabled={this.state.loading}>
                                Search
                            </button>
                            <button className='btn btn-outline-primary' type="button" disabled={this.state.loading} onClick={this.export}>
                                Export
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        

            <table className="table table-striped fs-90">
                <thead>
                    <tr>
                        
                        <th style={{width:"250px"}}>Email</th>
                        <th style={{width:"160px"}}>User created</th>
                        <th style={{width:"150px"}}>First name</th>
                        <th>Last name</th>
                        <th style={{width:"150px"}}>Role</th>
                        <th style={{width:"150px"}}>Active</th>
                        <th style={{width:"50px"}} ></th>
                    </tr>
                </thead>
                <tbody>
                    {this.searchResult.length==0 && <tr className='table-primary'>
                        <td colSpan={6} className='text-center'>No record found</td>    
                    </tr>}
                    {this.state.displayRecords.map((record, i) => <>
                        <tr>
                            <td>
                                {record.email}
                            </td>
                            <td>
                                {record.userCreated == 0 ? <span className='text-warning'>No</span> : <span className='text-success'>Yes</span>}
                            </td>
                            <td>
                                {record.firstName}
                            </td>
                            <td>
                                {record.lastName}
                            </td>
                            <td>
                                <button onClick={()=>{this.openEditRoleModal(record)}} className='btn btn-outline-secondary btn-sm btn-icon-16'><PenSquare/></button>
                                &nbsp;&nbsp;
                                {convertUserAdminRole(record.admin)}
                            </td>
                            <td>
                                {record.active == null ? <em className='text-secondary'>Not applicable</em> : <>
                                
                                    <div class="form-check form-switch">
                                        <input class="form-check-input" type="checkbox" role="switch" id={"check" + i} checked={record.active} onChange={()=>{this.openToggleActivationModal(record)}} />
                                        <label class="form-check-label" for={"check" + i}>{record.active ? "Yes" : "No" }</label>
                                    </div>
                                </>}
                            </td>
                            <td className='text-end'>
                                {record.userCreated != 1 ? 
                                    <button onClick={()=>{ this.deleteDataModal(record)}} className='btn btn-outline-secondary btn-sm btn-icon-16'><Trash/></button>
                                    : <button onClick={()=>this.openAuditModel(record)} className='btn btn-outline-secondary btn-sm btn-icon-16'><ListIcon/></button>
                                }
                            </td>
                        </tr>
                    </>)}
                </tbody>
            </table>
            <div class="row justify-content-md-center">
                <div class="col"></div>
                <div class="col-md-auto">
                    <Pagination numberOfRecordsPerPage={10} dataProvider={this.displayRecordPagenationDataProvider} displayRecordsUpdate={this.refreshDisplayRecords} />
                    <div className='fs-6 text-center mt-2'><em>{this.searchResult.length} records</em></div>
                </div>
                <div class="col"></div>
            </div>




            <div class="modal fade" id="editRoleModal" tabindex="-1" aria-hidden="true" data-bs-keyboard="false">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">
                                Edit user role
                            </h5>
                        </div>
                        <div class="modal-body">

                            <InputText
                                disabled={true}
                                value={this.state.editRoleRecord.email}
                                className="mb-3"
                                label="Email"
                            />
                            
                            <div>
                                <label class="form-label">Role</label>
                                <select class="form-select mb-3" onChange={this.roleSelectChange} value={this.state.roleChangeTo == null ? "0" : this.state.roleChangeTo.toString()} > 
                                    <option value="0" >DQP</option>
                                    <option value="1" >CFO Admin</option>
                                    <option value="3" >CDO</option>
                                    <option value="2" >Super Admin</option>
                                </select>
                            </div>
                        </div>
                        <div class="modal-footer position-relative">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" disabled={this.state.loading}>Close</button>
                            <button type="button" class="btn btn-primary" disabled={this.state.loading} onClick={this.saveRole}>Save</button>
                        </div>
                    </div>
                </div>
            </div>


            <div class="modal fade" id="deleteConfirmationModal" data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-body p-5">
                           <h5 className='text-center mb-4'>Do you want to delete this email?</h5>
                           <p className='text-center text-primary mb-4'>{this.state.deletingEmail}</p>
                           <div className='text-center'>
                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" disabled={this.state.loading}>Cancel</button>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <button type="button" class="btn btn-primary" disabled={this.state.loading} onClick={this.delete}>Confirm</button>
                           </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal fade" id="toggleActivationConfirmationModal" data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-body p-5">
                           <h5 className='text-center mb-4'>Do you want to <strong className='text-primary'>{this.state.toggleActivationRecord ? (this.state.toggleActivationRecord.active ? "deactivate" : "activate") : ""}</strong> this user?</h5>
                           <p className='text-center text-primary mb-4'>{this.state.toggleActivationRecord ? (<>{this.state.toggleActivationRecord.firstName} {this.state.toggleActivationRecord.lastName} <em>({this.state.toggleActivationRecord.email})</em></>) : ""}</p>
                           <div className='text-center'>
                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" disabled={this.state.loading}>Cancel</button>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <button type="button" class="btn btn-primary" disabled={this.state.loading} onClick={this.toggleActivation}>Confirm</button>
                           </div>
                        </div>
                    </div>
                </div>
            </div>



            <div class="modal fade" id="addEmailModal" data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">
                                Add email
                            </h5>
                        </div>
                        <div class="modal-body">

                            <InputText
                                error = {this.state.emailError}
                                disabled={this.state.loading}
                                onChange={this.changeEmail}
                                value={this.state.email}
                            />
                        </div>
                        <div class="modal-footer position-relative">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" disabled={this.state.loading}>Close</button>
                            <button type="button" class="btn btn-primary" disabled={this.state.loading} onClick={this.submitNewEmail}>Save</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal fade" id="auditModal" data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">
                                Audit for {this.state.auditUser && <>{this.state.auditUser.firstName} {this.state.auditUser.lastName}</>}
                            </h5>
                        </div>
                        <div class="modal-body">
                            {this.state.audits == null ? 
                            <div className='text-center'>
                                <div class="spinner-border" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                            </div>
                            :<>
                                <table class="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col" style={{width:"200px"}}>Date &amp; Time</th>
                                            <th scope="col" style={{width:"200px"}}>Action</th>
                                            <th scope="col" style={{width:"180px"}}>Actioned By</th>
                                            <th scope="col">Comments</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.audits.map(a=>{

                                            return <tr>
                                                <td>{moment((new Date(a.auditDate))).format('DD/MM/YYYY h:mma')}</td>
                                                <td>{this.convertAuditAction(a.auditAction)}</td>
                                                <td>{a.auditUser?<>{a.auditUser.firstName} {a.auditUser.lastName}</>:<i className='text-muted'>System</i>}</td>
                                                <td>
                                                    {a.comments}
                                                </td>
                                            </tr>
                                        })}
                                    </tbody>
                                </table>
                            </>}
                        </div>
                        <div class="modal-footer position-relative">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" disabled={this.state.loading}>Close</button>
                        </div>
                    </div>
                </div>
            </div>
            
        </>
    }

}

export default ManageUsers;


