import { 
        workApprovalExpression, workApprovalExpressionMessage,
        integer1to6Digits,integer1to6DigitsMessage,
        MESSAGE_REQUIRED,
        isNumber, MESSAGE_REQUIRED_NUMBER,
        MESSAGE_MAX_ALLOWED_CHARCATERS_100,
        MESSAGE_MAX_ALLOWED_CHARCATERS_10
    } from '../../../constants/MeterFormConstants';

export const validate = (values, params) => {

    const errors = {
        approvedWorkDetails: {
            propertyName: "",
            lotDp: "",
            waterSupplyWorkApprovalNo: "",        
            esid: "",
            meterNumber: ""
        }
    }
    let clickSaveDraft = params.clickSaveDraft
    let clickNext = params.clickNext;
    if(clickSaveDraft == undefined)
        clickSaveDraft = values.clickSaveDraft;
    if(clickNext == undefined)
        clickNext = values.clickNext;
    console.log("draft:"+clickSaveDraft);
    if(clickSaveDraft === 1 && clickNext === 0)
    {
        if (values.approvedWorkDetails.propertyName && values.approvedWorkDetails.propertyName.length > 100) {
            errors.approvedWorkDetails.propertyName = MESSAGE_MAX_ALLOWED_CHARCATERS_100
        }

        if (values.approvedWorkDetails.lotDp && values.approvedWorkDetails.lotDp.length > 100) {
            errors.approvedWorkDetails.lotDp = MESSAGE_MAX_ALLOWED_CHARCATERS_100
        }

        if (values.approvedWorkDetails.waterSupplyWorkApprovalNo)
        {
            if (!workApprovalExpression.test(values.approvedWorkDetails.waterSupplyWorkApprovalNo)) {
                errors.approvedWorkDetails.waterSupplyWorkApprovalNo = workApprovalExpressionMessage;
            } else if (values.approvedWorkDetails.waterSupplyWorkApprovalNo.length > 10) {
                errors.approvedWorkDetails.waterSupplyWorkApprovalNo = MESSAGE_MAX_ALLOWED_CHARCATERS_10
            }
        }

        if (values.approvedWorkDetails.esid)
        {
            if (!isNumber(values.approvedWorkDetails.esid)) {
                errors.approvedWorkDetails.esid = MESSAGE_REQUIRED_NUMBER;
            } else if (!integer1to6Digits.test(values.approvedWorkDetails.esid)) {
                errors.approvedWorkDetails.esid = integer1to6DigitsMessage;
            }
        }
        
        if (values.approvedWorkDetails.waterSourceName) {
            if (values.approvedWorkDetails.waterSourceName.length > 100) {
                errors.approvedWorkDetails.waterSourceName = MESSAGE_MAX_ALLOWED_CHARCATERS_100
            }
        }

        if (values.approvedWorkDetails.waterAccessLicenceNo) {
            if (values.approvedWorkDetails.waterAccessLicenceNo.length > 100) {
                errors.approvedWorkDetails.waterAccessLicenceNo = MESSAGE_MAX_ALLOWED_CHARCATERS_100
            }
        }
    }
    else if(clickSaveDraft == 0 && clickNext === 1 && values.meteringEquipmentInformation.formStatus != 2)
    {
        if (!values.approvedWorkDetails.propertyName) {
            errors.approvedWorkDetails.propertyName = MESSAGE_REQUIRED
        } else if (values.approvedWorkDetails.propertyName.length > 100) {
            errors.approvedWorkDetails.propertyName = MESSAGE_MAX_ALLOWED_CHARCATERS_100
        }
        if (!values.approvedWorkDetails.lotDp) {
            errors.approvedWorkDetails.lotDp = MESSAGE_REQUIRED
        } else if (values.approvedWorkDetails.lotDp.length > 100) {
            errors.approvedWorkDetails.lotDp = MESSAGE_MAX_ALLOWED_CHARCATERS_100
        }
    
        if (!values.approvedWorkDetails.waterSupplyWorkApprovalNo) {
            errors.approvedWorkDetails.waterSupplyWorkApprovalNo = MESSAGE_REQUIRED
        } else if (!workApprovalExpression.test(values.approvedWorkDetails.waterSupplyWorkApprovalNo)) {
            errors.approvedWorkDetails.waterSupplyWorkApprovalNo = workApprovalExpressionMessage;
        } else if (!(values.approvedWorkDetails.waterSupplyWorkApprovalNo.length == 10)) {
            errors.approvedWorkDetails.waterSupplyWorkApprovalNo = MESSAGE_MAX_ALLOWED_CHARCATERS_10
        }
    
    
        if (!values.approvedWorkDetails.esid) {
            errors.approvedWorkDetails.esid = MESSAGE_REQUIRED_NUMBER
        } else if (!isNumber(values.approvedWorkDetails.esid)) {
            errors.approvedWorkDetails.esid = MESSAGE_REQUIRED_NUMBER;
        }
        else if (!integer1to6Digits.test(values.approvedWorkDetails.esid)) {
            errors.approvedWorkDetails.esid = integer1to6DigitsMessage;
        }

        if (!values.approvedWorkDetails.meterNumber) {
            errors.approvedWorkDetails.meterNumber = MESSAGE_REQUIRED;
        }

        if (values.approvedWorkDetails.waterSourceName) {
            if (values.approvedWorkDetails.waterSourceName.length > 100) {
                errors.approvedWorkDetails.waterSourceName = MESSAGE_MAX_ALLOWED_CHARCATERS_100
            }
        }

        if (values.approvedWorkDetails.waterAccessLicenceNo) {
            if (values.approvedWorkDetails.waterAccessLicenceNo.length > 100) {
                errors.approvedWorkDetails.waterAccessLicenceNo = MESSAGE_MAX_ALLOWED_CHARCATERS_100
            }
        }
    }

    return errors;
}



