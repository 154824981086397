import React from 'react';
import uiHelpers from '../../../../helpers/uiHelpers';
import { UneditableTextInput } from '../../../../components/tools/BootstrapControls';
import AsyncSelect from 'react-select/async';
import * as apiCaller from '../../../../helpers/apiCaller'
import serviceUrls from '../../../../constants/serviceUrls';
import { nulToEmptyAndTrim } from '../../../../helpers/formatters';

export default class UsersDropDown  extends React.Component {

    constructor(props) {
        super(props);
        this.state = {defaultOptions:[]};
    }

    loadOptions = async (
        inputValue,
        callback
      ) => {
        if(nulToEmptyAndTrim(inputValue)=='') return;
        const data = await apiCaller.post(serviceUrls.others.searchUsers,{keyword:inputValue, filter:this.props.filter});
        
        callback(data.result);
    };

    async componentDidMount() {
        const data = await apiCaller.post(serviceUrls.others.searchUsers,{keyword:null, filter:this.props.filter});
        this.setState({defaultOptions:data.result});
    }

    render() {
        return <AsyncSelect 
            cacheOptions
            loadOptions={this.loadOptions} 
            defaultOptions={this.state.defaultOptions}
            onChange={this.props.onChange}
            getOptionLabel={(o)=><>{o.firstname} {o.lastname} <i className='fs-80 text-muted'>({o.emailAddress})</i></>}
            getOptionValue={(o)=>o.id}
            isDisabled={this.props.isDisabled}
            value={this.props.selectedUser}
        />
    }
}