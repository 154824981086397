import React from 'react';
import moment from 'moment';
import * as apiPathes from '../apiPathes';
import * as serviceCaller from '../serviceCaller'
import DatePicker from 'react-datepicker';
import {ArrowLeft} from '../../components/tools/Icons'
import uiHelpers from '../../helpers/uiHelpers'
import {Info, Trash} from '../../components/tools/Icons'
import {UneditableTextInput} from '../../components/tools/BootstrapControls'
import {convertDateIsoToAusFormat, convertOtherInFlowType,convertDateTimeIsoToAusFormat, convertErrorCode} from '../conveters'
import DeleteOtherInFlowForm from './forms/DeleteOtherInFlowForm'
import AddOtherInflowForm from './forms/AddOtherInflowForm'



class OhterInflowSection  extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            addingOtherInFlow: false,
            displayDeletedOtherInFlow: false
        };
    }
    
    componentDidMount() {
          
    }

    toggleAddOtherInFlowForm = () => {
        if(!this.state.addingOtherInFlow) {
            this.state.addingOtherInFlow = true;
            this.props.setDim(true);
        } else {
            this.state.addingOtherInFlow = false;
            this.props.setDim(false);
        }
    }

    toggleDeleteOtherInFlowForm = (otherInFlow) => {
        if(otherInFlow) {
            this.state.deletingOtherInFlow = otherInFlow;
            this.props.setDim(true);
        } else {
            this.state.deletingOtherInFlow = null;
            this.props.setDim(false);
        }
    }

    isEditingOtherInflow = () => {
        return this.state.deletingOtherInFlow || this.state.displayDeletedOtherInFlow
    }

    onAddOtherInFlowCompleted = (period) =>{
        this.state.addingOtherInFlow = false;
        this.props.updatePeriod(period, false, false);
    }

    onDeleteOtherInFlowCompleted = (period) => {
        this.state.deletingOtherInFlow = null;
        this.props.updatePeriod(period,false,false);
    }


    render(){
        const { dim, disableFunctions, nominatedMeasurementPeriod} = this.props;
        const otherInFlows = nominatedMeasurementPeriod.nominatedMeasurementPeriodInFlows;
        return <div className={`card mb-3 `}>
            <div class={`card-header ${dim?'opacity-25':''}`}>
                Other in-flow during the measurement period
            </div>
            <div class="card-body">
                <div class="form-check form-switch mb-3">

                    <input class="form-check-input" type="checkbox" id="showDeletedInFlowCheck" checked={this.state.displayDeletedOtherInFlow} disabled={disableFunctions} onChange={()=>{this.setState({displayDeletedOtherInFlow: !this.state.displayDeletedOtherInFlow})}}/>
                    <label class="form-check-label" for="showDeletedInFlowCheck">
                        Display previously deleted records
                    </label>
                </div>
                <table class="table align-middle">
                    <thead>
                    <tr className={`${dim && !this.isEditingOtherInflow() ?'opacity-25':''}`}>
                        <th scope="col" style={{"width":"180px"}}>Start date</th>
                        <th scope="col" style={{"width":"180px"}}>End date</th>
                        <th scope="col" style={{"width":"200px"}}>Type of water</th>
                        <th scope="col" style={{"width":"200px"}}>Access licence</th>
                        <th scope="col" style={{"width":"150px"}}>Volume (ML)</th>
                        <th scope="col" style={{ minWidth:"200px"}}></th>
                    </tr>
                    </thead>
                    <tbody>
                        {otherInFlows.filter((o)=>(!this.state.displayDeletedOtherInFlow && !o.isDeleted) || this.state.displayDeletedOtherInFlow).length == 0 && <tr class={`table-light ${dim?'opacity-25':''}`}>
                            <td colSpan={6} className="text-center"><small><em>No other in-flow record</em></small></td>
                        </tr>}
                        {otherInFlows.map((o,index) =>{

                            if(o.isDeleted && !this.state.displayDeletedOtherInFlow) return null;
                            
                            if(this.state.deletingOtherInFlow == o) return <DeleteOtherInFlowForm noLoginCallback={this.props.noLoginCallback} onDeleteCompleted={this.onDeleteOtherInFlowCompleted} nominatedMeasurementPeriod={nominatedMeasurementPeriod} otherInFlow={o} toggleForm={this.toggleDeleteOtherInFlowForm}/>

                            return <tr className={`${dim?'opacity-25':''} ${o.isDeleted ? 'text-decoration-line-through text-black-50' : ''}`}>
                                <td>{convertDateIsoToAusFormat(o.startDate)}</td>
                                <td>{convertDateIsoToAusFormat(o.endDate)}</td>
                                <td>{convertOtherInFlowType(o.inFlowType)}</td>
                                <td>{o.accessLicense}</td>
                                <td>{o.volume}</td>
                                {!(nominatedMeasurementPeriod.status=="OPEN"  || nominatedMeasurementPeriod.status=="CLOSED") && <td></td>}
                                {(nominatedMeasurementPeriod.status=="OPEN"  || nominatedMeasurementPeriod.status=="CLOSED")  && <td className='align-middle text-end'>
                                    {o.isDeleted && <div class="dropstart btn-group">
                                        <button type="button" class="btn btn-outline-secondary btn-sm dropdown-toggle" disabled={disableFunctions} data-bs-toggle="dropdown" aria-expanded="false">
                                            <Info/>
                                        </button>
                                        <ul class="dropdown-menu dropdown-menu-end">
                                            <li className='px-3'>
                                                <small>
                                                    <strong>Deleted on: </strong><br/>
                                                    {convertDateTimeIsoToAusFormat(o.createdDate)}
                                                </small>
                                            </li>
                                        </ul>
                                    </div>}

                                    {!o.isDeleted && <button className='btn btn-sm btn-outline-danger' disabled={disableFunctions} onClick={()=>{this.toggleDeleteOtherInFlowForm(o)}}><Trash/></button>}
                                </td>}
                            </tr>
                        })}

                        {!this.state.addingOtherInFlow && <tr className={`table-secondary ${dim?'opacity-25':''}`}>
                            <td colSpan={4} className="text-end"><strong>Total volume:</strong></td>
                            <td colSpan={2}>
                                <strong className='text-primary'>
                                    {nominatedMeasurementPeriod.totalOtherNonFPHInFlow} ML
                                </strong>
                            </td>
                        </tr>}

                        {this.state.addingOtherInFlow && <AddOtherInflowForm nominatedMeasurementPeriod={nominatedMeasurementPeriod} noLoginCallback={this.props.noLoginCallback} onAddCompleted={this.onAddOtherInFlowCompleted} toggleForm={this.toggleAddOtherInFlowForm} />}
                        {(nominatedMeasurementPeriod.status=="OPEN"  || nominatedMeasurementPeriod.status=="CLOSED") && !this.state.addingOtherInFlow && <tr>
                            <td colSpan={6} className={`text-center ${dim?'opacity-25':''}`}>
                                <button className='btn btn-sm btn-outline-primary' disabled={disableFunctions} onClick={this.toggleAddOtherInFlowForm}>
                                    Add other in-flow record
                                </button>
                            </td>
                        </tr>}
                    </tbody>
                </table>
            </div>
        </div>
    }

}

export default OhterInflowSection;