import React from 'react';
import Select from 'react-select';
import * as bootstrap from 'bootstrap';
import moment from 'moment';
import { InputText, InputTextWithFloatingLabel } from '../../components/tools/BootstrapControls'
import {UneditableTextInput} from '../../components/tools/BootstrapControls'
import * as apiCaller from '../../helpers/apiCaller'
import uiHelpers from '../../helpers/uiHelpers';
import serviceUrls from '../../constants/serviceUrls';
import { isStringNullOrEmpty } from '../../helpers/validators';
import { convertConsentAuditAction, nullToEmpty } from '../../helpers/converters';
import commonConstants from '../../constants/commonConstants'
import { nulToEmptyAndTrim } from '../../helpers/formatters';

const initState = {
    callingService:false,
    approvalNumber:null,
    approvalError: null,
    approval: null,
    consent: null,
    requestConsentType : 'SEND_EMAIL',
    governmentOwn:null,
    selectedHolder: null,
    requestSendToEmail: null,
    requestSendToEmailError:null,
    requestToNoneHolder: null,
    consentGovernmentOwned:null,
    consentPrivateOwned:null
};

class ApprovalConsentProcess  extends React.Component {

    governmentOwnRequestEmail;
    fileUploaderDisplayListUpdate;
    pdfDocument;
    constructor(props) {
        super(props);
        this.state = Object.assign({}, initState);;
    }

    #historyModal;
    async componentDidMount() {
        this.#historyModal = new bootstrap.Modal(document.getElementById("historyModal"));
    }   

    componentWillUnmount() {
        if(this.#historyModal) this.#historyModal.dispose();
    }

    openHistoryModal = () => {
        this.#historyModal.show();
    }

    onApprovalNumberChange = (event) => {
        
        this.setState(Object.assign(Object.assign({},initState), {approvalNumber:nullToEmpty(event.target.value).trim().toUpperCase()}));
    }

    fileUploaderFilesUploaded = (files) => {
        this.pdfDocument = files[0];
        this.fileUploaderDisplayListUpdate([this.pdfDocument]);
    }

    onApprovalSearchClick = async () => {
        uiHelpers.showProgressOnTop('Searching approval');
        this.setState(Object.assign(Object.assign({},initState), {approvalNumber:this.state.approvalNumber, callingService:true}));

        await apiCaller.post(serviceUrls.consent.getByApproval,{approvalNumber: this.state.approvalNumber}).then((result)=>{

            this.governmentOwnRequestEmail = result.govenmentOwnRequestEmail;
            this.setState(Object.assign(this.state,
                {
                    callingService:false, 
                    approval: result.approval, 
                    
                    approvalError: (
                        result.approval == null?'Approval cannot be found' 
                        : !(result.approval.groupCode=="AP" || (result.approval.groupCode=="MS" && result.approval.licenceTypeCode=='MW')) ? 'This may not be a valid approval. Please check if there is a linked license/approval to search in the portal.' 
                        : !(result.approval.statusCode=="CU" || result.approval.statusCode=="EX") ? 'Approval is not in valid status' 
                        :null
                    ),
                    consentGovernmentOwned:result.consentGovernmentOwned,
                    consentPrivateOwned:result.consentPrivateOwned,
                    selectedHolder:null,
                    consent: null, 
                    requestSendToEmail:''
                }
            ));
            
        }).finally(()=>{
            this.setState(Object.assign(this.state,{callingService:false}));
            uiHelpers.hideProgressOnTop();
        });
    }

    onHolderSelectChange = (option) => {
        this.setState(Object.assign(this.state,{selectedHolder :  option, requestSendToEmail: nullToEmpty(option.email)}),()=>{
            if(isStringNullOrEmpty(this.state.requestSendToEmail)) {
                if(this.state.requestToNoneHolder){
                    this.setState({requestSendToEmailError:'Email address is required.'})
                } else {
                    this.setState({requestSendToEmailError:'Email address is required. Please ask the customer to contact WaterNSW at 1300 662 077 to update their email address.'})
                }
            }
        });
    }

    sendEmailRequest = async () => {
        var err = false;
        if(isStringNullOrEmpty(this.state.requestSendToEmail)) {
            if(this.state.requestToNoneHolder){
                this.setState({requestSendToEmailError:'Email address is required.'})
            } else {
                this.setState({requestSendToEmailError:'Email address is required. Please ask the customer to contact WaterNSW at 1300 662 077 to update their email address.'})
            }
            err = true;
        } else if(!commonConstants.regex.EMAIL.test(nulToEmptyAndTrim(this.state.requestSendToEmail))) {
            this.setState({requestSendToEmailError:'Email address is invalid.'})
            err = true;
        } else {
            this.setState({requestSendToEmailError:null})
        }
        if(err) return;

        const serviceUrl = this.state.consent?serviceUrls.consent.resendConsentByEmail:serviceUrls.consent.requestConsentByEmail;
        const data = this.state.consent?{
            consentId: this.state.consent.id, 
            dlwcInpaAddrId:this.state.selectedHolder?this.state.selectedHolder.dlwcInpaAddrId:null,
            email:this.state.requestSendToEmail,
            requestToNoneHolder:this.state.requestToNoneHolder,
            governmentOwn:this.state.governmentOwn
        }:{
            approvalNumber: this.state.approvalNumber, 
            dlwcInpaAddrId:this.state.selectedHolder?this.state.selectedHolder.dlwcInpaAddrId:null,
            email:this.state.requestSendToEmail,
            requestToNoneHolder:this.state.requestToNoneHolder,
            governmentOwn:this.state.governmentOwn
        };

        uiHelpers.showProgressOnTop('Sending request');
        this.setState(Object.assign(this.state,{callingService:true}));
        await apiCaller.post(serviceUrl,data)
        .then((result)=>{
            this.setState(Object.assign(this.state,{
                consent:result.consent,  
                requestSendToEmail:null,
                selectedHolder:null, 
                governmentOwn:result.consent.governmentOwn,
                requestSendToEmail:result.consent.governmentOwn?this.governmentOwnRequestEmail:null
            }));
            uiHelpers.showSuccessMsgTop("Request sent",true,null);
        }).finally(()=>{
            this.setState(Object.assign(this.state,{callingService:false}));
            uiHelpers.hideProgressOnTop();
        });
    }

    renderHistoryModal =() =>{
        return <div class="modal fade" id="historyModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
            <div class="modal-dialog modal-xl modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">History of Authorisation request</h5>
                    </div>
                    <div class="modal-body">

                        <table className='table'>
                            <thead>
                                <tr>
                                    <th>
                                        Date
                                    </th>
                                    <th>
                                        Action
                                    </th>
                                    <th>
                                        User
                                    </th>
                                    <th>
                                        Request sent to
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.consent && this.state.consent.audits.map((a)=>{
                                    return <tr>
                                        <td>
                                        {moment(a.auditDate).format('DD/MM/YYYY h:mma')}
                                        </td>
                                        <td>
                                            {convertConsentAuditAction(a.auditAction)}
                                        </td>
                                        <td>
                                            {a.firstName} {a.lastName}
                                        </td>
                                        <td>
                                            {a.sentToEmail}
                                        </td>
                                    </tr>
                                })}
                            </tbody>
                        </table>
                        
                        
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
    }

    renderSendEmail =(label, alwaysDisabled) => {
        return <div className='row mb-3'>
            <div className='col-md-6'>
                <InputText
                    className="mb-3"
                    label={label}
                    disabled={this.state.callingService || alwaysDisabled}
                    value={this.state.requestSendToEmail}
                    error={this.state.requestSendToEmailError}
                    onChange={(e)=>this.setState({requestSendToEmail:e.target.value})}
                />
                {!this.state.consent && <button type="button" class="btn btn-primary" disabled={this.state.callingService} onClick={this.sendEmailRequest}>
                    Send request
                </button>}
                {this.state.consent && <button type="button" class="btn btn-success" disabled={this.state.callingService} onClick={this.sendEmailRequest}>
                    {this.state.consent.approvalType=="EMAIL" ? "Resend request":"Send request"}
                </button>}
            </div>
        </div>
    }

    renderHolderPhone = (holder) => {
        if(!holder) return null;
        if(!holder.phoneNumbers) return null;
        if(holder.phoneNumbers==0) return;

        let firstPhone = holder.phoneNumbers[0];
        let mobile;
        let phone;
        let business;
        let phoneToDisplay = firstPhone;
        for(var i=0; i<holder.phoneNumbers.length;i++){
            if(holder.phoneNumbers[i].serviceType=='MOBILE'){
                mobile=holder.phoneNumbers[i];
            } else if(holder.phoneNumbers[i].serviceType=='PHONE'){
                phone=holder.phoneNumbers[i];
            } else if(holder.phoneNumbers[i].serviceType=='BUSINESS'){
                business=holder.phoneNumbers[i];
            }
        }

        if(holder.inpaType =='Person') {

            if(mobile) phoneToDisplay= mobile;
            else if(phone) phoneToDisplay= phone;
        } else {
            if(business) phoneToDisplay= business;
            else if(phone) phoneToDisplay= phone;
        }

        return <>
            <div className='row '>
                <div className='col-md-6 mb-3'>
                    <UneditableTextInput
                        fieldExtraClass="bg-light"
                        label="Phone Number"
                        value={<>{phoneToDisplay.prefix} {phoneToDisplay.phoneNumber}</>}
                    />
                </div>
            </div>
        </>
    }

    onRequestToNoneHolderChanged = (value) => {
        this.setState({
            requestToNoneHolder:value,
            selectedHolder:null,
            requestSendToEmail:null,
            requestSendToEmailError:null
        });
    }

    onChangeGovernmentOwn = (value) =>{
        let email = value?this.governmentOwnRequestEmail:'';
        if(value) {
            this.setState({
                governmentOwn:value,
                consent:this.state.consentGovernmentOwned,
                selectedHolder: null,
                requestSendToEmail: email,
                requestSendToEmailError:null,
            });
        } else {
            this.setState({
                governmentOwn:value,
                consent:this.state.consentPrivateOwned,
                selectedHolder: null,
                requestSendToEmail: email,
                requestSendToEmailError:null,
            });
        }
    }

    onContinueClick=()=>{
        this.props.approvalFound(this.state.approval, this.state.consent)
    }

    renderRequestOptions() {
        return  <>             

                    {this.state.consent && this.state.consent.reachResendLimit && <div className='row'>
                        <div className='col-md-8'>
                            <div className='alert alert-warning'>
                                You have reached your limit of re-sending Customer Authorisation request. Please contact system administrator for resolution.
                            </div>
                        </div>
                    </div>}

                    {(!this.state.consent || !this.state.consent.reachResendLimit) && this.state.requestConsentType && <>

                        {(this.state.requestToNoneHolder==null || !this.state.requestToNoneHolder) && this.state.governmentOwn===false && <>
                            <div className='row mb-3'>
                                <div className='col-md-6'>
                                    <label class="form-label">
                                        Holder to Send Authorisation Request
                                        <span class="text-danger">*</span>
                                    </label>
                                    <Select
                                        isDisabled={this.state.callingService}
                                        getOptionLabel={(o)=>{return o.name}}
                                        getOptionValue={(o)=>{return o.dlwcInpaAddrId}}
                                        options={this.state.approval.holders}
                                        onChange={this.onHolderSelectChange}
                                        value={this.state.approval.holders.filter(option => this.state.selectedHolder?option.dlwcInpaAddrId === this.state.selectedHolder.dlwcInpaAddrId:false)}
                                    />
                                </div>
                            </div>
                        </>}
                    </>}


                    {(!this.state.consent || !this.state.consent.reachResendLimit) && this.state.governmentOwn && this.renderSendEmail(<>Request to Email <span className='text-danger'>*</span></>, true)}

                    {(!this.state.consent || !this.state.consent.reachResendLimit) && this.state.requestToNoneHolder && this.renderSendEmail(<>Request to Email <span className='text-danger'>*</span></>, false)}
 

                    {(!this.state.consent || !this.state.consent.reachResendLimit) && (this.state.requestToNoneHolder==null || !this.state.requestToNoneHolder)&& this.state.selectedHolder && <>
                        <div className='row '>
                            <div className='col-md-6 mb-3'>
                                <UneditableTextInput
                                    fieldExtraClass="bg-light"
                                    label="Holder Type"
                                    value={this.state.selectedHolder.inpaType}
                                />
                            </div>
                        </div>
                        {this.renderHolderPhone(this.state.selectedHolder)}

                        {this.state.requestConsentType == "SEND_EMAIL" && this.renderSendEmail(<>Approval Holder Email <span className='text-danger'>*</span></>, true)}
                    </>}
                </>
    }

    renderConsentFormWithApproval() {
        return <div className='card card-body '>
            <h5 class="card-title">Authorisation request for {this.state.approval.approvalNumber} <span className='fs-80 fw-light text-primary'>({this.state.consent.governmentOwn? "for Government-owned meters" : "for Private-owned meters"})</span></h5>

            <p>
                <button className='btn btn-outline-secondary btn-sm' onClick={this.openHistoryModal}>
                    Audit of this authorisation
                </button>
            </p>
            {this.state.consent.status !='EXPIRED' && <>
                <div className='row '>
                    <div className='col-md-3 mb-3'>
                        <UneditableTextInput
                            fieldExtraClass="bg-light"
                            label={<>Request type</>}
                            value={this.state.consent.approvalType=="EMAIL"?"Email":"PDF Signature"}
                        />
                    </div>
                    <div className='col-md-3 mb-3'>
                        <UneditableTextInput
                            fieldExtraClass="bg-light"
                            label={<>Request created</>}
                            value={moment(this.state.consent.requestedDate).format('DD/MM/YYYY h:mma')}
                        />
                    </div>
                </div>

                <div className='row '>
                    <div className='col-md-6 mb-3'>
                        <UneditableTextInput
                            fieldExtraClass="bg-light"
                            label={<>Request status</>}
                            value={
                                this.state.consent.status =='PENDING_APPROVAL' ? 
                                <span className='text-warning'>Pending approval</span>
                                :
                                this.state.consent.status =='REJECTED' ? 
                                <span className='text-danger'>Consent not Authorised</span>
                                :
                                this.state.consent.status =='APPROVED' ? 
                                <span className='text-success'>Approved</span>
                                :
                                this.state.consent.status =='EXPIRED' ? 
                                <span className='text-danger'>Expired</span>
                                :
                                null
                            }
                        />
                    </div>
                </div>
            </>}
            {this.state.consent.approvalType=="EMAIL" && <>
                {this.state.consent.status !='EXPIRED' && <>
                    {!this.state.consent.requestToNoneHolder && !this.state.consent.governmentOwn && <>
                        <div className='row '>
                            <div className='col-md-6 mb-3'>
                                <UneditableTextInput
                                    fieldExtraClass="bg-light"
                                    label={<>Request sent to holder</>}
                                    value={this.state.consent.selectedHolder ? this.state.consent.selectedHolder.name:null}
                                />
                            </div>
                        </div>
                        <div className='row '>
                            <div className='col-md-6 mb-3'>
                                <UneditableTextInput
                                    fieldExtraClass="bg-light"
                                    label="Holder Type"
                                    value={this.state.consent.selectedHolder ? this.state.consent.selectedHolder.inpaType:null}
                                />
                            </div>
                        </div>
                    </>}

                    {this.renderHolderPhone(this.state.consent.selectedHolder)}

                    <div className='row mb-3'>
                        <div className='col-md-6'>
                            <UneditableTextInput
                                fieldExtraClass="bg-light"
                                label={<>Request sent to email</>}
                                value={this.state.consent.requestedEmail}
                            />
                        </div>
                    </div>
                </>}

                {(this.state.consent.status =='PENDING_APPROVAL' || this.state.consent.status =='REJECTED' || this.state.consent.status =='EXPIRED') && <>
                    
                    {this.state.consent.status =='PENDING_APPROVAL' ? 
                    <>
                        {this.state.consent.governmentOwn ? 
                        <p className='text-primary'>Please wait for the holder to approve your authorisation request. Alternatively you can resend your authorisation request.</p> 
                        :
                        <p className='text-primary'>Please wait for the holder to approve your authorisation request. Alternatively select one of the option below to resend your authorisation request.</p> 
                        }
                    </>:
                    this.state.consent.status =='REJECTED' ? <p className='text-danger'>Consent to your previous request has not been granted. Please select one of the option below to resend your authorisation request.</p>
                    :<p className='text-danger'>The Authorisation period for this Work Approval has expired. Please select one of the option below to resend your authorisation request.</p>
                    }
                    {this.renderRequestOptions()}
                </>}
            </>}

            {this.state.consent.approvalType=="PDF" && <>
                <div className='row mb-3'>
                    <div className='col-md-6'>
                        <div className='alert alert-warning'>This request type is no longer supported. Please select one of the option below to resend your authorisation request.</div>
                    </div>
                </div>
                {this.renderRequestOptions()}
            </>}
        </div>
    }


    render() {
        return <>
            <div class="row mb-2">
                <div class="col-md-6">
                    <InputTextWithFloatingLabel
                        className="mb-3"
                        value={this.state.approvalNumber}
                        disabled={this.state.callingService}
                        onChange={this.onApprovalNumberChange}
                        error={this.state.approvalError}
                        label={<>Work approval number <span className='text-danger'>*</span></>}
                    />
                    <p>
                        <button type="button" class="btn btn-primary" disabled={this.state.callingService} onClick={this.onApprovalSearchClick}>
                            Search
                        </button>
                    </p>
                </div>
            </div>

            {this.state.approval && <>
                <div class="row mb-4">
                    <div class="col-md-8">
                        <div class="list-group">
                            <label class="list-group-item">
                                <input class="form-check-input me-2" type="radio" value="" name="governmentOwn" checked={this.state.governmentOwn===false} onChange={()=>this.onChangeGovernmentOwn(false)}/>
                                Work to commence on Private-owned Meter
                                {this.state.consentPrivateOwned == null ? <i className='text-muted fs-80 ms-2'>(No authorisation)</i>
                                : this.state.consentPrivateOwned.status == 'PENDING_APPROVAL' ? <i className='text-warning fs-80 ms-2'>(Authorisation pending approval)</i>
                                : this.state.consentPrivateOwned.status == 'APPROVED' ? <i className='text-success fs-80 ms-2'>(Authorised)</i>
                                : this.state.consentPrivateOwned.status == 'EXPIRED' ? <i className='text-danger fs-80 ms-2'>(Authorisation expired)</i>
                                : <i className='text-danger fs-80 ms-2'>(Authorisation request rejected)</i>
                                }
                            </label>
                            <label class="list-group-item">
                                <input class="form-check-input me-2" type="radio" value="" name="governmentOwn" checked={this.state.governmentOwn===true} onChange={()=>this.onChangeGovernmentOwn(true)}/>
                                Work to commence on Government-owned Meter
                                {this.state.consentGovernmentOwned == null ? <i className='text-muted fs-80 ms-2'>(No authorisation)</i>
                                : this.state.consentGovernmentOwned.status == 'PENDING_APPROVAL' ? <i className='text-warning fs-80 ms-2'>(Authorisation pending approval)</i>
                                : this.state.consentGovernmentOwned.status == 'APPROVED' ? <i className='text-success fs-80 ms-2'>(Authorised)</i>
                                : this.state.consentGovernmentOwned.status == 'EXPIRED' ? <i className='text-danger fs-80 ms-2'>(Authorisation expired)</i>
                                : <i className='text-danger fs-80 ms-2'>(Authorisation request rejected)</i>
                                }
                            </label>
                        </div>
                    </div>
                </div>
            </>}

            {this.state.approval && !this.state.approvalError && this.state.governmentOwn!=null && this.state.consent == null && <>
                <div className='card card-body'>
                    <h5 class="card-title mb-3">Request Authorisation <span className='fs-80 fw-light text-primary'>({this.state.governmentOwn? "for Government-owned meters" : "for Private-owned meters"})</span></h5>

                    <div className='row'>
                        <div className='col-md-6'>
                            <p className='alert alert-primary'>
                                You do not have authorisation to work on <b>{this.state.governmentOwn? "Government-owned" : "Private-owned"}</b> meters yet, please follow the options below to send your authorisation request.
                            </p>
                        </div>
                    </div>
                    

                    {this.renderRequestOptions()}
                </div>
            </>}

            {this.state.consent && this.state.consent.status!="APPROVED" && this.renderConsentFormWithApproval()}

            {this.state.consent && this.state.consent.status=="APPROVED" && <div className='card card-body'>
                <h5 class="card-title text-success">You have authorisation for {this.state.approval.approvalNumber} <i className='fs-80'>({this.state.governmentOwn?"for Government-owned":"for Private-owned"} meters)</i></h5>

                <div className='row '>
                    <div className='col-md-4 mb-3'>
                        <UneditableTextInput
                            fieldExtraClass="bg-light"
                            label={<>Authorisation approved on</>}
                            value={moment(this.state.consent.approvedDate).format('DD/MM/YYYY h:mma')}
                        />
                    </div>
                    <div className='col-md-4 mb-3'>
                        <UneditableTextInput
                            fieldExtraClass="bg-light"
                            label={<>Authorisation expiring on</>}
                            value={moment(this.state.consent.expiryDate).format('DD/MM/YYYY h:mma')}
                        />
                    </div>
                </div>
                <div className='row '>
                    <div className='col-md-8 text-end'>
                        <button className='btn btn-primary' onClick={this.onContinueClick}>Continue</button>
                    </div>
                </div>
            </div>}

            {this.renderHistoryModal()}
            
        </>
    }
}

export default ApprovalConsentProcess;