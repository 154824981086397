import React, { useContext,useEffect } from 'react';
import { GlobalContext } from './State';
import { GlobalProvider } from './State';
import { Master } from './Master';
import BasePageLayout, {menuItems, MenuConnector, SetCurrentMenu, AuthenticationRequired, AuthenticationSwitch} from '../layout/base/BasePageLayout'
import ReportingPortalPageLayout from '../layout/base/ReportingPortalPageLayout'

export const Home2 = () => {  
    const menuConnector = new MenuConnector();
    return (
    <GlobalProvider>
        <ReportingPortalPageLayout menuConnector={menuConnector}><Master/></ReportingPortalPageLayout>
    </GlobalProvider>)    
}