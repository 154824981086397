import React from 'react';
import { reduxForm, formValueSelector , change} from 'redux-form';
import { connect } from 'react-redux'
import 'react-redux';
import 'redux';
import { validate } from '../common/AuthorityHolderDetailsFormSection.Validation'
import {  saveDraftAction, clickNextAction} from "../common/MeterFormValidations"

let MeterDesignCertificateConfirmation = props => {
    const {
        masterDataValues
        ,meterEquipInfo
        ,authHolderInfo
        ,appWorkInfo
        , waterAccessLicenceNoExternalList
        , documents
        , cancelConfrimSubmissionAndGoToPage
        , cancelConfrimSubmission

        , handleSubmit

        ,loading
        ,hasError
    } = props;

  return (
    <form className="vertical" onSubmit={handleSubmit}>
        <h5 className='mb-3'>Review</h5>
        <div class="alert alert-primary" role="alert">
            Please review the information below before completing your submission.
            <br/>
            The form cannot be edited once submitted. Therefore, please confirm all configuration details are correct before submitting this form.
        </div>

        <div className='card mb-3'>
            <div className='card-header fw-bold'>Authority Holder Details</div>
            <div className='card-body'>
                <table class="table fs-90 mb-3">
                    <tbody>
                        {(authHolderInfo.firstName != null && authHolderInfo.lastName != null && authHolderInfo.firstName != '' && authHolderInfo.lastName != '') &&
                        <tr>
                            <th className='w-25'>
                            Authority holder name(s)
                            </th>
                            <td>{authHolderInfo.authorityHolderName}</td>
                        </tr>
                        }
                        <tr>
                            <th className='w-25'>
                            Corporation name and ACN
                            </th>
                            <td>{authHolderInfo.corporationNameOrAcn}</td>
                        </tr>
                        <tr>
                            <th>
                            Postal address
                            </th>
                            <td>{authHolderInfo.postalAddress}</td>
                        </tr>
                        <tr>
                            <th>
                            Phone
                            </th>
                            <td>{authHolderInfo.phone}</td>
                        </tr>
                        <tr>
                            <th>
                            Alternate phone
                            </th>
                            <td>{authHolderInfo.alternatePhone}</td>
                        </tr>
                        <tr>
                            <th>
                            Email address
                            </th>
                            <td>{authHolderInfo.emailAddress}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className='card-header fw-bold'>Contact Person or Agent</div>
            <div className='card-body'>
                <table class="table fs-90 mb-3">
                    <tbody>
                        <tr>
                            <th className='w-25'>
                            Name
                            </th>
                            <td>{authHolderInfo.contactPersonName}</td>
                        </tr>
                        <tr>
                            <th>
                            Phone
                            </th>
                            <td>{authHolderInfo.contactPersonPhone}</td>
                        </tr>
                        <tr>
                            <th>
                            Email address
                            </th>
                            <td>{authHolderInfo.contactPersonEmailAddress}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className='card-footer'>
                <button type="button" className="btn btn-primary" 
                    onClick={() => { cancelConfrimSubmissionAndGoToPage(1); }}>
                        Edit
                </button>
            </div>
        </div>

        <div className='card mb-3'>
            <div className='card-header fw-bold'>Details of Approved Works</div>
            <div className='card-body'>
                <table class="table fs-90 mb-3">
                    <tbody>
                        <tr>
                            <th className='w-25'>
                            Property name
                            </th>
                            <td>{appWorkInfo.propertyName}</td>
                        </tr>
                        <tr>
                            <th>
                            Lot DP
                            </th>
                            <td>{appWorkInfo.lotDp}</td>
                        </tr>
                        <tr>
                            <th>
                            Work approval number
                            </th>
                            <td>{appWorkInfo.waterSupplyWorkApprovalNo}</td>
                        </tr>
                        {
                            (appWorkInfo.approvedByCustomer == 0) || (appWorkInfo.approvedByCustomer == 1 && waterAccessLicenceNoExternalList.length == 0) &&
                            <tr>
                                <th>
                                Water access license number(s)
                                </th>
                                <td>{appWorkInfo.waterAccessLicenceNo}</td>
                            </tr>
                        }
                        {
                            appWorkInfo.approvedByCustomer == 1 && waterAccessLicenceNoExternalList.length > 0 &&
                            <tr>
                                <th>
                                Water access license number(s)
                                </th>
                                <td>{appWorkInfo.waterAccessLicenceNo}</td>
                            </tr>
                        }
                        <tr>
                            <th>
                            Unique extraction site identifier (ESID)
                            </th>
                            <td>{appWorkInfo.esid}</td>
                        </tr>
                        <tr>
                            <th>
                            Name of water source
                            </th>
                            <td>{appWorkInfo.waterSourceName}</td>
                        </tr>
                        <tr>
                            <th>
                            Name of water sharing plan
                            </th>
                            <td>{appWorkInfo.waterSharingPlanName}</td>
                        </tr>
                        <tr>
                            <th>
                            Type of water source
                            </th>
                            <td>
                                {masterDataValues.waterSourceTypes &&  masterDataValues.waterSourceTypes.map((ws) => {
                                    if(ws.id == appWorkInfo.waterSourceTypeId) {
                                        return ws.name;
                                    }
                                })}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className='card-footer'>
                <button type="button" className="btn btn-primary" 
                    onClick={() => { cancelConfrimSubmissionAndGoToPage(2); }}>
                        Edit
                </button>   
            </div>
        </div>
        <div className='card mb-3'>
            <div className='card-header fw-bold'>Design of metering equipment information</div>
            <div className='card-body'>
                <table class="table fs-90 mb-3">
                    <tbody>
                        <tr>
                            <th className='w-25'>
                            Channel type
                            </th>
                            <td>{meterEquipInfo.channelType}</td>
                        </tr>
                        <tr>
                            <th>
                            Channel width (m)
                            </th>
                            <td>{meterEquipInfo.channelWidth}</td>
                        </tr>
                        <tr>
                            <th>
                            Distance of the meter from pumping source or filling point (m)
                            </th>
                            <td>{meterEquipInfo.meterDistanceFromSource}</td>
                        </tr>
                        <tr>
                            <th>
                            Channel length upstream of meter (m)
                            </th>
                            <td>{meterEquipInfo.channelLengthUpstream}</td>
                        </tr>
                        <tr>
                            <th>
                            Channel length downstream of meter (m)
                            </th>
                            <td>{meterEquipInfo.channelLlengthDownstream}</td>
                        </tr>
                        <tr>
                            <th>
                            Process used to check the design
                            </th>
                            <td>{meterEquipInfo.processUsedToCheckDesign}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className='card-footer'>
                <button type="button" className="btn btn-primary" 
                    onClick={() => { cancelConfrimSubmissionAndGoToPage(3); }}>
                        Edit
                </button> 
            </div>
        </div>
        
        {documents.filter(function (option) { return ((option.status === "new" || option.status === "existing") && option.documentType == 2) }).length > 0 && <>
            <h5>Supporting documentation checklist - Engineered design plan for the open channel metering equipment</h5>
            <p>
                <button type="button" className="btn btn-primary" 
                    onClick={() => { cancelConfrimSubmissionAndGoToPage(4); }}>
                        Edit
                </button>
            </p>
            <table class="table table-sm fs-90" >
                <thead>
                    <tr>
                        <th scope="row" style={{width:'40%'}}>Description</th>
                        <th>FileName</th>
                    </tr>
                </thead>
                <tbody>
                    {documents.map((option, index) => (
                        ((option.status === "new" || option.status === "existing") && option.documentType == 2) &&
                        <tr>
                            <td>{option.description}</td>
                            <td>{option.fileOriginalName}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </>}
        {documents.filter(function (option) { return ((option.status === "new" || option.status === "existing") && option.documentType == 1) }).length > 0 && <>
            <h5>Supporting documentation checklist - Others</h5>
            <p>
                <button type="button" className="btn btn-primary" 
                    onClick={() => { cancelConfrimSubmissionAndGoToPage(4); }}>
                        Edit
                </button>
            </p>
            <table class="table table-sm fs-90" >
                <tr>
                    <th scope="row" style={{width:'40%'}}>Description</th>
                    <th>FileName</th>
                </tr>
                {documents.map((option, index) => (
                    ((option.status === "new" || option.status === "existing") && option.documentType == 1) &&
                    <tr>
                        <td>{option.description}</td>
                        <td>{option.fileOriginalName}</td>
                    </tr>
                ))}
            </table>
        </>}

        {hasError && <div className='alert alert-danger'>
            Please fix all errors before you continue
        </div>}
        
        <p class="text-end mt-4">
            
            {(parseInt(meterEquipInfo.formStatus) == 1) && (!props.disableEditing) && <>
                <button type="button"  className="btn btn-danger me-3" disabled={loading} 
                    data-bs-toggle="modal" data-bs-target="#deleteDraftModal">
                    Delete Draft
                </button>
                <button type="button"  className="btn btn-primary" disabled={loading} 
                    onClick={() => { 
                        saveDraftAction(props); 
                    }}>
                    Save Draft
                </button>
                <div className='vr mx-3' />
            </>}

            <button type="button" className="btn btn-warning me-3" 
                disabled={loading} 
               onClick={()=> {
                    cancelConfrimSubmission();
               }}>
                  Previous
            </button>

            <button type="button" className="btn btn-success"
                disabled={loading} 
               onClick={() => { clickNextAction(props); }}>
                  {(parseInt(meterEquipInfo.formStatus) == 1) ? "Confirm" : "Next"}
            </button>
        </p>


    </form>
  )
}

MeterDesignCertificateConfirmation = reduxForm({
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate,
    onSubmitFail: (errors, dispatch ) => {
        dispatch(change('certificate', 'hasError', true));
    },
    onSubmitSuccess:(result , dispatch )=>{
    dispatch(change('certificate', 'hasError', false));
    window.scrollTo(0, 0);
    }

 })(MeterDesignCertificateConfirmation)

MeterDesignCertificateConfirmation = connect(
  (state, props) => {
    const selector = formValueSelector(props.form);
    const masterDataValues = selector(state, 'mdata');
    const authHolderInfo = selector(state, 'authorityHolderDetails');
    const meterEquipInfo = selector(state, 'meteringEquipmentInformation');
    const appWorkInfo = selector(state, 'approvedWorkDetails');
    const meterNumberExternalList = selector(state, 'meterNumberExternalList');
    const esidExternalList = selector(state, 'esidExternalList');
    const waterAccessLicenceNoExternalList = selector(state, 'waterAccessLicenceNoExternalList');
    const documents = selector(state, 'documents');
    const loading = selector(state, 'loading')
    
    const selectorCert = formValueSelector('certificate')
    const hasError = selectorCert(state, 'hasError')
    
    return {
      masterDataValues,
      authHolderInfo,
      meterEquipInfo,
      appWorkInfo,
      meterNumberExternalList,
      esidExternalList,
      waterAccessLicenceNoExternalList,
      documents,
      form: props.form,
      loading,
      hasError
    }
  }
)(MeterDesignCertificateConfirmation)

export default MeterDesignCertificateConfirmation;
