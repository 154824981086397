import axios from 'axios';

class RegistrationApiHelpers {

    constructor(token) {
        this.config = {
            baseURL: process.env.REACT_APP_WEBAPI_URL,
            headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }
        };
    }
    

    getInstallationTypeOptions =(formType, storageMeasurementMethodId) =>{
        return axios.get('installationtype', this.config).then(response => {
            var installationTypes = [];
            for (var i = 0; i < response.data.data.length; i++) {
                if(formType=="NON_URBAN" || storageMeasurementMethodId=='POINT_OF_INTAKE'){
                    installationTypes.push({ label: response.data.data[i].name, value: response.data.data[i].id });
                } else if(response.data.data[i].typeKey=='N_M_N_L'){
                    installationTypes.push({ label: response.data.data[i].nameForFPH, value: response.data.data[i].id });
                }
            }
            return installationTypes;
        });
    }

    getMeterSystemTypeOptions =(formType, storageMeasurementMethodId) =>{
        if(formType=="NON_URBAN" ){
            return axios.get('metertype/meterTypesForNonUrban', this.config).then(response => {
                var manufacturerModels = [];
                for (var i = 0; i < response.data.data.length; i++) {
                   manufacturerModels.push({ label: response.data.data[i].name, value: response.data.data[i].id });
                }
                return manufacturerModels;
            });
        } else if (storageMeasurementMethodId=='POINT_OF_INTAKE'){
            return axios.get('metertype/meterTypesForFPHPointOfIntake', this.config).then(response => {
                var manufacturerModels = [];
                for (var i = 0; i < response.data.data.length; i++) {
                   manufacturerModels.push({ label: response.data.data[i].name, value: response.data.data[i].id });
                }
                return manufacturerModels;
            });
        } else {
            return axios.get('metertype/meterTypesForStorageMeasurement', this.config).then(response => {
                var manufacturerModels = [];
                for (var i = 0; i < response.data.data.length; i++) {
                   manufacturerModels.push({ label: response.data.data[i].name, value: response.data.data[i].id });
                }
                return manufacturerModels;
            });
        }
    }


    getMeterUnitOfMeasureOptions = () => {
        return axios.get('meterUnitOfMeasures', this.config).then(response => {
            var meterUnitOfMeasures =[];
            for (var i = 0; i < response.data.data.length; i++) {
               meterUnitOfMeasures.push({ label: response.data.data[i].name, value: response.data.data[i].id });
            }
            return meterUnitOfMeasures;
        });
    }

    

    getTelemetryTypeOptions = ()=>{
        return axios.get('telemetrytype', this.config).then(response => {
            var telemetryTypes = [];
            for (var i = 0; i < response.data.data.length; i++) {
            telemetryTypes.push({ label: response.data.data[i].name, value: response.data.data[i].id });
            }
            return telemetryTypes;
        });
    }

    getMeterFlowUnitOptions = () => {
        return axios.get('meterflowunits', this.config).then(response => {
            var meterflowUnits=[];
            for (var i = 0; i < response.data.data.length; i++) {
               meterflowUnits.push({ label: response.data.data[i].name, value: response.data.data[i].id });
            }
            return meterflowUnits;
        });
    }

    getBillingPeriodOptions = () => {
        return axios.get('billingperiod', this.config).then(response => {
            var billingPeriods = [];
            for (var i = 0; i < response.data.data.length; i++) {
               billingPeriods.push({ label: response.data.data[i].name, value: response.data.data[i].id });
            }
            return billingPeriods;
        });
    }

    getBillingFlowUnitOptions = () => {
        return axios.get('billingflowunits', this.config).then(response => {
            var billingFlowUnits = [];
            for (var i = 0; i < response.data.data.length; i++) {
               billingFlowUnits.push({ label: response.data.data[i].name, value: response.data.data[i].id });
            }
            return billingFlowUnits;
        });
    }

    getMeterTypeOptions = () =>{
        return axios.get('meter', this.config).then(response => {
            var meterTypes=[];
            for (var i = 0; i < response.data.data.length; i++) {
               meterTypes.push({ label: response.data.data[i].name, value: response.data.data[i].id });
            }
            return meterTypes;
        });
    }


    getStorageDepthSensorTypeOptions = () => {
        return axios.get('storageDepthSensorType', this.config).then(response => {
            var storageDepthSensorTypes = [];
            for (var i = 0; i < response.data.data.length; i++) {
               storageDepthSensorTypes.push({ label: response.data.data[i].name, value: response.data.data[i].id });
            }
            return storageDepthSensorTypes;
        });
    }

    getStorageMeasurementMethodOptions =() =>{
        return axios.get('storageMeasurementMethod', this.config).then(response => {
            var storageMeasurementMethods =[];
            for (var i = 0; i < response.data.data.length; i++) {
               storageMeasurementMethods.push({ label: response.data.data[i].name, value: response.data.data[i].id });
            }
            return storageMeasurementMethods;
        });
    }

    getLidOptions = (formType, storageMeasurementMethodId) => {

        //alert(formType);
        //alert(storageMeasurementMethodId);

        if(formType=="NON_URBAN" ){
            return axios.get('lid/getNonUrbanLids', this.config).then(response => {
                let lidData = response.data.data.sort((a,b) => a.displayText > b.displayText?1:-1 ) ;
                var lids = [];
                lidData.map(row => {
                   lids.push({ label: row.displayText, value: row.id , name: row.name, active: row.active});
                });          
                return lids;
            });
        } else if (storageMeasurementMethodId=='POINT_OF_INTAKE'){

            

            return axios.get('lid/getFPHPointOfIntakeLids', this.config).then(response => {
                let lidData = response.data.data.sort((a,b) => a.displayText > b.displayText?1:-1 ) ;
                var lids = []
                lidData.map(row => {
                   lids.push({ label: row.displayText, value: row.id , name: row.name, active: row.active});
                });        
                return lids;  
            });
        } else {
            return axios.get('lid/getFPHStorageMeasurementLids', this.config).then(response => {
                let lidData = response.data.data.sort((a,b) => a.displayText > b.displayText?1:-1 ) ;
                var lids = []
                lidData.map(row => {
                   lids.push({ label: row.displayText, value: row.id , name: row.name, active: row.active});
                });          
                return lids;
            });
        }
    }
    
}

export default RegistrationApiHelpers;
