import React from 'react';
import { loadModules } from 'esri-loader';
import { gridLayer } from 'leaflet';
import {
  MAP_SIX_LAYER9, MAP_SIX_LAYER3, WNSW_LICENCE_LAYER,
  WNSW_PRODUCTION_BORE_LAYER, WNSW_MONITORING_BORE_LAYER
} from '../../constants/MeterFormConstants'

export class WebMapViewActualLocation extends React.Component {

  constructor(props) {
    super(props);
    this.mapRef = React.createRef();
    /* this.point = props.point;
     */
    this.actualPoint = props.actualPoint;
    this.updatePoints = props.updatePoints;
    this.formStatus = props.formStatus;
    this.form = props.form;
    this.state = {
      actualPoint: props.actualPoint
    }
  }

  updateActualMapPoints(actualPoint) {
    this.setState({ actualPoint: actualPoint });
    //document.getElementById('actualLatitude').click();
    this.displayMap();
  }
  componentWillReceiveProps(nextProps) {
    this.actualPoint = nextProps.actualPoint;
  }

  componentDidMount() {
    this.displayMap();
  }

  displayMap() {

    // lazy load the required ArcGIS API for JavaScript modules and CSS
    loadModules(['esri/Map', 'esri/views/MapView', 'esri/layers/FeatureLayer',
      'esri/layers/GraphicsLayer', 'esri/Graphic', 'esri/config', 'esri/widgets/BasemapGallery',
      "esri/widgets/Fullscreen", 'esri/widgets/BasemapToggle', "esri/widgets/Sketch/SketchViewModel",
      "esri/geometry/support/webMercatorUtils", "dojo/dom-geometry",
      "esri/symbols/SimpleFillSymbol",
      "esri/symbols/SimpleLineSymbol",
      "esri/Color", "esri/geometry/geometryEngine", "esri/geometry/Point", "dojo/request",
      "esri/geometry/SpatialReference",], { css: true })
      .then(([ArcGISMap, MapView, FeatureLayer, GraphicsLayer, Graphic, esriConfig, BasemapGallery,
        Fullscreen, BasemapToggle, SketchViewModel, webMercatorUtils, domGeom,
        SimpleFillSymbol, SimpleLineSymbol, Color, geometryEngine, Point, esriRequest, Spatialreference]) => {

        var updateActualPoints = this.updatePoints;
        var formStatus = this.formStatus;
        var form = this.form;
        var actualPoint = this.state.actualPoint;
        var map = new ArcGISMap({
          basemap: 'satellite'
          //basemap: 'osm'
        });

        this.view = new MapView({
          container: this.mapRef.current,
          map: map,
          center: [151, -33],
          zoom: 9
        });

        var toggle = new BasemapToggle({

          view: this.view,
          //nextBasemap: "satellite"
          nextBasemap: "osm"
        });

        this.view.ui.add(toggle, "top-right");

        var fullscreen = new Fullscreen({
          view: this.view
        });

        this.view.ui.add(fullscreen, "top-right");

        var lineButton = document.getElementById("line-button");
        this.view.ui.add("line-button", "top-left");
        lineButton.style.display = "flex";

        if (form == "Meter Validation" && formStatus == "2") {
          lineButton.style.display = "none";
        } else if (form == "Meter Registration" && formStatus >= 1) {
          lineButton.style.display = "none";
        }




        /* var featureLayer = new FeatureLayer({
          url: "https://spatial.industry.nsw.gov.au/arcgis/rest/services/WaterNSW/WNSW_WaterManagement_V4/MapServer/0",
          outfields: ["*"],
        }); */

        const labelClassLot = {  // autocasts as new LabelClass()
          symbol: {

            type: "text",  // autocasts as new TextSymbol()

            color: "rgba(255, 255, 255, 0)",

            verticalAlignment: "bottom",

            horizontalAlignment: "left",

            font: {  // autocast as new Font()

              family: "Arial",

              size: 11,

              weight: "bold"

            }

          },

          labelPlacement: "always-horizontal",

          labelExpressionInfo: {

            expression: "$feature.lotidstring"

          },

          maxScale: 0,

          minScale: 25000000



        };


        const lotRenderer = {  // autocasts as new LabelClass()

          type: "simple",

          symbol: {

            type: "simple-fill",  // autocasts as new TextSymbol()

            //color: "rgba(255, 255, 255, 0)",

            style: "none",

            outline: {  // autocasts as new SimpleLineSymbol()

              color: "orange",

              width: 1



            }

          }

        }



        var featureLayerLotLabels = new FeatureLayer({

          url: MAP_SIX_LAYER3,

          outfields: ["*"],

          labelsVisible: true,

          labelingInfo: [labelClassLot],

          visible: true,

          renderer: lotRenderer,

          minScale: 0

        });




        map.add(featureLayerLotLabels);

        // Layer used to draw graphics returned
        var refThis = this;
        var graphicsLayer = new GraphicsLayer();
        map.add(graphicsLayer);

        // var actualLatitudeTextChange = document.getElementById("actualLatitude");
        // if (actualLatitudeTextChange != null) {
        //   actualLatitudeTextChange.addEventListener("click", function () {

        //     setActualLocationOnMap(refThis.state.actualPoint);

        //   });
        // }



        var refView = this.view;
        //var refActualPoint = this.actualPoint;



        const setActualLocationOnMap = (actualPoints) => {


          var actualPoint = {
            latitude: actualPoints.latitude,
            longitude: actualPoints.longitude
          }
          addGraphics(actualPoint);
        }

        const setActualPointFromMap = (longitude, latitude) => {

        }

        const addGraphics = (actualPointLocation) => {
          graphicsLayer.removeAll();
          if (actualPointLocation.longitude != null && actualPointLocation.latitude != null &&
            actualPointLocation.longitude != "" && actualPointLocation.latitude != "") {

            var actualPoint = {
              type: "point",
              longitude: actualPointLocation.longitude,
              latitude: actualPointLocation.latitude
            };

            var g1 = new Graphic({
              geometry: actualPoint,
              symbol: {
                type: "picture-marker",
                url: "https://static.arcgis.com/images/Symbols/Shapes/BluePin1LargeB.png",
                height: "50px",
                width: "50px",
                // color: color1,
                outline: {
                  width: 2,
                  color: [0, 255, 255],
                },
                size: "50px"
              }
            });

            graphicsLayer.add(g1);

          }
          if (actualPointLocation.longitude != null && actualPointLocation.latitude != null &&
            actualPointLocation.longitude != "" && actualPointLocation.latitude != "") {
            refView.goTo({ target: g1, center: [actualPointLocation.longitude, actualPointLocation.latitude], zoom: 17 });
          }

        }

        function createPointGraphic1(coordinates) {
          graphicsLayer.removeAll();

          var point = {
            type: "point", // autocasts as /Point
            x: coordinates[0],
            y: coordinates[1],
            spatialReference: refView.spatialReference
          };

          const textSymbol = {
            type: "text",
            color: "blue",
            text: "&#x1F4CD;",
            font: {
              size: 30,
              family: "CalciteWebCoreIcons"
            }
          };

          var g = new Graphic({
            geometry: point,
            symbol: {
              type: "picture-marker",
              url: 'https://static.arcgis.com/images/Symbols/Shapes/BluePin1LargeB.png',
              color: "#00FFFF",
              width: '50px',
              height: '50px',
              outline: {
                width: 2,
                color: [0, 255, 255],
              },
              size: "50px"
            },
            // popupTemplate: {
            //   title: "{LICENCE}",
            //   outFields: ["*"],
            //   content: [{
            //     type: "fields",
            //     fieldInfos: [{
            //       fieldName: "WORKS_ID",
            //       label: "WORKS ID"
            //     }, {
            //       fieldName: "WORK_NO",
            //       label: "GW NO",
            //     },
            //     {
            //       fieldName: "WORK_TYPE",
            //       label: "WORK TYPE",
            //     },
            //     {
            //       fieldName: "LICENSED_PURPOSES",
            //       label: "APPROVAL PURPOSES",
            //     },
            //     {
            //       fieldName: "WORK_STATUS",
            //       label: "WORK STATUS",
            //     }
            //     ]
            //   }]
            // }
          });

          graphicsLayer.add(g);
        }


        //********************************************* */
        let draggingGraphic;
        let tempGraphic;

        refView.on("drag", function (evt) {
          // if this is the starting of the drag, do a hitTest

          if (form == "Meter Validation" && formStatus == "2") {
            evt.stopPropagation();
            return;
          } else if (form == "Meter Registration" && formStatus >= 1) {
            evt.stopPropagation();
            return;
          }
          if (evt.action === 'start') {
            refView.hitTest(evt).then(resp => {

              if (resp.results.length > 0) {
                const graphicArray = resp.results.filter(function (result) {

                  return result.graphic.layer === graphicsLayer;
                });
                if (graphicArray.length > 0 && graphicArray[0].graphic) {
                  evt.stopPropagation();
                  // if the hitTest returns a point graphic, set dragginGraphic
                  draggingGraphic = graphicArray[0].graphic;
                }

              }

            });
          } else if (evt.action === 'update') {
            // on drag update events, only continue if a draggingGraphic is set
            if (draggingGraphic) {
              evt.stopPropagation();
              console.log("update")
              // if there is a tempGraphic, remove it
              if (tempGraphic) {
                //refView.graphics.remove(tempGraphic);
                graphicsLayer.remove(tempGraphic);
              } else {
                // if there is no tempGraphic, this is the first update event, so remove original graphic
                //refView.graphics.remove(draggingGraphic);
                graphicsLayer.remove(draggingGraphic);
              }
              // create new temp graphic and add it
              tempGraphic = draggingGraphic.clone();
              tempGraphic.geometry = refView.toMap(evt);
              //refView.graphics.add(tempGraphic);
              graphicsLayer.add(tempGraphic);
              var lnglat = webMercatorUtils.xyToLngLat(tempGraphic.geometry.x, tempGraphic.geometry.y);
              //alert(JSON.stringify(graphicsLayerApproved.graphics))
              //var distance = 0;
              updateMapPoints(lnglat);

            }

          } else if (evt.action === 'end') {
            // on drag end, continue only if there is a draggingGraphic
            if (draggingGraphic) {
              evt.stopPropagation();
              console.log("end")
              // rm temp
              if (tempGraphic)
                //refView.graphics.remove(tempGraphic);
                graphicsLayer.remove(tempGraphic);
              // create new graphic based on original dragging graphic
              let newGraphic = draggingGraphic.clone();
              if (newGraphic != undefined && newGraphic != null) {


                newGraphic.geometry = tempGraphic.geometry.clone();

                // add replacement graphic
                //refView.graphics.add(newGraphic);
                graphicsLayer.add(newGraphic);
                var lnglat = webMercatorUtils.xyToLngLat(newGraphic.geometry.x, newGraphic.geometry.y)
                //alert(JSON.stringify(graphicsLayerApproved.graphics))
                //var distance = 0;
                updateMapPoints(lnglat);
                /* if(graphicsLayer.graphics && graphicsLayer.graphics.length > 0 &&
                  graphicsLayerApproved.graphics && graphicsLayerApproved.graphics.length > 0)
                 distance = calculateDistance(graphicsLayer.graphics[0].geometry,
                  graphicsLayerApproved.graphics[0].geometry);
                updateActualPoints(lnglat[0],lnglat[1],distance); */
                // reset vars
              }
              draggingGraphic = null;
              tempGraphic = null;
            }
          }
        });


        //*********************************************** */






        this.view.when(function () {
          //if(useCurrentLocation == 1 || useCurrentLocation == -1 )
          //{

          //addGraphics(approvedPoint, actualPoint);
          setActualLocationOnMap(actualPoint);

          //}

          var sketchViewModel = new SketchViewModel({
            view: refView,
            layer: graphicsLayer,

            pointSymbol: {
              type: "simple-marker",
              color: [0, 200, 50],
              outline: {
                width: 2,
                color: [0, 255, 255],
              },
              size: "20px"
            },
            updateOnGraphicClick: false,
            defaultUpdateOptions: {
              // set the default options for the update operations
              toggleToolOnClick: false // only reshape operation will be enabled
            }
          });
          sketchViewModel.on(["update", "undo", "redo"], onGraphicUpdate);
          sketchViewModel.on("create", (event) => {
            if (event.state === "complete") {
              createPointGraphic1(event.graphic);
              var lnglat = webMercatorUtils.xyToLngLat(event.graphic.geometry.x, event.graphic.geometry.y);
              var distance = 0;
              updateMapPoints(lnglat);
              /* if(graphicsLayer.graphics && graphicsLayer.graphics.length > 0 &&
                graphicsLayerApproved.graphics && graphicsLayerApproved.graphics.length > 0)
               distance = calculateDistance(graphicsLayer.graphics[0].geometry,
                graphicsLayerApproved.graphics[0].geometry);
              updateActualPoints(lnglat[0],lnglat[1],distance); */
            }
          });
          document.getElementById("line-button").ondrag = function () {

          };

          refView.container.addEventListener("dragover", function (event) {
            event.preventDefault();
          });


          //
          refView.container.addEventListener("drop", function (event) {
            // set the sketch to create a point geometry

            refView.focus();
            var pos = domGeom.position(event.currentTarget);
            var pt = refView.toMap({ x: event.clientX - pos.x, y: event.clientY - pos.y });

            // alert(JSON.stringify(pos));
            createPointGraphic1([pt.x, pt.y])
            var lnglat = webMercatorUtils.xyToLngLat(pt.x, pt.y);
            updateMapPoints(lnglat);
            /*  var distance = 0;
               if(graphicsLayer.graphics && graphicsLayer.graphics.length > 0 &&
                 graphicsLayerApproved.graphics && graphicsLayerApproved.graphics.length > 0)
                distance = calculateDistance(graphicsLayer.graphics[0].geometry,
                 graphicsLayerApproved.graphics[0].geometry);
             updateActualPoints(lnglat[0],lnglat[1],distance); */
          });

          function onGraphicUpdate(event) {
            // get the graphic as it is being updated
            const graphic = event.graphics[0];
            //graphic.symbol=sketchViewModel.symbol;
            // check if the graphic is intersecting school buffers or is
            // still contained by the boundary polygon as the graphic is being updated


            // check if the update event's the toolEventInfo.type is move-stop or reshape-stop
            // then it means user finished moving or reshaping the graphic, call complete method.
            // this will change update event state to complete and we will check the validity of the graphic location.
            if (
              event.toolEventInfo &&
              (event.toolEventInfo.type === "move-stop")
            ) {

              sketchViewModel.complete();

            } else if (event.state === "complete") {
              createPointGraphic1([graphic.geometry.x, graphic.geometry.y]);
              var lnglat = webMercatorUtils.xyToLngLat(graphic.geometry.x, graphic.geometry.y)
              updateMapPoints(lnglat);
              /* var distance = 0;
              if(graphicsLayer.graphics && graphicsLayer.graphics.length > 0 &&
                graphicsLayerApproved.graphics && graphicsLayerApproved.graphics.length > 0)
               distance = calculateDistance(graphicsLayer.graphics[0].geometry,
                graphicsLayerApproved.graphics[0].geometry);
              updateActualPoints(lnglat[0],lnglat[1],distance); */
              // graphic moving or reshaping has been completed or cancelled (distinguish with aborted property)
              // if the graphic is in an illegal spot, call sketchviewmodel's update method again
              // giving user a chance to correct the location of the graphic

            }
          }

        });

        refView.on("click", function (event) {
          refView.hitTest(event).then(function (response) {
            // check if a feature is returned from the graphicsLayer
            // do something with the result graphic
            const graphicArray = response.results.filter(function (result) {
              return result.graphic.layer === graphicsLayer;
            });

            graphicArray.forEach(function (point) {
              var lnglat = webMercatorUtils.xyToLngLat(point.graphic.geometry.x, point.graphic.geometry.y);
              // updateSelectedInfos(point.graphic.attributes.WORKS_ID,
              //   lnglat[0],
              //   lnglat[1]);
            })

            const graphicArrayLots = response.results.filter(function (result) {
              return result.graphic.layer === featureLayerLotLabels;
            });

            if (graphicArrayLots.length > 0) {
              var lotidstring = graphicArrayLots[0].graphic.attributes.lotidstring;
              const labelClassLotLabel = {  // autocasts as new LabelClass()
                symbol: {
                  type: "text",  // autocasts as new TextSymbol()
                  color: [0, 0, 0],
                  verticalAlignment: "bottom",
                  horizontalAlignment: "left",
                  font: {  // autocast as new Font()
                    family: "Arial",
                    size: 11,
                    weight: "bold"
                  }
                },
                labelPlacement: "always-horizontal",
                labelExpressionInfo: {
                  expression: "$feature.lotidstring"
                },
                maxScale: 0,
                minScale: 25000000,
                where: "lotidstring = '" + lotidstring + "'"
              };
              var geometry = graphicArrayLots[0].graphic.geometry;
              if (geometry.type === "polygon") {
                var symbol = new SimpleFillSymbol(
                  "solid",
                  new SimpleLineSymbol("solid", new Color([232, 104, 80]), 2),
                  new Color([232, 104, 80, 0.25])
                );
                featureLayerLotLabels.labelingInfo = [];
                var graphic = new Graphic(geometry, symbol);
                refView.graphics.removeAll(); // make sure to remmove previous highlighted feature
                refView.graphics.add(graphic);
                featureLayerLotLabels.visible = false;
                featureLayerLotLabels.labelsVisible = false;
                featureLayerLotLabels.labelingInfo = [labelClassLotLabel]
                featureLayerLotLabels.visible = true;
                featureLayerLotLabels.labelsVisible = true;

              }
            }
          });
        });



        function updateMapPoints(lnglat) {
          var distance = 0;
          refThis.updatePoints(lnglat[0], lnglat[1]);
          //updateActualPoints(lnglat[0], lnglat[1]);
          //setActualPointFromMap(lnglat[0], lnglat[1]);
        }
      });
  }

  componentWillUnmount() {
    if (this.view) {
      // destroy the map view
      this.view.container = null;
    }

  }

  render() {

    return (

      <div>


        <div className="webmap" ref={this.mapRef}  >
          <div
            id="line-button"
            class="esri-widget esri-widget--button
              esri-interactive"
            title="Drop pin"
            draggable="true"
          >
            <span /* class="esri-icon-blank-map-pin" */><img src="https://static.arcgis.com/images/Symbols/Shapes/BluePin1LargeB.png" style={{ marginBottom: "10px" }} height="50px" width="50px"></img>{/* <i style={{color:"blue"}} class="fa fa-map-marker fa-2x"> </i>*/}</span>
          </div>

        </div>
        {/* <div class="col_12 column" style={{ color: 'rgb(100,100,100)', fontSize: "16px", padding: "0px 0px" }}>
          <div class="col_6 column">
            <label>Actual latitude</label>
            <button type="button" id="actualLatitude" ></button>
          </div>
          </div> */}

      </div>

    );
  }
}