import React from 'react';
import Authentication from '../../helpers/Authentication'

import axios from 'axios';

import {ReactSelect} from '../tools/BootstrapControls'


const authentication = new Authentication();
class DasWorkspaceIdSelect extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            workspacesLoaded : false,
            workspaceOptions : [],
            selectedWorkspaceId : null
        }
        if(props.selectedWorkspaceId) {
            this.state.selectedWorkspaceId = props.selectedWorkspaceId;
        }
    }

    ajaxConfig = {
        baseURL: process.env.REACT_APP_WEBAPI_URL,
        headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authentication.getToken() }
    };

    componentDidMount() {
        var context = this;
        var selectedWorkspaceId = this.state.selectedWorkspaceId;
        axios.post('/das/getWorkspacesByApprovalNumber', JSON.stringify({ approvalNumber: this.props.approvalNumber}), this.ajaxConfig).then(function (response) {
            var workspaceOptions = []
            var found = false;
            if(response.data.data && response.data.data.length > 0){
                for(var i = 0 ; i < response.data.data.length ; i++){
                    if(selectedWorkspaceId == response.data.data[i].workspaceName) found = true;
                    workspaceOptions.push({
                        label : response.data.data[i].workspaceName,
                        value : response.data.data[i].workspaceName
                    });
                }
            }

            if(selectedWorkspaceId && !found){
                workspaceOptions.push({
                    label : selectedWorkspaceId,
                    value : selectedWorkspaceId
                });
            }

            context.setState({
                workspaceOptions : workspaceOptions,
                workspacesLoaded : true
            })
        }).catch(function (error) {
            console.log(error);
            alert('Error happened on the server side when getting DAS workspaces.');
        }).then(function () {
            
        }); 
    }   

    workspaceIdChange = (e) => {
        this.setState({
            selectedWorkspaceId : e.value
        });
        if(this.props.onWorkspaceIdChange){
            this.props.onWorkspaceIdChange(e.value);
        }
    }



    render() {

        if(!this.state.workspacesLoaded) 
            return <div class="spinner-border spinner-border-sm text-primary" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>

        return <>
            <ReactSelect
                options = {this.state.workspaceOptions}
                label= {<>Select workspace ID from DAS <span className='text-danger'>*</span></>}
                value={this.state.selectedWorkspaceId}
                onChange={this.workspaceIdChange}
                disabled={this.props.disabled}
            />
        </>


    }

}

export default DasWorkspaceIdSelect;
