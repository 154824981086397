import React from 'react';
import moment from 'moment';
import { Redirect  } from "react-router-dom";

class MeterForms  extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    convertFormType = (type) => {
        switch(type) {
            case null : return null;
            case 'MeterCommission' : return "Meter Commission";
            case 'MeterRegistration' : return "Meter Registration";
            case 'ValidationCert' : return "Validation Certificate";
            case 'DesignCert' : return "Design Certificate";
            case 'AccuracyCert' : return "Accuracy Certificate";
            case 'TransactionalArrangementsCert' : return "Transactional Arrangements Certificate";
            case 'MaintenanceValidationCert' : return "Maintenance Validation Certificate";
            default: return "Unknown"
        }
    }

    openForm =(form) => {
        if(form.type == 'MeterCommission') {
            this.setState({redirectTo:`/compliance-process/${form.id}/site-registration`});return;
        } else if(form.type == 'MeterRegistration') {
            window.location.href = `/NonUrbanRegistration?id=${form.id}`;
        } else if(form.type == 'ValidationCert') {
            window.location.href = `/validation-certificate-num?id=${form.id}`;
        } else if(form.type == 'DesignCert') {
            window.location.href = `/MeterDesignCertificate?id=${form.id}`;
        } else if(form.type == 'AccuracyCert') {
            window.location.href = `/accuracy-certificate-num?id=${form.id}`;
        } else if(form.type == 'TransactionalArrangementsCert') {
            window.location.href = `/TransitionalReporting?id=${form.id}`;
        } else if(form.type == 'MaintenanceValidationCert') {
            window.location.href = `/validation-certificate-num?id=${form.id}`;
        } 
    }

    linkForForm =(form) =>{
        if(form.type == 'MeterCommission') {
            return `/#/compliance-process/${form.id}/site-registration`;
        } else if(form.type == 'MeterRegistration') {
            return `/NonUrbanRegistration?id=${form.id}`;
        } else if(form.type == 'ValidationCert') {
            return `/validation-certificate-num?id=${form.id}`;
        } else if(form.type == 'DesignCert') {
            return `/MeterDesignCertificate?id=${form.id}`;
        } else if(form.type == 'AccuracyCert') {
            return `/accuracy-certificate-num?id=${form.id}`;
        } else if(form.type == 'TransactionalArrangementsCert') {
            return `/TransitionalReporting?id=${form.id}`;
        } else if(form.type == 'MaintenanceValidationCert') {
            return `/validation-certificate-num?id=${form.id}`;
        } 
        return null;
    }

    render() {
        const forms = this.props.forms;
        return <>
            {this.state.redirectTo && <Redirect to={this.state.redirectTo}  />}
            <div class="table-responsive-xxl">
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th scope="col" style={{width:"120px"}}></th>
                            <th scope="col" style={{width:"200px"}}>Form Created Time</th>
                            <th scope="col" >Form Type</th>
                        </tr>
                    </thead>
                    <tbody>
                        {forms.map(f=>{
                            return <tr>
                                <td>
                                    <a className='btn btn-primary btn-sm' href={this.linkForForm(f)}>Open Form</a>
                                </td>
                                <td>{moment((new Date(f.createdDate))).format('DD/MM/YYYY h:mma')}</td>
                                <td>{this.convertFormType(f.type)}</td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>
            

            
        </>
    }
}

export default MeterForms;
