import React, {useState}  from 'react';
import { reduxForm, Field, formValueSelector, change } from 'redux-form';
import { connect } from 'react-redux';
import 'redux';
import $ from 'jquery'
import "react-datepicker/dist/react-datepicker.css";
import { RadiosForRedux, InputText , DatePickerForRedux, SelectForRedux, CheckboxForRedux}  from '../../tools/BootstrapControls'
import { updateDocuments, updateDeleteDocuments, changeFormStatus , uploadFiles} from './MeterDesignCertificate.Reducers';
import axios from 'axios';
import Authentication from '../../../helpers/Authentication';
import { DQP_QUALIFICATION_TYPE,DQP_QUALIFICATION_TYPE_VALUE,MESSAGE_UPLOAD_LIMITS,uploadOptionsForDesignCert as uploadOptions} from '../../../constants/MeterFormConstants'
import { saveDraftAction, saveSubmitAction, scrollTofocusError } from "../common/MeterFormValidations"
import { validateCertificatePartD } from './MeterDesignCertificate.Validation'
import FileUploader from '../../common/FileUploader'

const validate = validateCertificatePartD; 

let fileUploaderDisplayListUpdate;

let MeterDesignCertificatePartD = props => {
   const authentication = new Authentication();

   let dqpUserName = "";
   let dqpUserEmail = "";
   var user = authentication.currentUser();
   if (authentication.isLoggedIn) {
      dqpUserName = user.firstName + ' ' + user.lastName;
      dqpUserEmail = user.emails;
   }
   const designOwnOptions = 
         [
            { id: "1", name:"YES",selected:false},
            { id: "0", name:"NO", selected:false},
         ];

   let { handleSubmit, previousPage, meterEquipInfo,
      documents, loading,
      hasError
   } = props
   
   let dqpPhoneNumber = "";
   let dqpAddress = "";
   let dqpMeterInstallerNumber = "";
   let dqpQulaificationType = "";
   let dqpBusinessName = "";
   let dqpAbnOrAcn = "";
   
   if(props.userProfileValues != undefined && props.userProfileValues != null && props.userProfileValues.length > 0)
   {
      let profile = props.userProfileValues[0];
      dqpPhoneNumber =profile.phoneNumber;
      dqpAddress = profile.postalAddress.addressLine;
      dqpMeterInstallerNumber = profile.cmino;
      dqpQulaificationType = (profile.dqpQualificationType== DQP_QUALIFICATION_TYPE_VALUE.CERTIFIED_METER_INSTALLER)? DQP_QUALIFICATION_TYPE.CERTIFIED_METER_INSTALLER: DQP_QUALIFICATION_TYPE.CERTIFIED_PRACTISING_HYDROGRAPHER;
      dqpBusinessName = profile.businessName;
      dqpAbnOrAcn = profile.abnOrAcn;
   }
   
   const disableFields = (parseInt(meterEquipInfo.formStatus) == 2) || props.disableEditing || loading? true: false;
   const dateCreated = new Date()
   if (meterEquipInfo.date != null && meterEquipInfo.date == "")
      meterEquipInfo.date = dateCreated;

   meterEquipInfo.date = new Date(meterEquipInfo.date)
   if(!props.loadingFile)
   {
      props.dispatch(change(props.form, 'loadingFile', 0))
   } 

   //--------------------------------------
   const [renderCnt, setRenderCnt] = useState(0);
   
   const updateDisplayFileList = ()=>{

      if(!fileUploaderDisplayListUpdate) return;
      const newList = [];

      if(documents && documents.length!=0) {

         var option;
         for(var i = 0; i< documents.length; i++){
            if(documents[i].status=='new' || documents[i].status=='existing'){
               var option = uploadOptions.filter(option=>option.id==documents[i].documentType)[0];
               newList.push({
                  id: documents[i].id,
                  fileTypeDesc : option?option.name:"",
                  description: documents[i].description,
                  fileOriginalName: documents[i].fileOriginalName,
                  fileGUID: documents[i].fileGUID
               });
            }
         }

      }
      fileUploaderDisplayListUpdate(newList);
      setRenderCnt(renderCnt+1)
   }

   const fileUploaderFilesUploaded=(files)=>{
      
      var fileType = props.meterEquipInfo.fileType;
      var docs = props.documents;

      for(var i = 0; i< files.length; i++){

         docs.push({
            id : files[i].id,
            documentType : fileType,
            description : files[i].description,
            fileOriginalName : files[i].fileOriginalName,
            fileGUID : files[i].fileGUID,
            status : "new"
         });
      }
      props.dispatch(change(props.form, 'documents', docs))
      updateDisplayFileList();
   }

   
   const fileUploaderFileDeleted=(file)=>{
      if(!props.documents) return;

      for(var i = 0; i< props.documents.length; i++){
         if(props.documents[i].id==file.id){
            if (props.documents[i].status == "new")
               props.documents[i].status = "newDeleted";
            else if (props.documents[i].status == "existing")
               props.documents[i].status = "existingDeleted";
         }
      }

      updateDisplayFileList();
   }
   //--------------------------------------

   return (
      <form className="vertical" onSubmit={handleSubmit}>
         <h5 className='mb-3'>Certification</h5>

         <div className='card mb-3'>
            <div className='card-header fw-bold'>I certify that:</div>
            <div className='card-body'>
               <p className='fw-bold'>
               a) I am a duly qualified person under the Water Management (General) Regulation 2018 for the purpose of designing metering equipment for an open channel
               </p>
               <p className='fw-bold'>
               b) The design of the metering equipment complies with the requirements of Australian Standard 4747: Meters for non-urban water supply
               </p>
               <p className='fw-bold'>
               c) all information contained in this certificate is accurate, true and complete
               </p>
               <p className='fw-bold'>
               d) I am aware that I must notify the Minister within 7 days if I know or reasonably suspect that metering equipment I am installing or carrying out work on has been tampered with
               </p>
               <p className='fw-bold'>
               I am designing my own metering equipment <span className='text-danger'>*</span>
               </p>
               <Field 
                  name="meteringEquipmentInformation.designingOwnEquipment"
                  component={RadiosForRedux} 
                  options={designOwnOptions} 
                  values={meterEquipInfo.designingOwnEquipment}
                  props={{ disabled: disableFields }}
               />
               <hr className='my-4' />

               <div className='row mb-3'>
                  <div className='col-sm-6'>
                     <InputText
                        label="Name"
                        id="dqpUserName"
                        value={dqpUserName}
                        disabled={true}
                     />
                  </div>
                  <div className='col-sm-6'>
                     <InputText
                        label="Certified meter installer number"
                        id="dqpMeterInstallerNumber"
                        value={dqpMeterInstallerNumber}
                        disabled={true}
                     />
                  </div>
               </div>
               <div className='row mb-3'>
                  <div className='col-sm-6'>
                     <InputText
                        label="DQP qalification type"
                        id="dqpQulaificationType"
                        value={dqpQulaificationType}
                        disabled={true}
                     />
                  </div>
               </div>
               <div className='row mb-3'>
                  <div className='col-sm-6'>
                     <InputText
                        label="Business name"
                        id="dqpBusinessName"
                        value={dqpBusinessName}
                        disabled={true}
                     />
                  </div>
                  <div className='col-sm-6'>
                     <InputText
                        label="ABN or ACN"
                        id="dqpAbnOrAcn"
                        value={dqpAbnOrAcn}
                        disabled={true}
                     />
                  </div>
               </div>
               <div className='row mb-3'>
                  <div className='col-sm-6'>
                     <InputText
                        label="Address"
                        id="dqpAddress"
                        value={dqpAddress}
                        disabled={true}
                     />
                  </div>
                  <div className='col-sm-6'>
                     <InputText
                        label="Phone"
                        id="dqpPhoneNumber"
                        value={dqpPhoneNumber}
                        disabled={true}
                     />
                  </div>
               </div>
               <div className='row mb-3'>
                  <div className='col-sm-6'>
                     <InputText
                        label="Email"
                        id="dqpUserEmail"
                        value={dqpUserEmail}
                        disabled={true}
                     />
                  </div>
               </div>
               <div className='row mb-3'>
                  <div className='col-sm-3'>
                     <Field
                        name="meteringEquipmentInformation.date"
                        label={<>Date <span className='text-danger'>*</span></>}
                        component={DatePickerForRedux}
                        values={meterEquipInfo.date}
                        props= {{disabled: disableFields, maxDate: new Date()}}
                        handleChange={date => { props.change(props.form, 'meteringEquipmentInformation.date', date) }}
                     />
                  </div>
               </div>
               <p>
                  <i>
                  It is an offence under section 91J of the Water Management Act 2000 for a person to make a statement or furnish information in connection with a metering record the person knows to be false or misleading.
                  </i>
               </p>
            </div>
         </div>


         <h5>Supporting documentation checklist</h5>


         <div className='row mb-3 mt-4'>
            <div className='col-sm-6'>
               <Field
                  name="meteringEquipmentInformation.fileType"
                  component={SelectForRedux}
                  props={{ disabled: disableFields }}
                  value="3"
                  options={uploadOptions} 
               />
            </div>
         </div>
         <FileUploader
            fileInputLabel={<strong>Upload your supporting documents here:</strong>}
            fileTableHeader={<p><strong>Your uploaded files: </strong></p>}
            warning={MESSAGE_UPLOAD_LIMITS}
            maxFileSizeMB={10}
            exceedFileSizeErrorMsg = "Your file is too big"
            disabled={disableFields}
            provideDisplayDocumentsUpdator={(updateFunction)=>{fileUploaderDisplayListUpdate = updateFunction;}}

            fileTableHeadComponent={<tr>
               <th style={{width:"80px"}}></th>
               <th style={{width:"280px"}}>Type</th>
               <th>File name</th>
               <th>Description</th>
            </tr>}

            fileTableRowCompponent={(file, index, deleteButton)=>{
               return <tr key={index}>
                  <td>
                     {deleteButton}
                  </td>
                  <td>
                     {file.fileTypeDesc}
                  </td>
                  <td>
                     <a target='_blank' href={process.env.REACT_APP_WEBAPI_URL + process.env.REACT_APP_FIELS_GET + "/" + file.id}>
                        {file.fileOriginalName}
                     </a>
                  </td>
                  <td>{file.description}</td>
            </tr>
            }}

            onFilesUploaded={fileUploaderFilesUploaded}
            askForDocumentUpdate={updateDisplayFileList}
            onFileDeleted={fileUploaderFileDeleted}
         />

         {hasError && <div className='alert alert-danger'>
            Please fix all errors before you continue
         </div>}

         <div className="text-end">
            {(parseInt(meterEquipInfo.formStatus) == 1) && (!props.disableEditing) && <>
               {meterEquipInfo.id!=0 && <button type="button"  className="btn btn-danger me-3" disabled={loading} 
               data-toggle="modal" data-target="#deleteDraftModal">
                  Delete Draft
               </button>}
               <button type="button" className="btn btn-primary" disabled={loading} 
                  onClick={() => { saveDraftAction(props); }}>
                     Save Draft
               </button>
               <div className='vr mx-3' />
            </>}
            <button type="button" className="btn btn-warning me-3"  disabled={loading} 
              onClick={()=>{previousPage(meterEquipInfo.formStatus)}}>
                  Previous
            </button> 
            {(parseInt(meterEquipInfo.formStatus) == 1) && (!props.disableEditing) &&<button type="button" className="btn btn-success" disabled={loading} 
               onClick={() => { saveSubmitAction(props); }}>
                  Submit
            </button>}
         </div>
      </form>
   )
}


MeterDesignCertificatePartD = reduxForm({
   destroyOnUnmount: false,
   forceUnregisterOnUnmount: true,
   validate,
   onSubmitFail: (errors, dispatch ) => {
      dispatch(change('certificate', 'hasError', true));
   },
   onSubmitSuccess:(result , dispatch )=>{
      dispatch(change('certificate', 'hasError', false));
      window.scrollTo(0, 0);
   }
})(MeterDesignCertificatePartD)

MeterDesignCertificatePartD = connect(
   (state,props) => {
      const selectorCert = formValueSelector('certificate')
      const hasError = selectorCert(state, 'hasError')

      const selector = formValueSelector(props.form)
      const userProfileValues = selector(state, 'mdata.userProfile')
      const meterEquipInfo = selector(state, 'meteringEquipmentInformation')
      const documents = selector(state, 'documents')
      const supportingDocuments = selector(state,'meterSupportingDocuments')
      const loading = selector(state, 'loading')
      return {
         userProfileValues,
         meterEquipInfo,
         documents,
         supportingDocuments,
         loading,
         hasError
      }
   }, {
   uploadFiles: (formData) => uploadFiles(formData),
   updateDeleteDocuments: (doc) => updateDeleteDocuments(doc),
   changeFormStatus: (payload, val) => changeFormStatus(payload, val),
   updateDocuments: (payload) => updateDocuments(payload)

})(MeterDesignCertificatePartD)

export default MeterDesignCertificatePartD