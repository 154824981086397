import React, { Fragment } from 'react';

import $ from 'jquery';
import "react-datepicker/dist/react-datepicker.css";
import RegistrationFormBase from './base/RegistrationFormBase'
import LIDInfoPage from '../components/meterRegistration/LIDInfoPage';
import {menuItems} from './base/BasePageLayout';


//const authentication = new Authentication();


class NonUrbanRegistrationForm extends RegistrationFormBase {

   constructor(props){
      super(props);
      this.state.meterRegistration.formType = "NON_URBAN";
  }

   getMenuItem =() => menuItems.nonUrbanMetering();

   renderDepthSesorLevel = () => {
      return null;
   }
   
   validateFieldsOnSubmit = () => {
      this.validateFieldIfNoPreviousError("workApproval",()=>{
         if(!this.state.meterRegistration.workApproval || $.trim(this.state.meterRegistration.workApproval)=='') return "This field is required";
      });
      this.validateFieldIfNoPreviousError("esid",()=>{
         if(!this.state.meterRegistration.esid || $.trim(this.state.meterRegistration.esid)=='') return "This field is required";
      });
   }

   preprocessOnSubmit = () => {
      this.state.meterRegistration.esid = parseInt(this.state.meterRegistration.esid)
      if (this.state.meterRegistration.meterNumber != null) {
         this.state.meterRegistration.meterNumber = parseInt(this.state.meterRegistration.meterNumber);
      }
   }

   validateFieldsOnSave = () => {
      this.validateFieldIfNoPreviousError("workApproval",()=>{
         if(!this.state.meterRegistration.workApproval || $.trim(this.state.meterRegistration.workApproval)=='') return "This field is required";
      });
      this.validateFieldIfNoPreviousError("esid",()=>{
         if(!this.state.meterRegistration.esid || $.trim(this.state.meterRegistration.esid)=='') return "This field is required";
      });
   }

   preprocessOnSave = () => {
      this.state.meterRegistration.esid = parseInt(this.state.meterRegistration.esid)

      if (this.state.meterRegistration.meterNumber != null) {
         this.state.meterRegistration.meterNumber = parseInt(this.state.meterRegistration.meterNumber);
      }
   }

   getPageName = () => {
      return "NonUrbanRegistration";
   }

   retrieveSpecificSelectOptions = (meterRegistration) => {}

   infoPageAcknowledgeClickHandler =()=>{
      window.scrollTo(0, 0);
      this.setState(Object.assign(this.state.displayControl, { pageStage: this.pageStages.mainForm()}));
   }
   //============ field events  ====================================


   //=======  function to determin displays ==============

   displayMeterDetails = () => {
      return true;
   }

   displayMeterType = () => {
      return true;
  }
  
  displayMeterSize = () => {
      return true;
  }

  displayMeterSerialNumber = () => {
      return true;
  }

  displayMeterUnitOfMeasures = () => {
   return true;
  }

  displayMeterUnits = () => {
      return true;
  }

   displayScallingFactor = () => {
      return true;
   }

   displayStorageDepthSensorTypeOptions = () => {
      return false;
   }

   displayOldMeterNumber = () => {
      return true;
   }

   displayDepthSesorLevel = () => {
      return false;
   }

   displayInfoPage = ()=> {
      return this.state.isDisplayInfoPage;
   }

   //=========== return text functions =========================
   /*
   getInstallationTypeNameText =(installtionType) => {
      return installtionType.name
   }
   */
   

   getTitleForApprovalOrStorageSession =() => {
      return "Work approval details";
   }

   getTitleForOldMeterDetails = () =>{
      return "Old meter details";
   }

   getTitleForNewMeterDetails = () => {
      return "New meter details";
   }

   getTitleForExistingMeterDetails = () => {
      return "Existing meter details";
   }

   //=========== start render functions =======================================================================================================
   renderStorageManagementMethodOptions = () => {
      return null;
   }

   renderStorageDepthSensorTypeOptions = () => {
      return  null;
   }

   renderApprovalOrStorageSession = () => {
      return this.renderApprovalSession();
   }

   renderSearchForm = () =>{
      return this.renderWorkApprovalSearchForm();
   }

   renderPageTitle= () =>{
      if(this.state.meterRegistration.isReplacementForm) 
         return <h3 className='mb-3'>Meter Telemetry Replacement Form</h3>
      return <h3 className='mb-3'>Meter-Telemetry Registration</h3>;

   }

   renderInfoPage = () => {
      return <LIDInfoPage acknowledgeClick={this.infoPageAcknowledgeClickHandler} />;
   }
}

export default NonUrbanRegistrationForm;