import React from 'react';
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import RequestAccess from './components/RequestAccess';
import History from './components/History';




class Routes  extends React.Component {

    render(){        

        return <Switch>
            <Route exact path="/das-access/request-access" component={RequestAccess} />
            <Route exact path="/das-access/history" component={History} />
        </Switch>
    }
}

export default Routes;