import * as md from './MeterValidationCertificate.Api';
import axios from 'axios';
import dispatch from 'redux';
import Authentication from '../../../helpers/Authentication';
import uiHelpers from '../../../helpers/uiHelpers';

const authentication = new Authentication();
const LOAD = '[MeterValidationCertificate] LOAD'
const SAVE = '[MeterValidationCertificate] SAVE'
const SEACRH_METER_DESIGN = '[MeterValidationCertificate] SEARCH'
const UPDATE_METER_DESIGN = '[MeterValidationCertificate] UPDATE'
const CHECK_METER_SERIALNUMBER = '[MeterValidationCertificate] SERIALNUMBER'


let initial = {
  loaded: false,
  searchForm: {
    workApprovalNo: "",
    holderName: "",
    isMatched: false,
    authorityHolderLastName: "",
    organisation: "",
    isCustomerConsent: false,
    customerEmail: ''
  },
  consentData: [],
  clickNext: 0,
  clickSaveDraft: 1,
  cloneStatus: 0,
  authorityHolderDetails: {
    id: 0,
    salutationId: 0,
    salutationText: '',
    authorityHolderName: '',
    corporationNameOrAcn: '',
    postalAddress: '',
    phone: '',
    alternatePhone: '',
    emailAddress: '',
    contactPersonName: '',
    contactPersonPhone: '',
    contactPersonEmailAddress: '',
    firstName: '',
    lastName: '',
    mobile: ''
  },
  approvedWorkDetails: {
    id: 0,
    meterCircumstancesOptions: '',
    propertyName: '',
    lotDp: '',
    waterSupplyWorkApprovalNo: '',
    waterAccessLicenceNo: '',
    esid: '',
    waterSourceId: 0,
    waterSourceName: '',
    waterSharingPlanName: '',
    waterSourceTypeId: 0,
    waterSourceType: '',
    meterId: '', //added by sk06052020 persist in DB R2
    meterNumber: '', //added by sk06052020 persist in DB R2
    meterNumberGUID: '', //added by sk06052020 persist in DB R2
    approvedByCustomer: 0, //added by sk06052020 persist in DB R2 change
    customersSiteName: '',
    customersPumpNumber: 0,
    isRelatedToMeterRegistry: 0,
    workspaceId: '',
    meterForm: 0,
    worksId: undefined,
    noOfWorks: undefined,
    correctWaterInfoCheck: '',
    correctWaterSourceDetails: ''
  },
  meteringEquipmentInformation: {

    id: 0,//number
    meterFormType: 1,
    formCategory: 'NUM',//text
    meterCircumstancesOptions: '',//text
    dateOfLastValidation: '',//date
    meteringSystemTypeId: 0,//number
    meteringSystemType: [],//text
    meterManufacturer: '',//text
    meterTypeModel: '',//text
    meterSerialNo: '',//text
    meterSize: '', //number
    pipeType: '',//text
    pipeSize: '',//number
    pipeConnectionType: '',//decimal
    pipeLengthUpstream: '',//decimal
    pipeLengthDownstream: '',//decimal
    longitude: 154,//decimal
    latitude: -32,//decimal
    supplyTypeId: 0,//number
    supplyType: '',//text,
    sealNoIds: '',//text
    sealNo: [],//text
    dataCollectorTypeId: 0,//number
    dataCollectorType: '',//text
    dataCollectorModelNo: '',//text
    dataCollectorSerialNo: '',//text
    dataCollectorPowerSourceId: 0,//number
    dataCollectorPowerSource: '',//text
    dataCollectorSolarPanelWattage: '',//text
    dataCollectorSolarPanelSerialNumber: '',//text
    channelType: '',//text
    channelWidth: '',//text
    meterDistanceFromSource: '',//number
    channelLengthUpstream: '',//number
    channelLlengthDownstream: '',//number
    maxMeasuredDischarge: '',//decimal
    minMeasuredDischarge: '',//decimal
    maxMeasuredVelocity: '',//decimal
    minMeasuredVelocity: '',//decimal
    maxGaugeHeight: '',//decimal
    minGaugeHeight: '',//decimal
    maxError: '',//decimal
    minError: '',//decimal
    noOfMeasurementsTaken: '',//nummber
    accuracyMethod: '',//text
    accuracyConclusionOpenChannel: '',//text
    accuracyMaxErrorMeteringEquipment: '',//number
    accuracyOwnMeteringEquipment: '',//number
    dateValidation: '',//date
    formStatus: 1,//number
    date: '',//date
    //added for MeterValidationCertificatePartC extra fields
    patternApprovalNumber: '',
    meterTestCertificate: '',
    meterq1FlowRateMinimum: '',
    meterq3FlowRateOptimum: '',
    designFlowRate: '',
    softwareVersion: '',
    gpsLocation: '',
    meterReading: '',
    meterReadingDate: '',
    finalReadingRemovedMeter: '',
    finalReadingDateRemovedMeter: '',
    newMeterEquipment: '',
    designEquipmentDetails: '',
    meteringEquipmentConsistent: '',
    meterRemovedDate: '',
    readingBeforeMaintenance: '',
    dateBeforeMaintenance: '',
    readingAfterMaintenance: '',
    dateAfterMaintenance: '',

    isModificationToCompliance: '',
    validationMeterEquipmentComplies: '',
    validationMaxErrorMeteringEquipment: '',//number
    validationOwnMeteringEquipment: '',//number
    otherConclusion: '',
    openChannelConclusion: '',
    endMeterReading: 0, //not to be added in db
    fileType: 0, //not to be added in db
    meterTypeModelId: 0,
    processUsedToCheckDesign: '',
    designingOwnEquipment: '',
    complyMeteringEquipment: 0,
    compylyNavigation: 0,
    meterBeforeApril2019: 0,//added new fields for existing meter
    dateOfInstallationBeforeApril2019: '',//added
    meterDescription: '',
    calibrationFactorMeter: '',
    previousMeterManufactureModel: '',
    previousMeterSerialNumber: '',
    previousMeterSize: '',
    lidId: 0,//added
    meterFlowUnitsId: 0,//added
    meterUnitOfMeasuresId: 0,//added
    pipeMaterialsId: 0,//added
    lIDType: '',
    lIDTelemetry: '',
    lIDMeterReading: '',
    scalingFactor: '',
    reasonForRevalidation: '',
    methodUsed: '',
    maintenanceValidation: 0,
    reasonForMaintenance: '',
    reasonForMaintenanceComment: '',
    sealNumbersVerified: ''
  },

  meterNonComplianceReason: [{
    //nonComplianceData: [],
    reason: '',
    comments: '',
    //modificationsPerformed: '',
    //meteringEquipmentInformationId: ''
  }],

  meterNonCompliance: {
    modificationsPerformed: '',
    complianceReasonId: '',
    meteringEquipmentInformationId: ''
  },

  meterCertification: {
    meterEquipmentComplies: '',
    permissibleError: '',
    ownMeteringValidation: '',

    name: '',
    cmino: '',
    dqpqualificationType: '',
    businessName: '',
    abnorACN: '',
    address: '',
    phoneNumber: '',
    emailAddress: '',
    validationDate: '',
    signature: '',
    date: ''
  },

  maintenanceChecklist: {
    q1: '3',
    q2: '3',
    q3: '3',
    q4: '3',
    q5: '3',
    q6: '3',
    q7: '3',
    q8: '3',
    q9: '3',
    q10: '3',
    q11: '3',
    q12: '3',
    q13: '3',
    q14: '3',
    q15: '3',
    q16: '3',
    q17: '3',
    q18: '3',
    q19: '3',
    q20: '3',
    q21: '3',
    q22: '3',
    q23: '3',
    q24: '3',
    q25: '3',
    q26: '3',
    q27: '3',
    q28: '3',
    q29: '3',
    q30: '3',
    q31: '3',
    q32: '3',
    q33: '3',
    q34: '3',
    q35: '3',
    q36: '3',
    q37: '3',
    q38: '3'
  },
  mdata: [{}],
  documents: [],
  registrationDoc: [],
  esidExternalList: [], //added by sk06052020 retrieved from external System R2
  meterNumberExternalList: [], //added by sk06052020 retrieved from external System R2,
  waterAccessLicenceNoExternalList: [], //added by sk06052020 retrieved from external System R2,
  meterModelTypeList: []
}

const reducerMeterValidation = (state = initial, action) => {
  console.log('---------data--------' + JSON.stringify(action.data));
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loaded: true,
        mdata: action.data.data,
      }
    case SAVE:
      return state;

    case SEACRH_METER_DESIGN:
      return {
        ...state,
        loaded: action.data.payload.loaded,
        mdata: action.data.payload.mdata,
        authorityHolderDetails: action.data.payload.authorityHolderDetails,
        approvedWorkDetails: action.data.payload.approvedWorkDetails,
        meteringEquipmentInformation: action.data.payload.meteringEquipmentInformation,
        meterNonCompliance: action.data.payload.meterNonCompliance,
        meterNonComplianceReason: action.data.payload.meterNonComplianceReason,
        maintenanceChecklist: action.data.payload.checklist,
        documents: action.data.payload.documents,
        registrationDoc: action.data.payload.registrationDoc,
        meterModelTypeList: action.data.payload.mdata.meterTypeModel,
        consentValid: action.data.consentValid
      }

    case UPDATE_METER_DESIGN:
      return state;
    case CHECK_METER_SERIALNUMBER:
      return state;
    default:
      return state;
  }
  return state;
}

/**
 * Simulates data loaded into this reducer from somewhere
 */
/* export const load = () => {

  const options = {
    url: process.env.REACT_APP_WEBAPI_URL + process.env.REACT_APP_METER_VALIDATION_CERT_GETALLDATA,
    method: 'GET',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Accept': 'application/json',
      'Content-Type': 'application/json;charset=UTF-8',
      'Authorization': 'Bearer ' + authentication.getToken()
    }
  };
  return async (dispatch) => {
    const response = await axios(options);
    const data = await response.data;
    console.log("printing");
    console.log(data);
    dispatch({ type: LOAD, data });
  }
} */

export const load = (payload) => {

  const options = {
    url: process.env.REACT_APP_WEBAPI_URL + process.env.REACT_APP_METER_VALIDATION_CERT_GETALLDATA,
    method: 'POST',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Accept': 'application/json',
      'Content-Type': 'application/json;charset=UTF-8',
      'Authorization': 'Bearer ' + authentication.getToken()
    },
    data: {
      payload
    }
  };
  return async (dispatch) => {
    const response = await axios(options);
    const data = await response.data;
    console.log("printing_in_load");
    console.log(data);
    dispatch({ type: LOAD, data });
  }
}



export const save = (payload, page, callback) => {
  //alert("save in reducer called");
  console.log(payload);
  const options = {
    url: process.env.REACT_APP_WEBAPI_URL + process.env.REACT_APP_METER_VALIDATION_CERT_POSTDATA,
    method: 'POST',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Accept': 'application/json',
      'Content-Type': 'application/json;charset=UTF-8',
      'Authorization': 'Bearer ' + authentication.getToken()
    },
    data: {
      payload
    }
  };
  return async (dispatch) => {
    // const response = await axios(options);
    // alert(response);
    // const data = await response.data;
    // dispatch({ type: SAVE });

    try {
      const response = await axios(options);
      const data = await response.data;
      console.log(JSON.stringify(data));
      if (data.success) {
        if (callback) {
          callback(data.meterDesignId, page);
        }
      } else {
        uiHelpers.showErrorTop("Error when saving data", true, false)
      }
      dispatch({ type: SAVE });
    } catch (error) {
      console.log(error);
      uiHelpers.showErrorTop("Error when saving data", true, false)
    }

  }
}

export const findById = (id) => {

  const options = {
    url: process.env.REACT_APP_WEBAPI_URL + process.env.REACT_APP_METER_VALIDATION_CERT_URI + id,
    method: 'GET',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Accept': 'application/json',
      'Content-Type': 'application/json;charset=UTF-8',
      'Authorization': 'Bearer ' + authentication.getToken()
    }
  };
  return async (dispatch) => {
    try {
      const response = await axios(options);
      const data = await response.data;
      console.log("printing values");
      console.log(data);
      if (!data.success) {
        if (data.message != undefined && data.message != "")
          alert(data.message);
        else
          alert("Error in loading Data");
      }
      else {
        console.log("data_return_findById_valcert" + JSON.stringify(data, null, 2));
        dispatch({ type: SEACRH_METER_DESIGN, data });
      }
    }
    catch (error) {

      if (!error.success) {
        if (error.message != undefined && error.message != "") {
          uiHelpers.showErrorTop(error.message)
        }
      }
    }


  }
}

export const findByIdCopy = (id) => {
  debugger;
  const options = {
    url: process.env.REACT_APP_WEBAPI_URL + process.env.REACT_APP_METER_VALIDATION_REVIEW_CERT_URI + '/' + id,
    method: 'GET',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Accept': 'application/json',
      'Content-Type': 'application/json;charset=UTF-8',
      'Authorization': 'Bearer ' + authentication.getToken()
    }
  };
  return async (dispatch) => {
    try {
      const response = await axios(options);
      const data = await response.data;
      console.log("printing values");
      console.log(data);
      if (!data.success) {
        if (data.message != undefined && data.message != "")
          alert(data.message);
        else
          alert("Error in loading Data");
      }
      else {
        console.log("data return");
        console.log(data);
        console.log(JSON.stringify(data));
        dispatch({ type: SEACRH_METER_DESIGN, data });
      }
    }
    catch (error) {

      if (!error.success) {
        if (error.message != undefined && error.message != "") {
          uiHelpers.showErrorTop(error.message)
        }
      }
    }


  }
}

export const update = (payload, page, callback) => {
  //alert("update in reducer called");
  console.log(payload);
  debugger;
  let url = process.env.REACT_APP_WEBAPI_URL + process.env.REACT_APP_METER_VALIDATION_CERT_UPDATEDATA;
  if (payload.cloneStatus) {
    url = process.env.REACT_APP_WEBAPI_URL + process.env.REACT_APP_METER_VALIDATION_CERT_COPYVALIDATION
  }

  const options = {
    url: url,
    method: 'POST',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Accept': 'application/json',
      'Content-Type': 'application/json;charset=UTF-8',
      'Authorization': 'Bearer ' + authentication.getToken()
    },
    data: {
      payload
    }
  };
  return async (dispatch) => {
    // const response = await axios(options);
    // //alert(response);
    // const data = await response.data;
    // alert(JSON.stringify(data));
    // dispatch({ type: UPDATE_METER_DESIGN, data });

    try {
      const response = await axios(options);

      const data = await response.data;

      if (data.success) {
        if (payload.cloneStatus) {
          if (payload.meteringEquipmentInformation.formCategory == "FPH")
            document.location.href = "/validation-certificate-point-of-intake?id=" + data.meterDesignId;
          else
            document.location.href = "/validation-certificate-num?id=" + data.meterDesignId;
        } else if (callback) {
          callback(data.meterDesignId, page);
        } else {
          if (payload.meteringEquipmentInformation.formCategory == "FPH")
            document.location.href = "/validation-certificate-point-of-intake?id=" + data.meterDesignId;
          else
            document.location.href = "/validation-certificate-num?id=" + data.meterDesignId;
        }
      }
      else
        uiHelpers.showErrorTop("Error when saving data", true, false)

      dispatch({ type: UPDATE_METER_DESIGN, data });
    } catch (error) {
      console.log(error);
      uiHelpers.showErrorTop("Error when saving data", true, false)
    }


  }
}

/**
 * Simulates data loaded into this reducer from somewhere
 */
export const checkSerialNumber = (id, serialNumber) => {

  const options = {
    url: process.env.REACT_APP_WEBAPI_URL + process.env.REACT_APP_METER_VALIDATION_CERT_CHECK_SERIALNUMBER + '/' + id + '/' + serialNumber,
    method: 'GET',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Accept': 'application/json',
      'Content-Type': 'application/json;charset=UTF-8',
      'Authorization': 'Bearer ' + authentication.getToken()
    }
  };
  return async (dispatch) => {
    try {
      const response = await axios(options);
      const data = await response.data;
      console.log("printing");
      console.log(data);
      if (data.success)
        alert(data.message);

    }
    catch (error) {

    }
    dispatch({ type: CHECK_METER_SERIALNUMBER });

  }
}


export default reducerMeterValidation