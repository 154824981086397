import React from 'react';
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import BasePageLayout from '../layout/base/BasePageLayout'
import IWasRedirect from './components/IWasRedirect'
import Dashboard from './components/Dashboard'
import NewPeriod from './components/NewPeriod'
import Logout from './components/Logout'
import UserInfo from './components/UserInfo'
import Menu from './components/Menu'
import Details from './components/Details'
import GlobalModals from './components/GlobalModals'
import ComponentsConnector from '../helpers/ComponentsConnector';
import {logoImage, portalName} from './constants';

class Routes  extends React.Component {
    noLoginConnector = new ComponentsConnector();
    helpPopupConnector = new ComponentsConnector();
    render(){        
        return <Switch>
            <Route exact path="/measurement-period/iwas-redirect" component={IWasRedirect} />
            <Route exact path="/measurement-period/*">
                <Switch>
                        <Router>
                            <Switch>
                                <Route exact path="/logout">    
                                    <BasePageLayout 
                                        portalName={portalName} 
                                        logoImg={logoImage}
                                        noUserInfo={true}
                                        menuComponent={Menu}
                                    ><Logout/></BasePageLayout>
                                </Route>
                                <Route exact path="/*">
                                    <GlobalModals noLoginCallbackReceiver={this.noLoginConnector.callbackReceiver} helpPopupCallbackReceiver={this.helpPopupConnector.callbackReceiver}>
                                        <BasePageLayout 
                                            portalName={portalName} 
                                            logoImg={logoImage}
                                            userInfo={<UserInfo openHelpPopup={this.helpPopupConnector.call} />}
                                            menuComponent={Menu}
                                        >
                                            <Route exact path={["/","/dashboard"]} ><Dashboard openHelpPopup={this.helpPopupConnector.call} noLoginCallback={this.noLoginConnector.call} helpPopupCallback={this.helpPopupConnector.call} /></Route>
                                            <Route exact path="/new" ><NewPeriod noLoginCallback={this.noLoginConnector.call} /></Route>
                                            <Route exact path={["/details/:id"]} ><Details noLoginCallback={this.noLoginConnector.call} /></Route>
                                        </BasePageLayout>
                                    </GlobalModals>
                                </Route>
                            </Switch>
                        </Router>
                </Switch>
            </Route>
        </Switch>
    }
}

export default Routes;