import React from 'react';
import Authentication from '../../helpers/Authentication'
import axios from 'axios';
import uiHelpers from '../../helpers/uiHelpers';
import {InputTextWithFloatingLabel} from '../../components/tools/BootstrapControls'
import {QuestionFill} from '../../components/tools/Icons'
import DatePicker from 'react-datepicker';
import Pagination from '../../components/tools/Pagination';

import {renderRecordTrRow, renderRecordHeaderTr} from '../../components/admin/renderCombineResult';

const authentication = new Authentication();

class SearchForms extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            criterias : {
                keyword : null,
                keywordError : null,
                nonUrbanFormTypes : {
                    registration : true,
                    design : true,
                    validation : true,
                    accuracy : true,
                    transactionalArrangements: true,
                    maintenanceValidation : true
                },
                fphFormTypes : {
                    pointOfIntakeRegistration : true,
                    storageMeasurementRegistration : true,
                    surveyBranchmarksValidation : true,
                    pointOfIntakeAccuracy : true,
                    updateRatingTable: true,
                    secondaryMeteringEquipment : true,
                    pointOfIntakeValidation : true,
                    storageManagementValidation : true
                },
                formStatus : {
                    draft : true,
                    submitted : true,
                    installed : true,
                    removed : false
                },
                createdFrom : null,
                createdTo : null,
                submittedFrom : null,
                submittedTo : null,
                installedFrom : null,
                installedTo : null
            },
            searching: false,
            searchCompleted : false,
            displayRecords : []
        }
    }

    searchResult = null;

    ajaxConfig = {
        baseURL: process.env.REACT_APP_WEBAPI_URL,
        headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authentication.getToken() }
    };

    componentDidMount() {

    }   

    searchKeywordChange = (event) => {
        this.setState(Object.assign(this.state.criterias,{keyword : event.target.value} ));
        this.setState({searchCompleted:false});
        
    }

    search = async () => {

        if(!this.state.keyword || this.state.keyword.trim()==''){
            this.setState({keywordError : "Please enter some keyword"})
            return;
        }

        this.setState(Object.assign(this.state,{searching : true} ));
        uiHelpers.showProgressOnTop("Searching for forms...")
        await axios.post('/admin/search-forms', JSON.stringify(this.state.criterias), this.ajaxConfig).then((response) => {
            this.searchResult = response.data.data.data;
            this.setState({searchCompleted : true},()=>{
                if(this.displayRecordSetter) this.displayRecordSetter(this.searchResult);
            });
            uiHelpers.hideProgressOnTop();
        }, (error) => {
            uiHelpers.showErrorTop('Error occur when searching for forms')
            console.log(error);
        });
        this.setState(Object.assign(this.state,{searching : false} ));
    }

    onChangeNonUrbanForms = (formType) =>{
        this.state.criterias.nonUrbanFormTypes[formType] = !this.state.criterias.nonUrbanFormTypes[formType];
        this.forceUpdate();
    }

    onChangeFphForms = (formType) => {
        this.state.criterias.fphFormTypes[formType] = !this.state.criterias.fphFormTypes[formType];
        this.forceUpdate();
    }

    onChangeFormStatus = (formStatus) =>{
        this.state.criterias.formStatus[formStatus] = !this.state.criterias.formStatus[formStatus];
        this.forceUpdate();
    }

    onChangeDates = (dateName, date) =>{
        this.state.criterias[dateName] = date;
        this.forceUpdate();
    }

    refreshDisplayRecords = (data)=>{
        this.setState({displayRecords: data });
    }
  
     displayRecordSetter = null;
     displayRecordPagenationDataProvider = (dataSetter) =>{
        this.displayRecordSetter = dataSetter;
     }

    


    render() {
        return <>

            <div class="modal" tabindex="-1" id="instructionModal">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">Instructions for search</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <p>
                                <strong>About Keyword</strong>
                            </p>
                            <p>
                                You can search keyword for the following fields:
                            </p>
                            <ul>
                                <li>
                                    Approval number
                                </li>
                                <li>
                                    Rating table name
                                </li>
                                <li>
                                    DQP name (who created the form)
                                </li>
                                <li>
                                    Holder name of the approval for the form
                                </li>
                            </ul>
                            <p>
                                <strong>About advance criterias</strong>
                            </p>
                            <ul>
                                <li>
                                    Advance criterias can be expended
                                    </li>
                                <li>
                                By default, all form types are pre-selected
                                </li>
                                <li>
                                "Installed" and "Removed" status only apply to Meter Registration forms
                                </li>
                                <li>
                                "Installed date search" apply to Meter Registration forms
                                </li>
                            </ul>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>

            <h3 className='mb-3'>Search for Forms</h3>

            <div className='mb-3'>
                <button className='btn btn-secondary btn-sm' data-bs-toggle="modal" data-bs-target="#instructionModal">Instructions <QuestionFill/></button>
            </div>

            <div class="row mb-3">
                <div class="col-md-7">
                    <InputTextWithFloatingLabel
                        id="searchKeyword"
                        error={this.state.keywordError}
                        value={this.state.criterias.keyword} 
                        disabled={this.state.searching}
                        onChange={this.searchKeywordChange}
                        label="Keyword"
                    />
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-md-7">
                    <div class="accordion mb-3" id="accordionCriteries">
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headingCirterias">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseCriterias" aria-controls="collapseFormTypes">
                                Advanced Criteria
                                </button>
                            </h2>
                            <div id="collapseCriterias" class="accordion-collapse collapse" aria-labelledby="headingCirterias" data-bs-parent="#accordionCriteries">
                                <div class="accordion-body">

                                    <p className='mb-1'>
                                        <strong>Non-urban Metering forms</strong>
                                        <button className='btn btn-outline-primary btn-sm ms-3' onClick={()=>{
                                            this.state.criterias.nonUrbanFormTypes.registration = true;
                                            this.state.criterias.nonUrbanFormTypes.design = true;
                                            this.state.criterias.nonUrbanFormTypes.validation = true;
                                            this.state.criterias.nonUrbanFormTypes.accuracy = true;
                                            this.state.criterias.nonUrbanFormTypes.transactionalArrangements = true;
                                            this.state.criterias.nonUrbanFormTypes.maintenanceValidation = true;
                                            this.forceUpdate();
                                        }}>Select all</button>
                                        <button className='btn btn-outline-primary btn-sm ms-3' onClick={()=>{
                                            this.state.criterias.nonUrbanFormTypes.registration = false;
                                            this.state.criterias.nonUrbanFormTypes.design = false;
                                            this.state.criterias.nonUrbanFormTypes.validation = false;
                                            this.state.criterias.nonUrbanFormTypes.accuracy = false;
                                            this.state.criterias.nonUrbanFormTypes.transactionalArrangements = false;
                                            this.state.criterias.nonUrbanFormTypes.maintenanceValidation = false;
                                            this.forceUpdate();
                                        }}>Dis-select all</button>
                                    </p>
                                
                                    <div class="form-check">
                                        <input class="form-check-input" disabled={this.state.searching} type="checkbox" value="" id="check_1" checked={this.state.criterias.nonUrbanFormTypes.registration} onChange={()=>{this.onChangeNonUrbanForms("registration")}}/>
                                        <label class="form-check-label" for="check_1">
                                            Meter-Telemetry Registration
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" disabled={this.state.searching} type="checkbox" value="" id="check_2" checked={this.state.criterias.nonUrbanFormTypes.design} onChange={()=>{this.onChangeNonUrbanForms("design")}}/>
                                        <label class="form-check-label" for="check_2">
                                            Design Certificate
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" disabled={this.state.searching} type="checkbox" value="" id="check_3" checked={this.state.criterias.nonUrbanFormTypes.validation} onChange={()=>{this.onChangeNonUrbanForms("validation")}}/>
                                        <label class="form-check-label" for="check_3">
                                            Validation Certificate
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" disabled={this.state.searching} type="checkbox" value="" id="check_4" checked={this.state.criterias.nonUrbanFormTypes.accuracy} onChange={()=>{this.onChangeNonUrbanForms("accuracy")}}/>
                                        <label class="form-check-label" for="check_4">
                                            Accuracy Certificate
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" disabled={this.state.searching} type="checkbox" value="" id="check_5" checked={this.state.criterias.nonUrbanFormTypes.transactionalArrangements} onChange={()=>{this.onChangeNonUrbanForms("transactionalArrangements")}}/>
                                        <label class="form-check-label" for="check_5">
                                            Transitional Arrangements Report
                                        </label>
                                    </div>
                                    <div class="form-check mb-3">
                                        <input class="form-check-input" disabled={this.state.searching} type="checkbox" value="" id="check_6" checked={this.state.criterias.nonUrbanFormTypes.maintenanceValidation} onChange={()=>{this.onChangeNonUrbanForms("maintenanceValidation")}}/>
                                        <label class="form-check-label" for="check_6">
                                            Maintenance Validation Certificate
                                        </label>
                                    </div>
                                    
                                    <p className='mb-1'>
                                        <strong>Flooadplain Harvesting forms</strong>
                                        <button className='btn btn-outline-primary btn-sm ms-3' onClick={()=>{
                                            this.state.criterias.fphFormTypes.pointOfIntakeRegistration = true;
                                            this.state.criterias.fphFormTypes.storageMeasurementRegistration = true;
                                            this.state.criterias.fphFormTypes.surveyBranchmarksValidation = true;
                                            this.state.criterias.fphFormTypes.pointOfIntakeAccuracy = true;
                                            this.state.criterias.fphFormTypes.updateRatingTable = true;
                                            this.state.criterias.fphFormTypes.secondaryMeteringEquipment = true;
                                            this.state.criterias.fphFormTypes.pointOfIntakeValidation = true;
                                            this.state.criterias.fphFormTypes.storageManagementValidation = true;
                                            
                                            this.forceUpdate();
                                        }}>Select all</button>
                                        <button className='btn btn-outline-primary btn-sm ms-3' onClick={()=>{
                                            this.state.criterias.fphFormTypes.pointOfIntakeRegistration = false;
                                            this.state.criterias.fphFormTypes.storageMeasurementRegistration = false;
                                            this.state.criterias.fphFormTypes.surveyBranchmarksValidation = false;
                                            this.state.criterias.fphFormTypes.pointOfIntakeAccuracy = false;
                                            this.state.criterias.fphFormTypes.updateRatingTable = false;
                                            this.state.criterias.fphFormTypes.secondaryMeteringEquipment = false;
                                            this.state.criterias.fphFormTypes.pointOfIntakeValidation = false;
                                            this.state.criterias.fphFormTypes.storageManagementValidation = false;
                                            this.forceUpdate();
                                        }}>Dis-select all</button>
                                    </p>

                                    <div class="form-check">
                                        <input class="form-check-input" disabled={this.state.searching} type="checkbox" value="" id="check_7" checked={this.state.criterias.fphFormTypes.pointOfIntakeRegistration} onChange={()=>{this.onChangeFphForms("pointOfIntakeRegistration")}}/>
                                        <label class="form-check-label" for="check_7">
                                            Point of Intake Telemetry Registration
                                        </label>
                                    </div>

                                    <div class="form-check">
                                        <input class="form-check-input" disabled={this.state.searching} type="checkbox" value="" id="check_8" checked={this.state.criterias.fphFormTypes.storageMeasurementRegistration} onChange={()=>{this.onChangeFphForms("storageMeasurementRegistration")}}/>
                                        <label class="form-check-label" for="check_8">
                                            Storage Measurement Telemetry Registration
                                        </label>
                                    </div>

                                    <div class="form-check">
                                        <input class="form-check-input" disabled={this.state.searching} type="checkbox" value="" id="check_9" checked={this.state.criterias.fphFormTypes.surveyBranchmarksValidation} onChange={()=>{this.onChangeFphForms("surveyBranchmarksValidation")}}/>
                                        <label class="form-check-label" for="check_9">
                                            Survey Benchmarks Validation Certificate
                                        </label>
                                    </div>

                                    <div class="form-check">
                                        <input class="form-check-input" disabled={this.state.searching} type="checkbox" value="" id="check_10" checked={this.state.criterias.fphFormTypes.pointOfIntakeAccuracy} onChange={()=>{this.onChangeFphForms("pointOfIntakeAccuracy")}}/>
                                        <label class="form-check-label" for="check_10">
                                            Point of Intake Accuracy Certificate
                                        </label>
                                    </div>

                                    <div class="form-check">
                                        <input class="form-check-input" disabled={this.state.searching} type="checkbox" value="" id="check_11" checked={this.state.criterias.fphFormTypes.updateRatingTable} onChange={()=>{this.onChangeFphForms("updateRatingTable")}}/>
                                        <label class="form-check-label" for="check_11">
                                        Update Rating Table
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" disabled={this.state.searching} type="checkbox" value="" id="check_12" checked={this.state.criterias.fphFormTypes.secondaryMeteringEquipment} onChange={()=>{this.onChangeFphForms("secondaryMeteringEquipment")}}/>
                                        <label class="form-check-label" for="check_12">
                                        Secondary Metering Equipment Validation Certificate
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" disabled={this.state.searching} type="checkbox" value="" id="check_13" checked={this.state.criterias.fphFormTypes.pointOfIntakeValidation} onChange={()=>{this.onChangeFphForms("pointOfIntakeValidation")}}/>
                                        <label class="form-check-label" for="check_13">
                                        Point of Intake Validation Certificate
                                        </label>
                                    </div>
                                    <div class="form-check mb-3">
                                        <input class="form-check-input" disabled={this.state.searching} type="checkbox" value="" id="check_14" checked={this.state.criterias.fphFormTypes.storageManagementValidation} onChange={()=>{this.onChangeFphForms("storageManagementValidation")}}/>
                                        <label class="form-check-label" for="check_14">
                                        Primary Validation certificate
                                        </label>
                                    </div>

                                    <p className='mb-1'><strong>Form status</strong></p>
                                    <div class="form-check">
                                        <input class="form-check-input" disabled={this.state.searching} type="checkbox" value="" id="check_15" checked={this.state.criterias.formStatus.draft} onChange={()=>{this.onChangeFormStatus("draft")}}/>
                                        <label class="form-check-label" for="check_15">
                                        Draft
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" disabled={this.state.searching} type="checkbox" value="" id="check_16" checked={this.state.criterias.formStatus.submitted} onChange={()=>{this.onChangeFormStatus("submitted")}}/>
                                        <label class="form-check-label" for="check_16">
                                        Submitted
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" disabled={this.state.searching} type="checkbox" value="" id="check_17" checked={this.state.criterias.formStatus.installed} onChange={()=>{this.onChangeFormStatus("installed")}}/>
                                        <label class="form-check-label" for="check_17">
                                        Installed
                                        </label>
                                    </div>
                                    <div class="form-check mb-3">
                                        <input class="form-check-input" disabled={this.state.searching} type="checkbox" value="" id="check_18" checked={this.state.criterias.formStatus.removed} onChange={()=>{this.onChangeFormStatus("removed")}}/>
                                        <label class="form-check-label" for="check_18">
                                        Removed
                                        </label>
                                    </div>

                                    <div class="row mb-3">
                                        <div class="col-md-5">
                                            <label className='form-label d-block'><strong>Created from:</strong></label>
                                            <DatePicker dateFormat="dd/MM/yyyy" disabled={this.state.searching} className='form-control' selected={this.state.criterias.createdFrom} onChange={(e)=>{this.onChangeDates('createdFrom',e)}}  />
                                        </div>
                                        <div class="col-md-5">
                                            <label className='form-label d-block'><strong>To:</strong></label>
                                            <DatePicker dateFormat="dd/MM/yyyy" disabled={this.state.searching} className='form-control' selected={this.state.criterias.createdTo} onChange={(e)=>{this.onChangeDates('createdTo',e)}}  />
                                        </div>
                                    </div>

                                    <div class="row mb-3">
                                        <div class="col-md-5">
                                            <label className='form-label d-block'><strong>Submitted from:</strong></label>
                                            <DatePicker dateFormat="dd/MM/yyyy" disabled={this.state.searching} className='form-control' selected={this.state.criterias.submittedFrom} onChange={(e)=>{this.onChangeDates('submittedFrom',e)}}  />
                                        </div>
                                        <div class="col-md-5">
                                            <label className='form-label d-block'><strong>To:</strong></label>
                                            <DatePicker dateFormat="dd/MM/yyyy" disabled={this.state.searching} className='form-control' selected={this.state.criterias.submittedTo} onChange={(e)=>{this.onChangeDates('submittedTo',e)}}  />
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-5">
                                            <label className='form-label d-block'><strong>Installed from:</strong></label>
                                            <DatePicker dateFormat="dd/MM/yyyy" disabled={this.state.searching} className='form-control' selected={this.state.criterias.installedFrom} onChange={(e)=>{this.onChangeDates('installedFrom',e)}}  />
                                        </div>
                                        <div class="col-md-5">
                                            <label className='form-label d-block'><strong>To:</strong></label>
                                            <DatePicker dateFormat="dd/MM/yyyy" disabled={this.state.searching} className='form-control' selected={this.state.criterias.installedTo} onChange={(e)=>{this.onChangeDates('installedTo',e)}}  />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <button type="button" class="btn btn-primary" disabled={this.state.searching} onClick={this.search}>Search</button>
                    </div>
                </div>
            </div>
            
            {this.state.searchCompleted && <div className='my-3'>
                <hr/>
                <div style={{opacity : this.state.searching? 0.3 : 1}}>
                    {this.searchResult && this.searchResult.length==0 && <div>
                        <div className="alert alert-primary">
                            No result returned.
                        </div>
                    </div>}

                    {this.searchResult && this.searchResult.length!=0 && <div>
                        <table className="table table-striped fs-90">
                            <thead>
                                {renderRecordHeaderTr()}
                            </thead>
                            <tbody>
                                {this.state.displayRecords.map((record, i) => renderRecordTrRow(record) )}
                            </tbody>
                        </table>
                        <div class="row justify-content-md-center">
                            <div class="col"></div>
                            <div class="col-md-auto">
                                <Pagination numberOfRecordsPerPage={20} dataProvider={this.displayRecordPagenationDataProvider} displayRecordsUpdate={this.refreshDisplayRecords} />
                                <div className='fs-6 text-center mt-2'><em>{this.searchResult.length} records</em></div>
                            </div>
                            <div class="col"></div>
                        </div>
                        
                    </div>}
                </div>
            </div>}
        </>
    }

}

export default SearchForms;


