import {
    workApprovalExpression, workApprovalExpressionMessage,
    integer1to6Digits, integer1to6DigitsMessage, integerUpto20Digits, integerUpto20DigitsMessage,
    MESSAGE_REQUIRED,
    isNumber, MESSAGE_REQUIRED_NUMBER,
    MESSAGE_MAX_ALLOWED_CHARCATERS_100,
    MESSAGE_MAX_ALLOWED_CHARCATERS_50,
    MESSAGE_MAX_ALLOWED_CHARCATERS_20,
    MESSAGE_MAX_ALLOWED_CHARCATERS_10,
    MESSAGE_REQUIRED_METER_NUMBER,
    MESSAGE_REQUIRED_ESID,
    integerUpto10Digits
} from '../../../constants/MeterFormConstants';

export const validate = (values, params) => {

    const errors = {
        approvedWorkDetails: {
            propertyName: "",
            lotDp: "",
            waterSupplyWorkApprovalNo: "",
            esid: "",
            selectedMeter: "",
            meterNumber: "",
            customersSiteName: "",
            customersPumpNumber: "",
            workspaceId: "",
            correctWaterInfoCheck: "",
            correctWaterSourceDetails: ""
        }
    }
    let clickSaveDraft = params.clickSaveDraft
    let clickNext = params.clickNext;
    if (clickSaveDraft == undefined)
        clickSaveDraft = values.clickSaveDraft;
    if (clickNext == undefined)
        clickNext = values.clickNext;
    console.log("draft:" + clickSaveDraft);
    if (clickSaveDraft === 1 && clickNext === 0) {
        if (values.approvedWorkDetails.propertyName && values.approvedWorkDetails.propertyName.length > 100) {
            errors.approvedWorkDetails.propertyName = MESSAGE_MAX_ALLOWED_CHARCATERS_100
        }

        if (values.approvedWorkDetails.waterSupplyWorkApprovalNo) {
            if (!workApprovalExpression.test(values.approvedWorkDetails.waterSupplyWorkApprovalNo)) {
                errors.approvedWorkDetails.waterSupplyWorkApprovalNo = workApprovalExpressionMessage;
            } else if (values.approvedWorkDetails.waterSupplyWorkApprovalNo.length > 10) {
                errors.approvedWorkDetails.waterSupplyWorkApprovalNo = MESSAGE_MAX_ALLOWED_CHARCATERS_10
            }
        }

        if (values.approvedWorkDetails.esid) {
            if (!isNumber(values.approvedWorkDetails.esid)) {
                errors.approvedWorkDetails.esid = MESSAGE_REQUIRED_NUMBER;
            } else if (!integer1to6Digits.test(values.approvedWorkDetails.esid)) {
                errors.approvedWorkDetails.esid = integer1to6DigitsMessage;
            }
        }

        if (values.approvedWorkDetails.meterNumber) {
            if (!isNumber(values.approvedWorkDetails.meterNumber)) {
                errors.approvedWorkDetails.esid = MESSAGE_REQUIRED_NUMBER;
            } else if (!integer1to6Digits.test(values.approvedWorkDetails.meterNumber)) {
                errors.approvedWorkDetails.meterNumber = integer1to6DigitsMessage;
            }
        }

        if (values.approvedWorkDetails.selectedMeter) {
            if (!isNumber(values.approvedWorkDetails.selectedMeter)) {
                errors.approvedWorkDetails.esid = MESSAGE_REQUIRED_NUMBER;
            } else if (!integerUpto20Digits.test(values.approvedWorkDetails.selectedMeter)) {
                errors.approvedWorkDetails.selectedMeter = integerUpto20DigitsMessage;
            }
        }

        if (values.approvedWorkDetails.customersSiteName) {
            if (values.approvedWorkDetails.customersSiteName.length > 50) {
                errors.approvedWorkDetails.customersSiteName = MESSAGE_MAX_ALLOWED_CHARCATERS_50
            }
        }
        if (values.approvedWorkDetails.customersPumpNumber) {
            if (!isNumber(values.approvedWorkDetails.customersPumpNumber)) {
                errors.approvedWorkDetails.customersPumpNumber = MESSAGE_REQUIRED_NUMBER;
            }
            else if (!integerUpto20Digits.test(values.approvedWorkDetails.customersPumpNumber)) {
                errors.approvedWorkDetails.customersPumpNumber = integerUpto20DigitsMessage;
            }
        }

        if (values.approvedWorkDetails.waterAccessLicenceNo) {
            if (values.approvedWorkDetails.waterAccessLicenceNo.length > 100) {
                errors.approvedWorkDetails.waterAccessLicenceNo = MESSAGE_MAX_ALLOWED_CHARCATERS_100
            }
        }

        if (values.approvedWorkDetails.waterSourceName) {
            if (values.approvedWorkDetails.waterSourceName.length > 100) {
                errors.approvedWorkDetails.waterSourceName = MESSAGE_MAX_ALLOWED_CHARCATERS_100
            }
        }

        if (values.approvedWorkDetails.waterSharingPlanName) {
            if (values.approvedWorkDetails.waterSharingPlanName.length > 100) {
                errors.approvedWorkDetails.waterSharingPlanName = MESSAGE_MAX_ALLOWED_CHARCATERS_100
            }
        }




        if (values.approvedWorkDetails.workspaceId) {
            if (values.approvedWorkDetails.workspaceId.length > 20) {
                errors.approvedWorkDetails.workspaceId = MESSAGE_MAX_ALLOWED_CHARCATERS_20
            }
        }

    }
    else if (clickSaveDraft == 0 && clickNext === 1 && values.meteringEquipmentInformation.formStatus != 2) {
        if (!values.approvedWorkDetails.propertyName) {
            errors.approvedWorkDetails.propertyName = MESSAGE_REQUIRED
        } else if (values.approvedWorkDetails.propertyName.length > 100) {
            errors.approvedWorkDetails.propertyName = MESSAGE_MAX_ALLOWED_CHARCATERS_100
        }

        if (values.approvedWorkDetails.correctWaterInfoCheck == 1) {
            if (!values.approvedWorkDetails.correctWaterSourceDetails) {
                errors.approvedWorkDetails.correctWaterSourceDetails = MESSAGE_REQUIRED
            }
        }

        if (values.approvedWorkDetails.meterForm == 1) {

            if (!values.approvedWorkDetails.customersSiteName) {
                errors.approvedWorkDetails.customersSiteName = MESSAGE_REQUIRED
            } else if (values.approvedWorkDetails.customersSiteName.length > 50) {
                errors.approvedWorkDetails.customersSiteName = MESSAGE_MAX_ALLOWED_CHARCATERS_50
            }


            if (values.approvedWorkDetails.customersPumpNumber) {
                if (!isNumber(values.approvedWorkDetails.customersPumpNumber)) {
                    errors.approvedWorkDetails.customersPumpNumber = MESSAGE_REQUIRED_NUMBER;
                }
                else if (!integerUpto20Digits.test(values.approvedWorkDetails.customersPumpNumber)) {
                    errors.approvedWorkDetails.customersPumpNumber = integerUpto20DigitsMessage;
                }
            }

            if (!values.approvedWorkDetails.isRelatedToMeterRegistry) {
                errors.approvedWorkDetails.isRelatedToMeterRegistry = MESSAGE_REQUIRED

                if (values.approvedWorkDetails.isRelatedToMeterRegistry == 1) {
                    if (!values.approvedWorkDetails.workspaceId) {
                        errors.approvedWorkDetails.isRelatedToMeterRegistry = MESSAGE_REQUIRED

                    }
                    // else if (values.approvedWorkDetails.workspaceId.length > 10) {
                    //     errors.approvedWorkDetails.workspaceId = MESSAGE_MAX_ALLOWED_CHARCATERS_10
                    // }
                }

            }

            if (!values.approvedWorkDetails.meterNumber) {
                errors.approvedWorkDetails.meterNumber = MESSAGE_REQUIRED_METER_NUMBER
            } else if (!isNumber(values.approvedWorkDetails.meterNumber)) {
                errors.approvedWorkDetails.meterNumber = MESSAGE_REQUIRED_METER_NUMBER;
            }
            else if (!integer1to6Digits.test(values.approvedWorkDetails.meterNumber)) {
                errors.approvedWorkDetails.meterNumber = integer1to6DigitsMessage;
            }

            // if (values.meteringEquipmentInformation.newMeterEquipment == "2") {
            //     if (!values.approvedWorkDetails.selectedMeter) {
            //         errors.approvedWorkDetails.selectedMeter = MESSAGE_REQUIRED_NUMBER
            //     } else if (!isNumber(values.approvedWorkDetails.selectedMeter)) {
            //         errors.approvedWorkDetails.selectedMeter = MESSAGE_REQUIRED_NUMBER;
            //     }
            //     else if (!integerUpto20Digits.test(values.approvedWorkDetails.selectedMeter)) {
            //         errors.approvedWorkDetails.selectedMeter = integerUpto20DigitsMessage;
            //     }
            // }


        }

        if (!values.approvedWorkDetails.waterSupplyWorkApprovalNo) {
            errors.approvedWorkDetails.waterSupplyWorkApprovalNo = MESSAGE_REQUIRED
        } else if (!workApprovalExpression.test(values.approvedWorkDetails.waterSupplyWorkApprovalNo)) {
            errors.approvedWorkDetails.waterSupplyWorkApprovalNo = workApprovalExpressionMessage;
        } else if (!(values.approvedWorkDetails.waterSupplyWorkApprovalNo.length == 10)) {
            errors.approvedWorkDetails.waterSupplyWorkApprovalNo = MESSAGE_MAX_ALLOWED_CHARCATERS_10
        }

        if (values.approvedWorkDetails.waterAccessLicenceNo) {
            if (values.approvedWorkDetails.waterAccessLicenceNo.length > 100) {
                errors.approvedWorkDetails.waterAccessLicenceNo = MESSAGE_MAX_ALLOWED_CHARCATERS_100
            }
        }

        if (values.approvedWorkDetails.waterSourceName) {
            if (values.approvedWorkDetails.waterSourceName.length > 100) {
                errors.approvedWorkDetails.waterSourceName = MESSAGE_MAX_ALLOWED_CHARCATERS_100
            }
        }

        if (values.approvedWorkDetails.waterSharingPlanName) {
            if (values.approvedWorkDetails.waterSharingPlanName.length > 100) {
                errors.approvedWorkDetails.waterSharingPlanName = MESSAGE_MAX_ALLOWED_CHARCATERS_100
            }
        }


        if (!values.approvedWorkDetails.esid) {
            errors.approvedWorkDetails.esid = MESSAGE_REQUIRED_ESID;
        } else if (!isNumber(values.approvedWorkDetails.esid)) {
            errors.approvedWorkDetails.esid = MESSAGE_REQUIRED_ESID;
        }
        else if (!integer1to6Digits.test(values.approvedWorkDetails.esid)) {
            errors.approvedWorkDetails.esid = integer1to6DigitsMessage;
        }




    }

    return errors;
}
