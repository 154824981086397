import e from "cors";
import { FlatForm,  mandatoryValidator, positiveWholeNumberValidator, preConditionToValidate, trueValidator, txtMandatoryValidator } from "../../../helpers/FormHelper";
import { isStringNullOrEmpty } from "../../../helpers/validators";

export const getSiteRegistrationApprovalForm = (formName, context, rejectAction, approveAction, getInstallationType) => {

    const result = new FlatForm(formName, context);
    result.initialise([
        "id",
        "status",
        "createdBy",
        "createdDate",
        "approvingOfficer",
        "approvalDetailsCorrect",
        "lidDetailsCorrect",
        "meterDetailsCorrect",
        "comments",
        "newMeterNumber",
        "documents",
        "s91iReviewed",
        "rejectOrApprove",
        "scenarios"
    ])
    .setValueChangedValidators({
        newMeterNumber:[positiveWholeNumberValidator('Please enter a valid meter number')]
    })
    .setPostChangeActions({
        rejectOrApprove:(val,vals,form)=>{
            form.vals({scenarios:[]},false);
            form.errs({scenarios:null});
            
        }
    })
    .defineAction('reject',rejectAction,{
        approvalDetailsCorrect:[mandatoryValidator("This is required")],
        lidDetailsCorrect:[mandatoryValidator("This is required")],
        meterDetailsCorrect:[mandatoryValidator("This is required")],
        s91iReviewed:[preConditionToValidate((val, vals)=>{return getInstallationType().key=='E_M_R_L'},mandatoryValidator("This is required"))],
        rejectOrApprove:[(val,vals)=>{
            if(!vals.scenarios || vals.scenarios.length==0) return "Please select one of the scenarios";
            vals.scenarios.forEach(e => {
                if(isStringNullOrEmpty(e.outcome)) e.outcomeErr = "";
                else e.outComeErr = null;
            });
        }],
        scenarios:[(val,vals)=>{
            if(!val || val.length==0) return null;
            var hasError = false;
            val.forEach(e => {
                if(isStringNullOrEmpty(e.outcome)) {
                    e.outcomeErr = "Please provide the oucome for this scenario";
                    hasError=true;
                } else e.outcomeErr = null;
            });
            if(hasError) return "Outcome missing";
            else return null;
        }]
    })
    .defineAction('approve',approveAction,{
        approvalDetailsCorrect:[mandatoryValidator("This is required"),trueValidator("This must be yes when approving the form")],
        lidDetailsCorrect:[mandatoryValidator("This is required"),trueValidator("This must be yes when approving the form")],
        meterDetailsCorrect:[mandatoryValidator("This is required"),trueValidator("This must be yes when approving the form")],
        s91iReviewed:[preConditionToValidate((val, vals)=>{return getInstallationType().key=='E_M_R_L'},mandatoryValidator("This is required"))],
        rejectOrApprove:[(val,vals)=>{
            if(!vals.scenarios || vals.scenarios.length==0) return "Please select one of the scenarios";
        }],
        scenarios:[(val,vals)=>{
            if(!val || val.length==0) return null;
            var hasError = false;
            val.forEach(e => {
                if(isStringNullOrEmpty(e.outcome)) {
                    e.outcomeErr = "Please provide the oucome for this scenario";
                    hasError=true;
                } else e.outcomeErr = null;
            });
            if(hasError) return "Outcome missing";
            else return null;
        }]
    })
    return result;
}