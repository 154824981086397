import React from 'react';
import { InputText, UneditableTextInput } from "../components/tools/BootstrapControls";
import { isStringNullOrEmpty, isNumber, isPositiveWholeNumber, isPositiveNumber, isValidEmail, hasDigit } from './validators';
import Select from 'react-select';

export class Form {
    
    formName;
    context;
    fieldNames;
    form;
    valueChangeValidators;
    postChangeActions;
    actions;

    constructor(formName,context) {
        this.formName = formName;
        this.context = context;
        this.actions = {};

        this.form = {
            fieldValues : {},
            fieldOptions: {},
            errors : {},
            disableFields : {},
            formDisabled : false,
            fieldsDisabled : false
        }
    }

    initialise = (fieldNames) => {
        this.fieldNames = fieldNames;
        fieldNames.forEach(fieldName=>{
            this.form.fieldValues[fieldName] = null;
            this.form.errors[fieldName] = null;
            this.form.disableFields[fieldName] = false;
        });
        this.context.state[this.formName] = this.form;

        
        return this;
    }

    defineAction = (actionName,action,preValidators) =>{
        this.actions[actionName] = {action, preValidators};
        return this;
    }

    setValueChangedValidators = (validators) => {
        this.valueChangeValidators = validators;
        return this;
    }

    setPostChangeActions = (actions) => {
        this.postChangeActions = actions;
        return this;
    }

    

    setOptions = (options, updateState = true,callback) => {
        this.form.fieldOptions = Object.assign(this.form.fieldOptions, options);
        if(updateState)
            this.context.setState({[this.formName]: this.form}, callback);
    }

    options = (fieldName) => {
        return this.form.fieldOptions[fieldName]
    }

    disableForm = (callback) => {
        this.form.formDisabled = true;
        this.context.setState({[this.formName]: this.form}, callback);
    }

    enableForm = (callback) => {
        this.form.formDisabled = false;
        this.context.setState({[this.formName]: this.form}, callback);
    }

    isFormDisabled = () => {
        return this.form.formDisabled;
    }

    disableField =(fieldName, updateState = true,callback) => {
        this.form.disableFields[fieldName]=true;
        if(updateState)
            this.context.setState({[this.formName]: this.form}, callback);
    }

    enableField =(fieldName, updateState = true,callback) => {
        this.form.disableFields[fieldName]=false;
        if(updateState)
            this.context.setState({[this.formName]: this.form}, callback);
    }

    disableFields = (callback) => {
        this.form.fieldsDisabled = true;
        this.context.setState({[this.formName]: this.form}, callback);
    }

    enableFields = (callback) => {
        this.form.fieldsDisabled = false;
        this.context.setState({[this.formName]: this.form}, callback);
    }

    val = (fieldName) => {
        return this.form.fieldValues[fieldName];
    }

    vals =(values, updateState = true,callback) => {
        if(!values) {
            return this.form.fieldValues;
        }
        this.form.fieldValues = Object.assign(this.form.fieldValues, values);
        if(updateState)
            this.context.setState({[this.formName]: this.form}, callback);
    }

    err = (fieldName) => {
        return this.form.errors[fieldName];
    }

    errs = (errs, updateState = true,callback) => {
        if(!errs) {
            return this.form.errors;
        }
        this.form.errors = Object.assign(this.form.errors, errs);
        if(updateState)
            this.context.setState({[this.formName]: this.form}, callback);
    }

    haveErrors = (fieldNames) => {
        if(!fieldNames) fieldNames = this.fieldNames;
        for(var i = 0; i < fieldNames.length; i++) {
            if(this.form.errors[fieldNames[i]]) return true;
        }
        return false
    }

    isFieldDisabled = (fieldName) =>{
        return this.context.state[this.formName].formDisabled || this.context.state[this.formName].disableFields[fieldName] || this.context.state[this.formName].fieldsDisabled;
    }

    fieldValueChanged = async (fieldName, value) =>{
        this.form.fieldValues[fieldName] = value;
        let error = null;
        if(this.valueChangeValidators && this.valueChangeValidators[fieldName]){
            for(var i = 0; i < this.valueChangeValidators[fieldName].length; i++){
                error = await this.valueChangeValidators[fieldName][i](value,this.form.fieldValues,this);
                this.form.errors[fieldName] = error;
                if(error) break;
            }
        }
        this.context.setState({[this.formName]: this.form});
        if(this.postChangeActions && this.postChangeActions[fieldName]){
            await this.postChangeActions[fieldName](value,this.form.fieldValues,this);
        }

    }

    runAction = (actionName,callback) => {
        var hasError = false;
        if(this.actions[actionName].preValidators) {
            
            var error = null;
            var keys = Object.keys(this.actions[actionName].preValidators);

            for (var j = 0; j < keys.length; j++) {
                if(this.actions[actionName].preValidators[keys[j]].length==0) {
                    this.form.errors[keys[i]] = null;
                    continue;
                }
                error = null;
                for(var i = 0; i < this.actions[actionName].preValidators[keys[j]].length; i++){
                    error = this.actions[actionName].preValidators[keys[j]][i](this.form.fieldValues[keys[j]],this.form.fieldValues,this);
                    this.form.errors[keys[j]] = error;
                    if(error) {
                        hasError=true;
                        break;
                    }
                }
            }

            var found;
            for(var i = 0; i < this.fieldNames.length; i++) {
                found = false;
                for (var j = 0; j < keys.length; j++) {
                    if(keys[j]==this.fieldNames[i]) {
                        found = true;
                    }
                }
                if(!found) {
                    this.form.errors[this.fieldNames[i]] = null;
                }
            }
        }
        if(hasError) {
            this.context.setState({[this.formName]: this.form});
        } else {
            this.context.setState({[this.formName]: this.form},()=>{
                this.actions[actionName].action(this, callback);
            });
        }
        
    }

    renderUneditableTextInput = (fieldName, converter, label) => {
        if(converter) {
            return <UneditableTextInput label={label} value={converter(this.context.state[this.formName].fieldValues[fieldName])} fieldExtraClass="bg-light"/>
        }
        return <UneditableTextInput label={label} value={this.context.state[this.formName].fieldValues[fieldName]} fieldExtraClass="bg-light"/>
    }

    renderTextBox = (fieldName,label) => {
        return <InputText
            label={label}
            value={this.context.state[this.formName].fieldValues[fieldName]} 
            disabled={this.context.state[this.formName].formDisabled || this.context.state[this.formName].disableFields[fieldName] || this.context.state[this.formName].fieldsDisabled} 
            error={this.context.state[this.formName].errors[fieldName]} 
            onChange={(e)=>this.fieldValueChanged(fieldName,e.target.value)} 
        /> 
    }

    renderTextArea = (fieldName,textareaProps) => {
        const error = this.context.state[this.formName].errors[fieldName];
        return <><textarea
            textareaProps
            className={`form-control ${(error && error != '') ? "is-invalid" : ""}`}
            disabled={this.context.state[this.formName].formDisabled || this.context.state[this.formName].disableFields[fieldName] || this.context.state[this.formName].fieldsDisabled} 
            onChange={(e)=>this.fieldValueChanged(fieldName,e.target.value)} 
            value={this.context.state[this.formName].fieldValues[fieldName]}
        />
        {error  && <div className='text-danger'>{error}</div>}
        </>
    }

    renderYesNoOptions = (fieldName) => {
        const error = this.context.state[this.formName].errors[fieldName];
        const name = this.formName+"_"+fieldName;
        const value = this.context.state[this.formName].fieldValues[fieldName] ;
        const disabled = this.context.state[this.formName].formDisabled || this.context.state[this.formName].disableFields[fieldName] || this.context.state[this.formName].fieldsDisabled;
        return <>
            <div class="form-check form-check-inline">
                <input className={`form-check-input ${error?'is-invalid':''}`} type="radio" 
                    autocomplete="off" value="YES"
                    onChange={()=>this.fieldValueChanged(fieldName,true)} 
                    disabled={disabled} 
                    name={name} id={name +'Yes'} checked={value === true} 
                />
                <label class="form-check-label" for={name +'Yes'}>Yes</label>
            </div>
            <div class="form-check form-check-inline">
                <input className={`form-check-input ${error?'is-invalid':''}`} type="radio" 
                    autocomplete="off" value="NO"
                    onChange={()=>this.fieldValueChanged(fieldName,false)} 
                    disabled={disabled} 
                    name={name} id={name +'No'} checked={value === false} 
                />
                <label class="form-check-label" for={name +'No'}>No</label>
            </div>
            {error  && <div className='text-danger'>{error}</div>}
        </>;
    }

    renderRadioOptions = (fieldName,options,inline) => {
        const error = this.context.state[this.formName].errors[fieldName];
        const name = this.formName+"_"+fieldName;
        const value = this.context.state[this.formName].fieldValues[fieldName] ;
        const disabled = this.context.state[this.formName].formDisabled || this.context.state[this.formName].disableFields[fieldName] || this.context.state[this.formName].fieldsDisabled;
        return <>

            {options.map(o=><div className={`form-check ${inline?'form-check-inline':''}`}>
                <input className={`form-check-input ${error?'is-invalid':''}`} type="radio" 
                    autocomplete="off" value={o.value}
                    onChange={()=>this.fieldValueChanged(fieldName,o.value)} 
                    disabled={disabled} 
                    name={name} id={name +o.optionId} checked={o.isChecked(value)} 
                />
                <label class="form-check-label" for={name +o.optionId}>{o.label}</label>
            </div>)}
            {error  && <div className='text-danger'>{error}</div>}
        </>;
    }


    renderDropDown = (fieldName,getOptionValue,getOptionLabel, props) => {
        const error = this.context.state[this.formName].errors[fieldName];
        const value = this.context.state[this.formName].fieldValues[fieldName] ;
        const options = this.context.state[this.formName].fieldOptions[fieldName] ? this.context.state[this.formName].fieldOptions[fieldName] : [];
        const disabled = this.context.state[this.formName].formDisabled || this.context.state[this.formName].disableFields[fieldName] || this.context.state[this.formName].fieldsDisabled;



        return <>
            <Select
                placeholder=""
                isDisabled={disabled}
                className={error ? "react-select-invalid" : ""}
                getOptionLabel={getOptionLabel}
                getOptionValue={getOptionValue}
                options={options}
                onChange={(o)=>this.fieldValueChanged(fieldName,o)}
                value={value}
                {...props}
            />
            {error && <div className='invalid-feedback'>{error}</div>}
        </>
    }
}

export class FlatForm extends Form {
    renderUneditableTextInputRow = (fieldName, label, options) =>{
        const labelWidth = options.labelWidth?options.labelWidth:4;
        const fieldWidth = options.fieldWidth?options.fieldWidth:8;
        const rowClasses = options.rowClasses?options.rowClasses:'';
        return <div className={`row ${rowClasses} `}>
            <div className={`col-md-${labelWidth} py-2`}>{label}</div>
            <div className={`col-md-${fieldWidth}`}>
                {this.renderUneditableTextInput(fieldName,options.converter)}
            </div>
        </div>;
    }

    renderYesNoOptionsRow = (fieldName,label,options) =>{
        const labelWidth = options.labelWidth?options.labelWidth:4;
        const fieldWidth = options.fieldWidth?options.fieldWidth:8;
        const rowClasses = options.rowClasses?options.rowClasses:'';
        return <div className={`row ${rowClasses}`}>
            <div className={`col-md-${labelWidth} py-2`}>{label}</div>
            <div className={`col-md-${fieldWidth} pt-1`}>
                {this.renderYesNoOptions(fieldName)}
            </div>
        </div>
    }

    renderTextBoxRow = (fieldName,label,options) =>{
        const labelWidth = options.labelWidth?options.labelWidth:4;
        const fieldWidth = options.fieldWidth?options.fieldWidth:8;
        const rowClasses = options.rowClasses?options.rowClasses:'';
        return <div className={`row ${rowClasses}`}>
            <div className={`col-md-${labelWidth} py-2`}>{label}</div>
            <div className={`col-md-${fieldWidth}`}>
                {this.renderTextBox(fieldName)}
            </div>
        </div>
    }


    renderDropDownRow = (fieldName,label,getOptionValue,getOptionLabel,options, props) =>{
        const labelWidth = options.labelWidth?options.labelWidth:4;
        const fieldWidth = options.fieldWidth?options.fieldWidth:8;
        const rowClasses = options.rowClasses?options.rowClasses:'';
        return <div className={`row ${rowClasses}`}>
            <div className={`col-md-${labelWidth} py-2`}>{label}</div>
            <div className={`col-md-${fieldWidth}`}>
                {this.renderDropDown(fieldName,getOptionValue,getOptionLabel, props)}
            </div>
        </div>
    }

}


export const preConditionToValidate = (shoudlValidate, validator) => {
    return (val,vals)=>{
        if(shoudlValidate(val,vals)) {
            return validator(val,vals);
        }
        return null;
    }
    
}


export const latitudeNswValidator = (val)=>{
    if(isStringNullOrEmpty(val)) return null;
    if(!isNumber(val)) return "Please enter valid latitude value between -37.50538547 and -28.15709423";
    const latitude = parseFloat(val);
    if(!(latitude >= -37.50538547 && latitude <= -28.15709423)) return "Please enter valid latitude value between -37.50538547 and -28.15709423 aaa";
    return null;
}

export const longitudeNswValidator = (val)=>{
    if(isStringNullOrEmpty(val)) return null;
    if(!isNumber(val)) return "Please enter valid longitude value between 140.9953061 and 153.6387301";
    const longitude = parseFloat(val);
    if(!(longitude >= 140.9953061 && longitude <= 153.6387301)) return "Please enter valid longitude value between 140.9953061 and 153.6387301";
    return null;
}

export const positiveWholeNumberValidator = (message) =>{
    return (val)=>{
        if(isStringNullOrEmpty(val)) return null;
        if(!isPositiveWholeNumber(val)) return message;
        return null;
    }
}

export const noDigitValidator = (message) =>{
    return (val)=>{
        if(isStringNullOrEmpty(val)) return null;
        if(hasDigit(val)) return message;
        return null;
    }
}

export const positiveNumberValidator = (message) =>{
    return (val)=>{
        if(isStringNullOrEmpty(val)) return null;
        if(!isPositiveNumber(val)) return message;
        return null;
    }
}

export const emailValidator = (message) =>{
    return (val)=>{
        if(isStringNullOrEmpty(val)) return null;
        if(!isValidEmail(val)) return message;
        return null;
    }
}

export const mandatoryValidator = (message) =>{
    return (val)=>{
        if(val==null) return message;
        return null;
    }
}


export const txtMandatoryValidator = (message) =>{
    return (val)=>{
        if(isStringNullOrEmpty(val)) return message;
        return null;
    }
}

export const trueValidator = (message) =>{
    return (fieldValue)=>{
        if(!(fieldValue===true)) return message;
        return null;
    }
}