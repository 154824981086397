import {
    decimal2UpTo2Digits, decimal2UpTo2DigitsMessage,
    decimal3UpTo6Digits, decimal3UpTo6DigitsMessage,
    MESSAGE_REQUIRED,
    isNumber, MESSAGE_REQUIRED_NUMBER,
    MESSAGE_MAX_ALLOWED_CHARCATERS_100,
    MESSAGE_MIN_ALLOWED_DIGITS_2_TO_4
} from '../../../constants/MeterFormConstants';

export const validate = (values, params) => {

    const errors = {
        meteringEquipmentInformation: {

            meterCircumstancesOptions: "",
            dateOfLastValidation: "",
            meteringSystemTypeId: "",
            meterManufacturer: "",
            meterTypeModel: "",
            meterSerialNo: "",
            meterSize: "",
            pipeSize: "",
            pipeLengthUpstream: "",
            pipeLengthDownstream: "",
            longitude: "",
            latitude: "",
            channelType: "",
            channelWidth: "",
            meterDistanceFromSource: "",
            channelLengthUpstream: "",
            channelLlengthDownstream: "",
            date: "",
        }
    }
    console.log("in meter design validation");
    let clickSaveDraft = params.clickSaveDraft
    let clickNext = params.clickNext;
    if (clickSaveDraft == undefined)
        clickSaveDraft = values.clickSaveDraft;
    if (clickNext == undefined)
        clickNext = values.clickNext;
    console.log("draft:" + clickSaveDraft);

    if (clickSaveDraft === 1 || clickNext === 1) {
        debugger;
        if (values.meteringEquipmentInformation.channelWidth
            && !isNumber(values.meteringEquipmentInformation.channelWidth)) {
            errors.meteringEquipmentInformation.channelWidth = MESSAGE_REQUIRED_NUMBER;
        }
    }

    if (clickSaveDraft === 1 && clickNext === 0) {

        if (values.meteringEquipmentInformation.channelType
            && values.meteringEquipmentInformation.channelType.length > 100)
            errors.meteringEquipmentInformation.channelType = MESSAGE_MAX_ALLOWED_CHARCATERS_100;       

        if (values.meteringEquipmentInformation.meterDistanceFromSource
            && !isNumber(values.meteringEquipmentInformation.meterDistanceFromSource)) {
            errors.meteringEquipmentInformation.meterDistanceFromSource = MESSAGE_REQUIRED_NUMBER;
        }

        if (values.meteringEquipmentInformation.channelLengthUpstream
            && !decimal2UpTo2Digits.test(values.meteringEquipmentInformation.channelLengthUpstream)) {
            errors.meteringEquipmentInformation.channelLengthUpstream = decimal2UpTo2DigitsMessage;
        }
        if (values.meteringEquipmentInformation.channelLlengthDownstream
            && !decimal2UpTo2Digits.test(values.meteringEquipmentInformation.channelLlengthDownstream)) {
            errors.meteringEquipmentInformation.channelLlengthDownstream = decimal2UpTo2DigitsMessage;
        }

    }
    else if (clickSaveDraft == 0 && clickNext === 1 && values.meteringEquipmentInformation.formStatus != 2) {

        if (!values.meteringEquipmentInformation.channelType) {
            errors.meteringEquipmentInformation.channelType = MESSAGE_REQUIRED
        }

        if (!values.meteringEquipmentInformation.channelWidth) {
            errors.meteringEquipmentInformation.channelWidth = MESSAGE_REQUIRED
        }

        if (!values.meteringEquipmentInformation.meterDistanceFromSource) {
            errors.meteringEquipmentInformation.meterDistanceFromSource = MESSAGE_REQUIRED
        }

        if (!values.meteringEquipmentInformation.channelLengthUpstream) {
            errors.meteringEquipmentInformation.channelLengthUpstream = MESSAGE_REQUIRED
        }

        if (!values.meteringEquipmentInformation.channelLlengthDownstream) {
            errors.meteringEquipmentInformation.channelLlengthDownstream = MESSAGE_REQUIRED
        }

        if (values.meteringEquipmentInformation.channelType && values.meteringEquipmentInformation.channelType.length > 100)
            errors.meteringEquipmentInformation.channelType = MESSAGE_MAX_ALLOWED_CHARCATERS_100;

        if (values.meteringEquipmentInformation.meterDistanceFromSource && !isNumber(values.meteringEquipmentInformation.meterDistanceFromSource)) {
            errors.meteringEquipmentInformation.meterDistanceFromSource = MESSAGE_REQUIRED_NUMBER;
        }

        if (values.meteringEquipmentInformation.channelLengthUpstream && !decimal2UpTo2Digits.test(values.meteringEquipmentInformation.channelLengthUpstream)) {
            errors.meteringEquipmentInformation.channelLengthUpstream = decimal2UpTo2DigitsMessage;
        }

        if (values.meteringEquipmentInformation.channelLlengthDownstream && !decimal2UpTo2Digits.test(values.meteringEquipmentInformation.channelLlengthDownstream)) {
            errors.meteringEquipmentInformation.channelLlengthDownstream = decimal2UpTo2DigitsMessage;
        }
        if (!values.meteringEquipmentInformation.processUsedToCheckDesign) {
            errors.meteringEquipmentInformation.processUsedToCheckDesign = MESSAGE_REQUIRED;
        }

    }

    return errors;
}

export const validateCertificatePartD = (values, params) => {

    const errors = {
        meteringEquipmentInformation: {

            meterCircumstancesOptions: "",
            dateOfLastValidation: "",
            meteringSystemTypeId: "",
            meterManufacturer: "",
            meterTypeModel: "",
            meterSerialNo: "",
            meterSize: "",
            pipeSize: "",
            pipeLengthUpstream: "",
            pipeLengthDownstream: "",
            longitude: "",
            latitude: "",
            channelType: "",
            channelWidth: "",
            meterDistanceFromSource: "",
            channelLengthUpstream: "",
            channelLlengthDownstream: "",
            date: "",
            designingOwnEquipment: ""
        }
    }
    console.log("in meter design validation");
    let clickSaveDraft = params.clickSaveDraft
    let clickNext = params.clickNext;
    if (clickSaveDraft == undefined)
        clickSaveDraft = values.clickSaveDraft;
    if (clickNext == undefined)
        clickNext = values.clickNext;
    console.log("draft:" + clickSaveDraft);
    if (clickSaveDraft === 1 && clickNext === 0) {

    }
    else if (clickSaveDraft == 0 && clickNext === 1 && values.meteringEquipmentInformation.formStatus != 2) {
        debugger;

        if (!values.meteringEquipmentInformation.date) {
            errors.meteringEquipmentInformation.date = MESSAGE_REQUIRED
        }

        if (!values.meteringEquipmentInformation.designingOwnEquipment) {
            errors.meteringEquipmentInformation.designingOwnEquipment = MESSAGE_REQUIRED
        }
    }

    return errors;
}
