import React from 'react';
import { Redirect } from 'react-router-dom';
import {Dot, ArrowRight} from '../../../components/tools/Icons'
import ApprovalConsentProcess from '../../../components/common/ApprovalConsentProcess';
import { UneditableTextInput } from '../../../components/tools/BootstrapControls';
import moment from 'moment';
import LIDInfoPage from '../../../components/meterRegistration/LIDInfoPage';
import uiHelpers from '../../../helpers/uiHelpers';
import serviceUrls from '../../../constants/serviceUrls';
import * as apiCaller from '../../../helpers/apiCaller'

class StartCommisionProcess  extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            approval:null,
            consent:null
        }
    }

    approvalFound = (approval,consent) => {
        this.setState({approval,consent, showImportantInfo:true});
    }

    onResetClick = () => {
        this.setState({approval:null, consent:null})
    }

    onContinueClick =() =>{
        this.state.showImportantInfo = true;
        this.forceUpdate();
    }

    onInfoAcknowledged = async () => {
        uiHelpers.showProgressOnTop('Creating meter compliance process...');
        this.setState(Object.assign(this.state,{callingService:true}));
        await apiCaller.post(serviceUrls.meterCommission.createMeterCommissionProcess,{approvalNumber:this.state.approval.approvalNumber, consentId:this.state.consent.id})
        .then((result)=>{
            this.setState({redirectTo:`/compliance-process/${result.meterCommissionId}/site-registration`})
        }).finally(()=>{
            this.setState(Object.assign(this.state,{callingService:false}));
            uiHelpers.hideProgressOnTop();
        });
    }


    render() {
        if(this.state.redirectTo) return <Redirect to={this.state.redirectTo} />;
        return <>
            <h3 class="mb-3">Meter Compliance Process</h3>
            <div className='card mb-4'>
                <div className='card-body py-1 fs-90'>
                    <span className='text-primary'><Dot/></span><strong className='text-primary'>Find Approval &amp; Customer Authorisation</strong> 
                    <span className='px-2'><ArrowRight/></span>
                    <i className='text-secondary fs-80'>Site Registration</i>
                    <span className='px-2'><ArrowRight/></span>
                    <i className='text-secondary fs-80'>Commissioning &amp; Validation</i>
                </div>
            </div>

            
            {!this.state.approval && <ApprovalConsentProcess approvalFound={this.approvalFound}/>}
            {this.state.approval && this.state.showImportantInfo && <LIDInfoPage acknowledgeClick={this.onInfoAcknowledged}/>}
            
        </>
    }
}

export default StartCommisionProcess;