import React from 'react';
import moment from 'moment';

export default class ValidationApprovalHistory  extends React.Component {

    constructor(props) {
        super(props);
        this.state = {showApprovalHistory:false};
    }

    render = () => {
        if(!this.props.approvalHistories || this.props.approvalHistories.length<2) return null;
        const approvalHistories = this.props.approvalHistories;
        const form = this.props.approvalForm;
        return <div className='row mt-3'>
                <div className="col-md-4 py-2">Submissions History</div>
                <div className='col-md-8'>

                    <div class="form-check form-switch mb-3">
                        <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" checked={this.state.showApprovalHistory} onClick={()=>this.setState({showApprovalHistory:this.state.showApprovalHistory?false:true})} />
                        <label class="form-check-label" for="flexSwitchCheckDefault">Show/Hide history</label>
                    </div>
                    {this.state.showApprovalHistory && <div class="list-group">
                        {approvalHistories.filter(a=>a.id!=form.val("id")).map(a=><>
                            <div href="#" class="list-group-item">
                                <div class="d-flex w-100 justify-content-between mb-2">
                                    <p class="mb-1">
                                        <b className={a.status=='REJECTED' ? 'text-danger' : 'text-success'}>{a.status == 'REJECTED' ? "Not Approved" : "Approved"}</b>
                                    </p>
                                    <small class="text-muted">Requested on {moment((new Date(a.createdDate))).format('DD/MM/YYYY h:mma')}</small>
                                </div>
                                {!(a.formVersion > 1) && <p class="mb-1"><b>Outcome:</b> {a.comments}</p>}
                                {(a.formVersion > 1) && <>
                                    <ul class="list-group ">
                                        {a.scenarios.map(s=>{
                                            return <li class="list-group-item bg-light">
                                                <span className='text-primary'>{s.scenario}</span> <br/>
                                                <b>Outcome: </b> {s.outcome} 
                                            </li>
                                        })}
                                    </ul>
                                </>}
                                {a.documents.length > 0 && <table className='table table-striped table-sm fs-80'>
                                    <thead>
                                        <tr><th>File name</th><th style={{width:"350px"}}>Description</th></tr>
                                    </thead>
                                    <tbody>
                                        {a.documents.map(file=><tr>
                                            <td>
                                                <a target='_blank' href={process.env.REACT_APP_WEBAPI_URL + process.env.REACT_APP_FIELS_GET + "/" + file.id}>
                                                    {file.fileOriginalName}
                                                </a>
                                            </td>
                                            <td>{file.description}</td>
                                        </tr>)}
                                    </tbody>
                                </table>}
                            </div>
                        </>)}
                    </div>}
                </div>
            </div>
    }
}