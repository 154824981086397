import React from 'react';

export const LoadingPage = (props) => {
    return <div className='py-3'>
        <p class="placeholder-glow"><span class="placeholder col-12"></span></p>
        <p class="placeholder-glow"><span class="placeholder col-3"></span></p>
        <p class="placeholder-glow"><span class="placeholder col-5"></span></p>
        <p class="placeholder-glow"><span class="placeholder col-8"></span></p>
        <p class="placeholder-glow"><span class="placeholder col-4"></span></p>
        <p class="placeholder-glow"><span class="placeholder col-9"></span></p>
        <p class="placeholder-glow"><span class="placeholder col-5"></span></p>
        <p class="placeholder-glow"><span class="placeholder col-10"></span></p>
        <p class="placeholder-glow"><span class="placeholder col-4"></span></p>
    </div>
}

export const AsteriskRed = () => <span className='text-danger'>*</span>