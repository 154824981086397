import Authentication from '../../../helpers/Authentication';
import axios from 'axios';
import {removeEmptyElementsFromArray} from '../../../constants/MeterFormConstants'
import uiHelpers from '../../../helpers/uiHelpers';
const authentication = new Authentication();

const UPLOAD = '[Certificate] UPLOAD'

export const getMeterNumbers = async (workApprovalNumber, esidNumber) => {

    const options = {
        url: process.env.REACT_APP_WEBAPI_URL + process.env.REACT_APP_DATALAKE_GET_METER_NUMBERS + "?waapprovalno=" + workApprovalNumber + "&esid=" + esidNumber,
        method: 'GET',
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8',
            'Authorization': 'Bearer ' + authentication.getToken()
        }
    };
    
    var payloadData = {
        meterNumberExternalList: [],
        success: false,
        code: ''
    }

    try {
        const response = await axios(options);
        const data = await response.data;
        console.log("printing values");
        console.log(data);
        

        if (!data.data) {
            payloadData.success = false;
            //alert("Error in loading Data");
        }
        else {
            //console.log("data return");
            //console.log(data);
            //console.log(JSON.stringify(data));
            payloadData.success = true;
            var meterNumberExternalList = [];

            for (var i = 0; i < data.data.length; i++) {
                var payload = {
                    id: 0,
                    name: '',
                    meterId: '',
                    meterNumber: '',
                    waterSupplyWorkApprovalNo: '',
                    esid: '',
                    meterNumberGUID: '',
                    meterCategoryDesc: '',
                    meterTypeDesc: '',
                    meterSizeDesc: '',
                    meterSize: '',
                    meterSerialNumber: '',
                    meterSiteId: '',
                    endMeterReading: ''

                }

                payload.id = data.data[i].METER_ID;
                payload.name = data.data[i].METER_ID + "-" + data.data[i].METER_NO
                //payload.meterId = data.data[i].METER_ID;
                //payload.meterNumber = data.data[i].METER_NO;
                payload.meterId = data.data[i].METER_ID;
                payload.meterNumber = data.data[i].METER_NO;
                payload.waterSupplyWorkApprovalNo = data.data[i].LICENSE;
                payload.esid = data.data[i].EXTRACTION_SITE_ID;
                payload.meterNumberGUID = data.data[i].GUID;
                payload.meterCategoryDesc = data.data[i].METER_CATEGORY_DESC;
                payload.meterTypeDesc = data.data[i].METER_TYPE_DESCRIPTION;
                payload.meterSizeDesc = data.data[i].METER_SIZE_DESC;
                payload.meterSize = data.data[i].SIZE_IN_MM;
                payload.meterSerialNumber = data.data[i].SERIAL_NUMBER;
                payload.meterSiteId = data.data[i].METER_SITE_ID
                payload.endMeterReading = data.data[i].END_METER_READING
                meterNumberExternalList.push(payload);
            }
            if (meterNumberExternalList.length > 0) {
                //payloadData.success = true;
                payloadData.meterNumberExternalList = meterNumberExternalList;
            }
            console.log("meter_list_from_api"+JSON.stringify(meterNumberExternalList,null,2));
        }
    } catch (error) {
        payloadData.success = false;
        if (!error.success) {
            if (error.code != undefined || error.code != null || error.code != "") {
                payloadData.code = error.code
            }
        }
    }
    return payloadData;
}

export const dropdownDataFromReferenceData = (referenceData, category, header="Select...", code="text") => {
    let options =  referenceData.filter(e=> e.category === category).map(ele => { let obj =  { id: code=="text"?ele.code:parseInt(ele.code), name: ele.name}; return obj});
    if(header == "") {
        return options;
    }
    return [{id: '', name: header},...options];
}

export const getNamesForOptions = (option, names) => {
    let retVal = [];
    debugger;
    if(!option || option == '') {
        return retVal;
    }
    let options = [];
    if(Array.isArray(option)) {
        options = option;
    } else {
        options = option.split(",");
    }
    
    for(let i=0;i<options.length; i++) {
        if(options[i] && options[i].length>0 && !isNaN(parseInt(options[i]))) {
            console.log("this_op" + options[i]);
        retVal.push(getNameForOption(options[i], names));
        }
    } 
    return retVal.join(",");
}

export const getNameForOption = (option, names) => {
    let retVal = "";
    names.map((st) => {      
             
        if(st.id == option) {
            retVal =  st.name;
        }
    })
    return retVal;
}



let decode = (esidStatus) => {
   console.log("esidStatus is: "+ esidStatus)    
    return (esidStatus && esidStatus==='A')?"Active":"Inactive";
}

let transform = (obj) => {   
    let retval =  {
    "id": obj.EXTRACTION_SITE_ID,
    "name": obj.EXTRACTION_SITE_ID,
    "status": decode(obj.STATUS),
    "workTypeDescription": obj.WORK_TYPE_DESCRIPTION,
    "gdaLongitude": obj.GDA_LONGITUDE,
    "gdaLatitude": obj.GDA_LATITUDE,
    "worksId": obj.WORKS_ID,
    "lotDP":removeEmptyElementsFromArray([obj.LOT_NUM ,obj.SECTION_NUM, obj.PLAN_NUM ,obj.PLAN_TYPE]).join('/'),
    "noOfWorks": obj.NO_OF_WORKS
    };
    console.log("return from transform is " + JSON.stringify(retval));
    return retval;
}


export const approvalSearch = async (workApprovalNumber) => {
    const options = {
        url: process.env.REACT_APP_WEBAPI_URL + process.env.REACT_APP_DATALAKE_APPROVAL_SEARCH,
        method: 'POST',
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8',
            'Authorization': 'Bearer ' + authentication.getToken()
        },
        data: {
            workApprovalNo: workApprovalNumber            
        }
    };

    let payload = {        
       data: [],
        success: false,
        code: ''
    }  

    try {
        const response = await axios(options);
        const result = await response.data;
        console.log("search data");
        console.log("response from approvalSearch "+ JSON.stringify(response,2,null));
        
        if (result.data) {           
            payload.success = true;
            payload.data = result;            
        }

    }catch (error) {
        payload.success = false;        
        if (error.code != undefined || error.code != null || error.code != "") {
            payload.code = error.code
        }        
    }
    return payload;
}



export const deleteDraftForm = async (id, beforeSend, success, complete,onError) => {

    try {
        var axiosConfig = {
            baseURL: process.env.REACT_APP_WEBAPI_URL,
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization': 'Bearer ' + authentication.getToken()
            }
        };

        if(beforeSend) beforeSend();

        axios.post('/meterFormCommon/deleteDraft', {id: id}, axiosConfig )
        .then(function (response) {
            if(response.data.success) {
                if(success){
                    success();
                }
            } else {
                alert(response.data.message);
            }
            
        }).catch(function (error) {
            onError(error)
        }).then(function () {
            if(complete){
                complete();
            }
        });
    }
    catch (error) {
        console.log(error);
        uiHelpers.showErrorTop('We have server error when deleting the form, please try again or contact system administrator');
    }
}

export const deleteDraftAHDForm = async (id, beforeSend, success, complete) => {

    try {
        var axiosConfig = {
            baseURL: process.env.REACT_APP_WEBAPI_URL,
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Authorization': 'Bearer ' + authentication.getToken()
            }
        };

        if(beforeSend) beforeSend();
        let url = '/fphvalidation/deleteDraft?cid='+id;
        debugger;
        axios.get(url,  axiosConfig )
        .then(function (response) {
            if(response.data.success) {
                if(success){
                    success();
                }
            } else {
                alert(response.data.message);
            }
            
        }).catch(function (error) {
            alert('Server error when deleting the form, please try again or contact system administrator. Error msg from server : ' + error.message);
        }).then(function () {
            if(complete){
                complete();
            }
        });
    }
    catch (error) {
        console.log(JSON.stringify(error));
        alert('We have server error when deleting the form, please try again or contact system administrator');
    }
}

export const deleteDraftFormGeneric = async (id, beforeSend, success, complete, url) => {

    try {
        var axiosConfig = {
            baseURL: process.env.REACT_APP_WEBAPI_URL,
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Authorization': 'Bearer ' + authentication.getToken()
            }
        };

        if(beforeSend) beforeSend();        
        debugger;
        axios.get(url,  axiosConfig )
        .then(function (response) {
            if(response.data.success) {
                if(success){
                    success();
                }
            } else {
                alert(response.data.message);
            }
            
        }).catch(function (error) {
            alert('Server error when deleting the form, please try again or contact system administrator. Error msg from server : ' + error.message);
        }).then(function () {
            if(complete){
                complete();
            }
        });
    }
    catch (error) {
        console.log(JSON.stringify(error));
        alert('We have server error when deleting the form, please try again or contact system administrator');
    }
}



