import React from 'react';
import { ReactSelect, InputText, Popover, InputTextWithEndAddOn } from '../../components/tools/BootstrapControls'


class LIDSection extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {

    }

    render() {
        return <>

            {(this.props.displayScallingFactor() || this.props.displayMeterUnits()) && <div className='row mb-3'>
                {this.props.displayScallingFactor() && <div className='col-sm-6'>
                    <InputText
                        label={<>
                            Scaling factor - (Refer to Meter and LID Manufacturer) <span className='text-danger'>*</span>
                            <Popover
                                className="text-primary"
                                content="<p>
                            Each pulse LID needs to be configured with a scaling factor. The scaling factor is the number that tells the LID how much water has been pumped each time it receives a pulse from the meter. Each meter manufacturer has different configurations depending on the diameter of the pump the meter is attached to.
                            </p>
                            <p>
                            It is recommended that you work with the meter and LID manufacturer directly to ensure you set the correct scaling factor for the pulse output of the meter.
                            </p>
                            It is vital that the scaling factor is set correctly. If not, the usage data will be communicated to WaterNSW incorrectly, your customer will not be compliant with the Regulations and they will likely hold you responsible for correcting any errors."
                            />
                        </>}
                        error={this.props.fieldErrors.meterScalingFactor}
                        id="scalingfactor"
                        onChange={this.props.onChangeOMeterScalingFactor}
                        value={this.props.meterRegistration.meterScalingFactor}
                        readOnly={this.props.meterRegistration.meterType == 2 || this.props.meterRegistration.formStatus >= 1 || this.props.readOnly}
                    />
                </div>}
                {this.props.displayMeterUnits() && <div className='col-sm-6'>
                    <ReactSelect
                        id="meterFlowUnits"
                        label={<>Unit of measure transmitted by LID <span className='text-danger'>*</span></>}
                        onChange={this.props.onChangeOfMeterFlowUnit}
                        value={this.props.meterRegistration.meterFlowUnits}
                        disabled={this.props.meterRegistration.formStatus >= 1 || this.props.readOnly}
                        error={this.props.fieldErrors.meterFlowUnits}
                        options={this.props.meterflowUnits}
                    />
                </div>}
            </div>}

            <div className='mb-3'>
                <label class="form-label">
                    Is the LID being used for Telemetry? <span className='text-danger'>*</span>
                    <Popover
                        className="text-primary"
                        content={"Select No <strong>only if</strong> this LID will not be used to send Telemetry to the DAS. This will indicate that the meter is being made 'Telemetry ready' but will not be setup to send Telemetry."}
                    />
                </label>
                <div className='ps-2'>
                    <div class="form-check">
                        <input
                            className={`form-check-input ${this.props.fieldErrors.isLidTelemetry && this.props.fieldErrors.isLidTelemetry != "" ? "is-invalid" : ""}`}
                            type="radio" name="isLidTelemetry" id="isLidTelemetryYes"
                            autocomplete="off" value="YES"
                            checked={this.props.meterRegistration.isLidTelemetry === true ? true : false}
                            onChange={() => { this.props.onChangeisLidTelemetry(true); }}
                            disabled={this.props.meterRegistration.formStatus >= 1 || this.props.readOnly}
                        />
                        <label class="form-check-label" for="isLidTelemetryYes">
                            YES
                        </label>
                    </div>
                    <div class="form-check">
                        <input
                            className={`form-check-input ${this.props.fieldErrors.isLidTelemetry && this.props.fieldErrors.isLidTelemetry != "" ? "is-invalid" : ""}`}
                            type="radio" name="isLidTelemetry" id="isLidTelemetryNo"
                            autocomplete="off" value="NO"
                            checked={this.props.meterRegistration.isLidTelemetry === false ? true : false}
                            onChange={() => { this.props.onChangeisLidTelemetry(false); }}
                            disabled={this.props.meterRegistration.formStatus >= 1 || this.props.readOnly}
                        />
                        <label class="form-check-label" for="isLidTelemetryNo">
                            NO
                        </label>
                        <div class="invalid-feedback">{this.props.fieldErrors.isLidTelemetry}</div>
                    </div>
                </div>
            </div>



        </>
    }

}

export default LIDSection;
