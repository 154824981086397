import React from 'react';
import Authentication from '../../helpers/Authentication'
import * as bootstrap from 'bootstrap'
import axios from 'axios';
import uiHelpers from '../../helpers/uiHelpers';
import {Trash, PenSquare, Refresh} from '../../components/tools/Icons'
import {InputText} from '../../components/tools/BootstrapControls'
import {nulToEmptyAndTrim} from '../../helpers/formatters';


const authentication = new Authentication();

class ReferenceData extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            refereceData : [],

            formMode : 'NEW',
            form : {
                id: null,
                name : '',
                category : '',
                code : '',
                module : ''   
            },
            formError : {
                name:null,
                category:null,
                code:null,
                module:null,
                overall:null
            },

            deletingId : null
        }
    }

    ajaxConfig = {
        baseURL: process.env.REACT_APP_WEBAPI_URL,
        headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authentication.getToken() }
    };

    #formModal;
    #deleteConfirmationModal;
    async componentDidMount() {
        this.#formModal = new bootstrap.Modal(document.getElementById('formModal'), {
            keyboard: false
        });
        this.#deleteConfirmationModal = new bootstrap.Modal(document.getElementById('deleteConfirmationModal'), {
            keyboard: false
        })
        this.refresh();
        
    }   

    refresh = async ()=>{
        uiHelpers.showProgressOnTop('Loading referece data...');
        this.setState({loading:true});
        try {
            var response = await axios.get('/admin/get-all-reference-data',this.ajaxConfig);
            this.setState({refereceData : response.data.data})
            uiHelpers.hideProgressOnTop();
        } catch(e){
            console.log(e);
            uiHelpers.showErrorTop("Error on loading referece data...");
        }
        this.setState({loading:false});
    }


    newDataModal = ()=>{
        this.setState({ form : {
                id: null,
                name : '',
                category : '',
                code : '',
                module : ''   
            },
            formError : {
                name:null,
                category:null,
                code:null,
                module:null,
                overall:null
            },
            formMode : 'NEW'
        });
        this.#formModal.show();
    }

    editDataModal = (record) =>{
        this.setState({ form : {
                id: record.id,
                name : record.name,
                category : record.category,
                code : record.code,
                module : record.module  
            },
            formError : {
                name:null,
                category:null,
                code:null,
                module:null,
                overall:null
            },
            formMode : 'EDIT'
        });
        this.#formModal.show();
    }

    deleteDataModal=(record)=>{
        this.state.deletingId = record.id;
        this.#deleteConfirmationModal.show();
    }

    saveChanges = async () => {

        if(!this.validateField()) return;

        this.setState({loading:true});
        
        try {
            var response;
            if(this.state.formMode == 'NEW') {
                response = await axios.post('/admin/add-reference-data',{
                    name : nulToEmptyAndTrim(this.state.form.name),
                    category : nulToEmptyAndTrim(this.state.form.category),
                    module : nulToEmptyAndTrim(this.state.form.module),
                    code : nulToEmptyAndTrim(this.state.form.code),
                },this.ajaxConfig);
            } else if(this.state.formMode == 'EDIT') {
                response = await axios.post('/admin/edit-reference-data',{
                    id : this.state.form.id,
                    name : nulToEmptyAndTrim(this.state.form.name),
                    category : nulToEmptyAndTrim(this.state.form.category),
                    module : nulToEmptyAndTrim(this.state.form.module),
                    code : nulToEmptyAndTrim(this.state.form.code),
                },this.ajaxConfig);
            }

            if(!response.data.success) {
                this.state.formError.overall = response.data.message;
            } else {
                this.state.refereceData = response.data.data;
            }
            this.setState({loading:false});
            this.#formModal.hide();

        } catch(e){
            console.log(e);
            uiHelpers.showErrorTop("Error occurred when deleting this record");
            this.setState({loading:false});
            this.#formModal.hide();
            return;
        }
    }

    changeField(value, field){
        this.state.form[field] = value;
        this.forceUpdate();
    }

    validateField = () =>{
        var result = true;
        if(nulToEmptyAndTrim(this.state.form.name) == '') {this.state.formError.name="Required";result=false}
        else this.state.formError.name=null;

        if(nulToEmptyAndTrim(this.state.form.category) == '') {this.state.formError.category="Required";;result=false}
        else this.state.formError.category=null;

        if(nulToEmptyAndTrim(this.state.form.code) == '') {this.state.formError.code="Required";;result=false}
        else this.state.formError.code=null;

        if(nulToEmptyAndTrim(this.state.form.module) == '') {this.state.formError.module="Required";;result=false}
        else this.state.formError.module=null;

        this.state.formError.overall = null;
        this.forceUpdate();

        return result;
    }

    delete =async () =>{
        this.setState({loading:true});
        try {
            var response = await axios.post('/admin/delete-reference-data',{id:this.state.deletingId},this.ajaxConfig);
            this.setState({refereceData : response.data.data})
        } catch(e){
            console.log(e);
            uiHelpers.showErrorTop("Error occurred when deleting this record");
        }
        this.#deleteConfirmationModal.hide();
        this.setState({loading:false});
    }


    render() {
        return <>
            <h3 className='mb-3'>Manage Reference Data</h3>

            <div class="card  mb-3" >
                <div className='card-body'>
                    <button className='btn btn-outline-primary me-3 btn-sm' disabled={this.state.loading} onClick={this.refresh}><Refresh/></button>
                    <button className='btn btn-outline-primary btn-sm' onClick={this.newDataModal} disabled={this.state.loading}>Create record</button>
                </div>
            </div>

            <hr/>

            <table className="table table-striped fs-90">
                <thead>
                    <tr>
                        <th style={{width:"20%"}}>
                            Module
                        </th>
                        <th style={{width:"20%"}}>
                            Category
                        </th>
                        <th style={{width:"10%"}}>
                            Code
                        </th>
                        <th>
                            Name
                        </th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.refereceData.map((record)=> <tr>
                        <td>
                            {record.module}
                        </td>
                        <td>
                            {record.category}
                        </td>
                        <td>
                            {record.code}
                        </td>
                        <td class="text-break">
                            {record.name}
                        </td>
                        <td>
                            <button onClick={()=>{this.editDataModal(record)}} disabled={this.state.loading} className='btn btn-outline-secondary btn-sm btn-icon-16'><PenSquare/></button>
                        </td>
                        <td>
                            <button onClick={()=>{  this.deleteDataModal(record)}} disabled={this.state.loading} className='btn btn-outline-secondary btn-sm btn-icon-16'><Trash/></button>
                        </td>
                    </tr>)}
                </tbody>
            </table>


            <div class="modal fade" id="formModal" tabindex="-1" aria-hidden="true" data-bs-keyboard="false">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">
                                {this.state.formMode == 'NEW' && 'New reference data'}
                                {this.state.formMode == 'EDIT' && 'Edit reference data'}
                            </h5>
                        </div>
                        <div class="modal-body">
                            <InputText 
                                label="Module"
                                className="mb-3"
                                value={this.state.form.module}
                                onChange={(e)=>{this.changeField(e.target.value, 'module')}}
                                disabled={this.state.loading}
                                error = {this.state.formError.module}
                            />
                            <InputText 
                                label="Category"
                                className="mb-3"
                                value={this.state.form.category}
                                onChange={(e)=>{this.changeField(e.target.value, 'category')}}
                                disabled={this.state.loading}
                                error = {this.state.formError.category}
                            />
                            <InputText 
                                label="Code"
                                className="mb-3"
                                value={this.state.form.code}
                                onChange={(e)=>{this.changeField(e.target.value, 'code')}}
                                disabled={this.state.loading}
                                error = {this.state.formError.code}
                            />
                            <InputText 
                                label="Name"
                                className="mb-3"
                                value={this.state.form.name}
                                onChange={(e)=>{this.changeField(e.target.value, 'name')}}
                                disabled={this.state.loading}
                                error = {this.state.formError.name}
                            />

                            {this.state.formError.overall && <div className='alert alert-danger'>
                                {this.state.formError.overall}
                            </div>}
                        </div>
                        <div class="modal-footer position-relative">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" disabled={this.state.loading}>Close</button>
                            <button type="button" class="btn btn-primary" disabled={this.state.loading} onClick={this.saveChanges}>Save</button>
                        </div>
                    </div>
                </div>
            </div>



            <div class="modal fade" id="deleteConfirmationModal" data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-body p-5">
                           <h5 className='text-center mb-5'>Do you want to delete this record?</h5>
                           <div className='text-center'>
                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" disabled={this.state.loading}>Cancel</button>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <button type="button" class="btn btn-primary" disabled={this.state.loading} onClick={this.delete}>Confirm</button>
                           </div>
                        </div>
                    </div>
                </div>
            </div>



        </>
    }

}

export default ReferenceData;


