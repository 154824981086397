import React, { Fragment } from 'react';
import Authentication from '../../helpers/Authentication'
import uiHelpers from '../../helpers/uiHelpers'
import axios from 'axios';
import moment from 'moment';


const authentication = new Authentication();
class ApprovalConsents extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            consentsLoaded : false,
            consents : [],
            approvalNumber : '',
            pdfDownloading : false
        }
    }

    ajaxConfig = {
        baseURL: process.env.REACT_APP_WEBAPI_URL,
        headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authentication.getToken() }
    };


    componentDidMount() {
        this.state.approvalNumber = this.props.approvalNumber;
        this.state.hidePDFButton = this.props.hidePDFButton;
        let currentContext = this;
        uiHelpers.showProgressOnTop("Loading consent data...")
        axios.post('/customerConsent/getConsentsByApprovalNumber', JSON.stringify({ 
            approvalNumber: this.props.approvalNumber
        }), this.ajaxConfig).then(function (response) {
            currentContext.setState(Object.assign(currentContext.state,{consents : response.data.data, consentsLoaded: true}));
            uiHelpers.hideProgressOnTop();
        }).catch(function (error) {
            uiHelpers.showErrorTop('Error happened on the server side when getting esids details.');
        })
    }   

    downloadPDF = () =>{
        let currentContext = this;

        
        currentContext.setState(Object.assign(currentContext.state,{pdfDownloading : true} ));
        uiHelpers.showProgressOnTop("Downloading pdf...")
        axios.post('/customerConsent/generatePDFForConsentReport', JSON.stringify({approvalNumber : this.state.approvalNumber}), {...this.ajaxConfig,responseType:'arraybuffer'}).then(function (response) {
            var mime = 'application/pdf';
            var filename = currentContext.state.approvalNumber   + '.pdf';
            console.log(response);
            var blob = new Blob([response.data], {type: mime || 'application/pdf'});

            if (typeof window.navigator.msSaveBlob !== 'undefined') {
                // IE workaround for "HTML7007: One or more blob URLs were
                // revoked by closing the blob for which they were created.
                // These URLs will no longer resolve as the data backing
                // the URL has been freed."
                window.navigator.msSaveBlob(blob, filename);
            }
            else {
                var blobURL = (window.URL && window.URL.createObjectURL) ? window.URL.createObjectURL(blob) : window.webkitURL.createObjectURL(blob);
                var tempLink = document.createElement('a');
                tempLink.style.display = 'none';
                tempLink.href = blobURL;
                tempLink.setAttribute('download', filename);
        
                // Safari thinks _blank anchor are pop ups. We only want to set _blank
                // target if the browser does not support the HTML5 download attribute.
                // This allows you to download files in desktop safari if pop up blocking
                // is enabled.
                if (typeof tempLink.download === 'undefined') {
                    tempLink.setAttribute('target', '_blank');
                }
        
                document.body.appendChild(tempLink);
                tempLink.click();
        
                // Fixes "webkit blob resource error 1"
                setTimeout(function() {
                    document.body.removeChild(tempLink);
                    window.URL.revokeObjectURL(blobURL);
                }, 200)
            }
            uiHelpers.hideProgressOnTop();
        }).catch(function (error) {
            console.log(error);
            uiHelpers.showErrorTop('Error happened when dowloading file.');
        }).then(function () {
            currentContext.setState(Object.assign(currentContext.state,{pdfDownloading : false} ));
        });
    }


    render() {
        if(!this.state.consentsLoaded)
            return <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>

        if(this.state.consentsLoaded)
            return <div>
                        {!this.state.hidePDFButton && <p>
                            {this.state.pdfDownloading && <span style={{ color: 'green' }}><i className="fa fa-spinner fa-spin" style={{ fontSize: '24px', color: 'green' }}></i></span>}
                            {!this.state.pdfDownloading && <button type="button" class="btn btn-primary" onClick={this.downloadPDF}>Download PDF</button>}
                        </p>}
                        <table class="table table-sm fs-90">
                            <tbody>
                                <tr class="table-primary">
                                    <th style={{width:'200px'}}>Requested by</th>
                                    <th style={{width:'150px'}}>Date Requested</th>
                                    <th style={{width:'150px'}}>Date Approved</th>
                                    <th>Consent Duration</th>
                                </tr>
                                {this.state.consents.map((item) =>{
                                    return <tr key={item.id}>
                                            <td>
                                                {item.createdBy.firstName} {item.createdBy.lastName}
                                            </td>
                                            <td>
                                                {item.requestedDate==null?"":moment((new Date(item.requestedDate))).format('DD/MM/YYYY')}
                                            </td>
                                            <td>
                                                {item.approvedDate==null?"":moment((new Date(item.approvedDate))).format('DD/MM/YYYY')}
                                            </td>
                                            <td>
                                                {
                                                    (item.authorizationPeriod==365) ? 
                                                    <span>1 year ({item.authorizationPeriod} days)</span>
                                                    :
                                                    (item.authorizationPeriod==180) ? 
                                                    <span>Half year({item.authorizationPeriod} days)</span>
                                                    :
                                                    (item.authorizationPeriod==90) ? 
                                                    <span>3 months({item.authorizationPeriod} days)</span>
                                                    :
                                                    (item.authorizationPeriod==30) ? 
                                                    <span>1 month ({item.authorizationPeriod} days)</span>
                                                    :
                                                    <span>{item.authorizationPeriod} days</span>
                                                }
                                            </td>
                                        </tr>
                                })}
                            </tbody>
                        </table>
                    </div>
    }

}

export default ApprovalConsents;
