import React from 'react';

export const GlossaryAccuracyNUM = <p>
    The new metering requirements are prescribed in the Water Management Act 2000 (WM Act) and the Water Management (General) Regulation 2018, and are set out in the NSW Non-Urban Water Metering Policy. This glossary explains terms in the regulations that are used in this form.<br />
    <strong>Application day </strong> means the day the mandatory metering equipment condition applies to an authority—i.e. your roll-out date. [Clause 228 (1)]<br />
    <strong>Authority</strong> means a water supply work approval, an access licence, or a licence or other entitlement under the Water Act 1912. [Clause 228 (1)]<br />
    <strong>Certified meter installer</strong> is a person who holds a current certification as a meter installer issued by Irrigation Australia Ltd. It is a category of duly qualified person. [Clause 236 (1)]<br />
    <strong>Certified practising hydrographer</strong>is a person who is listed as a certified practising hydrographer in the register of certified professionals kept by the Australian Hydrographers Association. [Clause 236 (1)]<br />
    <strong>Duly qualified person</strong> is a person that has the following qualifications, skills and experience to carry out specified work in connection with metering equipment: (a) certified meter installer (b) certified practising hydrographer (c) metering system designer, or (d) telemetry technician. [Clause 236 (2)]<br />
    <strong>Faulty metering equipment</strong> means metering equipment that is not operating properly or is not operating. [Clause 240]<br />
    <strong>Existing validation</strong> means that the metering equipment installed before 1 April 2019 was validated by a duly qualified person in accordance with AS 4747: (a) within five years before the application day for the equipment, or (b) within 12 months before the application day for the equipment in the case of metering equipment that is installed in connection with an open channel. [Clause 8 of Schedule 8]<br />
    <strong>Maintenance specifications</strong> set out the requirements for how metering equipment must be maintained. They are published in the NSW Government Gazette and on the NSW Department of Industry website. [Clause 2 (4) of Schedule 8]<br />
    <strong>Open channel</strong> means a channel or conduit used for conveying water that is not enclosed. [Clause 228 (1)]<br />
</p>;

export const GlossaryNUM = <p>
    The metering requirements are prescribed in the Water Management Act 2000 (WM Act) and the Water Management (General) Regulation 2018, and are set out in the NSW Non-Urban Water Metering Policy. This glossary explains terms used in the regulations when referring to the metering framework.<br />
    <strong>AS 4747</strong> means the Australian Standard AS 4747—2013, Meters for non-urban water supply. This standard is updated from time to time by Standards Australia. [Clause 228 (1)]<br />
    <strong>Authority</strong> means a water supply work approval, an access licence, or a licence or other entitlement under the Water Act 1912. [Clause 228 (1)]<br />
    <strong>Certified meter installer</strong> is a person who holds a current certification as a meter installer issued by Irrigation Australia Ltd. It is a category of duly qualified person. [Clause 236 (1)]<br />
    <strong>Duly qualified person</strong> is a person that has the following qualifications, skills and experience to carry out specified work in connection with metering equipment: (a) certified meter installer (b) certified practising hydrographer (c) metering system designer, or (d) telemetry technician. [Clause 236 (2)]<br />
    <strong>Faulty metering equipment</strong> means metering equipment that is not operating properly or is not operating. [Clause 240]<br />
    <strong>Existing validation</strong> means that the metering equipment installed before 1 April 2019 was validated by a duly qualified person in accordance with AS 4747: (a) within five years before the application day for the equipment, or (b) within 12 months before the application day for the equipment in the case of metering equipment that is installed in connection with an open channel. [Clause 8 of Schedule 8]<br />
    <strong>Maintenance specifications</strong> set out the requirements for how metering equipment must be maintained. They are published in the NSW Government Gazette and on the NSW Department of Industry website. [Clause 2 (4) of Schedule 8]<br />
    <strong>Open channel</strong>  means a channelor conduit used for conveying water that is not enclosed. [Clause 228 (1)]<br />
    <strong>Water take data</strong> means the flow rate and cumulative volume of water taken with respect to a data logger. [Clause 4 (6) of Schedule 8]
</p>;

export const PrivacyStatementNUM = <p>
    The personal information you provide on this form is subject to the Privacy &amp; Personal Information
    Protection Act 1989. It is being collected by NSW Department of Planning, Industry and Environment and
    will be used for purposes related to the NSW non-urban water metering policy.
    NSW Department of Planning, Industry and Environment may disclose your personal information to the
    Natural Resources Access Regulator and WaterNSW. NSW Department Planning, Industry and Environment
    will not disclose your personal information to anybody else unless authorised by law.
    The provision of this information is required to be supplied under the Water Management Act 2000.
    If you choose not to provide the requested information you may be in breach of the conditions of your
    water supply work approval, access licence, or licence or other entitlement under the Water Act 1912.
    You have the right to request access to, and correct details of, your personal information held by the
    department. Further information regarding privacy can be obtained from NSW Department of Planning,
    Industry and Environment website at dpie.nsw.gov.au/privacy or by contacting us at
    patiunit@planning.nsw.gov.au.
</p>;

export const GlossaryFPH = <p>Metering requirements are prescribed in the Floodplain Harvesting Measurement Guidelines.<br /><br />

        This glossary explains terms used when referring to storage metering.<br />

        <strong>Authority</strong> means a water supply work approval, an access licence, or a licence or other entitlement under the <i>Water Act 1912</i>.<br />
        <strong>Certified Storage Meter Installer and Validator (CSV)</strong> is a person who holds a current certification as a storage meter installer and validator issued by Irrigation Australia Ltd. It is a category of duly qualified person.<br />
        <strong>Data Acquisition System (DAS)</strong> is a cloud-based platform used by NSW Department of Planning, Industry and Environment, Water NSW and Natural Resources Access Regulator for the purposes of acquiring and storing data from water meters.<br />
        <strong>Duly Qualified Person (DQP)</strong> is a person as defined in the Dictionary to the <i>Water Management Act 2000</i> and in clause 236 of the <i>Regulation</i>. A Duly Qualified Person, is a person that has the prescribed qualifications, skills and experience to carry out work in connection with water measurement equipment, including:<br />
        <ul>
            <li>Certified Meter Installer (CMI)</li>
            <li>Certified Storage Meter Installer and Validator (CSV)</li>
            <li>Registered Surveyor as recognised by the NSW Board of Surveying and Spatial Information (BOSSI)</li>
            <li>Registered Engineer as recognised by the Engineers Australia</li>
        </ul>
        <strong>Existing storage metering equipment</strong> means storage metering equipment as specified in the Floodplain Harvesting Measurement - Existing storage metering equipment (2021) guideline. <br />
        <strong>Faulty storage metering equipment</strong> means a point of in-take meter, storage meter, LID or associated equipment that is not operating in accordance with the manufacturers specifications or is not operating.<br />
        <strong>Floodplain Harvesting Measurement Guidelines </strong> include:
        <ul>
            <li>Establishing survey benchmarks for floodplain harvesting measurement in NSW (2021)</li>
            <li>Floodplain Harvesting Measurement - Storage curves (2020)</li>
            <li>Floodplain Harvesting Measurement - Secondary (backup) Measurement Devices (2020)</li>
            <li>Floodplain Harvesting Measurement - Existing storage metering equipment (2021)</li>
            <li>Floodplain Harvesting Measurement - Point-of-intake measurement method (2021)</li>
            <li>Floodplain Harvesting - Storage Meter Installation, Calibration and Certification (IAL 2020)</li>
        </ul>
        <strong>Gauge board</strong> means a backup storage metering devise as specified in the Floodplain Harvesting Measurement – Secondary (backup) Measurement Devices (2020) guideline. <br />
        <strong>Local Intelligence Device (LID)</strong> is a combined data logger and telemetry unit that complies with the <i>Data Logging and Telemetry Specifications 2020</i>.
        A Local Intelligence Device stores and transmits the water take data to the data acquisition service (DAS).
        Only units that have met the <i>Data Logging and Telemetry Specifications 2020</i>
        are permitted to send data to the data acquisition service for data security and device robustness reasons.<br />
        <strong>Point of in-take metering equipment</strong> means a non-urban (flow) meter as specified in the Floodplain Harvesting Measurement – Point-of-intake measurement method (2021).<br />
        <strong>Storage meter</strong> is a device that measures storage water height as specified in the Floodplain Harvesting Measurement – Storage curves (2020). For the purposes of measuring floodplain harvesting, only radar and submersible sensors that have met the minimum acceptable specifications, and are listed devices on the Department’s website, are permitted for data security and device robustness reasons.<br />
        <strong>Water supply work approval holder</strong> is any property owner or person who holds a water supply work approval as specified in the Water Management (General) Amendment (Floodplain Harvesting Measurement) Regulation 2018 and <i>Water Management Act 2000</i> (WM Act).<br />
</p>;

export const PrivacyStatementFPH=<p>The personal information you provide on this form is subject to the Privacy &amp; Personal Information Protection Act 1989. It is being collected by Water NSW
        and will be used for purposes related to the NSW Floodplain Harvesting Measurement Policy. Water NSW may disclose your personal information to the Natural
        Resources Access Regulator and NSW Department of Planning, Industry and Environment. Water NSW will not disclose your personal information to anybody else
        unless authorised by law. The provision of this information is required to be supplied under the Water Management Act 2000. If you choose not to provide
        the requested information you may be in breach of the conditions of your water supply work approval, access licence, or licence or other entitlement under
        the Water Act 1912. You have the right to request access to, and correct details of, your personal information held by the department. Further information
        regarding privacy can be obtained from Water NSW website at <a href="http://www.waternsw.com.au/privacy">www.waternsw.com.au/privacy</a> or by contacting us
        at 1300 662 077
</p> ;