import React ,{useState} from 'react';

import { fileSizeUploadLimit, MESSAGE_UPLOAD_LIMITS, uploadOptionsForSecondaryEquipmentValidation as uploadOptions } from '../../../constants/MeterFormConstants'
import { connect } from 'react-redux'
import { Field, reduxForm, formValueSelector, change } from 'redux-form';

import { updateDocuments, updateDeleteDocuments, changeFormStatus , uploadFiles} from '../design/MeterDesignCertificate.Reducers';

import { SelectForRedux, ShowErrorInAlertForRedux}  from '../../tools/BootstrapControls'
import FileUploader from '../../common/FileUploader'
import { saveDraftAction, saveSubmitAction, scrollTofocusError } from "./Validations"
import 'redux';
import "react-datepicker/dist/react-datepicker.css";
import Authentication from '../../../helpers/Authentication';

let fileUploaderDisplayListUpdate;

let _validateFiles;

const validate = (values, props) => {
   let clickSaveDraft = (props.clickSaveDraft== undefined? values.clickSaveDraft:props.clickSaveDraft);
   let clickNext = props.clickNext== undefined ? values.clickNext : props.clickNext;

   if (clickSaveDraft == 0 && clickNext === 1 && values.secondaryEquipmentValidationData.formStatus != 2) {
      //alert(_validateFiles)
      if(_validateFiles) {
         if(!_validateFiles()){
            return {secondaryEquipmentValidationData : {fileType:"Please upload all manadatory supporting documents"}};
         }
      }
      
   }

   return {};
}

let CertificatePartE = props => {

   let {previousPage, handleSubmit, secondaryEquipmentValidationData, documents, loading, hasError } = props    


   //--------------------------------------
   const [renderCnt, setRenderCnt] = useState(0);
   
   const updateDisplayFileList = ()=>{

      if(!fileUploaderDisplayListUpdate) return;
      const newList = [];

      if(documents && documents.length!=0) {

         var option;
         for(var i = 0; i< documents.length; i++){
            if(documents[i].status=='new' || documents[i].status=='existing'){
               var option = uploadOptions.filter(option=>option.id==documents[i].documentType)[0];
               newList.push({
                  id: documents[i].id,
                  fileTypeDesc : option?option.name:"",
                  description: documents[i].description,
                  fileOriginalName: documents[i].fileOriginalName,
                  fileGUID: documents[i].fileGUID
               });
            }
         }

      }
      fileUploaderDisplayListUpdate(newList);
      setRenderCnt(renderCnt+1)
   }

   const fileUploaderFilesUploaded=(files)=>{
      
      var fileType = props.secondaryEquipmentValidationData.fileType;
      var docs = props.documents;

      for(var i = 0; i< files.length; i++){

         docs.push({
            id : files[i].id,
            documentType : fileType,
            description : files[i].description,
            fileOriginalName : files[i].fileOriginalName,
            fileGUID : files[i].fileGUID,
            status : "new"
         });
      }
      props.dispatch(change(props.form, 'documents', docs))
      updateDisplayFileList();
   }

   
   const fileUploaderFileDeleted=(file)=>{
      if(!props.documents) return;

      for(var i = 0; i< props.documents.length; i++){
         if(props.documents[i].id==file.id){
            if (props.documents[i].status == "new")
               props.documents[i].status = "newDeleted";
            else if (props.documents[i].status == "existing")
               props.documents[i].status = "existingDeleted";
         }
      }

      updateDisplayFileList();
   }
   //--------------------------------------

   const validateFiles =()=>{   
      var found;
      for (var k=0;k<uploadOptions.length;k++) {
         if(uploadOptions[k].id=="6") continue;
         else if(
            (
            props.secondaryEquipmentValidationData.certFor == 1 
            || 
            props.secondaryEquipmentValidationData.certFor == "1"
            )
            &&
            uploadOptions[k].id=='4'
         ) continue;

         found = false;
         for (var i=0;i<props.documents.length;i++) {
            if(props.documents[i].documentType == uploadOptions[k].id){
               found = true;
               break;
            }
         }
         if(!found) return false;
      }
      

      return true;
   }

   _validateFiles = validateFiles;
   
   
   const disableFields = (parseInt(secondaryEquipmentValidationData.formStatus) == 2) || props.disableEditing  || loading ? true : false;   

   if ((!props.secondaryEquipmentValidationData.fileType || props.secondaryEquipmentValidationData.fileType == 0) && uploadOptions.length > 0) {
      props.dispatch(change(props.form, 'secondaryEquipmentValidationData.fileType', "3"))
   }  

   return (
      <form onSubmit={handleSubmit}>            
         <h5 className='mb-3'>Supporting documentation</h5>                
         <p className='fw-bold'>The following supporting information is mandatory and must be loaded into the DQP portal: </p>

         

         <div className='my-3 ps-2'>
            {uploadOptions.map((option) => {
               if(option.id=='6') return null;
               else if(
                  (
                  props.secondaryEquipmentValidationData.certFor == 1 
                  || 
                  props.secondaryEquipmentValidationData.certFor == "1"
                  )
                  &&
                  option.id=='4'
               ) return null;

               return <div class="form-check">
                  <input class="form-check-input" type="checkbox" value="" id={"checklist_" + option.id} checked={props.documents.filter(doc=>( (doc.status=='new' || doc.status=='existing' ) && doc.documentType == option.id)).length > 0} />
                  <label class="form-check-label" for={"checklist_" + option.value}>
                     {option.name}
                  </label>
               </div>
            })}
         </div>

            
            
         <div className='row mb-3'>
            <div className='col-sm-7'>
               <Field
                  name="secondaryEquipmentValidationData.fileType"
                  component={SelectForRedux}
                  props={{ disabled: disableFields }}
                  value="secondaryEquipmentValidationData.fileType"
                  options={uploadOptions} />
            </div>
         </div>
         <FileUploader
            fileInputLabel={<strong>Upload your supporting documents here:</strong>}
            fileTableHeader={<p><strong>Your uploaded files: </strong></p>}
            warning={MESSAGE_UPLOAD_LIMITS}
            maxFileSizeMB={10}
            exceedFileSizeErrorMsg = "Your file is too big"
            disabled={disableFields}
            provideDisplayDocumentsUpdator={(updateFunction)=>{fileUploaderDisplayListUpdate = updateFunction;}}

            fileTableHeadComponent={<tr>
               <th style={{width:"80px"}}></th>
               <th style={{width:"280px"}}>Type</th>
               <th>File name</th>
               <th>Description</th>
            </tr>}

            fileTableRowCompponent={(file, index, deleteButton)=>{
               return <tr key={index}>
                  <td>
                     {deleteButton}
                  </td>
                  <td>
                     {file.fileTypeDesc}
                  </td>
                  <td>
                     <a target='_blank' href={process.env.REACT_APP_WEBAPI_URL + process.env.REACT_APP_FIELS_GET + "/" + file.id}>
                        {file.fileOriginalName}
                     </a>
                  </td>
                  <td>{file.description}</td>
            </tr>
            }}

            onFilesUploaded={fileUploaderFilesUploaded}
            askForDocumentUpdate={updateDisplayFileList}
            onFileDeleted={fileUploaderFileDeleted}
         />
            
         <div className="text-end">           

            {(parseInt(secondaryEquipmentValidationData.formStatus) == 1) && (!props.disableEditing) && <>
               <button type="button"  className="btn btn-danger me-3" disabled={loading} 
               data-bs-toggle="modal" data-bs-target="#deleteDraftModal">
                  Delete Draft
               </button>
               <button type="button"  className="btn btn-primary" disabled={loading}
                  onClick={() => { saveDraftAction(props); }}>
                  Save Draft
               </button>
               <div className='vr mx-3'></div>
            </>}
            
            <button type="button"  className="btn btn-warning me-3" disabled={loading} onClick={()=>{previousPage(secondaryEquipmentValidationData.formStatus)}}>
               Previous
            </button>
            {(parseInt(secondaryEquipmentValidationData.formStatus) == 1) && (!props.disableEditing) && 
            <button type="button" className="btn btn-success" disabled={loading}
                  onClick={() =>  { saveSubmitAction(props);  } }>
                  Submit
            </button>
            }

         </div>
      </form>
   )
}



CertificatePartE = reduxForm({
   destroyOnUnmount: false,
   forceUnregisterOnUnmount: true,   
   validate ,
   onSubmitFail: (errors, dispatch ) => {
      dispatch(change('certificate', 'hasError', true));
   },
   onSubmitSuccess:(result , dispatch )=>{
      dispatch(change('certificate', 'hasError', false));
      window.scrollTo(0, 0);
   }
})(

CertificatePartE)




CertificatePartE = connect(
   (state, props) => {
      // can select values individually

      const selectorCert = formValueSelector('certificate')
      const hasError = selectorCert(state, 'hasError')
      
      const selector = formValueSelector(props.form)
      const loadingFile = selector(state, 'loadingFile')      
      const masterDataValues = selector(state, 'mdata')
      const secondaryEquipmentValidationData = selector(state, 'secondaryEquipmentValidationData')
      
      const documents = selector(state, 'documents')
      const loading = selector(state, 'loading')
  
      const form = props.form
      return {       
         secondaryEquipmentValidationData,
         documents,         
         masterDataValues, 
         form,
         loadingFile,

         hasError,
         loading
      }
   }, {
      uploadFiles: (formData) => uploadFiles(formData),
      updateDeleteDocuments: (doc) => updateDeleteDocuments(doc),
      changeFormStatus: (payload, val) => changeFormStatus(payload, val),
      updateDocuments: (payload) => updateDocuments(payload)
   }
)(CertificatePartE)

export default CertificatePartE