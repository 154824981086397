import React from 'react';
import { Field, reduxForm, formValueSelector, change } from 'redux-form';
import { connect } from 'react-redux'

import 'react-redux';
import 'redux';
import "react-datepicker/dist/react-datepicker.css";
import { saveDraftAction, clickNextAction } from "./Validations"

import { InputTextForRedux , DatePickerForRedux}  from '../../tools/BootstrapControls'

let 
CertificatePartD = props => {

   const {
      handleSubmit, masterDataValues, updateRatingTableData, previousPage, loading
   } = props

   const disableFields = (parseInt(updateRatingTableData.formStatus) == 2) || props.disableEditing  || loading? true : false;

   const dateToday = new Date();
   if (updateRatingTableData.validationDate != null && updateRatingTableData.validationDate != "")
   updateRatingTableData.validationDate = new Date(updateRatingTableData.validationDate);

   if(updateRatingTableData.validationDate == null || updateRatingTableData.validationDate == ""){
      updateRatingTableData.validationDate = dateToday;
   }

   if (updateRatingTableData.certificateDate != null && updateRatingTableData.certificateDate != "")
   updateRatingTableData.certificateDate = new Date(updateRatingTableData.certificateDate);

   if(updateRatingTableData.certificateDate == null || updateRatingTableData.certificateDate == ""){
      updateRatingTableData.certificateDate = dateToday;
   }

   var thisDQP = masterDataValues.userProfile[0];
   console.log("dqp"+JSON.stringify(thisDQP));
   thisDQP = {...thisDQP, "fullName": thisDQP.user.firstName + ' ' + thisDQP.user.lastName};
   props.dispatch(change(props.form, 'dqpdata', thisDQP));

   return (
      <form onSubmit={handleSubmit}>            
         <h5 className='mb-3'>Certification</h5>

         <div className='card mb-3'>
            <div className='card-header fw-bold'>I certify that:</div>
            <div className='card-body'>
               <p className='fw-bold'>
               a) I am a duly qualified person under the Floodplain Harvesting Measurement Guidelines for the purpose of certifying the rating table / storage curve described in this certificate.
               </p>
               <p className='fw-bold'>
               b) The rating table / storage curve comply with the Floodplain harvesting measurement – storage curves guideline (2020).
               </p>
               <p className='fw-bold'>
               c) All information contained in this certificate is accurate, true and complete.
               </p>

               <hr className='my-4' />

               <div className='row mb-3'>
                  <div className='col-sm-6'>
                     <Field
                        name="dqpdata.fullName"
                        component={InputTextForRedux}                       
                        label="Name"
                        props={{ disabled: true }}
                     /> 
                  </div>
                  <div className='col-sm-6'>
                     <Field
                        name="dqpdata.cmino"
                        label="Registered Surveyor/Engineer licence number"
                        component={InputTextForRedux}
                        props={{ disabled: true}}
                        /> 
                  </div>
               </div>

               <div className='row mb-3'>
                  <div className='col-sm-6'>
                     <Field
                        name="dqpdata.businessName"
                        component={InputTextForRedux}                       
                        label="Business name"
                        props={{ disabled: true }}
                     />  
                  </div>
                  <div className='col-sm-6'>
                     <Field
                        name="dqpdata.abnOrAcn"
                        label="ABN or ACN"
                        component={InputTextForRedux}
                        props={{ disabled: true }}
                        />
                  </div>
               </div>

               <div className='row mb-3'>
                  <div className='col-sm-6'>
                     <Field
                        name="dqpdata.postalAddress.addressLine"
                        label="Address"
                        component={InputTextForRedux}
                        props={{ disabled: true}}
                        />
                  </div>
                  <div className='col-sm-6'>
                     <Field
                        name="dqpdata.phoneNumber"
                        label="Phone"
                        component={InputTextForRedux}
                        props={{ disabled: true }}
                        />
                  </div>
               </div>

               <div className='row mb-3'>
                  <div className='col-sm-6'>
                     <Field
                        name="dqpdata.user.emailAddress"
                        label="Email"
                        component={InputTextForRedux}
                        props={{ disabled: true }}
                     />
                  </div>
               </div>

               <div className='row mb-3'>
                  <div className='col-sm-3'>
                     <Field
                        name="updateRatingTableData.validationDate"
                        label="Date of validation"
                        handleChange={date => { props.change(props.form, 'updateRatingTableData.validationDate', date) }}
                        component={DatePickerForRedux}
                        props={{ disabled:disableFields, maxDate: new Date() }}
                        values={updateRatingTableData.validationDate}
                     />
                  </div>
                  <div className='col-sm-3'></div>
                  <div className='col-sm-3'>
                     <Field
                        name="updateRatingTableData.certificateDate"
                        label="Date"
                        component={DatePickerForRedux}
                        props={{ disabled: true, maxDate: new Date()}}
                        values={updateRatingTableData.certificateDate}
                     />  
                  </div>
               </div>

               <p>
                  <i>
                  It is an offence under section 91J of the Water Management Act 2000 for a person to make a statement or furnish information in connection with a level measurement record the person knows to be false or misleading.
                  </i>
               </p>


            </div>
         </div>
        
         <div className="text-end">
            {(parseInt(updateRatingTableData.formStatus) == 1)  && (!props.disableEditing) && <>
               {updateRatingTableData.id!=0 && <button type="button"  className="btn btn-danger me-3" disabled={loading} 
                  data-bs-toggle="modal" data-bs-target="#deleteDraftModal">
                  Delete Draft
               </button>}
               <button type="button"  className="btn btn-primary" disabled={loading}
                  onClick={() => { saveDraftAction(props); }}>
                  Save Draft
               </button>
               <div className='vr mx-3'></div>
            </>}
            <button type="button"  className="btn btn-warning me-3" disabled={loading} onClick={()=>{previousPage(updateRatingTableData.formStatus)}}>
               Previous
            </button>
            <button type="button" className="btn btn-success" disabled={loading}
                  onClick={() => { clickNextAction(props); }}>
                  Next
            </button>
         </div>
      </form>
   )
}


CertificatePartD = reduxForm({
   destroyOnUnmount: false,
   forceUnregisterOnUnmount: true,
   validate : ()=>{return {}},
   onSubmitFail: (errors, dispatch ) => {
      dispatch(change('certificate', 'hasError', true));
   },
   onSubmitSuccess:(result , dispatch )=>{
      dispatch(change('certificate', 'hasError', false));
      window.scrollTo(0, 0);
   }
})(
CertificatePartD)


CertificatePartD = connect(
   (state, props) => {
      // can select values individually
      const selector = formValueSelector(props.form)
      const masterDataValues = selector(state, 'mdata')
      const updateRatingTableData = selector(state, 'updateRatingTableData')
      const documents = selector(state, 'documents')
      const loading = selector(state, 'loading')
      const form = props.form
      return {
         masterDataValues,
         updateRatingTableData,
         documents,
         form,
         loading

      }
   }
)(
CertificatePartD)

export default 
CertificatePartD