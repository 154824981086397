import React from 'react';



class LIDInfoPage extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {

    }   

    render() {
        return <>
            <h5 className='mb-3'>Important information for Meter Compliance Process</h5>
            <p>
            This information is to assist you to select and configure an appropriate Local Intelligence Device (<strong>LID</strong>) for your customer.
            </p>
            <p>
                <strong class="text-primary">What are the differences between a Pulse and Modbus LID?</strong>
            </p>

            <table class="table mb-3">
                <tbody>
                    <tr>
                        <th colSpan="2" className='table-primary text-center p-2 text-primary'>How they record and report water take</th>
                    </tr>
                    <tr>
                        <th className='table-secondary w-25'>Modbus LID</th>
                        <td>
                        The Modbus LID records the cumulative meter reading <strong>hourly</strong> and sends these readings to the WaterNSW Data Acquisition Service <strong>(DAS)</strong> once a day. The Modbus transmits the exact value of cumulative water usage you see on the meter.
                        </td>
                    </tr>
                    <tr>
                        <th className='table-secondary'>Pulse LID</th>
                        <td>
                        The Pulse LID calculates water usage by counting the number of pulses it receives, then multiplying that value by the scaling factor. Unlike the Modbus, Pulse LIDs only capture and transmit the volume of water used <strong>daily</strong> to the DAS. 
                        </td>
                    </tr>
                </tbody>
            </table>

            <table class="table mb-3">
                <tbody>
                    <tr>
                        <th colSpan="2" class="table-primary text-center p-2 text-primary">What key information is needed to configure each LID?</th>
                    </tr>
                    <tr>
                        <th class="table-secondary w-25">Modbus LID</th>
                        <td>
                        <p><strong>Unit of measure</strong></p>
                        <p>The key factor you need to configure correctly is the unit of measure used by the meter. If this is configured incorrectly, your customer’s usage will not be recorded accurately. For example, if you select ‘megalitre’ (ML) as the unit of measure when it should be a ‘kilolitre’ (kL), every kL used by your customer will be recorded as an ML, causing a significant error between what is used and what is reported. </p>
                        </td>
                    </tr>
                    <tr>
                        <th class="table-secondary">Pulse LID</th>
                        <td>
                        <p><strong>Scaling Factor</strong></p>
                        <p>Each pulse LID needs to be configured with a scaling factor. The scaling factor is the number that tells the LID how much water has been pumped each time it receives a pulse from the meter. Each meter manufacturer has different configurations depending on the diameter of the pump the meter is attached to. </p>
                        <p>It is recommended that you work with the meter and LID manufacturer directly to ensure you set the correct scaling factor for the pulse output of the meter.</p>
                        <p>It is vital that the scaling factor is set correctly. If not, the usage data will be communicated to WaterNSW incorrectly, your customer will not be compliant with the Regulations and they will likely hold you responsible for correcting any errors.</p>

                        <p><strong>Meter Unit of measure</strong></p>
                        <p>You also need to correctly configure the unit of measure used by the meter. If this is set incorrectly, your customer’s usage will not be recorded accurately. For example, if you select ‘megalitre’ (<strong>ML</strong>) when it should be a ‘kilolitre’ (<strong>kL</strong>), every kL used by your customer will be recorded as an ML, causing a significant error between what is used and what is reported. </p>

                        <p><strong>Capturing the meter read at the time of LID installation</strong></p>
                        <p>As Pulse LIDs only communicate the usage over a 24-hour period, it is vital that the meter reading at the time the LID is installed is recorded. If this does not happen, WaterNSW will not be able to establish the full water take for your customer. This will need to be corrected by a further visit to the site by you.  </p>
                        </td>
                    </tr>
                </tbody>
            </table>

            <p><strong class="text-primary">What happens if an LID is configured incorrectly?</strong></p>
            <p>If an LID is configured incorrectly, your customer’s water usage will not be transmitted to WaterNSW accurately. </p>
            <p><strong>What this means for the customer:</strong></p>
            <ul>
                <li>Your customer may not be compliant with the NSW Government’s non-urban metering rules</li>
                <li>Your customer may receive incorrect billings from WaterNSW until the issue is rectified and may be liable for all water consumed in the period until the correct usage is established; and</li>
                <li>Your customer may be at risk of inadvertently exceeding their water allocation.</li>
            </ul>
            <p><strong>What this means for the DQP:</strong></p>
            <ul>
                <li>You may be responsible for rectifying any errors; and </li>
                <li>You may be in breach of your obligations as a certified meter installer under Irrigation Australia Limited’s code of conduct.  </li>
            </ul>

            <p><strong className="text-primary">What if a customer chooses to voluntarily opt in or out of telemetry?</strong></p>
            <p>Customers with pumps below 200mm or groundwater works of any size are not required by the Regulations to install telemetry on their meter. However, many customers voluntarily choose to do so as they believe it is more efficient for them to have their meter usage captured daily and electronically sent to the DAS.</p>
            <p>If your customer voluntarily opts in to the telemetry service it is important that they understand the technical capabilities of their LID.  If a customer installs an LID that can only transmit their water usage through telemetry and they subsequently choose to opt out of the service, they will need to install an alternative LID that has in field data download capability.  Currently only a small selection of the Modbus LIDs offers this functionality.</p>

            <p><strong className="text-primary">Where to go for more information?</strong></p>
            <p>If you have further questions about the different types of LIDs or need installation support, please discuss this with the LID and/or meter manufacturer.</p>
            <p>Visit the 'Help' section of the DQP Portal for useful links and information about using the portal at <a href="dqp.waternsw.com.au/help" target="_blank">dqp.waternsw.com.au/help</a></p>
            <p>All other enquiries can be emailed to <a href="mailto:dqp.enquiries@waternsw.com.au">dqp.enquiries@waternsw.com.au</a></p>

            <div class="text-end" >
                <button type="button"  className="btn btn-primary" onClick={this.props.acknowledgeClick}>
                    Acknowledge and Continue
                </button>
            </div>
        </>


    }

}

export default LIDInfoPage;
