import React from 'react';
import Authentication from '../../helpers/Authentication'
import axios from 'axios';

import RegistrationsWithIssues from '../../components/admin/RegistrationsWithIssues'


class FormsWithIssues extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    }



    render() {
        return <>
            <h3 className='mb-3'>Forms with issues</h3>
            <RegistrationsWithIssues />
        </>
    }

}

export default FormsWithIssues;


