import React from 'react';
import Select from 'react-select';
import MeterAdminDeletion from './MeterAdminDeletion';

const adminOptions = [
    {key:"DELETE", label:"Delete Meter"}
]
class MeterAdmin  extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    onAdminOptionChange = (option) => {
        this.setState({selectedOption:option})
    }

    render() {
        return <>

            <div className='card card-body'>
                <div className='row mb-3'>
                    <div className='col-md-4 py-2'>
                        Action Type
                    </div>
                    <div className='col-md-8'>
                        <Select
                            getOptionLabel={(o)=>o.label}
                            getOptionValue={(o)=>o.key}
                            options={adminOptions}
                            onChange={this.onAdminOptionChange}
                            value={this.state.selectedOption}
                        />
                    </div>
                </div>

                {this.state.selectedOption && this.state.selectedOption.key=='DELETE' && <MeterAdminDeletion refresh={this.props.refresh} meter={this.props.meter} />}

            </div>
            
        </>
    }
}

export default MeterAdmin;
