import { MESSAGE_REQUIRED,MESSAGE_MAX_ALLOWED_CHARCATERS_500 } from '../../../constants/MeterFormConstants';

export const validateCertificatePartC = (values, params) => {

    const errors = {

        updateRatingTableData: {
            id:'',
            updateReason: '',
            updateDescription: '',
            surveyType: '',
            surveyDate: '',
            validationDate: '',
            certificateDate: '',
            createdBy: '',
            modifiedBy: '',
            confirmCert: '',    
            formStatus: ''
          }

    }    

    console.log("in updateRatingTableData validation");
    debugger;
    let clickSaveDraft = params.clickSaveDraft
    let clickNext = params.clickNext;
    if (clickSaveDraft == undefined) clickSaveDraft = values.clickSaveDraft;
    if (clickNext == undefined) clickNext = values.clickNext;
    console.log(`draft: + ${clickSaveDraft} clickNext ${clickNext}`);       

    if (clickSaveDraft === 1|| clickNext === 1) {
        if (values.updateRatingTableData.updateDescription && values.updateRatingTableData.updateDescription.length> 500) {       
            errors.updateRatingTableData.updateDescription = MESSAGE_MAX_ALLOWED_CHARCATERS_500;           
        }

        if (values.updateRatingTableData.surveyType && values.updateRatingTableData.surveyType.length> 500 ) {       
            errors.updateRatingTableData.surveyType = MESSAGE_MAX_ALLOWED_CHARCATERS_500;            
        }
     }
   
    
    if (clickSaveDraft == 0 && clickNext === 1 && values.updateRatingTableData.formStatus != 2) {
        if (!values.updateRatingTableData.updateReason || values.updateRatingTableData.updateReason =='' ) {       
            errors.updateRatingTableData.updateReason = MESSAGE_REQUIRED            
        }

        if (!values.updateRatingTableData.updateDescription || values.updateRatingTableData.updateDescription.trim() =='' ) {       
            errors.updateRatingTableData.updateDescription = MESSAGE_REQUIRED            
        }

        if (!values.updateRatingTableData.surveyType || values.updateRatingTableData.surveyType.trim() =='' ) {       
            errors.updateRatingTableData.surveyType = MESSAGE_REQUIRED            
        }

        if (!values.updateRatingTableData.surveyDate || values.updateRatingTableData.surveyDate =='' ) {       
            errors.updateRatingTableData.surveyDate = MESSAGE_REQUIRED            
        }
        
    }  

    console.log("errors from validateCertificatePartC"+JSON.stringify(errors));
    return errors;
}

export const validateCertificatePartE = (values, params) => {   
    return {};       
}





