import React from 'react';
import axios from 'axios';
import Authentication from '../helpers/Authentication'
import { reduxForm, formValueSelector, change } from 'redux-form'
import { connect } from 'react-redux'
import { load, save, findById, update } from '../components/certificates/accuracy/AccuracyExistingMeteringCertificate.Reducers';
import AuthorityHolderDetailsFormSection from '../components/certificates/common/AuthorityHolderDetailsFormSection';
import ApprovalDetailsFormSection from '../components/certificates/common/ApprovalDetailsFormSection';
import MeteringEquipmentInformationFormSection from '../components/certificates/accuracy/MeteringEquipmentInformationFormSection';
import AccuracyCheckFormSection from '../components/certificates/accuracy/AccuracyCheckFormSection';
import CertificationFormSection from '../components/certificates/accuracy/CertificationFormSection';
import ConfirmationFormSection from '../components/certificates/accuracy/ConfirmationFormSection';
import { deleteDraftForm } from '../components/certificates/common/MeterCertificateCommon';
import { closedConduitandProtableOptionsForAccuracyCert as closedConduitandProtableOptions, openChannelOptionsForAccuracyCert as  openChannelOptions } from '../constants/MeterFormConstants'
import {GlossaryAccuracyNUM, GlossaryFPH, PrivacyStatementNUM, PrivacyStatementFPH} from '../components/common/StaticContents';
import SearchCustomerForCertificates from '../components/certificates/common/SearchCustomerForCertificates';
import uiHelpers from '../helpers/uiHelpers';

import DisplayForRoles from '../components/tools/DisplayForRoles'
import MeterEquipInfoAudit from '../components/certificates/common/MeterEquipInfoAudit'
import CertificateFiles from '../components/certificates/common/CertificateFiles'
import CertificateAdmin from '../components/certificates/common/CertificateAdmin'
import {StepProgressBar} from '../components/tools/BootstrapControls'
import ReApplyConsentNotice from '../components/common/ReApplyConsentNotice'
import { userRole, addUserRoleUpdateNotifier } from '../helpers/userHelpers';

const authentication = new Authentication();

class AccuracyExistingMeteringCertificate extends React.Component {

  #currentUserEmail;
  #currentUserId;

  ajaxConfig = {
    baseURL: process.env.REACT_APP_WEBAPI_URL,
    headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authentication.getToken() }
  };

  constructor(props) {
    super(props)
    this.nextPage = this.nextPage.bind(this);
    this.previousPage = this.previousPage.bind(this);
    this.handleValidationDateChange = this.handleValidationDateChange.bind(this);
    this.handleMeterReadingDateChange = this.handleMeterReadingDateChange.bind(this);
    this.#currentUserEmail = authentication.currentUser().emails;

    this.state = {
      page: 0,
      validationDate: new Date(),
      meterReadingDate: new Date(),
      showInstructions: false,
      status: 1,
      meteringCircumstanceOptions:
        [
          { id: "1", name: "I have an existing validation that was done by a duly qualified person.", selected: false },
          { id: "2", name: "I have a manufacturer’s certificate.", selected: false },
          { id: "3", name: "I have an accuracy certificate provided by a duly qualified person.", selected: false }
        ],
      data: {},
      confirmingSubmission : false,
      CERT_NAME: 'Certificate of Accuracy for Existing meter',
      PrivacyStatement: PrivacyStatementNUM,
      Glossary: GlossaryAccuracyNUM,
      fphMode: false,
      tab : 'MAIN'
    }
    addUserRoleUpdateNotifier(() => {
      if(userRole.isSuperAdmin){
        this.forceUpdate();
      }
    });
  }

  

  async componentDidMount() {

    const currentContxt = this;
    axios.post('users/getprofile', JSON.stringify({ email: this.#currentUserEmail }), this.ajaxConfig).then(response => {
      currentContxt.#currentUserId = response.data.data.user.user.id;
      currentContxt.pageRefresh();
      
    }).catch(function (error) {
      console.log(error);
      uiHelpers.showErrorTop('Error occurred when reading your user info');
    });
  }

  pageRefresh = async(certificateId = null,page) =>{
    let search = window.location.search;
    let params = new URLSearchParams(search);

    if(!certificateId){
      certificateId = params.get('id');
    }

    //let fphmodeParam = params.get('fph');
    
    //if(fphmodeParam) {
    if(this.props.fphMode) {
      this.setFPHMode();     
    }

    if (certificateId != null) {

      if(page) {
        this.setState({ page : parseInt(page)});
      } else {
        this.setState({ page: this.state.page + 1 });
      }
      
      //this.props.dispatch(change(this.props.form, 'showSpinner', 1));
      uiHelpers.showProgressOnTop("Getting/Refreshing certificate data...");
      this.props.dispatch(change(this.props.form, 'loading', true));
      await this.props.dispatch(findById(certificateId));
      uiHelpers.hideProgressOnTop();
      this.props.dispatch(change(this.props.form, 'loading', false));

      //debugger;
      if (this.props.meterEquipInfo.formCategory == 'FPH'){
        this.setFPHMode();  
      }
     
    } else {
      try {
        //this.props.dispatch(change(this.props.form, 'showSpinner', 1));
        uiHelpers.showProgressOnTop("Loading data...");
        this.props.dispatch(change(this.props.form, 'loading', true));
        await this.props.dispatch(load());
        //this.props.dispatch(change(this.props.form, 'showSpinner', 0));
        uiHelpers.hideProgressOnTop();
        this.props.dispatch(change(this.props.form, 'loading', false));
      } catch (error) {
        //this.props.dispatch(change(this.props.form, 'showSpinner', 0));
        uiHelpers.showErrorTop("Error when getting data...", true, false);
      }
    }
  }

  nextPage = (values) => {
    //console.log("MeterAccuracyPage" + values.clickSaveDraft + "next--" + values.clickNext);
    this.saveDraft(values);
  }

  saveDraft = (values) => {
    if(this.state.fphMode) {
      this.props.dispatch(change(this.props.form, 'meteringEquipmentInformation.formCategory', 'FPH'));
    } else {
      this.props.dispatch(change(this.props.form, 'meteringEquipmentInformation.formCategory', 'NUM'));
    }

    if (parseInt(values.clickSaveDraft) === 1 && parseInt(values.clickNext) === 0) {
      //console.log("MeterAccuracyPage:saving draft");
      this.resetValues(values);

      //this.props.dispatch(change(this.props.form, 'showSpinner', 1));
      uiHelpers.showProgressOnTop("Saving your record...");
      this.props.dispatch(change(this.props.form, 'loading', true));
      if (values.meteringEquipmentInformation.id > 0)
        this.props.dispatch(update(values, this.state.page, this.pageRefresh));
      else
        this.props.dispatch(save(values, this.state.page, this.pageRefresh));
      //this.props.dispatch(change(this.props.form, 'showSpinner', 0));

    } else if (parseInt(values.clickSaveDraft) === 0 && parseInt(values.clickNext) === 1 && values.meteringEquipmentInformation.formStatus == 2) {
      this.resetValues(values);
      //this.props.dispatch(change(this.props.form, 'showSpinner', 1));
      uiHelpers.showProgressOnTop("Saving your record...");
      this.props.dispatch(change(this.props.form, 'loading', true));
      if (values.meteringEquipmentInformation.id > 0)
        this.props.dispatch(update(values, this.state.page, this.pageRefresh));
      else
        this.props.dispatch(save(values, this.state.page, this.pageRefresh));
      //this.props.dispatch(change(this.props.form, 'showSpinner', 0));
    } else {

      if(values.meteringEquipmentInformation.formStatus==2) {
        this.setState({ page: this.state.page + 1 });
      } else if(this.state.page == 4 && !this.state.confirmingSubmission )
        this.setState({ confirmingSubmission: true });
      else if(this.state.page == 4 && this.state.confirmingSubmission )
        this.setState({ confirmingSubmission: false, page: this.state.page + 1 });
      else 
        this.setState({ page: this.state.page + 1 });
    }
  }

  previousPage = (formStatus) => {
    if(formStatus && formStatus == 2) {
      this.setState({ page: this.state.page - 1 });
      //window.scrollTo(0, 0);
    } else if(this.state.page==5) {
      this.setState({ page: this.state.page - 1 , confirmingSubmission: true});
      //window.scrollTo(0, 0);
    } else {
      this.setState({ page: this.state.page - 1 });
      //window.scrollTo(0, 0);
    }
  }

  nextPageForSubmittedForm = () => {
    this.setState({ page: this.state.page + 1 });
  }

  goToFinalPageFromReviewPage= () =>{
    this.setState({ confirmingSubmission: false, page: 5 });
  }

  cancelConfrimSubmission = () => {
    this.setState({ confirmingSubmission: false });
  }

  cancelConfrimSubmissionAndGoToPage = (page) => {
    this.setState({ confirmingSubmission: false, page: page });
    window.scrollTo(0, 0);
  }

  handleValidationDateChange = (date) => {
    this.setState({ validationDate: date });
  }

  handleMeterReadingDateChange = (date) => {
    this.setState({ meterReadingDate: date });
  }

  HideShow = () => {
    this.setState({ showInstructions: !this.state.showInstructions });
  }

  resetValues = (values) => {
    if (parseInt(values.meteringEquipmentInformation.meteringSystemTypeId) != parseInt(this.props.masterDataValues.OpenChannelId)) {
      values.meteringEquipmentInformation.channelType = "";
      values.meteringEquipmentInformation.channelWidth = "";
      values.meteringEquipmentInformation.meterDistanceFromSource = "";
      values.meteringEquipmentInformation.channelLengthUpstream = "";
      values.meteringEquipmentInformation.channelLlengthDownstream = "";
      values.meteringEquipmentInformation.maxMeasuredDischarge = "";
      values.meteringEquipmentInformation.minMeasuredDischarge = "";
      values.meteringEquipmentInformation.maxMeasuredVelocity = "";
      values.meteringEquipmentInformation.minMeasuredVelocity = "";
      values.meteringEquipmentInformation.maxGaugeHeight = "";
      values.meteringEquipmentInformation.minGaugeHeight = "";
      values.meteringEquipmentInformation.maxError = "";
      values.meteringEquipmentInformation.minError = "";
      values.meteringEquipmentInformation.noOfMeasurementsTaken = "";
      values.meteringEquipmentInformation.maxGaugeHeight = "";
      values.meteringEquipmentInformation.accuracyConclusionOpenChannel = "";

    }
    else {
      values.meteringEquipmentInformation.pipeType = "";
      values.meteringEquipmentInformation.pipeSize = "";
      values.meteringEquipmentInformation.pipeConnectionType = "";
      values.meteringEquipmentInformation.pipeLengthUpstream = "";
      values.meteringEquipmentInformation.pipeLengthDownstream = "";
    }
  }

  deleteDraft = ()=>{
    if (this.props.meterEquipInfo.id != 0) {
      var currentContext = this;
      deleteDraftForm(
        parseInt(this.props.meterEquipInfo.id)
        , function(){
          currentContext.props.dispatch(change(currentContext.props.form, 'loading', true));
        }
        , function(){
          window.location = 'Home';
        }
        , function(){

        }, function(error){
          console.log(error);
          uiHelpers.showErrorTop('Error occurred when trying to delete the draft record ', true, false);
          currentContext.props.dispatch(change(currentContext.props.form, 'loading', false));
      });
    } else {
      uiHelpers.showErrorTop('You are accessing this function in a invalid way', true, false);
    }
  }

  setFPHMode() {
    this.setState({...this.state, 
      CERT_NAME: "Point of Intake Certificate of Accuracy for Existing meter",
      PrivacyStatement: PrivacyStatementFPH,
      Glossary: GlossaryFPH,
      fphMode: true
    });
  }

  render() {
    const { page, showInstructions, meteringCircumstanceOptions, confirmingSubmission, deleteLoading} = this.state
    const { form, loading , consentValid} = this.props;

    const needReApplyConsent = !userRole.isSuperAdmin && (this.props.meterEquipInfo && this.props.meterEquipInfo.formStatus==1 && consentValid===false && !(this.props.meterEquipInfo.createdBy && this.props.meterEquipInfo.createdBy.id != this.#currentUserId)) ? true : null;

    return <>

        <h3 className='mb-3'>{this.state.CERT_NAME}</h3>
        <p>
          <a className="btn btn-primary"  onClick={this.HideShow}>Privacy and Glossary</a>
        </p>
        {showInstructions && <div class="card card-body fs-90 mb-5">
          <h5 className='mb-3'>Privacy Statement</h5>
          {this.state.PrivacyStatement}
          <h5>Glossary of terms</h5>
          {this.state.Glossary}
        </div>}
        
        {!confirmingSubmission && page === 0 && <SearchCustomerForCertificates form={form} onSubmit={this.nextPage} />}

        {page > 0 && this.props.meterEquipInfo && <>
          <div class="card mb-3">
            <div class="card-body row">
                <div className='col-md-3'>
                  <label class="form-label">Record ID</label>
                  <input type="text" disabled={true} class="form-control" value={this.props.meterEquipInfo.id == 0 ? "Not saved" : this.props.meterEquipInfo.id} />
                </div>
                <div className='col-md-3'>
                  <label class="form-label">Form Status</label>
                  <input type="text" disabled={true} class="form-control" value={this.props.meterEquipInfo.formStatus==1?'Draft' : 'Submitted'} />
                </div>
                <div className='col-md-3'>
                  <label class="form-label">DQP</label>
                  <input type="text" disabled={true} class="form-control" value={this.props.meterEquipInfo.createdBy ? (this.props.meterEquipInfo.createdBy.firstName + " " + this.props.meterEquipInfo.createdBy.lastName) : ""} />
                </div>
            </div>
          </div>
          <ul class="nav nav-tabs mb-3">
              <li class="nav-item ">
                <a class={`nav-link ${this.state.tab == 'MAIN'?'active':''}`} aria-current="page" href="#"
                    onClick={(e)=>{e.preventDefault(); this.setState({ tab : 'MAIN'}); }}
                >Record details</a>
              </li>
              <li class="nav-item">
                <a class={`nav-link ${this.state.tab == 'AUDIT'?'active':''}`} href="#"
                    onClick={(e)=>{e.preventDefault(); this.setState({ tab : 'AUDIT'}); }}
                >Audit/Logs</a>
              </li>
              {this.props.meterEquipInfo.formStatus == 2 && <DisplayForRoles roles={{superAdmin : true, isCFOAdmin:true}}>
                <li class="nav-item">
                    <a class={`nav-link ${this.state.tab == 'FILES'?'active':''}`} href="#"
                      onClick={(e)=>{e.preventDefault(); this.setState({ tab : 'FILES'}); }}
                    >Files</a>
                </li>
              </DisplayForRoles>}
              {this.props.meterEquipInfo.formStatus == 2 && <DisplayForRoles roles={{superAdmin : true}}>
                <li class="nav-item">
                    <a class={`nav-link ${this.state.tab == 'ADMIN'?'active':''}`} href="#"
                      onClick={(e)=>{e.preventDefault(); this.setState({ tab : 'ADMIN'}); }}
                    >Admin</a>
                </li>
              </DisplayForRoles>}
          </ul>

          {this.state.tab == 'AUDIT' && <MeterEquipInfoAudit id={this.props.meterEquipInfo.id} /> }
                
          {this.state.tab == 'FILES' && <DisplayForRoles roles={{superAdmin : true, isCFOAdmin:true}}>
              <CertificateFiles 
                form={form} 
                certificateNameForSelector="meteringEquipmentInformation"
                fileTypeResolver={(document)=>{
                  let uploadOptions = closedConduitandProtableOptions;
                  if (parseInt(this.props.masterDataValues.OpenChannelId) == parseInt(this.props.meterEquipInfo.meteringSystemTypeId)) {
                    uploadOptions = openChannelOptions;
                  }
                  var option = uploadOptions.filter(option=>option.id==document.documentType)[0];
                  return <>{option?option.name:""}</>
                }}
              />
            </DisplayForRoles> 
          }
    
          {this.state.tab == 'ADMIN' && this.props.meterEquipInfo.formStatus == 2 && <DisplayForRoles roles={{superAdmin : true, isCFOAdmin:true}}>
            <CertificateAdmin id={this.props.meterEquipInfo.id} entityType="AccuracyCertificate" />
          </DisplayForRoles> }

          {this.state.tab == 'MAIN' && <>

            {needReApplyConsent && <ReApplyConsentNotice approvalNumber={this.props.approvedWorkDetails.waterSupplyWorkApprovalNo} />}

            {this.props.meterEquipInfo.formStatus == 1 && this.props.meterEquipInfo.createdBy && this.props.meterEquipInfo.createdBy.id != this.#currentUserId && <div className='alert alert-primary'>
              This form is owned by <strong>{this.props.meterEquipInfo.createdBy.firstName} {this.props.meterEquipInfo.createdBy.lastName}</strong> and cannot be edited
            </div>}

            <div className='my-5'>      
              <StepProgressBar 
                totalSteps={6} 
                currentStep={confirmingSubmission? 5 : page==5?6:page} 
                disableFutureSteps={this.props.meterEquipInfo.formStatus == 1} 
                stepClick={(page) =>{this.setState({page : page==6?5:page==5?4:page, confirmingSubmission : page==5  })}}/>
            </div>
            {!confirmingSubmission && page === 1 && <AuthorityHolderDetailsFormSection form={form} onSubmit={this.nextPage} disableEditing={(this.props.meterEquipInfo.createdBy && this.props.meterEquipInfo.createdBy.id != this.#currentUserId) || needReApplyConsent} />}
            {!confirmingSubmission && page === 2 && <ApprovalDetailsFormSection form={form} onSubmit={this.nextPage} previousPage={this.previousPage} disableEditing={(this.props.meterEquipInfo.createdBy && this.props.meterEquipInfo.createdBy.id != this.#currentUserId) || needReApplyConsent} />}
            {!confirmingSubmission && page === 3 && <MeteringEquipmentInformationFormSection form={form} onSubmit={this.nextPage} previousPage={this.previousPage} meteringCircumstanceOptions={meteringCircumstanceOptions} disableEditing={(this.props.meterEquipInfo.createdBy && this.props.meterEquipInfo.createdBy.id != this.#currentUserId) || needReApplyConsent} />}
            {!confirmingSubmission && page === 4 && <AccuracyCheckFormSection form={form} onSubmit={this.nextPage} previousPage={this.previousPage} status={this.state.status} nextPageForSubmittedForm={this.nextPageForSubmittedForm} disableEditing={(this.props.meterEquipInfo.createdBy && this.props.meterEquipInfo.createdBy.id != this.#currentUserId) || needReApplyConsent} />}
            {
              confirmingSubmission && 
              <ConfirmationFormSection 
                form={form} 
                onSubmit={this.nextPage} 
                cancelConfrimSubmissionAndGoToPage={this.cancelConfrimSubmissionAndGoToPage} 
                cancelConfrimSubmission={this.cancelConfrimSubmission} 
                goToFinalPageFromReviewPage={this.goToFinalPageFromReviewPage}
                disableEditing={(this.props.meterEquipInfo.createdBy && this.props.meterEquipInfo.createdBy.id != this.#currentUserId) || needReApplyConsent}
              />
            }
            {!confirmingSubmission && page === 5 && <CertificationFormSection form={form} onSubmit={this.nextPage} previousPage={this.previousPage} status={this.state.status}  disableEditing={(this.props.meterEquipInfo.createdBy && this.props.meterEquipInfo.createdBy.id != this.#currentUserId) || needReApplyConsent}  />}
          </>}
        </>}


        {/* start the delete draft dialog */}
        <div class="modal fade" id="deleteDraftModal" tabindex="-1" role="dialog" aria-labelledby="deleteDraftModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
            <div class="modal-dialog modal-dialog-centered" role="document">
               <div class="modal-content">
                <div class="modal-header">
                    <button type="button" id="deleteDraftModalCloseBtn"  disabled={loading} class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                  <div class="modal-body">
                    <strong>Are you sure you want to delete this draft version of the form?</strong>
                  </div>
                  <div class="modal-footer">

                    <button type="button" class="btn btn-secondary" disabled={loading} data-bs-dismiss="modal">Cancel</button>
                  
                    <button type="button" disabled={loading} class="btn btn-primary"
                      onClick={() => { this.deleteDraft(); }}
                    >Confirm deletion</button>
                    
                  </div>
               </div>
            </div>
         </div>
         {/* end the delete draft dialog */}

    </>;
  }
}

AccuracyExistingMeteringCertificate = reduxForm({
  enableReinitialize: true,
})(AccuracyExistingMeteringCertificate)

AccuracyExistingMeteringCertificate = connect(
  (state, props) => ({
    initialValues: state.reducerMeterAccuracy,
    loaded: state.reducerMeterAccuracy.loaded,
    masterDataValues: formValueSelector(props.form)(state, 'mdata'),
    meterEquipInfo : formValueSelector(props.form)(state, 'meteringEquipmentInformation'),
    form: props.form,
    approvedWorkDetails: formValueSelector(props.form)(state, 'approvedWorkDetails'),
    authorityHolderDetails: formValueSelector(props.form)(state, 'authorityHolderDetails'),
    //showSpinner: formValueSelector(props.form)(state, 'showSpinner')
    loading: formValueSelector(props.form)(state, 'loading'),
    consentValid: formValueSelector(props.form)(state, 'consentValid')
  }), {
  load: load,
  save: (payload) => save(payload),
  findById: (id) => findById(id),
  update: (payload) => update(payload)
}
)(AccuracyExistingMeteringCertificate)

export default AccuracyExistingMeteringCertificate;

