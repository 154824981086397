import React from 'react';
import * as apiCaller from '../../../../helpers/apiCaller'



class SystemMessage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            systemMessage : null, loading: false
        }
    }

    async componentDidMount() {
        await apiCaller.get('/system/system-message',{systemMessage : this.state.systemMessage})
        .then((data)=>{
            this.setState({systemMessage: data.systemMessage});
        });
    }   


    handleChange = (e) =>{
        this.setState({systemMessage: e.target.value});
    }

    saveChange = async () => {
        this.setState({loading:true})
        await apiCaller.post('/admin/save-system-message',{systemMessage : this.state.systemMessage})
        .then(()=>{
            window.location.reload();
        });
    }

    render() {
        return <>
            <textarea class="form-control mb-3" rows="3" value={this.state.systemMessage} disabled={this.state.loading} onChange={this.handleChange} />
            <div className='text-end'>
                <button className='btn btn-primary' disabled={this.state.loading} onClick={this.saveChange}>Save</button>
            </div>
        </>
    }

}

export default SystemMessage;


