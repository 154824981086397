import React from 'react';
//import './global';
import Authentication from '../helpers/Authentication';
import axios from 'axios';
import moment from 'moment';
import { userRole, addUserRoleUpdateNotifier } from '../helpers/userHelpers';
import {Link} from 'react-router-dom';
import uiHelpers from '../helpers/uiHelpers';

const authentication = new Authentication();

class RatingTablesSearch extends React.Component
{


    constructor(props)
    {
        super(props);
        this.state = {       
            searchForm : {
                roiOrStorageWorkApproval : ''
            },
            ratingTableForm : {
                id : -1
            },
            isSearchFormLoading : false,
            isVersionContentLoading : false,
            ratingTableSearchResult : [],
            isSearchCompleted : false,
            isRatingTableLoading : false,
            ratingTableData : null
        }
        addUserRoleUpdateNotifier(() => {
            this.forceUpdate();
        });
    }

    ajaxConfig = {
        baseURL: process.env.REACT_APP_WEBAPI_URL,
        headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authentication.getToken() }
    };

    componentDidMount() {
    }   

    encodeChosenRatingTable = (ratingTableData) => {
        console.log("ratingTableData is:" + ratingTableData,null,2);
        return btoa(JSON.stringify(ratingTableData));     
    }

    onRoiOrStorageWorkApprovalChange = (e) => {
        this.setState(Object.assign(this.state.searchForm, { roiOrStorageWorkApproval: e.target.value }));
    }
    onRatingTableDropdownChange  = (e) =>{
        this.setState(Object.assign(this.state.ratingTableForm, { id: e.target.value }));
    }

    searchRoiOrStorageWorkApproval= (e) => {
        e.preventDefault();
        let currentContext = this;
        currentContext.setState(Object.assign(currentContext.state,{isSearchFormLoading : true, ratingTableData:null} ));
        this.setState(Object.assign(this.state.ratingTableForm, { id: -1 }));
        uiHelpers.showProgressOnTop("Searching for storage...")
        axios.post('/ratingTables/searchByRoiOrStorageWorkApproval', JSON.stringify(this.state.searchForm), this.ajaxConfig).then(function (response) {

            currentContext.setState(Object.assign(currentContext.state,{ratingTableSearchResult : response.data.data} ));
            currentContext.setState(Object.assign(currentContext.state,{isSearchCompleted : true} ));
            uiHelpers.hideProgressOnTop();
        }).catch(function (error) {
            uiHelpers.showErrorTop('Error happened on the server side when searching for ROI / Storage work approval number.');
        }).then(function () {
            currentContext.setState(Object.assign(currentContext.state,{isSearchFormLoading : false} ));
        }); 
    }

    viewRatingTableData = (e) => {
        //console.log(this.state.ratingTableForm.id);
        let currentContext = this;

        currentContext.setState(Object.assign(currentContext.state,{ratingTableData : null, isRatingTableLoading:true} ));
        uiHelpers.showProgressOnTop("Searching for rating tables...")
        axios.post('/ratingTables/getRatingTableContentByTableId', JSON.stringify(this.state.ratingTableForm), this.ajaxConfig).then(function (response) {
            currentContext.setState(Object.assign(currentContext.state,{ratingTableData : response.data.data} ));
            uiHelpers.hideProgressOnTop();
        }).catch(function (error) {
            uiHelpers.hideErrorTop('Error happened on the server side when when getting table content.');
        }).then(function () {
            currentContext.setState(Object.assign(currentContext.state,{isRatingTableLoading : false} ));
        }); 
    }

    downloadCsv = (e) =>{
        let currentContext = this;
        uiHelpers.showProgressOnTop('Download CSV...')
        axios.post('/ratingTables/getTableContent', JSON.stringify({ratingTableVersionId : this.state.ratingTableData.ratingTableVersion.id}), this.ajaxConfig).then(function (response) {
            uiHelpers.hideProgressOnTop();
            var mime = 'application/octet-stream';
            var filename = currentContext.state.ratingTableData.ratingTable.ratingTableName + '.csv';
            var blob = new Blob([response.data], {type: mime || 'application/octet-stream'});

            if (typeof window.navigator.msSaveBlob !== 'undefined') {
                // IE workaround for "HTML7007: One or more blob URLs were
                // revoked by closing the blob for which they were created.
                // These URLs will no longer resolve as the data backing
                // the URL has been freed."
                window.navigator.msSaveBlob(blob, filename);
            }
            else {
                var blobURL = (window.URL && window.URL.createObjectURL) ? window.URL.createObjectURL(blob) : window.webkitURL.createObjectURL(blob);
                var tempLink = document.createElement('a');
                tempLink.style.display = 'none';
                tempLink.href = blobURL;
                tempLink.setAttribute('download', filename);
        
                // Safari thinks _blank anchor are pop ups. We only want to set _blank
                // target if the browser does not support the HTML5 download attribute.
                // This allows you to download files in desktop safari if pop up blocking
                // is enabled.
                if (typeof tempLink.download === 'undefined') {
                    tempLink.setAttribute('target', '_blank');
                }
        
                document.body.appendChild(tempLink);
                tempLink.click();
        
                // Fixes "webkit blob resource error 1"
                setTimeout(function() {
                    document.body.removeChild(tempLink);
                    window.URL.revokeObjectURL(blobURL);
                }, 200)
            }
        }).catch(function (error) {
            console.log(error);
            uiHelpers.showErrorTop('Error happened when dowloading file.');
        });
    }

    ratingTableVersionChange(id){
        let currentContext = this;
        currentContext.setState(Object.assign(currentContext.state,{isVersionContentLoading : true} ));
        uiHelpers.showProgressOnTop('Loading version data...')
        axios.post('/ratingTables/getRatingTableContentByVersionId', {id}, this.ajaxConfig).then(function (response) {
            currentContext.setState(Object.assign(currentContext.state.ratingTableData,{ ratingTableVersion : response.data.data.ratingTableVersion} ));
            currentContext.setState(Object.assign(currentContext.state.ratingTableData,{ ratingTableContents : response.data.data.ratingTableContents} ));
            uiHelpers.hideProgressOnTop();
        }).catch(function (error) {
            uiHelpers.showErrorTop('Error happened on the server side when when getting table content.');
        }).then(function () {
            currentContext.setState(Object.assign(currentContext.state,{isVersionContentLoading : false} ));
        }); 
    }

   render()
   {
       return <>
                <h3 className='mb-3'>Rating table / Storage curve search</h3>          

                
                <div class="row mb-3">
                    <div class="col-md-7">
                        <form onSubmit={this.searchRoiOrStorageWorkApproval}>
                            <div class="mb-3">
                                <label className='form-label'>
                                    Enter Registration of Interest (ROI) number / Storage work approval number
                                </label>
                                <input type="text" class="form-control" disabled={this.state.isSearchFormLoading} onChange={this.onRoiOrStorageWorkApprovalChange} value={this.state.searchForm.roiOrStorageWorkApproval} placeholder="ROI / Storage work approval" />
                            </div>
                            <div>
                                <button disabled={this.state.isSearchFormLoading} type="submit" class="btn btn-primary" >Search</button>
                            </div>
                        </form>
                    </div>
                </div>


                {this.state.isSearchCompleted && !this.state.isSearchFormLoading && this.state.ratingTableSearchResult && this.state.ratingTableSearchResult.length>0 &&
                    <>
                        
                        <div class="row mb-3">
                            <div class="col-md-7">
                                <div class="mb-3">
                                    <label className='form-label'>Select rating table to view</label>
                                    <select class="form-select" disabled={this.state.isRatingTableLoading} onChange={this.onRatingTableDropdownChange} value={this.state.ratingTableForm.id}>
                                        <option value={-1}>Select</option>
                                        {this.state.ratingTableSearchResult.map(function(object, i){
                                            return <option value={object.id} key={object.id}>{object.ratingTableName}</option>;
                                        })}
                                    </select>
                                </div>
                                <div>
                                    <button type="button" disabled={this.state.isRatingTableLoading} class="btn btn-primary" onClick={this.viewRatingTableData}>View Rating Table</button>
                                </div>
                            </div>
                        </div>
                    </>
                }

                {this.state.isSearchCompleted && this.state.ratingTableSearchResult && this.state.ratingTableSearchResult.length==0 &&
                    <div class="alert alert-primary" role="alert">
                        Search result is empty
                    </div>
                }

                {this.state.ratingTableData && 
                    <>
                        <h4 className='mb-3'>Selected table data</h4>
                        <div class="card">
                            <div class="card-header"><strong>Table name : </strong> {this.state.ratingTableData.ratingTable.ratingTableName}</div>
                            <div class="card-body">
                                <h5>Table version:</h5>
                                <select class="form-select mb-3" disabled={this.state.isVersionContentLoading} onChange={(val) => this.ratingTableVersionChange(val.target.value)} >
                                    {(function (ratingTableVersions, currentVersion) {
                                        var rows = [];
                                        for(var i =0; i<ratingTableVersions.length;i++){
                                            rows.push(
                                                <option selected={currentVersion.id == ratingTableVersions[i].id} value={ratingTableVersions[i].id} >
                                                    v{ratingTableVersions[i].versionNumber} - 
                                                    &nbsp;from&nbsp;
                                                    {moment((new Date(ratingTableVersions[i].startDate))).format('DD/MM/YYYY HH:mm:ss')} 
                                                    {
                                                        !ratingTableVersions[i].isActive &&
                                                        " to " + moment((new Date(ratingTableVersions[i+1].startDate))).format('DD/MM/YYYY HH:mm:ss')
                                                    }
                                                </option>
                                            )
                                        }
                                        return rows;
                                    })(this.state.ratingTableData.ratingTableVersions, this.state.ratingTableData.ratingTableVersion)}
                                </select>


                                <div className={`${this.state.isVersionContentLoading ? 'opacity-25' : ''}`}>
                                    <p>
                                        <button type="button" class="btn btn-primary" onClick={this.downloadCsv} disabled={!this.state.ratingTableData.ratingTableVersion.isActive || this.state.isVersionContentLoading}>Download .csv file</button>

                                        {userRole.loaded && userRole.isSuperAdmin && <>
                                            &nbsp;&nbsp;&nbsp;
                                            <Link to={{ pathname:'/UpdateRatingTable/RatingTable/'+this.encodeChosenRatingTable(this.state.ratingTableData.ratingTable)}} className='btn btn-primary' >
                                                Update Rating Table
                                            </Link>
                                        </>}
                                    </p>
                                    <table class="table table-striped fs-90">
                                        <thead>
                                            <tr class="table-primary">
                                                <th>
                                                    Elevation
                                                </th>
                                                <th>
                                                    Area
                                                </th>
                                                <th>
                                                    Volume
                                                </th>
                                            </tr>
                                            <tr>
                                                <th>
                                                    m
                                                </th>
                                                <th>
                                                    Ha
                                                </th>
                                                <th>
                                                    ML
                                                </th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {this.state.ratingTableData.ratingTableContents.map(function(object, i){
                                                return <tr key={object.id}>
                                                    <td>
                                                        {object.elevation}
                                                    </td>
                                                    <td>
                                                        {object.area}
                                                    </td>
                                                    <td>
                                                        {object.volume}
                                                    </td>
                                                </tr>
                                            })}
                                        </tbody>
                                        
                                    </table>
                                </div>
                            </div>
                        </div>
                    </>
                }
        </>        
       
   }
}

export default RatingTablesSearch;