import React from 'react';
import {reduxForm, Field, formValueSelector, change } from 'redux-form';
import { connect } from 'react-redux';
import 'redux';
import "react-datepicker/dist/react-datepicker.css";
import {TextAreaForRedux }  from '../../tools/BootstrapControls'
import { validateAccuracyCheckFormSection } from './AccuracyExistingMeteringCertificate.Validation'
import { saveDraftAction, clickNextAction, scrollTofocusError } from "../common/MeterFormValidations"
const validate = validateAccuracyCheckFormSection;

let AccuracyCheckFormSection = props => {
  //validate, onSubmit passed as properties
  const { handleSubmit, previousPage, loading , meterEquipInfo, nextPageForSubmittedForm, hasError} = props
  const disableFields = (parseInt(props.meterEquipInfo.formStatus) == 2) || props.disableEditing || loading? true: false;
  
  return <form className="vertical" onSubmit={handleSubmit}>
    <h5 className='mb-3'>Accuracy Check</h5>
    <div className='mb-5'>
      <Field
          name="meteringEquipmentInformation.accuracyMethod" 
          props={{ disabled: disableFields }}
          rows={10}
          cols={40}
          label={<>
            Method used to check accuracy of the metering equipment:<br/>
            <i>Provide the details on the method used and why it was selected. You will need to reference AS 4747, ISO and NMI. </i>
            <span className='text-danger'>*</span>
          </>}
          component={TextAreaForRedux}
      />
    </div>
    {(parseInt(props.masterDataValues.OpenChannelId) == parseInt(props.meterEquipInfo.meteringSystemTypeId)) &&<div className='mb-3'>
      <h5 className='mb-3'>Conclusion - for open channel meters only <span className='text-danger'>*</span></h5>
        <Field 
          label={<>
            In this conclusion provide:
            <ul>
              <li>a detailed description of the site installation, which includes the approach channel, channel reach downstream, list any vegetation impacts, detail how the sensor is mounted and list any structures which may affect the flow regime</li>
              <li>any advice that assists with maintaining the channel system so that calibration can be maintained.</li>
            </ul>
          </>}
          name="meteringEquipmentInformation.accuracyConclusionOpenChannel" 
          component={TextAreaForRedux}
          props={{ disabled: disableFields }}
          rows={10}
          cols={40}
        />
    </div>}

    {hasError && <div className='alert alert-danger'>
        Please fix all errors before you continue
    </div>}

    <div className="text-end">
      {(parseInt(meterEquipInfo.formStatus) == 1) && (!props.disableEditing) && <>
        {meterEquipInfo.id!=0 &&
          <button type="button"  
            className="btn btn-danger me-3" disabled={loading} 
            data-bs-toggle="modal" data-bs-target="#deleteDraftModal"
          >
              Delete Draft
          </button>
        }
        <button 
          type="button" className="btn btn-primary" disabled={loading} 
          onClick={() => { saveDraftAction(props); }}
        >
          Save Draft
        </button>
          
        <div className='vr mx-3' />
      </>}
      <button type="button" 
        className="btn btn-warning me-3"  disabled={loading} 
        onClick={()=>{previousPage(props.meterEquipInfo.formStatus)}}
      >
        Previous
      </button>

      <button type="button" className="btn btn-success" disabled={loading}
        onClick={props.meterEquipInfo.formStatus == 2?nextPageForSubmittedForm:() => { clickNextAction(props); }}>
            Next
      </button>
    </div>
      
  </form>
}

AccuracyCheckFormSection = reduxForm({
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    validate,
    onSubmitFail: (errors, dispatch ) => {
      dispatch(change('certificate', 'hasError', true));
    },
    onSubmitSuccess:(result , dispatch )=>{
        dispatch(change('certificate', 'hasError', false));
        window.scrollTo(0, 0);
    }
})(AccuracyCheckFormSection)

AccuracyCheckFormSection = connect(
  (state,props) => {
      const selectorCert = formValueSelector('certificate')
      const hasError = selectorCert(state, 'hasError')
    
     const selector = formValueSelector(props.form);
     const loading = selector(state, 'loading');
     const masterDataValues = selector(state, 'mdata');
     const appWorkInfo = selector(state, 'approvedWorkDetails');
     const meterEquipInfo = selector(state, 'meteringEquipmentInformation');
     return {
        masterDataValues,
        appWorkInfo,
        meterEquipInfo,
        form: props.form,
        loading,
        hasError
    }
  }
)(AccuracyCheckFormSection)

export default AccuracyCheckFormSection;