import React from 'react';
import { Field, reduxForm, formValueSelector, change } from 'redux-form';
import { connect } from 'react-redux'
import { checkSerialNumber } from './AHDValidationCertificate.Reducers';
import 'react-redux';
import 'redux';

import "react-datepicker/dist/react-datepicker.css";

import { saveDraftAction, clickNextAction, scrollTofocusError } from "./AHDFormValidations"
import { validateCertificatePartC } from './AHDValidationCertificate.Validation';
import { RadiosForRedux, InputText , DatePickerForRedux, SelectForRedux, InputTextForRedux}  from '../../tools/BootstrapControls'
const validate = validateCertificatePartC;
let 
AHDValidationCertificatePartD = props => {

   const {handleSubmit, masterDataValues, ahdData, previousPage, loading, hasError } = props

   const disableFields = (parseInt(ahdData.formStatus) == 2) || props.disableEditing || loading ? true : false;

   const designOwnOptions = 
         [
            { id: 1, name:"YES",selected:false},
            { id: 2, name:"NO", selected:false},
         ]; 

   const dateToday = new Date(new Date().setHours(12));
   if (ahdData.validationDate != null && ahdData.validationDate != "")
   ahdData.validationDate = new Date(ahdData.validationDate);

   if(ahdData.validationDate == null || ahdData.validationDate == ""){
      ahdData.validationDate = dateToday;
   }

   //benchmarkCertificateDate

   if (ahdData.benchmarkCertificateDate != null && ahdData.benchmarkCertificateDate != "")
   ahdData.benchmarkCertificateDate = new Date(ahdData.benchmarkCertificateDate);

   if(ahdData.benchmarkCertificateDate == null || ahdData.benchmarkCertificateDate == ""){
      ahdData.benchmarkCertificateDate = dateToday;
   }

   if(ahdData.formStatus == 1){
      ahdData.benchmarkCertificateDate = dateToday; //19881 always points to current date, before submit
   }

//expiryDate

   if (ahdData.expiryDate != null && ahdData.expiryDate != "") {
ahdData.expiryDate = new Date(ahdData.expiryDate);
}

   

     console.log("ddown values"+JSON.stringify(masterDataValues.meterModelTypes));

   return (
      <form onSubmit={handleSubmit}>            
         <h5 className='mb-3'>Certification</h5>

         <div className='card mb-3'>
            <div className='card-header fw-bold'>
            I certify that:
            </div>
            <div className='card-body'>
               <p className='fw-bold'>
               a) I am a duly qualified person under the Floodplain Harvesting Measurement Guidelines for the purpose of validating the survey benchmark installation described in this certificate.
               </p>
               <p className='fw-bold'>
               b) The survey benchmark installations comply with the Establishing survey benchmarks for floodplain harvesting meters in NSW (2020) guidelines.
               </p>
               <p className='fw-bold'>
               c) all information contained in this certificate is accurate, true and complete
               </p>
               <p className='fw-bold'>
               d) I am aware that I must notify the Minister within seven (7) days if I know or reasonably suspect that benchmark equipment I am installing has been tampered with.
               </p>
               <p className='fw-bold mb-1'>
               e) I am validating my own survey benchmark installations <span className='text-danger'>*</span>
               </p>
               <Field 
                  name="ahdData.confirmCert"
                  component={RadiosForRedux} 
                  options={designOwnOptions} 
                  values={ahdData.confirmCert}
                  props={{ disabled: disableFields}}
               />

               <hr className='my-4'/>
               <div className='row mb-3'>
                  <div className='col-sm-6'>
                     <Field
                        name="dqpdata.fullName"
                        component={InputTextForRedux}                       
                        label="Name"
                        props={{ disabled: true }}
                     />  
                  </div>
                  <div className='col-sm-6'>
                     <Field
                        name="dqpdata.cmino"
                        label="Registered Surveyor/Engineer licence number"
                        component={InputTextForRedux}
                        props={{ disabled: true }}
                     />   
                  </div>
               </div>

               <div className='row mb-3'>
                  <div className='col-sm-3'>
                     <Field
                        name="ahdData.expiryDate"
                        label="Expiry Date"
                        handleChange={date => { props.change(props.form, 'ahdData.expiryDate', date) }}
                        component={DatePickerForRedux}
                        props={{ disabled: true, maxDate: new Date() }}
                        values={ahdData.expiryDate}
                     />   
                  </div>
                  <div className='col-sm-3'></div>
                  <div className='col-sm-6'>
                     <Field
                        name="dqpdata.businessName"
                        component={InputTextForRedux}                       
                        label="Business Name"
                        props={{ disabled: true }}
                     />  
                  </div>
               </div>

               <div className='row mb-3'>
                  <div className='col-sm-6'>
                     <Field
                        name="dqpdata.postalAddress.addressLine"
                        label="Postal address"
                        component={InputTextForRedux}
                        props={{ disabled: true}}
                     />
                  </div>
                  <div className='col-sm-6'>
                     <Field
                        name="dqpdata.phoneNumber"
                        label="Phone"
                        component={InputTextForRedux}
                        props={{ disabled: true }}
                     />
                  </div>
               </div>

               <div className='row mb-3'>
                  <div className='col-sm-6'>
                     <Field
                        name="dqpdata.user.emailAddress"
                        label="Email"
                        component={InputTextForRedux}
                        props={{ disabled: true }}
                     />
                  </div>
                  <div className='col-sm-6'>
                     <Field
                        name="dqpdata.abnOrAcn"
                        label="ABN or ACN"
                        component={InputTextForRedux}
                        props={{ disabled: true }}
                     />
                  </div>
               </div>

               <div className='row mb-3'>
                  <div className='col-sm-3'>
                  <Field
                        name="ahdData.validationDate"
                        label="Date of validation"
                        handleChange={date => { props.change(props.form, 'ahdData.validationDate', date) }}
                        component={DatePickerForRedux}
                        props={{ disabled: true, maxDate: new Date() }}
                        values={ahdData.validationDate}
                     />
                  </div>
                  <div className='col-sm-3'></div>
                  <div className='col-sm-3'>
                     <Field
                        name="ahdData.benchmarkCertificateDate"
                        label="Date"
                        component={DatePickerForRedux}
                        props={{ disabled: true, maxDate: new Date()}}
                        values={ahdData.benchmarkCertificateDate}
                     />
                  </div>
                  <div className='col-sm-3'></div>
               </div>

               <p>
                  <i>
                  It is an offence under section 91J of the Water Management Act 2000 for a person to make a statement or furnish information in connection with a level measurement record the person knows to be false or misleading.
                  </i>
               </p>

            </div>
         </div>

         {hasError && <div className='alert alert-danger'>
            Please fix all errors before you continue
         </div>}

         <div className="text-end">
            {(parseInt(ahdData.formStatus) == 1)  && (!props.disableEditing) && <>
               {ahdData.id!=0 && <button type="button"  className="btn btn-danger me-3" disabled={disableFields} 
                  data-bs-toggle="modal" data-bs-target="#deleteDraftModal">
                  Delete Draft
               </button>}
            
               <button type="button"  className="btn btn-primary me-5" disabled={disableFields}
                  onClick={() => { saveDraftAction(props); }}>
                  Save Draft
               </button>
               <div className='vr mx-3'></div>
            </>}
            <button type="button"  className="btn btn-warning me-3" onClick={()=>{previousPage(ahdData.formStatus)}} disabled={loading}>
               Previous
            </button>
            <button type="button" className="btn btn-success" disabled={loading}
                  onClick={() => { clickNextAction(props); }}>
                  Next
            </button>

         </div>
      </form>
   )
}


AHDValidationCertificatePartD = reduxForm({
   destroyOnUnmount: false,
   forceUnregisterOnUnmount: true,
   validate,
   onSubmitFail: (errors, dispatch ) => {
      dispatch(change('certificate', 'hasError', true));
   },
   onSubmitSuccess:(result , dispatch )=>{
      dispatch(change('certificate', 'hasError', false));
      window.scrollTo(0, 0);
   }
})(
AHDValidationCertificatePartD)


AHDValidationCertificatePartD = connect(
   (state, props) => {
      const selectorCert = formValueSelector('certificate')
      const hasError = selectorCert(state, 'hasError')

      // can select values individually
      const selector = formValueSelector(props.form)
      const masterDataValues = selector(state, 'mdata')
      
      const ahdData = selector(state, 'ahdData')
      const loading = selector(state, 'loading')
      const form = props.form
      return {
         masterDataValues,      
         ahdData,         
         form,
         hasError,
         loading
      }
   },
   {
      checkSerialNumber: (serialNumber) => checkSerialNumber(serialNumber)
   }
)(
AHDValidationCertificatePartD)

export default 
AHDValidationCertificatePartD