import React from 'react';
import moment from 'moment';

export const ID = 'id';
export const START_DAY = moment().startOf('day').toDate();
export const START_MONTH = moment().startOf('month').toDate();
export const END_MONTH = moment().endOf('month').toDate();
export const INTAKE = 'intake';
export const DISCHARGE = 'discharge';
export const NEXT = "next";

export const DATE_FORMAT = "dd/MM/yyyy h:mm aa";
export const START = "start";
export const FLOW_WATER_SOURCE = 'flowWaterSource';
export const DISCHARGE_WATER_SOURCE = 'dischargeWaterSource';
export const DISCHARGE_AMOUNT = 'dischargeAmount';
export const MANUAL_ENTRY = 'manualEntry';
export const DISCHARGE_START_DATE = 'dischargeStartDate';
export const DISCHARGE_END_DATE = 'dischargeEndDate';
export const PRIMARY_HOLDER = 'primaryHolder';
export const INTRO = 'Intro';

export const PRIOR_GAUGE_HEIGHT = 'priorGaugeHeight';
export const NEW_GAUGE_HEIGHT = 'newGaugeHeight';
export const PENDING = 'pending';
export const TOTAL_TAKE = 'totalVolume';
export const MESSAGE = 'Message';
export const SPAN_RED = { color: 'red' };

export const colourText = (text, colour = "#EDEDED") => (<p style={{ color: colour }}>{text}</p>)

export const LOG_JSON = (json, text = '') => {
    console.log((text ? text + " " : ''));
    console.log(json);
};

export const parseISODate = input => input ? Date.parse(input) : undefined;


export const OBJECT_FLOW = {
    id: undefined,
    waterSource: undefined,
    amount: undefined
}
export const OBJECT_DISCHARGE = {
    id: undefined,
    timeRangeId: 'T' + new Date().getTime(),
    startDate: undefined,
    endDate: undefined,
    waterSource: undefined,
    amount: undefined
}

export const OBJECT_MANUAL_ENTRY = (date, index) => {
    let object = {
        id: TIME_AT_START_OF_DAY(date),
        entryDate: date,
        height: 0,
        area: 0,
        volume: 0,
        deltaVolume: 0
    }
    debugger;
    return object;
}
export const OBJECT_DAS_ENTRY = (date, index = 1, dasData, ratingTableContents) => {
    console.log('dasData');
    console.log(dasData);
    console.log("ratingTableContentsobj_create");
    console.log(ratingTableContents);
    let start = new Date();
    let day = start.getDate();
    let month = start.getMonth();
    let dasEntry = dasData?.find(row => TIME_AT_START_OF_DAY(row.date) == TIME_AT_START_OF_DAY(date));

    if (!dasEntry && date.getDate() == day && date.getMonth() == month) {
        debugger;
        console.log("DAS data not available");
        return {
            id: TIME_AT_START_OF_DAY(date),
            entryDate: date,
            status: PENDING
        }
    }

    let ratingTableEntry = ratingTableContents.find(entry => entry.elevation == dasEntry?.elevation);
    let object = {
        id: TIME_AT_START_OF_DAY(date),
        entryDate: date,
        height: dasEntry?.elevation,
        area: dasEntry.area ? dasEntry.area : ratingTableEntry?.area,
        volume: dasEntry.volume ? dasEntry.volume : ratingTableEntry?.volume,
        deltaVolume: 0
    }
    debugger;
    return object;
}

export const ACTIONS = {
    SET_RATING_TABLE_CONTENTS: "SET_RATING_TABLE_CONTENTS",
    SET_MANUAL_ENTRY_LOG_HEIGHT: "SET_MANUAL_ENTRY_LOG_HEIGHT",
    SET_PRIOR_ENTRY_HEIGHT: "SET_PRIOR_ENTRY_HEIGHT",
    SET_MANUAL_ENTRY_LOG: "SET_MANUAL_ENTRY_LOG",
    FLICK_PAGE: "FLICK_PAGE",
    LOAD_RATING_TABLE: "LOAD_RATING_TABLE",
    LOAD: "LOAD",
    UPDATE: "UPDATE",
    SET_REFERENCE_DATA: "SET_REFERENCE_DATA",
    ADD_NEW_DISCHARGE: "ADD_NEW_DISCHARGE",
    DEL_DISCHARGE: "DEL_DISCHARGE",
    SET_DAS_DATA_DISPLAYED: "SET_DAS_DATA_DISPLAYED",
    ADD_NEW_FLOW: "ADD_NEW_FLOW",
    DEL_FLOW: "DEL_FLOW",
    SET_MANUAL_ENTRY_FLAG: "SET_MANUAL_ENTRY_FLAG",
    SET_DECLARATION: "SET_DECLARATION",
    SET_AHD_HEIGHT: "SET_AHD_HEIGHT",
    COPY_ROW: "COPY_ROW",
    SET_MEASUREMENTDEVICE_DAMAGED: "SET_MEASUREMENTDEVICE_DAMAGED"
}
export const RENDER_CONDITIONAL = {
    HEIGHT: "height",
    AREA: "area",
    VOLUME: "volume",
    DELTA_VOLUME: "deltaVolume"

}
export const LOG_ENTRY_TYPE = {
    MANUAL: "manual",
    DAS: "DAS"
}

export const TIMESTAMP = () => new Date().getTime();
export const filterWaterSource = (target, data) => {
    let valArray = [];

    if (target && data && data.watersources) {
        valArray = data.watersources.filter(obj => obj.value == target);
        return valArray[0];
    }

    return ({});
}

export const NON_COMPLIANCE_REASONS = {
    warningText: [
        "The date selected is before your previously entered measurement period. Measurement periods should be sequential",
        "The end date of this measurement period is not within 24h of the current date/time. This will be logged for record with the Natural Resources Access Regulator (NRAR)"
    ],
    inputMessage: [
        "Provide a reason for non-sequential measurement period",
        "Provide a reason for not lodging within 24 hours"
    ]
}

export const MSG_SAVE = {
    SUCCESS: 'data saved sucessfully',
    ERROR: 'error saving data'
}

export const TIME_AT_START_OF_DAY = (date) => new Date(Date.parse(date)).setHours(12, 0, 0, 0);
export const HOURS_24 = (24 * 60 * 60 * 1000)
export const DAY_BEFORE = (date) => Date.parse(date) - HOURS_24;
