import React from 'react';
import { reduxForm, formValueSelector, change } from 'redux-form';
import { UPLOAD_CSV, fileSizeUploadLimit, MESSAGE_UPLOAD_LIMITS, decimal15Digits, uploadOptionsForUpdateRatingTable as uploadOptions } from '../../../constants/MeterFormConstants'
import { connect } from 'react-redux'

import { updateDocuments, updateDeleteDocuments, changeFormStatus , uploadFiles} from '../design/MeterDesignCertificate.Reducers';

import { saveDraftAction, saveSubmitAction } from "./Validations"
import 'redux';
import { validateCertificatePartE as validate} from './Validation';
import "react-datepicker/dist/react-datepicker.css";
import Authentication from '../../../helpers/Authentication';
import axios from 'axios';

import TableScrollbar from 'react-table-scrollbar';
import CSVReader from 'react-csv-reader';

let CertificatePartE = props => {

   const authentication = new Authentication();
   const allowedExtensions = {"4" : ["pdf"]};

   let {previousPage, handleSubmit, updateRatingTableData, ratingTableContents, documents, loading , hasError} = props    

   const updateList2 = (index, event) => {
      console.log("file clicked");
      var input = document.getElementById('uploadFileName'+index);
      var totalSize = 0;      
      
      for (var i = 0; i < input.files.length; i++) {
         totalSize = totalSize + input.files[i].size;
         var fileName = input.files[i].name;
         let tokens = fileName.split(".");
         debugger;
         const allowedExt = allowedExtensions[index];
         let thisExt = tokens[tokens.length-1];
         if(!allowedExt.includes(thisExt)) {
            alert("Only files of type PDF are allowed. Provided file type is invalid: " + fileName);
            event.target.value='';
            return;
         }   
      }

      if (totalSize > fileSizeUploadLimit) {
         var size = (totalSize) / (1024 * 1024);
         alert("File(s) size(" + size.toFixed(4) + " MB) exceeds the allowed limit");
         return;
      }
      displayList2(
         (
            uploadOptions.filter(option => option.id===index))[0]
         );     
   } 

   const displayList2 = (option) => {
      var imgdesc = [];     
      var input = document.getElementById('uploadFileName'+ option.id);
      
      let formData = new FormData();
      for (var i = 0; i < input.files.length; ++i) {
         var descc = option.name;
         debugger;
         imgdesc.push(descc);
         console.log(imgdesc);
         formData.append("files", input.files[i]);
         formData.append("description", descc);

      }
      if (props.documents.length > 0) {
         formData.append("fileguid", props.documents[0].fileGUID);
      }
      else
         formData.append("fileguid", "");
      const config = {
         headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + authentication.getToken()
         }
      };
      debugger;
      props.dispatch(change(props.form, 'loadingFile', 1))

      let url = process.env.REACT_APP_WEBAPI_URL + process.env.REACT_APP_METER_DESIGN_CERT_UPLOADMULTIPLE;
      if(option.id == 3) {
         console.log("csv detected");
         url = process.env.REACT_APP_WEBAPI_URL + UPLOAD_CSV;
      }

      axios.post(url, formData, config)
         .then(
            function (response) {
               debugger;
               props.dispatch(change(props.form, 'loadingFile', 0))
               var rowData = []
               var fileType = option.id;
               for (var i = 0; i < response.data.payload.files.length; i++) {
                  var row = {};
                  row.id = response.data.payload.files[i].id;
                  row.documentType = fileType;
                  row.description = response.data.payload.files[i].description;
                  row.fileOriginalName = response.data.payload.files[i].fileOriginalName;
                  row.fileGUID = response.data.payload.files[i].fileGUID;
                  row.status = "new"
                  rowData.push(row);
               }
               var docs = props.documents.concat(rowData);
               props.dispatch(change(props.form, 'documents', docs))
   
            }).catch(
   
               function (error) {
                  props.dispatch(change(props.form, 'loadingFile', 0))
                  JSON.stringify("File Error:" + error);
                  if (!error.success)
                     alert(error.message);
               });
   
   }
   
   const deleteFileList = (index) => {
   
      var status = "newDeleted";
      if (props.documents[index].status == "new")
         status = "newDeleted";
      else if (props.documents[index].status == "existing")
         status = "existingDeleted";
      var array = JSON.parse(JSON.stringify(props.documents))
      array[index].status = status;
      props.dispatch(change(props.form, 'documents', array))
   }  

   const validateFiles =()=>{      
      var validated=[];
      var options=[];
      console.log("documents array has " + JSON.stringify(documents));
      debugger;
      for (var k=0;k<=uploadOptions.length-1;k++)
      {
           if (uploadOptions[k].name!="Other")
           {
              options.push(uploadOptions[k]);
           }
      }
   
      if (props.documents.length>0)
      {
         for (var i=0;i<=props.documents.length-1;i++)
         {
            for (var j=0; j<=options.length-1;j++)
            {
               if (parseInt(props.documents[i].documentType)==parseInt(options[j].id))
               {
                   validated.push(options[j].id);
               }
            }
         }
      }
   
      return validated.length>=options.length;
   }
   
   const raiseErrorOnInvalidFormat = (actual, ideal, label) => { 
         if(!actual)    return false;

        let index=0;
        for(index=0;index<3 && index < actual.length;index++) {
         console.log("validating " + JSON.stringify(actual));
         
         if(actual[index].toLowerCase() != ideal[index]){
            alert(`Invalid data in ${label}.\n Expected ${ideal.join(' | ')} \n Found ${actual.join(' | ')}`);
            return true;
         }
      }     
      return false;
   }

   const raiseErrorOnInvalidData = data => {
      const errorList = []
      var currentMax = -1;           
         data.map((row, index) => {
            row.map( (col, colIndex) => {
               console.log(`testing ${col} at [${index},${colIndex}]`);
               
              let excelCol = label => (label==0)?'A':(label==1)?'B':'C';
              debugger;
              if(colIndex === 0) {
               if(currentMax > col && row.length != 1) {               
                     errorList.push(`invalid data at row: ${index+3} column: ${excelCol(colIndex)}. Elevation must be in ascending order`);            
                  }
                  currentMax = col;
               }
               if (!decimal15Digits.test(col)){                
                 
                  if(row.length != 1) { //in the end we get an empty cell
                     errorList.push(`invalid data at row: ${index+3} column: ${excelCol(colIndex)}. Only numbers expected. Found ${col}`);
                  }
                
               }
            });
         });

         if(errorList.length>0) {
            alert(errorList.join("\n"));
            return true;
         }
         return false;
   }

   const disableFields = (parseInt(updateRatingTableData.formStatus) == 2) || props.disableEditing  ||loading ? true : false;   

   if ((!props.updateRatingTableData.fileType || props.updateRatingTableData.fileType == 0) && uploadOptions.length > 0) {
      props.dispatch(change(props.form, 'updateRatingTableData.fileType', "3"))
   }  

   return (
      <form onSubmit={handleSubmit}>            
         <h5 className='mb-3'>Supporting documentation</h5>
                               
         <p className='mb-5'>Attachments for the update of a Rating table / Storage curve are required as specified in the <a rel="noopener noreferrer" target="_blank" href="https://www.industry.nsw.gov.au/__data/assets/pdf_file/0020/329420/storage-curve-guideline.pdf">Storage curve guidelines</a> provided by the Department of  Planning Industry and Environment (DPIE)</p>
               
         <label className='form-label'>PDF of the certified survey plans and data table.</label>
         <div className='row'>
            <div className='col-sm-7'>
               <input 
                     disabled={disableFields} 
                     className='form-control mb-1'
                     type="file" 
                     id="uploadFileName4" 
                     name="fileName" 
                     multiple 
                     onMouseDown = {event =>{ console.log("mouse clicked")}}
                     onChange={(event) => updateList2("4",event)}
               />
               <div className='text-warning'>{MESSAGE_UPLOAD_LIMITS}</div> 
               {(props.loadingFile == 1) && 
                  <div class="spinner-border text-primary" role="status">
                     <span class="visually-hidden">Loading...</span>
                  </div>
               }
            </div>
         </div>
         
               
         {documents.length > 0 &&
            <table className='table table-ms fs-90' >
               <thead>
                  <tr>
                     <th>Description</th>
                     <th>FileName</th>
                     <th></th>
                  </tr>
               </thead>
               <tbody>
                  {documents.map((option, index) => (
                     ((option.status === "new" || option.status === "existing") && option.documentType > 2) &&
                     <tr>
                        <td>{option.description}</td>
                        <td>{option.fileOriginalName}</td>
                        <td><button type="button" disabled={disableFields} onClick={() => deleteFileList(index)} class="btn btn-primary btn-sm">Delete</button></td>
                     </tr>
                  ))}
               </tbody>
            </table>
         }         

         <label className='form-label mt-5'>CSV data file in the required format.<br/></label>
         <p><i>CSV attachment must match the format specified in the <a rel="noopener noreferrer" target="_blank" href="https://www.industry.nsw.gov.au/__data/assets/pdf_file/0020/329420/storage-curve-guideline.pdf"> Storage Curve Guidelines</a></i></p>
         <div className='row mb-1'>
            <div className='col-sm-7'>
               <CSVReader 
                  disabled={disableFields}  
                  cssInputClass="form-control"
                  onFileLoaded = {(data,fileInfo,event) => {
                     if(fileInfo && !fileInfo.name.toLowerCase().endsWith(".csv")) {
                        alert("Only files of type CSV are allowed");                  
                        return;
                     }
                     props.dispatch(change(props.form, 'updateRatingTableData.loadRatingTableContents', true))
                     props.dispatch(change(props.form, 'ratingTableContents', []))
                     let header1Template = ["elevation","area","volume"];
                     let header2Template = ["m","ha","ml"];               
                     let header1 = data.shift();
                     if(raiseErrorOnInvalidFormat(header1,header1Template,"Line 1")) {
                        return;
                     }

                     let  header2 = data.shift();
                     if(raiseErrorOnInvalidFormat(header2,header2Template,"Line 2")) {
                        return;
                     }    
                     if(raiseErrorOnInvalidData(data)) {
                        return;
                     }             

                     props.dispatch(change(props.form, 'ratingTableContents', data))
                  
                  }} 
                  onChange = {event =>{
                     event.target.value = ""
                  }}
               />
            </div>                    
         </div>
         <div className='text-warning mb-3'>{MESSAGE_UPLOAD_LIMITS}</div> 
         {updateRatingTableData.loadRatingTableContents && 
            <div className='card card-body'>
               <TableScrollbar rows = "8">
                  <table class="table table-sm fs-90">
                     <thead>
                           <tr class="table-primary">
                              <th>
                                 Elevation
                              </th>
                              <th>
                                 Area
                              </th>
                              <th>
                                 Volume
                              </th>
                           </tr>
                           <tr className='table-secondary'>
                              <th>
                                 m
                              </th>
                              <th>
                                 Ha
                              </th>
                              <th>
                                 ML
                              </th>
                           </tr>
                     </thead>
                     <tbody>
                           {ratingTableContents.map(function(object, i){
                              return <tr key={object.id}>
                                 <td>
                                       {object[0]}
                                 </td>
                                 <td>
                                       {object[1]}
                                 </td>
                                 <td>
                                       {object[2]}
                                 </td>
                              </tr>
                           })}
                     </tbody>
                     
                  </table>
               </TableScrollbar>
            </div>
         } 
                

         {hasError && <div className='alert alert-danger'>
            Please fix all errors before you continue
         </div>}


         <div className="text-end mt-5">           

            {(parseInt(updateRatingTableData.formStatus) == 1) && (!props.disableEditing) && <>
               {updateRatingTableData.id!=0 && <button type="button"  className="btn btn-danger me-3" disabled={loading} 
               data-bs-toggle="modal" data-bs-target="#deleteDraftModal">
                  Delete Draft
               </button>}
               <button type="button"  className="btn btn-primary" disabled={loading}
                  onClick={() => { 
                     if(updateRatingTableData.loadRatingTableContents) {
                       props.dispatch(change(props.form, 'ratingTableContents', []))
                      }
                      saveDraftAction(props, updateRatingTableData.loadRatingTableContents);
                  }
               }>Save Draft</button>
               <div className='vr mx-3'></div>
            </>}
            <button type="button"  className="btn btn-warning me-3" disabled={loading} onClick={()=>{previousPage(updateRatingTableData.formStatus)}}>
               Previous
            </button>
            {!disableFields && (!props.disableEditing) && 
            <button type="button" className="btn btn-success" disabled={loading}
                  onClick={() => 
                     {
                      if(!validateFiles()){
                          alert('Please make sure at least one PDF file is uploaded for certified survey plans');
                        } else if (!ratingTableContents || ratingTableContents.length <1) {
                           debugger;
                           alert('Please make sure a valid Rating Table is chosen as per format specified in the Rating Table Guidelines');
                        } else {
                           saveSubmitAction(props); 
                        }
                     }
                  }>
                  Submit
            </button>
            }

         </div>
      </form>
   )
}



CertificatePartE = reduxForm({
   destroyOnUnmount: false,
   forceUnregisterOnUnmount: true,   
   validate,
   onSubmitFail: (errors, dispatch ) => {
      dispatch(change('certificate', 'hasError', true));
   },
   onSubmitSuccess:(result , dispatch )=>{
      dispatch(change('certificate', 'hasError', false));
      window.scrollTo(0, 0);
   }
})(

CertificatePartE)




CertificatePartE = connect(
   (state, props) => {
      // can select values individually
      const selectorCert = formValueSelector('certificate')
      const hasError = selectorCert(state, 'hasError')
      
      const selector = formValueSelector(props.form)
      const loading = selector(state, 'loading')      
      const masterDataValues = selector(state, 'mdata')
      const updateRatingTableData = selector(state, 'updateRatingTableData')
      const ratingTableContents = selector(state, 'ratingTableContents')
      
      const documents = selector(state, 'documents')
  
      const form = props.form
      return {       
         updateRatingTableData,
         ratingTableContents,
         documents,         
         masterDataValues, 
         form,
         loading,
         hasError
      }
   }, {
      uploadFiles: (formData) => uploadFiles(formData),
      updateDeleteDocuments: (doc) => updateDeleteDocuments(doc),
      changeFormStatus: (payload, val) => changeFormStatus(payload, val),
      updateDocuments: (payload) => updateDocuments(payload)
   }
)(CertificatePartE)

export default CertificatePartE