import {MESSAGE_REQUIRED} from '../../../constants/MeterFormConstants';

export const validate = (values,params) => {

    const errors = {

        meteringEquipmentInformation: {

            meterCircumstancesOptions: "",// [required()],//text
            dateOfLastValidation: "",//[date({ format: 'mm/dd/yyyy' })],//date
            meteringSystemTypeId: "",//[required(),numericality({int:true,'>':0})],//number
            //meteringSystemType: [required()],//text
            meterManufacturer: "",//[required()],//text
            meterTypeModel: "",//[required()],//text
            meterSerialNo: "",// [required()],//text
            meterSize: "",//[required(),numericality({int:true}),length({min:2,max:4})], //number
            //pipeType: ,//text
            pipeSize: "",//[required(),numericality({int:true}),length({min:2,max:4})] ,//number
            //pipeConnectionType:,//decimal
            pipeLengthUpstream: "",//[format({ with: decimal2UpTo2Digits, message: decimal2UpTo2DigitsMessage })],//decimal
            pipeLengthDownstream: "",// [format({ with: decimal2UpTo2Digits, message: decimal2UpTo2DigitsMessage })],//decimal
            //pipeLengthDownstream: [twonondecimalupto2decimal.test()],//decimal
            longitude: "",//[format({ with: decimal3UpTo6Digits, message: decimal3UpTo6DigitsMessage }),numericality({'<=': 141})],//decimal
            latitude: "",//[format({ with: decimal3UpTo6Digits, message: decimal3UpTo6DigitsMessage }),numericality({'<=': 34,'>=':29})],//decimal

            //channelType:[],//text
            // channelWidth:[],//text
            // meterDistanceFromSource:[],//number
            // channelLengthUpstream:[format({ with: decimal2UpTo2Digits, message: decimal2UpTo2DigitsMessage })],//decimal
            // channelLlengthDownstream:[format({ with: decimal2UpTo2Digits, message: decimal2UpTo2DigitsMessage })],//number

            channelType: "",
            channelWidth: "",
            meterDistanceFromSource: "",
            channelLengthUpstream: "",
            channelLlengthDownstream: "",
            meterTestCertificate: "",
            meterq1FlowRateMinimum: "",
            meterReading: "",
            meterReadingDate: "",
            finalReadingRemovedMeter: "",
            finalReadingDateRemovedMeter: "",
            documentError: "",
            validationMeterEquipmentComplies: "",
            validationMaxErrorMeteringEquipment: "",
            validationOwnMeteringEquipment: "",
            fileType: ""
        }
    }

    console.log("in accuracy validation");
    let clickSaveDraft = params.clickSaveDraft
    let clickNext = params.clickNext;
    if(clickSaveDraft == undefined)
        clickSaveDraft = values.clickSaveDraft;
    if(clickNext == undefined)
        clickNext = values.clickNext;
    console.log("draft:"+clickSaveDraft);
    if(clickSaveDraft === 1 && clickNext === 0)
    {
    }
    else if(clickSaveDraft == 0 && clickNext === 1 && values.meteringEquipmentInformation.formStatus != 2)
    {
        if (!values.meteringEquipmentInformation.validationMeterEquipmentComplies) {
            errors.meteringEquipmentInformation.validationMeterEquipmentComplies = MESSAGE_REQUIRED
        }
        if (!values.meteringEquipmentInformation.validationMaxErrorMeteringEquipment) {
            errors.meteringEquipmentInformation.validationMaxErrorMeteringEquipment = MESSAGE_REQUIRED
        }
        if (!values.meteringEquipmentInformation.validationOwnMeteringEquipment) {
            errors.meteringEquipmentInformation.validationOwnMeteringEquipment = MESSAGE_REQUIRED
        }
    
        //alert("fosmstatus : " + values.meteringEquipmentInformation.formStatus);
        /* if (parseInt(values.meteringEquipmentInformation.formStatus) == 1) {
            debugger;
            var isError = false;
            var ErrorString = [];
    
            if (values.documents.length < 1) {
                errors.meteringEquipmentInformation.fileType = MESSAGE_UPLOAD_DOCUMENTS;
            }
            else {
                if (values.meteringEquipmentInformation.meteringSystemTypeId == 2) {
                    for (var i = 0; i < openChannelOptions.length; i++) {
                        isError = false;
                        for (var j = 0; j < values.documents.length; j++) {
                            if (parseInt(openChannelOptions[i].id) == parseInt(values.documents[j].documentType)) {
                                isError = false;
                                break;
                            }
                            isError = true;
                        }
                        if (isError == true) {
                            ErrorString.push(openChannelOptions[i].name);
                        }
                        console.log(isError);
                    }
                    //alert("for open chhanel" + ErrorString.length);
                    if (ErrorString.length > 0) {
                        console.log(ErrorString);
                        errors.meteringEquipmentInformation.fileType = MESSAGE_UPLOAD_DOCUMENTS;
                        //alert(errors.meteringEquipmentInformation.documentError);
                    }
                }
                else {
                    for (var i = 0; i < closedConduitandProtableOptions.length; i++) {
                        isError = false;
                        for (var j = 0; j < values.documents.length; j++) {
                            if (parseInt(closedConduitandProtableOptions[i].id) == parseInt(values.documents[j].documentType)) {
                                isError = false;
                                break;
                            }
                            isError = true;
                        }
                        if (isError == true) {
                            ErrorString.push(closedConduitandProtableOptions[i].name);
                        }
                        console.log(isError);
    
                    }
                    //alert("for close conduit" + ErrorString.length);
                    if (ErrorString.length > 0) {
                        console.log(ErrorString);
                        errors.meteringEquipmentInformation.fileType = MESSAGE_UPLOAD_DOCUMENTS;
                        //alert(errors.meteringEquipmentInformation.documentError);
                    }
                }
            }
        } */
    }

    

    

    return errors;
}



