import React from 'react';

class MessageTranslate  extends React.Component {

    constructor(props) {
        super(props);
        let message;
        switch(props.msgKey) {
            case 'SYSTEM_ERROR': message = 'System error occurred, please contact system administrator';break;
            case 'IWAS_SESSION_ALREADY_OPENED': message = 'Your link has expired';break;
            case 'IWAS_SESSION_NOT_EXISTS': message = 'Invalid access';break;
            
        }
        this.state = {message}
    }


    render() {
        return <>{this.state.message}</>
    }
}

export default MessageTranslate;