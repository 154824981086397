import axios from 'axios';
import Authentication from '../../../helpers/Authentication';
const authentication = new Authentication();


export const getAllData =() => {
    const options = {
      url: process.env.REACT_APP_WEBAPI_URL + process.env.REACT_APP_METER_DESIGN_CERT_GETALLDATA,
      method: 'GET',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization': 'Bearer '+ authentication.getToken()
      }
    };
    return async (dispatch) => {
      const response = await axios(options);
      const data = await response.data;
      dispatch({type: 'LOAD', data});
    }
    
  }