import React from 'react';
import * as bootstrap from 'bootstrap';
import {Dot, ArrowRight, CheckCircle, QuestionFill} from '../../../components/tools/Icons'
import { UneditableTextInput } from '../../../components/tools/BootstrapControls';
import uiHelpers from '../../../helpers/uiHelpers';
import serviceUrls from '../../../constants/serviceUrls';
import * as apiCaller from '../../../helpers/apiCaller'
import {  converters } from '../../../helpers/converters';
import { MapView } from '../../../components/tools/MapView';
import { toStringIfNotNull } from '../../../helpers/formatters';
import { Link } from "react-router-dom";
import { getSiteRegistrationForm } from '../forms/SiteRegistrationForm';
import { getSiteRegistrationApprovalForm} from '../forms/SiteRegistrationApprovalForm'
import ApprovalFields from '../../../components/common/ApprovalFields'
import MeterCommissionHeading from './common/MeterCommissionHeading';
import { AsteriskRed, LoadingPage } from '../../../components/tools/Others';
import { isStringNullOrEmpty } from '../../../helpers/validators';
import MeterCommissionAudits from './common/MeterCommissionAudits';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import { Redirect  } from "react-router-dom";
import { userRole, addUserRoleUpdateNotifier } from '../../../helpers/userHelpers';
import MeterCommissionAdmin from './common/MeterCommissionAdmin';
import ApprovalFormFileUploader from './common/ApprovalFormFileUploader';
import ApprovalFormFileRO from './common/ApprovalFormFileRO';
import moment from 'moment';
import MeterCommissionConsent from './common/MeterCommissionConsent';
import { replacingLidReasonsRequireRef } from '../../../constants/MeterFormConstants';

const scenarioOpts = [
    {id:1,passing:true,scenario:"Approved", outcome:"Please proceed to complete installation and return to this form to complete the commissioning and validation process. Thank you."},
    {id:2,passing:true,scenario:"Other/Note", outcome:""},
    {id:3,passing:false,scenario:"Duplicate Registration Form", outcome:"Registration already exists for this site. Please search for the existing Form ID# XXXX using the Work Approval details in the search on the Portal Home Page and complete the commissioning and validation process.  Please delete this form."},
    {id:4,passing:false,scenario:"Incorrect Installation Type", outcome:"Incorrect Installation Type selected.  Please change the Installation type to XXXX, complete and then re-submit."},
    {id:5,passing:false,scenario:"Incorrect Installation Type - No meter in WAS", outcome:"Incorrect Installation Type and no current meter found in our system.  Please change Installation Type to \"New Meter - New LID\" and re-submit."},
    {id:6,passing:false,scenario:"Location Mis-match", outcome:"Mismatch meter location on form. It doesn’t match with location of the approve works ID XXXX."},
    {id:7,passing:false,scenario:"No previous meter in WAS – New meter / New LID", outcome:"Form ID #XXXX- NUMR 1, site pending Validation (for new site).\r\nNew meter created as part of NUMR compliance program - need to update when Validation is completed (add in notes for meter in WAS)."},
    {id:8,passing:false,scenario:"Telemetry set to install", outcome:"Site already has a LID set to installed; Need to complete a Site Re-validation for Form ID# XXXXX (Using the set to install information from the Telemetry Registration Form ID# XXXX)."},
    {id:9,passing:false,scenario:"Other/Note", outcome:""},
];

const initState = {
    view:'MAIN',
    adminTab:false,
    noPermission: false,
    role:null,
    initialised:false,
    confirmingSubmission:false,
    confirmDeletion:false,
    sendingSubmission:false,
    sendingDeletion:false,
    sendingRejection:false,
    sendingApproval:false,
    showApprovalHistory:false,
    rejecting:false,
    approving:false,
    redirectTo:null,
    approvalHistories:[],
    approvalScenarios:[],
    meterCommission : {
        id:null,
        approvalNumber:null,
        consent: {
            id:null,
            approvalType:null,
            status:null,
            requestedDate:null,
            approvedDate:null,
            expiryDate:null,
            requestedEmail:null,
            emailCount:null,
            reachResendLimit:null
        },
        owner: {
            id:null,
            firstname:null,
            lastname:null
        },
    },
    approval : {
        approvalNumber:null,
        holders:[]
    },
    backendErrors:null,
    audits:[]
}

class FinalMeterReadingRow  extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render () {
        const form = this.props.form;
        return <div className={`row mb-3`}>
            <div className={`col-md-4 py-2`}>Current Reading Date <span className='text-danger'>*</span></div>
            <div className={`col-md-3`}>
                <div className={`react-datepicker-block ${form.err('replacingMeterReadingDate') ? "react-date-invalid" : ""}`}>
                    <DatePicker
                        className={`form-control ${form.err('replacingMeterReadingDate') ? "is-invalid" : ""}`}
                        onChange={value => {
                            form.vals({replacingMeterReadingDate:value})
                        }}
                        selected={form.val('replacingMeterReadingDate')?new Date(form.val('replacingMeterReadingDate')):null}
                        maxDate={new Date()}

                        readOnly={form.isFieldDisabled('replacingMeterReadingDate')}
                        showTimeSelect
                        timeIntervals={15}
                        filterTime={(time) => {
                            const currentDate = new Date();
                            const selectedDate = new Date(time);
                            return currentDate.getTime() >= selectedDate.getTime();
                        }}
                        dateFormat={"dd/MM/yyyy h:mm aa"}
                        timeCaption="Time"
                        open={!form.isFieldDisabled('replacingMeterReadingDate') && this.state.datePickerOpen}
                        onInputClick={() => { this.setState(Object.assign(this.state, { datePickerOpen: true })); }}
                        onClickOutside={() => { this.setState(Object.assign(this.state, { datePickerOpen: false })); }}
                    />
                    <div class="invalid-feedback">
                        {form.err('replacingMeterReadingDate')}
                    </div>
                </div>
            </div>
        </div>
    }

}

class SiteRegistration  extends React.Component {

    meterCommissionId;
    siteRegistrationForm;
    approvalForm;
    errorModal;
    lidConfirmationModal;
    installationTypeAcknowledgementModal;
    installationTypeExplanationModal;

    constructor(props) {
        super(props);
        this.state = JSON.parse(JSON.stringify(initState));
        this.siteRegistrationForm = getSiteRegistrationForm('siteRegistrationForm',this, this.saveForm,this.submitForm, this.openLidConfirmationModal, this.openInstallationTypeAcknowledgementModal);
        this.approvalForm = getSiteRegistrationApprovalForm('siteRegistrationApprovalForm',this,this.reject, this.approve, this.getInstallationType);
        this.meterCommissionId = parseInt(props.match.params.id);
    }

    getInstallationType = () => {
        if(this.siteRegistrationForm && this.siteRegistrationForm.val('installationType')) return this.siteRegistrationForm.val('installationType');
        return null;
    }

    async componentDidMount() {
        addUserRoleUpdateNotifier(this.userRolesUpdated);
        if(!this.meterCommissionId) return;
        this.errorModal = new bootstrap.Modal(document.getElementById('error-modal'));
        this.lidConfirmationModal = new bootstrap.Modal(document.getElementById('lid-confimation-modal'));
        this.installationTypeAcknowledgementModal = new bootstrap.Modal(document.getElementById('installation-type-acknowledgement-modal'));
        this.installationTypeExplanationModal = new bootstrap.Modal(document.getElementById('installation-type-explanation-modal'));
        this.refreshPage();
        
    }

    userRolesUpdated = () => {
        if (userRole.isSuperAdmin) {
            this.setState({ adminTab:true});
        }
    }

    openLidConfirmationModal =() => {
        this.lidConfirmationModal.show();
    }

    openInstallationTypeAcknowledgementModal = () => {
        this.installationTypeAcknowledgementModal.show();
    }

    openInstallationTypeExplanationModal =() => {
        this.installationTypeExplanationModal.show();
    }

    isAllHoldersNoEmail = () => {
        if(!this.state.approval) return false;
        if(this.state.approval.holders.filter(h=>isStringNullOrEmpty(h.email)).length == this.state.approval.holders.length){
            return true;
        }
        return false
    }

    onPointLocationUpdateFromMap = (latitude, longitude) => {
        this.siteRegistrationForm.vals({
            latitude:toStringIfNotNull(latitude.toFixed(7)),
            longitude:toStringIfNotNull(longitude.toFixed(7))
        })
    }

    wholePageDataBind = (data, callback) =>{
        if(data.userNoPermission) this.setState({initialised:true, noPermission:true});
        else {
            const newState = JSON.parse(JSON.stringify(initState));
            newState.role = data.role;
            newState.meterCommission = data.meterCommission;
            newState.approval = data.approval;
            newState.initialised = true;
            newState.backendErrors = data.backendErrors;
            newState.audits = data.audits;
            newState.approvalHistories = data.approvalHistories;
            newState.view = this.state.view;
            newState.adminTab = userRole.isSuperAdmin;
            
            
            this.setState(newState,()=>{
                if(data.backendErrors) {
                    this.errorModal.show();
                }
                this.siteRegistrationForm.vals(data.siteRegistrationForm.fields, false);
                this.siteRegistrationForm.setOptions(data.siteRegistrationForm.optionsForFields, false);
                this.approvalForm.vals(data.siteRegistrationApprovalForm.fields,false);

                if(data.siteRegistrationApprovalForm.errors) this.approvalForm.errs(data.siteRegistrationApprovalForm.errors,false);
                if(data.meterCommission.status == 'DELETED') {
                    this.siteRegistrationForm.disableForm(this.approvalForm.disableForm(callback));
                } else if(this.siteRegistrationForm.val('formStatus') == 0 && this.state.role=="OWNER") {
                    this.siteRegistrationForm.enableForm(this.approvalForm.enableForm(callback));
                } else {
                    this.siteRegistrationForm.disableForm(this.approvalForm.enableForm(callback));
                }
            })
        }
    }

    wholePageAfterRefresh = () => {
        uiHelpers.hideProgressOnTop();
        window.scrollTo(0, 0);
    }

    refreshPage = async () => {
        uiHelpers.showProgressOnTop('Please wait...');
        this.siteRegistrationForm.disableForm();
        await apiCaller.post(serviceUrls.meterCommission.getMeterCommissionSiteRegistration,{id:this.meterCommissionId})
        .then(this.wholePageDataBind).finally(this.wholePageAfterRefresh);
    }

    saveForm = async () => {
        this.siteRegistrationForm.disableForm();
        uiHelpers.showProgressOnTop('Saving form...');
        await apiCaller.post(serviceUrls.meterCommission.saveMeterCommissionSiteRegistration,{id:this.meterCommissionId,siteRegistrationForm:this.siteRegistrationForm.vals()})
        .then(this.wholePageDataBind).finally(this.wholePageAfterRefresh);
    }

    submitForm = async()=>{
        this.setState({confirmingSubmission:true},this.siteRegistrationForm.disableForm)
    }

    cancelSubmission = async() => {
        this.setState({confirmingSubmission:false},this.siteRegistrationForm.enableForm)
    }

    deleteForm = async()=>{
        this.setState({confirmDeletion:true},this.siteRegistrationForm.disableForm)
    }

    cancelDeletion = async() => {
        this.setState({confirmDeletion:false},this.siteRegistrationForm.enableForm)
    }

    confirmSubmission = async () => {
        this.siteRegistrationForm.disableForm(()=>this.setState({sendingSubmission:true}));
        uiHelpers.showProgressOnTop('Submitting...');
        await apiCaller.post(serviceUrls.meterCommission.submitSiteRegistration,{id:this.meterCommissionId,siteRegistrationForm:this.siteRegistrationForm.vals()})
        .then((data)=>this.wholePageDataBind(data,this.setState({submissionSucceed:true}))).finally(this.wholePageAfterRefresh);
    }

    confirmDeletion = async () => {
        this.siteRegistrationForm.disableForm(()=>this.setState({sendingDeletion:true}));
        uiHelpers.showProgressOnTop('Deleting from...');
        
        await apiCaller.post(serviceUrls.meterCommission.deleteDraft,{id:this.meterCommissionId})
        .then(()=>this.setState({redirectTo:`/home`})).finally(this.wholePageAfterRefresh);
        
    }
    
    reject = () => {this.setState({rejecting:true},this.approvalForm.disableForm)}
    cancelReject = () => {this.setState({rejecting:false},this.approvalForm.enableForm)}

    confirmReject = async() => {
        uiHelpers.showProgressOnTop('Rejecting...');
        this.approvalForm.disableForm(()=>this.setState({sendingRejection:true}));
        await apiCaller.post(serviceUrls.meterCommission.rejectMeterRegistration,{id:this.meterCommissionId, siteRegistrationApprovalForm: this.approvalForm.vals()})
        .then((data)=>this.wholePageDataBind(data,()=>{this.setState({rejectionSucceed:true})})).finally(this.wholePageAfterRefresh);
    }

    approve = async() => {this.setState({approving:true},this.approvalForm.disableForm)}
    cancelApproving = async() => {this.setState({approving:false},this.approvalForm.enableForm)}

    confirmApprove = async()=>{
        uiHelpers.showProgressOnTop('Approving form...');
        this.approvalForm.disableForm(()=>this.setState({sendingApproval:true}));
        await apiCaller.post(serviceUrls.meterCommission.approveMeterRegistration,{id:this.meterCommissionId, siteRegistrationApprovalForm: this.approvalForm.vals()})
        .then((data)=>{
            if(data.backendErrors) {
                this.wholePageDataBind(data);
            } else {
                this.wholePageDataBind(data,()=>{this.setState({approvalSucceed:true})})
            }
            
        }).finally(this.wholePageAfterRefresh);
    }   

    scenarioCheckChanged = (s) => {
        const form = this.approvalForm;
        let scenarios;
        if(form.val('scenarios')){
            scenarios = form.val('scenarios');
        } else {
            scenarios = [];
        }

        var found = false;
        var index = null;
        for(var i =0; i < scenarios.length; i++){
            if(scenarios[i].id == s.id) {
                found = true;
                index = i;
            }
        }
        if(found){
            scenarios.splice(index,1);
        } else {
            scenarios.push({
                id:s.id,
                scenario: s.scenario,
                outcome:s.outcome
            });
        }
        form.fieldValueChanged('scenarios',scenarios);
    }

    onRejectOrApprove = () => {
        const form = this.approvalForm;
        if(form.val('rejectOrApprove')=="APPROVED"){
            form.runAction('approve')
        } else if(form.val('rejectOrApprove')=="REJECTED") {
            form.runAction('reject')
        } else {
            form.errs({rejectOrApprove:'Please select whether you would like to approve or reject'})
        }
    }
    

    renderApprovalFields = () => {
        return  <div class="card mb-3">
            <div class="card-header bg-primary text-white">
                <b>Approval Details</b>
            </div>
            <div className='card-body'>
                { this.isAllHoldersNoEmail() && <div className='alert alert-danger'>
                    None of the holder/s have email in our system, this needs to be fixed before submitting the form.
                </div>}
                <ApprovalFields approval={this.state.approval} authoriserDlwcInpaAddrId={this.state.meterCommission.consent.dlwcInpaAddrId} />
            </div>
        </div>
    }

    renderApprovalFormTop = () => {
        const form = this.approvalForm;
        const regoForm = this.siteRegistrationForm;
        if(!form.val('status') || form.val('status') == 'PENDING_APPROVAL') return null;

        const boolFieldOptions = {rowClasses:'mb-3',converter:converters.common.converBoolToYesNo}
        return <div class="card mb-3">
            <div class="card-header bg-primary text-white">
                <b>QA Review Outcome</b>
            </div>
            <div className='card-body'>
                {form.renderUneditableTextInputRow('approvingOfficer',<>
                        {form.val('status')=='REJECTED' && <>Submission <b className='text-danger'>Not Approved</b> By</>}
                        {form.val('status')=='APPROVED' && <>Submission <b className='text-success'>Approved</b> By</>}
                    </>,
                    {rowClasses:'mb-3',converter:officer=>this.state.role=="OWNER" || this.state.role=="READER"?`WaterNSW Customer Data Officer`:<>{officer.firstname} {officer.lastname}</>}
                )}
                {this.state.role!="OWNER" && form.renderUneditableTextInputRow('approvalDetailsCorrect',<>Are the work approval details correct?</>,boolFieldOptions)}
                {this.state.role!="OWNER" && form.renderUneditableTextInputRow('meterDetailsCorrect',<>Are the meter details and location correct?</>,boolFieldOptions)}
                {this.state.role!="OWNER" && form.renderUneditableTextInputRow('lidDetailsCorrect',<>Are the LID details correct?</>,boolFieldOptions)}
                {(regoForm.val('installationType').key=='E_M_R_L') && <>
                    {this.state.role!="OWNER" && form.renderUneditableTextInputRow('s91iReviewed',<>Has S91i been reviewed and reason for replacement confirmed?</>,{})}
                </>}

                {!(form.val('formVersion') > 1) && form.renderUneditableTextInputRow('comments',<>Outcome</>,{labelWidth:4,fieldWidth:8,rowClasses:"mb-3"})}
                {(form.val('formVersion') > 1) && <>
                    <div class="row mb-3">
                        <div class="col-md-4 py-2">Scenario/s</div>
                        <div class="col-md-8">
                            <ul class="list-group ">
                                {form.val('scenarios').map(s=>{
                                    return <li class="list-group-item bg-light">
                                        <span className='text-primary'>{s.scenario}</span> <br/>
                                        <b>Outcome: </b> {s.outcome} 
                                    </li>
                                })}
                            </ul>
                        </div>
                    </div>
                </>}
                
                {form.val('documents').length != 0 && <>
                    <div class="row ">
                        <div class="col-md-4 py-2">Attachment/s</div>
                        <div class="col-md-8">
                            <ApprovalFormFileRO documents={form.val('documents')} />
                        </div>
                    </div>
                </>}

                {this.renderApprovalHistories()}

            </div>
        </div>
    }

    renderGoToValidationButton = () => {
        const form = this.approvalForm;
        if(!form.val('status') || form.val('status') != 'APPROVED') return null;
        return <div class="text-end">
            <Link className="btn btn-success" to={`/compliance-process/${this.state.meterCommission.id}/validation`}>Next</Link>
        </div>;
    }

    renderApprovalHistories = () => {
        if(!this.state.approvalHistories || this.state.approvalHistories.length<2) return null;
        const form = this.approvalForm;
        return <div className='row mt-3'>
                <div className="col-md-4 py-2">Submissions History</div>
                <div className='col-md-8'>

                    <div class="form-check form-switch mb-3">
                        <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" checked={this.state.showApprovalHistory} onClick={()=>this.setState({showApprovalHistory:this.state.showApprovalHistory?false:true})} />
                        <label class="form-check-label" for="flexSwitchCheckDefault">Show/Hide history</label>
                    </div>
                    {this.state.showApprovalHistory && <div class="list-group">
                        {this.state.approvalHistories.filter(a=>a.id!=form.val("id")).map(a=><>
                            <div href="#" class="list-group-item">
                                <div class="d-flex w-100 justify-content-between mb-2">
                                    <p class="mb-1">
                                        <b className={a.status=='REJECTED' ? 'text-danger' : 'text-success'}>{a.status == 'REJECTED' ? "Not Approved" : "Approved"}</b>
                                    </p>
                                    <small class="text-muted">Requested on {moment((new Date(a.createdDate))).format('DD/MM/YYYY h:mma')}</small>
                                </div>
                                {!(a.formVersion > 1) && <p class="mb-1"><b>Outcome:</b> {a.comments}</p>}
                                {(a.formVersion > 1) && <>
                                    <ul class="list-group ">
                                        {a.scenarios.map(s=>{
                                            return <li class="list-group-item bg-light">
                                                <span className='text-primary'>{s.scenario}</span> <br/>
                                                <b>Outcome: </b> {s.outcome} 
                                            </li>
                                        })}
                                    </ul>
                                </>}
                                {a.documents.length > 0 && <table className='table table-striped table-sm fs-80'>
                                    <thead>
                                        <tr><th>File name</th><th style={{width:"350px"}}>Description</th></tr>
                                    </thead>
                                    <tbody>
                                        {a.documents.map(file=><tr>
                                            <td>
                                                <a target='_blank' href={process.env.REACT_APP_WEBAPI_URL + process.env.REACT_APP_FIELS_GET + "/" + file.id}>
                                                    {file.fileOriginalName}
                                                </a>
                                            </td>
                                            <td>{file.description}</td>
                                        </tr>)}
                                    </tbody>
                                </table>}
                            </div>
                        </>)}
                    </div>}
                </div>
            </div>
    }



    renderApprovalFormButtom = () => {
        const regoForm = this.siteRegistrationForm;
        const form = this.approvalForm;
        const boolFieldOptions = {rowClasses:'mb-3'}
        if(!(regoForm.val('formStatus') == 4 && this.state.role=="APPROVING_OFFICER")) return null;;
        return <>
            <div class="card mb-3">
                <div class="card-header bg-primary text-white">
                    <b>Approving Officer Use Only</b>
                </div>
                <div className='card-body'>
                    {(regoForm.val('installationType').key=='N_M_N_L' || regoForm.val('installationType').key=='R_M_N_L') && form.renderTextBoxRow('newMeterNumber',<>New Meter Number <AsteriskRed/></>,{labelWidth:4,fieldWidth:8, rowClasses:"mb-3"})}
                    {form.renderYesNoOptionsRow('approvalDetailsCorrect',<>Are the work approval details correct? <AsteriskRed/></>,boolFieldOptions)}
                    {form.renderYesNoOptionsRow('meterDetailsCorrect',<>Are the meter details and location correct? <AsteriskRed/></>,boolFieldOptions)}
                    {form.renderYesNoOptionsRow('lidDetailsCorrect',<>Are the LID details correct? <AsteriskRed/></>,boolFieldOptions)}
                    {(regoForm.val('installationType').key=='E_M_R_L') && <>
                        <div className={`row mb-3`}>
                            <div className={`col-md-4 py-2`}>Has S91i been reviewed and reason for replacement confirmed? <AsteriskRed/></div>
                            <div className={`col-md-8 pt-1`}>
                                    {form.renderRadioOptions('s91iReviewed',[
                                        {value:'Yes',optionId:'YES',label:'Yes', isChecked:(value)=>(value=='Yes')},
                                        {value:'No',optionId:'NO',label:'No', isChecked:((value)=>value=='No')},
                                        {value:'N/A',optionId:'NA',label:'N/A', isChecked:((value)=>value=='N/A')}
                                    ],true)}
                                
                            </div>
                        </div>
                    </>}
                    
                    <div className='row mb-3'>
                        <div className="col-md-4 py-2">Attachments</div>
                        <div className='col-md-8'>
                            <ApprovalFormFileUploader approvalForm={form} />
                        </div>
                    </div>

                    <div className={`row mb-3`}>
                        <div className={`col-md-4 py-2`}>Quality Assurance Checks</div>
                        <div className={`col-md-8 pt-1`}>
                            {form.renderRadioOptions("rejectOrApprove",[
                                {optionId:"APPROVED", label:"Pass",value:"APPROVED",isChecked:(val)=>val=="APPROVED"},
                                {optionId:"REJECTED", label:"Fail",value:"REJECTED",isChecked:(val)=>val=="REJECTED"},
                            ],true)}
                        </div>
                    </div>

                    {(form.val('rejectOrApprove')=="APPROVED" || form.val('rejectOrApprove')=="REJECTED") && <div className='row mb-3'>
                        <div className="col-md-4 py-2">Scenarios</div>
                        <div className='col-md-8'>
                            <ul class="list-group">
                                {scenarioOpts.filter(s=>(form.val('rejectOrApprove')=="APPROVED" && s.passing) || (form.val('rejectOrApprove')=="REJECTED" && !s.passing)).map(s=>{
                                    var scenario = null;
                                    if(form.val('scenarios') && form.val('scenarios').filter(sc=>sc.id == s.id).length!=0 ) 
                                        scenario = form.val('scenarios').filter(sc=>sc.id == s.id)[0];

                                    return <li className="list-group-item">
                                        <input className="form-check-input me-1" type="checkbox" value="" disabled={form.isFieldDisabled('scenarios') || form.isFormDisabled()} id={`scenario_opt_${s.id}`} onChange={()=>{this.scenarioCheckChanged(s);}} checked={scenario!=null}/>
                                        <label for={`scenario_opt_${s.id}`}>{s.scenario}</label>
                                        {scenario!=null && <div className="mt-3">
                                            <b className='mb-1'>Outcome:</b>
                                            <textarea
                                                onChange={(e)=>{
                                                    scenario.outcome=e.target.value;
                                                    form.vals({scenarios:form.val('scenarios')})
                                                }} 
                                                disabled={form.isFieldDisabled('scenarios') || form.isFormDisabled()}
                                                className={`form-control ${scenario.outcomeErr?'is-invalid':''}`}
                                            >{scenario.outcome}</textarea>
                                            <div className='invalid-feedback'>{scenario.outcomeErr}</div>
                                        </div>}
                                    </li>
                                })}
                            </ul>
                        </div>
                    </div>}

                    {this.renderApprovalHistories()}

                </div>
            </div>
            {this.state.rejecting &&  <div className='card border-danger border-1 mb-5 '>
                <div className='card-body bg-danger bg-opacity-10 text-center'>
                    <p className='text-danger'>
                        <b>Please confirm that you are rejecting this submission</b>
                    </p>
                    <p>
                        Please note that this form will be sent back to the DQP to update the details.
                    </p>
                    <div>
                        <button type="button" class="btn btn-secondary me-3" onClick={this.cancelReject} disabled={this.state.sendingRejection}>
                            Cancel
                        </button>
                        <button type="button" class="btn btn-primary" onClick={this.confirmReject} disabled={this.state.sendingRejection}>
                            Confirm
                        </button>
                    </div>
                </div>
            </div>}
            {this.state.approving && <div className='card border-primary border-1 mb-5 '>
                <div className='card-body bg-primary bg-opacity-10 text-center'>
                    <p className='text-primary'>
                        <b>Please confirm that you are approving this submission</b>
                    </p>
                    <p>
                        Please note once your approval is confirmed, Meter/LID will be created in relevant systems
                    </p>
                    <div>
                        <button type="button" class="btn btn-secondary me-3" onClick={this.cancelApproving} disabled={this.state.sendingApproval}>
                            Cancel
                        </button>
                        <button type="button" class="btn btn-primary" onClick={this.confirmApprove} disabled={this.state.sendingApproval}>
                            Confirm
                        </button>
                    </div>
                </div>
            </div>}
            {!(this.state.rejecting || this.state.approving) && <div class="text-end">
                {form.haveErrors() && <div className='alert alert-danger text-end'>
                Please complete the required information and resolve any errors that may display in order to proceed further.
                </div>}
                <button type="button" class="btn btn-primary" onClick={this.onRejectOrApprove} disabled={form.isFormDisabled()}>
                    Proceed
                </button>
            </div>}
            
        </>
    }

    renderRegistrationDetailsFields =() => {
        const form = this.siteRegistrationForm;
        return <div class="card mb-3">
            <div class="card-header bg-primary text-white">
                <b>Registration Details</b>
            </div>
            <div className='card-body'>
                {form.renderUneditableTextInputRow('formType','Form Type',{rowClasses:'mb-3',converter:converters.meterRegistration.formType})}
                {form.renderDropDownRow('installationType',<>Installation Type <span className='text-danger'>*</span> <span className='text-primary' style={{cursor:"pointer"}} onClick={this.openInstallationTypeExplanationModal}><QuestionFill /></span></>,(o)=>o.id, (o)=>o.name,{rowClasses:'mb-3'})}
                <div className='row'>
                    <div className='col-md-4'>DQP Description of Works <span className='text-danger'>*</span></div>
                    <div className='col-md-8'>
                        {form.renderTextArea("dqpNotes")}
                    </div>
                </div>
                {form.val('installationType') && form.val('installationType').key=='R_M_N_L' && <div className='row'>
                    <div className='col-md-4'></div>
                    <div className='col-md-8'>
                        <i className='text-muted fs-80'>For Reconfiguring LID, please add comments in the DQP Description of Works to specify the details of the LID in use.</i>
                    </div>
                </div>}
            </div>
        </div>;
    }

    renderExtractionAndWorksRow = () => {
        const form = this.siteRegistrationForm;
        if(!(form.val('formStatus') == 0 && this.state.role=="OWNER")) return null;
        if(!form.options('extractionSite')) return null;

        const extractionSites = form.options('extractionSite');
        const error = form.err('extractionSite');
        const disabled = form.isFormDisabled() || form.disableFields['extractionSite'] || form.isFieldDisabled();
        const options = [];
        let value = null;
        let option;
        
        for(var i = 0; i < extractionSites.length; i++) {
            for(var j = 0; j < extractionSites[i].works.length; j++) {
                option = {
                    extractionSite : extractionSites[i],
                    works: extractionSites[i].works[j]
                }
                options.push(option);
                if(form.val('extractionSite') && form.val('works') && form.val('extractionSite').worksiteId == option.extractionSite.worksiteId && form.val('works').worksId == option.works.worksId){
                    value = option;
                }
            }
        }

        return <div className='row mb-3'>
            <div className='col-md-4 py-2'>Extraction Site &amp; Works <span className='text-danger'>*</span></div>
            <div className='col-md-8'>
                <Select
                    placeholder=""
                    isDisabled={disabled}
                    className={error ? "react-select-invalid" : ""}
                    getOptionLabel={(o)=><>
                        ESID: {o.extractionSite.esid} <i className='fs-80'>({o.extractionSite.waterType})</i>,&nbsp;
                        {o.works.workType} <i className='fs-80'>(Works ID {o.works.worksId})</i>
                    </>}
                    getOptionValue={(o)=> o.extractionSite.worksiteId + "_" + o.works.worksId}
                    options={options}
                    value={value}
                    onChange={(o)=>{
                        form.vals({works:o.works}, false);
                        form.fieldValueChanged("extractionSite",o.extractionSite)
                    }}
                />
                {error && <div className='invalid-feedback'>{error}</div>}
            </div>
        </div>
    }

    renderEsidAndWorkFields = () => {
        const form = this.siteRegistrationForm;
        return <div class="card mb-3">
                <div class="card-header bg-primary text-white">
                <b>Extraction Site and Work Details</b>
            </div>
            <div className='card-body'>
                {form.renderDropDownRow('dlwcProperty','Property',(o)=>o.dlwcPropertyId, (o)=>o.propertyName,{rowClasses:'mb-3'})}
                {this.renderExtractionAndWorksRow()}
                {form.renderUneditableTextInputRow('extractionSite',"ESID",{rowClasses:'mb-3',converter:value=>value?value.esid:null})}
                {form.renderUneditableTextInputRow('extractionSite',"Water Sharing Plan",{rowClasses:'mb-3',converter:value=>value?value.waterSharingPlanDesc:null})}
                {form.renderUneditableTextInputRow('extractionSite',"Water Source",{rowClasses:'mb-3',converter:value=>value?value.waterSourceName:null})}
                {form.renderUneditableTextInputRow('extractionSite',"Water Type",{rowClasses:'mb-3',converter:value=>value?value.waterType:null})}
                {form.renderUneditableTextInputRow('works',"Works Description",{rowClasses:'mb-3',converter:value=>value?value.workType:null})}
                {form.renderUneditableTextInputRow('works',"Works ID",{rowClasses:'mb-3',converter:value=>value?value.worksId:null})}

                <div className='row mb-3'>
                    <div className='col-md-4 py2'>
                    Lot/DP 
                    </div>
                    <div className='col-md-8'>
                        {form.renderDropDown("dlwcLot",(o)=>o.dlwcLotId,o=><>{o.lotNumber}/{o.sectionNumber}/{o.planNumber} {o.planType}</>)}
                        {!form.val('extractionSite') && <span className='text-muted fs-80'>
                            Please select Extraction Site &amp; Works first
                        </span>}
                    </div>
                </div>
                {form.renderTextBoxRow('customersSiteName',<>Customer Site Name <AsteriskRed/></>,{rowClasses:'mb-3'})}
                {form.renderTextBoxRow('customersPumpNumber',<>Customer Pump Number</>,{})}

            </div>
        </div>
    }

    renderNewLidFields = () =>{
        const form = this.siteRegistrationForm;
        const getExistingLidLable = (o)=>
            <div className="text-truncate">This LID is connecting to {o.meters.map((m,i)=>{
                if(i==0) return <><strong className='text-primary fs-80'>{m.meterSiteId}</strong></>;
                return <>, <strong className='text-primary fs-80'>{m.meterSiteId}</strong></>;
            })}
        </div>;
        return <div class="card mb-3">
            <div class="card-header bg-primary text-white">
                <b>New LID Details</b>
            </div>
            <div className='card-body'>
                {form.renderYesNoOptionsRow('isLidTelemetry',<>Is the LID being used for Telemetry? <span className='text-danger'>*</span></>,{rowClasses:'mb-3'})}
                {
                    form.val('isLidTelemetry')===true 
                    && 
                    form.renderDropDownRow('telemetryType',<>LID Telemetry Type <span className='text-danger'>*</span></>,(o)=>o.id, (o)=>o.name,{rowClasses:'mb-3'})
                }

                {
                    (form.val('isLidTelemetry')===false || (form.val('isLidTelemetry')===true && form.val('telemetryType'))) 
                    && 
                    form.renderDropDownRow('lid',<>LID Device <span className='text-danger'>*</span></>,(o)=>o.id, (o)=>o.name,{rowClasses:'mb-3'})
                }

                {   
                    form.val('lid') && form.val('lid').allowMultiple 
                    && 
                    form.renderYesNoOptionsRow('isFirstMeterForLID',<>Is this the first meter registered to this LID? <span className='text-danger'>*</span></>,{})
                }

                {form.val('isFirstMeterForLID') ===false && <>
                    {form.renderDropDownRow('existingLid',<>Existing LID <span className='text-danger'>*</span></>,(o)=>o.id,getExistingLidLable,{rowClasses:'mb-3'})}
                    {form.renderUneditableTextInputRow('existingLid',"LID Serial Number",{converter:value=>value?value.lidSerialNumber:null})}
                </>}
            </div>
        </div>
    }

    renderMeterFields =(wasFieldName, meterFieldName, renderMeterMap) =>{        
        const form = this.siteRegistrationForm;

        if(!form.val('installationType')) return null;
        if(form.val('extractionSite')==null) return <div class="text-center bg-light text-muted p-5" >Please select extraction site first</div>;
        

        const getMeterLabel = (o)=><>
            Meter No.<strong>{o.meterNumber}</strong>
            {o.meter && <><i className='fs-80 ms-3'>({o.meter.meterSiteId}, LID {o.meter.lidName})</i>  </>}
        </>

        if(form.val('installationType').key=="E_M_N_L") {
            return <> 
                {form.val('formStatus')==0 && form.renderDropDownRow(wasFieldName,<>Meter <AsteriskRed /></>,(o)=>o.meterId, getMeterLabel,{rowClasses:'mb-3'})}
                {form.val('formStatus')!=0 && (
                    form.val(wasFieldName)!=null  ?  
                    form.renderUneditableTextInputRow(wasFieldName,"Meter Number",{rowClasses:'mb-3', converter:value=>value?value.meterNumber:null})
                    :
                    form.renderUneditableTextInputRow('meterNumber',"Meter Number",{rowClasses:'mb-3'})
                )}
                {form.renderDropDownRow('manufacturerAndModel',<>Manufacturer and Model <AsteriskRed /></>,(o)=>o.id, (o)=>o.name,{rowClasses:'mb-3'})}
                {form.val('manufacturerAndModel') && form.val('manufacturerAndModel').key=='Other' && form.renderTextBoxRow('otherManufacturerAndModelText',<>Other Manufacturer and Model <AsteriskRed/></>,{rowClasses:'mb-3'})}
                {form.renderTextBoxRow('meterSize',<>Size (in mm) </>,{})}
            </>
        }
        
        return <>
            {form.renderDropDownRow(wasFieldName,<>Meter <AsteriskRed /></>,(o)=>o.meterId, getMeterLabel,{rowClasses:'mb-3'})}
            {!form.val(meterFieldName) && form.val(wasFieldName) && <>
                {form.renderUneditableTextInputRow(wasFieldName,"Meter Number",{rowClasses:'mb-3', converter:value=>value?value.meterNumber:null})}
                {form.renderUneditableTextInputRow(wasFieldName,"Meter Category",{rowClasses:'mb-3', converter:value=>value?value.meterCategory:null})}
                {form.renderUneditableTextInputRow(wasFieldName,"Meter Type",{rowClasses:'mb-3', converter:value=>value?value.meterType:null})}
                {form.renderUneditableTextInputRow(wasFieldName,"Meter Size (in mm)",{ rowClasses:'mb-3', converter:value=>value?value.sizeInMM:null})}
                {form.renderUneditableTextInputRow(wasFieldName,"Meter Serial Number",{ converter:value=>value?value.serialNumber:null})}
            </>}
            {form.val(meterFieldName) && <>
                {meterFieldName=='replacingMeter' && <div className='alert alert-warning mb-3'>Please note that this meter will be decommissioned at submission.</div>}
                {form.renderUneditableTextInputRow(meterFieldName,"Workspace ID",{rowClasses:'mb-3', converter:value=>value.meterSiteId})}
                {form.renderUneditableTextInputRow(meterFieldName,"Manufacturer and Model",{rowClasses:'mb-3', converter:value=>value.meterModelName})}
                {form.renderUneditableTextInputRow(meterFieldName,"Meter Serial Number",{rowClasses:'mb-3', converter:value=>value.meterSerialNumber})}

                {form.val('installationType').key == "E_M_R_L" && <>
                    {form.renderDropDownRow("replacingLidReason",<>Reason for LID replacement <AsteriskRed /></>,o=>o.value,o=>o.value,{rowClasses:'mb-3'})}
                    {form.val("replacingLidReason") && replacingLidReasonsRequireRef.includes(form.val("replacingLidReason").value) && form.renderTextBoxRow('replacingLidS91iRef',<>S91i Reference Number <AsteriskRed/></>,{labelWidth:4,fieldWidth:3})}
                </>}

                {renderMeterMap && this.renderMeterMap(meterFieldName)}
            </>}
        </>
    }

    renderMeterMap = (meterFieldName) =>{
        const form = this.siteRegistrationForm;
        return <div className='card card-body mt-3'>
            <h6><strong>Meter Location</strong></h6>
            <div className='row'>
                <div className='col-md-3 mb-3'>
                    <UneditableTextInput
                        fieldExtraClass="bg-light"
                        label="Latitude"
                        value={form.val(meterFieldName).latitude} 
                    /> 
                </div>
                <div className='col-md-3 mb-3'>
                    <UneditableTextInput
                        fieldExtraClass="bg-light"
                        label="Latitude"
                        value={form.val(meterFieldName).longitude} 
                    /> 
                </div>
            </div>
            <MapView 
                readOnly={true}                
                point={{ latitude: form.val(meterFieldName).latitude, longitude: form.val(meterFieldName).longitude}}
                disabled={true}
            />
        </div>
    }

    renderNewMeterFields = () => {
        const form = this.siteRegistrationForm;
        return <>
            {(form.val('formStatus')==1 || form.val('formStatus')==2) && form.renderUneditableTextInputRow('meterNumber','Meter Number:',{rowClasses:'mb-3'})}
            {form.renderDropDownRow('manufacturerAndModel',<>Manufacturer and Model <AsteriskRed /></>,(o)=>o.id, (o)=>o.name,{rowClasses:'mb-3'})}
            {form.val('manufacturerAndModel') && form.val('manufacturerAndModel').key=='Other' && form.renderTextBoxRow('otherManufacturerAndModelText',<>Other Manufacturer and Model <AsteriskRed/></>,{rowClasses:'mb-3'})}
            {form.renderTextBoxRow('meterSize',<>Size (in mm) </>,{})}
        </>
    }

    renderReplacingMeterSection = () =>{
        const form = this.siteRegistrationForm;
        if(form.val('replacingWasMeter')==null && form.val('formStatus')!=0) {
            return <div className='card mb-3'>
            <div class="card-header bg-primary text-white">
                <b>Old Meter</b> 
            </div>
            <div className='card-body'>
                {form.renderUneditableTextInputRow("replaceMeterNumber","Meter Number",{rowClasses:'mb-3'})} 
            </div>
        </div> 
        }
        return <div className='card mb-3'>
            <div class="card-header bg-primary text-white">
                <b>Old Meter Details</b>
            </div>
            <div className='card-body'>
                {this.renderMeterFields('replacingWasMeter', 'replacingMeter', false)}
                {form.val('replacingWasMeter') && <>
                    {form.renderYesNoOptionsRow('replacingMeterFinal',<>Can you provide the current meter reading? <AsteriskRed/></>,{rowClasses:'my-3'})}

                    {form.val('replacingMeterFinal') === true && <>
                        <FinalMeterReadingRow form={form} />
                        {form.renderTextBoxRow('replacingMeterReading',<>Current Reading <AsteriskRed/></>,{labelWidth:4,fieldWidth:3})}
                    </>}

                    {form.val('replacingMeterFinal') === false && <>
                        {form.renderTextBoxRow('replacingMeterS91iRef',<>S91i Reference Number <AsteriskRed/></>,{labelWidth:4,fieldWidth:3})}
                    </>}
                </>}
                {form.val('replacingMeter') && this.renderMeterMap('replacingMeter')}
            </div>
        </div>
    }

    renderReplacingOrExistingMeterLidSection = (fieldName) => {
        const form = this.siteRegistrationForm;
        if(!form.val(fieldName)) return null;

        return <div className='card mb-3'>
            <div class="card-header bg-primary text-white">
                <b>{fieldName=='replacingMeter'?"Old":"Existing"} LID Details</b>
            </div>
            <div className='card-body'>
                {form.renderUneditableTextInputRow(fieldName,"LID being used for Telemetry",{rowClasses:'mb-3', converter:value=>value.isLidTelemetry===true?"Yes":value.isLidTelemetry===false?"No":""})}
                {form.val(fieldName).isLidTelemetry===true && form.renderUneditableTextInputRow(fieldName,"LID Telemetry Type",{rowClasses:'mb-3', converter:value=>value.telemetryTypeName})}
                {form.renderUneditableTextInputRow(fieldName,"LID Device",{rowClasses:'mb-3', converter:value=>value.lidName})}
                {form.renderUneditableTextInputRow(fieldName,"LID Serial Number",{rowClasses:'mb-3', converter:value=>value.lidSerialNumber})}
            </div>
        </div>
    }

    renderMeterSections = () => {
        const form = this.siteRegistrationForm;
        
        return <>
            <div class="card mb-3">
                <div class="card-header bg-primary text-white">
                    <b>{(form.val('installationType').key== 'N_M_N_L' || form.val('installationType').key== 'R_M_N_L')  ? "New" : "Existing"} Meter Details</b>
                </div>
                <div className='card-body'>
                    {(form.val('installationType').key== 'N_M_N_L' || form.val('installationType').key== 'R_M_N_L') ? this.renderNewMeterFields() : this.renderMeterFields('existingWasMeter','existingMeter',true)}

                    {(form.val('installationType').key== 'N_M_N_L' || form.val('installationType').key== 'R_M_N_L' || form.val('installationType').key== 'E_M_N_L') && <div className='card card-body mt-3'>
                        <h6><strong>Meter Location</strong></h6>
                        <div className='row'>
                            <div className='col-md-3 mb-3'>
                                {form.renderTextBox("latitude",<>Latitude <span className='text-danger'>*</span></>)}
                            </div>
                            <div className='col-md-3 mb-3'>
                                {form.renderTextBox("longitude",<>Longitude <span className='text-danger'>*</span></>)}
                            </div>
                        </div>
                        <MapView 
                            onPointLocationUpdate={this.onPointLocationUpdateFromMap}
                            readOnly={false}                
                            point={{ latitude: form.val('latitude'), longitude: form.val('longitude')}}
                            disabled={form.isFieldDisabled('latitude') || form.isFieldDisabled('longitude')}
                        />
                    </div>}
                </div>
            </div>
        </>
    }

    renderRegoFormButtons = () => {
        const form = this.siteRegistrationForm;
        if(!(form.val('formStatus') == 0 && this.state.role=="OWNER")) return null;
        return <>
            {this.state.confirmingSubmission ? 
                <div className='card border-primary border-1 mb-5 '>
                    <div className='card-body bg-primary bg-opacity-10 text-center'>
                        <p className='text-primary'>
                            <b>Please confirm all fields are filled in correctly</b>
                        </p>
                        <div>
                            <button type="button" class="btn btn-secondary me-3" onClick={this.cancelSubmission} disabled={this.state.sendingSubmission}>
                                Cancel
                            </button>
                            <button type="button" class="btn btn-primary" onClick={this.confirmSubmission} disabled={this.state.sendingSubmission}>
                                Confirm
                            </button>
                        </div>
                    </div>
                </div> 
                : this.state.confirmDeletion ? 
                <div className='card border-danger border-1 mb-5 '>
                    <div className='card-body bg-danger bg-opacity-10 text-center'>
                        <p className='text-danger'>
                            <b>Please confirm that you would like to proceed with this deletion.</b>
                        </p>
                        <div>
                            <button type="button" class="btn btn-secondary me-3" onClick={this.cancelDeletion} disabled={this.state.sendingDeletion}>
                                Cancel
                            </button>
                            <button type="button" class="btn btn-primary" onClick={this.confirmDeletion} disabled={this.state.sendingDeletion}>
                                Confirm
                            </button>
                        </div>
                    </div>
                </div> 
                : 
                <div class="text-end">
                    {this.isAllHoldersNoEmail() && <div className='mb-3 text-danger'>Please resolve the holder email issue (refer to approval details section) before submitting this form</div>}
                    {!this.isAllHoldersNoEmail() && form.haveErrors() && <div className='alert alert-danger text-end'>
                    Please complete the required information and resolve any errors that may display in order to proceed further.
                    </div>}

                    {form.val('formStatus') == 0 && <>
                        <button type="button" class="btn btn-danger" onClick={this.deleteForm} disabled={form.isFormDisabled()}>
                            Delete
                        </button>
                        <div className='vr mx-3'></div>
                    </>}
                    <button type="button" class="btn btn-primary me-3" onClick={()=>this.siteRegistrationForm.runAction('save')} disabled={form.isFormDisabled()}>
                        Save
                    </button>
                    <button type="button" class="btn btn-success" onClick={()=>this.siteRegistrationForm.runAction('submit')} disabled={this.isAllHoldersNoEmail() || form.isFormDisabled()}>
                        Submit
                    </button>
                    
                </div>
            }
        </>
    }

    renderLidAndMeters = () => {
        const form = this.siteRegistrationForm;
        if(!form.val('installationType')) return <div className='card card-body text-center bg-light text-muted mb-3' style={{padding:"60px 0"}}>Below section/s will only show after selecting installation type</div>;
        switch(form.val('installationType').key){
            case 'N_M_N_L' :
            case 'E_M_N_L' :
                return <>
                    {this.renderMeterSections()}
                    {this.renderNewLidFields()}
                </>
            case 'E_M_R_L':
                return <>
                    {this.renderMeterSections()}
                    {form.val('formStatus')==0 && this.renderReplacingOrExistingMeterLidSection('existingMeter')}
                    {this.renderNewLidFields()}
                </>
            case 'R_M_N_L':
                return <>
                    {this.renderReplacingMeterSection()}
                    {this.renderReplacingOrExistingMeterLidSection('replacingMeter')}
                    {this.renderMeterSections()}
                    {this.renderNewLidFields()}
                </>
            case 'S_R':
                return <>
                    {this.renderMeterSections()}
                    {this.renderReplacingOrExistingMeterLidSection('existingMeter')}
                </>
        }
    }

    renderMainContent = () => {
        if(!this.meterCommissionId) return <div className='alert alert-danger'>You access this page incorrectly</div>
        if(!this.state.initialised) return <LoadingPage />;
        if(this.state.noPermission) return <div className='alert alert-danger'>Access denied.</div>
        const form = this.siteRegistrationForm;

        return <>        
            <MeterCommissionHeading meterCommission={this.state.meterCommission} role={this.state.role} />

            <div className='mb-3'>
                <ul className="nav nav-pills">
                    <li className="nav-item">
                        <a onClick={(e)=>{e.preventDefault();this.setState({view:'MAIN'});}} className={`nav-link ${this.state.view=='MAIN'?'active':''}`}  href="#">Process Details</a>
                    </li>
                    <li className="nav-item">
                        <a onClick={(e)=>{e.preventDefault();this.setState({view:'AUDIT'})}} className={`nav-link ${this.state.view=='AUDIT'?'active':''}`} href="#">Audit</a>
                    </li>
                    <li className="nav-item">
                        <a onClick={(e)=>{e.preventDefault();this.setState({view:'CONSENT'})}} className={`nav-link ${this.state.view=='CONSENT'?'active':''}`} href="#">Customer Authorisation</a>
                    </li>
                    {this.state.role!="OWNER" && this.state.adminTab && <li className="nav-item">
                        <a onClick={(e)=>{e.preventDefault();this.setState({view:'ADMIN'})}} className={`nav-link ${this.state.view=='ADMIN'?'active':''}`} href="#">Admin</a>
                    </li>}
                </ul>
            </div>

            <hr/>
            {this.state.view=='AUDIT' ? <>
                <MeterCommissionAudits role={this.state.role} meterCommissionId={this.meterCommissionId}/>
            </> : this.state.view=='CONSENT' ? 
                <MeterCommissionConsent consent={this.state.meterCommission.consent}/> 
            : this.state.view=='ADMIN' ? <>
                <MeterCommissionAdmin 
                    meterCommission={this.state.meterCommission} 
                    refreshPage={this.refreshPage} 
                    lid={form.val('lid')}
                    installationTypeKey= { form.vals().installationType ? form.vals().installationType.key:null}
                />
            </> : <>

            <div className='card mb-3'>
                <div className='card-body py-1 fs-90'>
                    <span className='text-primary'><CheckCircle /> Find Approval &amp; Customer Authorisation</span> 
                    <span className='px-2'><ArrowRight/></span>
                    {(this.approvalForm.val('status') && this.approvalForm.val('status')=='APPROVED') ? <>
                        <strong className='text-primary'><CheckCircle /> Site Registration</strong>
                        <span className='px-2'><ArrowRight/></span>
                        {this.state.meterCommission.status=='COMPLETED' ?  <span className='text-primary'><CheckCircle/> </span> : <span className='text-primary'><Dot/> </span>}
                        <Link to={`/compliance-process/${this.state.meterCommission.id}/validation`}>Commissioning &amp; Validation</Link>
                    </>
                    :
                    <>
                        <span className='text-primary'><Dot/></span><strong className='text-primary'>Site Registration</strong>
                        <span className='px-2'><ArrowRight/></span>
                        <i className='text-secondary fs-80'>Commissioning &amp; Validation</i>
                    </>}
                </div>
            </div>
            
                {this.renderApprovalFormTop()}
                {this.renderRegistrationDetailsFields()}
                {this.renderApprovalFields()}
                {this.renderEsidAndWorkFields()}
                {this.renderLidAndMeters()}
                {this.renderRegoFormButtons()}
                {this.renderApprovalFormButtom()}

                
                {this.state.submissionSucceed && <div className='alert alert-success text-center'>This form is submitted for QA Review. Upon completion of review, an email shall be sent.</div>}
                {this.state.approvalSucceed && <div className='alert alert-success text-center'>This form has passed QA review. An email notification will be sent to the DQP. </div>}
                {this.state.rejectionSucceed && <div className='alert alert-primary text-center text-danger'>This form has failed QA review. An email notification will be sent to the DQP.</div>}

                {this.renderGoToValidationButton()}
            </>}
            
        </>
    }

    render() {
        return <>
            {this.state.redirectTo && <Redirect to={this.state.redirectTo}  />}
            {this.renderMainContent()}

            <div class="modal fade" id="error-modal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title text-danger">Error/s occurred</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            {this.state.backendErrors && <ul className='text-danger mb-0'>
                                {this.state.backendErrors.map(e=><li>{e}</li>)}
                            </ul>}
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" disabled={this.state.loading} data-bs-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal fade" id="lid-confimation-modal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title text-danger">LID Selection Warning &amp; Acknowledgement</h5>
                        </div>
                        <div class="modal-body">
                        Please review and confirm you have selected the correct LID for this site registration. An incorrect LID model selection will result in a delay to the procurement process for up to 30 days due to manual intervention needed to resolve this DQP error.
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-primary" disabled={this.state.loading} data-bs-dismiss="modal">Acknowledge and Continue</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal fade" id="installation-type-acknowledgement-modal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title text-danger">Installation Type Selection Acknowledgement</h5>
                        </div>
                        <div class="modal-body">
                            <p>Please review and confirm you have selected the correct Installation Type for this site registration.</p>
                            {this.siteRegistrationForm.vals().installationType && <p><strong className='text-primary'>{this.siteRegistrationForm.vals().installationType.name}</strong></p>}
                            {(()=>{
                                if(!this.siteRegistrationForm.vals().installationType) return null;
                                switch(this.siteRegistrationForm.vals().installationType.key) {
                                    case "N_M_N_L" : return <>
                                        New meter: currently no meter registered at that site in WaterNSW system – New LID: configured to this meter for the first time.
                                    </>;
                                    case "R_M_N_L" : return <>
                                        Replacement meter: replacing meter registered at that site in WaterNSW system – New LID: brand new LID / Reconfigure LID: has been connected to an old meter and now reconfigured to a new meter.
                                    </>;
                                    case "E_M_N_L" : return <>
                                        Existing meter: meter currently registered at that site in WaterNSW system – New LID: brand new LID configured to this meter for the first time / Reconfigure LID : has been connected and now reconfigured to an existing meter.
                                    </>;
                                    case "E_M_R_L" : return <>
                                        Existing meter: meter currently registered at that site in WaterNSW system – Replacement LID: replacing the existing LID.
                                    </>;
                                    case "S_R" : return <>
                                        All equipment registered in WaterNSW system, has a previous completed registration in the DQP Portal.
                                    </>;
                                }
                            })()}
                            
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-primary" disabled={this.state.loading} data-bs-dismiss="modal">Acknowledge and Continue</button>
                            <button type="button" class="btn btn-primary" disabled={this.state.loading} data-bs-dismiss="modal">Change Installation Type</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal fade" id="installation-type-explanation-modal"  data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">Installation Types</h5>
                        </div>
                        <div class="modal-body">
                            <p><strong className='text-primary'>New Meter - New LID</strong></p>
                            <p>New meter: currently no meter registered at that site in WaterNSW system – New LID: configured to this meter for the first time.</p>

                            <p><strong className='text-primary'>Replacement Meter - New/Reconfigure LID</strong></p>
                            <p>Replacement meter: replacing meter registered at that site in WaterNSW system – New LID: brand new LID / Reconfigure LID: has been connected to an old meter and now reconfigured to a new meter.</p>

                            <p><strong className='text-primary'>Existing Meter - New/Reconfigure LID</strong></p>
                            <p>Existing meter: meter currently registered at that site in WaterNSW system – New LID: brand new LID configured to this meter for the first time / Reconfigure LID : has been connected and now reconfigured to an existing meter.</p>

                            <p><strong className='text-primary'>Existing Meter - Replacement LID</strong></p>
                            <p>Existing meter: meter currently registered at that site in WaterNSW system – Replacement LID: replacing the existing LID.</p>

                            <p><strong className='text-primary'>Site Revalidation</strong></p>
                            <p>All equipment registered in WaterNSW system, has a previous completed registration in the DQP Portal.</p>
                            
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" disabled={this.state.loading} data-bs-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    }

    
}

export default SiteRegistration;
