import React from 'react';
import { reduxForm, formValueSelector, change} from 'redux-form';
import { connect } from 'react-redux'
import { checkSerialNumber } from './MeterValidationCertificate.Reducers'
import 'react-redux';
import 'redux';
import "react-datepicker/dist/react-datepicker.css";
import { saveDraftAction, clickNextAction, scrollTofocusError } from "../common/MeterFormValidations"
import { validateCertificatePartC } from './MeterValidationCertificate.Validation';
import MeteringEquipmentInformationMain from './MeteringEquipmentInformationMain'


const validate = validateCertificatePartC;

let MeterValidationCertificatePartC = props => {

   const {
      handleSubmit, meterEquipInfo, previousPage, loading, hasError
   } = props

   
   return <form className="vertical" onSubmit={handleSubmit}>
         <h5 className='mb-3'>New Metering Equipment</h5>

         {(meterEquipInfo.newMeterEquipment != undefined && meterEquipInfo.newMeterEquipment.length == 1 && (parseInt(meterEquipInfo.newMeterEquipment[0]) == 1 || parseInt(meterEquipInfo.newMeterEquipment[0]) == 3)) && <>
            <MeteringEquipmentInformationMain form={props.form} disableFields={true} disableEditing={props.disableEditing} />
         </>}

         {hasError && <div className='alert alert-danger my-3'>
         Please fix all errors before you continue
         </div>}

         <div className="text-end mt-4">
            <button type="button" class="btn btn-primary float-start" data-bs-toggle="modal" data-bs-target="#checkListModal" disabled={loading}>
               View Checklist
            </button>
            {(parseInt(meterEquipInfo.formStatus) == 1)  && (!props.disableEditing) && <>
               {meterEquipInfo.id!=0 && <button type="button"  
                  className="btn btn-danger me-3" disabled={loading} 
                  data-bs-toggle="modal" data-bs-target="#deleteDraftModal">
                  Delete Draft
               </button>}
               {/* 
               <button type="button"  className="btn btn-primary" disabled={loading}
                  onClick={() => { saveDraftAction(props); }}>
                  Save Draft
               </button>
               */}
               <div className='vr mx-3' />
            </>}
            <button type="button"  className="btn btn-warning me-3" 
               disabled={loading}
               onClick={()=>{previousPage(meterEquipInfo.formStatus)}}>
               Previous
            </button>
            <button type="button" className="btn btn-success"
               disabled={loading}
               onClick={() => { clickNextAction(props); }}>
               Next
            </button>
         </div>
   </form>
   
}

MeterValidationCertificatePartC = reduxForm({
   destroyOnUnmount: false,
   forceUnregisterOnUnmount: true,
   validate,
   onSubmitFail: (errors, dispatch ) => {
      dispatch(change('certificate', 'hasError', true));
   },
   onSubmitSuccess:(result , dispatch )=>{
      dispatch(change('certificate', 'hasError', false));
      window.scrollTo(0, 0);
   }
})(MeterValidationCertificatePartC)

MeterValidationCertificatePartC = connect(
   (state, props) => {
      const selectorCert = formValueSelector('certificate')
      const hasError = selectorCert(state, 'hasError')

      // can select values individually
      const selector = formValueSelector(props.form)
      const masterDataValues = selector(state, 'mdata')
      const loading = selector(state, 'loading')
      const meterEquipInfo = selector(state, 'meteringEquipmentInformation')
      const documents = selector(state, 'documents')
      const meterModelTypeList = selector(state, 'meterModelTypeList');
      const appWorkInfo = selector(state, 'approvedWorkDetails');
      const form = props.form
      return {
         masterDataValues,
         meterEquipInfo,
         documents,
         form,
         meterModelTypeList,
         appWorkInfo,
         loading,
         hasError
      }
   },
   {
      checkSerialNumber: (serialNumber) => checkSerialNumber(serialNumber)
   }
)(MeterValidationCertificatePartC)

export default MeterValidationCertificatePartC