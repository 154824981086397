import commonConstants from '../constants/commonConstants'

export const isStringNullOrEmpty = (value)=>{
    if(!value) return true;
    return String(value).trim()=="";
}

export const isNumber3DecimalPositiveOnly = (value)=>{
    return commonConstants.regex.DECIMAL_NUMBER_3_POSITIVE.test(value);
}

export const isNumber = (value)=>{
    return commonConstants.regex.IS_NUMBER.test(value);
}

export const isPositiveNumber = (value)=>{
    return commonConstants.regex.POSITIVE_NUMBER.test(value);
}

export const isValidEmail = (value) => {
    return commonConstants.regex.EMAIL.test(value);
}

export const isPositiveWholeNumber = (value)=>{
    return commonConstants.regex.POSITIVE_WHOLE_NUMBER.test(value);
}

export const hasDigit = (value)=>{
    return commonConstants.regex.HAS_DIGIT.test(value);
}


