import { FlatForm } from "../../../helpers/FormHelper";

export const getValitionApprovalForm = (formName, context) => {

    const result = new FlatForm(formName, context);
    result.initialise([
        "id",
        "status",
        "createdBy",
        "createdDate",
        "approvingOfficer",
        "comments",
        "newMeterNumber",
        "documents",
        "rejectOrApprove",
        "scenarios"
    ])
    return result;
}