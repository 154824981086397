import React from 'react';

export const portalName = {
    shortName: 'Water Reporting Portal',
    longName: 'Water Reporting Portal - WaterNSW',
    id: 'fph-nmp'
}

export const logoImage = <img alt="Nominated Measurement Period portal logo" src={require("../images/nmp-logo.jpg")} style={{height:"50px"}} className="mt-2" />;

export const periodStatus = {
    open: {value:"OPEN",desc:"Open"},
    closed: {value:"CLOSED",desc:"Closed"},
    finalised: {value:"FINALISED",desc:"Finalised"},
}

export const auditActions = {
    create: {value:"CREATE",desc:"Create"},
    changeDates: {value:"CHANGE_DATES",desc:"Change dates"},
    close: {value:"CLOSE",desc:"Close"},
    finalise: {value:"FINALISE",desc:"Finalise"},
    addOtherInFlow: {value:"ADD_OTHER_INFLOW",desc:"Add other in-flow"},
    updateOtherInFlow: {value:"UPDATE_OTHER_INFLOW",desc:"Update other in-flow"},
    deleteOtherInFlow: {value:"DELETE_OTHER_INFLOW",desc:"Delete other in-flow"},
    saveReading: {value:"SAVE_READING",desc:"Save reading"},
    delete: {value:"DELETE",desc:"Delete"},
    adminAction: {value:"ADMIN_ACTION",desc:"Admin action"},
    saveReasonForNotLodging: {value:"SAVE_REASONFORNOTLODGING",desc:"Save reason for not lodging"},

    turnOnManual: {value:"TURN_ON_ISMANUAL",desc:"Turn on secondary reading"},
    turnOffManual: {value:"TURN_OFF_ISMANUAL",desc:"Turn off secondary reading"},
}

export const pageInitialising = <>
    <p class="placeholder-glow"><span class="placeholder col-5"></span></p>
    <p class="placeholder-glow"><span class="placeholder col-7"></span></p>
    <p class="placeholder-glow"><span class="placeholder col-4"></span></p>
    <p class="placeholder-glow"><span class="placeholder col-9"></span></p>
    <p class="placeholder-glow"><span class="placeholder col-8"></span></p>
    <p class="placeholder-glow"><span class="placeholder col-4"></span></p>
    <p class="placeholder-glow"><span class="placeholder col-1"></span></p>
    <p class="placeholder-glow"><span class="placeholder col-7"></span></p>
    <p class="placeholder-glow"><span class="placeholder col-10"></span></p>
    <p class="placeholder-glow"><span class="placeholder col-5"></span></p>
</>;

export const iWasUrl = process.env.REACT_APP_IWAS_URL;