import React from 'react';
import axios from 'axios';
import Authentication from '../../helpers/Authentication'
import uiHelpers from '../../helpers/uiHelpers'
const authentication = new Authentication();

class MultipleMeterLIDSelection extends React.Component {


    ajaxConfig = {
        baseURL: process.env.REACT_APP_WEBAPI_URL,
        headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authentication.getToken() }
    };

    componentId = (new Date()).getTime();

    constructor(props) {
        super(props);
        this.state = {
            initialised : false,
            errorLoading : false,
            lids:null
        }
    }

    async componentDidMount() {
        this.refresh();
    }   

    componentWillUnmount() {
    }

    refresh = async () =>{
        try {
            const response = await axios.post('/meterregistration/getLIDsForApproval', {approvalNumber : this.props.approvalNumber}, this.ajaxConfig);
            this.setState({
                lids : response.data.data,
                initialised: true
            })
        } catch(e){
            console.log(e);
            this.setState({
                errorLoading : true,
                initialised: true
            })
        }
    }

    onChangeLid = (lid) => {
        if(this.props.onChangeLid) this.props.onChangeLid(lid);
    }

    render() {
        
        if(!this.state.initialised) {
            return <>
            <div className='mb-3 ps-3'>
                <div class="spinner-border text-primary" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
            </>
        }

        if(this.state.errorLoading) {
            return <>
            <div className='alert alert-danger'>
                Error on loading existing LIDs for this approval
            </div>
            </>
        }

        if(this.state.lids.length==0) {
            return <>
                <div className='alert alert-warning'>
                    There is no LID that allows multiple meters currently under this approval
                </div>
            </>
        }

        return <>
            <div className='mb-3 row'>
                <div className='col-md-6 ps-3'>
                    <div class="list-group fs-90">
                        {this.state.lids.map((l,i)=>{
                            return <label
                                for={`LID_${this.componentId + '_' + i}`}
                                className={`list-group-item list-group-item-action`}
                            >
                                <div className='float-start pt-1'>
                                    <input
                                        id={`LID_${this.componentId + '_' + i}`}
                                        type="radio"
                                        onChange={()=>{this.onChangeLid(l);}}
                                        checked={this.props.meterRegistrationLIDId == l.id}
                                        disabled={this.props.disabled}
                                    />
                                </div>
                                <div className='ms-4'>
                                    <p class="mb-1"><strong>This meter is connecting to the same LID as <span className='text-primary'>{l.firstRegistrationMeterSiteId}</span></strong></p>
                                    <div>All meters: {l.submittedMeterRegistrations.map((m,i)=>{
                                        return (i!=0 ? ", " : "") + m.meterSiteId
                                    })}</div>
                                </div>
                            </label>
                        })}
                    </div>
                </div> 
            </div>
        </>    
    }

}

export default MultipleMeterLIDSelection;
