import React from 'react';
import {Field, FieldArray, reduxForm, formValueSelector, change} from 'redux-form';
import { connect } from 'react-redux'
import 'react-redux';
import 'redux';
import { validate } from './MeterDesignCertificate.Validation'
import { saveDraftAction, clickNextAction, scrollTofocusError } from "../common/MeterFormValidations"
import { RadiosForRedux, InputTextForRedux , TextAreaForRedux}  from '../../tools/BootstrapControls'

let MeterDesignCertificatePartC = props => {
   const { 
      handleSubmit, meterEquipInfo, previousPage, loading, hasError
   } = props
   const disableFields = (parseInt(meterEquipInfo.formStatus) == 2) || props.disableEditing || loading? true: false;
   return (
      <form onSubmit={handleSubmit}>
      <h5 className='mb-4'>Design of metering equipment information</h5>

      <div className='row mb-3'>
         <div className='col-sm-6'>
            <Field 
               name="meteringEquipmentInformation.channelType" 
               label={<>Channel type <span className='text-danger'>*</span></>}
               component={InputTextForRedux}
               props={{ disabled: disableFields }}
            />
         </div>
      </div>

      <div className='row mb-3'>
         <div className='col-sm-6'>
            <Field 
               name="meteringEquipmentInformation.channelWidth"  
               label={<>Channel width (m) <span className='text-danger'>*</span></>}
               component={InputTextForRedux}
               props={{ disabled: disableFields }}
            />
         </div>
         <div className='col-sm-6'>
            <Field name="meteringEquipmentInformation.meterDistanceFromSource" 
               label={<>Distance of the meter from pumping source or filling point (m) <span className='text-danger'>*</span></>}
               component={InputTextForRedux}
               props={{ disabled: disableFields }}
            />
         </div>
      </div>

      <div className='row mb-3'>
         <div className='col-sm-6'>
            <Field 
               name="meteringEquipmentInformation.channelLengthUpstream" 
               label={<>Channel length upstream of meter (m) <span className='text-danger'>*</span></>}
               component={InputTextForRedux}
               props={{ disabled: disableFields}}
            />
         </div>
         <div className='col-sm-6'>
            <Field 
               name="meteringEquipmentInformation.channelLlengthDownstream" 
               label={<>Channel length downstream of meter (m) <span className='text-danger'>*</span></>}
               component={InputTextForRedux}
               props={{ disabled: disableFields}}
               
            />
         </div>
      </div>
      
      
      <div className='mb-3'>
         <Field 
            name="meteringEquipmentInformation.processUsedToCheckDesign" 
            props={{ disabled: disableFields }} 
            label={<>Process used to check the design <span className='text-danger'>*</span></>}
            component={TextAreaForRedux} 
            cols={40}
            rows={10}
         />
      </div>
      
      {hasError && <div className='alert alert-danger'>
         Please fix all errors before you continue
      </div>}

      <div className="text-end">         
         {(parseInt(meterEquipInfo.formStatus) == 1) && (!props.disableEditing) && <>
            {meterEquipInfo.id!=0 &&
               <button type="button" className="btn btn-danger me-3" disabled={loading}  
                  data-bs-toggle="modal" data-bs-target="#deleteDraftModal">
                  Delete Draft
               </button>
            }
            <button type="button"  className="btn btn-primary" disabled={loading} 
               onClick={() => { 
                  saveDraftAction(props); 
               }}>
                  Save Draft
            </button>
            <div className='vr mx-3'></div>
         </>}

         <button type="button"  className="btn btn-warning me-3" disabled={loading} onClick={()=>{previousPage(meterEquipInfo.formStatus)}}>Previous</button>
         <button type="button"  className="btn btn-success" disabled={loading} onClick={() => { clickNextAction(props); }}>Next</button>
      </div>
   </form>
  )
}

MeterDesignCertificatePartC = reduxForm({
   destroyOnUnmount: false,
   forceUnregisterOnUnmount: true,
   validate,
   onSubmitFail: (errors, dispatch ) => {
      dispatch(change('certificate', 'hasError', true));
   },
   onSubmitSuccess:(result , dispatch )=>{
      dispatch(change('certificate', 'hasError', false));
      window.scrollTo(0, 0);
   }
})(MeterDesignCertificatePartC)

MeterDesignCertificatePartC = connect(
   (state,props) => { 
      const selectorCert = formValueSelector('certificate')
      const hasError = selectorCert(state, 'hasError')

      const selector = formValueSelector(props.form)
      const masterDataValues = selector(state, 'mdata')
      const meterEquipInfo = selector(state, 'meteringEquipmentInformation')
      const loading = selector(state, 'loading');
      return {
         masterDataValues,
         meterEquipInfo,
         form: props.form,
         loading,
         hasError
      }
})(MeterDesignCertificatePartC)

export default MeterDesignCertificatePartC