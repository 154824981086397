import React from 'react';

export default class ValidationReplacingLidFields  extends React.Component {

    render = () => {
        const form = this.props.validationForm;
        const meter = form.val('replacingMeter');
        return <>
            {form.renderUneditableTextInputRow("replacingMeter",<>Is the LID being used for Telemetry? </>,{rowClasses:'mb-3',converter:m=>m.meterLid.isLidTelemetry===true?"Yes":m.meterLid.isLidTelemetry===false?"No":null})}
            {meter.meterLid.isLidTelemetry===true && form.renderUneditableTextInputRow("replacingMeter",<>LID Telemetry Type</>,{rowClasses:'mb-3',converter:m=>m.meterLid.telemetryType})}
            {form.renderUneditableTextInputRow("replacingMeter",<>LID Device</>,{rowClasses:'mb-3',converter:m=>m.meterLid.lid.name})}
            {form.renderUneditableTextInputRow("replacingMeter",<>LID Serial number</>,{rowClasses:'mb-3',converter:m=>m.meterLid.lidSerialNumber})}
            {form.renderUneditableTextInputRow("replacingMeter",<>LID Type</>,{rowClasses:'mb-3',converter:m=>m.meterType?m.meterType.name:null})}
            {meter.meterType && meter.meterType.key=='PULSE' && form.renderUneditableTextInputRow("replacingMeter",<>Scaling Factor (Multiplier)</>,{rowClasses:'mb-3',converter:m=>m.meterScalingFactor})}
        </>
    }
}