import React from 'react';
import { StepProgressBar } from '../../../../components/tools/BootstrapControls';
import ValidationAllFieldsRO from './ValidationAllFieldsRO';

export default class ValidationPage5  extends React.Component {

    async componentDidMount() {this.props.validationForm.disableFields();}


    render() {
        const approval = this.props.approval;
        const form = this.props.validationForm;
        const role = this.props.role;
        let parentNotActiveError = false;
        if(form.vals().parentMeter && form.vals().parentMeter.status!='ACTIVE') parentNotActiveError = true;
        return <>
            <div className='my-5'>      
                <StepProgressBar
                    totalSteps={5} 
                    currentStep={5} 
                    disableFutureSteps={form.val('formStatus')==1} 
                    stepClick={(page) =>form.enableFields(this.props.gotToStep(page))}
                />
            </div>

            <ValidationAllFieldsRO meter={this.props.meter} approval={approval} validationForm={form} />

            {form.val('formStatus')==1 && <div class="text-end">

                <button type="button" class="btn btn-primary float-start" onClick={()=>form.enableFields(()=>this.props.gotToStep(4))} disabled={form.isFormDisabled()}>
                    Back
                </button>

                {role=="OWNER" && <button type="button" class="btn btn-success" onClick={()=>form.runAction('submit')} disabled={form.isFormDisabled() || parentNotActiveError}>
                    Submit
                </button>}

                {parentNotActiveError && <div className='text-danger'>Please activate the first meter of this LID before you submit this form</div>}

            </div>}
        </>;
    }
}