const dotenv = require("dotenv");
dotenv.config();

const confg = {
  googleAnalytics: {
    gTagId: process.env.REACT_APP_GTAG_ID,
    gtmTag: process.env.REACT_APP_GTM_TAG
  }
};

module.exports = confg;
