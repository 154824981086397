import React, { useContext } from 'react';
import { GlobalContext } from './State';
import { formatDateTime, formatDate, roundOff } from '../constants/MeterFormConstants'
import { filterWaterSource } from './MeasurementConstants';
import { TIMESTAMP, colourText, LOG_ENTRY_TYPE, PENDING, SPAN_RED, MESSAGE, TOTAL_TAKE } from './MeasurementConstants';

const DAS_TAKE = 0.0;
const DAS_OPENING_LEVEL = 0.0;
const DAS_CLOSING_LEVEL = 0.0;
const CLSOING_STAORAGE_LEVEL = 0.0;

const getTakeFromNominiation = (nomination, consumedVolume) => {
  let total = DAS_TAKE;
  Object.keys(nomination.discharge).forEach((key, index) => {
    total -= parseInt(nomination.discharge[key].amount);
  })
  Object.keys(nomination.flow).forEach((key, index) => {
    total += parseInt(nomination.flow[key].amount);
  })
  if (consumedVolume) {
    total += consumedVolume;
  }
  return total;
}




export const TransactionTable = ({ nomination }) => {
  const { holder } = useContext(GlobalContext);
  console.log("nomination_summary_is");
  console.log(nomination);
  console.log("nomination date");
  console.log(nomination.timeRange.endDate);
  let entryLog = nomination.logEntry.entryLog;
  let priorDayReading = nomination.logEntry.priorDayReading;
  let filteredEntryLog = entryLog.filter(entry => entry?.status != PENDING);
  let consumedVolume = 'NA';
  if (filteredEntryLog && filteredEntryLog.length > 0) {
    consumedVolume = roundOff(filteredEntryLog[filteredEntryLog.length - 1].volume - (priorDayReading.volume)) + 'ML';
  }

  return (
    <>
      <table class="table table-bordered" border='1'>
        <thead>
          <tr bgcolor="#0054a6">
            <td width="14.2857%">{colourText('Description')}</td>
            <td width="14.2857%">{colourText('Type of water')}</td>
            <td width="14.2857%">{colourText('Date Range')}</td>
            <td width="14.2857%">{colourText('Data Source')}</td>
            <td width="14.2857%">{colourText('Prior Telemetered storage level')}</td>
            <td width="14.2857%">{colourText('Current Telemetered storage level')}</td>
            <td width="14.2857%">{colourText('Water Take Volume')}</td>
          </tr>
        </thead>
        <tbody>

          {(entryLog && (entryLog.length > 0)) &&
            <tr key={TIMESTAMP()}>
              <td>FPH in-flow({nomination.logEntry.type === LOG_ENTRY_TYPE.MANUAL ? "Manual" : "Telemetry"})</td>
              <td>Overland flows into storage</td>
              {(nomination.timeRange.startDate == null) &&
                <td></td>
              }
              {((nomination.timeRange.startDate != null) && (nomination.timeRange.endDate == null)) &&
                <td>{formatDateTime(nomination.timeRange.startDate)} to </td>
              }
              {((nomination.timeRange.startDate != null) && (nomination.timeRange.endDate != null)) &&
                <td>{formatDateTime(nomination.timeRange.startDate)} to {formatDateTime(nomination.timeRange.endDate ? nomination.timeRange.endDate : Date.now)}</td>
              }
              <td>{nomination.logEntry.type === LOG_ENTRY_TYPE.MANUAL ? "Manual" : "Telemetry"}</td>
              <td> {roundOff(priorDayReading.height)}mAHD</td>
              <td>{roundOff(entryLog[entryLog.length - 1].height)}mAHD</td>
              <td>{consumedVolume} <br /><span style={SPAN_RED} id={TOTAL_TAKE + MESSAGE}></span></td>
            </tr>
          }
          {Object.keys(nomination.flow).map((key, index) => {
            return <tr key={index}>
              <td>Other in-flow</td>
              {/* <td>Access Licence Regulated</td> */}
              <td>{filterWaterSource(nomination.flow[key].waterSource, holder.referenceData).label}</td>
              {/* <td>{formatDate(nomination.timeRange.startDate)}</td> */}
              {(nomination.timeRange.startDate == null) &&
                <td></td>
              }
              {((nomination.timeRange.startDate != null) && (nomination.timeRange.endDate == null)) &&
                <td>{formatDateTime(nomination.timeRange.startDate)} to </td>
              }
              {((nomination.timeRange.startDate != null) && (nomination.timeRange.endDate != null)) &&
                <td>{formatDateTime(nomination.timeRange.startDate)} to {formatDateTime(nomination.timeRange.endDate ? nomination.timeRange.endDate : Date.now)}</td>
              }
              <td>Customer Readings</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>{nomination.flow[key].amount}ML</td>
            </tr>
          })}
          {Object.keys(nomination.discharge).map((key, index) => {
            return <tr key={index}>
              <td>Discharge </td>
              {/* <td>Access Licence Regulated</td> */}
              <td>{filterWaterSource(nomination.discharge[key].waterSource, holder.referenceData).label}</td>
              {(nomination.discharge[key].startDate == null) &&
                <td></td>
              }
              {(nomination.discharge[key].startDate != null) && (nomination.discharge[key].endDate == null) &&
                <td>{formatDateTime(nomination.discharge[key].startDate)} to </td>
              }
              {(nomination.discharge[key].startDate != null) && (nomination.discharge[key].endDate != null) &&
                <td>{formatDateTime(nomination.discharge[key].startDate)} to {formatDateTime(nomination.discharge[key].endDate ? nomination.discharge[key].endDate : Date.now)}</td>
              }
              <td>Customer Readings</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>-{nomination.discharge[key].amount}ML</td>
            </tr>
          })}
        </tbody>
      </table>
    </>
  )
}