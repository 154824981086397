import React from 'react';
import { isStringNullOrEmpty } from '../../../../helpers/validators';
import { InputText } from '../../../../components/tools/BootstrapControls';
import { AsteriskRed } from '../../../../components/tools/Others';
import * as apiCaller from '../../../../helpers/apiCaller'
import serviceUrls from '../../../../constants/serviceUrls';
import uiHelpers from '../../../../helpers/uiHelpers';

class MeterAdminDeletion  extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            confirming:false
            , submitting:false
            , succeeded:false
            , comments:''
            , commentsError:null
        };
    }

    onExecuteClick = () => {
        if(isStringNullOrEmpty(this.state.comments)) {
            this.setState({commentsError:'This is required'})
        } else {
            this.setState({commentsError:null, confirming:true});
        }
        
    }

    onCancelClick = () => {this.setState({confirming:false});}

    onConfirmClick = async () => {
        
        uiHelpers.showProgressOnTop('Deleting...');
        this.setState({submitting:true})
        await apiCaller.post(serviceUrls.admin.deleteMeter,{id:this.props.meter.id, comments:this.state.comments});
        await this.props.refresh();
        this.setState({succeeded:true, submitting:false});
        uiHelpers.hideProgressOnTop();
        
    }

    renderWhatWillHappen = () => {
        return <div>
            <p>Please note that the following changes will be required in this deletion process:</p>
            <div className='text-start row'>
                <div className='col-md-3'></div>
                <div className='col-md-6'>
                    <ul>
                        <li>The meter record created in this process (if any) within DQP Portal will be deleted. (Status will be updated to "Deleted").</li>
                        <li>The workspace created in this process (if any) within DAS <b className='text-primary'>WILL NOT</b> be deleted. This will have to be progressed further manually.</li>
                    </ul>
                </div>
                <div className='col-md-3'></div>
            </div>
        </div>
    }

    renderMainDeleteFunction = () => {
        return <>

            <div className='row mb-3'>
                <div className='col-md-4'>
                    Comments <AsteriskRed/>
                </div>
                <div className='col-md-8'>
                    <InputText
                        value={this.state.comments} 
                        error={this.state.commentsError} 
                        disabled={this.state.confirming || this.state.succeeded}
                        onChange={(e)=>this.setState({comments:e.target.value})} 
                    /> 
                </div>
            </div>

            {!this.state.confirming && !this.state.succeeded && <>
                
                <div className='row mb-3'>
                    <div className='col-md-4'/>
                    <div className='col-md-8'>
                        <button className='btn btn-primary' onClick={this.onExecuteClick}>Proceed</button>
                    </div>
                </div>
            </>}

            {this.state.confirming && !this.state.succeeded && <div className='card border-danger border-1 mb-3'>
                <div className='card-body bg-danger bg-opacity-10 text-center'>
                    <p className='text-primary'>
                        <b>Please confirm that you would like to proceed with this deletion.</b>
                    </p>
                    {this.renderWhatWillHappen()}
                    <div>
                        <button type="button" class="btn btn-secondary me-3" onClick={this.onCancelClick} disabled={this.state.submitting}>
                            Cancel
                        </button>
                        <button type="button" class="btn btn-primary" onClick={this.onConfirmClick} disabled={this.state.submitting} >
                            Confirm
                        </button>
                    </div>
                </div>
            </div>}

            {this.state.succeeded && <div className='card border-success border-1 mb-3'>
                <div className='card-body bg-success bg-opacity-10 text-center text-success'>
                    <b>This meter has been deleted (Marked as deleted status).</b>
                </div>
            </div>}

        </>
    }

    render() {
        if(!this.state.succeeded && this.props.meter.status!='ACTIVE') return <div className='alert alert-danger mt-4'>This form cannot be deleted under this status.</div>;
        return this.renderMainDeleteFunction();
    }
}

export default MeterAdminDeletion;
