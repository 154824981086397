import e from 'cors';
import {
  INTAKE,
  DISCHARGE,
  NEXT,
  DATE_FORMAT,
  START,
  LOG_JSON,
  OBJECT_FLOW,
  OBJECT_DISCHARGE,
  FLOW_WATER_SOURCE,
  DISCHARGE_WATER_SOURCE,
  DISCHARGE_AMOUNT,
  START_DAY,
  END_MONTH,
  TIMESTAMP,
  ACTIONS,
  LOG_ENTRY_TYPE
} from './MeasurementConstants';

const logConcise = data => JSON.stringify({ ...data, nomination: { ...data.nomination, ratingTableContents: [] }, referenceData: {} },
  null,
  2);


export default function appReducer(state, action) {
  console.log("appReducer action=" + action.type);
  debugger;
  switch (action.type) {
    case ACTIONS.UPDATE:
      if (action.payload || action.payload == false) {

        //sanitise, return updated state
        console.log("payload_appReducer:");
        console.log(action.payload);
        let nomination = { ...state.nomination };
        if (action.payload.holderName || action.payload.holderName == false) {
          nomination = { ...nomination, holderName: action.payload.holderName };
        }

        if (action.payload.measurementDeviceDamaged || action.payload.measurementDeviceDamaged == false) {
          nomination = { ...nomination, measurementDeviceDamaged: action.payload.measurementDeviceDamaged };
        }

        if (!action.payload.subType) {

          if (action.payload.startDate) {
            nomination = { ...nomination, timeRange: { ...nomination.timeRange, startDate: action.payload.startDate } };
          }

          if (action.payload.endDate) {
            let nonCompliantCode = action.payload.nonCompliantCode ? action.payload.nonCompliantCode : 0;
            nomination = { ...nomination, timeRange: { ...nomination.timeRange, endDate: action.payload.endDate }, nonCompliantCode: nonCompliantCode };
          }
        }

        if (action.payload.subType) {
          if (action.payload.subType === INTAKE) {

            if (action.payload.waterSource) {
              nomination.flow[action.payload.key].waterSource = action.payload.waterSource;
            }

            if (action.payload.amount || action.payload.amount == false) {
              nomination.flow[action.payload.key].amount = action.payload.amount;
            }

          }
          if (action.payload.subType === DISCHARGE) {

            if (action.payload.waterSource) {
              nomination.discharge[action.payload.key].waterSource = action.payload.waterSource;
            }

            if (action.payload.amount || action.payload.amount == false) {
              nomination.discharge[action.payload.key].amount = action.payload.amount;
            }

            if (action.payload.startDate) {
              nomination.discharge[action.payload.key].startDate = action.payload.startDate;
            }

            if (action.payload.endDate) {
              nomination.discharge[action.payload.key].endDate = action.payload.endDate;
            }

          }
        }

        if (action.payload.nonCompliantComments == "" || action.payload.nonCompliantComments) {
          nomination = { ...nomination, nonCompliantComments: action.payload.nonCompliantComments };
        }

        if (action.payload.userid) {
          nomination = { ...nomination, attributes: { ...nomination.attributes, createdBy: action.payload.userid } };
        }

        state = ({ ...state, nomination: nomination, dirty: TIMESTAMP() });
        console.log("new_state");
        console.log(state);
        return state;
      }
      break; //TODO remove all break, return state here

    case ACTIONS.LOAD:
      if (action.payload) {
        let entryLog = action.payload.logEntry?.entryLog;
        if (entryLog && Array.isArray(entryLog) && entryLog.length > 1) {
          action.payload.logEntry.entryLog = entryLog.sort((a, b) => Date.parse(a.entryDate) > Date.parse(b.entryDate) ? 1 : -1)
        }

        state = ({ ...state, nomination: action.payload, page: action.page });
        console.log("new_state_POST_LOAD");
        console.log(state);
        debugger;
      }
      return state;


    case ACTIONS.LOAD_RATING_TABLE:
      if (action.payload) {
        state = ({ ...state, nomination: { ...state.nomination, ratingTable: action.payload } });
        console.log("new_state");
        console.log(state);
        return state;
      }
      break;

    case ACTIONS.FLICK_PAGE:
      if (action.payload) {
        state = ({ ...state, page: action.payload });
        console.log("new_state");
        console.log(state);
        return state;
      }
      break;

    case ACTIONS.ADD_NEW_FLOW: {
      let id = 'T' + TIMESTAMP();
      state.nomination.flow[id] = { ...OBJECT_FLOW };
      return { ...state, dirty: TIMESTAMP() };
    }

    case ACTIONS.DEL_FLOW: {
      return { ...state, dirty: TIMESTAMP() };
    }

    case ACTIONS.SET_REFERENCE_DATA: if (action.payload) {
      console.log("SET_REFERENCE_DATA_payload" + JSON.stringify(action.payload, null, 2));
      state = ({
        ...state, referenceData: {
          ...action.payload,
          completedForms: (action.payload.forms && action.payload.forms.length > 0) ?
            action.payload.forms.filter(theForm => theForm.FormStatus == 2).sort((a, b) => a.id > b.id ? 1 : -1
            ) : []
        }
      }
      );
      LOG_JSON(state, "SET_REFERENCE_DATA new_state");
      return state;
    }


    case ACTIONS.ADD_NEW_DISCHARGE: {
      let id = 'T' + TIMESTAMP();
      state.nomination.discharge[id] = { ...OBJECT_DISCHARGE };
      return { ...state, dirty: TIMESTAMP() };
    }

    case ACTIONS.DEL_DISCHARGE: {
      return { ...state, dirty: TIMESTAMP() };
    }

    case ACTIONS.SET_DAS_DATA_DISPLAYED: {
      return { ...state, dasDataAlertDisplayed: action.payload.dasDataAlertDisplayed };
    }


    case ACTIONS.SET_MANUAL_ENTRY_LOG:
      debugger;
      let priorVolume = state.nomination.logEntry.priorDayReading.volume;
      let revisedEntryLog = [];
      if (action.payload && Array.isArray(action.payload) && action.payload.length > 0) {
        for (let i = 0; i < action.payload.length; i++) {
          let thisElement = action.payload[i];
          if (thisElement.volume > 0) {
            if (i == 0) {
              thisElement.deltaVolume = thisElement.volume - priorVolume;
            } else {
              thisElement.deltaVolume = thisElement.volume - action.payload[i - 1].volume;
            }
          }
          revisedEntryLog.push(thisElement);

        }
      } else {
        revisedEntryLog = action.payload;
      }
      state = ({
        ...state,
        nomination: {
          ...state.nomination,
          logEntry: { ...state.nomination.logEntry, entryLog: revisedEntryLog }
        }
      });
      console.log("new_state" + logConcise(state))
      return state;


    case ACTIONS.SET_PRIOR_ENTRY_HEIGHT:
      debugger;
      let area;
      let volume;
      let ratingTableMatch = state.nomination.ratingTableContents.find(entry => entry.elevation == action.payload);
      if (ratingTableMatch) {
        area = ratingTableMatch.area;
        volume = ratingTableMatch.volume;
      } else {
        area = action.area;
        volume = action.volume;
      }

      state = ({
        ...state,
        nomination: {
          ...state.nomination,
          logEntry: { ...state.nomination.logEntry, priorDayReading: { volume: volume, area: area, height: action.payload, finalVolume: 0 } }
        }
      });
      console.log("new_state" + logConcise(state))
      return state;

    case ACTIONS.SET_MANUAL_ENTRY_LOG_HEIGHT:
      debugger;
      let entryLog = state.nomination.logEntry.entryLog;
      let entry = entryLog.find(ele => ele.id == action.key);
      let ratingTableEntry = state.nomination.ratingTableContents.find(entry => entry.elevation == action.value);

      entry.height = action.value;
      entry.volume = ratingTableEntry.volume;
      entry.area = ratingTableEntry.area;
      if (entry.height > 0) {
        if (action.index == 0) {
          entry.deltaVolume = entry.volume - state.nomination.logEntry.priorDayReading.volume;
        } else {
          entry.deltaVolume = entry.volume - state.nomination.logEntry.entryLog[action.index - 1].volume;
        }
      }

      state = ({
        ...state,
        nomination: {
          ...state.nomination,
          logEntry: { ...state.nomination.logEntry, entryLog: entryLog }
        }
      });

      console.log("new_state" + logConcise(state))
      return state;

    case ACTIONS.SET_RATING_TABLE_CONTENTS:
      debugger;
      return ({ ...state, nomination: { ...state.nomination, ratingTableContents: action.payload?.ratingTableContents } });

    case ACTIONS.SET_MANUAL_ENTRY_FLAG:
      return (
        {
          ...state,
          nomination: {
            ...state.nomination,
            logEntry: { ...state.nomination.logEntry, type: (action.payload === LOG_ENTRY_TYPE.MANUAL) ? LOG_ENTRY_TYPE.MANUAL : LOG_ENTRY_TYPE.DAS }
          }
        }
      );

    case ACTIONS.SET_DECLARATION:
      let curval = (action.payload == 3) ? state.nomination.declaration3 : (action.payload == 2) ? state.nomination.declaration2 : state.nomination.declaration1;
      console.log("curval:" + curval);
      return (
        {
          ...state,
          nomination: {
            ...state.nomination,
            ['declaration' + action.payload]: !curval
          }
        }
      );

    // case ACTIONS.SET_MEASUREMENTDEVICE_DAMAGED:
    //   let curMeasVal = (action.payload == 3) ? state.nomination.declaration3 : (action.payload == 2) ? state.nomination.declaration2 : state.nomination.declaration1;
    //   console.log("curval:" + curMeasVal);
    //   return (
    //     {
    //       ...state,
    //       nomination: { ...state.nomination, measurementDeviceDamaged: action.payload.measurementDeviceDamaged }
    //     }
    //   );

    case ACTIONS.SET_AHD_HEIGHT:

      let logEntry = state.nomination.logEntry;
      let gaugeHeight = action.payload.gaugeHeight;
      let index = action.payload.index
      let targetObject;

      if (index == -1) {
        targetObject = logEntry.priorDayReading;
      } else {
        targetObject = logEntry.entryLog[index];
      }

      console.log('targetObject before');
      console.log(targetObject);

      targetObject.height = action.payload.ahd.elevation;
      targetObject.volume = action.payload.ahd.volume;
      targetObject.area = action.payload.ahd.area;
      targetObject.gaugeHeight = gaugeHeight;

      console.log('targetObject after');
      console.log(targetObject);
      for (let idx = 0; idx < logEntry.entryLog.length; idx++) {
        if (logEntry.entryLog[idx].volume != 0) {
          if (idx == 0) {
            logEntry.entryLog[idx].deltaVolume = logEntry.entryLog[idx].volume - logEntry.priorDayReading.volume;
          } else {
            logEntry.entryLog[idx].deltaVolume = logEntry.entryLog[idx].volume - logEntry.entryLog[idx - 1].volume;
          }
        }
      }

      console.log('state');
      console.log(state);
      state = { ...state, dirty: TIMESTAMP() }
      return state;


    default: return state;
  }


}