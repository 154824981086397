import React from 'react';
import * as apiPathes from '../../apiPathes';
import * as serviceCaller from '../../serviceCaller'
import { Redirect } from 'react-router-dom';


class DeletePeriodForm  extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            deletingRecord: false
        };
    }
    
    componentDidMount() {
          
    }

    

    deletePeriod =async () => {
        const {nominatedMeasurementPeriod} = this.props;
        await serviceCaller.post(apiPathes.deleteNominatedMeasurementPeriod,
            {
                nominatedMeasurementPeriodId: nominatedMeasurementPeriod.id
            }, 
            {
                noIWasLoginHandler: this.props.noLoginCallback,
                loadingMsg: 'Deleting your record....',
                beforeSend: ()=>{
                    this.setState({deletingRecord: true})
                },
                success: ()=>{
                    this.state.redirectTo = '/dashboard';
                }
        });

        this.state.deletingRecord=false;
        this.setState(this.state)
    }

    render(){
        return <>
            {this.state.redirectTo && <Redirect to={this.state.redirectTo} />}
            <div className='card border-danger border-2 text-end mb-5'>
                <div className='card-body '>
                    <p><strong>Please confirm that you want to delete this nominated measurement period record</strong></p>
                    <button className='btn btn-primary me-4' disabled={this.state.deletingRecord} onClick={this.deletePeriod}>
                        Confirm
                    </button>
                    <button className='btn btn-outline-secondary' disabled={this.state.deletingRecord} onClick={this.props.onCancel}>
                        Cancel
                    </button>
                </div>
            </div>
        </>;
    }

}

export default DeletePeriodForm;