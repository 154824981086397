import React, { Component } from 'react';
//import './global';
import Authentication from '../../helpers/Authentication';
import axios from 'axios';
import uiHelpers from '../../helpers/uiHelpers';

const authentication = new Authentication();

class RatingTableSeacherLauncher extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchForm : {
                roiOrStorageWorkApproval : ""
            },
            selectedRatingTableId : -1,
            isSearchFormLoading : false,
            ratingTableSearchResult : [],
            isSearchCompleted : false,
            selectedRatingTable : null
        }
    }

    ajaxConfig = {
        baseURL: process.env.REACT_APP_WEBAPI_URL,
        headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authentication.getToken() }
    };

    searchRoiOrStorageWorkApproval= (e) => {
        e.preventDefault();
        let currentContext = this;
        currentContext.setState(Object.assign(currentContext.state,{isSearchFormLoading : true, selectedRatingTable: null, selectedRatingTableId: -1} ));
        uiHelpers.showProgressOnTop("Searching for storages")
        axios.post('/ratingTables/searchByRoiOrStorageWorkApproval', JSON.stringify(this.state.searchForm), this.ajaxConfig).then(function (response) {
            currentContext.setState(Object.assign(currentContext.state,{ratingTableSearchResult : response.data.data, isSearchCompleted: true} ));
            uiHelpers.hideProgressOnTop();
        }).catch(function (error) {
            uiHelpers.showErrorTop('Error happened on the server side when searching for ROI / Storage work approval number.');
        }).then(function () {
            currentContext.setState(Object.assign(currentContext.state,{isSearchFormLoading : false} ));
        }); 
    }

    onRoiOrStorageWorkApprovalChange = (e) => {
        this.setState(Object.assign(this.state.searchForm, { roiOrStorageWorkApproval: e.target.value }));
    }
    onRatingTableDropdownChange  = (e) =>{
        this.setState(Object.assign(this.state, { selectedRatingTableId: e.target.value }));
        if(e.target.value == -1) {
            this.setState(Object.assign(this.state, { selectedRatingTable: null }));
        }

        for(var i =0; i < this.state.ratingTableSearchResult.length; i++){
            if(this.state.ratingTableSearchResult[i].id == e.target.value) {
                this.setState(Object.assign(this.state, { selectedRatingTable: this.state.ratingTableSearchResult[i] }));
            }
         }

    }

    selectRatingTable = (e) => {
        if(this.state.selectedRatingTable == null) {
            return;
         }
        this.props.onRatingTableSelected(this.state.selectedRatingTable);
    }

    render(){
        return (
            <div>
                <h5 className='mb-3'>Search for FPH Storage</h5>
                
                <div class="row mb-3">
                    <div class="col-md-6">
                        <form onSubmit={this.searchRoiOrStorageWorkApproval}>
                            <div class="mb-3">
                                <label class="form-label">Enter Registration of Interest (ROI) number / Storage work approval number</label>
                                <input type="text" class="form-control" disabled={this.props.disabled} onChange={this.onRoiOrStorageWorkApprovalChange} value={this.state.searchForm.roiOrStorageWorkApproval} placeholder="ROI / Storage work approval" />
                            </div>
                            <div>
                                <button type="submit" disabled={this.state.isSearchFormLoading || this.props.disabled} class="btn btn-primary" >Search</button>
                            </div>
                        </form>
                    </div>
                </div>

                {this.state.isSearchCompleted && this.state.ratingTableSearchResult && this.state.ratingTableSearchResult.length==0&&
                    <div class="alert alert-primary" role="alert">
                        Search result is empty
                    </div>
                }

                {this.state.isSearchCompleted && !this.state.isSearchFormLoading && this.state.ratingTableSearchResult && this.state.ratingTableSearchResult.length>0&&
                    <>
                        
                        <div class="row">
                            <div class="col-md-6">
                                <div class="mb-3">
                                    <label className='form-label'>Select storage</label>
                                    <select class="form-select" disabled={this.props.disabled} onChange={this.onRatingTableDropdownChange} value={this.state.selectedRatingTableId}>
                                        <option value={-1}>Select</option>
                                        {this.state.ratingTableSearchResult.map(function(object, i){
                                            return <option value={object.id} key={object.id}>{object.ratingTableName}</option>;
                                        })}
                                    </select>
                                </div>
                                <div>
                                    <button type="button" class="btn btn-primary" disabled={this.state.selectedRatingTable==null || this.state.isRatingTableLoading || this.props.disabled } onClick={this.selectRatingTable}> 
                                        {this.props.contineBtnTxt && this.props.contineBtnTxt}
                                        {!this.props.contineBtnTxt && "Continue"}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </>
                }


                
            </div>
        )
    }
}

export default RatingTableSeacherLauncher;