import { MESSAGE_REQUIRED} from '../../../constants/MeterFormConstants';

export const validate = (values,params) => {

const errors = {

    meteringEquipmentInformation: {

        meterPatternApproved: "",// [required()],//text
        transitionalDeclaration: ""
    }
}

console.log("in accuracy validation");
let clickSaveDraft = params.clickSaveDraft
let clickNext = params.clickNext;
if(clickSaveDraft == undefined)
    clickSaveDraft = values.clickSaveDraft;
if(clickNext == undefined)
    clickNext = values.clickNext;
console.log("draft:"+clickSaveDraft);
if(clickSaveDraft === 1 && clickNext === 0)
{
}
else if(clickSaveDraft == 0 && clickNext === 1 && values.meteringEquipmentInformation.formStatus != 2)
{
    if (!values.meteringEquipmentInformation.meterPatternApproved) {
        errors.meteringEquipmentInformation.meterPatternApproved = MESSAGE_REQUIRED
    }
    
    if (!values.meteringEquipmentInformation.transitionalDeclaration) {
        errors.meteringEquipmentInformation.transitionalDeclaration = MESSAGE_REQUIRED
    }
    
}





return errors;
}



