import React, {useState} from 'react';
import { reduxForm, Field, formValueSelector, change } from 'redux-form';
import { connect } from 'react-redux';
import 'redux';
import "react-datepicker/dist/react-datepicker.css";
import { RadiosForRedux, InputText , DatePickerForRedux, SelectForRedux}  from '../../tools/BootstrapControls'
import {  MESSAGE_UPLOAD_LIMITS, closedConduitandProtableOptionsForAccuracyCert as closedConduitandProtableOptions, openChannelOptionsForAccuracyCert as openChannelOptions} from '../../../constants/MeterFormConstants'
import Authentication from '../../../helpers/Authentication';
import * as userHelpers from '../../../helpers/userHelpers';
import { saveDraftAction, saveSubmitAction, scrollTofocusError } from "../common/MeterFormValidations"
import { validateCertificationFormSection } from './AccuracyExistingMeteringCertificate.Validation'
import FileUploader from '../../common/FileUploader'

const validate = validateCertificationFormSection; 

let fileUploaderDisplayListUpdate;

let _validateFiles;

const validateAll = (values, props) => {
   const result = validate(values, props);

   let clickSaveDraft = (props.clickSaveDraft== undefined? values.clickSaveDraft:props.clickSaveDraft);
   let clickNext = props.clickNext== undefined ? values.clickNext : props.clickNext;

   if (clickSaveDraft == 0 && clickNext === 1 && values.meteringEquipmentInformation.formStatus != 2) {
      //alert(_validateFiles)
      if(_validateFiles) {
         if(!_validateFiles()){
            result.meteringEquipmentInformation.fileType = 'Please upload all manadatory supporting documents';
            return result;
         }
      }
   }
   return result;
}

let CertificationFormSection = props => {

   const [renderCnt, setRenderCnt] = useState(0);

   let { handleSubmit, previousPage, meterEquipmentValues,
      documents , loading, hasError
   } = props

   const disableFields = (parseInt(props.meterEquipmentValues.formStatus) ==2) || props.disableEditing || loading ? true: false;
   const authentication = new Authentication();
   let dqpUserName = "";
   let dqpUserEmail = "";
   var user = authentication.currentUser();
   if(authentication.isLoggedIn)
   {
      dqpUserName = user.firstName + ' '+ user.lastName;
      dqpUserEmail = user.emails;
   }

   const permissibleErrorOptions = 
         [
            { id: "1", name:"does not exceed +/-5%",selected:false},
            { id: "2", name:"exceeds +/-5%", selected:false},
         ];
   const accuracyOwnErrorOptions = 
         [
            { id: "1", name:"YES",selected:false},
            { id: "2", name:"NO", selected:false},
         ];

   let uploadOptions = closedConduitandProtableOptions;

   if (parseInt(props.meterEquipmentValues.meteringSystemTypeId)==2) {
      uploadOptions = openChannelOptions;
   }

   let dqpPhoneNumber = "";
   let dqpAddress = "";
   let dqpMeterInstallerNumber = "";
   let dqpQulaificationType = "";
   let dqpBusinessName = "";
   let dqpAbnOrAcn = "";
   
   if(props.userProfileValues != undefined && props.userProfileValues != null && props.userProfileValues.length > 0)
   {
      let profile = props.userProfileValues[0];
      dqpPhoneNumber =profile.phoneNumber;
      dqpAddress = profile.postalAddress.addressLine;
      dqpMeterInstallerNumber = profile.cmino;
      dqpQulaificationType = userHelpers.getDQPQulificationTypeNameFromValue(profile.dqpQualificationType);
      dqpBusinessName = profile.businessName;
      dqpAbnOrAcn = profile.abnOrAcn;
   }

  
   const validateFiles =()=>{
      var validated=[];
      var options=[];
      for (var k=0;k<=uploadOptions.length-1;k++)
      {
           if (uploadOptions[k].name!="Other")
           {
              options.push(uploadOptions[k]);
           }
      }

      if (props.documents.length>0)
      {
         for (var i=0;i<=props.documents.length-1;i++)
         {
            if(!(props.documents[i].status == 'new' || props.documents[i].status == 'existing')){
               continue;
            }
            for (var j=0; j<=options.length-1;j++)
            {
               if (parseInt(props.documents[i].documentType)==parseInt(options[j].id))
               {
                   validated.push(options[j].id);
               }
            }
         }
      }

      return validated.length>=options.length;
   }

   _validateFiles = validateFiles;
   

   const updateDisplayFileList = ()=>{

      if(!fileUploaderDisplayListUpdate) return;
      const newList = [];

      if(documents && documents.length!=0) {

         var option;
         for(var i = 0; i< documents.length; i++){
            if(documents[i].status=='new' || documents[i].status=='existing'){
               var option = uploadOptions.filter(option=>option.id==documents[i].documentType)[0];
               newList.push({
                  id: documents[i].id,
                  fileTypeDesc : option?option.name:"",
                  description: documents[i].description,
                  fileOriginalName: documents[i].fileOriginalName,
                  fileGUID: documents[i].fileGUID
               });
            }
         }

      }
      fileUploaderDisplayListUpdate(newList);
      setRenderCnt(renderCnt+1)
   }

   const fileUploaderFilesUploaded=(files)=>{
      
      var fileType = props.meterEquipmentValues.fileType;
      var docs = props.documents;

      for(var i = 0; i< files.length; i++){

         docs.push({
            id : files[i].id,
            documentType : fileType,
            description : files[i].description,
            fileOriginalName : files[i].fileOriginalName,
            fileGUID : files[i].fileGUID,
            status : "new"
         });
      }
      props.dispatch(change(props.form, 'documents', docs))
      updateDisplayFileList();
   }

   const fileUploaderFileDeleted=(file)=>{
      if(!props.documents) return;

      for(var i = 0; i< props.documents.length; i++){
         if(props.documents[i].id==file.id){
            if (props.documents[i].status == "new")
               props.documents[i].status = "newDeleted";
            else if (props.documents[i].status == "existing")
               props.documents[i].status = "existingDeleted";
         }
      }

      props.dispatch(change(props.form, 'documents', props.documents))
      updateDisplayFileList();
   }



   
   console.log(meterEquipmentValues.date);
   const dateValidation = new Date()
   const dateCreated = new Date()
   if(meterEquipmentValues.date != null && meterEquipmentValues.date == "")
      meterEquipmentValues.date = dateCreated;

   if(meterEquipmentValues.dateValidation != null && meterEquipmentValues.dateValidation == "")
    meterEquipmentValues.dateValidation = dateValidation;
   
   meterEquipmentValues.date = new Date(meterEquipmentValues.date);
   meterEquipmentValues.dateValidation = new Date(meterEquipmentValues.dateValidation);
   if ((!props.meterEquipmentValues.fileType || props.meterEquipmentValues.fileType == 0) && uploadOptions.length > 0) {
      if (parseInt(props.meterEquipmentValues.meteringSystemTypeId)==2) {
         props.dispatch(change(props.form, 'meteringEquipmentInformation.fileType', "4"))
      } else {
         props.dispatch(change(props.form, 'meteringEquipmentInformation.fileType', "3"))
      }
      
   }

   //updateDisplayFileList();

   

   return (
      <form className="vertical" onSubmit={handleSubmit}>
         <h5 className='mb-3'>Certification</h5>

         <div className='card mb-3'>
            <div className='card-header'><strong>I certify that: </strong></div>
            <div className='card-body'>
               <p className='fw-bold'>
               a) I am a duly qualified person under the Water Management (General) Regulation 2018 for the purpose of validating the metering equipment described in this certificate
               </p>

               <p className='fw-bold mb-1'>
               b) The maximum permissible error of the metering equipment: (choose one) <span className='text-danger'>*</span>
               </p>
               <Field 
                  name="meteringEquipmentInformation.accuracyMaxErrorMeteringEquipment"
                  component={RadiosForRedux} 
                  className="mb-3"
                  options={permissibleErrorOptions} 
                  values={meterEquipmentValues.accuracyMaxErrorMeteringEquipment}
                  props={{ disabled: disableFields }}
               />
               <p className='fw-bold'>
               c) All information contained in this certificate is accurate, true and complete
               </p>
               <p className='fw-bold'>
               d) I am aware that I must notify the Minister within 7 days if I know or reasonably suspect that metering equipment I am installing or carrying out work on has been tampered with
               </p>
               <p className='fw-bold mb-1'>
               e) I am checking the accuracy of my own metering equipment <span className='text-danger'>*</span>
               </p>
               <Field 
                  name="meteringEquipmentInformation.accuracyOwnMeteringEquipment"
                  component={RadiosForRedux} 
                  options={accuracyOwnErrorOptions} 
                  values={meterEquipmentValues.accuracyOwnMeteringEquipment}
                  props={{ disabled: disableFields }}
               />
               <hr className='my-4'/>

               <div className='row mb-3'>
                  <div className='col-sm-6'>
                     <InputText
                        label="Name"
                        id="dqpUserName"
                        value={dqpUserName}
                        disabled={true}
                     />
                  </div>
                  <div className='col-sm-6'>
                     <InputText
                        id="certifiedmeterinstallernumber"
                        label="Certified meter installer number"
                        value={dqpMeterInstallerNumber}
                        disabled={true}
                     />
                  </div>
               </div>

               <div className='row mb-3'>
                  <div className='col-sm-6'>
                     <InputText
                        label="DQP qalification type"
                        id="dqpQulaificationType"
                        value={dqpQulaificationType}
                        disabled={true}
                     />
                  </div>
               </div>
               <div className='row mb-3'>
                  <div className='col-sm-6'>
                     <InputText
                        label="Business name"
                        id="dqpBusinessName"
                        value={dqpBusinessName}
                        disabled={true}
                     />
                  </div>
                  <div className='col-sm-6'>
                     <InputText
                        label="ABN or ACN"
                        id="dqpAbnOrAcn"
                        value={dqpAbnOrAcn}
                        disabled={true}
                     />
                  </div>
               </div>
               <div className='row mb-3'>
                  <div className='col-sm-6'>
                     <InputText
                        label="Address"
                        id="dqpAddress"
                        value={dqpAddress}
                        disabled={true}
                     />
                  </div>
                  <div className='col-sm-6'>
                     <InputText
                        label="Phone"
                        id="dqpPhoneNumber"
                        value={dqpPhoneNumber}
                        disabled={true}
                     />
                  </div>
               </div>
               <div className='row mb-5'>
                  <div className='col-sm-6'>
                     <InputText
                        label="Email"
                        id="dqpUserEmail"
                        value={dqpUserEmail}
                        disabled={true}
                     />
                  </div>
                  <div className='col-sm-4'>
                     <Field 
                        name="meteringEquipmentInformation.dateValidation" 
                        label={<>Date of validation <span className='text-danger'>*</span></>}
                        component={DatePickerForRedux}
                        props={{ disabled: disableFields, maxDate: new Date()}}
                        values={meterEquipmentValues.dateValidation} 
                        handleChange={date => {props.change(props.form,'meteringEquipmentInformation.dateValidation', date)}}
                     />
                  </div>
               </div>
               <div className='row mb-3'>
                  <div className='col-sm-4'>
                     <Field 
                        name="meteringEquipmentInformation.date" 
                        label={<>Date <span className='text-danger'>*</span></>}
                        component={DatePickerForRedux}
                        props={{ disabled: disableFields, maxDate: new Date()}}
                        values={meterEquipmentValues.date} 
                        handleChange={date => {props.change(props.form,'meteringEquipmentInformation.date', date)}}
                     /> 
                  </div>
               </div>
               <div>
                  <i>
                  It is an offence under section 91J of the Water Management Act 2000 for a person to make a statement or furnish information in connection with a metering record the person knows to be false or misleading.
                  </i>
               </div>
            </div>
         </div>

         <div className='card mb-3'>
            <div className='card-header'>
               <strong>Supporting Documentation Checklist</strong>
            </div>
            <div className='card-body'>
               <p>
               Please ensure you upload photos of the meter installation as well as a copy of the Meter test certificate (if applicable)
               </p>
               <p>
               For closed-conduit meters this must include photos of the meter marker plate, meter seals, meter body and pipe, evidence of functioning display/readout panel, a picture of the site which shows the whole installation.
               </p>
               <p>
               For open-channel meters this must include photos of the sensor location, the channel looking upstream and downstream, evidence of functioning display/readout panel, a picture of the site which shows the whole installation.
               </p>

               <p className='fw-bold'>The following supporting information is mandatory and must be loaded into the DQP portal: </p>
               <div className='mb-3 ps-2'>
                  
                  {uploadOptions.map((option) => {
                     if(option.name=='Other') return null;
                     return <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id={"checklist_" + option.id} checked={props.documents.filter(doc=>( (doc.status=='new' || doc.status=='existing' ) && doc.documentType == option.id)).length > 0} />
                        <label class="form-check-label" for={"checklist_" + option.value}>
                           {option.name}
                        </label>
                     </div>
                  })}
               </div>


               <div className='row mb-3'>
                  <div className='col-sm-6'>
                     <Field
                        name="meteringEquipmentInformation.fileType"
                        component={SelectForRedux}
                        props={{ disabled: disableFields }}
                        value="3"
                        options={uploadOptions} 
                     />
                  </div>
               </div>
               
               <FileUploader
                     fileInputLabel={<strong>Upload your supporting documents here:</strong>}
                     fileTableHeader={<p><strong>Your uploaded files: </strong></p>}
                     warning={MESSAGE_UPLOAD_LIMITS}
                     maxFileSizeMB={10}
                     exceedFileSizeErrorMsg = "Your file is too big"
                     disabled={disableFields}
                     provideDisplayDocumentsUpdator={(updateFunction)=>{fileUploaderDisplayListUpdate = updateFunction;}}

                     fileTableHeadComponent={<tr>
                        <th style={{width:"80px"}}></th>
                        <th style={{width:"280px"}}>Type</th>
                        <th>File name</th>
                        <th>Description</th>
                     </tr>}

                     fileTableRowCompponent={(file, index, deleteButton)=>{
                        return <tr key={index}>
                           <td>
                              {deleteButton}
                           </td>
                           <td>
                              {file.fileTypeDesc}
                           </td>
                           <td>
                              <a target='_blank' href={process.env.REACT_APP_WEBAPI_URL + process.env.REACT_APP_FIELS_GET + "/" + file.id}>
                                 {file.fileOriginalName}
                              </a>
                           </td>
                           <td>{file.description}</td>
                     </tr>
                     }}

                     onFilesUploaded={fileUploaderFilesUploaded}
                     askForDocumentUpdate={updateDisplayFileList}
                     onFileDeleted={fileUploaderFileDeleted}
               />
            </div>
         </div>

         {hasError && <div className='alert alert-danger'>
            Please fix all errors before you continue
         </div>}

         <div className="text-end">   
            {(parseInt(meterEquipmentValues.formStatus) == 1)  && (!props.disableEditing) && <>
               {meterEquipmentValues.id!=0 &&
                  <button type="button"  
                     className="btn btn-danger me-3" disabled={loading} 
                     data-toggle="modal" data-target="#deleteDraftModal"
                  >
                        Delete Draft
                  </button>
               }
               <button type="button" 
                  className="btn btn-primary" disabled={loading} 
                  onClick={() => { saveDraftAction(props); }}
               >
                     Save Draft
               </button>
               
               <div className='vr mx-3'/>
            </>}

            <button type="button" className="btn btn-warning me-3"  disabled={loading} 
                  onClick={()=>{previousPage(meterEquipmentValues.formStatus)}}>
                     Previous
            </button>

            {(parseInt(meterEquipmentValues.formStatus) == 1)  && (!props.disableEditing) && <button type="button" className="btn btn-success" disabled={loading} 
                  onClick={() => { saveSubmitAction(props);}}>
                     Submit
            </button>}
            
         </div>
      </form>
   )
}

CertificationFormSection = reduxForm({
   destroyOnUnmount: false,
   forceUnregisterOnUnmount: true,
   validate : validateAll,
   onSubmitFail: (errors, dispatch ) => {
      dispatch(change('certificate', 'hasError', true));
   },
   onSubmitSuccess:(result , dispatch )=>{
      dispatch(change('certificate', 'hasError', false));
      window.scrollTo(0, 0);
   }
})(CertificationFormSection)

CertificationFormSection = connect(
   (state,props) => {
   const selectorCert = formValueSelector('certificate')
   const hasError = selectorCert(state, 'hasError')

     const selector = formValueSelector(props.form)
     const userProfileValues = selector(state, 'mdata.userProfile')
     const meterEquipmentValues = selector(state, 'meteringEquipmentInformation')
     const documents = selector(state, 'documents')
     const supportingDocuments = selector(state,'meterSupportingDocuments')
     const loading = selector(state, 'loading')
     return {
       userProfileValues,
       meterEquipmentValues,
       documents,
       form: props.form,
       supportingDocuments,
       loading,
       hasError
     }
   }, {
      //updateDeleteDocuments: (doc) => updateDeleteDocuments(doc),
      //changeFormStatus: (payload,val) => changeFormStatus(payload,val),
      //updateDocuments:(payload) => updateDocuments(payload)

})(CertificationFormSection)

export default CertificationFormSection



