import React from 'react';
import DatePicker from 'react-datepicker';
import { AsteriskRed } from '../../../../components/tools/Others';

export default class ValidationInstallationFields  extends React.Component {

    showMeterReadingDate = () => {
        //hide this field for all forms: 118548
        return false;
        /*
        const form = this.props.validationForm;
        if(form.val('meterReadingDate')) return true;
        if(form.val('newMeterEquipment') == '1' || form.val('newMeterEquipment') == '3') {
            return true;
        }
        return false;
        */
    }

    showMeterReading = () => {
        //hide this field for all forms: 118548
        return false;
        /*
        const form = this.props.validationForm;
        if(form.val('meterReading')) return true;
        if(form.val('newMeterEquipment') == '1' || form.val('newMeterEquipment') == '3') {
            return true;
        }
        return false;
        */
    }

    constructor(props) {
        super(props);
        this.state = {};
    }
    
    render = () => {
        const form = this.props.validationForm;
        return <>
            {form.renderTextBoxRow('installerDQPName',<>
                Installer DQP Name
            </>,{labelWidth:4,fieldWidth:3,rowClasses:'mb-3'})}

            {form.renderTextBoxRow('installerDQPCMI',<>
                Installer DQP CMI Number
            </>,{labelWidth:4,fieldWidth:3,rowClasses:'mb-3'})}

            {this.showMeterReadingDate() && <div className={`row mb-3`}>
                <div className={`col-md-4 py-2`}>Meter Installation Date <AsteriskRed/></div>
                <div className={`col-md-3`}>
                    <div className={`react-datepicker-block ${form.err('meterReadingDate') ? "react-date-invalid" : ""}`}>
                        <DatePicker
                            className={`form-control ${form.err('meterReadingDate') ? "is-invalid" : ""}`}
                            onChange={value => {
                                form.vals({meterReadingDate:value})
                            }}
                            selected={form.val('meterReadingDate')?new Date(form.val('meterReadingDate')):null}
                            maxDate={new Date()}

                            readOnly={form.isFieldDisabled('meterReadingDate')}
                            showTimeSelect
                            timeIntervals={15}
                            filterTime={(time) => {
                                const currentDate = new Date();
                                const selectedDate = new Date(time);
                                return currentDate.getTime() >= selectedDate.getTime();
                            }}
                            dateFormat={"dd/MM/yyyy h:mm aa"}
                            timeCaption="Time"
                            open={!form.isFieldDisabled('meterReadingDate') && this.state.installationDatePickerOpen}
                            onInputClick={() => { this.setState(Object.assign(this.state, { installationDatePickerOpen: true })); }}
                            onClickOutside={() => { this.setState(Object.assign(this.state, { installationDatePickerOpen: false })); }}
                        />
                        <div class="invalid-feedback">
                            {form.err('meterReadingDate')}
                        </div>
                    </div>
                </div>
            </div>}
            {this.showMeterReading() && form.renderTextBoxRow('meterReading',<>
                Meter Reading At The Time Of Installation <AsteriskRed/>
                <br/><span className='fs-80'>(as displayed on meter)</span>
            </>,{labelWidth:4,fieldWidth:3})}

            {form.vals().newMeterEquipment == '3' && 
                form.renderTextBoxRow('replacedMeterFinalReading',<>
                    Final Meter Reading of the Replacement Meter <AsteriskRed/>
                    <br/><span className='fs-80'>(as displayed on meter)</span>
                </>,{labelWidth:4,fieldWidth:3})
            }

            <div className={`row mb-3`}>
                <div className={`col-md-4 py-2`}>
                    Site Commissioning Date <AsteriskRed/>
                    <br/><span className='fs-80'>(date when LID is connected to meter)</span>
                </div>
                <div className={`col-md-3`}>
                    <div className={`react-datepicker-block ${form.err('lidInstallationDate') ? "react-date-invalid" : ""}`}>
                        <DatePicker
                            className={`form-control ${form.err('lidInstallationDate') ? "is-invalid" : ""}`}
                            onChange={value => {
                                form.vals({lidInstallationDate:value})
                            }}
                            selected={form.val('lidInstallationDate')?new Date(form.val('lidInstallationDate')):null}
                            maxDate={new Date()}

                            readOnly={form.isFieldDisabled('lidInstallationDate')}
                            showTimeSelect
                            timeIntervals={15}
                            filterTime={(time) => {
                                const currentDate = new Date();
                                const selectedDate = new Date(time);
                                return currentDate.getTime() >= selectedDate.getTime();
                            }}
                            dateFormat={"dd/MM/yyyy h:mm aa"}
                            timeCaption="Time"
                            open={!form.isFieldDisabled('lidInstallationDate') && this.state.lidInstallationDatePickerOpen}
                            onInputClick={() => { this.setState(Object.assign(this.state, { lidInstallationDatePickerOpen: true })); }}
                            onClickOutside={() => { this.setState(Object.assign(this.state, { lidInstallationDatePickerOpen: false })); }}
                        />
                        <div class="invalid-feedback">
                            {form.err('lidInstallationDate')}
                        </div>
                    </div>
                </div>
            </div>
            {form.renderTextBoxRow('meterReadingAtLidInstallation',<>
                Meter Reading On Commissioning Date <AsteriskRed/>
                <br/><span className='fs-80'>(as displayed on meter)</span>
            </>,{labelWidth:4,fieldWidth:3})}






            {form.renderYesNoOptionsRow('unitInDasCorrect',<>Confirm that flow units in DAS are correct? <AsteriskRed/></>,{labelWidth:4,fieldWidth:3,rowClasses:'mb-3'})}

            {form.val('unitInDasCorrect')===true && <>
                {form.renderTextBoxRow('dasCurrentReading',<>
                    Meter Reading in DAS at 7pm on Commissioning Date <AsteriskRed/>
                </>,{labelWidth:4,fieldWidth:3, rowClasses:'mb-3'})}
            </>}               
            


            {form.vals().newMeterEquipment == '5' &&  <div className={`row`}>
                <div className={`col-md-4 py-2`}>
                    Maintenance Notification Date
                    <br/><span className='fs-80'>(date as seen in DAS event list which triggered maintenance)</span>
                    <AsteriskRed/>
                </div>
                <div className={`col-md-3`}>
                    <div className={`react-datepicker-block ${form.err('maintenanceNotificateDate') ? "react-date-invalid" : ""}`}>
                        <DatePicker
                            className={`form-control ${form.err('maintenanceNotificateDate') ? "is-invalid" : ""}`}
                            onChange={value => {
                                form.vals({maintenanceNotificateDate:value})
                            }}
                            selected={form.val('maintenanceNotificateDate')?new Date(form.val('maintenanceNotificateDate')):null}
                            maxDate={new Date()}

                            readOnly={form.isFieldDisabled('maintenanceNotificateDate')}
                            showTimeSelect
                            timeIntervals={15}
                            filterTime={(time) => {
                                const currentDate = new Date();
                                const selectedDate = new Date(time);
                                return currentDate.getTime() >= selectedDate.getTime();
                            }}
                            dateFormat={"dd/MM/yyyy h:mm aa"}
                            timeCaption="Time"
                            open={!form.isFieldDisabled('maintenanceNotificateDate') && this.state.maintenanceNotificateDatePickerOpen}
                            onInputClick={() => { this.setState(Object.assign(this.state, { maintenanceNotificateDatePickerOpen: true })); }}
                            onClickOutside={() => { this.setState(Object.assign(this.state, { maintenanceNotificateDatePickerOpen: false })); }}
                        />
                        <div class="invalid-feedback">
                            {form.err('maintenanceNotificateDate')}
                        </div>
                    </div>
                </div>
            </div>}
        </>
    }
}