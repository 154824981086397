
import axios from 'axios';
import Authentication from './Authentication';
import uiHelpers from './uiHelpers';


const authentication = new Authentication();


export async function post(path, data, noAuth) {

    return await axios.post(path,data,{
        baseURL: process.env.REACT_APP_WEBAPI_URL,
        headers: noAuth ?
            { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json' }
            :
            { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + await authentication.getTokenAsync() }
    }).then((result)=>{
        if(result.data.businessError){
            throw result.data;
        }
        return result.data;
    })
    .catch((error)=>{
        if(error.businessError){
            uiHelpers.showErrorTop(error.errorMessage,true,null);
        } else if(error.response && error.response.status >= 500){
            uiHelpers.showErrorTop('An error has occurred. Please contact WaterNSW via dqp.enquiries@waternsw.com.au to resolve it.',true,null);
        } else if(error.response && error.response.status == 401){
            uiHelpers.showErrorTop('An error has occurred. Please refresh the page. If further errors occur then please contact WaterNSW via dqp.enquiries@waternsw.com.au to resolve it.',true,null);
        } else {
            uiHelpers.showErrorTop(error,true,null);
        }
        
        throw error;
    })
}

export async function get(path, noAuth) {
    return await axios.get(path,{
        baseURL: process.env.REACT_APP_WEBAPI_URL,
        headers: noAuth ?
            { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json' }
            :
            { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + await authentication.getTokenAsync() }
    }).then((result)=>{
        if(result.data.businessError){
            throw result.data;
        }
        return result.data;
    }).catch((error)=>{
        if(error.businessError){
            uiHelpers.showErrorTop(error.errorMessage,true,null);
        } else if(error.response && error.response.status >= 500){
            uiHelpers.showErrorTop('An error has occurred. Please contact WaterNSW via dqp.enquiries@waternsw.com.au to resolve it.',true,null);
        } else if(error.response && error.response.status == 401){
            uiHelpers.showErrorTop('An error has occurred. Please refresh the page. If further errors occur then please contact WaterNSW via dqp.enquiries@waternsw.com.au to resolve it.',true,null);
        } else {
            uiHelpers.showErrorTop(error,true,null);
        }
        
        throw error;
    })
}