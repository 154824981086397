import React from 'react';
import  { Redirect } from 'react-router-dom'
import * as apiPathes from '../apiPathes';
import * as serviceCaller from '../serviceCaller'
import DatePicker from 'react-datepicker';
import uiHelpers from '../../helpers/uiHelpers'
import {pageInitialising} from '../constants'
import {convertErrorCode} from '../conveters'

class NewPeriod  extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            initialising: true,
            disableFunctions: true,
            approvals: null,
            selectedApproval: null,
            startDate: null,
            endDate: null,
            startDateError: null,
            endDateError: null,
            globalError: null,
            
            nominatedMeasurePeriodId: null,
            redirect: false
        };
    }

    async componentDidMount(){
        this.refresh();
    }

    refresh = async ()=>{

        serviceCaller.get(apiPathes.getApprovals,{
            noIWasLoginHandler: this.props.noLoginCallback,
            loadingMsg: 'Getting your approvals...',
            beforeSend: ()=>{
                this.setState({disableFunctions: true})
            },
            success: (data)=>{
                this.setState({approvals: data, initialising: false, disableFunctions: false});
            }
        });
    }

    createPeriod = () => {
        if(!this.state.selectedApproval) return;
        let startDateError = null;
        let endDateError = null;
        const today = new Date();

        if(!this.state.startDate) startDateError = "This is mandatory";
        else if(this.state.startDate > today) startDateError="This cannot be future date";

        if(this.state.endDate && this.state.endDate > today) endDateError = "This cannot be future date";
        else if(this.state.endDate && this.state.startDate >= this.state.endDate) endDateError = "End date needs to be after start date";
        
        this.state.startDateError = startDateError;
        this.state.endDateError = endDateError;

        if(startDateError || endDateError) {
            this.state.globalError = "Please fix the above errors before continue";
            this.setState(this.state)
            return;
        }

        this.requestCreatePeriod();

    }

    requestCreatePeriod = ()=> {

        serviceCaller.post(apiPathes.createNominatedMeasurementPeriod,
            {approvalNumber: this.state.selectedApproval.approvalNumber, periodStartDate: this.state.startDate, periodEndDate: this.state.endDate},
            {
                noIWasLoginHandler: this.props.noLoginCallback,
                loadingMsg: 'Saving record...',
                beforeSend: ()=>{
                    this.setState({globalError: null, disableFunctions:true});
                },
                errorCodeHandler: (errorCode) =>{
                    this.setState({disableFunctions: false, globalError: convertErrorCode(errorCode)});
                },
                success: (data)=>{
                    this.setState({nominatedMeasurePeriodId: data.id, redirect: true})
                }
            }
        );
    }

    onApprovalChange = (approval) => {
        this.setState({selectedApproval: approval,startDateError:null,endDateError:null,globalError:null});
    }

    render() {
        if(this.state.initialising) return <>
            <h3 className='mb-3'>New nominated measurement period</h3>
            {pageInitialising}
        </>
        return <>
            {this.state.redirect && <Redirect to={"/details/" + this.state.nominatedMeasurePeriodId} />}
            <h3 className='mb-3'>New nominated measurement period</h3>
            <p>Please select the approval for the new nominated measurement period</p>
            {this.state.approvals.length == 0 && <>
                <div className='col-6 alert alert-warning'>
                    We cannot find any approval from you account can create nominated measurement period. 
                </div>
            </>}
            {this.state.approvals.length > 0 && <>
                <div class="list-group fs-90 col-md-6 mb-3">
                    {this.state.approvals.map(item=> {
                        var disabled = false;
                        if(item.poitms.length == 0 && item.storages.length==0) disabled = true;


                        return <label for={item.approvalNumber} className="list-group-item list-group-item-action">
                            <div className='float-start pt-1'>
                                <input
                                    type="radio"
                                    value={item.approvalNumber}
                                    id={item.approvalNumber}
                                    checked={this.state.selectedApproval && this.state.selectedApproval.approvalNumber == item.approvalNumber}
                                    onChange={(e)=>this.onApprovalChange(item)}
                                    disabled={this.state.disableFunctions || disabled}
                                />
                            </div>
                            <div className={`${item.poitms.length == 0 && item.storages.length==0 ? 'ms-4 text-muted': 'ms-4'}`}>
                                <p class="mb-1">
                                    <strong>{item.approvalNumber}</strong>
                                    {item.roi!=null && item.roi!="" && <small> (ROI: {item.roi})</small>}
                                </p>
                                {item.poitms.length == 0 && item.storages.length==0 && <em>No storages or meters</em>}
                                {item.poitms.length > 0 && <span class="badge bg-primary me-2">{item.poitms.length} point of intake meter{item.poitms.length >1 && 's'}</span>}
                                {item.storages.length > 0 && <span class="badge bg-primary">{item.storages.length} storage{item.storages.length >1 && 's'}</span>}
                            </div>
                        </label>
                    })}
                </div>

                {this.state.selectedApproval &&  <>
                    {this.state.selectedApproval.storages.length > 0 && <div className='row'>
                        <div className='col-md-6 mb-3'>
                            <p>Storage(s) of <span>{this.state.selectedApproval.approvalNumber}</span></p>
                            <ul className='list-group '>
                                {this.state.selectedApproval.storages.map((item, index)=><li className={`list-group-item`}>
                                    {item.ratingTable.ratingTableName}
                                    {(item.dasMeterExists || item.secondaryMeterExists) && <div>
                                        {item.dasMeterExists ? <span class="badge bg-success me-2">Telemetry device avaiable</span>: <span class="badge bg-warning me-2">No telemetry device</span>}
                                        {item.secondaryMeterExists ? <span class="badge bg-success">Secondary device avaiable</span> :  <span class="badge bg-warning">No secondary device</span>}
                                    </div>}
                                    {!item.dasMeterExists && !item.secondaryMeterExists && <>
                                        <div className='me-1'>
                                            <span class="badge bg-danger me-2">No telemetry device</span>
                                            <span class="badge bg-danger">No secondary device</span>
                                        </div>
                                        <div className='text-danger'>
                                            This storage does not have telemetry device and secondary device. You cannot create nominated measurement period 
                                            record unless it is resolved.
                                        </div>
                                    </>}
                                </li>)}
                            </ul>
                        </div>
                    </div>}

                    {this.state.selectedApproval.poitms.length > 0 && <div className='row'>
                        <div className='col-md-6 mb-3'>
                            <p>Point of intake meter(s) of <span>{this.state.selectedApproval.approvalNumber}</span></p>
                            <ul className='list-group'>
                                {this.state.selectedApproval.poitms.map(item=><li className='list-group-item'>
                                    {item.poitmTable.MeterSiteId}
                                </li>)}
                            </ul>
                        </div>
                    </div>}

                    <div className='row mb-3'>
                        <div className='col-md-3 mb-2'>
                            <div className={`react-datepicker-block ${this.state.startDateError ? "react-date-invalid" : ""}`}>
                                <label class="form-label d-block">Period starts at <span className='text-danger'>*</span>:</label>
                                <DatePicker
                                    className={`form-control ${this.state.startDateError? "is-invalid" : ""}`}
                                    maxDate={new Date()}
                                    onChange={(date)=>{this.setState({startDate: date})}}
                                    selected={this.state.startDate}
                                    showTimeSelect
                                    timeIntervals={15}
                                    dateFormat={"dd/MM/yyyy h:mm aa"}
                                    timeCaption="Time"
                                    disabled={this.state.disableFunctions}
                                />
                                <div class="invalid-feedback">{this.state.startDateError}</div>
                            </div>
                        </div>
                        <div className='col-md-3 mb-2'>
                            <div className={`react-datepicker-block ${this.state.endDateError? "react-date-invalid" : ""}`}>
                                <label class="form-label d-block">Period ends at:</label>
                                <DatePicker
                                    className={`form-control ${this.state.endDateError? "is-invalid" : ""}`}
                                    maxDate={new Date()}
                                    onChange={(date)=>{this.setState({endDate: date})}}
                                    selected={this.state.endDate}
                                    showTimeSelect
                                    timeIntervals={15}
                                    dateFormat={"dd/MM/yyyy h:mm aa"}
                                    timeCaption="Time"
                                    disabled={this.state.disableFunctions}
                                />
                                <div class="invalid-feedback">{this.state.endDateError}</div>
                            </div>
                        </div>
                    </div>
                </>}
                {this.state.globalError && <div className='col-md-6 alert alert-danger'>    
                    {this.state.globalError}
                </div>}
                <div className='col-md-6'>
                    <a disabled href="/measurement-period/#/dashboard" className={`btn btn-warning ${this.state.disableFunctions? 'disabled' : ''}`}>
                        Back
                    </a>
                    {this.state.selectedApproval && <>
                        <button class="btn btn-primary float-end" disabled={this.state.disableFunctions || this.state.selectedApproval.storages.filter(s=>(!s.dasMeterExists && !s.secondaryMeterExists)).length!=0} onClick={this.createPeriod}>
                            Create
                        </button>
                    </>}
                </div>
            </>}
        </>
    }
}

export default NewPeriod;