import React, { Fragment } from 'react';
import Select from 'react-select';
import BasePageLayout, {menuItems} from './base/BasePageLayout';
import ApprovalLTSReportComponent from '../components/trackingStatus/ApprovalLTSReportComponent'
import StorageLTSReportComponent from '../components/trackingStatus/StorageLTSReportComponent'


class TrackingStatusFPH extends React.Component {

    reportTypes = [
        {
            label : 'Storage measurement',
            value : 'STORAGE_MESUREMENT'
        },
        {
            label : 'Point of intake',
            value : 'POINT_OF_INTAKE'
        }
    ];
    constructor(props) {
        super(props);
        this.state = {
            reportType : null
        };
    }

    /*componentDidMount() {}*/


    onChangeOfReportType = (e)=>{
        this.setState(Object.assign(this.state, { reportType : e.value }));
    }

    

    render() {
        return <>
            <h3 className='mb-3'>Landholder tracking status report for FPH </h3>

            <h5 className='mb-3'>What is the measurement method for the storage?<span className='text-danger'>*</span></h5>
            <div className='row'>
                <div className="col-sm-6">
                    <Fragment>
                        <Select id="reportType" onChange={this.onChangeOfReportType} styles={this.customStyles} options={this.reportTypes} 
                            value={this.reportTypes.filter(option => option.value === this.state.reportType)}
                        />
                    </Fragment>
                </div>
            </div>

            <hr />
            
            {this.state.reportType == 'POINT_OF_INTAKE' && <ApprovalLTSReportComponent statusType="FPH" />}
            {this.state.reportType == 'STORAGE_MESUREMENT' && <StorageLTSReportComponent />}
        </>
    }

}

export default TrackingStatusFPH;
