import { SubmissionError } from 'redux-form'
import { METER_SAVE_CLICK_DRAFT,
    METER_RESET_CLICK_DRAFT,
    METER_SAVE_CLICK_NEXT,
    METER_RESET_CLICK_NEXT, METER_FORM_STATUS} from '../../../constants/MeterFormConstants'

export const saveDraftAction =(props) => {
    if(!props.dirty)
    {
        alert("There is nothing to save");
        return;
    }    
    submitForm({props: props,clickSaveDraft: METER_SAVE_CLICK_DRAFT, clickNext: METER_RESET_CLICK_NEXT, formStatus: METER_FORM_STATUS.DRAFT});
}

export const clickNextAction =(props) => {
    debugger;
    submitForm({props: props,clickSaveDraft: METER_RESET_CLICK_DRAFT, clickNext: METER_SAVE_CLICK_NEXT, formStatus: METER_FORM_STATUS.DRAFT});
}

export const saveSubmitAction =(props) => {
    submitForm({props: props,clickSaveDraft: METER_RESET_CLICK_DRAFT, clickNext: METER_SAVE_CLICK_NEXT, formStatus: METER_FORM_STATUS.FINAL });
}
export const submitForm = function (params) {
    const callHandleSubmit = params.props.handleSubmit(values => {
    return new Promise((resolve, reject) => {
debugger;
       const errors = params.props.validate(values, params);      
       const objError = flattenObject(errors);
       //check any error exists
       const errorEl = 
          Object.keys(objError).filter(key => objError[key] != "").reduce((a, c) => {a[c] = objError[c]; return a}, {})
       //in case of errors throw errors
       if (Object.keys(errorEl).length > 0) {
       reject(new SubmissionError(errors));
       } else {
           if(!values.updateRatingTableData){
            values.updateRatingTableData = {}; //this is done just so we reuse PartA, PartB in update Rating table cert
           }
           if(!values.secondaryEquipmentValidationData){
            values.secondaryEquipmentValidationData = {}; //this is done just so we reuse  PartB in secondaryEquipmentValidation cert
           }
           if(!values.storageManagementValidationData){
            values.storageManagementValidationData = {}; //this is done just so we reuse PartA, PartB in SMV cert
           }
           
       resolve(params.props.onSubmit({
           ...values,
           clickSaveDraft: params.clickSaveDraft,
           clickNext: params.clickNext,
           ahdData: { ...values.ahdData, formStatus: params.formStatus },
           updateRatingTableData: { ...values.updateRatingTableData, formStatus: params.formStatus },
           secondaryEquipmentValidationData:  { ...values.secondaryEquipmentValidationData, formStatus: params.formStatus },
           storageManagementValidationData:  { ...values.storageManagementValidationData, formStatus: params.formStatus }
        }));
       }
    })
    });
    callHandleSubmit();
 }

const flattenObject = (obj, prefix = '') =>
    Object.keys(obj).reduce((acc, k) => {
    const pre = prefix.length ? prefix + '.' : '';
    if (typeof obj[k] === 'object') Object.assign(acc, flattenObject(obj[k], pre + k));
    else acc[pre + k] = obj[k];
    return acc;
}, {});

export const scrollTofocusError = (errors) =>
{
    if(errors == undefined || errors == null || errors == {} ) return; 
    console.log("dddderrr"+JSON.stringify(flattenObject(errors)))
    let objError = flattenObject(errors);
    objError = Object.keys(objError).filter(key => objError[key] != "").reduce((a, c) => {a[c] = objError[c]; return a}, {})
    console.log("dwwwwwwwddderrr"+JSON.stringify(objError));
    if(Object.keys(objError).length === 0 ) return;
    const errorEl = document.querySelector(
        Object.keys(objError).filter(function(key){
            return objError[key] != "";
        }).map(fieldName => {if(fieldName != "" ) return `[name="${fieldName}"]`}).join(',') || ''
    );
    if (errorEl) {
        errorEl.scrollIntoView({ block: "center" });
    } 
}