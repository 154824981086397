import React from 'react';
import { Field, reduxForm, formValueSelector, change } from 'redux-form';
import { connect } from 'react-redux'

import 'react-redux';
import 'redux';
import "react-datepicker/dist/react-datepicker.css";
import { saveDraftAction, clickNextAction } from "./Validations"
import { RadiosForRedux, InputTextForRedux , DatePickerForRedux}  from '../../tools/BootstrapControls'

let 
CertificatePartD = props => {

   const {
      handleSubmit, masterDataValues, secondaryEquipmentValidationData, previousPage, loading, hasError
   } = props

   const disableFields = (parseInt(secondaryEquipmentValidationData.formStatus) == 2) || props.disableEditing  || loading? true : false;

   const designOwnOptions = 
         [
            { id: 1, name:"YES",selected:false},
            { id: 2, name:"NO", selected:false},
         ]; 

   const dateToday = new Date();
   if (secondaryEquipmentValidationData.validationDate != null && secondaryEquipmentValidationData.validationDate != "")
   secondaryEquipmentValidationData.validationDate = new Date(secondaryEquipmentValidationData.validationDate);

   if(secondaryEquipmentValidationData.validationDate == null || secondaryEquipmentValidationData.validationDate == ""){
      secondaryEquipmentValidationData.validationDate = dateToday;
   }

   if (secondaryEquipmentValidationData.certificateDate != null && secondaryEquipmentValidationData.certificateDate != "")
   secondaryEquipmentValidationData.certificateDate = new Date(secondaryEquipmentValidationData.certificateDate);

   if(secondaryEquipmentValidationData.certificateDate == null || secondaryEquipmentValidationData.certificateDate == ""){
      secondaryEquipmentValidationData.certificateDate = dateToday;
   }

   var thisDQP = masterDataValues.userProfile[0];
   console.log("dqp"+JSON.stringify(thisDQP));
   thisDQP = {...thisDQP, "fullName": thisDQP.user.firstName + ' ' + thisDQP.user.lastName};
   props.dispatch(change(props.form, 'dqpdata', thisDQP));

   return (
      <form className="vertical" onSubmit={handleSubmit}>            
         <h5 className='mb-3'>Certification</h5>

         <div className='card mb-3'>
            <div className='card-header fw-bold'>I certify that:</div>
            <div className='card-body'>
               <p className='fw-bold'>
               a) I am a duly qualified person under the Floodplain Harvesting Measurement Guidelines for the purpose of validating the storage metering equipment described in this certificate.
               </p>
               <p className='fw-bold'>
               b) The storage metering equipment complies with the Floodplain Harvesting Measurement Guidelines.
               </p>
               <p className='fw-bold'>
               c) All information contained in this certificate is accurate, true and complete.
               </p>
               <p className='fw-bold'>
               d) I am aware that I must notify the Minister within seven (7) days if I know or reasonably suspect that storage level measurement equipment I am installing or carrying out work on has been tampered with.
               </p>
               <p className='fw-bold mb-1'>
               e) I am validating my own storage metering equipment&nbsp;<span style={{color:'red'}}>*</span>
               </p>
               <Field 
                  name="secondaryEquipmentValidationData.confirmCert"
                  component={RadiosForRedux} 
                  options={designOwnOptions} 
                  values={secondaryEquipmentValidationData.confirmCert}
                  props={{ disabled: disableFields }}
               />

               <hr className='my-4'/>

               <div className='row mb-3'>
                  <div className='col-sm-6'>
                     <Field
                        name="dqpdata.fullName"
                        component={InputTextForRedux}                       
                        label="Name"
                        props={{ disabled: true}}
                     /> 
                  </div>
                  <div className='col-sm-6'>
                     <Field
                        name="dqpdata.cmino"
                        label="Registered Surveyor/Engineer licence number"
                        component={InputTextForRedux}
                        props={{ disabled: true }}
                        /> 
                  </div>
               </div> 

               <div className='row mb-3'>
                  <div className='col-sm-6'>
                     <Field
                        name="dqpdata.businessName"
                        component={InputTextForRedux}                       
                        label="Business Name"
                        props={{ disabled: true }}
                     /> 
                  </div>
                  <div className='col-sm-6'>
                     <Field
                        name="dqpdata.abnOrAcn"
                        label="ABN or ACN"
                        component={InputTextForRedux}
                        props={{ disabled: true }}
                        />
                  </div>
               </div> 

               <div className='row mb-3'>
                  <div className='col-sm-6'>
                     <Field
                        name="dqpdata.postalAddress.addressLine"
                        label="Postal address"
                        component={InputTextForRedux}
                        props={{ disabled: true}}
                        />
                  </div>
                  <div className='col-sm-6'>
                     <Field
                        name="dqpdata.phoneNumber"
                        label="Phone"
                        component={InputTextForRedux}
                        props={{ disabled: true}}
                        />
                  </div>
               </div> 

               <div className='row mb-3'>
                  <div className='col-sm-6'>
                     <Field
                        name="dqpdata.user.emailAddress"
                        label="Email"
                        component={InputTextForRedux}
                        props={{ disabled: true }}
                     />
                  </div>
                  <div className='col-sm-3'>
                     <Field
                        name="secondaryEquipmentValidationData.validationDate"
                        label="Date of validation"
                        handleChange={date => { props.change(props.form, 'secondaryEquipmentValidationData.validationDate', date) }}
                        component={DatePickerForRedux}
                        props={{  disabled: disableFields, maxDate: new Date() }}
                        values={secondaryEquipmentValidationData.validationDate}
                     />
                  </div>
               </div>

               <div className='row mb-3'>
                  <div className='col-sm-3'>
                     <Field
                        name="secondaryEquipmentValidationData.certificateDate"
                        label="Date"
                        component={DatePickerForRedux}
                        props={{ disabled: true}}
                        values={secondaryEquipmentValidationData.certificateDate}
                     />  
                  </div>
               </div>

               <p>
                  <i>
                  It is an offence under section 91J of the Water Management Act 2000 for a person to make a statement or furnish information in connection with a level measurement record the person knows to be false or misleading.
                  </i>
               </p>
            </div>
         </div>

         {hasError && <div className='alert alert-danger'>
            Please fix all errors before you continue
         </div>}

         <div className="text-end">
            {(parseInt(secondaryEquipmentValidationData.formStatus) == 1) && (!props.disableEditing) && <>
               {secondaryEquipmentValidationData.id!=0 &&  <button type="button"  className="btn btn-danger me-3" disabled={loading} 
               data-toggle="modal" data-target="#deleteDraftModal">
                  Delete Draft
               </button>}
               <button type="button"  className="btn btn-primary" disabled={loading}
                  onClick={() => { saveDraftAction(props); }}>
                  Save Draft
               </button>
               <div className='vr mx-3'></div>
            </>}

            <button type="button"  className="btn btn-warning me-3" disabled={loading}  onClick={()=>{previousPage(secondaryEquipmentValidationData.formStatus)}}>
               Previous
            </button>
            <button type="button" className="btn btn-success" disabled={loading} 
                  onClick={() => { clickNextAction(props); }}>
                  Next
            </button>
         </div>
      </form>
   )
}


CertificatePartD = reduxForm({
   destroyOnUnmount: false,
   forceUnregisterOnUnmount: true,
   validate : ()=>{return {}},
   onSubmitFail: (errors, dispatch ) => {
      dispatch(change('certificate', 'hasError', true));
   },
   onSubmitSuccess:(result , dispatch )=>{
      dispatch(change('certificate', 'hasError', false));
      window.scrollTo(0, 0);
   }
})(
CertificatePartD)


CertificatePartD = connect(
   (state, props) => {
      const selectorCert = formValueSelector('certificate')
      const hasError = selectorCert(state, 'hasError')

      // can select values individually
      const selector = formValueSelector(props.form)
      const masterDataValues = selector(state, 'mdata')
      const secondaryEquipmentValidationData = selector(state, 'secondaryEquipmentValidationData')
      const documents = selector(state, 'documents')
      const loading = selector(state, 'loading')
      const form = props.form
      return {
         masterDataValues,
         secondaryEquipmentValidationData,
         loading,
         documents,
         form,
         hasError
      }
   }
)(
CertificatePartD)

export default 
CertificatePartD