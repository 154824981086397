import { valid_email,
    MESSAGE_MAX_ALLOWED_CHARCATERS_100,  
    MESSAGE_INVALID_EMAIL,
    MESSAGE_MAX_ALLOWED_CHARCATERS_20,
    MESSAGE_REQUIRED,
    MESSAGE_MAX_ALLOWED_CHARCATERS,
    decimal3UpTo6Digits,
    decimal3UpTo6DigitsMessage,
    decimal3UpTo8Digits,
    decimal3UpTo8DigitsMessage, 
    integerUpto10Digits,
    integerUpto10DigitsMessage   ,
    beforeReadingBiggerThanAfterMessage ,
    beforeReadingDateAfterAfterReadingDateMessage,
    decimal9UpTo14Digits,
    decimal9UpTo14DigitsMessage,
    decimal3UpTo14DigitsMessage,
    longitudeMessage,
    latitudeMessage,
    checkLongitude,
    decimal3UpTo14Digits,
    checkLatitude
 } from '../../../constants/MeterFormConstants';

export const validateCertificatePartA = (values, params) => {   

    const errors = {
        authorityHolderDetails: {
            contactPersonPhone:'',
            contactPersonEmailAddress: '',
            contactPersonName: ''           
          }
    }

    let clickSaveDraft = params.clickSaveDraft
    let clickNext = params.clickNext;
    
    if (clickSaveDraft === 1|| clickNext === 1) {
        if (values.authorityHolderDetails.contactPersonPhone && values.authorityHolderDetails.contactPersonPhone> 20) {       
            errors.authorityHolderDetails.contactPersonPhone = MESSAGE_MAX_ALLOWED_CHARCATERS_20;           
        }

        if (values.authorityHolderDetails.contactPersonName && values.authorityHolderDetails.contactPersonName.length> 100 ) {       
            errors.authorityHolderDetails.contactPersonName = MESSAGE_MAX_ALLOWED_CHARCATERS_100;            
        }

        if(values.authorityHolderDetails.contactPersonEmailAddress){
            if(values.authorityHolderDetails.contactPersonEmailAddress.length > 100 ) {       
                errors.authorityHolderDetails.contactPersonEmailAddress = MESSAGE_MAX_ALLOWED_CHARCATERS_100;            
            } else if(!valid_email.test(values.authorityHolderDetails.contactPersonEmailAddress) ) {       
                errors.authorityHolderDetails.contactPersonEmailAddress = MESSAGE_INVALID_EMAIL;            
            } 
        }        
     }
    return errors;       
}

export const validateCertificatePartC = (values, params) => {

    const errors = {

        storageManagementValidationData: {
            certFor:"",
            certExistingFor:"",
            lastValidationDate:"",
            references:"",
            reinstallationDate:"",
            readingBeforeMaintenance     :"",
            readingBeforeMaintenanceDate : "",
            readingAfterMaintenance:"",
            readingAfterMaintenanceDate : "",
            storageMeterType:"",
            mountingMethod:"",
            meteringSystemType:"",
            meterSerialNumber:"",
            meterProtocol:"",
            refNum:"",
            lidType:"",
            lidModel:"",
            lidSerialNumber:"",
            lidVersion:"",
            easting:"",
            northing:"",
            mga:"",
            levelSensor:"",
            waterLevel:"",
            sealNo:"",
            //powerSource:"",
            //solarVoltage:"",
            //network:"",
            comments:"",
            storageManagementRegulation: {
                
                compliance1: '',
                compliance2: '',
                compliance3: '',
                compliance4: '',
                complianceAuthority: ''
              }                       
          }
    }    

    console.log("in storageManagementValidationData validation");
    debugger;
    let clickSaveDraft = params.clickSaveDraft
    let clickNext = params.clickNext;
    if (clickSaveDraft == undefined) clickSaveDraft = values.clickSaveDraft;
    if (clickNext == undefined) clickNext = values.clickNext;
    console.log(`draft: + ${clickSaveDraft} clickNext ${clickNext}`);       
    const topOption = values.storageManagementValidationData.certFor;
    if (clickSaveDraft === 1|| (clickNext === 1 && values.storageManagementValidationData.formStatus != 2)) {
        //validate lengths    

        if (values.storageManagementValidationData.meterProtocol && values.storageManagementValidationData.meterProtocol.length > 100) {
        errors.storageManagementValidationData.meterProtocol =MESSAGE_MAX_ALLOWED_CHARCATERS_100;
        }

        if (values.storageManagementValidationData.refNum && values.storageManagementValidationData.refNum.length > 100) {
            errors.storageManagementValidationData.refNum =MESSAGE_MAX_ALLOWED_CHARCATERS_100;
        }

        if (values.storageManagementValidationData.meterSerialNumber && values.storageManagementValidationData.meterSerialNumber.length > 100) {
            errors.storageManagementValidationData.meterSerialNumber =MESSAGE_MAX_ALLOWED_CHARCATERS_100;
            }

        if (values.storageManagementValidationData.lidSerialNumber && values.storageManagementValidationData.lidSerialNumber.length > 100) {
        errors.storageManagementValidationData.lidSerialNumber =MESSAGE_MAX_ALLOWED_CHARCATERS_100;
        }
        if (values.storageManagementValidationData.sealNo && values.storageManagementValidationData.sealNo.length > 100) {
        errors.storageManagementValidationData.sealNo =MESSAGE_MAX_ALLOWED_CHARCATERS_100;
        }
        if (values.storageManagementValidationData.lidVersion && values.storageManagementValidationData.lidVersion.length > 100) {
        errors.storageManagementValidationData.lidVersion =MESSAGE_MAX_ALLOWED_CHARCATERS_100;
        }
        /* This is commented out by 80837
        if (values.storageManagementValidationData.powerSource && values.storageManagementValidationData.powerSource == 1 && values.storageManagementValidationData.solarVoltage && values.storageManagementValidationData.solarVoltage.length > 50) {
        errors.storageManagementValidationData.solarVoltage = MESSAGE_MAX_ALLOWED_CHARCATERS(50);
        }
        */

        /*This is commented out by 80832
        if (values.storageManagementValidationData.easting && !decimal9UpTo14Digits.test(values.storageManagementValidationData.easting)) {
            errors.storageManagementValidationData.easting =decimal9UpTo14DigitsMessage;
        }
        if (values.storageManagementValidationData.northing && !decimal9UpTo14Digits.test(values.storageManagementValidationData.northing)){
        errors.storageManagementValidationData.northing =decimal9UpTo14DigitsMessage;
        }
        if (values.storageManagementValidationData.mga && !decimal3UpTo6Digits.test(values.storageManagementValidationData.mga)){
            errors.storageManagementValidationData.mga =decimal3UpTo6DigitsMessage;
        }
        */


        if (values.storageManagementValidationData.longitude && !decimal3UpTo14Digits.test(values.storageManagementValidationData.longitude)) {
            errors.storageManagementValidationData.longitude = decimal3UpTo14DigitsMessage;
        } else if (values.storageManagementValidationData.longitude && !checkLongitude(values.storageManagementValidationData.longitude))
            errors.storageManagementValidationData.longitude = longitudeMessage

        if (values.storageManagementValidationData.latitude && !decimal3UpTo14Digits.test(values.storageManagementValidationData.latitude)) {
            errors.storageManagementValidationData.latitude = decimal3UpTo14DigitsMessage;
        } else if (values.storageManagementValidationData.latitude && !checkLatitude(values.storageManagementValidationData.latitude))
            errors.storageManagementValidationData.latitude = latitudeMessage



        if (values.storageManagementValidationData.levelSensor && !decimal3UpTo8Digits.test(values.storageManagementValidationData.levelSensor)){
            errors.storageManagementValidationData.levelSensor =decimal3UpTo8DigitsMessage ;
        }

        if (values.storageManagementValidationData.waterLevel && !decimal3UpTo8Digits.test(values.storageManagementValidationData.waterLevel)){
            errors.storageManagementValidationData.waterLevel =decimal3UpTo8DigitsMessage ;
        }
        //debugger;
        if(topOption ==2) {
            if(values.storageManagementValidationData.readingBeforeMaintenance){
                if(!integerUpto10Digits.test(values.storageManagementValidationData.readingBeforeMaintenance)) errors.storageManagementValidationData.readingBeforeMaintenance = integerUpto10DigitsMessage;        
            } 

            if(values.storageManagementValidationData.readingAfterMaintenance){
                if(!integerUpto10Digits.test(values.storageManagementValidationData.readingAfterMaintenance)) errors.storageManagementValidationData.readingAfterMaintenance = integerUpto10DigitsMessage;        
            } 

            if(
                values.storageManagementValidationData.readingBeforeMaintenance
                && values.storageManagementValidationData.readingAfterMaintenance
                && errors.storageManagementValidationData.readingBeforeMaintenance == "" 
                && errors.storageManagementValidationData.readingAfterMaintenance ==""
            ){
                if(parseInt(values.storageManagementValidationData.readingBeforeMaintenance)> parseInt(values.storageManagementValidationData.readingAfterMaintenance)){
                    errors.storageManagementValidationData.readingAfterMaintenance = beforeReadingBiggerThanAfterMessage;        
                }
            }

            
            //alert(new Date(values.storageManagementValidationData.readingBeforeMaintenanceDate));
            //alert(new Date(values.storageManagementValidationData.readingAfterMaintenanceDate));
            

            if(
                values.storageManagementValidationData.readingBeforeMaintenanceDate 
                && values.storageManagementValidationData.readingAfterMaintenanceDate
                && (new Date(values.storageManagementValidationData.readingAfterMaintenanceDate)).getTime() < (new Date(values.storageManagementValidationData.readingBeforeMaintenanceDate)).getTime()
            ) {
                errors.storageManagementValidationData.readingAfterMaintenanceDate = beforeReadingDateAfterAfterReadingDateMessage;        
            }

        }
    }

    if (clickSaveDraft == 0 && clickNext === 1 && values.storageManagementValidationData.formStatus != 2) {
   if(!topOption || topOption == '') { errors.storageManagementValidationData.certFor = MESSAGE_REQUIRED; return errors;  }

   if(topOption ==2) {
    if(!values.storageManagementValidationData.certExistingFor){
        errors.storageManagementValidationData.certExistingFor = MESSAGE_REQUIRED;
    }    else if(values.storageManagementValidationData.certExistingFor ==1) {
        if(values.storageManagementValidationData.storageManagementRegulation) {

        if(!values.storageManagementValidationData.storageManagementRegulation.compliance1 
            || !values.storageManagementValidationData.storageManagementRegulation.compliance1[0]
            || !values.storageManagementValidationData.storageManagementRegulation.compliance1[0] == 1){
                errors.storageManagementValidationData.storageManagementRegulation.compliance1= MESSAGE_REQUIRED;
        }

        if(!values.storageManagementValidationData.storageManagementRegulation.compliance2 
            || !values.storageManagementValidationData.storageManagementRegulation.compliance2[0]
            || !values.storageManagementValidationData.storageManagementRegulation.compliance2[0] == 1){
                errors.storageManagementValidationData.storageManagementRegulation.compliance2= MESSAGE_REQUIRED;
        }else {
            if(!values.storageManagementValidationData.storageManagementRegulation.complianceAuthority
                || values.storageManagementValidationData.storageManagementRegulation.complianceAuthority == ''
                ){
                    errors.storageManagementValidationData.storageManagementRegulation.complianceAuthority = MESSAGE_REQUIRED;
            }
        }

        if(!values.storageManagementValidationData.storageManagementRegulation.compliance3
            || !values.storageManagementValidationData.storageManagementRegulation.compliance3[0]
            || !values.storageManagementValidationData.storageManagementRegulation.compliance3[0] == 1){
                errors.storageManagementValidationData.storageManagementRegulation.compliance3= MESSAGE_REQUIRED;
        }

        if(!values.storageManagementValidationData.storageManagementRegulation.compliance4
            || !values.storageManagementValidationData.storageManagementRegulation.compliance4[0]
            || !values.storageManagementValidationData.storageManagementRegulation.compliance4[0] == 1){
                errors.storageManagementValidationData.storageManagementRegulation.compliance4= MESSAGE_REQUIRED;
        }
    }

}   
   }

   if(!values.storageManagementValidationData.storageMeterType) {
        errors.storageManagementValidationData.storageMeterType = MESSAGE_REQUIRED;
   }



  

   if(!values.storageManagementValidationData.mountingMethod) { errors.storageManagementValidationData.mountingMethod= MESSAGE_REQUIRED;   }
   if(!values.storageManagementValidationData.meteringSystemType) { errors.storageManagementValidationData.meteringSystemType= MESSAGE_REQUIRED;   }
   if(!values.storageManagementValidationData.meterSerialNumber) { errors.storageManagementValidationData.meterSerialNumber= MESSAGE_REQUIRED;   }
   if(!values.storageManagementValidationData.meterProtocol) { errors.storageManagementValidationData.meterProtocol= MESSAGE_REQUIRED;   }
   //if(!values.storageManagementValidationData.lidType) { errors.storageManagementValidationData.lidType= MESSAGE_REQUIRED;   }
   if(!values.storageManagementValidationData.lidModel) { errors.storageManagementValidationData.lidModel= MESSAGE_REQUIRED;   }
   if(!values.storageManagementValidationData.lidSerialNumber) { errors.storageManagementValidationData.lidSerialNumber= MESSAGE_REQUIRED;   }
   if(!values.storageManagementValidationData.lidVersion) { errors.storageManagementValidationData.lidVersion= MESSAGE_REQUIRED;   }
   
   /*
   if(!values.storageManagementValidationData.easting) { errors.storageManagementValidationData.easting= MESSAGE_REQUIRED;   }
   if(!values.storageManagementValidationData.northing) { errors.storageManagementValidationData.northing= MESSAGE_REQUIRED;   }
   if(!values.storageManagementValidationData.mga) { errors.storageManagementValidationData.mga= MESSAGE_REQUIRED;   }
   */

   if (!values.storageManagementValidationData.longitude || values.storageManagementValidationData.longitude =='' ) {errors.storageManagementValidationData.longitude = MESSAGE_REQUIRED}
    if (!values.storageManagementValidationData.latitude || values.storageManagementValidationData.latitude =='' ) {errors.storageManagementValidationData.latitude = MESSAGE_REQUIRED}
   
   if(!values.storageManagementValidationData.levelSensor) { errors.storageManagementValidationData.levelSensor= MESSAGE_REQUIRED;   }
   if(!values.storageManagementValidationData.waterLevel) { errors.storageManagementValidationData.waterLevel= MESSAGE_REQUIRED;   }
   if(!values.storageManagementValidationData.sealNo || values.storageManagementValidationData.sealNo == '') { errors.storageManagementValidationData.sealNo= MESSAGE_REQUIRED;   }
   //if(!values.storageManagementValidationData.powerSource) { errors.storageManagementValidationData.powerSource= MESSAGE_REQUIRED;   }
   //if(values.storageManagementValidationData.powerSource && values.storageManagementValidationData.powerSource == 1 && !values.storageManagementValidationData.solarVoltage) { errors.storageManagementValidationData.solarVoltage= MESSAGE_REQUIRED;   }
   //if(!values.storageManagementValidationData.network) { errors.storageManagementValidationData.network= MESSAGE_REQUIRED;   }
   //if(!values.storageManagementValidationData.comments) { errors.storageManagementValidationData.comments= MESSAGE_REQUIRED;   }
    }

    console.log("errors from validateCertificatePartC"+JSON.stringify(errors));
    return errors;
}

export const validateCertificatePartD = (values, params) => {   
    const errors = { storageManagementValidationData: {
        confirmCert:"",
         
      }
    }   
    if(!values.storageManagementValidationData.confirmCert) {
        errors.storageManagementValidationData.confirmCert = MESSAGE_REQUIRED;
   } 
    return errors;       
}






