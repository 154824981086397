import React from 'react';
import { connect } from 'react-redux'
import { reduxForm, change, formValueSelector } from 'redux-form';
import { clickNextAction } from "./MeterFormValidations"
import ApprovalConsentProcess from '../../common/ApprovalConsentProcess';
import { isStringNullOrEmpty } from '../../../helpers/validators';

const validate = ()=>{
    return {
        approvedWorkDetails: {},
        authorityHolderDetails: {},
        meteringEquipmentInformation: {}
    };
}

let SearchCustomerForCertificates = props => {

    const approvalFoundHandler = async (approval,consent) => {
        let authorityHolderDetails = {
            id: 0,
            salutationId: 0,
            salutationText: '',
            authorityHolderName: "",
            corporationNameOrAcn: "",
            postalAddress: '',
            phone: '',
            alternatePhone: '',
            emailAddress: '' ,
            contactPersonName: '',
            contactPersonPhone: '',
            contactPersonEmailAddress: '',
            firstName: "",
            lastName: ""
        };

        let approvedWorkDetails = {
            id: 0,
            meterCircumstancesOptions: '',
            propertyName: '',
            lotDp: '',
            waterSupplyWorkApprovalNo: approval.approvalNumber,
            waterAccessLicenceNo: '',
            esid: '',
            waterSourceId: 0,
            waterSourceName: '',
            waterSharingPlanName: '',
            waterSourceTypeId: 0,
            waterSourceType: '',
            meterNumber: '',
            meterNumberGUID: '',
            approvedByCustomer: 1,
            lotDp : null
        }

        if(approval.holders && approval.holders.length>0){
            authorityHolderDetails.postalAddress= approval.holders[0].postalAddressLine1;

            if(!isStringNullOrEmpty(approval.holders[0].postalAddressLine2)){
                authorityHolderDetails.postalAddress= authorityHolderDetails.postalAddress + " " + approval.holders[0].postalAddressLine2;
            }

            if(!isStringNullOrEmpty(approval.holders[0].postalSuburb)){
                authorityHolderDetails.postalAddress= authorityHolderDetails.postalAddress + " " + approval.holders[0].postalSuburb;
            }

            if(!isStringNullOrEmpty(approval.holders[0].postalPostcode)){
                authorityHolderDetails.postalAddress= authorityHolderDetails.postalAddress + " " + approval.holders[0].postalPostcode;
            }

            if(!isStringNullOrEmpty(approval.holders[0].postalState)){
                authorityHolderDetails.postalAddress= authorityHolderDetails.postalAddress + " " + approval.holders[0].postalState;
            }

            if(!isStringNullOrEmpty(approval.holders[0].email)){
                authorityHolderDetails.emailAddress= approval.holders[0].email;
            }

            if(approval.holders[0].phoneNumbers && approval.holders[0].phoneNumbers.length>0){
                if(approval.holders[0].phoneNumbers[0].prefix!=null) {
                    authorityHolderDetails.phone = approval.holders[0].phoneNumbers[0].prefix + ' ' + approval.holders[0].phoneNumbers[0].phoneNumber;
                } else {
                    authorityHolderDetails.phone = approval.holders[0].phoneNumbers[0].phoneNumber;
                }
            }


            if (approval.holders[0].inpaType == "Person") {       
                authorityHolderDetails.firstName = approval.holders[0].givename;
                authorityHolderDetails.lastName = approval.holders[0].familyName;
                authorityHolderDetails.authorityHolderName = approval.holders[0].givename + " " + approval.holders[0].familyName;
             } else {
                authorityHolderDetails.corporationNameOrAcn = approval.holders[0].organisationName;
                authorityHolderDetails.authorityHolderName = approval.holders[0].organisationName;
             }
        }

        props.dispatch(change(props.form, 'authorityHolderDetails', authorityHolderDetails));
            props.dispatch(change(props.form, 'approvedWorkDetails', approvedWorkDetails));
            clickNextAction(props);
    };

    return <ApprovalConsentProcess approvalFound={approvalFoundHandler}/>;
}



SearchCustomerForCertificates = reduxForm({
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate,
    //onSubmitFail: (errors) => {},
    //onSubmitSuccess: () => { window.scrollTo(0, 0); }
 })(SearchCustomerForCertificates)
 
 SearchCustomerForCertificates = connect(
    (state, props) => {
        const selector = formValueSelector(props.form);
        const loading = selector(state, 'loading');
        return {
            loading
        }
    }
 )(SearchCustomerForCertificates)

export default SearchCustomerForCertificates;