import React from 'react';
import uiHelpers from '../../../helpers/uiHelpers';
import serviceUrls from '../../../constants/serviceUrls';
import * as apiCaller from '../../../helpers/apiCaller'
import Select from 'react-select';
import moment from 'moment';
import { Redirect  } from "react-router-dom";
import { InputText, InputTextWithFloatingLabel } from '../../../components/tools/BootstrapControls';
import { convertMeterCommissionStatus } from '../../../helpers/converters';
import DatePicker from 'react-datepicker';
import UsersDropDown from './common/UsersDropDown';
import { AsteriskRed } from '../../../components/tools/Others';
import { isPositiveWholeNumber } from '../../../helpers/validators';



const statusOptions = [
    {id:'PENDING_SITE_REGO',display:<>{convertMeterCommissionStatus('PENDING_SITE_REGO')} <i className='fs-80'>(Meter Compliance Process)</i></>},
    {id:'PENDING_REGO_APPROVAL',display:<>{convertMeterCommissionStatus('PENDING_REGO_APPROVAL')} <i className='fs-80'>(Meter Compliance Process)</i></>},
    {id:'PENDING_VALIDATION',display:<>{convertMeterCommissionStatus('PENDING_VALIDATION')} <i className='fs-80'>(Meter Compliance Process)</i></>},
    {id:'PENDING_VALIDATION_APPROVAL',display:<>{convertMeterCommissionStatus('PENDING_VALIDATION_APPROVAL')} <i className='fs-80'>(Meter Compliance Process)</i></>},
    {id:'COMPLETED',display:<>{convertMeterCommissionStatus('COMPLETED')} <i className='fs-80'>(Meter Compliance Process)</i></>},
    {id:'DRAFT',display:<>Draft <i className='fs-80'>(Meter Registration &amp; Certificate Forms)</i></>},
    {id:'SUBMITTED',display:<>Submitted <i className='fs-80'>(Meter Registration &amp; Certificate Forms)</i></>},
    {id:'INSTALLED',display:<>Installed <i className='fs-80'>(Meter Registration)</i></>},
    {id:'REMOVED',display:<>Removed <i className='fs-80'>(Meter Registration)</i></>},
    {id:'DELETED',display:<>Deleted <i className='fs-80'>(only for Meter Compliance Process)</i></>},
];

const formTypeOptions =[
    {id:'ALL',display:'All'},
    {id:'ALL_NON_URBAN',display:'All Non-urban Metering'},
    {id:'METER_COMMISSION_NUM',display:<i className='fs-90'>Meter Compliance Process</i>},
    {id:'METER_REGISTRATION_NUM',display:<i className='fs-90'>Meter Registration <span className='fs-80'>(Legacy)</span></i>},
    {id:'VALIDATION_CERT_NUM',display:<i className='fs-90'>Validation Certificate Form <span className='fs-80'>(Legacy)</span></i>},
    {id:'DESIGN_CERT_NUM',display:<i className='fs-90'>Design Certificate Form</i>},
    {id:'ACCURACY_CERT_NUM',display:<i className='fs-90'>Accuracy Certificate Form</i>},
    {id:'TRANSACTIONAL_ARRANGEMENTS_NUM',display:<i className='fs-90'>Transitional Arrgangements Report Form</i>},
    {id:'MAINTENANCE_VAL_CERT_NUM',display:<i className='fs-90'>Maintenance Validation Certificate Form</i>},
    {id:'ALL_FLOODPLAIN',display:'All Floodplain Harvesting'},
    {id:'METER_REGISTRATION_FPH_POI',display:<i className='fs-90'>Point of Intake Meter Registration</i>},
    {id:'METER_REGISTRATION_FPH_STORAGE',display:<i className='fs-90'>Storage Meter Registration</i>},
    {id:'SURVEY_BENCHMARKS_VAL_CERT_FPH',display:<i className='fs-90'>Survey Benchmarks Validation Certificate Form</i>},
    {id:'ACCURACY_CERT_FPH_POI',display:<i className='fs-90'>Point of Intake Accuracy Certificate Form</i>},
    {id:'UPDATE_RATING_TABLE_FPH',display:<i className='fs-90'>Update Rating Table Certificate Form</i>},
    {id:'SECONDARY_EQUIPMENT_VAL_FPH',display:<i className='fs-90'>Secondary Metering Equipment Validation Certificate Form</i>},
    {id:'VALIDATION_CERT_FPH_POI',display:<i className='fs-90'>Point of Intake Validation Certificate Form</i>},
    {id:'PRIMARY_VAL_CERT_FPH',display:<i className='fs-90'>Primary Validation Certificate Form</i>}
];

const initState = {
    callingBackend:false,
    formIdStr:'',
    formIdError:null,
    formId:null,
    keyword:'',
    statuses:[],
    formType:null,
    dqp:null,
    createdDateForm:null,
    createdDateTo:null,
    searched:false,
    records:[]
}

class SearchForms  extends React.Component {

    constructor(props) {
        super(props);
        this.state = JSON.parse(JSON.stringify(initState));
    }

    async componentDidMount() {
        this.setState({
            statuses:statusOptions.map(s=>s),
            formType: formTypeOptions[0]
        });
    }

    search = (e) => {
        e.preventDefault();
        this.refreshPage();
    }

    refreshPage = async () => {
        uiHelpers.showProgressOnTop('Please wait...');
        this.setState(Object.assign(this.state,{callingBackend:true}));
        await apiCaller.post(serviceUrls.admin.searchForms,{
            formId:this.state.formId,
            keyword:this.state.keyword,
            statuses:this.state.statuses.map(o=>o.id),
            formType:this.state.formType && this.state.formType.id != 'ALL'?this.state.formType.id:null,
            dqpId:this.state.dqp?this.state.dqp.id:null,
            createdDateForm:this.state.createdDateForm,
            createdDateTo:this.state.createdDateTo,
        })
        .then((data)=>{
            const newState = JSON.parse(JSON.stringify(initState));
            
            newState.searched = true;
            newState.records = data.result;

            newState.formId = this.state.formId;
            newState.formIdStr = this.state.formIdStr;
            newState.formIdError = this.state.formIdError;
            newState.keyword = this.state.keyword;
            newState.status = this.state.status;
            newState.createdDateForm = this.state.createdDateForm;
            newState.createdDateTo = this.state.createdDateTo;
            newState.dqp = this.state.dqp;
            newState.formType = this.state.formType;
            newState.statuses = this.state.statuses;
            this.state = newState;
        }).finally(this.wholePageAfterRefresh);
    }

    wholePageAfterRefresh = () => {
        this.setState(Object.assign(this.state,{callingBackend:false,editApprovingOfficerForm:null}));
        uiHelpers.hideProgressOnTop();
    }

    openMeterCommission  = (meterCommission) => {

        switch(meterCommission.status){
            case 'PENDING_SITE_REGO':
            case 'PENDING_REGO_APPROVAL':
                this.setState({redirectTo:`/compliance-process/${meterCommission.id}/site-registration`});return;
            default:
                this.setState({redirectTo:`/compliance-process/${meterCommission.id}/validation`});return;
        }
    }

    onKeywordChanged = (e) => {this.setState({keyword:e.target.value});}
    onStatusChanged =(o) =>{this.setState({status : o});}
    onFormTypeChanged =(o) =>{this.setState({formType : o});}
    onResetClick = () => {
        const newState = JSON.parse(JSON.stringify(initState));
        newState.statuses = statusOptions.map(s=>s);
        newState.formType = formTypeOptions[0];
        this.setState(newState);
    }
    onStatusesChanged = (status) => {
        if(this.state.statuses.includes(status)){
            const index = this.state.statuses.indexOf(status);
            if (index > -1) { // only splice array when item is found
                this.state.statuses.splice(index, 1); // 2nd parameter means remove one item only
            }
        } else {
            this.state.statuses.push(status);
        }
        this.forceUpdate();
    }

    onFormIdChange = (e) => {
        if(e.target.value.trim() == '' || isPositiveWholeNumber(e.target.value)) {
            this.setState({formIdStr:e.target.value, formIdError:null, formId:parseInt(e.target.value)})
        } else {
            this.setState({formIdStr:e.target.value, formIdError:'Not a valid ID', formId:null})
        }
    }

    convertFormType = (formType) => {
        switch(formType) {
            case 'NON_URBAN': return <span class="badge bg-primary">NUMR</span>
            case 'FPH': return <span class="badge bg-success">FPH</span>
            default : return null;
        }
    }

    convertFormSource = (source, subSource,formStatus) => {
        switch(source) {
            case 'MeterCommission': return "Meter Compliance Process"
            case 'MeterRegistration': return formStatus=="SUBMITTED"?<><strong><AsteriskRed/></strong> Meter Registration</>:"Meter Registration"
            case 'MeteringEquipmentInformation':
                switch(subSource) {
                    case 'VALIDATION_CERT': return "Validation Certificatie"
                    case 'DESIGN_CERT': return "Design Certificate"
                    case 'ACCURACY_CERT': return "Accuracy Certificate"
                    case 'TRANSITIONAL_ARRANGEMENTS_CERT': return "Transitional Arrangements Certificate"
                    case 'MAINTENANCE_VALIDATION_CERT': return "Maintenance Validation Certificate"
                    default : return null;
                }
            case 'BenchmarkDetails': return "Survey Benchmarks Validation Certificate"
            case 'RatingTableCertificate': return "Update Rating Table Certificate"
            case 'StorageManagementValidation': return "Storage Management Validation Certificate"
            case 'SecondaryEquipmentValidation': return "Secondary Validation Certificate"
            default : return null;
        }
    }

    convertFormStatus = (form) =>{
        switch(form.source) {
            case 'MeterCommission': 
                return <>Status: <b>{convertMeterCommissionStatus(form.formStatus)}</b></>;
            case 'MeterRegistration': 
                switch(form.formStatus) {
                    case 'DRAFT': return <>Status: <b>Draft</b></>
                    case 'SUBMITTED': return <>Status: <b>Submitted</b></>
                    case 'INSTALLED': return <>Status: <b>Installed</b></>
                    case 'REMOVED': return <>Status: <b>Removed</b></>
                    case 'PENDING_APPROVAL': return <>Status: <b>Pending Approval</b></>
                    default : return <>Status: <b>Unknown</b></>
                }
            case 'MeteringEquipmentInformation': 
            case 'BenchmarkDetails': 
            case 'RatingTableCertificate': 
            case 'StorageManagementValidation': 
            case 'SecondaryEquipmentValidation': 
                switch(form.formStatus) {
                    case 'DRAFT': return <>Status: <b>Draft</b></>
                    case 'SUBMITTED': return <>Status: <b>Submitted</b></>
                    default : return <>Status: <b>Unknown</b></>
                }
            default : return null;
        }
    }

    openForm =(form) => {
        if(form.source == 'MeterCommission') {
            switch(form.formStatus){
                case 'PENDING_SITE_REGO':
                case 'PENDING_REGO_APPROVAL':
                case 'DELETED':
                    this.setState({redirectTo:`/compliance-process/${form.id}/site-registration`});return;
                default:
                    this.setState({redirectTo:`/compliance-process/${form.id}/validation`});return;
            }
        } else if(form.source == 'MeterRegistration') {

            switch(form.formType){
                case 'NON_URBAN':
                    window.location.href = `/NonUrbanRegistration?id=${form.id}`;
                    return;
                default:
                    window.location.href = `/FPHRegistration?id=${form.id}`;
                    return;
            }
        } else if(form.source == 'MeteringEquipmentInformation') {
            switch(form.subSource){
                case 'VALIDATION_CERT':
                    if(form.formType=='NON_URBAN') window.location.href = `/validation-certificate-num?id=${form.id}`;
                    else window.location.href = `/validation-certificate-point-of-intake?id=${form.id}`;
                    return;
                case 'DESIGN_CERT':
                    window.location.href = `/MeterDesignCertificate?id=${form.id}`;
                    return;
                case 'ACCURACY_CERT':
                    if(form.formType=='NON_URBAN') window.location.href = `/accuracy-certificate-num?id=${form.id}`;
                    else window.location.href = `/accuracy-certificate-fph?id=${form.id}`;
                    return;
                case 'TRANSITIONAL_ARRANGEMENTS_CERT':
                    window.location.href = `/TransitionalReporting?id=${form.id}`;
                    return;
                case 'MAINTENANCE_VALIDATION_CERT':
                    window.location.href = `/validation-certificate-num?id=${form.id}`;
                    return;
            }
        } else if(form.source == 'BenchmarkDetails') {
            window.location.href = `/SurveyBenchmarkCertificate?id=${form.id}`;
            return;
        } else if(form.source == 'RatingTableCertificate') {
            window.location.href = `/UpdateRatingTable?id=${form.id}`;
            return;
        } else if(form.source == 'StorageManagementValidation') {
            window.location.href = `/StorageManagementValidation?id=${form.id}`;
            return;
        } else if(form.source == 'SecondaryEquipmentValidation') {
            window.location.href = `/SecondaryEquipmentValidation?id=${form.id}`;
            return;
        }
    }

    render() {
        return <>
            {this.state.redirectTo && <Redirect to={this.state.redirectTo}  />}
            <h3 class="mb-3">Search for Forms</h3>

            <form action={""} onSubmit={this.search} >
                <div class="row mb-2">
                    <div class="col-md-2">
                        <InputText
                            className="mb-3"
                            value={this.state.formIdStr} 
                            disabled={this.state.callingBackend}
                            onChange={this.onFormIdChange}
                            label={<>Form ID</>}
                            error={this.state.formIdError}
                        />
                    </div>
                    <div class="col-md-5">

                        <InputText
                            className="mb-3"
                            value={this.state.keyword} 
                            disabled={this.state.callingBackend}
                            onChange={this.onKeywordChanged}
                            label={<>Keyword <i className='fs-80'>(Approval / ESID / Worksite ID / ROI)</i></>}
                        />
                    </div>
                </div>

                <div class="row mb-3">
                    <div class="col-md-7">
                        <div class="accordion mb-3" id="accordionCriteries">
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingCirterias">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseCriterias" aria-controls="collapseFormTypes">
                                    Advanced Criteria
                                    </button>
                                </h2>
                                <div id="collapseCriterias" class="accordion-collapse collapse" aria-labelledby="headingCirterias" data-bs-parent="#accordionCriteries">
                                    <div class="accordion-body">

                                        
                                        <div className='mb-3'>
                                            <label className='form-label'>DQP</label>
                                            <UsersDropDown onChange={(u)=>this.setState({dqp:u})} isDisabled={this.state.callingBackend} selectedUser={this.state.dqp} />
                                        </div>

                                        <div className='mb-3'>
                                            <label className='form-label'>Form Type</label>
                                            <Select
                                                isDisabled={this.state.callingBackend}
                                                getOptionLabel={o=>o.display}
                                                getOptionValue={o=>o}
                                                options={formTypeOptions}
                                                onChange={this.onFormTypeChanged}
                                                value={this.state.formType}
                                            />
                                        </div>

                                        <div className='mb-3'>
                                            <label className='form-label'>Status</label>
                                            <button className='btn btn-outline-primary btn-sm ms-3' type="button" onClick={()=>{
                                                if(this.state.statuses.length == 0) {
                                                    this.state.statuses = statusOptions.map(s=>s);
                                                } else {
                                                    this.state.statuses = [];
                                                }
                                                this.forceUpdate();
                                            }}>Select/Deselect All</button>
                                            {statusOptions.map(o=>{
                                                return <div class="form-check">
                                                    <input class="form-check-input" disabled={this.state.callingBackend} type="checkbox" value="" id={"check_status_"+ o.id} checked={this.state.statuses.includes(o)} onChange={()=>{this.onStatusesChanged(o)}}/>
                                                    <label class="form-check-label" for={"check_status_"+ o.id}>
                                                        {o.display}
                                                    </label>
                                                </div>
                                            })}
                                        </div>

                                        <div class="row">
                                            <div className='col-md-5 mb-3'>
                                                <label className='form-label'>Created From</label><br/>
                                                <DatePicker dateFormat="dd/MM/yyyy" disabled={this.state.callingBackend} className='form-control' selected={this.state.createdDateForm} onChange={(e)=>{this.setState({createdDateForm:e})}}  />
                                            </div>
                                            <div class="col-md-5">
                                                <label className='form-label'>To</label><br/>
                                                <DatePicker dateFormat="dd/MM/yyyy" disabled={this.state.callingBackend} className='form-control' selected={this.state.createdDateTo} onChange={(e)=>{this.setState({createdDateTo:e})}}  />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                    
                    
                <div className='mb-3'>
                    <button type="submit" class="btn btn-primary me-2" disabled={this.state.callingBackend}>Search</button>
                    <button type="button" class="btn btn-secondary" disabled={this.state.callingBackend} onClick={this.onResetClick}>Reset</button>
                </div>
            </form>

            {this.state.searched && <>

                {this.state.records.length==0 && <div className='bg-light text-center p-3'>
                    No records found
                </div>}

                {this.state.records.length>0 && <ul className={`list-group my-5`}>
                    {this.state.records.map(form=><>
                        <li class="list-group-item">
                            <div className='row'>
                                <div className='col-md-2 mb-3'>
                                    {this.convertFormType(form.formType)}
                                    <br/><span class="fs-80 text-secondary">Created: <b>{moment(form.createdDate).format('DD/MM/YYYY')}</b></span>
                                    <br/><span class="fs-80 text-secondary">DQP: <b>{form.dqpFirstname} {form.dqpLastname}</b></span>
                                </div>
                                <div className='col-md-4 mb-3'>
                                    {this.convertFormSource(form.source, form.subSource, form.formStatus)}
                                    {form.source=='MeterRegistration' && form.formType=='FPH' && form.measurementMethod=='STORAGE_MESUREMENT' && <i className='fs-80 ms-2'>(Storage)</i>}
                                    {form.source=='MeterRegistration' && form.formType=='FPH' && form.measurementMethod=='POINT_OF_INTAKE' && <i className='fs-80 ms-2'>(Point of Intake)</i>}
                                    {form.source=='MeteringEquipmentInformation' && form.formType=='FPH'  && <i className='fs-80 ms-2'>(Point of Intake)</i>}
                                    <br/><span class="fs-80 text-secondary">ID: #<b>{form.id}</b></span>
                                    {form.installationType && <span className='fs-80 text-primary'><br/>{form.installationType}</span>}
                                </div>
                                <div className='col-md-4 mb-3'>
                                    <span class="fs-80 text-secondary">{this.convertFormStatus(form)}</span>
                                    
                                    {form.approval && <><br/><span class="fs-80 text-secondary">Approval: <b className='text-primary'>{form.approval}</b></span></>}

                                    {form.authorisedOrgName && <><br/><span class="fs-80 text-secondary">Approval Holder: <b>{form.authorisedOrgName}</b></span></>}
                                    {form.authorisedHolderFistName && <><br/><span class="fs-80 text-secondary">Approval Holder: <b>{form.authorisedHolderFistName} {form.authorisedHolderLastName}</b></span></>}

                                    {form.esid && <><br/><span class="fs-80 text-secondary">ESID: <b>{form.esid}</b></span></>}
                                    {form.meterNumber && <><br/><span class="fs-80 text-secondary">Meter No.: <b>{form.meterNumber}</b></span></>}
                                    {form.reference && <><br/><span class="fs-80 text-secondary">Reference: <b>{form.reference}</b></span></>}
                                </div>
                                <div className='col-md-2 text-end'>
                                    <button className='btn btn-primary' disabled={this.state.searching} onClick={()=>this.openForm(form)}>Open Form</button>
                                </div>
                            </div>

                        </li>
                    </>)}
                </ul>}
            </>}

        </>
    }
}

export default SearchForms;
