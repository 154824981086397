import React from 'react';
import { converters } from '../../../../helpers/converters';
import { AsteriskRed } from '../../../../components/tools/Others';

export default class ValidationLidFields  extends React.Component {

    render = () => {
        const form = this.props.validationForm;
        return <>
            {form.renderUneditableTextInputRow('lIDTelemetry',<>Is the LID being used for Telemetry?</>,{rowClasses:'mb-3', converter:converters.common.converBoolToYesNo})}
            {form.val('lIDTelemetry')===true && form.renderUneditableTextInputRow('telemetryType',<>LID Telemetry Type</>,{rowClasses:'mb-3', converter:type=>type?type.name:null})}
            {
                (form.val('lIDTelemetry')===false || (form.val('lIDTelemetry')===true && form.val('telemetryType'))) 
                && 
                form.renderUneditableTextInputRow('lid',<>LID Device</>,{rowClasses:'mb-3', converter:lid=>lid?lid.name:null})
            }
            {form.val('lid').allowMultiple && <>
                {form.renderUneditableTextInputRow('isFirstMeterForLID',<>Is this the first meter registered to this LID? </>,{rowClasses:'mb-3', converter:value=>value===true?"Yes":value===false?"No":null})}
                {form.renderUneditableTextInputRow('allMetersForLid',<>Currently Connecting to</>,{rowClasses:'mb-3', converter:value=>{
                    return <span className='fs-80'>{value.map((m,i)=>(i!=0?",":"") + m.meterSiteId)}</span>
                }})}
            </>}

            {(form.vals().newMeterEquipment == "5" && form.vals().lid.allowMultiple) || (form.vals().newMeterEquipment != "5" && form.vals().lid.allowMultiple && form.vals().isFirstMeterForLID===false)  ? <>
                <div className='row mb-3'>
                    <div className='col-md-4 py-2'>
                    LID Serial number
                    </div>
                    <div className='col-md-8'>
                        {form.renderUneditableTextInput('dataCollectorSerialNo')}
                        {form.vals().newMeterEquipment == "5"
                            ?
                            <span className='text-warning'>This is currently not supported to be modified for {form.val('lid').name}</span>
                            :
                            <span className='text-warning'>This field can only be edited by the first meter of this LID</span>
                        }
                    </div>
                </div>
            </> : <>
                {form.renderTextBoxRow('dataCollectorSerialNo',<>LID Serial number <AsteriskRed/></>,{rowClasses:'mb-3'})}
            </>}
            {form.val('lid').allowMultiple ?form.renderUneditableTextInputRow("lIDType",<>LID Type <AsteriskRed/></>,{converter:o=>o.name,rowClasses:'mb-3'}):form.renderDropDownRow("lIDType",<>LID Type <AsteriskRed/></>,(o)=>o.id,o=>o.name,{rowClasses:'mb-3'})}
            {form.val('lIDType') && form.val('lIDType').key=='PULSE' && form.renderTextBoxRow('scalingFactor',<>Scaling Factor (Multiplier) <AsteriskRed/></>,{})}
        </>
    }
}