import React from 'react';
import * as $ from 'jquery'
import {PenSquare , Pen, Meter} from '../../components/tools/Icons'
import {convertDateTimeIsoToAusFormat, convertErrorCode} from '../conveters'
import {UneditableTextInput} from '../../components/tools/BootstrapControls'
import {isStringNullOrEmpty} from '../../helpers/validators'
import DeletePeriodForm from './forms/DeletePeriodForm'
import ReasonForNotLodgingForm from './forms/ReasonForNotLodgingForm'
import ChangeDatesForm from './forms/ChangeDatesForm'
import PointOfIntakeMetersForm from './forms/PointOfIntakeMetersForm'
import OhterInflowSection from './OhterInflowSection'


class Period  extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            editingOn: null,
            errors : []
        };
    }

    

    toggleForm = (formName, callback) =>{
        if(this.state.editingOn == null) {
            this.state.editingOn = formName;
            this.props.setDim(true, callback);
        } else {
            this.state.editingOn = null;
            this.props.setDim(false, callback);
        } 
    }
    toggleChangeDateForm = () => this.toggleForm('CHANGE_DATES')
    toggleDeleteForm = () => this.toggleForm('DELETION',()=>{$("html, body").animate({ scrollTop: $(document).height() }, 100);})
    toggleReasonNotLodgingForm = () => this.toggleForm('REASON')

    validateClosingPeriod = () => {
        const errors = [];
        const period = this.props.nominatedMeasurementPeriod;
        if(!period.periodStartDate) errors.push("Please provide the start date of the period.");
        if(!period.periodEndDate) errors.push("Please provide the end date of the period.");
        if(this.isReasonIsRequired() && isStringNullOrEmpty(period.reasonForNotLodging)) {
            errors.push("Reason for not lodging within 24 hours is required.");
        }
        /*
        period.nominatedMeasurementPeriodStorages.forEach(storage => {
            if(storage.isManual) {
                if(!storage.heightPriorToPeriodManual || !storage.volumePriorToPeriodManual ){
                    errors.push("Please provide the reading prior to the period start for " + storage.ratingTable.ratingTableName);
                }
                for(var i =0; i< storage.nominatedMeasurementPeriodStorageDailys.length; i++) {
                    if(!storage.nominatedMeasurementPeriodStorageDailys[i].heightManual || !storage.nominatedMeasurementPeriodStorageDailys[i].heightManual) {
                        errors.push(storage.ratingTable.ratingTableName + " has incomplete daily log");
                        return;
                    }
                }
            }
        });
        */
        this.props.setDim(true, ()=>{
            this.setState({editingOn:"CLOSE_ERRORS", errors},()=>{$("html, body").animate({ scrollTop: $(document).height() }, 100);});
        });
        return errors.length==0
    }

    isReasonIsRequired =() => {
        const period = this.props.nominatedMeasurementPeriod;
        if(!period.periodEndDate) return false;
        return (new Date() - new Date(period.periodEndDate))/(1000 * 60 * 60 * 24) > 1
    }

    validateFinalisingPeriod = () => {

    }

    closePeriod = () => {
        if(!this.validateClosingPeriod()) return;
        this.props.confirmToCloseOrFinanlise();
    }

    finalisePeriod = () => {
        if(!this.validateFinalisingPeriod()) return;
        this.props.confirmToCloseOrFinanlise();
    }

    acceptErrors = () => {
        this.props.setDim(false, ()=>{
            this.setState({editingOn: null, errors: []});
        });
    }

    
    
    

    

    renderButtonBar =() =>{

        const {dim,disableFunctions, noLoginCallback, nominatedMeasurementPeriod} = this.props;
        const {editingOn} = this.state;

        if(editingOn=='DELETION') {
            return <DeletePeriodForm onCancel={this.toggleDeleteForm} noLoginCallback={noLoginCallback} nominatedMeasurementPeriod={nominatedMeasurementPeriod} />
        }

        if(editingOn=="CLOSE_ERRORS") {
            return <div className='card border-danger border-2 mb-5 '>
                <div className='card-body '>
                    
                    <h5 className='text-danger'>This period cannot be closed for the following reasons</h5>
                    <ul className='text-danger'>
                        {this.state.errors.map(e=><li>
                            {e}
                        </li>)}
                    </ul>
                    <div className='text-end'>
                        <button className='btn btn-primary' onClick={this.acceptErrors}>Ok, I will fix them</button>
                    </div>
                    
                </div>
            </div>
        }

        return <div className={`${dim?'opacity-25':''}`}>
            <a href="/measurement-period/#/dashboard" className='btn btn-warning '>Back to dashboard</a>

            {nominatedMeasurementPeriod.status == 'OPEN' && <div className='float-end'>
                <button className='btn btn-danger ' disabled={disableFunctions} onClick={this.toggleDeleteForm}>
                    Delete
                </button>
                <div class="vr mx-3"></div>
                <button className='btn btn-primary ' disabled={disableFunctions } onClick={this.closePeriod}>
                    Close period
                </button>
            </div>}

            {nominatedMeasurementPeriod.status == 'CLOSED' && <div className='float-end'>
                <button className='btn btn-primary' disabled={disableFunctions } onClick={this.props.confirmToCloseOrFinanlise}>
                    Finalise period
                </button>
            </div>}
            
        </div>
    }

    renderReasonForNotLodgingSection = () => {
        const period = this.props.nominatedMeasurementPeriod;
        const {dim,disableFunctions,noLoginCallback, updatePeriod} = this.props;
        if(this.state.editingOn=='REASON') {
            return <ReasonForNotLodgingForm toggleForm={this.toggleReasonNotLodgingForm} nominatedMeasurementPeriod={period} noLoginCallback={noLoginCallback} updatePeriod={updatePeriod} />
        }

        if(period.periodEndDate && this.isReasonIsRequired()) {
            return <div className={`row mb-3 ${dim?'opacity-25':''}`}>
                <div class="col-md-8">
                    <div className='card card-body'>
                        <div className='alert alert-warning mb-2'>
                            The end date of this measurement period is not within 24h of the current date/time. This will be logged for record with the Natural Resources Access Regulator (NRAR)
                        </div>
                        <label  class="form-label"><strong>Reason for not lodging within 24 hours</strong></label>
                        <div class="card card-body">
                            <pre style={{"white-space": "pre-wrap"}}>{isStringNullOrEmpty(period.reasonForNotLodging) ? <i className='text-muted'>Reason not provided yet...</i> : period.reasonForNotLodging }</pre>
                            {period.status == 'OPEN' && <div className='text-end'>
                                <button className='btn btn-outline-primary' disabled={disableFunctions} onClick={this.toggleReasonNotLodgingForm}>
                                    <PenSquare/> Edit
                                </button>  
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
        }
    }

    renderDatesSection = () => {
        const period = this.props.nominatedMeasurementPeriod;
        const {dim,disableFunctions,noLoginCallback, updatePeriod} = this.props;
        if(this.state.editingOn!='CHANGE_DATES') {
            
            return <div className={`row ${dim?'opacity-25':''}`}>
                <div className='col-md-3 mb-3'>
                    <UneditableTextInput 
                        label={<>Start date &amp; time:</>} fieldExtraClass="bg-light"
                        value={convertDateTimeIsoToAusFormat(period.periodStartDate, <i className='text-muted'>Not provided</i>)}
                    />
                </div>
                <div className='col-md-3 mb-3'>
                    <UneditableTextInput 
                        label={<>End date &amp; time:</>} fieldExtraClass="bg-light"
                        value={convertDateTimeIsoToAusFormat(period.periodEndDate, <i className='text-muted'>Not provided</i>)}
                    />
                </div>
                {period.status == 'OPEN' && <div className='col-md-3 mb-3'>
                    <label className='form-label invisible'>change button</label>
                    <div>
                        <button  className='btn btn-outline-primary' disabled={disableFunctions} onClick={()=>this.toggleChangeDateForm()}>Change end date</button>
                    </div>
                </div>}
            </div>;
        }

        return <ChangeDatesForm nominatedMeasurementPeriod={period} toggleForm={this.toggleChangeDateForm} noLoginCallback={noLoginCallback} updatePeriod={updatePeriod} />
    }

    

    render(){
        const {nominatedMeasurementPeriod, dim, disableFunctions} = this.props;
        return <>
            
            
            {this.renderDatesSection()}
            {this.renderReasonForNotLodgingSection()}
            <OhterInflowSection dim={dim} setDim={this.props.setDim} disableFunctions={disableFunctions} updatePeriod={this.props.updatePeriod} nominatedMeasurementPeriod={nominatedMeasurementPeriod} />

            {nominatedMeasurementPeriod.nominatedMeasurementPeriodStorages.length!=0 && <div className={`card mb-3 ${dim?'opacity-25':''}`}>
                <div className="card-header">
                    Storages
                </div>
                <div className="card-body">
                    <table className='table'>
                        <thead>
                            <tr>
                                <th style={{width:'250px'}}>
                                    Storage
                                </th>
                                <th style={{width:'280px'}}>
                                    Reading from
                                </th>
                                <th style={{width:'280px'}}>
                                    Change in storage volume (ML)
                                </th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {nominatedMeasurementPeriod.nominatedMeasurementPeriodStorages.map((storage, index)=>{
                                return <tr>
                                    <td>
                                        <div  className='text-truncate'>{storage.ratingTable.ratingTableName}</div>
                                        {!storage.dasMeterExists && <span className='badge bg-secondary'>No telemetry device</span>}
                                        {!storage.secondaryMeterId && <span className='badge bg-secondary'>No secondary device</span>}
                                    </td>
                                    <td>
                                        {storage.isManual ? <><Pen/> Secondary measurement</> : <><Meter/> Telemetry device</>} 
                                    </td>
                                    <td>
                                        {storage.totalVolumeChange}
                                    </td>
                                    <td className='text-end'>
                                        <button type="button" disabled={this.props.disableFunctions} class="btn btn-outline-primary btn-sm" onClick={()=>this.props.setStorage(storage)}>Daily log details</button>
                                    </td>
                                </tr>
                            })}
                            
                        </tbody>
                    </table>
                </div>
            </div>}

            {nominatedMeasurementPeriod.nominatedMeasurementPeriodPOITMs.length !=0 && 
                <PointOfIntakeMetersForm dim={dim}  setDisableFunctions={this.props.setDisableFunctions} setDim={this.props.setDim} disableFunctions={disableFunctions} updatePeriod={this.props.updatePeriod} nominatedMeasurementPeriod={nominatedMeasurementPeriod} />
            }

            <div className={`card mb-3 border-primary ${dim?'opacity-25':''}`}>
                <div class="card-header text-primary">
                    <strong>Summary</strong>
                </div>
                <div class="card-body">
                    <table className='table'>
                        <tbody>
                            {nominatedMeasurementPeriod.nominatedMeasurementPeriodPOITMs.length !=0 && <tr>
                                <th className='w-25 text-end'>
                                    Total point of intake volume:
                                </th>
                                <td>
                                    {nominatedMeasurementPeriod.totalChangeInFlow} ML
                                </td>
                            </tr>}
                            <tr>
                                <th className='w-25 text-end'>
                                    Total change in storage volume:
                                </th>
                                <td>
                                    {nominatedMeasurementPeriod.totalChangeInStorageVolume} ML
                                </td>
                            </tr>
                            <tr>
                                <th className='w-25 text-end'>
                                    Total other Non-FPH inflow:
                                </th>
                                <td>
                                    {nominatedMeasurementPeriod.totalOtherNonFPHInFlow} ML
                                </td>
                            </tr>
                            <tr className='table-primary'>
                                <th className='w-25 text-end'>
                                    Total FPH volume:
                                </th>
                                <td>
                                    {nominatedMeasurementPeriod.totalFPHVolume} ML
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            {this.renderButtonBar()}

            
        </>;
    }

}

export default Period;