import React from 'react';
import axios from 'axios';
import Authentication from '../helpers/Authentication'
import MeterValidationCertificateFormSelection from '../components/certificates/meterValidation/MeterValidationCertificateFormSelection';
import AuthorityHolderDetailsFormSection from '../components/certificates/common/AuthorityHolderDetailsFormSection';
import ApprovalDetailsFormSection from '../components/certificates/common/ApprovalDetailsFormSection';
import MeterValidationCertificatePartCForm from '../components/certificates/meterValidation/MeterValidationCertificatePartC';
import MeterValidationCertificatePartDForm from '../components/certificates/meterValidation/MeterValidationCertificatePartD';
import MeterValidationCertificatePartEForm from '../components/certificates/meterValidation/MeterValidationCertificatePartE';
import MeterValidationCertificatePartFForm from '../components/certificates/meterValidation/MeterValidationCertificatePartF';
import MeterValidationCertificateConfirmation from '../components/certificates/meterValidation/MeterValidationCertificateConfirmation';
import ValidationAndMaintenanceChecklistForm from '../components/certificates/common/ValidationAndMaintenanceChecklist';

import SearchCustomerForCertificates from '../components/certificates/common/SearchCustomerForCertificates';

import { closedConduitandProtableOptionsForValidationCert as closedConduitandProtableOptions, openChannelOptionsForValidationCert as  openChannelOptions } from '../constants/MeterFormConstants'

import DisplayForRoles from '../components/tools/DisplayForRoles'
import CertificateAdmin from '../components/certificates/common/CertificateAdmin'
import CertificateFiles from '../components/certificates/common/CertificateFiles'
import MeterEquipInfoAudit from '../components/certificates/common/MeterEquipInfoAudit'

import { formValueSelector, reduxForm, change } from 'redux-form'
import { connect } from 'react-redux'
import { load, save, findById, findByIdCopy, update } from '../components/certificates/meterValidation/MeterValidationCertificate.Reducers';
import { getMeterNumbers , deleteDraftForm} from '../components/certificates/common/MeterCertificateCommon'
import {GlossaryNUM, GlossaryFPH, PrivacyStatementNUM, PrivacyStatementFPH} from '../components/common/StaticContents'
import uiHelpers from '../helpers/uiHelpers';
import {StepProgressBar} from '../components/tools/BootstrapControls'
import ReApplyConsentNotice from '../components/common/ReApplyConsentNotice'
import { userRole, addUserRoleUpdateNotifier } from '../helpers/userHelpers';

const authentication = new Authentication();

class MeterValidationCertificate extends React.Component {

  approvedWorkDetails = {};
  authorityHolderDetails = {};
  meteringEquipmentInformation = {};
  #currentUserEmail;
  #currentUserId;

  ajaxConfig = {
    baseURL: process.env.REACT_APP_WEBAPI_URL,
    headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authentication.getToken() }
  };
  

  constructor(props) {
    super(props)
    this.nextPage = this.nextPage.bind(this);
    this.previousPage = this.previousPage.bind(this);
    this.#currentUserEmail = authentication.currentUser().emails;
    this.state = {
      page: -1,
      validationDate: new Date(),
      meterReadingDate: new Date(),
      showInstructions: false,
      showReview:0,
      meteringCircumstanceOptions:
        [
          { id: "1", name: "I have an existing validation that was done by a duly qualified person.", selected: false },
          { id: "2", name: "I have a manufacturer’s certificate.", selected: false },
          { id: "3", name: "I have an accuracy certificate provided by a duly qualified person.", selected: false }
        ],
      data: {},
      confirmingSubmission: false,      
      CERT_NAME: 'Meter Validation Certificate',
      PrivacyStatement: PrivacyStatementNUM,
      Glossary: GlossaryNUM,
      fphMode: false,
      tab : 'MAIN'
    }

    addUserRoleUpdateNotifier(() => {
      if(userRole.isSuperAdmin){
        this.forceUpdate();
      }
    });
  }

  async componentDidMount() {
    const currentContxt = this;
    axios.post('users/getprofile', JSON.stringify({ email: this.#currentUserEmail }), this.ajaxConfig).then(response => {
      currentContxt.#currentUserId = response.data.data.user.user.id;
      currentContxt.pageRefresh();
      
    }).catch(function (error) {
      console.log(error);
      uiHelpers.showErrorTop('Error occurred when reading your user info');
    });
  }

  pageRefresh = async(meterDesignId = null,page) =>{

    
    
    let search = window.location.search;
    let params = new URLSearchParams(search);

    let fphmodeParam = this.props.fphMode;


    var showRvw = false;
    if(fphmodeParam) {
     this.setFPHMode();     
    }
    
    if(!meterDesignId) {
      meterDesignId = params.get('id');
      if(params.get('showReview') && params.get('showReview')==1)
      {
        this.setState({ confirmingSubmission: true });
        this.setState({ CERT_NAME: "Maintenance Validation Certificate" });
        //this.setState({showReview:params.get('showReview')}, () => {alert(this.state.showReview)});
        this.setState({showReview:params.get('showReview')});
        showRvw = true;
        //this.setMaintenanceValidation();
        //showReview = params.get('showReview');
      }
    }
    
    if (meterDesignId != null) {
      

      if(page) {
        this.setState({ page});
      } else {
        this.setState({ page: this.state.page + 1 });
      }
      
      //this.props.dispatch(change(this.props.form, 'showSpinner', 1));
      uiHelpers.showProgressOnTop("Getting/Refreshing certificate data...");
      this.props.dispatch(change(this.props.form, 'loading', true));
      if(showRvw)
        await this.props.dispatch(findByIdCopy(meterDesignId));
      else
        await this.props.dispatch(findById(meterDesignId));
      uiHelpers.hideProgressOnTop();
      this.props.dispatch(change(this.props.form, 'loading', false));
        
      if (this.props.meterEquipInfo.formCategory == 'FPH'){
        this.setFPHMode();  
      }
      if (this.props.meterEquipInfo.maintenanceValidation == 1){
        this.setMaintenanceValidation();  
      }
      if (
        this.props.approvedWorkDetails.approvedByCustomer == 1 &&
        this.props.approvedWorkDetails.waterSupplyWorkApprovalNo != "" &&
        this.props.approvedWorkDetails.esid &&
        this.props.approvedWorkDetails.esid != ""
      ) {

        uiHelpers.showProgressOnTop("Getting meter data...");
        this.props.dispatch(change(this.props.form, 'loading', true));

        var payloadData = await getMeterNumbers(this.props.approvedWorkDetails.waterSupplyWorkApprovalNo,this.props.approvedWorkDetails.esid);
        
        uiHelpers.hideProgressOnTop();
        this.props.dispatch(change(this.props.form, 'loading', false));
        
        if (payloadData.success)
          //props.dispatch(getMeterNumbers(appWorkInfo.waterSupplyWorkApprovalNo,esidValue));
          this.props.dispatch(change(this.props.form, 'meterNumberExternalList', payloadData.meterNumberExternalLists));
        else
          uiHelpers.showErrorTop("Error occured when getting meter data", true, false);
      }
    } else {
      try {
        uiHelpers.showProgressOnTop("Loading data...");
        this.props.dispatch(change(this.props.form, 'loading', true));
        await this.props.dispatch(load());
        uiHelpers.hideProgressOnTop();
        this.props.dispatch(change(this.props.form, 'loading', false));
      } catch (error) {
        uiHelpers.showErrorTop("Error when getting data...", true, false);
      }

    }
  }

  nextPage = (values) => {
    this.saveDraft(values);
  }

  completeConfirmation = () => {
    this.setState({ confirmingSubmission: false, page: this.state.page + 1 });
  }

  cancelConfrimSubmission = () => {
    this.setState({ confirmingSubmission: false });
  }

  cancelConfrimSubmissionAndGoToPage = (page) => {
    this.setState({ confirmingSubmission: false, page: page });
  }

  saveDraft = (values) => {
    if(this.state.fphMode) {
      this.props.dispatch(change(this.props.form, 'meteringEquipmentInformation.formCategory', 'FPH'));
    }
   
    if (parseInt(values.clickSaveDraft) === 1 && parseInt(values.clickNext) === 0) {
      //console.log("MeterValidationPage:saving draft");
      this.resetValues(values);
     
      uiHelpers.showProgressOnTop("Saving your record...");
      this.props.dispatch(change(this.props.form, 'loading', true));
      if (values.meteringEquipmentInformation.id > 0)
        this.props.dispatch(update(values, this.state.page, this.pageRefresh));
      else
        this.props.dispatch(save(values, this.state.page, this.pageRefresh));

    } else if (parseInt(values.clickSaveDraft) === 0 && parseInt(values.clickNext) === 1 && values.meteringEquipmentInformation.formStatus == 2) {
      
      this.resetValues(values);
      
      uiHelpers.showProgressOnTop("submitting your record...");
      this.props.dispatch(change(this.props.form, 'loading', true));
      if (values.meteringEquipmentInformation.id > 0)
        this.props.dispatch(update(values, this.state.page, this.pageRefresh));
      else
        this.props.dispatch(save(values, this.state.page, this.pageRefresh));
    } else {
      //this.setState({ page: this.state.page + 1 });
      if(values.meteringEquipmentInformation.formStatus==1 && this.state.page == 5 && !this.state.confirmingSubmission ) {
        this.setState({ confirmingSubmission: true });
      } else if(values.meteringEquipmentInformation.formStatus==1 && this.state.page == 5 && this.state.confirmingSubmission ) {
        this.setState({ confirmingSubmission: false, page: this.state.page + 1 });
      } else if (values.meteringEquipmentInformation.newMeterEquipment == "2" && this.state.page == 2) {
        this.setState({ page: this.state.page + 2 });
      } else if ((values.meteringEquipmentInformation.newMeterEquipment == "1" || values.meteringEquipmentInformation.newMeterEquipment == "3") && this.state.page == 3) {
        this.setState({ page: this.state.page + 2 });
      } else {
        this.setState({ page: this.state.page + 1 });
      }
      //console.log(JSON.stringify(values));
    }

  }

  resetValues = (values) => {
    if (values.meteringEquipmentInformation.newMeterEquipment == "1") {
      values.meteringEquipmentInformation.meterCircumstancesOptions = [];;
      values.meteringEquipmentInformation.dateOfLastValidation = ""
      values.meteringEquipmentInformation.meterRemovedDate = "";
      values.meteringEquipmentInformation.dateBeforeMaintenance = "";
      values.meteringEquipmentInformation.readingBeforeMaintenance = "";
      values.meteringEquipmentInformation.dateAfterMaintenance = "";
      values.meteringEquipmentInformation.readingAfterMaintenance = "";
      values.meteringEquipmentInformation.finalReadingRemovedMeter = "";
      values.meteringEquipmentInformation.finalReadingDateRemovedMeter = "";
      values.meteringEquipmentInformation.otherConclusion = "";
      values.meteringEquipmentInformation.meterBeforeApril2019 = "";
      values.meteringEquipmentInformation.dateOfInstallationBeforeApril2019 = "";
    }
    if (values.meteringEquipmentInformation.newMeterEquipment == "3") {
      values.meteringEquipmentInformation.meterCircumstancesOptions = [];;
      values.meteringEquipmentInformation.dateOfLastValidation = ""
      values.meteringEquipmentInformation.meterRemovedDate = "";
      values.meteringEquipmentInformation.dateBeforeMaintenance = "";
      values.meteringEquipmentInformation.readingBeforeMaintenance = "";
      values.meteringEquipmentInformation.dateAfterMaintenance = "";
      values.meteringEquipmentInformation.readingAfterMaintenance = "";
      //values.meteringEquipmentInformation.finalReadingRemovedMeter = "";
      //values.meteringEquipmentInformation.finalReadingDateRemovedMeter = "";
      values.meteringEquipmentInformation.otherConclusion = "";
      values.meteringEquipmentInformation.meterBeforeApril2019 = "";
      values.meteringEquipmentInformation.dateOfInstallationBeforeApril2019 = "";
    }
    if (parseInt(values.meteringEquipmentInformation.meteringSystemTypeId) != parseInt(this.props.masterDataValues.OpenChannelId)) {
      values.meteringEquipmentInformation.channelType = "";
      values.meteringEquipmentInformation.channelWidth = "";
      values.meteringEquipmentInformation.meterDistanceFromSource = "";
      values.meteringEquipmentInformation.channelLengthUpstream = "";
      values.meteringEquipmentInformation.channelLlengthDownstream = "";
      values.meteringEquipmentInformation.designEquipmentDetails = "";
      values.meteringEquipmentInformation.meteringEquipmentConsistent = "";
      values.meteringEquipmentInformation.maxMeasuredDischarge = "";
      values.meteringEquipmentInformation.minMeasuredDischarge = "";
      values.meteringEquipmentInformation.maxMeasuredVelocity = "";
      values.meteringEquipmentInformation.minMeasuredVelocity = "";
      values.meteringEquipmentInformation.maxGaugeHeight = "";
      values.meteringEquipmentInformation.minGaugeHeight = "";
      values.meteringEquipmentInformation.maxError = "";
      values.meteringEquipmentInformation.minError = "";
      values.meteringEquipmentInformation.noOfMeasurementsTaken = "";
      values.meteringEquipmentInformation.openChannelConclusion = "";
    }
    else
    {
      values.meteringEquipmentInformation.pipeType = "";
      values.meteringEquipmentInformation.pipeSize = "";
      values.meteringEquipmentInformation.pipeConnectionType = "";
      values.meteringEquipmentInformation.pipeLengthUpstream = "";
      values.meteringEquipmentInformation.pipeLengthDownstream = "";
    }
  }

  nonCompliancePrevForm = (values) => {
    if (this.props.meterEquipInfo.newMeterEquipment != undefined &&
      (this.props.meterEquipInfo.newMeterEquipment == "1" || this.props.meterEquipInfo.newMeterEquipment == "3")) {
      this.setState({ page: this.state.page - 2 });
    }
    else {
      this.setState({ page: this.state.page - 1 });
    }
  }

  previousPage = (formStatus) => {

    if(this.state.page == 6 && formStatus == 1 ) {
      this.setState({ confirmingSubmission: true, page: this.state.page - 1 });
    } else if (this.state.page == 6) {
      this.setState({ page: this.state.page - 1 });
    }

    if (this.props.meterEquipInfo.newMeterEquipment == "2" && this.state.page == 4) {
      this.setState({ page: this.state.page - 2 });
    }
    else
      this.setState({ page: this.state.page - 1 });
  }

  HideShow = () => {
    this.setState({ showInstructions: !this.state.showInstructions });
  }

  deleteDraft = ()=>{
    if (this.props.meterEquipInfo.id != 0) {
      let currentContext = this;
      deleteDraftForm(
        parseInt(this.props.meterEquipInfo.id)
        , function(){
          currentContext.props.dispatch(change(currentContext.props.form, 'loading', true));
        }
        , function(){
          window.location = 'Home';
        }
        , function(){
          
        }
        , function(error) {
          console.log(error);
          uiHelpers.showErrorTop('Error occurred when trying to delete the draft record ', true, false);
          currentContext.props.dispatch(change(currentContext.props.form, 'loading', false));
        }
      );
    } else {
      uiHelpers.showErrorTop('You are accessing this function in a invalid way', true, false);
    }
  }

  setFPHMode() {
    this.setState({...this.state, 
    CERT_NAME: "Point of Intake Meter Validation Certificate",
    PrivacyStatement: PrivacyStatementFPH,
    Glossary: GlossaryFPH,
    fphMode: true
  });
  }

  setMaintenanceValidation() {
    this.setState({...this.state, 
    CERT_NAME: "Maintenance Validation Certificate"
  });
  }

  render() {
    const { page, showInstructions,  confirmingSubmission } = this.state

    const { form, loading , consentValid} = this.props;

    const needReApplyConsent = !userRole.isSuperAdmin && (this.props.meterEquipInfo && this.props.meterEquipInfo.formStatus==1 && consentValid===false && !(this.props.meterEquipInfo.createdBy && this.props.meterEquipInfo.createdBy.id != this.#currentUserId)) ? true : null;

    return <>
      <h3 className='mb-3'>{this.state.CERT_NAME}</h3>
      <p>
        <a className="btn btn-primary" onClick={this.HideShow}>Privacy and Glossary</a>
      </p>
      
      {showInstructions && <div class="card card-body fs-90 mb-5">
        <h5 className='mb-3'>Privacy Statement</h5>
        
        {this.state.PrivacyStatement}

        <h5>Glossary of terms</h5>
        {this.state.Glossary}
      </div>}

      {/*!confirmingSubmission && page === -1 && <SearchCustomerForCertificates form={form} onSubmit={this.nextPage} />*/}

      {!confirmingSubmission && page === -1 && <>
        <div className='alert alert-warning my-3'>
          This function is replaced by the Meter Compliance Process and it is no longer avaiable.
        </div>
      </>}

      {page >= 0 && this.props.meterEquipInfo && <>
        {!this.state.showReview &&<div class="card mb-3">
          <div class="card-body row">
              <div className='col-md-3'>
                <label class="form-label">Record ID</label>
                <input type="text" disabled={true} class="form-control" value={this.props.meterEquipInfo.id == 0 ? "Not saved" : this.props.meterEquipInfo.id} />
              </div>
              <div className='col-md-3'>
                <label class="form-label">Form Status</label>
                <input type="text" disabled={true} class="form-control" value={this.props.meterEquipInfo.formStatus==1?'Draft' : 'Submitted'} />
              </div>
              <div className='col-md-3'>
                <label class="form-label">DQP</label>
                <input type="text" disabled={true} class="form-control" value={this.props.meterEquipInfo.createdBy ? (this.props.meterEquipInfo.createdBy.firstName + " " + this.props.meterEquipInfo.createdBy.lastName) : ""} />
              </div>
          </div>
        </div>}
        {!this.state.showReview &&<ul class="nav nav-tabs mb-3">
            <li class="nav-item ">
              <a class={`nav-link ${this.state.tab == 'MAIN'?'active':''}`} aria-current="page" href="#"
                  onClick={(e)=>{e.preventDefault(); this.setState({ tab : 'MAIN'}); }}
              >Record details</a>
            </li>
            <li class="nav-item">
              <a class={`nav-link ${this.state.tab == 'AUDIT'?'active':''}`} href="#"
                  onClick={(e)=>{e.preventDefault(); this.setState({ tab : 'AUDIT'}); }}
              >Audit/Logs</a>
            </li>
            {this.props.meterEquipInfo.formStatus == 2 && <DisplayForRoles roles={{superAdmin : true, isCFOAdmin:true}}>
              <li class="nav-item">
                  <a class={`nav-link ${this.state.tab == 'FILES'?'active':''}`} href="#"
                    onClick={(e)=>{e.preventDefault(); this.setState({ tab : 'FILES'}); }}
                  >Files</a>
              </li>
            </DisplayForRoles>}
            {this.props.meterEquipInfo.formStatus == 2 && <DisplayForRoles roles={{superAdmin : true}}>
              <li class="nav-item">
                  <a class={`nav-link ${this.state.tab == 'ADMIN'?'active':''}`} href="#"
                    onClick={(e)=>{e.preventDefault(); this.setState({ tab : 'ADMIN'}); }}
                  >Admin</a>
              </li>
            </DisplayForRoles>}
        </ul>}

        {this.state.tab == 'AUDIT' && <MeterEquipInfoAudit id={this.props.meterEquipInfo.id} /> }
                
        {this.state.tab == 'FILES' && <DisplayForRoles roles={{superAdmin : true, isCFOAdmin:true}}>
            <CertificateFiles 
              form={form} 
              certificateNameForSelector="meteringEquipmentInformation"
              fileTypeResolver={(document)=>{
                let uploadOptions = closedConduitandProtableOptions;
                if (parseInt(this.props.masterDataValues.OpenChannelId) == parseInt(this.props.meterEquipInfo.meteringSystemTypeId)) {
                  uploadOptions = openChannelOptions;
                }
                var option = uploadOptions.filter(option=>option.id==document.documentType)[0];
                return <>{option?option.name:""}</>
              }}
            />
          </DisplayForRoles> }

          {this.state.tab == 'ADMIN' && this.props.meterEquipInfo.formStatus == 2 && <DisplayForRoles roles={{superAdmin : true, isCFOAdmin:true}}>
            <CertificateAdmin id={this.props.meterEquipInfo.id} entityType={this.props.meterEquipInfo.meterFormType==1 ? 'ValidationCertificate' : 'MaintenanceValidationCertificate'} />
            </DisplayForRoles> }

          {this.state.tab == 'MAIN' && <>

            {needReApplyConsent && <ReApplyConsentNotice approvalNumber={this.props.approvedWorkDetails.waterSupplyWorkApprovalNo} />}

            {this.props.meterEquipInfo.formStatus == 1 && this.props.meterEquipInfo.createdBy && this.props.meterEquipInfo.createdBy.id != this.#currentUserId && <div className='alert alert-primary'>
              This form is owned by <strong>{this.props.meterEquipInfo.createdBy.firstName} {this.props.meterEquipInfo.createdBy.lastName}</strong> and cannot be edited
            </div>}
 
            {!this.state.showReview &&<div className='my-5'>      
              <StepProgressBar 
                totalSteps={7} 
                currentStep={confirmingSubmission? 6 : (page==3 || page==4) ? 4 : (page==6) ? 7 : (page>4)?page:page+1} 
                disableFutureSteps={this.props.meterEquipInfo.formStatus == 1} 
                stepClick={(page) =>{this.setState({page : (page==4)? (this.props.meterEquipInfo.newMeterEquipment == "2" ? 4 : 3) : page>6 ? page -2 : page-1, confirmingSubmission : page==6 })}}/>
            </div>}
            {!confirmingSubmission && page === 0 && <MeterValidationCertificateFormSelection disableFields={true} form={form} onSubmit={this.nextPage} disableEditing={(this.props.meterEquipInfo.createdBy && this.props.meterEquipInfo.createdBy.id != this.#currentUserId) || needReApplyConsent}/>}
            {!confirmingSubmission && page === 1 && <AuthorityHolderDetailsFormSection disableFields={true} form={form} onSubmit={this.nextPage} previousPage={this.previousPage} disableEditing={(this.props.meterEquipInfo.createdBy && this.props.meterEquipInfo.createdBy.id != this.#currentUserId) || needReApplyConsent} />}
            {!confirmingSubmission && page === 2 && <ApprovalDetailsFormSection disableFields={true} form={form} onSubmit={this.nextPage} previousPage={this.previousPage} disableEditing={(this.props.meterEquipInfo.createdBy && this.props.meterEquipInfo.createdBy.id != this.#currentUserId) || needReApplyConsent}  />}
            {!confirmingSubmission && page === 3 && <MeterValidationCertificatePartCForm disableFields={true}form={form} onSubmit={this.nextPage} previousPage={this.previousPage} disableEditing={(this.props.meterEquipInfo.createdBy && this.props.meterEquipInfo.createdBy.id != this.#currentUserId) || needReApplyConsent} />}
            {!confirmingSubmission && page === 4 && <MeterValidationCertificatePartDForm disableFields={true}form={form} onSubmit={this.nextPage} previousPage={this.previousPage} disableEditing={(this.props.meterEquipInfo.createdBy && this.props.meterEquipInfo.createdBy.id != this.#currentUserId) || needReApplyConsent}  />}
            {!confirmingSubmission && page === 5 && <MeterValidationCertificatePartEForm disableFields={true} form={form} onSubmit={this.nextPage} previousPage={this.nonCompliancePrevForm} disableEditing={(this.props.meterEquipInfo.createdBy && this.props.meterEquipInfo.createdBy.id != this.#currentUserId) || needReApplyConsent}  />}
            {confirmingSubmission &&  <MeterValidationCertificateConfirmation disableFields={true}form={form} onSubmit={this.nextPage} completeConfirmation={this.completeConfirmation} cancelConfrimSubmissionAndGoToPage={this.cancelConfrimSubmissionAndGoToPage} cancelConfrimSubmission={this.cancelConfrimSubmission} showReview={this.state.showReview} disableEditing={(this.props.meterEquipInfo.createdBy && this.props.meterEquipInfo.createdBy.id != this.#currentUserId) || needReApplyConsent} /> }        
            {page === 6 && <MeterValidationCertificatePartFForm disableFields={true}form={form} onSubmit={this.nextPage} previousPage={this.previousPage} disableEditing={(this.props.meterEquipInfo.createdBy && this.props.meterEquipInfo.createdBy.id != this.#currentUserId) || needReApplyConsent}  />}

            {this.props.meterEquipInfo.formStatus == 1 && <div className='alert alert-warning my-3'>You cannot use this form since the Meter Compliance Process form has replaced it. Please click <a href='/#/compliance-process/start'>here</a> to start a new registration.</div>}

          </>}
      </>}

      {/* start the delete draft dialog */}
      <div class="modal fade" id="deleteDraftModal" tabindex="-1" role="dialog" aria-labelledby="deleteDraftModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
        <div class="modal-dialog modal-dialog-centered" role="document" >
            <div class="modal-content">
              <div class="modal-header">
                  <button type="button" id="deleteDraftModalCloseBtn"  disabled={loading} class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <strong>Are you sure you want to delete this draft version of the form?</strong>
              </div>
              <div class="modal-footer">

                <button type="button" class="btn btn-secondary" disabled={loading} data-bs-dismiss="modal">Cancel</button>
              
                <button type="button" disabled={loading} class="btn btn-primary"
                  onClick={() => { this.deleteDraft(); }}
                >Confirm deletion</button>
                
              </div>
            </div>
        </div>
      </div>
      {/* end the delete draft dialog */}

      

      <div class="modal fade" id="checkListModal" tabindex="-1" role="dialog" aria-labelledby="checkListModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="checkListModalLabel">Validation and Maintenance Checklist</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <ValidationAndMaintenanceChecklistForm form={form} />
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>

    </>;
  }
}


MeterValidationCertificate = reduxForm({
  enableReinitialize: true,

})(MeterValidationCertificate)

MeterValidationCertificate = connect(
  (state, props) => ({

    initialValues: state.reducerMeterValidation,
    loaded: state.reducerMeterValidation.loaded,  
    meterEquipInfo: formValueSelector(props.form)(state, 'meteringEquipmentInformation'),
    approvedWorkDetails: formValueSelector(props.form)(state, 'approvedWorkDetails'),
    authorityHolderDetails: formValueSelector(props.form)(state, 'authorityHolderDetails'),
    masterDataValues: formValueSelector(props.form)(state, 'mdata'),
    loading: formValueSelector(props.form)(state, 'loading'),
    consentValid: formValueSelector(props.form)(state, 'consentValid'),
    form: props.form
  }),
  {
    load: (payload) => load(payload),
    save: (payload) => save(payload),
    findById: (id) => findById(id),
    findByIdCopy: (id) => findByIdCopy(id),
    update: (payload) => update(payload)           // bind account loading action creator
  }
)(MeterValidationCertificate)

export default MeterValidationCertificate;


