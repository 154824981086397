import React, { useRef } from 'react';
import { Field, reduxForm, formValueSelector, change, initialize } from 'redux-form';
import { connect } from 'react-redux'
import { checkSerialNumber } from './MeterValidationCertificate.Reducers'
import 'react-redux';
import 'redux';
import "react-datepicker/dist/react-datepicker.css";
import { METER_VALIDATION_TYPE } from '../../../constants/MeterFormConstants'
import { WebMapViewActualLocation } from '../../tools/DQP.Map';
import LotSelectForEsid from '../../common/LotSelectForEsid'
import { InputTextForRedux, SelectForRedux, ShowErrorForRedux, TextAreaForRedux, DatePickerForRedux, RadiosForRedux, CreatableSelectForRedux, CheckboxForRedux } from '../../tools/BootstrapControls'

let MeteringEquipmentInformationMain = props => {

    const { masterDataValues, meterEquipInfo, appWorkInfo, loading } = props

    const disableFields = (parseInt(meterEquipInfo.formStatus) == 2) || props.disableFields  || props.disableEditing || loading ? true : false;

    const childRef = useRef(null);
    let meterModelTypes = masterDataValues.meterModelTypes
    if (meterEquipInfo.formCategory && meterEquipInfo.formCategory == 'FPH') {
        console.log("FPH Use case detected");
        meterModelTypes = masterDataValues.meterModelTypesPointOfIntake;
    }

    const updateMapPoints = () => {
        childRef.current.updateActualMapPoints({ longitude: meterEquipInfo.longitude, latitude: meterEquipInfo.latitude });
    }

    const populatePatternApprovalNumber = (e) => {
        debugger;
        var dropdown = e;
        var metersystypes = meterModelTypes.filter(function (options) {
            return options.id === parseInt(e.target.value);

        });

        console.log(metersystypes);
        if (metersystypes.length > 0)
            props.dispatch(change(props.form, 'meteringEquipmentInformation.patternApprovalNumber', metersystypes[0].patternApprovalNumber));

    }

    const resetOnDataCollectorOptions = (e) => {
        if (e.target.value == 4) {
            props.dispatch(change(props.form, 'meteringEquipmentInformation.lidId', ""));
            props.dispatch(change(props.form, 'meteringEquipmentInformation.dataCollectorModelNo', ""));
            props.dispatch(change(props.form, 'meteringEquipmentInformation.dataCollectorSerialNo', ""));
            props.dispatch(change(props.form, 'meteringEquipmentInformation.lIDType', ""));
            props.dispatch(change(props.form, 'meteringEquipmentInformation.lIDMeterReading', ""));
            props.dispatch(change(props.form, 'meteringEquipmentInformation.scalingFactor', ""));
            props.dispatch(change(props.form, 'meteringEquipmentInformation.lIDTelemetry', ""));
            props.dispatch(change(props.form, 'meteringEquipmentInformation.meterFlowUnitsId', ""));
        }
    }

    const sealNumberVerifiedOptions =
        [
            { id: "1", name: "I have verified/updated the Tamper Evident Seal numbers", selected: false }
        ];

    const updatePoints = (x, y) => {

        props.dispatch(change(props.form, 'meteringEquipmentInformation.latitude', parseFloat(y).toFixed(7)));
        props.dispatch(change(props.form, 'meteringEquipmentInformation.longitude', parseFloat(x).toFixed(7)));
        //props.dispatch(change(props.form, 'workDetails.distance', distance.toFixed(2)));
        //props.dispatch(change(props.form,'workDetails.actualLotNo',lotdp));

    }

    const lotChangeHandler = (lot) => {
        props.dispatch(change(props.form, 'approvedWorkDetails.lotDp',
            (lot.lotNumber ? lot.lotNumber : "")
            + "/"
            + (lot.sectionNumber ? lot.sectionNumber : "")
            + "/"
            + (lot.planNumber ? lot.planNumber : "")
            + " "
            + (lot.planType ? lot.planType : "")
        ));
    }

    const logTextChangeHandler = (displayText) => {
        props.dispatch(change(props.form, 'approvedWorkDetails.lotDp', displayText));
    }


    const resetValues = (event) => {
        //parseInt(props.masterDataValues.OpenChannelId) == parseInt(props.meterEquipInfo.meteringSystemTypeId)

        if (parseInt(event.target.value) != parseInt(props.masterDataValues.OpenChannelId)) {
            props.dispatch(change(props.form, 'meteringEquipmentInformation.channelType', ""));
            props.dispatch(change(props.form, 'meteringEquipmentInformation.channelWidth', ""));
            props.dispatch(change(props.form, 'meteringEquipmentInformation.meterDistanceFromSource', ""));
            props.dispatch(change(props.form, 'meteringEquipmentInformation.channelLengthUpstream', ""));
            props.dispatch(change(props.form, 'meteringEquipmentInformation.channelLlengthDownstream', ""));
            props.dispatch(change(props.form, 'meteringEquipmentInformation.designEquipmentDetails', ""));
            props.dispatch(change(props.form, 'meteringEquipmentInformation.meteringEquipmentConsistent', ""));
            props.dispatch(change(props.form, 'meteringEquipmentInformation.maxMeasuredDischarge', ""));
            props.dispatch(change(props.form, 'meteringEquipmentInformation.minMeasuredDischarge', ""));
            props.dispatch(change(props.form, 'meteringEquipmentInformation.maxMeasuredVelocity', ""));
            props.dispatch(change(props.form, 'meteringEquipmentInformation.minMeasuredVelocity', ""));
            props.dispatch(change(props.form, 'meteringEquipmentInformation.maxGaugeHeight', ""));
            props.dispatch(change(props.form, 'meteringEquipmentInformation.minGaugeHeight', ""));
            props.dispatch(change(props.form, 'meteringEquipmentInformation.maxError', ""));
            props.dispatch(change(props.form, 'meteringEquipmentInformation.minError', ""));
            props.dispatch(change(props.form, 'meteringEquipmentInformation.noOfMeasurementsTaken', ""));
        }
        else {
            props.dispatch(change(props.form, 'meteringEquipmentInformation.pipeMaterialsId', ""));
            props.dispatch(change(props.form, 'meteringEquipmentInformation.pipeType', ""));
            props.dispatch(change(props.form, 'meteringEquipmentInformation.pipeConnectionType', ""));
            props.dispatch(change(props.form, 'meteringEquipmentInformation.pipeSize', ""));
            props.dispatch(change(props.form, 'meteringEquipmentInformation.pipeLengthUpstream', ""));
            props.dispatch(change(props.form, 'meteringEquipmentInformation.pipeLengthDownstream', ""));
        }
    }


    const lidTypeOptions =
        [
            { id: "1", name: "Select", selected: true },
            { id: "2", name: "Pulse", selected: false },
            { id: "3", name: "Modbus", selected: false }
        ];

    const lIDTelemetryOptions =
        [
            { id: "1", name: "YES", selected: false },
            { id: "2", name: "NO", selected: false },
        ];

    const meteringEquipmentConsistentOptions =
        [
            { id: "1", name: "Yes", selected: false },
            { id: "2", name: "No", selected: false }
        ];

    const dateToday = new Date();

    if (meterEquipInfo.dateOfLastValidation != null && meterEquipInfo.dateOfLastValidation != "")
        meterEquipInfo.dateOfLastValidation = new Date(meterEquipInfo.dateOfLastValidation);
    else {
        meterEquipInfo.dateOfLastValidation = dateToday;
        meterEquipInfo.dateOfLastValidation = new Date(meterEquipInfo.dateOfLastValidation);
    }

    if (meterEquipInfo.dateValidation != null && meterEquipInfo.dateValidation == "")
        meterEquipInfo.dateValidation = dateToday;

    meterEquipInfo.dateValidation = new Date(meterEquipInfo.dateValidation)

    if (meterEquipInfo.meterReadingDate != null && meterEquipInfo.meterReadingDate != "")
        meterEquipInfo.meterReadingDate = new Date(meterEquipInfo.meterReadingDate);

    if (meterEquipInfo.finalReadingDateRemovedMeter != null && meterEquipInfo.finalReadingDateRemovedMeter != "")
        meterEquipInfo.finalReadingDateRemovedMeter = new Date(meterEquipInfo.finalReadingDateRemovedMeter);


    if (meterModelTypes.length > 0)
        props.dispatch(change(props.form, 'meterModelTypeList', meterModelTypes));


    return <>
        <div className='row mb-3'>
            <div className='col-sm-3'>
                <Field
                    name="meteringEquipmentInformation.meteringSystemTypeId"
                    component={RadiosForRedux}
                    label={<>Meter system type <span className='text-danger'>* </span></>}
                    options={masterDataValues.meteringSystemTypes}
                    values={meterEquipInfo.meteringSystemTypeId}
                    props={{ disabled: disableFields }}
                    handleChange={resetValues}
                />
            </div>
        </div>
        {(parseInt(props.meterEquipInfo.meteringSystemTypeId) != 0) && <>

            <div className='row mb-3'>
                <div className='col-sm-6'>
                    <Field
                        name="meteringEquipmentInformation.meterTypeModelId"
                        component={SelectForRedux}
                        options={meterModelTypes}
                        label={<>Meter manufacturer &amp; model <span className='text-danger'>*</span></>}
                        props={{ disabled: disableFields }}
                        onChange={populatePatternApprovalNumber}
                    />
                </div>
                {(parseInt(masterDataValues.MeterModelTypeOtherId) === parseInt(meterEquipInfo.meterTypeModelId)) &&
                    <div className='col-sm-6'>
                        <Field
                            name="meteringEquipmentInformation.meterTypeModel"
                            component={InputTextForRedux}
                            label={<>Other meter manufacturer &amp; model <span className='text-danger'>*</span></>}
                            props={{ disabled: disableFields }}
                        />
                    </div>
                }
            </div>

            <div className='row mb-3'>
                <div className='col-sm-6'>
                    <Field
                        name="meteringEquipmentInformation.meterSerialNo"
                        label={<>Meter serial number <span className='text-danger'>*</span></>}
                        props={{ disabled: disableFields, mandatory: true }}
                        component={InputTextForRedux}
                        onBlur={() => props.dispatch(checkSerialNumber(props.meterEquipInfo.id, props.meterEquipInfo.meterSerialNo))}
                    />

                </div>
                <div className='col-sm-6'>
                    <Field
                        name="meteringEquipmentInformation.meterSize"
                        label={<>Meter size (mm) <span className='text-danger'>*</span></>}
                        props={{ disabled: disableFields }}
                        component={InputTextForRedux}
                    />
                </div>
            </div>

            <div className='row mb-3'>
                <div className='col-sm-6'>
                    <Field
                        name="meteringEquipmentInformation.meterUnitOfMeasuresId"
                        props={{ disabled: disableFields }}
                        label={<>Unit of measure as displayed on meter <span className='text-danger'>*</span></>}
                        component={SelectForRedux}
                        options={masterDataValues.meterUnitOfMeasures}
                    />
                </div>
                <div className='col-sm-6'>
                    <Field
                        name="meteringEquipmentInformation.meterReading"
                        label={<>Meter reading at the date of this validation <span className='text-danger'>*</span></>}
                        props={{ disabled: disableFields }}
                        component={InputTextForRedux}
                    />
                </div>
            </div>
            <div className='row mb-3'>
                <div className='col-sm-6'>
                    <label for="sealnumbers" className='form-label'>Record the tamper-evident seal numbers <span className='text-danger'>* </span></label>
                    <Field
                        name='meteringEquipmentInformation.sealNo'
                        component={CreatableSelectForRedux}
                        placeholder="Type Seal Nos and press enter (or click +) to enter multiple entries"
                        props={{ disabled: disableFields, mandatory: true }}
                        options={meterEquipInfo.sealNo}
                    />
                </div>
            </div>
            {meterEquipInfo.maintenanceValidation == 1 && <div className='row mb-3'>
                <div className='col-sm-6'>
                    <Field
                        name="meteringEquipmentInformation.sealNumbersVerified"
                        component={CheckboxForRedux}
                        options={sealNumberVerifiedOptions}
                        props={{ disabled: disableFields }}
                    />
                </div>
            </div>}

            <div className='row mb-3'>
                <div className='col-sm-6'>
                    <Field
                        name="meteringEquipmentInformation.patternApprovalNumber"
                        label="Pattern Approval Number (if applicable)"
                        props={{ disabled: disableFields }}
                        component={InputTextForRedux}
                    />
                </div>
            </div>

            {(meterEquipInfo.meteringSystemTypeId != 2 && <>

                <div className='row mb-3'>
                    <div className='col-sm-6'>
                        <Field
                            name="meteringEquipmentInformation.pipeMaterialsId"
                            label={<>Pipe Materials</>}
                            component={SelectForRedux}
                            options={masterDataValues.pipeMaterials}
                            props={{ disabled: disableFields }}
                        />
                    </div>
                    {(parseInt(meterEquipInfo.pipeMaterialsId) === 21) && <div className='col-sm-6'>
                        <Field
                            name="meteringEquipmentInformation.pipeType"
                            props={{ disabled: disableFields }}
                            label="Other Pipe Material"
                            component={InputTextForRedux}
                        />
                    </div>}
                </div>
                <div className='row mb-3'>
                    <div className='col-sm-6'>
                        <Field
                            name="meteringEquipmentInformation.pipeSize"
                            props={{ disabled: disableFields }}
                            label={<>Pipe Size (mm) ID (Internal Diameter) <span className='text-danger'>*</span></>}
                            component={InputTextForRedux}
                        />
                    </div>
                </div>
                <div className='row mb-3'>
                    <div className='col-sm-6'>
                        <Field
                            name="meteringEquipmentInformation.pipeLengthUpstream"
                            props={{ disabled: disableFields }}
                            label="Pipe length upstream of meter (mm)"
                            component={InputTextForRedux}
                        />
                    </div>
                    <div className='col-sm-6'>
                        <Field
                            name="meteringEquipmentInformation.pipeLengthDownstream"
                            props={{ disabled: disableFields }}
                            label="Pipe length downstream of meter (mm)"
                            component={InputTextForRedux}
                        />
                    </div>
                </div>
            </>)}

            <div className='row mb-3'>
                <div className='col-sm-6'>
                    <Field
                        name="meteringEquipmentInformation.meterq1FlowRateMinimum"
                        props={{ disabled: disableFields }}
                        label={<>Meter Q1 flow rate minimum <span className='text-danger'>*</span></>}
                        component={InputTextForRedux}
                    />
                </div>
                <div className='col-sm-6'>
                    <Field
                        name="meteringEquipmentInformation.meterq3FlowRateOptimum"
                        props={{ disabled: disableFields }}
                        label={<>Meter Q3 flow rate optimum <span className='text-danger'>*</span></>}
                        component={InputTextForRedux}
                    />
                </div>
            </div>
            <div className='row mb-3'>
                <div className='col-sm-6'>
                    <Field
                        name="meteringEquipmentInformation.designFlowRate"
                        props={{ disabled: disableFields }}
                        label={<>Design flow rate <span className='text-danger'>*</span></>}
                        component={InputTextForRedux}
                    />
                </div>
                <div className='col-sm-6'>
                    <Field
                        name="meteringEquipmentInformation.softwareVersion"
                        props={{ disabled: disableFields }}
                        label="Software version"
                        component={InputTextForRedux}
                    />
                </div>
            </div>

            <div className='row mb-4'>
                <div className='col-sm-4'>
                    <label className='form-label'>Meter location <span className='text-danger'>*</span></label>

                    <Field
                        name="meteringEquipmentInformation.longitude"
                        component={InputTextForRedux}
                        onBlur={updateMapPoints}
                        props={{ disabled: disableFields, placeholder: 'longitude', className: 'mb-3' }}
                    />

                    <Field
                        name="meteringEquipmentInformation.latitude"
                        component={InputTextForRedux}
                        onBlur={updateMapPoints}
                        props={{ disabled: disableFields, placeholder: 'latitude' }}
                    />
                </div>
            </div>

            <div className='row mb-3'>
                <div className='col-sm-6'>
                    {!disableFields && <>
                        <label className='form-label'>Lot DP <span className='text-danger'>* </span></label>
                        <LotSelectForEsid
                            approvalNumber={appWorkInfo.waterSupplyWorkApprovalNo}
                            esid={appWorkInfo.esid}
                            onLotChange={lotChangeHandler}
                            onChangeOfDisplayText={logTextChangeHandler}
                            displayText={appWorkInfo.lotDp}
                        />
                        <Field name='approvedWorkDetails.lotDp' component={ShowErrorForRedux} />
                    </>}
                    {disableFields && <Field
                        name="approvedWorkDetails.lotDp"
                        label={<>Lot DP <span className='text-danger'>* </span></>}
                        placeholder=""
                        autocomplete="off"
                        component={InputTextForRedux}
                        props={{ disabled: true, autocomplete: "off" }}
                    />}
                </div>
            </div>

            <p>Drag and drop pin to correct GPS location</p>

            <div class="mb-3" id="map" style={{ minHeight: "360px" }}>
                <WebMapViewActualLocation
                    form="Meter Validation"
                    formStatus={meterEquipInfo.formStatus}
                    updatePoints={updatePoints}
                    ref={childRef}
                    actualPoint={{ latitude: meterEquipInfo.latitude, longitude: meterEquipInfo.longitude }}
                />
            </div>
            <div className='mb-3'>
                <Field
                    name="meteringEquipmentInformation.meterDescription"
                    props={{ disabled: disableFields }}
                    rows={10}
                    cols={40}
                    label={<>Meter Description (Please provide any further details or description of the meter) <span className='text-danger'>*</span></>}
                    component={TextAreaForRedux}
                />
            </div>

            <div className='row mb-3'>
                <div className='col-sm-6'>
                    <Field
                        name="meteringEquipmentInformation.supplyTypeId"
                        component={SelectForRedux}
                        options={masterDataValues.supplyTypes}
                        label="Type of supply"
                        props={{ disabled: disableFields }}
                    />
                </div>
            </div>

            {(meterEquipInfo.newMeterEquipment == METER_VALIDATION_TYPE.REPLACEMENT) && <>
                <h5 className='mb-3 mt-4'>Old Meter Information</h5>
                <div className='row mb-3'>
                    <div className='col-sm-6'>
                        <Field
                            name="meteringEquipmentInformation.previousMeterManufactureModel"
                            label={<>Old Meter manufacturer &amp; model <span className='text-danger'>*</span></>}
                            props={{ disabled: disableFields }}
                            component={InputTextForRedux}
                        />
                    </div>
                    <div className='col-sm-6'>
                        <Field
                            name="meteringEquipmentInformation.previousMeterSerialNumber"
                            label="Old Meter serial number (if available)"
                            props={{ disabled: disableFields }}
                            component={InputTextForRedux}
                        />
                    </div>
                </div>
                <div className='row mb-3'>
                    <div className='col-sm-6'>
                        <Field
                            name="meteringEquipmentInformation.previousMeterSize"
                            label={<>Old Meter Size (mm) <span className='text-danger'>*</span></>}
                            props={{ disabled: disableFields }}
                            component={InputTextForRedux}
                        />
                    </div>
                    <div className='col-sm-6'>
                        <Field
                            name="meteringEquipmentInformation.finalReadingRemovedMeter"
                            props={{ disabled: disableFields }}
                            label={<>Meter reading of old meter being removed <span className='text-danger'>*</span></>}
                            component={InputTextForRedux}
                        />
                    </div>
                </div>
                <div className='row mb-3'>
                    <div className='col-sm-4'>
                        <Field
                            name="meteringEquipmentInformation.finalReadingDateRemovedMeter"
                            label={<>Date removed <span className='text-danger'>*</span></>}
                            handleChange={date => { props.change(props.form, 'meteringEquipmentInformation.finalReadingDateRemovedMeter', date) }}
                            component={DatePickerForRedux}
                            props={{ disabled: disableFields, maxDate: new Date() }}
                            values={meterEquipInfo.finalReadingDateRemovedMeter}
                        />
                    </div>
                </div>
            </>}

            <h5 className='mb-3 mt-4'>LID Information</h5>
            <div className='row mb-3'>
                <div className='col-sm-4'>
                    {(meterEquipInfo.formStatus == 2 && meterEquipInfo.dataCollectorTypeId == '1') &&
                        <Field
                            name="meteringEquipmentInformation.dataCollectorTypeId"
                            component={SelectForRedux}
                            options={masterDataValues.dataCollectorTypes}
                            props={{ disabled: disableFields }}
                            label={<>Data collector type <span className='text-danger'>*</span></>}
                            onChange={resetOnDataCollectorOptions}
                        />
                    }
                    {
                        (meterEquipInfo.formStatus == 1 && (meterEquipInfo.dataCollectorTypeId == '1' || meterEquipInfo.dataCollectorTypeId != '1')) &&
                        <Field
                            name="meteringEquipmentInformation.dataCollectorTypeId"
                            component={SelectForRedux}
                            options={masterDataValues.dataCollectorTypes.filter(function (option) { return option.isActive == true })}
                            props={{ disabled: disableFields }}
                            label={<>Data collector type <span className='text-danger'>*</span></>}
                            onChange={resetOnDataCollectorOptions}
                        />
                    }
                    {
                        (meterEquipInfo.formStatus == 2 && meterEquipInfo.dataCollectorTypeId != '1') &&
                        <Field
                            name="meteringEquipmentInformation.dataCollectorTypeId"
                            component={SelectForRedux}
                            options={masterDataValues.dataCollectorTypes.filter(function (option) { return option.isActive == true })}
                            props={{ disabled: disableFields }}
                            label={<>Data collector type <span className='text-danger'>*</span></>}
                            onChange={resetOnDataCollectorOptions}
                        />
                    }
                </div>
            </div>

            {(meterEquipInfo.dataCollectorTypeId != 4 && meterEquipInfo.dataCollectorTypeId != '4') && <>
                <div className='row mb-3'>
                    <div className='col-sm-6'>
                        <Field
                            name="meteringEquipmentInformation.lidId"
                            props={{ disabled: disableFields }}
                            label={<>LID Device <span className='text-danger'>*</span></>}
                            component={SelectForRedux}
                            propForDisplay="displayText"
                            options={masterDataValues.lids}
                        />
                    </div>
                    <div className='col-sm-6'>
                        <Field name="meteringEquipmentInformation.dataCollectorModelNo"
                            props={{ disabled: disableFields }}
                            label={<>LID Model number <span className='text-danger'>*</span></>}
                            component={InputTextForRedux}
                        />
                    </div>
                </div>

                <div className='row mb-3'>
                    <div className='col-sm-6'>
                        <Field name="meteringEquipmentInformation.dataCollectorSerialNo"
                            props={{ disabled: disableFields }}
                            label={<>LID Serial number <span className='text-danger'>*</span></>}
                            component={InputTextForRedux}
                        />
                    </div>
                    <div className='col-sm-6'>
                        <Field
                            name="meteringEquipmentInformation.lIDType"
                            component={SelectForRedux}
                            options={lidTypeOptions}
                            values={meterEquipInfo.lIDType}
                            props={{ disabled: disableFields }}
                            label={<>LID Type <span className='text-danger'>*</span></>}
                        />
                    </div>
                </div>
                <div className='row mb-3'>
                    <div className='col-sm-4'>
                        <Field
                            name="meteringEquipmentInformation.lIDMeterReading"
                            props={{ disabled: disableFields }}
                            label={<>Meter reading when LID was installed, as displayed on the meter. (This reading must be correct to avoid revisiting the site) <span className='text-danger'>*</span></>}
                            component={InputTextForRedux}
                        />
                    </div>
                </div>
                <div className='row mb-3'>
                    <div className='col-sm-4'>
                        <Field
                            name="meteringEquipmentInformation.scalingFactor"
                            props={{ disabled: disableFields }}
                            label={<>Scaling factor – (Refer to manufacturer) <span className='text-danger'>*</span></>}
                            component={InputTextForRedux}
                        />
                    </div>
                </div>
                <div className='row mb-3'>
                    <div className='col-sm-4'>
                        <Field
                            name="meteringEquipmentInformation.lIDTelemetry"
                            component={RadiosForRedux}
                            options={lIDTelemetryOptions}
                            values={meterEquipInfo.lIDTelemetry}
                            props={{ disabled: disableFields }}
                            label={<>Is the LID being used for Telemetry? <span className='text-danger'>*</span></>}
                        />
                    </div>
                </div>
                <div className='row mb-3'>
                    <div className='col-sm-6'>
                        <Field
                            name="meteringEquipmentInformation.meterFlowUnitsId"
                            props={{ disabled: disableFields, mandatory: true }}
                            label={<>Unit of measure transmitted by LID <span className='text-danger'>*</span></>}
                            component={SelectForRedux}
                            options={masterDataValues.meterFlowUnits}
                        />
                    </div>
                </div>
            </>}

            {!(props.masterDataValues.OpenChannelId != props.meterEquipInfo.meteringSystemTypeId) && <>
                <div className='row mb-3'>
                    <div className='col-sm-6'>
                        <Field
                            name="meteringEquipmentInformation.channelType"
                            props={{ disabled: disableFields }}
                            label={<>Channel type <span className='text-danger'>*</span></>}
                            component={InputTextForRedux}
                        />
                    </div>
                </div>
                <div className='row mb-3'>
                    <div className='col-sm-6'>
                        <Field
                            name="meteringEquipmentInformation.channelWidth"
                            props={{ disabled: disableFields }}
                            label={<>Channel width (m) <span className='text-danger'>*</span></>}
                            component={InputTextForRedux}
                        />
                    </div>
                    <div className='col-sm-6'>
                        <Field
                            name="meteringEquipmentInformation.meterDistanceFromSource"
                            props={{ disabled: disableFields }}
                            label={<>Distance of the meter from pumping source or filling point (m) <span className='text-danger'>*</span></>}
                            component={InputTextForRedux}
                        />
                    </div>
                </div>
                <div className='row mb-3'>
                    <div className='col-sm-6'>
                        <Field
                            name="meteringEquipmentInformation.channelLengthUpstream"
                            props={{ disabled: disableFields }}
                            label={<>Channel length upstream of meter (m) <span className='text-danger'>*</span></>}
                            component={InputTextForRedux}
                        />
                    </div>
                    <div className='col-sm-6'>
                        <Field
                            name="meteringEquipmentInformation.channelLlengthDownstream"
                            props={{ disabled: disableFields }}
                            label={<>Channel length downstream of meter (m) <span className='text-danger'>*</span></>}
                            component={InputTextForRedux}
                        />
                    </div>
                </div>

                <h5 className='mb-3 mt-4'>Design</h5>
                <div className='mb-3'>
                    <Field
                        name="meteringEquipmentInformation.designEquipmentDetails"
                        props={{ disabled: disableFields }}
                        label="Provide the details of the design equipment"
                        component={TextAreaForRedux}
                        cols={40}
                        rows={10}
                    />
                </div>
                <div className='mb-3'>
                    <Field
                        name={"meteringEquipmentInformation.meteringEquipmentConsistent"}
                        props={{ disabled: disableFields, mandatory: true }}
                        component={RadiosForRedux}
                        className="mb-3"
                        options={meteringEquipmentConsistentOptions}
                        values={meterEquipInfo.meteringEquipmentConsistent}
                        label={<>The metering equipment that has been installed is consistent with the design certificate <span className='text-danger'>*</span></>}
                    />
                    <i>
                        Metering equipment installed in connection with an open channel on or after 1 April 2019 must be of a design that is certified by a duly qualified person as complying with the requirements of AS 4747.
                    </i>
                </div>

                <h5 className='mb-3 mt-4'>Calibration Measurement Details</h5>
                <div className='row mb-3'>
                    <div className='col-sm-6'>
                        <Field
                            name="meteringEquipmentInformation.maxMeasuredDischarge"
                            label="Maximum measured discharge (m³/s)"
                            props={{ disabled: disableFields }}
                            component={InputTextForRedux}
                        />
                    </div>
                    <div className='col-sm-6'>
                        <Field
                            name="meteringEquipmentInformation.minMeasuredDischarge"
                            label="Minimum measured discharge (m³/s)"
                            props={{ disabled: disableFields }}
                            component={InputTextForRedux}
                        />
                    </div>
                </div>
                <div className='row mb-3'>
                    <div className='col-sm-6'>
                        <Field
                            name="meteringEquipmentInformation.maxMeasuredVelocity"
                            label="Maximum measured velocity (m/s)"
                            props={{ disabled: disableFields }}
                            component={InputTextForRedux}
                        />
                    </div>
                    <div className='col-sm-6'>
                        <Field
                            name="meteringEquipmentInformation.minMeasuredVelocity"
                            label="Minimum measured velocity (m/s)"
                            props={{ disabled: disableFields }}
                            component={InputTextForRedux}
                        />
                    </div>
                </div>
                <div className='row mb-3'>
                    <div className='col-sm-6'>
                        <Field name="meteringEquipmentInformation.maxGaugeHeight"
                            label="Maximum gauge height (m)"
                            props={{ disabled: disableFields }}
                            component={InputTextForRedux}
                        />
                    </div>
                    <div className='col-sm-6'>
                        <Field
                            name="meteringEquipmentInformation.minGaugeHeight"
                            label="Minimum gauge height (m)"
                            props={{ disabled: disableFields }}
                            component={InputTextForRedux}
                        />
                    </div>
                </div>
                <div className='row mb-3'>
                    <div className='col-sm-6'>
                        <Field
                            name="meteringEquipmentInformation.maxError"
                            label="Maximum error (%)"
                            props={{ disabled: disableFields }}
                            component={InputTextForRedux}
                        />
                    </div>
                    <div className='col-sm-6'>
                        <Field
                            name="meteringEquipmentInformation.minError"
                            label="Minimum error (%)"
                            props={{ disabled: disableFields }}
                            component={InputTextForRedux}
                        />
                    </div>
                </div>
                <div className='row mb-3'>
                    <div className='col-sm-6'>
                        <Field
                            name="meteringEquipmentInformation.noOfMeasurementsTaken"
                            label="Number of measurements undertaken for index equation (if applicable)"
                            props={{ disabled: disableFields }}
                            component={InputTextForRedux}
                        />
                    </div>
                </div>
            </>}

            <h5 className='mb-3 mt-4'>Method used to perform in situ volumetric measurements</h5>
            <div className='mb-3'>
                <Field
                    name="meteringEquipmentInformation.methodUsed"
                    props={{ disabled: disableFields }}
                    component={TextAreaForRedux}
                    cols={40}
                    rows={10}
                    label="Provide the details on the method used and why it was selected. Reference AS 4747, ISO and NMI"
                />
            </div>

            <h5 className='mb-2 mt-4'>Conclusion</h5>
            <Field
                label={<>
                    In this conclusion:
                    <ul>
                        <li>
                            State the metering point variances and indicate whether these are within the maximum permissible error margin of +/-5% set by AS 4747
                        </li>
                        <li>
                            Provide a detailed description of the site installation, which includes the approach channel, channel reach downstream, list any vegetation impacts, detail how the sensor is mounted and list any structures which may affect the flow regime
                        </li>
                        <li>
                            Provide any advice that assists with maintaining the channel system so that calibration can be maintained.
                        </li>
                    </ul>
                </>}
                name="meteringEquipmentInformation.openChannelConclusion"
                component={TextAreaForRedux}
                props={{ disabled: disableFields }}
                rows={10}
                cols={40}
            />

        </>}
    </>

}

MeteringEquipmentInformationMain = reduxForm({
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true
})(MeteringEquipmentInformationMain)

MeteringEquipmentInformationMain = connect(
    (state, props) => {
        // can select values individually
        const selector = formValueSelector(props.form)
        const masterDataValues = selector(state, 'mdata')
        const loading = selector(state, 'loading')
        const meterEquipInfo = selector(state, 'meteringEquipmentInformation')
        const documents = selector(state, 'documents')
        const meterModelTypeList = selector(state, 'meterModelTypeList');
        const appWorkInfo = selector(state, 'approvedWorkDetails');
        const form = props.form
        return {
            masterDataValues,
            meterEquipInfo,
            documents,
            form,
            meterModelTypeList,
            appWorkInfo,
            loading
        }
    },
    {
        checkSerialNumber: (serialNumber) => checkSerialNumber(serialNumber)
    }
)(MeteringEquipmentInformationMain)

export default MeteringEquipmentInformationMain