import React from 'react';
import {reduxForm, formValueSelector, change} from 'redux-form';
import { connect } from 'react-redux'
import 'react-redux';
import 'redux';

import { formatDate } from '../../../constants/MeterFormConstants'
import { saveDraftAction } from "../common/MeterFormValidations"
import {dropdownDataFromReferenceData} from '../common/MeterCertificateCommon';

let CertificateConfirmation = props => {
    
    const {
        updateRatingTableData,        
        authHolderInfo,        
        floodplainWorkDetails,
        masterDataValues

        , cancelConfrimSubmissionAndGoToPage
        , cancelConfrimSubmission

        , completeConfirmation

        , loading
        , handleSubmit
    } = props;

    var dqpdata = masterDataValues.userProfile[0];
    console.log("dqp"+JSON.stringify(dqpdata));
    dqpdata = {...dqpdata, "fullName": dqpdata.user.firstName + ' ' + dqpdata.user.lastName};
    props.dispatch(change(props.form, 'dqpdata', dqpdata));

    let certOptions = dropdownDataFromReferenceData(masterDataValues.refData,"updateReason");
   console.log("certOptions "+ JSON.stringify(certOptions));

  return (
    <form className="vertical" onSubmit={handleSubmit}>
        <h5 className='mb-3'>Review</h5>
        <div class="alert alert-primary" role="alert">
            Please review the information below before completing your submission.
            <br/>           
            The form cannot be edited once submitted. Therefore, please confirm all configuration details are correct before submitting this form.
        </div>       

        <div className='card mb-3'>
            <div className='card-header'><strong>Registered surveyor’s/engineer’s details</strong></div>
            <div className='card-body'>
                <table class="table fs-90 mb-3">
                    <tbody>
                        <tr>
                            <th className='w-25'>
                            Registered surveyors’/engineer’s name
                            </th>
                            <td>{dqpdata.fullName}</td>
                        </tr>
                        <tr>
                            <th>
                        Registered surveyor's/engineer’s licence number
                            </th>
                            <td>{dqpdata.cmino}</td>
                        </tr>
                        <tr>
                            <th>
                            Business name
                            </th>
                            <td>{dqpdata.businessName}</td>
                        </tr>
                        
                        <tr>
                            <th>
                            ABN or ACN
                            </th>
                            <td>{dqpdata.abnOrAcn}</td>
                        </tr>
                        <tr>
                            <th>
                            Postal address
                            </th>
                            <td>{dqpdata.postalAddress.addressLine}</td>
                        </tr>
                        <tr>
                            <th>
                            Phone
                            </th>
                            <td>{dqpdata.phoneNumber}</td>
                        </tr>
                        <tr>
                            <th>
                            Email address
                            </th>
                            <td>{dqpdata.user.emailAddress}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div> 

        <div className='card mb-3'>
            <div className='card-header'><strong>Details of Approved Works</strong></div>
            <div className='card-body'>
                <table class="table fs-90 mb-3">
                    <tbody>
                        <tr>
                            <th className='w-25'>
                            Authority holder type
                            </th>
                            <td>{authHolderInfo.holderType}</td>
                        </tr>
                        {
                            authHolderInfo.holderType == "INDIVIDUAL" && 
                            <tr>
                                <th>
                                Authority holder firstname
                                </th>
                                <td>{authHolderInfo.firstName}</td>
                            </tr>
                        }
                        {
                            authHolderInfo.holderType == "INDIVIDUAL" && 
                            <tr>
                                <th>
                                Authority holder lastname
                                </th>
                                <td>{authHolderInfo.lastName}</td>
                            </tr>
                        }
                        {
                            authHolderInfo.holderType == "ORGANISATION" && 
                            <tr>
                                <th>
                                Organisation name
                                </th>
                                <td>{authHolderInfo.corporationNameOrAcn}</td>
                            </tr>
                        }
                        <tr>
                            <th>
                            Postal address
                            </th>
                            <td>{authHolderInfo.postalAddress}</td>
                        </tr>
                        <tr>
                            <th>
                            Phone
                            </th>
                            <td>{authHolderInfo.phone}</td>
                        </tr>
                        <tr>
                            <th>
                            Alternate phone
                            </th>
                            <td>{authHolderInfo.alternatePhone}</td>
                        </tr>
                        <tr>
                            <th>
                            Email address
                            </th>
                            <td>{authHolderInfo.emailAddress}</td>
                        </tr>
                        <tr>
                            <th>
                            Property name
                            </th>
                            <td>{floodplainWorkDetails.propertyName}</td>
                        </tr>
                        <tr>
                            <th>
                            Lot DP
                            </th>
                            <td>{floodplainWorkDetails.lotDp}</td>
                        </tr>
                    
                        {
                            (floodplainWorkDetails.waterAccessLicenceNo)  &&
                            <tr>
                                <th>
                                Water access license number(s)
                                </th>
                                <td>{floodplainWorkDetails.waterAccessLicenceNo}</td>
                            </tr>
                        }
                        {
                            (floodplainWorkDetails.ratingTable)  &&
                            <tr>
                                <th>
                                Storage identifying number
                                </th>
                                <td>{floodplainWorkDetails.ratingTable.ratingTableName}</td>
                            </tr>
                        }   
                    </tbody>
                </table>
            </div>
            <div className='card-footer'>
                <button type="button" className="btn btn-primary" 
                    onClick={() => { cancelConfrimSubmissionAndGoToPage(1); }}>
                        Edit
                </button>
            </div>
        </div>

        <div className='card mb-3'>
            <div className='card-header'><strong>Update Details</strong></div>
            <div className='card-body'>
                <table class="table fs-90 mb-3">
                    <tbody>
                        <tr>
                            <th className='w-25'>
                            Reason for update
                            </th>
                            <td>
                                {certOptions.map((st) => {
                                    debugger;
                                    if(st.id == updateRatingTableData.updateReason) {
                                        return st.name;
                                    }
                                })}
                            </td>
                        </tr>   
                        <tr>
                            <th>Description of reason</th>
                            <td>{updateRatingTableData.updateDescription}</td>
                        </tr>     

                        <tr>
                            <th>Type of Survey used to capture rating table data</th>
                            <td>{updateRatingTableData.surveyType}</td>
                        </tr>             
                                             
                        <tr>
                            <th>Date of survey</th>                                
                            <td>{formatDate(updateRatingTableData.surveyDate)}</td>
                        </tr>
                    </tbody>    
                </table>
            </div>
            <div className='card-footer'>
                <button type="button" className="btn btn-primary" 
                    onClick={() => { cancelConfrimSubmissionAndGoToPage(3); }}>
                        Edit
                </button>
            </div>
        </div>
        
        <div class="text-end">
            
            {(parseInt(updateRatingTableData.formStatus) == 1)  && (!props.disableEditing) && <>
                {updateRatingTableData.id!=0 &&  <button type="button"  className="btn btn-danger me-3" disabled={loading} 
                data-bs-toggle="modal" data-bs-target="#deleteDraftModal">
                    Delete Draft
                </button>}
            
                <button type="button"  className="btn btn-primary" disabled={loading} 
                    onClick={() => { 
                        saveDraftAction(props); 
                    }}>
                    Save Draft
                </button>
                <div className='vr mx-3'></div>
            </>}
            <button type="button" className="btn btn-warning me-3" 
               onClick={cancelConfrimSubmission}>
                  Previous
            </button>

            <button type="button" className="btn btn-success"
               onClick={completeConfirmation}>
                   {(parseInt(updateRatingTableData.formStatus) == 1) ? "Confirm" : "Next"}
            </button>
        </div>       
</form>    
  )
}

CertificateConfirmation = reduxForm({
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate: ()=>{return {}}
 })(CertificateConfirmation)

CertificateConfirmation = connect(
  (state, props) => {
    const selector = formValueSelector(props.form);
    const updateRatingTableData = selector(state, 'updateRatingTableData')
    const authHolderInfo = selector(state, 'authorityHolderDetails');
    const dqpdata = selector(state, 'dqpdata');
    const floodplainWorkDetails = selector(state, 'floodplainWorkDetails');
    const appWorkInfo = selector(state, 'approvedWorkDetails');
    const documents = selector(state, 'documents')
    const meterNonComplianceReason = selector(state, 'meterNonComplianceReason')
    const meterNonCompliance = selector(state, 'meterNonCompliance')
    const registrationDoc = selector(state, 'registrationDoc')
    const userProfileValues = selector(state, 'mdata.userProfile')
    const masterDataValues = selector(state, 'mdata')

    const consentData = selector(state, 'consentData')
    const loading = selector(state, 'loading')

    const form = props.form;
    
    return {
        updateRatingTableData,
        authHolderInfo,
        dqpdata,
        floodplainWorkDetails,        
        appWorkInfo,
        masterDataValues,
        documents,
        meterNonComplianceReason,
        meterNonCompliance,
        registrationDoc,
        userProfileValues,
        consentData,
        form,
        loading
    }
  }
)(CertificateConfirmation)

export default CertificateConfirmation;

