import React, { Fragment } from 'react';
import Authentication from '../../helpers/Authentication'
import RatingTableSeacherLauncher from '../../components/ratingTables/RatingTableSeacherLauncher'
import axios from 'axios';
import moment from 'moment';
import $ from 'jquery';
import uiHelpers from '../../helpers/uiHelpers';

const authentication = new Authentication();


class StorageLTSReportComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedRatingTable : null,
            trackingStatusRequesting : false,
            trackingStatusRequested: false,
            trackingStatusRecords : [],
            displayTrackingStatusRecords : [],
            meterViewOption : 'SUMMARY',
            commentsForm : {
                value : '',
                error : null
            },

            commentsSubmitting : false,

            pdfDownloading : false

        }
    }

    ajaxConfig = {
        baseURL: process.env.REACT_APP_WEBAPI_URL,
        headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authentication.getToken() }
    };

    commentsChange =  (event) => {
        this.setState(Object.assign(this.state.commentsForm,{value : event.target.value} ));
    }

    submitCommentsForm = (event) => {
        if($.trim(this.state.commentsForm.value) == ''){
            this.setState(Object.assign(this.state.commentsForm,{error : 'Please provide a comment'}));
            return;
        } else {
            this.setState(Object.assign(this.state.commentsForm,{error : null}));
        }

        let currentContext = this;

        currentContext.setState(Object.assign(currentContext.state,{commentsSubmitting : true} ));
        uiHelpers.showProgressOnTop("Submitting comment...")
        axios.post('/trackingStatusForStorage/submitComment', JSON.stringify({ratingTableId : this.state.selectedRatingTable.id, comments : this.state.commentsForm.value }), this.ajaxConfig).then(function (response) {
            currentContext.refreshData(response.data.data);
            currentContext.setState(Object.assign(currentContext.state.commentsForm,{value : ''} ));
            uiHelpers.hideProgressOnTop();
        }).catch(function (error) {
            console.log(error);
            uiHelpers.showErrorTop('Error happened on the server side when submitting comments.');
        }).then(function () {
            currentContext.setState(Object.assign(currentContext.state,{commentsSubmitting : false} ));
        }); 
    }

    refreshData = (data) => {
        for(var i = 0; i<data.length; i++){
            data[i].selected = true;
        }
        this.setState(Object.assign(this.state,{trackingStatusRecords : data} ));
        this.updateDisplayRecords();
    }

    selectedRecordChange(id){
        const newRecords = this.state.displayTrackingStatusRecords.map((item) => {
            if (item.id === id) {
              const updatedRecord = {
                ...item,
                selected: !item.selected,
              };
       
              return updatedRecord;
            }
            return item;
        });
        this.setState(Object.assign(this.state,{displayTrackingStatusRecords : newRecords} ));
    }

    ratingTableSelectedHandler = (selectedRatingTable) => {
        if(selectedRatingTable!=null){
            let currentContext = this;

            currentContext.setState(Object.assign(currentContext.state,{selectedRatingTable : selectedRatingTable} ));
            currentContext.setState(Object.assign(currentContext.state,{trackingStatusRecords : []} ));
            currentContext.setState(Object.assign(currentContext.state,{trackingStatusRequesting : true} ));
            currentContext.setState(Object.assign(currentContext.state,{trackingStatusRequested : false} ));
            uiHelpers.showProgressOnTop("Loading tracking status...")
            axios.get('/trackingStatusForStorage/getByRatingTableId/' + selectedRatingTable.id, this.ajaxConfig).then(function (response) {
                currentContext.refreshData(response.data.data);
                currentContext.setState(Object.assign(currentContext.state,{trackingStatusRequested : true} ));
                uiHelpers.hideProgressOnTop();
            }).catch(function (error) {
                uiHelpers.showErrorTop('Error happened on the server side when requesting tracking status records');
            }).then(function () {
                currentContext.setState(Object.assign(currentContext.state,{trackingStatusRequesting : false} ));
            }); 

        }
    }

    convertAtionText = (trackingStatusRecord) => {
        if(trackingStatusRecord.recordType == "NON_PORTAL_ACTIONS" && trackingStatusRecord.action == "COMMENT" ) return <span><span class="text-primary">DQP Comment:</span> {trackingStatusRecord.comments}</span>;
        else if(trackingStatusRecord.recordType == "METER_REGISTRATION" && trackingStatusRecord.action == "SUBMIT_AND_CREATE" ) return <span>Site registration in portal submitted {this.state.meterViewOption != 'SUMMARY' && <span class="label label-default">id={trackingStatusRecord.meterRegistration.id}</span>}</span>;
        else if(trackingStatusRecord.recordType == "METER_REGISTRATION" && trackingStatusRecord.action == "SET_TO_INSTALL" ) return <span>Site registration in portal set to installed {this.state.meterViewOption != 'SUMMARY' && <span class="label label-default">id={trackingStatusRecord.meterRegistration.id}</span>}</span>;
        else if(trackingStatusRecord.recordType == "METER_REGISTRATION" && trackingStatusRecord.action == "SAVE_DRAFT_AND_CREATE" ) return <span>Site registration in portal draft created {this.state.meterViewOption != 'SUMMARY' && <span class="label label-default">id={trackingStatusRecord.meterRegistration.id}</span>}</span>;
        else if(trackingStatusRecord.recordType == "METER_REGISTRATION" && trackingStatusRecord.action == "SAVE_DRAFT" ) return <span>Site registration in portal draft saved {this.state.meterViewOption != 'SUMMARY' && <span class="label label-default">id={trackingStatusRecord.meterRegistration.id}</span>}</span>;
        else if(trackingStatusRecord.recordType == "METER_REGISTRATION" && trackingStatusRecord.action == "SUBMIT" ) return <span>Site registration in portal submitted {this.state.meterViewOption != 'SUMMARY' && <span class="label label-default">id={trackingStatusRecord.meterRegistration.id}</span>}</span>;
        else if(trackingStatusRecord.recordType == "METER_REGISTRATION" && trackingStatusRecord.action == "DELETE_DRAFT" ) return <span>Site registration in portal draft deleted {this.state.meterViewOption != 'SUMMARY' && <span class="label label-default">id={trackingStatusRecord.meterRegistration.id}</span>}</span>;
        else if(trackingStatusRecord.recordType == "METER_REGISTRATION" && trackingStatusRecord.action == "REMOVE" ) return <span>Site registration in portal removed {this.state.meterViewOption != 'SUMMARY' && <span class="label label-default">id={trackingStatusRecord.meterRegistration.id}</span>}</span>;

        else if(trackingStatusRecord.recordType == "SURVERY_BENCHMARKS_VALIDATION" && trackingStatusRecord.action == "SUBMIT_AND_CREATE" ) return <span>Survey Benchmarks Validation Certificate {this.state.meterViewOption != 'SUMMARY' && <span class="label label-default">id={trackingStatusRecord.benchmarkDetails.id}</span>} submitted</span>;
        else if(trackingStatusRecord.recordType == "SURVERY_BENCHMARKS_VALIDATION" && trackingStatusRecord.action == "SET_TO_INSTALL" ) return <span>Survey Benchmarks Validation Certificate {this.state.meterViewOption != 'SUMMARY' && <span class="label label-default">id={trackingStatusRecord.benchmarkDetails.id}</span>} set to installed</span>;
        else if(trackingStatusRecord.recordType == "SURVERY_BENCHMARKS_VALIDATION" && trackingStatusRecord.action == "SAVE_DRAFT_AND_CREATE" ) return <span>Survey Benchmarks Validation Certificate {this.state.meterViewOption != 'SUMMARY' && <span class="label label-default">id={trackingStatusRecord.benchmarkDetails.id}</span>} draft created</span>;
        else if(trackingStatusRecord.recordType == "SURVERY_BENCHMARKS_VALIDATION" && trackingStatusRecord.action == "SAVE_DRAFT" ) return <span>Survey Benchmarks Validation Certificate {this.state.meterViewOption != 'SUMMARY' && <span class="label label-default">id={trackingStatusRecord.benchmarkDetails.id}</span>} draft saved</span>;
        else if(trackingStatusRecord.recordType == "SURVERY_BENCHMARKS_VALIDATION" && trackingStatusRecord.action == "SUBMIT" ) return <span>Survey Benchmarks Validation Certificate {this.state.meterViewOption != 'SUMMARY' && <span class="label label-default">id={trackingStatusRecord.benchmarkDetails.id}</span>} submitted</span>;
        else if(trackingStatusRecord.recordType == "SURVERY_BENCHMARKS_VALIDATION" && trackingStatusRecord.action == "DELETE_DRAFT" ) return <span>Survey Benchmarks Validation Certificate {this.state.meterViewOption != 'SUMMARY' && <span class="label label-default">id={trackingStatusRecord.benchmarkDetails.id}</span>} draft deleted</span>;
        else if(trackingStatusRecord.recordType == "SURVERY_BENCHMARKS_VALIDATION" && trackingStatusRecord.action == "REMOVE" ) return <span>Survey Benchmarks Validation Certificate {this.state.meterViewOption != 'SUMMARY' && <span class="label label-default">id={trackingStatusRecord.benchmarkDetails.id}</span>} removed</span>;

        else if(trackingStatusRecord.recordType == "UPDATE_RATING_TABLE" && trackingStatusRecord.action == "SUBMIT_AND_CREATE" ) return <span>Update Rating Table Certificate {this.state.meterViewOption != 'SUMMARY' && <span class="label label-default">id={trackingStatusRecord.ratingTableCertificate.id}</span>} submitted</span>;
        else if(trackingStatusRecord.recordType == "UPDATE_RATING_TABLE" && trackingStatusRecord.action == "SET_TO_INSTALL" ) return <span>Update Rating Table Certificate {this.state.meterViewOption != 'SUMMARY' && <span class="label label-default">id={trackingStatusRecord.ratingTableCertificate.id}</span>} set to installed</span>;
        else if(trackingStatusRecord.recordType == "UPDATE_RATING_TABLE" && trackingStatusRecord.action == "SAVE_DRAFT_AND_CREATE" ) return <span>Update Rating Table Certificate {this.state.meterViewOption != 'SUMMARY' && <span class="label label-default">id={trackingStatusRecord.ratingTableCertificate.id}</span>} draft created</span>;
        else if(trackingStatusRecord.recordType == "UPDATE_RATING_TABLE" && trackingStatusRecord.action == "SAVE_DRAFT" ) return <span>Update Rating Table Certificate {this.state.meterViewOption != 'SUMMARY' && <span class="label label-default">id={trackingStatusRecord.ratingTableCertificate.id}</span>} draft saved</span>;
        else if(trackingStatusRecord.recordType == "UPDATE_RATING_TABLE" && trackingStatusRecord.action == "SUBMIT" ) return <span>Update Rating Table Certificate {this.state.meterViewOption != 'SUMMARY' && <span class="label label-default">id={trackingStatusRecord.ratingTableCertificate.id}</span>} submitted</span>;
        else if(trackingStatusRecord.recordType == "UPDATE_RATING_TABLE" && trackingStatusRecord.action == "DELETE_DRAFT" ) return <span>Update Rating Table Certificate {this.state.meterViewOption != 'SUMMARY' && <span class="label label-default">id={trackingStatusRecord.ratingTableCertificate.id}</span>} draft deleted</span>;
        else if(trackingStatusRecord.recordType == "UPDATE_RATING_TABLE" && trackingStatusRecord.action == "REMOVE" ) return <span>Update Rating Table Certificate {this.state.meterViewOption != 'SUMMARY' && <span class="label label-default">id={trackingStatusRecord.ratingTableCertificate.id}</span>} removed</span>;

        else if(trackingStatusRecord.recordType == "SECONDARY_EQUIPMENT_VALIDATION" && trackingStatusRecord.action == "SUBMIT_AND_CREATE" ) return <span>Secondary Equipment Validation Certificate {this.state.meterViewOption != 'SUMMARY' && <span class="label label-default">id={trackingStatusRecord.secondaryEquipmentValidation.id}</span>} submitted</span>;
        else if(trackingStatusRecord.recordType == "SECONDARY_EQUIPMENT_VALIDATION" && trackingStatusRecord.action == "SET_TO_INSTALL" ) return <span>Secondary Equipment Validation Certificate {this.state.meterViewOption != 'SUMMARY' && <span class="label label-default">id={trackingStatusRecord.secondaryEquipmentValidation.id}</span>} set to installed</span>;
        else if(trackingStatusRecord.recordType == "SECONDARY_EQUIPMENT_VALIDATION" && trackingStatusRecord.action == "SAVE_DRAFT_AND_CREATE" ) return <span>Secondary Equipment Validation Certificate {this.state.meterViewOption != 'SUMMARY' && <span class="label label-default">id={trackingStatusRecord.secondaryEquipmentValidation.id}</span>} draft created</span>;
        else if(trackingStatusRecord.recordType == "SECONDARY_EQUIPMENT_VALIDATION" && trackingStatusRecord.action == "SAVE_DRAFT" ) return <span>Secondary Equipment Validation Certificate {this.state.meterViewOption != 'SUMMARY' && <span class="label label-default">id={trackingStatusRecord.secondaryEquipmentValidation.id}</span>} draft saved</span>;
        else if(trackingStatusRecord.recordType == "SECONDARY_EQUIPMENT_VALIDATION" && trackingStatusRecord.action == "SUBMIT" ) return <span>Secondary Equipment Validation Certificate {this.state.meterViewOption != 'SUMMARY' && <span class="label label-default">id={trackingStatusRecord.secondaryEquipmentValidation.id}</span>} submitted</span>;
        else if(trackingStatusRecord.recordType == "SECONDARY_EQUIPMENT_VALIDATION" && trackingStatusRecord.action == "DELETE_DRAFT" ) return <span>Secondary Equipment Validation Certificate {this.state.meterViewOption != 'SUMMARY' && <span class="label label-default">id={trackingStatusRecord.secondaryEquipmentValidation.id}</span>} draft deleted</span>;
        else if(trackingStatusRecord.recordType == "SECONDARY_EQUIPMENT_VALIDATION" && trackingStatusRecord.action == "REMOVE" ) return <span>Secondary Equipment Validation Certificate {this.state.meterViewOption != 'SUMMARY' && <span class="label label-default">id={trackingStatusRecord.secondaryEquipmentValidation.id}</span>} removed</span>;

        else if(trackingStatusRecord.recordType == "STORAGE_MEASUREMENT_VALIDATION" && trackingStatusRecord.action == "SUBMIT_AND_CREATE" ) return <span>Primary Validation certificate {this.state.meterViewOption != 'SUMMARY' && <span class="label label-default">id={trackingStatusRecord.storageManagementValidation.id}</span>} submitted</span>;
        else if(trackingStatusRecord.recordType == "STORAGE_MEASUREMENT_VALIDATION" && trackingStatusRecord.action == "SET_TO_INSTALL" ) return <span>Primary Validation certificate {this.state.meterViewOption != 'SUMMARY' && <span class="label label-default">id={trackingStatusRecord.storageManagementValidation.id}</span>} set to installed</span>;
        else if(trackingStatusRecord.recordType == "STORAGE_MEASUREMENT_VALIDATION" && trackingStatusRecord.action == "SAVE_DRAFT_AND_CREATE" ) return <span>Primary Validation certificate {this.state.meterViewOption != 'SUMMARY' && <span class="label label-default">id={trackingStatusRecord.storageManagementValidation.id}</span>} draft created</span>;
        else if(trackingStatusRecord.recordType == "STORAGE_MEASUREMENT_VALIDATION" && trackingStatusRecord.action == "SAVE_DRAFT" ) return <span>Primary Validation certificate {this.state.meterViewOption != 'SUMMARY' && <span class="label label-default">id={trackingStatusRecord.storageManagementValidation.id}</span>} draft saved</span>;
        else if(trackingStatusRecord.recordType == "STORAGE_MEASUREMENT_VALIDATION" && trackingStatusRecord.action == "SUBMIT" ) return <span>Primary Validation certificate {this.state.meterViewOption != 'SUMMARY' && <span class="label label-default">id={trackingStatusRecord.storageManagementValidation.id}</span>} submitted</span>;
        else if(trackingStatusRecord.recordType == "STORAGE_MEASUREMENT_VALIDATION" && trackingStatusRecord.action == "DELETE_DRAFT" ) return <span>Primary Validation certificate {this.state.meterViewOption != 'SUMMARY' && <span class="label label-default">id={trackingStatusRecord.storageManagementValidation.id}</span>} draft deleted</span>;
        else if(trackingStatusRecord.recordType == "STORAGE_MEASUREMENT_VALIDATION" && trackingStatusRecord.action == "REMOVE" ) return <span>Primary Validation certificate {this.state.meterViewOption != 'SUMMARY' && <span class="label label-default">id={trackingStatusRecord.storageManagementValidation.id}</span>} removed</span>;

        else return <span>Unknown action</span>;
    }

    downloadPDF = () =>{
        var selectedIDs = [];
        for(var i =0; i <this.state.displayTrackingStatusRecords.length; i++){
            if (this.state.displayTrackingStatusRecords[i].selected) {
                selectedIDs.push(this.state.displayTrackingStatusRecords[i].id);
            }
        }

        if(selectedIDs.length==0) {
            alert('Please select the list of actions to download into the PDF')
            return;
        }
        let currentContext = this;

        
        currentContext.setState(Object.assign(currentContext.state,{pdfDownloading : true} ));
        uiHelpers.showProgressOnTop("Generating PDF...")
        axios.post('/trackingStatusForStorage/generatePDF', JSON.stringify({ratingTableId : this.state.selectedRatingTable.id, selectedIDs : selectedIDs}), {...this.ajaxConfig ,responseType:'arraybuffer'}).then(function (response) {
            uiHelpers.hideProgressOnTop();
            var mime = 'application/pdf';
            var filename = currentContext.state.selectedRatingTable.ratingTableName  + '.pdf';
            console.log(response);
            var blob = new Blob([response.data], {type: mime || 'application/pdf'});

            if (typeof window.navigator.msSaveBlob !== 'undefined') {
                // IE workaround for "HTML7007: One or more blob URLs were
                // revoked by closing the blob for which they were created.
                // These URLs will no longer resolve as the data backing
                // the URL has been freed."
                window.navigator.msSaveBlob(blob, filename);
            }
            else {
                var blobURL = (window.URL && window.URL.createObjectURL) ? window.URL.createObjectURL(blob) : window.webkitURL.createObjectURL(blob);
                var tempLink = document.createElement('a');
                tempLink.style.display = 'none';
                tempLink.href = blobURL;
                tempLink.setAttribute('download', filename);
        
                // Safari thinks _blank anchor are pop ups. We only want to set _blank
                // target if the browser does not support the HTML5 download attribute.
                // This allows you to download files in desktop safari if pop up blocking
                // is enabled.
                if (typeof tempLink.download === 'undefined') {
                    tempLink.setAttribute('target', '_blank');
                }
        
                document.body.appendChild(tempLink);
                tempLink.click();
        
                // Fixes "webkit blob resource error 1"
                setTimeout(function() {
                    document.body.removeChild(tempLink);
                    window.URL.revokeObjectURL(blobURL);
                }, 200)
            }
        }).catch(function (error) {
            console.log(error);
            uiHelpers.showErrorTop('Error happened when dowloading file.');
        }).then(function () {
            currentContext.setState(Object.assign(currentContext.state,{pdfDownloading : false} ));
        });
    }

    changeMeterViewOption = (viewOption) => {
        this.setState(Object.assign(this.state,{meterViewOption : viewOption} ));
        this.updateDisplayRecords();
    }

    updateDisplayRecords(){
        var dispalyRecords = [];
        var filter;
        var maxId = 0;
        for(var i = 0; i < this.state.trackingStatusRecords.length; i++){

            if(this.state.meterViewOption == 'SUMMARY' && 
                (
                    (this.state.trackingStatusRecords[i].meterRegistration && this.state.trackingStatusRecords[i].meterRegistration.isDeleted)
                    ||
                    (
                        (this.state.trackingStatusRecords[i].meterRegistration && this.state.trackingStatusRecords[i].meterRegistration.formStatus!=0)
                        &&
                        (
                            this.state.trackingStatusRecords[i].action == 'SAVE_DRAFT'
                            ||
                            this.state.trackingStatusRecords[i].action == 'SAVE_DRAFT_AND_CREATE'
                        )
                    )
                    ||
                    (this.state.trackingStatusRecords[i].benchmarkDetails && this.state.trackingStatusRecords[i].benchmarkDetails.isDeleted)
                    ||
                    (
                        (this.state.trackingStatusRecords[i].benchmarkDetails && this.state.trackingStatusRecords[i].benchmarkDetails.formStatus!=1)
                        &&
                        (
                            this.state.trackingStatusRecords[i].action == 'SAVE_DRAFT'
                            ||
                            this.state.trackingStatusRecords[i].action == 'SAVE_DRAFT_AND_CREATE'
                        )
                    )
                    ||
                    (this.state.trackingStatusRecords[i].ratingTableCertificate && this.state.trackingStatusRecords[i].ratingTableCertificate.isDeleted)
                    ||
                    (
                        (this.state.trackingStatusRecords[i].ratingTableCertificate && this.state.trackingStatusRecords[i].ratingTableCertificate.formStatus!=1)
                        &&
                        (
                            this.state.trackingStatusRecords[i].action == 'SAVE_DRAFT'
                            ||
                            this.state.trackingStatusRecords[i].action == 'SAVE_DRAFT_AND_CREATE'
                        )
                    )
                    ||
                    (this.state.trackingStatusRecords[i].secondaryEquipmentValidation && this.state.trackingStatusRecords[i].secondaryEquipmentValidation.isDeleted)
                    ||
                    (
                        (this.state.trackingStatusRecords[i].secondaryEquipmentValidation && this.state.trackingStatusRecords[i].secondaryEquipmentValidation.formStatus!=1)
                        &&
                        (
                            this.state.trackingStatusRecords[i].action == 'SAVE_DRAFT'
                            ||
                            this.state.trackingStatusRecords[i].action == 'SAVE_DRAFT_AND_CREATE'
                        )
                    )
                    ||
                    (this.state.trackingStatusRecords[i].storageManagementValidation && this.state.trackingStatusRecords[i].storageManagementValidation.isDeleted)
                    ||
                    (
                        (this.state.trackingStatusRecords[i].storageManagementValidation && this.state.trackingStatusRecords[i].storageManagementValidation.formStatus!=1)
                        &&
                        (
                            this.state.trackingStatusRecords[i].action == 'SAVE_DRAFT'
                            ||
                            this.state.trackingStatusRecords[i].action == 'SAVE_DRAFT_AND_CREATE'
                        )
                    )
                )
            ){
                continue;
            }

            if(this.state.meterViewOption == 'SUMMARY' && this.state.trackingStatusRecords[i].meterRegistration && this.state.trackingStatusRecords[i].meterRegistration.formStatus==0){
                maxId = 0;
                for(var j = 0; j < this.state.trackingStatusRecords.length; j++){
                    if(
                        (this.state.trackingStatusRecords[j].meterRegistration && this.state.trackingStatusRecords[i].meterRegistration.id != this.state.trackingStatusRecords[j].meterRegistration.id)
                        ||
                        !this.state.trackingStatusRecords[j].meterRegistration
                    ){
                        continue;
                    }
                    if(maxId < this.state.trackingStatusRecords[j].id){
                        maxId = this.state.trackingStatusRecords[j].id;
                    }
                }
                if(this.state.trackingStatusRecords[i].id!=maxId) continue;
            }

            if(this.state.meterViewOption == 'SUMMARY' && this.state.trackingStatusRecords[i].benchmarkDetails && this.state.trackingStatusRecords[i].benchmarkDetails.formStatus==1){
                maxId = 0;
                for(var j = 0; j < this.state.trackingStatusRecords.length; j++){
                    if(
                        (this.state.trackingStatusRecords[j].benchmarkDetails && this.state.trackingStatusRecords[i].benchmarkDetails.id != this.state.trackingStatusRecords[j].benchmarkDetails.id)
                        ||
                        !this.state.trackingStatusRecords[j].benchmarkDetails
                    ){
                        continue;
                    }
                    if(maxId < this.state.trackingStatusRecords[j].id){
                        maxId = this.state.trackingStatusRecords[j].id;
                    }
                }
                if(this.state.trackingStatusRecords[i].id!=maxId) continue;
            }

            if(this.state.meterViewOption == 'SUMMARY' && this.state.trackingStatusRecords[i].ratingTableCertificate && this.state.trackingStatusRecords[i].ratingTableCertificate.formStatus==1){
                maxId = 0;
                for(var j = 0; j < this.state.trackingStatusRecords.length; j++){
                    if(
                        (this.state.trackingStatusRecords[j].ratingTableCertificate && this.state.trackingStatusRecords[i].ratingTableCertificate.id != this.state.trackingStatusRecords[j].ratingTableCertificate.id)
                        ||
                        !this.state.trackingStatusRecords[j].ratingTableCertificate
                    ){
                        continue;
                    }
                    if(maxId < this.state.trackingStatusRecords[j].id){
                        maxId = this.state.trackingStatusRecords[j].id;
                    }
                }
                if(this.state.trackingStatusRecords[i].id!=maxId) continue;
            }

            if(this.state.meterViewOption == 'SUMMARY' && this.state.trackingStatusRecords[i].secondaryEquipmentValidation && this.state.trackingStatusRecords[i].secondaryEquipmentValidation.formStatus==1){
                maxId = 0;
                for(var j = 0; j < this.state.trackingStatusRecords.length; j++){
                    if(
                        (this.state.trackingStatusRecords[j].secondaryEquipmentValidation && this.state.trackingStatusRecords[i].secondaryEquipmentValidation.id != this.state.trackingStatusRecords[j].secondaryEquipmentValidation.id)
                        ||
                        !this.state.trackingStatusRecords[j].secondaryEquipmentValidation
                    ){
                        continue;
                    }
                    if(maxId < this.state.trackingStatusRecords[j].id){
                        maxId = this.state.trackingStatusRecords[j].id;
                    }
                }
                if(this.state.trackingStatusRecords[i].id!=maxId) continue;
            }

            if(this.state.meterViewOption == 'SUMMARY' && this.state.trackingStatusRecords[i].storageManagementValidation && this.state.trackingStatusRecords[i].storageManagementValidation.formStatus==1){
                maxId = 0;
                for(var j = 0; j < this.state.trackingStatusRecords.length; j++){
                    if(
                        (this.state.trackingStatusRecords[j].storageManagementValidation && this.state.trackingStatusRecords[i].storageManagementValidation.id != this.state.trackingStatusRecords[j].storageManagementValidation.id)
                        ||
                        !this.state.trackingStatusRecords[j].storageManagementValidation
                    ){
                        continue;
                    }
                    if(maxId < this.state.trackingStatusRecords[j].id){
                        maxId = this.state.trackingStatusRecords[j].id;
                    }
                }
                if(this.state.trackingStatusRecords[i].id!=maxId) continue;
            }

            this.state.trackingStatusRecords[i].selected = true;
            dispalyRecords.push(this.state.trackingStatusRecords[i]); 

        }
        this.setState(Object.assign(this.state,{displayTrackingStatusRecords : dispalyRecords} ));
    }


    render() {
        return <>
            
            <RatingTableSeacherLauncher disabled={this.state.trackingStatusRequesting} onRatingTableSelected = {this.ratingTableSelectedHandler} contineBtnTxt={"View report"} />
            {this.state.trackingStatusRequested && <div className='mt-3'>

                <hr/>

                <div class="card mb-4">
                    <div class="card-header">
                        <strong>Add comment to track any non-portal completed/in progress actions</strong>
                    </div>
                    <div class="card-body">
                        <div className='mb-3' >
                            <textarea disabled={this.state.commentsSubmitting} className={`form-control ${(this.state.commentsForm.error && this.state.commentsForm.error!='') ? "is-invalid" : ""}`} rows="3" onChange={this.commentsChange} value={this.state.commentsForm.value} />
                            <div class="invalid-feedback">{this.state.commentsForm.error}</div>
                        </div>
                        <div>
                            <button disabled={this.state.commentsSubmitting} type="submit" class="btn btn-primary" onClick={this.submitCommentsForm} >Add comment</button>
                        </div>
                    </div>
                </div>
                
                <h5 mb-3>Storage history</h5>

                <div class="card">
                    <div class="card-header"><strong>Storage : </strong> {this.state.selectedRatingTable.ratingTableName}</div>
                    <div class="card-header">
                        <strong>View options : </strong> 
                        <div class="btn-group">
                            <button type="button" class="btn btn-secondary dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                {this.state.meterViewOption=='SUMMARY' && <span>Summary view</span> }
                                {this.state.meterViewOption=='DETAILED' && <span>Detailed view</span> }
                                &nbsp;&nbsp;
                                <span class="caret"></span>
                            </button>
                            <ul class="dropdown-menu">
                                <li><a href="#" class="dropdown-item" onClick={(e)=>{e.preventDefault();this.changeMeterViewOption('SUMMARY')}}>Summary view</a></li>
                                <li><a href="#" class="dropdown-item" onClick={(e)=>{e.preventDefault();this.changeMeterViewOption('DETAILED')}}>Detailed view</a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="card-body">

                        {!this.state.displayTrackingStatusRecords || this.state.displayTrackingStatusRecords.length == 0 && <div class="alert alert-info" role="alert">No history found for this storage</div>}

                        {this.state.displayTrackingStatusRecords && this.state.displayTrackingStatusRecords.length > 0 && 
                            <div>
                                <p>
                                    <button disabled={this.state.pdfDownloading} type="button" class="btn btn-primary" onClick={this.downloadPDF}>Download PDF</button>
                                </p>
                                <div class="alert alert-primary" role="alert">
                                    Select using checkboxes which action/s to include in the PDF
                                </div>
                                <table class="table table-sm fs-90">
                                    <tbody>
                                        <tr class="table-primary">
                                            <th style={{width:"20px"}}></th>
                                            <th>Action</th>
                                            <th style={{width:"20%"}}>DQP Name</th>
                                            <th style={{width:"20%"}}>Action Date</th>
                                        </tr>
                                        {this.state.displayTrackingStatusRecords.map((item) =>{
                                            return <tr key={item.id}>
                                                    <td>
                                                        <input type="checkbox" checked={item.selected} onChange={()=>{this.selectedRecordChange(item.id)}} />
                                                    </td>
                                                    <td>
                                                        {this.convertAtionText(item)}
                                                    </td>
                                                    <td>
                                                        {item.actionUser.firstName} {item.actionUser.lastName}
                                                    </td>
                                                    <td>
                                                        {
                                                            moment((new Date(item.actionDate))).format('DD/MM/YYYY h:mm:ss a')
                                                        }
                                                    </td>
                                                </tr>

                                        })}
                                    </tbody>
                                </table>
                            </div>
                        }
                    </div>
                </div>
            

            </div>}
           
        </>
    }
}

export default StorageLTSReportComponent;