import { valid_email,
    MESSAGE_MAX_ALLOWED_CHARCATERS_100,
    MESSAGE_MAX_ALLOWED_CHARCATERS_500,
    MESSAGE_INVALID_EMAIL,
    MESSAGE_MAX_ALLOWED_CHARCATERS_20,
    MESSAGE_REQUIRED,
    decimal3UpTo14Digits,
    decimal3UpTo14DigitsMessage,
    decimal9UpTo14Digits,
    decimal9UpTo14DigitsMessage,
    decimal10Digits2,
    decimal10Digits2Message,
    checkLongitude,
    longitudeMessage,
    checkLatitude,
    latitudeMessage,
    
 } from '../../../constants/MeterFormConstants';

export const validateCertificatePartA = (values, params) => {   

    const errors = {
        authorityHolderDetails: {
            contactPersonPhone:'',
            contactPersonEmailAddress: '',
            contactPersonName: ''           
          }
    }

    let clickSaveDraft = params.clickSaveDraft
    let clickNext = params.clickNext;
    
    if (clickSaveDraft === 1|| clickNext === 1) {
        if (values.authorityHolderDetails.contactPersonPhone && (values.authorityHolderDetails.contactPersonPhone + '').length> 20) {       
            errors.authorityHolderDetails.contactPersonPhone = MESSAGE_MAX_ALLOWED_CHARCATERS_20;           
        }

        if (values.authorityHolderDetails.contactPersonName && values.authorityHolderDetails.contactPersonName.length> 100 ) {       
            errors.authorityHolderDetails.contactPersonName = MESSAGE_MAX_ALLOWED_CHARCATERS_100;            
        }

        if(values.authorityHolderDetails.contactPersonEmailAddress){
            if(values.authorityHolderDetails.contactPersonEmailAddress.length > 100 ) {       
                errors.authorityHolderDetails.contactPersonEmailAddress = MESSAGE_MAX_ALLOWED_CHARCATERS_100;            
            } else if(!valid_email.test(values.authorityHolderDetails.contactPersonEmailAddress) ) {       
                errors.authorityHolderDetails.contactPersonEmailAddress = MESSAGE_INVALID_EMAIL;            
            } 
        }        
     }
    return errors;       
}

const NEW_INSTALLATION_VAL_MESSAGE = "If this validation is for a New Installation and Validation, your measurement increment must be 10mm";
export const validateCertificatePartC = (values, params) => {

    const errors = {

        secondaryEquipmentValidationData: {
            certFor:'',
            meterType:'',
            measurementIncrement:'',
            installedBy:'',
            gaugeOption:'',
            easting:'',
            northing:'',
            mga:'',           
            levelGaugeHeight:'',
            gaugeHeight:'',
            measuringPoint:'',
            storageFloorLevel:'',
            sealNo:'',
            comments:''
                       
          }
    }    

    console.log("in secondaryEquipmentValidationData validation");
    debugger;
    let clickSaveDraft = params.clickSaveDraft
    let clickNext = params.clickNext;
    if (clickSaveDraft == undefined) clickSaveDraft = values.clickSaveDraft;
    if (clickNext == undefined) clickNext = values.clickNext;
    console.log(`draft: + ${clickSaveDraft} clickNext ${clickNext}`);       

    if (clickSaveDraft === 1|| (clickNext === 1 && values.secondaryEquipmentValidationData.formStatus != 2)) {

        /*
        if (values.secondaryEquipmentValidationData.easting && !decimal9UpTo14Digits.test(values.secondaryEquipmentValidationData.easting)) errors.secondaryEquipmentValidationData.easting =decimal9UpTo14DigitsMessage;
        if (values.secondaryEquipmentValidationData.northing && !decimal9UpTo14Digits.test(values.secondaryEquipmentValidationData.northing)) errors.secondaryEquipmentValidationData.northing =decimal9UpTo14DigitsMessage;
        if (values.secondaryEquipmentValidationData.mga && !decimal3UpTo14Digits.test(values.secondaryEquipmentValidationData.mga)) errors.secondaryEquipmentValidationData.mga =decimal3UpTo14DigitsMessage;
        */

        if (values.secondaryEquipmentValidationData.longitude && !decimal3UpTo14Digits.test(values.secondaryEquipmentValidationData.longitude)) {
            errors.secondaryEquipmentValidationData.longitude = decimal3UpTo14DigitsMessage;
        } else if (values.secondaryEquipmentValidationData.longitude && !checkLongitude(values.secondaryEquipmentValidationData.longitude))
            errors.secondaryEquipmentValidationData.longitude = longitudeMessage

        if (values.secondaryEquipmentValidationData.latitude && !decimal3UpTo14Digits.test(values.secondaryEquipmentValidationData.latitude)) {
            errors.secondaryEquipmentValidationData.latitude = decimal3UpTo14DigitsMessage;
        } else if (values.secondaryEquipmentValidationData.latitude && !checkLatitude(values.secondaryEquipmentValidationData.latitude))
            errors.secondaryEquipmentValidationData.latitude = latitudeMessage
       
       
        if (values.secondaryEquipmentValidationData.levelGaugeHeight && !decimal10Digits2.test(values.secondaryEquipmentValidationData.levelGaugeHeight)) errors.secondaryEquipmentValidationData.levelGaugeHeight =decimal10Digits2Message;
        if (values.secondaryEquipmentValidationData.gaugeHeight && !decimal10Digits2.test(values.secondaryEquipmentValidationData.gaugeHeight)) errors.secondaryEquipmentValidationData.gaugeHeight =decimal10Digits2Message;
        if (values.secondaryEquipmentValidationData.measuringPoint && !decimal10Digits2.test(values.secondaryEquipmentValidationData.measuringPoint)) errors.secondaryEquipmentValidationData.measuringPoint =decimal10Digits2Message;
        if (values.secondaryEquipmentValidationData.storageFloorLevel && !decimal10Digits2.test(values.secondaryEquipmentValidationData.storageFloorLevel)) errors.secondaryEquipmentValidationData.storageFloorLevel =decimal10Digits2Message;
        if (values.secondaryEquipmentValidationData.sealNo && values.secondaryEquipmentValidationData.sealNo.length > 100) errors.secondaryEquipmentValidationData.sealNo =MESSAGE_MAX_ALLOWED_CHARCATERS_100;
        if (values.secondaryEquipmentValidationData.comments && values.secondaryEquipmentValidationData.comments.length > 500) errors.secondaryEquipmentValidationData.comments =MESSAGE_MAX_ALLOWED_CHARCATERS_500;

        if((values.secondaryEquipmentValidationData.certFor == 1) && (values.secondaryEquipmentValidationData.measurementIncrement != 2)){
            errors.secondaryEquipmentValidationData.measurementIncrement = NEW_INSTALLATION_VAL_MESSAGE;  
        }
        

    }
    
    if (clickSaveDraft == 0 && clickNext === 1 && values.secondaryEquipmentValidationData.formStatus != 2) {
        if (!values.secondaryEquipmentValidationData.certFor || values.secondaryEquipmentValidationData.certFor =='' ) {       
            errors.secondaryEquipmentValidationData.certFor = MESSAGE_REQUIRED            
        }

        if (!values.secondaryEquipmentValidationData.meterType || values.secondaryEquipmentValidationData.meterType =='' ) {       
            errors.secondaryEquipmentValidationData.meterType = MESSAGE_REQUIRED            
        }

        if (!values.secondaryEquipmentValidationData.measurementIncrement || values.secondaryEquipmentValidationData.measurementIncrement =='' ) {       
            errors.secondaryEquipmentValidationData.measurementIncrement = MESSAGE_REQUIRED            
        }

        if (!values.secondaryEquipmentValidationData.installedBy || values.secondaryEquipmentValidationData.installedBy =='' ) {       
            errors.secondaryEquipmentValidationData.installedBy = MESSAGE_REQUIRED            
        }

        if (!values.secondaryEquipmentValidationData.gaugeOption || values.secondaryEquipmentValidationData.gaugeOption =='' ) {       
            errors.secondaryEquipmentValidationData.gaugeOption = MESSAGE_REQUIRED            
        }

        /*
        if (!values.secondaryEquipmentValidationData.easting || values.secondaryEquipmentValidationData.easting =='' ) {       
            errors.secondaryEquipmentValidationData.easting = MESSAGE_REQUIRED            
        }

        if (!values.secondaryEquipmentValidationData.northing || values.secondaryEquipmentValidationData.northing =='' ) {       
            errors.secondaryEquipmentValidationData.northing = MESSAGE_REQUIRED            
        }

        if (!values.secondaryEquipmentValidationData.mga || values.secondaryEquipmentValidationData.mga =='' ) {       
            errors.secondaryEquipmentValidationData.mga = MESSAGE_REQUIRED            
        }       
        */ 

        if (!values.secondaryEquipmentValidationData.longitude || values.secondaryEquipmentValidationData.longitude =='' ) {       
            errors.secondaryEquipmentValidationData.longitude = MESSAGE_REQUIRED            
        }

        if (!values.secondaryEquipmentValidationData.latitude || values.secondaryEquipmentValidationData.latitude =='' ) {       
            errors.secondaryEquipmentValidationData.latitude = MESSAGE_REQUIRED            
        }

        if(values.secondaryEquipmentValidationData.gaugeOption==1) {
            
            if (!values.secondaryEquipmentValidationData.levelGaugeHeight || values.secondaryEquipmentValidationData.levelGaugeHeight =='' ) {       
                errors.secondaryEquipmentValidationData.levelGaugeHeight = MESSAGE_REQUIRED            
            }
        }
        if(values.secondaryEquipmentValidationData.gaugeOption==2) {

            if (!values.secondaryEquipmentValidationData.gaugeHeight || values.secondaryEquipmentValidationData.gaugeHeight =='' ) {       
                errors.secondaryEquipmentValidationData.gaugeHeight = MESSAGE_REQUIRED            
            }

            if (!values.secondaryEquipmentValidationData.measuringPoint || values.secondaryEquipmentValidationData.measuringPoint =='' ) {       
                errors.secondaryEquipmentValidationData.measuringPoint = MESSAGE_REQUIRED            
            }

            if (!values.secondaryEquipmentValidationData.storageFloorLevel || values.secondaryEquipmentValidationData.storageFloorLevel =='' ) {       
                errors.secondaryEquipmentValidationData.storageFloorLevel = MESSAGE_REQUIRED            
            }
        }

        if (!values.secondaryEquipmentValidationData.sealNo || values.secondaryEquipmentValidationData.sealNo =='' ) {       
            errors.secondaryEquipmentValidationData.sealNo = MESSAGE_REQUIRED            
        }

        /*

        if (!values.secondaryEquipmentValidationData.comments || values.secondaryEquipmentValidationData.comments =='' ) {       
            errors.secondaryEquipmentValidationData.comments = MESSAGE_REQUIRED            
        }
        */
        
    }  

    console.log("errors from validateCertificatePartC"+JSON.stringify(errors));
    return errors;
}





