import {
    decimal2UpTo2Digits, decimal2UpTo2DigitsMessage,
    decimal2UpTo8Digits, decimal2UpTo8DigitsMessage,
    //decimal3UpTo5Digits,decimal3UpTo5DigitsMessage,
    decimal3UpTo7Digits, decimal3UpTo7DigitsMessage,
    decimal2UpTo2Digits_notMandatory,
    decimal3UpTo14Digits, decimal3UpTo14DigitsMessage,

    decimal2Digits, decimal2DigitsMessage,
    MESSAGE_REQUIRED, checkLongitude, longitudeMessage,
    checkLatitude, latitudeMessage,
    integer2to4Digits, integer2to4DigitsMessage,
    decimal4Digits, decimal4DigitsMessage, integerUpto8Digits, integerUpto8DigitsMessage,
    isNumber, MESSAGE_REQUIRED_NUMBER,
    MESSAGE_MAX_ALLOWED_CHARCATERS_100, MESSAGE_MAX_ALLOWED_CHARCATERS_50,
    MESSAGE_MAX_ALLOWED_CHARCATERS_500,
    MESSAGE_MIN_ALLOWED_DIGITS_2_TO_4,
    positiveIntegerOnly_notMandatory,
    pipeLengthValidationMessage
} from '../../../constants/MeterFormConstants';

export const validateCertificatePartC = (values, params) => {

    const errors = {

        meteringEquipmentInformation: {

            meterCircumstancesOptions: "",// [required()],//text
            dateOfLastValidation: "",//[date({ format: 'mm/dd/yyyy' })],//date
            meteringSystemTypeId: "",//[required(),numericality({int:true,'>':0})],//number
            //meteringSystemType: [required()],//text
            meterManufacturer: "",//[required()],//text
            meterTypeModel: "",//[required()],//text
            meterSerialNo: "",// [required()],//text
            meterSize: "",//[required(),numericality({int:true}),length({min:2,max:4})], //number
            //pipeType: ,//text
            pipeSize: "",//[required(),numericality({int:true}),length({min:2,max:4})] ,//number
            //pipeConnectionType:,//decimal
            pipeLengthUpstream: "",//[format({ with: decimal2UpTo8Digits, message: decimal2UpTo8DigitsMessage })],//decimal
            pipeLengthDownstream: "",// [format({ with: decimal2UpTo8Digits, message: decimal2UpTo8DigitsMessage })],//decimal
            //pipeLengthDownstream: [twonondecimalupto2decimal.test()],//decimal
            longitude: "",//[format({ with: decimal3UpTo6Digits, message: decimal3UpTo6DigitsMessage }),numericality({'<=': 141})],//decimal
            latitude: "",//[format({ with: decimal3UpTo6Digits, message: decimal3UpTo6DigitsMessage }),numericality({'<=': 34,'>=':29})],//decimal

            //channelType:[],//text
            // channelWidth:[],//text
            // meterDistanceFromSource:[],//number
            // channelLengthUpstream:[format({ with: decimal2UpTo2Digits, message: decimal2UpTo2DigitsMessage })],//decimal
            // channelLlengthDownstream:[format({ with: decimal2UpTo2Digits, message: decimal2UpTo2DigitsMessage })],//number

            channelType: "",
            channelWidth: "",
            meterDistanceFromSource: "",
            channelLengthUpstream: "",
            channelLlengthDownstream: "",
            designEquipmentDetails: "",
            meteringEquipmentConsistent: "",
            meterTestCertificate: "",
            meterq1FlowRateMinimum: "",
            meterReading: "",
            dateOfLastValidation: "",
            finalReadingRemovedMeter: "",
            finalReadingDateRemovedMeter: "",
            isModificationToCompliance: "",
            dataCollectorTypeId: "",
            dataCollectorModelNo: "",
            dataCollectorSerialNo: "",
            //documentError: "",
            sealNo: ""


        },
        meterNonComplianceReason: [{
            reason: '',
            comments: '',
        }],
        meterNonCompliance: {
            modificationsPerformed: '',
        },

    }



    console.log("in accuracy validation");


    let clickSaveDraft = params.clickSaveDraft
    let clickNext = params.clickNext;
    if (clickSaveDraft == undefined)
        clickSaveDraft = values.clickSaveDraft;
    if (clickNext == undefined)
        clickNext = values.clickNext;
    console.log("draft:" + clickSaveDraft);

    if (values.meteringEquipmentInformation.dataCollectorTypeId && parseInt(values.meteringEquipmentInformation.dataCollectorTypeId) == 4) {
        values.meteringEquipmentInformation.dataCollectorModelNo = null;
        values.meteringEquipmentInformation.dataCollectorSerialNo = null;
        values.meteringEquipmentInformation.dataCollectorPowerSourceId = null;
        values.meteringEquipmentInformation.dataCollectorSolarPanelSerialNumber = null;
    }

    if (clickSaveDraft === 1 && clickNext === 0) {
        //commented as per bug 3575
        // if (values.meteringEquipmentInformation.meterManufacturer &&
        //     values.meteringEquipmentInformation.meterManufacturer.length > 100) {
        //     errors.meteringEquipmentInformation.meterManufacturer = MESSAGE_MAX_ALLOWED_CHARCATERS_100
        // }

        if (parseInt(values.meteringEquipmentInformation.meterTypeModelId) == parseInt(values.mdata.MeterModelTypeOtherId)) {
            if (!values.meteringEquipmentInformation.meterTypeModel) {
                errors.meteringEquipmentInformation.meterTypeModel = MESSAGE_REQUIRED
            } else if (values.meteringEquipmentInformation.meterTypeModel.length > 100) {
                errors.meteringEquipmentInformation.meterTypeModel = MESSAGE_MAX_ALLOWED_CHARCATERS_100
            }
        }

        if (values.meteringEquipmentInformation.meterSerialNo &&
            values.meteringEquipmentInformation.meterSerialNo.length > 100) {
            errors.meteringEquipmentInformation.meterSerialNo = MESSAGE_MAX_ALLOWED_CHARCATERS_100
        }

        if (values.meteringEquipmentInformation.patternApprovalNumber &&
            values.meteringEquipmentInformation.patternApprovalNumber.length > 100) {
            errors.meteringEquipmentInformation.patternApprovalNumber = MESSAGE_MAX_ALLOWED_CHARCATERS_100
        }

        if (values.meteringEquipmentInformation.meterSize) {
            if (values.meteringEquipmentInformation.meterSize && !isNumber(values.meteringEquipmentInformation.meterSize)) {
                errors.meteringEquipmentInformation.meterSize = MESSAGE_REQUIRED_NUMBER;
            } else if (values.meteringEquipmentInformation.meterSize && !integer2to4Digits.test(values.meteringEquipmentInformation.meterSize)) {
                errors.meteringEquipmentInformation.meterSize = integer2to4DigitsMessage;
            }
        }


        if (values.meteringEquipmentInformation.pipeType && values.meteringEquipmentInformation.pipeType.length > 100) {
            errors.meteringEquipmentInformation.pipeType = MESSAGE_MAX_ALLOWED_CHARCATERS_100
        }

        if (values.meteringEquipmentInformation.pipeSize) {
            if (values.meteringEquipmentInformation.pipeSize && !isNumber(values.meteringEquipmentInformation.pipeSize)) {
                errors.meteringEquipmentInformation.pipeSize = MESSAGE_REQUIRED_NUMBER;
            } else if (values.meteringEquipmentInformation.pipeSize && !integer2to4Digits.test(values.meteringEquipmentInformation.pipeSize)) {
                errors.meteringEquipmentInformation.pipeSize = integer2to4DigitsMessage;
            }

            /* else if (values.meteringEquipmentInformation.pipeSize.length < 2) {
                errors.meteringEquipmentInformation.pipeSize = MESSAGE_MIN_ALLOWED_DIGITS_2_TO_4;
            }
            else if (values.meteringEquipmentInformation.pipeSize.length > 4) {
                errors.meteringEquipmentInformation.pipeSize = MESSAGE_MIN_ALLOWED_DIGITS_2_TO_4;
            } */
        }

        if (values.meteringEquipmentInformation.pipeConnectionType
            && values.meteringEquipmentInformation.pipeConnectionType.length > 100) {
            errors.meteringEquipmentInformation.pipeConnectionType = MESSAGE_MAX_ALLOWED_CHARCATERS_100
        }


        if (values.meteringEquipmentInformation.pipeLengthUpstream && !positiveIntegerOnly_notMandatory.test(values.meteringEquipmentInformation.pipeLengthUpstream)) {
            errors.meteringEquipmentInformation.pipeLengthUpstream = pipeLengthValidationMessage;
        } else if (values.meteringEquipmentInformation.pipeLengthUpstream && (parseInt(values.meteringEquipmentInformation.pipeLengthUpstream) > 10000)) {
            errors.meteringEquipmentInformation.pipeLengthUpstream = pipeLengthValidationMessage;
        }

        if (values.meteringEquipmentInformation.pipeLengthDownstream && !positiveIntegerOnly_notMandatory.test(values.meteringEquipmentInformation.pipeLengthDownstream)) {
            errors.meteringEquipmentInformation.pipeLengthDownstream = pipeLengthValidationMessage;
        } else if (values.meteringEquipmentInformation.pipeLengthDownstream && (parseInt(values.meteringEquipmentInformation.pipeLengthDownstream) > 10000)) {
            errors.meteringEquipmentInformation.pipeLengthDownstream = pipeLengthValidationMessage;
        }




        if (values.meteringEquipmentInformation.designFlowRate && values.meteringEquipmentInformation.designFlowRate.length > 100) {
            errors.meteringEquipmentInformation.designFlowRate = MESSAGE_MAX_ALLOWED_CHARCATERS_100
        }

        if (values.meteringEquipmentInformation.softwareVersion && values.meteringEquipmentInformation.softwareVersion.length > 100) {
            errors.meteringEquipmentInformation.softwareVersion = MESSAGE_MAX_ALLOWED_CHARCATERS_100
        }

        if (values.meteringEquipmentInformation.longitude && !decimal3UpTo14Digits.test(values.meteringEquipmentInformation.longitude)) {
            errors.meteringEquipmentInformation.longitude = decimal3UpTo14DigitsMessage;
        } else if (values.meteringEquipmentInformation.longitude && !checkLongitude(values.meteringEquipmentInformation.longitude))
            errors.meteringEquipmentInformation.longitude = longitudeMessage

        if (values.meteringEquipmentInformation.latitude && !decimal3UpTo14Digits.test(values.meteringEquipmentInformation.latitude)) {
            errors.meteringEquipmentInformation.latitude = decimal3UpTo14DigitsMessage;
        } else if (values.meteringEquipmentInformation.latitude && !checkLatitude(values.meteringEquipmentInformation.latitude))
            errors.meteringEquipmentInformation.latitude = latitudeMessage




        /* bug 3704
            if (values.meteringEquipmentInformation.finalReadingRemovedMeter &&
            values.meteringEquipmentInformation.finalReadingRemovedMeter.length > 100) {
            errors.meteringEquipmentInformation.finalReadingRemovedMeter = MESSAGE_MAX_ALLOWED_CHARCATERS_100
        } */

        if (values.meteringEquipmentInformation.dataCollectorModelNo && values.meteringEquipmentInformation.dataCollectorModelNo.length > 100) {
            errors.meteringEquipmentInformation.dataCollectorModelNo = MESSAGE_MAX_ALLOWED_CHARCATERS_100
        }

        if (values.meteringEquipmentInformation.dataCollectorSerialNo && values.meteringEquipmentInformation.dataCollectorSerialNo.length > 100) {
            errors.meteringEquipmentInformation.dataCollectorSerialNo = MESSAGE_MAX_ALLOWED_CHARCATERS_100
        }

        if (values.meteringEquipmentInformation.dataCollectorSolarPanelSerialNumber && values.meteringEquipmentInformation.dataCollectorSolarPanelSerialNumber.length > 100) {
            errors.meteringEquipmentInformation.dataCollectorSolarPanelSerialNumber = MESSAGE_MAX_ALLOWED_CHARCATERS_100
        }
        if (parseInt(values.meteringEquipmentInformation.meteringSystemTypeId) === parseInt(values.mdata.OpenChannelId)) {




            if (values.meteringEquipmentInformation.channelType && values.meteringEquipmentInformation.channelType.length > 100)
                errors.meteringEquipmentInformation.channelType = MESSAGE_MAX_ALLOWED_CHARCATERS_100;
            if (values.meteringEquipmentInformation.channelWidth && values.meteringEquipmentInformation.channelWidth.length > 100)
                errors.meteringEquipmentInformation.channelWidth = MESSAGE_MAX_ALLOWED_CHARCATERS_100;

            if (values.meteringEquipmentInformation.meterDistanceFromSource && !isNumber(values.meteringEquipmentInformation.meterDistanceFromSource)) {
                errors.meteringEquipmentInformation.meterDistanceFromSource = MESSAGE_REQUIRED_NUMBER;
            }
            else if (values.meteringEquipmentInformation.meterDistanceFromSource && !integerUpto8Digits.test(values.meteringEquipmentInformation.meterDistanceFromSource)) {
                errors.meteringEquipmentInformation.meterDistanceFromSource = integerUpto8DigitsMessage;
            }

            if (values.meteringEquipmentInformation.channelLengthUpstream && !decimal2UpTo8Digits.test(values.meteringEquipmentInformation.channelLengthUpstream)) {
                errors.meteringEquipmentInformation.channelLengthUpstream = decimal2UpTo8DigitsMessage;
            }
            if (values.meteringEquipmentInformation.channelLlengthDownstream && !decimal2UpTo8Digits.test(values.meteringEquipmentInformation.channelLlengthDownstream)) {
                errors.meteringEquipmentInformation.channelLlengthDownstream = decimal2UpTo8DigitsMessage;
            }




            if (values.meteringEquipmentInformation.maxMeasuredDischarge && !decimal2Digits.test(values.meteringEquipmentInformation.maxMeasuredDischarge)) {
                errors.meteringEquipmentInformation.maxMeasuredDischarge = decimal2DigitsMessage;
            }

            /* if(!values.meteringEquipmentInformation.minMeasuredDischarge)
                errors.meteringEquipmentInformation.minMeasuredDischarge = MESSAGE_REQUIRED;
            else  */
            if (values.meteringEquipmentInformation.minMeasuredDischarge && !decimal2Digits.test(values.meteringEquipmentInformation.minMeasuredDischarge)) {
                errors.meteringEquipmentInformation.minMeasuredDischarge = decimal2DigitsMessage;
            }

            /* if(!values.meteringEquipmentInformation.maxMeasuredVelocity)
                errors.meteringEquipmentInformation.maxMeasuredVelocity = MESSAGE_REQUIRED;
            else */
            if (values.meteringEquipmentInformation.maxMeasuredVelocity && !decimal2Digits.test(values.meteringEquipmentInformation.maxMeasuredVelocity)) {
                errors.meteringEquipmentInformation.maxMeasuredVelocity = decimal2DigitsMessage;
            }

            /* if(!values.meteringEquipmentInformation.minMeasuredVelocity)
                errors.meteringEquipmentInformation.minMeasuredVelocity = MESSAGE_REQUIRED;
            else */
            if (values.meteringEquipmentInformation.minMeasuredVelocity && !decimal2Digits.test(values.meteringEquipmentInformation.minMeasuredVelocity)) {
                errors.meteringEquipmentInformation.minMeasuredVelocity = decimal2DigitsMessage;
            }

            /* if(!values.meteringEquipmentInformation.maxGaugeHeight)
                errors.meteringEquipmentInformation.maxGaugeHeight = MESSAGE_REQUIRED;
            else  */
            if (values.meteringEquipmentInformation.maxGaugeHeight && !decimal2Digits.test(values.meteringEquipmentInformation.maxGaugeHeight)) {
                errors.meteringEquipmentInformation.maxGaugeHeight = decimal2DigitsMessage;
            }

            /* if(!values.meteringEquipmentInformation.minGaugeHeight)
                errors.meteringEquipmentInformation.minGaugeHeight = MESSAGE_REQUIRED;
            else */
            if (values.meteringEquipmentInformation.minGaugeHeight && !decimal2Digits.test(values.meteringEquipmentInformation.minGaugeHeight)) {
                errors.meteringEquipmentInformation.minGaugeHeight = decimal2DigitsMessage;
            }

            /* if(!values.meteringEquipmentInformation.maxError)
                errors.meteringEquipmentInformation.maxError = MESSAGE_REQUIRED;
            else */
            if (values.meteringEquipmentInformation.maxError && !decimal2Digits.test(values.meteringEquipmentInformation.maxError)) {
                errors.meteringEquipmentInformation.maxError = decimal2DigitsMessage;
            }

            /* if(!values.meteringEquipmentInformation.minError)
                errors.meteringEquipmentInformation.minError = MESSAGE_REQUIRED;
            else  */
            if (values.meteringEquipmentInformation.minError && !decimal2Digits.test(values.meteringEquipmentInformation.minError)) {
                errors.meteringEquipmentInformation.minError = decimal2DigitsMessage;
            }

            /* if(!values.meteringEquipmentInformation.noOfMeasurementsTaken)
                errors.meteringEquipmentInformation.noOfMeasurementsTaken = MESSAGE_REQUIRED;
            else  */
            if (values.meteringEquipmentInformation.noOfMeasurementsTaken && !isNumber(values.meteringEquipmentInformation.noOfMeasurementsTaken)) {
                errors.meteringEquipmentInformation.noOfMeasurementsTaken = MESSAGE_REQUIRED_NUMBER
            } else if (values.meteringEquipmentInformation.noOfMeasurementsTaken && !integerUpto8Digits.test(values.meteringEquipmentInformation.noOfMeasurementsTaken)) {
                errors.meteringEquipmentInformation.noOfMeasurementsTaken = integerUpto8DigitsMessage;
            }

        }
    }
    else if (clickSaveDraft == 0 && clickNext === 1 && values.meteringEquipmentInformation.formStatus != 2) {
        if (values.meteringEquipmentInformation.newMeterEquipment != null && values.meteringEquipmentInformation.newMeterEquipment == "1") {

            // if (!values.meteringEquipmentInformation.dateOfLastValidation) {
            //     errors.meteringEquipmentInformation.dateOfLastValidation = MESSAGE_REQUIRED
            // }

            if (!values.meteringEquipmentInformation.meteringSystemTypeId) {
                errors.meteringEquipmentInformation.meteringSystemTypeId = MESSAGE_REQUIRED
            }

            if (!values.meteringEquipmentInformation.meterCircumstancesOptions) {
                errors.meteringEquipmentInformation.meterCircumstancesOptions = MESSAGE_REQUIRED
            }
            //commented as per bug 3575
            /* if (!values.meteringEquipmentInformation.meterManufacturer) {
                errors.meteringEquipmentInformation.meterManufacturer = MESSAGE_REQUIRED
            } else if (values.meteringEquipmentInformation.meterManufacturer.length > 100) {
                errors.meteringEquipmentInformation.meterManufacturer = MESSAGE_MAX_ALLOWED_CHARCATERS_100
            } */

            /* if (!values.meteringEquipmentInformation.meterTypeModel) {
                errors.meteringEquipmentInformation.meterTypeModel = MESSAGE_REQUIRED
            } else if (values.meteringEquipmentInformation.meterTypeModel.length > 100) {
                errors.meteringEquipmentInformation.meterTypeModel = MESSAGE_MAX_ALLOWED_CHARCATERS_100
            } */
            if (parseInt(values.meteringEquipmentInformation.meterTypeModelId) == 0) {
                errors.meteringEquipmentInformation.meterTypeModelId = MESSAGE_REQUIRED
            }
            if (parseInt(values.meteringEquipmentInformation.meterTypeModelId) == parseInt(values.mdata.MeterModelTypeOtherId)) {

                if (!values.meteringEquipmentInformation.meterTypeModel) {
                    errors.meteringEquipmentInformation.meterTypeModel = MESSAGE_REQUIRED
                } else if (values.meteringEquipmentInformation.meterTypeModel.length > 100) {
                    errors.meteringEquipmentInformation.meterTypeModel = MESSAGE_MAX_ALLOWED_CHARCATERS_100
                }
            }

            if (!values.meteringEquipmentInformation.meterSerialNo) {
                errors.meteringEquipmentInformation.meterSerialNo = MESSAGE_REQUIRED
            } else if (values.meteringEquipmentInformation.meterSerialNo.length > 100) {
                errors.meteringEquipmentInformation.meterSerialNo = MESSAGE_MAX_ALLOWED_CHARCATERS_100
            }

            if (values.meteringEquipmentInformation.patternApprovalNumber && values.meteringEquipmentInformation.patternApprovalNumber.length > 100) {
                errors.meteringEquipmentInformation.patternApprovalNumber = MESSAGE_MAX_ALLOWED_CHARCATERS_100
            }

            if (!values.meteringEquipmentInformation.meterSize) {
                errors.meteringEquipmentInformation.meterSize = MESSAGE_REQUIRED_NUMBER
            } else if (values.meteringEquipmentInformation.meterSize && !isNumber(values.meteringEquipmentInformation.meterSize)) {
                errors.meteringEquipmentInformation.meterSize = MESSAGE_REQUIRED_NUMBER;
            } else if (values.meteringEquipmentInformation.meterSize && !integer2to4Digits.test(values.meteringEquipmentInformation.meterSize)) {
                errors.meteringEquipmentInformation.meterSize = integer2to4DigitsMessage;
            }

            if (values.meteringEquipmentInformation.pipeType && values.meteringEquipmentInformation.pipeType.length > 100) {
                errors.meteringEquipmentInformation.pipeType = MESSAGE_MAX_ALLOWED_CHARCATERS_100
            }


            if (parseInt(values.meteringEquipmentInformation.meteringSystemTypeId) != parseInt(values.mdata.OpenChannelId)) {
                if (!values.meteringEquipmentInformation.pipeSize) {
                    errors.meteringEquipmentInformation.pipeSize = MESSAGE_REQUIRED_NUMBER
                } else if (values.meteringEquipmentInformation.pipeSize && !isNumber(values.meteringEquipmentInformation.pipeSize)) {
                    errors.meteringEquipmentInformation.pipeSize = MESSAGE_REQUIRED_NUMBER;
                } else if (values.meteringEquipmentInformation.pipeSize && !integer2to4Digits.test(values.meteringEquipmentInformation.pipeSize)) {
                    errors.meteringEquipmentInformation.pipeSize = integer2to4DigitsMessage;
                }
            }

            /* else if (values.meteringEquipmentInformation.pipeSize.length < 2) {
                errors.meteringEquipmentInformation.pipeSize = MESSAGE_MIN_ALLOWED_DIGITS_2_TO_4;
            }
            else if (values.meteringEquipmentInformation.pipeSize.length > 4) {
                errors.meteringEquipmentInformation.pipeSize = MESSAGE_MIN_ALLOWED_DIGITS_2_TO_4;
            } */

            if (values.meteringEquipmentInformation.pipeConnectionType && values.meteringEquipmentInformation.pipeConnectionType.length > 100) {
                errors.meteringEquipmentInformation.pipeConnectionType = MESSAGE_MAX_ALLOWED_CHARCATERS_100
            }


            if (values.meteringEquipmentInformation.pipeLengthUpstream && !positiveIntegerOnly_notMandatory.test(values.meteringEquipmentInformation.pipeLengthUpstream)) {
                errors.meteringEquipmentInformation.pipeLengthUpstream = pipeLengthValidationMessage;
            } else if (values.meteringEquipmentInformation.pipeLengthUpstream && (parseInt(values.meteringEquipmentInformation.pipeLengthUpstream) > 10000)) {
                errors.meteringEquipmentInformation.pipeLengthUpstream = pipeLengthValidationMessage;
            }

            if (values.meteringEquipmentInformation.pipeLengthDownstream && !positiveIntegerOnly_notMandatory.test(values.meteringEquipmentInformation.pipeLengthDownstream)) {
                errors.meteringEquipmentInformation.pipeLengthDownstream = pipeLengthValidationMessage;
            } else if (values.meteringEquipmentInformation.pipeLengthDownstream && (parseInt(values.meteringEquipmentInformation.pipeLengthDownstream) > 10000)) {
                errors.meteringEquipmentInformation.pipeLengthDownstream = pipeLengthValidationMessage;
            }

            if (values.meteringEquipmentInformation.designFlowRate && values.meteringEquipmentInformation.designFlowRate.length > 100) {
                errors.meteringEquipmentInformation.designFlowRate = MESSAGE_MAX_ALLOWED_CHARCATERS_100
            }

            if (values.meteringEquipmentInformation.softwareVersion && values.meteringEquipmentInformation.softwareVersion.length > 100) {
                errors.meteringEquipmentInformation.softwareVersion = MESSAGE_MAX_ALLOWED_CHARCATERS_100
            }

            // if (!values.meteringEquipmentInformation.longitude)
            //     errors.meteringEquipmentInformation.longitude = MESSAGE_REQUIRED_NUMBER
            // else 
            if (values.meteringEquipmentInformation.longitude && !decimal3UpTo14Digits.test(values.meteringEquipmentInformation.longitude)) {
                errors.meteringEquipmentInformation.longitude = decimal3UpTo14DigitsMessage;
            } else if (values.meteringEquipmentInformation.longitude && !checkLongitude(values.meteringEquipmentInformation.longitude))
                errors.meteringEquipmentInformation.longitude = longitudeMessage

            // if (!values.meteringEquipmentInformation.latitude)
            //     errors.meteringEquipmentInformation.latitude = MESSAGE_REQUIRED_NUMBER
            // else 
            if (values.meteringEquipmentInformation.latitude && !decimal3UpTo14Digits.test(values.meteringEquipmentInformation.latitude)) {
                errors.meteringEquipmentInformation.latitude = decimal3UpTo14DigitsMessage;
            } else if (values.meteringEquipmentInformation.longitude && !checkLatitude(values.meteringEquipmentInformation.latitude))
                errors.meteringEquipmentInformation.latitude = latitudeMessage


            if (values.meteringEquipmentInformation.supplyTypeId && parseInt(values.meteringEquipmentInformation.supplyTypeId) == 0) {
                //errors.meteringEquipmentInformation.supplyTypeId = MESSAGE_REQUIRED
            }

            if (values.meteringEquipmentInformation.dataCollectorTypeId && parseInt(values.meteringEquipmentInformation.dataCollectorTypeId) == 0) {
                errors.meteringEquipmentInformation.dataCollectorTypeId = MESSAGE_REQUIRED
            } else if (values.meteringEquipmentInformation.dataCollectorTypeId && parseInt(values.meteringEquipmentInformation.dataCollectorTypeId) != 4) {
                if (!values.meteringEquipmentInformation.dataCollectorModelNo) {
                    errors.meteringEquipmentInformation.dataCollectorModelNo = MESSAGE_REQUIRED
                }
                if (!values.meteringEquipmentInformation.dataCollectorSerialNo) {
                    errors.meteringEquipmentInformation.dataCollectorSerialNo = MESSAGE_REQUIRED
                }
            }

            if (values.meteringEquipmentInformation.sealNo && values.meteringEquipmentInformation.sealNo.length < 1) {
                errors.meteringEquipmentInformation.sealNo = MESSAGE_REQUIRED
            }

            /* bug 3704 
                if (!values.meteringEquipmentInformation.finalReadingRemovedMeter) {
                errors.meteringEquipmentInformation.finalReadingRemovedMeter = MESSAGE_REQUIRED
            } else if (values.meteringEquipmentInformation.finalReadingRemovedMeter.length > 100) {
                errors.meteringEquipmentInformation.finalReadingRemovedMeter = MESSAGE_MAX_ALLOWED_CHARCATERS_100
            }
        
            if (!values.meteringEquipmentInformation.finalReadingDateRemovedMeter) {
                errors.meteringEquipmentInformation.finalReadingDateRemovedMeter = MESSAGE_REQUIRED
            } */

            if (values.meteringEquipmentInformation.dataCollectorTypeId && parseInt(values.meteringEquipmentInformation.dataCollectorTypeId) == 0) {
                errors.meteringEquipmentInformation.dataCollectorTypeId = MESSAGE_REQUIRED
            }

            if (values.meteringEquipmentInformation.dataCollectorModelNo && values.meteringEquipmentInformation.dataCollectorModelNo.length > 100) {
                errors.meteringEquipmentInformation.dataCollectorModelNo = MESSAGE_MAX_ALLOWED_CHARCATERS_100
            }

            if (values.meteringEquipmentInformation.dataCollectorSerialNo && values.meteringEquipmentInformation.dataCollectorSerialNo.length > 100) {
                errors.meteringEquipmentInformation.dataCollectorSerialNo = MESSAGE_MAX_ALLOWED_CHARCATERS_100
            }

            if (values.meteringEquipmentInformation.dataCollectorSolarPanelSerialNumber && values.meteringEquipmentInformation.dataCollectorSolarPanelSerialNumber.length > 100) {
                errors.meteringEquipmentInformation.dataCollectorSolarPanelSerialNumber = MESSAGE_MAX_ALLOWED_CHARCATERS_100
            }
            if (parseInt(values.meteringEquipmentInformation.meteringSystemTypeId) === parseInt(values.mdata.OpenChannelId)) {


                if (!values.meteringEquipmentInformation.channelType) {
                    errors.meteringEquipmentInformation.channelType = MESSAGE_REQUIRED
                }
                if (!values.meteringEquipmentInformation.channelWidth) {
                    errors.meteringEquipmentInformation.channelWidth = MESSAGE_REQUIRED
                }
                if (!values.meteringEquipmentInformation.meterDistanceFromSource) {
                    errors.meteringEquipmentInformation.meterDistanceFromSource = MESSAGE_REQUIRED
                }
                if (!values.meteringEquipmentInformation.channelLengthUpstream) {
                    errors.meteringEquipmentInformation.channelLengthUpstream = MESSAGE_REQUIRED
                }
                if (!values.meteringEquipmentInformation.channelLlengthDownstream) {
                    errors.meteringEquipmentInformation.channelLlengthDownstream = MESSAGE_REQUIRED
                }


                if (values.meteringEquipmentInformation.channelType && values.meteringEquipmentInformation.channelType.length > 100)
                    errors.meteringEquipmentInformation.channelType = MESSAGE_MAX_ALLOWED_CHARCATERS_100;
                if (values.meteringEquipmentInformation.channelWidth && values.meteringEquipmentInformation.channelWidth.length > 100)
                    errors.meteringEquipmentInformation.channelWidth = MESSAGE_MAX_ALLOWED_CHARCATERS_100;

                if (values.meteringEquipmentInformation.meterDistanceFromSource && !isNumber(values.meteringEquipmentInformation.meterDistanceFromSource)) {
                    errors.meteringEquipmentInformation.meterDistanceFromSource = MESSAGE_REQUIRED_NUMBER;
                } else if (values.meteringEquipmentInformation.meterDistanceFromSource && !integerUpto8Digits.test(values.meteringEquipmentInformation.meterDistanceFromSource)) {
                    errors.meteringEquipmentInformation.meterDistanceFromSource = integerUpto8DigitsMessage;
                }
                if (values.meteringEquipmentInformation.channelLengthUpstream && !decimal2UpTo8Digits.test(values.meteringEquipmentInformation.channelLengthUpstream)) {
                    errors.meteringEquipmentInformation.channelLengthUpstream = decimal2UpTo8DigitsMessage;
                }
                if (values.meteringEquipmentInformation.channelLlengthDownstream && !decimal2UpTo8Digits.test(values.meteringEquipmentInformation.channelLlengthDownstream)) {
                    errors.meteringEquipmentInformation.channelLlengthDownstream = decimal2UpTo8DigitsMessage;
                }



                if (values.meteringEquipmentInformation.maxMeasuredDischarge && !decimal2Digits.test(values.meteringEquipmentInformation.maxMeasuredDischarge)) {
                    errors.meteringEquipmentInformation.maxMeasuredDischarge = decimal2DigitsMessage;
                }

                /* if(!values.meteringEquipmentInformation.minMeasuredDischarge)
                    errors.meteringEquipmentInformation.minMeasuredDischarge = MESSAGE_REQUIRED;
                else  */
                if (values.meteringEquipmentInformation.minMeasuredDischarge && !decimal2Digits.test(values.meteringEquipmentInformation.minMeasuredDischarge)) {
                    errors.meteringEquipmentInformation.minMeasuredDischarge = decimal2DigitsMessage;
                }

                /* if(!values.meteringEquipmentInformation.maxMeasuredVelocity)
                    errors.meteringEquipmentInformation.maxMeasuredVelocity = MESSAGE_REQUIRED;
                else */
                if (values.meteringEquipmentInformation.maxMeasuredVelocity && !decimal2Digits.test(values.meteringEquipmentInformation.maxMeasuredVelocity)) {
                    errors.meteringEquipmentInformation.maxMeasuredVelocity = decimal2DigitsMessage;
                }

                /* if(!values.meteringEquipmentInformation.minMeasuredVelocity)
                    errors.meteringEquipmentInformation.minMeasuredVelocity = MESSAGE_REQUIRED;
                else */
                if (values.meteringEquipmentInformation.minMeasuredVelocity && !decimal2Digits.test(values.meteringEquipmentInformation.minMeasuredVelocity)) {
                    errors.meteringEquipmentInformation.minMeasuredVelocity = decimal2DigitsMessage;
                }

                /* if(!values.meteringEquipmentInformation.maxGaugeHeight)
                    errors.meteringEquipmentInformation.maxGaugeHeight = MESSAGE_REQUIRED;
                else  */
                if (values.meteringEquipmentInformation.maxGaugeHeight && !decimal2Digits.test(values.meteringEquipmentInformation.maxGaugeHeight)) {
                    errors.meteringEquipmentInformation.maxGaugeHeight = decimal2DigitsMessage;
                }

                /* if(!values.meteringEquipmentInformation.minGaugeHeight)
                    errors.meteringEquipmentInformation.minGaugeHeight = MESSAGE_REQUIRED;
                else */
                if (values.meteringEquipmentInformation.minGaugeHeight && !decimal2Digits.test(values.meteringEquipmentInformation.minGaugeHeight)) {
                    errors.meteringEquipmentInformation.minGaugeHeight = decimal2DigitsMessage;
                }

                /* if(!values.meteringEquipmentInformation.maxError)
                    errors.meteringEquipmentInformation.maxError = MESSAGE_REQUIRED;
                else */
                if (values.meteringEquipmentInformation.maxError && !decimal2Digits.test(values.meteringEquipmentInformation.maxError)) {
                    errors.meteringEquipmentInformation.maxError = decimal2DigitsMessage;
                }

                /* if(!values.meteringEquipmentInformation.minError)
                    errors.meteringEquipmentInformation.minError = MESSAGE_REQUIRED;
                else  */
                if (values.meteringEquipmentInformation.minError && !decimal2Digits.test(values.meteringEquipmentInformation.minError)) {
                    errors.meteringEquipmentInformation.minError = decimal2DigitsMessage;
                }

                /* if(!values.meteringEquipmentInformation.noOfMeasurementsTaken)
                    errors.meteringEquipmentInformation.noOfMeasurementsTaken = MESSAGE_REQUIRED;
                else  */
                if (values.meteringEquipmentInformation.noOfMeasurementsTaken && !isNumber(values.meteringEquipmentInformation.noOfMeasurementsTaken)) {
                    errors.meteringEquipmentInformation.noOfMeasurementsTaken = MESSAGE_REQUIRED_NUMBER
                } else if (values.meteringEquipmentInformation.noOfMeasurementsTaken && !integerUpto8Digits.test(values.meteringEquipmentInformation.noOfMeasurementsTaken)) {
                    errors.meteringEquipmentInformation.noOfMeasurementsTaken = integerUpto8DigitsMessage;
                }

            }

        }

    }
    //alert("checking error");

    console.log(errors);
    return errors;
}

export const validateCertificatePartD = (values, params) => {

    const closedConduitandProtableOptions =
        [
            { id: "3", name: "Meter marker plate", selected: false },
            { id: "4", name: "Evidence of tamper proofing/Meter seals", selected: false },
            { id: "5", name: "Meter body and type", selected: false },
            { id: "6", name: "Functioning display/readout panel showing meter reading", selected: false },
            { id: "7", name: "Site photo showing whole installation", selected: false },
            { id: "11", name: "Other", selected: false }
        ];

    const openChannelOptions =
        [
            //{ id: "3", name: "Meter marker plate", selected: false },
            { id: "4", name: "Evidence of tamper proofing/Meter seals", selected: false },
            { id: "5", name: "Meter body and type", selected: false },
            { id: "6", name: "Functioning display/readout panel showing meter reading", selected: false },
            { id: "7", name: "Site photo showing whole installation", selected: false },
            { id: "8", name: "Sensor location", selected: false },
            { id: "9", name: "Channel looking upstream", selected: false },
            { id: "10", name: "Channel looking downstream", selected: false },
            { id: "11", name: "Other", selected: false },
        ];

    const errors = {

        meteringEquipmentInformation: {

            meterCircumstancesOptions: "",// [required()],//text
            dateOfLastValidation: "",//[date({ format: 'mm/dd/yyyy' })],//date
            meteringSystemTypeId: "",//[required(),numericality({int:true,'>':0})],//number
            //meteringSystemType: [required()],//text
            meterManufacturer: "",//[required()],//text
            meterTypeModel: "",//[required()],//text
            meterSerialNo: "",// [required()],//text
            meterSize: "",//[required(),numericality({int:true}),length({min:2,max:4})], //number
            //pipeType: ,//text
            pipeSize: "",//[required(),numericality({int:true}),length({min:2,max:4})] ,//number
            //pipeConnectionType:,//decimal
            pipeLengthUpstream: "",//[format({ with: decimal2UpTo2Digits, message: decimal2UpTo2DigitsMessage })],//decimal
            pipeLengthDownstream: "",// [format({ with: decimal2UpTo2Digits, message: decimal2UpTo2DigitsMessage })],//decimal
            //pipeLengthDownstream: [twonondecimalupto2decimal.test()],//decimal
            longitude: "",//[format({ with: decimal3UpTo6Digits, message: decimal3UpTo6DigitsMessage }),numericality({'<=': 141})],//decimal
            latitude: "",//[format({ with: decimal3UpTo6Digits, message: decimal3UpTo6DigitsMessage }),numericality({'<=': 34,'>=':29})],//decimal

            //channelType:[],//text
            // channelWidth:[],//text
            // meterDistanceFromSource:[],//number
            // channelLengthUpstream:[format({ with: decimal2UpTo2Digits, message: decimal2UpTo2DigitsMessage })],//decimal
            // channelLlengthDownstream:[format({ with: decimal2UpTo2Digits, message: decimal2UpTo2DigitsMessage })],//number

            channelType: "",
            channelWidth: "",
            meterDistanceFromSource: "",
            channelLengthUpstream: "",
            channelLlengthDownstream: "",
            meterTestCertificate: "",
            meterq1FlowRateMinimum: "",
            meterReading: "",
            dateOfLastValidation: "",
            finalReadingRemovedMeter: "",
            finalReadingDateRemovedMeter: "",
            isModificationToCompliance: "",
            //documentError: "",

            designEquipmentDetails: "",
            meteringEquipmentConsistent: "",
            dataCollectorTypeId: "",
            dataCollectorModelNo: "",
            dataCollectorSerialNo: "",
            sealNo: ""


        },
        meterNonComplianceReason: [{
            reason: '',
            comments: '',
        }],
        meterNonCompliance: {
            modificationsPerformed: '',
        },

    }

    console.log("in accuracy validation");
    let clickSaveDraft = params.clickSaveDraft
    let clickNext = params.clickNext;
    if (clickSaveDraft == undefined)
        clickSaveDraft = values.clickSaveDraft;
    if (clickNext == undefined)
        clickNext = values.clickNext;
    console.log("draft:" + clickSaveDraft);

    if (values.meteringEquipmentInformation.dataCollectorTypeId && parseInt(values.meteringEquipmentInformation.dataCollectorTypeId) == 4) {
        values.meteringEquipmentInformation.dataCollectorModelNo = null;
        values.meteringEquipmentInformation.dataCollectorSerialNo = null;
        values.meteringEquipmentInformation.dataCollectorPowerSourceId = null;
        values.meteringEquipmentInformation.dataCollectorSolarPanelSerialNumber = null;
    }

    if (clickSaveDraft === 1 && clickNext === 0) {
        //commented as per bug 3575
        /* if (values.meteringEquipmentInformation.meterManufacturer &&
            values.meteringEquipmentInformation.meterManufacturer.length > 100) {
            errors.meteringEquipmentInformation.meterManufacturer = MESSAGE_MAX_ALLOWED_CHARCATERS_100
        } */

        /* if (values.meteringEquipmentInformation.meterTypeModel &&
            values.meteringEquipmentInformation.meterTypeModel.length > 100) {
            errors.meteringEquipmentInformation.meterTypeModel = MESSAGE_MAX_ALLOWED_CHARCATERS_100
        } */

        if (values.meteringEquipmentInformation.readingBeforeMaintenance && !isNumber(values.meteringEquipmentInformation.readingBeforeMaintenance)) {
            errors.meteringEquipmentInformation.readingBeforeMaintenance = MESSAGE_REQUIRED_NUMBER;
        } else if (values.meteringEquipmentInformation.readingBeforeMaintenance && !decimal4Digits.test(values.meteringEquipmentInformation.readingBeforeMaintenance)) {
            errors.meteringEquipmentInformation.readingBeforeMaintenance = decimal4DigitsMessage;
        }

        if (values.meteringEquipmentInformation.readingAfterMaintenance && !isNumber(values.meteringEquipmentInformation.readingAfterMaintenance)) {
            errors.meteringEquipmentInformation.readingAfterMaintenance = MESSAGE_REQUIRED_NUMBER;
        } else if (values.meteringEquipmentInformation.readingAfterMaintenance && !decimal4Digits.test(values.meteringEquipmentInformation.readingAfterMaintenance)) {
            errors.meteringEquipmentInformation.readingAfterMaintenance = decimal4DigitsMessage;
        }

        if (parseInt(values.meteringEquipmentInformation.meterTypeModelId) == parseInt(values.mdata.MeterModelTypeOtherId)) {

            if (!values.meteringEquipmentInformation.meterTypeModel) {
                errors.meteringEquipmentInformation.meterTypeModel = MESSAGE_REQUIRED
            } else if (values.meteringEquipmentInformation.meterTypeModel.length > 100) {
                errors.meteringEquipmentInformation.meterTypeModel = MESSAGE_MAX_ALLOWED_CHARCATERS_100
            }
        }

        if (values.meteringEquipmentInformation.meterSerialNo &&
            values.meteringEquipmentInformation.meterSerialNo.length > 100) {
            errors.meteringEquipmentInformation.meterSerialNo = MESSAGE_MAX_ALLOWED_CHARCATERS_100
        }

        if (values.meteringEquipmentInformation.patternApprovalNumber &&
            values.meteringEquipmentInformation.patternApprovalNumber.length > 100) {
            errors.meteringEquipmentInformation.patternApprovalNumber = MESSAGE_MAX_ALLOWED_CHARCATERS_100
        }

        if (values.meteringEquipmentInformation.meterSize) {
            if (!isNumber(values.meteringEquipmentInformation.meterSize)) {
                errors.meteringEquipmentInformation.meterSize = MESSAGE_REQUIRED_NUMBER;
            } else if (values.meteringEquipmentInformation.meterSize && !isNumber(values.meteringEquipmentInformation.meterSize)) {
                errors.meteringEquipmentInformation.meterSize = MESSAGE_REQUIRED_NUMBER;
            } else if (values.meteringEquipmentInformation.meterSize && !integer2to4Digits.test(values.meteringEquipmentInformation.meterSize)) {
                errors.meteringEquipmentInformation.meterSize = integer2to4DigitsMessage;
            }
        }


        if (values.meteringEquipmentInformation.pipeType && values.meteringEquipmentInformation.pipeType.length > 100) {
            errors.meteringEquipmentInformation.pipeType = MESSAGE_MAX_ALLOWED_CHARCATERS_100
        }

        if (values.meteringEquipmentInformation.pipeSize) {
            if (values.meteringEquipmentInformation.pipeSize && !isNumber(values.meteringEquipmentInformation.pipeSize)) {
                errors.meteringEquipmentInformation.pipeSize = MESSAGE_REQUIRED_NUMBER;
            } else if (values.meteringEquipmentInformation.pipeSize && !integer2to4Digits.test(values.meteringEquipmentInformation.pipeSize)) {
                errors.meteringEquipmentInformation.pipeSize = integer2to4DigitsMessage;
            }

            /* else if (values.meteringEquipmentInformation.pipeSize.length < 2) {
                errors.meteringEquipmentInformation.pipeSize = MESSAGE_MIN_ALLOWED_DIGITS_2_TO_4;
            }
            else if (values.meteringEquipmentInformation.pipeSize.length > 4) {
                errors.meteringEquipmentInformation.pipeSize = MESSAGE_MIN_ALLOWED_DIGITS_2_TO_4;
            } */
        }

        if (values.meteringEquipmentInformation.pipeConnectionType
            && values.meteringEquipmentInformation.pipeConnectionType.length > 100) {
            errors.meteringEquipmentInformation.pipeConnectionType = MESSAGE_MAX_ALLOWED_CHARCATERS_100
        }


        if (values.meteringEquipmentInformation.pipeLengthUpstream && !positiveIntegerOnly_notMandatory.test(values.meteringEquipmentInformation.pipeLengthUpstream)) {
            errors.meteringEquipmentInformation.pipeLengthUpstream = pipeLengthValidationMessage;
        } else if (values.meteringEquipmentInformation.pipeLengthUpstream && (parseInt(values.meteringEquipmentInformation.pipeLengthUpstream) > 10000)) {
            errors.meteringEquipmentInformation.pipeLengthUpstream = pipeLengthValidationMessage;
        }

        if (values.meteringEquipmentInformation.pipeLengthDownstream && !positiveIntegerOnly_notMandatory.test(values.meteringEquipmentInformation.pipeLengthDownstream)) {
            errors.meteringEquipmentInformation.pipeLengthDownstream = pipeLengthValidationMessage;
        } else if (values.meteringEquipmentInformation.pipeLengthDownstream && (parseInt(values.meteringEquipmentInformation.pipeLengthDownstream) > 10000)) {
            errors.meteringEquipmentInformation.pipeLengthDownstream = pipeLengthValidationMessage;
        }





        if (values.meteringEquipmentInformation.designFlowRate &&
            values.meteringEquipmentInformation.designFlowRate.length > 100) {
            errors.meteringEquipmentInformation.designFlowRate = MESSAGE_MAX_ALLOWED_CHARCATERS_100
        }

        if (values.meteringEquipmentInformation.softwareVersion &&
            values.meteringEquipmentInformation.softwareVersion.length > 100) {
            errors.meteringEquipmentInformation.softwareVersion = MESSAGE_MAX_ALLOWED_CHARCATERS_100
        }

        if (values.meteringEquipmentInformation.longitude && !decimal3UpTo14Digits.test(values.meteringEquipmentInformation.longitude)) {
            errors.meteringEquipmentInformation.longitude = decimal3UpTo14DigitsMessage;
        } else if (values.meteringEquipmentInformation.longitude && !checkLongitude(values.meteringEquipmentInformation.longitude))
            errors.meteringEquipmentInformation.longitude = longitudeMessage

        if (values.meteringEquipmentInformation.latitude && !decimal3UpTo14Digits.test(values.meteringEquipmentInformation.latitude)) {
            errors.meteringEquipmentInformation.latitude = decimal3UpTo14DigitsMessage;
        } else if (values.meteringEquipmentInformation.latitude && !checkLatitude(values.meteringEquipmentInformation.latitude))
            errors.meteringEquipmentInformation.latitude = latitudeMessage



        if (values.meteringEquipmentInformation.finalReadingRemovedMeter && !isNumber(values.meteringEquipmentInformation.finalReadingRemovedMeter)) {
            errors.meteringEquipmentInformation.finalReadingRemovedMeter = MESSAGE_REQUIRED_NUMBER;
        } else if (values.meteringEquipmentInformation.finalReadingRemovedMeter && !decimal4Digits.test(values.meteringEquipmentInformation.finalReadingRemovedMeter)) {
            errors.meteringEquipmentInformation.finalReadingRemovedMeter = decimal4DigitsMessage;
        }

        /* if (values.meteringEquipmentInformation.finalReadingRemovedMeter &&
            values.meteringEquipmentInformation.finalReadingRemovedMeter.length > 100) {
            errors.meteringEquipmentInformation.finalReadingRemovedMeter = MESSAGE_MAX_ALLOWED_CHARCATERS_100
        } */

        if (values.meteringEquipmentInformation.dataCollectorModelNo && values.meteringEquipmentInformation.dataCollectorModelNo.length > 100) {
            errors.meteringEquipmentInformation.dataCollectorModelNo = MESSAGE_MAX_ALLOWED_CHARCATERS_100
        }

        if (values.meteringEquipmentInformation.dataCollectorSerialNo && values.meteringEquipmentInformation.dataCollectorSerialNo.length > 100) {
            errors.meteringEquipmentInformation.dataCollectorSerialNo = MESSAGE_MAX_ALLOWED_CHARCATERS_100
        }

        if (values.meteringEquipmentInformation.dataCollectorSolarPanelSerialNumber && values.meteringEquipmentInformation.dataCollectorSolarPanelSerialNumber.length > 100) {
            errors.meteringEquipmentInformation.dataCollectorSolarPanelSerialNumber = MESSAGE_MAX_ALLOWED_CHARCATERS_100
        }
        if (parseInt(values.meteringEquipmentInformation.meteringSystemTypeId) === parseInt(values.mdata.OpenChannelId)) {

            if (values.meteringEquipmentInformation.channelType && values.meteringEquipmentInformation.channelType.length > 100)
                errors.meteringEquipmentInformation.channelType = MESSAGE_MAX_ALLOWED_CHARCATERS_100;
            if (values.meteringEquipmentInformation.channelWidth && values.meteringEquipmentInformation.channelWidth.length > 100)
                errors.meteringEquipmentInformation.channelWidth = MESSAGE_MAX_ALLOWED_CHARCATERS_100;

            if (values.meteringEquipmentInformation.meterDistanceFromSource && !isNumber(values.meteringEquipmentInformation.meterDistanceFromSource)) {
                errors.meteringEquipmentInformation.meterDistanceFromSource = MESSAGE_REQUIRED_NUMBER;
            } else if (values.meteringEquipmentInformation.meterDistanceFromSource && !integerUpto8Digits.test(values.meteringEquipmentInformation.meterDistanceFromSource)) {
                errors.meteringEquipmentInformation.meterDistanceFromSource = integerUpto8DigitsMessage;
            }

            if (values.meteringEquipmentInformation.channelLengthUpstream && !decimal2UpTo8Digits.test(values.meteringEquipmentInformation.channelLengthUpstream)) {
                errors.meteringEquipmentInformation.channelLengthUpstream = decimal2UpTo8DigitsMessage;
            }
            if (values.meteringEquipmentInformation.channelLlengthDownstream && !decimal2UpTo8Digits.test(values.meteringEquipmentInformation.channelLlengthDownstream)) {
                errors.meteringEquipmentInformation.channelLlengthDownstream = decimal2UpTo8DigitsMessage;
            }



            if (values.meteringEquipmentInformation.maxMeasuredDischarge && !decimal2Digits.test(values.meteringEquipmentInformation.maxMeasuredDischarge)) {
                errors.meteringEquipmentInformation.maxMeasuredDischarge = decimal2DigitsMessage;
            }

            /* if(!values.meteringEquipmentInformation.minMeasuredDischarge)
                errors.meteringEquipmentInformation.minMeasuredDischarge = MESSAGE_REQUIRED;
            else  */
            if (values.meteringEquipmentInformation.minMeasuredDischarge && !decimal2Digits.test(values.meteringEquipmentInformation.minMeasuredDischarge)) {
                errors.meteringEquipmentInformation.minMeasuredDischarge = decimal2DigitsMessage;
            }

            /* if(!values.meteringEquipmentInformation.maxMeasuredVelocity)
                errors.meteringEquipmentInformation.maxMeasuredVelocity = MESSAGE_REQUIRED;
            else */
            if (values.meteringEquipmentInformation.maxMeasuredVelocity && !decimal2Digits.test(values.meteringEquipmentInformation.maxMeasuredVelocity)) {
                errors.meteringEquipmentInformation.maxMeasuredVelocity = decimal2DigitsMessage;
            }

            /* if(!values.meteringEquipmentInformation.minMeasuredVelocity)
                errors.meteringEquipmentInformation.minMeasuredVelocity = MESSAGE_REQUIRED;
            else */
            if (values.meteringEquipmentInformation.minMeasuredVelocity && !decimal2Digits.test(values.meteringEquipmentInformation.minMeasuredVelocity)) {
                errors.meteringEquipmentInformation.minMeasuredVelocity = decimal2DigitsMessage;
            }

            /* if(!values.meteringEquipmentInformation.maxGaugeHeight)
                errors.meteringEquipmentInformation.maxGaugeHeight = MESSAGE_REQUIRED;
            else  */
            if (values.meteringEquipmentInformation.maxGaugeHeight && !decimal2Digits.test(values.meteringEquipmentInformation.maxGaugeHeight)) {
                errors.meteringEquipmentInformation.maxGaugeHeight = decimal2DigitsMessage;
            }

            /* if(!values.meteringEquipmentInformation.minGaugeHeight)
                errors.meteringEquipmentInformation.minGaugeHeight = MESSAGE_REQUIRED;
            else */
            if (values.meteringEquipmentInformation.minGaugeHeight && !decimal2Digits.test(values.meteringEquipmentInformation.minGaugeHeight)) {
                errors.meteringEquipmentInformation.minGaugeHeight = decimal2DigitsMessage;
            }

            /* if(!values.meteringEquipmentInformation.maxError)
                errors.meteringEquipmentInformation.maxError = MESSAGE_REQUIRED;
            else */
            if (values.meteringEquipmentInformation.maxError && !decimal2Digits.test(values.meteringEquipmentInformation.maxError)) {
                errors.meteringEquipmentInformation.maxError = decimal2DigitsMessage;
            }

            /* if(!values.meteringEquipmentInformation.minError)
                errors.meteringEquipmentInformation.minError = MESSAGE_REQUIRED;
            else  */
            if (values.meteringEquipmentInformation.minError && !decimal2Digits.test(values.meteringEquipmentInformation.minError)) {
                errors.meteringEquipmentInformation.minError = decimal2DigitsMessage;
            }

            /* if(!values.meteringEquipmentInformation.noOfMeasurementsTaken)
                errors.meteringEquipmentInformation.noOfMeasurementsTaken = MESSAGE_REQUIRED;
            else  */
            if (values.meteringEquipmentInformation.noOfMeasurementsTaken && !isNumber(values.meteringEquipmentInformation.noOfMeasurementsTaken)) {
                errors.meteringEquipmentInformation.noOfMeasurementsTaken = MESSAGE_REQUIRED_NUMBER
            } else if (values.meteringEquipmentInformation.noOfMeasurementsTaken && !integerUpto8Digits.test(values.meteringEquipmentInformation.noOfMeasurementsTaken)) {
                errors.meteringEquipmentInformation.noOfMeasurementsTaken = integerUpto8DigitsMessage;
            }

        }
    }
    else if (clickSaveDraft == 0 && clickNext === 1 && values.meteringEquipmentInformation.formStatus != 2) {

        if (values.meteringEquipmentInformation.readingBeforeMaintenance && !isNumber(values.meteringEquipmentInformation.readingBeforeMaintenance)) {
            errors.meteringEquipmentInformation.readingBeforeMaintenance = MESSAGE_REQUIRED_NUMBER;
        } else if (values.meteringEquipmentInformation.readingBeforeMaintenance && !decimal4Digits.test(values.meteringEquipmentInformation.readingBeforeMaintenance)) {
            errors.meteringEquipmentInformation.readingBeforeMaintenance = decimal4DigitsMessage;
        }

        if (values.meteringEquipmentInformation.readingAfterMaintenance && !isNumber(values.meteringEquipmentInformation.readingAfterMaintenance)) {
            errors.meteringEquipmentInformation.readingAfterMaintenance = MESSAGE_REQUIRED_NUMBER;
        } else if (values.meteringEquipmentInformation.readingAfterMaintenance && !decimal4Digits.test(values.meteringEquipmentInformation.readingAfterMaintenance)) {
            errors.meteringEquipmentInformation.readingAfterMaintenance = decimal4DigitsMessage;
        }

        if (!values.meteringEquipmentInformation.meteringSystemTypeId) {
            errors.meteringEquipmentInformation.meteringSystemTypeId = MESSAGE_REQUIRED
        }

        if (!values.meteringEquipmentInformation.meterCircumstancesOptions) {
            errors.meteringEquipmentInformation.meterCircumstancesOptions = MESSAGE_REQUIRED
        }

        if (parseInt(values.meteringEquipmentInformation.meterTypeModelId) == 0) {
            errors.meteringEquipmentInformation.meterTypeModelId = MESSAGE_REQUIRED
        }
        if (parseInt(values.meteringEquipmentInformation.meterTypeModelId) == parseInt(values.mdata.MeterModelTypeOtherId)) {

            if (!values.meteringEquipmentInformation.meterTypeModel) {
                errors.meteringEquipmentInformation.meterTypeModel = MESSAGE_REQUIRED
            } else if (values.meteringEquipmentInformation.meterTypeModel.length > 100) {
                errors.meteringEquipmentInformation.meterTypeModel = MESSAGE_MAX_ALLOWED_CHARCATERS_100
            }
        }

        if (!values.meteringEquipmentInformation.meterSerialNo) {
            errors.meteringEquipmentInformation.meterSerialNo = MESSAGE_REQUIRED
        } else if (values.meteringEquipmentInformation.meterSerialNo.length > 100) {
            errors.meteringEquipmentInformation.meterSerialNo = MESSAGE_MAX_ALLOWED_CHARCATERS_100
        }

        if (values.meteringEquipmentInformation.patternApprovalNumber && values.meteringEquipmentInformation.patternApprovalNumber.length > 100) {
            errors.meteringEquipmentInformation.patternApprovalNumber = MESSAGE_MAX_ALLOWED_CHARCATERS_100
        }

        if (!values.meteringEquipmentInformation.meterSize) {
            errors.meteringEquipmentInformation.meterSize = MESSAGE_REQUIRED_NUMBER
        } else if (values.meteringEquipmentInformation.meterSize && !isNumber(values.meteringEquipmentInformation.meterSize)) {
            errors.meteringEquipmentInformation.meterSize = MESSAGE_REQUIRED_NUMBER;
        } else if (values.meteringEquipmentInformation.meterSize && !integer2to4Digits.test(values.meteringEquipmentInformation.meterSize)) {
            errors.meteringEquipmentInformation.meterSize = integer2to4DigitsMessage;
        }

        if (values.meteringEquipmentInformation.pipeType && values.meteringEquipmentInformation.pipeType.length > 100) {
            errors.meteringEquipmentInformation.pipeType = MESSAGE_MAX_ALLOWED_CHARCATERS_100
        }

        if (parseInt(values.meteringEquipmentInformation.meteringSystemTypeId) != parseInt(values.mdata.OpenChannelId)) {
            if (!values.meteringEquipmentInformation.pipeSize) {
                errors.meteringEquipmentInformation.pipeSize = MESSAGE_REQUIRED_NUMBER
            } else if (values.meteringEquipmentInformation.pipeSize && !isNumber(values.meteringEquipmentInformation.pipeSize)) {
                errors.meteringEquipmentInformation.pipeSize = MESSAGE_REQUIRED_NUMBER;
            } else if (values.meteringEquipmentInformation.pipeSize && !integer2to4Digits.test(values.meteringEquipmentInformation.pipeSize)) {
                errors.meteringEquipmentInformation.pipeSize = integer2to4DigitsMessage;
            }
        }

        if (values.meteringEquipmentInformation.pipeConnectionType && values.meteringEquipmentInformation.pipeConnectionType.length > 100) {
            errors.meteringEquipmentInformation.pipeConnectionType = MESSAGE_MAX_ALLOWED_CHARCATERS_100
        }


        if (values.meteringEquipmentInformation.pipeLengthUpstream && !positiveIntegerOnly_notMandatory.test(values.meteringEquipmentInformation.pipeLengthUpstream)) {
            errors.meteringEquipmentInformation.pipeLengthUpstream = pipeLengthValidationMessage;
        } else if (values.meteringEquipmentInformation.pipeLengthUpstream && (parseInt(values.meteringEquipmentInformation.pipeLengthUpstream) > 10000)) {
            errors.meteringEquipmentInformation.pipeLengthUpstream = pipeLengthValidationMessage;
        }

        if (values.meteringEquipmentInformation.pipeLengthDownstream && !positiveIntegerOnly_notMandatory.test(values.meteringEquipmentInformation.pipeLengthDownstream)) {
            errors.meteringEquipmentInformation.pipeLengthDownstream = pipeLengthValidationMessage;
        } else if (values.meteringEquipmentInformation.pipeLengthDownstream && (parseInt(values.meteringEquipmentInformation.pipeLengthDownstream) > 10000)) {
            errors.meteringEquipmentInformation.pipeLengthDownstream = pipeLengthValidationMessage;
        }

        if (values.meteringEquipmentInformation.designFlowRate && values.meteringEquipmentInformation.designFlowRate.length > 100) {
            errors.meteringEquipmentInformation.designFlowRate = MESSAGE_MAX_ALLOWED_CHARCATERS_100
        }

        if (values.meteringEquipmentInformation.softwareVersion && values.meteringEquipmentInformation.softwareVersion.length > 100) {
            errors.meteringEquipmentInformation.softwareVersion = MESSAGE_MAX_ALLOWED_CHARCATERS_100
        }

        // if (!values.meteringEquipmentInformation.longitude)
        //     errors.meteringEquipmentInformation.longitude = MESSAGE_REQUIRED_NUMBER
        // else 
        if (values.meteringEquipmentInformation.longitude && !decimal3UpTo14Digits.test(values.meteringEquipmentInformation.longitude)) {
            errors.meteringEquipmentInformation.longitude = decimal3UpTo14DigitsMessage;
        } else if (values.meteringEquipmentInformation.longitude && !checkLongitude(values.meteringEquipmentInformation.longitude))
            errors.meteringEquipmentInformation.longitude = longitudeMessage

        // if (!values.meteringEquipmentInformation.latitude)
        //     errors.meteringEquipmentInformation.latitude = MESSAGE_REQUIRED_NUMBER
        // else 
        if (values.meteringEquipmentInformation.latitude && !decimal3UpTo14Digits.test(values.meteringEquipmentInformation.latitude)) {
            errors.meteringEquipmentInformation.latitude = decimal3UpTo14DigitsMessage;
        } else if (values.meteringEquipmentInformation.latitude && !checkLatitude(values.meteringEquipmentInformation.latitude))
            errors.meteringEquipmentInformation.latitude = latitudeMessage

        let dataCollectorTypeIdExists = (values.meteringEquipmentInformation.dataCollectorTypeId != undefined && values.meteringEquipmentInformation.dataCollectorTypeId != null)
        if (dataCollectorTypeIdExists && parseInt(values.meteringEquipmentInformation.dataCollectorTypeId) == 0) {
            errors.meteringEquipmentInformation.dataCollectorTypeId = MESSAGE_REQUIRED
        } else if (values.meteringEquipmentInformation.dataCollectorTypeId && parseInt(values.meteringEquipmentInformation.dataCollectorTypeId) != 4) {
            if (!values.meteringEquipmentInformation.dataCollectorModelNo) {
                errors.meteringEquipmentInformation.dataCollectorModelNo = MESSAGE_REQUIRED
            }
            if (!values.meteringEquipmentInformation.dataCollectorSerialNo) {
                errors.meteringEquipmentInformation.dataCollectorSerialNo = MESSAGE_REQUIRED
            }
        }

        if (values.meteringEquipmentInformation.sealNo && values.meteringEquipmentInformation.sealNo.length < 1) {
            errors.meteringEquipmentInformation.sealNo = MESSAGE_REQUIRED
        }


        if (values.meteringEquipmentInformation.finalReadingRemovedMeter && !isNumber(values.meteringEquipmentInformation.finalReadingRemovedMeter)) {
            errors.meteringEquipmentInformation.finalReadingRemovedMeter = MESSAGE_REQUIRED_NUMBER;
        } else if (values.meteringEquipmentInformation.finalReadingRemovedMeter && !decimal4Digits.test(values.meteringEquipmentInformation.finalReadingRemovedMeter)) {
            errors.meteringEquipmentInformation.finalReadingRemovedMeter = decimal4DigitsMessage;
        }


        if (values.meteringEquipmentInformation.dataCollectorModelNo && values.meteringEquipmentInformation.dataCollectorModelNo.length > 100) {
            errors.meteringEquipmentInformation.dataCollectorModelNo = MESSAGE_MAX_ALLOWED_CHARCATERS_100
        }

        if (values.meteringEquipmentInformation.dataCollectorSerialNo && values.meteringEquipmentInformation.dataCollectorSerialNo.length > 100) {
            errors.meteringEquipmentInformation.dataCollectorSerialNo = MESSAGE_MAX_ALLOWED_CHARCATERS_100
        }

        if (values.meteringEquipmentInformation.dataCollectorSolarPanelSerialNumber && values.meteringEquipmentInformation.dataCollectorSolarPanelSerialNumber.length > 100) {
            errors.meteringEquipmentInformation.dataCollectorSolarPanelSerialNumber = MESSAGE_MAX_ALLOWED_CHARCATERS_100
        }
        if (parseInt(values.meteringEquipmentInformation.meteringSystemTypeId) === parseInt(values.mdata.OpenChannelId)) {


            if (!values.meteringEquipmentInformation.channelType) {
                errors.meteringEquipmentInformation.channelType = MESSAGE_REQUIRED
            }
            if (!values.meteringEquipmentInformation.channelWidth) {
                errors.meteringEquipmentInformation.channelWidth = MESSAGE_REQUIRED
            }
            if (!values.meteringEquipmentInformation.meterDistanceFromSource) {
                errors.meteringEquipmentInformation.meterDistanceFromSource = MESSAGE_REQUIRED
            }
            if (!values.meteringEquipmentInformation.channelLengthUpstream) {
                errors.meteringEquipmentInformation.channelLengthUpstream = MESSAGE_REQUIRED
            }
            if (!values.meteringEquipmentInformation.channelLlengthDownstream) {
                errors.meteringEquipmentInformation.channelLlengthDownstream = MESSAGE_REQUIRED
            }

            if (values.meteringEquipmentInformation.channelType && values.meteringEquipmentInformation.channelType.length > 100)
                errors.meteringEquipmentInformation.channelType = MESSAGE_MAX_ALLOWED_CHARCATERS_100;
            if (values.meteringEquipmentInformation.channelWidth && values.meteringEquipmentInformation.channelWidth.length > 100)
                errors.meteringEquipmentInformation.channelWidth = MESSAGE_MAX_ALLOWED_CHARCATERS_100;

            if (values.meteringEquipmentInformation.meterDistanceFromSource && !isNumber(values.meteringEquipmentInformation.meterDistanceFromSource)) {
                errors.meteringEquipmentInformation.meterDistanceFromSource = MESSAGE_REQUIRED_NUMBER;
            } else if (values.meteringEquipmentInformation.meterDistanceFromSource && !integerUpto8Digits.test(values.meteringEquipmentInformation.meterDistanceFromSource)) {
                errors.meteringEquipmentInformation.meterDistanceFromSource = integerUpto8DigitsMessage;
            }
            if (values.meteringEquipmentInformation.channelLengthUpstream && !decimal2UpTo8Digits.test(values.meteringEquipmentInformation.channelLengthUpstream)) {
                errors.meteringEquipmentInformation.channelLengthUpstream = decimal2UpTo8DigitsMessage;
            }
            if (values.meteringEquipmentInformation.channelLlengthDownstream && !decimal2UpTo8Digits.test(values.meteringEquipmentInformation.channelLlengthDownstream)) {
                errors.meteringEquipmentInformation.channelLlengthDownstream = decimal2UpTo8DigitsMessage;
            }



            if (values.meteringEquipmentInformation.maxMeasuredDischarge && !decimal2Digits.test(values.meteringEquipmentInformation.maxMeasuredDischarge)) {
                errors.meteringEquipmentInformation.maxMeasuredDischarge = decimal2DigitsMessage;
            }

            /* if(!values.meteringEquipmentInformation.minMeasuredDischarge)
                errors.meteringEquipmentInformation.minMeasuredDischarge = MESSAGE_REQUIRED;
            else  */
            if (values.meteringEquipmentInformation.minMeasuredDischarge && !decimal2Digits.test(values.meteringEquipmentInformation.minMeasuredDischarge)) {
                errors.meteringEquipmentInformation.minMeasuredDischarge = decimal2DigitsMessage;
            }

            /* if(!values.meteringEquipmentInformation.maxMeasuredVelocity)
                errors.meteringEquipmentInformation.maxMeasuredVelocity = MESSAGE_REQUIRED;
            else */
            if (values.meteringEquipmentInformation.maxMeasuredVelocity && !decimal2Digits.test(values.meteringEquipmentInformation.maxMeasuredVelocity)) {
                errors.meteringEquipmentInformation.maxMeasuredVelocity = decimal2DigitsMessage;
            }

            /* if(!values.meteringEquipmentInformation.minMeasuredVelocity)
                errors.meteringEquipmentInformation.minMeasuredVelocity = MESSAGE_REQUIRED;
            else */
            if (values.meteringEquipmentInformation.minMeasuredVelocity && !decimal2Digits.test(values.meteringEquipmentInformation.minMeasuredVelocity)) {
                errors.meteringEquipmentInformation.minMeasuredVelocity = decimal2DigitsMessage;
            }

            /* if(!values.meteringEquipmentInformation.maxGaugeHeight)
                errors.meteringEquipmentInformation.maxGaugeHeight = MESSAGE_REQUIRED;
            else  */
            if (values.meteringEquipmentInformation.maxGaugeHeight && !decimal2Digits.test(values.meteringEquipmentInformation.maxGaugeHeight)) {
                errors.meteringEquipmentInformation.maxGaugeHeight = decimal2DigitsMessage;
            }

            /* if(!values.meteringEquipmentInformation.minGaugeHeight)
                errors.meteringEquipmentInformation.minGaugeHeight = MESSAGE_REQUIRED;
            else */
            if (values.meteringEquipmentInformation.minGaugeHeight && !decimal2Digits.test(values.meteringEquipmentInformation.minGaugeHeight)) {
                errors.meteringEquipmentInformation.minGaugeHeight = decimal2DigitsMessage;
            }

            /* if(!values.meteringEquipmentInformation.maxError)
                errors.meteringEquipmentInformation.maxError = MESSAGE_REQUIRED;
            else */
            if (values.meteringEquipmentInformation.maxError && !decimal2Digits.test(values.meteringEquipmentInformation.maxError)) {
                errors.meteringEquipmentInformation.maxError = decimal2DigitsMessage;
            }

            /* if(!values.meteringEquipmentInformation.minError)
                errors.meteringEquipmentInformation.minError = MESSAGE_REQUIRED;
            else  */
            if (values.meteringEquipmentInformation.minError && !decimal2Digits.test(values.meteringEquipmentInformation.minError)) {
                errors.meteringEquipmentInformation.minError = decimal2DigitsMessage;
            }

            /* if(!values.meteringEquipmentInformation.noOfMeasurementsTaken)
                errors.meteringEquipmentInformation.noOfMeasurementsTaken = MESSAGE_REQUIRED;
            else  */
            if (values.meteringEquipmentInformation.noOfMeasurementsTaken && !isNumber(values.meteringEquipmentInformation.noOfMeasurementsTaken)) {
                errors.meteringEquipmentInformation.noOfMeasurementsTaken = MESSAGE_REQUIRED_NUMBER
            } else if (values.meteringEquipmentInformation.noOfMeasurementsTaken && !integerUpto8Digits.test(values.meteringEquipmentInformation.noOfMeasurementsTaken)) {
                errors.meteringEquipmentInformation.noOfMeasurementsTaken = integerUpto8DigitsMessage;
            }

        }

    }

    return errors;
}

export const validateCertificatePartE = (values, params) => {

    const closedConduitandProtableOptions =
        [
            { id: "3", name: "Meter marker plate", selected: false },
            { id: "4", name: "Evidence of tamper proofing/Meter seals", selected: false },
            { id: "5", name: "Meter body and type", selected: false },
            { id: "6", name: "Functioning display/readout panel showing meter reading", selected: false },
            { id: "7", name: "Site photo showing whole installation", selected: false },
            { id: "11", name: "Other", selected: false }
        ];

    const openChannelOptions =
        [
            //{ id: "3", name: "Meter marker plate", selected: false },
            { id: "4", name: "Evidence of tamper proofing/Meter seals", selected: false },
            { id: "5", name: "Meter body and type", selected: false },
            { id: "6", name: "Functioning display/readout panel showing meter reading", selected: false },
            { id: "7", name: "Site photo showing whole installation", selected: false },
            { id: "8", name: "Sensor location", selected: false },
            { id: "9", name: "Channel looking upstream", selected: false },
            { id: "10", name: "Channel looking downstream", selected: false },
            { id: "11", name: "Other", selected: false },
        ];

    const errors = {

        meteringEquipmentInformation: {

            meterCircumstancesOptions: "",// [required()],//text
            dateOfLastValidation: "",//[date({ format: 'mm/dd/yyyy' })],//date
            meteringSystemTypeId: "",//[required(),numericality({int:true,'>':0})],//number
            //meteringSystemType: [required()],//text
            meterManufacturer: "",//[required()],//text
            meterTypeModel: "",//[required()],//text
            meterSerialNo: "",// [required()],//text
            meterSize: "",//[required(),numericality({int:true}),length({min:2,max:4})], //number
            //pipeType: ,//text
            pipeSize: "",//[required(),numericality({int:true}),length({min:2,max:4})] ,//number
            //pipeConnectionType:,//decimal
            pipeLengthUpstream: "",//[format({ with: decimal2UpTo2Digits, message: decimal2UpTo2DigitsMessage })],//decimal
            pipeLengthDownstream: "",// [format({ with: decimal2UpTo2Digits, message: decimal2UpTo2DigitsMessage })],//decimal
            //pipeLengthDownstream: [twonondecimalupto2decimal.test()],//decimal
            longitude: "",//[format({ with: decimal3UpTo6Digits, message: decimal3UpTo6DigitsMessage }),numericality({'<=': 141})],//decimal
            latitude: "",//[format({ with: decimal3UpTo6Digits, message: decimal3UpTo6DigitsMessage }),numericality({'<=': 34,'>=':29})],//decimal

            //channelType:[],//text
            // channelWidth:[],//text
            // meterDistanceFromSource:[],//number
            // channelLengthUpstream:[format({ with: decimal2UpTo2Digits, message: decimal2UpTo2DigitsMessage })],//decimal
            // channelLlengthDownstream:[format({ with: decimal2UpTo2Digits, message: decimal2UpTo2DigitsMessage })],//number

            channelType: "",
            channelWidth: "",
            meterDistanceFromSource: "",
            channelLengthUpstream: "",
            channelLlengthDownstream: "",
            meterTestCertificate: "",
            meterq1FlowRateMinimum: "",
            meterReading: "",
            dateOfLastValidation: "",
            finalReadingRemovedMeter: "",
            finalReadingDateRemovedMeter: "",
            isModificationToCompliance: "",
            //documentError: "",


        },
        meterNonComplianceReason: [{
            reason: '',
            comments: '',
        }],
        meterNonCompliance: {
            modificationsPerformed: '',
        },

    }

    //console.log("in accuracy validation");
    let clickSaveDraft = params.clickSaveDraft
    let clickNext = params.clickNext;
    if (clickSaveDraft == undefined)
        clickSaveDraft = values.clickSaveDraft;
    if (clickNext == undefined)
        clickNext = values.clickNext;
    //console.log("draft:"+clickSaveDraft);
    if (clickSaveDraft === 1 && clickNext === 0) {
        const reasonArrayErrors = [];
        values.meterNonComplianceReason.forEach((reason, reasonIndex) => {
            const reasonErrors = {};
            if (reason.reason && reason.reason.length > 50) {
                reasonErrors.reason = MESSAGE_MAX_ALLOWED_CHARCATERS_50;
                reasonArrayErrors[reasonIndex] = reasonErrors;
            }
        });
        if (reasonArrayErrors.length > 0) {
            errors.meterNonComplianceReason = reasonArrayErrors;
        }

        if (parseInt(values.meteringEquipmentInformation.isModificationToCompliance) == 1 && values.meterNonCompliance.modificationsPerformed && values.meterNonCompliance.modificationsPerformed.length > 500) {
            errors.meterNonCompliance.modificationsPerformed = MESSAGE_MAX_ALLOWED_CHARCATERS_500;
        }
    }
    else if (clickSaveDraft == 0 && clickNext === 1 && values.meteringEquipmentInformation.formStatus != 2) {

        if (!values.meteringEquipmentInformation.complyMeteringEquipment || parseInt(values.meteringEquipmentInformation.complyMeteringEquipment) == 0) {

            errors.meteringEquipmentInformation.complyMeteringEquipment = MESSAGE_REQUIRED;
        }
        else if (parseInt(values.meteringEquipmentInformation.complyMeteringEquipment) == 2) {

            if (!values.meteringEquipmentInformation.isModificationToCompliance || values.meteringEquipmentInformation.isModificationToCompliance == "")
                errors.meteringEquipmentInformation.isModificationToCompliance = MESSAGE_REQUIRED;
            else if (parseInt(values.meteringEquipmentInformation.isModificationToCompliance) == 0)
                errors.meteringEquipmentInformation.isModificationToCompliance = MESSAGE_REQUIRED;

            if (values.meterNonComplianceReason.length == 0) {
                errors.meterNonComplianceReason = MESSAGE_REQUIRED;
            }
            else {

                /* if (!values.meterNonComplianceReason[0].reason) {
                    errors.meterNonComplianceReason[0].reason = MESSAGE_REQUIRED;
                } */
                /* if (!values.meterNonComplianceReason[0].comments) {
                    errors.meterNonComplianceReason[0].comments = MESSAGE_REQUIRED;
                } */
                const reasonArrayErrors = [];
                values.meterNonComplianceReason.forEach((reason, reasonIndex) => {
                    const reasonErrors = {};
                    if (!reason || !reason.reason) {
                        reasonErrors.reason = MESSAGE_REQUIRED;
                        reasonArrayErrors[reasonIndex] = reasonErrors;
                    } else if (reason.reason.length > 50) {
                        reasonErrors.reason = MESSAGE_MAX_ALLOWED_CHARCATERS_50;
                        reasonArrayErrors[reasonIndex] = reasonErrors;
                    }
                });
                if (reasonArrayErrors.length > 0) {
                    errors.meterNonComplianceReason = reasonArrayErrors;
                }
                /* for(var i=0; i < values.meterNonComplianceReason.length;i++ )
                {
                    alert(values.meterNonComplianceReason[i].reason);
                    if (!values.meterNonComplianceReason[i].reason) {
                        errors.meterNonComplianceReason[i].reason = MESSAGE_REQUIRED;
                    } else
                    if (values.meterNonComplianceReason[i].reason && values.meterNonComplianceReason[i].reason.length > 50 ) {
                        errors.meterNonComplianceReason[i].reason = MESSAGE_MAX_ALLOWED_CHARCATERS_50;
                    }
                } */
            }

            if (parseInt(values.meteringEquipmentInformation.isModificationToCompliance) == 1 && !values.meterNonCompliance.modificationsPerformed) {
                errors.meterNonCompliance.modificationsPerformed = MESSAGE_REQUIRED;
            } else if (parseInt(values.meteringEquipmentInformation.isModificationToCompliance) == 1 && values.meterNonCompliance.modificationsPerformed && values.meterNonCompliance.modificationsPerformed.length > 500) {
                errors.meterNonCompliance.modificationsPerformed = MESSAGE_MAX_ALLOWED_CHARCATERS_500;
            }
        }
        else {
            console.log("error")
            errors.meteringEquipmentInformation = {}
            errors.meterNonCompliance = {}
            errors.meterNonComplianceReason = {}
        }


    }
    return errors;
}

export const validateCertificateChecklist = (values, params) => {

    const errors = {

        meteringEquipmentInformation: {
        },
        meterNonComplianceReason: [{
            reason: '',
            comments: '',
        }],
        meterNonCompliance: {
            modificationsPerformed: '',
        },

    }

    console.log("in accuracy validation");
    let clickSaveDraft = params.clickSaveDraft
    let clickNext = params.clickNext;
    if (clickSaveDraft == undefined)
        clickSaveDraft = values.clickSaveDraft;
    if (clickNext == undefined)
        clickNext = values.clickNext;
    console.log("draft:" + clickSaveDraft);
    if (clickSaveDraft === 1 && clickNext === 0) {

    }
    else if (clickSaveDraft == 0 && clickNext === 1) {

    }
    return errors;
}

export const validateCertificateFormSelection = (values, params) => {

    const errors = {

        meteringEquipmentInformation: {
        },
        meterNonComplianceReason: [{
            reason: '',
            comments: '',
        }],
        meterNonCompliance: {
            modificationsPerformed: '',
        },

    }

    console.log("in meter validation");
    let clickSaveDraft = params.clickSaveDraft
    let clickNext = params.clickNext;
    if (clickSaveDraft == undefined)
        clickSaveDraft = values.clickSaveDraft;
    if (clickNext == undefined)
        clickNext = values.clickNext;
    console.log("draft:" + clickSaveDraft);
    if (clickSaveDraft === 1 && clickNext === 0) {

    }
    else if (clickSaveDraft == 0 && clickNext === 1 && values.meteringEquipmentInformation.formStatus != 2) {
        if (values.meteringEquipmentInformation.newMeterEquipment == null
            || values.meteringEquipmentInformation.newMeterEquipment == "") {
            errors.meteringEquipmentInformation.newMeterEquipment = MESSAGE_REQUIRED;
        }
    }
    return errors;
}



