import React from 'react';

const defaultState = {
    data : null,
    numberOfRecordsPerPage : 10,
    numberOfPages : 1,
    pageIndex : 0
};

const showNumberOfPages = 5;

class Pagination extends React.Component {

    

    constructor(props) {
        super(props);
        this.state= Object.assign({}, defaultState);
        if(props.numberOfRecordsPerPage)
            this.state.numberOfRecordsPerPage = this.props.numberOfRecordsPerPage;

        

        if(props.dataProvider) {
            props.dataProvider(this.setData);
        }
    }

    setData =(data, preservePageIndex) => {
        this.state.data = data;
        if(data) {
            if(this.state.data.length==0)
                this.state.numberOfPages = 1;
            else 
                this.state.numberOfPages = Math.ceil(this.state.data.length/this.state.numberOfRecordsPerPage);
            if(!preservePageIndex) this.setState({pageIndex: 0}, this.calculateDisplayRecords);
            else this.calculateDisplayRecords();
        }
    }

    calculateDisplayRecords = () => {
        var displayRecords = [];
        
        for(var i = (this.state.pageIndex*this.state.numberOfRecordsPerPage), j=0 ; (i < this.state.data.length) && (j<this.state.numberOfRecordsPerPage); i++, j++){
            displayRecords.push(this.state.data[i]);
        }
        if(this.props.displayRecordsUpdate)
            this.props.displayRecordsUpdate(displayRecords);
    }

    renderPageNumbers = () => {
        var pages = [];
        const half = Math.floor(showNumberOfPages/2);
        const startIndex = (this.state.pageIndex-half)<0?0:this.state.pageIndex-half;
        const endIndex = (this.state.pageIndex+half)>(this.state.numberOfPages-1)?(this.state.numberOfPages-1):(this.state.pageIndex+half);

        if(startIndex>0) 
            pages.push(<li class="page-item disabled">
                <a class="page-link">...</a>
            </li>);

        for(var i = startIndex; i <= endIndex; i++){
            
            if(i==this.state.pageIndex)
                pages.push(<li class="page-item active" aria-current="page"><a class="page-link" onClick={(e)=>{e.preventDefault()}}>{i+1}</a></li>);
            else 
                pages.push(<li class="page-item" ><a class="page-link" href="#" data-page-index={i} onClick={this.goToPage}>{i+1}</a></li>);
        }

        if(endIndex < (this.state.numberOfPages-1)) 
            pages.push(<li class="page-item disabled">
                <a class="page-link">...</a>
            </li>);
        return pages;
    }

    goToPage = (e)=>{
        e.preventDefault()
        this.setState({pageIndex: parseInt(e.target.getAttribute('data-page-index'))},this.calculateDisplayRecords)
        
    }

    nextPage = (e) => {
        e.preventDefault();
        if(!(this.state.pageIndex == (this.state.numberOfPages-1)))
            this.setState({pageIndex: this.state.pageIndex+1}, this.calculateDisplayRecords)
    }

    previousPage = (e) =>{
        e.preventDefault();
        if(this.state.pageIndex!=0)
            this.setState({pageIndex: this.state.pageIndex-1}, this.calculateDisplayRecords)
    }
   
    render() {
        if(!this.state.data)
            return null;
        return <>
            <nav>
                <ul class="pagination mb-0">
                    <li className={`page-item ${(this.state.pageIndex == 0)?"disabled":""}`}>
                        <a class="page-link" aria-label="Previous" href="#" onClick={this.previousPage}>
                            <span aria-hidden="true">&laquo;</span>
                        </a>
                    </li>
                    {this.renderPageNumbers()}
                    <li className={`page-item ${(this.state.pageIndex == (this.state.numberOfPages-1))?"disabled":""}`}>
                        <a class="page-link" aria-label="Next" href="#" onClick={this.nextPage}>
                            <span aria-hidden="true">&raquo;</span>
                        </a>
                    </li>
                </ul>
            </nav>
        </>;
   }
}

export default Pagination;