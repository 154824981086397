import React from 'react';
import {userRole, addUserRoleUpdateNotifier} from '../../helpers/userHelpers';

class DisplayForRoles extends React.Component {  
    
    async componentWillMount() {
        addUserRoleUpdateNotifier(this.userRolesUpdated);
    }

    userRolesUpdated = () =>{
        this.forceUpdate();
    }
   
    render() {
        if(!this.props.roles) return;
        if(userRole.isCFOAdmin && this.props.roles.cfoAdmin) return this.props.children;
        if(userRole.isSuperAdmin && this.props.roles.superAdmin) return this.props.children;
        return null;
   }
}

export default DisplayForRoles;