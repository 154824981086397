import React from 'react';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux'
import 'react-redux';
import 'redux';
import {SelectForRedux} from '../../tools/BootstrapControls'

import { scrollTofocusError } from "./MeterFormValidations"

const validateCertificateChecklist = (values, params) => {

     const errors = {
 
         meteringEquipmentInformation: {
         },
         meterNonComplianceReason: [{
             reason: '',
             comments: '',
         }],
         meterNonCompliance: {
             modificationsPerformed: '',
         },
 
     }
 
     console.log("in accuracy validation");
     let clickSaveDraft = params.clickSaveDraft
     let clickNext = params.clickNext;
     if (clickSaveDraft == undefined)
         clickSaveDraft = values.clickSaveDraft;
     if (clickNext == undefined)
         clickNext = values.clickNext;
     console.log("draft:" + clickSaveDraft);
     if (clickSaveDraft === 1 && clickNext === 0) {
 
     }
     else if (clickSaveDraft == 0 && clickNext === 1) {
 
     }
     return errors;
}


let ValidationAndMaintenanceChecklist = props => {

     const radioStyle = {
          width:'150px'
     }

     const checklistOptions =
          [
               { id: "1", name: "Yes", selected: false },
               { id: "2", name: "No", selected: false },
               { id: "3", name: "NA", selected: false}
          ];

     const {
          handleSubmit, meterEquipInfo, masterDataValues, previousPage, onSubmit, checklistInfo,validate
     } = props

     const disableFields = (parseInt(meterEquipInfo.formStatus) == 2)? true : false;
     const openChannel = ((parseInt(masterDataValues.OpenChannelId) == parseInt(meterEquipInfo.meteringSystemTypeId))? true: false);

     return (
          <form className="vertical" onSubmit={handleSubmit}>
               {/* <h3>Validation and Maintenance Checklist</h3> */}
               <h5>Use this checklist as a guide to ensure that all items have been considered when installing, validating or performing meter maintenance.</h5>
               <table className="table fs-90">
                    <thead>
                         <tr>
                              <th>No.</th>
                              <th>Description of Item</th>
                              <th>Yes/No or NA</th>
                         </tr>
                    </thead>
                    <tbody>
                         <tr>
                              <td>
                                   1
                              </td>
                              <td>
                                   Does the meter comply with its test certificate or certificate of approval?
                                   Serial number/make/model, AS4747 marked, pattern approval mark, verification mark, flow direction, pipe or meter size, totaliser is ML, kL, M3

                              </td>
                              <td style={radioStyle}>
                                   <Field 
                                        name="maintenanceChecklist.q1"
                                        component={SelectForRedux}
                                        values={checklistInfo.q1}
                                        props={{ disabled: disableFields }}
                                        options={checklistOptions} 
                                   />

                              </td>
                         </tr>
                         <tr>
                              <td>
                                   2
                              </td>
                              <td>
                                   Is the meter being used in an appropriate manner? Vertical/horizontal, water quality. If not state why.
                              </td>
                              <td>
                                   <Field
                                        name="maintenanceChecklist.q2"
                                        component={SelectForRedux}
                                        values={checklistInfo.q2}
                                        props={{ disabled: disableFields }}
                                        options={checklistOptions} 
                                   />
                              </td>
                         </tr>
                         <tr>
                              <td>
                                   3
                              </td>
                              <td>
                                   Has the meter been installed or maintained by non-duly qualified person(s)?
                              </td>
                              <td>
                                   <Field
                                        name="maintenanceChecklist.q3"
                                        component={SelectForRedux}
                                        values={checklistInfo.q3}
                                        props={{ disabled: disableFields }}
                                        options={checklistOptions} />
                              </td>
                         </tr>
                         <tr>
                              <td>
                                   4
                              </td>
                              <td>
                                   If the answer to 3 is yes, have the requirements of AS4747 been adhered to?
                              </td>
                              <td>
                                   <Field
                                        name="maintenanceChecklist.q4"
                                        component={SelectForRedux}
                                        values={checklistInfo.q4}
                                        props={{ disabled: disableFields }}
                                        options={checklistOptions} 
                                   />
                              </td>
                         </tr>
                         <tr>
                              <td>
                                   5
                              </td>
                              <td>
                                   Is there any evidence of tampering? If the answer is yes, what has been affected?
                              </td>
                              <td>
                                   <Field
                                        name="maintenanceChecklist.q5"
                                        component={SelectForRedux}
                                        values={checklistInfo.q5}
                                        props={{ disabled: disableFields }}
                                        options={checklistOptions} 
                                   />
                              </td>
                         </tr>
                         <tr>
                              <td>
                                   6
                              </td>
                              <td>
                                   Does the meter facility meet all compliance items of AS4747 and complies with the Water Management Act 2000 and the Water Management (General) Regulation 2018, set out in the NSW Non-Urban Water Metering Policy?
                              </td>
                              <td>
                                   <Field
                                        name="maintenanceChecklist.q6"
                                        component={SelectForRedux}
                                        values={checklistInfo.q6}
                                        props={{ disabled: disableFields }}
                                        options={checklistOptions} 
                                   />
                              </td>
                         </tr>
                         <tr>
                              <td>
                                   7
                              </td>
                              <td>
                                   If not state why:
                              </td>
                              <td>
                                   <Field
                                        name="maintenanceChecklist.q7"
                                        component={SelectForRedux}
                                        values={checklistInfo.q7}
                                        props={{ disabled: disableFields }}
                                        options={checklistOptions} 
                                   />
                              </td>
                         </tr>
                         <tr>
                              <td>
                                   8
                              </td>
                              <td>
                                   Is the totalizer non-resettable in operational mode?
                              </td>
                              <td>
                                   <Field
                                        name="maintenanceChecklist.q8"
                                        component={SelectForRedux}
                                        values={checklistInfo.q8}
                                        props={{ disabled: disableFields }}
                                        options={checklistOptions} 
                                   />
                              </td>
                         </tr>
                         <tr>
                              <td>
                                   9
                              </td>
                              <td>
                                   Has a test certificate been provided for this meter?
                              </td>
                              <td>
                                   <Field
                                        name="maintenanceChecklist.q9"
                                        component={SelectForRedux}
                                        values={checklistInfo.q9}
                                        props={{ disabled: disableFields }}
                                        options={checklistOptions} 
                                   />
                              </td>
                         </tr>
                         <tr>
                              <td>
                                   10
                              </td>
                              <td>
                                   Are all the mandatory descriptive markings (checked in Item 1), clearly and permanently marked on a data plate that is fixed to the meter body?
                              </td>
                              <td>
                                   <Field
                                        name="maintenanceChecklist.q10"
                                        component={SelectForRedux}
                                        values={checklistInfo.q10}
                                        props={{ disabled: disableFields }}
                                        options={checklistOptions} 
                                   />
                              </td>
                         </tr>
                         <tr>
                              <td>
                                   11
                              </td>
                              <td>
                                   Are the indicators (digits) clearly visible under all conditions?
                              </td>
                              <td>
                                   <Field
                                        name="maintenanceChecklist.q11"
                                        component={SelectForRedux}
                                        values={checklistInfo.q11}
                                        props={{ disabled: disableFields }}
                                        options={checklistOptions} 
                                   />
                              </td>
                         </tr>
                         <tr>
                              <td>
                                   12
                              </td>
                              <td>
                                   Is there evidence of interruption of signal transfer between the flow sensor and flow computer?
                              </td>
                              <td>
                                   <Field
                                        name="maintenanceChecklist.q12"
                                        component={SelectForRedux}
                                        values={checklistInfo.q12}
                                        props={{ disabled: disableFields }}
                                        options={checklistOptions} 
                                   />
                              </td>
                         </tr>
                         <tr>
                              <td>
                                   13
                              </td>
                              <td>
                                   Where applicable, has the flow computer been programmed with any revised parameters and are these parameters correct?
                              </td>
                              <td>
                                   
                                   <Field
                                        name="maintenanceChecklist.q13"
                                        component={SelectForRedux}
                                        values={checklistInfo.q13}
                                        props={{ disabled: disableFields }}
                                        options={checklistOptions} 
                                   />
                              </td>
                         </tr>
                         <tr>
                              <td>
                                   14
                              </td>
                              <td>
                                   Where a battery is fitted, is the battery marked with the installation date and expected replacement date?
                              </td>
                              <td>
                                   
                                   <Field
                                        name="maintenanceChecklist.q14"
                                        component={SelectForRedux}
                                        values={checklistInfo.q14}
                                        props={{ disabled: disableFields }}
                                        options={checklistOptions} 
                                   />
                              </td>
                         </tr>
                         <tr>
                              <td>
                                   15
                              </td>
                              <td>
                                   If the answer to 14 is yes, has the expected replacement date been reached?
                              </td>
                              <td>
                                   
                                   <Field
                                        name="maintenanceChecklist.q15"
                                        component={SelectForRedux}
                                        values={checklistInfo.q15}
                                        props={{ disabled: disableFields }}
                                        options={checklistOptions} 
                                   />
                              </td>
                         </tr>
                         <tr>
                              <td>
                                   16
                              </td>
                              <td>
                                   Is the meter within the stipulated design life (e.g. years, cumulative volume)?
                              </td>
                              <td>
                                   
                                   <Field
                                        name="maintenanceChecklist.q16"
                                        component={SelectForRedux}
                                        values={checklistInfo.q16}
                                        props={{ disabled: disableFields }}
                                        options={checklistOptions} 
                                   />
                              </td>
                         </tr>
                         <tr>
                              <td>
                                   17
                              </td>
                              <td>
                                   Are the meter security seals in place and unbroken?
                              </td>
                              <td>
                                   
                                   <Field
                                        name="maintenanceChecklist.q17"
                                        component={SelectForRedux}
                                        values={checklistInfo.q17}
                                        props={{ disabled: disableFields }}
                                        options={checklistOptions} 
                                   />
                              </td>
                         </tr>
                         <tr>
                              <td>
                                   18
                              </td>
                              <td>
                                   Is the electrical connection integrity still sound and solar panels where fitted, orientation correct, not shaded, clean and functioning correctly?
                              </td>
                              <td>
                                   
                                   <Field
                                        name="maintenanceChecklist.q18"
                                        component={SelectForRedux}
                                        values={checklistInfo.q18}
                                        props={{ disabled: disableFields }}
                                        options={checklistOptions} 
                                   />
                              </td>
                         </tr>
                         <tr>
                              <td>
                                   19
                              </td>
                              <td>
                                   Are there any offtakes before the meter?
                              </td>
                              <td>
                                   
                                   <Field
                                        name="maintenanceChecklist.q19"
                                        component={SelectForRedux}
                                        values={checklistInfo.q19}
                                        props={{ disabled: disableFields }}
                                        options={checklistOptions} 
                                   />
                              </td>
                         </tr>
                         <tr>
                              <td>
                                   20
                              </td>
                              <td>
                                   Have you checked the integrity of the telemetry pole, antenna and the fence around it (if applicable)?
                              </td>
                              <td>
                                   
                                   <Field
                                        name="maintenanceChecklist.q20"
                                        component={SelectForRedux}
                                        values={checklistInfo.q20}
                                        props={{ disabled: disableFields }}
                                        options={checklistOptions} 
                                   />
                              </td>
                         </tr>
                         <tr>
                              <td>
                                   21
                              </td>
                              <td>
                                   Are the displays/readouts correctly factored (e.g. multipliers correct)?
                              </td>
                              <td>
                                   
                                   <Field
                                        name="maintenanceChecklist.q21"
                                        component={SelectForRedux}
                                        values={checklistInfo.q21}
                                        props={{ disabled: disableFields }}
                                        options={checklistOptions} 
                                   />
                              </td>
                         </tr>
                         <tr>
                              <td>
                                   22
                              </td>
                              <td>
                                   Is there any scaling up or build-up of calcium, iron oxide or iron bacteria occurring?
                              </td>
                              <td>
                                   
                                   <Field
                                        name="maintenanceChecklist.q22"
                                        component={SelectForRedux}
                                        values={checklistInfo.q22}
                                        props={{ disabled: disableFields }}
                                        options={checklistOptions} 
                                   />
                              </td>
                         </tr>
                         <tr>
                              <td>
                                   23
                              </td>
                              <td>
                                   Are earthing straps, where fitted sound (only tested by DQP)?
                              </td>
                              <td>
                                   
                                   <Field
                                        name="maintenanceChecklist.q23"
                                        component={SelectForRedux}
                                        values={checklistInfo.q23}
                                        props={{ disabled: disableFields }}
                                        options={checklistOptions} 
                                   />
                              </td>
                         </tr>
                         <tr>
                              <td>
                                   24
                              </td>
                              <td>
                                   Are lightning arrestors, where fitted sound?
                              </td>
                              <td>
                                   
                                   <Field
                                        name="maintenanceChecklist.q24"
                                        component={SelectForRedux}
                                        values={checklistInfo.q24}
                                        props={{ disabled: disableFields }}
                                        options={checklistOptions} 
                                   />
                              </td>
                         </tr>
                         <tr>
                              <td>
                                   25
                              </td>
                              <td>
                                   Are there any leaks?
                              </td>
                              <td>
                                   
                                   <Field
                                        name="maintenanceChecklist.q25"
                                        component={SelectForRedux}
                                        values={checklistInfo.q25}
                                        props={{ disabled: disableFields }}
                                        options={checklistOptions} 
                                   />
                              </td>
                         </tr>
                         <tr>
                              <td>
                                   26
                              </td>
                              <td>
                                   Is the quality of the pipe and internal diameter satisfactory?
                              </td>
                              <td>
                                   
                                   <Field
                                        name="maintenanceChecklist.q26"
                                        component={SelectForRedux}
                                        props={{ disabled: disableFields }}
                                        values={checklistInfo.q26}
                                        options={checklistOptions} 
                                   />
                              </td>
                         </tr>
                         <tr>
                              <td>
                                   27
                              </td>
                              <td>
                                   Round and clear of obstructions?
                              </td>
                              <td>
                                   
                                   <Field
                                        name="maintenanceChecklist.q27"
                                        component={SelectForRedux}
                                        values={checklistInfo.q27}
                                        props={{ disabled: disableFields }}
                                        options={checklistOptions} 
                                   />
                              </td>
                         </tr>
                         <tr>
                              <td>
                                   28
                              </td>
                              <td>
                                   Have you recorded the meter reading?
                              </td>
                              <td>
                                   
                                   <Field
                                        name="maintenanceChecklist.q28"
                                        component={SelectForRedux}
                                        values={checklistInfo.q28}
                                        props={{ disabled: disableFields }}
                                        options={checklistOptions} 
                                   />
                              </td>
                         </tr>
                         <tr>
                              <td>
                                   29
                              </td>
                              <td>
                                   Are there any workmanship issues?
                              </td>
                              <td>
                                   
                                   <Field
                                        name="maintenanceChecklist.q29"
                                        component={SelectForRedux}
                                        values={checklistInfo.q29}
                                        props={{ disabled: disableFields }}
                                        options={checklistOptions} 
                                   />
                              </td>
                         </tr>
                         <tr>
                              <td>
                                   30
                              </td>
                              <td>
                                   Has the site been left clean and clear?
                              </td>
                              <td>
                                   
                                   <Field
                                        name="maintenanceChecklist.q30"
                                        component={SelectForRedux}
                                        values={checklistInfo.q30}
                                        props={{ disabled: disableFields }}
                                        options={checklistOptions} 
                                   />
                              </td>
                         </tr>
                         <tr>
                              <td>
                                   31
                              </td>
                              <td>
                                   Clear away debris, excess soil, check for vermin issues/damage and check that site is weed free
                              </td>
                              <td>
                                   
                                   <Field
                                        name="maintenanceChecklist.q31"
                                        component={SelectForRedux}
                                        values={checklistInfo.q31}
                                        props={{ disabled: disableFields }}
                                        options={checklistOptions} 
                                   />
                              </td>
                         </tr>
                         <tr>
                              <td>
                                   32
                              </td>
                              <td>
                                   Have you completed any other inspections and/or basic maintenance as per the meter manufactures required (if specified)?
                              </td>
                              <td>
                                   
                                   <Field
                                        name="maintenanceChecklist.q32"
                                        component={SelectForRedux}
                                        values={checklistInfo.q32}
                                        props={{ disabled: disableFields }}
                                        options={checklistOptions} 
                                   />
                              </td>
                         </tr>
                         <tr>
                              <td>
                                   33
                              </td>
                              <td>
                                   Ensure that the site is safe for employees, contractors or visitors to inspect or perform work at the metering site
                              </td>
                              <td>
                                   
                                   <Field
                                        name="maintenanceChecklist.q33"
                                        component={SelectForRedux}
                                        values={checklistInfo.q33}
                                        props={{ disabled: disableFields }}
                                        options={checklistOptions} 
                                   />
                              </td>
                         </tr>
                         {openChannel && <tr>
                              <th colSpan="3">
                                   For open channel systems only
                         </th>

                         </tr>}
                         {openChannel && <tr>
                              <td>
                                   34
                              </td>
                              <td>
                                   Have you checked for vegetation in flow measuring section?
                              </td>
                              <td>
                                   
                                   <Field
                                        name="maintenanceChecklist.q34"
                                        component={SelectForRedux}
                                        values={checklistInfo.q34}
                                        props={{ disabled: disableFields }}
                                        options={checklistOptions} 
                                   />
                              </td>
                         </tr>}
                         {openChannel && <tr>
                              <td>
                                   35
                              </td>
                              <td>
                                   Have you inspected the bank profile and noted any changes?
                              </td>
                              <td>
                                   
                                   <Field
                                        name="maintenanceChecklist.q35"
                                        component={SelectForRedux}
                                        values={checklistInfo.q35}
                                        props={{ disabled: disableFields }}
                                        options={checklistOptions} 
                                   />
                              </td>
                         </tr>}
                         {openChannel && <tr>
                              <td>
                                   36
                              </td>
                              <td>
                                   Have you removed any vegetation and silt on the sensors or sensor system?
                              </td>
                              <td>
                                   
                                   <Field
                                        name="maintenanceChecklist.q36"
                                        component={SelectForRedux}
                                        values={checklistInfo.q36}
                                        props={{ disabled: disableFields }}
                                        options={checklistOptions} 
                                   />
                              </td>
                         </tr>}
                         {openChannel && <tr>
                              <td>
                                   37
                              </td>
                              <td>
                                   Record date of last cross sectional survey
                              </td>
                              <td>
                                   
                                   <Field
                                        name="maintenanceChecklist.q37"
                                        component={SelectForRedux}
                                        values={checklistInfo.q37}
                                        props={{ disabled: disableFields }}
                                        options={checklistOptions} 
                                   />
                              </td>
                         </tr>}
                         {openChannel && <tr>
                              <td>
                                   38
                              </td>
                              <td>
                                   If the meter facility is connected to mains power supply, check that there is sufficient back up i.e. an uninterruptible power supply device installed?
                              </td>
                              <td>
                                   
                                   <Field
                                        name="maintenanceChecklist.q38"
                                        component={SelectForRedux}
                                        values={checklistInfo.q38}
                                        props={{ disabled: disableFields }}
                                        options={checklistOptions} 
                                   />
                              </td>
                         </tr>}
                    </tbody>
               </table>
               <div className="clear" />
         
          </form>
     )
}

ValidationAndMaintenanceChecklist = reduxForm({
     destroyOnUnmount: false,
     forceUnregisterOnUnmount: true,
     validate : validateCertificateChecklist,
     onSubmitFail: (errors) => scrollTofocusError(errors),
     onSubmitSuccess:() => { window.scrollTo(0,0);}
})(ValidationAndMaintenanceChecklist)

ValidationAndMaintenanceChecklist = connect(
     (state, props) => {
          // can select values individually
          const selector = formValueSelector(props.form)
          const masterDataValues = selector(state, 'mdata')
          const meterEquipInfo = selector(state, 'meteringEquipmentInformation')
          const checklistInfo = selector(state, 'maintenanceChecklist')
          const form = props.form
          return {
               masterDataValues,
               meterEquipInfo,
               checklistInfo,
               form
          }
     }
)(ValidationAndMaintenanceChecklist)

export default ValidationAndMaintenanceChecklist



