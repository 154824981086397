import React, { useRef } from 'react';
import { Field, reduxForm, formValueSelector, change } from 'redux-form';
import { connect } from 'react-redux'
import 'react-redux';
import 'redux';
import { validateMeteringEquipmentInformationFormSection } from './AccuracyExistingMeteringCertificate.Validation'
import { saveDraftAction, clickNextAction, scrollTofocusError } from "../common/MeterFormValidations"
import LotSelectForEsid from '../../common/LotSelectForEsid'
import { InputTextForRedux, SelectForRedux, ShowErrorForRedux, TextAreaForRedux, DatePickerForRedux, RadiosForRedux, CreatableSelectForRedux } from '../../tools/BootstrapControls'
import { WebMapViewActualLocation } from '../../tools/DQP.Map';
const validate = validateMeteringEquipmentInformationFormSection;

let MeteringEquipmentInformationFormSection = props => {

   const {
      handleSubmit, masterDataValues, meterEquipmentValues, previousPage, loading,appWorkInfo, hasError
   } = props

   if (meterEquipmentValues.dateOfLastValidation != null && meterEquipmentValues.dateOfLastValidation != "")
      meterEquipmentValues.dateOfLastValidation = new Date(meterEquipmentValues.dateOfLastValidation);
   if (meterEquipmentValues.supplyTypeId == 0 && masterDataValues.supplyTypes.length > 0)
      props.dispatch(change(props.form, 'meteringEquipmentInformation.supplyTypeId', masterDataValues.supplyTypes[0].id))
   if (meterEquipmentValues.dataCollectorTypeId == 0 && masterDataValues.dataCollectorTypes.length > 0)
      props.dispatch(change(props.form, 'meteringEquipmentInformation.dataCollectorTypeId', masterDataValues.dataCollectorTypes[0].id))


   const disableFields = (parseInt(props.meterEquipmentValues.formStatus) == 2) || props.disableEditing || loading ? true : false;
   const childRef = useRef(null);
   const updateMapPoints = () => {
      childRef.current.updateActualMapPoints({ longitude: meterEquipmentValues.longitude, latitude: meterEquipmentValues.latitude });
   }

   const updatePoints = (x, y) => {

      props.dispatch(change(props.form, 'meteringEquipmentInformation.latitude', parseFloat(y).toFixed(7)));
      props.dispatch(change(props.form, 'meteringEquipmentInformation.longitude', parseFloat(x).toFixed(7)));
      //props.dispatch(change(props.form, 'workDetails.distance', distance.toFixed(2)));
      //props.dispatch(change(props.form,'workDetails.actualLotNo',lotdp));

   }
   const lotChangeHandler = (lot) => {
      props.dispatch(change(props.form, 'approvedWorkDetails.lotDp',
         (lot.lotNumber ? lot.lotNumber : "")
         + "/"
         + (lot.sectionNumber ? lot.sectionNumber : "")
         + "/"
         + (lot.planNumber ? lot.planNumber : "")
         + " "
         + (lot.planType ? lot.planType : "")
      ));
   }

   const logTextChangeHandler = (displayText) => {
      props.dispatch(change(props.form, 'approvedWorkDetails.lotDp', displayText));
   }

   return (
      <form className="vertical" onSubmit={handleSubmit}>
         <h5 className='mb-3'>Metering Equipment Information</h5>
         <div className='row mb-3'>
            <div className='col-sm-3'>
               <Field
                  name="meteringEquipmentInformation.dateOfLastValidation"
                  handleChange={date => { props.change(props.form, 'meteringEquipmentInformation.dateOfLastValidation', date) }}
                  component={DatePickerForRedux}
                  values={meterEquipmentValues.dateOfLastValidation}
                  label="Last date of validation (if applicable)"
                  props={{ disabled: disableFields, maxDate: new Date() }}
               />
            </div>
         </div>

         <div className='row mb-3'>
            <div className='col-sm-3'>
               <Field
                  name="meteringEquipmentInformation.meteringSystemTypeId"
                  component={RadiosForRedux}
                  label="Meter system type"
                  options={masterDataValues.meteringSystemTypes}
                  values={meterEquipmentValues.meteringSystemTypeId}
                  props={{ disabled: disableFields }}
               />
            </div>
         </div>


         {(parseInt(props.meterEquipmentValues.meteringSystemTypeId) != 0) && <>
            <div className='row mb-3'>
               <div className='col-sm-6'>
                  <Field
                     name="meteringEquipmentInformation.meterTypeModelId"
                     component={SelectForRedux}
                     options={masterDataValues.meterModelTypes}
                     label={<>Meter manufacturer &amp; model <span className='text-danger'>*</span></>}
                     props={{ disabled: disableFields }}
                  />
               </div>
               {(parseInt(masterDataValues.MeterModelTypeOtherId) === parseInt(meterEquipmentValues.meterTypeModelId)) &&
                  <div className='col-sm-6'>
                     <Field
                        name="meteringEquipmentInformation.meterTypeModel"
                        component={InputTextForRedux}
                        label={<>Other meter manufacturer &amp; model <span className='text-danger'>*</span></>}
                        props={{ disabled: disableFields }}
                     />
                  </div>
               }
            </div>
            <div className='row mb-3'>
               <div className='col-sm-6'>
                  <Field
                     name="meteringEquipmentInformation.meterSerialNo"
                     label={<>Meter serial number <span className='text-danger'>*</span></>}
                     component={InputTextForRedux}
                     props={{ disabled: disableFields }}
                  />
               </div>
            </div>

            <div className='row mb-3'>
               <div className='col-sm-6'>
                  <Field
                     name="meteringEquipmentInformation.meterSize"
                     label={<>Meter size (mm) <span className='text-danger'>*</span></>}
                     component={InputTextForRedux}
                     props={{ disabled: disableFields }}
                  />
               </div>
            </div>

            {meterEquipmentValues.meteringSystemTypeId != 2 &&
               <>
                  <hr className='my-4' />
                  <div className='row mb-3'>
                     <div className='col-sm-6'>
                        <Field
                           name="meteringEquipmentInformation.pipeType"
                           label="Pipe type"
                           component={InputTextForRedux}
                           props={{ disabled: disableFields }}
                        />
                     </div>
                     <div className='col-sm-6'>
                        <Field
                           name="meteringEquipmentInformation.pipeSize"
                           label={<>Pipe size (mm) <span className='text-danger'>*</span></>}
                           component={InputTextForRedux}
                           props={{ disabled: disableFields }}
                        />
                     </div>
                  </div>

                  <div className='row mb-3'>
                     <div className='col-sm-6'>
                        <Field
                           name="meteringEquipmentInformation.pipeConnectionType"
                           label="Pipe connection type"
                           component={InputTextForRedux}
                           props={{ disabled: disableFields }}
                        />
                     </div>
                     <div className='col-sm-6'>
                        <Field
                           name="meteringEquipmentInformation.pipeLengthUpstream"
                           label="Pipe length upstream of meter (mm)"
                           component={InputTextForRedux}
                           props={{ disabled: disableFields }}
                        />
                     </div>
                  </div>
                  <div className='row mb-3'>
                     <div className='col-sm-6'>
                        <Field
                           name="meteringEquipmentInformation.pipeLengthDownstream"
                           label="Pipe length downstream of meter (mm)"
                           component={InputTextForRedux}
                           props={{ disabled: disableFields }}
                        />
                     </div>
                  </div>

                  <hr className='my-4' />
               </>
            }



            <div className='row mb-4'>
               <div className='col-sm-4'>
                  <label className='form-label'>Meter location <span className='text-danger'>*</span></label>
                  <Field
                     name="meteringEquipmentInformation.longitude"
                     component={InputTextForRedux}
                     props={{ disabled: disableFields, placeholder: 'longitude', className: "mb-3" }}
                  />
                  <Field
                     name="meteringEquipmentInformation.latitude"
                     component={InputTextForRedux}
                     props={{ disabled: disableFields, placeholder: 'latitude' }}
                  />
               </div>
            </div>

            <div class="mb-3" id="map" style={{ minHeight: "360px" }}>
               <WebMapViewActualLocation
                  form="Accuracy Certificate"
                  formStatus={meterEquipmentValues.formStatus}
                  updatePoints={updatePoints}
                  ref={childRef}
                  actualPoint={{ latitude: meterEquipmentValues.latitude, longitude: meterEquipmentValues.longitude }}
               />
            </div>

            <div className='row mb-3'>
               <div className='col-sm-6'>
                  {!disableFields && <>
                     <label className='form-label'>Lot DP <span className='text-danger'>* </span></label>
                     <LotSelectForEsid
                        approvalNumber={appWorkInfo.waterSupplyWorkApprovalNo}
                        esid={appWorkInfo.esid}
                        onLotChange={lotChangeHandler}
                        onChangeOfDisplayText={logTextChangeHandler}
                        displayText={appWorkInfo.lotDp}
                     />
                     <Field name='approvedWorkDetails.lotDp' component={ShowErrorForRedux} />
                  </>}
                  {disableFields && <Field
                     name="approvedWorkDetails.lotDp"
                     label={<>Lot DP <span className='text-danger'>* </span></>}
                     placeholder=""
                     autocomplete="off"
                     component={InputTextForRedux}
                     props={{ disabled: true, autocomplete: "off" }}
                  />}
               </div>
            </div>


            <div className='mb-3'>
               <Field
                  name="meteringEquipmentInformation.meterDescription"
                  props={{ disabled: disableFields }}
                  rows={10}
                  cols={40}
                  label="Meter Description"
                  component={TextAreaForRedux}
               />
            </div>

            <div className='row mb-3'>
               <div className='col-sm-6'>
                  <Field
                     name="meteringEquipmentInformation.supplyTypeId"
                     component={SelectForRedux}
                     options={masterDataValues.supplyTypes}
                     label="Type of supply"
                     props={{ disabled: disableFields }}
                  />
               </div>
            </div>



            <div className='row mb-3'>
               <div className='col-sm-6'>
                  <label for="sealnumbers" className='form-label'>Record the tamper-evident seal numbers<span className='text-danger'>* </span></label>
                  <Field
                     name='meteringEquipmentInformation.sealNo'
                     component={CreatableSelectForRedux}
                     placeholder="Type Seal Nos and press enter (or click +) to enter multiple entries"
                     options={meterEquipmentValues.sealNo}
                     props={{ disabled: disableFields }}
                  />
               </div>
            </div>

            {(meterEquipmentValues.formStatus == 2 && meterEquipmentValues.dataCollectorTypeId == '1') &&
               <div className='row mb-3'>
                  <div className='col-sm-6'>
                     <Field
                        name="meteringEquipmentInformation.dataCollectorTypeId"
                        component={SelectForRedux}
                        options={masterDataValues.dataCollectorTypes}
                        props={{ disabled: disableFields }}
                        label={<>Data collector type <span className='text-danger'>*</span></>}
                     />
                  </div>
               </div>
            }
            {(meterEquipmentValues.formStatus == 1 && (meterEquipmentValues.dataCollectorTypeId == '1' || meterEquipmentValues.dataCollectorTypeId != '1')) &&
               <div className='row mb-3'>
                  <div className='col-sm-6'>
                     <Field
                        name="meteringEquipmentInformation.dataCollectorTypeId"
                        component={SelectForRedux}
                        options={masterDataValues.dataCollectorTypes.filter(function (option) { return option.isActive == true })}
                        props={{ disabled: disableFields }}
                        label={<>Data collector type <span className='text-danger'>*</span></>}
                     />
                  </div>
               </div>
            }
            {(meterEquipmentValues.formStatus == 2 && meterEquipmentValues.dataCollectorTypeId != '1') &&
               <div className='row mb-3'>
                  <div className='col-sm-6'>
                     <Field
                        name="meteringEquipmentInformation.dataCollectorTypeId"
                        component={SelectForRedux}
                        options={masterDataValues.dataCollectorTypes.filter(function (option) { return option.isActive == true })}
                        props={{ disabled: disableFields }}
                        label={<>Data collector type <span className='text-danger'>*</span></>}
                     />
                  </div>
               </div>

            }

            {(meterEquipmentValues.dataCollectorTypeId != 4 && meterEquipmentValues.dataCollectorTypeId != '4') &&
               <>
                  <div className='row mb-3'>
                     <div className='col-sm-6'>
                        <Field
                           name="meteringEquipmentInformation.dataCollectorModelNo"
                           label={<>Model number <span className='text-danger'>*</span></>}
                           component={InputTextForRedux}
                           props={{ disabled: disableFields }}
                        />
                     </div>
                     <div className='col-sm-6'>
                        <Field
                           name="meteringEquipmentInformation.dataCollectorSerialNo"
                           label={<>Serial number <span className='text-danger'>*</span></>}
                           component={InputTextForRedux}
                           props={{ disabled: disableFields }}
                        />
                     </div>
                  </div>

                  <div className='row mb-3'>
                     <div className='col-sm-6'>
                        <Field
                           name="meteringEquipmentInformation.dataCollectorPowerSourceId"
                           component={SelectForRedux}
                           options={masterDataValues.powerSources}
                           label="Power source"
                           props={{ disabled: disableFields }}
                        />
                     </div>
                     <div className='col-sm-6'>
                        {(meterEquipmentValues.dataCollectorPowerSourceId == 1) &&
                           <Field
                              name="meteringEquipmentInformation.dataCollectorSolarPanelWattage"
                              label="Solar panel wattage"
                              component={InputTextForRedux}
                              props={{ disabled: disableFields }}
                           />
                        }
                     </div>
                  </div>
               </>}

            {(parseInt(props.masterDataValues.OpenChannelId) == parseInt(props.meterEquipmentValues.meteringSystemTypeId)) && <>
               <div className='row mb-3'>
                  <div className='col-sm-6'>
                     <Field
                        name="meteringEquipmentInformation.channelType"
                        label={<>Channel type <span className='text-danger'>*</span></>}
                        component={InputTextForRedux}
                        props={{ disabled: disableFields }}
                     />
                  </div>
               </div>
               <div className='row mb-3'>
                  <div className='col-sm-6'>
                     <Field
                        name="meteringEquipmentInformation.channelWidth"
                        label={<>Channel width (m) <span className='text-danger'>*</span></>}
                        component={InputTextForRedux}
                        props={{ disabled: disableFields }}
                     />
                  </div>
                  <div className='col-sm-6'>
                     <Field name="meteringEquipmentInformation.meterDistanceFromSource"
                        label={<>Distance of the meter from pumping source or filling point (m) <span className='text-danger'>*</span></>}
                        component={InputTextForRedux}
                        props={{ disabled: disableFields }}
                     />
                  </div>
               </div>
               <div className='row mb-3'>
                  <div className='col-sm-6'>
                     <Field
                        name="meteringEquipmentInformation.channelLengthUpstream"
                        label={<>Channel length upstream of meter (m) <span className='text-danger'>*</span></>}
                        component={InputTextForRedux}
                        props={{ disabled: disableFields, mandatory: true }}
                     />
                  </div>
                  <div className='col-sm-6'>
                     <Field
                        name="meteringEquipmentInformation.channelLlengthDownstream"
                        label={<>Channel length downstream of meter (m) <span className='text-danger'>*</span></>}
                        component={InputTextForRedux}
                        props={{ disabled: disableFields, mandatory: true }}
                     />
                  </div>
               </div>

               <h5 className='mt-4 mb-3'>Calibration Measurement Details</h5>
               <div className='row mb-3'>
                  <div className='col-sm-6'>
                     <Field
                        name="meteringEquipmentInformation.maxMeasuredDischarge"
                        label="Maximum measured discharge (m³/s)"
                        component={InputTextForRedux}
                        props={{ disabled: disableFields }}
                     />
                  </div>
                  <div className='col-sm-6'>
                     <Field
                        name="meteringEquipmentInformation.minMeasuredDischarge"
                        label="Minimum measured discharge (m³/s)"
                        component={InputTextForRedux}
                        props={{ disabled: disableFields }}
                     />
                  </div>
               </div>
               <div className='row mb-3'>
                  <div className='col-sm-6'>
                     <Field
                        name="meteringEquipmentInformation.maxMeasuredVelocity"
                        label="Maximum measured velocity (m/s)"
                        component={InputTextForRedux}
                        props={{ disabled: disableFields }}
                     />
                  </div>
                  <div className='col-sm-6'>
                     <Field
                        name="meteringEquipmentInformation.minMeasuredVelocity"
                        label="Minimum measured velocity (m/s)"
                        component={InputTextForRedux}
                        props={{ disabled: disableFields }}
                     />
                  </div>
               </div>
               <div className='row mb-3'>
                  <div className='col-sm-6'>
                     <Field
                        name="meteringEquipmentInformation.maxGaugeHeight"
                        label="Maximum gauge height (m)"
                        component={InputTextForRedux}
                        props={{ disabled: disableFields }}
                     />
                  </div>
                  <div className='col-sm-6'>
                     <Field
                        name="meteringEquipmentInformation.minGaugeHeight"
                        label="Minimum gauge height (m)"
                        component={InputTextForRedux}
                        props={{ disabled: disableFields }}
                     />
                  </div>
               </div>
               <div className='row mb-3'>
                  <div className='col-sm-6'>
                     <Field
                        name="meteringEquipmentInformation.maxError"
                        label="Maximum gauge height (m)"
                        component={InputTextForRedux}
                        props={{ disabled: disableFields }}
                     />
                  </div>
                  <div className='col-sm-6'>
                     <Field
                        name="meteringEquipmentInformation.minError"
                        label="Minimum gauge height (m)"
                        component={InputTextForRedux}
                        props={{ disabled: disableFields }}
                     />
                  </div>
               </div>
               <div className='row mb-3'>
                  <div className='col-sm-6'>
                     <Field
                        name="meteringEquipmentInformation.noOfMeasurementsTaken"
                        label="Number of measurements undertaken for index equation (if applicable)"
                        component={InputTextForRedux}
                        props={{ disabled: disableFields }}
                     />
                  </div>
               </div>
            </>}
         </>}

         {hasError && <div className='alert alert-danger'>
            Please fix all errors before you continue
         </div>}

         <div className="text-end">
            {(parseInt(meterEquipmentValues.formStatus) == 1) && (!props.disableEditing) && <>
               {meterEquipmentValues.id != 0 &&
                  <button type="button"
                     className="btn btn-danger me-3" disabled={loading}
                     data-bs-toggle="modal" data-target="#deleteDraftModal"
                  >
                     Delete Draft
                  </button>
               }
               <button type="button" className="btn btn-primary" disabled={loading}
                  onClick={() => { saveDraftAction(props); }}>
                  Save Draft
               </button>

               <div className='vr mx-3' />
            </>}

            <button type="button" className="btn btn-warning me-3"
               onClick={() => { previousPage(meterEquipmentValues.formStatus) }}
               disabled={loading}
            >
               Previous
            </button>
            <button type="button" className="btn btn-success"
               disabled={loading}
               onClick={() => { clickNextAction(props); }}>
               Next
            </button>
         </div>
      </form>
   )
}

MeteringEquipmentInformationFormSection = reduxForm({
   destroyOnUnmount: false,
   forceUnregisterOnUnmount: true,
   validate,
   onSubmitFail: (errors, dispatch ) => {
      dispatch(change('certificate', 'hasError', true));
    },
    onSubmitSuccess:(result , dispatch )=>{
        dispatch(change('certificate', 'hasError', false));
        window.scrollTo(0, 0);
    }
})(MeteringEquipmentInformationFormSection)

MeteringEquipmentInformationFormSection = connect(
   (state, props) => {
      const selectorCert = formValueSelector('certificate')
      const hasError = selectorCert(state, 'hasError')

      const selector = formValueSelector(props.form)
      const loading = selector(state, 'loading');
      const masterDataValues = selector(state, 'mdata')
      const meterEquipmentValues = selector(state, 'meteringEquipmentInformation')
      const appWorkInfo = selector(state, 'approvedWorkDetails');
      return {
         masterDataValues,
         meterEquipmentValues,
         form: props.form,
         appWorkInfo,
         loading,
         hasError
      }
   })(MeteringEquipmentInformationFormSection)

export default MeteringEquipmentInformationFormSection