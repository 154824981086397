import React from 'react';
import axios from 'axios';
import moment from 'moment';
import * as bootstrap from 'bootstrap';

import Authentication from '../../../helpers/Authentication'
import uiHelpers from '../../../helpers/uiHelpers'
import {Refresh} from '../../tools/Icons'
import {InputText} from '../../tools/BootstrapControls'

const authentication = new Authentication();

class CertificateAdmin extends React.Component {

    ajaxConfig = {
        baseURL: process.env.REACT_APP_WEBAPI_URL,
        headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authentication.getToken() }
     };

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            triggers : [],

            triggerWithEmail : false,
            comments : null,
            commentsError : null

        }
    }

    #triggerConfirmationModal;
    async componentDidMount() {


        this.#triggerConfirmationModal = new bootstrap.Modal(document.getElementById('triggerConfirmationModal'), {
            keyboard: false
        });

        this.refresh();
    }   

    refresh =async () =>{
        uiHelpers.showProgressOnTop("Loading PowerAutomate trigger records...");
        this.setState({loading : true})
        try {
            const response = await axios.post('/meterFormCommon/triggers-for-certificate-generation', {id : this.props.id, entityType: this.props.entityType}, this.ajaxConfig);
            this.state.triggers = response.data.data;
            uiHelpers.hideProgressOnTop();
        } catch(e){
            console.log(e);
            uiHelpers.showErrorTop('Error occured when loading PowerAutomate trigger records...');
        }
        this.setState({loading : false})
    }

    openTriggerModal =() =>{
        this.setState({triggerWithEmail : false, comments:null, commentsError: null})
        this.#triggerConfirmationModal.show();
    }

    triggerCertGeneration =async () => {

        if(!this.state.comments || this.state.comments.trim()==''){
            this.setState({commentsError: 'This is mandatory'})
            return;
        } 

        this.setState({commentsError: null})


        uiHelpers.showProgressOnTop("Saving trigger...");
        this.setState({loading : true})
        try {
            await axios.post('/meterFormCommon/trigger-certificate-generation', {
                id : this.props.id
                , entityType: this.props.entityType
                , triggerEmail: this.state.triggerWithEmail
                , comments: this.state.comments
            }, this.ajaxConfig);
            this.#triggerConfirmationModal.hide();
            this.refresh();
            uiHelpers.hideProgressOnTop();
        } catch(e){
            console.log(e);
            uiHelpers.showErrorTop('Error occured when saving your trigger...');
        }
        this.setState({loading : false})


    }

    onCommentsChange =(e)=>{
        this.setState({comments:e.target.value})
    }

    render() {
        return <>
            <h5 className='mb-3'>Power Automate triggers for PDF generation</h5>

            <div class="card mb-3" >
                <div className='card-body'>
                    <button className='btn btn-outline-primary me-3 btn-sm' disabled={this.state.loading} onClick={this.refresh}><Refresh/></button>
                    <button className='btn btn-outline-primary btn-sm' disabled={this.state.loading} onClick={this.openTriggerModal}>Trigger again</button>
                </div>
            </div>

            <table className='table table-striped fs-90'>
                <thead>
                    <tr>
                        <th style={{width:"200px"}}>Triggered time</th>
                        <th style={{width:"150px"}}>Manually triggered</th>
                        <th style={{width:"200px"}}>Send email or not</th>
                        <th style={{width:"200px"}}>Completed time</th>
                        <th>Comments</th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.triggers.map((record)=><>
                        <tr>
                            <td>
                            {moment((new Date(record.triggeredTime))).format('DD/MM/YYYY h:mma')}
                            </td>
                            <td>
                            {record.isManualTriggered ? 'Yes' : 'No'}
                            </td>
                            <td>
                            {record.triggerEmail ? 'Yes' : 'No'}
                            </td>
                            <td>
                            {record.completedDate && moment((new Date(record.completedDate))).format('DD/MM/YYYY h:mma')}
                            </td>
                            <td>
                            {record.comments}
                            </td>
                        </tr>
                    </>)}
                </tbody>
            </table>


            <div class="modal fade" id="triggerConfirmationModal" data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">
                                Trigger certificate generation
                            </h5>
                        </div>
                        <div class="modal-body">
                           <InputText 
                            label={<>Comments <span className='text-danger'>*</span> <em>(Incident number)</em></>}
                            error={this.state.commentsError}
                            onChange={this.onCommentsChange}
                            className="mb-3"
                           />

                            <div class="form-check form-switch">
                                <input class="form-check-input" type="checkbox" role="switch" id="switchCheckEmail" checked={this.state.triggerWithEmail} onChange={()=>{this.setState({triggerWithEmail : !this.state.triggerWithEmail})}} />
                                <label class="form-check-label" for="switchCheckEmail">Send email to holder</label>
                            </div>
                        </div>
                        <div class="modal-footer position-relative">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" disabled={this.state.loading}>Close</button>
                            <button type="button" class="btn btn-primary" disabled={this.state.loading} onClick={this.triggerCertGeneration}>Confirm</button>
                        </div>
                    </div>
                </div>
            </div>

        </>
    }

}

export default CertificateAdmin;
