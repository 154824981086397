import React from 'react';
import {convertRegistrationFormStatus} from '../../helpers/converters'

const convertRegistrationFormType = (record)=>{
    if(record.registrationFormType == 'NON_URBAN') return 'Telemetry Registration (NUM)';
    else if(record.registrationFormType == 'FPH' && record.registrationMeasureMethodId == 'POINT_OF_INTAKE' ) return 'Telemetry Registration (FPH)';
    else if(record.registrationFormType == 'FPH' && record.registrationMeasureMethodId == 'STORAGE_MESUREMENT' ) return 'Telemetry Registration (FPH)';
    else return 'Unknown';
}

const convertRegistrationLink = (record) => {
    if(record.registrationFormType == 'NON_URBAN') return "NonUrbanRegistration?id=" + record.id;
    else if(record.registrationFormType == 'FPH' && record.registrationMeasureMethodId == 'POINT_OF_INTAKE' ) return "FPHRegistration?id=" + record.id;
    else if(record.registrationFormType == 'FPH' && record.registrationMeasureMethodId == 'STORAGE_MESUREMENT' ) return "FPHRegistration?id=" + record.id;
    else return '#';
}

const convertEquipInfoFormType = (record) => {
    if(record.meterFormType == 1 && (!record.formCategory || record.formCategory=='NUM')) return 'Validation Certificate';
    if(record.meterFormType == 2 && (!record.formCategory || record.formCategory=='NUM')) return 'Design Certificate';
    if(record.meterFormType == 3 && (!record.formCategory || record.formCategory=='NUM')) return 'Accuracy Certificate (NUM)';
    if(record.meterFormType == 4 && (!record.formCategory || record.formCategory=='NUM')) return 'Transactional Arrangements Report';
    if(record.meterFormType == 9 && (!record.formCategory || record.formCategory=='NUM')) return 'Maintenance Validation Certificate';
    if(record.meterFormType == 1 && record.formCategory=='FPH') return 'Point of Intake Validation Certificate';
    if(record.meterFormType == 3 && record.formCategory=='FPH') return 'Point of Intake Accuracy Certificate';
    else return 'Unknown';
}

const convertEquipInfoLink = (record) => {
    if(record.meterFormType == 1 && (!record.formCategory || record.formCategory=='NUM')) return 'validation-certificate-num?id=' + record.id;
    if(record.meterFormType == 2 && (!record.formCategory || record.formCategory=='NUM')) return 'MeterDesignCertificate?id=' + record.id;
    if(record.meterFormType == 3 && (!record.formCategory || record.formCategory=='NUM')) return 'accuracy-certificate-num?id='+ record.id;
    if(record.meterFormType == 4 && (!record.formCategory || record.formCategory=='NUM')) return 'TransitionalReporting?id='+ record.id;
    if(record.meterFormType == 9 && (!record.formCategory || record.formCategory=='NUM')) return 'validation-certificate-num?id='+ record.id;
    if(record.meterFormType == 1 && record.formCategory=='FPH') return 'validation-certificate-point-of-intake?id='+ record.id;
    if(record.meterFormType == 3 && record.formCategory=='FPH') return 'accuracy-certificate-fph?id='+record.id;
    else return '#';
}

const renderRegistrationRecord = (record) =>{
    return <tr>
        <td><a href={convertRegistrationLink(record)} target={"_blank"}>Details</a></td>
        <td>{record.id}</td>
        <td>{convertRegistrationFormType(record)}</td>
        <td>{convertRegistrationFormStatus(record.formStatus)}</td>
        <td>{record.dqpFirstName} {record.dqpLastName}</td>
        <td>{record.approval}</td>
        <td>{record.esid}</td>
        <td>{record.meterNumber}</td>
        <td>{record.ratingTableName}</td>
    </tr>
}

const renderEquipInfoRecord = (record) =>{
    return <tr>
        <td><a href={convertEquipInfoLink(record)} target={"_blank"}>Details</a></td>
        <td>{record.id}</td>
        <td>{convertEquipInfoFormType(record)}</td>
        <td>{record.formStatus==1?'Draft' : 'Submitted'}</td>
        <td>{record.dqpFirstName} {record.dqpLastName}</td>
        <td>{record.approval}</td>
        <td>{record.esid}</td>
        <td>{record.meterNumber}</td>
        <td>{record.ratingTableName}</td>
    </tr>
}

const renderSurveyBranchmarkRecord = (record) => {
    return <tr>
        <td><a href={"SurveyBenchmarkCertificate?id=" + record.id} target={"_blank"}>Details</a></td>
        <td>{record.id}</td>
        <td>Survey Benchmark Certificate</td>
        <td>{record.formStatus==1?'Draft' : 'Submitted'}</td>
        <td>{record.dqpFirstName} {record.dqpLastName}</td>
        <td>{record.approval}</td>
        <td>{record.esid}</td>
        <td>{record.meterNumber}</td>
        <td>{record.ratingTableName}</td>
    </tr>
}

const renderUpdateRatingTableRecord = (record) => {
    return <tr>
        <td><a href={"UpdateRatingTable?id=" + record.id} target={"_blank"}>Details</a></td>
        <td>{record.id}</td>
        <td>Update Rating Table</td>
        <td>{record.formStatus==1?'Draft' : 'Submitted'}</td>
        <td>{record.dqpFirstName} {record.dqpLastName}</td>
        <td>{record.approval}</td>
        <td>{record.esid}</td>
        <td>{record.meterNumber}</td>
        <td>{record.ratingTableName}</td>
    </tr>
}


const renderSecondaryMeterEquipment = (record) => {
    return <tr>
        <td><a href={"SecondaryEquipmentValidation?id=" + record.id} target={"_blank"}>Details</a></td>
        <td>{record.id}</td>
        <td>Secondary Metering Equipment Validation Certificate</td>
        <td>{record.formStatus==1?'Draft' : 'Submitted'}</td>
        <td>{record.dqpFirstName} {record.dqpLastName}</td>
        <td>{record.approval}</td>
        <td>{record.esid}</td>
        <td>{record.meterNumber}</td>
        <td>{record.ratingTableName}</td>
    </tr>
}

const renderStorageMeasurementValidation = (record) => {
    return <tr>
        <td><a href={"StorageManagementValidation?id=" + record.id} target={"_blank"}>Details</a></td>
        <td>{record.id}</td>
        <td>Primary Validation certificate</td>
        <td>{record.formStatus==1?'Draft' : 'Submitted'}</td>
        <td>{record.dqpFirstName} {record.dqpLastName}</td>
        <td>{record.approval}</td>
        <td>{record.esid}</td>
        <td>{record.meterNumber}</td>
        <td>{record.ratingTableName}</td>
    </tr>
}


export const renderRecordTrRow = (record) => {

    if(record.recordType == 'Registration'){
        return renderRegistrationRecord(record);
    } else if(record.recordType == 'EquipInfo') {
        return renderEquipInfoRecord(record);
    } else if(record.recordType == 'SurveyBranchmark') {
        return renderSurveyBranchmarkRecord(record);
    } else if(record.recordType == 'UpdateRatingTable') {
        return renderUpdateRatingTableRecord(record);
    } else if(record.recordType == 'SecondaryMeterEquipment') {
        return renderSecondaryMeterEquipment(record);
    } else if(record.recordType == 'StorageManagementValidation') {
        return renderStorageMeasurementValidation(record);
    } else {
        return <tr>
            <td colspan={10} className='text-center'>
                Unknown record type
            </td>
        </tr>
    }
}

export const renderRecordHeaderTr = () =><tr>
    <th style={{width:"50px"}}></th>
    <th style={{width:"50px"}}>ID</th>
    <th style={{width:"300px"}}>Form type</th>
    <th>Status</th>
    <th>DQP</th>
    <th>Approval</th>
    <th>ESID</th>
    <th>Meter number</th>
    <th>Rating table</th>
</tr>