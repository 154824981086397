import React from 'react';
import Authentication from '../../helpers/Authentication'
import axios from 'axios';
import uiHelpers from '../../helpers/uiHelpers';
import EsidSelect from '../../components/common/EsidSelect'
import MeterSelectForEsid from '../../components/common/MeterSelectForEsid'
import {InputTextWithFloatingLabel} from '../../components/tools/BootstrapControls'


const authentication = new Authentication();
const convertApprovalType = (approvalTypeCode) => {
    switch(approvalTypeCode) {
        case 'CA' : return 'Combined approval'
        case 'WA' : return 'Work approval'
        case 'UA' : return 'Use approval'
        case 'FW' : return 'Flood work approval'
        default:return 'Unknown'
    }
}
const convertStatus = (statusCode) => {
    switch(statusCode) {
        case 'CA' : return 'Cancelled'
        case 'CU' : return 'Current'
        case 'EX' : return 'Expired'
        default:return 'Unknown'
    }
}
class SearchApproval extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            searchForm : {
                approvalNumber : null,
                searching: false,
                searchCompleted : false
            },
            approval : null,
            view : 'ACTIVE_WORKS',
            esid : null,
            meter : null
        }
    }

    ajaxConfig = {
        baseURL: process.env.REACT_APP_WEBAPI_URL,
        headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authentication.getToken() }
    };

    componentDidMount() {

    }   

    approvalNumberChange = (event) => {
        this.setState(Object.assign(this.state.searchForm,{approvalNumber : event.target.value, searchCompleted : false} ));
    }

    searchApproval = async (e) => {
        e.preventDefault();
        this.setState(Object.assign(this.state.searchForm,{searching : true} ));
        this.setState(Object.assign(this.state,{view : 'ACTIVE_WORKS'} ));
        uiHelpers.showProgressOnTop("Searching approval...")
        await axios.post('/admin/search-approval', JSON.stringify({ 
            approvalNumber: this.state.searchForm.approvalNumber
        }), this.ajaxConfig).then((response) => {
            this.setState(Object.assign(this.state.searchForm,{searchCompleted : true} ));
            this.setState(Object.assign(this.state,{approval : response.data.data.data} ));
            uiHelpers.hideProgressOnTop();
        }, (error) => {
            uiHelpers.showErrorTop('Error occur when searching for approval')
            console.log(error);
        });
        this.setState(Object.assign(this.state.searchForm,{searching : false} ));
    }

    esidChangeHandler = async(esid) =>{
        console.log(esid);
        this.setState(Object.assign(this.state,{esid : esid, meter: null} ));
    }

    meterChangeHandler = async(meter) =>{
        console.log(meter);
        this.setState(Object.assign(this.state,{meter: meter} ));
    }

    renderActiveWorks(){
        var works = [];
        for(var i =0; i < this.state.approval.works.length; i++){
            if(this.state.approval.works[i].status=='A') works.push(this.state.approval.works[i])
        }
        return this.renderWorks(works,'No active work avaiable under this approval')
    }


    renderWorks(worksForRender, messageForNoWork){
        return <>

            {worksForRender.length==0 && (
                <div className="alert alert-primary">
                    {messageForNoWork}
                </div>
            )}
            {worksForRender.map((work, i) => <div className="card mb-3 fs-90">
                <div className="card-body">
                    <table className="table">
                        <tr>
                            <th style={{"width":"180px"}}>Works ID: </th>
                            <td>{work.worksId}</td>
                        </tr>
                        <tr>
                            <th style={{"width":"180px"}}>Status: </th>
                            <td>{work.status=="A"? <strong class="text-success">Active</strong> : <strong class="text-danger">Deleted</strong>}</td>
                        </tr>
                        <tr>
                            <th style={{"width":"180px"}}>Work type: </th>
                            <td>{work.workTypeDescription}</td>
                        </tr>
                        <tr>
                            <th style={{"width":"180px"}}>Number of works: </th>
                            <td>{work.numberOfWorks}</td>
                        </tr>
                    </table>

                    <table className="table table-primary">
                        <thead>
                            <tr>
                                <th>
                                    ESID(s) under this work
                                </th>
                            </tr>
                        </thead>
                    </table>

                    {work.esids.length==0 && (
                        <div className="alert alert-primary">
                            No ESID avaiable udner this work
                        </div>
                    )}

                    {work.esids.map((esid, i) => {

                        if(esid.status!="A") return null;
                    
                        return <div className="card">
                            <div className="card-body">
                                <table className="table">
                                    <tr>
                                        <th style={{"width":"180px"}}>ESID: </th>
                                        <td>{esid.esid}</td>
                                    </tr>
                                    <tr>
                                        <th style={{"width":"180px"}}>Status: </th>
                                        <td>{esid.status=="A"? <strong class="text-success">Active</strong> : <strong class="text-danger">Deleted</strong>}</td>
                                    </tr>
                                    <tr>
                                        <th style={{"width":"180px"}}>WAS Extraction sites: </th>
                                        <td>
                                            {esid.wasExtractionSites.map((site, i) => <div className="card my-3">
                                                <div className="card-body ">
                                                    <table className="table mb-0">
                                                        <tr>
                                                            <th style={{"width":"180px"}}>Transaction status: </th>
                                                            <td>{site.wasExtractionSiteTransactionStatus=="Current" ? <strong class="text-success">{site.wasExtractionSiteTransactionStatus}</strong> : <strong class="text-success">{site.wasExtractionSiteTransactionStatus}</strong>}</td>
                                                        </tr>
                                                        <tr>
                                                            <th style={{"width":"180px"}}>Latitude: </th>
                                                            <td>{site.esLat}</td>
                                                        </tr>
                                                        <tr>
                                                            <th style={{"width":"180px"}}>Longitude: </th>
                                                            <td>{site.esLong}</td>
                                                        </tr>
                                                    </table>
                                                </div> 
                                            </div>)}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th style={{"width":"180px"}}>Meter(s): </th>
                                        <td>
                                            {esid.meters.length==0 && (
                                                <div className="alert alert-primary my-3">
                                                    No meter avaiable under this ESID
                                                </div>
                                            )}

                                            {esid.meters.length!=0 && (
                                                <ul className='mb-0'>
                                                    {esid.meters.map((meter, i) => <li>
                                                        Meter No.: {meter.METER_NO} ({meter.METER_TYPE_DESCRIPTION} )
                                                    </li>)}
                                                </ul>
                                            )}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th style={{"width":"180px"}}>Lot(s): </th>
                                        <td>
                                            {esid.lots.length==0 && (
                                                <div className="alert alert-primary  my-3">
                                                    No lot avaiable under this ESID
                                                </div>
                                            )}

                                            {esid.lots.length!=0 && (
                                                <ul className='mb-0'>
                                                    {esid.lots.map((lot, i) => <li>
                                                        {lot.lotNumber}/{lot.sectionNumber}/{lot.planNumber} {lot.planType}
                                                    </li>)}
                                                </ul>
                                            )}
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>})}
                </div>
            </div>)}
        </>
    }


    render() {
        return <>
            <h3 className='mb-3'>Approval search</h3>
            <form action={""} onSubmit={this.searchApproval} >
                <div class="row">
                    <div class="col-sm-6">

                        <InputTextWithFloatingLabel
                            className="mb-3"
                            id="approvalNumber"
                            value={this.state.searchForm.approvalNumber} 
                            disabled={this.state.searchForm.searching}
                            onChange={this.approvalNumberChange}
                            label={<>Work approval number <span className='text-danger'>*</span></>}
                        />
                        <div>
                            <button type="submit" class="btn btn-primary" disabled={this.state.searchForm.searching}>Search</button>
                        </div>
                    </div>
                </div>
            </form>
            {this.state.searchForm.searchCompleted && <div>
                <hr/>
                <div className={`${this.state.searchForm.searching? 'opacity-25' : ''}`}>
                    {!this.state.approval && <div>
                        <div className="alert alert-primary">
                            Approval number "{this.state.searchForm.approvalNumber}" does not exists
                        </div>
                    </div>}

                    {this.state.approval && <div>
                        <div>
                            <table className="table">
                                <thead>
                                    <tr className='table-primary'>
                                        <th colSpan={2}>
                                            Approval details
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className='fs-90'>
                                    <tr>
                                        <th style={{"width" : "200px"}}>
                                            Approval number:
                                        </th>
                                        <td>
                                            {this.state.approval.license}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th style={{"width" : "200px"}}>
                                            Status:
                                        </th>
                                        <td>
                                            {convertStatus(this.state.approval.status_code)}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th style={{"width" : "200px"}}>
                                            Approval type:
                                        </th>
                                        <td>
                                            {convertApprovalType(this.state.approval.license_type_code)}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <table className="table mb-3">
                                <thead>
                                    <tr className="table-primary">
                                        <th>
                                            Holders
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className='fs-90'>
                                    {this.state.approval.holders.map((holder, i) => <tr>
                                        <td>
                                            {holder.INPA_TYPE=='P' && <span>{holder.Given_Names} {holder.family_name}</span>}
                                            {holder.INPA_TYPE!='P' && <span>{holder.ORGANISATION_NAME}</span>}
                                        </td>
                                    </tr>)}
                                </tbody>
                            </table>

                    

                            <ul class="nav nav-tabs mb-4">
                                <li class="nav-item">
                                    <a role="presentation" href="#" className={this.state.view=="ACTIVE_WORKS"?"nav-link active":"nav-link"} onClick={(e)=>{e.preventDefault();this.setState(Object.assign(this.state,{view : 'ACTIVE_WORKS', esid:null} ));}}>Active works</a>
                                </li>
                                <li class="nav-item">
                                    <a role="presentation" href="#" className={this.state.view=="ESID_VIEW"?"nav-link active":"nav-link"} onClick={(e)=>{e.preventDefault();this.setState(Object.assign(this.state,{view : 'ESID_VIEW', esid:null} ));}}>ESID view</a>
                                </li>
                            </ul>

                            {this.state.view=="ACTIVE_WORKS" && this.renderActiveWorks()}


                            {this.state.view=="ESID_VIEW" && <>
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row mb-4">
                                            <div className="col-sm-6">
                                                <div className='mb-3'>
                                                    <label for="esid" class="control-label">Unique extraction site ID (ESID)</label>
                                                    <EsidSelect approvalNumber={this.state.searchForm.approvalNumber} onEsidChange={this.esidChangeHandler} defaultEsid={this.state.esid? this.state.esid.esid : null } />
                                                    
                                                </div>
                                                {this.state.esid && <div className='mb-6'>
                                                    <label for="esid" class="control-label">Meters under ESID {this.state.esid.esid}</label>
                                                    <MeterSelectForEsid approvalNumber={this.state.searchForm.approvalNumber} esid={this.state.esid.esid} onMeterChange={this.meterChangeHandler} defaultMeterNo={this.state.meter?this.state.meter.METER_NO:null}  />
                                                </div>}
                                            </div>
                                        </div>

                                        <div style={{"min-height": "300px"}}>
                                            {this.state.esid && <>
                                                <table className="table mb-3">
                                                    <thead>
                                                        <tr className='table-primary'>
                                                            <th colSpan={2}>Selected ESID details</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className='fs-90'>
                                                        <tr>
                                                            <th style={{"width":"200px"}}>
                                                                ESID:
                                                            </th>
                                                            <td>
                                                                {this.state.esid.esid}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th>
                                                                Site status:
                                                            </th>
                                                            <td>
                                                                {this.state.esid.status}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th>
                                                                WAS site status:
                                                            </th>
                                                            <td>
                                                                {this.state.esid.wasExtractionSiteStatus}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th>
                                                                Work description:
                                                            </th>
                                                            <td>
                                                                {this.state.esid.workTypeDescription}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th>
                                                                WAS site latitude:
                                                            </th>
                                                            <td>
                                                                {this.state.esid.esLat}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th>
                                                                WAS site longitude:
                                                            </th>
                                                            <td>
                                                                {this.state.esid.esLong}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th>
                                                                Works ID:
                                                            </th>
                                                            <td>
                                                                {this.state.esid.workId}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th>
                                                                Number of works:
                                                            </th>
                                                            <td>
                                                                {this.state.esid.numberOfWorks}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th>
                                                                Work site ID:
                                                            </th>
                                                            <td>
                                                                {this.state.esid.worksiteId}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th>
                                                                Water sharing plan:
                                                            </th>
                                                            <td>
                                                                {this.state.esid.waterSharingPlanDesc}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th>
                                                                Water source: 
                                                            </th>
                                                            <td>
                                                                {this.state.esid.waterSourceName}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th>
                                                                Water type:
                                                            </th>
                                                            <td>
                                                                {this.state.esid.waterType}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table> 
                                            </>}

                                            {this.state.meter && <>
                                                <table className="table">
                                                    <thead>
                                                        <tr className='table-primary'>
                                                            <th colSpan={2}>Selected meter details</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className='fs-90'>
                                                        <tr>
                                                            <th style={{"width":"200px"}}>
                                                                Meter ID:
                                                            </th>
                                                            <td>
                                                                {this.state.meter.METER_ID}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th>
                                                                Meter number:
                                                            </th>
                                                            <td>
                                                                {this.state.meter.METER_NO}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th>
                                                                Calibration factor:
                                                            </th>
                                                            <td>
                                                            {this.state.meter.CALIBRATION_FACTOR}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th>
                                                                Meter category:
                                                            </th>
                                                            <td>
                                                            {this.state.meter.METER_CATEGORY_DESC}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th>
                                                                Meter type:
                                                            </th>
                                                            <td>
                                                            {this.state.meter.METER_TYPE_DESCRIPTION}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table> 
                                            </>}

                                        </div>
                                    </div>
                                </div>
                            </>}
                            
                        </div>
                    </div>}
                </div>
            </div>}
        </>
    }

}

export default SearchApproval;


