import $ from 'jquery';

const closeAllTop =()=>{
    hideProgressOnTop();
    hideErrorTop();
    hideSuccessMsgTop();
}

const showProgressOnTop = (text) =>{
    closeAllTop();
    if(!text) text="";
    $('#progress-top-txt').html(text);
    $('#progress-top').show(100);
}

const hideProgressOnTop =() =>{
    $('#progress-top').hide();
}

var errorTopCloseTimer;
const showErrorTop = (text, withCloseButton=true, milliseconds=6000) =>{
    closeAllTop();
    $('#error-top-txt').html(text);
    if(withCloseButton) {
        $('#error-top .btn').show();
    } else {
        $('#error-top .btn').hide();
    }
    $('#error-top').show(100);
    if(milliseconds){
        errorTopCloseTimer = setTimeout(hideErrorTop, milliseconds);
    }
}

const hideErrorTop = () => {
    $('#error-top').hide();
    if(errorTopCloseTimer){
        clearTimeout(errorTopCloseTimer);
        errorTopCloseTimer = null;
    }
}

var successTopCloseTimer;
const showSuccessMsgTop = (text, withCloseButton=true, milliseconds=6000) =>{
    closeAllTop();
    $('#success-top-txt').html(text);
    if(withCloseButton) {
        $('#success-top .btn').show();
    } else {
        $('#success-top .btn').hide();
    }
    $('#success-top').show(100);
    if(milliseconds){
        successTopCloseTimer = setTimeout(hideSuccessMsgTop, milliseconds);
    }
}

const hideSuccessMsgTop = () =>{
    $('#success-top').hide();
    if(successTopCloseTimer){
        clearTimeout(successTopCloseTimer);
        successTopCloseTimer = null;
    }
}

const getCookie = (name)=>{
    // Split cookie string and get all individual name=value pairs in an array
    var cookieArr = document.cookie.split(";");
    
    // Loop through the array elements
    for(var i = 0; i < cookieArr.length; i++) {
        var cookiePair = cookieArr[i].split("=");
        
        /* Removing whitespace at the beginning of the cookie name
        and compare it with the given string */
        if(name == cookiePair[0].trim()) {
            // Decode the cookie value and return
            return decodeURIComponent(cookiePair[1]);
        }
    }
    
    // Return null if not found
    return null;
}

const setCookie = (name, value, options = {}) => {

    options = {
      path: '/',
      // add other defaults here if necessary
      ...options
    };
  
    if (options.expires instanceof Date) {
      options.expires = options.expires.toUTCString();
    }
  
    let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);
  
    for (let optionKey in options) {
      updatedCookie += "; " + optionKey;
      let optionValue = options[optionKey];
      if (optionValue !== true) {
        updatedCookie += "=" + optionValue;
      }
    }
  
    document.cookie = updatedCookie;
}

export default { 
    showProgressOnTop,
    hideProgressOnTop,
    showErrorTop,
    hideErrorTop,
    showSuccessMsgTop,
    hideSuccessMsgTop,
    getCookie,
    setCookie
};