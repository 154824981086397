import React from 'react'


 const InstallationTypeModal = (props)=> 
<div class="modal fade" id={props.id} tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
        <div class="modal-body">
                <table class="table fs-80">
                    <tbody>
                        <tr>
                            <th>New Meter</th>
                            <td>
                                <p>
                                Brand new meter and telemetry being installed for the first time on a green field site or new established pump. Meter unknown to WaterNSW.
                                </p>
                                This will assign a new meter number.
                            </td>
                        </tr>
                        <tr>
                            <th>Replacement Meter</th>
                            <td>
                                <p>
                                As part of the telemetry installation, a new meter is replacing an existing meter that is known to WaterNSW. Selecting this option will allow you to select the existing meter to be replaced from a list.
                                </p>
                                This will assign a new meter number.
                            </td>
                        </tr>
                        <tr>
                            <th>Existing Meter</th>
                            <td>
                                Telemetry is being installed on a meter that already exists on the site infrastructure and is known to WaterNSW. Selecting this option will allow you to select the existing meter from a list.
                            </td>
                        </tr>
                        
                    </tbody>
                </table>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        </div>
    </div>
    </div>
</div>

export default InstallationTypeModal;