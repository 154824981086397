import React from 'react';
import axios from 'axios';
import Authentication from '../helpers/Authentication'
import RatingTableSeacherLauncher from '../components/ratingTables/RatingTableSeacherLauncher';

import DQPInfoSection from '../components/certificates/common/DQPInfoSection';
import FPHAuthorityHolderDetailsFormSection from '../components/certificates/common/FPHAuthorityHolderDetailsFormSection';
import AHDValidationCertificatePartC from '../components/certificates/ahdValidation/AHDValidationCertificatePartC';
import AHDValidationCertificatePartD from '../components/certificates/ahdValidation/AHDValidationCertificatePartD';
import AHDValidationCertificatePartE from '../components/certificates/ahdValidation/AHDValidationCertificatePartE';
import AHDValidationAndMaintenanceChecklistForm  from '../components/certificates/ahdValidation/AHDValidationAndMaintenanceChecklist';
import AHDValidationCertificateConfirmation from '../components/certificates/ahdValidation/AHDValidationCertificateConfirmation'
import Audit from '../components/certificates/ahdValidation/Audit';

import { formValueSelector, reduxForm, change } from 'redux-form'
import { connect } from 'react-redux'
import { load, save, findById, update } from '../components/certificates/ahdValidation/AHDValidationCertificate.Reducers';
import { deleteDraftAHDForm} from '../components/certificates/common/MeterCertificateCommon'
import $ from 'jquery';
import {PrivacyStatementFPH, GlossaryFPH} from "../components/common/StaticContents";
import {StepProgressBar} from '../components/tools/BootstrapControls'
import uiHelpers from '../helpers/uiHelpers';

import DisplayForRoles from '../components/tools/DisplayForRoles'
import CertificateFiles from '../components/certificates/common/CertificateFiles'
import CertificateAdmin from '../components/certificates/common/CertificateAdmin'

import {  uploadOptionsForAHDValidation as uploadOptions } from '../constants/MeterFormConstants'

const authentication = new Authentication();

class AHDValidationCertificate extends React.Component {

  approvedWorkDetails = {};
  authorityHolderDetails = {};
  meteringEquipmentInformation = {};
  floodplainWorkDetails = {};
  #currentUserEmail;
  #currentUserId;

  ajaxConfig = {
    baseURL: process.env.REACT_APP_WEBAPI_URL,
    headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authentication.getToken() }
  };

  constructor(props) {
    super(props)
    this.nextPage = this.nextPage.bind(this);
    this.previousPage = this.previousPage.bind(this);
    this.#currentUserEmail = authentication.currentUser().emails;
    this.state = {
      page: -1,
      validationDate: new Date(),
      meterReadingDate: new Date(),
      showInstructions: false,
      meteringCircumstanceOptions:
        [
          { id: "1", name: "I have an existing validation that was done by a duly qualified person.", selected: false },
          { id: "2", name: "I have a manufacturer’s certificate.", selected: false },
          { id: "3", name: "I have an accuracy certificate provided by a duly qualified person.", selected: false }
        ],
      data: {},
      confirmingSubmission : false,
      tab : 'MAIN'
    }
  }



  async componentDidMount() {
    const currentContxt = this;
    axios.post('users/getprofile', JSON.stringify({ email: this.#currentUserEmail }), this.ajaxConfig).then(response => {
      currentContxt.#currentUserId = response.data.data.user.user.id;
      currentContxt.pageRefresh();
      
    }).catch(function (error) {
      console.log(error);
      uiHelpers.showErrorTop('Error occurred when reading your user info');
    });
  }

  pageRefresh = async(id) =>{
    var certificateID = id;

    if(!id && this.props.secondaryEquipmentValidationData)
      certificateID = this.props.secondaryEquipmentValidationData.id;

    let search = window.location.search;
    let params = new URLSearchParams(search);

    if(!certificateID || certificateID==0){
      certificateID = params.get("id");
      if(certificateID)
        certificateID = parseInt(certificateID)
    }

    if (certificateID && certificateID!=0) {
      if(this.state.page==-1)
        this.setState({ page: 0});

      try {

        uiHelpers.showProgressOnTop("Getting/Refreshing certificate data...");
        this.props.dispatch(change(this.props.form, 'loading', true));
        await this.props.dispatch(findById(certificateID));
        uiHelpers.hideProgressOnTop();
        this.props.dispatch(change(this.props.form, 'loading', false));
      } catch (error) {
        console.log(error)
        uiHelpers.showErrorTop("Error when getting data...", true, false);
      }

    } else {
      try {
        uiHelpers.showProgressOnTop("Loading data...");
        this.props.dispatch(change(this.props.form, 'loading', true));
        await this.props.dispatch(load());
        uiHelpers.hideProgressOnTop();
        this.props.dispatch(change(this.props.form, 'loading', false)); 
      }
      catch (error) {
        uiHelpers.showErrorTop("Error when getting data...", true, false);
      }

    }
  }

  nextPage = (values) => {
    this.saveDraft(values);
  }

  completeConfirmation = () => {
    this.setState({ confirmingSubmission: false, page: this.state.page + 1 });
  }

  cancelConfrimSubmission = () => {
    this.setState({ confirmingSubmission: false });
  }

  cancelConfrimSubmissionAndGoToPage = (page) => {
    this.setState({ confirmingSubmission: false, page: page });
  }

  saveDraft = (values) => {
    
    //debugger;
    //console.log("EUREKA"+ JSON.stringify(values));
    //values.ahdData.WaterSupplyWorkApprovalNO = values.approvedWorkDetails.WaterSupplyWorkApprovalNO;
    //console.log("source "+ values.approvedWorkDetails.WaterSupplyWorkApprovalNO + "dest" +values.ahdData.WaterSupplyWorkApprovalNO);
    
    if (parseInt(values.clickSaveDraft) === 1 && parseInt(values.clickNext) === 0) {
      
      this.resetValues(values);
      try {
        uiHelpers.showProgressOnTop("Saving your record...");
        this.props.dispatch(change(this.props.form, 'loading', true)); 
        if (values.meteringEquipmentInformation.id > 0)
          this.props.dispatch(update(values, this.pageRefresh));
        else
          this.props.dispatch(save(values, this.pageRefresh));
      }
      catch (error) {
        uiHelpers.showErrorTop("Error when saving your record..", true, false);
      }

    } else if (parseInt(values.clickSaveDraft) === 0 && parseInt(values.clickNext) === 1 && values.ahdData.formStatus == 2 ) {
     
      this.resetValues(values);
      try {
        uiHelpers.showProgressOnTop("Saving your record...");
        this.props.dispatch(change(this.props.form, 'loading', true));
        if (values.approvedWorkDetails.id > 0)
          this.props.dispatch(update(values, this.pageRefresh));
        else
          this.props.dispatch(save(values, this.pageRefresh));
      }
      catch (error) {
        uiHelpers.showErrorTop("Error when saving your record..", true, false);
      }

    }
    else {
      //this.setState({ page: this.state.page + 1 });
      //debugger;
      if(this.state.page == 2 && !this.state.confirmingSubmission ) {//if page 4 and write-mode and not confirmed yet
        this.setState({ confirmingSubmission: true }); //show confirm
      } else if(this.state.page == 2 && this.state.confirmingSubmission ) { //if previously confirmed , goto next page
        this.setState({ confirmingSubmission: false, page: this.state.page + 1 });
      } else if (values.meteringEquipmentInformation.newMeterEquipment == "2" && this.state.page == 2) {
        this.setState({ page: this.state.page + 2 });
      } else if (values.meteringEquipmentInformation.newMeterEquipment == "1" && this.state.page == 3) {
        this.setState({ page: this.state.page + 2 });
      } else {
        this.setState({ page: this.state.page + 1 });
      }
      //console.log(JSON.stringify(values));
    }
  }

  ratingTableSelectedHandler = (selectedRatingTable) => {
    //this.props.dispatch(change(this.props.floodplainWorkDetails, 'storageIdentifyingNumber', selectedRatingTable.ratingTableName));
    this.props.floodplainWorkDetails.ratingTable = selectedRatingTable;
    this.setState({ page: this.state.page + 1 });
  }

  resetValues = (values) => {
    //anyvalues we dont want ovveridden in update
  }


  previousPage = () => {
    if(this.state.page == 3) {
      this.setState({ confirmingSubmission: true, page: this.state.page - 1 });
    } else if (this.state.page == 6) {
      this.setState({ page: this.state.page - 1 });
    }

    this.setState({ page: this.state.page - 1 });   
      
  }

  HideShow = () => {
    this.setState({ showInstructions: !this.state.showInstructions });
  }

  deleteDraft = ()=>{
    let currentContext = this;
    if (this.props.ahdData.id != 0) {
      deleteDraftAHDForm(
        parseInt(this.props.ahdData.id)
        , function(){
          currentContext.props.dispatch(change(currentContext.props.form, 'loading', true));
        }
        , function(){
          window.location = 'Home';
        }
        , function(){

      });
    } else {
      uiHelpers.showErrorTop('You are accessing this function in a invalid way');
    }
  }

  render() {
    const { page, showInstructions,  confirmingSubmission } = this.state

    const { form, loading } = this.props


    return (<div className="tab-content clearfix">
      <h3 className='mb-3'>Survey Benchmarks Validation Certificate</h3>

      <p>
        <a className="btn btn-primary" onClick={this.HideShow}>Privacy and Glossary</a>
      </p>
      
      {showInstructions && (<div class="card card-body fs-90 mb-5">
        <h4>Privacy Statement</h4>
        {PrivacyStatementFPH}
        <h4>Glossary of terms</h4>
        {GlossaryFPH} 
      </div>)}

      {!confirmingSubmission && page === -1 && <RatingTableSeacherLauncher onRatingTableSelected = {this.ratingTableSelectedHandler} />}
      
      {page >= 0 && <>
        <div class="card mb-5">
          <div class="card-body row">
              <div className='col-md-3'>
                <label class="form-label">Record ID</label>
                <input type="text" disabled={true} class="form-control" value={this.props.ahdData.id == 0 ? "Not saved" : this.props.ahdData.id} />
              </div>
              <div className='col-md-3'>
                <label class="form-label">Form Status</label>
                <input type="text" disabled={true} class="form-control" value={this.props.ahdData.formStatus==1?'Draft' : 'Submitted'} />
              </div>
              <div className='col-md-3'>
                <label class="form-label">DQP</label>
                <input type="text" disabled={true} class="form-control" value={this.props.ahdData.createdBy ? (this.props.ahdData.createdBy.firstName + " " + this.props.ahdData.createdBy.lastName) : ""} />
              </div>
          </div>
        </div>

        <ul class="nav nav-tabs mb-3">
            <li class="nav-item ">
              <a class={`nav-link ${this.state.tab == 'MAIN'?'active':''}`} aria-current="page" href="#"
                  onClick={(e)=>{e.preventDefault(); this.setState({ tab : 'MAIN'}); }}
              >Record details</a>
            </li>
            <li class="nav-item">
              <a class={`nav-link ${this.state.tab == 'AUDIT'?'active':''}`} href="#"
                  onClick={(e)=>{e.preventDefault(); this.setState({ tab : 'AUDIT'}); }}
              >Audit/Logs</a>
            </li>
            {this.props.ahdData.formStatus == 2 && <DisplayForRoles roles={{superAdmin : true, isCFOAdmin:true}}>
              <li class="nav-item">
                  <a class={`nav-link ${this.state.tab == 'FILES'?'active':''}`} href="#"
                    onClick={(e)=>{e.preventDefault(); this.setState({ tab : 'FILES'}); }}
                  >Files</a>
              </li>
            </DisplayForRoles>}
            {this.props.ahdData.formStatus == 2 && <DisplayForRoles roles={{superAdmin : true}}>
              <li class="nav-item">
                  <a class={`nav-link ${this.state.tab == 'ADMIN'?'active':''}`} href="#"
                    onClick={(e)=>{e.preventDefault(); this.setState({ tab : 'ADMIN'}); }}
                  >Admin</a>
              </li>
            </DisplayForRoles>}
        </ul>

        {this.state.tab == 'AUDIT' && <Audit id={this.props.ahdData.id} /> }

        {this.state.tab == 'ADMIN' && this.props.ahdData.formStatus == 2 && <DisplayForRoles roles={{superAdmin : true, isCFOAdmin:true}}>
          <CertificateAdmin id={this.props.ahdData.id} entityType="BenchmarkDetails" />
        </DisplayForRoles> }

        {this.state.tab == 'FILES' && <DisplayForRoles roles={{superAdmin : true, isCFOAdmin:true}}>
            <CertificateFiles 
              form={form} 
              certificateNameForSelector="ahdData"
              fileTypeResolver={(document)=>{
                
                var option = uploadOptions.filter(option=>option.id==document.documentType)[0];
                return <>{option?option.name:""}</>
                
              }}
            />
        </DisplayForRoles> }

        {this.state.tab == 'MAIN' && <>

          {this.props.ahdData.formStatus == 1 && this.props.ahdData.createdBy && this.props.ahdData.createdBy.id != this.#currentUserId && <div className='alert alert-primary'>
            This form is owned by <strong>{this.props.ahdData.createdBy.firstName} {this.props.ahdData.createdBy.lastName}</strong> and cannot be edited
          </div>}

          <div className='my-5'>      
            <StepProgressBar 
              totalSteps={6} 
              currentStep={confirmingSubmission? 4 : page>2? page+2: page+1} 
              disableFutureSteps={this.props.ahdData.formStatus == 1} 
              stepClick={(page) =>{this.setState({page : page>=4?page-2:page-1, confirmingSubmission : page==4 })}}/>
          </div>

          {!confirmingSubmission && page === 0 && <DQPInfoSection form={form} onSubmit={this.nextPage} previousPage={this.previousPage} disableEditing={this.props.ahdData.createdBy && this.props.ahdData.createdBy.id != this.#currentUserId} />}
          {!confirmingSubmission && page === 1 && <FPHAuthorityHolderDetailsFormSection form={form} onSubmit={this.nextPage} previousPage={this.previousPage}  disableEditing={this.props.ahdData.createdBy && this.props.ahdData.createdBy.id != this.#currentUserId} />}
          {!confirmingSubmission && page === 2 && <AHDValidationCertificatePartC form={form} onSubmit={this.nextPage} previousPage={this.previousPage}  disableEditing={this.props.ahdData.createdBy && this.props.ahdData.createdBy.id != this.#currentUserId} />}
          
          {confirmingSubmission &&  
            <AHDValidationCertificateConfirmation 
              form={form}
              onSubmit={this.nextPage}
              completeConfirmation={this.completeConfirmation}
              cancelConfrimSubmissionAndGoToPage={this.cancelConfrimSubmissionAndGoToPage}
              cancelConfrimSubmission={this.cancelConfrimSubmission} 
              disableEditing={this.props.ahdData.createdBy && this.props.ahdData.createdBy.id != this.#currentUserId}
          />}      
          {!confirmingSubmission && page === 3 && <AHDValidationCertificatePartD form={form} onSubmit={this.nextPage} previousPage={this.previousPage}  disableEditing={this.props.ahdData.createdBy && this.props.ahdData.createdBy.id != this.#currentUserId} />}      
          {page === 4 && <AHDValidationCertificatePartE form={form} onSubmit={this.nextPage} previousPage={this.previousPage}  disableEditing={this.props.ahdData.createdBy && this.props.ahdData.createdBy.id != this.#currentUserId} />}
              
        </>}

      </>}

      {/* start the delete draft dialog */}
      <div class="modal fade" id="deleteDraftModal" tabindex="-1" role="dialog" aria-labelledby="deleteDraftModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
            <div class="modal-dialog modal-dialog-centered" role="document">
               <div class="modal-content">
                <div class="modal-header">
                    <button type="button" id="deleteDraftModalCloseBtn"  disabled={loading} class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                  <div class="modal-body">
                    <strong>Are you sure you want to delete this draft version of the form?</strong>
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" disabled={loading} data-bs-dismiss="modal">Cancel</button>
                
                    <button type="button" disabled={loading} class="btn btn-primary"
                      onClick={() => { this.deleteDraft(); }}
                    >Confirm deletion</button>
                  </div>
               </div>
            </div>
         </div>
        {/* end the delete draft dialog */}    



        <div class="modal fade" id="checkListModal" tabindex="-1" role="dialog" aria-labelledby="checkListModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="checkListModalLabel">Validation and Maintenance Checklist</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <AHDValidationAndMaintenanceChecklistForm form={form} />
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>

    </div>);
  }
}


AHDValidationCertificate = reduxForm({
  enableReinitialize: true,

})(AHDValidationCertificate)

AHDValidationCertificate = connect(
  (state, props) => ({

    initialValues: state.reducerAHDValidation,
    loaded: state.reducerAHDValidation.loaded,
    meterEquipInfo: formValueSelector(props.form)(state, 'meteringEquipmentInformation'),
    approvedWorkDetails: formValueSelector(props.form)(state, 'approvedWorkDetails'),
    authorityHolderDetails: formValueSelector(props.form)(state, 'authorityHolderDetails'),
    floodplainWorkDetails: formValueSelector(props.form)(state, 'floodplainWorkDetails'),
    masterDataValues: formValueSelector(props.form)(state, 'mdata'),
    form: props.form,
    loading: formValueSelector(props.form)(state, 'loading'),
    ahdData: formValueSelector(props.form)(state, 'ahdData')
    // pull initial values from account reducer
  }),
  {
    load: (payload) => load(payload),
    save: (payload) => save(payload),
    findById: (id) => findById(id),
    update: (payload) => update(payload)           // bind account loading action creator
  }
)(AHDValidationCertificate)

export default AHDValidationCertificate;


