import React from 'react';
import SearchConsents from './common/SearchConsents';

const initState = {
}

class ManageConsent  extends React.Component {

    constructor(props) {
        super(props);
        this.state = JSON.parse(JSON.stringify(initState));
    }

    async componentDidMount() {
    }


    render() {
        return <>
            <SearchConsents/>
        </>
    }
}

export default ManageConsent;

