import React from 'react';
import * as bootstrap from 'bootstrap';
import uiHelpers from '../../../helpers/uiHelpers';
import serviceUrls from '../../../constants/serviceUrls';
import * as apiCaller from '../../../helpers/apiCaller'
import { ArrowRight, CheckCircle, Dot } from '../../../components/tools/Icons';
import { Link } from "react-router-dom";
import { converters } from '../../../helpers/converters';
import { getValidationForm } from '../forms/ValidationForm';
import MeterCommissionHeading from './common/MeterCommissionHeading';
import ValidationPage2 from './common/ValidationPage2';
import ValidationPage3 from './common/ValidationPage3';
import ValidationPage4 from './common/ValidationPage4';
import ValidationPage5 from './common/ValidationPage5';
import { LoadingPage } from '../../../components/tools/Others';
import ValidationPage1 from './common/ValidationPage1';
import ValidationAllFieldsRO from './common/ValidationAllFieldsRO';
import { getValitionApprovalForm } from '../forms/ValidationApprovalForm';
import ValidationApprovalFields from './common/ValidationApprovalFields'
import MeterCommissionAudits from './common/MeterCommissionAudits';
import moment from 'moment';
import MeterCommissionAdmin from './common/MeterCommissionAdmin';
import { userRole, addUserRoleUpdateNotifier } from '../../../helpers/userHelpers';
import ApprovalFormFileRO from './common/ApprovalFormFileRO';
import ValidationApprovalHistory from './common/ValidationApprovalHistory';
import MeterCommissionConsent from './common/MeterCommissionConsent';

const initState = {
    view:'MAIN',
    adminTab:false,
    noPermission: false,
    role:null,
    initialised:false,
    step:1,
    meterCommission : {
        id:null,
        approvalNumber:null,
        consent: {
            id:null,
            approvalType:null,
            status:null,
            requestedDate:null,
            approvedDate:null,
            expiryDate:null,
            requestedEmail:null,
            emailCount:null,
            reachResendLimit:null
        },
        owner: {
            id:null,
            firstname:null,
            lastname:null
        },
    },
    approval : {
        approvalNumber:null,
        holders:[]
    },
    approvalHistories:[],
    commissiongHistories: [],
    backendErrors:null,
    audits:[]
}

class Validation  extends React.Component {

    validationForm;
    approvalForm;
    constructor(props) {
        super(props);
        addUserRoleUpdateNotifier(this.userRolesUpdated);
        this.state = JSON.parse(JSON.stringify(initState));
        this.meterCommissionId = parseInt(props.match.params.id);
        this.validationForm = getValidationForm('validationForm', this,this.triggerCommissiong,this.save,this.goToStep2, this.goToStep3,this.goToStep4,this.goToStep5,this.submit);
        this.approvalForm = getValitionApprovalForm('approvalForm',this);
    }

    async componentDidMount() {
        if(!this.meterCommissionId) return;
        this.errorModal = new bootstrap.Modal(document.getElementById('error-modal'));
        this.refreshPage();
    }

    userRolesUpdated = () => {
        if (userRole.isSuperAdmin) {
            this.setState({ adminTab:true});
        }
    }

    wholePageDataBind = (data,callback) =>{
        if(data.userNoPermission) {
            this.setState({
                noPermission :true,
                initialised: true
            })
        } else {
            const newState = JSON.parse(JSON.stringify(initState));
            newState.role = data.role;
            newState.meterCommission = data.meterCommission;
            newState.approval = data.approval;
            newState.commissiongHistories = data.commissiongHistories;
            newState.initialised = true;
            newState.step = this.state.step;
            newState.backendErrors = data.backendErrors;
            newState.audits = data.audits;
            newState.approvalHistories = data.approvalHistories;
            newState.view = this.state.view;
            newState.adminTab = userRole.isSuperAdmin;

            this.validationForm.vals(data.validationCertificateForm.fields,false);
            this.validationForm.setOptions(data.validationCertificateForm.optionsForFields, false)


            
            if(!(newState.role == "OWNER" && newState.meterCommission.status == "PENDING_VALIDATION")){
                this.validationForm.disableField("formWorkStatus",false)    
            }

            this.validationForm.enableForm(()=>this.setState(newState,()=>{

                if(data.backendErrors) {
                    this.errorModal.show();
                }
                
                if(data.validationApprovalForm.fields){
                    this.approvalForm.vals(data.validationApprovalForm.fields,false);
                    this.approvalForm.enableForm(callback);
                } else if(callback)
                    callback();
            }));
        }
    }

    wholePageAfterRefresh = () => {
        uiHelpers.hideProgressOnTop();
        window.scrollTo(0, 0);
    }

    refreshPage = async () => {
        uiHelpers.showProgressOnTop('Please wait...');
        this.validationForm.disableForm();
        await apiCaller.post(serviceUrls.meterCommission.getMeterCommissionValidation,{id:this.meterCommissionId})
        .then(this.wholePageDataBind).finally(this.wholePageAfterRefresh);
    }

    triggerCommissiong = async (form,callback) => {
        uiHelpers.showProgressOnTop('Triggering commissioning...');
        this.validationForm.disableForm();
        await apiCaller.post(serviceUrls.meterCommission.triggerMeterCommissioning,{id:this.meterCommissionId,validationCertificateForm:this.validationForm.vals()})
        .then((data)=>this.wholePageDataBind(data,callback)).finally(this.wholePageAfterRefresh);
    }

    save = async () => {
        uiHelpers.showProgressOnTop('Saving form...');
        this.validationForm.disableForm();
        await apiCaller.post(serviceUrls.meterCommission.saveMeterCommissionValidation,{id:this.meterCommissionId,validationCertificateForm:this.validationForm.vals()})
        .then(this.wholePageDataBind).finally(this.wholePageAfterRefresh);
    }

    submit = async () => {
        uiHelpers.showProgressOnTop('Submitting form...');
        this.validationForm.disableForm();
        await apiCaller.post(serviceUrls.meterCommission.submitMeterCommissionValidation,{id:this.meterCommissionId,validationCertificateForm:this.validationForm.vals()})
        .then((data)=>this.wholePageDataBind(data,this.setState({submissionSucceed:true}))).finally(this.wholePageAfterRefresh);
    }

    approve = async(callback) => {
        uiHelpers.showProgressOnTop('Approving form...');
        this.validationForm.disableForm();
        await apiCaller.post(serviceUrls.meterCommission.approveMeterValidation,{id:this.meterCommissionId,validationApprovalForm:this.approvalForm.vals()})
        .then((data)=>{
            if(data.backendErrors) this.wholePageDataBind(data,callback);
            else this.wholePageDataBind(data,()=>{this.setState({approvalSucceed:true},callback)});
            
        }).finally(this.wholePageAfterRefresh);
    }

    reject = async(callback) => {
        uiHelpers.showProgressOnTop('Rejecting form...');
        this.validationForm.disableForm();
        await apiCaller.post(serviceUrls.meterCommission.rejectMeterValidation,{id:this.meterCommissionId,validationApprovalForm:this.approvalForm.vals()})
        .then((data)=>this.wholePageDataBind(data,this.setState({rejectionSucceed:true},callback))).finally(this.wholePageAfterRefresh);
    }

    goToStep2 = async () => this.gotToStep(2)
    goToStep3 = async () => this.gotToStep(3)
    goToStep4 = async () => this.gotToStep(4)
    goToStep5 = async () => {
        if(this.validationForm.val('lIDTelemetry')) {
            this.validationForm.disableForm();
            uiHelpers.showProgressOnTop('Checking meter in DAS...');
            this.setState({displayDasInstalaltionErrMsg:false});
            await apiCaller.post(serviceUrls.meterCommission.validateReadyForInstallationDAS,{id:this.meterCommissionId})
            .then((data)=>{
    
                if(data.error_code==0) this.gotToStep(5);
                else {
                    this.setState({displayDasInstalaltionErrMsg:true})
                }
    
            }).finally(()=>{
                this.validationForm.enableForm();
                uiHelpers.hideProgressOnTop();
            });
        } else {
            this.gotToStep(5);
        }
    }
    
    gotToStep = (step) => {this.setState({step})}

    renderPdfFileTop = () => {
        const form = this.validationForm;

        if(form.val('pdfGenerated') !== true  || this.state.role=="OWNER" || this.state.role=="READER") return null;

        return <div class="card mb-3">
            <div class="card-header bg-primary text-white">
                <b>Validation Certificate</b> 
            </div>
            <div className='card-body'>
                {form.renderUneditableTextInputRow('pdfGeneratedDate',<>Generation Date:</>,{labelWidth:4,fieldWidth:8,rowClasses:'mb-3', converter:(o)=>moment((new Date(o))).format('DD/MM/YYYY h:mma')})}                
                {form.renderUneditableTextInputRow('generatedCertificatePath',<>Click to Open:</>,{labelWidth:4,fieldWidth:8, converter:(v)=><a target='_blank' href={'https://wnsw.sharepoint.com/teams/collaboration_CC-DQPPortal'+v}>Validation Certificate</a>})}                
            </div>
        </div>
    }

    renderApprovalFormTop = () => {
        const form = this.approvalForm;
        if(!form.val('status') || form.val('status') == 'PENDING_APPROVAL') return null;

        return <div class="card mb-3">
            <div class="card-header bg-primary text-white">
                <b>QA Review Outcome</b>
            </div>
            <div className='card-body'>
                {form.renderUneditableTextInputRow('approvingOfficer',<>
                        {form.val('status')=='REJECTED' && <>Submission <b className='text-danger'>Not Approved</b> By:</>}
                        {form.val('status')=='APPROVED' && <>Submission <b className='text-success'>Approved</b> By:</>}
                    </>,
                    {rowClasses:'mb-3',converter:officer=>this.state.role=="OWNER" || this.state.role=="READER"?`WaterNSW Customer Data Officer`:<>{officer.firstname} {officer.lastname}</>}
                )}
                {!(form.val('formVersion') > 1) && form.renderUneditableTextInputRow('comments',<>Outcome</>,{labelWidth:4,fieldWidth:8,rowClasses:'mb-3'})}    
                {(form.val('formVersion') > 1) && <>
                    <div class="row mb-3">
                        <div class="col-md-4 py-2">Scenario/s</div>
                        <div class="col-md-8">
                            <ul class="list-group ">
                                {form.val('scenarios').map(s=>{
                                    return <li class="list-group-item bg-light">
                                        <span className='text-primary'>{s.scenario}</span> <br/>
                                        <b>Outcome: </b> {s.outcome} 
                                    </li>
                                })}
                            </ul>
                        </div>
                    </div>
                </>}    

                {form.val('documents').length != 0 && <>
                    <div class="row ">
                        <div class="col-md-4 py-2">Attachment/s</div>
                        <div class="col-md-8">
                            <ApprovalFormFileRO documents={form.val('documents')} />
                        </div>
                    </div>
                </>}        

                <ValidationApprovalHistory approvalHistories={this.state.approvalHistories} approvalForm={this.approvalForm} />
            </div>
        </div>
    }

    renderWorkStatus = () => {
        return <>
            <label class="form-label">Work Status</label>
            {this.validationForm.renderDropDown("formWorkStatus",o=>o.value,o=>o.value)}
        </>
    }

    renderMainContent = () => {
        if(!this.meterCommissionId) return <div className='alert alert-danger'>You access this page incorrectly</div>
        if(!this.state.initialised) return <LoadingPage />;
        if(this.state.noPermission) return <div className='alert alert-danger'>Access denied.</div>

        const form = this.validationForm;
        return <>        
            <MeterCommissionHeading meterCommission={this.state.meterCommission} role={this.state.role} renderWorkStatus={this.renderWorkStatus} />

            <div className='mb-3'>
                <ul className="nav nav-pills">
                    <li className="nav-item">
                        <a onClick={(e)=>{e.preventDefault();this.setState({view:'MAIN'});}} className={`nav-link ${this.state.view=='MAIN'?'active':''}`}  href="#">Process Details</a>
                    </li>
                    <li className="nav-item">
                        <a onClick={(e)=>{e.preventDefault();this.setState({view:'AUDIT'})}} className={`nav-link ${this.state.view=='AUDIT'?'active':''}`} href="#">Audit</a>
                    </li>

                    <li className="nav-item">
                        <a onClick={(e)=>{e.preventDefault();this.setState({view:'CONSENT'})}} className={`nav-link ${this.state.view=='CONSENT'?'active':''}`} href="#">Customer Authorisation</a>
                    </li>

                    {this.state.role!="OWNER" && this.state.adminTab && <li className="nav-item">
                        <a onClick={(e)=>{e.preventDefault();this.setState({view:'ADMIN'})}} className={`nav-link ${this.state.view=='ADMIN'?'active':''}`} href="#">Admin</a>
                    </li>}
                </ul>
            </div>

            <hr/>

            {this.state.view=='AUDIT' ? <>
                <MeterCommissionAudits role={this.state.role} meterCommissionId={this.meterCommissionId}/>
            </> : this.state.view=='CONSENT' ? 
                <MeterCommissionConsent consent={this.state.meterCommission.consent}/> 
            : this.state.view=='ADMIN' ? <>
                <MeterCommissionAdmin 
                    meterCommission={this.state.meterCommission} 
                    refreshPage={this.refreshPage} 
                    lid={form.vals().lid}
                    installationTypeKey= { 
                        form.vals().newMeterEquipment == null ? null :
                        form.vals().newMeterEquipment == '1' ? 'N_M_N_L' :
                        form.vals().newMeterEquipment == '2' ? 'E_M_N_L' :
                        form.vals().newMeterEquipment == '3' ? 'R_M_N_L' :
                        form.vals().newMeterEquipment == '4' ? 'E_M_R_L' :
                        form.vals().newMeterEquipment == '5' ? 'S_R' :
                        null
                    }
                />
            </> : <>

                <div className='card mb-3'>
                    <div className='card-body py-1 fs-90'>
                        <span className='text-primary'><CheckCircle /> Find Approval &amp; Customer Authorisation</span> 
                        <span className='px-2'><ArrowRight/></span>
                        {this.state.meterCommission.status=='COMPLETED' ? <>
                            <span className='text-primary'><CheckCircle /></span> <Link to={`/compliance-process/${this.state.meterCommission.id}/site-registration`}>Site Registration</Link>
                            <span className='px-2'><ArrowRight/></span>
                            <span className='text-primary'><CheckCircle /> <strong>Commissioning &amp; Validation</strong></span>
                        </>
                        :
                        <>
                            <span className='text-primary'><CheckCircle /></span> <Link to={`/compliance-process/${this.state.meterCommission.id}/site-registration`}>Site Registration</Link>
                            <span className='px-2'><ArrowRight/></span>
                            <span className='text-primary'><Dot/></span> <strong className='text-primary'>Commissioning &amp; Validation</strong>
                        </>}
                    </div>
                </div>

                <div className='row mb-3'>
                    <div className='col-md-3 mb-3'>
                        {this.validationForm.renderUneditableTextInput('newMeterEquipment',converters.validationForm.newMeterEquipment,"Installation Type")}
                    </div>
                </div>

                {this.renderPdfFileTop()}

                {this.renderApprovalFormTop()}

                {(this.validationForm.val('formStatus') != 1 || this.state.role!='OWNER' || this.state.meterCommission.status=='DELETED') && <>
                    <ValidationAllFieldsRO meter={this.state.meterCommission.meter} approval={this.state.approval} validationForm={this.validationForm} authoriserDlwcInpaAddrId={this.state.meterCommission.consent.dlwcInpaAddrId} />
                    {this.validationForm.val('formStatus') ==3 && this.state.role=='APPROVING_OFFICER' && <ValidationApprovalFields approvalForm={this.approvalForm} approve={this.approve} reject={this.reject} approvalHistories={this.state.approvalHistories} />}
                </>}

                {this.validationForm.val('formStatus') == 1 && this.state.role=='OWNER' && this.state.meterCommission.status!='DELETED' && <>
                    {this.state.step==1 && <ValidationPage1 role={this.state.role} gotToStep={this.gotToStep} approval={this.state.approval} validationForm={this.validationForm} commissiongHistories={this.state.commissiongHistories} />}
                    
                    {this.state.step==2 && <ValidationPage2 meterCommission={this.state.meterCommission} meter={this.state.meterCommission.meter} authoriserDlwcInpaAddrId={this.state.meterCommission.consent.dlwcInpaAddrId} role={this.state.role} gotToStep={this.gotToStep} approval={this.state.approval} validationForm={this.validationForm}/>}
                    {this.state.step==3 && <ValidationPage3 role={this.state.role} gotToStep={this.gotToStep} approval={this.state.approval} validationForm={this.validationForm}/>}
                    {this.state.step==4 && <ValidationPage4 displayDasInstalaltionErrMsg={this.state.displayDasInstalaltionErrMsg} role={this.state.role} gotToStep={this.gotToStep} approval={this.state.approval} validationForm={this.validationForm}/>}
                    {this.state.step==5 && <ValidationPage5 meter={this.state.meterCommission.meter} role={this.state.role} gotToStep={this.gotToStep} approval={this.state.approval} validationForm={this.validationForm}/>}
                </>}

                {this.state.rejectionSucceed && <div className='alert alert-danger text-center'>This form has failed QA review. An email notification will be sent to the DQP.</div>}
                {this.state.approvalSucceed && <div className='alert alert-success text-center'>This form has passed QA review. An email notification will be sent to the DQP. </div>}
                {this.state.submissionSucceed && <div className='alert alert-success text-center'>This form is submitted for QA Review. Upon completion of review, an email shall be sent.</div>}
            </>}
        </>
    }

    render() {
        return <>
            {this.renderMainContent()}

            <div class="modal fade" id="error-modal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title text-danger">Error/s occurred</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            {this.state.backendErrors && <ul className='text-danger mb-0'>
                                {this.state.backendErrors.map(e=><li>{e}</li>)}
                            </ul>}
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" disabled={this.state.loading} data-bs-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    }
}

export default Validation;
