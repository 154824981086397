import {jwtAuthentication as authentication} from './jwtSignon';
import decodeJWT from 'jwt-decode'; 


const FEATURE_NOT_IMPLEMENTED = "NA";
export class JwtAuthentication {
  config = {};
  constructor() {
    authentication.initialize();
    this.config = {
        
    }
    
  }

  getAuthentication(){
    return authentication;
  }

  isB2C() {
    return authentication.isB2C()
    }
  
  isLoggedIn() {
    return authentication.isLoggedIn()
    }

  logout() {
    authentication.signOut();
  }

  getToken() {
    return authentication.getAccessToken();
  }

  setAccessToken(accessToken) {
    return authentication.setAccessToken(accessToken);
  }

  currentUser() {
    let token = authentication.getAccessToken();    
    const decoded = decodeJWT(token);    
    return decoded;
  }

  
  currentUserAsync(setUser) {
    authentication.getAccessTokenAsync(setUser);    
  }

  getProfile(){   
    return FEATURE_NOT_IMPLEMENTED;
  }

  getSignUp(){
    return FEATURE_NOT_IMPLEMENTED;
  }

  getSignIn(){
    return FEATURE_NOT_IMPLEMENTED;  
  }
  
  getLogout(){
    return FEATURE_NOT_IMPLEMENTED;  
  }

  login() {
    authentication.login();
  }
  signUp() {
   //no op. login is federeated, maintained elsewhere
  }
  profile() {
   //no op. login is federeated, maintained elsewhere
  }
    
  

}

