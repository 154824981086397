import React from 'react';
import * as bootstrap from 'bootstrap';
import * as $ from 'jquery'
import * as apiPathes from '../apiPathes';
import * as serviceCaller from '../serviceCaller'
import DatePicker from 'react-datepicker';
import {Refresh} from '../../components/tools/Icons'
import uiHelpers from '../../helpers/uiHelpers'
import {periodStatus, pageInitialising} from '../constants'
import {convertStatus, convertDateTimeIsoToAusFormat, nameOfCustomer} from '../conveters'
import { withRouter } from "react-router";
import { Trash } from '../../components/tools/Icons';
import Period from './Period'
import Storage from './Storage'
import Audit from './Audit'
import CloseOrFinalisePeriod from './CloseOrFinalisePeriod'
import {UneditableTextInput} from '../../components/tools/BootstrapControls'

class Details  extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            initialising: true,
            disableFunctions: true,
            nominatedMeasurementPeriod: null,
            page: null,
            dim: false,
            selectedStorage: null
        };
        this.idFromUrl = props.match.params.id;
    }
    
    componentDidMount() {
        this.refresh();    
    }

    setDim = (dim,callback,notNotRender) =>{
        if(notNotRender){
            this.state.dim = dim;
            this.state.disableFunctions = dim;
            return;
        }
        this.setState({dim, disableFunctions: dim}, callback);
    }

    setDisableFunctions = (disableFunctions, doNotRender) =>{
        if(doNotRender){
            this.state.disableFunctions = disableFunctions;
            this.setState(this.state)
            return;
        }
        this.setState({disableFunctions})
    }

    refresh =async () =>{
        if(!this.idFromUrl || isNaN(parseInt(this.idFromUrl))) {
            this.setState({initialising: false,disableFunctions: true,invalidAccess: true});
            return;
        }
        let id = parseInt(this.idFromUrl);

        serviceCaller.post(apiPathes.getNominatedMeasurementPeriod,
            {nominatedMeasurementPeriodId: id}, 
            {
                noIWasLoginHandler: this.props.noLoginCallback,
                loadingMsg: 'Loading period details...',
                beforeSend: ()=>{
                    this.setState({disableFunctions: true})
                },
                success: (data)=>{
                    if(data == null) {
                        this.setState({initialising: false,disableFunctions: true,noRecordOrAccess: true});
                    } else {
                        if(data.isDeleted){
                            this.setState({initialising: false,dim:true, disableFunctions: true, nominatedMeasurementPeriod: data, page: 'PERIOD'});
                        } else {
                            this.setState({initialising: false,disableFunctions: false, nominatedMeasurementPeriod: data, page: 'PERIOD'});
                        }
                    }
                }
        });
    }

    setStorage =(storage) =>{this.setState({selectedStorage : storage})}

    updatePeriod = (period, disableFunctions, dim, callback) => {
        var newSelectedStorage = null;
        if(this.state.selectedStorage) {
            period.nominatedMeasurementPeriodStorages.forEach(s=>{
                if(s.id==this.state.selectedStorage.id) {
                    newSelectedStorage = s;
                }
            })
        }
        this.setState({nominatedMeasurementPeriod: period, selectedStorage: newSelectedStorage, disableFunctions, dim}, callback);
    }

    confirmToCloseOrFinanlise =() =>{
        this.setState({page:'CONFIRM_CLOSE_FINANLISE'});
    }

    backToDetails = () => {
        this.setState({page:'PERIOD', dim:false,disableFunctions: false },()=>{
            $("html, body").animate({ scrollTop: 0}, 50);
        });
    }

    render(){

        const period = this.state.nominatedMeasurementPeriod;
        const {dim} = this.state;
        const {noLoginCallback} = this.props;
        const setDim = this.setDim;
        const setDisableFunctions = this.setDisableFunctions;

        if(this.state.invalidAccess) {
            return <>
                <h3 className='mb-3'>Nominated measurement period details</h3>
                <div className='alert alert-danger'>You are not accessing this page in a valid way</div>
            </>
        }

        if(this.state.noRecordOrAccess) {
            return <>
                <h3 className='mb-3'>Nominated measurement period details</h3>
                <div className='alert alert-danger'>We cannot find this record or you do not have access to this record</div>
            </>
        }

        if(this.state.initialising) return <>
            <h3 className='mb-3'>Nominated measurement period details</h3>
            {pageInitialising}
        </>

        if(this.state.page =="CONFIRM_CLOSE_FINANLISE") return <CloseOrFinalisePeriod noLoginCallback={noLoginCallback} updatePeriod={this.updatePeriod} nominatedMeasurementPeriod={period} completedOrCancel={this.backToDetails}/>;

        
        return <>
            
            <div className={`${this.state.dim && !period.isDeleted ?'opacity-25':''}`}>
                <h3 className='mb-3'>Nominated measurement period details</h3>
       
                <div className='row'>
                    <div className='col-md-3 mb-3'>
                        <UneditableTextInput  label="Record id:" fieldExtraClass="bg-light"  value={period.id} />
                    </div>
                    <div className='col-md-3 mb-3'>
                        <UneditableTextInput  label="Status:"  fieldExtraClass="bg-light" value={<><span className={`${period.isDeleted ? 'text-decoration-line-through': ''}`} >{convertStatus(period.status)}</span>{period.isDeleted && <span className='text-danger'>Deleted</span>}</> }/>
                    </div>
                    <div className='col-md-3 mb-3'>
                        <UneditableTextInput  label="Approval:"  fieldExtraClass="bg-light" value={period.approvalNumber} />
                    </div>
                </div>

                <ul class="nav nav-tabs mb-3 mt-4">
                    <li class="nav-item">
                        <a className={`nav-link ${this.state.page=="PERIOD" ? "active" : ''} ${this.state.disableFunctions && !period.isDeleted ? "disabled" : ''}`} href="#" onClick={(e)=>{e.preventDefault();this.setState({page:'PERIOD'});}}>Details</a>
                    </li>
                    <li class="nav-item">
                        <a className={`nav-link ${this.state.page=="AUDIT" ? "active" : ''} ${this.state.disableFunctions && !period.isDeleted ? "disabled" : ''}`} href="#" onClick={(e)=>{e.preventDefault();this.setState({page:'AUDIT'});}}>Audit/Logs</a>
                    </li>
                </ul>
            </div>

            
            {this.state.page =="PERIOD" && this.state.selectedStorage==null && <Period noLoginCallback={noLoginCallback} setDim={setDim} dim={dim} setStorage={this.setStorage} disableFunctions={this.state.disableFunctions} nominatedMeasurementPeriod={period} updatePeriod={this.updatePeriod} confirmToCloseOrFinanlise={this.confirmToCloseOrFinanlise}  setDisableFunctions={setDisableFunctions}/>}
            {this.state.page =="PERIOD" && this.state.selectedStorage && <Storage noLoginCallback={noLoginCallback} setDim={setDim} dim={dim} setStorage={this.setStorage} storage={this.state.selectedStorage} nominatedMeasurementPeriod={period} disableFunctions={this.state.disableFunctions} setDisableFunctions={setDisableFunctions} updatePeriod={this.updatePeriod} />}
            {this.state.page =="AUDIT" && <Audit setDim={setDim} dim={dim} setStorage={this.setStorage} disableFunctions={this.state.disableFunctions} audits={period.nominatedMeasurementPeriodAudits} nominatedMeasurementPeriod={period}  />}


            {/* 
            <hr/>

            {period.approvalNumber == "90CA800300" && <>
                {this.state.page =="PERIOD" && <PointOfTakeMockup nominatedMeasurementPeriod={period} />}
            </>}
            */}
            
        </>;
    }

}

export default withRouter(Details);

class PointOfTakeMockup  extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            meters: [
                {
                    workspaceId: '90CA800300-1345-1',
                    manual: false,
                    volume: 1.424
                },
                {
                    workspaceId: '90CA800300-3412-1',
                    manual: false,
                    volume: 336 
                },
                {
                    workspaceId: '90CA800300-3412-2',
                    manual: false,
                    volume: 1626
                }
            ]
        };
    }

    #dasModal
    async componentDidMount() {
        this.#dasModal = new bootstrap.Modal(document.getElementById('das-modal'));
    }   

    componentWillUnmount() {
        if(this.#dasModal) this.#dasModal.dispose();
    }

    openDasModal= () => {
        this.#dasModal.show();
    }

    render() {

        const period = this.props.nominatedMeasurementPeriod;

        return <>
        
            <div className={`row`}>
                <div className='col-md-3 mb-3'>
                    <UneditableTextInput 
                        label={<>Start date &amp; time:</>} fieldExtraClass="bg-light"
                        value={convertDateTimeIsoToAusFormat(period.periodStartDate, <i className='text-muted'>Not provided</i>)}
                    />
                </div>
                <div className='col-md-3 mb-3'>
                    <UneditableTextInput 
                        label={<>End date &amp; time:</>} fieldExtraClass="bg-light"
                        value={convertDateTimeIsoToAusFormat(period.periodEndDate, <i className='text-muted'>Not provided</i>)}
                    />
                </div>
                    <div className='col-md-3 mb-3'>
                    <label className='form-label invisible'>change button</label>
                    <div>
                        <button  className='btn btn-outline-primary'    >Change end date</button>
                    </div>
                </div>
            </div>

            <div className={`card mb-3`}>
                <div className="card-header">
                    Other in-flow for point of take meters
                </div>
                <div className="card-body">
                    <table className='table'>
                        <thead>
                            <tr>
                                <th style={{width:'220px'}}>
                                    From workspace
                                </th>
                                <th style={{width:'280px'}}>
                                    Type of water
                                </th>
                                <th style={{width:'280px'}}>
                                    Access licence
                                </th>
                                <th style={{width:'200px'}}>
                                    Volume
                                </th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <div  className='text-truncate'>90CA800300-1345-1</div>
                                </td>
                                <td>
                                Regulated water 
                                </td>
                                <td>
                                    90AL3029382
                                </td>
                                <td>
                                    2ML
                                </td>
                                <td className='text-end'>
                                    <button className='btn btn-sm btn-outline-danger'><Trash/></button>
                                </td>
                            </tr>

                            <tr className={`table-secondary`}>
                                <td colSpan={3} className="text-end"><strong>Total volume:</strong></td>
                                <td colSpan={2}>
                                    <strong className='text-primary'>
                                        2 ML
                                    </strong>
                                </td>
                            </tr>

                            <tr>
                                <td colSpan={5} className={`text-center`}>
                                    <button className='btn btn-sm btn-outline-primary'>
                                        Add other in-flow record
                                    </button>
                                </td>
                            </tr>
                            
                            
                        </tbody>
                    </table>
                </div>
            </div>

            <div className={`card mb-3`}>
                <div className="card-header">
                    Point of Intake Meters
                </div>
                <div className="card-body">
                    <table className='table'>
                        <thead>
                            <tr>
                                <th style={{width:'220px'}}>
                                    Workspace ID
                                </th>
                                <th style={{width:'250px'}}>
                                    Telemetry meter is broken
                                </th>
                                <th style={{width:'200px'}}>
                                    Volume during period
                                </th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.meters.map((m,index)=>{
                                return <tr>
                                    <td>
                                        <div  className='text-truncate'>{m.workspaceId}</div>
                                    </td>
                                    <td>
                                        {m.manual && <>
                                            <button type="button" class="btn btn-primary btn-sm me-2" >Yes</button>
                                            <button type="button" class="btn btn-outline-primary btn-sm" onClick={()=>{ this.state.meters[index].manual = false; this.forceUpdate() }}>No</button>                                        
                                        </>}

                                        {!m.manual && <>
                                            <button type="button" class="btn btn-outline-primary btn-sm me-2" onClick={()=>{ this.state.meters[index].manual = true; this.forceUpdate() }}>Yes</button>
                                            <button type="button" class="btn btn-primary btn-sm">No</button>                                        
                                        </>}

                                    </td>
                                    <td>
                                        {m.manual && <>
                                            <div class="input-group">
                                                <input type="text" class="form-control text-end" value={m.volume} />
                                                <span class="input-group-text" >ML</span>
                                            </div>
                                        </>}
                                        {!m.manual && <>
                                            {m.volume} ML                                        
                                        </>}
                                    </td>
                                    <td>
                                        {m.manual && <>
                                            
                                        </>}
                                        {!m.manual && <>
                                            <button type="button" class="btn btn-outline-primary btn-sm" onClick={this.openDasModal}>DAS data details</button>                                   
                                        </>}
                                        
                                    </td>
                                </tr>
                            })}
                        </tbody>
                    </table>
                </div>
            </div>

            <div className={`card mb-3 border-primary`}>
                <div class="card-header text-primary">
                    <strong>Summary</strong>
                </div>
                <div class="card-body">
                    <table className='table'>
                        <tbody>
                            <tr>
                                <th className='w-25 text-end'>
                                    Total flow volume from meter(s):
                                </th>
                                <td>
                                    14.424 ML
                                </td>
                            </tr>
                            <tr>
                                <th className='w-25 text-end'>
                                    Total other Non-FPH inflow:
                                </th>
                                <td>
                                    2 ML
                                </td>
                            </tr>
                            <tr className='table-primary'>
                                <th className='w-25 text-end'>
                                    Total FPH volume:
                                </th>
                                <td>
                                    12.424 ML
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div >
                <a href="/measurement-period/#/dashboard" className='btn btn-warning '>Back to dashboard</a>

                <div className='float-end'>
                    <button className='btn btn-danger '>
                        Delete
                    </button>
                    <div class="vr mx-3"></div>
                    <button className='btn btn-primary'>
                        Close period
                    </button>
                </div>

            </div>


            <div class="modal fade" id="das-modal" tabindex="-1" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">Readings from DAS</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <table className='table'>
                                <thead>
                                    <tr>
                                        <th>
                                            Time
                                        </th>
                                        <th>
                                            flow(inML) <span className='text-muted'>(ML)</span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className='table-info'>
                                        <td>
                                        2022-07-13 04:22:25
                                        </td>
                                        <td>
                                        14984.866
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                        2022-07-13 04:32:25
                                        </td>
                                        <td>
                                        14986.866
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                        2022-07-13 04:32:25
                                        </td>
                                        <td>
                                        14986.866
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                        2022-07-13 04:32:25
                                        </td>
                                        <td>
                                        14986.866
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                        2022-07-13 04:32:25
                                        </td>
                                        <td>
                                        14986.866
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                        2022-07-13 04:32:25
                                        </td>
                                        <td>
                                        14986.866
                                        </td>
                                    </tr>
                                    <tr className='table-info'>
                                        <td>
                                        2022-07-13 04:32:25
                                        </td>
                                        <td>
                                        14986.866
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

        </>
    }
}