import React from 'react';
import { HashRouter as Router, Route } from "react-router-dom";
import { iWasUrl } from '../constants';

export default class Menu extends React.Component { 
    render(){
        return <>
            <nav aria-label="breadcrumb" className='m-2 me-auto'>
                <ol class="breadcrumb mb-0">
                    <li class="breadcrumb-item"><a href={iWasUrl}>iWAS</a> </li>
                    <Router>
                        <Route exact path={["/","/dashboard"]}>
                            <li class="breadcrumb-item active" aria-current="page">Dashboard</li>
                        </Route>
                        <Route exact path="/new">
                            <li class="breadcrumb-item"><a href="/measurement-period/#/dashboard">Dashboard</a></li>
                            <li class="breadcrumb-item active" aria-current="page">New Period</li>
                        </Route>
                        <Route exact path="/details/*">
                            <li class="breadcrumb-item"><a href="/measurement-period/#/dashboard">Dashboard</a></li>
                            <li class="breadcrumb-item active" aria-current="page">Period Details</li>
                        </Route>
                    </Router>
                </ol>
            </nav>
        </>
    }
}