
import {  DQP_QUALIFICATION_TYPE,DQP_QUALIFICATION_TYPE_VALUE} from '../constants/MeterFormConstants';


export const getDQPQulificationTypeNameFromValue = (value) =>{
    switch(value) {
        case DQP_QUALIFICATION_TYPE_VALUE.CERTIFIED_METER_INSTALLER :
            return DQP_QUALIFICATION_TYPE.CERTIFIED_METER_INSTALLER;
        case DQP_QUALIFICATION_TYPE_VALUE.CERTIFIED_METER_INSTALL_AND_STORAGE_METER_VALIDATOR :
            return DQP_QUALIFICATION_TYPE.CERTIFIED_METER_INSTALL_AND_STORAGE_METER_VALIDATOR;
        case DQP_QUALIFICATION_TYPE_VALUE.CERTIFIED_PRACTISING_HYDROGRAPHER :
            return DQP_QUALIFICATION_TYPE.CERTIFIED_PRACTISING_HYDROGRAPHER;
        case DQP_QUALIFICATION_TYPE_VALUE.CERTIFIED_STORAGE_METER_VALIDATOR :
            return DQP_QUALIFICATION_TYPE.CERTIFIED_STORAGE_METER_VALIDATOR;
        case DQP_QUALIFICATION_TYPE_VALUE.REGISTERED_ENGINEER :
            return DQP_QUALIFICATION_TYPE.REGISTERED_ENGINEER;
        case DQP_QUALIFICATION_TYPE_VALUE.REGISTERED_SURVEYOR :
            return DQP_QUALIFICATION_TYPE.REGISTERED_SURVEYOR;
        default :
            return DQP_QUALIFICATION_TYPE.CERTIFIED_METER_INSTALLER;
    }
}

const notifyFunctions = [];

export const addUserRoleUpdateNotifier = (notifyFunction) => {
    notifyFunctions.push(notifyFunction);
}

export const notifyUserRoleUpdated = () => {
    for(var i =0; i <notifyFunctions.length; i++){
        notifyFunctions[i]();
    }
}

export const userRole = {
    loaded : false,
    isActive : false,
    isCFOAdmin : false,
    isSuperAdmin : false,
    isFphUser : false,
    isCDO : false
}