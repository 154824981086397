import React from 'react';
import * as apiPathes from '../../apiPathes';
import * as serviceCaller from '../../serviceCaller'
import { Redirect } from 'react-router-dom';
import { ReactDateTimePicker} from '../../../components/tools/BootstrapControls'
import { convertErrorCode} from '../../conveters'

class ChangeDatesForm  extends React.Component {

    constructor(props){
        super(props);
        const period = props.nominatedMeasurementPeriod;
        this.state = {
            startDate: period.periodStartDate?new Date(period.periodStartDate):null,
            endDate:period.periodEndDate?new Date(period.periodEndDate):null,
            startDateError:null,
            endDateError:null,
            saving: false,
            overallError: null
        };
    }
    
    componentDidMount() {
          
    }

    validatePeriodDates = () => {
        const {startDate,endDate} = this.state;
        const period = this.props.nominatedMeasurementPeriod;
        const today = new Date();
        this.state.startDateError = null;
        this.state.endDateError = null;

        if(!startDate) this.state.startDateError = "Start date is required";
        else if(startDate > today ) this.state.startDateError = "This cannot be future date"; 
        else {
            period.nominatedMeasurementPeriodInFlows.forEach(f=>{
                if(f.isDeleted) return;

                var startDateWithoutTime = new Date(
                    startDate.getFullYear(),
                    startDate.getMonth(),
                    startDate.getDate()
                );

                if(startDateWithoutTime > new Date(f.startDate)){
                    this.state.startDateError = "Some other inflow is outside the start date of the period";
                }
            });
        }

        if(endDate && endDate > today) this.state.endDateError = "This cannot be future date";
        else if(startDate && endDate && startDate >= endDate) this.state.endDateError = "End date needs to be after start date";
        else if(endDate){
            period.nominatedMeasurementPeriodInFlows.forEach(f=>{
                if(f.isDeleted) return;

                var endDateWithoutTime = new Date(
                    endDate.getFullYear(),
                    endDate.getMonth(),
                    endDate.getDate()
                );

                if(endDateWithoutTime < new Date(f.endDate)){
                    this.state.endDateError = "Some other inflow is outside the end date of the period";
                }
            });
        }


        const hasError = (this.state.startDateError || this.state.endDateError);
        if(hasError) this.setState(this.state)
        return !hasError;
    }

    savePeriodDates = async () => {
        
        if(!this.validatePeriodDates()) return;

        const {nominatedMeasurementPeriod} = this.props;
        const {startDate,endDate} = this.state;

        await serviceCaller.post(apiPathes.changePeriodDates,
            {
                nominatedMeasurementPeriodId: nominatedMeasurementPeriod.id,
                approvalNumber : nominatedMeasurementPeriod.approvalNumber,
                periodStartDate: startDate,
                periodEndDate: endDate
            }, 
            {
                noIWasLoginHandler: this.props.noLoginCallback,
                loadingMsg: 'Saving dates....',
                beforeSend: ()=>{
                    this.setState({saving:true});
                },
                errorCodeHandler: (errorCode) =>{
                    this.setState({overallError: convertErrorCode(errorCode), saving: false})
                },
                success: (data)=>{
                    this.props.updatePeriod(data,false, false,()=>{
                        this.props.toggleForm();
                    });
                    
                }
        });
    }

    render(){
        const {startDate,endDate,startDateError,endDateError,saving,overallError} = this.state;
        return <div className='card card-body border-primary border-2 mb-3'>
            <div className="row">
                <div className="col-md-3 mb-2">
                    <ReactDateTimePicker
                        error={startDateError} maxDate={new Date()}
                        onChange={(date)=> this.setState({startDate:date}) }
                        value={startDate}
                        label={<>Start date &amp; time:</>}
                        disabled={true}
                    />
                </div>
                <div className="col-md-3 mb-2">
                    <ReactDateTimePicker
                        error={endDateError} maxDate={new Date()}
                        onChange={(date)=> {this.setState({endDate:date})} }
                        value={endDate}
                        label={<>End date &amp; time:</>}
                        disabled={saving}
                    />
                </div>
                <div className='col-md-6'>
                    <label className='form-label invisible'>buttons</label>
                    <div>
                        <button className="btn btn-outline-primary ms-2" type="button" disabled={saving} onClick={this.savePeriodDates} >Save</button>
                        <button className="btn btn-outline-secondary ms-2" type="button" disabled={saving}  onClick={this.props.toggleForm}>Cancel</button>
                    </div>
                </div>
            </div>
            {overallError && <div className='alert alert-danger'>{overallError}</div>}
        </div>
    }

}

export default ChangeDatesForm;