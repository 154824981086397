import React from 'react';
import { Field, reduxForm, formValueSelector, change } from 'redux-form';
import { connect } from 'react-redux';
import { validate } from '../ahdValidation/AHDCertificateWorkApprovalDetails.Validation'
import { saveDraftAction, clickNextAction } from "../ahdValidation/AHDFormValidations"
import { RadiosForRedux, InputTextForRedux }  from '../../tools/BootstrapControls'

let FPHAuthorityHolderDetailsFormSection = props => {

   const { handleSubmit,ahdData, updateRatingTableData, secondaryEquipmentValidationData,storageManagementValidationData, previousPage, loading, authorityHolderDetails, hasError } = props

   let disableFields = false;
   let formStatus = 1;
   let id = 0;
   
   if(ahdData && ahdData.id!=undefined) {//doChange
      
      disableFields = (parseInt(ahdData.formStatus) == 2)|| props.disableEditing || loading? true:false;
      formStatus = parseInt(ahdData.formStatus);
      id = ahdData.id;

      
   } else if(updateRatingTableData && updateRatingTableData.id!=undefined){
      disableFields = (parseInt(updateRatingTableData.formStatus) == 2)|| props.disableEditing || loading? true:false;
      formStatus = parseInt(updateRatingTableData.formStatus);
      id = updateRatingTableData.id;
   } else if(secondaryEquipmentValidationData && secondaryEquipmentValidationData.id!=undefined){
      disableFields = (parseInt(secondaryEquipmentValidationData.formStatus) == 2)|| props.disableEditing || loading? true:false;
      formStatus = parseInt(secondaryEquipmentValidationData.formStatus);
      id = secondaryEquipmentValidationData.id;
   } else if(storageManagementValidationData && storageManagementValidationData.id!=undefined){
      disableFields = (parseInt(storageManagementValidationData.formStatus) == 2)|| props.disableEditing || loading? true:false;  //if none are set, the logic will error out here.
      formStatus = parseInt(storageManagementValidationData.formStatus);
      id = storageManagementValidationData.id;
   } else {
      disableFields = loading; //its definitely not 2
   }

   const holderTypeSelectionOptions = [{ id: "INDIVIDUAL", name: "Individual", selected: false }, { id: "ORGANISATION", name: "Organisation", selected: false }];
   const holderTypeChangeHandler =(event) => {
      if(event.target.value=='INDIVIDUAL') {
         props.dispatch(change(props.form, 'authorityHolderDetails.corporationNameOrAcn', null));
      } else if(event.target.value=='ORGANISATION') {
         props.dispatch(change(props.form, 'authorityHolderDetails.firstName', null));
         props.dispatch(change(props.form, 'authorityHolderDetails.lastName', null));
      }
   }

   return (
      <form onSubmit={handleSubmit}>
        <h5 className='mb-3'>Details of Approved Works</h5>
        <p><strong>Authority holder details</strong></p>

         <div className='row mb-3'>  
            <div className='col-sm-7'>
               <Field
                  name={"authorityHolderDetails.holderType"}
                  component={RadiosForRedux} 
                  options={holderTypeSelectionOptions}
                  props={{ disabled: disableFields }}
                  handleChange={holderTypeChangeHandler}
                  values={authorityHolderDetails.holderType}
                  label={<>What is the Authority holder type?<span className='text-danger'>* </span></>}
               />
            </div>
         </div>

         {authorityHolderDetails.holderType && <>
         
            {authorityHolderDetails.holderType=='INDIVIDUAL' && <>
               <div className='row mb-3'>
                  <div className='col-sm-7'>
                     <Field
                        name="authorityHolderDetails.firstName"
                        label={<>First name <span className='text-danger'>*</span></>}
                        component={InputTextForRedux}
                        props={{ disabled: disableFields }}
                     />
                  </div>
               </div>
               <div className='row mb-3'>
                  <div className='col-sm-7'>
                     <Field
                        name="authorityHolderDetails.lastName"
                        label={<>Last name <span className='text-danger'>*</span></>}
                        component={InputTextForRedux}
                        props={{ disabled: disableFields }}
                     />
                  </div>
               </div>
            </>}

            {authorityHolderDetails.holderType=='ORGANISATION' && <>
               <div className='row mb-3'>
                  <div className='col-sm-7'>
                     <Field
                        name="authorityHolderDetails.corporationNameOrAcn"
                        label={<>Organisation name <span className='text-danger'>*</span></>}
                        component={InputTextForRedux}
                        props={{ disabled: disableFields }}
                     />
                  </div>
               </div>
            </>}

            <div className='row mb-3'>
               <div className='col-sm-7'>
                  <Field
                     name="authorityHolderDetails.postalAddress"
                     label={<>Postal address <span className='text-danger'>*</span></>}
                     component={InputTextForRedux}
                     props={{ disabled: disableFields }}
                  />
               </div>
            </div>
               
            <div className='row mb-3'>
               <div className='col-sm-7'>
                  <Field
                     name="authorityHolderDetails.phone"
                     label={<>Phone <span className='text-danger'>*</span></>}
                     component={InputTextForRedux}
                     props={{ disabled: disableFields }}
                  />
               </div>
            </div>
               
            <div className='row mb-3'>
               <div className='col-sm-7'>
                  <Field
                     name="authorityHolderDetails.alternatePhone"
                     label="Alternate phone"
                     component={InputTextForRedux}
                     props={{ disabled: disableFields}}
                  />
               </div>
            </div>

            <div className='row mb-3'>
               <div className='col-sm-7'>
                  <Field
                     name="authorityHolderDetails.emailAddress"
                     label={<>Email address <span className='text-danger'>*</span></>}
                     component={InputTextForRedux}
                     props={{ disabled: disableFields }}
                  />
               </div>
            </div>
         </>}

         <hr className='my-4' />
         <p><strong>Property details</strong></p>
         <div className='row mb-3'>
            <div className='col-sm-7'>
               <Field 
                  name="floodplainWorkDetails.propertyName" 
                  label={<>Property Name <span className='text-danger'>*</span></>}
                  props={{ disabled: disableFields}} 
                  component={InputTextForRedux} 
               />
            </div>
         </div>
         <div className='row mb-3'>
            <div className='col-sm-7'>
               <Field 
                  name="floodplainWorkDetails.lotDp" 
                  label={<>Lot DP <span className='text-danger'>*</span></>}
                  props={{ disabled: disableFields }} 
                  component={InputTextForRedux} 
               />
            </div>
         </div>
         <div className='row mb-3'>
            <div className='col-sm-7'>
               <Field 
                  name="floodplainWorkDetails.waterAccessLicenceNo" 
                  label="Water Access License Number(s)" 
                  props={{ disabled: disableFields }} 
                  component={InputTextForRedux} 
               />
            </div>
         </div>
         <div className='row mb-3'>
            <div className='col-sm-7'>
               <Field 
                  name="floodplainWorkDetails.ratingTable.ratingTableName" 
                  label={<>Storage identifying number <span className='text-danger'>*</span></>}
                  props={{ disabled: true, mandatory: false }} 
                  component={InputTextForRedux} 
               />
            </div>
         </div>
         {storageManagementValidationData && storageManagementValidationData.formStatus &&
            <div className='row mb-3'>
               <div className='col-sm-7'>
                  <Field
                     name="floodplainWorkDetails.siteDescription"
                     label="Storage description or name (if applicable)"
                     component={InputTextForRedux}
                     props={{ disabled: disableFields, mandatory: false }}
                  />
               </div>
            </div>
         }

         {hasError && <div className='alert alert-danger'>
         Please fix all errors before you continue
         </div>}

         <div className="text-end">           
            {(formStatus == 1) && (!props.disableEditing) && <>
               {id!= 0 && <button type="button"  className="btn btn-danger me-3" disabled={loading} 
               data-bs-toggle="modal" data-bs-target="#deleteDraftModal">
                  Delete Draft
               </button>}
            
               <button type="button"  className="btn btn-primary" disabled={loading} 
                  onClick={() => { saveDraftAction(props); }}>
                  Save Draft
               </button>
               <div className='vr mx-3'></div>
            </>}
            <button type="button"  className="btn btn-warning me-3" onClick={()=>{previousPage(1)}} disabled={loading} >
               Previous
            </button>
            <button type="button" className="btn btn-success"
               disabled={loading} 
                  onClick={() => { clickNextAction(props); }}>
                  Next
            </button>
         </div>
      </form>
   )
}


FPHAuthorityHolderDetailsFormSection = reduxForm({
   destroyOnUnmount: false,
   forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
   validate,
   onSubmitFail: (errors, dispatch ) => {
      dispatch(change('certificate', 'hasError', true));
   },
   onSubmitSuccess:(result , dispatch )=>{
      dispatch(change('certificate', 'hasError', false));
      window.scrollTo(0, 0);
   }
})(FPHAuthorityHolderDetailsFormSection)

FPHAuthorityHolderDetailsFormSection = connect(
   (state,props) => {

      const selectorCert = formValueSelector('certificate')
      const hasError = selectorCert(state, 'hasError')

      const selector = formValueSelector(props.form)
      const formValues = selector(state, 'mdata')
      const formInfo = selector(state, 'approvedWorkDetails')
      const ahdData = selector(state, 'ahdData')
      const updateRatingTableData = selector(state, 'updateRatingTableData')
      const secondaryEquipmentValidationData = selector(state, 'secondaryEquipmentValidationData')  
      const storageManagementValidationData = selector(state, 'storageManagementValidationData')  //doChange
      const formEqpInfo = selector(state, 'meteringEquipmentInformation')
      const form = props.form
      const floodplainWorkDetails = selector(state, 'floodplainWorkDetails')
      const authorityHolderDetails = selector(state, 'authorityHolderDetails')

      const loading = selector(state, 'loading')

      return {
         formValues,
         ahdData,
         floodplainWorkDetails,
         updateRatingTableData,
         secondaryEquipmentValidationData,
         storageManagementValidationData,
         authorityHolderDetails,
         formInfo,
         formEqpInfo,
         form,
         loading,
         hasError
      }
   }
)(FPHAuthorityHolderDetailsFormSection)
export default FPHAuthorityHolderDetailsFormSection;
