import React from 'react';
import { LoadingPage } from '../../../components/tools/Others';
import * as apiCaller from '../../../helpers/apiCaller'
import serviceUrls from '../../../constants/serviceUrls'
import { UneditableTextInput } from '../../../components/tools/BootstrapControls';
import { convertMeterStatus } from '../../../helpers/converters';
import MeterInfo from './common/MeterInfo';
import MeterForms from './common/MeterForms';
import MeterAudits from './common/MeterAudits';
import MeterIntegrations from './common/MeterIntegrations';
import MeterAdmin from './common/MeterAdmin';

class MeterDetails  extends React.Component {

    meterId;
    constructor(props) {
        super(props)
        this.state = {
            view:'DETAILS',
            initialised:false,
            meter:null
        }
        this.meterId = parseInt(props.match.params.id);
    }

    async componentDidMount() {
        this.refresh();
    }

    refresh = async () => {
        await apiCaller.post(serviceUrls.admin.getMeter,{id:this.meterId})
        .then((data)=>{
            this.setState({
                meter:data.meter,
                audits:data.audits,
                forms:data.forms,
                wasMeter:data.wasMeter,
                initialised:true
            })
        })
    }


    render() {
        if(!this.state.initialised) return <LoadingPage />

        const meter = this.state.meter;
        return <>
            <h3 class="mb-3">Meter Details</h3>
            <div className='row mb-4'>
                <div className='col-md-3 mb-3'>
                    <UneditableTextInput
                        label={<>ID in Portal</>}
                        value={meter.id}
                    />
                </div>
                <div className='col-md-3 mb-3'>
                    <UneditableTextInput
                        label={<>Worksite ID</>}
                        value={meter.meterSiteId}
                    />
                </div>
                <div className='col-md-3 mb-3'>
                    <UneditableTextInput
                        label={<>Source ID</>}
                        value={meter.meterId}
                    />
                </div>
                <div className='col-md-3 mb-3'>
                    <UneditableTextInput
                        label={<>Status</>}
                        value={convertMeterStatus(meter.status)}
                    />
                </div>
            </div>

            <div className='mb-3'>
                <ul className="nav nav-pills">
                    <li className="nav-item">
                        <a onClick={(e)=>{e.preventDefault();this.setState({view:'DETAILS'})}} className={`nav-link ${this.state.view=='DETAILS'?'active':''}`} href="#">Meter Info</a>
                    </li>
                    <li className="nav-item">
                        <a onClick={(e)=>{e.preventDefault();this.setState({view:'AUDITS'})}} className={`nav-link ${this.state.view=='AUDITS'?'active':''}`} href="#">Audits</a>
                    </li>
                    <li className="nav-item">
                        <a onClick={(e)=>{e.preventDefault();this.setState({view:'FORMS'})}} className={`nav-link ${this.state.view=='FORMS'?'active':''}`} href="#">Forms</a>
                    </li>
                    <li className="nav-item">
                        <a onClick={(e)=>{e.preventDefault();this.setState({view:'INTEGRATION'})}} className={`nav-link ${this.state.view=='INTEGRATION'?'active':''}`} href="#">Integration</a>
                    </li>
                    <li className="nav-item">
                        <a onClick={(e)=>{e.preventDefault();this.setState({view:'ADMIN'})}} className={`nav-link ${this.state.view=='ADMIN'?'active':''}`} href="#">Admin</a>
                    </li>
                </ul>
            </div>
            <hr/>
            
            {this.state.view=='DETAILS' && <MeterInfo meter={meter} />}
            {this.state.view=='FORMS' && <MeterForms forms={this.state.forms} />}
            {this.state.view=='AUDITS' && <MeterAudits audits={this.state.audits} />}
            {this.state.view=='INTEGRATION' && <MeterIntegrations meter={meter} />}
            {this.state.view=='ADMIN' && <MeterAdmin meter={meter} refresh={this.refresh} />}

            

            
        </>
    }
}

export default MeterDetails;


