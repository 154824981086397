import {        
        MESSAGE_REQUIRED,        
        MESSAGE_INVALID_EMAIL,
        MESSAGE_MAX_ALLOWED_CHARCATERS_100,
        MESSAGE_MAX_ALLOWED_CHARCATERS_32,
        MESSAGE_MAX_ALLOWED_CHARCATERS_50,
        TEN_CHARS,
        //storageIdentifyingNumber_validation,
        //storageIdentifyingNumber_validation_message
    } from '../../../constants/MeterFormConstants';

export const validate = (values, params) => {

    const errors = {
        floodplainWorkDetails: {
            propertyName: "",
            lotDp: "",
            waterAccessLicenceNo: "",
            //storageIdentifyingNumber: ""
        },
        authorityHolderDetails : {
            holderType: "",
            firstName: "",
            lastName: "",
            corporationNameOrAcn: "",
            postalAddress: "",
            phone: "",
            emailAddress: ""
        }
    }
    let clickSaveDraft = params.clickSaveDraft
    let clickNext = params.clickNext;
//doChange auditPoint
    let effectiveFormStatus = getEffectiveStatus(values);
                            
    if(values.ahdData)


    if(clickSaveDraft == undefined)
        clickSaveDraft = values.clickSaveDraft;
    if(clickNext == undefined)
        clickNext = values.clickNext;

    console.log("clickSaveDraft: "+clickSaveDraft + "clickNext: "+clickNext + "errors:" + JSON.stringify(errors));
    debugger;
    if(clickSaveDraft === 1 || (clickNext === 1 && effectiveFormStatus !=2) ){
        
        if (values.floodplainWorkDetails.propertyName && values.floodplainWorkDetails.propertyName.length > 50) {
            errors.floodplainWorkDetails.propertyName = MESSAGE_MAX_ALLOWED_CHARCATERS_50
        }

        if (values.floodplainWorkDetails.lotDp && values.floodplainWorkDetails.lotDp.length > 100) {
            errors.floodplainWorkDetails.lotDp = MESSAGE_MAX_ALLOWED_CHARCATERS_100
        }       

        if (values.floodplainWorkDetails.waterAccessLicenceNo)
        {
           if (values.floodplainWorkDetails.waterAccessLicenceNo.length > 50) {
                errors.floodplainWorkDetails.waterAccessLicenceNo = MESSAGE_MAX_ALLOWED_CHARCATERS_50
            }
        }

        if (values.floodplainWorkDetails.siteDescription)
        {
           if (values.floodplainWorkDetails.siteDescription.length > 50) {
                errors.floodplainWorkDetails.siteDescription = MESSAGE_MAX_ALLOWED_CHARCATERS_50
            }
        } 
        let unsavedDataAlert = "Data not saved due to validation errors";
        let showUnsavedDataAlert = false;

        if(values.authorityHolderDetails.holderType === 'INDIVIDUAL') {
            if (!values.authorityHolderDetails.firstName || values.authorityHolderDetails.firstName === '') {
                errors.authorityHolderDetails.firstName = MESSAGE_REQUIRED
                showUnsavedDataAlert = true;
            }

            if (!values.authorityHolderDetails.lastName || values.authorityHolderDetails.lastName === '') {
                errors.authorityHolderDetails.lastName = MESSAGE_REQUIRED;
                showUnsavedDataAlert = true;
            }
        } else if(values.authorityHolderDetails.holderType === 'ORGANISATION'){
            if (!values.authorityHolderDetails.corporationNameOrAcn || values.authorityHolderDetails.corporationNameOrAcn === '') {
                showUnsavedDataAlert = true;
                errors.authorityHolderDetails.corporationNameOrAcn = MESSAGE_REQUIRED
            }
        }

        if(showUnsavedDataAlert && params.clickSaveDraft == 1) { 
            //alert(unsavedDataAlert);
        }

    }
    

   if(clickSaveDraft == 0 && clickNext === 1 && effectiveFormStatus !=2)
    {
        debugger;
        if (!values.floodplainWorkDetails.propertyName) {
            errors.floodplainWorkDetails.propertyName = MESSAGE_REQUIRED
        }

        if (!values.floodplainWorkDetails.lotDp) {
            errors.floodplainWorkDetails.lotDp = MESSAGE_REQUIRED
        }     

        if (!values.authorityHolderDetails.holderType || values.authorityHolderDetails.holderType === '') {
            errors.authorityHolderDetails.holderType = MESSAGE_REQUIRED
        } else {
            
            if (!values.authorityHolderDetails.postalAddress || values.authorityHolderDetails.postalAddress === '') {
                errors.authorityHolderDetails.postalAddress = MESSAGE_REQUIRED
            }
            if (!values.authorityHolderDetails.phone || values.authorityHolderDetails.phone === '') {
                errors.authorityHolderDetails.phone = MESSAGE_REQUIRED
            }
            if (!values.authorityHolderDetails.emailAddress || values.authorityHolderDetails.emailAddress === '') {
                errors.authorityHolderDetails.emailAddress = MESSAGE_REQUIRED
            } else {
                if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.authorityHolderDetails.emailAddress)) {
                    errors.authorityHolderDetails.emailAddress = MESSAGE_INVALID_EMAIL
                } else if (values.authorityHolderDetails.emailAddress.length > 100) {
                    errors.authorityHolderDetails.emailAddress = MESSAGE_MAX_ALLOWED_CHARCATERS_100
                }
            }
        }
    }
    console.log('errors in AHDCertificateWorkApprovalDetails.Validation' + JSON.stringify(errors));
    debugger;
    return errors;
}

function getEffectiveStatus(values) {
    return values.secondaryEquipmentValidationData ? values.secondaryEquipmentValidationData.formStatus :
        values.updateRatingTableData ? values.updateRatingTableData.formStatus :
        values.storageManagementValidationData ? values.storageManagementValidationData.formStatus :
            values.ahdData.formStatus;
}
