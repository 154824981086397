import React from 'react';
import axios from 'axios';
import { DQP_QUALIFICATION_TYPE, DQP_QUALIFICATION_TYPE_VALUE } from '../../constants/MeterFormConstants'
import BasePageLayout, {menuItems, MenuConnector, SetCurrentMenu, AuthenticationRequired, AuthenticationSwitch} from './BasePageLayout'
import '../../scss/bootstrap-custom.scss';
import 'bootstrap';
import '../../scss/main.scss';
//import 'react-app-polyfill/ie11';
//import 'react-app-polyfill/stable';
import Authentication from '../../helpers/Authentication';

const authentication = new Authentication();

const menuItemsRP = {
    help : ()=>"help",
    home : ()=>"home",    
    back: ()=>"back"
} 

class ReportingPortalPageLayout extends BasePageLayout {
    constructor(props) {
        super(props);
    }

    async doLogin() {
        let validToken = await authentication.isLoggedInAndTokenValid();
        debugger;
        if (validToken) {
            this.state.menuItems = this.loginMenu();
            this.setState({
                menuItems: this.loginMenu(),
                loggedIn: true
            });
        } else {
            this.state.menuItems = this.nonLoginMenu();
            this.setState({
                menuItems: this.nonLoginMenu(),
                loggedIn: false
            });
        }

    }

    getPortalSpecificLogo = () => {
        return  <a href = "/ReportingPortal"><img alt="DQP logo" src={require("../../images/reporting-portal-logo.png")} style={{height:"30px"}} className="mt-4" /></a>
    }

    loginMenu = () => [
        {
            text: 'Home',
            href: '/ReportingPortal',
            id: menuItemsRP.home()
        },
        {
            text: 'Back',
            href: '/',
            id: menuItemsRP.back()
        }
    ];

    nonLoginMenu = () => [
        {
            text: 'Home',
            href: '/ReportingPortal',
            id: menuItemsRP.home()
        },
        {
            text: 'Back',
            href: '/',
            id: menuItemsRP.back()
        }
    ];

    getPortalName = () => {
        return {
            shortName: 'Water Reporting Portal',
            longName: 'Water Reporting Portal - WaterNSW',
            id: 'rp'
        }
    }

    setUserRoles = (roles = []) => {
        this.setState({ ...this.state, menuItems: [...this.loginMenu()] });
    }
}

export default ReportingPortalPageLayout;


