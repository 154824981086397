import {
    decimal2UpTo8Digits, decimal2UpTo8DigitsMessage,
    positiveIntegerOnly_notMandatory,
    //decimal3UpTo5Digits, decimal3UpTo5DigitsMessage,
    decimal3UpTo7Digits, decimal3UpTo7DigitsMessage,
    decimal2UpTo2Digits_notMandatory,
    decimal3UpTo14Digits, decimal3UpTo14DigitsMessage,

    decimal2Digits, decimal2DigitsMessage,
    isNumber, checkLongitude, longitudeMessage,
    checkLatitude, latitudeMessage,
    integer2to4Digits, integer2to4DigitsMessage, integerUpto8Digits, integerUpto8DigitsMessage,
    MESSAGE_REQUIRED, MESSAGE_REQUIRED_NUMBER,
    MESSAGE_MAX_ALLOWED_CHARCATERS_100,
    pipeLengthValidationMessage
} from '../../../constants/MeterFormConstants';



export const validateMeteringEquipmentInformationFormSection = (values, params) => {

    const errors = {

        meteringEquipmentInformation: {

            meterCircumstancesOptions: "",
            dateOfLastValidation: "",
            meteringSystemTypeId: "",
            meterManufacturer: "",
            meterTypeModel: "",
            meterSerialNo: "",
            meterSize: "",
            pipeSize: "",
            pipeLengthUpstream: "",
            pipeLengthDownstream: "",
            longitude: "",
            latitude: "",
            channelType: "",
            channelWidth: "",
            meterDistanceFromSource: "",
            channelLengthUpstream: "",
            channelLlengthDownstream: "",
            date: "",

            dataCollectorTypeId: "",
            dataCollectorModelNo: "",
            dataCollectorSerialNo: "",
            sealNo: ""

        },
        approvedWorkDetails : {
            lotDp : ""
        }
    }
    console.log("in accuracy validation");
    let clickSaveDraft = params.clickSaveDraft
    let clickNext = params.clickNext;
    if (clickSaveDraft == undefined)
        clickSaveDraft = values.clickSaveDraft;
    if (clickNext == undefined)
        clickNext = values.clickNext;
    console.log("draft:" + clickSaveDraft);

    if (values.meteringEquipmentInformation.dataCollectorTypeId && parseInt(values.meteringEquipmentInformation.dataCollectorTypeId) == 4) {
        values.meteringEquipmentInformation.dataCollectorModelNo = null;
        values.meteringEquipmentInformation.dataCollectorSerialNo = null;
        values.meteringEquipmentInformation.dataCollectorPowerSourceId = null;
        values.meteringEquipmentInformation.dataCollectorSolarPanelSerialNumber = null;
    }

    if (clickSaveDraft === 1 && clickNext === 0) {
        /* if (values.meteringEquipmentInformation.meterManufacturer &&
                values.meteringEquipmentInformation.meterManufacturer.length > 100) {
            errors.meteringEquipmentInformation.meterManufacturer = MESSAGE_MAX_ALLOWED_CHARCATERS_100
        } */
        if (parseInt(values.meteringEquipmentInformation.meterTypeModelId) == parseInt(values.mdata.MeterModelTypeOtherId)) {

            if (!values.meteringEquipmentInformation.meterTypeModel) {
                errors.meteringEquipmentInformation.meterTypeModel = MESSAGE_REQUIRED
            } else if (values.meteringEquipmentInformation.meterTypeModel.length > 100) {
                errors.meteringEquipmentInformation.meterTypeModel = MESSAGE_MAX_ALLOWED_CHARCATERS_100
            }
        }
        if (values.meteringEquipmentInformation.meterSerialNo &&
            values.meteringEquipmentInformation.meterSerialNo.length > 100) {
            errors.meteringEquipmentInformation.meterSerialNo = MESSAGE_MAX_ALLOWED_CHARCATERS_100
        }
        if (values.meteringEquipmentInformation.meterSize) {
            if (!isNumber(values.meteringEquipmentInformation.meterSize)) {
                errors.meteringEquipmentInformation.meterSize = MESSAGE_REQUIRED_NUMBER;
            } else if (values.meteringEquipmentInformation.meterSize && !isNumber(values.meteringEquipmentInformation.meterSize)) {
                errors.meteringEquipmentInformation.meterSize = MESSAGE_REQUIRED_NUMBER;
            } else if (values.meteringEquipmentInformation.meterSize && !integer2to4Digits.test(values.meteringEquipmentInformation.meterSize)) {
                errors.meteringEquipmentInformation.meterSize = integer2to4DigitsMessage;
            }
        }


        if (values.meteringEquipmentInformation.pipeType && values.meteringEquipmentInformation.pipeType.length > 100) {
            errors.meteringEquipmentInformation.pipeType = MESSAGE_MAX_ALLOWED_CHARCATERS_100
        }
        if (values.meteringEquipmentInformation.pipeSize) {
            if (!isNumber(values.meteringEquipmentInformation.pipeSize)) {
                errors.meteringEquipmentInformation.pipeSize = MESSAGE_REQUIRED_NUMBER;
            } else if (!integer2to4Digits.test(values.meteringEquipmentInformation.pipeSize)) {
                errors.meteringEquipmentInformation.pipeSize = integer2to4DigitsMessage;
            }

            /* else if (values.meteringEquipmentInformation.pipeSize.length < 2) {
                errors.meteringEquipmentInformation.pipeSize = MESSAGE_MIN_ALLOWED_DIGITS_2_TO_4;
            }
            else if (values.meteringEquipmentInformation.pipeSize.length > 4) {
                errors.meteringEquipmentInformation.pipeSize = MESSAGE_MIN_ALLOWED_DIGITS_2_TO_4;
            } */
        }

        if (values.meteringEquipmentInformation.pipeConnectionType && values.meteringEquipmentInformation.pipeConnectionType.length > 100) {
            errors.meteringEquipmentInformation.pipeConnectionType = MESSAGE_MAX_ALLOWED_CHARCATERS_100
        }

        if (values.meteringEquipmentInformation.pipeLengthUpstream && !positiveIntegerOnly_notMandatory.test(values.meteringEquipmentInformation.pipeLengthUpstream)) {
            errors.meteringEquipmentInformation.pipeLengthUpstream = pipeLengthValidationMessage;
        } else if (values.meteringEquipmentInformation.pipeLengthUpstream && (parseInt(values.meteringEquipmentInformation.pipeLengthUpstream) > 100000)) {
            errors.meteringEquipmentInformation.pipeLengthUpstream = pipeLengthValidationMessage;
        }

        if (values.meteringEquipmentInformation.pipeLengthDownstream && !positiveIntegerOnly_notMandatory.test(values.meteringEquipmentInformation.pipeLengthDownstream)) {
            errors.meteringEquipmentInformation.pipeLengthDownstream = pipeLengthValidationMessage;
        } else if (values.meteringEquipmentInformation.pipeLengthDownstream && (parseInt(values.meteringEquipmentInformation.pipeLengthDownstream) > 100000)) {
            errors.meteringEquipmentInformation.pipeLengthDownstream = pipeLengthValidationMessage;
        }

        if (values.meteringEquipmentInformation.longitude && !decimal3UpTo14Digits.test(values.meteringEquipmentInformation.longitude)) {
            errors.meteringEquipmentInformation.longitude = decimal3UpTo14DigitsMessage;
        } else if (values.meteringEquipmentInformation.longitude && !checkLongitude(values.meteringEquipmentInformation.longitude))
            errors.meteringEquipmentInformation.longitude = longitudeMessage

        if (values.meteringEquipmentInformation.latitude && !decimal3UpTo14Digits.test(values.meteringEquipmentInformation.latitude)) {
            errors.meteringEquipmentInformation.latitude = decimal3UpTo14DigitsMessage;
        } else if (values.meteringEquipmentInformation.latitude && !checkLatitude(values.meteringEquipmentInformation.latitude))
            errors.meteringEquipmentInformation.latitude = latitudeMessage

        if (values.meteringEquipmentInformation.dataCollectorModelNo && values.meteringEquipmentInformation.dataCollectorModelNo.length > 100) {
            errors.meteringEquipmentInformation.dataCollectorModelNo = MESSAGE_MAX_ALLOWED_CHARCATERS_100
        }

        if (values.meteringEquipmentInformation.dataCollectorSerialNo && values.meteringEquipmentInformation.dataCollectorSerialNo.length > 100) {
            errors.meteringEquipmentInformation.dataCollectorSerialNo = MESSAGE_MAX_ALLOWED_CHARCATERS_100
        }

        if (values.meteringEquipmentInformation.dataCollectorSolarPanelWattage && values.meteringEquipmentInformation.dataCollectorSolarPanelWattage.length > 100) {
            errors.meteringEquipmentInformation.dataCollectorSolarPanelWattage = MESSAGE_MAX_ALLOWED_CHARCATERS_100
        }
        if (parseInt(values.meteringEquipmentInformation.meteringSystemTypeId) === parseInt(values.mdata.OpenChannelId)) {
            if (values.meteringEquipmentInformation.channelType && values.meteringEquipmentInformation.channelType.length > 100)
                errors.meteringEquipmentInformation.channelType = MESSAGE_MAX_ALLOWED_CHARCATERS_100;
            if (values.meteringEquipmentInformation.channelWidth && values.meteringEquipmentInformation.channelWidth.length > 100)
                errors.meteringEquipmentInformation.channelWidth = MESSAGE_MAX_ALLOWED_CHARCATERS_100;

            if (values.meteringEquipmentInformation.meterDistanceFromSource && !isNumber(values.meteringEquipmentInformation.meterDistanceFromSource))
                errors.meteringEquipmentInformation.meterDistanceFromSource = MESSAGE_REQUIRED_NUMBER;
            else if (values.meteringEquipmentInformation.meterDistanceFromSource && !integerUpto8Digits.test(values.meteringEquipmentInformation.meterDistanceFromSource)) {
                errors.meteringEquipmentInformation.meterDistanceFromSource = integerUpto8DigitsMessage;
            }
            if (values.meteringEquipmentInformation.channelLengthUpstream && !decimal2UpTo8Digits.test(values.meteringEquipmentInformation.channelLengthUpstream)) {
                errors.meteringEquipmentInformation.channelLengthUpstream = decimal2UpTo8DigitsMessage;
            }
            if (values.meteringEquipmentInformation.channelLlengthDownstream && !decimal2UpTo8Digits.test(values.meteringEquipmentInformation.channelLlengthDownstream)) {
                errors.meteringEquipmentInformation.channelLlengthDownstream = decimal2UpTo8DigitsMessage;
            }
            if (values.meteringEquipmentInformation.maxMeasuredDischarge && !decimal2Digits.test(values.meteringEquipmentInformation.maxMeasuredDischarge)) {
                errors.meteringEquipmentInformation.maxMeasuredDischarge = decimal2DigitsMessage;
            }
            if (values.meteringEquipmentInformation.minMeasuredDischarge && !decimal2Digits.test(values.meteringEquipmentInformation.minMeasuredDischarge)) {
                errors.meteringEquipmentInformation.minMeasuredDischarge = decimal2DigitsMessage;
            }

            if (values.meteringEquipmentInformation.maxMeasuredVelocity && !decimal2Digits.test(values.meteringEquipmentInformation.maxMeasuredVelocity)) {
                errors.meteringEquipmentInformation.maxMeasuredVelocity = decimal2DigitsMessage;
            }
            if (values.meteringEquipmentInformation.minMeasuredVelocity && !decimal2Digits.test(values.meteringEquipmentInformation.minMeasuredVelocity)) {
                errors.meteringEquipmentInformation.minMeasuredVelocity = decimal2DigitsMessage;
            }

            if (values.meteringEquipmentInformation.maxGaugeHeight && !decimal2Digits.test(values.meteringEquipmentInformation.maxGaugeHeight)) {
                errors.meteringEquipmentInformation.maxGaugeHeight = decimal2DigitsMessage;
            }
            if (values.meteringEquipmentInformation.minGaugeHeight && !decimal2Digits.test(values.meteringEquipmentInformation.minGaugeHeight)) {
                errors.meteringEquipmentInformation.minGaugeHeight = decimal2DigitsMessage;
            }

            if (values.meteringEquipmentInformation.maxError && !decimal2Digits.test(values.meteringEquipmentInformation.maxError)) {
                errors.meteringEquipmentInformation.maxError = decimal2DigitsMessage;
            }

            if (values.meteringEquipmentInformation.minError && !decimal2Digits.test(values.meteringEquipmentInformation.minError)) {
                errors.meteringEquipmentInformation.minError = decimal2DigitsMessage;
            }
            if (values.meteringEquipmentInformation.noOfMeasurementsTaken && !isNumber(values.meteringEquipmentInformation.noOfMeasurementsTaken)) {
                errors.meteringEquipmentInformation.noOfMeasurementsTaken = MESSAGE_REQUIRED_NUMBER
            } else if (values.meteringEquipmentInformation.noOfMeasurementsTaken && !integerUpto8Digits.test(values.meteringEquipmentInformation.noOfMeasurementsTaken)) {
                errors.meteringEquipmentInformation.noOfMeasurementsTaken = integerUpto8DigitsMessage;
            }

        }


    }
    else if (clickSaveDraft == 0 && clickNext === 1 && values.meteringEquipmentInformation.formStatus != 2) {
        debugger;

        if(!values.approvedWorkDetails.lotDp || values.approvedWorkDetails.lotDp==''){
            errors.approvedWorkDetails.lotDp = MESSAGE_REQUIRED;
        }
        
        if (!values.meteringEquipmentInformation.meteringSystemTypeId) {
            errors.meteringEquipmentInformation.meteringSystemTypeId = MESSAGE_REQUIRED
        }

        /*  if (!values.meteringEquipmentInformation.meterManufacturer) {
             errors.meteringEquipmentInformation.meterManufacturer = MESSAGE_REQUIRED
         } else if (values.meteringEquipmentInformation.meterManufacturer.length > 100) {
             errors.meteringEquipmentInformation.meterManufacturer = MESSAGE_MAX_ALLOWED_CHARCATERS_100
         } */

        if (parseInt(values.meteringEquipmentInformation.meterTypeModelId) == 0) {
            errors.meteringEquipmentInformation.meterTypeModelId = MESSAGE_REQUIRED
        }
        if (parseInt(values.meteringEquipmentInformation.meterTypeModelId) == parseInt(values.mdata.MeterModelTypeOtherId)) {

            if (!values.meteringEquipmentInformation.meterTypeModel) {
                errors.meteringEquipmentInformation.meterTypeModel = MESSAGE_REQUIRED
            } else if (values.meteringEquipmentInformation.meterTypeModel.length > 100) {
                errors.meteringEquipmentInformation.meterTypeModel = MESSAGE_MAX_ALLOWED_CHARCATERS_100
            }
        }

        if (!values.meteringEquipmentInformation.meterSerialNo) {
            errors.meteringEquipmentInformation.meterSerialNo = MESSAGE_REQUIRED
        } else if (values.meteringEquipmentInformation.meterSerialNo.length > 100) {
            errors.meteringEquipmentInformation.meterSerialNo = MESSAGE_MAX_ALLOWED_CHARCATERS_100
        }

        if (!values.meteringEquipmentInformation.meterSize) {
            errors.meteringEquipmentInformation.meterSize = MESSAGE_REQUIRED
        } else if (values.meteringEquipmentInformation.meterSize && !isNumber(values.meteringEquipmentInformation.meterSize)) {
            errors.meteringEquipmentInformation.meterSize = MESSAGE_REQUIRED_NUMBER;
        } else if (values.meteringEquipmentInformation.meterSize && !integer2to4Digits.test(values.meteringEquipmentInformation.meterSize)) {
            errors.meteringEquipmentInformation.meterSize = integer2to4DigitsMessage;
        }

        if (values.meteringEquipmentInformation.pipeType && values.meteringEquipmentInformation.pipeType.length > 100) {
            errors.meteringEquipmentInformation.pipeType = MESSAGE_MAX_ALLOWED_CHARCATERS_100
        }


        if (parseInt(values.meteringEquipmentInformation.meteringSystemTypeId) != parseInt(values.mdata.OpenChannelId)) {
            if (!values.meteringEquipmentInformation.pipeSize) {
                errors.meteringEquipmentInformation.pipeSize = MESSAGE_REQUIRED_NUMBER
            } else if (!isNumber(values.meteringEquipmentInformation.pipeSize)) {
                errors.meteringEquipmentInformation.pipeSize = MESSAGE_REQUIRED_NUMBER;
            }
            else if (!integer2to4Digits.test(values.meteringEquipmentInformation.pipeSize)) {
                errors.meteringEquipmentInformation.pipeSize = integer2to4DigitsMessage;
            }
        }

        /* } else if (values.meteringEquipmentInformation.pipeSize.length < 2) {
            errors.meteringEquipmentInformation.pipeSize = MESSAGE_MIN_ALLOWED_DIGITS_2_TO_4;
        }
        else if (values.meteringEquipmentInformation.pipeSize.length > 4) {
            errors.meteringEquipmentInformation.pipeSize = MESSAGE_MIN_ALLOWED_DIGITS_2_TO_4;
        } */

        if (values.meteringEquipmentInformation.pipeConnectionType && values.meteringEquipmentInformation.pipeConnectionType.length > 100) {
            errors.meteringEquipmentInformation.pipeConnectionType = MESSAGE_MAX_ALLOWED_CHARCATERS_100
        }

        if (values.meteringEquipmentInformation.pipeLengthUpstream && !positiveIntegerOnly_notMandatory.test(values.meteringEquipmentInformation.pipeLengthUpstream)) {
            errors.meteringEquipmentInformation.pipeLengthUpstream = pipeLengthValidationMessage;
        } else if (values.meteringEquipmentInformation.pipeLengthUpstream && (parseInt(values.meteringEquipmentInformation.pipeLengthUpstream) > 100000)) {
            errors.meteringEquipmentInformation.pipeLengthUpstream = pipeLengthValidationMessage;
        }

        if (values.meteringEquipmentInformation.pipeLengthDownstream && !positiveIntegerOnly_notMandatory.test(values.meteringEquipmentInformation.pipeLengthDownstream)) {
            errors.meteringEquipmentInformation.pipeLengthDownstream = pipeLengthValidationMessage;
        } else if (values.meteringEquipmentInformation.pipeLengthDownstream && (parseInt(values.meteringEquipmentInformation.pipeLengthDownstream) > 100000)) {
            errors.meteringEquipmentInformation.pipeLengthDownstream = pipeLengthValidationMessage;
        }

        // if (!values.meteringEquipmentInformation.longitude)
        //     errors.meteringEquipmentInformation.longitude = MESSAGE_REQUIRED_NUMBER
        // else 
        if (values.meteringEquipmentInformation.longitude && !checkLongitude(values.meteringEquipmentInformation.longitude))
            errors.meteringEquipmentInformation.longitude = longitudeMessage
        else if (values.meteringEquipmentInformation.longitude && !decimal3UpTo14Digits.test(values.meteringEquipmentInformation.longitude)) {
            errors.meteringEquipmentInformation.longitude = decimal3UpTo14DigitsMessage;
        }

        // if (!values.meteringEquipmentInformation.latitude)
        //     errors.meteringEquipmentInformation.latitude = MESSAGE_REQUIRED_NUMBER
        // else 
        if (values.meteringEquipmentInformation.latitude && !decimal3UpTo14Digits.test(values.meteringEquipmentInformation.latitude)) {
            errors.meteringEquipmentInformation.latitude = decimal3UpTo14DigitsMessage;
        } else if (values.meteringEquipmentInformation.longitude && !checkLatitude(values.meteringEquipmentInformation.latitude))
            errors.meteringEquipmentInformation.latitude = latitudeMessage

        if (values.meteringEquipmentInformation.dataCollectorModelNo && values.meteringEquipmentInformation.dataCollectorModelNo.length > 100) {
            errors.meteringEquipmentInformation.dataCollectorModelNo = MESSAGE_MAX_ALLOWED_CHARCATERS_100
        }

        if (values.meteringEquipmentInformation.dataCollectorSerialNo && values.meteringEquipmentInformation.dataCollectorSerialNo.length > 100) {
            errors.meteringEquipmentInformation.dataCollectorSerialNo = MESSAGE_MAX_ALLOWED_CHARCATERS_100
        }

        if (values.meteringEquipmentInformation.dataCollectorSolarPanelWattage && values.meteringEquipmentInformation.dataCollectorSolarPanelWattage.length > 100) {
            errors.meteringEquipmentInformation.dataCollectorSolarPanelWattage = MESSAGE_MAX_ALLOWED_CHARCATERS_100
        }



        if (values.meteringEquipmentInformation.dataCollectorTypeId && parseInt(values.meteringEquipmentInformation.dataCollectorTypeId) == 0) {
            errors.meteringEquipmentInformation.dataCollectorTypeId = MESSAGE_REQUIRED
        } else if (values.meteringEquipmentInformation.dataCollectorTypeId && parseInt(values.meteringEquipmentInformation.dataCollectorTypeId) != 4) {
            if (!values.meteringEquipmentInformation.dataCollectorModelNo) {
                errors.meteringEquipmentInformation.dataCollectorModelNo = MESSAGE_REQUIRED
            }
            if (!values.meteringEquipmentInformation.dataCollectorSerialNo) {
                errors.meteringEquipmentInformation.dataCollectorSerialNo = MESSAGE_REQUIRED
            }
        }

        /*
        if (!values.meteringEquipmentInformation.dataCollectorModelNo) {
            errors.meteringEquipmentInformation.dataCollectorModelNo = MESSAGE_REQUIRED
        }

        if (!values.meteringEquipmentInformation.dataCollectorSerialNo) {
            errors.meteringEquipmentInformation.dataCollectorSerialNo = MESSAGE_REQUIRED
        }
        */

        if (values.meteringEquipmentInformation.sealNo && values.meteringEquipmentInformation.sealNo.length < 1) {
            errors.meteringEquipmentInformation.sealNo = MESSAGE_REQUIRED
        }

        if (parseInt(values.meteringEquipmentInformation.meteringSystemTypeId) === parseInt(values.mdata.OpenChannelId)) {


            if (!values.meteringEquipmentInformation.channelType) {
                errors.meteringEquipmentInformation.channelType = MESSAGE_REQUIRED
            }
            if (!values.meteringEquipmentInformation.channelWidth) {
                errors.meteringEquipmentInformation.channelWidth = MESSAGE_REQUIRED
            }
            if (!values.meteringEquipmentInformation.meterDistanceFromSource) {
                errors.meteringEquipmentInformation.meterDistanceFromSource = MESSAGE_REQUIRED
            }
            if (!values.meteringEquipmentInformation.channelLengthUpstream) {
                errors.meteringEquipmentInformation.channelLengthUpstream = MESSAGE_REQUIRED
            }
            if (!values.meteringEquipmentInformation.channelLlengthDownstream) {
                errors.meteringEquipmentInformation.channelLlengthDownstream = MESSAGE_REQUIRED
            }

            if (values.meteringEquipmentInformation.channelType && values.meteringEquipmentInformation.channelType.length > 100)
                errors.meteringEquipmentInformation.channelType = MESSAGE_MAX_ALLOWED_CHARCATERS_100;
            if (values.meteringEquipmentInformation.channelWidth && values.meteringEquipmentInformation.channelWidth.length > 100)
                errors.meteringEquipmentInformation.channelWidth = MESSAGE_MAX_ALLOWED_CHARCATERS_100;

            if (values.meteringEquipmentInformation.meterDistanceFromSource && !isNumber(values.meteringEquipmentInformation.meterDistanceFromSource)) {
                errors.meteringEquipmentInformation.meterDistanceFromSource = MESSAGE_REQUIRED_NUMBER;
            } else if (values.meteringEquipmentInformation.meterDistanceFromSource && !integerUpto8Digits.test(values.meteringEquipmentInformation.meterDistanceFromSource)) {
                errors.meteringEquipmentInformation.meterDistanceFromSource = integerUpto8DigitsMessage;
            }

            if (values.meteringEquipmentInformation.channelLengthUpstream && !decimal2UpTo8Digits.test(values.meteringEquipmentInformation.channelLengthUpstream)) {
                errors.meteringEquipmentInformation.channelLengthUpstream = decimal2UpTo8DigitsMessage;
            }

            if (values.meteringEquipmentInformation.channelLlengthDownstream && !decimal2UpTo8Digits.test(values.meteringEquipmentInformation.channelLlengthDownstream)) {
                errors.meteringEquipmentInformation.channelLlengthDownstream = decimal2UpTo8DigitsMessage;
            }

            if (values.meteringEquipmentInformation.maxMeasuredDischarge && !decimal2Digits.test(values.meteringEquipmentInformation.maxMeasuredDischarge)) {
                errors.meteringEquipmentInformation.maxMeasuredDischarge = decimal2DigitsMessage;
            }

            if (values.meteringEquipmentInformation.minMeasuredDischarge && !decimal2Digits.test(values.meteringEquipmentInformation.minMeasuredDischarge)) {
                errors.meteringEquipmentInformation.minMeasuredDischarge = decimal2DigitsMessage;
            }

            if (values.meteringEquipmentInformation.maxMeasuredVelocity && !decimal2Digits.test(values.meteringEquipmentInformation.maxMeasuredVelocity)) {
                errors.meteringEquipmentInformation.maxMeasuredVelocity = decimal2DigitsMessage;
            }

            if (values.meteringEquipmentInformation.minMeasuredVelocity && !decimal2Digits.test(values.meteringEquipmentInformation.minMeasuredVelocity)) {
                errors.meteringEquipmentInformation.minMeasuredVelocity = decimal2DigitsMessage;
            }

            if (values.meteringEquipmentInformation.maxGaugeHeight && !decimal2Digits.test(values.meteringEquipmentInformation.maxGaugeHeight)) {
                errors.meteringEquipmentInformation.maxGaugeHeight = decimal2DigitsMessage;
            }

            if (values.meteringEquipmentInformation.minGaugeHeight && !decimal2Digits.test(values.meteringEquipmentInformation.minGaugeHeight)) {
                errors.meteringEquipmentInformation.minGaugeHeight = decimal2DigitsMessage;
            }

            if (values.meteringEquipmentInformation.maxError && !decimal2Digits.test(values.meteringEquipmentInformation.maxError)) {
                errors.meteringEquipmentInformation.maxError = decimal2DigitsMessage;
            }

            if (values.meteringEquipmentInformation.minError && !decimal2Digits.test(values.meteringEquipmentInformation.minError)) {
                errors.meteringEquipmentInformation.minError = decimal2DigitsMessage;
            }

            if (values.meteringEquipmentInformation.minError && !decimal2Digits.test(values.meteringEquipmentInformation.minError)) {
                errors.meteringEquipmentInformation.minError = decimal2DigitsMessage;
            }

            if (values.meteringEquipmentInformation.noOfMeasurementsTaken && !isNumber(values.meteringEquipmentInformation.noOfMeasurementsTaken)) {
                errors.meteringEquipmentInformation.noOfMeasurementsTaken = MESSAGE_REQUIRED_NUMBER
            } else if (values.meteringEquipmentInformation.noOfMeasurementsTaken && !integerUpto8Digits.test(values.meteringEquipmentInformation.noOfMeasurementsTaken)) {
                errors.meteringEquipmentInformation.noOfMeasurementsTaken = integerUpto8DigitsMessage;
            }
        }

    }
    return errors;
}

export const validateAccuracyCheckFormSection = (values, params) => {

    const errors = {

        meteringEquipmentInformation: {
            date: "",
        }
    }
    console.log("in accuracy validation part D");
    let clickSaveDraft = params.clickSaveDraft
    let clickNext = params.clickNext;
    if (clickSaveDraft == undefined)
        clickSaveDraft = values.clickSaveDraft;
    if (clickNext == undefined)
        clickNext = values.clickNext;
    console.log("draft:" + clickSaveDraft);
    if (clickSaveDraft === 1 && clickNext === 0) {


    }
    else if (clickSaveDraft == 0 && clickNext === 1 && values.meteringEquipmentInformation.formStatus != 2) {
        if (!values.meteringEquipmentInformation.accuracyMethod)
            errors.meteringEquipmentInformation.accuracyMethod = MESSAGE_REQUIRED;

        if (parseInt(values.meteringEquipmentInformation.meteringSystemTypeId) === parseInt(values.mdata.OpenChannelId)) {
            if (!values.meteringEquipmentInformation.accuracyConclusionOpenChannel)
                errors.meteringEquipmentInformation.accuracyConclusionOpenChannel = MESSAGE_REQUIRED;
        }

    }

    return errors;
}

export const validateCertificationFormSection = (values, params) => {

    const errors = {
        meteringEquipmentInformation: {
            date: "",
        }
    }

    let clickSaveDraft = params.clickSaveDraft
    let clickNext = params.clickNext;
    if (clickSaveDraft == undefined)
        clickSaveDraft = values.clickSaveDraft;
    if (clickNext == undefined)
        clickNext = values.clickNext;

    if (clickSaveDraft == 0 && clickNext === 1 && values.meteringEquipmentInformation.formStatus != 2) {

        if (!values.meteringEquipmentInformation.accuracyMaxErrorMeteringEquipment || values.meteringEquipmentInformation.accuracyMaxErrorMeteringEquipment == "")
            errors.meteringEquipmentInformation.accuracyMaxErrorMeteringEquipment = MESSAGE_REQUIRED;
        else if (parseInt(values.meteringEquipmentInformation.accuracyMaxErrorMeteringEquipment) == 0)
            errors.meteringEquipmentInformation.accuracyMaxErrorMeteringEquipment = MESSAGE_REQUIRED;

        if (!values.meteringEquipmentInformation.accuracyOwnMeteringEquipment || values.meteringEquipmentInformation.accuracyOwnMeteringEquipment == "")
            errors.meteringEquipmentInformation.accuracyOwnMeteringEquipment = MESSAGE_REQUIRED;
        else if (parseInt(values.meteringEquipmentInformation.accuracyOwnMeteringEquipment) == 0)
            errors.meteringEquipmentInformation.accuracyOwnMeteringEquipment = MESSAGE_REQUIRED;
        if (!values.meteringEquipmentInformation.dateValidation) {
            errors.meteringEquipmentInformation.dateValidation = MESSAGE_REQUIRED
        }
        if (!values.meteringEquipmentInformation.date) {
            errors.meteringEquipmentInformation.date = MESSAGE_REQUIRED
        }
    }


    return errors;
}



