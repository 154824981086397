import authentication from './reactazureb2c';
import decodeJWT from 'jwt-decode'; 

class Authentication {
  config = {};
  constructor() {
    authentication.initialize();
    this.config = {
        instance: process.env.REACT_APP_B2C_INSTANCE,
        tenant: process.env.REACT_APP_B2C_TENANT,
        signInPolicy: process.env.REACT_APP_B2C_SIGNIN_SIGNOUT_POLICY,
        signUpPolicy: process.env.REACT_APP_B2C_SIGNUP_POLICY,
        profileEditPolicy: process.env.REACT_APP_B2C_PROFILE_EDIT_POLICY,
        clientId: process.env.REACT_APP_B2C_SECRET_KEY,
        cacheLocation: process.env.REACT_APP_B2C_CACHE_LOCATION,
        scopes: [process.env.REACT_APP_B2C_SCOPES],
        redirectUri: process.env.REACT_APP_B2C_REDIRECT_URI,
        postLogoutRedirectUri: process.env.REACT_APP_B2C_LOGOUT_REDIRECT_URI,
    }
    
  }

  getAuthentication(){
    return authentication;
  }
  
  isLoggedIn() {
    return authentication.isLoggedIn()
  }

  async isLoggedInAndTokenValid() {
    return await authentication.isLoggedInAndTokenValid();
  }

  logout() {
    authentication.signOut();
  }

  getToken() {
    return authentication.getAccessToken();
  }

  setAccessToken(accessToken) {
    return authentication.setAccessToken(accessToken);
  }

  async getTokenAsync(){
    return await authentication.getTokenSilentAsync();
  }

  decodeUserFromToken(token){
    const decoded = decodeJWT(token);
    return {
      name: decoded.name,
      firstName: decoded.given_name,
      lastName: decoded.family_name,
      emails: decoded.emails,
      city: decoded.city,
      country: decoded.country,
      dqpabnoracn : decoded.extension_DQPABNORACN,
      dqpbusinessname : decoded.extension_DQPBusinessName,
      dqpcertifiedmeterinstallernumber : decoded.extension_DQPCertifiedMeterInstallerNumber,
      dqpphonenumber : decoded.extension_DQPPhoneNumber,
      dqppostaladdress : decoded.extension_DQPPostalAddress,
      dqpqualificationtype : decoded.extension_DQPQualificationType
    };
  }

  currentUser() {
    const decoded = decodeJWT(authentication.getAccessToken());
    return {
      name: decoded.name,
      firstName: decoded.given_name,
      lastName: decoded.family_name,
      emails: decoded.emails,
      city: decoded.city,
      country: decoded.country,
      dqpabnoracn : decoded.extension_DQPABNORACN,
      dqpbusinessname : decoded.extension_DQPBusinessName,
      dqpcertifiedmeterinstallernumber : decoded.extension_DQPCertifiedMeterInstallerNumber,
      dqpphonenumber : decoded.extension_DQPPhoneNumber,
      dqppostaladdress : decoded.extension_DQPPostalAddress,
      dqpqualificationtype : decoded.extension_DQPQualificationType
    };
  }

  getProfile(){
    
    const tenantSubdomain = this.config.tenant.split('.')[0]
    const instance = `https://${tenantSubdomain}.b2clogin.com/`
    const authority = `${instance}${this.config.tenant}/${this.config.profileEditPolicy}`
    const profileURL = authority +"/oauth2/v2.0/authorize?response_type=id_token&scope="+encodeURIComponent(this.config.scopes[0]+" openid profile")+"&client_id="+this.config.clientId+"&redirect_uri=" + encodeURIComponent(this.config.redirectUri) +"&state="+window.msal.state.login+"&nonce=defaultNonce&client_info=1&x-client-SKU=MSAL.JS&x-client-Ver=0.2.4&client-request-id=0a6a5644-f07a-41b6-bf73-70e7f81e9e02&response_mode=fragment";// "&state=7ae3fd94-c96d-4412-a096-68645989a2a1&nonce=330b1813-014b-4d52-969a-d9a37906d3c2&client_info=1&x-client-SKU=MSAL.JS&x-client-Ver=0.2.4&client-request-id=0a6a5644-f07a-41b6-bf73-70e7f81e9e02&response_mode=fragment";
    //this.state.profileUrl = "https://wnswdqpdev.b2clogin.com/wnswdqpdev.onmicrosoft.com/B2C_1_Profilev2/oauth2/v2.0/authorize?response_type=id_token&scope=https%3A%2F%2Fwnswdqpdev.onmicrosoft.com%2Fapi%2Fdemo.read%20openid%20profile&client_id=685d6a00-3dc6-40e0-be9d-2cc02be1fc4d&redirect_uri=" + encodeURIComponent(process.env.REACT_APP_B2C_REDIRECT_URI) + "&state=7ae3fd94-c96d-4412-a096-68645989a2a1&nonce=330b1813-014b-4d52-969a-d9a37906d3c2&client_info=1&x-client-SKU=MSAL.JS&x-client-Ver=0.2.4&client-request-id=0a6a5644-f07a-41b6-bf73-70e7f81e9e02&response_mode=fragment";
    return profileURL;
  }

  getSignUp(){
    const tenantSubdomain = this.config.tenant.split('.')[0]
    const instance = `https://${tenantSubdomain}.b2clogin.com/`
    const authority = `${instance}${this.config.tenant}`
    const signUpUrl = authority+"/oauth2/v2.0/authorize?p="+this.config.signUpPolicy+"&client_id="+this.config.clientId+"&nonce=defaultNonce&redirect_uri="+this.config.redirectUri+"&scope=openid&response_type=id_token&prompt=login"
    return signUpUrl;
    //https://wnswdqpdev.b2clogin.com/wnswdqpdev.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_Signup&client_id=84172315-272c-416b-9d52-a2dc0305c6af&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fjwt.ms&scope=openid&response_type=id_token&prompt=login
    //https://wnswdqpdev.b2clogin.com/wnswdqpdev.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_Signup&client_id=84172315-272c-416b-9d52-a2dc0305c6af&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fjwt.ms&scope=openid&response_type=id_token&prompt=login
  }

  getSignIn(){
    const tenantSubdomain = this.config.tenant.split('.')[0]
    const instance = `https://${tenantSubdomain}.b2clogin.com/`
    const authority = `${instance}${this.config.tenant}`
    const signUpUrl = authority+"/oauth2/v2.0/authorize?p="+this.config.signInPolicy+"&client_id="+this.config.clientId+"&nonce=defaultNonce&redirect_uri="+this.config.redirectUri+"&scope=openid&response_type=id_token&prompt=login"
    return signUpUrl;
    //https://wnswdqpdev.b2clogin.com/wnswdqpdev.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_Signup&client_id=84172315-272c-416b-9d52-a2dc0305c6af&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fjwt.ms&scope=openid&response_type=id_token&prompt=login
    //https://wnswdqpdev.b2clogin.com/wnswdqpdev.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_Signup&client_id=84172315-272c-416b-9d52-a2dc0305c6af&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fjwt.ms&scope=openid&response_type=id_token&prompt=login
  }
  
  getLogout(){
    
    
   // https://wnswdqpdev.b2clogin.com/wnswdqpdev.onmicrosoft.com/{policy-name}/oauth2/v2.0/logout
    const tenantSubdomain = this.config.tenant.split('.')[0]
    const instance = `https://${tenantSubdomain}.b2clogin.com/`
    const authority = `${instance}${this.config.tenant}/${this.config.signInPolicy}`
    const logoutUrl = authority+"/oauth2/v2.0/logout?"+'post_logout_redirect_uri=' + encodeURIComponent(this.config.postLogoutRedirectUri);
    return logoutUrl;
    //https://wnswdqpdev.b2clogin.com/wnswdqpdev.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_Signup&client_id=84172315-272c-416b-9d52-a2dc0305c6af&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fjwt.ms&scope=openid&response_type=id_token&prompt=login
    //https://wnswdqpdev.b2clogin.com/wnswdqpdev.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_Signup&client_id=84172315-272c-416b-9d52-a2dc0305c6af&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fjwt.ms&scope=openid&response_type=id_token&prompt=login
  }
  login() {
    authentication.login();
  }
  signUp() {
    authentication.signUp();
  }
  profile() {
    authentication.profile();
  }
    
  

}

export default Authentication;