import React, { useContext, useEffect, useState } from 'react';
import { GlobalContext } from './State';
import { submission } from './API';
import { TIMESTAMP,MSG_SAVE } from './MeasurementConstants';

const WIDTH_RADIO = { width: '0.025%' }
const WIDTH_TEXT = { width: '97.5%' }         

export const Declaration = () => {
  const { holder: { nomination, referenceData }, loadNominiation, flickPage,updateDeclaration } = useContext(GlobalContext);
  const [timestamp, setTimestamp] = useState();

  const handleSubmit = (e) => {
    e.preventDefault();
    setTimestamp(TIMESTAMP());
  };

  const handleChangeChk = (id) => {    
      updateDeclaration(id);
  }

  useEffect(() => {
    if (timestamp) {
      nomination.attributes = {...nomination.attributes, formStatus:2}
      submission(nomination,
        (data) => {
          loadNominiation(data);
          alert(MSG_SAVE.SUCCESS);
        },
        () => {
          alert(MSG_SAVE.ERROR);
        })
    }
  }, [timestamp]);

  return (<React.Fragment>
    <div class="row">     
      <div class="col_12"><h3 style={{color: "#0054a6"}}>Declarations</h3></div>
    </div>
    <div class="row">     
      <div class="col_12">&nbsp;</div>
    </div>
    {(nomination.timeRange.endDate != null) &&
          <div class="row">
            <div style ={WIDTH_RADIO} >
            <input type="checkbox" id = "declaration1" checked={nomination.declaration1==true} onChange={e=>handleChangeChk(1)} />              
            </div>
            <div style ={WIDTH_TEXT} >
            <label>I acknowledge that all temporary storage areas, field storages and dams, used to hold Floodplain Harvesting water during measurement period are empty.</label>
            </div>
          </div>
    
    }
    {(nomination.discharge && Object.keys(nomination.discharge).length>0) &&
          <React.Fragment>
          <div class="row">
            <div style ={WIDTH_RADIO} >
            <input type="checkbox" id = "declaration2" checked={nomination.declaration2==true} onChange={e=>handleChangeChk(2)} /><br />              
            </div>
            <div style ={WIDTH_TEXT} >
            <label>No Floodplain Harvesting water was added to the storage during this discharge period.</label>
            </div>
          </div>
           <div class="row">
           <div style ={WIDTH_RADIO} >
           <input type="checkbox" id = "declaration3" checked={nomination.declaration3==true} onChange={e=>handleChangeChk(3)} /><br />              
           </div>
           <div style ={WIDTH_TEXT} >
           <label>No Floodplain Harvesting water from the current period was taken out of the storage during the discharge period. All Floodplain Harvesting water taken out of the storage has been accounted for in previous measurement periods</label>
           </div>
         </div>
         </React.Fragment>    
    }

   
    <div class="row" >
      <form onSubmit={handleSubmit} id="placeholder">      
        <button class="button btn-success btn-lg" type="submit"
          style={{ display: (nomination.attributes.formStatus < 2) ? 'inline' : 'none', float: 'right' }} >Submit information</button>
          <div style={{ float: 'right' }}>&nbsp;&nbsp;</div>
        {<button class="button btn-success btn-lg" onClick={() => flickPage(2)}
         style={{ display: 'inline', float: 'right' }} >Previous</button>
        }
      </form>
    </div>
    
  </React.Fragment>)
}