import React from 'react';
import { Field, reduxForm, formValueSelector, change, clearSubmitErrors, untouch, stopSubmit, SubmissionError } from 'redux-form';
import { connect } from 'react-redux'
import 'react-redux';
import 'redux';
import moment from 'moment';
import { btnStyle, METER_FORM_TYPES, METER_VALIDATION_TYPE } from '../../../constants/MeterFormConstants'
import {  clickNextAction, saveDraftAction } from "../common/MeterFormValidations"
import { validateCertificatePartE } from './TransitionalReporting.Validation';
const validate = validateCertificatePartE;


let TransitionalReportingConfirmation = props => {
    
    const {
        meterEquipInfo,
        authHolderInfo,
        meterNumberExternalList,
        waterAccessLicenceNoExternalList,
        appWorkInfo,
        masterDataValues

        , cancelConfrimSubmissionAndGoToPage
        , cancelConfrimSubmission

        , completeConfirmation

        , loading

        , handleSubmit
        , hasError
    } = props;

    const meteringEquipmentConsistentOptions =
      [
         { id: "1", name: "Yes", selected: false },
         { id: "2", name: "No", selected: false }
      ];


      const meteringCircumstanceValidationOptions =
      [
         { id: "1", name: "I have an existing validation that was done by a duly qualified person", selected: false },
         { id: "2", name: "I have a manufacturer's certificate", selected: false },
         { id: "3", name: "I have an accuracy certificate provided by a duly qualified person", selected: false }
      ];

    

  return (
    <form className="vertical" onSubmit={handleSubmit}>
        <h5 className='mb-3'>Review</h5>
        <div class="alert alert-primary" role="alert">
            Please review the information below before completing your submission.
            <br/>
            The form cannot be edited once submitted. Therefore, please confirm all configuration details are correct before submitting this form.
        </div>

        <div className='card mb-3'>
            <div className='card-header fw-bold'>Authority Holder Details</div>
            <div className='card-body'>
                <table class="table fs-90 mb-3">
                    <tbody>
                        {(authHolderInfo.firstName != null && authHolderInfo.lastName != null && authHolderInfo.firstName != '' && authHolderInfo.lastName != '') &&
                        <tr>
                            <th scope="row" style={{width:'40%'}}>
                            Authority holder name(s)
                            </th>
                            <td>{authHolderInfo.authorityHolderName}</td>
                        </tr>
                        }
                        <tr>
                            <th scope="row" style={{width:'40%'}}>
                            Corporation name and ACN
                            </th>
                            <td>{authHolderInfo.corporationNameOrAcn}</td>
                        </tr>
                        <tr>
                            <th scope="row" style={{width:'40%'}}>
                            Postal address
                            </th>
                            <td>{authHolderInfo.postalAddress}</td>
                        </tr>
                        <tr>
                            <th scope="row" style={{width:'40%'}}>
                            Phone
                            </th>
                            <td>{authHolderInfo.phone}</td>
                        </tr>
                        <tr>
                            <th scope="row" style={{width:'40%'}}>
                            Alternate phone
                            </th>
                            <td>{authHolderInfo.alternatePhone}</td>
                        </tr>
                        <tr>
                            <th scope="row" style={{width:'40%'}}>
                            Email address
                            </th>
                            <td>{authHolderInfo.emailAddress}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className='card-header'>
                Contact Person or Agent
            </div>
            <div className='card-body'>
                <table class="table fs-90 mb-3">
                    <tbody>
                        <tr>
                            <th scope="row" style={{width:'40%'}}>
                            Name
                            </th>
                            <td>{authHolderInfo.contactPersonName}</td>
                        </tr>
                        <tr>
                            <th scope="row" style={{width:'40%'}}>
                            Phone
                            </th>
                            <td>{authHolderInfo.contactPersonPhone}</td>
                        </tr>
                        <tr>
                            <th scope="row" style={{width:'40%'}}>
                            Email address
                            </th>
                            <td>{authHolderInfo.contactPersonEmailAddress}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className='card-header'>
                <button type="button" className="btn btn-primary" 
                    onClick={() => { cancelConfrimSubmissionAndGoToPage(1); }}>
                        Edit
                </button>
            </div>
        </div>

        <div className='card mb-3'>
            <div className='card-header fw-bold'>Details of Approved Works</div>
            <div className='card-body'>
                <table class="table fs-90 mb-3">
                    <tbody>
                        <tr>
                            <th scope="row" style={{width:'40%'}}>
                            Property name
                            </th>
                            <td>{appWorkInfo.propertyName}</td>
                        </tr>
                        <tr>
                            <th scope="row" style={{width:'40%'}}>
                            Lot DP
                            </th>
                            <td>{appWorkInfo.lotDp}</td>
                        </tr>
                        <tr>
                            <th scope="row" style={{width:'40%'}}>
                            Work approval number
                            </th>
                            <td>{appWorkInfo.waterSupplyWorkApprovalNo}</td>
                        </tr>
                        {
                            (appWorkInfo.approvedByCustomer == 0) || (appWorkInfo.approvedByCustomer == 1 && waterAccessLicenceNoExternalList.length == 0) &&
                            <tr>
                                <th scope="row" style={{width:'40%'}}>
                                Water access license number(s)
                                </th>
                                <td>{appWorkInfo.waterAccessLicenceNo}</td>
                            </tr>
                        }
                        {
                            appWorkInfo.approvedByCustomer == 1 && waterAccessLicenceNoExternalList.length > 0 &&
                            <tr>
                                <th scope="row" style={{width:'40%'}}>
                                Water access license number(s)
                                </th>
                                <td>{appWorkInfo.waterAccessLicenceNo}</td>
                            </tr>
                        }
                        <tr>
                            <th scope="row" style={{width:'40%'}}>
                            Unique extraction site identifier (ESID)
                            </th>
                            <td>{appWorkInfo.esid}</td>
                        </tr>
                        {
                            appWorkInfo.approvedByCustomer == 1 && meterNumberExternalList.length > 0 &&
                            (
                                (
                                    meterEquipInfo.newMeterEquipment == METER_VALIDATION_TYPE.EXISTING &&
                                    meterEquipInfo.meterFormType == METER_FORM_TYPES.METER_VALIDATION
                                ) 
                                ||
                                meterEquipInfo.meterFormType == METER_FORM_TYPES.METER_ACCURACY) 
                                &&
                                <tr>
                                    <th scope="row" style={{width:'40%'}}>
                                    Selected meter
                                    </th>
                                    <td>{appWorkInfo.selectedMeter}</td>
                                </tr>
                        }
                        {((
                            meterEquipInfo.meterFormType == METER_FORM_TYPES.METER_VALIDATION) ||
                            meterEquipInfo.meterFormType == METER_FORM_TYPES.METER_ACCURACY) &&
                            <tr>
                                <th scope="row" style={{width:'40%'}}>
                                Meter number
                                </th>
                                <td>{appWorkInfo.meterNumber}</td>
                            </tr>
                        }
                        <tr>
                            <th scope="row" style={{width:'40%'}}>
                            Name of water source
                            </th>
                            <td>{appWorkInfo.waterSourceName}</td>
                        </tr>
                        <tr>
                            <th scope="row" style={{width:'40%'}}>
                            Type of water source
                            </th>
                            <td>
                                {masterDataValues.waterSourceTypes &&  masterDataValues.waterSourceTypes.map((ws) => {
                                    if(ws.id == appWorkInfo.waterSourceTypeId) {
                                        return ws.name;
                                    }
                                })}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className='card-footer'>
                <button type="button" className="btn btn-primary" 
                    onClick={() => { cancelConfrimSubmissionAndGoToPage(2); }}>
                        Edit
                </button>
            </div>
        </div>


        {(meterEquipInfo.newMeterEquipment == METER_VALIDATION_TYPE.NEW && meterEquipInfo.newMeterEquipment.length == 1 && parseInt(meterEquipInfo.newMeterEquipment[0]) == 1) && <>
            <div className='card mb-3'>
                <div className='card-header fw-bold'>New Metering Equipment</div>
                <div className='card-body'>
                    <table class="table fs-90 mb-3">
                        <tbody>
                            <tr>
                                <th className='w-25'>
                                Meter system type
                                </th>
                                <td>
                                    {masterDataValues.meteringSystemTypes &&  masterDataValues.meteringSystemTypes.map((st) => {
                                        if(st.id == meterEquipInfo.meteringSystemTypeId) {
                                            return st.name;
                                        }
                                    })}
                                </td>
                            </tr>
                            <tr>
                                <th>
                                Meter manufacturer &amp; model
                                </th>
                                <td>
                                    {masterDataValues.meterModelTypes &&  masterDataValues.meterModelTypes.map((st) => {
                                        if(st.id == meterEquipInfo.meterTypeModelId) {
                                            return st.name;
                                        }
                                    })}
                                </td>
                            </tr>
                            {
                                (parseInt(masterDataValues.MeterModelTypeOtherId) === parseInt(meterEquipInfo.meterTypeModelId)) &&
                                <tr>
                                    <th>
                                    Other meter manufacturer &amp; model
                                    </th>
                                    <td>{meterEquipInfo.meterTypeModel}</td>
                                </tr>
                            }
                            <tr>
                                <th>
                                Meter serial number
                                </th>
                                <td>{meterEquipInfo.meterSerialNo}</td>
                            </tr>
                            <tr>
                                <th>
                                Pattern approval number (if applicable)
                                </th>
                                <td>{meterEquipInfo.patternApprovalNumber}</td>
                            </tr>
                            <tr>
                                <th>
                                Meter size (mm)
                                </th>
                                <td>{meterEquipInfo.meterSize}</td>
                            </tr>
                            {(meterEquipInfo.meteringSystemTypeId != 2 && <>
                                <tr>
                                    <th>
                                    Pipe type
                                    </th>
                                    <td>{meterEquipInfo.pipeType}</td>
                                </tr>
                                <tr>
                                    <th>
                                    Pipe size (mm)
                                    </th>
                                    <td>{meterEquipInfo.pipeSize}</td>
                                </tr>
                                <tr>
                                    <th>
                                    Pipe connection type
                                    </th>
                                    <td>{meterEquipInfo.pipeConnectionType}</td>
                                </tr>
                                <tr>
                                    <th>
                                    Pipe length upstream of meter (mm)
                                    </th>
                                    <td>{meterEquipInfo.pipeLengthUpstream}</td>
                                </tr>
                                <tr>
                                    <th>
                                    Pipe length downstream of meter (mm)
                                    </th>
                                    <td>{meterEquipInfo.pipeLengthDownstream}</td>
                                </tr>
                            </>)}
                            <tr>
                                <th>
                                Meter Q1 flow rate minimum
                                </th>
                                <td>{meterEquipInfo.meterq1FlowRateMinimum}</td>
                            </tr>
                            <tr>
                                <th>
                                Meter Q3 flow rate optimum
                                </th>
                                <td>{meterEquipInfo.meterq3FlowRateOptimum}</td>
                            </tr>
                            <tr>
                                <th>
                                Design flow rate
                                </th>
                                <td>{meterEquipInfo.designFlowRate}</td>
                            </tr>
                            <tr>
                                <th>
                                Software version
                                </th>
                                <td>{meterEquipInfo.softwareVersion}</td>
                            </tr>

                            <tr>
                                <th>
                                Meter location longitude
                                </th>
                                <td>{meterEquipInfo.longitude}</td>
                            </tr>
                            <tr>
                                <th>
                                Meter location latitude
                                </th>
                                <td>{meterEquipInfo.latitude}</td>
                            </tr>
                            <tr>
                                <th>
                                Meter Description
                                </th>
                                <td>{meterEquipInfo.meterDescription}</td>
                            </tr>
                            <tr>
                                <th>
                                Type of supply
                                </th>
                                <td>
                                {masterDataValues.supplyTypes &&  masterDataValues.supplyTypes.map((st) => {
                                    if(st.id == meterEquipInfo.supplyTypeId) {
                                        return st.name;
                                    }
                                })}
                                </td>
                            </tr>
                            <tr>
                                <th>
                                Meter reading at installation
                                </th>
                                <td>{meterEquipInfo.meterReading}</td>
                            </tr>
                            <tr>
                                <th>
                                Date of meter reading
                                </th>
                                <td>
                                    {meterEquipInfo.meterReadingDate==""?"":moment((new Date(meterEquipInfo.meterReadingDate))).format('DD/MM/YYYY')}
                                </td>
                            </tr>
                            <tr>
                                <th>
                                Tamper-evident seal numbers
                                </th>
                                <td>
                                    {meterEquipInfo.sealNo &&  meterEquipInfo.sealNo.map((sn) => {
                                        return sn.label + "; ";
                                    })}
                                </td>
                            </tr>
                            <tr>
                                <th>
                                Data collector type
                                </th>
                                <td>
                                    {masterDataValues.dataCollectorTypes &&  masterDataValues.dataCollectorTypes.map((dt) => {
                                        if(dt.id == meterEquipInfo.dataCollectorTypeId) {
                                            return dt.name;
                                        }
                                    })}
                                </td>
                            </tr>
                            {(meterEquipInfo.dataCollectorTypeId !=4 && meterEquipInfo.dataCollectorTypeId !='4' )&& <>
                                <tr>
                                    <th>
                                    Model number
                                    </th>
                                    <td>
                                        {meterEquipInfo.dataCollectorModelNo}
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                    Serial number
                                    </th>
                                    <td>
                                        {meterEquipInfo.dataCollectorSerialNo}
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                    Power source
                                    </th>
                                    <td>
                                        {masterDataValues.powerSources &&  masterDataValues.powerSources.map((ps) => {
                                            if(ps.id == meterEquipInfo.dataCollectorPowerSourceId) {
                                                if(ps.name == 'Select...') return "";
                                                return ps.name;
                                            }
                                        })}
                                    </td>
                                </tr>
                                {(meterEquipInfo.dataCollectorPowerSourceId == 1) && <tr>
                                        <th scope="row" style={{width:'40%'}}>
                                        Solar panel serial number
                                        </th>
                                        <td>
                                            {meterEquipInfo.dataCollectorSolarPanelSerialNumber}
                                        </td>
                                </tr>}
                            </>}
                            { !(props.masterDataValues.OpenChannelId != props.meterEquipInfo.meteringSystemTypeId) && <>
                                <tr>
                                    <th>
                                    Channel type
                                    </th>
                                    <td>
                                        {meterEquipInfo.channelType}
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                    Channel width (m)
                                    </th>
                                    <td>
                                        {meterEquipInfo.channelWidth}
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                    Distance of the meter from pumping source or filling point (m)
                                    </th>
                                    <td>
                                        {meterEquipInfo.meterDistanceFromSource}
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                    Channel length upstream of meter (m)
                                    </th>
                                    <td>
                                        {meterEquipInfo.channelLengthUpstream}
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                    Channel length downstream of meter (m)
                                    </th>
                                    <td>
                                        {meterEquipInfo.channelLlengthDownstream}
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                    Design
                                    </th>
                                    <td>
                                        {meterEquipInfo.designEquipmentDetails}
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                    The metering equipment that has been installed is consistent with the design certificate
                                    </th>
                                    <td>
                                    {meteringEquipmentConsistentOptions.map((mc) => {
                                        if(mc.id == meterEquipInfo.meteringEquipmentConsistent) {
                                            return mc.name;
                                        }
                                    })}
                                    </td>
                                </tr>
                            </>}
                        </tbody>
                    </table>
                </div>
                {!(props.masterDataValues.OpenChannelId != props.meterEquipInfo.meteringSystemTypeId) && <>
                    <div className='card-header fw-bold'>Calibration Measurement Details</div>
                    <div className='card-body'>
                        <table class="table fs-90 mb-3">
                            <tbody>
                                <tr>
                                    <th className='w-25'>
                                    Maximum measured discharge (m³/s)
                                    </th>
                                    <td>
                                        {meterEquipInfo.maxMeasuredDischarge}
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                    Minimum measured discharge (m³/s)
                                    </th>
                                    <td>
                                        {meterEquipInfo.minMeasuredDischarge}
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                    Maximum measured velocity (m/s)
                                    </th>
                                    <td>
                                        {meterEquipInfo.maxMeasuredVelocity}
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                    Minimum measured velocity (m/s)
                                    </th>
                                    <td>
                                        {meterEquipInfo.minMeasuredVelocity}
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                    Maximum gauge height (m)
                                    </th>
                                    <td>
                                        {meterEquipInfo.maxGaugeHeight}
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                    Minimum gauge height (m)
                                    </th>
                                    <td>
                                        {meterEquipInfo.minGaugeHeight}
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                    Maximum error (%)
                                    </th>
                                    <td>
                                        {meterEquipInfo.maxError}
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                    Minimum error (%)
                                    </th>
                                    <td>
                                        {meterEquipInfo.minError}
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                    Number of measurements undertaken for index equation (if applicable)
                                    </th>
                                    <td>
                                        {meterEquipInfo.noOfMeasurementsTaken}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </>}
                <div className='card-header fw-bold'>
                    Method used to perform in situ volumetric measurements
                </div>
                <div className='card-body'>
                    {meterEquipInfo.openChannelConclusion}
                </div>
                <div className='card-footer'>
                    <button type="button" className="btn btn-priamry" 
                        onClick={() => { cancelConfrimSubmissionAndGoToPage(3); }}>
                            Edit
                    </button>
                </div>
            </div>
        </>}
        {(meterEquipInfo.newMeterEquipment == METER_VALIDATION_TYPE.EXISTING) && <>  
        	<div className='card mb-3'>
				<div className='card-header fw-bold'>Existing Metering Equipment</div>
                <div className='card-body'>
                    <table class="table fs-90 mb-3">
                        <tbody>
                            <tr>
                                <th className='w-25'>
                                Please indicate all circumstances that apply to your metering equipment installed before 1 April 2019
                                </th>
                                <td>
                                    {meteringCircumstanceValidationOptions.map((o) => {
                                        for(var i =0; i < meterEquipInfo.meterCircumstancesOptions.length; i++){
                                            if(o.id == meterEquipInfo.meterCircumstancesOptions[i]) {
                                                return <p>{o.name}</p>;
                                            }
                                        }
                                    })}
                                </td>
                            </tr>
                            <tr>
                                <th>
                                Date of last validation
                                </th>
                                <td>
                                {meterEquipInfo.dateOfLastValidation==""?"":moment((new Date(meterEquipInfo.dateOfLastValidation))).format('DD/MM/YYYY')}
                                </td>
                            </tr>
                            <tr>
                                <th>
                                Meter system type
                                </th>
                                <td>
                                    {masterDataValues.meteringSystemTypes &&  masterDataValues.meteringSystemTypes.map((st) => {
                                        if(st.id == meterEquipInfo.meteringSystemTypeId) {
                                            return st.name;
                                        }
                                    })}
                                </td>
                            </tr>
                            <tr>
                                <th>
                                Meter manufacturer &amp; model
                                </th>
                                <td>
                                    {masterDataValues.meterModelTypes &&  masterDataValues.meterModelTypes.map((o) => {
                                        if(o.id == meterEquipInfo.meterTypeModelId) {
                                            return o.name;
                                        }
                                    })}
                                </td>
                            </tr>
                            {(parseInt(masterDataValues.MeterModelTypeOtherId) === parseInt(meterEquipInfo.meterTypeModelId)) &&
                                <tr>
                                    <th>
                                    Other meter manufacturer &amp; model
                                    </th>
                                    <td>
                                    {meterEquipInfo.meterTypeModel}
                                    </td>
                                </tr>
                            }
                            <tr>
                                <th>
                                Meter serial number
                                </th>
                                <td>
                                {meterEquipInfo.meterSerialNo}
                                </td>
                            </tr>
                            
                            <tr>
                                <th>
                                Meter size (mm)
                                </th>
                                <td>
                                {meterEquipInfo.meterSize}
                                </td>
                            </tr>
                            {(meterEquipInfo.meteringSystemTypeId != 2 && <>
                                <tr>
                                    <th>
                                    Pipe type
                                    </th>
                                    <td>
                                    {meterEquipInfo.pipeType}
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                    Pipe size (mm)
                                    </th>
                                    <td>
                                    {meterEquipInfo.pipeSize}
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                    Pipe connection type
                                    </th>
                                    <td>
                                    {meterEquipInfo.pipeConnectionType}
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                    Pipe length upstream of meter (mm)
                                    </th>
                                    <td>
                                    {meterEquipInfo.pipeLengthUpstream}
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                    Pipe length downstream of meter (mm)
                                    </th>
                                    <td>
                                    {meterEquipInfo.pipeLengthDownstream}
                                    </td>
                                </tr>
                            </>)}
                            <tr>
                                <th>
                                Meter location longitude
                                </th>
                                <td>
                                {meterEquipInfo.longitude}
                                </td>
                            </tr>
                            <tr>
                                <th>
                                Meter location latitude
                                </th>
                                <td>
                                {meterEquipInfo.latitude}
                                </td>
                            </tr>
                            <tr>
                                <th>
                                Type of supply
                                </th>
                                <td>
                                    {masterDataValues.supplyTypes &&  masterDataValues.supplyTypes.map((o) => {
                                        if(o.id == meterEquipInfo.supplyTypeId) {
                                            return o.name;
                                        }
                                    })}
                                </td>
                            </tr>
                            <tr>
                                <th>
                                Tamper-evident seal numbers
                                </th>
                                <td>
                                    {meterEquipInfo.sealNo &&  meterEquipInfo.sealNo.map((sn) => {
                                        return sn.label + "; ";
                                    })}
                                </td>
                            </tr>
                            <tr>
                                <th>
                                Data collector type
                                </th>
                                <td>
                                    {masterDataValues.dataCollectorTypes &&  masterDataValues.dataCollectorTypes.map((dt) => {
                                        if(dt.id == meterEquipInfo.dataCollectorTypeId) {
                                            return dt.name;
                                        }
                                    })}
                                </td>
                            </tr>
                            {(meterEquipInfo.dataCollectorTypeId !=4 && meterEquipInfo.dataCollectorTypeId !='4' ) && <>
                                <tr>
                                    <th>
                                    Model number
                                    </th>
                                    <td>
                                    {meterEquipInfo.dataCollectorModelNo}
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                    Serial number
                                    </th>
                                    <td>
                                    {meterEquipInfo.dataCollectorSerialNo}
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                    Power source
                                    </th>
                                    <td>
                                        {masterDataValues.powerSources &&  masterDataValues.powerSources.map((ps) => {
                                            if(ps.id == meterEquipInfo.dataCollectorPowerSourceId) {
                                                if(ps.name == 'Select...') return "";
                                                return ps.name;
                                            }
                                        })}
                                    </td>
                                </tr>
                                {(meterEquipInfo.dataCollectorPowerSourceId == 1) &&
                                    <tr>
                                        <th>
                                        Solar panel serial number
                                        </th>
                                        <td>
                                        {meterEquipInfo.dataCollectorSolarPanelSerialNumber}
                                        </td>
                                    </tr>
                                }
                            </>}
                            {(parseInt(masterDataValues.OpenChannelId) == parseInt(meterEquipInfo.meteringSystemTypeId)) && <>
                                <tr>
                                    <th>
                                    Channel type
                                    </th>
                                    <td>
                                    {meterEquipInfo.channelType}
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                    Channel width (m)
                                    </th>
                                    <td>
                                    {meterEquipInfo.channelWidth}
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                    Distance of the meter from pumping source or filling point (m)
                                    </th>
                                    <td>
                                    {meterEquipInfo.meterDistanceFromSource}
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                    Channel length upstream of meter (m)
                                    </th>
                                    <td>
                                    {meterEquipInfo.channelLengthUpstream}
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                    Channel length downstream of meter (m)
                                    </th>
                                    <td>
                                    {meterEquipInfo.channelLlengthDownstream}
                                    </td>
                                </tr>
                            </>}
                        </tbody>
                    </table>
                </div>
                <div className='card-footer'>
                    <button type="button" className="btn btn-primary" 
                        onClick={() => { cancelConfrimSubmissionAndGoToPage(4); }}>
                            Edit
                    </button>
                </div>
            </div>
        </>}
        
        {hasError && <div className='alert alert-danger my-3'>
        Please fix all errors before you continue
        </div>}

        <div class="text-end">
            {(parseInt(meterEquipInfo.formStatus) == 1) && (!props.disableEditing) && <>
                <button type="button"  className="btn btn-danger me-3" disabled={loading} 
                data-bs-toggle="modal" data-bs-target="#deleteDraftModal">
                    Delete Draft
                </button>
                <button type="button"  className="btn btn-primary" disabled={loading} 
                    onClick={() => { 
                        saveDraftAction(props); 
                    }}>
                    Save Draft
                </button>
                <div className='vr mx-3'></div>
            </>}
            <button type="button" className="btn btn-warning me-3" 
                disabled={loading}
               onClick={cancelConfrimSubmission}>
                  Previous
            </button>
            <button type="button" className="btn btn-success"
                disabled={loading}
               onClick={completeConfirmation}>
                  {(parseInt(meterEquipInfo.formStatus) == 1) ? "Confirm" : "Next"}
            </button>
        </div>
    </form>
  )
}

TransitionalReportingConfirmation = reduxForm({
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate,
    onSubmitFail: (errors, dispatch ) => {
        dispatch(change('certificate', 'hasError', true));
    },
    onSubmitSuccess:(result , dispatch )=>{
        dispatch(change('certificate', 'hasError', false));
        window.scrollTo(0, 0);
    }
 })(TransitionalReportingConfirmation)

TransitionalReportingConfirmation = connect(
  (state, props) => {
    const selectorCert = formValueSelector('certificate')
    const hasError = selectorCert(state, 'hasError')

    const selector = formValueSelector(props.form);
    const meterEquipInfo = selector(state, 'meteringEquipmentInformation')
    const authHolderInfo = selector(state, 'authorityHolderDetails');
    const esidExternalList = selector(state, 'esidExternalList');
    const meterNumberExternalList = selector(state, 'meterNumberExternalList');
    const waterAccessLicenceNoExternalList = selector(state, 'waterAccessLicenceNoExternalList');
    const appWorkInfo = selector(state, 'approvedWorkDetails');
    const documents = selector(state, 'documents')
    const meterNonComplianceReason = selector(state, 'meterNonComplianceReason')
    const meterNonCompliance = selector(state, 'meterNonCompliance')
    const registrationDoc = selector(state, 'registrationDoc')
    const userProfileValues = selector(state, 'mdata.userProfile')
    const masterDataValues = selector(state, 'mdata')

    const consentData = selector(state, 'consentData')

    const loading = selector(state, 'loading')

    const form = props.form;
    
    return {
        meterEquipInfo,
        authHolderInfo,
        esidExternalList,
        meterNumberExternalList,
        waterAccessLicenceNoExternalList,
        appWorkInfo,
        masterDataValues,
        documents,
        meterNonComplianceReason,
        meterNonCompliance,
        registrationDoc,
        userProfileValues,
        consentData,
        form,
        loading,
        hasError
    }
  }
)(TransitionalReportingConfirmation)

export default TransitionalReportingConfirmation;

