import React from 'react';
import { AsteriskRed } from '../../../../components/tools/Others';

export default class ValidationEmplacementFields  extends React.Component {

    render = () => {
        const form = this.props.validationForm;
        return <>
            {form.renderTextBoxRow('pipeLengthUpstream',<>Pipe length upstream of meter (mm)</>,{rowClasses:'mb-3'})}
            {form.renderTextBoxRow('pipeLengthDownstream',<>Pipe length downstream of meter (mm)</>,{})}
        </>
    }
}