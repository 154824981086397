import React from 'react';
import {auditActions, periodStatus} from './constants'
import moment from 'moment'

export const convertStatus = (status) => {
    switch(status){
        case periodStatus.open.value : return <span className="text-success">{periodStatus.open.desc}</span>;
        case periodStatus.closed.value : return <span className="text-warning">{periodStatus.closed.desc}</span>;
        case periodStatus.finalised.value : return <span className="text-muted">{periodStatus.finalised.desc}</span>;
        default: return <span className="text-danger">Unknown</span>;
    }
};

export const convertAuditAction = (action) => {
    switch(action){
        case auditActions.create.value : return <span>{auditActions.create.desc}</span>;
        case auditActions.changeDates.value : return <span>{auditActions.changeDates.desc}</span>;
        case auditActions.close.value : return <span>{auditActions.close.desc}</span>;
        case auditActions.finalise.value : return <span>{auditActions.finalise.desc}</span>;
        case auditActions.addOtherInFlow.value : return <span>{auditActions.addOtherInFlow.desc}</span>;
        case auditActions.updateOtherInFlow.value : return <span>{auditActions.updateOtherInFlow.desc}</span>;
        case auditActions.deleteOtherInFlow.value : return <span>{auditActions.deleteOtherInFlow.desc}</span>;
        case auditActions.saveReading.value : return <span>{auditActions.saveReading.desc}</span>;
        case auditActions.delete.value : return <span>{auditActions.delete.desc}</span>;
        case auditActions.adminAction.value : return <span>{auditActions.adminAction.desc}</span>;
        case auditActions.saveReasonForNotLodging.value : return <span>{auditActions.saveReasonForNotLodging.desc}</span>;
        case auditActions.turnOnManual.value : return <span>{auditActions.turnOnManual.desc}</span>;
        case auditActions.turnOffManual.value : return <span>{auditActions.turnOffManual.desc}</span>;
        default: return <span className="text-danger">Unknown</span>;
    }
}

export const convertDateIsoToAusFormat = (date, whenEmpty) =>{
    return date ? moment((new Date(date))).format('DD/MM/YYYY') : whenEmpty?whenEmpty:'';
}

export const convertDateTimeIsoToAusFormat = (date, whenEmpty) =>{
    return date ? moment((new Date(date))).format('DD/MM/YYYY h:mm A') : whenEmpty?whenEmpty:'';
}

export const convertOtherInFlowType = (type) => {
    switch(type){
        case "REG" : return "Regulated water";
        case "UNREG" : return "Unregulated water";
        case "GROUNDWATER" : return "Goundwater";
        case "SUPPLEMENTARY" : return "Supplementary water";
        default: return "Unknown";
    }
}

export const nameOfCustomer = (customer) => {
    if(!customer) return "";
    if(customer.inpaType == "P") return customer.givenName + " " + customer.familyName;
    return customer.organisationName;
}

export const convertErrorCode = (errorCode) => {

    switch(errorCode) {
        case 'APPROVAL_EMPTY': return "Please provide an approval";
        case "APPROVAL_NO_ACCESS": return "You cannot create nominated measurement period for this approval";
        case "START_DATE_INVALID": return "Start date is invalid";
        case "OVERLAPPING_PERIOD": return "There is already a nominated measurement period overlaping this period";
        case "INVALID_DATES": return "Invalid value of provided date(s)";
        case "INVALID_STATUS_FOR_ACTION": return "You cannot perform this action now";
        case "INVALID_STATUS_FOR_DELETION": return "You cannot delete this record under this status";
        case "INVALID_ACTION": return "You cannot perform this action";
        case "TOTAL_INCOMPLTE": return "Incomplete readings for the period to calculate total value";
        case "INFLOW_DATES_OUTSIDE": return "Other inflow period cannot be outside nomination period";
        case 'INVALID_STATUS_FOR_FINALIZE': return '';
        case 'INVALID_STATUS_FOR_CLOSE': return '';
        case 'NO_STORAGE_DATA': return '';

        case 'MANDATORY_FILED_IS_EMPTY': return 'Some mandatory field(s) is empty';
        case 'PERIOD_START_DATE_EMPTY': return 'Period start date is empty';
        case 'PERIOD_END_DATE_EMPTY': return 'Period end date is empty';
        case 'PERIOD_END_DATE_OUTSIDE': return 'Outside the period';
        case 'INVALID_PERIOD_START_DATE': return 'Invalid period start date';
        case 'INVALID_PERIOD_END_DATE': return 'Invalid period end date';

        default:return "Unknown error happened on server"
    }
}