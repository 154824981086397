import React, { useRef } from 'react';
import { Field, reduxForm, formValueSelector, change } from 'redux-form';
import { connect } from 'react-redux'
import 'react-redux';
import 'redux';
import "react-datepicker/dist/react-datepicker.css";
import { WebMapViewActualLocation } from '../../tools/DQP.Map';
import { checkSerialNumber } from '../meterValidation/MeterValidationCertificate.Reducers'
import { saveDraftAction, clickNextAction, scrollTofocusError } from "../common/MeterFormValidations"
import { validateCertificatePartD } from './TransitionalReporting.Validation';
import { InputTextForRedux, RadiosForRedux, DatePickerForRedux, CheckboxForRedux, SelectForRedux, CreatableSelectForRedux } from '../../tools/BootstrapControls'

const validate = validateCertificatePartD;

let TransitionalReportingPartC = props => {

   const {
      handleSubmit, masterDataValues, meterEquipInfo, previousPage, loading, hasError
   } = props

   const disableFields = (parseInt(meterEquipInfo.formStatus) == 2) || props.disableEditing || loading? true : false;

   const childRef = useRef(null);
   const updateMapPoints = () => {
      childRef.current.updateActualMapPoints({ longitude: meterEquipInfo.longitude, latitude: meterEquipInfo.latitude });
   }

   const updatePoints = (x, y) => {

      props.dispatch(change(props.form, 'meteringEquipmentInformation.latitude', parseFloat(y).toFixed(7)));
      props.dispatch(change(props.form, 'meteringEquipmentInformation.longitude', parseFloat(x).toFixed(7)));
      //props.dispatch(change(props.form, 'workDetails.distance', distance.toFixed(2)));
      //props.dispatch(change(props.form,'workDetails.actualLotNo',lotdp));

   }
   const meteringCircumstanceValidationOptions =
      [
         { id: "1", name: "I have an existing validation that was done by a duly qualified person", selected: false },
         { id: "2", name: "I have a manufacturers certificate", selected: false },
         { id: "3", name: "I have an accuracy certificate provided by a duly qualified person", selected: false }
      ];

   const meteringEquipmentConsistentOptions =
      [
         { id: "1", name: "Yes", selected: false },
         { id: "2", name: "No", selected: false }
      ];
   const meterInstalledBeforeApril2019Options =
      [
         { id: "1", name: "Yes", selected: false },
         { id: "2", name: "No", selected: false }
      ];

   const resetValues = (event) => {

   }

   if (meterEquipInfo.dateOfInstallationBeforeApril2019 != null && meterEquipInfo.dateOfInstallationBeforeApril2019 != "")
      meterEquipInfo.dateOfInstallationBeforeApril2019 = new Date(meterEquipInfo.dateOfInstallationBeforeApril2019);

   if (meterEquipInfo.dateOfLastValidation != null && meterEquipInfo.dateOfLastValidation != "")
      meterEquipInfo.dateOfLastValidation = new Date(meterEquipInfo.dateOfLastValidation);

   if (meterEquipInfo.meterRemovedDate != null && meterEquipInfo.meterRemovedDate != "")
      meterEquipInfo.meterRemovedDate = new Date(meterEquipInfo.meterRemovedDate);

   if (meterEquipInfo.dateBeforeMaintenance != null && meterEquipInfo.dateBeforeMaintenance != "")
      meterEquipInfo.dateBeforeMaintenance = new Date(meterEquipInfo.dateBeforeMaintenance);

   if (meterEquipInfo.dateAfterMaintenance != null && meterEquipInfo.dateAfterMaintenance != "")
      meterEquipInfo.dateAfterMaintenance = new Date(meterEquipInfo.dateAfterMaintenance);

   if (meterEquipInfo.meterReadingDate != null && meterEquipInfo.meterReadingDate != "")
      meterEquipInfo.meterReadingDate = new Date(meterEquipInfo.meterReadingDate);

   if (meterEquipInfo.finalReadingDateRemovedMeter != null && meterEquipInfo.finalReadingDateRemovedMeter != "")
      meterEquipInfo.finalReadingDateRemovedMeter = new Date(meterEquipInfo.finalReadingDateRemovedMeter);


   if (meterEquipInfo.supplyTypeId == 0 && masterDataValues.supplyTypes.length > 0)
      props.dispatch(change(props.form, 'meteringEquipmentInformation.supplyTypeId', masterDataValues.supplyTypes[0].id))

   return (
      <form onSubmit={handleSubmit}>

         <h5 className='mb-3'>Metering Equipment Information</h5>

         <div className='mb-3'>
            <Field
               name="meteringEquipmentInformation.meterCircumstancesOptions"
               component={CheckboxForRedux}
               label={<>Please indicate all circumstances that apply to your metering equipment installed before 1 April 2019<span className='text-danger'>* </span></>}
               options={meteringCircumstanceValidationOptions}
               props={{ disabled: disableFields }}
            />
         </div>

         <div className='row mb-3'>
            <div className='col-sm-3'>
               <Field
                  name="meteringEquipmentInformation.dateOfLastValidation"
                  handleChange={date => { props.change(props.form, 'meteringEquipmentInformation.dateOfLastValidation', date) }}
                  component={DatePickerForRedux}
                  values={meterEquipInfo.dateOfLastValidation}
                  props={{ disabled: disableFields, maxDate: new Date() }}
                  label="Date of validation"
               />

            </div>
         </div>
         <div className='mb-3'>
            <Field
               name={"meteringEquipmentInformation.meteringSystemTypeId"}
               props={{ disabled: disableFields }}
               component={RadiosForRedux}
               options={masterDataValues.meteringSystemTypes}
               values={meterEquipInfo.meteringSystemTypeId}
               handleChange={resetValues}
               label={<>Metering system type <span className='text-danger'>* </span></>}
            />
         </div>


         {(parseInt(props.meterEquipInfo.meteringSystemTypeId) != 0) && <>

            <div className='row mb-3'>
               <div className='col-sm-6'>
                  <Field
                     name="meteringEquipmentInformation.meterTypeModelId"
                     component={SelectForRedux}
                     options={masterDataValues.meterModelTypes}
                     label={<>Meter manufacturer &amp; model <span className='text-danger'>*</span></>}
                     props={{ disabled: disableFields }}
                  />
               </div>
               {(parseInt(masterDataValues.MeterModelTypeOtherId) === parseInt(meterEquipInfo.meterTypeModelId)) && <div className='col-sm-6'>
                  <Field
                     name="meteringEquipmentInformation.meterTypeModel"
                     component={InputTextForRedux}
                     label={<>Other meter manufacturer &amp; model</>}
                     props={{ disabled: disableFields }}
                  />
               </div>}
            </div>
            <div className='row mb-3'>
               <div className='col-sm-6'>
                  <Field
                     name="meteringEquipmentInformation.meterSerialNo"
                     props={{ disabled: disableFields }}
                     label={<>Meter serial number <span className='text-danger'>*</span></>}
                     component={InputTextForRedux}
                     onBlur={() => props.dispatch(checkSerialNumber(props.meterEquipInfo.id, props.meterEquipInfo.meterSerialNo))}
                  />
               </div>
               <div className='col-sm-6'>
                  <Field
                     name="meteringEquipmentInformation.meterSize"
                     props={{ disabled: disableFields }}
                     label={<>Meter size (mm) <span className='text-danger'>*</span></>}
                     component={InputTextForRedux}
                  />
               </div>
            </div>

            {(meterEquipInfo.meteringSystemTypeId != 2 && <>

               <div className='row mb-3'>
                  <div className='col-sm-6'>
                     <Field
                        name="meteringEquipmentInformation.pipeType"
                        props={{ disabled: disableFields }}
                        label="Pipe type"
                        component={InputTextForRedux}
                     />
                  </div>
                  <div className='col-sm-6'>
                     <Field
                        name="meteringEquipmentInformation.pipeSize"
                        props={{ disabled: disableFields }}
                        label="Pipe size (mm)"
                        laebl={<>Pipe size (mm) <span className='text-danger'>*</span></>}
                        component={InputTextForRedux}
                     />
                  </div>
               </div>
               <div className='row mb-3'>
                  <div className='col-sm-6'>
                     <Field
                        name="meteringEquipmentInformation.pipeConnectionType"
                        props={{ disabled: disableFields }}
                        label="Pipe connection type"
                        component={InputTextForRedux}
                     />
                  </div>
                  <div className='col-sm-6'>
                     <Field
                        name="meteringEquipmentInformation.pipeLengthUpstream"
                        props={{ disabled: disableFields }}
                        label="Pipe length upstream of meter (mm)"
                        component={InputTextForRedux}
                     />
                  </div>
               </div>
               <div className='row mb-3'>
                  <div className='col-sm-6'>
                     <Field
                        name="meteringEquipmentInformation.pipeLengthDownstream"
                        props={{ disabled: disableFields }}
                        label="Pipe length downstream of meter (mm)"
                        component={InputTextForRedux}
                     />
                  </div>
               </div>
            </>)}

            <div class='row mb-3'>
               <div className='col-sm-6'>
                  <label className='form-label'>Meter location <span style={{ color: 'red' }}>*</span></label>
                  <Field
                     name="meteringEquipmentInformation.longitude"
                     component={InputTextForRedux}
                     className="mb-2"
                     props={{ disabled: disableFields, placeholder: 'longitude' }}
                  />
                  <Field
                     name="meteringEquipmentInformation.latitude"
                     component={InputTextForRedux}
                     props={{ disabled: disableFields, placeholder: 'latitude' }}
                  />
               </div>
            </div>
            <div class="mb-3" id="map" style={{ minHeight: "360px" }}>
               <WebMapViewActualLocation
                  form="Accuracy Certificate"
                  formStatus={meterEquipInfo.formStatus}
                  updatePoints={updatePoints}
                  ref={childRef}
                  actualPoint={{ latitude: meterEquipInfo.latitude, longitude: meterEquipInfo.longitude }}
               />
            </div>
            <div class='row mb-3'>
               <div className='col-sm-6'>
                  <Field
                     name="meteringEquipmentInformation.supplyTypeId"
                     component={SelectForRedux}
                     options={masterDataValues.supplyTypes}
                     props={{ disabled: disableFields }}
                     label="Type of supply"
                  />
               </div>
            </div>
            <div class='row mb-3'>
               <div className='col-sm-6'>
                  <label className='form-label'>Record the tamper-evident seal numbers<span className='text-danger'>* </span></label>
                  <Field
                     name='meteringEquipmentInformation.sealNo'
                     component={CreatableSelectForRedux}
                     props={{ disabled: disableFields }}
                     options={meterEquipInfo.sealNo}
                     placeholder={"Record the tamper-evident seal numbers"}
                  />
               </div>
            </div>
            <div class='row mb-3'>
               <div className='col-sm-6'>
                  <Field
                     name="meteringEquipmentInformation.dataCollectorTypeId"
                     component={SelectForRedux}
                     options={masterDataValues.dataCollectorTypes.filter(function (option) { return option.isActive == true })}
                     props={{ disabled: disableFields }}
                     label={<>Data collector type <span className='text-danger'>*</span></>}
                  />
               </div>
            </div>

            {(meterEquipInfo.dataCollectorTypeId != 4 && meterEquipInfo.dataCollectorTypeId != '4') && <>
               <div class='row mb-3'>
                  <div className='col-sm-6'>
                     <Field
                        name="meteringEquipmentInformation.dataCollectorModelNo"
                        props={{ disabled: disableFields }}
                        label={<>Model number <span className='text-danger'>*</span></>}
                        component={InputTextForRedux}
                     />
                  </div>
                  <div className='col-sm-6'>
                     <Field
                        name="meteringEquipmentInformation.dataCollectorSerialNo"
                        props={{ disabled: disableFields }}
                        label={<>Serial number <span className='text-danger'>*</span></>}
                        component={InputTextForRedux}
                     />
                  </div>
               </div>
               <div class='row mb-3'>
                  <div className='col-sm-6'>
                     <Field
                        name="meteringEquipmentInformation.dataCollectorPowerSourceId"
                        component={SelectForRedux}
                        options={masterDataValues.powerSources}
                        props={{ disabled: disableFields }}
                        label="Power source"
                     />
                  </div>
                  {(meterEquipInfo.dataCollectorPowerSourceId == 1) && <div className='col-sm-6'>
                     <Field
                        name="meteringEquipmentInformation.dataCollectorSolarPanelSerialNumber"
                        props={{ disabled: disableFields }}
                        label="Solar panel serial number"
                        component={InputTextForRedux}
                     />
                  </div>}
               </div>
            </>}

            {(parseInt(props.masterDataValues.OpenChannelId) == parseInt(props.meterEquipInfo.meteringSystemTypeId)) && <>
               <div class='row mb-3'>
                  <div className='col-sm-6'>
                     <Field
                        name="meteringEquipmentInformation.channelType"
                        props={{ disabled: disableFields }}
                        label={<>Channel type <span className='text-danger'>*</span></>}
                        component={InputTextForRedux}
                     />
                  </div>
               </div>
               <div class='row mb-3'>
                  <div className='col-sm-6'>
                     <Field
                        name="meteringEquipmentInformation.channelWidth"
                        props={{ disabled: disableFields }}
                        label={<>Channel width (m) <span className='text-danger'>*</span></>}
                        component={InputTextForRedux}
                     />
                  </div>
                  <div className='col-sm-6'>
                     <Field
                        name="meteringEquipmentInformation.meterDistanceFromSource"
                        props={{ disabled: disableFields }}
                        label={<>Distance of the meter from pumping source or filling point (m) <span className='text-danger'>*</span></>}
                        component={InputTextForRedux}
                     />
                  </div>
               </div>
               <div class='row mb-3'>
                  <div className='col-sm-6'>
                     <Field
                        name="meteringEquipmentInformation.channelLengthUpstream"
                        props={{ disabled: disableFields }}
                        label={<>Channel length upstream of meter (m) <span className='text-danger'>*</span></>}
                        component={InputTextForRedux}
                     />
                  </div>
                  <div className='col-sm-6'>
                     <Field
                        name="meteringEquipmentInformation.channelLlengthDownstream"
                        props={{ disabled: disableFields }}
                        label={<>Channel length downstream of meter (m) <span className='text-danger'>*</span></>}
                        component={InputTextForRedux}
                     />
                  </div>
               </div>
            </>}
         </>}

         {hasError && <div className='alert alert-danger my-3'>
         Please fix all errors before you continue
         </div>}


         <div className="text-end">
            {(parseInt(meterEquipInfo.formStatus) == 1) && (!props.disableEditing) && <>
               {meterEquipInfo.id!=0 &&
                  <button type="button"  className="btn btn-danger me-3" disabled={loading} 
                     data-bs-toggle="modal" data-bs-target="#deleteDraftModal">
                     Delete Draft
                  </button>
               }
            
               <button type="button" className="btn btn-primary" disabled={loading}
                  onClick={() => { saveDraftAction(props); }}>
                  Save Draft
               </button>

               <div className='vr mx-3'></div>
            </>}
            <button type="button" disabled={loading} className="btn btn-warning me-3" onClick={()=>{previousPage(meterEquipInfo.formStatus)}}>
               Previous
            </button>
            <button type="button" disabled={loading} className="btn btn-success"
               onClick={() => { clickNextAction(props); }}>
               Next
            </button>
         </div>
      </form>
   )
}

TransitionalReportingPartC = reduxForm({
   destroyOnUnmount: false,
   forceUnregisterOnUnmount: true,
   validate,
   onSubmitFail: (errors, dispatch ) => {
      dispatch(change('certificate', 'hasError', true));
   },
   onSubmitSuccess:(result , dispatch )=>{
      dispatch(change('certificate', 'hasError', false));
      window.scrollTo(0, 0);
   }
})(TransitionalReportingPartC)

TransitionalReportingPartC = connect(
   (state, props) => {
      const selectorCert = formValueSelector('certificate')
      const hasError = selectorCert(state, 'hasError')

      // can select values individually
      const selector = formValueSelector(props.form)
      const masterDataValues = selector(state, 'mdata')
      const meterEquipInfo = selector(state, 'meteringEquipmentInformation')
      const documents = selector(state, 'documents')
      const loading = selector(state, 'loading')
      const form = props.form
      return {
         masterDataValues,
         meterEquipInfo,
         documents,
         form,
         loading,
         hasError
      }
   },
   {
      checkSerialNumber: (serialNumber) => checkSerialNumber(serialNumber)
   }
)(TransitionalReportingPartC)

export default TransitionalReportingPartC